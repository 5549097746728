var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (disableToggle, useCanvas) {
      pug_html = pug_html + "\u003Cdiv id=\"scalebar-template\"\u003E\u003Cdiv class=\"leaflet-control-attribution leaflet-control-scale\"\u003E";
if (!disableToggle) {
pug_html = pug_html + "\u003Cdiv class=\"show-scalebar\" id=\"toggle-scale-units\"\u003E\u003Ca id=\"toggle-scale-units-href\" href=\"#\" rel=\"tipsy\" title=\"Toggle scale units\"\u003E\u003Cspan class=\"glyphicon glyphicon-refresh\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"no-scalebar\"\u003EAn accurate scalebar cannot be displayed at this zoom level\u003C\u002Fdiv\u003E";
if (useCanvas) {
pug_html = pug_html + "\u003Ccanvas class=\"show-scalebar scalebar-canvas leaflet-canvas-marker\"\u003E\u003C\u002Fcanvas\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"show-scalebar scalebar-canvas\" id=\"no-canvas\"\u003E\u003Cspan\u003E&nbsp\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"show-unit\" id=\"scalebar-zero\"\u003E0\u003C\u002Fdiv\u003E\u003Cdiv class=\"show-unit\" id=\"scalebar-label\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "disableToggle" in locals_for_with ?
        locals_for_with.disableToggle :
        typeof disableToggle !== 'undefined' ? disableToggle : undefined, "useCanvas" in locals_for_with ?
        locals_for_with.useCanvas :
        typeof useCanvas !== 'undefined' ? useCanvas : undefined));
    ;;return pug_html;};
module.exports = template;