/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';

import {
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_USERS_REQUEST,
  FETCH_PROJECT_USERS_SUCCESS,
  FETCH_PROJECT_USERS_FAILURE,
} from '.';

const fetchProjectRequest = createAction(FETCH_PROJECT_REQUEST);
const fetchProjectSuccess = createAction(FETCH_PROJECT_SUCCESS);
const fetchProjectFailure = createAction(FETCH_PROJECT_FAILURE);
const fetchProjectUsersRequest = createAction(FETCH_PROJECT_USERS_REQUEST);
const fetchProjectUsersSuccess = createAction(FETCH_PROJECT_USERS_SUCCESS);
const fetchProjectUsersFailure = createAction(FETCH_PROJECT_USERS_FAILURE);

export function fetchProjectAction(projectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/projects/${projectId}`,
      lifecycleActions: [
        fetchProjectRequest,
        fetchProjectSuccess,
        fetchProjectFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchProjectUsersAction(projectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/projects/${projectId}/users`,
      lifecycleActions: [
        fetchProjectUsersRequest,
        fetchProjectUsersSuccess,
        fetchProjectUsersFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}
