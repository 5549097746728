var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (alertClass, analysis_date, availableSamplingMethods, detection_limit, isPHLinked, method, methodDisplayText, method_slug, name, parameterSlug, parameter_value, reporting_limit, samplingUnitsDisplayText) {
      pug_mixins["parameterColumn"] = pug_interp = function(isPHLinked, parameterSlug, parameterValue, samplingUnitsDisplayText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E";
if (!isPHLinked || parameterSlug !== 'ph') {
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cinput" + (" class=\"location-parameter-value form-control\""+" type=\"text\""+pug.attr("value", parameterValue, true, true)+" style=\"width: 60px; margin-right: 4px\"") + "\u003E\u003Clabel\u003E" + (pug.escape(null == (pug_interp = samplingUnitsDisplayText) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = parameterValue ? parameterValue + ' ' + samplingUnitsDisplayText : 'N/A') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["methodColumn"] = pug_interp = function(isPHLinked, parameterSlug, availableSamplingMethods){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E";
if (isPHLinked && parameterSlug === 'ph') {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = methodDisplayText || 'N/A') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (!availableSamplingMethods.length) {
pug_html = pug_html + "\u003Cspan\u003EN\u002FA\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cselect class=\"location-method form-control\" style=\"width: 100%\"\u003E";
// iterate availableSamplingMethods
;(function(){
  var $$obj = availableSamplingMethods;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var samplingMethod = $$obj[pug_index0];
if (method_slug === samplingMethod.slug) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", samplingMethod.slug, true, true)+" selected=\"selected\"") + "\u003E" + (pug.escape(null == (pug_interp = samplingMethod.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", samplingMethod.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = samplingMethod.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var samplingMethod = $$obj[pug_index0];
if (method_slug === samplingMethod.slug) {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", samplingMethod.slug, true, true)+" selected=\"selected\"") + "\u003E" + (pug.escape(null == (pug_interp = samplingMethod.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (pug.attr("value", samplingMethod.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = samplingMethod.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["detectionLimitColumn"] = pug_interp = function(parameterSlug, detectionLimit, samplingUnitsDisplayText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (parameterSlug != 'ph') {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput" + (" class=\"detection-limit form-control\""+" type=\"text\""+pug.attr("value", detectionLimit, true, true)+" style=\"width: 75px; margin-right: 4px\"") + "\u003E\u003Clabel\u003E" + (pug.escape(null == (pug_interp = samplingUnitsDisplayText) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
};
pug_mixins["reportingLimitColumn"] = pug_interp = function(parameterSlug, reportingLimit, samplingUnitsDisplayText){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (parameterSlug != 'ph') {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput" + (" class=\"reporting-limit form-control\""+" type=\"text\""+pug.attr("value", reportingLimit, true, true)+" style=\"width: 75px; margin-right: 4px\"") + "\u003E\u003Clabel\u003E" + (pug.escape(null == (pug_interp = samplingUnitsDisplayText) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
};
pug_mixins["analysisDateColumn"] = pug_interp = function(parameterSlug, analysisDate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\" style=\"max-width: 300px; padding: 0px\"\u003E";
var isDisabled = parameterSlug === 'ph' && method_slug !== 'epa_150_1' && isPHLinked;
var formGroupStyle = isDisabled ? 'cursor: not-allowed;' : '';
pug_html = pug_html + "\u003Cdiv" + (" class=\"form-group\""+pug.attr("style", pug.style(formGroupStyle), true, true)) + "\u003E";
var inputGroupStyle = isDisabled ? 'pointer-events: none;' : '';
pug_html = pug_html + "\u003Cdiv" + (" class=\"input-group date\""+" data-role=\"datepicker\""+pug.attr("style", pug.style(inputGroupStyle), true, true)) + "\u003E\u003Cinput" + (" class=\"form-control\""+" type=\"text\""+pug.attr("value", analysisDate, true, true)+pug.attr("disabled", isDisabled, true, true)) + "\u003E\u003Cspan class=\"input-group-addon\"\u003E\u003Ci class=\"glyphicon glyphicon-th\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([alertClass], [true]), false, true)) + "\u003E\u003Cdiv class=\"row form-inline\" style=\"padding: 10px 0; border-bottom-color: gray\"\u003E\u003Cdiv class=\"col-xs-2 text-center\"\u003E" + (pug.escape(null == (pug_interp = name || 'Unnamed Location') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (isPHLinked && parameterSlug === 'ph' && !parameter_value && !method) {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-9\"\u003E\u003Cspan\u003EPlease add pH value and method to the corresponding Sampling Observation form.\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_mixins["parameterColumn"](isPHLinked, parameterSlug, parameter_value, samplingUnitsDisplayText);
pug_mixins["methodColumn"](isPHLinked, parameterSlug, availableSamplingMethods);
pug_mixins["reportingLimitColumn"](parameterSlug, reporting_limit, samplingUnitsDisplayText);
pug_mixins["detectionLimitColumn"](parameterSlug, detection_limit, samplingUnitsDisplayText);
pug_mixins["analysisDateColumn"](parameterSlug, analysis_date);
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "alertClass" in locals_for_with ?
        locals_for_with.alertClass :
        typeof alertClass !== 'undefined' ? alertClass : undefined, "analysis_date" in locals_for_with ?
        locals_for_with.analysis_date :
        typeof analysis_date !== 'undefined' ? analysis_date : undefined, "availableSamplingMethods" in locals_for_with ?
        locals_for_with.availableSamplingMethods :
        typeof availableSamplingMethods !== 'undefined' ? availableSamplingMethods : undefined, "detection_limit" in locals_for_with ?
        locals_for_with.detection_limit :
        typeof detection_limit !== 'undefined' ? detection_limit : undefined, "isPHLinked" in locals_for_with ?
        locals_for_with.isPHLinked :
        typeof isPHLinked !== 'undefined' ? isPHLinked : undefined, "method" in locals_for_with ?
        locals_for_with.method :
        typeof method !== 'undefined' ? method : undefined, "methodDisplayText" in locals_for_with ?
        locals_for_with.methodDisplayText :
        typeof methodDisplayText !== 'undefined' ? methodDisplayText : undefined, "method_slug" in locals_for_with ?
        locals_for_with.method_slug :
        typeof method_slug !== 'undefined' ? method_slug : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "parameterSlug" in locals_for_with ?
        locals_for_with.parameterSlug :
        typeof parameterSlug !== 'undefined' ? parameterSlug : undefined, "parameter_value" in locals_for_with ?
        locals_for_with.parameter_value :
        typeof parameter_value !== 'undefined' ? parameter_value : undefined, "reporting_limit" in locals_for_with ?
        locals_for_with.reporting_limit :
        typeof reporting_limit !== 'undefined' ? reporting_limit : undefined, "samplingUnitsDisplayText" in locals_for_with ?
        locals_for_with.samplingUnitsDisplayText :
        typeof samplingUnitsDisplayText !== 'undefined' ? samplingUnitsDisplayText : undefined));
    ;;return pug_html;};
module.exports = template;