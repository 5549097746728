/* eslint-disable */
// Services
var APP = require('../config');

// Views
var LayerNameChooserView = require('../formWidgets/LayerNameChooserView');

// Collections
var layerCollection =
  require('../models_collections/layerCollection').singleton();

var QuestionsView = Backbone.View.extend({
  template: require('./templates/questions.pug'),

  initialize: function () {
    APP.vent.on('upload:modalHidden', this._reset, this);
  },

  /**
   * Renders the view and sets up the TwitterTypeahead
   */
  render: function () {
    var layerType = 'marker';

    this.$el = $('#questions');
    this.$el.append(this.template());
    this._layerNameChooserView = this._renderLayerNameChooserView();
    this._layerNameChooserView.activate(layerCollection, layerType);
    this._initEvents();

    return this;
  },

  _renderLayerNameChooserView: function () {
    var layerNameChooserView = new LayerNameChooserView();
    this.$('.layer-name-widget-container').html(
      layerNameChooserView.render().el,
    );
    return layerNameChooserView;
  },

  _initEvents: function () {
    this.off('click');
    this.$('#add').click(this._handleAddLayerClick.bind(this));
    this.$('#btn-addresses').click(this._handleBtnAddressesClick.bind(this));
    this.$('#btn-latlng').click(this._handleBtnLatLngClick.bind(this));
    this.$('#no-separate-columns').click(
      this._handleNoSeparateColumnsClick.bind(this),
    );
    this.$('#yes-separate-columns').click(
      this._handleYesSeparateColumnsClick.bind(this),
    );
  },

  /**
   * Empty out the view
   */
  _reset: function () {
    this.$el.empty();
  },

  /**
   * Executes what needs to be done when user clicks on button to choose a layer
   */
  _handleAddLayerClick: function () {
    var layerId = this._layerNameChooserView.getValue();
    APP.vent.trigger('upload:layerQuestionAnswered', layerId);
    this.showNextQuestion();
  },

  /**
   * Executes what needs to be done when user clicks No that there are
   * separate column headers.
   */
  _handleNoSeparateColumnsClick: function () {
    this.showUnstructuredAddressQuestion();
    APP.vent.trigger('upload:hasSeparateColumnHeaders', true);
  },

  /**
   * Executes what needs to be done when user clicks Yes that there are
   * separate column headers.
   */
  _handleYesSeparateColumnsClick: function () {
    this.$('.active').removeClass('active');
    this.$('#question-column-street-address').addClass('active');
    APP.vent.trigger('upload:hasSeparateColumnHeaders', false);
  },

  /**
   * Executes what needs to be done when user clicks that the file contains
   * address values.
   */
  _handleBtnAddressesClick: function () {
    this.showSeparateColumnsQuestion();
    APP.vent.trigger('upload:addressOrLatLngQuestionAnswered', 'addresses');
  },

  /**
   * Executes what needs to be done when user clicks that the file contains
   * Latitude/Longitude values.
   */
  _handleBtnLatLngClick: function () {
    this.$('.active').removeClass('active');
    this.$('#question-latlng').addClass('active');
    APP.vent.trigger('upload:addressOrLatLngQuestionAnswered', 'latlng');
  },

  /**
   * Hides the currently active question and shows the next question in the DOM
   */
  showNextQuestion: function () {
    this.$('.active').removeClass('active').next().addClass('active');
  },

  /**
   * Hides the currently active question and shows the previous question in the DOM
   */
  showPreviousQuestion: function () {
    this.$('.active').removeClass('active').prev().addClass('active');
  },

  /**
   * Hides the currently active question and shows the file type question
   */
  showFileTypeQuestion: function () {
    this._showQuestionHelper('#question-type');
  },

  /**
   * Hides the currently active question and shows the separate columns question
   */
  showSeparateColumnsQuestion: function () {
    this._showQuestionHelper('#question-separate-columns');
  },

  /**
   * Hides the currently active question and shows the feature label question
   */
  showFeatureLabelQuestion: function () {
    this._showQuestionHelper('#question-feature-label');
  },

  /**
   * Hides the currently active question and shows the longitude question
   */
  showLongitudeQuestion: function () {
    this._showQuestionHelper('#question-longitude');
  },

  /**
   * Hides the currently active question and shows the postal code question
   */
  showPostalCodeQuestion: function () {
    this._showQuestionHelper('#question-postal-code');
  },

  /**
   * Hides the currently active question and shows the unstructured address question
   */
  showUnstructuredAddressQuestion: function () {
    this._showQuestionHelper('#question-column-addresses');
  },

  /**
   * Hides the currently active question and shows the last question
   */
  showLastQuestion: function () {
    this._showQuestionHelper('#last-question');
  },

  hideAllQuestions: function () {
    this.$('.active').removeClass('active');
  },

  /**
   * Used by all the other show function to hide the currently active question
   * and show question that has the id that was passed in.
   *
   * Parameters:
   *     id: string
   *        This should be CSS id
   *
   * Return:
   *     None
   */
  _showQuestionHelper: function (id) {
    this.$('.active').removeClass('active');
    this.$(id).addClass('active');
  },
});

Backbone.addSingletonToView(QuestionsView);
module.exports = QuestionsView;
