/* eslint-disable */
var _ = require('underscore');
var GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');

var AlabamaSamplingEventParameterView =
  GenericSamplingEventParameterView.extend({
    regions: {
      locations: '.locations-container',
      dischargeLimitRow: '.benchmark-limit-row-container',
    },

    _afterOnShow: function () {
      if (this.model.get('daily_max')) this._showDischargeLimitRow();
    },

    _showDischargeLimitRow: function () {
      let DischargeLimitRowView = require('./DischargeLimitRowView');
      let dischargeLimitRowView = new DischargeLimitRowView({
        model: this.model,
        sectorData: this.sectorData,
      });
      this.dischargeLimitRow.show(dischargeLimitRowView);
    },

    _getUpdatedModelAttributes(parameter) {
      return {
        locations: parameter.locations,
        daily_max: parameter.daily_max,
        daily_min: parameter.daily_min,
        monthly_average_limit: parameter.monthly_average_limit,
      };
    },

    _isRequiredSamplingParameter(parameterSlug) {
      return this.sectorData.isRequiredSamplingParameter({
        permitDsns: this.projectModel.getPermitDsnSlugs(),
        industrialActivities: this.projectModel.getIndustrialActivitySlugs(),
        parameterSlug,
      });
    },
  });

module.exports = AlabamaSamplingEventParameterView;
