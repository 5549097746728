var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (confirmed, did_sampling, rainfallAmt, stormLabel, user_generated) {
      if (user_generated || confirmed === true || confirmed === null) {
pug_html = pug_html + "\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (did_sampling) {
pug_html = pug_html + "\u003Ci class=\"fa fa-flask\" rel=\"tipsy\" title=\"Sample was collected\"\u003E\u003C\u002Fi\u003E";
}
else
if (confirmed === null && !user_generated) {
pug_html = pug_html + "\u003Ci class=\"fa fa-question-circle\" rel=\"tipsy\" title=\"We automatically detected this rain event using weather station data. Please click on the event to confirm or delete the storm.\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca class=\"storm-label edit-or-delete-storm-event\" rel=\"tipsy\" title=\"Edit or delete storm event\"\u003E" + (pug.escape(null == (pug_interp = stormLabel + rainfallAmt) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
    }.call(this, "confirmed" in locals_for_with ?
        locals_for_with.confirmed :
        typeof confirmed !== 'undefined' ? confirmed : undefined, "did_sampling" in locals_for_with ?
        locals_for_with.did_sampling :
        typeof did_sampling !== 'undefined' ? did_sampling : undefined, "rainfallAmt" in locals_for_with ?
        locals_for_with.rainfallAmt :
        typeof rainfallAmt !== 'undefined' ? rainfallAmt : undefined, "stormLabel" in locals_for_with ?
        locals_for_with.stormLabel :
        typeof stormLabel !== 'undefined' ? stormLabel : undefined, "user_generated" in locals_for_with ?
        locals_for_with.user_generated :
        typeof user_generated !== 'undefined' ? user_generated : undefined));
    ;;return pug_html;};
module.exports = template;