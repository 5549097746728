import React from 'react';
import PropTypes from 'prop-types';
import { CalendarName } from 'mapistry-shared';

import RecordPanelCalendarAccordion from '../../../views/projectDashboard/RecordPanelCalendarAccordion';
import { CalendarFrequencyType } from '../../../propTypes';

const PhCalibrationRecordList = ({
  calendarFrequencies,
  monitoringLocations,
  onEditAttachment,
  onDeleteAttachment,
  onFetchAttachment,
  projectId,
}) => (
  <RecordPanelCalendarAccordion
    calendarName={CalendarName.PH_LOG_CALIBRATION}
    frequencies={calendarFrequencies}
    onDeleteAttachment={onDeleteAttachment}
    onEditAttachment={onEditAttachment}
    onFetchAttachment={onFetchAttachment}
    parameterSlug="ph"
    projectId={projectId}
    resourceConversionCollection={monitoringLocations}
  />
);

PhCalibrationRecordList.propTypes = {
  calendarFrequencies: PropTypes.arrayOf(CalendarFrequencyType).isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onEditAttachment: PropTypes.func.isRequired,
  onFetchAttachment: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  projectId: PropTypes.string.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PhCalibrationRecordList;
