/* eslint-disable */
var PermitSummaryRowView = require('./PermitSummaryRowView');

var PermitSummaryTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',

  className: 'table table-striped',

  template: require('./templates/permitSummaryTable.pug'),

  childView: PermitSummaryRowView,

  childViewContainer: 'tbody',
});

module.exports = PermitSummaryTableView;
