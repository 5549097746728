/* eslint-disable */
let _ = require('underscore');
let projectStateConfig = require('mapistry-project-state-config');

let SectorDataView = Backbone.Marionette.LayoutView.extend({
  className: 'row',

  template: require('./templates/sectorData.pug'),

  templateHelpers() {
    let currentSicCodeSlugs = this.model.getSicCodeSlugs();

    return {
      hasPollutantsOfConcernQuestions: this.hasPollutantsOfConcernQuestions,
      hasWashingtonQuestions: this.hasWashingtonQuestions,
      sicCodeRecords: this._getSicCodeRecords(currentSicCodeSlugs),
    };
  },

  regions: {
    sicCodeSummaryTable: '#sic-code-summary-table-container',
    industrialActivityChoices: '#industrial-activity-choices-container',
    receivingWaterSaltOrFresh: '#receiving-water-salt-or-fresh-container',
    pollutantsOfConcernContainer: '#pollutants-of-concern-container',
    washingtonContainer: '#washington-container',
  },

  ui: {
    $modal: '.modal',
    $error: '.alert-danger',
    $sicCodeSelect: 'select[name="sic_codes"]',
    $form: 'form',
  },

  events: {
    'click .edit-sic-codes': '_showModal',
    'click .btn-submit': '_saveSectorData',
    submit: '_saveSectorData',
    'hidden.bs.modal': 'render',
    'change @ui.$sicCodeSelect': '_handleChangeSicCodeSelect',
  },

  behaviors: {
    Tooltip: {},
  },

  modelEvents: {
    'change:sic_codes change:industrial_activities change:receiving_water_is_saltwater change:receiving_water_hardness change:is_western_wa change:is_rcra_facility change:site_discharges_into_puget_sound':
      '_handleSectorDataChange',
  },

  initialize() {
    let APP = require('../../../../../../config');
    this.industrialStormwaterSectorData =
      require('industrial-stormwater-sector-data')(this.model.get('state'));
    this.listenTo(APP.vent, 'sectorData:openModal', this._showModal.bind(this));

    const project = this.model.toJSON();
    this.hardnessDoesNotMatter = projectStateConfig.isOneOfStatesIndustrial(
      project,
      ['al', 'pa', 'wa'],
    );
    this.alwaysFreshwater = projectStateConfig.isOneOfStatesIndustrial(
      project,
      ['oh', 'il'],
    );
    this.receivingWaterDoesNotMatter =
      projectStateConfig.isOneOfStatesIndustrial(project, ['ar', 'pa', 'wv']);
    this.hasPollutantsOfConcernQuestions = projectStateConfig.isStateIndustrial(
      project,
      'ny',
    );
    this.hasWashingtonQuestions =
      projectStateConfig.isWashingtonIndustrial(project);
  },

  onShow() {
    let sicCodeSlugs = this.model.getSicCodeSlugs();
    this._resetModal();
    if (!sicCodeSlugs.length) {
      this._showModal();
    }
  },

  _initSicCodeSelect(sicCodeSlugs) {
    let data = _.map(
      this.industrialStormwaterSectorData.getAllSicCodeRecords(),
      function (record) {
        let text = [
          record.sic_code,
          record.subsector,
          record.activity_represented,
        ].join(' - ');
        let selected = _.contains(sicCodeSlugs, record.slug) ? true : false;
        return {
          id: record.slug,
          text: text,
          selected: selected,
        };
      },
    );

    this.ui.$sicCodeSelect.select2({
      placeholder: 'Please enter your SIC code(s) e.g. 2421',
      width: '100%',
      maximumInputLength: 4,
      data: data,
      templateSelection(record) {
        return record.id;
      },
    });
  },

  _showSicCodeSummaryTable(sicCodeRecords) {
    let SicCodeSummaryTableView = require('./SicCodeSummaryTableView');
    let sicCodeSummaryTableView = new SicCodeSummaryTableView({
      collection: new Backbone.Collection(sicCodeRecords),
    });
    this.sicCodeSummaryTable.show(sicCodeSummaryTableView);
  },

  _showPollutantsOfConcernContainer() {
    if (!this.hasPollutantsOfConcernQuestions) {
      return;
    }
    const PollutantsOfConcernRegionView = require('./PollutantsOfConcernRegionView');
    const pollutantsOfConcernRegionView = new PollutantsOfConcernRegionView({
      model: this.model,
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      pollutantsOfConcernOptions:
        this.industrialStormwaterSectorData.getPollutantsOfConcern(),
    });
    this.pollutantsOfConcernContainer.show(pollutantsOfConcernRegionView);
  },

  _showWashingtonContainer() {
    if (!this.hasWashingtonQuestions) {
      return;
    }
    let WashingtonRegionView = require('./WashingtonRegionView');
    let washingtonRegionView = new WashingtonRegionView({
      model: this.model,
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
    });
    this.washingtonContainer.show(washingtonRegionView);
  },

  _showIndustrialActivityChoices(industrialActivityRecords) {
    if (
      !industrialActivityRecords.length ||
      projectStateConfig.isStateIndustrial(this.model, 'ar')
    ) {
      return;
    }

    let IndustrialActivityChoicesView = require('./IndustrialActivityChoicesView');
    let industrialActivityChoicesView = new IndustrialActivityChoicesView({
      collection: new Backbone.Collection(industrialActivityRecords),
    });
    this.industrialActivityChoices.show(industrialActivityChoicesView);
  },

  _showReceivingWaterSaltOrFreshAndHardness() {
    if (this.receivingWaterDoesNotMatter) {
      return;
    }

    let ShowReceivingWaterSaltOrFreshView = require('./receivingWaterSaltOrFreshHardnessView');
    let showReceivingWaterSaltOrFreshView =
      new ShowReceivingWaterSaltOrFreshView({
        alwaysFreshwater: this.alwaysFreshwater,
        hardnessDoesNotMatter: this.hardnessDoesNotMatter,
        receivingWaterIsSaltWater: this.model.get(
          'receiving_water_is_saltwater',
        ),
        receivingWaterHardness: this.model.get('receiving_water_hardness'),
      });
    this.receivingWaterSaltOrFresh.show(showReceivingWaterSaltOrFreshView);
  },

  _resetModal() {
    let sicCodeSlugs = this.model.getSicCodeSlugs();
    let sicCodeRecords = this._getSicCodeRecords(sicCodeSlugs);
    let industrialActivityRecords =
      this._getIndustrialActivityRecords(sicCodeSlugs);

    this._initSicCodeSelect(sicCodeSlugs);

    if (!sicCodeRecords.length) {
      return;
    }

    this._showSicCodeSummaryTable(sicCodeRecords);
    this._showIndustrialActivityChoices(industrialActivityRecords);

    this._showReceivingWaterSaltOrFreshAndHardness();
    this._showPollutantsOfConcernContainer();
    this._showWashingtonContainer();
  },

  _showModal() {
    this._resetModal();
    this.ui.$modal.modal('show');
  },

  _getSicCodeValues() {
    return this.ui.$sicCodeSelect.val() || [];
  },

  _getIndustrialActivitySlugValues() {
    let industrialActivityValues = _.flatten([
      this.ui.$form.serializeObject().industrial_activities,
    ]);
    return industrialActivityValues.join(',');
  },

  _getReceivingWaterValue() {
    let receivingWaterIsSaltwater =
      this.ui.$form.serializeObject().receiving_water_is_saltwater;
    return receivingWaterIsSaltwater
      ? receivingWaterIsSaltwater === 'true'
      : null;
  },

  _getReceivingWaterHardnessValue() {
    return this.ui.$form.serializeObject().receiving_water_hardness || null;
  },

  _saveSectorData(e) {
    e.preventDefault();

    let modelChanges = {
      sic_codes: this._getSicCodeValues().join(),
      industrial_activities: this._getIndustrialActivitySlugValues(),
    };

    if (!this.receivingWaterDoesNotMatter) {
      if (!this.alwaysFreshwater) {
        modelChanges.receiving_water_is_saltwater =
          this._getReceivingWaterValue();
      }
      if (!this.hardnessDoesNotMatter) {
        modelChanges.receiving_water_hardness =
          this._getReceivingWaterHardnessValue();
      }
    }

    // wait for server to save so that when sampling parameters update, the sic code has been saved on server side
    this.model.save(modelChanges, { wait: true });

    this.ui.$modal.modal('hide');
  },

  _getSicCodesFromSicCodeSlugs(sicCodeSlugs) {
    return _.map(sicCodeSlugs, (sc) =>
      this.industrialStormwaterSectorData.getSicCode(sc),
    );
  },

  _getSicCodeRecords(sicCodeSlugs) {
    return _.map(sicCodeSlugs, (sc) =>
      this.industrialStormwaterSectorData.getSicCodeRecord(sc),
    );
  },

  _getIndustrialActivityRecords(sicCodeSlugs) {
    if (!this.industrialStormwaterSectorData.getIndustrialActivities) return [];
    let sicCodes = this._getSicCodesFromSicCodeSlugs(sicCodeSlugs);
    let industrialActivityRecords = _.flatten(
      _.map(sicCodes, (sc) =>
        this.industrialStormwaterSectorData.getIndustrialActivities(sc),
      ),
    );
    industrialActivityRecords = _.uniq(industrialActivityRecords, 'slug');

    let currentIndustrialActivitySlugs =
      this.model.getIndustrialActivitySlugs();

    return _.map(industrialActivityRecords, function (record) {
      return _.extend(record, {
        is_checked: _.contains(currentIndustrialActivitySlugs, record.slug),
      });
    });
  },

  _handleChangeSicCodeSelect() {
    let sicCodeSlugs = this._getSicCodeValues();
    let sicCodeRecords = this._getSicCodeRecords(sicCodeSlugs);
    let industrialActivityRecords =
      this._getIndustrialActivityRecords(sicCodeSlugs);

    this._showSicCodeSummaryTable(sicCodeRecords);
    this._showIndustrialActivityChoices(industrialActivityRecords);
    this._showReceivingWaterSaltOrFreshAndHardness();
    this._showPollutantsOfConcernContainer();
    this._showWashingtonContainer();
  },

  _handleSectorDataChange() {
    let APP = require('../../../../../../config');
    APP.vent.trigger('sectorData:change');
  },
});

module.exports = SectorDataView;
