import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GenericLogLoggedItemType, GenericLogType } from 'mapistry-shared';
import withProvider from '../../../../withProvider';
import EquationFlyout from './EquationFlyout';
import {
  getCalculatedValues,
  getEmissionFactors,
  getLoggedItems,
  getRollingCalculations,
  getUnitConversions,
} from '../../../../../selectors/genericLogs';

class EquationFlyoutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceCollection: [],
    };
    this.buildresourceCollection = this.buildresourceCollection.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { resourceId, resourceType, visible } = this.props;

    if (
      prevProps.resourceId !== resourceId ||
      (!prevProps.visible && visible)
    ) {
      this.setState(
        {
          resourceCollection: [],
        },
        () => {
          this.buildresourceCollection(resourceId, resourceType);
        },
      );
    }
  }

  buildresourceCollection(resourceId, resourceType, collection) {
    const { calculatedValues } = this.props;
    const { resourceCollection } = this.state;
    const nextCollection = collection || resourceCollection;
    const resourceWithDetails = this.resourceWithDetails(
      resourceId,
      resourceType,
    );

    if (resourceType === GenericLogType.CALCULATED_VALUE) {
      const calculatedValue = calculatedValues.find(
        (cv) => cv.id === resourceId,
      );
      const { factors } = calculatedValue;
      const nextChildren = factors.reduce((acc, factor) => {
        if (factor.resourceId) {
          acc.push(
            this.resourceWithDetails(factor.resourceId, factor.resourceType),
          );
          if (factor.resourceType === GenericLogType.CALCULATED_VALUE) {
            this.buildresourceCollection(
              factor.resourceId,
              factor.resourceType,
              resourceCollection,
            );
          }
        } else if (factor !== factors[factors.length - 1]) {
          acc.push(factor);
        }
        return acc;
      }, []);
      nextCollection.push({
        ...resourceWithDetails,
        children: nextChildren,
      });
    } else {
      nextCollection.push(resourceWithDetails);
    }

    this.setState({ resourceCollection: nextCollection });
  }

  resourceWithDetails(resourceId, resourceType) {
    const {
      calculatedValues,
      emissionFactors,
      loggedItems,
      rollingCalculations,
      unitConversions,
    } = this.props;

    let resourceWithDetails;

    switch (resourceType) {
      case GenericLogType.CALCULATED_VALUE: {
        const calculatedValue = calculatedValues.find(
          (cv) => cv.id === resourceId,
        );
        resourceWithDetails = {
          name: calculatedValue.name,
          label: 'Calculated Value',
        };
        break;
      }
      case GenericLogType.EMISSION_FACTOR: {
        const emissionFactor = emissionFactors.find(
          (ef) => ef.id === resourceId,
        );
        resourceWithDetails = {
          name: emissionFactor.name,
          label: emissionFactor.itemType,
          value: emissionFactor.itemValue,
          units: emissionFactor.units,
        };
        break;
      }
      case GenericLogType.LOGGED_ITEM: {
        const loggedItem = loggedItems.find((li) => li.id === resourceId);
        resourceWithDetails = {
          name: loggedItem.name,
          label: 'Logged Item',
        };
        break;
      }
      case GenericLogType.ROLLING_CALCULATION: {
        const rollingCalculation = rollingCalculations.find(
          (rc) => rc.id === resourceId,
        );
        resourceWithDetails = {
          name: rollingCalculation.name,
          label: 'Rolling calculation',
        };
        break;
      }
      case GenericLogType.UNIT_CONVERSION: {
        const unitConversion = unitConversions.find(
          (uc) => uc.id === resourceId,
        );
        resourceWithDetails = {
          name: unitConversion.name,
          label: 'Unit conversion',
        };
        break;
      }
      default:
        break;
    }
    return resourceWithDetails;
  }

  render() {
    const { onClose, visible } = this.props;

    const { resourceCollection } = this.state;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <EquationFlyout
        alignment="right"
        resourceCollection={resourceCollection}
        onClose={onClose}
        title="Item Information"
        visible={visible}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  const calculatedValues = getCalculatedValues(state, logProjectId);
  const emissionFactors = getEmissionFactors(state, logProjectId);
  const loggedItems = getLoggedItems(state, logProjectId);

  const calculatedValuesWithTypes = calculatedValues.map((cv) => ({
    ...cv,
    resourceType: GenericLogType.CALCULATED_VALUE,
  }));

  const emissionFactorsWithTypes = emissionFactors.map((factor) => ({
    ...factor,
    resourceType: GenericLogType.EMISSION_FACTOR,
  }));

  const loggedItemsWithTypes = loggedItems.map((item) => ({
    ...item,
    resourceType: GenericLogType.LOGGED_ITEM,
  }));

  const numericLoggedItemsWithTypes = loggedItemsWithTypes.filter(
    (item) => item.itemType === GenericLogLoggedItemType.NUMBER,
  );

  return {
    calculatedValues: calculatedValuesWithTypes,
    emissionFactors: emissionFactorsWithTypes,
    loggedItems: numericLoggedItemsWithTypes,
    rollingCalculations: getRollingCalculations(state, logProjectId),
    unitConversions: getUnitConversions(state, logProjectId),
  };
};

EquationFlyoutContainer.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  logProjectId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  resourceType: PropTypes.string,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unitConversions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visible: PropTypes.bool.isRequired,
};

EquationFlyoutContainer.defaultProps = {
  resourceId: null,
  resourceType: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, null)(EquationFlyoutContainer),
);
