/* eslint-disable */
var apiCaller = require('../../../../../../apiCaller');

var ProjectUserCollection = Backbone.Collection.extend({
  url: function () {
    return apiCaller.getProjectUsersUrl(this.projectId);
  },

  initialize: function (models, options = {}) {
    this.projectId = options.projectId;
    this.isFederalProject = options.isFederalProject;
    this.on('change:projectRoles', this.reloadUsersData, this);
  },

  reloadUsersData: function (e) {
    this.fetch();
  },

  comparator: 'userName',
});

module.exports = ProjectUserCollection;
