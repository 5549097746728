import APP from '@monorepo/old-web/js/config';
import {
  createKey as createUserPermissionsKey,
  fetcher as userPermissionsFetcher,
} from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { createFeatureFlagsKey } from 'mapistry-shared/api';
import { useEffect } from 'react';
import { queryCache } from 'react-query';

const prefetchConfig = { staleTime: Infinity, cacheTime: Infinity };

// globally prefetch some stuff that's definitely needed and causes
// UI jiggle otherwise on first load
export function ReactQueryPrefetchGlobals() {
  useEffect(() => {
    // prefetch user permissions
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryCache.prefetchQuery(
      createUserPermissionsKey(),
      userPermissionsFetcher,
      prefetchConfig,
    );

    // pre-set feature flags
    queryCache.setQueryData(
      createFeatureFlagsKey(),
      APP.featureFlags,
      prefetchConfig,
    );
  }, []);

  return null;
}
