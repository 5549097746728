/* eslint-disable */
let CollapsibleFormListRowView = require('../../../../../formLists/CollapsibleFormListRowView');

module.exports = CollapsibleFormListRowView.extend({
  childView: require('./StormEventRowItemView'),

  _getCollapseIdPrefix() {
    return 'rain-events';
  },

  _allGroupsCollapsible() {
    return true;
  },
});
