/* eslint-disable react/prop-types */

import React from 'react';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import MarkerError from './MarkerError';
import MarkerComplete from './MarkerComplete';
import MarkerEmpty from './MarkerEmpty';
import { MapistryTooltip } from '../../../elements';

class SectionChild extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickSection = this.handleClickSection.bind(this);
    this.deleteSubsection = this.deleteSubsection.bind(this);
  }

  handleClickSection() {
    const { onClick, templateGroupId, submissionGroupNumber } = this.props;
    onClick(templateGroupId, submissionGroupNumber);
  }

  statusMarker() {
    const { error, isComplete } = this.props;
    if (error) {
      return <MarkerError />;
    }
    if (isComplete) {
      return <MarkerComplete />;
    }
    return <MarkerEmpty />;
  }

  deleteSubsection(event) {
    const { onDeleteSubsection, templateGroupId, submissionGroupNumber } =
      this.props;
    event.stopPropagation();
    return onDeleteSubsection(templateGroupId, submissionGroupNumber);
  }

  render() {
    const { isGroupSelected, isComplete, isDeletable, isSelected, name } =
      this.props;
    const groupSelectedClass = isGroupSelected
      ? ' sidebar-group-section-selected'
      : '';
    const sectionCompleteClass = isComplete ? ' section-text-bold' : '';
    const sectionSelectedClass = isSelected
      ? ' sidebar-child-section-selected'
      : '';

    return (
      <div className="section-wrapper">
        <button
          type="button"
          className={`sidebar-child-section sidebar-section${groupSelectedClass}${sectionSelectedClass}`}
          onClick={this.handleClickSection}
        >
          {this.statusMarker()}
          <span className={`sidebar-section-text${sectionCompleteClass}`}>
            {name}
          </span>
        </button>
        {!isDeletable && (
          <MapistryTooltip placement="top" title={`Delete ${name}`}>
            <button
              type="button"
              className="delete-child-icon"
              onClick={this.deleteSubsection}
            >
              <SvgIcon identifier="trash" />
            </button>
          </MapistryTooltip>
        )}
      </div>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SectionChild;
