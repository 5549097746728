var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (errors, is_completed, label, warnings) {
      pug_html = pug_html + "\u003Cdiv class=\"flex align-items-center\"\u003E\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (is_completed) {
pug_html = pug_html + "\u003Csvg class=\"update-is-complete-checkmark check\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (errors.length) {
pug_html = pug_html + "\u003Csvg class=\"errors warning-red fire\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (warnings.length) {
pug_html = pug_html + "\u003Csvg class=\"warnings warning-yellow exclamation\"\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex justify-content-space-between align-items-center padding-right-20\"\u003E\u003Cdiv class=\"modal-container\"\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"show-hmbp-update margin-right-10\" rel=\"tipsy\" title=\"Edit &quot; + planName + &quot; Update Info\"\u003E\u003Csvg class=\"edit\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ca class=\"delete-hmbp-update red-on-hover\" rel=\"tipsy\" title=\"Delete &quot; + planName + &quot; Update\"\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "errors" in locals_for_with ?
        locals_for_with.errors :
        typeof errors !== 'undefined' ? errors : undefined, "is_completed" in locals_for_with ?
        locals_for_with.is_completed :
        typeof is_completed !== 'undefined' ? is_completed : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "warnings" in locals_for_with ?
        locals_for_with.warnings :
        typeof warnings !== 'undefined' ? warnings : undefined));
    ;;return pug_html;};
module.exports = template;