import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import _ from 'underscore';
import {
  Draggable,
  DroppableGroup,
  TABLE_SETTINGS,
  withDragDropContext,
} from '.';
import useWindowResizeHandler from '../../../../hooks/useWindowResizeHandler';
import {
  AnimatedComponent,
  FormErrors,
  LoadingIndicator,
  TBody,
  TH,
  THead,
  Table,
} from '../../../elements';
import { ANIMATION_TYPES } from '../../../elements/AnimatedComponent';
import AddItemGroup from '../AddItemGroup';
import VirtualizedListFixedRowHeight from './VirtualizedListFixedRowHeight';
import VirtualizedListVaryingRowHeight from './VirtualizedListVaryingRowHeight';

const EmissionTrackingTable = (props) => {
  const {
    isDragging,
    formSubmissionDraft,
    groupType,
    renderRow,
    renderRowWhileBeingDragged,
    getRowCountForGroup,
    handleDeleteGroup,
    hasFixedRowHeight,
    itemGroups,
    isLoading,
    logProjectId,
    formErrors,
    tableInfo,
    addItem,
    rowHeight,
  } = props;
  const { COLUMN_LABELS, TOOLTIP_TEXTS = [] } = tableInfo;

  const [scrollIndex, setScrollIndex] = useState(undefined);
  const [headerStyles, setHeaderStyles] = useState({});
  const [rowsDidRender, setRowsDidRender] = useState(false);

  const alignTableHeaders = useCallback(() => {
    const firstRow = document.querySelector(
      '.mapistry-table__body .mapistry-table__row',
    );
    if (firstRow) {
      const columns = firstRow.getElementsByClassName('mapistry-table__cell');
      const hStyles = {};
      columns.forEach((column, index) => {
        hStyles[index] = { width: column.offsetWidth };
      });
      setHeaderStyles(hStyles);
    }
  }, []);

  useLayoutEffect(alignTableHeaders, [rowsDidRender, alignTableHeaders]);

  useWindowResizeHandler(alignTableHeaders);

  const addItemAndScrollToIt = (groupId) => {
    const lastIndex = _get(formSubmissionDraft.groups, groupId, []).length;
    setScrollIndex(lastIndex);
    addItem(groupId);
  };

  const setRowsDidRenderCallback = React.useCallback(
    () => setRowsDidRender(true),
    [],
  );

  const getDraggableRow =
    (groupOfItems, groupId) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/no-unstable-nested-components
    ({
      // eslint-disable-next-line react/prop-types
      index,
      // eslint-disable-next-line react/prop-types
      style,
      // eslint-disable-next-line react/prop-types
      measure,
    }) => {
      if (index >= groupOfItems.length) return null;
      const loggedItem = groupOfItems[index];

      return (
        <Draggable
          uniqueId={loggedItem.renderKey}
          key={loggedItem.renderKey}
          index={index}
          style={style}
          afterRowRender={index === 0 ? setRowsDidRenderCallback : undefined}
        >
          {renderRow(loggedItem, index, groupId, measure, isDragging)}
        </Draggable>
      );
    };

  const getDisplayWhileBeingDragged = (groupOfItems) => (index) => {
    const loggedItem = groupOfItems[index];
    // usually not displaying the whole row for a smoother drag experience
    return renderRowWhileBeingDragged(loggedItem);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className="emission-tracking__header">
        <AddItemGroup groupType={groupType} logProjectId={logProjectId} />
      </div>
      <Table>
        {!!formErrors.displayable.length && (
          <div className="flex flex-column">
            <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
              <FormErrors errors={formErrors.displayable} />
            </AnimatedComponent>
          </div>
        )}
        <THead>
          {COLUMN_LABELS.map((label, index) => (
            <TH
              key={`tableHeading-${label}`}
              label={label}
              style={headerStyles[index]}
              tooltipText={TOOLTIP_TEXTS[index]}
            />
          ))}
        </THead>
        <TBody>
          {_.map(formSubmissionDraft.groups, (groupOfItems, groupId) => (
            <DroppableGroup
              addItemAndScrollToIt={addItemAndScrollToIt}
              groupId={groupId}
              key={groupId}
              groups={itemGroups}
              isDragging={isDragging}
              itemCount={groupOfItems.length}
              logProjectId={logProjectId}
              renderRowWhileBeingDragged={getDisplayWhileBeingDragged(
                groupOfItems,
              )}
              onDeleteGroup={() => handleDeleteGroup(groupId)}
            >
              {({ dropSnapshot }) =>
                hasFixedRowHeight ? (
                  <div>
                    <VirtualizedListFixedRowHeight
                      rowHeight={rowHeight || TABLE_SETTINGS.ROW_HEIGHT}
                      rowRenderer={getDraggableRow(groupOfItems, groupId)}
                      scrollIndex={scrollIndex}
                      groupOfItems={groupOfItems}
                      groupId={groupId}
                      isUsingPlaceholder={dropSnapshot.isUsingPlaceholder}
                    />
                  </div>
                ) : (
                  <div>
                    <VirtualizedListVaryingRowHeight
                      rowHeight={rowHeight || TABLE_SETTINGS.ROW_HEIGHT}
                      rowRenderer={getDraggableRow(groupOfItems, groupId)}
                      scrollIndex={scrollIndex}
                      getRowCountForGroup={getRowCountForGroup}
                      groupOfItems={groupOfItems}
                      groupId={groupId}
                      isUsingPlaceholder={dropSnapshot.isUsingPlaceholder}
                    />
                  </div>
                )
              }
            </DroppableGroup>
          ))}
        </TBody>
      </Table>
    </>
  );
};

EmissionTrackingTable.propTypes = {
  addItem: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  renderRow: PropTypes.func.isRequired,
  renderRowWhileBeingDragged: PropTypes.func.isRequired,
  getRowCountForGroup: PropTypes.func,
  groupType: PropTypes.string.isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  logProjectId: PropTypes.string.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  isDragging: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rowHeight: PropTypes.number,
  tableInfo: PropTypes.shape({
    COLUMN_LABELS: PropTypes.arrayOf(PropTypes.string),
    COLUMN_WIDTHS: PropTypes.arrayOf(PropTypes.number),
    COLUMN_GROWTH: PropTypes.arrayOf(PropTypes.string),
    TOOLTIP_TEXTS: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  hasFixedRowHeight: PropTypes.bool,
};

EmissionTrackingTable.defaultProps = {
  rowHeight: null,
  hasFixedRowHeight: true,
  getRowCountForGroup: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withDragDropContext(EmissionTrackingTable);
