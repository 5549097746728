/* eslint-disable */
import { waterTags } from 'mapistry-shared';

var _ = require('underscore');
const escape = require('lodash.escape');
const { convertSetToArray } = require('../../js/utils');
const BluebirdPromise = require('bluebird');

// Services
var AttrCollectionWrapper = require('./attrs/attrCollectionWrapper');
var featureCollection = require('./featureCollection').singleton();

var LayerModel = Backbone.Model.extend({
  defaults: {
    expanded: false,
  },

  initialize: function () {
    // This initializes the attrCollectionWrapper on page load
    if (this.id) {
      this.initializeAttrCollectionWrapper();
      return;
    }

    // This initializes the attrCollectionWrapper when creating a new layer
    this.listenTo(this, 'change:id', this.initializeAttrCollectionWrapper);
  },

  /**
   * When creating a new layer, this must explicitly be called on success because
   * the layer id doesn't exist until a successful response from the server is
   * received.
   *
   * @param name {string} The name of the attr
   * @return array - An array filled with errors
   */
  initializeAttrCollectionWrapper: function () {
    this.attrCollectionWrapper = new AttrCollectionWrapper(this);

    this.listenTo(this.attrCollectionWrapper, 'add', ({ attrValue }) =>
      this.trigger('change:attrs', attrValue),
    );
    this.listenTo(this.attrCollectionWrapper, 'change', (value) => {
      if (value && value.attrValue) {
        this.trigger('change:attrs', value.attrValue);
      }
    });
    this.listenTo(this.attrCollectionWrapper, 'destroy', ({ attrValue }) =>
      this.trigger('change:attrs', attrValue),
    );
  },

  /**
   * Override built-in destroy method to destroy the layer and its features.
   *
   * @return string
   */
  destroy: function (options) {
    var features = this.features();

    // Use { silent: true } because if this layer contains a lot of features
    // this will trigger a lot of remove events and possibly crash the
    // browser
    featureCollection.remove(features, { silent: true });
    featureCollection.trigger('removeBulk', features);

    return Backbone.Model.prototype.destroy.call(this, options);
  },

  /**
   * Returns a list of form tags or empty array if none was selected.
   * @returns {string[]}
   */
  getFormTags: function () {
    let tags = this.get('form_tags') || '[]';
    return JSON.parse(tags) || [];
  },

  /**
   * Gets the type of the layer in Leaflet terms
   *
   * @return string
   */
  getType: function () {
    var types = {
      point: 'marker',
      marker: 'marker',
      linestring: 'polyline',
      polyline: 'polyline',
      polygon: 'polygon',
      aerialimagery: 'aerialimagery',
    };

    let _type = this.get('type') || '';
    return types[_type.toLowerCase()];
  },

  containsMarkers: function () {
    return this.getType() === 'marker';
  },

  containsPolylines: function () {
    return this.getType() === 'polyline';
  },

  containsPolygons: function () {
    return this.getType() === 'polygon';
  },

  isAerialImageryLayer: function () {
    return this.getType() === 'aerialimagery';
  },

  containsCustomIcon: function () {
    return this.style().isCustomIcon();
  },

  /**
   * Loads all the attrs for this layer if necessary
   *
   * @return promise
   */
  ensureAttrsLoaded: function () {
    // Guard clause to prevent unnecessary fetches
    if (this._loadAttrsPromise) {
      return this._loadAttrsPromise;
    }

    this._loadAttrsPromise = BluebirdPromise.bind(this).then(function () {
      return this.attrCollectionWrapper.fetch();
    });

    return this._loadAttrsPromise;
  },

  addAttr: function (featureId, name, value, format, template_key) {
    this.attrCollectionWrapper.create(
      featureId,
      name,
      value,
      format,
      template_key,
    );
  },

  addAttrValue: function (featureId, value, attrId) {
    this.attrCollectionWrapper.addAttrValue(featureId, value, attrId);
  },

  /**
   * Gets the attrs of this layer
   *
   * @return object:
   *   key: featured model id
   *   value: array of objects containing attr names, values and models for each
   */
  attrs: function () {
    return this.attrCollectionWrapper.attrs();
  },

  /**
   * Checks to see if attr already exists in this layer
   *
   * @param name {string} - The name of the attr
   */
  isExistingAttrName: function (nameToCheck) {
    var index = _.indexOf(
      this.attrCollectionWrapper.attrNames({ lowercase: true }),
      nameToCheck.toLowerCase(),
    );

    return index >= 0 ? true : false;
  },

  style: function () {
    var styleCollections =
      require('./styles/styleCollectionsWrapper').singleton();
    return styleCollections.getStyle(this.getType(), this.id);
  },

  isEmpty: function () {
    return this.features().length == 0;
  },

  /**
   * Gets all the feature models associated with this layer
   *
   * @return array - all the feature models associated with this layer
   */
  features: function () {
    return featureCollection.where({ layer_id: this.id });
  },

  /**
   * Gets all the feature model ids associated with this layer
   *
   * @return array - all the feature model ids associated with this layer
   */
  featureIds: function () {
    return this.features().map(function (feature) {
      return feature.id;
    });
  },

  /**
   * Gets all the feature model name associated with this layer
   *
   * Parameters:
   *     options: object
   *       lowerCase: boolean
   *          downcase all names if set to true
   *
   * @return array - all the feature model names associated with this layer
   */
  featureNames: function (options = {}) {
    return this.features()
      .filter((feature) => feature.shouldShowInSidebar())
      .map((feature) =>
        options.lowerCase
          ? feature.get('name').toLowerCase()
          : feature.get('name'),
      );
  },

  layerBuildingNames: function () {
    const buildingNameSet = this.features()
      .map((feature) => feature.get('building_name'))
      .filter((building_name) => building_name)
      .reduce((set, building_name) => {
        set.add(building_name);
        return set;
      }, new Set());
    return convertSetToArray(buildingNameSet);
  },

  featureCount: function () {
    return this.features().filter((f) => f.shouldShowInSidebar()).length;
  },

  /**
   * @param: mapModel [optional] {Backbone.Model instance}
   */
  onFeatureCount: function (mapModel) {
    return this.onFeatures(mapModel).length;
  },

  /**
   * Parameters:
   *     mapModel: Backbone.Model instance (optional)
   *        Use currently selected map if no map model passed in
   */
  onFeatures: function (mapModel) {
    var projectMapsService = require('../project/services/projectMapsService');
    mapModel = mapModel || projectMapsService.getSelectedMap();
    var onFeatureIds = _.intersection(
      this.featureIds(),
      mapModel.onFeatureIds(),
    );

    return _.map(onFeatureIds, function (id) {
      return featureCollection.get(id);
    });
  },

  /**
   * Toggle the value of the expanded attribute.
   */
  toggleExpand: function () {
    this.set({ expanded: !this.get('expanded') });
  },

  /**
   * Update the layer name
   *
   * Parameters:
   *     newName: string
   *        the new name
   */
  updateName: function (newName) {
    var oldName = this.get('name');

    if (newName !== oldName) {
      this.save({ name: newName });
    }
  },

  /**
   * Validates a feature name for this layer
   *
   * @param featureName {string}
   * @returns {array} - errors
   */
  validateFeatureName: function (featureName) {
    var errors = [];

    if (!(featureName && featureName.trim && featureName.trim())) {
      errors.push("Feature Label value can't be blank.");
    }

    if (this._containsFeatureWithName(featureName)) {
      errors.push(
        escape(featureName) +
          ' already exists as a feature label in this layer.',
      );
    }

    if (featureName && featureName.length > 140) {
      errors.push('Feature Label must be less or equal than 140 characters.');
    }

    return errors;
  },

  updateAttrForAllFeatures: function (oldName, newName) {
    _.each(this.features(), function (feature) {
      if (feature.get(oldName)) {
        feature.updateAttr(oldName, newName, feature.get(oldName));
      }
    });
  },

  calculateTotalAreaInSquareMeters: function () {
    if (!this.containsPolygons()) {
      return 0;
    }
    return _.reduce(
      this.features(),
      function (memo, feature) {
        return memo + feature.calculateAreaInSquareMeters();
      },
      0,
    );
  },

  isSiteBoundaryLayer: function () {
    return this.get('name').toLowerCase() === 'site boundary';
  },

  _containsFeatureWithName: function (featureName) {
    return _.contains(
      this.featureNames({ lowerCase: true }),
      featureName.toLowerCase(),
    );
  },

  needsFileInformation() {
    return (
      this.get('type') === 'aerialimagery' &&
      !this.get('mapbox_tileset_id') &&
      !this.get('mapbox_upload_error')
    );
  },

  isProcessingAerialImagery() {
    return (
      this.get('type') === 'aerialimagery' &&
      this.get('mapbox_tileset_id') &&
      !this.get('mapbox_upload_error') &&
      !this.get('mapbox_finished_processing_at')
    );
  },

  hasFailedUpload() {
    return (
      this.get('type') === 'aerialimagery' &&
      this.get('mapbox_upload_error') &&
      !this.get('mapbox_finished_processing_at')
    );
  },

  fileUploadedSuccessfully() {
    return (
      this.get('type') === 'aerialimagery' &&
      this.get('mapbox_tileset_id') &&
      !!this.get('mapbox_finished_processing_at')
    );
  },

  isDischargeLocationLayer() {
    const tags = this.getFormTags();
    return (tags || []).some((t) => waterTags.includes(t));
  },
});

module.exports = LayerModel;
