var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (modalTitle) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = modalTitle) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cform class=\"container-fluid\"\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "modalTitle" in locals_for_with ?
        locals_for_with.modalTitle :
        typeof modalTitle !== 'undefined' ? modalTitle : undefined));
    ;;return pug_html;};
module.exports = template;