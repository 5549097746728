import {
  ValidDateString,
  ValidNumberString,
  ValidTimeString,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { WastewaterUnits } from 'mapistry-shared';

export const NOT_DETECTED = 'ND';

export enum uploadFieldKeys {
  analysisDate = 'analysisDate',
  casNumber = 'casNumber',
  detect = 'detect',
  detectionLimit = 'detectionLimit',
  evaluationLimit = 'evaluationLimit',
  labName = 'labName',
  labReportNumber = 'labReportNumber',
  locationId = 'locationId',
  matrix = 'matrix',
  methodSlug = 'methodSlug',
  methodText = 'methodText',
  parameterSlug = 'parameterSlug',
  parameterValue = 'parameterValue',
  qualifiers = 'qualifiers',
  reportingLimit = 'reportingLimit',
  sampleDate = 'eventDate',
  sampleDescription = 'sampleDescription',
  sampleTime = 'eventTime',
  units = 'units',
}

export type WastewaterSamplingResultUpload = {
  [uploadFieldKeys.analysisDate]?: ValidDateString;
  [uploadFieldKeys.casNumber]?: string;
  [uploadFieldKeys.detect]?: boolean;
  [uploadFieldKeys.detectionLimit]?: ValidNumberString;
  [uploadFieldKeys.evaluationLimit]?: string;
  [uploadFieldKeys.labName]?: string;
  [uploadFieldKeys.labReportNumber]?: string;
  [uploadFieldKeys.locationId]: string;
  [uploadFieldKeys.matrix]?: string;
  [uploadFieldKeys.methodSlug]?: string;
  [uploadFieldKeys.methodText]?: string;
  [uploadFieldKeys.parameterSlug]: string;
  [uploadFieldKeys.parameterValue]: string | number;
  [uploadFieldKeys.qualifiers]?: string;
  [uploadFieldKeys.reportingLimit]?: ValidNumberString;
  [uploadFieldKeys.sampleDate]: ValidDateString;
  [uploadFieldKeys.sampleDescription]?: string;
  [uploadFieldKeys.sampleTime]: ValidTimeString;
  [uploadFieldKeys.units]?: WastewaterUnits;
};
