/* eslint-disable */
var apiCaller = require('../../../../apiCaller');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/basicInfo.pug'),

  className: 'panel panel-default',

  ui: {
    $form: 'form',
  },
});
