/* eslint-disable */
// A spinner would be nice
import { KeyService } from '@monorepo/shared/KeyService';

let router = require('../../../../../router');

let ProjectHeaderItemView = Backbone.Marionette.ItemView.extend({
  tagName: 'header',

  className: 'flex align-items-center',

  template: require('./templates/projectHeader.pug'),

  ui: {
    $projectName: '.project-name',
    $editProjectNameInput: '.edit-project-name-input',
  },

  events: {
    'keyup @ui.$editProjectNameInput': '_handleKeyUpOnProjectNameInput',
    'click .edit-project-name-icon': '_editProjectName',
    'blur @ui.$editProjectNameInput': '_saveProjectName',
  },

  behaviors: {
    Tooltip: {},
  },

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
  },

  templateHelpers() {
    return { hasProjectUpdatePermission: this.hasProjectUpdatePermission };
  },

  _editProjectName() {
    this.ui.$editProjectNameInput.show();
    this.ui.$editProjectNameInput.focus();
    this.ui.$projectName.hide();
  },

  _saveProjectName() {
    this.model.save({ name: this.ui.$editProjectNameInput.val() });
    this.render();
  },

  _handleKeyUpOnProjectNameInput(e) {
    if (KeyService.isEnterKey(e.keyCode)) {
      this._saveProjectName();
    }
  },
});

module.exports = ProjectHeaderItemView;
