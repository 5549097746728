import React from 'react';

import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';

const DragIcon = () => (
  <div className="table-row__grab-target">
    <div className="flex items-center">
      <SvgIcon identifier="grab" />
    </div>
  </div>
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DragIcon;
