var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getWarningClass, hasError, hasWarning, label, number_of_samples) {
      pug_html = pug_html + "\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (!hasWarning && !hasError && number_of_samples > 0) {
pug_html = pug_html + "\u003Csvg class=\"check\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (hasWarning || hasError) {
pug_html = pug_html + "\u003Csvg" + (pug.attr("class", pug.classes(["exclamation",getWarningClass()], [false,true]), false, true)) + "\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"sampling-result-summary-name-container\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "getWarningClass" in locals_for_with ?
        locals_for_with.getWarningClass :
        typeof getWarningClass !== 'undefined' ? getWarningClass : undefined, "hasError" in locals_for_with ?
        locals_for_with.hasError :
        typeof hasError !== 'undefined' ? hasError : undefined, "hasWarning" in locals_for_with ?
        locals_for_with.hasWarning :
        typeof hasWarning !== 'undefined' ? hasWarning : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "number_of_samples" in locals_for_with ?
        locals_for_with.number_of_samples :
        typeof number_of_samples !== 'undefined' ? number_of_samples : undefined));
    ;;return pug_html;};
module.exports = template;