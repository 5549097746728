import { CustomLogType } from '@monorepo/shared/apiClient/types';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { GenericLogType } from 'mapistry-shared';
import { EmptyTabContent, TABLE_SETTINGS } from '../shared';
import EmissionTrackingTable from '../shared/EmissionTrackingTable';
// ComponentPicker imports both the table and the row so we get a cycle
// If this becomes a problem we can split it into multiple "pickers" (ajb)
// eslint-disable-next-line import/no-cycle
import { ComponentPicker } from '../../ComponentPicker';
import { TextField } from '../../../elements';
import {
  useCustomLogTemplates,
  useLocateTemplateSettingsSection,
} from '../../../../hooks/genericLogs/useCustomLogTemplates';

const LimitItemsTable = (props) => {
  const {
    addLimitGroup,
    addLimitItem,
    calculatedValues,
    deleteLimitGroup,
    deleteLimitItem,
    emissionFactors,
    formErrors,
    formSubmissionDraft,
    handleDeleteGroup,
    limitItems,
    loggedItems,
    logProjectId,
    onDragEnd,
    rollingCalculations,
    isLoading,
    itemGroups,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const logTemplate = useCustomLogTemplates();
  const { rowType } = useLocateTemplateSettingsSection(logTemplate);
  const isWaterLog =
    logTemplate.type === CustomLogType.stormwater ||
    logTemplate.type === CustomLogType.water;

  const renderRow = (limitItem, index, groupId, measureRowHeight) => (
    <ComponentPicker
      type={rowType}
      addLimitGroup={addLimitGroup}
      calculatedValues={calculatedValues}
      deleteLimitGroup={deleteLimitGroup}
      deleteLimitItem={deleteLimitItem}
      emissionFactors={emissionFactors}
      formErrors={formErrors}
      groupId={groupId}
      itemGroups={itemGroups}
      limitItem={limitItem}
      limitItemIdx={index}
      loggedItems={loggedItems}
      logProjectId={logProjectId}
      measureRowHeight={measureRowHeight}
      rollingCalculations={rollingCalculations}
      selectMenuPortalRef={selectMenuPortalRef}
      stageEdit={stageEdit}
    />
  );

  const renderRowWhileBeingDragged = (limitItem) => (
    <TextField
      disabled
      controlled={false}
      defaultValue={limitItem.userDefinedId || '...dragging'}
    />
  );

  const getRowCountForGroup = useCallback(
    (groupOfItems) =>
      groupOfItems.reduce((sum, item) => sum + item.limits.length, 0),
    [],
  );

  const canCreateLimitItems =
    calculatedValues.length ||
    emissionFactors.length ||
    loggedItems.length ||
    rollingCalculations.length;

  const hasLimitItems =
    limitItems.length ||
    Object.keys(formSubmissionDraft.groups).some(
      (groupKey) => _get(formSubmissionDraft.groups, groupKey).length,
    );

  if (!canCreateLimitItems && !isLoading) {
    return (
      <div className="emissions-tracking__empty-tab">
        <div className="emissions-tracking__empty-tab-content">
          You currently have no items defined to set a limit for. To add limits,
          first create numeric logged items, factors, calculated values, rolling
          calculations or unit conversions in one of the other tabs.
        </div>
      </div>
    );
  }

  if (!hasLimitItems && !isLoading) {
    return <EmptyTabContent title="Limit Items" addItem={addLimitItem} />;
  }

  return (
    <EmissionTrackingTable
      addItem={addLimitItem}
      formErrors={formErrors}
      formSubmissionDraft={formSubmissionDraft}
      getRowCountForGroup={getRowCountForGroup}
      groupType={GenericLogType.LIMIT_ITEM}
      handleDeleteGroup={handleDeleteGroup}
      hasFixedRowHeight={false}
      itemGroups={itemGroups}
      items={limitItems}
      isLoading={isLoading}
      logProjectId={logProjectId}
      onDragEnd={onDragEnd}
      renderRow={renderRow}
      renderRowWhileBeingDragged={renderRowWhileBeingDragged}
      tableInfo={
        isWaterLog
          ? TABLE_SETTINGS.WATER_LOG_LIMIT_ITEMS
          : TABLE_SETTINGS.LIMIT_ITEMS
      }
    />
  );
};

LimitItemsTable.propTypes = {
  addLimitGroup: PropTypes.func.isRequired,
  addLimitItem: PropTypes.func.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteLimitGroup: PropTypes.func.isRequired,
  deleteLimitItem: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  limitItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LimitItemsTable;
