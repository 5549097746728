/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/documentRow.pug'),

  tagName: 'li',

  className: 'list-styled padding-lr-20',

  ui: {
    delete: '.delete-document',
  },

  events: {
    'click .download-document': '_handleClickDownloadDocument',
  },

  behaviors: {
    Tooltip: { gravity: 'e' },
    WarnBeforeDelete: {},
  },

  getWarningBeforeDeleteText() {
    return this.model.get('name') + '?';
  },

  _handleClickDownloadDocument() {
    window.open(this.model.get('src'));
  },
});
