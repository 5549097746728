interface AdminOnboardingSessionParams {
  organizationId: string;
}

export function createAdminOnboardingSessionKey(
  params: AdminOnboardingSessionParams,
) {
  const { organizationId } = params;
  return ['onboarding_sessions', organizationId] as const;
}
