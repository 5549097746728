import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import {
  formattedCalendarDateFromEvent,
  getFormattedCalendarDate,
} from '../../../../utils/calendarEvents';

import RecordPanelIconStatus from '../RecordPanelIconStatus';
import RecordPanelListItemExpandable from '../RecordPanelListItemExpandable';
import {
  ConfirmDialog,
  LoadingIndicator,
  MapistryTooltip,
} from '../../../elements';

class RecordPanelCalendarChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmationIsVisible: false,
      hasFetchedAttachments: false,
      isExpanded: false,
      isLoading: false,
    };
    this.onFetchAttachment = this.onFetchAttachment.bind(this);
  }

  onFetchAttachment() {
    const { isExpanded } = this.state;
    const {
      onFetchAttachment,
      event: { start, end },
    } = this.props;
    if (isExpanded) {
      // Only fetch data if the accordion is currently closed
      return this.setState({ isExpanded: false });
    }
    onFetchAttachment(start, end);
    return this.setState(
      {
        isLoading: true,
      },
      () => {
        setTimeout(
          () =>
            this.setState({
              hasFetchedAttachments: true,
              isLoading: false,
              isExpanded: true,
            }),
          500,
        );
      },
    );
  }

  render() {
    const {
      attachments,
      onEditAttachment,
      onDeleteAttachment,
      locationId,
      event,
    } = this.props;

    const {
      deleteConfirmationIsVisible,
      hasFetchedAttachments,
      isExpanded,
      isLoading,
    } = this.state;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/no-unstable-nested-components
    const StatusUI = () => {
      const { status, startDate, dueDate, warningDate } = event;

      let iconStatus;
      let tooltipText;
      const hasAttachments = attachments.length;
      const nowDate = new Date();

      if (status === 'complete') {
        iconStatus = 'complete';
        tooltipText = 'Complete';
      } else if (new Date(dueDate) < nowDate && hasAttachments) {
        iconStatus = 'overdue';
        tooltipText = 'Overdue';
      } else if (
        new Date(startDate) > nowDate &&
        new Date(warningDate) < nowDate &&
        hasAttachments
      ) {
        iconStatus = 'upcoming';
        tooltipText = 'Upcoming';
      } else if (hasFetchedAttachments && !hasAttachments) {
        iconStatus = 'overdue';
        tooltipText = 'No records for this period.';
      }

      return iconStatus ? (
        <RecordPanelIconStatus status={iconStatus} tooltipText={tooltipText} />
      ) : null;
    };

    const editAttachmentUI = (attachment) => (
      <MapistryTooltip title="Update this record" placement="top">
        <button
          className="button-link"
          onClick={() => onEditAttachment(event, attachment)}
          type="button"
        >
          <SvgIcon className="pointer grow" identifier="edit" />
        </button>
      </MapistryTooltip>
    );

    const deleteAttachmentUI = (attachment) => (
      <>
        <MapistryTooltip title="Delete this record" placement="top">
          <button
            className="button-link"
            onClick={() => this.setState({ deleteConfirmationIsVisible: true })}
            type="button"
          >
            <SvgIcon className="red grow pointer" identifier="trash" />
          </button>
        </MapistryTooltip>
        <ConfirmDialog
          danger
          confirmButtonText="Delete"
          onCancelled={() =>
            this.setState({ deleteConfirmationIsVisible: false })
          }
          onConfirmed={() =>
            this.setState(
              {
                deleteConfirmationIsVisible: false,
                isExpanded: false,
              },
              () => {
                onDeleteAttachment(attachment);
              },
            )
          }
          open={deleteConfirmationIsVisible}
          title="Are you sure you want to delete this record?"
        />
      </>
    );

    return (
      <RecordPanelListItemExpandable
        key={`${locationId}.${event.start}.${event.end}`}
        isExpanded={isExpanded}
        onClick={this.onFetchAttachment}
        summary={
          <div className="w-100">
            <div className="flex items-center">
              <StatusUI />
              <span>{formattedCalendarDateFromEvent(event)}</span>
              <span className="pl3">
                {isLoading && <LoadingIndicator isAbsolute={false} size={16} />}
              </span>
            </div>
          </div>
        }
        details={
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {attachments.length > 0 &&
              _.map(attachments, (attachment, idx) => (
                <RecordPanelListItemExpandable
                  key={idx}
                  summary={
                    <div className="calendar-accordion-record-row gray-link green-on-hover">
                      <div className="flex items-center">
                        <StatusUI />
                        {getFormattedCalendarDate(
                          attachment.date || attachment.dueDate,
                        )}
                      </div>
                      <div className="attachment-actions">
                        {onEditAttachment && editAttachmentUI(attachment)}
                        {onDeleteAttachment && deleteAttachmentUI(attachment)}
                      </div>
                    </div>
                  }
                />
              ))}
          </>
        }
      />
    );
  }
}

RecordPanelCalendarChild.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape),
  event: PropTypes.shape({
    dueDate: PropTypes.string,
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    status: PropTypes.string,
    startDate: PropTypes.string,
    warningDate: PropTypes.string,
  }).isRequired,
  locationId: PropTypes.string,
  onDeleteAttachment: PropTypes.func,
  onEditAttachment: PropTypes.func,
  onFetchAttachment: PropTypes.func.isRequired,
  // parameterSlug is used only to get correct data from the Redux store in RecordPanelCalendarChild index
  // eslint-disable-next-line react/no-unused-prop-types
  parameterSlug: PropTypes.string.isRequired,
};

RecordPanelCalendarChild.defaultProps = {
  attachments: [],
  locationId: null,
  onEditAttachment: null,
  onDeleteAttachment: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default RecordPanelCalendarChild;
