import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line consistent-return
const EmissionTrackingListEmptyStates = (props) => {
  const {
    hasLoggedItems,
    hasSelectedColumns,
    hasAnyLogs,
    navigateToSettings,
    showEditLogModal,
  } = props;

  if (!hasLoggedItems) {
    return (
      <div className="emission-tracking-list__empty-tab">
        <div className="emission-tracking-list__empty-tab-content">
          <div>
            In order to view your data, you need to add the items you wish to
            log in the log settings screen.
          </div>
          <Button color="secondary" onClick={navigateToSettings}>
            Go to Log Settings
          </Button>
        </div>
      </div>
    );
  }
  if (!hasSelectedColumns) {
    return (
      <div className="emission-tracking-list__empty-tab">
        <div className="emission-tracking-list__empty-tab-content">
          <div>
            <span>
              In order to view your data, please select the column you would
              like to view using the
            </span>
            <span className="emission-tracking-list__empty-tab-content__icon">
              <SvgIcon identifier="filter" />
            </span>
            <span>icon in the top right of the table.</span>
          </div>
        </div>
      </div>
    );
  }

  if (!hasAnyLogs) {
    return (
      <div className="emission-tracking-list__empty-tab">
        <div className="emission-tracking-list__empty-tab-content">
          <div>
            In order to view your data, you need to add a log for any of your
            logged items.
          </div>
          <Button color="secondary" onClick={showEditLogModal}>
            Add a log
          </Button>
        </div>
      </div>
    );
  }
};

EmissionTrackingListEmptyStates.propTypes = {
  hasLoggedItems: PropTypes.bool.isRequired,
  hasSelectedColumns: PropTypes.bool.isRequired,
  hasAnyLogs: PropTypes.bool.isRequired,
  navigateToSettings: PropTypes.func.isRequired,
  showEditLogModal: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EmissionTrackingListEmptyStates;
