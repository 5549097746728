import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addFlowLogLimit,
  deleteFlowLogLimit,
  editFlowLogLimit,
} from '../../../../actions/wastewater';
import APP from '../../../../config';
import { goToMapsUrl } from '../../../../router';
import {
  delayedModalClose,
  isNullOrUndefined,
  isNumeric,
} from '../../../../utils';
import withProvider from '../../../withProvider';
import FlowLogSettingsModal from './FlowLogSettingsModal';

class FlowLogSettingsModalContainer extends Component {
  constructor(props) {
    super(props);
    this.getInitialState = () => ({
      formDraft: {
        limitGroups: {
          flowRate: [
            {
              key: uuidv4(),
              calculationType: null,
              frequency: null,
              limitType: 'flowRate',
              limitValue: null,
              units: null,
            },
          ],
          volume: [
            {
              key: uuidv4(),
              calculationType: null,
              frequency: null,
              limitType: 'volume',
              limitValue: null,
              units: null,
            },
          ],
        },
      },
      formErrors: {
        displayable: {
          flowRate: [],
          volume: [],
        },
        limitGroups: {
          flowRate: [],
          volume: [],
        },
      },
      limitGroupIdsToDelete: [],
      saveState: SaveState.CLEAN,
      showDirtyFormConfirmation: false,
    });
    this.state = this.getInitialState();
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate({ flowLogLimits: prevFlowLogLimits, open: prevOpen }) {
    const { flowLogLimits, open } = this.props;

    const { saveState } = this.state;
    APP.isNavigationBlocked = saveState === SaveState.DIRTY;

    const loadedSavedLimits =
      prevFlowLogLimits.length === 0 && flowLogLimits.length > 0;
    const limitsHaveChanged = prevFlowLogLimits.length !== flowLogLimits.length;

    if (loadedSavedLimits || (!prevOpen && open) || limitsHaveChanged) {
      const isLimitType = (limitTypeFilter) => (limit) =>
        limit.limitType === limitTypeFilter;
      const withKey = (limit) => ({ ...limit, key: limit.id });
      const defaultLimit = (limitType) => {
        if (flowLogLimits.filter(isLimitType(limitType)).length > 0) {
          return [];
        }
        return [
          {
            key: uuidv4(),
            calculationType: null,
            frequency: null,
            limitType,
            limitValue: null,
            units: null,
          },
        ];
      };
      const flowRateLimits = flowLogLimits
        .filter(isLimitType('flowRate'))
        .map(withKey)
        .concat(defaultLimit('flowRate'));
      const volumeLimits = flowLogLimits
        .filter(isLimitType('volume'))
        .map(withKey)
        .concat(defaultLimit('volume'));
      this.setState({
        ...this.getInitialState(),
        formDraft: {
          limitGroups: {
            flowRate: flowRateLimits,
            volume: volumeLimits,
          },
        },
      });
    }
  }

  addLimitGroup(limitType) {
    const { formDraft } = this.state;
    const { limitGroups } = formDraft;
    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: {
        limitGroups: {
          ...limitGroups,
          [limitType]: [
            ...limitGroups[limitType],
            {
              key: uuidv4(),
              calculationType: null,
              frequency: null,
              limitType,
              limitValue: null,
              units: null,
            },
          ],
        },
      },
    });
  }

  closeModal() {
    const { onClose } = this.props;
    this.setState(this.getInitialState(), onClose);
  }

  removeLimitGroup(idxToRemove, limitType) {
    const {
      formDraft: { limitGroups },
      formErrors: { displayable, limitGroups: limitGroupsErrors },
      limitGroupIdsToDelete,
    } = this.state;

    const limitGroupIdToRemove = limitGroups[limitType][idxToRemove].id;
    const newlimitGroupIdsToDelete = isNullOrUndefined(limitGroupIdToRemove)
      ? limitGroupIdsToDelete
      : limitGroupIdsToDelete.concat(limitGroupIdToRemove);

    const newLimitGroups = [...limitGroups[limitType]];
    newLimitGroups.splice(idxToRemove, 1);
    const newLimitGroupsErrors = [...limitGroupsErrors[limitType]];
    newLimitGroupsErrors.splice(idxToRemove, 1);

    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: {
        limitGroups: {
          ...limitGroups,
          [limitType]: newLimitGroups,
        },
      },
      formErrors: {
        displayable: {
          ...displayable,
          [limitType]: displayable[limitType].filter(
            ({ limitGroupIndex }) => limitGroupIndex !== idxToRemove,
          ),
        },
        limitGroups: {
          ...limitGroupsErrors,
          [limitType]: newLimitGroupsErrors,
        },
      },
      limitGroupIdsToDelete: newlimitGroupIdsToDelete,
    });
  }

  saveForm() {
    const { formDraft, limitGroupIdsToDelete } = this.state;
    const { addFlowLimit, deleteFlowLimit, editFlowLimit } = this.props;

    const saveStateCallback = () => {
      this.setState(
        {
          limitGroupIdsToDelete: [],
          saveState: SaveState.SAVED,
        },
        () => delayedModalClose(this.closeModal),
      );
    };
    const isEmpty = ({ calculationType, limitValue, units }) =>
      isNullOrUndefined(calculationType) &&
      isNullOrUndefined(units) &&
      (isNullOrUndefined(limitValue) || limitValue.trim() === '');
    const toApiFormat = ({ key, ...limitToSave }) => ({ ...limitToSave });
    const allLimitGroups = Object.keys(formDraft.limitGroups)
      .reduce(
        (accum, limitGroupKey) => [
          ...accum,
          ...formDraft.limitGroups[limitGroupKey],
        ],
        [],
      )
      .filter((limitGroup) => !isEmpty(limitGroup));
    const limitGroupsToAdd = allLimitGroups
      .filter((limitGroup) => isNullOrUndefined(limitGroup.id))
      .map(toApiFormat);
    const limitGroupsToEdit = allLimitGroups
      .filter((limitGroup) => !isNullOrUndefined(limitGroup.id))
      .map(toApiFormat);

    this.setState({ saveState: SaveState.SAVING }, () =>
      Promise.all([
        ...limitGroupsToAdd.map((limitGroup) => addFlowLimit(limitGroup)),
        ...limitGroupsToEdit.map(({ id, ...limitGroupData }) =>
          editFlowLimit(id, limitGroupData),
        ),
        ...limitGroupIdsToDelete.map((id) => deleteFlowLimit(id)),
      ]).then(saveStateCallback),
    );
  }

  submitForm() {
    const formErrors = this.validateForm();
    this.setState({ formErrors }, () => {
      const allErrors = Object.keys(formErrors.displayable).reduce(
        (accum, displayableKey) => [
          ...accum,
          ...formErrors.displayable[displayableKey],
        ],
        [],
      );
      if (allErrors.length === 0) {
        this.saveForm();
      }
    });
  }

  // nextFields: { [editField1]: nextValue1, [editField2]: nextValue2, ...}
  stageEdit(nextFields, idxToUpdate, limitType) {
    const {
      formDraft: { limitGroups },
      formErrors: { displayable, limitGroups: limitGroupsErrors },
    } = this.state;
    const updatedFields = Object.keys(nextFields);
    const nextLimitGroups = limitGroups[limitType].map((limitGroup, idx) =>
      idx !== idxToUpdate
        ? limitGroup
        : {
            ...limitGroup,
            ...nextFields,
          },
    );
    const nextDisplayableErrors = displayable[limitType].filter(
      ({ field, limitGroupIndex }) =>
        limitGroupIndex !== idxToUpdate || !updatedFields.includes(field),
    );
    const nextLimitGroupErrors = limitGroupsErrors[limitType].map(
      (limitGroupError, idx) => {
        if (idx !== idxToUpdate) {
          return limitGroupError;
        }
        const newLimitGroupError = updatedFields.reduce(
          (accum, updatedField) => {
            const { [updatedField]: _, ...rest } = limitGroupError;
            return rest;
          },
          {},
        );
        return newLimitGroupError;
      },
    );
    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: {
        limitGroups: {
          ...limitGroups,
          [limitType]: nextLimitGroups,
        },
      },
      formErrors: {
        displayable: {
          ...displayable,
          [limitType]: nextDisplayableErrors,
        },
        limitGroups: {
          ...limitGroupsErrors,
          [limitType]: nextLimitGroupErrors,
        },
      },
    });
  }

  validateForm() {
    const {
      formDraft: { limitGroups },
    } = this.state;
    const validations = [
      {
        id: 'limitValueNumeric',
        isInvalid: (limitGroup) =>
          !isNullOrUndefined(limitGroup.limitValue) &&
          !isNumeric(limitGroup.limitValue),
        field: 'limitValue',
        message: 'the limit must be a number.',
      },
      {
        id: 'calculationMethodRequired',
        isInvalid: (limitGroup) => isNullOrUndefined(limitGroup.frequency),
        field: 'frequency',
        message: 'calculation method is required.',
      },
      {
        id: 'limitRequired',
        isInvalid: (limitGroup) => isNullOrUndefined(limitGroup.limitValue),
        field: 'limitValue',
        message: 'limit is required.',
      },
      {
        id: 'unitsRequired',
        isInvalid: (limitGroup) => isNullOrUndefined(limitGroup.units),
        field: 'units',
        message: 'units are required.',
      },
    ];
    const isEmpty = ({ calculationType, limitValue, units }) =>
      isNullOrUndefined(calculationType) &&
      isNullOrUndefined(units) &&
      (isNullOrUndefined(limitValue) || limitValue.trim() === '');
    const allLimitGroups = Object.keys(limitGroups)
      .reduce(
        (accum, limitGroupKey) => [...accum, ...limitGroups[limitGroupKey]],
        [],
      )
      .filter((limitGroup) => !isEmpty(limitGroup));

    return allLimitGroups.reduce(
      (accum, limitGroup, idx) => {
        const { limitType } = limitGroup;
        const applicableErrors = validations.filter(({ isInvalid }) =>
          isInvalid(limitGroup),
        );
        const displayableErrors = applicableErrors.map(
          ({ field, id, message }) => ({
            field,
            id: `${limitType}-${id}-${idx}`,
            limitGroupIndex: idx,
            message: `${
              limitType === 'volume' ? 'Volume' : 'Flow rate'
            } limit ${idx + 1}: ${message}`,
          }),
        );
        const limitGroupFieldErrors = applicableErrors.reduce(
          (errorsAccum, { field, message }) => ({
            ...errorsAccum,
            [field]: message,
          }),
          {},
        );
        return {
          displayable: {
            ...accum.displayable,
            [limitType]: [
              ...accum.displayable[limitType],
              ...displayableErrors,
            ],
          },
          limitGroups: {
            ...accum.limitGroups,
            [limitType]: [
              ...accum.limitGroups[limitType],
              limitGroupFieldErrors,
            ],
          },
        };
      },
      {
        displayable: {
          flowRate: [],
          volume: [],
        },
        limitGroups: {
          flowRate: [],
          volume: [],
        },
      },
    );
  }

  render() {
    const { formDraft, formErrors, saveState, showDirtyFormConfirmation } =
      this.state;
    const { isFetching, monitoringLocations, open, project } = this.props;

    return (
      <FlowLogSettingsModal
        formDraft={formDraft}
        formErrors={formErrors}
        // TODO replace goToMapsURL when React router is implemented
        goToMapsURL={() => goToMapsUrl(project.organizationId, project.id)}
        isFetching={isFetching}
        monitoringLocations={monitoringLocations}
        onAddLimitGroup={(limitType) => this.addLimitGroup(limitType)}
        onConfirmCancel={() =>
          this.setState({ showDirtyFormConfirmation: false })
        }
        onConfirmClose={() =>
          this.setState({ showDirtyFormConfirmation: false }, this.closeModal)
        }
        onClose={() =>
          [SaveState.CLEAN, SaveState.SAVED].includes(saveState)
            ? this.closeModal()
            : this.setState({ showDirtyFormConfirmation: true })
        }
        onRemoveLimitGroup={(idxToRemove, limitType) =>
          this.removeLimitGroup(idxToRemove, limitType)
        }
        onSave={() => this.submitForm()}
        open={open}
        saveState={saveState}
        showDirtyFormConfirmation={showDirtyFormConfirmation}
        stageEdit={(...params) => this.stageEdit(...params)}
      />
    );
  }
}
FlowLogSettingsModalContainer.defaultProps = {
  isFetching: false,
  monitoringLocations: [],
};

FlowLogSettingsModalContainer.propTypes = {
  addFlowLimit: PropTypes.func.isRequired,
  deleteFlowLimit: PropTypes.func.isRequired,
  editFlowLimit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  flowLogLimits: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    organizationId: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: _get(state.wastewater, 'isFetching.flowLogLimits', false),
  flowLogLimits: state.wastewater.flowLogLimits,
  project: state.project,
  monitoringLocations: state.wastewater.monitoringLocations,
});

const mapDispatchToProps = (dispatch) => ({
  addFlowLimit: (limitGroup) =>
    dispatch(addFlowLogLimit(APP.projectId, limitGroup)),
  deleteFlowLimit: (id) => dispatch(deleteFlowLogLimit(APP.projectId, id)),
  editFlowLimit: (id, limitGroup) =>
    dispatch(editFlowLogLimit(APP.projectId, id, limitGroup)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(FlowLogSettingsModalContainer),
);
