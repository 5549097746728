/* eslint-disable */
import React, { useCallback } from 'react';
import NotesIcon from '@material-ui/icons/SpeakerNotes';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { useAlertDialog } from '@monorepo/shared/hooks/useAlertDialog';
import { useConfirmDialog } from '@monorepo/shared/hooks/useConfirmDialog';
import { Button, IconButton } from '@monorepo/old-web/js/components/elements';
import { OnboardingApi } from '../../apiClient';
import { OnboardingDocumentRequestTitleInput as TitleInput } from '../OnboardingDocumentRequestTitleInput';
import { OnboardingDocumentStatusDisplay as StatusDisplay } from '../OnboardingDocumentStatusDisplay';
import { OnboardingDocumentUploadInput as DocumentUploadInput } from '../OnboardingDocumentUploadInput';

interface OnboardingDocumentsTableDocumentRowProps {
  documentRequest: OnboardingApi.OnboardingDocumentRequest;
  editable?: boolean;
  notesCell?: React.ReactElement<any>;
  onClearDocument: (documentRequestId: string) => void;
  onUpdateDocumentRequestTitle: (
    documentRequestId: string,
    title: string,
  ) => void;
  onRemoveDocumentRequest: (documentRequestId: string) => void;
  onUploadDocument: (documentRequestId: string, file: File) => void;
  statusCell?: React.ReactElement<any>;
}

export function OnboardingDocumentsTableDocumentRow(
  props: OnboardingDocumentsTableDocumentRowProps,
) {
  const {
    documentRequest,
    onUpdateDocumentRequestTitle,
    onRemoveDocumentRequest,
    onUploadDocument,
    onClearDocument,
  } = props;

  const confirm = useConfirmDialog();
  const alert = useAlertDialog();

  const handleUpdateTitle = useCallback(
    (title: string) => {
      onUpdateDocumentRequestTitle(documentRequest.id, title);
    },
    [onUpdateDocumentRequestTitle, documentRequest],
  );

  const handleRemove = useCallback(async () => {
    const isConfirmed = await confirm({
      title: 'Remove subsection',
      description: 'All information will be lost.',
      confirmButtonText: 'Remove',
      danger: true,
    });
    if (isConfirmed) onRemoveDocumentRequest(documentRequest.id);
  }, [onRemoveDocumentRequest, documentRequest, confirm]);

  const handleViewNotes = useCallback(() => {
    alert({
      title: 'Notes:',
      description: documentRequest.notes,
    });
  }, [documentRequest, alert]);

  const editable = props.editable ?? documentRequest.customerEditable;

  const statusCell =
    props.statusCell !== undefined ? (
      props.statusCell
    ) : (
      <td>
        <StatusDisplay status={documentRequest.status} />
      </td>
    );
  const notesCell =
    props.notesCell !== undefined ? (
      props.notesCell
    ) : (
      <td>
        <Button
          type="button"
          variant="text"
          color="secondary"
          startIcon={<NotesIcon />}
          onClick={handleViewNotes}
        >
          View notes
        </Button>
      </td>
    );

  return (
    <tr key={documentRequest.id} className="document-row">
      <td>
        {editable ? (
          <TitleInput
            onFinishEditing={handleUpdateTitle}
            title={documentRequest.title}
          />
        ) : (
          documentRequest.title
        )}
      </td>
      <td>
        <DocumentUploadInput
          documentRequest={documentRequest}
          onUpload={onUploadDocument}
          onClear={onClearDocument}
        />
      </td>
      {statusCell}
      {notesCell}
      {editable ? (
        <td>
          <IconButton
            type="button"
            aria-label="Remove subsection"
            onClick={handleRemove}
          >
            <RemoveIcon color="error" />
          </IconButton>
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
}
