var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (progress) {
      pug_html = pug_html + "\u003Cdiv class=\"progress display-none col-md-9\"\u003E\u003Cdiv" + (" class=\"progress-bar progress-bar-striped active\""+" role=\"progressbar\""+pug.attr("aria-valuenow", progress, true, true)+" aria-valuemax=\"1\""+pug.attr("style", pug.style(`width: ${progress}%`), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = progress) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "progress" in locals_for_with ?
        locals_for_with.progress :
        typeof progress !== 'undefined' ? progress : undefined));
    ;;return pug_html;};
module.exports = template;