var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isMarkerSizeSelected) {
      pug_html = pug_html + "\u003Cdiv class=\"form-group inline-row margin-right\"\u003E\u003Cdiv class=\"subheader-container\"\u003E\u003Ch4\u003EFeature Size\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cselect class=\"size form-control\" name=\"size\"\u003E\u003Coption" + (pug.attr("selected", isMarkerSizeSelected('small'), true, true)) + "\u003Esmall\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isMarkerSizeSelected('medium'), true, true)) + "\u003Emedium\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isMarkerSizeSelected('large'), true, true)) + "\u003Elarge\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isMarkerSizeSelected('xlarge'), true, true)) + "\u003Exlarge\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isMarkerSizeSelected('xxlarge'), true, true)) + "\u003Exxlarge\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isMarkerSizeSelected" in locals_for_with ?
        locals_for_with.isMarkerSizeSelected :
        typeof isMarkerSizeSelected !== 'undefined' ? isMarkerSizeSelected : undefined));
    ;;return pug_html;};
module.exports = template;