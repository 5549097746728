import { TextField } from '@monorepo/old-web/js/components/elements';
import React, { useCallback } from 'react';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
import { ParameterLimitType, ValidationError } from './types';

type ParameterLimitInputProps = {
  errors?: ValidationError[];
  limitType: ParameterLimitType;
  limitLower?: number | string;
  limitUpper?: number | string;
  locationName: string;
  monitoringLocationId: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void;
  onError: (errors: ValidationError[] | undefined) => void;
};

export function ParameterLimitInput(props: ParameterLimitInputProps) {
  const {
    errors,
    limitType,
    limitLower,
    limitUpper,
    locationName,
    monitoringLocationId,
    onChange,
    onError,
  } = props;
  const ownErrors = (errors || []).filter(
    (err) => err.monitoringLocationId === monitoringLocationId,
  );
  const hasError = !!ownErrors.length;

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>, key: string) => {
      // Write to a variable, because React will release e.target unpredictably
      const limit = e.target.value;
      let validationError: string | undefined;
      if (_.isFinite(limit)) {
        if (
          key === 'limit_lower' &&
          _.isFinite(limitUpper) &&
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          Number.parseFloat(limit) > Number.parseFloat(limitUpper)
        ) {
          validationError =
            'Lower limit must be less than or equal to upper limit.';
        }
        if (
          key === 'limit_upper' &&
          _.isFinite(limitLower) &&
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          Number.parseFloat(limit) < Number.parseFloat(limitLower)
        ) {
          validationError =
            'Upper limit must be greater than or equal to lower limit.';
        }
      } else if (limit) {
        validationError = 'Limit value must be a number.';
      }

      if (!validationError) {
        onError(
          errors &&
            errors.filter(
              (err) => err.monitoringLocationId !== monitoringLocationId,
            ),
        );
        onChange(key, limit);
      } else {
        const nextErrors = _.uniq(
          [
            ...(errors || []),
            {
              name: locationName,
              text: validationError,
              monitoringLocationId,
            },
          ],
          true,
          'text',
        );
        onError(nextErrors);
      }
    },
    [
      errors,
      limitLower,
      limitUpper,
      locationName,
      monitoringLocationId,
      onChange,
      onError,
    ],
  );

  if (limitType === 'range') {
    return (
      <div className="section--parameter-row--item">
        <div className="w-100 flex">
          <div className="w-50 pr2">
            <TextField
              controlled={false}
              error={hasError}
              defaultValue={limitLower}
              onBlur={(e) => handleBlur(e, 'limit_lower')}
              placeholder="Lower limit"
            />
          </div>
          <div className="w-50 pl2">
            <TextField
              controlled={false}
              error={hasError}
              defaultValue={limitUpper}
              onBlur={(e) => handleBlur(e, 'limit_upper')}
              placeholder="Upper limit"
            />
          </div>
        </div>
      </div>
    );
  }

  const isMax = limitType === 'maximum';
  return (
    <div className="section--parameter-row--item">
      <div className="w-100">
        <TextField
          controlled={false}
          error={hasError}
          defaultValue={isMax ? limitUpper : limitLower}
          onBlur={(e) => handleBlur(e, isMax ? 'limit_upper' : 'limit_lower')}
          placeholder="Upper limit"
        />
      </div>
    </div>
  );
}
