import { CalendarEventType } from '@monorepo/old-web/js/components/propTypes';
import { Api } from '@monorepo/shared/apiClient';
import { FetchWastewaterCalendarEventsProps } from '@monorepo/shared/apiClient/water';
import { DateLike } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';

const WASTEWATER_SAMPLING_EVENTS_QUERY_KEY = 'wastewaterSamplingEvents';

export const matchSamplingEventsKey = (
  projectId: string,
): readonly string[] => [WASTEWATER_SAMPLING_EVENTS_QUERY_KEY, projectId];

export const createSamplingEventsKey = ({
  projectId,
  parameterSlug,
  date,
}: FetchWastewaterCalendarEventsProps) =>
  [
    WASTEWATER_SAMPLING_EVENTS_QUERY_KEY,
    projectId,
    parameterSlug,
    date,
  ] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createSamplingEventsKey,
  typeof Api.fetchWastewaterCalendarEvents
>;

const fetcher: Fetcher = (_, projectId, parameterSlug, date) =>
  Api.fetchWastewaterCalendarEvents({ projectId, parameterSlug, date });

type UseWastewaterSamplingEventsProps = {
  config?: QueryConfig<typeof CalendarEventType, Api.ErrorResponse>;
  projectId: string;
  parameterSlug: string;
  date?: DateLike;
};

export const useWastewaterSamplingEvents = ({
  config: inputConfig,
  projectId,
  parameterSlug,
  date,
}: UseWastewaterSamplingEventsProps) => {
  const isEnabled = !!date;

  const key = isEnabled
    ? createSamplingEventsKey({ projectId, parameterSlug, date })
    : undefined;

  const config = { enabled: isEnabled, ...inputConfig };
  const { data, ...rest } = useQuery(key, fetcher, config);

  return {
    wastewaterSamplingCalendarEvents: data,
    ...rest,
  };
};
