import { resolve } from 'bluebird';
import { apiUrl } from '../config';

const dataProvider = {
  fetchCurrentUserPermissions() {
    // $ is globally defined
    // eslint-disable-next-line no-undef
    return resolve($.get(`${apiUrl}/userPermissions`));
  },
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default dataProvider;
