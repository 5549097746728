/* eslint-disable */
var _ = require('underscore');
var GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');

var PennsylvaniaSamplingEventParameterView =
  GenericSamplingEventParameterView.extend({
    regions: {
      locations: '.locations-container',
      benchmarkLimitRow: '.benchmark-limit-row-container',
    },

    _afterOnShow: function () {
      if (this.model.get('benchmark_limit')) this._showBenchmarkLimitRow();
    },

    _showBenchmarkLimitRow: function () {
      let BenchmarkLimitRowView = require('./BenchmarkLimitRowView');
      let benchmarkLimitRowView = new BenchmarkLimitRowView({
        model: this.model,
        sectorData: this.sectorData,
      });
      this.benchmarkLimitRow.show(benchmarkLimitRowView);
    },

    // I couldn't find a way around this. Normal subclassing rules do not appear to apply here.
    _isRequiredSamplingParameter(parameterSlug) {
      return GenericSamplingEventParameterView.prototype._isRequiredSamplingParameter.apply(
        this,
        [parameterSlug],
      );
    },

    _getUpdatedModelAttributes(parameter) {
      let current_value = parameter.current_value;
      let locations = parameter.locations;
      return { current_value, locations };
    },
  });

module.exports = PennsylvaniaSamplingEventParameterView;
