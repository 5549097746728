var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (contactLabel, contactName, contactValue) {
      pug_html = pug_html + "\u003Clabel class=\"col-xs-3 control-label\"\u003E" + (pug.escape(null == (pug_interp = contactLabel) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Cdiv class=\"col-xs-9\"\u003E\u003Cinput" + (" class=\"form-control\""+" type=\"text\""+pug.attr("value", contactValue, true, true)+pug.attr("name", contactName, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "contactLabel" in locals_for_with ?
        locals_for_with.contactLabel :
        typeof contactLabel !== 'undefined' ? contactLabel : undefined, "contactName" in locals_for_with ?
        locals_for_with.contactName :
        typeof contactName !== 'undefined' ? contactName : undefined, "contactValue" in locals_for_with ?
        locals_for_with.contactValue :
        typeof contactValue !== 'undefined' ? contactValue : undefined));
    ;;return pug_html;};
module.exports = template;