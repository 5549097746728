import React from 'react';
import MapistryTooltip from '../../../elements/MapistryTooltip';

function MarkerEmpty() {
  return (
    <MapistryTooltip placement="top" title="This section is incomplete">
      <span className="section-marker section-incomplete-marker" />
    </MapistryTooltip>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MarkerEmpty;
