/* eslint-disable */
var APP = require('../../config');
var StyleModel = require('./styleModel');

module.exports = Backbone.Collection.extend({
  model: StyleModel,

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  url: function () {
    var mainPageUrl = '/projects/' + APP.projectId + '/' + this.styleType;

    return APP.apiUrl + (APP.mapId ? publishPageUrl : mainPageUrl);
  },
});
