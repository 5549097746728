/* eslint-disable */
var DsnChoiceRow = Backbone.Marionette.ItemView.extend({
  className: 'checkbox',

  template: require('./templates/dsnChoiceRow.pug'),

  templateHelpers() {
    return {
      dsnNumber: this.model.get('dsn_slug').split(':')[1],
    };
  },
});

module.exports = DsnChoiceRow;
