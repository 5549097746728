/* eslint-disable */
var APP = require('../config');
var UploadFileView = require('./uploadFileView');

var UploadFeatureDataView = Backbone.View.extend({
  el: '#upload-data-modal',

  events: {
    'hidden.bs.modal': '_publishModelHidden',
  },

  initialize: function () {
    this._uploadFileView = new UploadFileView({ model: this.model });
    this.listenTo(this._uploadFileView, 'hideModal', this.hideModal);
  },

  hideModal: function () {
    this.$el.modal('hide');
  },

  showModal: function () {
    this._uploadFileView.show();
    this.$el.modal('show');
  },

  _publishModelHidden: function () {
    APP.vent.trigger('upload:modalHidden');
  },
});

module.exports = UploadFeatureDataView;
