/* eslint-disable */
var APP = require('../../../config');
var mapCollection =
  require('../../../models_collections/mapCollection').singleton();
var MapRowView = require('./mapRowView');

var MapListView = Backbone.Marionette.CollectionView.extend({
  className: 'sidebar-list',
  collection: mapCollection,
  childView: MapRowView,
  behaviors: {
    Scrollbar: {},
  },
});

module.exports = MapListView;
