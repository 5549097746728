/* eslint-disable */
var BenchmarkLimitRowView = Backbone.Marionette.ItemView.extend({
  tagName: 'table',

  className: 'table',

  template: require('./templates/dischargeLimitRow.pug'),

  modelEvents: {
    'change:locations': 'render',
  },

  initialize: function (options = {}) {
    this.sectorData = options.sectorData;
  },

  templateHelpers() {
    const parameterSlug = this.model.get('slug');
    const samplingUnits = this.sectorData.getSamplingUnits(parameterSlug);
    const samplingUnitsText = samplingUnits && samplingUnits.display_text;

    return {
      dischargeMaxLimitText: this._dischargeMaxLimitText(samplingUnitsText),
    };
  },

  _dischargeMaxLimitText(samplingUnitsText) {
    let max = this.model.get('daily_max');
    if (!max) return 'N/A';
    return `${max} ${samplingUnitsText}`;
  },
});

module.exports = BenchmarkLimitRowView;
