import { Api } from '@monorepo/shared/apiClient';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getDateColumnSetting,
  getNumericColumnSetting,
  getSingleSelectColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import {
  didValidationSucceed,
  validateDayField,
  validatePositiveNumber,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useMapFeatures } from '@monorepo/shared/hooks/useMapFeatures';
import { useProjectUsers } from '@monorepo/shared/hooks/useProjectUsers';
import {
  SimpleUnits,
  UTCEquivalentOfLocal,
  getRelatedUnits,
  waterTags,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import APP from '../../../../config';
import { parseFlowReading, parseRateOfDischarge } from './parsers';
import { FlowLogUpload, rateOfDischargeUnit } from './types';
import {
  uploadFieldKeys,
  validateFlowReadingOrRateOfDischarge,
  validateLogDoesNotExist,
  validateRateOfDischargeUnits,
} from './validators';

type FlowLogsUploadModalProps = {
  isOpen: boolean;
  onClose: React.ComponentProps<typeof InteractiveFileUploadModal>['onClose'];
};

const flowReadingUnitsOptions = getRelatedUnits(SimpleUnits.GALLONS);
const rateOfDischargeUnitsOptions = [
  {
    label: 'm³/min',
    value: rateOfDischargeUnit.cubicMetersPerMinute,
  },
  {
    label: 'm³/hr',
    value: rateOfDischargeUnit.cubicMetersPerHour,
  },
  {
    label: 'm³/day',
    value: rateOfDischargeUnit.cubicMetersPerDay,
  },
  {
    label: 'gal/min',
    value: rateOfDischargeUnit.gallonsPerMinute,
  },
  {
    label: 'gal/hr',
    value: rateOfDischargeUnit.gallonsPerHour,
  },
  {
    label: 'gal/day',
    value: rateOfDischargeUnit.gallonsPerDay,
  },
];

export const FlowLogsUploadModal = ({
  isOpen,
  onClose,
}: FlowLogsUploadModalProps) => {
  const { projectId } = APP;
  const { isLoading: usersIsLoading, users } = useProjectUsers({
    config: { enabled: isOpen },
    projectId,
    includeArchived: false, // don't allow archived users when uploading
  });
  const { isLoading: locationIsLoading, mapFeatures } = useMapFeatures({
    config: { enabled: isOpen },
    projectId,
    tags: waterTags,
  });
  const locationOptions = useMemo(
    () =>
      mapFeatures
        ?.filter((f) => !f.sampleType && !f.parentId) // QA locations are only for sampling results
        .map((f) => ({
          label: f.name,
          value: f.id,
        })) || [],
    [mapFeatures],
  );
  const userOptions = useMemo(
    () => users?.map((u) => ({ label: u.userName, value: u.userId })) || [],
    [users],
  );

  const workbook: WorkbookConfig = useMemo<WorkbookConfig>(() => {
    // declaring as a const before returning for better type checking
    const workbookConfig: WorkbookConfig = {
      name: 'Upload flow logs',
      sheets: [
        {
          name: 'Flow logs',
          slug: 'upload-flow-logs',

          fields: [
            getDateColumnSetting({
              label: 'Date of discharge',
              key: uploadFieldKeys.dateOfDischarge,
              isRequired: true,
            }),
            getSingleSelectColumnSetting(
              {
                label: 'Discharge location',
                key: uploadFieldKeys.monitoringLocationId,
                isRequired: true,
              },
              locationOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Person reporting discharge',
                key: uploadFieldKeys.personReportingDischarge,
                isRequired: true,
              },
              userOptions,
            ),
            getNumericColumnSetting({
              label: 'Volume',
              key: uploadFieldKeys.flowReading,
              description:
                'The total amount of water discharged. Required if "Rate of discharge" is not provided',
            }),
            getSingleSelectColumnSetting(
              {
                label: 'Volume units',
                key: uploadFieldKeys.flowReadingUnits,
                description:
                  'The units for the total amount of water discharged. If left blank gallons is assumed',
              },
              flowReadingUnitsOptions,
            ),
            getNumericColumnSetting({
              label: 'Hours of discharge',
              key: uploadFieldKeys.hoursOfDischarge,
              description:
                'The number of hours that the discharge occurred. If left blank 24 hours is assumed',
            }),
            getNumericColumnSetting({
              label: 'Rate of discharge',
              key: uploadFieldKeys.rateOfDischarge,
              description:
                'The rate that water was being discharged. Required if "Volume" is not provided',
            }),
            getSingleSelectColumnSetting(
              {
                label: 'Rate of discharge units',
                key: uploadFieldKeys.rateOfDischargeUnits,
                description:
                  'The units that the "Rate of discharged" was measured. Required if "Rate of discharge" is provided',
              },
              rateOfDischargeUnitsOptions,
            ),
          ],
        },
      ],
    };
    return workbookConfig;
  }, [locationOptions, userOptions]);

  const recordHookCallback: RecordHookCallback = useCallback(async (record) => {
    validatePositiveNumber(uploadFieldKeys.flowReading, record);
    validatePositiveNumber(uploadFieldKeys.hoursOfDischarge, record);
    validatePositiveNumber(uploadFieldKeys.rateOfDischarge, record);
    validateDayField(uploadFieldKeys.dateOfDischarge, record);

    validateFlowReadingOrRateOfDischarge(record);
    validateRateOfDischargeUnits(record);

    // only check if there isn't already a date error
    if (didValidationSucceed(uploadFieldKeys.dateOfDischarge, record)) {
      await validateLogDoesNotExist(record);
    }
  }, []);

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: FlowLogUpload[]) => {
      const flowLogs = results.map((result) => {
        const flowLog: Api.FlowLog = {
          dateOfDischarge: UTCEquivalentOfLocal(
            parseDateString(result.dateOfDischarge),
          ),
          flowReading: parseFlowReading(result) ?? 0,
          hoursOfDischarge: result.hoursOfDischarge
            ? Number(result.hoursOfDischarge)
            : undefined,
          monitoringLocationId: result.monitoringLocationId,
          personReportingDischarge: result.personReportingDischarge,
        };
        if (result.rateOfDischarge) {
          flowLog.flowReading = parseRateOfDischarge(result) ?? 0;
        }
        return flowLog;
      });

      await Api.bulkUploadFlowLogs({
        flowLogs,
        projectId,
      });

      return `
        Successfully uploaded flow logs!
        Now you can see your data in "Water: Flow Log" Records section.
      `;
    },
    [projectId],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ projectId }),
    [projectId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={locationIsLoading || usersIsLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      workbook={workbook}
      showTableForManualInput
    />
  );
};
