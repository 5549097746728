import React from 'react';
import Select from '../../../elements/Select';
import FormField from './FormField';

class MultiUserField extends FormField {
  constructor(props) {
    super(props);
    this.originalValue = this.getValue();
  }

  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);

    return (
      <Select
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        isMulti
        error={error}
      />
    );
  }

  formatOptions(templateField) {
    const { currentUser } = this.props;
    const options = templateField.options.map((option) => ({
      label: option.name,
      value: option.id,
      isArchived: option.is_archived,
      email: option.email,
    }));

    const parsedValue = this.originalValue?.length
      ? JSON.parse(this.originalValue)
      : [];
    const selectedIds = parsedValue.map((v) => v.user_id);
    // if user was selected, allow it no matter what
    // if current user is NOT mapistry user, filter out mapistry users
    // if archived user, filter it out
    return options.filter((option) => {
      if (selectedIds.includes(option.value)) {
        return true;
      }
      if (
        !currentUser.email.includes('@mapistry.com') &&
        option.email.includes('@mapistry.com')
      ) {
        return false;
      }
      return !option.isArchived;
    });
  }

  formatValue(formattedOptions) {
    const value = this.getValue();
    const parsedValue = value && value.length ? JSON.parse(value) : [];
    const userIds = parsedValue.map((v) => v.user_id);
    return formattedOptions.filter((o) => userIds.includes(o.value));
  }

  handleChange(selection) {
    const { templateField, onChange } = this.props;
    let newValue = '';
    if (selection && selection.length) {
      const selectedValues = selection.map((option) => ({
        user_id: option.value,
      }));
      newValue = JSON.stringify(selectedValues);
    }
    return onChange(templateField.slug, newValue);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiUserField;
