import { Api } from '@monorepo/shared/apiClient';
import { isMultiErrorResponse } from '@monorepo/shared/apiClient/httpClient';
import {
  invalidateTasksCache,
  TasksCacheEvents,
} from '@monorepo/shared/cacheRegisters/complianceCalendar/tasksCacheRegister';
import {
  getBooleanColumnSetting,
  getDateColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import {
  validateDateFieldsOrder,
  validateDayField,
  validateThatPrerequisiteFieldNotEmpty,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import { valueOrUndefinedIfEmpty } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useOrgProjectsWithUsers } from '@monorepo/shared/hooks/useOrgProjectsWithUsers';
import { useOrganizationUsers } from '@monorepo/shared/hooks/useOrgUsers';
import { customFrequencyOffset } from '@monorepo/shared/utils/frequencyUtils';
import { format } from 'date-fns';
import {
  CreateTaskRequest,
  CustomFrequency,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
  Permissions,
  ProjectWithUsersResponse,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { validateThatUserBelongsToProject } from './taskBulkUploadValidators';

enum uploadFieldKeys {
  assigneeId = 'assigneeId',
  deficiency = 'deficiency',
  description = 'description',
  dueDate = 'dueDate',
  endDate = 'endDate',
  frequency = 'frequency',
  isRecurring = 'isRecurring',
  notes = 'notes',
  projectId = 'projectId',
  title = 'title',
}

type TaskUploadRecord = {
  [uploadFieldKeys.assigneeId]: string;
  [uploadFieldKeys.deficiency]?: string;
  [uploadFieldKeys.description]: string;
  [uploadFieldKeys.dueDate]: string;
  [uploadFieldKeys.endDate]: string;
  [uploadFieldKeys.frequency]?: IntervalFrequencyEnum;
  [uploadFieldKeys.isRecurring]: boolean;
  [uploadFieldKeys.notes]?: string;
  [uploadFieldKeys.projectId]: string;
  [uploadFieldKeys.title]: string;
};

const allowedFrequencies = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.YEAR,
];

interface FrequencyOption {
  label: string;
  value: IntervalFrequencyEnum | ''; // '' for non-recurring fields, so you can reset the field to empty if accidentally filled out
}

const frequencyOptions: FrequencyOption[] = allowedFrequencies.map(
  (frequencyOption) => {
    const intervalFrequency = IntervalFrequencyFactory.For(frequencyOption);
    return {
      value: frequencyOption,
      label: intervalFrequency.toString(),
    };
  },
);
frequencyOptions.push({
  value: '',
  label: 'None',
});

type TasksUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onError: (saveError: Api.MultiErrorResponseData) => void;
  onSuccess: () => void;
  organizationId: string;
};

export function TaskUploadModal(props: TasksUploadModalProps) {
  const { isOpen, onClose, onError, onSuccess, organizationId } = props;

  const { projectsWithUsers, isLoading: isProjectsLoading } =
    useOrgProjectsWithUsers({
      organizationId,
      permission: Permissions.ASSIGN_TASKS_TO,
    });
  const { users, isLoading: isUsersLoading } = useOrganizationUsers({
    organizationId,
  });

  const userOptions = useMemo(() => {
    if (!users) return [];
    return users
      .map((u) => ({
        label: u.email,
        value: u.id,
      }))
      .sort((a, b) =>
        a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1,
      );
  }, [users]);

  const projectOptions = useMemo(() => {
    if (!projectsWithUsers) return [];
    return projectsWithUsers
      .map((u) => ({
        label: u.name,
        value: u.id,
      }))
      .sort((a, b) =>
        a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1,
      );
  }, [projectsWithUsers]);

  const userIdsByProjectId: Record<
    string,
    ProjectWithUsersResponse['userIds']
  > = useMemo(() => {
    if (!projectsWithUsers) return {};
    return projectsWithUsers.reduce(
      (grouped, project) => ({
        ...grouped,
        [project.id]: project.userIds,
      }),
      {},
    );
  }, [projectsWithUsers]);

  const workbook = useMemo<WorkbookConfig>(() => {
    // declaring as a const before returning for better type checking
    const workbookConfig: WorkbookConfig = {
      name: 'Upload tasks',
      sheets: [
        {
          name: 'Tasks',
          slug: 'upload-tasks',
          fields: [
            getSingleSelectColumnSetting(
              {
                label: 'Project Name',
                key: uploadFieldKeys.projectId,
                isRequired: true,
              },
              projectOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Assignee Email',
                key: uploadFieldKeys.assigneeId,
                isRequired: true,
              },
              userOptions,
            ),
            getTextColumnSetting({
              label: 'Task Title',
              key: uploadFieldKeys.title,
              isRequired: true,
            }),
            getTextColumnSetting({
              label: 'Task Description',
              key: uploadFieldKeys.description,
            }),
            getBooleanColumnSetting({
              label: 'Is Recurring?',
              key: uploadFieldKeys.isRecurring,
            }),
            getSingleSelectColumnSetting(
              {
                label: 'Frequency',
                key: uploadFieldKeys.frequency,
              },
              frequencyOptions,
            ),
            getDateColumnSetting({
              label: 'Task Due Date',
              key: uploadFieldKeys.dueDate,
              isRequired: true,
            }),
            getDateColumnSetting({
              label: 'Recurring Task End Date',
              key: uploadFieldKeys.endDate,
            }),
            getTextColumnSetting({
              label: 'Deficiency',
              key: uploadFieldKeys.deficiency,
            }),
            getTextColumnSetting({
              label: 'Notes',
              key: uploadFieldKeys.notes,
            }),
          ],
        },
      ],
    };
    return workbookConfig;
  }, [projectOptions, userOptions]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => {
      validateThatUserBelongsToProject(
        uploadFieldKeys.assigneeId,
        record,
        record.get('projectId'),
        userIdsByProjectId,
      );

      validateDayField(uploadFieldKeys.dueDate, record, {
        canBeInTheFuture: true,
      });
      validateDayField(uploadFieldKeys.endDate, record, {
        canBeInTheFuture: true,
      });

      if (record.get(uploadFieldKeys.isRecurring)) {
        if (!record.get(uploadFieldKeys.frequency)) {
          record.addError(
            uploadFieldKeys.frequency,
            'Frequency is required for recurring tasks.',
          );
        }
      }

      validateThatPrerequisiteFieldNotEmpty(
        uploadFieldKeys.frequency,
        uploadFieldKeys.isRecurring,
        record,
        'Frequency is only allowed for recurring tasks.',
      );

      validateThatPrerequisiteFieldNotEmpty(
        uploadFieldKeys.endDate,
        uploadFieldKeys.isRecurring,
        record,
        'End date is only allowed for recurring tasks.',
      );

      validateDateFieldsOrder(
        uploadFieldKeys.endDate,
        record,
        {
          before: record.get(uploadFieldKeys.dueDate),
          after: record.get(uploadFieldKeys.endDate),
        },
        'End date must be after the Due date.',
      );
    },
    [userIdsByProjectId],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: TaskUploadRecord[]) => {
      const taskResults: CreateTaskRequest[] = results.map((result) => {
        const taskId = uuidv4();

        const { assigneeId, ...restRecord } = result;
        const dueDate = format(new Date(result.dueDate), 'yyyy-MM-dd');

        // Currently, a user can't upload custom frequency.
        // This is mainly for converting Quarterly frequency into
        // custom monthly frequency that repeats every three months.
        // Or when other frequencies starts not at the beginning of a week/month/year.
        let frequency = valueOrUndefinedIfEmpty(result.frequency);
        let customFrequency: CustomFrequency | undefined;
        if (result.isRecurring) {
          const intervalFrequency = IntervalFrequencyFactory.For(frequency);
          frequency = intervalFrequency.frequency;
          customFrequency = {
            ...intervalFrequency.customFrequency,
            ...customFrequencyOffset(
              frequency,
              parseDateString(result.dueDate), // we need local date
              // this flag is true for when we create tasks from the UI,
              // mimicking the same behaviour here.
              true,
            ),
          };
        }

        return {
          ...restRecord,
          id: taskId,
          assignees: [
            {
              // The way BE distinguish btw new and existing assignees.
              // We use the same logic for create and update tasks.
              // Without it email notifications won't be sent.
              added: true,
              userId: result.assigneeId,
            },
          ],
          folderSlug: `ad-hoc-tasks-${taskId}`,
          subTasks: [],
          // Recurring tasks don't use due date, but start and end date.
          // Non-recurring tasks don't use start and end date, only due Date.
          dueDate: result.isRecurring ? undefined : dueDate,
          startDate: result.isRecurring ? dueDate : undefined,
          endDate: result.endDate
            ? format(new Date(result.endDate), 'yyyy-MM-dd')
            : undefined,
          // When creating a new recurring task, we basically create a new template.
          isTemplate: result.isRecurring,
          frequency,
          customFrequency,
        };
      });

      await Api.createTasksBulk(taskResults);
      onSuccess();
      // technically we needn't invalidate the cache since task stuff lives in the vortex app
      // but might as well
      invalidateTasksCache(TasksCacheEvents.UPSERT, {}); // for all projects
      return 'Successfully uploaded Tasks!';
    },
    [onSuccess],
  );

  const onSubmitError = useCallback(
    (error: unknown) => {
      if (isMultiErrorResponse(error)) {
        const message =
          error.status === 422
            ? `${error.data.message}. Please correct the issues and re-upload the file`
            : error.data.message;
        onError({
          message,
          errors: error.data.errors,
        });
      }
    },
    [onError],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ organizationId }),
    [organizationId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isProjectsLoading || isUsersLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      customErrorMessage="Close the upload modal to see more details."
      onSubmitError={onSubmitError}
      recordHookCallback={recordHookCallback}
      workbook={workbook}
      showTableForManualInput
    />
  );
}
