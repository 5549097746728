/* eslint-disable */
var IndustrialActivityChoicesView = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/industrialActivityChoices.pug'),

  childView: require('./IndustrialActivityChoiceRow'),

  childViewContainer: '.industrial-activity-choices',
});

module.exports = IndustrialActivityChoicesView;
