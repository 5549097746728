import React from 'react';
import Select from '../../../elements/Select';
import FormField from './FormField';

class SingleUserField extends FormField {
  constructor(props) {
    super(props);
    this.originalValue = this.getValue();
  }

  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);
    const { isStencil } = this.props;

    return (
      <Select
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        error={error}
        isClearable={isStencil || !templateField.required}
      />
    );
  }

  formatOptions(templateField) {
    const { currentUser } = this.props;
    const options = templateField.options.map((option) => ({
      label: option.name,
      value: option.id,
      isArchived: option.is_archived,
      email: option.email,
    }));

    const parsedValue = this.originalValue && JSON.parse(this.originalValue);
    // if user was selected, allow it no matter what
    // if current user is NOT mapistry user, filter out mapistry users
    // if archived user, filter it out
    return options.filter((option) => {
      if (parsedValue && option.value === parsedValue.user_id) {
        return true;
      }
      if (
        !currentUser.email.includes('@mapistry.com') &&
        option.email.includes('@mapistry.com')
      ) {
        return false;
      }
      return !option.isArchived;
    });
  }

  formatValue(formattedOptions) {
    const value = this.getValue();
    if (!value || !value.length) {
      return null;
    }
    const parsedValue = JSON.parse(value);
    return formattedOptions.find(
      (option) => option.value === parsedValue.user_id,
    );
  }

  handleChange(selection) {
    const { templateField, onChange } = this.props;
    const newValue = selection ? { user_id: selection.value } : '';
    return onChange(templateField.slug, JSON.stringify(newValue));
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SingleUserField;
