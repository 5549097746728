/* eslint-disable */
var ReceivingWaterSaltOrFreshView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/receivingWaterSaltOrFreshHardness.pug'),

  ui: {
    $saltwaterRadio: '#saltwater-radio',
    $freshwaterRadio: '#freshwater-radio',
    $hardnessDiv: '#hardness-div',
    $receivingWaterInput: 'input[name="receiving_water_is_saltwater"]',
    $hardnessInput: '#hardness-input',
  },

  events: {
    'change @ui.$receivingWaterInput': '_handleChangeReceivingWater',
  },

  initialize(options) {
    this.alwaysFreshwater = options.alwaysFreshwater;
    this.receivingWaterIsSaltWater = options.receivingWaterIsSaltWater;
    this.receivingWaterHardness = options.receivingWaterHardness;
    this.hardnessDoesNotMatter = options.hardnessDoesNotMatter;
  },

  templateHelpers() {
    return {
      alwaysFreshwater: this.alwaysFreshwater,
      hardnessDoesNotMatter: this.hardnessDoesNotMatter,
    };
  },

  onRender() {
    if (this.receivingWaterIsSaltWater) {
      this.ui.$saltwaterRadio.prop('checked', true);
    } else if (this.receivingWaterIsSaltWater === false) {
      this.ui.$freshwaterRadio.prop('checked', true);
      if (!this.hardnessDoesNotMatter) {
        this.ui.$hardnessDiv.show();
      }
    }

    this.$('#receiving-waters-popover').popover({
      html: true,
      content: function () {
        return $('#receiving-waters-popover-content-wrapper').html();
      },
    });

    if (this.hardnessDoesNotMatter) {
      return;
    }

    if (this.receivingWaterHardness) {
      this.ui.$hardnessInput.val(this.receivingWaterHardness);
    }

    if (this.alwaysFreshwater) {
      this.ui.$hardnessDiv.show();
    }

    this.$('#hardness-div [data-toggle="popover"]').popover();
  },

  _handleChangeReceivingWater() {
    if (this.hardnessDoesNotMatter) {
      return;
    }

    if (this._getReceivingWaterValue()) {
      this.ui.$hardnessDiv.hide();
    } else {
      this.ui.$hardnessDiv.show();
    }
  },

  _getReceivingWaterValue() {
    let receivingWaterIsSaltwater = this.$(
      'input[name="receiving_water_is_saltwater"]:checked',
    ).val();
    return receivingWaterIsSaltwater
      ? receivingWaterIsSaltwater === 'true'
      : null;
  },
});

module.exports = ReceivingWaterSaltOrFreshView;
