/* eslint-disable */
var SettingRowItemView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  template: require('./templates/settingRow.pug'),

  ui: {
    $link: '.setting-nav-link',
  },

  events: {
    'click @ui.$link': 'navigateToSettingsPage',
  },

  initialize(options = {}) {
    this.currentSettingSlug = options.currentSettingSlug;
    this.userId = options.userId;
  },

  onRender() {
    if (this.model.get('slug') === this.currentSettingSlug) {
      this.$el.addClass('active');
    }
    if (this.model.get('groupHeader')) {
      this.$el.addClass('sidebar-group-header');
    }
  },

  navigateToSettingsPage() {
    if (this.model.get('groupHeader')) {
      return;
    }
    let router = require('../../../router');
    router.goToSettingDashboard(this.model.get('slug'), this.userId);
  },
});

module.exports = SettingRowItemView;
