import React, { useCallback, useState } from 'react';
import { Button } from '@monorepo/shared/components/buttons/Button';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { useCurrentUser } from '@monorepo/shared/hooks/useCurrentUser';
import { useUpdateCurrentUser } from '@monorepo/old-web/js/hooks/users/useUpdateCurrentUser';
import { Checkbox } from '@monorepo/shared/components/Checkbox';

export const AcceptTosView = () => {
  const [isAccepted, setAccepted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useCurrentUser();
  const [updateCurrentUser] = useUpdateCurrentUser();

  const tos = (
    <a
      href="http://www.mapistry.com/terms-of-use"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Use
    </a>
  );
  const privacyPolicy = (
    <a
      href="http://www.mapistry.com/privacy-policy"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setSubmitting(true);
      setError('');

      const user = await updateCurrentUser({ acceptLatestTos: isAccepted });
      if (user) {
        /*
          We don't currently have a great way to navigate via URL. This inline require is
          necessary to use the `goToRootUrl` method because otherwise `router` is empty.
        */
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const router = require('@monorepo/old-web/js/router');
        router.goToRootUrl();
      } else {
        setSubmitting(false);
        setError(
          'Sorry, something went wrong with the acceptance. Please try again or contact us at help@mapistry.com if the problem persists.',
        );
      }
    },
    [isAccepted, updateCurrentUser],
  );

  return (
    <div className="accept-tos__container">
      <form className="authentication-form" onSubmit={onSubmit}>
        <SvgIcon className="logo-full" identifier="MapistryLogo" />
        <div className="login-inner">
          <h1>{`Welcome to Mapistry, ${currentUser?.name.split(' ')[0]}!`}</h1>
          <h4 className="description-text">
            Please first accept the Terms and Conditions to get started.
          </h4>
          <div className="full-width">
            <div className="fields">
              <div className="text-center">
                <Checkbox
                  label={
                    <span>
                      I agree to the {tos} and {privacyPolicy}
                    </span>
                  }
                  onChange={() => setAccepted(!isAccepted)}
                  isChecked={isAccepted}
                />
              </div>
              <Button
                className="full-width"
                disabled={!isAccepted || isSubmitting}
                size="large"
                type="submit"
              >
                {isSubmitting ? 'Loading...' : 'Accept'}
              </Button>
              {error && <p className="error-text">{error}</p>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
