/* eslint-disable */
module.exports = Backbone.Model.extend({
  initialize: function (options) {
    this.set({
      text: options.text,
      error: options.error,
      confirmMsg: options.confirmMsg,
      useSpinner: options.useSpinner,
      confirmClicked: options.confirmClicked,
      confirmNotClicked: options.confirmNotClicked,
    });
  },

  /**
   * Returns JSON representation of this model
   */
  toJSON: function () {
    var attrs = Backbone.Model.prototype.toJSON.call(this);
    var text = this.get('text');

    attrs.text = text ? 'Are you sure you want to delete ' + text : '';
    attrs.btnConfirm = text ? 'hidden' : '';
    attrs.btnDelete = text ? '' : 'hidden';

    return attrs;
  },
});
