import { SaveState } from '@monorepo/shared/types/SaveState';
import { startOfToday } from 'date-fns';
import update from 'immutability-helper';
import _get from 'lodash.get';
import { CalendarName, UTCEquivalentOfLocal } from 'mapistry-shared';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import APP from '../../../../config';
import { delayedModalClose } from '../../../../utils';
import withProvider from '../../../withProvider';
import PhCalibrationModal from './PhCalibrationModal';

import {
  createPhCalibrationReadingAction,
  editPhCalibrationReadingAction,
  fetchWastewaterMonitoringLocationsAction,
} from '../../../../actions/wastewater';

import { fetchSamplingCalendarEvents } from '../../../../actions/calendar';

class PhCalibrationModalContainer extends Component {
  constructor(props) {
    super(props);
    this.INITIAL_STATE = {
      formErrors: {},
      formSaveState: SaveState.CLEAN,
      formSubmissionDraft: {
        calibrationDate: UTCEquivalentOfLocal(startOfToday()),
        calibrationTime: new Date(),
      },
      originalCalibratingUserId: null,
      saveError: null,
      shouldShowDirtyCloseConfirmation: false,
    };
    this.state = this.INITIAL_STATE;

    this.closeModal = this.closeModal.bind(this);
    this.onModalRequestClose = this.onModalRequestClose.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validateFormSubmission = this.validateFormSubmission.bind(this);
  }

  componentDidMount() {
    const { fetchWastewaterMonitoringLocations } = this.props;
    fetchWastewaterMonitoringLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { attachment } = this.props;
    const { formSaveState } = this.state;

    APP.isNavigationBlocked = formSaveState === SaveState.DIRTY;

    const userIsEditing =
      (!_get(prevState, 'formSubmissionDraft.id') && _get(attachment, 'id')) ||
      (attachment !== prevProps.attachment &&
        _get(prevState, 'formSubmissionDraft.id') &&
        _get(attachment, 'id'));
    if (userIsEditing) {
      this.setState((prev) =>
        update(prev, {
          formSubmissionDraft: {
            id: { $set: attachment.id },
            calibrationDate: { $set: attachment.calibration_date },
            calibrationTime: {
              $set: `${attachment.calibration_date.slice(0, 10)}T${
                attachment.calibration_time
              }`,
            },
            userId: { $set: attachment.user_id },
            locationId: { $set: attachment.monitoring_location_id },
            calibrationMethod: { $set: attachment.calibration_method },
          },
          originalCalibratingUserId: { $set: attachment.user_id },
        }),
      );
    }
  }

  onModalRequestClose() {
    const { formSaveState } = this.state;
    if (![SaveState.CLEAN, SaveState.SAVED].includes(formSaveState)) {
      this.setState({
        shouldShowDirtyCloseConfirmation: true,
      });
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    const { onClose } = this.props;
    this.setState(this.INITIAL_STATE, onClose);
  }

  stageEdit(editField, nextValue) {
    this.setState((prevState) =>
      update(prevState, {
        formSaveState: { $set: SaveState.DIRTY },
        formSubmissionDraft: {
          $merge: {
            [editField]: nextValue,
          },
        },
      }),
    );
  }

  submitForm() {
    const { createOrUpdatePhCalibration, event } = this.props;
    const { formSubmissionDraft } = this.state;

    this.setState({
      formSaveState: SaveState.SAVING,
      saveError: null,
    });

    const { calibrationTime: time } = formSubmissionDraft;
    const timeString = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

    createOrUpdatePhCalibration(
      {
        id: formSubmissionDraft.id,
        calibrationDate: formSubmissionDraft.calibrationDate,
        calibrationTime: timeString,
        monitoringLocationId: formSubmissionDraft.locationId,
        calibrationMethod: formSubmissionDraft.calibrationMethod,
        userId: formSubmissionDraft.userId,
      },
      { start: _get(event, 'start', null), end: _get(event, 'end', null) },
    )
      .then(() => {
        this.setState(
          {
            formSaveState: SaveState.SAVED,
          },
          () => delayedModalClose(this.closeModal),
        );
      })
      .catch((error) => {
        this.setState({
          formSaveState: SaveState.DIRTY,
          saveError: error,
        });
      });
  }

  validateFormSubmission() {
    const { formSubmissionDraft } = this.state;
    const formErrors = {
      displayable: [],
    };
    /*
     * Ensure required fields exist
     */
    const requiredFieldsWithMessage = [
      {
        field: 'calibrationDate',
        message: 'Calibration date is required.',
      },
      {
        field: 'calibrationTime',
        message: 'Calibration time is required.',
      },
      {
        field: 'userId',
        message: 'Person performing calibration is required.',
      },
      {
        field: 'locationId',
        message: 'Monitoring location is required.',
      },
      {
        field: 'calibrationMethod',
        message: 'Calibration method is required.',
      },
    ];

    _.map(requiredFieldsWithMessage, (rf) => {
      if (!_.has(formSubmissionDraft, rf.field)) {
        formErrors[rf.field] = rf.message;
        formErrors.displayable.push(rf.message);
      }
    });

    if (formErrors.displayable.length === 0) {
      return this.setState({ formErrors: {} }, this.submitForm);
    }

    return this.setState((prevState) =>
      update(prevState, {
        formErrors: {
          $set: formErrors,
        },
      }),
    );
  }

  render() {
    const { monitoringLocations, open, organizationId, projectId } = this.props;
    const {
      formErrors,
      formSaveState,
      formSubmissionDraft,
      originalCalibratingUserId,
      saveError,
      shouldShowDirtyCloseConfirmation,
    } = this.state;
    const goToMapsURL = `/organizations/${organizationId}/projects/${projectId}`;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <PhCalibrationModal
        errors={formErrors}
        formSaveState={formSaveState}
        formSubmissionDraft={formSubmissionDraft}
        goToMapsURL={
          () => (window.top.location.href = goToMapsURL) // eslint-disable-line no-return-assign
        }
        monitoringLocations={monitoringLocations}
        onCancelDirtyCloseConfirmation={() =>
          this.setState({ shouldShowDirtyCloseConfirmation: false })
        }
        onClose={this.onModalRequestClose}
        onCloseErrorMessage={() => this.setState({ saveError: null })}
        onConfirmDirtyCloseConfirmation={() =>
          this.setState(
            { shouldShowDirtyCloseConfirmation: false },
            this.closeModal,
          )
        }
        open={open}
        originalCalibratingUserId={originalCalibratingUserId}
        saveError={saveError}
        shouldShowDirtyCloseConfirmation={shouldShowDirtyCloseConfirmation}
        stageEdit={this.stageEdit}
        submitForm={this.validateFormSubmission}
      />
    );
  }
}

PhCalibrationModalContainer.propTypes = {
  attachment: PropTypes.shape({
    calibration_date: PropTypes.string,
    calibration_method: PropTypes.string,
    calibration_time: PropTypes.string,
    id: PropTypes.string,
    monitoring_location_id: PropTypes.string,
    user_id: PropTypes.string,
  }),
  createOrUpdatePhCalibration: PropTypes.func.isRequired,
  event: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }),
  fetchWastewaterMonitoringLocations: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
};

PhCalibrationModalContainer.defaultProps = {
  organizationId: null,
  projectId: null,
  attachment: null,
  event: null,
};

const mapStateToProps = (state) => {
  const {
    project: { id, organizationId },
    wastewater: { monitoringLocations },
  } = state;

  return {
    monitoringLocations,
    organizationId,
    projectId: id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOrUpdatePhCalibration: (phCalibration, interval) =>
    phCalibration.id
      ? dispatch(
          editPhCalibrationReadingAction({
            phCalibration,
            projectId: APP.projectId,
          }),
        ).then(() =>
          dispatch(
            fetchSamplingCalendarEvents({
              calendarName: CalendarName.PH_LOG_CALIBRATION,
              end: interval.end,
              projectId: APP.projectId,
              start: interval.start,
            }),
          ),
        )
      : dispatch(
          createPhCalibrationReadingAction({
            phCalibration,
            projectId: APP.projectId,
          }),
        ),
  fetchWastewaterMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(PhCalibrationModalContainer),
);
