import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CalendarName } from 'mapistry-shared';
import { Loading } from '@monorepo/shared/components';
import withProvider from '../../../withProvider';
import PhLogRecordList from './PhLogRecordList';

import APP from '../../../../config';
import { deletePhLogReadingAction } from '../../../../actions/wastewater';
import {
  fetchParentIntervalsAction,
  fetchSamplingCalendarEvents,
} from '../../../../actions/calendar';
import { CalendarFrequencyType } from '../../../propTypes';

const PhLogRecordListContainer = (props) => {
  const {
    deleteAttachment,
    fetchAttachments,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    fetchIntervalSkeleton,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    isLoading,
    monitoringLocations,
    openPhLogModal,
    phCalendarFrequencies,
    projectId,
  } = props;

  useEffect(() => {
    if (projectId) {
      fetchIntervalSkeleton(projectId, CalendarName.PH_LOG_READINGS);
    }
  }, [projectId, fetchIntervalSkeleton]);

  if (isLoading) {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ paddingTop: '50px' }}>
        <Loading size="medium" />
      </div>
    );
  }

  return (
    <PhLogRecordList
      onFetchAttachment={fetchAttachments}
      onEditAttachment={openPhLogModal}
      onDeleteAttachment={deleteAttachment}
      monitoringLocations={monitoringLocations}
      phCalendarFrequencies={phCalendarFrequencies}
      projectId={projectId}
    />
  );
};

PhLogRecordListContainer.defaultProps = {
  phCalendarFrequencies: [],
  projectId: null,
};

PhLogRecordListContainer.propTypes = {
  deleteAttachment: PropTypes.func.isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openPhLogModal: PropTypes.func.isRequired,
  phCalendarFrequencies: PropTypes.arrayOf(CalendarFrequencyType),
  projectId: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { calendar, project, wastewater } = state;
  const isLoading =
    wastewater.isFetching?.monitoringLocations ||
    project.isFetching ||
    !project.id;
  return {
    isLoading,
    monitoringLocations: wastewater.monitoringLocations,
    phCalendarFrequencies: calendar.frequencies[CalendarName.PH_LOG_READINGS],
    projectId: project.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteAttachment: (attachment) =>
    dispatch(
      deletePhLogReadingAction({
        id: attachment.id,
        projectId: APP.projectId,
      }),
    ),
  fetchAttachments: (start, end) =>
    dispatch(
      fetchSamplingCalendarEvents({
        calendarName: CalendarName.PH_LOG_READINGS,
        end,
        projectId: APP.projectId,
        start,
      }),
    ),
  fetchIntervalSkeleton: (projectId, calendarName) =>
    dispatch(fetchParentIntervalsAction(projectId, calendarName)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(PhLogRecordListContainer),
);
