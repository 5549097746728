var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (colorClass, iconClass, tooltip) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["circle",colorClass], [false,true]), false, true)+" rel=\"tipsy\""+pug.attr("title", tooltip, true, true)) + "\u003E\u003Csvg class=\"svg\"\u003E\u003Cuse" + (pug.attr("xlink:href", iconClass, true, true)) + "\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "colorClass" in locals_for_with ?
        locals_for_with.colorClass :
        typeof colorClass !== 'undefined' ? colorClass : undefined, "iconClass" in locals_for_with ?
        locals_for_with.iconClass :
        typeof iconClass !== 'undefined' ? iconClass : undefined, "tooltip" in locals_for_with ?
        locals_for_with.tooltip :
        typeof tooltip !== 'undefined' ? tooltip : undefined));
    ;;return pug_html;};
module.exports = template;