/* eslint-disable */
// Views
var EditLayerStylesView = require('./EditLayerStylesLayoutView');
var EditLayerDetailsView = require('./EditLayerDetailsView');

var EditLayerLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/editLayerLayout.pug'),

  regions: {
    details: '#layer-details',
    styles: '#layer-styles',
  },

  onRender: function () {
    this.details.show(new EditLayerDetailsView({ model: this.model }));
    this.styles.show(new EditLayerStylesView({ model: this.model }));
  },
});

module.exports = EditLayerLayoutView;
