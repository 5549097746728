import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { CalendarName } from 'mapistry-shared';

import { Select, TableAction, TR, TD, TextField } from '../../../elements';
import { NotesPopover, TABLE_SETTINGS } from '../shared';

import Units from '../../../../utils/units';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.FACTORS;
const UNIT_OPTIONS = Units.getAllForSuite(CalendarName.GENERIC_LOG);
const TYPE_OPTIONS = ['Constant', 'Factor', 'Other'].map((v) => ({
  label: v,
  value: v,
}));

const EmissionsFactorsRow = (props) => {
  const {
    emissionsFactor,
    emissionsFactorIdx,
    deleteEmissionFactor,
    formErrors,
    groupId,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const stageChangedValue = useCallback(
    (editField, nextValue) => {
      stageEdit({ editField, nextValue }, { emissionsFactorIdx, groupId });
    },
    [groupId, emissionsFactorIdx, stageEdit],
  );

  const deleteOnClick = useCallback(
    () => deleteEmissionFactor(emissionsFactorIdx, groupId),
    [groupId, emissionsFactorIdx, deleteEmissionFactor],
  );

  const sharedSelectProps = {
    menuPlacement: 'auto',
    menuPortalTarget: selectMenuPortalRef.current,
    isClearable: false,
  };

  return (
    <TR>
      <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
        <TextField
          autoFocus={!emissionsFactor.name}
          controlled={false}
          defaultValue={emissionsFactor.name || ''}
          error={
            !!_get(formErrors, `groups.${groupId}.${emissionsFactorIdx}.name`)
          }
          onBlur={(e) => stageChangedValue('name', e.target.value)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
        <Select
          {...sharedSelectProps}
          error={
            !!_get(
              formErrors,
              `groups.${groupId}.${emissionsFactorIdx}.itemType`,
            )
          }
          options={TYPE_OPTIONS}
          onChange={(opt) => stageChangedValue('itemType', opt.value)}
          value={TYPE_OPTIONS.find((o) => o.value === emissionsFactor.itemType)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
        <TextField
          controlled={false}
          defaultValue={emissionsFactor.itemValue || ''}
          error={
            !!_get(
              formErrors,
              `groups.${groupId}.${emissionsFactorIdx}.itemValue`,
            )
          }
          onBlur={(e) => stageChangedValue('itemValue', e.target.value)}
          type="number"
        />
      </TD>
      <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
        <Select
          {...sharedSelectProps}
          error={
            !!_get(formErrors, `groups.${groupId}.${emissionsFactorIdx}.units`)
          }
          options={UNIT_OPTIONS}
          onChange={(opt) => stageChangedValue('units', opt.value)}
          value={UNIT_OPTIONS.find((o) => o.value === emissionsFactor.units)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
        <NotesPopover
          notes={emissionsFactor.notes}
          onClose={(nextNotes) => stageChangedValue('notes', nextNotes)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[5]} growth={COLUMN_GROWTH[5]}>
        <TableAction
          actionType="delete"
          deleteTooltipText="Delete row"
          onClick={deleteOnClick}
        />
      </TD>
    </TR>
  );
};

EmissionsFactorsRow.propTypes = {
  deleteEmissionFactor: PropTypes.func.isRequired,
  emissionsFactor: PropTypes.shape({
    renderKey: PropTypes.string,
    name: PropTypes.string,
    units: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    itemValue: PropTypes.string,
    itemType: PropTypes.string,
  }).isRequired,
  emissionsFactorIdx: PropTypes.number.isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default React.memo(EmissionsFactorsRow);
