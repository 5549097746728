var pug = require("!../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isStormwaterSamplingEnabled, isWastewaterEnabled, showExceedances, showParameterFrequency, showSamplesOutfallsSummary) {
      pug_html = pug_html + "\u003Cdiv id=\"site-summary-container\"\u003E\u003Cdiv class=\"dashboard-section\"\u003E";
if (isWastewaterEnabled) {
pug_html = pug_html + "\u003Cdiv class=\"dashboard-react-card\"\u003E\u003Cdiv id=\"flow-log-chart\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"row flex\"\u003E";
if (showParameterFrequency) {
pug_html = pug_html + "\u003Cdiv class=\"flex flex-1 card\"\u003E\u003Ch4\u003EParameter Sampling Frequency\u003C\u002Fh4\u003E\u003Cdiv id=\"parameter-frequency\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (showSamplesOutfallsSummary) {
pug_html = pug_html + "\u003Cdiv class=\"flex flex-1 card\"\u003E\u003Ch4\u003EStormwater Samples\u003C\u002Fh4\u003E\u003Cdiv id=\"stormwater-samples-outfalls-summary-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (isStormwaterSamplingEnabled) {
pug_html = pug_html + "\u003Cdiv class=\"flex flex-1 card\"\u003E\u003Cdiv class=\"sampling-parameters\" id=\"stormwater-sampling-parameters\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (showExceedances) {
pug_html = pug_html + "\u003Cdiv class=\"flex flex-1 card\"\u003E\u003Ch4\u003EStormwater Exceedances\u003C\u002Fh4\u003E\u003Cdiv id=\"exceedances-summary-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"project-records\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isStormwaterSamplingEnabled" in locals_for_with ?
        locals_for_with.isStormwaterSamplingEnabled :
        typeof isStormwaterSamplingEnabled !== 'undefined' ? isStormwaterSamplingEnabled : undefined, "isWastewaterEnabled" in locals_for_with ?
        locals_for_with.isWastewaterEnabled :
        typeof isWastewaterEnabled !== 'undefined' ? isWastewaterEnabled : undefined, "showExceedances" in locals_for_with ?
        locals_for_with.showExceedances :
        typeof showExceedances !== 'undefined' ? showExceedances : undefined, "showParameterFrequency" in locals_for_with ?
        locals_for_with.showParameterFrequency :
        typeof showParameterFrequency !== 'undefined' ? showParameterFrequency : undefined, "showSamplesOutfallsSummary" in locals_for_with ?
        locals_for_with.showSamplesOutfallsSummary :
        typeof showSamplesOutfallsSummary !== 'undefined' ? showSamplesOutfallsSummary : undefined));
    ;;return pug_html;};
module.exports = template;