import { useQuery } from 'react-query';
import { Api } from '@monorepo/shared/apiClient';
import {
  fetchOnboardingSession,
  FetchOnboardingSessionVariables,
  FetchOnboardingSessionResponse,
} from '../onboardingApiClient';
import { createOnboardingSessionKey } from './createOnboardingSessionKey';

type FetchOnboardingSessionQueryFn = Api.DataHookQueryFn<
  typeof createOnboardingSessionKey,
  typeof fetchOnboardingSession
>;

const queryFn: FetchOnboardingSessionQueryFn = () => fetchOnboardingSession();

type Input = Api.DataHookInput<
  FetchOnboardingSessionVariables,
  FetchOnboardingSessionResponse
>;

export function useOnboardingSession(input?: Input) {
  const key = Api.isDisabledDataHookInput(input)
    ? undefined
    : createOnboardingSessionKey();
  const config = input?.config;

  const { data, ...queryInfo } = useQuery(key, queryFn, config);

  return {
    ...queryInfo,
    onboardingSession: data,
  };
}
