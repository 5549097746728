import { InputLabel } from '@material-ui/core';
import {
  ConfirmDialog,
  Select,
} from '@monorepo/old-web/js/components/elements';
import { KeyService } from '@monorepo/shared/KeyService';
import { SvgIcon } from '@monorepo/shared/components';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { CustomFrequency, CustomFrequencyMessage } from 'mapistry-shared';
import React, { useMemo } from 'react';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _ from 'underscore';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import _s from 'underscore.string';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
import { FrequencyPicker } from './FrequencyPicker';
import { ParameterLimitInput } from './ParameterLimitInput';
import {
  ParameterLimitType,
  ParameterMonitoringLocation,
  ValidationError,
} from './types';

const allSamplingUnits = SamplingUnits.getAll();
const massLoadingUnit = allSamplingUnits.find(
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  (u) => u.slug === 'pounds_per_day',
);

export const samplingTypeOptions = [
  { label: 'Composite Sample', value: 'composite' },
  { label: 'Grab Sample', value: 'grab' },
];

export const calculationMethodologyOptions = [
  { label: 'Annual average', value: 'annual_average' },
  { label: 'Annual max', value: 'annual_max' },
  { label: 'Daily average', value: 'daily_average' },
  { label: 'Daily max', value: 'daily_max' },
  { label: 'Monthly average', value: 'monthly_average' },
  { label: 'Monthly max', value: 'monthly_max' },
  { label: 'Quarterly average', value: 'quarterly_average' },
  { label: 'Quarterly max', value: 'quarterly_max' },
  { label: 'Weekly average', value: 'weekly_average' },
  { label: 'Weekly max', value: 'weekly_max' },
  { label: 'Instantaneous', value: 'instantaneous' },
  { label: '30-day rolling average', value: '30-day_rolling_average' },
];

type ParameterLimitGroupRowProps = {
  hideSamplingTypeAndFrequency: boolean;
  limitErrors?: ValidationError[];
  limitType: ParameterLimitType;
  locationName: string;
  onDelete: (spml: ParameterMonitoringLocation) => void;
  onLimitError: (errors: ValidationError[] | undefined) => void;
  parameterSlug: string;
  spml: ParameterMonitoringLocation;
  stageSamplingParameterEdit: (
    key: string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    spml: ParameterMonitoringLocation,
  ) => void;
};

export function ParameterLimitGroupRow(props: ParameterLimitGroupRowProps) {
  const {
    hideSamplingTypeAndFrequency,
    limitErrors,
    limitType,
    locationName,
    onDelete,
    onLimitError,
    parameterSlug,
    spml,
    stageSamplingParameterEdit,
  } = props;

  const unitOptions = useMemo(() => {
    const parameterUnitSlug = SamplingParameters.getUnits(parameterSlug);
    if (!parameterUnitSlug) return [];

    const parameterUnit = allSamplingUnits.find(
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (u) => u.slug === parameterUnitSlug,
    );
    const relatedUnits = parameterUnit.measure
      ? /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        allSamplingUnits.filter((u) => u.measure === parameterUnit.measure)
      : [parameterUnit];
    // For concentration parameters user can define limits in lbs/day,
    // it will be calculated using flow rate for the same date
    if (parameterUnit.measure === 'concentration') {
      relatedUnits.push(massLoadingUnit);
    }

    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    return relatedUnits.map((u) => ({
      label: u.display_text,
      value: u.slug,
    }));
  }, [parameterSlug]);

  const customFrequencyInCamel = useMemo<CustomFrequency | undefined>(() => {
    if (!spml.custom_frequency) return undefined;
    const inCamel = {} as CustomFrequency;
    Object.entries(spml.custom_frequency).forEach(([key, value]) => {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      inCamel[_s.camelize(key)] = value;
    });
    return inCamel;
  }, [spml.custom_frequency]);
  const customFrequencyMessage = useMemo(() => {
    if (!customFrequencyInCamel) return undefined;
    return new CustomFrequencyMessage(
      spml.frequency,
      customFrequencyInCamel,
    ).getMessage({ messageStart: 'This limit will be applied' });
  }, [customFrequencyInCamel, spml.frequency]);

  const [deleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm] =
    useOpenClose();

  return (
    <>
      <div className="section--parameter-row">
        <div className="section--parameter-row--item" />
        <div className="section--parameter-row--item">
          {!hideSamplingTypeAndFrequency && (
            <>
              <InputLabel
                className="visually-hidden"
                htmlFor={`select-sample-type-for-${spml.monitoring_location_id}-input`}
                id={`select-sample-type-for-${spml.monitoring_location_id}-label`}
              >
                <span>
                  Select sample type for {spml.monitoring_location_id}
                </span>
              </InputLabel>
              <Select
                aria-labelledby={`select-sample-type-for-${spml.monitoring_location_id}-label`}
                isClearable={false}
                isFixed
                onChange={(opt) =>
                  stageSamplingParameterEdit('sampling_type', opt.value, spml)
                }
                options={samplingTypeOptions}
                value={_.find(
                  samplingTypeOptions,
                  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
                  (o) => o.value === spml.sampling_type,
                )}
              />
            </>
          )}
        </div>
        <div className="section--parameter-row--item">
          {!hideSamplingTypeAndFrequency && (
            <>
              <InputLabel
                className="visually-hidden"
                htmlFor={`select-frequency-for-${spml.monitoring_location_id}-input`}
                id={`select-frequency-for-${spml.monitoring_location_id}-label`}
              >
                <span>Select frequency for {spml.monitoring_location_id}</span>
              </InputLabel>
              <FrequencyPicker
                aria-labelledby={`select-frequency-for-${spml.monitoring_location_id}-label`}
                customFrequencyInCamel={customFrequencyInCamel}
                spml={spml}
                stageSamplingParameterEdit={stageSamplingParameterEdit}
              />
            </>
          )}
        </div>
        <div className="section--parameter-row--item">
          <InputLabel
            className="visually-hidden"
            htmlFor={`select-calculation-methodology-for-${spml.id}-input`}
            id={`select-calculation-methodology-for-${spml.id}-label`}
          >
            <span>Select calculation methodology for {spml.id}</span>
          </InputLabel>
          <Select
            aria-labelledby={`select-calculation-methodology-for-${spml.id}-label`}
            isClearable={false}
            isFixed
            onChange={(opt) =>
              stageSamplingParameterEdit(
                'calculation_methodology',
                opt.value,
                spml,
              )
            }
            options={calculationMethodologyOptions}
            value={_.find(
              calculationMethodologyOptions,
              /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              (o) => o.value === spml.calculation_methodology,
            )}
          />
        </div>
        <ParameterLimitInput
          errors={limitErrors}
          limitType={limitType}
          limitLower={spml.limit_lower}
          limitUpper={spml.limit_upper}
          locationName={locationName}
          monitoringLocationId={spml.id}
          onChange={(key, value) =>
            stageSamplingParameterEdit(key, value, spml)
          }
          onError={onLimitError}
        />
        <div className="section--parameter-row--item">
          <Select
            isClearable={false}
            isFixed
            onChange={(opt) =>
              stageSamplingParameterEdit('units', opt.value, spml)
            }
            options={unitOptions}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            value={unitOptions.find((o) => o.value === spml.units)}
          />
        </div>
        <div className="section--parameter-row--item">
          <ConfirmDialog
            confirmButtonText="Delete"
            danger
            description="Are you sure you want to delete this limit group?"
            open={deleteConfirmOpen}
            onCancelled={closeDeleteConfirm}
            onConfirmed={() => {
              closeDeleteConfirm();
              onDelete(spml);
            }}
            title="Delete"
          />
          <div
            role="button"
            tabIndex={0}
            aria-label="Delete"
            className="pointer tr"
            onClick={(e) => {
              e.stopPropagation();
              openDeleteConfirm();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (KeyService.triggersButtonClick(e.keyCode)) {
                openDeleteConfirm();
              }
            }}
          >
            <SvgIcon
              identifier="trash"
              className="red hover-dark-red grow-large f5"
            />
          </div>
        </div>
      </div>
      {customFrequencyMessage && (
        <div className="section--parameter-row--custom-frequency-msg">
          {customFrequencyMessage}
        </div>
      )}
    </>
  );
}
