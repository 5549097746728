/* eslint-disable */
import { CalendarName, FormSlugs } from 'mapistry-shared';
import * as React from 'react';
import CONSTANTS from '../../../../../../../../shared/constants';
import { ComponentWrapper } from '../../../../../../../components/componentWrapper';
import FlowLogRecordPanel from '../../../../../../../components/wastewater/FlowLogRecordPanel';
import PHLogRecordPanel from '../../../../../../../components/wastewater/PHLogRecordPanel';
import SamplingResultRecordPanel from '../../../../../../../components/wastewater/SamplingResultRecordPanel';
import DocumentService from '../../../../../../../services/DocumentService';

const moment = require('moment');
const projectStateConfig = require('mapistry-project-state-config');
const BluebirdPromise = require('bluebird');

const RecordsSidebarCollectionView = require('./RecordsSidebarCollectionView');
const RainLogLayoutView = require('./rainLog/RainLogLayoutView');
const DocumentsCompositeView = require('./documents/DocumentsCompositeView');
const RemoteDocumentsView = require('./documents/RemoteDocumentsView');
const DocumentCollection = require('../../../../../../../models_collections/documentCollection');
const SamplingResultSummariesLayoutView = require('../../../shared/sampling/resultSummaries/SamplingResultSummariesLayoutView');
const SwpppView = require('./swppp/SwpppView');
const apiCaller = require('../../../../../../../apiCaller');
const APP = require('../../../../../../../config');
const SwpppUpdateCollection = require('../../../shared/swpppUpdates/SwpppUpdateCollection');
const ExceedanceReportsCompositeView = require('./reports/ExceedanceReportsCompositeView');
const ExceedanceReportsCollection = require('./reports/ExceedanceReportsCollection.js');
const RecordsSpinnerView = require('../../../../../../../services/SpinnerItemView');
const SpccView = require('../../spcc/spccPlan/SpccView');
const SpccUpdateCollection = require('../../../shared/spccUpdates/SpccUpdateCollection');
const ProjectListAttrTableLayout = require('./projectListAttrTables/LayoutView');
const HmbpView = require('../../hmbp/hmbpPlan/HmbpView');
const HmbpUpdateCollection = require('../../../shared/hmbpUpdates/HmbpUpdateCollection');
const ProjectSettingsModel = require('../../../../../../../models_collections/projectSettingsModel');

const RecordsLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/recordsLayout.pug'),

  regions: {
    sidebar: '#records-sidebar-container',
    content: '#records-content-container',
  },

  initialize(options = {}) {
    this.projectModel = options.project;
    this.isDisabled = options.isDisabled;
    this.projectCalendars = options.projectCalendars;
    this.listenTo(APP.vent, 'formListSettings:save', this.onShow);
    this.listenTo(
      APP.vent,
      'washingtonRegion:change sectorData:change',
      this._fetchResultSummaryCollection,
    );
  },

  onShow() {
    this.recordsCollection = this.recordsCollection
      ? this.recordsCollection
      : this._getSidebarCollection();
    const recordsSidebarCollectionView = new RecordsSidebarCollectionView({
      collection: this.recordsCollection,
    });
    this.sidebar.show(recordsSidebarCollectionView);
    recordsSidebarCollectionView.selectFirstItem();
  },

  _fetchResultSummaryCollection() {
    let SamplingResultSummaryCollection = require('../../../shared/sampling/resultSummaries/SamplingResultSummaryCollection');
    SamplingResultSummaryCollection.fetchSingleton();
  },

  _getSidebarCollection() {
    const project = this.projectModel.toJSON();
    const sidebarCollection = new Backbone.Collection();
    if (
      this.projectModel.isSwpppProject() ||
      this._hasCalendar(CalendarName.STORMWATER_ANNUAL_EVALUATION) ||
      this._hasCalendar(CalendarName.STORMWATER_ROUTINE_INSPECTION) ||
      this._hasCalendar(CalendarName.STORMWATER_VISUAL_INSPECTION) ||
      this._hasCalendar(CalendarName.STORMWATER_ANNUAL_EVALUATION)
    ) {
      sidebarCollection.add({
        title: 'Stormwater: SWPPP',
        showRecordsView: this._showSwppp.bind(this, project, 'SWPPP'),
      });
    } else if (this.projectModel.isBmpEnabled()) {
      sidebarCollection.add({
        title: 'Stormwater: BMP Plan',
        showRecordsView: this._showSwppp.bind(this, project, 'BMP Plan'),
      });
    }

    if (this._hasCalendar(CalendarName.STORMWATER_VISUAL_INSPECTION)) {
      if (projectStateConfig.isWashingtonIndustrial(project)) {
        sidebarCollection.add({
          title: 'Stormwater: Discharge Monitoring Reports',
          showRecordsView: this._showDMR.bind(this),
        });
      }

      if (!projectStateConfig.isStateIndustrial(project, 'mi')) {
        sidebarCollection.add({
          title: 'Stormwater: Sampling Results',
          showRecordsView: this._showSamplingResultSummaries.bind(this),
        });
      }

      if (projectStateConfig.isCaliforniaIndustrial(project)) {
        sidebarCollection.add({
          title: 'Stormwater: Exceedance Reports',
          showRecordsView: this._showExceedanceReports.bind(this, project),
        });
      }
    }

    if (this.projectModel.isSpccProject()) {
      sidebarCollection.add({
        title: 'SPCC: Plan',
        showRecordsView: this._showSpccPlanUpdateLog.bind(this, project),
      });
    }

    if (this.projectModel.isWastewaterEnabled()) {
      if (APP.featureFlags.isShowPhLog) {
        sidebarCollection.add({
          title: 'Water: pH Log',
          showRecordsView: this._showWastewaterPHLog.bind(this, project),
        });
      }
      sidebarCollection.add({
        title: 'Water: Flow Log',
        showRecordsView: this._showWastewaterFlowLog.bind(this, project),
      });
      sidebarCollection.add({
        title: 'Water: Sampling Results',
        showRecordsView: this._showWastewaterSamplingResults.bind(this),
      });
    }

    if (this.projectModel.isHmbpEnabled()) {
      sidebarCollection.add({
        title: 'HazMat: Chemicals',
        showRecordsView: this._showHmbpProjectAttrsTable.bind(this, project),
      });
      sidebarCollection.add({
        title: 'HMBP',
        showRecordsView: this._showHmbpPlanUpdateLog.bind(this, project),
      });
    }

    sidebarCollection.add({
      title: 'Rain Log',
      showRecordsView: this._showRainLog.bind(this),
    });

    if (this._hasCalendar(CalendarName.STORMWATER_VISUAL_INSPECTION)) {
      sidebarCollection.add({
        title: 'Lab Reports',
        showRecordsView: this._showLabReports.bind(this, project),
      });
    }

    sidebarCollection.add({
      title: 'Documents',
      showRecordsView: this._showOtherDocuments.bind(this, project),
    });

    return sidebarCollection;
  },

  _hasCalendar(calendarName) {
    return this.projectCalendars.some((c) => c.name === calendarName);
  },

  _showHmbpProjectAttrsTable(project) {
    this.content.show(new RecordsSpinnerView());

    const ProjectListAttrTableCollection = require('../../../../../../../models_collections/projectListAttrTableCollection');
    const collection = new ProjectListAttrTableCollection({
      key: CONSTANTS.LIST_ATTR_DEFAULTS.CHEMICAL_HAZMAT_INV,
    });
    const projectSettings = new ProjectSettingsModel(null, {
      projectId: project.id,
    });
    const tableView = new ProjectListAttrTableLayout({
      project,
      projectSettings,
      collection,
      listSettings: {
        modalSettingsView: require('./projectListAttrTables/SettingsModalView'),
      },
    });

    const projectSettingsRequest = projectSettings.fetch();
    const fetchRequest = tableView.collection.fetchPromise();

    return BluebirdPromise.all([projectSettingsRequest, fetchRequest]).then(
      () => this.content.show(tableView),
    );
  },

  _showHmbpPlanUpdateLog(project) {
    const currentUserArbiter = require('../../../../../../../permissions/currentUserArbiter');
    const [startDate, endDate] = this._getHmbpLogStartDateAndEndDate(project);
    const collection = new HmbpUpdateCollection([], {
      projectId: project.id,
      project,
      startDate,
      endDate,
    });
    const uploadHmbpCollection = new DocumentCollection([], {
      projectId: this.projectModel.id,
      folderSlug: 'hmbp_plan',
    });

    return currentUserArbiter
      .hasProjectUpdatePermission(project.id)
      .then((hasProjectUpdatePermission) => {
        const hmbpView = new HmbpView({
          hasProjectUpdatePermission,
          uploadHmbpCollection,
          collection,
          hmbpYearStartDate: startDate,
          hmbpYearEndDate: endDate,
          projectModel: this.projectModel,
          isDisabled: () => false,
        });

        return Promise.all([
          collection.fetchPromise(),
          uploadHmbpCollection.fetchPromise(),
        ]).then(() => this.content.show(hmbpView));
      });
  },

  _showWastewaterPHLog() {
    this.content.show(new ComponentWrapper(<PHLogRecordPanel />));
  },

  _showWastewaterFlowLog() {
    this.content.show(new ComponentWrapper(<FlowLogRecordPanel />));
  },
  _showWastewaterSamplingResults() {
    this.content.show(new ComponentWrapper(<SamplingResultRecordPanel />));
  },

  _showSpccPlanUpdateLog(project) {
    const currentUserArbiter = require('../../../../../../../permissions/currentUserArbiter');
    const [startDate, endDate] = this._getSpccLogStartDateAndEndDate(project);
    const collection = new SpccUpdateCollection([], {
      projectId: project.id,
      project,
      startDate,
      endDate,
    });
    const uploadSpccCollection = new DocumentCollection([], {
      projectId: this.projectModel.id,
      folderSlug: 'spcc_plan',
    });

    return currentUserArbiter
      .hasProjectUpdatePermission(project.id)
      .then((hasProjectUpdatePermission) => {
        const spccView = new SpccView({
          hasProjectUpdatePermission,
          uploadSpccCollection,
          collection,
          spccYearStartDate: startDate,
          spccYearEndDate: endDate,
          projectModel: this.projectModel,
          isDisabled: () => false,
        });

        return BluebirdPromise.all([
          collection.fetchPromise(),
          uploadSpccCollection.fetchPromise(),
        ])
          .bind(this)
          .then(() => this.content.show(spccView));
      });
  },

  _getStartDateAndEndDate(project) {
    let startDate = projectStateConfig.getSwpppYearStartDate(project);
    let endDate = projectStateConfig.getSwpppYearEndDate(project);
    startDate = moment(startDate).isBefore(moment(project.created_at))
      ? startDate
      : moment(project.created_at).format('YYYY-MM-DD');
    return [startDate, endDate];
  },

  _getSpccLogStartDateAndEndDate() {
    // we may need to revisit this date range in the future, to enable a drop down selection of
    // the calendar year that will be queried for the SPCC Update Log
    let startDate = '2018-01-01';
    let endDate = new Date().getFullYear() + '-12-31';
    return [startDate, endDate];
  },

  _getHmbpLogStartDateAndEndDate() {
    // we may need to revisit this date range in the future, to enable a drop down selection of
    // the calendar year that will be queried for the HMBP Update Log
    let startDate = '2018-01-01';
    let endDate = new Date().getFullYear() + '-12-31';
    return [startDate, endDate];
  },

  _showDMR() {
    const collection =
      require('./dischargeMonitoringReports/DMRCollection').singleton();
    const DMRView = require('./dischargeMonitoringReports/DMRView');
    const isDisabled = this.isDisabled.bind(
      this,
      [
        CalendarName.STORMWATER_VISUAL_INSPECTION,
        CalendarName.STORMWATER_ROUTINE_INSPECTION,
      ],
      'Track DMR submissions with Mapistry so you never miss one.',
    );
    const dmrView = new DMRView({ collection, isDisabled });
    return this.content.show(dmrView);
  },

  _showSwppp(project, planName) {
    const currentUserArbiter = require('../../../../../../../permissions/currentUserArbiter');
    const [startDate, endDate] = this._getStartDateAndEndDate(project);
    const collection = new SwpppUpdateCollection([], {
      projectId: project.id,
      project,
      startDate,
      endDate,
    });
    const uploadSwpppCollection = new DocumentCollection([], {
      projectId: this.projectModel.id,
      folderSlug: 'swppp',
    });

    return currentUserArbiter
      .hasProjectUpdatePermission(project.id)
      .then((hasProjectUpdatePermission) => {
        const swpppView = new SwpppView({
          hasProjectUpdatePermission,
          uploadSwpppCollection,
          collection,
          swpppYearStartDate: startDate,
          swpppYearEndDate: endDate,
          projectModel: this.projectModel,
          isDisabled: () => false,
          planName,
        });

        return BluebirdPromise.all([
          collection.fetchPromise(),
          uploadSwpppCollection.fetchPromise(),
        ])
          .bind(this)
          .then(() => this.content.show(swpppView));
      });
  },

  _showSamplingResultSummaries() {
    const isDisabled = this.isDisabled.bind(
      this,
      [
        CalendarName.STORMWATER_VISUAL_INSPECTION,
        CalendarName.STORMWATER_ROUTINE_INSPECTION,
      ],
      "With Mapistry, tracking sampling results is easy. We'll take care of calculating storm averages and annual averages so that we can compare your results against NALs.",
    );
    return this.content.show(
      new SamplingResultSummariesLayoutView({
        projectModel: this.projectModel,
        isDisabled,
      }),
    );
  },

  async _showOtherDocuments(project) {
    this.content.show(new RecordsSpinnerView());
    const documentService = new DocumentService();
    const { location } = await documentService.getLocation(project.id);
    if (location) {
      return this.content.show(
        new RemoteDocumentsView({ documentsLocation: location, project }),
      );
    }

    let collection = new DocumentCollection([], {
      projectId: project.id,
      folderSlug: 'stormwater',
    });
    let documentsCompositeView = new DocumentsCompositeView({
      collection,
      isLocal: true,
    });

    return documentsCompositeView.collection
      .fetchPromise()
      .bind(this)
      .then(() => this.content.show(documentsCompositeView));
  },

  _showLabReports(project) {
    // turn on a spinner, since this enough time for user to notice lag.
    this.content.show(new RecordsSpinnerView());

    const isDisabled = this.isDisabled.bind(
      this,
      [
        CalendarName.STORMWATER_VISUAL_INSPECTION,
        CalendarName.STORMWATER_ROUTINE_INSPECTION,
      ],
      'Mapistry makes it easy to stay organized. Keep all your stormwater lab reports here, in one place.',
    );
    const collection = new DocumentCollection([], {
      projectId: project.id,
      folderSlug: 'lab-report',
    });
    const labReportsCompositeView = new DocumentsCompositeView({
      collection,
      isDisabled,
    });

    return labReportsCompositeView.collection
      .fetchPromise()
      .bind(this)
      .then(() => this.content.show(labReportsCompositeView));
  },

  _showExceedanceReports(project) {
    // turn on a spinner, since this enough time for user to notice lag.
    this.content.show(new RecordsSpinnerView());

    const isDisabled = this.isDisabled.bind(
      this,
      [
        CalendarName.STORMWATER_VISUAL_INSPECTION,
        CalendarName.STORMWATER_ROUTINE_INSPECTION,
      ],
      'Mapistry makes it easy to create reports required by your regulator organization. You just answer a series of questions and a formatted pdf is created!',
    );
    const collection = new ExceedanceReportsCollection([], {
      projectId: project.id,
      folderSlug: 'exceedance-report',
    });
    const exceedanceReportsCompositeView = new ExceedanceReportsCompositeView({
      collection,
      isDisabled,
      project: this.projectModel,
    });

    return exceedanceReportsCompositeView.collection
      .fetchPromise()
      .then(this._addExceedanceReportForms.bind(this, project))
      .bind(this)
      .then(() => this.content.show(exceedanceReportsCompositeView));
  },

  _addExceedanceReportForms(project, collection) {
    if (this.projectModel.isStormwaterLevel1Enabled()) {
      let formTemplateSlug = FormSlugs.getCALevel1ExceedanceTemplate(
        project.is_federal,
        project.state,
      );
      // TODO - implement for more than just 2017-2018
      // TODO - sorting isn't working correctly.
      let formSubmissionSlug = FormSlugs.getCALevel1ExceedanceSubmission(
        project.is_federal,
        project.state,
        2017,
      );

      return apiCaller
        .getFormSubmission(project.id, formTemplateSlug, formSubmissionSlug)
        .then((submission) => {
          let submissionId = submission && submission.id ? submission.id : null;
          collection.add({
            name: 'Level 1 Exceedance Response Action Report',
            submissionId,
            formTemplateSlug,
            formSubmissionSlug,
          });
          collection.sort();
        });
    }
  },

  _showRainLog() {
    let rainLogLayoutView = new RainLogLayoutView({
      project: this.projectModel,
    });
    return this.content.show(rainLogLayoutView);
  },
});

module.exports = RecordsLayoutView;
