import { Button } from '@monorepo/shared/components';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useIsSuperAdmin } from '@monorepo/shared/hooks/permissions/useIsSuperAdmin';
import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React from 'react';
import { ConfirmDialog, Modal, SaveButton } from '../../../elements';

import SamplingResultForm from '../SamplingResultForm';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function SamplingResultModal({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  deleteErrored,
  errorCount,
  eventDate,
  eventTime,
  initialSearchParameterSlug,
  isCancelDisabled,
  isLoading,
  labReportNumber,
  monitoringLocations,
  onClose,
  onCloseErrorMessage,
  onConfirmCancel,
  onConfirmClose,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onConfirmDeleteSamplingEvent,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onDeleteSamplingEvent,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onCancelDeleteSamplingEvent,
  onFormChange,
  onSave,
  open,
  project,
  samplingEventId,
  samplingResults,
  saveError,
  saveState,
  showConfirmation,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  showDeleteSamplingEventConfirmation,
  showFormError,
  wastewaterParameters,
}) {
  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(project.id);
  const isSuperAdmin = useIsSuperAdmin();

  return (
    <Modal
      className="wastewater-sampling-result-modal"
      header={<h4>Water: Sampling Results</h4>}
      fullWidth
      footer={
        <>
          {isSuperAdmin && (
            <Button
              color="danger"
              onClick={onDeleteSamplingEvent}
              className="wastewater-sampling-result-modal__delete"
              disabled={!samplingEventId}
            >
              Delete Sampling Event
            </Button>
          )}
          <Button
            color="secondary"
            disabled={isCancelDisabled}
            onClick={onClose}
          >
            {SaveState.DIRTY === saveState ? 'Cancel' : 'Close'}
          </Button>
          <span className="ph2" />
          {hasProjectUpdatePermission && (
            <SaveButton
              onSave={onSave}
              saveState={saveState}
              disabled={!eventDate || !eventTime}
            />
          )}
        </>
      }
      onClose={onClose}
      open={open}
    >
      <>
        <SamplingResultForm
          errorCount={errorCount}
          eventDate={eventDate}
          eventTime={eventTime}
          initialSearchParameterSlug={initialSearchParameterSlug}
          isLoading={isLoading}
          labReportNumber={labReportNumber}
          onChange={onFormChange}
          onClose={onClose}
          onCloseErrorMessage={onCloseErrorMessage}
          project={project}
          samplingEventId={samplingEventId}
          samplingResults={samplingResults}
          saveError={saveError}
          showFormError={showFormError}
          monitoringLocations={monitoringLocations}
          wastewaterParameters={wastewaterParameters}
        />
        <ConfirmDialog
          confirmButtonText="Close without saving"
          danger
          onCancelled={onConfirmCancel}
          onConfirmed={onConfirmClose}
          open={showConfirmation}
          title="Are you sure you want to close without saving?"
        />

        {/* only super admins see this */}
        <ConfirmDialog
          confirmButtonText="Delete Sampling Event"
          danger
          onCancelled={onCancelDeleteSamplingEvent}
          onConfirmed={onConfirmDeleteSamplingEvent}
          open={showDeleteSamplingEventConfirmation}
          description={
            deleteErrored
              ? 'Oops, something went wrong deleting this. Try again, or check the console logs for the error.'
              : undefined
          }
          title="Are you sure you want to delete all records in this event?"
        />
      </>
    </Modal>
  );
}

SamplingResultModal.defaultProps = {
  errorCount: 0,
  eventDate: null,
  eventTime: null,
  initialSearchParameterSlug: null,
  isCancelDisabled: false,
  isLoading: false,
  labReportNumber: null,
  samplingEventId: null,
  saveError: null,
  showConfirmation: false,
  showFormError: false,
};

SamplingResultModal.propTypes = {
  errorCount: PropTypes.number,
  eventDate: PropTypes.instanceOf(Date),
  eventTime: PropTypes.instanceOf(Date),
  initialSearchParameterSlug: PropTypes.string,
  isCancelDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  labReportNumber: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  onConfirmClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  samplingEventId: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  samplingResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveError: PropTypes.string,
  saveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  showConfirmation: PropTypes.bool,
  showFormError: PropTypes.bool,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  wastewaterParameters: PropTypes.arrayOf(PropTypes.object).isRequired,
};
