import React from 'react';

export function ParameterSectionHeader() {
  return (
    <div className="section--parameter-row section--parameter-row-header">
      <div className="section--parameter-row--item">Monitoring location</div>
      <div className="section--parameter-row--item">Sampling Type</div>
      <div className="section--parameter-row--item">Frequency</div>
      <div className="section--parameter-row--item">
        Calculation methodology
      </div>
      <div className="section--parameter-row--item">Limit</div>
      <div className="section--parameter-row--item">Units</div>
      <div className="section--parameter-row--item" />
    </div>
  );
}
