/* eslint-disable */
import _ from 'underscore';
import 'core-js/features/array/find';

const _attrStore = {
  lists: [],
};

const ACTIONS = {
  getLists: () => {
    return JSON.parse(JSON.stringify(_attrStore.lists));
  },
  getListKvps: (key) => {
    const list = _.find(_attrStore.lists, (l) => l.key === key);
    return !!list ? JSON.parse(JSON.stringify(list.kvps)) : [];
  },
  getListChemicalAttributes: (key) => {
    const list = _attrStore.lists.find((l) => l.key === key);
    return !!list ? JSON.parse(JSON.stringify(list.attributes)) : [];
  },
  getSwitchableChemicalAttrs: (key) => {
    const list = _attrStore.lists.find((l) => l.key === key);
    return !!list ? JSON.parse(JSON.stringify(list.switchableAttributes)) : [];
  },
  storeLists: (lists) => {
    _attrStore.lists = lists;
  },
};
export default ACTIONS;
