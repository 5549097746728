/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '../../../elements/FieldWrapper';

class FormField extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  getInputComponent(templateField) {
    return <div>{`Field type '${templateField.type}' not implemented`}</div>;
  }

  getValue() {
    const { submissionField } = this.props;
    return submissionField && submissionField.fieldValue;
  }

  fieldClassName() {
    return 'form-field';
  }

  hasError(submissionField) {
    return !!(
      submissionField &&
      submissionField.errors &&
      submissionField.errors.length
    );
  }

  embeddedErrorMessage(submissionField) {
    const errors = submissionField && submissionField.errors;
    return errors && errors.length ? errors.join('\n') : null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  handleChange(event) {
    const { onChange, templateField } = this.props;
    return onChange(templateField.slug, event.target.value);
  }

  render() {
    const { templateField, submissionField } = this.props;
    const error = this.hasError(submissionField);

    return (
      <FieldWrapper
        className={this.fieldClassName()}
        label={templateField.name}
        isRequired={templateField.required}
        helpText={templateField.helpText}
        subtext={templateField.nameSubtext}
        error={error}
        errorMessage={this.embeddedErrorMessage(submissionField)}
      >
        {this.getInputComponent(templateField, error)}
      </FieldWrapper>
    );
  }
}

const TemplateFieldShape = {
  id: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  nameSubtext: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
};

const SubmissionFieldShape = {
  errors: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FormField.propTypes = {
  templateField: PropTypes.shape(TemplateFieldShape).isRequired,
  submissionField: PropTypes.shape(SubmissionFieldShape).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormField;
