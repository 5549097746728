var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (display_text, frequency) {
      pug_html = pug_html + "\u003Ctd class=\"padding-right-10\"\u003E" + (pug.escape(null == (pug_interp = display_text) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = frequency.charAt(0).toUpperCase() + frequency.slice(1)) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }.call(this, "display_text" in locals_for_with ?
        locals_for_with.display_text :
        typeof display_text !== 'undefined' ? display_text : undefined, "frequency" in locals_for_with ?
        locals_for_with.frequency :
        typeof frequency !== 'undefined' ? frequency : undefined));
    ;;return pug_html;};
module.exports = template;