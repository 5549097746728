/* eslint-disable */
const APP = require('../config');
const apiCaller = require('../apiCaller');

let ProjectFeaturePhotoCollection = Backbone.Collection.extend({
  initialize(featureId) {
    this.featureId = featureId;
    this.projectId = APP.projectId;
  },

  url() {
    return apiCaller.getProjectFeaturesPhotosUrl(
      this.projectId,
      this.featureId,
    );
  },
});

Backbone.addSingletonToCollection(ProjectFeaturePhotoCollection);
module.exports = ProjectFeaturePhotoCollection;
