/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
// NOTE: es5 phantom shim MUST come first if this is running in phantom
if (typeof window.callPhantom === 'function') {
  require('../lib/es5PhantomShim');
}
window.Spinner = require('@monorepo/old-web/js/services/spinner');
const router = require('@monorepo/old-web/js/router');

module.exports = function bootProject() {
  require('../lib/jqueryOverrides')();
  require('../lib/leafletOverrides')();
  require('../lib/bootstrapOverrides')();
  require('../lib/select2Overrides')();

  // Globally register our custom behaviors so Marionette knows about them
  Backbone.Marionette.Behaviors.behaviorsLookup = require('@monorepo/old-web/js/marionetteBehaviors/getBehaviorsRegistry');

  router.start();
};
/* eslint-enable global-require */
