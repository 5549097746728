import React, { useMemo } from 'react';
import { OnboardingDocumentsTableProjectRow as CustomerProjectRow } from '../../../components/OnboardingDocumentsTable';

type CustomerProjectRow = React.ComponentProps<typeof CustomerProjectRow>;

type OnboardingDocumentsTableAdminProjectRowProps = CustomerProjectRow;

export function OnboardingDocumentsTableAdminProjectRow(
  props: OnboardingDocumentsTableAdminProjectRowProps,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  const { sections } = props;
  const projectRowProps = props;

  const hasAnyEnabledSections = useMemo(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    () => sections.some((section) => section.enabled),
    [sections],
  );

  if (!hasAnyEnabledSections) {
    return <CustomerProjectRow {...projectRowProps} hideDocumentsCount />;
  }

  return <CustomerProjectRow {...projectRowProps} />;
}
