var pug = require("!../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (groupHeader, label, slug) {
      if (groupHeader) {
pug_html = pug_html + "\u003Cdiv class=\"flex padding-left-10\"\u003E\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
if (slug) {
pug_html = pug_html + "\u003Cdiv class=\"flex sidebar-row setting-nav-link align-items-center\"\u003E\u003Cdiv class=\"flex padding-left-30\"\u003E\u003Ca\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "groupHeader" in locals_for_with ?
        locals_for_with.groupHeader :
        typeof groupHeader !== 'undefined' ? groupHeader : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "slug" in locals_for_with ?
        locals_for_with.slug :
        typeof slug !== 'undefined' ? slug : undefined));
    ;;return pug_html;};
module.exports = template;