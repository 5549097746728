var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isWeightSelected) {
      pug_html = pug_html + "\u003Cdiv class=\"form-group inline-row margin-right\"\u003E\u003Cdiv class=\"subheader-container\"\u003E\u003Ch4\u003ELine Weight\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cselect class=\"weight form-control\" name=\"weight\"\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(3), true, true)) + "\u003E3\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(4), true, true)) + "\u003E4\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(5), true, true)) + "\u003E5\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(6), true, true)) + "\u003E6\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(7), true, true)) + "\u003E7\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(8), true, true)) + "\u003E8\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(9), true, true)) + "\u003E9\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(10), true, true)) + "\u003E10\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(12), true, true)) + "\u003E12\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(14), true, true)) + "\u003E14\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(16), true, true)) + "\u003E16\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(18), true, true)) + "\u003E18\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isWeightSelected(20), true, true)) + "\u003E20\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isWeightSelected" in locals_for_with ?
        locals_for_with.isWeightSelected :
        typeof isWeightSelected !== 'undefined' ? isWeightSelected : undefined));
    ;;return pug_html;};
module.exports = template;