/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { MapistryTooltip } from '../../elements';

export default class RecordPanelIconStatus extends React.Component {
  icon() {
    const { status } = this.props;
    const statusClassName = `record-panel-icon-status-${status}`;
    if (status === 'complete') {
      return <SvgIcon identifier="check" className={statusClassName} />;
    }
    if (status === 'overdue') {
      return <SvgIcon identifier="close" className={statusClassName} />;
    }
    return <SvgIcon identifier="exclamation" className={statusClassName} />;
  }

  render() {
    const { status, tooltipText } = this.props;
    const withPossibleTooltip = (component) =>
      tooltipText ? (
        <MapistryTooltip title={tooltipText}>{component}</MapistryTooltip>
      ) : (
        component
      );

    const validStatuses = ['complete', 'overdue', 'upcoming'];

    if (
      status == null ||
      !validStatuses.some((validStatus) => validStatus === status)
    ) {
      return withPossibleTooltip(<div className="record-panel-icon-status" />);
    }

    return withPossibleTooltip(
      <div className="record-panel-icon-status">{this.icon()}</div>,
    );
  }
}

RecordPanelIconStatus.defaultProps = {
  status: null,
  tooltipText: null,
};

RecordPanelIconStatus.propTypes = {
  status: PropTypes.string,
  tooltipText: PropTypes.string,
};
