/* eslint-disable */
let samplingParametersCollection =
  require('../../../../../../../models_collections/samplingParametersCollection').singleton();
let samplingResultSummaryCollection =
  require('./SamplingResultSummaryCollection').singleton();
let SamplingResultSummaryCollectionView = require('./SamplingResultSummaryCollectionView');

let SamplingResultSummariesLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/samplingResultSummariesLayout.pug'),

  regions: {
    header: '#dashboard-sampling-result-summaries-header',
    content: '#dashboard-sampling-result-summaries-content',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.isDisabled = options.isDisabled;
  },

  onShow() {
    this._showHeader();
    this._showContent();

    let APP = require('../../../../../../../config');
    this.listenTo(
      APP.vent,
      'sampleEventParameterLocation:change sampleEventParameter:add sampleEventParameter:destroy sampleEventParameter:reset',
      this._fetchSampleResultsSummaryAndShowContent.bind(this),
    );
    this.listenTo(
      samplingParametersCollection,
      'add remove update reset',
      this._showContent.bind(this),
    );
  },

  _showHeader() {
    let SamplingResultSummariesHeaderView = require('./SamplingResultSummariesHeaderView');
    this.header.show(
      new SamplingResultSummariesHeaderView({
        projectModel: this.projectModel,
        isDisabled: this.isDisabled,
      }),
    );
  },

  _fetchSampleResultsSummaryAndShowContent() {
    let SamplingResultSummaryCollection = require('./SamplingResultSummaryCollection');
    return SamplingResultSummaryCollection.fetchSingleton().then(
      this._showContent.bind(this),
    );
  },

  _showContent() {
    let samplingResultSummaryCollectionView =
      new SamplingResultSummaryCollectionView({
        collection: samplingResultSummaryCollection,
      });
    this.content.show(samplingResultSummaryCollectionView);
  },
});

module.exports = SamplingResultSummariesLayoutView;
