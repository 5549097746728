const formatKey = {
  forUserRoles(userId, entityId, category) {
    return ['user', userId, category.toLowerCase(), entityId, 'roles'].join(
      '|',
    );
  },

  forUserPermissions(userId, entityId, category) {
    return [
      'user',
      userId,
      category.toLowerCase(),
      entityId,
      'permissions',
    ].join('|');
  },
};

module.exports = formatKey;
