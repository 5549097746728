/* eslint-disable */
import { CustomLogType } from '@monorepo/shared/apiClient/types';
import update from 'immutability-helper';
import _get from 'lodash.get';
import { GenericLogLoggedItemType } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import ProjectContext from '../../../../contexts/ProjectContext';

import {
  createEmissionLoggedItemAction,
  deleteEmissionItemGroupAction,
  deleteEmissionLoggedItemAction,
  fetchEmissionItemGroupsAction,
  fetchEmissionLoggedItemsAction,
  updateEmissionLoggedItemAction,
} from '../../../../actions/air';
import { isNullOrUndefined } from '../../../../utils';
// ComponentPicker imports both the table and the row so we get a cycle
// If this becomes a problem we can split it into multiple "pickers" (ajb)
// eslint-disable-next-line import/no-cycle
import { SaveState } from '@monorepo/shared/types/SaveState';
import { withLogTemplate } from '../../../../hooks/genericLogs/useCustomLogTemplates';
import {
  getIsFetching,
  getLoggedItemGroups,
  getLoggedItems,
} from '../../../../selectors/genericLogs';
import withProvider from '../../../withProvider';
import LoggedItemsTable from './LoggedItemsTable';

class LoggedItemsContainer extends Component {
  constructor(props) {
    super(props);
    this.formSubmissionDraftObject = {
      frequency: null,
      customFrequency: null,
      groupId: null,
      isLocked: false,
      itemType: null,
      location: null,
      name: null,
      notes: null,
      parameter: null,
      selectTypeOptions: [],
      units: null,
      userDefinedId: null,
    };
    this.initialState = {
      formErrors: {
        displayable: [],
      },
      formSubmissionDraft: {
        groups: {},
      },
      formSaveState: SaveState.CLEAN,
      deletedGroups: [],
      itemsToUpsert: [],
      itemsToDelete: [],
      isWaterLog:
        props.logTemplate.type === CustomLogType.stormwater ||
        props.logTemplate.type === CustomLogType.water,
    };
    this.state = this.initialState;
    this.addLoggedItem = this.addLoggedItem.bind(this);
    this.deleteLoggedItem = this.deleteLoggedItem.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.reorderCollectionInDragDropContext =
      this.reorderCollectionInDragDropContext.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const { fetchEmissionItemGroups, fetchEmissionLoggedItems, logProjectId } =
      this.props;
    const { projectId } = this.context;
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchEmissionLoggedItems(projectId, logProjectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      itemGroups,
      loggedItems,
      setHasSubmittedForm,
      shouldSubmitForm,
    } = this.props;

    const { formSubmissionDraft, deletedGroups } = prevState;

    const hasFetchedLoggedItems =
      (prevProps.isFetching && !isFetching) ||
      !_.isEqual(loggedItems, prevProps.loggedItems);
    if (!prevProps.shouldSubmitForm && shouldSubmitForm) {
      setHasSubmittedForm();
      Promise.resolve(this.validateFormSubmission()).then(this.submitForm);
    }

    if (
      Object.keys(itemGroups).length >
      Object.keys(formSubmissionDraft.groups).length
    ) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (!nextFormSubmissionDraft.groups[id]) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (!_.isEqual(itemGroups, prevProps.itemGroups)) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (
          !deletedGroups.includes(id) &&
          !nextFormSubmissionDraft.groups[id]
        ) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (hasFetchedLoggedItems) {
      const nextLoggedItems = loggedItems.reduce(
        (acc, loggedItem) =>
          update(acc, {
            [loggedItem.groupId]: (group) =>
              group
                ? update(group, {
                    $push: [{ ...loggedItem, renderKey: loggedItem.id }],
                  })
                : [{ ...loggedItem, renderKey: loggedItem.id }],
          }),
        {},
      );

      return this.setState((ps) =>
        update(ps, {
          formSubmissionDraft: {
            groups: {
              $merge: nextLoggedItems,
            },
          },
        }),
      );
    }
  }

  handleDeleteGroup(groupId) {
    const { deleteEmissionItemGroup, logProjectId, fetchEmissionLoggedItems } =
      this.props;
    const { projectId } = this.context;
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              $set: _.omit(prevState.formSubmissionDraft.groups, groupId),
            },
          },
          deletedGroups: {
            $push: [groupId],
          },
        }),
      async () => {
        await deleteEmissionItemGroup(projectId, groupId);
        await fetchEmissionLoggedItems(projectId, logProjectId);
      },
    );
  }

  setFormSaveState(formSaveState) {
    const { setFormSaveState } = this.props;
    setFormSaveState(formSaveState);
  }

  deleteLoggedItem(loggedItemIdx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemId = formSubmissionDraft.groups[groupId][loggedItemIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                $splice: [[loggedItemIdx, 1]],
              },
            },
          },
          itemsToDelete: {
            $push: [deletedItemId],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  reorderCollectionInDragDropContext(nextFormSubmissionDraft, itemToUpsert) {
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            $set: nextFormSubmissionDraft,
          },
          itemsToUpsert: {
            $push: [itemToUpsert],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  stageEdit(editsToMake, editsLocation) {
    const { editField, nextValue } = editsToMake;
    const { loggedItemIdx, groupId } = editsLocation;
    const { formSubmissionDraft } = this.state;
    const editedId = formSubmissionDraft.groups[groupId][loggedItemIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [loggedItemIdx]: {
                  $merge: {
                    [editField]: nextValue === '' ? null : nextValue,
                  },
                },
              },
            },
          },
          itemsToUpsert: {
            $push: [editedId],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  addLoggedItem(groupId = null) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: (n) =>
              update(n || [], {
                $push: [
                  {
                    ...this.formSubmissionDraftObject,
                    renderKey: uuidv4(),
                    groupId,
                  },
                ],
              }),
          },
        },
      }),
    );
  }

  validateFormSubmission() {
    const { formSubmissionDraft, isWaterLog } = this.state;
    const formErrors = {
      displayable: [],
      groups: {},
    };

    const requiredFields = isWaterLog
      ? [
          {
            field: 'location',
            message: 'Discharge location is required.',
          },
          {
            field: 'parameter',
            message: 'Parameter is required.',
          },
          {
            field: 'units',
            message: 'Units is required.',
          },
          {
            field: 'frequency',
            message: 'Frequency is required.',
          },
        ]
      : [
          {
            field: 'name',
            message: 'Name is required.',
          },
          {
            field: 'itemType',
            message: 'Type is required.',
          },
          {
            field: 'units',
            message: 'Units is required.',
          },
          {
            field: 'frequency',
            message: 'Frequency is required.',
          },
        ];

    _.map(formSubmissionDraft.groups, (group, groupId) => {
      formErrors.groups[groupId] = {};
      _.map(group, (loggedItem, loggedItemId) => {
        requiredFields.forEach((rf) => {
          const field = loggedItem[rf.field];
          if (
            isNullOrUndefined(field) ||
            (Array.isArray(field) && !field.length)
          ) {
            if (
              rf.field === 'units' &&
              loggedItem.itemType !== GenericLogLoggedItemType.NUMBER
            ) {
              return;
            }
            const errorMessage = `${
              loggedItem.name ? `${loggedItem.name}: ` : ''
            }${rf.message}`;
            formErrors.groups[groupId] = {
              ...formErrors.groups[groupId],
              [loggedItemId]: {
                ...formErrors.groups[groupId][loggedItemId],
                [rf.field]: errorMessage,
              },
            };
            formErrors.displayable.push(errorMessage);
          }
        });
      });
    });

    return this.setState((prevState) =>
      update(prevState, {
        formErrors: {
          $set: {
            ...formErrors,
            displayable: formErrors.displayable.sort(),
          },
        },
      }),
    );
  }

  upsertItem(loggedItem) {
    const { logProjectId, upsertEmissionLoggedItem } = this.props;
    const { projectId } = this.context;
    upsertEmissionLoggedItem(projectId, {
      ...loggedItem,
      logProjectId,
    });
  }

  formatAndUpsertLoggedItem(loggedItem) {
    const { isWaterLog } = this.state;

    if (!isWaterLog) {
      return this.upsertItem(loggedItem);
    }

    // Multi-select sets this as an array. We need to save one record per item in the array
    if (Array.isArray(loggedItem.location)) {
      loggedItem.location.forEach((dischargeLocation) => {
        this.upsertItem({
          ...loggedItem,
          itemType: GenericLogLoggedItemType.NUMBER,
          location: dischargeLocation.value,
        });
      });
      return;
    }

    // Once a water logged item has been saved we disable the multi-select
    this.upsertItem({
      ...loggedItem,
      itemType: GenericLogLoggedItemType.NUMBER,
      // if we modified the discharge location this will be a DischargeLocationOption and have
      // a value. If it wasn't modified it'll just be a uuid string
      location: loggedItem.location.value ?? loggedItem.location,
    });
  }

  submitGroup(group) {
    const { itemsToUpsert } = this.state;
    group.forEach((loggedItem) => {
      if (itemsToUpsert.includes(loggedItem.id)) {
        this.formatAndUpsertLoggedItem(loggedItem);
      }
    });
  }

  submitForm() {
    const { formErrors, itemsToDelete, formSubmissionDraft } = this.state;
    const { deleteEmissionLoggedItem, setHasSubmittedForm } = this.props;
    const { projectId } = this.context;

    if (formErrors.displayable.length !== 0) {
      return;
    }
    _.map(formSubmissionDraft.groups, (group) => this.submitGroup(group));
    itemsToDelete.forEach((id) => deleteEmissionLoggedItem(projectId, id));
    this.setState({
      itemsToUpsert: [],
      itemsToDelete: [],
    });
    setHasSubmittedForm();
    this.setFormSaveState(SaveState.SAVED);
  }

  render() {
    const { formErrors, formSubmissionDraft } = this.state;

    const orderedFormSubmissionDraft = {
      groups: {
        null: _get(formSubmissionDraft, 'groups.null', []),
        ..._.omit(formSubmissionDraft.groups, 'null'),
      },
    };

    const {
      isFetching,
      itemGroups,
      loggedItems,
      logProjectId,
      selectMenuPortalRef,
    } = this.props;
    return (
      <LoggedItemsTable
        addLoggedItem={this.addLoggedItem}
        deleteLoggedItem={this.deleteLoggedItem}
        formErrors={formErrors}
        formSubmissionDraft={orderedFormSubmissionDraft}
        handleDeleteGroup={this.handleDeleteGroup}
        isLoading={isFetching}
        itemGroups={itemGroups}
        loggedItems={loggedItems}
        logProjectId={logProjectId}
        onDragEnd={this.reorderCollectionInDragDropContext}
        selectMenuPortalRef={selectMenuPortalRef}
        stageEdit={this.stageEdit}
      />
    );
  }
}

LoggedItemsContainer.propTypes = {
  deleteEmissionItemGroup: PropTypes.func.isRequired,
  deleteEmissionLoggedItem: PropTypes.func.isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  setFormSaveState: PropTypes.func.isRequired,
  setHasSubmittedForm: PropTypes.func.isRequired,
  shouldSubmitForm: PropTypes.bool.isRequired,
  upsertEmissionLoggedItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  return {
    isFetching: getIsFetching(state, 'airEmissionsLoggedItems'),
    loggedItems: getLoggedItems(state, logProjectId),
    itemGroups: getLoggedItemGroups(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertEmissionLoggedItem: (projectId, loggedItem) =>
    loggedItem.id
      ? dispatch(updateEmissionLoggedItemAction(projectId, loggedItem))
      : dispatch(createEmissionLoggedItemAction(projectId, loggedItem)),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  deleteEmissionLoggedItem: (projectId, id) =>
    dispatch(
      deleteEmissionLoggedItemAction(projectId, ownProps.logProjectId, id),
    ),
  deleteEmissionItemGroup: (projectId, id) =>
    dispatch(
      deleteEmissionItemGroupAction(projectId, ownProps.logProjectId, id),
    ),
});

LoggedItemsContainer.contextType = ProjectContext;
export default withProvider(
  withLogTemplate(
    connect(mapStateToProps, mapDispatchToProps)(LoggedItemsContainer),
  ),
);
