import apiCaller from '@monorepo/old-web/js/apiCaller';
import APP from '@monorepo/old-web/js/config';
import template from './impersonatingUser.pug';

const ImpersonatingUserView = Backbone.Marionette.ItemView.extend({
  template,
  ui: {
    $stopImpersonating: '.stop-impersonating',
  },
  events: {
    'click @ui.$stopImpersonating': 'stopImpersonating',
  },
  initialize() {
    this.model = new Backbone.Model();
    this.listenTo(APP.vent, 'impersonating-user', (email) => {
      this.model.set({ email });
      this.render();
    });
  },
  onRender() {
    this.$el.toggle(!!this.model.get('email'));
  },
  async stopImpersonating(e) {
    e.preventDefault();
    await apiCaller.stopImpersonating();
    window.location.reload();
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ImpersonatingUserView;
