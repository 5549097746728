import { Loading } from '@monorepo/shared/components';
import { useWastewaterMonitoringLocations } from '@monorepo/shared/hooks/useMapFeatures';
import React from 'react';
import RecordPanelListItemExpandable from '../../../views/projectDashboard/RecordPanelListItemExpandable';
import { WastewaterParameterLocationCalendarCard } from '../../../views/projectDashboard/WastewaterSamplingCalendarCard/WastewaterSamplingCalendarCard';

export const SamplingResultsLocationList = ({
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  projectId,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  parameterSlug,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  openResultsEditModal,
}) => {
  const { isLoading, isError, monitoringLocations } =
    useWastewaterMonitoringLocations({
      projectId,
    });

  if (isLoading) {
    <div style={{ paddingTop: '50px' }}>
      <Loading size="medium" />
    </div>;
  }

  if (isError) {
    return (
      <div style={{ padding: '50px' }}>
        An error happened while loading. Try refreshing the page or contacting
        customer support.
      </div>
    );
  }

  if (!monitoringLocations?.length) {
    // eslint-disable-next-line no-console
    console.log(
      "You shouldn't have gotten here, it is required to add a monitoring location before being able to add sampling parameters",
    );
    return null;
  }

  const sortedMonitoringLocations = monitoringLocations.sort(
    (location1, location2) => location1.name.localeCompare(location2.name),
  );

  return (
    <div>
      {sortedMonitoringLocations?.map((location) => (
        <RecordPanelListItemExpandable
          key={location.id}
          summary={<div>{location.name}</div>}
          details={
            <WastewaterParameterLocationCalendarCard
              projectId={projectId}
              locationId={location.id}
              parameterSlug={parameterSlug}
              openResultsEditModal={openResultsEditModal}
            />
          }
        />
      ))}
    </div>
  );
};
