import React, { memo, useEffect, useState } from 'react';
import { Tabs } from '../../elements';
import {
  CustomLogTemplateSection,
  CustomLogTemplateSectionType,
  useCustomLogTemplates,
} from '../../../hooks/genericLogs/useCustomLogTemplates';

interface GenericLogTrackingViewTabsProps {
  isTabsLoading: boolean;
  onTabCreate: (tab: CustomLogTemplateSection | undefined) => void;
  onTabDelete: (tab: CustomLogTemplateSection) => void;
  onTabRename: (tab: CustomLogTemplateSection) => void;
  onTabSelect: (tab: CustomLogTemplateSection) => void;
  selectedTab: CustomLogTemplateSection;
  tabs: CustomLogTemplateSection[];
}

export const GenericLogTrackingViewTabs = memo(
  ({
    isTabsLoading,
    onTabCreate,
    onTabDelete,
    onTabRename,
    onTabSelect,
    selectedTab,
    tabs,
  }: GenericLogTrackingViewTabsProps) => {
    const { view: logTemplateView } = useCustomLogTemplates();
    const { availableSections, requiredSections } = logTemplateView;
    const [hasFetchedTabs, setHasFetchedTabs] = useState<boolean>(false);

    useEffect(() => {
      /**
       * This is a little bit of a hack. isTabsLoading is initially false and tabs is
       * initially an empty array. This will cause all required sections to be created
       * on the first render. This hack ensures that we fetch/load tabs at least once
       * before we attempt to create any required sections
       */
      if (isTabsLoading && !hasFetchedTabs) {
        setHasFetchedTabs(true);
      }
      requiredSections.forEach((section) => {
        if (
          hasFetchedTabs &&
          !isTabsLoading &&
          !tabs.some((tab) => tab.type === section.type)
        ) {
          // Bypass handleCreateTab because required tabs may not be allowed to be user created
          onTabCreate(section);
        }
      });
    }, [
      hasFetchedTabs,
      isTabsLoading,
      onTabCreate,
      requiredSections,
      setHasFetchedTabs,
      tabs,
    ]);

    /**
     * TODO: For now we only have 1 type of tab that we can create. In the future
     * we'll probably need to pop a modal window here to select a tabType from the
     * availableSections
     */
    const handleCreateTab = () => {
      const tabType = CustomLogTemplateSectionType.rawData;
      const newTab = availableSections.find((tab) => tab.type === tabType);
      if (newTab) {
        onTabCreate(newTab);
      }
    };

    return (
      <Tabs
        isLoading={isTabsLoading}
        options={tabs}
        onCreateTab={handleCreateTab}
        onDeleteTab={onTabDelete}
        onRenameTab={onTabRename}
        onSelectTab={onTabSelect}
        selected={selectedTab}
      />
    );
  },
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogTrackingViewTabs;
