var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (header, modalDialogClasses, modalMustStayOpen) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"modal fade\""+" tabindex=\"-1\" role=\"dialog\" style=\"overflow-y: auto\" aria-hidden=\"true\""+pug.attr("data-keyboard", modalMustStayOpen ? 'false' : 'true', true, true)+pug.attr("data-backdrop", modalMustStayOpen ? 'static' : 'true', true, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["modal-dialog",modalDialogClasses], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E";
if (!(modalMustStayOpen)) {
pug_html = pug_html + "\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E×\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Ch4 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = header) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger error-msg\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-success success-msg\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-content-placeholder\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "header" in locals_for_with ?
        locals_for_with.header :
        typeof header !== 'undefined' ? header : undefined, "modalDialogClasses" in locals_for_with ?
        locals_for_with.modalDialogClasses :
        typeof modalDialogClasses !== 'undefined' ? modalDialogClasses : undefined, "modalMustStayOpen" in locals_for_with ?
        locals_for_with.modalMustStayOpen :
        typeof modalMustStayOpen !== 'undefined' ? modalMustStayOpen : undefined));
    ;;return pug_html;};
module.exports = template;