var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isOpacitySelected) {
      pug_html = pug_html + "\u003Coption" + (pug.attr("selected", isOpacitySelected(0.1), true, true)) + "\u003E0.1\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.2), true, true)) + "\u003E0.2\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.3), true, true)) + "\u003E0.3\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.4), true, true)) + "\u003E0.4\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.5), true, true)) + "\u003E0.5\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.6), true, true)) + "\u003E0.6\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.7), true, true)) + "\u003E0.7\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.8), true, true)) + "\u003E0.8\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(0.9), true, true)) + "\u003E0.9\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isOpacitySelected(1), true, true)) + "\u003E1\u003C\u002Foption\u003E";
    }.call(this, "isOpacitySelected" in locals_for_with ?
        locals_for_with.isOpacitySelected :
        typeof isOpacitySelected !== 'undefined' ? isOpacitySelected : undefined));
    ;;return pug_html;};
module.exports = template;