var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (text, title) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade above-trip-overlay\" id=\"info-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"info-modal-label\" aria-hidden=\"true\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E×\u003C\u002Fbutton\u003E\u003Ch3 class=\"modal-title\" id=\"info-modal\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"text-center\"\u003E" + (null == (pug_interp = text) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success pull-right\" data-dismiss=\"modal\"\u003EClose\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;};
module.exports = template;