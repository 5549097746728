var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (numFeatures, text) {
      pug_html = pug_html + "\u003Ch4\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003Cspan\u003EContains " + (pug.escape(null == (pug_interp = numFeatures) ? "" : pug_interp)) + " features\u003C\u002Fspan\u003E";
    }.call(this, "numFeatures" in locals_for_with ?
        locals_for_with.numFeatures :
        typeof numFeatures !== 'undefined' ? numFeatures : undefined, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined));
    ;;return pug_html;};
module.exports = template;