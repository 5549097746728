/* eslint-disable */
var BaseDisplaySettingCollection = require('./baseDisplaySettingCollection');
var LayerDisplaySettingModel = require('./layerDisplaySettingModel');

var LayerDisplaySettingCollection = BaseDisplaySettingCollection.extend({
  model: LayerDisplaySettingModel,

  fetch: function (options) {
    return BaseDisplaySettingCollection.prototype.fetch.call(this, options);
  },

  onLayerIds: function () {
    return this.where({ layer_on: true }).map(function (lds) {
      return lds.get('layer_id');
    });
  },

  offLayerIds: function () {
    return this.where({ layer_on: false }).map(function (lds) {
      return lds.get('layer_id');
    });
  },

  displayedInLegendLayerIds: function () {
    return this.where({ layer_displayed_in_legend: true }).map(function (lds) {
      return lds.get('layer_id');
    });
  },
});

module.exports = LayerDisplaySettingCollection;
