/* eslint-disable */
const SimpleTextView = Backbone.Marionette.ItemView.extend({
  className: 'simple-text-view',
  template: require('./templates/simpleTextView.pug'),

  ui: {
    $input: 'input',
  },

  initialize({ content, handlerAccept }) {
    this.model = new Backbone.Model({ content });
    this.handlerAccept = handlerAccept;
  },

  edit() {
    this.model.set('editMode', true);
    return this.render();
  },

  accept(content) {
    this.model.set('editMode', false);
    if (content) {
      this.model.set('content', content);
    }
    if (this.handlerAccept) {
      this.handlerAccept(content);
    }
    return this.render();
  },

  getValue() {
    return this.ui.$input.val();
  },
});

module.exports = SimpleTextView;
