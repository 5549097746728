/* eslint-disable */
const apiCaller = require('../../../../../apiCaller');

let ProjectTagCollection = Backbone.Collection.extend({
  initialize(options = {}) {
    this.projectId = options.projectId;
  },

  url() {
    return apiCaller.getProjectTagsUrl(this.projectId);
  },
});

module.exports = ProjectTagCollection;
