/* eslint-disable */
import SiteBulkUploadLayoutView from './SiteBulkUploadLayoutView';
import UserBulkUploadLayoutView from './UserBulkUploadLayoutView';
import TaskBulkUploadLayoutView from './TaskBulkUploadLayoutView';

let NotificationsContentLayoutView = require('./NotificationsContentLayoutView');
let ContactContentLayoutView = require('./ProfileSettingsContentLayoutView');
let OrganizationSettingsContentLayoutView = require('./OrganizationSettingsContentLayoutView');
let User = require('./UserModel');
let P = require('bluebird');
let router = require('../../../router');

export default {
  create(slug, userId, adminOrganizations) {
    if (!userId) {
      let currentUser = require('../../../currentUser');
      return P.resolve(this._helper(slug, currentUser, adminOrganizations));
    }
    return new User({ id: userId })
      .fetchPromise()
      .then((user) => this._helper(slug, user, adminOrganizations))
      .catch((err) => router.goToErrorPage(null, err.responseText));
  },

  _helper(slug, user, adminOrganizations) {
    user.set('adminOrganizations', adminOrganizations);
    switch (slug) {
      case 'notifications':
        return new NotificationsContentLayoutView({ model: user });
      case 'profile':
        return new ContactContentLayoutView({ model: user });
      case 'organization':
        return new OrganizationSettingsContentLayoutView({ model: user });
      case 'site-bulk-upload':
        // Permission guard: this url is not accessible for non-super-admins
        if (user?.get('is_super_admin')) {
          return new SiteBulkUploadLayoutView();
        }
        break;
      case 'user-bulk-upload':
        // Permission guard: this url is not accessible for non-super-admins
        if (user?.get('is_super_admin')) {
          return new UserBulkUploadLayoutView();
        }
        break;
      case 'task-bulk-upload':
        // Permission guard: this url is not accessible for non-super-admins
        if (user?.get('is_super_admin')) {
          return new TaskBulkUploadLayoutView();
        }
        break;
    }
  },
};
