/* eslint-disable */
var apiCaller = require('../../../../../../../apiCaller');

var SamplingEventModel = Backbone.Model.extend({
  urlRoot() {
    return apiCaller.getSamplingEventsRootUrl(this.get('project_id'));
  },

  /**
   * Fetch is not supported because the show route required swpppStartYear as a query param.
   */
  fetch() {
    throw new Error('Fetch is not supported for this model.');
  },
});

module.exports = SamplingEventModel;
