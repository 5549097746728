/* eslint-disable */
var ChemicAttrsSelectView = require('../../../../../../../../featureManagement/ChemicalAttrsSelectView');

module.exports = Backbone.Marionette.CollectionView.extend({
  tagName: 'tbody',
  childView: require('./RowItemView'),

  onShow: function () {
    const collections = this.collection.toJSON();
    this.children.each((view, index) => {
      const childViewEl = view.el;
      const selectContainer = $(childViewEl).find('#select-container');
      const attributes = collections[index].chemicalAttributes;
      const chemicAttrsSelectView = new ChemicAttrsSelectView({
        chemicalAttributes: attributes,
        disabled: true,
      }).render();
      selectContainer.append(chemicAttrsSelectView.el);
    });

    // removes backbone collection tag to fix tbody nested tobdy
    this.$el.parent().html(this.el.innerHTML);
  },
});
