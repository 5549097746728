/* eslint-disable */
function WebMapDrawControl(options = {}) {
  // Set the button title text for the polyline button. Default is a little
  // confusing.
  L.drawLocal.draw.handlers.polyline.tooltip.end =
    'Double click last point to finish line.';

  // Set the button tooltip text so that the stuff you draw are called features.
  L.drawLocal.edit.toolbar.buttons.edit = 'Edit features';

  // Edit the text that is displayed on the cursor when you are editing.
  L.drawLocal.edit.handlers.edit.tooltip.text =
    'Drag vertices or markers to edit feature';

  // The options used to specify how features will be drawn and which drawing
  // controls will be available.
  var drawVisOptions = {
    polyline: {
      guidelineDistance: 10,
      shapeOptions: { color: '#FF6200' },
      metric: options.isMetric,
    },
    polygon: {
      // Restricts shapes to simple polygons
      allowIntersection: false,

      drawError: {
        // Color the shape will turn when intersects
        color: '#e1e100',

        // Message that will show when intersect
        message: 'Intersecting polygons are not allowed.',
      },

      shapeOptions: {
        color: '#bada55',
      },
    },
    marker: {
      shapeOptions: {},
    },
    circle: false, // Turns off circles in drawing tool
    rectangle: false, // Turns off rectangles in drawing tool
  };

  // Add the ability to draw lines, polygons and markers on the map
  var drawOptions = {
    position: 'topright',
    draw: drawVisOptions,
    edit: {
      markerClusterGroup: options.markerClusterGroup,
      polyGroup: options.polyGroup,
      remove: false,
    },
  };

  return new L.Control.Draw(drawOptions);
}

module.exports = WebMapDrawControl;
