import React from 'react';
import PropTypes from 'prop-types';

const DropPlaceholder = (props) => {
  const { groupName, isDragging } = props;
  if (isDragging) {
    return (
      <div className="drop-placeholder__container mapistry-table__row">
        <div className="drop-placeholder__copy">{`Drag item here to add to group ${groupName}`}</div>
      </div>
    );
  }
  return <div className="h10p bg-white__override" />;
};

DropPlaceholder.propTypes = {
  groupName: PropTypes.string,
  isDragging: PropTypes.bool.isRequired,
};

DropPlaceholder.defaultProps = {
  groupName: '',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DropPlaceholder;
