import { SaveState } from '@monorepo/shared/types/SaveState';
import update from 'immutability-helper';
import _get from 'lodash.get';
import { GenericLogLoggedItemType, GenericLogType } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import {
  createEmissionCalculatedValueAction,
  deleteEmissionCalculatedValueAction,
  deleteEmissionItemGroupAction,
  fetchEmissionCalculatedValuesAction,
  fetchEmissionFactorsAction,
  fetchEmissionItemGroupsAction,
  fetchEmissionLoggedItemsAction,
  fetchEmissionRollingCalculationsAction,
  fetchEmissionUnitConversionsAction,
  updateEmissionCalculatedValueAction,
} from '../../../../actions/air';
import ProjectContext from '../../../../contexts/ProjectContext';
import {
  getCalculatedValueGroups,
  getCalculatedValues,
  getEmissionFactors,
  getIsFetching,
  getLoggedItems,
  getRollingCalculations,
} from '../../../../selectors/genericLogs';
import { isNullOrUndefined } from '../../../../utils';
import { LoadingIndicator } from '../../../elements';
import withProvider from '../../../withProvider';
import CalculatedValuesTable from './CalculatedValuesTable';

class CalculatedValuesContainer extends Component {
  constructor(props) {
    super(props);
    this.factorObject = {
      name: null,
      units: null,
      calculationType: null,
      resourceId: null,
      resourceType: null,
    };
    this.formSubmissionDraftObject = {
      factors: [this.factorObject],
      groupId: null,
      name: null,
      notes: null,
      units: null,
    };
    this.initialState = {
      formErrors: {
        displayable: [],
      },
      formSubmissionDraft: {
        groups: {},
      },
      formSaveState: SaveState.CLEAN,
      deletedGroups: [],
      itemsToUpsert: [],
      itemsToDelete: [],
      referencedCalculatedValuesByRollingCalcs: {},
    };
    this.state = this.initialState;
    this.addCalculatedValue = this.addCalculatedValue.bind(this);
    this.addEquationFactor = this.addEquationFactor.bind(this);
    this.deleteCalculatedValue = this.deleteCalculatedValue.bind(this);
    this.deleteEquationFactor = this.deleteEquationFactor.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.refreshReferenceFlags = this.refreshReferenceFlags.bind(this);
    this.reorderCollectionInDragDropContext =
      this.reorderCollectionInDragDropContext.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const {
      fetchEmissionItemGroups,
      fetchEmissionCalculatedValues,
      fetchEmissionFactors,
      fetchEmissionLoggedItems,
      fetchEmissionRollingCalculations,
      fetchEmissionsUnitConversions,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchEmissionCalculatedValues(projectId, logProjectId);
    fetchEmissionFactors(projectId, logProjectId);
    fetchEmissionLoggedItems(projectId, logProjectId);
    fetchEmissionRollingCalculations(projectId, logProjectId);
    fetchEmissionsUnitConversions(projectId, logProjectId);
  }

  // eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      itemGroups,
      calculatedValues,
      rollingCalculations,
      setHasSubmittedForm,
      shouldSubmitForm,
    } = this.props;

    const { deletedGroups, formSubmissionDraft } = prevState;

    const hasFetchedCalculatedValues =
      (prevProps.isFetching && !isFetching) ||
      !_.isEqual(calculatedValues, prevProps.calculatedValues);

    if (!prevProps.shouldSubmitForm && shouldSubmitForm) {
      setHasSubmittedForm();
      this.validateFormSubmission().then(this.submitForm);
    }

    if (
      Object.keys(itemGroups).length >
      Object.keys(formSubmissionDraft.groups).length
    ) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (!nextFormSubmissionDraft.groups[id]) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (!_.isEqual(itemGroups, prevProps.itemGroups)) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (
          !deletedGroups.includes(id) &&
          !nextFormSubmissionDraft.groups[id]
        ) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (hasFetchedCalculatedValues) {
      const nextCalculatedValues = calculatedValues.reduce(
        (acc, calculatedValue) => {
          const nextFactors = [];
          for (let i = 0; i < calculatedValue.factors.length; i += 2) {
            nextFactors.push({
              ...calculatedValue.factors[i],
              ...calculatedValue.factors[i + 1],
            });
          }
          const nextCalculatedValue = {
            ...calculatedValue,
            factors: nextFactors,
          };
          return update(acc, {
            [calculatedValue.groupId]: (group) =>
              group
                ? update(group, {
                    $push: [
                      { ...nextCalculatedValue, renderKey: calculatedValue.id },
                    ],
                  })
                : [{ ...nextCalculatedValue, renderKey: calculatedValue.id }],
          });
        },
        {},
      );

      const referencedCalculatedValuesByRollingCalcs = {};
      rollingCalculations.forEach((rc) => {
        if (rc.resourceType === GenericLogType.CALCULATED_VALUE) {
          referencedCalculatedValuesByRollingCalcs[rc.resourceId] = true;
        }
      });

      return this.setState(
        (ps) =>
          update(ps, {
            formSubmissionDraft: {
              groups: {
                $merge: nextCalculatedValues,
              },
            },
            referencedCalculatedValuesByRollingCalcs: {
              $set: referencedCalculatedValuesByRollingCalcs,
            },
          }),
        () => {
          this.refreshReferenceFlags();
        },
      );
    }
  }

  handleDeleteGroup(groupId) {
    const {
      deleteEmissionItemGroup,
      fetchEmissionCalculatedValues,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;

    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              $set: _.omit(prevState.formSubmissionDraft.groups, groupId),
            },
          },
          deletedGroups: {
            $push: [groupId],
          },
        }),
      async () => {
        await deleteEmissionItemGroup(projectId, groupId);
        await fetchEmissionCalculatedValues(projectId, logProjectId);
      },
    );
  }

  setFormSaveState(formSaveState) {
    const { setFormSaveState } = this.props;
    setFormSaveState(formSaveState);
  }

  addEquationFactor(groupId, calculatedValueIdx) {
    return this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: {
              [calculatedValueIdx]: {
                factors: {
                  $push: [this.factorObject],
                },
              },
            },
          },
        },
      }),
    );
  }

  deleteCalculatedValue(idx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemId = formSubmissionDraft.groups[groupId][idx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                $splice: [[idx, 1]],
              },
            },
          },
          itemsToDelete: {
            $push: [deletedItemId],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
        this.refreshReferenceFlags();
      },
    );
  }

  deleteEquationFactor(groupId, calculatedValueIdx, factorIdx) {
    const { formSubmissionDraft } = this.state;
    const editedCalculatedValueId =
      formSubmissionDraft.groups[groupId][calculatedValueIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [calculatedValueIdx]: {
                  factors: {
                    $splice: [[factorIdx, 1]],
                  },
                },
              },
            },
          },
          itemsToUpsert: {
            $push: [editedCalculatedValueId],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
        this.refreshReferenceFlags();
      },
    );
  }

  reorderCollectionInDragDropContext(nextFormSubmissionDraft, itemToUpsert) {
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            $set: nextFormSubmissionDraft,
          },
          itemsToUpsert: {
            $push: [itemToUpsert],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  stageEdit(editsToMake, editsLocation) {
    const { editField, nextValue } = editsToMake;
    const { calculatedValueIdx, groupId, factorIdx } = editsLocation;
    const { formSubmissionDraft } = this.state;
    const editedId = formSubmissionDraft.groups[groupId][calculatedValueIdx].id;

    let nextValueToSet = {
      [editField]: nextValue === '' ? null : nextValue,
    };

    if (nextValue?.value) {
      const { value, resourceType, resourceId } = nextValue;
      nextValueToSet = {
        [editField]: value === '' ? null : value,
        resourceType,
        resourceId,
      };
    }
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [calculatedValueIdx]: (cv) => {
                  if (!isNullOrUndefined(factorIdx)) {
                    return update(cv, {
                      factors: {
                        [factorIdx]: {
                          $merge: nextValueToSet,
                        },
                      },
                    });
                  }
                  return update(cv, {
                    $merge: nextValueToSet,
                  });
                },
              },
            },
          },
          itemsToUpsert: {
            $push: [editedId],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
        this.refreshReferenceFlags();
      },
    );
  }

  addCalculatedValue(groupId = null) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: (n) =>
              update(n || [], {
                $push: [
                  {
                    ...this.formSubmissionDraftObject,
                    renderKey: uuidv4(),
                    groupId,
                  },
                ],
              }),
          },
        },
      }),
    );
  }

  refreshReferenceFlags() {
    const { formSubmissionDraft, referencedCalculatedValuesByRollingCalcs } =
      this.state;

    const referencedCalculatedValues = {
      ...referencedCalculatedValuesByRollingCalcs,
    };
    Object.values(formSubmissionDraft.groups).forEach((group) =>
      group.forEach((cv) => {
        cv.factors?.forEach((factor) => {
          if (factor.resourceType === GenericLogType.CALCULATED_VALUE) {
            referencedCalculatedValues[factor.resourceId] = true;
          }
        });
      }),
    );

    return this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: (groups) => {
            const newGroups = {};
            Object.entries(groups).forEach(([groupId, group]) => {
              newGroups[groupId] = group.map((cv) => ({
                ...cv,
                isReferenced: !!referencedCalculatedValues[cv.id],
              }));
            });
            return newGroups;
          },
        },
      }),
    );
  }

  validateFormSubmission() {
    const { formSubmissionDraft } = this.state;
    let formErrors = {
      displayable: [],
      groups: {},
    };

    const requiredFields = [
      {
        field: 'name',
        message: 'Name is required.',
      },
      {
        field: 'units',
        message: 'Units is required.',
      },
      {
        field: 'frequency',
        message: 'Equation needs to have at least one term with frequency.',
      },
    ];

    const factorsRequiredFields = [
      {
        field: 'name',
        message: 'Name is required.',
      },
      {
        field: 'units',
        message: 'Units is required.',
      },
      {
        field: 'calculationType',
        message: 'Calculation type is required.',
      },
    ];

    _.map(formSubmissionDraft.groups, (group, groupId) => {
      formErrors.groups[groupId] = {};
      _.map(group, (calculatedValue, calculatedValueId) => {
        const calculationName = calculatedValue.name
          ? `${calculatedValue.name}`
          : 'Unnamed calculation';
        requiredFields.forEach((rf) => {
          if (isNullOrUndefined(calculatedValue[rf.field])) {
            const errorMessage = `${calculationName}: ${rf.message}`;
            formErrors = update(formErrors, {
              groups: {
                [groupId]: {
                  [calculatedValueId]: (cv) =>
                    update(cv || {}, {
                      $merge: {
                        [rf.field]: errorMessage,
                      },
                    }),
                },
              },
            });
            formErrors.groups[groupId] = {
              ...formErrors.groups[groupId],
              [calculatedValueId]: {
                ...formErrors.groups[groupId][calculatedValueId],
                [rf.field]: errorMessage,
              },
            };
            formErrors.displayable.push(errorMessage);
          }
        });

        calculatedValue.factors.forEach((factor, factorIdx) => {
          factorsRequiredFields.forEach((factorRf) => {
            if (
              isNullOrUndefined(factor[factorRf.field]) &&
              // Do not require calculation type if this is the very last item in the list.
              !(
                factorRf.field === 'calculationType' &&
                factorIdx === calculatedValue.factors.length - 1
              )
            ) {
              const errorMessage = `${calculationName} (factor #${
                factorIdx + 1
              }): ${factorRf.message}`;
              formErrors = update(formErrors, {
                groups: {
                  [groupId]: {
                    [calculatedValueId]: (cv) =>
                      update(cv || {}, {
                        factors: (f) =>
                          update(f || [], {
                            [factorIdx]: (fi) =>
                              update(fi || {}, {
                                $merge: {
                                  [factorRf.field]: errorMessage,
                                },
                              }),
                          }),
                      }),
                  },
                },
              });
              formErrors.displayable.push(errorMessage);
            }
          });
        });
      });
    });

    return new Promise((res) => {
      this.setState(
        (prevState) =>
          update(prevState, {
            formErrors: {
              $set: {
                ...formErrors,
                displayable: formErrors.displayable.sort(),
              },
            },
          }),
        res,
      );
    });
  }

  submitForm() {
    const { formErrors, itemsToUpsert, itemsToDelete, formSubmissionDraft } =
      this.state;

    const {
      deleteEmissionCalculatedValue,
      logProjectId,
      setHasSubmittedForm,
      upsertEmissionCalculatedValue,
    } = this.props;
    const { projectId } = this.context;

    if (formErrors.displayable.length === 0) {
      _.map(formSubmissionDraft.groups, (group) => {
        group.forEach((calculatedValue) => {
          if (itemsToUpsert.includes(calculatedValue.id)) {
            upsertEmissionCalculatedValue(projectId, {
              ...calculatedValue,
              logProjectId,
            });
          }
        });
      });
      itemsToDelete.forEach((id) => {
        deleteEmissionCalculatedValue(projectId, id);
      });
      this.setState({
        itemsToUpsert: [],
        itemsToDelete: [],
      });
      setHasSubmittedForm();
      this.setFormSaveState(SaveState.SAVED);
    }
  }

  render() {
    const { formErrors, formSubmissionDraft } = this.state;

    const {
      isFetching,
      calculatedValues,
      emissionFactors,
      loggedItems,
      logProjectId,
      selectMenuPortalRef,
    } = this.props;

    const orderedFormSubmissionDraft = {
      groups: {
        null: _get(formSubmissionDraft, 'groups.null', []),
        ..._.omit(formSubmissionDraft.groups, 'null'),
      },
    };

    const { itemGroups } = this.props;

    if (isFetching) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      return <LoadingIndicator />;
    }

    return (
      <CalculatedValuesTable
        addCalculatedValue={this.addCalculatedValue}
        addEquationFactor={this.addEquationFactor}
        calculatedValues={calculatedValues}
        deleteCalculatedValue={this.deleteCalculatedValue}
        deleteEquationFactor={this.deleteEquationFactor}
        emissionFactors={emissionFactors}
        formErrors={formErrors}
        formSubmissionDraft={orderedFormSubmissionDraft}
        handleDeleteGroup={this.handleDeleteGroup}
        isLoading={isFetching}
        itemGroups={itemGroups}
        logProjectId={logProjectId}
        loggedItems={loggedItems}
        onDragEnd={this.reorderCollectionInDragDropContext}
        selectMenuPortalRef={selectMenuPortalRef}
        stageEdit={this.stageEdit}
      />
    );
  }
}

CalculatedValuesContainer.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteEmissionCalculatedValue: PropTypes.func.isRequired,
  deleteEmissionItemGroup: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchEmissionCalculatedValues: PropTypes.func.isRequired,
  fetchEmissionFactors: PropTypes.func.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  fetchEmissionRollingCalculations: PropTypes.func.isRequired,
  fetchEmissionsUnitConversions: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  setFormSaveState: PropTypes.func.isRequired,
  setHasSubmittedForm: PropTypes.func.isRequired,
  shouldSubmitForm: PropTypes.bool.isRequired,
  upsertEmissionCalculatedValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;

  const calculatedValues = getCalculatedValues(state, logProjectId);
  const emissionFactors = getEmissionFactors(state, logProjectId);
  const loggedItems = getLoggedItems(state, logProjectId);

  const calculatedValuesWithTypes = calculatedValues.map((cv) => ({
    ...cv,
    resourceType: GenericLogType.CALCULATED_VALUE,
  }));

  const emissionFactorsWithTypes = emissionFactors.map((factor) => ({
    ...factor,
    resourceType: GenericLogType.EMISSION_FACTOR,
  }));

  const loggedItemsWithTypes = loggedItems.map((item) => ({
    ...item,
    resourceType: GenericLogType.LOGGED_ITEM,
  }));

  const numericLoggedItemsWithTypes = loggedItemsWithTypes.filter(
    (item) => item.itemType === GenericLogLoggedItemType.NUMBER,
  );

  return {
    isFetching:
      getIsFetching(state, 'airEmissionsCalculatedValues') ||
      getIsFetching(state, 'airEmissionsLoggedItems') ||
      getIsFetching(state, 'airEmissionsEmissionFactors') ||
      getIsFetching(state, 'airEmissionsRollingCalculations'),
    calculatedValues: calculatedValuesWithTypes,
    emissionFactors: emissionFactorsWithTypes,
    loggedItems: numericLoggedItemsWithTypes,
    itemGroups: getCalculatedValueGroups(state, logProjectId),
    rollingCalculations: getRollingCalculations(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteEmissionItemGroup: (projectId, id) =>
    dispatch(
      deleteEmissionItemGroupAction(projectId, ownProps.logProjectId, id),
    ),
  upsertEmissionCalculatedValue: (projectId, calculatedValue) =>
    calculatedValue.id
      ? dispatch(
          updateEmissionCalculatedValueAction(projectId, calculatedValue),
        )
      : dispatch(
          createEmissionCalculatedValueAction(projectId, calculatedValue),
        ),
  fetchEmissionCalculatedValues: (projectId, logProjectId) =>
    dispatch(fetchEmissionCalculatedValuesAction(projectId, logProjectId)),
  fetchEmissionFactors: (projectId, logProjectId) =>
    dispatch(fetchEmissionFactorsAction(projectId, logProjectId)),
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  fetchEmissionsUnitConversions: (projectId, logProjectId) =>
    dispatch(fetchEmissionUnitConversionsAction(projectId, logProjectId)),
  fetchEmissionRollingCalculations: (projectId, logProjectId) =>
    dispatch(fetchEmissionRollingCalculationsAction(projectId, logProjectId)),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  deleteEmissionCalculatedValue: (projectId, id) =>
    dispatch(
      deleteEmissionCalculatedValueAction(projectId, ownProps.logProjectId, id),
    ),
});
CalculatedValuesContainer.contextType = ProjectContext;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(CalculatedValuesContainer),
);
