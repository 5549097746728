/* eslint-disable */
// Collections
const layerCollection =
  require('../../models_collections/layerCollection').singleton();

// Services
const configureTipsy = require('../../lib/tipsyConfig');

const editLegendView = require('./editLegendView').singleton();
const TitlebarView = require('../titlebar/TitlebarView');

L.Control.Legend = L.Control.extend({
  options: {
    position: 'bottomleft',
  },

  /**
   * Formats the legend when added to the map
   *
   * Parameters:
   *     map: Leaflet map
   *         The map that the legend is being added to.
   *
   * Return:
   *     container: DOM element
   *       The formatted legend
   */
  onAdd: function () {
    var template = require('./legend.pug')();

    this._$container = $(template);
    this._$layersContainer = this._$container.find('.layers-container');
    this._legendItemTemplate = require('./legendItem.pug');
    this._$container.on(
      'click',
      '.fa-chevron-down',
      this._closeLegend.bind(this, false),
    );
    this._$container.on(
      'click',
      '.fa-chevron-up',
      this._openLegend.bind(this, false),
    );
    this._$container.on(
      'click',
      '#edit-legend',
      this.toggleEditMode.bind(this),
    );
    this._$container.on(
      'click',
      '#legend-configuration',
      this.showModal.bind(this),
    );
    this._$titlebar = this._$container.find('#titlebar');
    this._$northArrow = this._$container.find('#north-arrow');
    this._$mapArea = $('#map-area');

    return this._$container[0];
  },

  /** This is used to populate the legend with data
   *
   * Parameters:
   *     options: obj
   *        layerCollection: Backbone.Collection
   *          All the layers in the current project
   *        mapModel: Backbone.Model
   *          The currently selected map view
   *        customIcons: obj
   *          Custom icon available
   */
  render: function (options) {
    var APP = require('../../config');
    if (APP.environment !== 'development') {
      // Heroku uses a backup font when taking a screenshot with PhantomJS
      // which causes the alignment of the arrows to be a little off.
      // This is a hack to fix the alignment.
      // TODO: Figure out a better way to do this.
      this._$container.addClass('fix-heroku-arrow-button-alignment');
    }

    this._$container.addClass('active');
    this.disableEditMode();
    this._$layersContainer.empty();
    this.mapModel = options.mapModel;
    this._showTitlebar(this.mapModel);

    if (layerCollection.length > 0) {
      const legendFontSize = this.mapModel.get('legend_font_size');
      this._$layersContainer.css({ 'font-size': `${legendFontSize}px` });

      this.mapModel
        .onLayers()
        .sort()
        .forEach(function (layer) {
          if (layer.get('type') === 'aerialimagery') {
            return;
          }

          var layerLabel = this._getLayerLabelForLegendDisplay(
            layer,
            options.isFederalProject,
          );
          var style = layer.style();
          var layerOptions = {
            layerName: layer.get('name'),
            layerLabel: layerLabel,
            style: style,
            color: this._hexToRgba(style.get('color'), style.get('opacity')),
            hidden:
              this.mapModel.isLayerDisplayedInLegend(layer.id) &&
              !layer.isEmpty()
                ? ''
                : 'turned-off',
          };

          this._renderLayer(layer, layerOptions);
        }, this);

      if (this.mapModel.get('legend_displayed')) {
        this._openLegend(true);
      } else {
        this._closeLegend(true);
      }
    } else {
      this._$container.removeClass('active');
      this._$container.removeClass('open');
      this._$layersContainer.slideUp();
    }

    // make the tooltips pretty
    if (this._$container) {
      configureTipsy(this._$container.find('[rel=tipsy]'));
    }
  },

  _getLayerLabelForLegendDisplay: function (layer, isFederalProject) {
    var layerName = layer.get('name');

    if (!isFederalProject || !layer.isSiteBoundaryLayer()) {
      return layerName;
    }

    var squareMetersInOneAcre = 4046.86;
    var areaInAcres = (
      layer.calculateTotalAreaInSquareMeters() / squareMetersInOneAcre
    ).toFixed(2);

    return layerName + ' (' + areaInAcres + ' acres)';
  },

  /**
   * Enables edit modes
   */
  enableEditMode: function () {
    this._$container.on(
      'click',
      '.symbol-container',
      this._toggleHide.bind(this),
    );
    this._$container.on('click', '.layer-name', this._toggleHide.bind(this));
    this._$container.addClass('edit');
  },

  /**
   * Disables edit mode
   */
  disableEditMode: function () {
    this._$container.off('click', '.symbol-container');
    this._$container.off('click', '.layer-name');
    this._$container.removeClass('edit');
  },

  /**
   * Toggles between normal and edit mode
   */
  toggleEditMode: function () {
    if (this._$container.hasClass('edit')) {
      this.disableEditMode();
    } else {
      this.enableEditMode();
    }
  },

  showModal: function () {
    editLegendView.showModal();
  },

  _showTitlebar: function (mapModel) {
    const titlebarModel = mapModel.titlebar();
    if (!this.titlebarView) {
      this.titlebarView = new TitlebarView({ model: titlebarModel });
    }
    this.titlebarView.render();
  },

  /**
   * Renders a layer within the legend
   *
   * Parameters:
   *     layer: Backbone.Model instance
   *     options: obj
   */
  _renderLayer: function (layer, options) {
    if (layer.containsMarkers()) {
      this._$layersContainer.append(this._prepMarkerTemplate(options));
    } else if (layer.containsPolylines()) {
      this._$layersContainer.append(this._prepPolylineTemplate(options));
    } else if (layer.containsPolygons()) {
      this._$layersContainer.append(this._prepPolygonTemplate(options));
    }
  },

  /**
   * Toggles the hidden status of a layer in the legend
   */
  _toggleHide: function (e) {
    var $legendItem = $(e.target).closest('.legend-item');
    var layerName = $legendItem.find('[data-layer-name]').data('layer-name');
    var layer = layerCollection.findWhere({ name: layerName });

    // Was going to use the class hide or hidden, but both are used by bootstrap
    // to hide elements. To avoid confusion I made a class called turned-off.
    if ($legendItem.hasClass('turned-off')) {
      $legendItem.removeClass('turned-off');
    } else {
      $legendItem.addClass('turned-off');
    }

    this.mapModel.toggleLayerDisplayedInLegend(layer);
  },

  /**
   * Shows the legend
   *
   * Parameters:
   *      skipUpdate: boolean
   *          If true, just open the legend, do not attempt to update the
   *          the this.mapModel attribute indicating whether or not the legend is
   *          visiblex
   */
  _openLegend: function (skipUpdate) {
    this._$container.addClass('open');

    this._$titlebar.removeClass('hidden');
    this._$mapArea.removeClass('hide-scale');
    this._$northArrow.removeClass('hidden');
    this._$container.find('#edit-legend').removeClass('hidden');
    this._$container.find('#legend-configuration').removeClass('hidden');

    this._$container.css('background-color', 'white');
    this._$container.parent().css('background-color', 'white');
    if (!skipUpdate) {
      this.mapModel.save({ legend_displayed: true });
    }
  },

  /**
   * Hides the legend
   *
   * Parameters:
   *      skipUpdate: boolean
   *          If true, just open the legend, do not attempt to update the
   *          the this.mapModel attribute indicating whether or not the legend is
   *          visible
   */
  _closeLegend: function (skipUpdate) {
    this._$container.removeClass('open');

    this._$titlebar.addClass('hidden');
    this._$mapArea.addClass('hide-scale');
    this._$northArrow.addClass('hidden');
    this._$container.find('#edit-legend').addClass('hidden');
    this._$container.find('#legend-configuration').addClass('hidden');

    this._$container.css('background-color', 'transparent');
    this._$container.parent().css('background-color', 'transparent');

    if (!skipUpdate) {
      this.mapModel.save({ legend_displayed: false });
    }
    this.disableEditMode();
  },

  /**
   * Prepares template for markers
   *
   * Parameters:
   *      options: object
   *        layerName: 'string'
   *          The name of the layer
   *        layerLabel: 'string'
   *          The label to display for this layer
   *        style: Backbone.Model instance
   *        color: 'string'
   *          The hex value color for the layer
   *        hidden: string
   *          The class name to denote that this layer is turned off if the user
   *          chose to turn this layer off. Empty string if layer is not turned
   *          off
   *
   * Return:
   *     HTML to ready to be appended to the legend
   */
  _prepMarkerTemplate: function (options) {
    var icon = options.style.getIcon();
    return this._legendItemTemplate({
      layerName: options.layerName,
      layerLabel: options.layerLabel,
      color: options.color,
      shape: options.style.get('shape'),
      hidden: options.hidden,
      customIconUrl: icon ? icon.url() : null,
    });
  },

  /** Prepares template for polylines
   *
   * Parameters:
   *      options: object
   *        layerName: 'string'
   *          The name of the layer
   *        layerLabel: 'string'
   *          The label to display for this layer
   *        styles: object
   *          Contains the style attributes for the layer
   *        color: 'string'
   *          The hex value color for the layer
   *        hidden: string
   *          The class name to denote that this layer is turned off if the user
   *          chose to turn this layer off. Empty string if layer is not turned
   *          off
   *
   * Return:
   *     HTML to ready to be appended to the legend
   */
  _prepPolylineTemplate: function (options) {
    var icon = options.style.getIcon();
    var shapes = {
      arrow: 'triangle-right',
      arrowMultiple: 'triangle-right',
      arrowReverse: 'triangle-left',
      arrowReverseMultiple: 'triangle-left',
      circles: 'circle',
      squares: 'square',
      pluses: 'plus',
      asterisks: 'asterisk',
    };
    var decorator = icon
      ? 'custom-icon-decorator'
      : options.style.get('decorator');
    let lineColor =
      options.color.slice(0, 16) !== 'rgba(255,255,255'
        ? options.color
        : 'rgba(222,222,222' + options.color.slice(16); // Convert White to LightGray
    return this._legendItemTemplate({
      layerName: options.layerName,
      layerLabel: options.layerLabel,
      color: lineColor,
      shape: shapes[options.style.get('decorator')],
      decoratorColor: options.style.get('color'),
      decorator: decorator,
      lineStyle: this._lineStyle(options.style.get('dash_array')),
      type: 'polyline',
      hidden: options.hidden,
      customIconUrl: icon ? icon.url() : null,
    });
  },

  /**
   * Prepares template for polygons
   *
   * Parameters:
   *      options: object
   *        layerName: 'string'
   *          The name of the layer
   *        layerLabel: 'string'
   *          The label to display for this layer
   *        styles: object
   *          Contains the style attributes for the layer
   *        color: 'string'
   *          The hex value color for the layer
   *        hidden: string
   *          The class name to denote that this layer is turned off if the user
   *          chose to turn this layer off. Empty string if layer is not turned
   *          off
   *
   * Return:
   *     HTML to ready to be appended to the legend
   */
  _prepPolygonTemplate: function (options) {
    var backgroundColor = this._hexToRgba(
      options.style.get('color'),
      options.style.get('fill_opacity'),
    );

    let fillColor =
      backgroundColor.slice(0, 16) !== 'rgba(255,255,255'
        ? backgroundColor
        : this._hexToRgba(
            '#EFEFEF',
            options.style.get('fill_opacity'), // Convert White to LightGray
          );
    let borderColor =
      options.color.slice(0, 16) !== 'rgba(255,255,255'
        ? options.color
        : 'rgba(222,222,222' + options.color.slice(16); // Convert White to LightGray

    return this._legendItemTemplate({
      layerName: options.layerName,
      layerLabel: options.layerLabel,
      color: borderColor,
      lineStyle: this._lineStyle(options.style.get('dash_array')),
      backgroundColor: fillColor,
      type: 'polygon',
      hidden: options.hidden,
    });
  },

  _lineStyle: function (dashArray) {
    return dashArray ? 'dashed' : 'solid';
  },

  /**
   * This is used to convert the hex color to an rgba value. This is needed so that
   *  the opacity of the line is not inherited by the layer names and symbols.
   *
   * Parameters:
   *     hex: string
   *        The hex value of a string e.g. '#0000ff'
   *     opacity: string
   *        The opacity e.g. '0.5'
   */
  _hexToRgba: function (hex, opacity) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex),
      r = parseInt(result[1], 16),
      g = parseInt(result[2], 16),
      b = parseInt(result[3], 16);

    return result
      ? 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
      : null;
  },
});

module.exports = L.Control.Legend;
