/* eslint-disable */
let apiCaller = require('../apiCaller');
let currentUser = require('../currentUser');
let APP = require('../config');

let SetRecoveryQuestionView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/setRecoveryQuestion.pug'),

  templateHelpers() {
    let questionOptions = [
      '',
      'What is the first and last name of your first boyfriend or girlfriend?',
      'What is the first name of the person you first kissed?',
      'To what city did you go the first time you flew on a plane?',
      'What is the first name of your best friend in high school?',
      'What is the first name of your oldest nephew?',
      'What is the first name of your oldest niece?',
      "What is your oldest cousin's first name?",
      'What was the first name of the first person you dated?',
      'What was the first name of your favorite childhood friend?',
      'What was the last name of your third grade teacher?',
      'What was the street name where your best friend in high school lived (street name only)?',
      'Where were you when you first heard about 9/11?',
      'How much did you pay for your first car?',
      'In what city or town did you spend your honeymoon?',
      'In what city or town did your parents meet?',
      'In what city or town was your first job?',
      'In which city did you first study abroad?',
      "What is the first name of your eldest cousin on your father's side?",
      "What is the first name of your eldest cousin on your mother's side?",
      'What is the last name of the teacher who gave you your first failing grade?',
      'What is the last name of your first boss?',
      'What is the last name of your most memorable childhood neighbors?',
      'What is the last name of your very first Best Friend Forever?',
      'What is the name of the first beach you visited?',
      "What is your grandmother's (on your mother's side) maiden name?",
      "What is your oldest child's nickname?",
      "What is your oldest sibling's birthday month and year? (e.g., January 1900)",
      'What was the first album that you purchased?',
      'What was the last name of your favorite childhood teacher?',
      'What was the last name of your favorite elementary school teacher?',
      'What was the last name of your favorite high school teacher?',
      'What was the make and model of your first car?',
      'What was the name of the company where you had your first job?',
      'What was the name of the street where you were living when you were 10 years old?',
      'What was the name of the street where you were living when you were in third grade?',
      'What was your childhood nickname?',
      'What was your favorite vacation place as a child?',
      "What you would have named your first child but didn't?",
      'What is the zip code where you grew up?',
    ];
    return { questionOptions };
  },

  ui: {
    $recoveryQuestionInput: '[name=recovery-question]',
    $recoveryAnswerInput: '[name=recovery-answer]',
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
    $btnSubmit: '[type=submit]',
  },

  behaviors: {
    DisplayMessages: {},
    DisplayLoader: {},
  },

  events: {
    'submit form': 'setRecoveryQuestion',
  },

  onRender: function () {
    $('html > body').attr('data-view', 'not-authenticated');
  },

  setRecoveryQuestion: function (e) {
    let router = require('../router');
    e.preventDefault();
    let question = this.ui.$recoveryQuestionInput.val().trim();
    let answer = this.ui.$recoveryAnswerInput.val().trim();

    this.triggerMethod('ShowLoader');
    apiCaller
      .setRecoveryQuestion(question, answer)
      .bind(this)
      .then(() => (APP.recoveryQuestionSet = question))
      .then(() => router.goToRootUrl())
      .catch((error) => this.triggerMethod('ShowError', error))
      .finally(() => this.triggerMethod('ResetLoader'));
  },
});

module.exports = SetRecoveryQuestionView;
