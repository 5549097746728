import React from 'react';
import { format } from 'date-fns';
import { IconButton, SvgIcon, Tooltip } from '@monorepo/shared/components';

interface FormSubmissionLockInfoProps {
  currentUserId?: string;
  requestPageReloadBeforeYieldingFormLock: boolean;
  lockInfo?: {
    lockedBy: {
      id: string;
      name: string;
      email: string;
    };
    expiresAt: string;
  };
  isStencil: boolean;
}

const MoreInfoTooltip = ({ infoText }: { infoText: string }) => (
  <Tooltip title={infoText}>
    <div>
      <IconButton>
        <SvgIcon identifier="information" className="main-lock--extra-info" />
        <span className="visually-hidden">More Info on Form Lock</span>
      </IconButton>
    </div>
  </Tooltip>
);

export function FormSubmissionLockInfo(props: FormSubmissionLockInfoProps) {
  const {
    currentUserId,
    isStencil,
    lockInfo,
    requestPageReloadBeforeYieldingFormLock,
  } = props;
  if (!lockInfo || !currentUserId) {
    // this might happen because the lock info request hasn't finished yet, or because
    // the lock info request failed for users who don't have submission update permission
    return null;
  }

  const getContent = () => {
    const { lockedBy, expiresAt } = lockInfo;
    const formattedExpiresAt = format(new Date(expiresAt), 'h:mm aa');
    const subjectDescriptor = isStencil ? 'template' : 'form';

    if (requestPageReloadBeforeYieldingFormLock) {
      return (
        <div className="main-lock main-lock--locked" aria-live="polite">
          <span className="main-lock--warning-icon main-lock--warning-icon--locked">
            <div className="visually-hidden">Attention</div>!
          </span>
          Reload the page to fetch the most recent data before making changes.
          <MoreInfoTooltip
            infoText={`For the next 45 minutes (until ${formattedExpiresAt}), no one else will be able to edit this ${subjectDescriptor}. Please close the form when you are finished.`}
          />
        </div>
      );
    }

    if (lockedBy.id === currentUserId) {
      return (
        <div className="main-lock">
          <span className="main-lock--warning-icon">
            <div className="visually-hidden">Attention</div>!
          </span>
          No one else can edit this {subjectDescriptor} while you are filling it
          out. Please exit the form when you are finished to allow others to
          edit.
          <MoreInfoTooltip
            infoText={`For the next 45 minutes (until ${formattedExpiresAt}), no one else will be able to edit this ${subjectDescriptor}.
          As you move to new sections of this form, the 45-minute countdown will reset.
          If you exit this form or stay in the same section for more than 45 minutes, others will be able to edit the form.`}
          />
        </div>
      );
    }

    return (
      <div className="main-lock main-lock--locked">
        <span className="main-lock--warning-icon main-lock--warning-icon--locked">
          <div className="visually-hidden">Attention</div>!
        </span>
        {lockedBy.name} is currently editing this {subjectDescriptor}. You won’t
        be able to make edits while another person completes the form.
        <MoreInfoTooltip
          infoText={`
          You can view this ${subjectDescriptor}, but you cannot edit it while  ${lockedBy.name} is completing this form.
          If you need to edit the ${subjectDescriptor}, please contact ${lockedBy.email} and ask them to exit this form.
          Refresh this page to confirm whether or not you can edit.
          If you cannot get a hold of ${lockedBy.name}, the form will automatically become unlocked after they are inactive for 45 minutes.
        `}
        />
      </div>
    );
  };

  return (
    <div className={isStencil ? 'lock-info--stencil' : 'lock-info'}>
      {getContent()}
    </div>
  );
}
