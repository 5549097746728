import APP from '../config';

// this was copied from the main app
// many things were removed but this much was kept because
// we may miss subtle bugs without it
const BackboneWrapperLayoutView = Backbone.Marionette.LayoutView.extend({
  el: 'body',

  regions: {
    content: '#content-container',
  },

  events: {
    click: '_handleClick',
  },

  addClass(cssClassName) {
    this.$el.addClass(cssClassName);
  },

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-underscore-dangle
  _handleClick(e) {
    APP.vent.trigger('body:clicked', e);
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default new BackboneWrapperLayoutView();
