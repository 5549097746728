import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntervalFrequency } from 'mapistry-shared';

import APP from '../../../../../config';
import FlowReadingListItem from './FlowReadingListItem';
import {
  deleteFlowLogReading,
  fetchFlowLogStatsAction,
} from '../../../../../actions/wastewater';
import withProvider from '../../../../withProvider';

class FlowReadingListItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
    };
  }

  render() {
    const { showConfirmation } = this.state;
    const {
      flowLogLimits,
      flowReading,
      onDeleteFlowLogReading,
      onEditFlowLogReading,
    } = this.props;

    return (
      <FlowReadingListItem
        flowLogLimits={flowLogLimits}
        flowReading={flowReading}
        onConfirmCancel={() => this.setState({ showConfirmation: false })}
        onConfirmDelete={(flowLogId, statsRefreshParams) =>
          this.setState({ showConfirmation: false }, () =>
            onDeleteFlowLogReading(flowLogId, statsRefreshParams),
          )
        }
        onDeleteFlowLogReading={() => this.setState({ showConfirmation: true })}
        onEditFlowLogReading={onEditFlowLogReading}
        showConfirmation={showConfirmation}
      />
    );
  }
}

FlowReadingListItemContainer.propTypes = {
  flowLogLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowReading: PropTypes.shape({
    dateOfDischarge: PropTypes.string,
    flowRate: PropTypes.number,
    flowReading: PropTypes.number,
    hoursOfDischarge: PropTypes.number,
    id: PropTypes.string,
    monitoringLocationId: PropTypes.string,
  }).isRequired,
  onDeleteFlowLogReading: PropTypes.func.isRequired,
  onEditFlowLogReading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  flowLogLimits: state.wastewater.flowLogLimits,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteFlowLogReading: (flowLogId, statsRefreshParams) =>
    dispatch(deleteFlowLogReading(APP.projectId, flowLogId)).then(() => {
      const { dateOfDischarge, monitoringLocationId } = statsRefreshParams;
      const frequenciesToFetch = [
        IntervalFrequency.YEAR,
        IntervalFrequency.MONTH,
      ];
      return Promise.all(
        frequenciesToFetch.map((frequency) => {
          const startDate = moment
            .utc(dateOfDischarge)
            .startOf(frequency)
            .toISOString();
          const endDate = moment
            .utc(dateOfDischarge)
            .endOf(frequency)
            .toISOString();
          const query = {
            endDate,
            frequency,
            monitoringLocationId,
            startDate,
          };
          return dispatch(fetchFlowLogStatsAction(APP.projectId, query));
        }),
      );
    }),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(FlowReadingListItemContainer),
);
