/* eslint-disable */
let NoParametersItemView = require('./currentStatus/NoParametersItemView.js');

let BaseParameterCollectionView = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',
  className: 'list-styled',

  emptyView: NoParametersItemView,

  isEmpty() {
    return (
      _.isUndefined(this.collection) ||
      _.isNull(this.collection) ||
      this.collection.length === 0
    );
  },
});

module.exports = BaseParameterCollectionView;
