import React, { FormEvent, useCallback, useState } from 'react';
import { Button, TextInput } from '@monorepo/shared/components';

interface OrganizationSearchBarProps {
  isLoading: boolean;
  onSearch: (orgId: string) => void;
}

export function OrganizationSearchBar(props: OrganizationSearchBarProps) {
  const { isLoading, onSearch } = props;
  const [organizationId, setOrganizationId] = useState('');

  const handleSearch = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      organizationId && onSearch(organizationId);
    },
    [onSearch, organizationId],
  );

  return (
    <form
      className="bulk-upload-organization-search-bar"
      onSubmit={handleSearch}
    >
      <div className="bulk-upload-organization-search-bar__input-container">
        <TextInput
          controlled
          id="siteBulkCreateOrgId"
          label="Enter Organization ID"
          onChange={(evt) => {
            setOrganizationId(evt.target.value);
          }}
          value={organizationId}
        />
      </div>
      <Button
        className="bulk-upload-organization-search-bar__button"
        color="secondary"
        disabled={isLoading || !organizationId}
        type="submit"
      >
        {isLoading ? 'Searching...' : 'Search'}
      </Button>
    </form>
  );
}
