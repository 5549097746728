/* eslint-disable */
var APP = require('../../config');

var AttrModelBase = Backbone.Model.extend({
  save: function (attrs, options) {
    attrs = attrs || {};
    attrs.type = this.collection.type;

    return Backbone.Model.prototype.save.call(this, attrs, options);
  },

  destroy: function (options = {}) {
    // Need to add these options to send type along with DELETE request
    options.data = JSON.stringify({ type: this.collection.type });
    options.contentType = 'application/json';

    return Backbone.Model.prototype.destroy.call(this, options);
  },
});

// This will be used as a template for create all the different types of
// attr collections.
var AttrCollectionBase = Backbone.Collection.extend({
  model: AttrModelBase,

  initialize: function (models, options) {
    this.url = options.url;
    this.type = options.type;
    this.attrType = options.attrType;
  },

  create: function (attrs, options) {
    attrs = attrs || {};
    attrs.type = this.type;

    return Backbone.Collection.prototype.create.call(this, attrs, options);
  },
});

module.exports = AttrCollectionBase;
