import BaseValidator from './BaseValidator';

class PhoneNumberValidator extends BaseValidator {
  fieldValidation() {
    const numberValidation =
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const value = this.getValue();
    if (value && !value.match(numberValidation)) {
      return ['This must be a valid phone number'];
    }
    return [];
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PhoneNumberValidator;
