/* eslint-disable */
// Views
var CreateAndEditMapViewBase = require('../../project/sidebar/mapsPanel/CreateAndEditMapViewBase');

// Collections and Models
var EditTitlebarModel = require('../../models_collections/newAndEditMap/editTitlebarModel');
var projectCollection =
  require('../../models_collections/projectCollection').singleton();

// Services
var APP = require('../../config');
var projectMapsService = require('../../project/services/projectMapsService');

var EditTitlebarView = CreateAndEditMapViewBase.extend({
  template: require('../../project/sidebar/mapsPanel/createAndEditMapView.pug'),

  events: {
    'shown.bs.modal': '_handleModalShown',
    'hidden.bs.modal': '_removeView',
    'click #upload-image-wrapper': '_clickRealFileUploadButton',
    'change #btn-hidden-file': '_updateImageName',
    'click .btn-success': '_submitForms',
    'keydown input': '_handleKeyPress',
  },

  setModel: function () {
    this.model = new EditTitlebarModel();
  },

  _handleModalShown: function () {
    this.$('#titlebar-view-name').focus();
  },

  /**
   * Submit the titlebar form and logo form to edit the titlebar's contents
   */
  _submitForms: function () {
    var project = projectCollection.getCurrent();
    var map = projectMapsService.getSelectedMap();
    var formData = this._$titlebarForm.serializeObject();

    this._$loader.show();
    this._$submitButton.hide();

    project.save({
      name: formData.projectName,
      site_address: formData.siteAddress,
      site_city: formData.siteCity,
      site_zip: formData.siteZip,
    });
    map.save({ name: this.$('#map-name').val() });

    this.model.setTitlebarParams(formData, !this._$file.val());
    this.model.setLogoFormData(this._getLogoFormData());

    return this.model
      .updateTitlebar()
      .then(this._hideModal.bind(this))
      .then(this._uploadLogoImage.bind(this))
      .catch(
        this._displayErrors.bind(
          this,
          $(
            '<p>There was an error editing the titlebar. Please try again.</p>',
          ),
        ),
      );
  },

  /**
   * If the user has chosen an new logo, upload a new logo and update the
   * the titlebar model to reflect this change
   */
  _uploadLogoImage: function () {
    if (!!this._$file.val()) {
      return this.model.saveLogoImage();
    }
  },

  /**
   * Return all the data needed to render template correctly
   */
  _getTemplateData: function () {
    var titlebar = this.model.map.titlebar();
    var title = 'Edit Titlebar';
    var logoImage = titlebar.get('logo_image');
    var project = projectCollection.getCurrent();
    var map = projectMapsService.getSelectedMap();
    var displayVal = function displayVal(name) {
      return titlebar.get(name) ? titlebar.get(name) : '';
    };

    return {
      title: title,
      name: map.get('name'),
      projectName: project.get('name'),
      siteAddress: project.get('site_address')
        ? project.get('site_address')
        : '',
      siteCity: project.get('site_city') ? project.get('site_city') : '',
      siteZip: project.get('site_zip') ? project.get('site_zip') : '',
      titlebarName: displayVal('name'),
      figureNum: displayVal('figure_number'),
      fontSize: displayVal('font_size'),
      loaderUrl: APP.assetsRoot + 'ajax-login-loader.gif',
      csrfToken: APP.csrfToken,
      projectId: APP.projectId,
      logoImageName:
        logoImage !== 'DEFAULT'
          ? logoImage.substr(
              logoImage.indexOf('_', logoImage.indexOf('_') + 1) + 1,
            )
          : '',
      isFontSizeSelected: (o) =>
        parseInt(o) === parseInt(titlebar.get('font_size')),
    };
  },
});

Backbone.addSingletonToView(EditTitlebarView);
module.exports = EditTitlebarView;
