/* eslint-disable */
var WebMapLabelOptionsFactory = {
  create: function (shapeModel, centerCoordinates) {
    let type = shapeModel.get('type');
    let featureGroup = shapeModel.get('featureGroup');
    let labelOffsetCoordinates = shapeModel.get('labelOffsetCoordinates');
    let styleModel = shapeModel.get('styleModel');
    let rotation = !!shapeModel.get('rotation')
      ? parseInt(shapeModel.get('rotation'))
      : 0;

    switch (type) {
      case 'marker':
        return this._createMarkerLabelOptions(
          featureGroup,
          labelOffsetCoordinates,
          styleModel,
          rotation,
        );
      case 'polyline':
        return this._createPolylineLabelOptions(
          featureGroup,
          labelOffsetCoordinates,
          centerCoordinates,
          styleModel,
        );
      case 'polygon':
        return this._createPolygonLabelOptions(
          featureGroup,
          labelOffsetCoordinates,
          centerCoordinates,
          styleModel,
        );
    }
  },

  _createMarkerLabelOptions: function (
    featureGroup,
    labelOffsetCoordinates,
    styleModel,
    rotation,
  ) {
    const labelOptions = {
      type: 'marker',
      nonPointGroup: featureGroup._nonPointGroup,
    };

    if (labelOffsetCoordinates) {
      labelOptions.direction = 'custom';
    }

    if (rotation) {
      labelOptions.rotation = rotation;
    }

    return this._commonOptions(
      labelOptions,
      labelOffsetCoordinates,
      styleModel,
    );
  },

  _createPolylineLabelOptions: function (
    featureGroup,
    labelOffsetCoordinates,
    centerCoordinates,
    styleModel,
  ) {
    const labelOptions = {
      direction: 'custom',
      referenceVertex: centerCoordinates,
      offset: [0, 0],
      type: 'polyline',
      polyGroup: featureGroup,
    };

    if (labelOffsetCoordinates) {
      labelOptions.scaleOffset = true;
    }

    return this._commonOptions(
      labelOptions,
      labelOffsetCoordinates,
      styleModel,
    );
  },

  _createPolygonLabelOptions: function (
    featureGroup,
    labelOffsetCoordinates,
    centerCoordinates,
    styleModel,
  ) {
    var labelOptions = {
      direction: 'custom',
      referenceVertex: centerCoordinates,
      offset: [0, 0],
      type: 'polygon',
      polyGroup: featureGroup,
    };

    if (labelOffsetCoordinates) {
      labelOptions.scaleOffset = true;
    }

    return this._commonOptions(
      labelOptions,
      labelOffsetCoordinates,
      styleModel,
    );
  },

  _commonOptions(labelOptions, labelOffsetCoordinates, styleModel) {
    labelOptions.clickable = true;
    labelOptions.noHide = true;

    if (labelOffsetCoordinates) {
      labelOptions.userOffset = labelOffsetCoordinates;
    }

    if (styleModel.has('label_background_color')) {
      let backgroundColor = styleModel.get('label_background_color');
      if (backgroundColor === 'none') {
        labelOptions.className = 'no-background-color';
      } else {
        labelOptions.className =
          'backgroundColor' + backgroundColor.substring(1);
      }
    } else {
      labelOptions.className = '';
    }

    if (styleModel.has('label_text_color')) {
      let colorClass =
        'color' + styleModel.get('label_text_color').substring(1);
      labelOptions.className += ' ' + colorClass;
    }

    if (styleModel.has('label_text_size')) {
      let sizeClass = 'size' + styleModel.get('label_text_size');
      labelOptions.className += ' ' + sizeClass;
    }

    if (labelOptions.rotation) {
      labelOptions.className += ` rotate-${labelOptions.rotation}`;
    }

    return labelOptions;
  },
};

module.exports = WebMapLabelOptionsFactory;
