var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (rotation, url) {
      pug_html = pug_html + "\u003Cspan class=\"middle-align-helper\"\u003E\u003C\u002Fspan\u003E\u003Cimg" + (pug.attr("class", pug.classes(["full-size-photo",'rotate-' + rotation], [false,true]), false, true)+pug.attr("src", url, true, true)) + "\u003E\u003Cdiv class=\"feature-photo-overlay-panel\"\u003E\u003Cdiv class=\"feature-photo-overlay-actions\"\u003E\u003Cbutton class=\"feature-photo-overlay-button rotate-button-left\" aria-label=\"Rotate Left\"\u003E\u003Cspan class=\"glyphicon glyphicon-repeat mirror-horizontal\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"feature-photo-overlay-button rotate-button-right\" aria-label=\"Rotate Right\"\u003E\u003Cspan class=\"glyphicon glyphicon-repeat\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"feature-photo-overlay-button edit-caption-button\" aria-label=\"Edit Caption\"\u003E\u003Cspan class=\"glyphicon glyphicon-pencil\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"feature-photo-overlay-button save-caption-button\" aria-label=\"Save Caption\"\u003E\u003Cspan class=\"glyphicon glyphicon-floppy-saved\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cbutton class=\"feature-photo-overlay-button cancel-edit-caption-button\" aria-label=\"Cancel Caption Editing\"\u003E\u003Cspan class=\"glyphicon glyphicon-floppy-remove\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"caption\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "rotation" in locals_for_with ?
        locals_for_with.rotation :
        typeof rotation !== 'undefined' ? rotation : undefined, "url" in locals_for_with ?
        locals_for_with.url :
        typeof url !== 'undefined' ? url : undefined));
    ;;return pug_html;};
module.exports = template;