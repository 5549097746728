/* eslint-disable */
var APP = require('../config');
var errorView = require('../modals/errorView').singleton();

var GeolocateButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/geolocateButton.pug'),

  ui: {
    $buttonIcon: 'img',
  },

  events: {
    click: '_toggleGeolocation',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  initialize: function () {
    this._isOn = false;
    this._gelocationWatchId = null;
    this._isGeolocationSupported = !!(
      window.navigator &&
      window.navigator.geolocation &&
      window.navigator.geolocation.watchPosition &&
      window.navigator.geolocation.clearWatch
    );
    this._geolocationNotSupportedMessage =
      "Your browser doesn't support geolocation.";
  },

  _turnOn: function () {
    var successCount = 0;

    if (!this._isGeolocationSupported) {
      throw new Error(this._geolocationNotSupportedMessage);
    }

    this._isOn = true;
    this.ui.$buttonIcon.addClass('spin');
    this._geolocationWatchId = window.navigator.geolocation.watchPosition(
      success.bind(this),
      error.bind(this),
      {
        enableHighAccuracy: true,
        timeout: 10000,
      },
    );

    function success(position) {
      var lat = position.coords.latitude;
      var lng = position.coords.longitude;

      this.trigger('geolocate:success', lat, lng, ++successCount);
    }

    function error(err) {
      if (err.code === 1) {
        this._turnOff();
        var message =
          'Permission to geolocate denied. Please check your browser settings.';
        errorView.showModal({ error: message });
      }
    }
  },

  _turnOff: function () {
    if (!this._isGeolocationSupported) {
      throw new Error(this._geolocationNotSupportedMessage);
    }

    this.trigger('turnOff');
    this._isOn = false;
    this.ui.$buttonIcon.removeClass('spin');
    window.navigator.geolocation.clearWatch(this._geolocationWatchId);
    this._geolocationWatchId = null;
  },

  _toggleGeolocation: function () {
    APP.vent.trigger('geolocate:toggle');

    if (!this._isGeolocationSupported) {
      errorView.showModal({ error: this._geolocationNotSupportedMessage });
      return;
    }

    if (this._isOn) {
      this._turnOff();
    } else {
      this._turnOn();
    }
  },
});

module.exports = GeolocateButtonView;
