/* eslint-disable */
var UserNotificationCollection = Backbone.Collection.extend({
  initialize(options = {}) {
    this.userId = options.userId;
  },

  url() {
    let currentUser = require('../../../../currentUser');
    let currentUserId = currentUser && currentUser.id;
    return require('../../../../apiCaller').getUserNotificationsUrl(
      this.userId || currentUserId,
    );
  },
});

module.exports = UserNotificationCollection;
