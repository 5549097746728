import { KeyService } from '@monorepo/shared/KeyService';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Popover, RichTextEditor } from '../../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class NotesPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      notes: props.notes,
      popoverIsOpen: false,
    };
    this.handlePopoverTargetClick = this.handlePopoverTargetClick.bind(this);
  }

  handlePopoverTargetClick(event) {
    const { currentTarget } = event;
    const { notes, popoverIsOpen } = this.state;
    const { onClose } = this.props;

    if (popoverIsOpen) {
      return this.setState(
        {
          anchorEl: null,
          popoverIsOpen: false,
        },
        () => {
          onClose(notes);
        },
      );
    }
    return this.setState({
      anchorEl: currentTarget,
      popoverIsOpen: true,
    });
  }

  render() {
    const { anchorEl, popoverIsOpen } = this.state;

    const { notes } = this.props;

    const notesIsNotEmpty =
      _get(notes, '0.children', []).length >= 1 &&
      _get(notes, '0.children.0.text', null);

    const popoverTarget = () => {
      if (notesIsNotEmpty) {
        return (
          <>
            <SvgIcon identifier="notes" />
            <span className="pl2">View Notes</span>
          </>
        );
      }
      return (
        <>
          <SvgIcon identifier="add" />
          <span className="pl2">Add Notes</span>
        </>
      );
    };

    return (
      <>
        <div
          role="button" // TODO: this should just be a <button>, too lazy to figure out the css
          tabIndex={0}
          className="notes-popover__target-container emission-tracking__action grow"
          onClick={this.handlePopoverTargetClick}
          onKeyDown={(e) => {
            if (KeyService.triggersButtonClick(e.keyCode))
              this.handlePopoverTargetClick(e);
          }}
        >
          {popoverTarget()}
        </div>
        <Popover
          anchorEl={anchorEl}
          open={popoverIsOpen}
          onClose={this.handlePopoverTargetClick}
        >
          <div className="notes-popover__rich-text-container">
            <RichTextEditor
              onChange={(nextNotes) =>
                this.setState({
                  notes: nextNotes,
                })
              }
              initialValue={notes}
            />
          </div>
        </Popover>
      </>
    );
  }
}

NotesPopover.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
};

NotesPopover.defaultProps = {
  notes: null,
};
