/* eslint-disable */
var _s = require('underscore.string');
var APP = require('../../config');
var AttrCollectionBase = require('./attrCollectionBase');
import attrStore from '../../attrStore';
const BluebirdPromise = require('bluebird');

function AttrCollectionWrapper(layer) {
  this.url =
    APP.apiUrl +
    '/projects/' +
    APP.projectId +
    '/layers/' +
    layer.id +
    '/attrs';
  this.stringAttrCollection = new AttrCollectionBase([], {
    url: this.url,
    type: 'stringAttr',
  });
  this.stringAttrValueCollection = new AttrCollectionBase([], {
    url: this.url,
    attrType: 'stringAttr',
    type: 'stringAttrValue',
  });
  this.floatAttrCollection = new AttrCollectionBase([], {
    url: this.url,
    type: 'floatAttr',
  });
  this.floatAttrValueCollection = new AttrCollectionBase([], {
    url: this.url,
    attrType: 'floatAttr',
    type: 'floatAttrValue',
  });
  this.listAttrCollection = new AttrCollectionBase(
    layer.get('listAttrs') || [],
    {
      url: this.url,
      type: 'listAttr',
    },
  );
  this.listAttrValueCollection = new AttrCollectionBase(
    layer.get('listAttrValues') || [],
    {
      url: this.url,
      attrType: 'listAttr',
      type: 'listAttrValue',
    },
  );

  _.extend(this, Backbone.Events);
  this.listenTo(this.stringAttrValueCollection, 'add', this._handleAdd);
  this.listenTo(this.floatAttrValueCollection, 'add', this._handleAdd);
  this.listenTo(this.listAttrValueCollection, 'add', this._handleAdd);
  this.listenTo(this.stringAttrValueCollection, 'destroy', this._handleDestroy);
  this.listenTo(this.floatAttrValueCollection, 'destroy', this._handleDestroy);
  this.listenTo(this.listAttrValueCollection, 'destroy', this._handleDestroy);
  this.listenTo(
    this.stringAttrValueCollection,
    'change',
    this._handleAttrValueChange,
  );
  this.listenTo(
    this.floatAttrValueCollection,
    'change',
    this._handleAttrValueChange,
  );
  this.listenTo(
    this.listAttrValueCollection,
    'change',
    this._handleAttrValueChange,
  );
  this.listenTo(this.stringAttrCollection, 'change', this._handleAttrChange);
  this.listenTo(this.floatAttrCollection, 'change', this._handleAttrChange);
  this.listenTo(this.listAttrCollection, 'change', this._handleAttrChange);
}

AttrCollectionWrapper.prototype = {
  addAttrValue: function (featureId, value, attrId) {
    if (this.stringAttrCollection.get(attrId)) {
      this.stringAttrValueCollection.create({
        value: value,
        feature_id: featureId,
        string_attr_id: attrId,
      });
    } else if (this.floatAttrCollection.get(attrId)) {
      this.floatAttrValueCollection.create({
        value: value,
        feature_id: featureId,
        float_attr_id: attrId,
      });
    } else if (this.listAttrCollection.get(attrId)) {
      this.listAttrValueCollection.create({
        value: value,
        feature_id: featureId,
        list_attr_id: attrId,
        is_list: true,
      });
    }
  },

  prepareValue(format, value) {
    return typeof value === 'string' ? value : JSON.stringify(value);
  },

  create: function (featureId, name, value, format, template_key) {
    var _this = this;
    const preparedValue = this.prepareValue(format, value);

    if (format === 'text') {
      createAttrAndValue('string');
    } else if (format === 'number') {
      createAttrAndValue('float');
    } else if (format === 'list') {
      createAttrAndValue('list', template_key);
    }

    function createAttrAndValue(type, template_key) {
      let attrCollectionName = type + 'AttrCollection';
      let attrValueCollectionName = type + 'AttrValueCollection';
      let async = require('async');

      async.waterfall(
        [
          function (cb) {
            const request = { name: name };
            if (type === 'list') {
              request.template_key = template_key;
            }
            _this[attrCollectionName].create(request, {
              success: function (model) {
                cb(null, model.id);
              },
              error: function (model, response) {
                cb({ message: response.responseText });
              },
            });
          },

          function (attrId, cb) {
            var attrs = {
              value: preparedValue,
              feature_id: featureId,
            };
            attrs[type + '_attr_id'] = attrId;

            _this[attrValueCollectionName].create(attrs, {
              wait: true,
              success: function () {
                cb();
              },
              error: function (model, response) {
                // rollback if error creating value
                _this[attrCollectionName].get(attrId).destroy();
                cb({ message: response.responseText });
              },
            });
          },
        ],

        function (err) {
          if (err) {
            var errorMessages = [];

            try {
              errorMessages = _.flatten(_.values(JSON.parse(err.message)));
            } catch (e) {
              errorMessages.push((err && err.message) || err);
            }

            _this.trigger('add:errors', errorMessages);
          }
        },
      );
    }
  },

  /**
   * Gets all the attr data as json for a specific layer.
   */
  fetch: function () {
    return BluebirdPromise.resolve($.getJSON(this.url))
      .bind(this)
      .then(this._parse)
      .catch(function () {
        throw new Error('There was an error trying to fetch attrs.');
      });
  },

  /**
   * Creates attr objects that combine the attr and attrValue models.
   * @param attrsJSON {object} - This should be the json received from hitting attrs endpoint
   */
  _parse: function (attrsJSON) {
    if (!!attrsJSON.listDefaults) {
      attrStore.storeLists(attrsJSON.listDefaults);
    }
    return BluebirdPromise.bind(this).then(function () {
      _.keys(attrsJSON)
        .filter((type) => type !== 'listDefaults')
        .forEach(function (type) {
          var collectionName = type.slice(0, -1) + 'Collection';
          var models = attrsJSON[type];
          this[collectionName].reset(models);
        }, this);
    });
  },

  /**
   * Creates attr objects that combine the attr and attrValue models.
   *
   * @returns {array<objects>}
   *   - name {string}
   *   - value {string | float}
   *   - model {Backbone.Model}
   *   - valueModel {Backbone.Model}
   */
  attrs: function () {
    var helper = {
      attrs: {},
      add: function (featureId, attr) {
        if (this.attrs[featureId]) {
          this.attrs[featureId].push(attr);
        } else {
          this.attrs[featureId] = [attr];
        }
      },
    };
    var featureId;
    var attr;
    var _this = this;

    this.stringAttrValueCollection.each(function (stringAttrValue) {
      var stringAttr = _this.stringAttrCollection.get(
        stringAttrValue.get('string_attr_id'),
      );

      featureId = stringAttrValue.get('feature_id');
      attr = {
        name: stringAttr.get('name'),
        geocoded: stringAttr.get('geocoded'),
        value: stringAttrValue.get('value'),
        attrModel: stringAttr,
        valueModel: stringAttrValue,
        isNumeric: false,
      };
      helper.add(featureId, attr);
    });

    this.floatAttrValueCollection.each(function (floatAttrValue) {
      var floatAttr = _this.floatAttrCollection.get(
        floatAttrValue.get('float_attr_id'),
      );

      featureId = floatAttrValue.get('feature_id');
      attr = {
        name: floatAttr.get('name'),
        value: floatAttrValue.get('value'),
        attrModel: floatAttr,
        valueModel: floatAttrValue,
        isNumeric: true,
      };
      helper.add(featureId, attr);
    });

    this.listAttrValueCollection.each(function (listAttrValue) {
      var listAttr = _this.listAttrCollection.get(
        listAttrValue.get('list_attr_id'),
      );

      featureId = listAttrValue.get('feature_id');
      attr = {
        name: listAttr.get('name'),
        templateKey: listAttr.get('template_key'),
        value: listAttrValue.get('value'),
        attrModel: listAttr,
        valueModel: listAttrValue,
        isNumeric: false,
        isList: true,
      };
      helper.add(featureId, attr);
    });

    return helper.attrs;
  },

  /**
   * The names of the attrs
   *
   * @param options {object}
   *   - lowercase {boolean}
   *
   * @returns {Backbone.Model}
   */
  attrNames: function (options) {
    var stringAttrNames = this.stringAttrCollection.pluck('name');
    var floatAttrNames = this.floatAttrCollection.pluck('name');
    var listAttrNames = this.listAttrCollection.pluck('name');

    if (options.lowercase) {
      stringAttrNames = stringAttrNames.map(function (name) {
        return name.toLowerCase();
      });
      floatAttrNames = floatAttrNames.map(function (name) {
        return name.toLowerCase();
      });
      listAttrNames = listAttrNames.map(function (name) {
        return name.toLowerCase();
      });
    }

    return stringAttrNames.concat(floatAttrNames).concat(listAttrNames);
  },

  _handleAdd: function (attrValueModel) {
    this.trigger('add', { attrValue: attrValueModel });
  },

  _handleAttrChange: function () {
    this.trigger('change');
  },

  _handleAttrValueChange: function (attrValueModel) {
    var stringAttrId = attrValueModel.get('string_attr_id');
    var isGeocoded = false;

    if (stringAttrId) {
      var stringAttr = this.stringAttrCollection.get(stringAttrId);
      isGeocoded = stringAttr.get('geocoded');
    }

    this.trigger('change', {
      needToUpdateLatLng: isGeocoded,
      attrValue: attrValueModel,
    });
  },

  _handleDestroy: function (attrValueModel, collection) {
    var options = {};
    var key = _s.underscored(`${collection.attrType}Id`);
    var attrModelId = attrValueModel.get(key);
    var attrModel = this[collection.attrType + 'Collection'].get(attrModelId);
    var isGeocoded = attrModel.get('geocoded');

    options[key] = attrModelId;

    // Destroy the attr model along with attrValueModel
    // if no more attr values with this attr name in the layer;
    if (!collection.findWhere(options)) {
      attrModel.destroy();
    }

    this.trigger('destroy', {
      needToUpdateLatLng: isGeocoded,
      attrValue: attrValueModel,
    });
  },
};

module.exports = AttrCollectionWrapper;
