import React from 'react';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import { TABLE_SETTINGS } from '.';

const VirtualizedListFixedRowHeight = (props) => {
  const {
    rowRenderer,
    rowHeight,
    groupOfItems,
    groupId,
    scrollIndex,
    isUsingPlaceholder,
  } = props;

  let itemCount = isUsingPlaceholder
    ? groupOfItems.length + 1
    : groupOfItems.length;
  if (groupOfItems.length === 0) itemCount = 0; // show only the drop placeholder for empty lists
  const totalHeight = rowHeight * itemCount;

  return (
    <div className="logged-items-table__body">
      <AutoSizer
        style={{
          height: `min(calc(100vh - ${TABLE_SETTINGS.SPACE_ABOVE_TABLE}px), ${totalHeight}px)`,
        }}
      >
        {({ height, width }) => (
          <List
            overscanRowCount={20}
            rowCount={itemCount}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
            height={height}
            width={width}
            scrollToIndex={groupId === 'null' ? scrollIndex : undefined}
          />
        )}
      </AutoSizer>
    </div>
  );
};

VirtualizedListFixedRowHeight.propTypes = {
  rowRenderer: PropTypes.func.isRequired,
  rowHeight: PropTypes.number,
  scrollIndex: PropTypes.number,
  groupId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  groupOfItems: PropTypes.array.isRequired,
  isUsingPlaceholder: PropTypes.bool.isRequired,
};

VirtualizedListFixedRowHeight.defaultProps = {
  rowHeight: TABLE_SETTINGS.ROW_HEIGHT,
  scrollIndex: undefined,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default VirtualizedListFixedRowHeight;
