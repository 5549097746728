/* eslint-disable */
const _s = require('underscore.string');

module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/notifications.pug'),

  className: 'panel panel-default',

  childView: require('./NotificationRow'),

  childViewContainer: 'ul',

  templateHelpers() {
    let category =
      this.collection.length && this.collection.at(0).has('category')
        ? this.collection.at(0).get('category')
        : 'Unknown';
    return {
      title: category + ' Notifications',
    };
  },
});
