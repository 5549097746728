import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RecordPanel from '../../views/projectDashboard/RecordPanel';
import { Button, Menu, Tabs } from '../../elements';

import PhCalibrationModal from './PhCalibrationModal';
import PhCalibrationRecordList from './PhCalibrationRecordList';
import PhLogModal from './PhLogModal';
import PhLogRecordList from './PhLogRecordList';

import EditParametersModal from '../SamplingResultRecordPanel/EditParametersModal';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class PHLogRecordPanel extends Component {
  constructor(props) {
    super(props);
    this.TAB_OPTIONS = [{ label: 'pH Log' }, { label: 'pH Calibrations' }];
    this.state = {
      selectedTab: this.TAB_OPTIONS[0],
    };
    this.selectTab = this.selectTab.bind(this);
  }

  selectTab(selectedTab) {
    this.setState({ selectedTab });
  }

  render() {
    const {
      editParametersModalIsOpen,
      hasConfiguredPhSettings,
      onCloseEditParametersModal,
      onClosePhCalibrationModal,
      onClosePhLogModal,
      onOpenEditParametersModal,
      onOpenPhCalibrationModal,
      onOpenPhLogModal,
      phCalibrationModalIsOpen,
      phCalibrationAttachmentToEdit,
      phCalibrationEventToEdit,
      phLogModalIsOpen,
      phLogAttachmentToEdit,
      phLogEventToEdit,
    } = this.props;

    const { selectedTab } = this.state;
    const menuOptions = [
      { label: 'Add pH Log', onClick: onOpenPhLogModal, iconIdentifier: 'add' },
      {
        label: 'Add pH Calibration',
        onClick: onOpenPhCalibrationModal,
        iconIdentifier: 'add',
      },
    ];
    return (
      <RecordPanel
        header={
          <div className="w-100 flex items-center justify-between">
            <Tabs
              onSelectTab={this.selectTab}
              options={this.TAB_OPTIONS}
              selected={selectedTab}
            />
            <Menu options={menuOptions} />
          </div>
        }
      >
        <>
          {!hasConfiguredPhSettings && (
            <div className="not-configured">
              <Button onClick={onOpenEditParametersModal} color="secondary">
                Configure pH in the water parameter settings
              </Button>
            </div>
          )}
          {selectedTab === this.TAB_OPTIONS[0] && hasConfiguredPhSettings && (
            <PhLogRecordList openPhLogModal={onOpenPhLogModal} />
          )}
          {selectedTab === this.TAB_OPTIONS[1] && hasConfiguredPhSettings && (
            <PhCalibrationRecordList
              openPhCalibrationModal={onOpenPhCalibrationModal}
            />
          )}
          <PhCalibrationModal
            attachment={phCalibrationAttachmentToEdit}
            event={phCalibrationEventToEdit}
            open={phCalibrationModalIsOpen}
            onClose={onClosePhCalibrationModal}
          />
          <PhLogModal
            attachment={phLogAttachmentToEdit}
            event={phLogEventToEdit}
            open={phLogModalIsOpen}
            onClose={onClosePhLogModal}
          />
          <EditParametersModal
            onClose={onCloseEditParametersModal}
            open={editParametersModalIsOpen}
          />
        </>
      </RecordPanel>
    );
  }
}

PHLogRecordPanel.propTypes = {
  editParametersModalIsOpen: PropTypes.bool.isRequired,
  hasConfiguredPhSettings: PropTypes.bool.isRequired,
  onCloseEditParametersModal: PropTypes.func.isRequired,
  onClosePhCalibrationModal: PropTypes.func.isRequired,
  onClosePhLogModal: PropTypes.func.isRequired,
  onOpenEditParametersModal: PropTypes.func.isRequired,
  onOpenPhCalibrationModal: PropTypes.func.isRequired,
  onOpenPhLogModal: PropTypes.func.isRequired,
  phCalibrationModalIsOpen: PropTypes.bool.isRequired,
  phLogModalIsOpen: PropTypes.bool.isRequired,
  phCalibrationAttachmentToEdit: PropTypes.shape({}),
  phCalibrationEventToEdit: PropTypes.shape({}),
  phLogAttachmentToEdit: PropTypes.shape({}),
  phLogEventToEdit: PropTypes.shape({}),
};

PHLogRecordPanel.defaultProps = {
  phCalibrationAttachmentToEdit: null,
  phCalibrationEventToEdit: null,
  phLogAttachmentToEdit: null,
  phLogEventToEdit: null,
};
