/* eslint-disable */

var APP = require('./config');
import React from 'react';
import { ComponentWrapper } from './components/componentWrapper';
import ConfirmDialog from './components/elements/ConfirmDialog';

var RootLayoutView = Backbone.Marionette.LayoutView.extend({
  el: 'body',

  regions: {
    impersonating: '#impersonating-container',
    navbar: '#navbar-container',
    content: '#content-container',
    dialog: '#dialog-container',
  },

  events: {
    click: '_handleClick',
  },

  initialize: function () {
    this.navbar.on('show', this._initializeNavbarListeners.bind(this));
    this._initilializeDownloadMapListeners();
    this._initializeGeolocateListeners();
    this._initializeBlockedNavigationListener();
  },

  appendToBody: function (html) {
    this.$el.append(html);
  },

  addClass: function (cssClassName) {
    this.$el.addClass(cssClassName);
  },

  setupPageForProjectViewerIfNecessary: function () {
    var currentUserArbiter = require('./permissions/currentUserArbiter');
    currentUserArbiter
      .hasProjectUpdatePermission(APP.projectId)
      .bind(this)
      .then(function (hasProjectUpdatePermission) {
        if (!hasProjectUpdatePermission) {
          this.$el.addClass('no-project-data-update-permission');
        }
      });
  },

  _handleClick: function (e) {
    APP.vent.trigger('body:clicked', e);
  },

  _initilializeDownloadMapListeners: function () {
    var $body = this.$el;
    APP.vent.on('downloadMapButtonView:click:downloadMapButton', function () {
      $body.toggleClass('download-map-preview');
    });
    APP.vent.on(
      'downloadMapButtonView:change:mapSize',
      function (orientation, mapSize) {
        removeSizeAndOrientationClasses();
        $body.addClass(
          'download-map-' + orientation + ' ' + 'download-map-' + mapSize,
        );
      },
    );
    APP.vent.on('projectLayoutView:change:selectedMap', function () {
      removeSizeAndOrientationClasses();
    });
    APP.vent.on('routeChange', function () {
      $body.removeClass(function (index, css) {
        return (css.match(/download-map-\w+/g) || []).join(' ');
      });
    });
    APP.vent.on('toggleSidebarView:click:toggleSidebarButton', function () {
      $body.toggleClass('sidebar-closed');
    });

    function removeSizeAndOrientationClasses() {
      $body.removeClass(function (index, css) {
        return (
          css.match(/download-map-(letter|landscape|tabloid|portrait)/g) || []
        ).join(' ');
      });
    }
  },

  _initializeGeolocateListeners: function () {
    this.listenTo(APP.vent, 'geolocate:toggle', function () {
      this.$el.toggleClass('geolocate');
    });
  },

  _initializeNavbarListeners: function (navbarView) {
    this.listenTo(navbarView, 'click:toggleSidebar', this._toggleSidebar);
  },

  _initializeBlockedNavigationListener: function () {
    this.listenTo(APP.vent, 'navigation:blocked', (callbacks) => {
      const navBlockedDialog = new ComponentWrapper(
        (
          <ConfirmDialog
            open={true}
            title="Are you sure you want to exit without saving?"
            description="Any updates you've entered since you last saved will be lost if you proceed."
            confirmButtonText="Exit without saving"
            onConfirmed={() => {
              this.dialog.empty();
              APP.isNavigationBlocked = false;
              callbacks.continue();
            }}
            onCancelled={() => {
              this.dialog.empty();
              callbacks.cancel();
            }}
            danger
          />
        ),
        { noProviders: true },
      );
      this.dialog.show(navBlockedDialog);
    });
  },
});

module.exports = new RootLayoutView();
