var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isLineStyleSelected) {
      pug_html = pug_html + "\u003Cdiv class=\"form-group inline-row margin-right\"\u003E\u003Cdiv class=\"subheader-container\"\u003E\u003Ch4\u003ELine Style\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cselect class=\"lineStyle form-control\" name=\"line_style\"\u003E\u003Coption" + (pug.attr("selected", isLineStyleSelected('dashed'), true, true)) + "\u003Edashed\u003C\u002Foption\u003E\u003Coption" + (pug.attr("selected", isLineStyleSelected(''), true, true)) + "\u003Esolid\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isLineStyleSelected" in locals_for_with ?
        locals_for_with.isLineStyleSelected :
        typeof isLineStyleSelected !== 'undefined' ? isLineStyleSelected : undefined));
    ;;return pug_html;};
module.exports = template;