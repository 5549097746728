import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { RecordValue } from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { SamplingUnit } from '@monorepo/shared/types/industrial-stormwater-sector-data';
/* @ts-expect-error - old Mapistry package without TS */
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
/* @ts-expect-error - old Mapistry package without TS */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
import { validateSamplingParameterUnits } from '../shared/uploadValidators';

const allUnits = SamplingUnits.getAll() as SamplingUnit[];

export function validateParameterSettingUnits(
  unitFieldId: string,
  record: FlatfileRecord,
  parameterSlug: RecordValue,
): void {
  const unitSlug = record.get(unitFieldId);

  // Special use case: limit can be in lbs/day for parameters which are measured in mg/L, or similar.
  // User can log flow rate the same day as sampling result and Mapistry will
  // calculate that parameter pollution rate in lbs/day and compare it to the limit.
  if (parameterSlug && unitSlug === 'pounds_per_day') {
    const parameterUnitSlug = SamplingParameters.getUnits(parameterSlug);
    const parameterUnit = allUnits.find((u) => u.slug === parameterUnitSlug);
    if (parameterUnit?.measure === 'concentration') {
      return;
    }
  }

  validateSamplingParameterUnits(unitFieldId, record, parameterSlug);
}
