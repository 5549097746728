/* eslint-disable */
import BaseParameterCollectionView from '../BaseParameterCollectionView';
let CurrentStatusItemView = require('./CurrentStatusItemView.js');

const CurrentStatusCollectionView = BaseParameterCollectionView.extend({
  childView: CurrentStatusItemView,

  initialize(options = {}) {
    this.showParameterStatus = options.showParameterStatus;
    this.sectorData = options.sectorData;
    this.isWashingtonIndustrial = options.isWashingtonIndustrial;
  },

  childViewOptions() {
    return {
      isWashingtonIndustrial: this.isWashingtonIndustrial,
      getParameterDisplayText: this.sectorData.getParameterDisplayText.bind(
        this.sectorData,
      ),
      showParameterStatus: this.showParameterStatus,
    };
  },
});

module.exports = CurrentStatusCollectionView;
