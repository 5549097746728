import mathjs from 'mathjs';
import _ from 'underscore';
import _s from 'underscore.string';
import { round, convertSetToArray } from '..';
import 'core-js/features/array/find-index';

const DECIMAL_PLACES = 4;
const conversionSettingMap = {
  pounds: {
    group: 'solid',
    units: 'pounds',
    mathjsUnits: 'poundmass',
    priority: 3,
  },
  tons: {
    group: 'solid',
    units: 'tons',
    mathjsUnits: 'ton',
    priority: 4,
  },
  gram: {
    group: 'solid',
    units: 'gram',
    mathjsUnits: 'gram',
    priority: 1,
  },
  ounce: {
    group: 'solid',
    units: 'ounce',
    mathjsUnits: 'ounce',
    priority: 2,
  },
  gallons: {
    group: 'liquid',
    units: 'gallons',
    mathjsUnits: 'gallon',
    priority: 6,
  },
  fluidounce: {
    group: 'liquid',
    units: 'fluidounce',
    mathjsUnits: 'fluidounce',
    priority: 2,
  },
  pint: {
    group: 'liquid',
    units: 'pint',
    mathjsUnits: 'pint',
    priority: 3,
  },
  quart: {
    group: 'liquid',
    units: 'quart',
    mathjsUnits: 'quart',
    priority: 4,
  },
  litre: {
    group: 'liquid',
    units: 'litre',
    mathjsUnits: 'litre',
    priority: 5,
  },
  milliliter: {
    group: 'liquid',
    units: 'milliliter',
    mathjsUnits: 'milliliter',
    priority: 1,
  },
};

const lengthConstraints = [
  { name: 'location', length: 140 },
  { name: 'grid_number', length: 15 },
];

function extractLeaderlineOptions(styleModel) {
  return {
    markerLatLng: styleModel.get('geography'),
    weight: styleModel.get('leaderline_weight'),
    opacity: styleModel.get('leaderline_opacity'),
    fillOpacity: 1.0,
    color: styleModel.get('leaderline_color'),
  };
}

function calculateMaximumDailyAmount(number, size) {
  if (number && size) {
    const result = number * size;
    if (!Number.isNaN(result)) {
      return result;
    }
  }
  return '';
}

function createValueExtractor(value) {
  return (key) => value[key] && value[key].value;
}

function miniMapMapper(value) {
  const extractValue = createValueExtractor(value);
  return {
    chemical_name: value.chemical_name && value.chemical_name.value,
    common_name: value.common_name && value.common_name.value,
    maximum_daily_amount: calculateMaximumDailyAmount(
      extractValue('containers_number'),
      extractValue('container_size'),
    ),
    units: value.units && value.units.value,
    cas_number: value.cas_number && value.cas_number.value,
    physical_state: value.physical_state && value.physical_state.value,
    hazardous_material_type:
      value.hazardous_material_type && value.hazardous_material_type.value,
    curies: value.curies && value.curies.value,
    state_waste_code: value.state_waste_code && value.state_waste_code.value,
    days_on_site: value.days_on_site && value.days_on_site.value,
    storage_pressure: value.storage_pressure && value.storage_pressure.value,
    storage_temperature:
      value.storage_temperature && value.storage_temperature.value,
    chemical_description_comment:
      value.chemical_description_comment &&
      value.chemical_description_comment.value,
    us_epa_substance: value.us_epa_substance && value.us_epa_substance.value,
    DOT_hazard_classification_ID:
      value.DOT_hazard_classification_ID &&
      value.DOT_hazard_classification_ID.value,
    'chemical-attrs': value['chemical-attrs'] && value['chemical-attrs'].value,
  };
}

function hmbpTableRemapper(headerModel) {
  return (value) => {
    const newModel = headerModel.clone();
    const newValue = newModel
      .get('value')
      .map((attr) => _.extend({}, attr, { value: value[attr.key] }));
    newModel.set('value', newValue);
    newModel.set('chemicalAttributes', value['chemical-attrs']);
    newModel.set('feature_name', value.location);
    return newModel;
  };
}

function createHmbpTableValueExtractor(value) {
  return (key) => value[key];
}

function hmbpTableMapper(hmbpChemicalsLocationField) {
  return (attr) => {
    const valueObject = {};
    attr.attributes.value.forEach((v) => {
      valueObject[v.key] = v.value;
    });
    const extractValue = createHmbpTableValueExtractor(valueObject);
    return _.extend({}, valueObject, {
      maximum_daily_amount: calculateMaximumDailyAmount(
        extractValue('containers_number'),
        extractValue('container_size'),
      ),
      'chemical-attrs': attr.attributes.chemicalAttributes,
      location:
        hmbpChemicalsLocationField === 'building_name'
          ? attr.attributes.building_name
          : attr.attributes.feature_name,
      grid_number:
        hmbpChemicalsLocationField === 'building_name'
          ? attr.attributes.feature_name
          : extractValue('grid_number'),
    });
  };
}

function convertToBaseUnits(list) {
  const groupSettingMap = list.reduce((map, value) => {
    const setting = conversionSettingMap[value.units];
    if (setting) {
      const previousSetting = map.get(setting.group);
      if (!previousSetting || previousSetting.priority < setting.priority) {
        map.set(setting.group, setting);
      }
    }
    return map;
  }, new Map());

  return list.map((value) => {
    const valueSetting = conversionSettingMap[value.units];
    if (valueSetting) {
      const groupSetting = groupSettingMap.get(valueSetting.group);
      if (groupSetting && groupSetting.priority !== valueSetting.priority) {
        const convertedDailyAmount = mathjs
          .unit(value.maximum_daily_amount, valueSetting.mathjsUnits)
          .toNumber(groupSetting.mathjsUnits);
        return _.extend({}, value, {
          maximum_daily_amount: round(convertedDailyAmount, DECIMAL_PLACES),
          units: groupSetting.units,
        });
      }
    }
    return value;
  });
}
function groupSortedBy(property) {
  return (list, value) => {
    if (
      list.length > 0 &&
      list[list.length - 1][0][property] === value[property]
    ) {
      list[list.length - 1].push(value);
    } else {
      list.push([value]);
    }
    return list;
  };
}

function naturalCmpBy(property) {
  if (Array.isArray(property)) {
    return (attr1, attr2) => {
      const property1 = property.filter((prop) => !!attr1[prop]);
      const property2 = property.filter((prop) => !!attr2[prop]);
      return _s.naturalCmp(attr1[property1[0]], attr2[property2[0]]);
    };
  }
  return (attr1, attr2) => _s.naturalCmp(attr1[property], attr2[property]);
}

function checkCasNumberVariability(attrs) {
  const filteredAttrs = attrs
    .map((attr) => attr.cas_number)
    .filter((casNumber) => casNumber);

  for (let i = 1; i < filteredAttrs.length; i += 1) {
    if (filteredAttrs[i - 1] !== filteredAttrs[i]) {
      return true;
    }
  }
  return false;
}

function groupByCasNumber(list) {
  const considerCasNumber = checkCasNumberVariability(list);
  if (considerCasNumber) {
    const groupedByCasNumber = [];
    list
      .reduce((map, value) => {
        const group = map.get(value.cas_number);
        if (group) {
          group.push(value);
        } else {
          map.set(value.cas_number, [value]);
        }
        return map;
      }, new Map())
      .forEach((value) => groupedByCasNumber.push(value));
    return groupedByCasNumber;
  }
  return [list];
}

function flatReducer(result, group) {
  result.push(...group);
  return result;
}

function groupByUnits(list) {
  const result = [];
  list
    .reduce((map, value) => {
      const group = map.get(value.units);
      if (group) {
        group.push(value);
      } else {
        map.set(value.units, [value]);
      }
      return map;
    }, new Map())
    .forEach((value) => result.push(value));
  return result;
}

function generateAttributeKey(value, hmbpChemicalsLocationField) {
  const chemicalAttrs = value['chemical-attrs'];
  let chemicalAttrsKey = '';
  let isRadioActive;
  if (chemicalAttrs) {
    chemicalAttrsKey = _.chain(chemicalAttrs)
      .sortBy((attr) => attr.key)
      .map((attr) => (attr.selected ? 'y' : 'n'))
      .reduce((attr1, attr2) => attr1.concat(attr2), '')
      .value();
    const radioActive = _.find(
      chemicalAttrs,
      (attr) => attr.key === 'radio_active',
    );
    if (radioActive && radioActive.selected) {
      isRadioActive = true;
    }
  }

  if (chemicalAttrs) {
    chemicalAttrsKey = _.chain(chemicalAttrs)
      .sortBy((attr) => attr.key)
      .map((attr) => (attr.selected ? 'y' : 'n'))
      .reduce((attr1, attr2) => attr1.concat(attr2), '')
      .value();
  }

  const criteriaList = [
    value.chemical_name,
    value.common_name,
    value.physical_state,
    value.hazardous_material_type,
    isRadioActive ? value.curies : '',
    value.state_waste_code,
    value.days_on_site,
    value.storage_pressure,
    value.storage_temperature,
    value.chemical_description_comment,
    value.us_epa_substance,
    value.DOT_hazard_classification_ID,
    value.average_daily_amount,
    value.annual_waste_amount,
    value.cers_chemical_library_id,
    value.primary_fire_code_hazard_class,
    value.secondary_fire_code_hazard_class,
    value.third_fire_code_hazard_class,
    value.fourth_fire_code_hazard_class,
    value.fifth_fire_code_hazard_class,
    value.sixth_fire_code_hazard_class,
    value.seventh_fire_code_hazard_class,
    value.eighth_fire_code_hazard_class,
    chemicalAttrsKey,
  ];

  if (hmbpChemicalsLocationField === 'building_name') {
    criteriaList.push(value.location);
  }

  return criteriaList.join('►');
}

function replaceSelectTypeValues(collection, headerModel) {
  const optionMapper = (map, option) => {
    map.set(option.key, option.label);
    return map;
  };
  const selectOptionMap = headerModel
    .get('value')
    .filter((val) => val.type === 'select' || val.type === 'multiselect')
    .reduce((map, val) => {
      map.set(val.key, val.options.reduce(optionMapper, new Map()));
      return map;
    }, new Map());

  collection.models.forEach((attr) => {
    let selectValue;
    const value = attr.get('value').map((item) => {
      switch (item.type) {
        case 'multiselect':
        case 'select':
          if (Array.isArray(item.value)) {
            selectValue = item.value
              .map((v) => selectOptionMap.get(item.key).get(v))
              .join(', ');
          } else {
            selectValue = selectOptionMap.get(item.key).get(item.value);
          }
          return _.extend({}, item, { value: selectValue });
        default:
          return item;
      }
    });
    attr.set('value', value);
    return attr;
  });
  return collection;
}

function groupByKey(hmbpChemicalsLocationField) {
  return (preparedAttrs) => {
    const groupedAttrs = [];

    preparedAttrs
      .reduce((map, value, index) => {
        const key = generateAttributeKey(value, hmbpChemicalsLocationField);
        if (Number.isNaN(value.maximum_daily_amount)) {
          return map.set(`${key}-${index}`, value);
        }

        const list = map.get(key);
        if (list) {
          map.set(key, list.concat([value]));
        } else {
          map.set(key, [value]);
        }
        return map;
      }, new Map())
      .forEach((value) => groupedAttrs.push(value));
    return groupedAttrs;
  };
}

function findMaximumExcessByLength(attrs, key) {
  let excessEl = attrs[0];

  attrs.forEach((attr, index) => {
    if (
      index !== 0 &&
      attr[key] &&
      excessEl[key] &&
      attr[key].length > excessEl[key].length
    ) {
      excessEl = attr;
    }
  });

  return excessEl;
}

function findLowestConstraint() {
  let lowestLengthItem = lengthConstraints[0];

  lengthConstraints.forEach((constraint, index) => {
    if (index !== 0 && constraint.length < lowestLengthItem.length) {
      lowestLengthItem = constraint;
    }
  });

  return lowestLengthItem;
}

function findLengthByKey(attrs, key) {
  const valueSet = attrs.reduce((set, attr) => {
    if (attr[key]) {
      set.add(attr[key]);
    }
    return set;
  }, new Set());

  return convertSetToArray(valueSet).join(',').length;
}

function findDuplicatesIncorrectLength(attrs, key, constraints) {
  const grouped = [];
  const length = findLengthByKey(attrs, key);

  if (length > constraints) {
    const excessEl = findMaximumExcessByLength(attrs, key);
    const excessElIndex = attrs.findIndex((attr) => attr === excessEl);
    attrs.splice(excessElIndex, 1);
    grouped.push(attrs, [excessEl]);
  } else {
    return false;
  }

  return grouped;
}

function groupByLength(attrs) {
  let groupedAttrs = [];

  let locationLength = findLengthByKey(attrs, 'location');
  let gridNumberLength = findLengthByKey(attrs, 'grid_number');

  let excessEl;
  const attrEqualsExcessEl = (attr) => attr === excessEl;
  while (locationLength > 140 || gridNumberLength > 15) {
    if (locationLength > 140) {
      excessEl = findMaximumExcessByLength(attrs, 'location');
    } else if (gridNumberLength > 15) {
      excessEl = findMaximumExcessByLength(attrs, 'grid_number');
    }

    const excessElIndex = attrs.findIndex(attrEqualsExcessEl);
    attrs.splice(excessElIndex, 1);
    groupedAttrs.push([excessEl]);

    locationLength = findLengthByKey(attrs, 'location');
    gridNumberLength = findLengthByKey(attrs, 'grid_number');
  }

  if (groupedAttrs.length) {
    if (groupedAttrs.length > 1) {
      const lowestConstraint = findLowestConstraint();
      const duplicatesIncorrectLength = findDuplicatesIncorrectLength(
        _.flatten(groupedAttrs),
        lowestConstraint.name,
        lowestConstraint.length,
      );
      if (duplicatesIncorrectLength) {
        groupedAttrs = duplicatesIncorrectLength;
        groupedAttrs.splice(0, 0, attrs);

        return groupedAttrs;
      }
    }

    const preparedAttrs = groupByKey()(_.flatten(groupedAttrs));
    preparedAttrs.splice(0, 0, attrs);

    return preparedAttrs;
  }

  return [attrs];
}

export {
  checkCasNumberVariability,
  extractLeaderlineOptions,
  calculateMaximumDailyAmount,
  convertToBaseUnits,
  generateAttributeKey,
  miniMapMapper,
  groupByUnits,
  replaceSelectTypeValues,
  hmbpTableMapper,
  hmbpTableRemapper,
  groupByKey,
  groupByCasNumber,
  flatReducer,
  groupSortedBy,
  naturalCmpBy,
  groupByLength,
};
