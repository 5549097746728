import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import _get from 'lodash.get';
import update from 'immutability-helper';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function withDragDropContext(WrappedComponent) {
  class innerComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isDragging: false,
      };
      this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
      const { onDragEnd, formSubmissionDraft } = this.props;
      const { destination, source } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const originGroupId = source.droppableId;
      const originIdx = source.index;
      const destinationIdx = destination.index;
      const draggedItem = _get(
        formSubmissionDraft.groups,
        `${originGroupId}.${originIdx}`,
      );

      let destinationGroupId = destination.droppableId;
      let nextFormSubmissionDraft;

      if (originGroupId !== destinationGroupId) {
        destinationGroupId =
          destinationGroupId === 'null' ? null : destinationGroupId;
        nextFormSubmissionDraft = update(formSubmissionDraft, {
          groups: {
            [originGroupId]: {
              $splice: [[originIdx, 1]],
            },
            [destinationGroupId]: (d) =>
              d
                ? update(d, {
                    $splice: [
                      [
                        destinationIdx,
                        0,
                        {
                          ...draggedItem,
                          groupId: destinationGroupId,
                        },
                      ],
                    ],
                  })
                : [
                    {
                      ...draggedItem,
                      groupId: destinationGroupId,
                    },
                  ],
          },
        });
      } else {
        let nextGroupCollection = update(
          formSubmissionDraft.groups[originGroupId],
          {
            $splice: [[originIdx, 1]],
          },
        );
        nextGroupCollection = update(nextGroupCollection, {
          $splice: [[destinationIdx, 0, draggedItem]],
        });
        nextFormSubmissionDraft = update(formSubmissionDraft, {
          groups: {
            [originGroupId]: {
              $set: nextGroupCollection,
            },
          },
        });
      }

      onDragEnd(nextFormSubmissionDraft, draggedItem.id);
    }

    render() {
      const { isDragging } = this.state;
      return (
        <DragDropContext
          onDragEnd={(result) =>
            this.setState({ isDragging: false }, () => this.onDragEnd(result))
          }
          onBeforeCapture={() => this.setState({ isDragging: true })}
        >
          <WrappedComponent isDragging={isDragging} {...this.props} />
        </DragDropContext>
      );
    }
  }

  innerComponent.propTypes = {
    formSubmissionDraft: PropTypes.shape({
      groups: PropTypes.shape({}),
    }).isRequired,
    onDragEnd: PropTypes.func.isRequired,
  };

  return innerComponent;
}
