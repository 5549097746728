/* eslint-disable */
var StyleModel = require('../models_collections/styles/styleModel');

var WebMapShapeModel = Backbone.Model.extend({
  defaults: {
    styleModel: new StyleModel(),
    featureGroup: new L.FeatureGroup(),
    liderlineGroup: new L.FeatureGroup(),
    hiddenLiderlineArray: [],
    decoratorMarkerGroup: undefined,
    labelOffsetCoordinates: undefined,
    rotation: undefined,
    geography: undefined,
    labelContent: undefined,
    type: undefined,
  },
});

module.exports = WebMapShapeModel;
