/* eslint-disable */
let apiCaller = require('../../../../../../../../apiCaller');

let DMRCollection = Backbone.Collection.extend({
  url() {
    let APP = require('../../../../../../../../config');
    return apiCaller.getDMRUrl(APP.projectId);
  },
});

Backbone.addSingletonToCollection(DMRCollection);
module.exports = DMRCollection;
