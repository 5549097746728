/* eslint-disable */
let apiCaller = require('../apiCaller');

let SamplingParametersCollection = Backbone.Collection.extend({
  url() {
    let APP = require('../config');
    return apiCaller.getSamplingParametersUrl(APP.projectId);
  },

  comparator: 'parameter_slug',
});

Backbone.addSingletonToCollection(SamplingParametersCollection);
module.exports = SamplingParametersCollection;
