/* eslint-disable */
var BaseContactItemView = require('./BaseContactItemView');
var PhoneNumberContactItemView = require('./PhoneNumberContactItemView');
var EmailContactItemView = require('./EmailContactItemView');

var ContactItemViewFactory = {
  create(contactModel) {
    return this._getContactItemViewClass(contactModel);
  },

  _getContactItemViewClass(contactModel) {
    switch (
      contactModel.has('category') ? contactModel.get('category') : null
    ) {
      case 'email':
        return EmailContactItemView;
      case 'phone-number':
        return PhoneNumberContactItemView;
      default:
        return BaseContactItemView;
    }
  },
};

module.exports = ContactItemViewFactory;
