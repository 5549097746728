import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  ConfirmDialog,
  LoadingIndicator,
  Modal,
  NoLocationsDefined,
  SaveButton,
  Select,
} from '../../../elements';
import { ParameterExpandableSection } from './ParameterExpandableSection';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class EditParametersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      samplingParameterToAdd: null,
      shouldShowDirtyCloseConfirmation: false,
    };
    this.addSamplingParameter = this.addSamplingParameter.bind(this);
    this.requestModalClose = this.requestModalClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { parameters } = this.props;
    if (parameters.length > prevProps.parameters.length) {
      // Clear the select box on successful parameter creation
      this.setState({
        samplingParameterToAdd: null,
      });
    }
  }

  addSamplingParameter(e) {
    const { samplingParameterToAdd } = this.state;
    const { addSamplingParameter } = this.props;
    e.preventDefault(); // Don't actually submit the form
    if (samplingParameterToAdd) {
      addSamplingParameter(samplingParameterToAdd.value);
    }
  }

  requestModalClose() {
    const { formSaveState, onClose } = this.props;
    if (formSaveState === SaveState.DIRTY) {
      this.setState({ shouldShowDirtyCloseConfirmation: true });
    } else {
      onClose();
    }
  }

  render() {
    const {
      addSamplingParameterAtMonitoringLocation,
      availableSamplingParameters,
      canUpload,
      deleteSamplingParameter,
      deleteSamplingParameterAtMonitoringLocation,
      formSaveState,
      goToMapsURL,
      isLoading,
      monitoringLocations,
      onClose,
      onOpenUpload,
      open,
      parameters,
      stageSamplingParameterEdit,
      submitForm,
    } = this.props;

    const { samplingParameterToAdd, shouldShowDirtyCloseConfirmation } =
      this.state;

    const availableSamplingParametersToOptions =
      availableSamplingParameters.map((param) => {
        const casNumberLabel = param.cas_number ? ` (${param.cas_number})` : '';
        return {
          label: `${param.display_text}${casNumberLabel}`,
          value: param.slug,
        };
      });
    // QA locations are only for sampling results,
    // they are monitored as needed, and don't need any setup.
    const nonQaDischargeLocations = monitoringLocations.filter(
      (l) => !l.sampleType && !l.parentId,
    );

    return (
      <Modal
        className="wastewater-edit-parameters-modal"
        fullWidth
        header={<h4>Water: Sampling Parameters</h4>}
        footer={
          <>
            <Button
              color="secondary"
              disabled={formSaveState === SaveState.DIRTY}
              onClick={this.requestModalClose}
            >
              Close
            </Button>
            <span className="ph2" />
            <SaveButton onSave={submitForm} saveState={formSaveState} />
          </>
        }
        open={open}
        onClose={this.requestModalClose}
      >
        <>
          {isLoading && <LoadingIndicator />}
          {!nonQaDischargeLocations.length && (
            <NoLocationsDefined
              goToMapsURL={goToMapsURL}
              message="You must define all water monitoring locations before configuring water sampling parameters."
            />
          )}
          {!!nonQaDischargeLocations.length && (
            <>
              <div className="section--wrapper">
                <div className="section--header">Add sampling parameters</div>
                <form onSubmit={this.addSamplingParameter}>
                  <div className="section--body db flex-ns items-center">
                    <div className="pr3-ns w-100 mw6">
                      <Select
                        options={availableSamplingParametersToOptions}
                        onChange={(opt) =>
                          this.setState({ samplingParameterToAdd: opt })
                        }
                        placeholder="Select a parameter"
                        value={samplingParameterToAdd}
                      />
                    </div>
                    <div className="mt3 mt0-ns">
                      <Button
                        className="w-100 w-auto-ns"
                        onClick={this.addSamplingParameter}
                      >
                        Add new parameter
                      </Button>
                      {canUpload && onOpenUpload && (
                        <Button
                          className="w-100 w-auto-ns"
                          color="secondary"
                          onClick={onOpenUpload}
                        >
                          Upload parameter settings
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="section--wrapper section--wrapper__no-h-padding">
                <div className="section--header">Sampling parameters</div>
                {!parameters.length && (
                  <div className="w-100 pa4 dark-gray tc">
                    <div className="mw6 lh-copy center">
                      There are no water sampling parameters currently defined.
                    </div>
                  </div>
                )}
                {!!parameters.length &&
                  parameters.map((p) => (
                    <ParameterExpandableSection
                      key={p.parameter_slug}
                      locations={nonQaDischargeLocations}
                      onAddLimitGroup={addSamplingParameterAtMonitoringLocation}
                      onDeleteParameter={deleteSamplingParameter}
                      onDeleteParameterMonitoringLocation={
                        deleteSamplingParameterAtMonitoringLocation
                      }
                      parameterId={p.id}
                      parameterLimitType={p.limitType}
                      parameterMonitoringLocations={p.sp_monitoring_locations}
                      parameterSlug={p.parameter_slug}
                      stageSamplingParameterEdit={stageSamplingParameterEdit}
                    />
                  ))}
              </div>
            </>
          )}
          <ConfirmDialog
            confirmButtonText="Close without saving"
            danger
            description="Are you sure you want to close without saving?"
            open={shouldShowDirtyCloseConfirmation}
            onCancelled={() =>
              this.setState({ shouldShowDirtyCloseConfirmation: false })
            }
            onConfirmed={() =>
              this.setState(
                { shouldShowDirtyCloseConfirmation: false },
                onClose,
              )
            }
            title="Confirm"
          />
        </>
      </Modal>
    );
  }
}

EditParametersModal.propTypes = {
  addSamplingParameter: PropTypes.func.isRequired,
  addSamplingParameterAtMonitoringLocation: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  availableSamplingParameters: PropTypes.arrayOf(PropTypes.object),
  canUpload: PropTypes.bool,
  deleteSamplingParameter: PropTypes.func.isRequired,
  deleteSamplingParameterAtMonitoringLocation: PropTypes.func.isRequired,
  formSaveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  goToMapsURL: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpenUpload: PropTypes.func,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  parameters: PropTypes.arrayOf(PropTypes.object),
  stageSamplingParameterEdit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

EditParametersModal.defaultProps = {
  canUpload: false,
  availableSamplingParameters: [],
  monitoringLocations: [],
  onOpenUpload: null,
  parameters: [],
};
