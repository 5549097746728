import { OnboardingApi } from '../apiClient';

type OnboardingProjectSections = [string, OnboardingApi.OnboardingSection[]];

interface OnboardingSectionsByProject {
  [projectName: string]: OnboardingApi.OnboardingSection[];
}

export function sortAndOrderAndFilterOnboardingProjectSections(
  onboardingSections: OnboardingApi.OnboardingSection[],
  statusFilter: OnboardingApi.OnboardingDocumentStatus | null,
): OnboardingProjectSections[] {
  const sectionsWithFilteredDocumentRequests = statusFilter
    ? onboardingSections.map((section) => ({
        ...section,
        documentRequests: section.documentRequests.filter(
          (documentRequest) => documentRequest.status === statusFilter,
        ),
      }))
    : onboardingSections;

  const sectionsByProject = Object.entries(
    sectionsWithFilteredDocumentRequests.reduce<OnboardingSectionsByProject>(
      (acc, section) => {
        if (statusFilter != null && section.documentRequests.length === 0) {
          return acc;
        }
        const projectAlreadyIncluded = acc[section.projectName] != null;
        if (projectAlreadyIncluded) {
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          acc[section.projectName].push(section);
        } else {
          acc[section.projectName] = [section];
        }
        return acc;
      },
      {},
    ),
  );

  sectionsByProject?.forEach((projectSections) => {
    const sections = projectSections[1];
    sections.sort((a, b) => {
      if (a.moduleName === 'other') return 1;
      if (b.moduleName === 'other') return -1;
      return a.moduleName > b.moduleName ? 1 : -1;
    });
  });

  return sectionsByProject;
}
