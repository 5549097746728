var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (name) {
      pug_html = pug_html + "\u003Cdiv class=\"panel-heading\"\u003E\u003Cdiv class=\"panel-title\"\u003ETag Types\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cdiv class=\"form-horizontal\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"col-xs-3 control-label\" for=\"tag-type-name\"\u003EName\u003C\u002Flabel\u003E\u003Cdiv class=\"col-xs-9\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"tag-type-name\" type=\"text\""+pug.attr("value", name, true, true)+" name=\"name\"") + "\u003E\u003Cspan class=\"btn btn-success input-group-addon\" id=\"create-tag\"\u003ECreate\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"tagTypeList\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;