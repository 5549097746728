import React from 'react';

import { MapistryTooltip } from '../../../elements';

import { capitalizeFirstLetter } from '../../../../utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function cellWithAppliedLimits(
  limitsToApplyToValue,
  valueToCheck,
  unitsForValue,
  formatValue,
) {
  const WARNING_THRESHOLD_FACTOR = 0.9;
  const unitsHelper = {
    gallons: {
      displayUnits: 'gallons',
      gallons: (valueToConvert) => valueToConvert,
      millions_of_gallons: (valueToConvert) => valueToConvert / 10 ** 6,
    },
    gallons_per_minute: {
      displayUnits: 'gpm',
      gallons_per_minute: (valueToConvert) => valueToConvert,
    },
    millions_of_gallons: {
      displayUnits: 'millions of gallons',
      gallons: (valueToConvert) => valueToConvert * 10 ** 6,
      millions_of_gallons: (valueToConvert) => valueToConvert,
    },
  };

  const limitsWithConvertedValues = limitsToApplyToValue.map((limit) => ({
    ...limit,
    convertedValue: unitsHelper[limit.units][unitsForValue](limit.limitValue),
  }));
  const limitWarnings = limitsWithConvertedValues.filter(
    (limit) =>
      valueToCheck > limit.convertedValue * WARNING_THRESHOLD_FACTOR &&
      valueToCheck <= limit.convertedValue,
  );
  const limitErrors = limitsWithConvertedValues.filter(
    (limit) => valueToCheck > limit.convertedValue,
  );

  if (limitWarnings.length === 0 && limitErrors.length === 0) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-filename-extension
    return <div>{formatValue(valueToCheck)}</div>;
  }

  const colorClass =
    limitErrors.length > 0 ? 'color-error-red' : 'color-warning-yellow';
  const tooltipMessages = limitErrors
    .concat(limitWarnings)
    .map(
      (limit) =>
        `${capitalizeFirstLetter(limit.calculationType)}: ${limit.limitValue} ${
          unitsHelper[limit.units].displayUnits
        }`,
    );
  const tooltipTitle =
    tooltipMessages.length === 1 ? (
      tooltipMessages[0]
    ) : (
      <span
        // Need to display multiple lines in the tooltip
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: tooltipMessages.join('<br />'),
        }}
      />
    );
  return (
    <div>
      <MapistryTooltip title={tooltipTitle} placement="top">
        <span className={`font-weight-500 ${colorClass}`}>
          {formatValue(valueToCheck)}
        </span>
      </MapistryTooltip>
    </div>
  );
}
