/* eslint-disable */
var _ = require('underscore');
var GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');

var OregonSamplingEventParameterView = GenericSamplingEventParameterView.extend(
  {
    onShow: function () {
      this._showLocationRows();
    },

    _afterOnShow: function () {
      // Do nothing
    },

    _isRequiredSamplingParameter: function (parameterSlug) {
      return this.sectorData.isRequiredSamplingParameter({ parameterSlug });
    },
  },
);

module.exports = OregonSamplingEventParameterView;
