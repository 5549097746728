var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (planName, spccUpload) {
      pug_html = pug_html + "\u003Cdiv class=\"flex right-col-heading justify-content-space-between heading\"\u003E\u003Cdiv class=\"flex align-items-center\"\u003E\u003Cdiv class=\"list-header\"\u003E" + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex align-items-center justify-content-flex-end\"\u003E";
if (spccUpload) {
pug_html = pug_html + "\u003Ca" + (" class=\"flex align-items-center margin-right-20 download-spcc-plan\""+" href=\"#\" rel=\"tipsy\""+pug.attr("title", 'Download the latest version of your ' + planName, true, true)) + "\u003E\u003Csvg class=\"fa-cloud-download download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan class=\"font-size-16 margin-left-10\"\u003E" + (pug.escape(null == (pug_interp = 'Download ' + planName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca" + (" class=\"flex align-items-center upload-spcc-plan\""+" href=\"#\" rel=\"tipsy\""+pug.attr("title", 'Upload a new version of your ' + planName, true, true)) + "\u003E\u003Csvg class=\"upload upload-pdf fa-cloud-upload\"\u003E\u003Cuse xlink:href=\"#upload\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan class=\"font-size-16 padding-left-10\"\u003E" + (pug.escape(null == (pug_interp = 'Upload ' + planName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ci class=\"fa fa-spinner fa-spin upload-spinner\" style=\"display: none\"\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"flex align-items-center upload-spcc-plan\""+" href=\"#\" rel=\"tipsy\""+pug.attr("title", 'Upload a new version of your ' + planName, true, true)) + "\u003E\u003Csvg class=\"upload upload-pdf fa-cloud-upload\"\u003E\u003Cuse xlink:href=\"#upload\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan class=\"font-size-16 padding-left-10\"\u003E" + (pug.escape(null == (pug_interp = 'Upload ' + planName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ci class=\"fa fa-spinner fa-spin upload-spinner\" style=\"display: none\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Cinput id=\"upload-spcc-pdf\"  type=\"file\" name=\"file\" accept=\".pdf,.doc,.docx,.docm,.dtox,.dotm,.dot,.docb,.txt\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "planName" in locals_for_with ?
        locals_for_with.planName :
        typeof planName !== 'undefined' ? planName : undefined, "spccUpload" in locals_for_with ?
        locals_for_with.spccUpload :
        typeof spccUpload !== 'undefined' ? spccUpload : undefined));
    ;;return pug_html;};
module.exports = template;