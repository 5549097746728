/**
 * @deprecated - Use public/js/utils/calendarEvents.js
 * Taken from: Mapistry/server/lib/intervals/intervalFrequencyUtils.js
 */
const IntervalFrequency = {
  NEVER: 'never',
  HOUR: 'hour',
  DAY: 'day',
  TWICE_WEEK: 'twice-week',
  WEEK: 'week',
  TWICE_MONTH: 'twice-month',
  MONTH: 'month',
  QUARTER: 'quarter',
  TWICE_BIANNUAL: 'twice-biannual',
  BIANNUAL: 'biannual',
  YEAR: 'year',
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default IntervalFrequency;
