import APP from '@monorepo/old-web/js/config';
import { useProjectUsers } from '@monorepo/shared/hooks/useProjectUsers';
import PropTypes from 'prop-types';
import React from 'react';
import { isNullOrUndefined } from '../../../utils';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const SignatureCell = (props) => {
  const { statistic } = props;
  const { users } = useProjectUsers({
    projectId: APP.projectId,
    includeArchived: true,
  });
  if (isNullOrUndefined(statistic.totalLoggedValue)) {
    return <div className="emission-tracking-list__item">--</div>;
  }

  const parsedValue = JSON.parse(statistic.totalLoggedValue);
  const matchingUser = users?.find(
    (user) => user.userId === parsedValue.user_id,
  );

  return (
    <div className="emission-tracking-list__item">
      <span>
        {matchingUser ? (
          <img alt="Signature" src={matchingUser.signature} />
        ) : (
          ''
        )}
      </span>
    </div>
  );
};

SignatureCell.propTypes = {
  statistic: PropTypes.shape({
    totalLoggedValue: PropTypes.number,
  }),
};

SignatureCell.defaultProps = {
  statistic: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SignatureCell;
