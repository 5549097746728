/* eslint-disable */
import truncate from 'lodash.truncate';
import { FormSlugs } from 'mapistry-shared';

let moment = require('moment');
let APP = require('../config');

let Project = Backbone.Model.extend({
  displayName: function () {
    return truncate(this.get('name'), { length: 25 });
  },

  isSwpppProject: function () {
    return this.get('swppp_enabled');
  },

  isSpccProject: function () {
    return this.get('spcc_enabled');
  },

  isStormwaterLevel1Enabled: function () {
    return this.get('stormwater_level1_enabled');
  },

  isStormwaterComplianceEnabled: function () {
    return this.get('stormwater_enabled');
  },

  isHmbpEnabled: function () {
    return this.get('hmbp_enabled');
  },

  isBmpEnabled() {
    return this.get('bmp_module_enabled');
  },

  isWastewaterEnabled: function () {
    return this.get('wastewater_enabled');
  },

  isIndustrial: function () {
    return this.get('industry_type') === 'industrial';
  },

  getStartDate: function () {
    return new Date(this.get('start_date'));
  },

  getSwpppFormSlug: function () {
    return FormSlugs.getSwppp(
      this.get('state'),
      this.get('industry_type'),
      this.get('is_federal'),
    );
  },

  getSicCodeSlugs: function () {
    let sicCodeSlugs = this.get('sic_codes');
    return sicCodeSlugs ? sicCodeSlugs.split(',') : [];
  },

  getPermitDsnSlugs: function () {
    let permitDsns = this.get('permits');
    return permitDsns ? permitDsns.split(',') : [];
  },

  getIndustrialActivitySlugs: function () {
    let industrialActivitySlugs = this.get('industrial_activities');
    return industrialActivitySlugs
      ? this.get('industrial_activities').split(',')
      : [];
  },

  hasAddress: function () {
    const address = this.get('site_address');
    const city = this.get('site_city');
    const zip = this.get('site_zip');

    return address || city || zip;
  },

  getFullAddress: function () {
    const address = this.get('site_address');
    const city = this.get('site_city');
    const state = this.get('state');
    const zip = this.get('site_zip');

    return this.formatAddress(address, city, state, zip);
  },

  formatAddress: function (address, city, state, zip) {
    return `${address ? `${address},` : ''} ${city ? `${city},` : ''} ${
      state && state !== 'other' ? state.toUpperCase() : ''
    } ${zip ? zip : ''}`
      .replace(/,\s*$|\s+/g, ' ')
      .trim();
  },
});

let ProjectCollection = Backbone.Collection.extend({
  model: Project,
  url: APP.apiUrl + '/api/projects',

  comparator: function (project) {
    return project.get('name').toLowerCase();
  },

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  getCurrent: function () {
    return this.get(APP.projectId);
  },
});

Backbone.addSingletonToCollection(ProjectCollection);

module.exports = ProjectCollection;
