/* eslint-disable */
import apiCaller from '@monorepo/old-web/js/apiCaller';
import { ComponentWrapper } from '@monorepo/old-web/js/components/componentWrapper';
import FormSubmissionContainer from '@monorepo/old-web/js/components/views/formSubmissionEditor/FormSubmissionContainer';
import APP from '@monorepo/old-web/js/config';
import { loadProject } from '@monorepo/old-web/js/dashboards/projects/loader';
import CopyFormView from '@monorepo/old-web/js/forms/CopyFormView';
import InfoView from '@monorepo/old-web/js/modals/infoView';
import { FormSlugs } from 'mapistry-shared';
import React from 'react';
// An old modules that are imported in so many places with Common JS require statement.
// Since it will be obsolete not changing them to ES6 default export and not updating all their Common JS imports.
/* eslint-disable import/default */
import BaseController from '@monorepo/old-web/js/BaseController';
import NavbarView from '@monorepo/old-web/js/dashboards/navbar/NavbarView';
import ProjectCollection from '@monorepo/old-web/js/models_collections/projectCollection';
import rootLayoutView from '@monorepo/old-web/js/rootLayoutView';
/* eslint-enable import/default */
import { ReactRoutesView } from './ReactRoutesView';

const infoView = InfoView.singleton();
const projectCollection = ProjectCollection.singleton();

const ReactBackboneController = BaseController.extend({
  async showTopLevelReactRoute() {
    try {
      await this.enforceAuthentication();
      const topLevelPath = Backbone.history.location.pathname
        .split('/')
        .filter(Boolean)[0];
      const navbarView = new NavbarView({
        activeClass: topLevelPath,
      });
      rootLayoutView.navbar.show(navbarView);
      this._showReactRouter();
    } catch (error) {
      this.handleError(error);
    }
  },

  async showProjectReactRoute(projectId) {
    try {
      await loadProject(projectId);
      this._showReactRouter();
    } catch (error) {
      this.handleError(error);
    }
  },

  async _showReactRouter() {
    try {
      const location = Backbone.history.fragment;
      const { currentView = {} } = rootLayoutView.content;
      if (currentView instanceof ReactRoutesView) {
        currentView.setLocation(location);
      } else {
        rootLayoutView.content.show(new ReactRoutesView(location));
      }
    } catch (error) {
      this.handleError(error);
    }
  },

  showFormWithoutEvent(projectId, templateSlug, submissionSlug, calendarName) {
    APP.projectId = projectId;
    const redirectFunc = FormSlugs.isSwpppSubmissionSlug(templateSlug)
      ? this._redirectToFormThatCanBeCopied
      : this._redirectToCopyPageOrSubmissionPage;
    return this.enforceAuthentication()
      .then(() =>
        redirectFunc.call(
          this,
          projectId,
          templateSlug,
          submissionSlug,
          calendarName,
        ),
      )
      .catch(this.handleError.bind(this));
  },

  showFormWithEvent(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
    eventDate,
    eventNumber,
  ) {
    APP.projectId = projectId;
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const router = require('@monorepo/old-web/js/router');

    return this.enforceAuthentication()
      .then(() =>
        router.goToFormSubmissionEditor({
          projectId,
          templateSlug,
          submissionSlug,
          shouldReplace: true,
          calendarName,
          eventDate,
          eventNumber,
        }),
      )
      .catch(this.handleError.bind(this));
  },

  showFormSubmissionStencil(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
  ) {
    this._showFormSubmission({
      projectId,
      templateSlug,
      submissionSlug,
      isStencil: true,
      calendarName,
    });
  },

  showFormSubmissionGroup(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
    groupId,
  ) {
    this._showFormSubmission({
      projectId,
      templateSlug,
      submissionSlug,
      calendarName,
      groupId,
    });
  },

  showFormSubmissionWithoutEvent(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
  ) {
    this._showFormSubmission({
      projectId,
      templateSlug,
      submissionSlug,
      calendarName,
    });
  },

  showFormSubmissionWithEvent(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
    eventDate,
    eventNumber,
  ) {
    this._showFormSubmission({
      projectId,
      templateSlug,
      submissionSlug,
      calendarName,
      eventDate,
      eventNumber,
    });
  },

  _showFormSubmission(options) {
    const {
      projectId,
      templateSlug,
      submissionSlug,
      calendarName,
      eventDate,
      eventNumber,
      groupId,
      isStencil = false,
    } = options;
    this.enforceAuthentication()
      .then(() => {
        APP.projectId = projectId;
        const submissionEditor = new ComponentWrapper(
          (
            <FormSubmissionContainer
              calendarName={calendarName}
              eventDate={eventDate}
              eventNumber={eventNumber}
              groupId={groupId ? parseInt(groupId, 10) : null}
              isStencil={isStencil}
              projectId={projectId}
              submissionSlug={submissionSlug}
              templateSlug={templateSlug}
            />
          ),
        );
        rootLayoutView.navbar.show(new NavbarView());
        rootLayoutView.content.show(submissionEditor);
      })
      .catch(this.handleError.bind(this));
  },

  isDisabled(text) {
    if (
      !this.projectModel.isStormwaterComplianceEnabled() &&
      !this.projectModel.isBmpEnabled()
    ) {
      infoView.showModal({
        title: 'This Feature Is Not Included In Your Plan',
        text: `${text} Please contact Mapistry if you are interested! You can contact us with the chat box in the lower left hand corner of your screen.`,
      });
      return true;
    }
    return false;
  },

  _redirectToFormThatCanBeCopied(projectId, templateSlug, submissionSlug) {
    return this._getSimilarFormSubmissions(
      projectId,
      templateSlug,
      submissionSlug,
    ).then((relatedFormSubmissions) =>
      this._redirectToCopyPageOrSubmissionPage.bind(
        this,
        projectId,
        templateSlug,
        submissionSlug,
        undefined,
      )(relatedFormSubmissions),
    );
  },

  _getSimilarFormSubmissions(projectId, templateSlug, submissionSlug) {
    return apiCaller.getRelatedFormSubmissions(
      projectId,
      templateSlug,
      submissionSlug,
    );
  },

  _redirectToCopyPageOrSubmissionPage(
    projectId,
    templateSlug,
    submissionSlug,
    calendarName,
    submissions,
  ) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const router = require('@monorepo/old-web/js/router');

    if (!submissions || submissions.existing_submission) {
      return router.goToFormSubmissionEditor({
        projectId,
        templateSlug,
        submissionSlug,
        calendarName,
        shouldReplace: true,
      });
    }
    if (
      submissions.related_submissions &&
      submissions.related_submissions.length
    ) {
      return this._renderCopyFormPage(
        projectId,
        templateSlug,
        submissionSlug,
        submissions.related_submissions,
      );
    }
    return router.goToFormSubmissionEditor({
      projectId,
      templateSlug,
      submissionSlug,
      calendarName,
      shouldReplace: true,
    });
  },

  _renderCopyFormPage(
    projectId,
    templateSlug,
    submissionSlug,
    relatedSubmissions,
  ) {
    return projectCollection.fetchPromise().then(() => {
      rootLayoutView.content.show(
        new CopyFormView({
          projectId,
          templateSlug,
          submissionSlug,
          relatedSubmissions,
          projectCollection,
        }),
      );
    });
  },
});

export default ReactBackboneController;
