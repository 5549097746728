import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { Checkbox } from '@monorepo/shared/components/Checkbox';
import { Menu } from '@material-ui/core';
import _get from 'lodash.get';
import { IconButton } from '../../elements';

class FilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      checkedOptions: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { options, tab } = this.props;
    if (tab && (prevProps.tab !== tab || prevProps.options !== options)) {
      const parameterIds = tab.parameters.map((p) => p.id);
      const checkedOptions = options.reduce((acc, option) => {
        acc[option.id] = parameterIds.includes(option.id);
        return acc;
      }, {});
      this.setState({ checkedOptions });
    }
  }

  handleMenuButtonClicked(event) {
    this.setState({ menuAnchor: event.currentTarget });
  }

  handleMenuClosed() {
    const { checkedOptions } = this.state;
    const { onChange, options } = this.props;
    const enabledParameters = Object.keys(checkedOptions).reduce((acc, key) => {
      if (checkedOptions[key]) {
        const option = options.find((o) => o.id === key);
        acc.push({ id: option.id, type: option.type });
      }
      return acc;
    }, []);
    this.setState({ menuAnchor: null }, () => onChange(enabledParameters));
  }

  handleOptionChange(optionId) {
    const { checkedOptions } = this.state;
    this.setState({
      checkedOptions: {
        ...checkedOptions,
        [optionId]: !checkedOptions[optionId],
      },
    });
  }

  options() {
    const { checkedOptions } = this.state;
    const { options } = this.props;

    return options.map((option) => (
      <div
        key={option.id}
        className="emission-tracking-log-filter__item-wrapper"
      >
        <Checkbox
          label={option.label}
          isChecked={checkedOptions[option.id]}
          onChange={() => this.handleOptionChange(option.id)}
        />
      </div>
    ));
  }

  render() {
    const { menuAnchor } = this.state;
    const { tab } = this.props;

    return (
      <div className="emission-tracking-log-filter__wrapper">
        <IconButton onClick={(e) => this.handleMenuButtonClicked(e)}>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          {<SvgIcon identifier="filter" />}
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{
            paper: 'emission-tracking-log-filter__menu-root',
            list: 'emission-tracking-log-filter__menu-list',
          }}
          getContentAnchorEl={null}
          onClose={() => this.handleMenuClosed()}
          open={!!menuAnchor}
          transformOrigin={{
            vertical: 40,
            horizontal: 'right',
          }}
        >
          <div className="emission-tracking-log-filter__content-wrapper">
            <div className="emission-tracking-log-filter__title">
              {_get(tab, 'label')}
            </div>
            {this.options()}
          </div>
        </Menu>
      </div>
    );
  }
}

const OptionType = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
});

const TabType = PropTypes.shape({
  label: PropTypes.string,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
});

FilterMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(OptionType),
  tab: TabType,
};

FilterMenu.defaultProps = {
  options: [],
  tab: undefined,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FilterMenu;
