/* eslint-disable */
let RecordsSidebarCollectionView = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',
  className: 'list-unstyled',
  childView: require('./RecordsSidebarItemView'),
  childEvents: {
    'click-sidebar-item-link': '_selectNewSidebarItem',
  },

  selectFirstItem() {
    let first;
    if (!this._findSelected()) {
      first = this.collection.at(0);
      this._deselectAll();
      first.set({ selected: true });
    } else {
      first = this._findSelected();
    }
    first.get('showRecordsView')();
    first.trigger('select');
  },

  _findSelected() {
    return this.collection.findWhere({ selected: true });
  },

  _deselectAll() {
    this.collection.forEach((model) => {
      model.set({ selected: false });
      return model.trigger('deselect');
    });
  },

  _selectNewSidebarItem(selectedItem) {
    this._deselectAll();
    selectedItem.select();
    selectedItem.model.set({ selected: true });
  },
});

module.exports = RecordsSidebarCollectionView;
