/* eslint-disable */
import moment from 'moment';
import CONSTANTS from '../shared/constants';
import IntervalFrequency from './lib/IntervalFrequency';

const UTIL = {
  getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    let byteUnits = [' kB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  },

  // TODO: consider putting this in projectStateConfig.
  DATE: {
    getCalendarYearStartDate() {
      return moment.utc().startOf('year').format();
    },

    getCalendarYearEndDate() {
      return moment.utc().endOf('year').format();
    },

    getFormattedCalendarDate(utcString, formatType) {
      const calendarMoment = moment.utc(utcString);
      const formats = {
        day: 'DD MMMM',
        month: 'YYYY MMMM',
        biannual: 'YYYY MMMM',
        week: 'DD MMM',
        year: 'YYYY',
      };
      if (formats[formatType] != null) {
        return calendarMoment.format(formats[formatType]);
      }
      switch (formatType) {
        case IntervalFrequency.HOUR:
          return `${calendarMoment.format('HH')}00 hours`;
        case IntervalFrequency.QUARTER:
          return `${calendarMoment.format('YYYY')} Q${calendarMoment.format(
            'Q',
          )}`;
        default:
          return calendarMoment.format();
      }
    },
  },

  ATTRS: {
    rendersOnlyToOwningFeature: function (key) {
      return key == CONSTANTS.LIST_ATTR_DEFAULTS.CHEMICAL_HAZMAT_INV;
    },
  },

  IDENTIFIER: {
    generateUUIDv4: function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        },
      );
    },
  },
};

export default UTIL;
