/* eslint-disable */
import aggregateAverageRowTemplate from './templates/aggregateAverageRow.pug';

const AggregateAverageItemView = Backbone.Marionette.ItemView.extend({
  initialize: function (options = {}) {
    this.getMonthlyAverageDisplayText = options.getMonthlyAverageDisplayText;
    this.getQuarterlyAverageDisplayText =
      options.getQuarterlyAverageDisplayText;
  },

  modelEvents: {
    'change:aggregate_quarterly_average': 'render',
    'change:aggregate_monthly_average': 'render',
  },

  template: aggregateAverageRowTemplate,

  templateHelpers: function () {
    return {
      getMonthlyAverageDisplayText: this.getMonthlyAverageDisplayText,
      getQuarterlyAverageDisplayText: this.getQuarterlyAverageDisplayText,
    };
  },
});

module.exports = AggregateAverageItemView;
