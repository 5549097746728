var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (planName) {
      pug_html = pug_html + "\u003Cp class=\"padding-left-20\"\u003ENo " + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + " updates have been recorded. To add " + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + " updates, click the + to the right of \"" + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + " Update Log\"\u003C\u002Fp\u003E";
    }.call(this, "planName" in locals_for_with ?
        locals_for_with.planName :
        typeof planName !== 'undefined' ? planName : undefined));
    ;;return pug_html;};
module.exports = template;