/* eslint-disable */
var APP = require('../config');
var MapModel = require('./mapModel');

var MapCollection = Backbone.Collection.extend({
  model: MapModel,

  comparator: function (model) {
    return model.get('name').toLowerCase();
  },

  url: function () {
    return APP.apiUrl + '/projects/' + APP.projectId + '/maps';
  },

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  isNameAlreadyInUse: function (name) {
    var mapViewNamesArray = _.map(this.pluck('name'), (n) => n.toLowerCase());
    return _.contains(mapViewNamesArray, name.toLowerCase());
  },

  validateNewMapView: function (name) {
    var errors = [];

    if (!name) {
      errors.push('Map view name is required.');
    } else if (this.isNameAlreadyInUse(name)) {
      errors.push(
        'You already have a view named ' +
          name +
          '. Please choose a unique name.',
      );
    }

    return errors;
  },

  getSelectedMap(project) {
    let selectedMap = this.get(project.get('selected_map'));
    if (!selectedMap) {
      const selectedMapId = MapCollection.singleton().at(0);
      project.set('selected_map', selectedMapId);
      selectedMap = this.get(project.get('selected_map'));
    }
    return selectedMap;
  },
});

Backbone.addSingletonToCollection(MapCollection);

module.exports = MapCollection;
