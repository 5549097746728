import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { setWithMessage } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileRecordHelpers';
import { RecordValue } from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import {
  SamplingParameter,
  SamplingUnit,
} from '@monorepo/shared/types/industrial-stormwater-sector-data';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';

const allParameters = SamplingParameters.getAll() as SamplingParameter[];
const allUnits = SamplingUnits.getAll() as SamplingUnit[];

export function validateSamplingParameterUnits(
  unitFieldId: string,
  record: FlatfileRecord,
  parameterSlug: RecordValue,
): void {
  const unitSlug = record.get(unitFieldId);
  if (!parameterSlug) {
    return;
  }

  const parameterUnitSlug = SamplingParameters.getUnits(parameterSlug);
  if (parameterUnitSlug === unitSlug) {
    return;
  }

  if (!unitSlug) {
    if (!parameterUnitSlug) return;
    record.addError(unitFieldId, 'Units is required for this parameter');
    return;
  }

  if (!parameterUnitSlug) {
    const unitLabel = SamplingUnits.getDisplayText(unitSlug);
    setWithMessage(
      unitFieldId,
      record,
      '',
      `This parameter is unitless. Original value: ${unitLabel}`,
    );
    return;
  }

  const parameterUnit = allUnits.find((u) => u.slug === parameterUnitSlug);
  if (!parameterUnit) {
    record.addError(
      unitFieldId,
      `Parameter ${parameterSlug} was configured with non-existing unit "${parameterUnitSlug}". Please contact Mapistry support.`,
    );
    return;
  }
  const relatedUnits = parameterUnit.measure
    ? allUnits.filter((u) => u.measure === parameterUnit.measure)
    : [parameterUnit];
  const relatedUnitSlugs = relatedUnits.map((u) => u.slug);
  if (relatedUnitSlugs.includes(String(unitSlug))) {
    return;
  }

  // Special use case for "% (mass in water)", it measures concentration,
  // and differs from "%" units because not all % can be converted to mg/L or similar.
  // Lab files will include just "%" symbol, that's why we need to change it
  // to our internal "% (mass in water)" unit if a parameter is measured as concentration in water.
  if (parameterUnit.measure === 'concentration' && unitSlug === 'percent') {
    record.set(unitFieldId, 'percent_grams_per_liter');
    return;
  }

  const relatedUnitsString = relatedUnits.map((u) => u.display_text).join(', ');
  const parameterLabel = allParameters.find(
    (p) => p.slug === parameterSlug,
  )?.display_text; // eslint-disable-line camelcase
  record.addError(
    unitFieldId,
    `${parameterLabel} sampling results should be in one of these units: ${relatedUnitsString}`,
  );
}
