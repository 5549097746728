/* eslint-disable */
var BaseListSettingsModalView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/settingsModal.pug'),

  events: {
    'click .btn-submit': '_saveSettings',
  },

  behaviors: {
    DisplayMessages: {},
  },

  showModal() {
    this.ui.$modal.modal('show');
  },

  hideModal() {
    this.ui.$modal.modal('hide');
    $('.modal-backdrop').remove();
  },

  _saveSettings() {
    return;
  },
});

module.exports = BaseListSettingsModalView;
