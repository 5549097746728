import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import React from 'react';
import { useProject } from '../../contexts/ProjectContext';

type LinkProps = { projectId: string };

export const LinkToLogsDashboardFromWithinBackboneApp = ({
  projectId,
}: LinkProps) => {
  const { project } = useProject();
  const logsSubnavUrl = `/vortex/organizations/${project?.organizationId}/sites/${projectId}/logs`;
  return (
    <a href={logsSubnavUrl}>
      <SvgIcon identifier="left-arrow" />
      <span>Back to dashboard</span>
    </a>
  );
};
