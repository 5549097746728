/* eslint-disable */
import { CustomLogType } from '@monorepo/shared/apiClient/types';
import update from 'immutability-helper';
import _get from 'lodash.get';
import {
  GenericLogLimitType,
  GenericLogLoggedItemType,
  GenericLogType,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import {
  createEmissionLimitItemAction,
  deleteEmissionItemGroupAction,
  deleteEmissionLimitItemAction,
  fetchEmissionCalculatedValuesAction,
  fetchEmissionFactorsAction,
  fetchEmissionItemGroupsAction,
  fetchEmissionLimitItemsAction,
  fetchEmissionLoggedItemsAction,
  fetchEmissionRollingCalculationsAction,
  updateEmissionLimitItemAction,
} from '../../../../actions/air';
import ProjectContext from '../../../../contexts/ProjectContext';
import { isNullOrUndefined } from '../../../../utils';
// ComponentPicker imports both the table and the row so we get a cycle
// If this becomes a problem we can split it into multiple "pickers" (ajb)
// eslint-disable-next-line import/no-cycle
import { SaveState } from '@monorepo/shared/types/SaveState';
import { withLogTemplate } from '../../../../hooks/genericLogs/useCustomLogTemplates';
import {
  getCalculatedValues,
  getEmissionFactors,
  getIsFetching,
  getLimitItemGroups,
  getLimitItems,
  getLoggedItems,
  getRollingCalculations,
  getUnitConversions,
} from '../../../../selectors/genericLogs';
import withProvider from '../../../withProvider';
import LimitItemsTable from './LimitItemsTable';

class LimitItemsContainer extends Component {
  constructor(props) {
    super(props);
    this.limitObject = {
      id: null,
      renderKey: uuidv4(),
      limitFrequency: null,
      limitCustomFrequency: null,
      limitType: null,
      limitValue: null,
      units: null,
    };

    this.formSubmissionDraftObject = {
      groupId: null,
      userDefinedId: null,
      resourceId: null,
      resourceType: null,
      status: null,
      notes: null,
      limits: [this.limitObject],
    };

    this.initialState = {
      formErrors: {
        displayable: [],
      },
      formSubmissionDraft: {
        groups: {},
      },
      formSaveState: SaveState.CLEAN,
      deletedGroups: [],
      itemsToUpsert: [],
      itemsToDelete: [],
      isWaterLog:
        props.logTemplate.type === CustomLogType.stormwater ||
        props.logTemplate.type === CustomLogType.water,
    };
    this.state = this.initialState;
    this.addLimitItem = this.addLimitItem.bind(this);
    this.addLimitGroup = this.addLimitGroup.bind(this);
    this.deleteLimitItem = this.deleteLimitItem.bind(this);
    this.deleteLimitGroup = this.deleteLimitGroup.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.reorderCollectionInDragDropContext =
      this.reorderCollectionInDragDropContext.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const {
      fetchEmissionCalculatedValues,
      fetchEmissionFactors,
      fetchEmissionItemGroups,
      fetchEmissionLimitItems,
      fetchEmissionLoggedItems,
      fetchEmissionRollingCalculations,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;
    fetchEmissionCalculatedValues(projectId, logProjectId);
    fetchEmissionFactors(projectId, logProjectId);
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchEmissionLimitItems(projectId, logProjectId);
    fetchEmissionLoggedItems(projectId, logProjectId);
    fetchEmissionRollingCalculations(projectId, logProjectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      itemGroups,
      limitItems,
      setHasSubmittedForm,
      shouldSubmitForm,
    } = this.props;

    const { formSubmissionDraft, deletedGroups } = prevState;

    const hasFetchedLimitItems =
      (prevProps.isFetching && !isFetching) ||
      !_.isEqual(limitItems, prevProps.limitItems);

    if (!prevProps.shouldSubmitForm && shouldSubmitForm) {
      setHasSubmittedForm();
      this.validateFormSubmission().then(this.submitForm);
    }

    if (
      Object.keys(itemGroups).length >
      Object.keys(formSubmissionDraft.groups).length
    ) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (!nextFormSubmissionDraft.groups[id]) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (!_.isEqual(itemGroups, prevProps.itemGroups)) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (
          !deletedGroups.includes(id) &&
          !nextFormSubmissionDraft.groups[id]
        ) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (hasFetchedLimitItems) {
      const limitItemsByResourceId = limitItems.reduce(
        (acc, li) =>
          update(acc, {
            [li.resourceId]: (existing) =>
              update(existing || {}, {
                $merge: {
                  userDefinedId: li.userDefinedId,
                  groupId: li.groupId,
                  notes: li.notes,
                  renderKey: li.id,
                  resourceId: li.resourceId,
                  resourceType: li.resourceType,
                  status: li.status,
                  limits: [
                    ..._get(acc, `${li.resourceId}.limits`, []),
                    {
                      id: li.id,
                      renderKey: li.id,
                      limitFrequency: li.limitFrequency,
                      limitCustomFrequency: li.limitCustomFrequency,
                      limitType: li.limitType,
                      limitValue: li.limitValue,
                      units: li.units,
                    },
                  ],
                },
              }),
          }),
        {},
      );

      let nextLimitItems = {};
      _.map(limitItemsByResourceId, (groupedLimitItem) => {
        nextLimitItems = update(nextLimitItems, {
          [groupedLimitItem.groupId]: (existing) =>
            update(existing || [], {
              $push: [groupedLimitItem],
            }),
        });
      });

      return this.setState((ps) =>
        update(ps, {
          formSubmissionDraft: {
            groups: {
              $merge: nextLimitItems,
            },
          },
        }),
      );
    }
  }

  handleDeleteGroup(groupId) {
    const { deleteEmissionItemGroup, fetchEmissionLimitItems, logProjectId } =
      this.props;
    const { projectId } = this.context;

    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              $set: _.omit(prevState.formSubmissionDraft.groups, groupId),
            },
          },
          deletedGroups: {
            $push: [groupId],
          },
        }),
      async () => {
        await deleteEmissionItemGroup(projectId, groupId);
        await fetchEmissionLimitItems(projectId, logProjectId);
      },
    );
  }

  setFormSaveState(formSaveState) {
    const { setFormSaveState } = this.props;
    setFormSaveState(formSaveState);
  }

  deleteLimitItem(limitItemIdx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemCollection =
      formSubmissionDraft.groups[groupId][limitItemIdx];
    const deletedItemIds = deletedItemCollection.limits.map((i) => i.id);
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                $splice: [[limitItemIdx, 1]],
              },
            },
          },
          itemsToDelete: {
            $push: deletedItemIds,
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  deleteLimitGroup(limitItemIdx, limitGroupIdx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemId = _get(
      formSubmissionDraft,
      `groups.${groupId}[${limitItemIdx}].limits[${limitGroupIdx}].id`,
    );
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [limitItemIdx]: {
                  limits: {
                    $splice: [[limitGroupIdx, 1]],
                  },
                },
              },
            },
          },
          itemsToDelete: {
            $push: [deletedItemId],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  reorderCollectionInDragDropContext(nextFormSubmissionDraft, itemToUpsert) {
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            $set: nextFormSubmissionDraft,
          },
          itemsToUpsert: {
            $push: [itemToUpsert],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  stageEdit(editsToMake, editsLocation) {
    const { editField, nextValue } = editsToMake;
    const { limitItemIdx, limitIdx, groupId } = editsLocation;
    const { formSubmissionDraft } = this.state;
    const editedIds = (() => {
      if (!isNullOrUndefined(limitIdx)) {
        return [
          formSubmissionDraft.groups[groupId][limitItemIdx].limits[limitIdx].id,
        ];
      }
      return formSubmissionDraft.groups[groupId][limitItemIdx].limits.map(
        (lg) => lg.id,
      );
    })();

    const nextResourceValue = (() => {
      if (editField === 'resource') {
        return {
          resourceId: nextValue.resourceId,
          resourceType: nextValue.resourceType,
          resourceUnits: nextValue.resourceUnits,
        };
      }
      return {
        [editField]: nextValue === '' ? null : nextValue,
      };
    })();
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [limitItemIdx]: (obj) => {
                  if (!isNullOrUndefined(limitIdx)) {
                    return update(obj, {
                      limits: {
                        [limitIdx]: {
                          $merge: nextResourceValue,
                        },
                      },
                    });
                  }
                  return update(obj, {
                    $merge: nextResourceValue,
                  });
                },
              },
            },
          },
          itemsToUpsert: {
            $push: editedIds,
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  addLimitItem(groupId = null) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: (n) =>
              update(n || [], {
                $push: [
                  {
                    ...this.formSubmissionDraftObject,
                    renderKey: uuidv4(),
                    groupId,
                  },
                ],
              }),
          },
        },
      }),
    );
  }

  addLimitGroup(groupId, limitItemIdx) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: {
              [limitItemIdx]: {
                limits: {
                  $push: [
                    {
                      ...this.limitObject,
                      renderKey: uuidv4(),
                    },
                  ],
                },
              },
            },
          },
        },
      }),
    );
  }

  validateFormSubmission() {
    const { formSubmissionDraft, isWaterLog } = this.state;
    const formErrors = {
      displayable: [],
      groups: {},
    };

    const limitItemRequiredFields = [
      {
        field: 'resourceId',
        message: isWaterLog
          ? 'Discharge location and Parameters required'
          : 'Item name required.',
      },
    ];

    const limitRequiredFields = [
      {
        condition: (limit) =>
          limit.limitType === GenericLogLimitType.AVERAGE ||
          limit.limitType === GenericLogLimitType.GEOMETRIC_MEAN,
        field: 'limitFrequency',
        message: 'Frequency required.',
      },
    ];

    _.map(formSubmissionDraft.groups, (group, groupId) => {
      formErrors.groups[groupId] = {};
      _.map(group, (limitItem, resourceId) => {
        limitItemRequiredFields.forEach((rf) => {
          if (isNullOrUndefined(limitItem[rf.field])) {
            formErrors.groups[groupId] = {
              ...formErrors.groups[groupId],
              [resourceId]: {
                ...formErrors.groups[groupId][resourceId],
                [rf.field]: rf.message,
              },
            };
            formErrors.displayable.push(rf.message);
          }
        });
        limitRequiredFields.forEach((rf) => {
          limitItem.limits.forEach((limit, limitIdx) => {
            if (
              (rf.condition == null || rf.condition(limit)) &&
              isNullOrUndefined(limit[rf.field])
            ) {
              formErrors.groups[groupId] = {
                ...formErrors.groups[groupId],
                [resourceId]: {
                  ...formErrors.groups[groupId][resourceId],
                  limits: [
                    ...(formErrors.groups[groupId][resourceId]?.limits || []),
                  ],
                },
              };
              formErrors.groups[groupId][resourceId].limits[limitIdx] = {
                ...formErrors.groups[groupId][resourceId].limits[limitIdx],
                [rf.field]: rf.message,
              };
              formErrors.displayable.push(rf.message);
            }
          });
        });
      });
    });

    return new Promise((res) => {
      this.setState(
        (prevState) =>
          update(prevState, {
            formErrors: {
              $set: {
                ...formErrors,
                displayable: formErrors.displayable.sort(),
              },
            },
          }),
        res,
      );
    });
  }

  submitForm() {
    const { formErrors, itemsToUpsert, itemsToDelete, formSubmissionDraft } =
      this.state;

    const {
      deleteEmissionLimitItem,
      logProjectId,
      setHasSubmittedForm,
      upsertLimitItem,
    } = this.props;
    const { projectId } = this.context;

    if (formErrors.displayable.length === 0) {
      _.map(formSubmissionDraft.groups, (group) => {
        group.forEach((limitItem) => {
          limitItem.limits.forEach((limitGroup) => {
            if (itemsToUpsert.includes(limitGroup.id)) {
              upsertLimitItem(projectId, {
                ...limitGroup,
                groupId: limitItem.groupId,
                logProjectId,
                notes: limitItem.notes,
                resourceId: limitItem.resourceId,
                resourceType: limitItem.resourceType,
                status: limitItem.status,
                userDefinedId: limitItem.userDefinedId,
              });
            }
          });
        });
      });
      itemsToDelete.forEach((id) => {
        if (!isNullOrUndefined(id)) {
          deleteEmissionLimitItem(projectId, id);
        }
      });

      this.setState({
        itemsToUpsert: [],
        itemsToDelete: [],
      });
      setHasSubmittedForm();
      this.setFormSaveState(SaveState.SAVED);
    }
  }

  render() {
    const { formErrors, formSubmissionDraft } = this.state;

    const orderedFormSubmissionDraft = {
      groups: {
        null: _get(formSubmissionDraft, 'groups.null', []),
        ..._.omit(formSubmissionDraft.groups, 'null'),
      },
    };

    const {
      calculatedValues,
      emissionFactors,
      limitItems,
      loggedItems,
      logProjectId,
      rollingCalculations,
      isFetching,
      itemGroups,
      selectMenuPortalRef,
    } = this.props;
    return (
      <LimitItemsTable
        addLimitGroup={this.addLimitGroup}
        addLimitItem={this.addLimitItem}
        calculatedValues={calculatedValues}
        deleteLimitGroup={this.deleteLimitGroup}
        deleteLimitItem={this.deleteLimitItem}
        emissionFactors={emissionFactors}
        formErrors={formErrors}
        formSubmissionDraft={orderedFormSubmissionDraft}
        handleDeleteGroup={this.handleDeleteGroup}
        isLoading={isFetching}
        itemGroups={itemGroups}
        limitItems={limitItems}
        logProjectId={logProjectId}
        loggedItems={loggedItems}
        rollingCalculations={rollingCalculations}
        onDragEnd={this.reorderCollectionInDragDropContext}
        selectMenuPortalRef={selectMenuPortalRef}
        stageEdit={this.stageEdit}
      />
    );
  }
}

LimitItemsContainer.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteEmissionItemGroup: PropTypes.func.isRequired,
  deleteEmissionLimitItem: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchEmissionLimitItems: PropTypes.func.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  limitItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  setFormSaveState: PropTypes.func.isRequired,
  setHasSubmittedForm: PropTypes.func.isRequired,
  shouldSubmitForm: PropTypes.bool.isRequired,
  upsertLimitItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  const calculatedValues = getCalculatedValues(state, logProjectId);
  const emissionFactors = getEmissionFactors(state, logProjectId);
  const loggedItems = getLoggedItems(state, logProjectId);
  const rollingCalculations = getRollingCalculations(state, logProjectId);
  const unitConversions = getUnitConversions(state, logProjectId);

  const calculatedValuesWithTypes = calculatedValues.map((cv) => ({
    ...cv,
    resourceType: GenericLogType.CALCULATED_VALUE,
  }));

  const emissionFactorsWithTypes = emissionFactors.map((factor) => ({
    ...factor,
    resourceType: GenericLogType.EMISSION_FACTOR,
  }));

  const loggedItemsWithTypes = loggedItems.map((item) => ({
    ...item,
    resourceType: GenericLogType.LOGGED_ITEM,
  }));

  const numericLoggedItemsWithTypes = loggedItemsWithTypes.filter(
    (item) => item.itemType === GenericLogLoggedItemType.NUMBER,
  );

  const rollingCalculationsWithTypes = rollingCalculations.map((rc) => ({
    ...rc,
    resourceType: GenericLogType.ROLLING_CALCULATION,
  }));

  const unitConversionsWithTypes = unitConversions.map((uc) => ({
    ...uc,
    resourceType: GenericLogType.UNIT_CONVERSION,
  }));

  return {
    isFetching: getIsFetching(state, 'airEmissionsMaterialLimits'),
    calculatedValues: calculatedValuesWithTypes,
    emissionFactors: emissionFactorsWithTypes,
    itemGroups: getLimitItemGroups(state, logProjectId),
    limitItems: getLimitItems(state, logProjectId),
    loggedItems: numericLoggedItemsWithTypes,
    rollingCalculations: rollingCalculationsWithTypes,
    unitConversions: unitConversionsWithTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertLimitItem: (projectId, limitItem) =>
    limitItem.id
      ? dispatch(updateEmissionLimitItemAction(projectId, limitItem))
      : dispatch(createEmissionLimitItemAction(projectId, limitItem)),
  fetchEmissionCalculatedValues: (projectId, logProjectId) =>
    dispatch(fetchEmissionCalculatedValuesAction(projectId, logProjectId)),
  fetchEmissionFactors: (projectId, logProjectId) =>
    dispatch(fetchEmissionFactorsAction(projectId, logProjectId)),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  fetchEmissionLimitItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLimitItemsAction(projectId, logProjectId)),
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  fetchEmissionRollingCalculations: (projectId, logProjectId) =>
    dispatch(fetchEmissionRollingCalculationsAction(projectId, logProjectId)),
  deleteEmissionLimitItem: (projectId, id) =>
    dispatch(
      deleteEmissionLimitItemAction(projectId, ownProps.logProjectId, id),
    ),
  deleteEmissionItemGroup: (projectId, id) =>
    dispatch(
      deleteEmissionItemGroupAction(projectId, ownProps.logProjectId, id),
    ),
});

LimitItemsContainer.contextType = ProjectContext;
export default withProvider(
  withLogTemplate(
    connect(mapStateToProps, mapDispatchToProps)(LimitItemsContainer),
  ),
);
