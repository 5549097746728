import { format } from 'date-fns';
import { CalendarEventStatus, localEquivalentOfUTC } from 'mapistry-shared';
import React from 'react';
import EventChoiceMenu from '../CalendarCard/EventChoiceMenu';

export const WaterSamplingEventChoiceMenu = ({
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  menuAnchor,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  eventChoices,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onEventClick,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  closeEventChoiceMenu,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  hasProjectUpdatePermission,
}) => {
  const formatDate = (d) =>
    format(localEquivalentOfUTC(new Date(d)), 'MMM dd, HH:mm');

  const getAsNeededItemText = (calendarEvent) => {
    const eventDate = calendarEvent.attachments[0]?.date;

    return eventDate
      ? `Sampling Event #${calendarEvent.eventNumber}: ${formatDate(eventDate)}`
      : 'Create a new sampling event';
  };

  const getRecurringEventItemText = (calendarEvent) => {
    const eventDate = calendarEvent.attachments[0]?.date;

    return eventDate
      ? `Sampling event #${calendarEvent.eventNumber}: ${formatDate(eventDate)}`
      : `Sampling event #${calendarEvent.eventNumber}`;
  };

  const shouldEventChoiceMenuRenderEvent = (calendarEvent) => {
    if (hasProjectUpdatePermission) {
      return true;
    }
    // users without update permissions shouldn't see incomplete additional ad hoc events
    return calendarEvent.status === CalendarEventStatus.COMPLETE;
  };

  return (
    <EventChoiceMenu
      eventChoices={eventChoices}
      menuAnchor={menuAnchor}
      onClose={closeEventChoiceMenu}
      onEventClick={onEventClick}
      getAsNeededItemText={getAsNeededItemText}
      getRecurringEventItemText={getRecurringEventItemText}
      shouldRenderEvent={shouldEventChoiceMenuRenderEvent}
    />
  );
};
