/* eslint-disable */
var SettingRowItemView = require('./SettingRowItemView');

var SidebarSettingCollectionView = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'list-unstyled',

  childView: SettingRowItemView,

  childViewOptions() {
    return {
      currentSettingSlug: this.currentSettingSlug,
      userId: this.userId,
    };
  },

  initialize(options = {}) {
    this.currentSettingSlug = options.currentSettingSlug;
    this.userId = options.userId;
  },
});

module.exports = SidebarSettingCollectionView;
