var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (assetsRoot) {
      pug_html = pug_html + "\u003Cdiv class=\"error-page-container spill\"\u003E\u003Cimg" + (pug.attr("src", assetsRoot + '404_spill.svg', true, true)) + "\u003E\u003Cdiv class=\"text-wrapper\"\u003E\u003Ch2\u003E404\u003C\u002Fh2\u003E\u003Ch3\u003EOh no! Looks like you've gone down the wrong drain.\u003C\u002Fh3\u003E\u003Cp\u003EIf you think you've reached this page in error, please let us know. You can email us at \u003Ca class=\"mapistry-green dark-green-on-hover\" href=\"mailto:help@mapistry.com\"\u003Ehelp@mapistry.com\u003C\u002Fa\u003E.\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "assetsRoot" in locals_for_with ?
        locals_for_with.assetsRoot :
        typeof assetsRoot !== 'undefined' ? assetsRoot : undefined));
    ;;return pug_html;};
module.exports = template;