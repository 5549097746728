/* eslint-disable */
import BaseParameterLayoutView from '../BaseParameterLayoutView';
let UpdateParameterStatusModalCompositeView = require('./UpdateParameterStatusModalCompositeView');
let featuresInDischargeLocationsLayerCollection =
  require('../stormwaterSamples/FeaturesInDischargeLocationsLayerCollection').singleton();

const StormwaterParameterLayoutView = BaseParameterLayoutView.extend({
  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.showParameterStatus = options.showParameterStatus;
    this.sectorData = options.sectorData;
    this.isWashingtonIndustrial = options.isWashingtonIndustrial;
    this.collection = options.collection;
  },

  templateHelpers: function () {
    if (this.showParameterStatus) {
      return {
        header: 'Current Stormwater Status',
        linkTitle: 'Edit Parameter Status',
      };
    } else {
      return {
        header: 'Stormwater Sampling Parameters',
        linkTitle: 'Update Stormwater Sampling Parameters',
      };
    }
  },

  _showParameterCollection() {
    const CurrentStatusCollectionView = require('./CurrentStatusCollectionView');
    let currentStatusCollectionView = new CurrentStatusCollectionView({
      showParameterStatus: this.showParameterStatus,
      sectorData: this.sectorData,
      isWashingtonIndustrial: this.isWashingtonIndustrial,
      collection: this.collection,
    });
    this.parametersContainer.show(currentStatusCollectionView);
  },

  _showEditParameterModalView() {
    this.modal = new UpdateParameterStatusModalCompositeView({
      collection: this.collection,
      availableDischargeLocations: featuresInDischargeLocationsLayerCollection,
      projectModel: this.projectModel,
      sectorData: this.sectorData,
      showParameterStatus: this.showParameterStatus,
      suite: 'stormwater',
      modalTitle: this.showParameterStatus
        ? 'Update Parameter Status'
        : 'Update Stormwater Sampling Parameters',
    });
    this.editParameterModal.show(this.modal);
  },
});

module.exports = StormwaterParameterLayoutView;
