/* eslint-disable */
var configureTipsy = require('../lib/tipsyConfig');

var Tooltip = Backbone.Marionette.Behavior.extend({
  onRender: function () {
    configureTipsy(this.$('[rel=tipsy]'), this.options);
  },

  onShow: function () {
    configureTipsy(this.$('[rel=tipsy]'), this.options);
  },
});

module.exports = Tooltip;
