/* eslint-disable */
// Services
import { MapWaterSampleType, featureSampleTypeLabel } from 'mapistry-shared';
import FeatureModel from '../models_collections/featureModel';
import {
  addDependantFeatures,
  updateFeatureNameAliases,
} from '@monorepo/shared/apiClient/mapFeatures';

const APP = require('../config');
const apiCaller = require('../apiCaller');
const bigSpinner = require('../services/bigSpinner');
const EditLayerModalView = require('../layerManagement/EditLayerModalView');
const FeaturePhotoLayoutView = require('./FeaturePhotoLayoutView');
const BluebirdPromise = require('bluebird');
const featureCollection =
  require('../models_collections/featureCollection').singleton();

// Views
const FeatureAttributesBodyView = require('./editAttributeListView');
const FeaturePhotoOverlay = require('../featureManagement/FeaturePhotoOverlay');
const errorView = require('../modals/errorView').singleton();
const confirmView = require('../modals/confirmView').singleton();

const EditFeatureView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/editFeature.pug'),

  regions: {
    photosContainer: '#features-photos-container',
    featurePhotoOverlayContainer: '#feature-photo-overlay-container',
  },

  ui: {
    $content: '.modal-content',
  },

  events: {
    'click .btn-done': '_handleDoneClick',
    'hidden.bs.modal': '_hideModal',
    'click .show-layer-modal': '_showLayerModal',
    'click .btn-delete': '_confirmDelete',
    'change #feature-rotation': '_handleRotationChange',
    'change #feature-sample-type': '_handleSampleTypeChange',
    'change #feature-aliases': '_handleAliasesUpdate',
  },

  render: function () {
    let data = {
      header: 'Loading Attributes ...',
      showSpinner: true,
    };

    this.setElement(this.template(data));
    this.$el.modal('show');

    this.listenTo(
      APP.vent,
      'featurePhotoLayoutView:showPhotoOverlay',
      this.showPhotoOverlay,
    );
    this.listenTo(APP.vent, 'featurePhotoOverlay:hide', this.hidePhotoOverlay);

    return this;
  },

  showPhotoOverlay(photo) {
    this.$el.find('.modal-content').hide();
    this.$el.find('.modal-dialog').addClass('full-screen');
    this.featurePhotoOverlayContainer.reset();
    this.featurePhotoOverlayContainer.show(
      new FeaturePhotoOverlay({ model: photo }),
    );
  },

  hidePhotoOverlay() {
    this.featurePhotoOverlayContainer.reset();
    this.$el.find('.modal-content').show();
    this.$el.find('.modal-dialog').removeClass('full-screen');
  },

  _showLayerModal(e) {
    e.preventDefault();
    var modal = new EditLayerModalView({ model: this.model.layer() });
    modal.render();
    this.$el.modal('hide');
  },

  _renderAttrs() {
    let rotation = this.model.get('rotation')
      ? parseInt(this.model.get('rotation'))
      : 0;

    var currentUserArbiter = require('../permissions/currentUserArbiter');
    return currentUserArbiter
      .hasProjectUpdatePermission(APP.projectId)
      .bind(this)
      .then(function (hasProjectUpdatePermission) {
        var data = {
          header: this._shownFromNewFeature ? 'Add Attributes' : 'Edit Feature',
          display: this._shownFromNewFeature ? 'hidden' : '',
          rotation: rotation,
          isDischargeLocation: this.model.isDischargeLocation(),
        };

        if (!hasProjectUpdatePermission) {
          data.header = 'Feature Attributes';
        }

        this.$el.html($(this.template(data)).children());

        this._renderSampleTypeSelect();
        this._renderAliasesSelect();
        return this._renderAttributes();
      });
  },

  _renderPhotos() {
    const containerEl = $('#features-photos-container', this.$el);
    const layoutView = new FeaturePhotoLayoutView({
      featureId: this.model.get('id'),
    });
    const content = layoutView.render().el;
    containerEl.html(content);
    layoutView.postRender();
  },

  showModal(options) {
    this._name = 'EditFeatureView';
    this.model = options.model;
    this._layer = this.model.layer();
    this._shownFromNewFeature = options.newFeature;
    this.updateFeatureLocationOnClose = false;

    this._initListeners();
    this.render();

    // before showing modal, make sure attrs are loaded
    return BluebirdPromise.resolve(this._layer.ensureAttrsLoaded())
      .bind(this)
      .then(this._renderAttrs)
      .then(this._renderPhotos);
  },

  _initListeners() {
    this.listenTo(
      this._layer.attrCollectionWrapper,
      'change destroy',
      function (options) {
        if (options && options.needToUpdateLatLng) {
          this.updateFeatureLocationOnClose = true;
        }
      }.bind(this),
    );
  },

  _hideModal() {
    if (this.updateFeatureLocationOnClose) {
      bigSpinner
        .show()
        .then(this._updateFeatureLocation.bind(this))
        .finally(bigSpinner.hide);
    }

    this.remove();

    if (this._attrsView) {
      this._attrsView.remove();
    }
  },

  _handleDoneClick(e) {
    e.preventDefault();

    var $newAttrName = this.$('#new-attr-name');
    if ($newAttrName.length && $newAttrName.val()) {
      this._attrsView._submitNewAttr(e);
    }

    this.$el.modal('hide');
  },

  _handleRotationChange(e) {
    const rotation = e.target.value;
    this._updateFeature(rotation);
  },

  async _handleSampleTypeChange() {
    const dependantFeatures = this.model.getDependantFeatures();
    const currentSampleTypes = dependantFeatures.map((f) =>
      f.get('sample_type'),
    );
    const updatedSampleTypes =
      this.$el.find('#feature-sample-type').val() || [];
    const sampleTypesToAdd = updatedSampleTypes.filter(
      (t) => !currentSampleTypes.includes(t),
    );
    const featuresToDelete = dependantFeatures.filter(
      (f) => !updatedSampleTypes.includes(f.get('sample_type')),
    );

    if (sampleTypesToAdd.length) {
      addDependantFeatures(
        APP.projectId,
        this.model.get('id'),
        sampleTypesToAdd,
      )
        .then((features) => {
          const featureModels = features.map((f) => new FeatureModel(f));
          return featureCollection.add(featureModels);
        })
        .catch(function () {
          errorView.showModal({
            error: 'Could not update sample types for this location.',
          });
        });
    }

    featuresToDelete.forEach((feature) => {
      // A dependant feature will be archived
      feature.destroy();
    });
  },

  async _handleAliasesUpdate() {
    const updatedAliases = this.$el.find('#feature-aliases').val() || null;

    try {
      const saved = await updateFeatureNameAliases(
        APP.projectId,
        this.model.get('id'),
        updatedAliases,
      );
      this.model.save({ aliases: saved.aliases });
    } catch (err) {
      errorView.showModal({
        error: 'Could not update name aliases for this location.',
      });
    }
  },

  _confirmDelete(e) {
    this.$el.modal('hide');

    var options = {
      text: 'the ' + this.model.get('name') + ' feature?',
      confirmClicked: this._deleteFeature.bind(this),
      useSpinner: false,
    };

    confirmView.showModal(options);

    if (e) {
      e.preventDefault();
    }
  },

  _deleteFeature() {
    this.model.destroy();
  },

  _updateFeatureLocation() {
    return apiCaller
      .geolocate(this.model.address())
      .bind(this)
      .then(function (geography) {
        return BluebirdPromise.resolve(
          this.model.save({ geography: geography }),
        );
      })
      .catch(function () {
        errorView.showModal({
          error:
            'There was an error on the server, and the' +
            ' new address you entered could not be geocoded. The location of your ' +
            'marker has not been updated.',
        });
      });
  },

  _renderSampleTypeSelect() {
    const dependantFeatures = this.model.getDependantFeatures();
    const sampleTypes = dependantFeatures.map((f) => f.get('sample_type'));
    const options = Object.values(MapWaterSampleType)
      .map((type) => ({
        id: type,
        text: featureSampleTypeLabel[type],
        selected: sampleTypes.includes(type),
      }))
      .sort((op1, op2) => (op1.text > op2.text ? 1 : -1));

    this.$el.find('#feature-sample-type').select2({
      placeholder: 'Select all applicable sample types',
      data: options,
      width: '100%',
    });
  },

  _renderAliasesSelect() {
    const aliases = this.model.get('aliases');
    const options = (aliases || []).map((alias) => ({
      id: alias,
      text: alias,
      selected: true,
    }));
    this.$el.find('#feature-aliases').select2({
      placeholder: 'Type an alias and press Enter',
      tags: options,
      width: '100%',
    });
  },

  _renderAttributes() {
    this._attrsView = new FeatureAttributesBodyView({
      model: this.model,
      projectSettings: this.projectSettings,
    });

    return this.$('#feature-attrs').empty().append(this._attrsView.render().el);
  },

  _updateFeature: function (rotation) {
    let data = {
      name: this.model.get('name'),
      layer_id: this.model.get('layer_id'),
      type: this.model.get('type'),
      geography: this.model.get('geography'),
      rotation: rotation,
    };

    return apiCaller
      .updateFeature(APP.projectId, this.model.get('id'), data)
      .bind(this)
      .then(function () {
        return this.model.save({ rotation: rotation });
      })
      .catch(function () {
        errorView.showModal({
          error:
            'There was an error on the server, and the rotation could not be set.',
        });
      });
  },
});

Backbone.addSingletonToView(EditFeatureView);
module.exports = EditFeatureView;
