/* eslint-disable */
var apiCaller = require('../../../../../../../apiCaller');

var DownloadParameterFileItemView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/downloadParameterFile.pug'),

  initialize: function (options) {
    this._samplingEventId = options.sampling_event_id;
    this._projectId = options.project_id;
  },

  behaviors: {
    DisplayMessages: {},
  },

  ui: {
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
    $downloadButton: '.download-pdf',
  },

  events: {
    'click @ui.$downloadButton': 'downloadFile',
  },

  downloadFile: function (e) {
    e.preventDefault();
    var downloadUrl = apiCaller.downloadSamplingResultsUrl(
      this._projectId,
      this._samplingEventId,
    );

    apiCaller
      .validateSamplingResultDownload(this._projectId, this._samplingEventId)
      .bind(this)
      .then(function () {
        window.open(downloadUrl, '_blank');
      })
      .catch(function (err) {
        this.triggerMethod('ShowError', err);
      });
  },
});

module.exports = DownloadParameterFileItemView;
