import React from 'react';
import ReactDOM from 'react-dom';
import { Providers } from '@monorepo/old-web/js/components/componentWrapper';
import backboneHistory from './backboneHistory';
import ReactRoutes from './ReactRoutes';

const normalize = (location) =>
  `/${`${location}`.replace(/\/$/, '/')}`.split('?');

export class ReactRoutesView extends Backbone.View {
  initialize(location) {
    this.model = new Backbone.Model();
    this.listenTo(this.model, 'change', () => {
      const [pathname, search] = normalize(this.model.get('location'));
      backboneHistory.originalPush({
        pathname,
        search,
      });
    });
    this.model.set({ location });
  }

  setLocation(location) {
    this.model.set({ location });
  }

  render() {
    this.$el.addClass('component-wrapper');
    ReactDOM.render(
      <Providers>
        <ReactRoutes history={backboneHistory} />
      </Providers>,
      this.el,
    );
    return this;
  }

  remove() {
    ReactDOM.unmountComponentAtNode(this.el);
    Backbone.View.prototype.remove.call(this);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ReactRoutesView;
