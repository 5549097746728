import { Api } from '@monorepo/shared/apiClient';
import { MutationConfig, queryCache, useMutation } from 'react-query';
import {
  removeOnboardingDocumentRequest,
  RemoveOnboardingDocumentRequestResponse,
} from '../onboardingApiClient';
import { createOnboardingSessionKey } from './createOnboardingSessionKey';

type Input = {
  config?: MutationConfig<
    RemoveOnboardingDocumentRequestResponse,
    Api.ErrorResponse
  >;
};

const mutationFn = removeOnboardingDocumentRequest;

export function useRemoveOnboardingDocumentRequest(input?: Input) {
  const config = input?.config;

  const mutationInfo = useMutation(mutationFn, {
    ...config,
    onSuccess: (res, variables) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryCache.invalidateQueries(createOnboardingSessionKey());
      return config?.onSuccess?.(res, variables);
    },
  });

  return mutationInfo;
}
