var pug = require("!../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (id, tag_name, tagtype_name) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"project-tag badge margin-right-10\""+pug.attr("data-id", id, true, true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = tagtype_name + ': ' + tag_name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "tag_name" in locals_for_with ?
        locals_for_with.tag_name :
        typeof tag_name !== 'undefined' ? tag_name : undefined, "tagtype_name" in locals_for_with ?
        locals_for_with.tagtype_name :
        typeof tagtype_name !== 'undefined' ? tagtype_name : undefined));
    ;;return pug_html;};
module.exports = template;