var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (questionOptions) {
      pug_html = pug_html + "\u003Cform class=\"authentication-form\"\u003E\u003Csvg class=\"logo-full\"\u003E\u003Cuse xlink:href=\"#MapistryLogo\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cdiv class=\"login-inner\"\u003E\u003Ch1\u003ESet Security Question\u003C\u002Fh1\u003E\u003Cp\u003EAt Mapistry we take your security very seriously. That is why we are adding security questions to everyone's account.\u003C\u002Fp\u003E\u003Cp class=\"last-paragraph\"\u003EChoose a question and record your answer. If you ever forget your password, we will ask you for your answer to your chosen question before allowing your password to be reset.\u003C\u002Fp\u003E\u003Cdiv class=\"alert alert-success\"\u003E\u003Cp class=\"message\"\u003ESuccess\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"full-width\"\u003E\u003Cdiv class=\"fields\"\u003E\u003Cdiv class=\"mapistry-field form-field\"\u003E\u003Cdiv class=\"field-heading\"\u003E\u003Cdiv class=\"main-heading\"\u003E\u003Clabel class=\"field-label\" for=\"recovery-question\"\u003ESecurity Question\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"mapistry-select\"\u003E\u003Cdiv class=\"mapistry-select__control\"\u003E\u003Cselect class=\"recovery-question\" id=\"recovery-question\" name=\"recovery-question\"\u003E";
// iterate questionOptions
;(function(){
  var $$obj = questionOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var question = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", question, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = question) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var question = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", question, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = question) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"mapistry-field form-field\"\u003E\u003Cdiv class=\"field-heading\"\u003E\u003Cdiv class=\"main-heading\"\u003E\u003Clabel class=\"field-label\" for=\"recovery-answer\"\u003EAnswer\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"mapistry-text-field\"\u003E\u003Cdiv class=\"mapistry-text-field-input\"\u003E\u003Cinput class=\"mapistry-text-field recovery-answer\" id=\"recovery-answer\" name=\"recovery-answer\" required\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"full-width\"\u003E\u003Cbutton class=\"mapistry-button primary large full-width\" type=\"submit\" data-id=\"close\"\u003ESave Security Question\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";
    }.call(this, "questionOptions" in locals_for_with ?
        locals_for_with.questionOptions :
        typeof questionOptions !== 'undefined' ? questionOptions : undefined));
    ;;return pug_html;};
module.exports = template;