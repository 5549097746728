/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/spcc.pug'),

  initialize(options = {}) {
    this.hideHeader = options.hideHeader;
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadSpccCollection = options.uploadSpccCollection;
    this.projectModel = options.projectModel;
    this.spccYearStartDate = options.spccYearStartDate;
    this.spccYearEndDate = options.spccYearEndDate;
    this.isDisabled = options.isDisabled;
    this.planName = 'SPCC Plan';
  },

  regions: {
    header: '#dashboard-spcc-records-header',
    spccUpdates: '#dashboard-spcc-updates-content-container',
    modalContainer: '#new-spcc-update-modal-container',
  },

  onShow() {
    if (!this.hideHeader) {
      this._showHeader();
    }
    this._showSpccUpdates();
  },

  _showHeader() {
    const SpccRecordsHeader = require('./SpccRecordsHeader');
    this.header.show(
      new SpccRecordsHeader({
        hasProjectUpdatePermission: this.hasProjectUpdatePermission,
        uploadSpccCollection: this.uploadSpccCollection,
        projectModel: this.projectModel,
        planName: this.planName,
      }),
    );
  },

  _showSpccUpdates() {
    const SpccUpdateCollectionView = require('./SpccUpdateCompositeView');
    const spccUpdateCollectionView = new SpccUpdateCollectionView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      isDisabled: this.isDisabled,
      addNewUpdateModal: this._showModalView(),
      planName: this.planName,
    });

    this.spccUpdates.show(spccUpdateCollectionView);
  },

  _showModalView() {
    const ModalView = require('../../../shared/spccUpdates/SpccUpdateModalView');
    const modalView = new ModalView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      title: 'SPCC Plan',
    });

    this.modalContainer.show(modalView);
    return modalView;
  },
});
