import {
  convertUnits,
  getUnitConversionMultiplier,
  SimpleUnits,
} from 'mapistry-shared';
import { FlowLogUpload, rateOfDischargeUnit } from './types';

const HOURS_PER_DAY =
  getUnitConversionMultiplier(SimpleUnits.DAYS, SimpleUnits.HOURS) || 24;

export const parseRateOfDischarge = (upload: FlowLogUpload) => {
  if (!upload.rateOfDischarge || !upload.rateOfDischargeUnits) {
    return Number(upload.flowReading);
  }

  const converted = convertUnits(
    Number(upload.rateOfDischarge),
    upload.rateOfDischargeUnits,
    rateOfDischargeUnit.gallonsPerHour,
  );

  if (!converted) {
    return null;
  }

  return upload.hoursOfDischarge
    ? converted * Number(upload.hoursOfDischarge)
    : converted * HOURS_PER_DAY;
};

export const parseFlowReading = (upload: FlowLogUpload) => {
  if (!upload.flowReadingUnits) {
    return Number(upload.flowReading);
  }

  return convertUnits(
    Number(upload.flowReading),
    upload.flowReadingUnits,
    SimpleUnits.GALLONS,
  );
};
