import React from 'react';
import FormField from './FormField';
import Select from '../../../elements/Select';

class SingleLocationField extends FormField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);
    const { isStencil } = this.props;

    return (
      <Select
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        error={error}
        isClearable={isStencil || !templateField.required}
      />
    );
  }

  formatOptions(templateField) {
    const value = this.parsedValue();
    const options = templateField.options.filter(
      (option) => !option.isArchived || option.id === value[0],
    );
    return options.map((option) => ({
      label: option.name,
      value: option.id,
    }));
  }

  parsedValue() {
    const value = this.getValue();
    if (!(value?.length > 0)) return [];
    // In addition to being a serialized JSON array the
    // field value can also be a literal value
    try {
      const parsed = JSON.parse(value);
      return parsed;
    } catch (e) {
      // In the case of a literal value we just return it as
      // the expected single element array
      return [value];
    }
  }

  formatValue(formattedOptions) {
    const parsedValue = this.parsedValue();
    return (
      formattedOptions.find((option) => option.value === parsedValue[0]) || null
    );
  }

  handleChange(selection) {
    const { templateField, onChange } = this.props;
    const newValue = selection ? JSON.stringify([selection.value]) : '';
    return onChange(templateField.slug, newValue);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SingleLocationField;
