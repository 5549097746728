/* eslint-disable */
let projectStateConfig = require('mapistry-project-state-config');

module.exports = Backbone.Marionette.ItemView.extend({
  tagName: 'li',
  className: 'flex align-items-center',
  template: require('./templates/exceedanceRow.pug'),

  behaviors: {
    Tooltip: {},
  },

  modelEvents: {
    'change:errors': 'render',
    'change:warnings': 'render',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.sectorData = require('industrial-stormwater-sector-data')(
      this.projectModel.get('state'),
    );
  },

  templateHelpers() {
    let errors = this.model.get('errors');

    return {
      instantaneousErrors: this._filterByStr(errors, 'instantaneous'),
      instantaneousWarnings: this._filterByStr(
        this.model.get('warnings'),
        'instantaneous',
      ),
      averageErrors: this._filterByStr(errors, 'average'),
      averageWarningText:
        'Average Trending Over ' +
        (projectStateConfig.isCaliforniaIndustrial(this.projectModel.toJSON())
          ? 'NAL'
          : 'Benchmark Limit'),
      errors: this._filterByStr(errors, 'exceedance'),
      instantaneousLabel: this.sectorData.getInstantaneousLabel
        ? 'Latest Samples Were Above ' + this.sectorData.getInstantaneousLabel()
        : 'Instantaneous Exceedance',
    };
  },

  _filterByStr(arr, str) {
    return _.filter(arr, (a) => a.toLowerCase().indexOf(str) >= 0);
  },
});
