import P from 'bluebird';
import APP from './config';
import LargeFileUploadProgressTray from './layerManagement/LargeFileUploadProgressTray';

export function handleLargeUploadFile(options) {
  const { layerModel, file } = options;
  const largeFileUploadProgressTray = new LargeFileUploadProgressTray();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-use-before-define
  registerGlobalOnUnloadListener();

  let cancelled = false;
  let error = false;
  const prom = largeFileUploadProgressTray
    .on('canceled', () => {
      cancelled = true;
      prom.cancel();
    })
    .startUploadingFile(layerModel, file)
    .catch((e) => {
      if (cancelled) {
        cancelled = false;
        return;
      }

      error = true;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-use-before-define
      const formattedErrorMsg = formatUploadError(e);

      APP.log({
        func: 'largeFileUploadProgressTray',
        error: e,
      });

      largeFileUploadProgressTray.showError(formattedErrorMsg);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line consistent-return
      return P.resolve(
        layerModel.save({
          mapbox_upload_error: formattedErrorMsg,
          mapbox_tileset_slug: null,
        }),
      );
    })
    .finally(() => {
      if (!error) {
        largeFileUploadProgressTray.destroy();
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-use-before-define
      unRegisterGlobalOnUnloadListener();
    });

  // eslint-disable-next-line no-undef
  $('#file-uploader-tray').html(largeFileUploadProgressTray.render().el);
}

function formatUploadError(e) {
  if (typeof e === 'string') {
    return e;
  }

  if (e && e.message) {
    return e.message;
  }

  if (e && e.name) {
    return e.name;
  }

  return 'Unknown error occurred while uploading file';
}

function confirmBeforeLeavingPage(e) {
  const confirmationMessage = 'File upload progress will be lost';
  (e || window.event || {}).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Webkit, Safari, Chrome
}

function registerGlobalOnUnloadListener() {
  window.addEventListener('beforeunload', confirmBeforeLeavingPage);
}

function unRegisterGlobalOnUnloadListener() {
  window.removeEventListener('beforeunload', confirmBeforeLeavingPage);
}
