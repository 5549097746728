/* eslint-disable */
var apiCaller = require('../apiCaller');
var currentUser = require('../currentUser');

var ForgotPasswordView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/forgot-password.pug'),

  ui: {
    $emailInput: '[name=email]',
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
    $btnSubmit: '[type=submit]',
  },

  behaviors: {
    DisplayMessages: {},
    DisplayLoader: {},
  },

  events: {
    'submit form': 'requestPasswordReset',
  },

  initialize({ errorMessage }) {
    this.errorMessage = errorMessage;
  },

  onRender: function () {
    $('html > body').attr('data-view', 'not-authenticated');
    if (this.errorMessage) {
      this.ui.$errorContainer.html(this.errorMessage).show();
    }
  },

  requestPasswordReset: function (e) {
    let message =
      'An email has been sent to your email address with instructions to reset your password.';
    e.preventDefault();

    this.triggerMethod('ShowLoader');
    apiCaller
      .requestPasswordReset(this.ui.$emailInput.val())
      .bind(this)
      .then(() => this.triggerMethod('ShowSuccess', message))
      .catch((error) => this.triggerMethod('ShowError', error))
      .finally(() => this.triggerMethod('ResetLoader'));
  },
});

module.exports = ForgotPasswordView;
