import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CalendarName } from 'mapistry-shared';

import APP from '../../../config';
import { fetchProjectAction } from '../../../actions/project';
import {
  downloadWastewaterEventsAction,
  fetchWastewaterMonitoringLocationsAction,
  fetchWastewaterSamplingParametersAction,
} from '../../../actions/wastewater';
import {
  clearCalendarFrequenciesAction,
  fetchCalendarFrequenciesAction,
} from '../../../actions/calendar';
import SamplingResultRecordPanel from './SamplingResultRecordPanel';
import withProvider from '../../withProvider';
import { objectToSortedArray } from '../../../utils/shared';

class SamplingResultRecordPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalParameterSlug: undefined,
      editParametersModalIsOpen: false,
      editResultsModalIsOpen: false,
      parameterSettingUploadModalIsOpen: false,
      samplingResultAttachmentToEdit: undefined,
      samplingResultsUploadModalIsOpen: false,
    };
  }

  componentDidMount() {
    const {
      fetchCalendarFrequencies,
      fetchProject,
      fetchWastewaterMonitoringLocations,
      fetchWastewaterSamplingParameters,
    } = this.props;
    fetchProject();
    fetchWastewaterMonitoringLocations();
    fetchWastewaterSamplingParameters();
    fetchCalendarFrequencies(CalendarName.WASTEWATER_SAMPLING);
  }

  componentWillUnmount() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { clearCalendarFrequencies } = this.props;
    clearCalendarFrequencies(CalendarName.WASTEWATER_SAMPLING);
  }

  openResultsEditModal = (attachment = null, param = null) => {
    this.setState({
      // eslint-disable-next-line camelcase
      editModalParameterSlug: param?.parameter_slug,
      samplingResultAttachmentToEdit: attachment,
      editResultsModalIsOpen: true,
    });
  };

  closeResultsEditModal = () => {
    this.setState({
      editModalParameterSlug: undefined,
      samplingResultAttachmentToEdit: undefined,
      editResultsModalIsOpen: false,
    });
  };

  render() {
    const { downloadWastewaterEvents, project, wastewaterParameters } =
      this.props;

    const {
      editModalParameterSlug,
      editResultsModalIsOpen,
      editParametersModalIsOpen,
      parameterSettingUploadModalIsOpen,
      samplingResultAttachmentToEdit,
      samplingResultsUploadModalIsOpen,
    } = this.state;

    return (
      <SamplingResultRecordPanel
        projectId={APP.projectId}
        hasConfiguredSamplingParameters={wastewaterParameters.length > 0}
        onCloseEditParametersModal={() =>
          this.setState({ editParametersModalIsOpen: false })
        }
        onCloseParameterSettingsUploadModal={() =>
          this.setState({ parameterSettingUploadModalIsOpen: false })
        }
        onCloseResultsEditModal={this.closeResultsEditModal}
        onCloseSamplingResultsUploadModal={() =>
          this.setState({ samplingResultsUploadModalIsOpen: false })
        }
        onDownloadEvents={() => downloadWastewaterEvents(project.id)}
        onOpenEditParametersModal={() =>
          this.setState({ editParametersModalIsOpen: true })
        }
        onOpenParameterSettingsUploadModal={() =>
          this.setState({ parameterSettingUploadModalIsOpen: true })
        }
        onOpenResultsEditModal={this.openResultsEditModal}
        onOpenSamplingResultsUploadModal={() =>
          this.setState({ samplingResultsUploadModalIsOpen: true })
        }
        editResultsModalIsOpen={editResultsModalIsOpen}
        editParametersModalIsOpen={editParametersModalIsOpen}
        editModalParameterSlug={editModalParameterSlug}
        parameterSettingUploadModalIsOpen={parameterSettingUploadModalIsOpen}
        samplingResultAttachmentToEdit={samplingResultAttachmentToEdit}
        samplingResultsUploadModalIsOpen={samplingResultsUploadModalIsOpen}
        wastewaterParameters={wastewaterParameters}
      />
    );
  }
}

SamplingResultRecordPanelContainer.propTypes = {
  downloadWastewaterEvents: PropTypes.func.isRequired,
  fetchCalendarFrequencies: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  fetchWastewaterMonitoringLocations: PropTypes.func.isRequired,
  fetchWastewaterSamplingParameters: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  wastewaterParameters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  project: state.project.id === APP.projectId ? state.project : {},
  wastewaterParameters: objectToSortedArray(
    state.wastewater.parameters,
    'parameter_slug',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  clearCalendarFrequencies: (calendarName) =>
    dispatch(clearCalendarFrequenciesAction({ calendarName })),
  fetchCalendarFrequencies: (calendarName) =>
    dispatch(fetchCalendarFrequenciesAction(APP.projectId, calendarName)),
  downloadWastewaterEvents: (projectId) =>
    downloadWastewaterEventsAction(projectId),
  fetchProject: () => dispatch(fetchProjectAction(APP.projectId)),
  fetchWastewaterMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
  fetchWastewaterSamplingParameters: () =>
    dispatch(fetchWastewaterSamplingParametersAction(APP.projectId)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SamplingResultRecordPanelContainer),
);
