import React, { useCallback, useState } from 'react';
import { useCurrentLogProject } from '../../LogProjectContext';
import { AccordionContainer, ErrorMessage } from '../../../elements';
import EditLogProject from './EditLogProject';
import './_logProjectHeader.scss';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LogProjectHeader = () => {
  const { logProject, isLoading, isError, error } = useCurrentLogProject();

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleEditing = useCallback(
    () => setIsExpanded(!isExpanded),
    [isExpanded],
  );

  if (isLoading) return null;
  if (isError) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <ErrorMessage errorMessage="Error loading log project" />;
  }
  return (
    <div className="log-project-header">
      <h1 className="page__header">{logProject?.name} Settings</h1>
      <AccordionContainer
        className="group__container "
        isExpanded={isExpanded}
        onToggle={toggleEditing}
        title="Log name and informational text"
      >
        <EditLogProject handleClose={() => setIsExpanded(false)} />
      </AccordionContainer>
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LogProjectHeader;
