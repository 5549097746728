/* eslint-disable */
let ExceedanceItemView = require('./ExceedanceItemView');
let NoExceedancesItemView = require('./NoExceedancesItemView');

let ExceedanceSummaryCollectionView = Backbone.Marionette.CollectionView.extend(
  {
    tagName: 'ul',
    className: 'list-styled',
    childView: ExceedanceItemView,
    emptyView: NoExceedancesItemView,

    initialize(options = {}) {
      this.projectModel = options.projectModel;
      this.noSamples = options.samplingEvents.length === 0;
    },

    isEmpty() {
      if (this.noSamples) {
        return true;
      }

      return !this.collection.reduce((memo, model) => {
        return (
          model.get('warnings').length || model.get('errors').length || memo
        );
      }, false);
    },

    filter(child) {
      return child.get('warnings').length || child.get('errors').length;
    },

    childViewOptions() {
      return { projectModel: this.projectModel };
    },

    emptyViewOptions() {
      return {
        text: this.noSamples
          ? 'No samples have been collected this year.'
          : 'This year, your stormwater samples are trending below limits.',
      };
    },
  },
);

module.exports = ExceedanceSummaryCollectionView;
