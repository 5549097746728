var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (kvps) {
      pug_html = pug_html + "\u003Cth class=\"center-center\"\u003ELocation\u003C\u002Fth\u003E";
// iterate kvps
;(function(){
  var $$obj = kvps;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cth\u003EChemical Attrs\u003C\u002Fth\u003E";
    }.call(this, "kvps" in locals_for_with ?
        locals_for_with.kvps :
        typeof kvps !== 'undefined' ? kvps : undefined));
    ;;return pug_html;};
module.exports = template;