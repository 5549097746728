/* eslint-disable */
let BaseNotificationCompositeView = require('./userNotifications/BaseNotificationCompositeView');

let ContentLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/notificationsContent.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    notifications0: '#notifications-container-0',
    notifications1: '#notifications-container-1',
    notifications2: '#notifications-container-2',
    notifications3: '#notifications-container-3',
    notifications4: '#notifications-container-4',
    notifications5: '#notifications-container-5',
    notifications6: '#notifications-container-6',
    notifications7: '#notifications-container-7',
    notifications8: '#notifications-container-8',
    notifications9: '#notifications-container-9',
    notifications10: '#notifications-container-10',
    notifications11: '#notifications-container-11',
    notifications12: '#notifications-container-12',
    notifications13: '#notifications-container-13',
    notifications14: '#notifications-container-14',
    notifications15: '#notifications-container-15',
    notifications16: '#notifications-container-16',
    notifications17: '#notifications-container-17',
    notifications18: '#notifications-container-18',
    notifications19: '#notifications-container-18',
    notifications20: '#notifications-container-20',
  },

  onShow() {
    let NotificationCollection = require('./userNotifications/NotificationCollection');
    let notificationCollection = new NotificationCollection({
      userId: this.model.id,
    });

    return notificationCollection.fetch().then(() => {
      const categories = _.uniq(notificationCollection.pluck('category'));
      const groupedModels = _.map(categories, (category) =>
        notificationCollection.where({ category }),
      );
      _.each(groupedModels, (models, index) => {
        let collection = new Backbone.Collection(models);
        this['notifications' + index].show(
          new BaseNotificationCompositeView({ collection }),
        );
      });
    });
  },
});

module.exports = ContentLayoutView;
