/* eslint-disable */
var WebMapMarkerIconFactory = require('./WebMapMarkerIconFactory');
var WebMapLabelOptionsFactory = require('./WebMapLabelOptionsFactory');
var StyleModel = require('../models_collections/styles/styleModel');

var WebMapMarkerFactory = {
  create: function (shapeModel) {
    var styleModel = shapeModel.get('styleModel');
    var customIcon = styleModel.getIcon();
    var rotation = !!shapeModel.get('rotation')
      ? parseInt(shapeModel.get('rotation'))
      : 0;
    var icon = WebMapMarkerIconFactory.create(styleModel, customIcon, rotation);
    var leafletLayer = L.marker(shapeModel.get('geography'), { icon: icon });

    if (styleModel.needToShowLabel()) {
      require('./leaderline/BaseLabelMethods');
      let labelOptions = WebMapLabelOptionsFactory.create(shapeModel);
      leafletLayer.bindLabel(shapeModel.get('labelContent'), labelOptions);

      const leaderlineStyle = styleModel.get('leaderline_style');
      if (leaderlineStyle && leaderlineStyle === 'standard') {
        leafletLayer
          .getLabel()
          .bindLeaderline(
            this._extractLeaderlineOptions(styleModel),
            leafletLayer,
          );
      }
    }
    return leafletLayer;
  },

  _extractLeaderlineOptions: (styleModel) => ({
    markerLatLng: styleModel.get('geography'),
    weight: styleModel.get('leaderline_weight'),
    opacity: styleModel.get('leaderline_opacity'),
    fillOpacity: 1.0,
    color: styleModel.get('leaderline_color'),
  }),

  createSearchMarker: function (shapeModel) {
    var styleModel = new StyleModel({
      size: '40',
      shape: 'map-marker',
      color: '#31B14A',
      opacity: 1,
      label_style: 'none',
    });

    shapeModel.set('styleModel', styleModel);
    return this.create(shapeModel);
  },

  createGeolocateMarker: function (shapeModel) {
    var styleModel = new StyleModel({
      size: 50,
      shape: 'circle',
      color: '#38A9DB',
      opacity: 0.7,
      label_style: 'none',
    });

    shapeModel.set('styleModel', styleModel);
    return this.create(shapeModel);
  },
};

module.exports = WebMapMarkerFactory;
