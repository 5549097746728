/* eslint-disable */
let moment = require('moment');
let DMRModalView = require('./DMRModalView');

module.exports = Backbone.Marionette.LayoutView.extend({
  tagName: 'li',
  className: 'flex align-items-center',
  template: require('./templates/dmrRow.pug'),

  regions: {
    modalContainer: '.modal-container',
  },

  events: {
    'click .open-modal': '_openModal',
  },

  ui: {
    $errors: '.errors',
    $warnings: '.warnings',
  },

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
  },

  templateHelpers() {
    let daysToAlmostOverdue = 30;
    let dueDate = moment(new Date(this.model.get('due_date')));
    let today = moment();
    let dateSubmitted = this.model.has('date_submitted')
      ? moment(new Date(this.model.get('date_submitted')))
      : null;

    return {
      overdue: !dateSubmitted && today.isAfter(dueDate),
      almostOverdue:
        !dateSubmitted &&
        today.add(daysToAlmostOverdue, 'days').isAfter(dueDate),
      label: dueDate.format('MMMM DD, YYYY'),
      daysToAlmostOverdue,
    };
  },

  onShow() {
    this.ui.$errors.popover();
    this.ui.$warnings.popover();
  },

  _openModal(e) {
    e.preventDefault();
    if (!this.isDisabled()) {
      let dmrModalView = new DMRModalView({ model: this.model });
      this.modalContainer.show(dmrModalView);
      this.listenTo(dmrModalView, 'hidden:modal', this.render);
      dmrModalView.showModal();
    }
  },
});
