/* eslint-disable */
/**
 * https://github.com/twbs/bootstrap/issues/16619
 * TODO: Implement a Bootstrap modal manager
 */
function bootstrapOverrides() {
  var APP = require('@monorepo/old-web/js/config');

  APP.vent.on('hidden.bs.modal', function () {
    if ($('.modal:visible').length > 0) {
      $('body').addClass('modal-open');
    }
  });
}

module.exports = bootstrapOverrides;
