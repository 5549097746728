/* eslint-disable */
import IntervalFrequency from '../../../../../../../../lib/IntervalFrequency';
import _get from 'lodash.get';

const moment = require('moment');
const projectStateConfig = require('mapistry-project-state-config');
const _ = require('underscore');

const SemiAnnualPeriodOutfallsAndFrequencyLayoutView = require('./SemiAnnualPeriodOutfallsAndFrequencyLayoutView');
const QuarterPeriodOutfallsAndFrequencyLayoutView = require('./QuarterPeriodOutfallsAndFrequencyLayoutView');

const getResultsInDateRange = (samplingResults, startMoment, endMoment) => {
  const halfOpenInterval = '[)';
  return samplingResults.filter((samplingResult) =>
    moment(samplingResult.event_date).isBetween(
      startMoment,
      endMoment,
      null,
      halfOpenInterval,
    ),
  );
};

const samplingParameterHasFrequency = (frequency) => (samplingParameter) =>
  samplingParameter.get('frequency') === frequency;

const dischargeLocationHasFrequency = (frequency) => (dischargeLocation) =>
  dischargeLocation.frequency === frequency;

const samplingParameterDischargeLocationsHaveFrequency =
  (frequency) => (samplingParameter) =>
    samplingParameter
      .get('discharge_locations')
      .every(dischargeLocationHasFrequency(frequency));

const mapSamplingParameterWithDischargeLocationFrequency =
  (frequency) => (samplingParameter) => {
    const frequencyOnlyDischargeLocations = samplingParameter
      .get('discharge_locations')
      .filter(dischargeLocationHasFrequency(frequency));
    return _.extend({}, samplingParameter.toJSON(), {
      discharge_locations: frequencyOnlyDischargeLocations,
    });
  };

module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/outfallAndFrequencySamplingSummary.pug'),

  regions: {
    firstPeriod: '#first-period-samples',
    secondPeriod: '#second-period-samples',
  },

  initialize(options = {}) {
    const { projectModel, samplingParameters, samplingResults, sectorData } =
      options;
    this.project = projectModel.toJSON();

    this.requiresSamplesAtAllLocations =
      projectStateConfig.hasDischargeLocationsPerParameter(this.project);
    this.requiredSamplingParameters = this.requiresSamplesAtAllLocations
      ? samplingParameters.filter(
          (samplingParameter) =>
            samplingParameter.get('discharge_locations') &&
            samplingParameter.get('discharge_locations').length > 0,
        )
      : samplingParameters;
    if (!this.requiresSamplesAtAllLocations) {
      const requiredParametersWithFrequency = this.requiredSamplingParameters
        .toJSON()
        .map((p) => ({
          ...p,
          frequency: sectorData.getParameterDefaultFrequency(
            p.parameter_slug,
            projectModel.get('pollutants_of_concern'),
          ),
        }));
      const sectorDataRequiredParameters =
        sectorData.getRequiredSamplingParameters({
          sicCodeSlugs: projectModel.getSicCodeSlugs(),
          industrialActivities: projectModel.get('industrial_activities'),
          isRcraFacility: projectModel.get('is_rcra_facility'),
          pollutantsOfConcern: projectModel.get('pollutants_of_concern'),
          siteDischargesIntoPugetSound: projectModel.get(
            'site_discharges_into_puget_sound',
          ),
        });
      const newRequiredParameters = sectorDataRequiredParameters
        .filter((p) =>
          this.requiredSamplingParameters
            .toJSON()
            .every(
              (requiredParameter) =>
                requiredParameter.parameter_slug !== p.slug,
            ),
        )
        .map((p) => ({
          ...p,
          // from UpdateParameterStatusModalCompositeView
          project_id: this.project.id,
          parameter_slug: p.slug,
          status: 'baseline',
          suite: 'stormwater',
          discharge_locations: [],
        }));
      this.requiredSamplingParameters.set(
        requiredParametersWithFrequency.concat(newRequiredParameters),
        { silent: true },
      );
    }

    this.samplingResults = samplingResults;
    const isSampledSemiAnnually =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project)
        ? samplingParameterHasFrequency(IntervalFrequency.BIANNUAL)
        : samplingParameterDischargeLocationsHaveFrequency(
            IntervalFrequency.BIANNUAL,
          );

    this.numberOfSamplesPerPeriod = this.requiredSamplingParameters.every(
      isSampledSemiAnnually,
    )
      ? 1
      : 2;
  },

  onShow() {
    if (this.numberOfSamplesPerPeriod === 1) {
      this._showSemiAnnualPeriods();
    } else {
      this._showQuarterPeriods();
    }
  },

  _showQuarterPeriods() {
    const startOfYearMoment = moment().startOf('year');
    const q2StartMoment = moment().month(3).startOf('month');
    const q3StartMoment = moment().month(6).startOf('month');
    const q4StartMoment = moment().month(9).startOf('month');
    const startOfNextYearMoment = moment().add(1, 'year').startOf('year');

    this._showSingleQuarterPeriod(
      'firstPeriod',
      'Jan - Mar',
      'Apr - June',
      startOfYearMoment,
      q2StartMoment,
      q3StartMoment,
    );
    this._showSingleQuarterPeriod(
      'secondPeriod',
      'July - Sept',
      'Oct - Dec',
      q3StartMoment,
      q4StartMoment,
      startOfNextYearMoment,
    );
  },
  _showSemiAnnualPeriods() {
    const startOfYearMoment = moment().startOf('year');
    const secondHalfStartMoment = moment().month(6).startOf('month');
    const startOfNextYearMoment = moment().add(1, 'year').startOf('year');

    this._showSingleSemiAnnualPeriod(
      'firstPeriod',
      'Jan - June',
      startOfYearMoment,
      secondHalfStartMoment,
    );
    this._showSingleSemiAnnualPeriod(
      'secondPeriod',
      'July - Dec',
      secondHalfStartMoment,
      startOfNextYearMoment,
    );
  },
  _showSingleQuarterPeriod(
    regionName,
    q1Label,
    q2Label,
    q1StartMoment,
    q2StartMoment,
    endMoment,
  ) {
    const isSemiAnnualParameter =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project)
        ? samplingParameterHasFrequency(IntervalFrequency.BIANNUAL)
        : (samplingParameter) =>
            samplingParameter
              .get('discharge_locations')
              .some(dischargeLocationHasFrequency(IntervalFrequency.BIANNUAL));
    const isQuarterlyParameter =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project)
        ? samplingParameterHasFrequency(IntervalFrequency.QUARTER)
        : (samplingParameter) =>
            samplingParameter
              .get('discharge_locations')
              .some(dischargeLocationHasFrequency(IntervalFrequency.QUARTER));

    const filteredSemiAnnualParams = this.requiredSamplingParameters.filter(
      isSemiAnnualParameter,
    );
    const filteredQuarterlyParams =
      this.requiredSamplingParameters.filter(isQuarterlyParameter);

    const semiAnnualParameters =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project)
        ? filteredSemiAnnualParams
        : new Backbone.Collection(
            filteredSemiAnnualParams.map(
              mapSamplingParameterWithDischargeLocationFrequency(
                IntervalFrequency.BIANNUAL,
              ),
            ),
          );

    const quarterlyParameters =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project)
        ? filteredQuarterlyParams
        : new Backbone.Collection(
            filteredQuarterlyParams.map(
              mapSamplingParameterWithDischargeLocationFrequency(
                IntervalFrequency.QUARTER,
              ),
            ),
          );

    const periodResults1 = getResultsInDateRange(
      this.samplingResults,
      q1StartMoment,
      q2StartMoment,
    );
    const periodResults2 = getResultsInDateRange(
      this.samplingResults,
      q2StartMoment,
      endMoment,
    );

    this.getRegion(regionName).show(
      new QuarterPeriodOutfallsAndFrequencyLayoutView({
        semiAnnualParameters,
        quarterlyParameters,
        q1Label: q1Label,
        q2Label: q2Label,
        requiresSamplesAtAllLocations: this.requiresSamplesAtAllLocations,
        samplingResultsQ1: periodResults1,
        samplingResultsQ2: periodResults2,
      }),
    );
  },
  _showSingleSemiAnnualPeriod(
    regionName,
    semiAnnualLabel,
    startMoment,
    endMoment,
  ) {
    const periodResults = getResultsInDateRange(
      this.samplingResults,
      startMoment,
      endMoment,
    );
    this.getRegion(regionName).show(
      new SemiAnnualPeriodOutfallsAndFrequencyLayoutView({
        requiresSamplesAtAllLocations: this.requiresSamplesAtAllLocations,
        samplingParameters: this.requiredSamplingParameters,
        samplingResults: periodResults,
        semiAnnualLabel: semiAnnualLabel,
      }),
    );
  },
});
