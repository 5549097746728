/* eslint-disable */
var APP = require('../config');
const BluebirdPromise = require('bluebird');

var TitlebarModel = Backbone.Model.extend({
  hasDefaultLogo: function () {
    return this.get('logo_image') === 'DEFAULT';
  },

  getLogoUrl: function () {
    if (!this.get('logo_image')) {
      return BluebirdPromise.resolve(APP.assetsRoot + 'ajax-loader.gif');
    }

    var url =
      APP.apiUrl +
      '/buckets/' +
      APP.titlebarLogoBucketName +
      '/images/' +
      this.get('logo_image');

    return BluebirdPromise.resolve($.get(url));
  },

  getImgSrc: function () {
    if (this.hasDefaultLogo()) {
      return BluebirdPromise.resolve(APP.DEFAULTS.TITLEBAR_LOGO);
    }

    return this.getLogoUrl();
  },
});

module.exports = TitlebarModel;
