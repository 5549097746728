/* eslint-disable */
var LayerDisplaySettingModel = Backbone.Model.extend({
  defaults: function () {
    return {
      id: this.cid,
      layer_on: true,
      layer_displayed_in_legend: true,
    };
  },
});

module.exports = LayerDisplaySettingModel;
