import { FormTemplateFieldTypes } from 'mapistry-shared';
import BaseValidator from './BaseValidator';
import PhoneNumberValidator from './PhoneNumberValidator';
import SignatureValidator from './SignatureValidator';
import TasksValidator from './TasksValidator';

class ValidationFactory {
  static For(templateField, submissionField, currentUser) {
    switch (templateField.type) {
      case FormTemplateFieldTypes.PHONE_NUMBER:
        return new PhoneNumberValidator(templateField, submissionField);
      case FormTemplateFieldTypes.SIGNATURE:
        return new SignatureValidator(
          templateField,
          submissionField,
          currentUser,
        );
      case FormTemplateFieldTypes.TASKS:
        return new TasksValidator(templateField, submissionField);
      default:
        return new BaseValidator(templateField, submissionField);
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ValidationFactory;
