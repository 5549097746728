/* eslint-disable */
let apiCaller = require('../../../../../../../apiCaller');
let errorView = require('../../../../../../../modals/errorView').singleton();
let infoView = require('../../../../../../../modals/infoView').singleton();
let router = require('../../../../../../../router');

module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/hmbp.pug'),

  initialize(options = {}) {
    this.hideHeader = options.hideHeader;
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadHmbpCollection = options.uploadHmbpCollection;
    this.projectModel = options.projectModel;
    this.hmbpYearStartDate = options.hmbpYearStartDate;
    this.hmbpYearEndDate = options.hmbpYearEndDate;
    this.isDisabled = options.isDisabled;
    this.planName = 'HMBP';
  },

  regions: {
    header: '#dashboard-hmbp-records-header',
    hmbpUpdates: '#dashboard-hmbp-updates-content-container',
    modalContainer: '#new-hmbp-update-modal-container',
  },

  onShow() {
    if (!this.hideHeader) {
      this._showHeader();
    }
    this._showHmbpUpdates();
  },

  _showHeader() {
    let HmbpRecordsHeader = require('./HmbpRecordsHeader');
    this.header.show(
      new HmbpRecordsHeader({
        hasProjectUpdatePermission: this.hasProjectUpdatePermission,
        uploadHmbpCollection: this.uploadHmbpCollection,
        projectModel: this.projectModel,
        planName: this.planName,
      }),
    );
  },

  _showHmbpUpdates() {
    let HmbpUpdateCollectionView = require('./HmbpUpdateCompositeView');
    let hmbpUpdateCollectionView = new HmbpUpdateCollectionView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      isDisabled: this.isDisabled,
      addNewUpdateModal: this._showModalView(),
      planName: this.planName,
    });

    this.hmbpUpdates.show(hmbpUpdateCollectionView);
  },

  _showModalView() {
    let ModalView = require('../../../shared/hmbpUpdates/HmbpUpdateModalView');
    let modalView = new ModalView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      title: 'HMBP',
    });

    this.modalContainer.show(modalView);
    return modalView;
  },
});
