/* eslint-disable */
var BaseDashboardLayoutView = Backbone.Marionette.LayoutView.extend({
  className: 'flex',
  template: require('./templates/dashboard.pug'),

  regions: {
    sidebar: '#dashboard-sidebar',
    content: '#dashboard-content',
  },
});

module.exports = BaseDashboardLayoutView;
