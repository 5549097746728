import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CalendarName } from 'mapistry-shared';
import { Loading } from '@monorepo/shared/components';
import withProvider from '../../../withProvider';
import PhCalibrationRecordList from './PhCalibrationRecordList';

import APP from '../../../../config';
import { deletePhCalibrationAction } from '../../../../actions/wastewater';
import {
  fetchParentIntervalsAction,
  fetchSamplingCalendarEvents,
} from '../../../../actions/calendar';
import { CalendarFrequencyType } from '../../../propTypes';

const PhCalibrationRecordListContainer = (props) => {
  const {
    deleteAttachment,
    fetchAttachments,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    fetchIntervalSkeleton,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    isLoading,
    monitoringLocations,
    openPhCalibrationModal,
    calendarFrequencies,
    projectId,
  } = props;

  useEffect(() => {
    if (projectId) {
      fetchIntervalSkeleton(projectId, CalendarName.PH_LOG_CALIBRATION);
    }
  }, [projectId, fetchIntervalSkeleton]);

  if (isLoading) {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ paddingTop: '50px' }}>
        <Loading size="medium" />
      </div>
    );
  }

  return (
    <PhCalibrationRecordList
      calendarFrequencies={calendarFrequencies}
      monitoringLocations={monitoringLocations}
      onFetchAttachment={fetchAttachments}
      onEditAttachment={openPhCalibrationModal}
      onDeleteAttachment={deleteAttachment}
      projectId={projectId}
    />
  );
};

PhCalibrationRecordListContainer.defaultProps = {
  calendarFrequencies: {},
  projectId: null,
};

PhCalibrationRecordListContainer.propTypes = {
  calendarFrequencies: PropTypes.arrayOf(CalendarFrequencyType),
  deleteAttachment: PropTypes.func.isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openPhCalibrationModal: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { calendar, project, wastewater } = state;
  const isLoading =
    wastewater.isFetching?.monitoringLocations ||
    project.isFetching ||
    !project.id;
  return {
    isLoading,
    monitoringLocations: wastewater.monitoringLocations,
    calendarFrequencies: calendar.frequencies[CalendarName.PH_LOG_CALIBRATION],
    projectId: project.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteAttachment: (attachment) =>
    dispatch(
      deletePhCalibrationAction({
        id: attachment.id,
        projectId: APP.projectId,
      }),
    ),
  fetchAttachments: (start, end) =>
    dispatch(
      fetchSamplingCalendarEvents({
        calendarName: CalendarName.PH_LOG_CALIBRATION,
        end,
        projectId: APP.projectId,
        start,
      }),
    ),
  fetchIntervalSkeleton: (projectId, calendarName) =>
    dispatch(fetchParentIntervalsAction(projectId, calendarName)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PhCalibrationRecordListContainer),
);
