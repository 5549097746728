/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _get from 'lodash.get';
import _ from 'underscore';
import { IntervalFrequency } from 'mapistry-shared';

import withProvider from '../../withProvider';
import APP from '../../../config';

import {
  fetchFlowLogsForChartAction,
  fetchWastewaterMonitoringLocationsAction,
} from '../../../actions/wastewater';

import {
  fetchCurrentUserSavedSettingAction,
  setCurrentUserSavedSettingAction,
} from '../../../actions/currentUser';

import { formattedCalendarDateFromEvent } from '../../../utils/calendarEvents';
import {
  delimitNumbers,
  fixedDecimalPlaces,
  isNullOrUndefined,
  UTCEquivalentOfLocal,
} from '../../../utils';

import Units from '../../../utils/units';

import UserSettings from '../../../types/UserSettings';

import FlowLogChart, { CALCULATION_TYPE, LOG_TYPE } from './FlowLogChart';

const CHART_SERIES_COLORS = {
  BLACK: '#1D3E4A',
  GREEN: '#5BAE57',
  BLUE: '#59ABCE',
  MAGENTA: '#ff00d9',
};

const LIMIT_COLOR = '#ff0000';

class FlowLogChartContainer extends Component {
  constructor(props) {
    super(props);
    const newDateInUTC = new Date(new Date().toUTCString());
    const thirtyDaysAgoUTC = new Date(
      newDateInUTC.setDate(newDateInUTC.getDate() - 30),
    ).toUTCString();
    this.state = {
      chartData: {
        axes: {
          x: null,
          y: null,
        },
        title: null,
        records: {},
      },
      filterSettings: {
        calculationType: null,
        frequency: null,
        logType: null,
        monitoringLocationIds: null,
        start: new Date(thirtyDaysAgoUTC),
        end: new Date(new Date().toUTCString()),
        units: null,
      },
      formErrors: {
        displayable: [],
      },
      settingsIsVisible: false,
    };
    this.buildRecordsForChart = this.buildRecordsForChart.bind(this);
    this.setFilterSetting = this.setFilterSetting.bind(this);
    this.toggleSettingsIsVisible = this.toggleSettingsIsVisible.bind(this);
  }

  componentDidMount() {
    const {
      fetchCurrentUserSavedSetting,
      fetchMonitoringLocations,
      monitoringLocations,
    } = this.props;
    if (monitoringLocations.length === 0) {
      fetchMonitoringLocations();
    }
    fetchCurrentUserSavedSetting();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchFlowLogsForChart,
      flowLogLimits,
      flowLogReadings,
      isLoading,
      savedSettings,
    } = this.props;
    const savedSettingsWereFetched =
      savedSettings &&
      !prevState.filterSettings.monitoringLocationIds &&
      !this.state.settingsIsVisible;

    const loadingHasFinished = prevProps.isLoading && !isLoading;
    const flowLogsHaveChanged = !_.isEqual(
      prevProps.flowLogReadings,
      flowLogReadings,
    );
    const limitsHaveChanged = !_.isEqual(
      prevProps.flowLogLimits,
      flowLogLimits,
    );
    if (savedSettingsWereFetched) {
      const { start, end, ...restOfSettings } = savedSettings;
      const dateConvertedSettings = {
        ...restOfSettings,
        start: new Date(start),
        end: new Date(end),
      };
      this.setState(
        (ps) =>
          update(ps, {
            filterSettings: {
              $set: dateConvertedSettings,
            },
          }),
        () => {
          fetchFlowLogsForChart({
            ...restOfSettings,
            startDate: UTCEquivalentOfLocal(new Date(start)).toISOString(),
            endDate: UTCEquivalentOfLocal(new Date(end)).toISOString(),
          });
        },
      );
    } else if (flowLogsHaveChanged) {
      const { filterSettings } = this.state;
      fetchFlowLogsForChart({
        ...filterSettings,
        startDate: UTCEquivalentOfLocal(
          new Date(filterSettings.start),
        ).toISOString(),
        endDate: UTCEquivalentOfLocal(
          new Date(filterSettings.end),
        ).toISOString(),
      });
    } else if (loadingHasFinished || limitsHaveChanged) {
      const { flowLogsForChart } = this.props;
      const { filterSettings } = this.state;
      const { monitoringLocationIds } = filterSettings;

      const hasLocations =
        !isNullOrUndefined(monitoringLocationIds) &&
        monitoringLocationIds.length > 0;
      if (hasLocations) {
        const locationId = monitoringLocationIds[0];
        const recordsAtLocation = _get(
          flowLogsForChart,
          `statsByLocation[${locationId}]`,
          [],
        );
        const recordsWithStats = recordsAtLocation.filter(
          (r) => !isNullOrUndefined(r.statistics),
        );
        const minRecordsNeededToDisplay = 3;
        if (recordsWithStats.length < minRecordsNeededToDisplay) {
          this.setState({
            formErrors: {
              displayable: [
                "There aren't enough flow logs to display. Try adjusting the date range for the chart or creating more log entries.",
              ],
            },
            settingsIsVisible: true,
          });
        } else {
          this.buildRecordsForChart();
        }
      }
    }
  }

  setFilterSetting(editField, nextValue) {
    this.setState((prevState) =>
      update(prevState, {
        filterSettings: {
          $merge: {
            [editField]: nextValue,
          },
        },
      }),
    );
  }

  toggleSettingsIsVisible() {
    const { fetchFlowLogsForChart, setCurrentUserSavedSetting } = this.props;
    const { filterSettings, settingsIsVisible } = this.state;

    if (!settingsIsVisible) {
      return this.setState({ settingsIsVisible: true });
    }

    const formErrors = {
      displayable: [],
    };

    const requiredFieldsWithMessage = [
      {
        field: 'monitoringLocationIds',
        message: 'At least one monitoring location is required.',
      },
      {
        field: 'logType',
        message: 'Log type is required.',
      },
      {
        field: 'start',
        message: 'Start date is required.',
      },
      {
        field: 'end',
        message: 'End date is required.',
      },
    ];

    requiredFieldsWithMessage.forEach((rf) => {
      if (isNullOrUndefined(filterSettings[rf.field])) {
        formErrors[rf.field] = rf.message;
        formErrors.displayable.push(rf.message);
      }
    });

    const unitsFields = ['units', 'calculationType', 'frequency'];
    const missingUnitsFields = unitsFields.filter((f) =>
      isNullOrUndefined(filterSettings[f]),
    );
    if (missingUnitsFields.length > 0) {
      const message = 'The units information is incomplete.';
      missingUnitsFields.forEach((f) => {
        formErrors[f] = message;
      });
      formErrors.displayable.push(message);
    }
    if (filterSettings.start >= filterSettings.end) {
      const message = 'Start date must be before end date.';
      formErrors.start = message;
      formErrors.end = message;
      formErrors.displayable.push(message);
    }

    if (formErrors.displayable.length === 0) {
      const { end, start, ...restOfSettings } = filterSettings;
      return this.setState(
        {
          formErrors: {
            displayable: [],
          },
          settingsIsVisible: false,
        },
        () => {
          fetchFlowLogsForChart({
            ...restOfSettings,
            startDate: UTCEquivalentOfLocal(new Date(start)).toISOString(),
            endDate: UTCEquivalentOfLocal(new Date(end)).toISOString(),
          });
          setCurrentUserSavedSetting({
            settingKey: UserSettings.FLOW_LOG_CHART,
            settingValue: filterSettings,
          });
        },
      );
    }

    return this.setState((prevState) =>
      update(prevState, {
        formErrors: {
          $set: formErrors,
        },
      }),
    );
  }

  buildRecordsForChart() {
    const { flowLogsForChart, monitoringLocations } = this.props;
    const { filterSettings } = this.state;
    const {
      calculationType,
      frequency,
      logType,
      monitoringLocationIds,
      units,
    } = filterSettings;

    const categories = flowLogsForChart.statsByLocation[
      monitoringLocationIds[0]
    ].map((record) =>
      formattedCalendarDateFromEvent({
        frequency,
        start: record.start,
      }),
    );

    const limitsSeries = flowLogsForChart.limits.map((limit, idx) => ({
      name: `${limit.limitType} limit ${idx + 1}`,
      type: 'line',
      data: flowLogsForChart.statsByLocation[monitoringLocationIds[0]].map(
        () => limit.limitValue,
      ),
    }));

    const locationDataSeries = monitoringLocations
      .filter((ml) => monitoringLocationIds.includes(ml.id))
      .map((ml) => {
        const recordsAtLocation = flowLogsForChart.statsByLocation[ml.id];
        const dataSeries = recordsAtLocation.map((record) => {
          const recordInPeriod = record.statistics;
          if (recordInPeriod) {
            return recordInPeriod[calculationType];
          }
          return null;
        });
        return {
          name: ml.name,
          type: 'line',
          data: dataSeries,
        };
      });
    const chartSeries = locationDataSeries.concat(limitsSeries);
    const markerSizes = _.range(locationDataSeries.length)
      .map(() => 3)
      .concat(_.range(limitsSeries.length).map(() => 0));
    const lineColors = Object.values(CHART_SERIES_COLORS);
    const locationDataColors = monitoringLocationIds.map(
      (id, idx) => lineColors[idx % lineColors.length],
    );
    const limitColors = limitsSeries.map(() => LIMIT_COLOR);
    const colors = locationDataColors.concat(limitColors);

    const calculation = {
      [CALCULATION_TYPE.average]: 'Average',
      [CALCULATION_TYPE.max]: 'Max',
      [CALCULATION_TYPE.total]: 'Total',
    };
    const type = {
      [LOG_TYPE.flowRate]: 'Flow Rate',
      [LOG_TYPE.volume]: 'Volume',
    };
    const yAxisLabel =
      `${calculation[calculationType]} ${new IntervalFrequency(
        frequency,
      ).toString()} ` +
      `${type[logType]} (${Units.formatUnitDisplayText(units)})`;
    const chartData = {
      axes: {
        x: 'Log date',
        y: yAxisLabel,
      },
      colors,
      markers: {
        size: markerSizes,
      },
      plotOptions: {
        line: {
          stroke: {
            curve: 'straight',
            width: 2,
          },
        },
      },
      records: {
        categories,
        series: chartSeries,
      },
      yaxis: {
        labels: {
          formatter: (value) => delimitNumbers(fixedDecimalPlaces(value, 2)),
        },
      },
    };
    this.setState({
      chartData,
    });
  }

  render() {
    const { isLoading, monitoringLocations } = this.props;

    const { chartData, filterSettings, formErrors, settingsIsVisible } =
      this.state;
    return (
      <FlowLogChart
        chartData={chartData}
        errors={formErrors}
        filterSettings={filterSettings}
        isLoading={isLoading}
        monitoringLocations={monitoringLocations}
        setFilterSetting={this.setFilterSetting}
        settingsIsVisible={settingsIsVisible}
        toggleSettingsIsVisible={this.toggleSettingsIsVisible}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { wastewater, currentUser } = state;
  return {
    isLoading:
      _get(wastewater, 'isFetching.flowLogsForChart', false) ||
      _get(wastewater, 'isFetching.monitoringLocations', false) ||
      _get(wastewater, 'isFetching.flowLogLimits', false),
    flowLogsForChart: wastewater.flowLogsForChart,
    flowLogLimits: wastewater.flowLogLimits,
    flowLogReadings: wastewater.flowLogReadings,
    monitoringLocations: wastewater.monitoringLocations,
    savedSettings: _get(currentUser.userSettings, UserSettings.FLOW_LOG_CHART),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
  fetchFlowLogsForChart: (query) =>
    dispatch(fetchFlowLogsForChartAction(APP.projectId, query)),
  fetchCurrentUserSavedSetting: () =>
    dispatch(
      fetchCurrentUserSavedSettingAction(
        APP.projectId,
        UserSettings.FLOW_LOG_CHART,
      ),
    ),
  setCurrentUserSavedSetting: (userSetting) =>
    dispatch(setCurrentUserSavedSettingAction(APP.projectId, userSetting)),
});

FlowLogChartContainer.propTypes = {
  fetchCurrentUserSavedSetting: PropTypes.func.isRequired,
  fetchMonitoringLocations: PropTypes.func.isRequired,
  fetchFlowLogsForChart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowLogsForChart: PropTypes.shape({}),
  flowLogLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowLogReadings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  savedSettings: PropTypes.shape({
    calculationType: PropTypes.string,
    end: PropTypes.string,
    frequency: PropTypes.string,
    logType: PropTypes.string,
    monitoringLocationIds: PropTypes.arrayOf(PropTypes.string),
    start: PropTypes.string,
    units: PropTypes.string,
  }),
  setCurrentUserSavedSetting: PropTypes.func.isRequired,
};

FlowLogChartContainer.defaultProps = {
  flowLogsForChart: null,
  savedSettings: null,
};

export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(FlowLogChartContainer),
);
