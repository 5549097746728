import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { IntervalFrequency, IntervalFrequencyEnum } from 'mapistry-shared';

import { formattedCalendarDateFromEvent } from '../../../../utils/calendarEvents';

import { isNullOrUndefined } from '../../../../utils';

import RecordPanelCalendarChildren from '../RecordPanelCalendarChildren';
import RecordPanelListItemExpandable from '../RecordPanelListItemExpandable';
import { CalendarFrequencyType } from '../../../propTypes';

class RecordPanelCalendarAccordion extends Component {
  render() {
    const {
      calendarName,
      frequencies,
      parentIntervals,
      onDeleteAttachment,
      onEditAttachment,
      onFetchAttachment,
      projectId,
      resourceConversionCollection,
      parameterSlug,
    } = this.props;

    if (!frequencies.length) {
      return null;
    }

    const getNameFromKey = (id) => {
      if (resourceConversionCollection) {
        const match = resourceConversionCollection.find((c) => c.id === id);
        return match?.name || id;
      }
      return id;
    };
    const getFrequencyFromLocationId = (locationId) => {
      const match = frequencies.find((f) => f.resourceId === locationId);
      return match.frequency;
    };

    const buildChildren = (child, locationId) => {
      if (isNullOrUndefined(child.children)) {
        // This is the final iteration
        return (
          <RecordPanelCalendarChildren
            key={`${locationId}.${child.start}.${child.end}`}
            onDeleteAttachment={onDeleteAttachment}
            onEditAttachment={onEditAttachment}
            onFetchAttachment={onFetchAttachment}
            calendarName={calendarName}
            event={child}
            frequency={getFrequencyFromLocationId(locationId)}
            locationId={locationId}
            projectId={projectId}
            parameterSlug={parameterSlug}
          />
        );
      }

      return child.children.map((c) =>
        c.children ? (
          <RecordPanelListItemExpandable
            key={`${locationId}.${child.start}.${child.end}`}
            details={buildChildren(c, locationId)}
            summary={<span>{formattedCalendarDateFromEvent(c)}</span>}
          />
        ) : (
          buildChildren(c, locationId)
        ),
      );
    };

    const accordionCollection = (collection, locationId) => {
      const frequency = getFrequencyFromLocationId(locationId);
      const mustRecursivelyBuildChildren =
        frequency === IntervalFrequencyEnum.AS_NEEDED ||
        new IntervalFrequency(frequency) >=
          new IntervalFrequency(IntervalFrequencyEnum.TWICE_MONTH);
      const topLevelChildComponent = mustRecursivelyBuildChildren
        ? (child) => (
            <RecordPanelListItemExpandable
              key={`${locationId}.${child.start}.${child.end}`}
              summary={<span>{formattedCalendarDateFromEvent(child)}</span>}
              details={buildChildren(child, locationId)}
            />
          )
        : (child) => (
            <RecordPanelCalendarChildren
              key={`${locationId}.${child.start}.${child.end}`}
              onDeleteAttachment={onDeleteAttachment}
              onEditAttachment={onEditAttachment}
              onFetchAttachment={onFetchAttachment}
              calendarName={calendarName}
              event={child}
              frequency={frequency}
              locationId={locationId}
              projectId={projectId}
              parameterSlug={parameterSlug}
            />
          );

      return (
        <RecordPanelListItemExpandable
          key={locationId}
          details={_get(collection, 'year.intervals', []).map(
            topLevelChildComponent,
          )}
          summary={<span>{getNameFromKey(locationId)}</span>}
        />
      );
    };

    if (_.isArray(parentIntervals)) {
      throw new Error(
        'Ensure arrays are properly handled when we run into them',
      );
    }
    return (
      <div className="accordion-container">
        {_.map(parentIntervals, (collection, locationId) =>
          accordionCollection(collection, locationId),
        )}
      </div>
    );
  }
}

RecordPanelCalendarAccordion.propTypes = {
  calendarName: PropTypes.string.isRequired,
  frequencies: PropTypes.arrayOf(CalendarFrequencyType).isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onEditAttachment: PropTypes.func.isRequired,
  onFetchAttachment: PropTypes.func.isRequired,
  parameterSlug: PropTypes.string.isRequired,
  parentIntervals: PropTypes.shape({}).isRequired,
  projectId: PropTypes.string,
  resourceConversionCollection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

RecordPanelCalendarAccordion.defaultProps = {
  projectId: null,
  resourceConversionCollection: [],
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default RecordPanelCalendarAccordion;
