/* eslint-disable */
// Models
var ErrorModel = require('../models_collections/error');

// Services
var APP = require('../config');

var ErrorView = Backbone.View.extend({
  template: require('./templates/error.pug'),

  events: {
    'hidden.bs.modal': '_hideModal',
  },

  render: function () {
    this.setElement(this.template(this.model.toJSON()));
    this.$el.modal('show');
    return this;
  },

  /**
   * Show the modal
   * @param options - eg { error: '[String]', text: '[Html to display around error]' }
   */
  showModal: function (options) {
    this.isShowing = true;
    this._name = 'ErrorView';
    this.model = new ErrorModel(options);
    this.render();
  },

  _hideModal: function () {
    this.isShowing = false;
    this.remove();
  },
});

Backbone.addSingletonToView(ErrorView);
module.exports = ErrorView;
