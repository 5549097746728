/* eslint-disable */
import _ from 'underscore';
import ChemicalSelectView from '../ChemicalSelectView/ChemicalSelectView';

const ContainerTypeSelectView = ChemicalSelectView.extend({
  template: require('./ContainerTypeSelectView.pug'),

  ui: {
    description: 'input[name=container_description]',
    select: 'select',
    popover: '[data-toggle="popover"]',
  },

  onRender() {
    this.ui.popover.popover();
    const { width = '200px' } = this.options;
    const options = this.model.get('options');
    const value = formatValue(this.model.get('value'));

    let data = options.map((item) => ({
      id: item.key,
      text: item.label,
      selected: _.contains(value, item.key),
    }));

    this.ui.select.select2({
      placeholder: 'Select attributes',
      data,
      width,
      disabled: false,
    });
  },

  templateHelpers() {
    const value = this.model.get('value');

    return {
      isOptionSelected: ({ key }) => {
        return Array.isArray(value) ? value.includes(key) : value === key;
      },
    };
  },
});

function formatValue(value) {
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === 'string') {
    return [value];
  }
  return [];
}

module.exports = ContainerTypeSelectView;
