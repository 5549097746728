import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';
import APP from '../../config';

export const queryKey = (logProjectId: string) => ['loggedItems', logProjectId];

const fetcher = (_: string, logProjectId: string) =>
  Api.fetchLoggedItems({ logProjectId, projectId: APP.projectId });

/**
 * @deprecated Use @monorepo/genericLogs/src/hooks
 */
export const useLoggedItems = (logProjectId: string) => {
  const { data, ...rest } = useQuery(queryKey(logProjectId), fetcher);
  return {
    loggedItems: data,
    ...rest,
  };
};
