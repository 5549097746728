import { SaveState } from '@monorepo/shared/types/SaveState';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { queryCache } from 'react-query';
import { connect } from 'react-redux';
import _ from 'underscore';
import {
  addSamplingParameterAtMonitoringLocationAction,
  addWastewaterSamplingParameterAction,
  deleteSamplingParameterAction,
  deleteSamplingParameterAtMonitoringLocationAction,
  editSamplingParameterAtMonitoringLocationAction,
  fetchWastewaterMonitoringLocationsAction,
  fetchWastewaterSamplingParametersAction,
} from '../../../../actions/wastewater';
import APP from '../../../../config';
import { matchSamplingEventsKey } from '../../../../hooks/water/useWastewaterSamplingEvents';
import { goToMapsUrl } from '../../../../router';
import { delayedModalClose } from '../../../../utils';
import {
  getAvailableSamplingParameters,
  getParameterDisplayText,
  getSectorData,
} from '../../../../utils/shared';
import withProvider from '../../../withProvider';
import EditParametersModal from './EditParametersModal';

class EditParametersModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsFetching: false,
      formSaveState: SaveState.CLEAN,
      formSubmissionDraft: {
        parameters: props.parameters,
      },
      parameterIdToReceiveSPML: null,
      parameterIdToRemoveSPML: null,
      stagedEdits: {},
    };
    this.addSamplingParameter = this.addSamplingParameter.bind(this);
    // eslint-disable-next-line max-len
    this.addSamplingParameterAtMonitoringLocation =
      this.addSamplingParameterAtMonitoringLocation.bind(this);
    this.deleteSamplingParameter = this.deleteSamplingParameter.bind(this);
    // eslint-disable-next-line max-len
    this.deleteSamplingParameterAtMonitoringLocation =
      this.deleteSamplingParameterAtMonitoringLocation.bind(this);
    this.stageSamplingParameterEdit =
      this.stageSamplingParameterEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const {
      fetchWastewaterMonitoringLocations,
      fetchWastewaterSamplingParameters,
    } = this.props;
    fetchWastewaterSamplingParameters();
    fetchWastewaterMonitoringLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { parameters } = this.props;
    const currentParameters = Object.keys(parameters);
    const previousParameters = Object.keys(prevProps.parameters);

    const isInitialLoad =
      currentParameters.length > 0 && previousParameters.length === 0;

    if (isInitialLoad) {
      // When parameters come back after the first load, initialize the draft with them.
      this.setState({
        formSaveState: SaveState.CLEAN,
        formSubmissionDraft: { parameters },
        stagedEdits: {},
      });
    } else if (currentParameters.length > previousParameters.length) {
      // The user has just added a parameter. We should merge it into the current draft
      // to avoid overwriting any other staged data currently in the drarft.
      const newParameterId = _.difference(
        currentParameters,
        previousParameters,
      );
      this.setState((prev) =>
        update(prev, {
          formSubmissionDraft: {
            parameters: {
              [newParameterId]: { $set: parameters[newParameterId] },
            },
          },
        }),
      );
    } else if (currentParameters.length < previousParameters.length) {
      // The user has just deleted a parameter. We should merge it into the current draft
      // to avoid overwriting any other staged data currently in the drarft.
      const deletedParameterId = _.difference(
        previousParameters,
        currentParameters,
      );
      this.setState((prev) =>
        update(prev, {
          formSubmissionDraft: {
            parameters: { $unset: [deletedParameterId] },
          },
        }),
      );
    } else if (prevState.parameterIdToReceiveSPML) {
      // The user has just added a new limit group (sampling_parameter_monitoring_location)
      // Update the correct parameter[id].sp_monitoring_location draft record to reflect the change.
      const parameterId = prevState.parameterIdToReceiveSPML;
      const previousSPML =
        prevProps.parameters[parameterId].sp_monitoring_locations;
      const newSPML = _.difference(
        parameters[parameterId].sp_monitoring_locations,
        previousSPML,
      );

      if (newSPML.length > 0) {
        this.setState((prev) =>
          update(prev, {
            formSubmissionDraft: {
              parameters: {
                [parameterId]: {
                  sp_monitoring_locations: {
                    $push: [newSPML[0]],
                  },
                },
              },
            },
            parameterIdToReceiveSPML: { $set: null },
          }),
        );
      }
    } else if (prevState.parameterIdToRemoveSPML) {
      // The user has just removed a limit group (sampling_parameter_monitoring_location)
      // Update the correct parameter[id].sp_monitoring_location draft record to reflect the change.
      const parameterId = prevState.parameterIdToRemoveSPML;
      const previousSPML =
        prevProps.parameters[parameterId].sp_monitoring_locations;
      const deletedSPML = _.difference(
        previousSPML,
        parameters[parameterId].sp_monitoring_locations,
      );
      if (deletedSPML.length > 0) {
        const deletedSPMLIndex = previousSPML.findIndex(
          (i) => i.id === deletedSPML[0].id,
        );
        this.setState((prev) =>
          update(prev, {
            formSubmissionDraft: {
              parameters: {
                [parameterId]: {
                  sp_monitoring_locations: {
                    $splice: [[deletedSPMLIndex, 1]],
                  },
                },
              },
            },
            parameterIdToRemoveSPML: { $set: null },
          }),
        );
      }
    }
  }

  addSamplingParameter(param) {
    const { addWastewaterSamplingParameter } = this.props;
    addWastewaterSamplingParameter(param);
  }

  addSamplingParameterAtMonitoringLocation(
    wastewaterSamplingParameterId,
    monitoringLocationId,
  ) {
    const { addSamplingParameterAtMonitoringLocation } = this.props;
    this.setState(
      {
        parameterIdToReceiveSPML: wastewaterSamplingParameterId,
      },
      () =>
        addSamplingParameterAtMonitoringLocation(
          wastewaterSamplingParameterId,
          monitoringLocationId,
        ),
    );
  }

  deleteSamplingParameter(paramId) {
    const { deleteSamplingParameter } = this.props;
    deleteSamplingParameter(paramId);
  }

  deleteSamplingParameterAtMonitoringLocation(sPML) {
    const { deleteSamplingParameterAtMonitoringLocation } = this.props;
    this.setState(
      {
        parameterIdToRemoveSPML: sPML.wastewater_sampling_parameter_id,
      },
      () => deleteSamplingParameterAtMonitoringLocation(sPML),
    );
  }

  stageSamplingParameterEdit(
    editField,
    nextValue,
    samplingParameterMonitoringLocation,
  ) {
    const {
      formSubmissionDraft: { parameters },
    } = this.state;
    const parameterId =
      samplingParameterMonitoringLocation.wastewater_sampling_parameter_id;
    const matchingSPMLIndex = parameters[
      parameterId
    ].sp_monitoring_locations.findIndex(
      (i) => i.id === samplingParameterMonitoringLocation.id,
    );

    this.setState((prevState) =>
      update(prevState, {
        stagedEdits: {
          $merge: {
            [samplingParameterMonitoringLocation.id]: {
              ...prevState.stagedEdits[samplingParameterMonitoringLocation.id],
              wastewater_sampling_parameter_id:
                samplingParameterMonitoringLocation.wastewater_sampling_parameter_id,
              monitoring_location_id:
                samplingParameterMonitoringLocation.monitoring_location_id,
              [editField]: nextValue,
            },
          },
        },
        formSaveState: { $set: SaveState.DIRTY },
        formSubmissionDraft: {
          parameters: {
            [parameterId]: {
              sp_monitoring_locations: {
                [matchingSPMLIndex]: {
                  $merge: {
                    [editField]: nextValue,
                  },
                },
              },
            },
          },
        },
      }),
    );
  }

  submitForm() {
    const { stagedEdits } = this.state;
    const { editSamplingParameterAtMonitoringLocation, onClose, projectId } =
      this.props;
    this.setState(
      { formIsFetching: true, formSaveState: SaveState.SAVING },
      () =>
        Promise.all(
          Object.keys(stagedEdits).map((k) =>
            editSamplingParameterAtMonitoringLocation({
              id: k,
              ...stagedEdits[k],
            }),
          ),
        )
          .then(() => {
            this.setState(
              {
                formIsFetching: false,
                formSaveState: SaveState.SAVED,
              },
              () => delayedModalClose(onClose),
            );
          })
          .catch(() => {
            this.setState({ formIsFetching: false });
          })
          .finally(() => {
            const queryKeyForAllSamplingEvents =
              matchSamplingEventsKey(projectId);
            queryCache.removeQueries(queryKeyForAllSamplingEvents);
          }),
    );
  }

  render() {
    const { formIsFetching, formSaveState, formSubmissionDraft } = this.state;
    const {
      canUpload,
      monitoringLocations,
      onClose,
      onOpenUpload,
      open,
      organizationId,
      parameters,
      projectId,
    } = this.props;
    APP.isNavigationBlocked = formSaveState === SaveState.DIRTY;

    // eslint-disable-next-line react/destructuring-assignment
    const availableSamplingParameters = _.filter(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/destructuring-assignment
      this.props.availableSamplingParameters,
      (p) => !_.contains(_.pluck(parameters, 'parameter_slug'), p.slug),
    );
    const draftParametersList = Object.values(formSubmissionDraft.parameters);
    const parametersWithDisplayText = draftParametersList.map((p) => ({
      ...p,
      displayText: getParameterDisplayText(p.parameter_slug),
    }));
    const sortedParameters = parametersWithDisplayText.sort((p1, p2) =>
      p1.displayText.localeCompare(p2.displayText),
    );
    return (
      <EditParametersModal
        addSamplingParameterAtMonitoringLocation={
          this.addSamplingParameterAtMonitoringLocation
        }
        addSamplingParameter={this.addSamplingParameter}
        availableSamplingParameters={availableSamplingParameters}
        canUpload={canUpload}
        deleteSamplingParameter={this.deleteSamplingParameter}
        deleteSamplingParameterAtMonitoringLocation={
          this.deleteSamplingParameterAtMonitoringLocation
        }
        isLoading={formIsFetching}
        formSaveState={formSaveState}
        // TODO replace when React router is implemented
        goToMapsURL={() => goToMapsUrl(organizationId, projectId)}
        stageSamplingParameterEdit={this.stageSamplingParameterEdit}
        monitoringLocations={monitoringLocations}
        open={open}
        onClose={onClose}
        onOpenUpload={onOpenUpload}
        parameters={sortedParameters}
        submitForm={this.submitForm}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    project,
    wastewater: { monitoringLocations, parameters },
  } = state;
  const sectorData = getSectorData('federal');

  return {
    availableSamplingParameters: getAvailableSamplingParameters(sectorData),
    monitoringLocations,
    organizationId: project.organizationId,
    parameters,
    projectId: project.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // eslint-disable-next-line max-len
  addSamplingParameterAtMonitoringLocation: (
    wastewaterSamplingParameterId,
    monitoringLocationId,
  ) =>
    // eslint-disable-next-line max-len
    dispatch(
      addSamplingParameterAtMonitoringLocationAction({
        wastewaterSamplingParameterId,
        monitoringLocationId,
        projectId: APP.projectId,
      }),
    ),
  addWastewaterSamplingParameter: (parameterSlug) =>
    dispatch(
      addWastewaterSamplingParameterAction({
        parameterSlug,
        projectId: APP.projectId,
      }),
    ),
  deleteSamplingParameter: (paramId) =>
    dispatch(
      deleteSamplingParameterAction({ paramId, projectId: APP.projectId }),
    ),
  deleteSamplingParameterAtMonitoringLocation: (sPML) =>
    dispatch(
      deleteSamplingParameterAtMonitoringLocationAction({
        sPML,
        projectId: APP.projectId,
      }),
    ),
  editSamplingParameterAtMonitoringLocation: (parameter) =>
    dispatch(
      editSamplingParameterAtMonitoringLocationAction({
        parameter,
        projectId: APP.projectId,
      }),
    ),
  fetchWastewaterSamplingParameters: () =>
    dispatch(fetchWastewaterSamplingParametersAction(APP.projectId)),
  fetchWastewaterMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
});

EditParametersModalContainer.propTypes = {
  addSamplingParameterAtMonitoringLocation: PropTypes.func.isRequired,
  addWastewaterSamplingParameter: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  availableSamplingParameters: PropTypes.arrayOf(PropTypes.object),
  canUpload: PropTypes.bool,
  deleteSamplingParameter: PropTypes.func.isRequired,
  deleteSamplingParameterAtMonitoringLocation: PropTypes.func.isRequired,
  editSamplingParameterAtMonitoringLocation: PropTypes.func.isRequired,
  fetchWastewaterSamplingParameters: PropTypes.func.isRequired,
  fetchWastewaterMonitoringLocations: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpenUpload: PropTypes.func,
  open: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
  parameters: PropTypes.shape({}),
  projectId: PropTypes.string,
};

EditParametersModalContainer.defaultProps = {
  availableSamplingParameters: [],
  canUpload: false,
  onOpenUpload: null,
  organizationId: null,
  parameters: {},
  projectId: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(EditParametersModalContainer),
);
