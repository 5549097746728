/* eslint-disable */
var apiCaller = require('../../../../../apiCaller');

var projectUserDataProvider = {
  getProjectUsers: function (projectId) {
    var url = apiCaller.getProjectUsersUrl(projectId);
    return Promise.resolve($.get(url));
  },

  saveProjectInvitation: function (projectId, email, role, name) {
    var data = {
      email,
      name: name || undefined,
      role,
    };
    var url = apiCaller.getProjectInvitationUrl(projectId);
    var jqPromise = $.ajax({
      url: url,
      type: 'POST',
      data: data,
      dataType: 'json',
    });
    return Promise.resolve(jqPromise);
  },

  getInvitedUserInfo: function (emails) {
    let data = _.isArray(emails) ? emails : [emails];
    let url = apiCaller.getUserInfoSearchUrl(data);
    let jqPromise = $.get(url);
    return Promise.resolve(jqPromise);
  },

  removeProjectUser: function (userId, projectId, roleToRemove) {
    if (!(userId && projectId && roleToRemove)) {
      throw new Error('Must pass in userId, projectId, and roleToRemove.');
    }

    var data = {
      userId: userId,
      projectId: projectId,
      roleToRemove: roleToRemove,
    };
    var url = apiCaller.getProjectUserUrl(projectId, userId);
    return Promise.resolve(
      $.ajax({
        url: url,
        type: 'DELETE',
        data: data,
      }),
    );
  },

  updateUserProjectRole: function (userId, projectId, role) {
    var data = { role: role };
    var url = apiCaller.getUpdateProjectUserPermissionsUrl(projectId, userId);
    var jqPromise = $.ajax({
      url: url,
      type: 'PUT',
      data: data,
    }).done(function (result) {
      return result;
    });
    return Promise.resolve(jqPromise);
  },
};

module.exports = projectUserDataProvider;
