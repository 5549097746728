/* eslint-disable */
var Scrollbar = Backbone.Marionette.Behavior.extend({
  onShow: function () {
    this.view.$el.perfectScrollbar({
      suppressScrollX: true,
    });
  },
});

module.exports = Scrollbar;
