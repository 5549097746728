var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isChecked, pollutantsOfConcernOptions) {
      pug_html = pug_html + "\u003Clabel\u003EAdditionally, we may need to know:\u003C\u002Flabel\u003E\u003Cdiv class=\"well\"\u003E\u003Clabel\u003EDo you discharge to waters that have any of the following pollutants of concern?\u003C\u002Flabel\u003E\u003Cdiv class=\"pollutants-of-concern\"\u003E";
// iterate pollutantsOfConcernOptions
;(function(){
  var $$obj = pollutantsOfConcernOptions;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var pollutantOption = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"checkbox\"\u003E\u003Clabel\u003E\u003Cinput" + (" type=\"checkbox\" name=\"pollutants_of_concern\""+pug.attr("value", pollutantOption.slug, true, true)+pug.attr("checked", isChecked(pollutantOption.slug), true, true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = pollutantOption.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var pollutantOption = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"checkbox\"\u003E\u003Clabel\u003E\u003Cinput" + (" type=\"checkbox\" name=\"pollutants_of_concern\""+pug.attr("value", pollutantOption.slug, true, true)+pug.attr("checked", isChecked(pollutantOption.slug), true, true)) + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = pollutantOption.label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isChecked" in locals_for_with ?
        locals_for_with.isChecked :
        typeof isChecked !== 'undefined' ? isChecked : undefined, "pollutantsOfConcernOptions" in locals_for_with ?
        locals_for_with.pollutantsOfConcernOptions :
        typeof pollutantsOfConcernOptions !== 'undefined' ? pollutantsOfConcernOptions : undefined));
    ;;return pug_html;};
module.exports = template;