/* eslint-disable */
const apiCaller = require('../../../../../../../../apiCaller');

module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/swppp.pug'),

  initialize(options = {}) {
    this.hideHeader = options.hideHeader;
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadSwpppCollection = options.uploadSwpppCollection;
    this.projectModel = options.projectModel;
    this.swpppYearStartDate = options.swpppYearStartDate;
    this.swpppYearEndDate = options.swpppYearEndDate;
    this.isDisabled = options.isDisabled;
    this.planName = options.planName;
  },

  regions: {
    header: '#dashboard-swppp-records-header',
    swpppUpdates: '#dashboard-swppp-updates-content-container',
    modalContainer: '#new-swppp-update-modal-container',
  },

  onShow() {
    if (!this.hideHeader) {
      this._showHeader();
    }
    this._showSwpppUpdates();
  },

  _showHeader() {
    let SwpppRecordsHeader = require('./SwpppRecordsHeader');
    this.header.show(
      new SwpppRecordsHeader({
        hasProjectUpdatePermission: this.hasProjectUpdatePermission,
        uploadSwpppCollection: this.uploadSwpppCollection,
        projectModel: this.projectModel,
        planName: this.planName,
      }),
    );
  },

  _showSwpppUpdates() {
    let SwpppUpdateCollectionView = require('./SwpppUpdateCompositeView');
    let swpppUpdateCollectionView = new SwpppUpdateCollectionView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      isDisabled: this.isDisabled,
      downloadSwpppUpdatesUrl: apiCaller.downloadSwpppUpdateLogUrl(
        this.projectModel.id,
        this.swpppYearStartDate,
        this.swpppYearEndDate,
      ),
      addNewUpdateModal: this._showModalView(),
      planName: this.planName,
    });

    this.swpppUpdates.show(swpppUpdateCollectionView);
  },

  _showModalView() {
    const ModalView = require('../../../../shared/swpppUpdates/SwpppUpdateModalView');
    const modalView = new ModalView({
      collection: this.collection,
      projectState: this.projectModel.get('state'),
      title: this.planName,
    });

    this.modalContainer.show(modalView);
    return modalView;
  },
});
