/* eslint-disable */
var _ = require('underscore');
var _s = require('underscore.string');

var SamplingResultSummaryRowView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  template: require('./templates/samplingResultSummaryRow.pug'),

  templateHelpers: function () {
    let hasWarning = !!this.model.get('warnings').length;
    let hasError = !!this.model.get('errors').length;

    return {
      hasWarning: hasWarning,
      hasError: hasError,
      getWarningClass: () => {
        if (hasError) {
          return 'warning-red';
        } else if (hasWarning) {
          return 'warning-yellow';
        } else {
          return '';
        }
      },
    };
  },

  ui: {
    $statusIcon: '.dashboard-item-status-icon-container svg',
  },

  onRender: function () {
    this._enablePopovers();
  },

  _enablePopovers: function () {
    let messages = this.model.get('errors').concat(this.model.get('warnings'));
    this.ui.$statusIcon.popover({
      content: _s.toSentenceSerial(messages),
      trigger: 'hover',
      container: 'body',
    });
  },
});

module.exports = SamplingResultSummaryRowView;
