/* eslint-disable */
let RecordsSidebarItemView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',
  className: 'no-style-list',
  template: require('./templates/recordsSidebarRow.pug'),
  events: {
    'click .sidebar-item-link': '_showRecords',
  },

  initialize() {
    this.listenTo(this.model, 'deselect', this.deselect);
    this.listenTo(this.model, 'select', this.select);
  },

  _showRecords() {
    this.trigger('click-sidebar-item-link');
    return this.model.get('showRecordsView')();
  },

  deselect() {
    this.$el.removeClass('selected');
  },

  select() {
    this.$el.addClass('selected');
  },
});

module.exports = RecordsSidebarItemView;
