import React, { memo } from 'react';
import { MapistryTooltip } from '../../elements';
import { LoggedItemValueEvent } from './types';

interface ColumnHeaderProps {
  loggedItemValueEvents: LoggedItemValueEvent[];
}

export const ColumnHeaders = memo(
  ({ loggedItemValueEvents }: ColumnHeaderProps) => {
    if (!loggedItemValueEvents.length) {
      return (
        <div className="emission-tracking-list__item">
          <span className="emission-tracking-list__item-child">
            Date / Time
          </span>
        </div>
      );
    }
    return (
      <>
        <div className="emission-tracking-list__item-wrapper">
          <div className="emission-tracking-list__item">
            <span className="emission-tracking-list__item-child">
              Date / Time
            </span>
          </div>
          {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
          {loggedItemValueEvents[0].statistics.map((stat) => (
            <MapistryTooltip
              key={stat.resourceId}
              placement="top"
              title={stat.label}
            >
              <div className="emission-tracking-list__item">
                <span className="emission-tracking-list__item-child">
                  {stat.label}
                </span>
              </div>
            </MapistryTooltip>
          ))}
        </div>
        <div className="emission-tracking-list__actions">
          <span className="emission-tracking-list__item-child">Actions</span>
        </div>
      </>
    );
  },
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ColumnHeaders;
