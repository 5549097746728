var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (dropdown, id, label, swppp_year) {
      if (dropdown) {
pug_html = pug_html + "\u003Ca" + (pug.attr("data-model-id", id, true, true)+pug.attr("swppp-year", swppp_year, true, true)+" data-toggle=\"dropdown\"") + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003Ci class=\"fa fa-caret-down padding-left-10\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (pug.attr("data-model-id", id, true, true)+pug.attr("swppp-year", swppp_year, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cul class=\"dropdown-menu\" id=\"tabs-dropdown-menu\"\u003E\u003C\u002Ful\u003E";
    }.call(this, "dropdown" in locals_for_with ?
        locals_for_with.dropdown :
        typeof dropdown !== 'undefined' ? dropdown : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "swppp_year" in locals_for_with ?
        locals_for_with.swppp_year :
        typeof swppp_year !== 'undefined' ? swppp_year : undefined));
    ;;return pug_html;};
module.exports = template;