import React from 'react';
import MultiSelectField from './MultiSelectField';
import SelectCreatable from '../../../elements/SelectCreatable';

class MultiSelectFreeFormField extends MultiSelectField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);

    return (
      <SelectCreatable
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        isMulti
        error={error}
      />
    );
  }

  formatValue(formattedOptions) {
    const parsedValue = this.getParsedValue();
    return parsedValue.map((value) => {
      const selectedOption = formattedOptions.find(
        (option) => option.value === value,
      );
      return selectedOption || { value, label: value };
    });
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiSelectFreeFormField;
