import React, { useCallback } from 'react';
import { usePromptDialog } from '@monorepo/shared/hooks/usePromptDialog';
import { AddButton } from '@monorepo/old-web/js/components/elements';
import { OnboardingApi } from '../../apiClient';

interface OnboardingDocumentsTableNewDocumentRowProps {
  onCreateDocumentRequest: (
    documentRequest: OnboardingApi.CreateOnboardingDocumentRequestValues,
  ) => void;
  sectionId: string;
}

export function OnboardingDocumentsTableNewDocumentRow(
  props: OnboardingDocumentsTableNewDocumentRowProps,
) {
  const { sectionId, onCreateDocumentRequest } = props;

  const prompt = usePromptDialog();

  const handleAdd = useCallback(async () => {
    const title = await prompt({
      title: 'What is the title of this subsection?',
      confirmButtonText: 'Create subsection',
    });
    if (!title) return;
    onCreateDocumentRequest({
      onboardingSectionId: sectionId,
      title,
      notes: '',
    });
  }, [onCreateDocumentRequest, sectionId, prompt]);

  return (
    <tr className="document-row">
      <td>
        <AddButton type="button" color="secondary" onClick={handleAdd}>
          Add a subsection
        </AddButton>
      </td>
      <td />
      <td />
      <td />
      <td />
    </tr>
  );
}
