/* eslint-disable */
var AverageRowView = require('./AverageRowView');

var StormAverageRowView = AverageRowView.extend({
  tagName: 'table',

  className: 'table',

  template: require('./templates/stormAverageRow.pug'),

  modelEvents: {
    'change:storm_average': 'render',
  },
});

module.exports = StormAverageRowView;
