var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (content, editMode) {
      if (editMode) {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control edit-input\""+pug.attr("value", content, true, true)) + "\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"simple-text-content\"\u003E" + (pug.escape(null == (pug_interp = content) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
    }.call(this, "content" in locals_for_with ?
        locals_for_with.content :
        typeof content !== 'undefined' ? content : undefined, "editMode" in locals_for_with ?
        locals_for_with.editMode :
        typeof editMode !== 'undefined' ? editMode : undefined));
    ;;return pug_html;};
module.exports = template;