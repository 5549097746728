/* eslint-disable */
var APP = require('../../config');
var projectMapsService = require('../../project/services/projectMapsService');

// Collections
var NewAndEditMapModelBase = require('./newAndEditMapModelBase');
var mapCollection =
  require('../../models_collections/mapCollection').singleton();
var titlebarCollection =
  require('../../models_collections/titlebarCollection').singleton();
var projectCollection =
  require('../../models_collections/projectCollection').singleton();

var NewMapModel = NewAndEditMapModelBase.extend({
  initialize: function () {
    this.project = projectCollection.getCurrent();
  },

  /**
   * Save the supplied titelbar params, if no logo was choosen, set the titlebar
   * params to have the logoImage that matches the curently selected map.
   */
  setTitlebarParams: function (titlebarForm, noLogoChosen) {
    this.titlebarParams = titlebarForm;

    // If a logo was not choosen, set the logoImage to be the same logo as the
    // currently selected image
    this.titlebarParams.logo_image = noLogoChosen
      ? projectMapsService.getSelectedMap().titlebar().get('logo_image')
      : '';
  },

  /**
   * Create a new map by copying the parameters from the currently selected map,
   * and give it the name supplied by the user
   */
  createMap: function () {
    return new Promise(
      function (resolve, reject) {
        // Set new map's default location to be the location of the currently
        // selected map
        var selectedMap = projectMapsService.getSelectedMap();
        var mapParams = selectedMap.toJSON();
        mapParams.name = this.get('name');
        (mapParams.offFeatureIds = selectedMap.offFeatureIds()),
          (mapParams.offLayerIds = selectedMap.offLayerIds()),
          delete mapParams.id;

        mapCollection.create(mapParams, {
          wait: true,
          success: function (map) {
            this.map = map;
            this.map.initializeDisplaySettingCollections();
            resolve();
          }.bind(this),
          error: reject,
        });
      }.bind(this),
    );
  },

  /**
   * Update the default titlebar created on the server of the new map with the
   * user supplied parameters. Also make sure that the new map is selected.
   */
  updateTitlebarAndSelectedMap: function () {
    // Fetch silently. Not ready to do any updates to map until the titlebar
    // has been updated.
    return new Promise(
      function (resolve, reject) {
        titlebarCollection
          .fetch({ silent: true })
          .done(
            function () {
              // Save silently. Do not need to update displayed titlebar yet,
              // since the displayed titlebar is not for map yet
              this.map
                .titlebar()
                .save(this.titlebarParams, { silent: true })
                .done(
                  function () {
                    this.project
                      .save({ selected_map: this.map.id })
                      .always(resolve);
                  }.bind(this),
                )
                .fail(reject);
            }.bind(this),
          )
          .fail(reject);
      }.bind(this),
    );
  },

  /**
   * Copy the logo image of this.titlebarParms.logoImage and update the
   * this.map's titlebar accordingly
   */
  copyLogoImage: function () {
    // Copy titlebar image so that this map has a its own copy of the logo
    return new Promise(
      function (resolve) {
        $.ajax({
          url:
            APP.apiUrl + '/buckets/' + APP.titlebarLogoBucketName + '/images/',
          type: 'post',
          data: {
            srcKey: this.titlebarParams.logo_image,
            projectId: APP.projectId,
            mapId: this.map.id,
          },
        })
          .done(
            function (res) {
              this.titlebarParams.logo_image = res.key;
            }.bind(this),
          )
          .fail(
            function () {
              this.titlebarParams.logo_image = 'DEFAULT';
            }.bind(this),
          )
          .always(
            function () {
              // Update the logoImage for the titlebar
              this.map.titlebar().save(this.titlebarParams);
              resolve();
            }.bind(this),
          );
      }.bind(this),
    );
  },
});

module.exports = NewMapModel;
