/* eslint-disable */
/* eslint-disable import/default */
import apiCaller from '@monorepo/old-web/js/apiCaller';
import { ComponentWrapper } from '@monorepo/old-web/js/components/componentWrapper';
import FlowLogChart from '@monorepo/old-web/js/components/wastewater/FlowLogChart';
import APP from '@monorepo/old-web/js/config';
import currentUser from '@monorepo/old-web/js/currentUser';
import FeaturesInDischargeLocationsLayerCollection from '@monorepo/old-web/js/dashboards/projects//content/custom/industrial/stormwater/siteSummary/stormwaterSamples/FeaturesInDischargeLocationsLayerCollection';
import SamplingResultSummaryCollection from '@monorepo/old-web/js/dashboards/projects//content/custom/shared/sampling/resultSummaries/SamplingResultSummaryCollection';
import DMRCollection from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/stormwater/records/dischargeMonitoringReports/DMRCollection';
import StormwaterParameterLayoutView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/stormwater/siteSummary/currentStatus/StormwaterParameterLayoutView';
import ExceedancesSummaryCollectionView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/stormwater/siteSummary/exceedances/ExceedancesSummaryCollectionView';
import ParameterFrequencyCollectionView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/stormwater/siteSummary/parameterFrequency/ParameterFrequencyCollectionView';
import StormwaterOutfallsAndFrequencySummaryLayoutView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/stormwater/siteSummary/stormwaterSamples/StormwaterOutfallsAndFrequencySummaryLayoutView.js';
import SamplingEventCollection from '@monorepo/old-web/js/dashboards/projects/content/custom/shared/sampling/results/SamplingEventLabelCollection';
import InfoView from '@monorepo/old-web/js/modals/infoView';
import SamplingParametersCollection from '@monorepo/old-web/js/models_collections/samplingParametersCollection';
import { endOfYear, format, startOfYear } from 'date-fns';
import sectorData from 'industrial-stormwater-sector-data';
import projectStateConfig from 'mapistry-project-state-config';
import { CalendarName } from 'mapistry-shared';
import React from 'react';

const ProjectRecordsLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/records.pug'),

  className: 'backbone-wrapper-content-container',

  regions: {
    flowLogChart: '#flow-log-chart',
    projectRecords: '#project-records',
    parameterFrequency: '#parameter-frequency',
    stormwaterSamplingParameters: '#stormwater-sampling-parameters',
    stormwaterSamplesOutfallsSummary:
      '#stormwater-samples-outfalls-summary-container',
    exceedances: '#exceedances-summary-container',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.project = options.projectModel.toJSON();
    this.projectCalendars = options.projectCalendars;
    this.dashboardSettings = currentUser.get('projectDashboardSettings')[
      this.project.id
    ];

    this.isWastewaterEnabled = this.projectModel.isWastewaterEnabled();
    this.isStormwaterSamplingEnabled =
      this._hasCalendar(CalendarName.STORMWATER_VISUAL_INSPECTION) ||
      this._hasCalendar(CalendarName.STORMWATER_ROUTINE_INSPECTION);
    this.showExceedances =
      this._projectSupportsExceedances() && this.isStormwaterSamplingEnabled;
    this.showSamplesOutfallsSummary =
      (projectStateConfig.hasSamplingParamFrequencyPerParameter(this.project) ||
        projectStateConfig.hasSamplingParamFrequencyPerOutfall(this.project)) &&
      this.isStormwaterSamplingEnabled;
    this.showParameterFrequency = projectStateConfig.isStateIndustrial(
      this.project,
      'al',
    );
    this.sectorData = sectorData(this.project.state);
  },

  templateHelpers() {
    return {
      isStormwaterSamplingEnabled: this.isStormwaterSamplingEnabled,
      isWastewaterEnabled: this.isWastewaterEnabled,
      showExceedances: this.showExceedances,
      showParameterFrequency: this.showParameterFrequency,
      showSamplesOutfallsSummary: this.showSamplesOutfallsSummary,
    };
  },

  async onShow() {
    // used by stormwater widgets and records -> stormwater sampling results
    await this._fetchStormwaterData();

    /*
      this flow widget is broken (Feb 2023), and causes the rest of the page to render slowly
      if the user has flow widget filter settings set. We're not displaying it for now, but
      are hoping to fix the widget in the future (thus not fully removing it right now)
    */
    // if (this.isWastewaterEnabled) {
    // this._showFlowLogChart();
    // }

    if (this.showParameterFrequency) {
      this._showParameterFrequencySummary();
    }
    if (this.showSamplesOutfallsSummary) {
      this._showStormwaterSamplesOutfallsSummary();
    }
    if (this.isStormwaterSamplingEnabled) {
      this._showCurrentStatusSummary();
    }
    if (this.showExceedances) {
      this._showExceedancesSummary();
    }
    if (!this.dashboardSettings || this.dashboardSettings.recordsPanelEnabled) {
      this._showRecords();
    }
  },

  _hasCalendar(calendarName) {
    return this.projectCalendars?.some(
      (c) => c.name === calendarName && this._isCalendarWidgetsEnabled(c),
    );
  },

  _isCalendarWidgetsEnabled(calendar) {
    return (
      !this.dashboardSettings ||
      !this.dashboardSettings.calendarsEnabled ||
      this.dashboardSettings.calendarsEnabled.includes(calendar.id)
    );
  },

  _projectSupportsExceedances() {
    const statesWithoutExceedances = [
      'ar',
      'il',
      'in',
      'la',
      'mi',
      'oh',
      'pa',
      'tn',
      'wv',
    ];
    return !projectStateConfig.isOneOfStatesIndustrial(
      this.project,
      statesWithoutExceedances,
    );
  },

  _projectSupportsParameterStatus() {
    const statesWithoutParameterStatus = [
      'al',
      'ar',
      'il',
      'in',
      'la',
      'mi',
      'oh',
      'pa',
      'sc',
      'tn',
      'va',
      'wv',
    ];
    return !projectStateConfig.isOneOfStatesIndustrial(
      this.project,
      statesWithoutParameterStatus,
    );
  },

  _fetchStormwaterData() {
    const fetchOptions = { silent: true, reset: true };
    const fetchPromises = [
      SamplingParametersCollection.fetchSingleton(fetchOptions),
      FeaturesInDischargeLocationsLayerCollection.fetchSingleton(fetchOptions),
      SamplingResultSummaryCollection.fetchSingleton(fetchOptions),
    ];
    if (projectStateConfig.isWashingtonIndustrial(this.project)) {
      fetchPromises.push(DMRCollection.fetchSingleton(fetchOptions));
    }
    return Promise.all(fetchPromises);
  },

  async _showRecords() {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const RecordsLayoutView = require('../../custom/industrial/stormwater/records/RecordsLayoutView');
    const recordsLayoutView = new RecordsLayoutView({
      project: this.projectModel,
      isDisabled: (requiredCalendars, text) =>
        this._isDisabled(requiredCalendars, text),
      projectCalendars: this.projectCalendars,
    });
    this.projectRecords.show(recordsLayoutView);
  },

  _isDisabled(requiredCalendars, text) {
    if (
      !this.projectCalendars.some((pc) => requiredCalendars.includes(pc.name))
    ) {
      InfoView.singleton().showModal({
        title: 'This Feature Is Not Included In Your Plan',
        text: `${text} Please contact Mapistry if you are interested! You can contact us with the chat box in the lower right hand corner of your screen.`,
      });
      return true;
    }
    return false;
  },

  _showFlowLogChart() {
    this.flowLogChart.show(
      new ComponentWrapper(<FlowLogChart />, { noStyling: true }),
    );
  },

  _showParameterFrequencySummary() {
    const permitDsns = this.projectModel.getPermitDsnSlugs();
    const industrialActivities = this.projectModel.getIndustrialActivitySlugs();
    const requiredParameters = this.sectorData.getRequiredSamplingParameters({
      permitDsns,
      industrialActivities,
    });

    const parameterFrequencyCollectionView =
      new ParameterFrequencyCollectionView({
        collection: new Backbone.Collection(requiredParameters),
      });

    return this.parameterFrequency.show(parameterFrequencyCollectionView);
  },

  _showCurrentStatusSummary() {
    const samplingParametersCollection =
      SamplingParametersCollection.singleton();
    const samplingParameters = samplingParametersCollection.filter(
      (p) => p.get('id') !== null && p.get('suite') === 'stormwater',
    );
    const parameterCollection = new SamplingParametersCollection(
      samplingParameters,
    );
    const isWashingtonIndustrial = projectStateConfig.isWashingtonIndustrial(
      this.project,
    );
    const showParameterStatus =
      this._projectSupportsParameterStatus() &&
      this.isStormwaterSamplingEnabled;

    const stormwaterParameterView = new StormwaterParameterLayoutView({
      collection: parameterCollection,
      showParameterStatus,
      sectorData: this.sectorData,
      isWashingtonIndustrial,
      projectModel: this.projectModel,
    });
    return this.stormwaterSamplingParameters.show(stormwaterParameterView);
  },

  _showExceedancesSummary() {
    const startDate = projectStateConfig
      .getSwpppYearStartDateAsMoment(this.project)
      .format('YYYY-MM-DD');
    const endDate = format(endOfYear(new Date()), 'yyyy-MM-dd');
    const samplingEventCollection = new SamplingEventCollection([], {
      projectId: this.projectModel.id,
      startDate,
      endDate,
      skipBlankSamplingEvent: true,
    });
    return samplingEventCollection.fetchPromise().then((samplingEvents) => {
      const exceedancesSummaryCollectionView =
        new ExceedancesSummaryCollectionView({
          collection: SamplingResultSummaryCollection.singleton(),
          projectModel: this.projectModel,
          samplingEvents,
        });

      return this.exceedances.show(exceedancesSummaryCollectionView);
    });
  },

  _showStormwaterSamplesOutfallsSummary() {
    const samplingParametersCollection =
      SamplingParametersCollection.singleton();
    this.listenTo(
      samplingParametersCollection,
      'add remove update reset',
      this._showOutfallsFrequencyView.bind(this),
    );

    const globalEvents = [
      'sampleEventParameterLocation:change',
      'pollutantsOfConcern:change',
      'sampleEventParameter:add',
      'sampleEventParameter:destroy',
      'sampleEventParameter:reset',
      'sampleParameter:update',
    ];
    this.listenTo(
      APP.vent,
      globalEvents.join(' '),
      this._showOutfallsFrequencyView.bind(this),
    );

    return this._showOutfallsFrequencyView();
  },

  _showOutfallsFrequencyView() {
    const projectId = this.project.id;
    const now = new Date();
    const startDate = format(startOfYear(now), 'yyyy-MM-dd');
    const endDate = format(endOfYear(now), 'yyyy-MM-dd');
    const samplingParametersCollection =
      SamplingParametersCollection.singleton();
    const samplingEventCollection = new SamplingEventCollection([], {
      projectId,
      startDate,
      endDate,
      skipBlankSamplingEvent: true,
    });
    return samplingParametersCollection
      .fetchPromise()
      .then(() => samplingEventCollection.fetchPromise())
      .then(() => {
        const samplingEventIds = samplingEventCollection.pluck('id');
        return Promise.all(
          samplingEventIds.map((samplingEventId) =>
            apiCaller.getSamplingEvent(projectId, samplingEventId),
          ),
        );
      })
      .then((samplingResults) => {
        this.stormwaterSamplesOutfallsSummary.show(
          new StormwaterOutfallsAndFrequencySummaryLayoutView({
            projectModel: this.projectModel,
            samplingParameters: samplingParametersCollection,
            samplingResults,
            sectorData: this.sectorData,
          }),
        );
      });
  },
});

export default ProjectRecordsLayoutView;
