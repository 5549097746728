var pug = require("!../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (hasProjectUpdatePermission) {
      pug_html = pug_html + "\u003Ch5 class=\"flex align-items-center\"\u003E\u003Cspan\u003ETags:\u003C\u002Fspan\u003E";
if (hasProjectUpdatePermission) {
pug_html = pug_html + "\u003Ca class=\"edit-tags margin-left-5 flex\" rel=\"tipsy\" title=\"Edit tags\"\u003E\u003Csvg class=\"edit small\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fh5\u003E\u003Cdiv id=\"project-tags\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003EEdit Tags\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel for=\"tag-type-selection\"\u003EType\u003C\u002Flabel\u003E\u003Cselect id=\"tag-type-selection\" name=\"tag-types\"\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\" id=\"tag-name-selection-group\"\u003E\u003Clabel for=\"tag-name\"\u003EName\u003C\u002Flabel\u003E\u003Cselect id=\"tag-name-selection\" name=\"tag-name\" multiple\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success\" data-dismiss=\"modal\" aria-label=\"Close\"\u003EDone\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "hasProjectUpdatePermission" in locals_for_with ?
        locals_for_with.hasProjectUpdatePermission :
        typeof hasProjectUpdatePermission !== 'undefined' ? hasProjectUpdatePermission : undefined));
    ;;return pug_html;};
module.exports = template;