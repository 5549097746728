/* eslint-disable */
const APP = require('../config');
const FeaturePhotosCollection = require('../models_collections/ProjectFeaturePhotoCollection');
const FeaturePhotosCollectionView = require('./FeaturePhotoCollectionView');
const apiCaler = require('../apiCaller');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/featurePhotoLayout.pug'),

  ui: {
    $uploadPhotoButton: '#feature-upload-photo-btn',
    $uploadPhoto: 'input#feature-upload-photo',
    $photoUploadSpinner: '#feature-photo-upload-spinner',
  },

  events: {
    'click @ui.$uploadPhotoButton': '_selectPhoto',
    'change @ui.$uploadPhoto': '_savePhoto',
  },

  initialize: function (options) {
    this.featureId = options.featureId;
    this.listenTo(
      APP.vent,
      'projectFeaturePhoto:destroy',
      this._removePhoto.bind(this),
    );
    this.listenTo(
      APP.vent,
      'projectFeaturePhoto:show',
      this._showPhotoOverlay.bind(this),
    );
  },

  postRender: function () {
    this._enableButtons();
    this.photos = new FeaturePhotosCollection(this.featureId);
    this.photos.fetchPromise().then(() => {
      const containerEl = $('#feature-photo-entries-container', this.$el);
      const photoCollectionView = new FeaturePhotosCollectionView({
        collection: this.photos,
      });
      const photoEl = photoCollectionView.render();
      const content = photoEl.el;
      containerEl.html(content);
    });
  },

  _removePhoto(photo) {
    photo.destroy();
  },

  _showPhotoOverlay(photo) {
    APP.vent.trigger('featurePhotoLayoutView:showPhotoOverlay', photo);
  },

  _disableButtons() {
    this.ui.$uploadPhotoButton.prop('disabled', true);
  },

  _enableButtons() {
    this.ui.$uploadPhotoButton.prop('disabled', false);
    this.ui.$photoUploadSpinner.hide();
  },

  _selectPhoto() {
    this.ui.$uploadPhoto.click();
  },

  _savePhoto() {
    let file = this.ui.$uploadPhoto.prop('files')[0];
    if (!file) return;

    this._disableButtons();
    this.ui.$photoUploadSpinner.css('display', 'inline-block');

    let data = new FormData();
    data.append('projectId', APP.projectId);
    data.append('featureId', this.featureId);
    data.append('file', file);

    this.photos
      .createPromise(null, {
        data,
        processData: false,
        contentType: false,
        wait: true,
      })
      .then(() => {
        this._enableButtons();
        this.ui.$uploadPhoto.val('');
      });
  },
});
