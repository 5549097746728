/* eslint-disable */
// Views
const LayerNameChooserView = require('../formWidgets/LayerNameChooserView');

// Collections
const layerCollection =
  require('../models_collections/layerCollection').singleton();
const featureCollection =
  require('../models_collections/featureCollection').singleton();

const { buildingNameOptionMapper, getBuildingNames } = require('./utils');
const APP = require('../config');

const NewFeatureView = Backbone.View.extend({
  template: require('./templates/newFeature.pug'),

  events: {
    'shown.bs.modal': '_handleModalShown',
    'hidden.bs.modal': '_hideModal',
    'click button[type="submit"]': '_submitNewFeature',
  },

  render: function () {
    this.setElement(this.template());
    this._layerNameChooserView = this._renderLayerNameChooserView();
    this.$el.modal('show');
  },

  showModal: function (webMapFeature) {
    this.model = webMapFeature;
    this.render();
  },

  _renderLayerNameChooserView: function () {
    let layerNameChooserView = new LayerNameChooserView();
    this.$('.layer-name-widget-container').html(
      layerNameChooserView.render().el,
    );
    this.listenTo(
      layerNameChooserView,
      'layer:chosen',
      this._updateFeatureName,
    );
    return layerNameChooserView;
  },

  _renderBuildingNameSelect() {
    const buildingNames = getBuildingNames(layerCollection);
    this.$el.find('.building-name').select2({
      placeholder: 'Select a building (optional)',
      tags: buildingNames.map(buildingNameOptionMapper()),
      allowClear: true,
      dropdownParent: this.$el,
      width: '100%',
    });
  },

  _handleModalShown: function () {
    let layerType = this.model.get('type');
    let layer = layerCollection.findWhere({ name: this._cachedLayerName });
    let cachedLayerType = layer && layer.getType();
    let layerTypeMatches = layerType === cachedLayerType;
    let defaultValue;

    if (layerTypeMatches) {
      defaultValue = layer.id;
      this._updateFeatureName(layer);
    }

    this._renderBuildingNameSelect();

    this._layerNameChooserView.activate(
      layerCollection,
      layerType,
      defaultValue,
    );

    APP.vent.trigger('newFeatureModal:shown');
  },

  _hideModal: function () {
    window.scrollTo(0, 0);
    this.remove();
  },

  _getLayerAndFeatureName: function () {
    let $form = this.$el.find('form');
    let data = $form.serializeObject();
    return {
      layer: layerCollection.get(data.layer_id),
      featureName: data.featureName,
      buildingName: data.buildingName,
    };
  },

  _submitNewFeature: function (e) {
    e.preventDefault();

    let { layer, featureName, buildingName } = this._getLayerAndFeatureName();
    let valid = this._validate(featureName, layer);

    if (valid) {
      this._btnClicked = $(e.target).data('id');
      this.$el.modal('hide');
      this._saveFeature(featureName, layer, buildingName);
    }
  },

  _validate: function (featureName, layer) {
    let $errorsNode = $('<ul>');
    let featureType = this.model.get('type');
    let errors = layer
      ? layer.validateFeatureName(featureName)
      : ['Please choose a layer in order to draw your ' + featureType + '.'];
    if (layer && featureType !== layer.getType()) {
      errors.push(
        'You cannot add a ' +
          featureType +
          ' to a ' +
          layer.getType() +
          ' layer.',
      );
    }

    _.each(errors, function (msg) {
      $errorsNode.append('<li>' + msg + '</li>');
    });

    if ($errorsNode.find('li').length) {
      this.$('.alert').html($errorsNode.html()).show();
    } else {
      return true;
    }
  },

  _saveFeature: function (featureName, layer, buildingName) {
    let data = {
      name: featureName,
      building_name: buildingName,
      type: this.model.get('type'),
      geography: this.model.get('geography'),
      layer_id: layer.id,
    };

    return new Promise(
      function (resolve, reject) {
        featureCollection.create(data, {
          wait: true,
          success: function () {
            this._cachedLayerName = layer.get('name');
            APP.vent.trigger('newFeature:saved');
            resolve();
          }.bind(this),
          error: reject,
        });
      }.bind(this),
    );
  },

  _getLastFeatureName(featureNames) {
    let featureNamesEndingInNumbers = _.filter(featureNames, (fn) =>
      fn.match(/\d+$/),
    );

    if (!featureNamesEndingInNumbers.length) return null;

    let indicies = _.map(featureNamesEndingInNumbers, (fn) => ({
      index: parseInt(fn.match(/\d+$/)[0]),
      featureName: fn,
    }));
    return _.last(indicies.sort((a, b) => a.index - b.index));
  },

  _updateFeatureName: function (initLayer) {
    if (!initLayer) {
      let { layer } = this._getLayerAndFeatureName();
      initLayer = layer;
    }

    let featureNames = initLayer.featureNames();
    let lastFeature = this._getLastFeatureName(featureNames);
    let featureIndex = lastFeature ? lastFeature.index : 0;
    let baseFeatureName = lastFeature
      ? lastFeature.featureName.substr(
          0,
          lastFeature.featureName.lastIndexOf(lastFeature.index),
        )
      : featureNames.length
      ? featureNames[0]
      : '';

    this.$el.find('#feature-input').val(baseFeatureName + (featureIndex + 1));
    APP.vent.trigger('layer:chosen');
  },
});

Backbone.addSingletonToView(NewFeatureView);
module.exports = NewFeatureView;
