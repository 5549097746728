/* eslint-disable */
const escape = require('lodash.escape');
const unescape = require('lodash.unescape');
const BluebirdPromises = require('bluebird');

// Services
var APP = require('../config');
var bigSpinner = require('../services/bigSpinner');
var projectMapsService = require('../project/services/projectMapsService');

// Collections
var layerCollection =
  require('../models_collections/layerCollection').singleton();
var mapCollection = require('../models_collections/mapCollection').singleton();
var featureCollection =
  require('../models_collections/featureCollection').singleton();

// Models
var UploadTable = require('./tableModel');

// Views
var questionsView = require('./questionsView').singleton();
var confirmView = require('../modals/confirmView').singleton();
var errorView = require('../modals/errorView').singleton();

/**
 * The Backbone.View for the uploading a csv file
 */
var TableView = Backbone.View.extend({
  events: {
    'click .clickable': '_columnClicked',
    'click #btn-done': '_finishUpload',
    'click .editable': '_editColumnHeader',
    'blur .edit-column': '_doneEditingHeader',
    'click #btn-skip': '_handleSkip',
    'click #btn-back': '_handleBack',
  },

  initialize: function (options) {
    this._bucket = options.bucket;

    this.$el = $('#uploaded-table');
    this._$errorNode = this.$el.find('.alert-danger');
    this._$tableSpinner = this.$('#table-spinner');
    this._$tableArea = this.$('#table-area');

    APP.vent.on('upload:questionError', this._displayErrors, this);
    APP.vent.on(
      'upload:layerQuestionAnswered',
      this._handleLayerQuestionAnswered,
      this,
    );
    APP.vent.on(
      'upload:addressOrLatLngQuestionAnswered',
      this._handleAddressOrLatLngQuestionAnswered,
      this,
    );
    APP.vent.on(
      'upload:hasSeparateColumnHeaders',
      this._handleSeparateColumnHeadersAnswered,
      this,
    );
  },

  show: function () {
    // Start with a fresh model everytime this show function is called
    this.model = new UploadTable();
    this.model.set({ bucket: this._bucket });
    this._prepView();
    this.$el.show();
  },

  hide: function () {
    this.$el.hide();
  },

  /**
   * Assemble the table that will be displayed to the user of the first few
   * rows of the data they uploaded.
   */
  createTable: function (response) {
    // Often there is an empty column at the end of a row. If this is
    // present, remove it.
    var csvRows = response.firstRows.map(function (row) {
      if (row[row.length - 1] === '') {
        row.splice(row.length - 1, 1);
      }
      return row;
    });

    this.model.set({ key: response.key });

    this.model.storeColumnNames(csvRows[0]);

    this.model.numFeatures = response.numFeatures;

    // Calculate the number of columns in the longest row
    var maxColumns = 0;
    _.each(csvRows, function (row) {
      if (row.length > maxColumns) {
        maxColumns = row.length;
      }
    });

    // Construct a table for the first three rows
    var tableEntryCharacterLimit = 30,
      rows = [];
    _.each(csvRows, function (row) {
      var cols = [];
      _.each(row, function (dataPoint) {
        dataPoint = unescape(dataPoint);
        if (dataPoint.length > tableEntryCharacterLimit) {
          dataPoint = dataPoint.substr(0, tableEntryCharacterLimit) + '...';
        }
        cols.push(dataPoint);
      });

      // If this row is shorter than the longest row, add some
      // empty columns
      for (var i = cols.length; i < maxColumns; i++) {
        cols.push('');
      }

      rows.push(cols);
    });

    // Add the table to the modal and hide the spinner.
    var template = require('./templates/upload.pug');

    this._$tableArea.append(template({ rows: rows }));
    this._$tableSpinner.hide();
    this._$loader = this.$('.loader');
    this._$btnDone = this.$('#btn-done');
    questionsView.render();
    this.model.addToBreadcrumbs('layerName');
  },

  /**
   *  Prepare the view to be shown by clearing fields and resetting variables
   */
  _prepView: function () {
    this._$errorNode.hide();
    this._currentMsg = 0;
    this._$tableArea.html('');
    this.$('#layer-picker').val('');
    this._$tableSpinner.show();
  },

  /**
   * Executes what needs to be done when user clicks skip
   */
  _handleSkip: function () {
    var nextStep = this.model.updateBreadcrumbs();
    this._showNextQuestion(nextStep);
  },

  /**
   * Executes what needs to be done when user clicks back
   */
  _handleBack: function () {
    this._$errorNode.hide();
    this.model.popBreadcrumbs();
    this._showPreviousQuestion();
  },

  _handleLayerQuestionAnswered: function (layerId) {
    var layer = layerCollection.get(layerId);
    this.model.set({ layerId: layer.id, layerName: layer.get('name') });
    this.model.addToBreadcrumbs('filetype');
    this.$('.modal-footer').show();
    this.$('#btn-back').show();
    this._$errorNode.hide();
  },

  /**
   * Executes what needs to be done when user chooses file type
   *
   * Parameters:
   *     type: String
   *        If 'latlng' setup for already geocoded, otherwise setup for need
   *        to geocode.
   *
   * Return:
   *     None
   */
  _handleAddressOrLatLngQuestionAnswered: function (type) {
    if (type === 'latlng') {
      this._makeTableClickable();
      if (this.model.has('geocode')) {
        this.model.unset('geocode');
      }

      this.model.addToBreadcrumbs('Latitude');
    } else {
      if (this.model.numFeatures > 500) {
        this._displayErrors([
          'Only 500 addresses can be uploaded per file. This file contains ' +
            this.model.numFeatures +
            ' addresses.',
        ]);
        questionsView.hideAllQuestions();
      } else {
        this.model.set({ geocode: true });
      }
      this.model.addToBreadcrumbs('separateColumns');
    }
  },

  /**
   * Turn the column header into an input box.
   */
  _editColumnHeader: function (e) {
    var $target = $(e.target);
    var $input = $('<input>');
    var $div = $('<div>');

    // Keep track of index being edited
    this.model.set({ currentEditIndex: $target.index() });

    $input.css('margin', '12px');
    $input.css('width', $target.width() + 'px');
    $div.css('height', '50px');
    $div.css('width', $target.css('width'));
    $div.css('background-color', '#f9f9f9');
    $input.html($target.html());
    $input.addClass('edit-column');
    $div.append($input);
    $target.replaceWith($div);
    $input.focus();
  },

  /**
   * Turn the column header back into a standard column header and record the
   * new column name.
   */
  _doneEditingHeader: function (e) {
    var $target = $(e.target),
      $th = $('<th></th>'),
      _this = this,
      userInput = $target.val().trim();

    // Make sure the user isn't naming column the same thing as another column
    var $colHeaders = this.$('tr:first-child th'),
      errorFound = false;
    _.each($colHeaders, function (header) {
      var $header = $(header);

      if ($header.html() === userInput) {
        _this._displayErrors([
          'You cannot name this column ' +
            userInput +
            ' because another column already has the same name.',
        ]);
        errorFound = true;
      }
    });

    if (!errorFound) {
      this.model.updateAttribute(userInput);
      $th.html(userInput);
      $th.css('background-color', '#f9f9f9');
      $th.addClass('editable');
      $target.parent().replaceWith($th);
    }
  },

  /**
   * Display errors at the top of the modal
   *
   * Parameters:
   *     errors: Array
   *        An array where each element is an error message in string format
   *
   * Return:
   *     None
   */
  _displayErrors: function (errors) {
    var $errors = $('<ul>');

    _.each(errors, function (msg) {
      $errors.append('<li>' + msg + '</li>');
    });

    if ($errors.find('li').length) {
      this._$errorNode.html($errors.html()).show();
    }
  },

  _removeAllTableCellInlineStyles: function () {
    this.$('td').removeAttr('style');
  },

  /**
   * Highlight the hovered over column
   */
  _highlightColumn: function (e) {
    var column = $(e.target).index() + 1;

    this.$('td:nth-child(' + column + ')').css('background-color', '#00A6CE');
    this.$('th:nth-child(' + column + ')').css('background-color', '#00A6CE');
  },

  /**
   * Remove the highlight from the column that is no longer hovered over.
   */
  _unHighlightColumn: function (e) {
    var $element = $(e.target);
    var column = $element.index() + 1;

    // Maintain striping
    this.$('td:nth-child(' + column + ')').css('background-color', 'white');
    this.$('tr:nth-child(odd) td:nth-child(' + column + ')').css(
      'background-color',
      '#f9f9f9',
    );
    this.$('th:nth-child(' + column + ')').css('background-color', '#f9f9f9');
  },

  /**
   * Executes what needs to be done when user chooses if there are separate
   * column headers for address
   *
   * Parameters:
   *     isUnstructuredGeocode: boolean
   *        true if file has unstructured address, false otherwise
   *
   * Return:
   *     None
   */
  _handleSeparateColumnHeadersAnswered: function (isUnstructuredGeocode) {
    if (isUnstructuredGeocode) {
      this.model.set({ unstructuredGeoCode: isUnstructuredGeocode });
      this.model.addToBreadcrumbs('Address');
    } else {
      if (this.model.has('unstructuredGeoCode')) {
        this.model.unset('unstructuredGeoCode');
      }
      this.model.addToBreadcrumbs('Street Address');
      this.$('#btn-skip').show();
    }

    this._makeTableClickable();
  },

  /**
   * Make all the cells of the table clickable. This means when a cell is
   * hovered over, the entire column changes color. In addition, add
   * 'clickable' class to cells.
   */
  _makeTableClickable: function () {
    this._$tableArea
      .find('td, th')
      .removeClass('not-clickable')
      .addClass('clickable');
    this._registerHoverEvents();
  },

  /**
   * Make all clickable cells not clickable anymore.
   */
  _removeClickable: function () {
    this._$tableArea.find('.clickable').removeClass('clickable').off();
  },

  /**
   * Register events for when a clickable cell is hovered over.
   */
  _registerHoverEvents: function () {
    // Register events for when the user hovers over a column
    var _this = this;
    this.$('.clickable').hover(
      function (e) {
        _this._highlightColumn(e);
      },
      function (e) {
        _this._unHighlightColumn(e);
      },
    );
  },

  /**
   * Change all clickable table headers to editable. Make all other clickable
   * cells not clickable
   */
  _clickableToEditable: function () {
    // Make remaining "clickable" headers, editable
    this._$tableArea
      .find('th.clickable')
      .removeClass('clickable')
      .off()
      .addClass('editable');

    // remove clickable class from renaming clickable cells
    this._$tableArea
      .find('.clickable')
      .removeClass('clickable')
      .addClass('not-clickable')
      .off();
  },

  /**
   * Change all clickable and editable cells back to clickable.
   */
  _editableToClickable: function () {
    // Turn editable columns back to clickable
    this._$tableArea
      .find('.editable, .not-clickable')
      .addClass('clickable')
      .removeClass('editable');

    this._registerHoverEvents();
  },

  /**
   * A column was clicked, record the clicked column as the answer the current
   * question.
   *
   * Parameters:
   *    e: Event
   *
   * Return:
   *     None
   */
  _columnClicked: function (e) {
    // Get the index of the column that was clicked on
    var index = $(e.target).index() + 1,
      $columnNodes = this._getColumnNodes(index),
      originalColumnHeader = $columnNodes[0].innerHTML;

    this._replaceHeader($columnNodes);
    this._disableColumn(e, $columnNodes);

    var nextStep = this.model.updateBreadcrumbs(
      index - 1,
      originalColumnHeader,
    );
    this._showNextQuestion(nextStep);
  },

  /**
   * A column was clicked, record the clicked column as the answer the current
   * question.
   *
   * Parameters:
   *    index: number
   *      The index of the column
   *
   * Return:
   *     jQuery object
   *        Contains all the nodes in a column
   */
  _getColumnNodes: function (index) {
    return this.$('table').find(
      'td:nth-child(' + index + '), th:nth-child(' + index + ')',
    );
  },

  /**
   * Figures out what to do to show the previous question
   */
  _showPreviousQuestion: function () {
    if (this.model.isAtFirstStep()) {
      this.$('#btn-back').hide();
    } else if (this.model.isAtLastStep()) {
      this._$btnDone.hide();
      this._$errorNode.hide();
      this._editableToClickable();
      this._revertColumn(this.model.getCurrentColumnData());

      if (this.model.has('geocode')) {
        this.$('#btn-skip').show();
      }

      questionsView.showFeatureLabelQuestion();

      return;
    } else if (this.model.isAtColumnPickingStep()) {
      var columnData = this.model.getCurrentColumnData();

      if (columnData.index !== undefined) {
        this._revertColumn(columnData);
      }

      if (this.model.isAtPostalCodeStep()) {
        questionsView.showPostalCodeQuestion();
        return;
      } else if (this.model.isAtUnstructuredAddressStep()) {
        questionsView.showUnstructuredAddressQuestion();
        return;
      }
    } else if (this.model.isAtFileTypeStep()) {
      this._removeClickable();
      this._removeAllTableCellInlineStyles();
      questionsView.showFileTypeQuestion();
      return;
    } else if (this.model.isAtSeparateColumnsStep()) {
      this._removeClickable();
      this._removeAllTableCellInlineStyles();
      this.$('#btn-skip').hide();
      questionsView.showSeparateColumnsQuestion();
      return;
    }

    questionsView.showPreviousQuestion();
  },

  /**
   * Figures out how to show the next question
   *
   * Parameters:
   *    nextStep: string
   *      The next step according to breadcrumb keywords
   *
   * Return:
   *     None
   */
  _showNextQuestion: function (nextStep) {
    if (nextStep === 'end') {
      questionsView.showLastQuestion();
      this._clickableToEditable();
      this._$btnDone.show();
      this.$('#btn-skip').hide();
    } else if (nextStep === 'Feature Label') {
      questionsView.showFeatureLabelQuestion();
    } else {
      questionsView.showNextQuestion();
    }
  },

  /**
   * Revert column to it's original state
   *
   * Parameters:
   *    columnData: obj
   *      Contains the index as well as original column name
   *
   * Return:
   *     None
   */
  _revertColumn: function (columnData) {
    var $columnNodes = this._getColumnNodes(columnData.index + 1);
    $columnNodes.addClass('clickable');
    $columnNodes[0].innerHTML = columnData.originalColumnName;
    this._registerHoverEvents();
  },

  /**
   * Put column in a disabled state.
   *
   * Parameters:
   *    e: Event
   *    $columnNodes: jQuery obj
   *      Contains all the nodes in the column
   *
   * Return:
   *     None
   */
  _disableColumn: function (e, $columnNodes) {
    $columnNodes.removeClass('clickable').off();
    this._unHighlightColumn(e);
  },

  /**
   * Replace duplicate header names
   *
   * Parameters:
   *    $columnHeaderNode: jQuery obj
   *      Contains the header node to check against
   *
   * Return:
   *     None
   */
  _replaceHeader: function ($columnNodes) {
    var $columnHeaderNode = $($columnNodes[0]);

    $columnHeaderNode.text(this.model.getCurrentStep());
    this._replaceDuplicateHeaders($columnHeaderNode);
  },

  /**
   * Replace duplicate header names
   *
   * Parameters:
   *    $columnHeaderNode: jQuery obj
   *      Contains the header node to check against
   *
   * Return:
   *     None
   */
  _replaceDuplicateHeaders: function ($columnHeaderNode) {
    var columnHeader = $columnHeaderNode.text(),
      $otherColumnHeaders = $columnHeaderNode.siblings(),
      count = 0;

    _.each($otherColumnHeaders, function (header) {
      var $header = $(header);

      if ($header.text().toLowerCase() === columnHeader.toLowerCase()) {
        if (count) {
          $header.text(columnHeader + ' Copy ' + count);
        } else {
          $header.text(columnHeader + ' Copy');
        }

        count++;
      }
    });
  },

  /**
   * Validate the users answers to questions about the format of the data they
   * uploaded.
   *
   * Parameters:
   *    None
   *
   * Return:
   *    noErrors: boolean
   *        If there aren't any errors, return true. Otherwise return false.
   *
   */
  _validateData: function () {
    var repeatHeaders = this._getRepeatHeaders();
    var errors = [];

    if (repeatHeaders.length > 0) {
      var err = 'The following column names are repeated more than once: ';
      _.each(repeatHeaders, function (r) {
        err += r + ', ';
      });
      err = err.substr(0, err.length - 2);
      err += '. Repeated column names are not allowed.';
      errors.push(err);
    }

    if (errors.length) {
      this._displayErrors(errors);
      return false;
    }
    return true;
  },

  /**
   * Figures out how to show the next question
   *
   * Parameters:
   *    None
   *
   * Return:
   *     Array
   *        containing header names as strings
   */
  _getColumnHeaders: function () {
    return _.map(this.$('tr:first-child th'), function (header) {
      return $(header).html();
    });
  },

  /**
   * Figures out how to show the next question
   *
   * Return:
   *     Array
   *        containing duplicate header names as strings
   */
  _getRepeatHeaders: function () {
    var columnHeaders = this._getColumnHeaders();
    var repeats = [];

    columnHeaders.sort();

    for (var i = 0; i < columnHeaders.length - 1; i++) {
      if (columnHeaders[i + 1] === columnHeaders[i]) {
        repeats.push(columnHeaders[i]);
      }
    }

    return repeats;
  },

  /**
   * Finish uploading the new data by creating new features.
   */
  _finishUpload: function (e) {
    e.preventDefault();
    this._$btnDone.hide();
    this._$loader.show();

    if (!this._validateData()) {
      this._$btnDone.show();
      this._$loader.hide();
      return;
    }

    this._createFeatures();
  },

  _createFeatures: function () {
    $.post(
      APP.apiUrl + '/projects/' + APP.projectId + '/features/createCSVFeatures',
      this.model.toJSON(),
      displayWarningsAndErrors.bind(this),
    ).fail(createFeaturesFail.bind(this));

    function createFeaturesFail() {
      this._displayErrors([
        'There was an error on the server and your data could not be uploaded.',
      ]);
      this._$btnDone.show();
      this._$loader.hide();
    }

    function displayWarningsAndErrors(result) {
      var $errorMsg = $('<div>');

      this.$errorList = $('<ul></ul>');
      this.trigger('hideModal');

      if (result.unsuccessfulFeatures || _.keys(result.warnings).length > 0) {
        if (result.unsuccessfulFeatures) {
          addErrorsToList.call(this, result.errors);
        }

        if (result.successfulFeatures === 0) {
          displayNoFeaturesCreatedError.call(this);
        } else {
          if (result.unsuccessfulFeatures) {
            $errorMsg = addSomeFeaturesCreatedMsg(
              result.successfulFeatures,
              result.unsuccessfulFeatures,
            );
            $errorMsg.append(this.$errorList);
          }

          if (_.keys(result.warnings).length) {
            $errorMsg.append('<br/>');
            $errorMsg.append(createWarnings(result.warnings));
          }

          var options = {
            confirmClicked: updateDisplay.bind(this, result),
            error: $errorMsg.html(),
            userSpinner: false,
            confirmNotClicked: removeNewFeatures.bind(this, result.featureIds),
            confirmMsg: 'Would you like to continue, or cancel your upload?',
          };
          confirmView.showModal(options);
        }
      } else {
        updateDisplay.call(this, result);
      }
    }

    function updateDisplay(result) {
      if (result.featureIds.length > 100) {
        bigSpinner.show();
      }

      var p1 = mapCollection.fetchPromise();
      var p2 = featureCollection.fetchPromise({ silent: true });

      BluebirdPromise.join(
        p1,
        p2,
        function () {
          return projectMapsService
            .getSelectedMap()
            .fetchDisplaySettings()
            .then(toggleLayerOnIfNecessary.bind(this))
            .then(bigSpinner.hide);
        }.bind(this),
      );
    }

    function toggleLayerOnIfNecessary() {
      var selectedMap = projectMapsService.getSelectedMap();
      var layerId = this.model.get('layerId');
      var layer = layerCollection.get(layerId);

      // Toggle existing layer on if it's currently off
      if (!selectedMap.isLayerOn(layer.id)) {
        selectedMap.toggleLayerOn(layer);
      }
    }

    function addErrorsToList(errors) {
      // Order the errors sequentially
      var errorKeys = _.sortBy(_.keys(errors), function (num) {
        return parseInt(num);
      });
      _.each(
        errorKeys,
        function (key) {
          this.$errorList.append(
            '<li> Error for row ' + key + ': ' + escape(errors[key]) + '</li>',
          );
        },
        this,
      );
    }

    function displayNoFeaturesCreatedError() {
      var $errorMsg = $(
        "<div><h4>We're sorry, but we could not upload any of the points you supplied. More details: </h4></div>",
      );
      $errorMsg.append(this.$errorList);
      errorView.showModal({ text: $errorMsg.html() });
    }

    function addSomeFeaturesCreatedMsg(
      successfulFeatures,
      unsuccessfulFeatures,
    ) {
      return $(
        '<div><h5>We successfully uploaded <strong>' +
          successfulFeatures +
          '</strong> of the points you ' +
          'supplied, but were unfortunately unable to upload <strong>' +
          unsuccessfulFeatures +
          '</strong> of them. ' +
          'More details: </h5></div>',
      );
    }

    function createWarnings(warnings) {
      var $warningList = $('<ul></ul>');

      // Order the warnings sequentially
      var warningKeys = _.sortBy(_.keys(warnings), function (num) {
        return parseInt(num);
      });
      _.each(warningKeys, function (key) {
        $warningList.append(
          '<li>Warning for row ' + key + ': ' + escape(warnings[key]) + '</li>',
        );
      });

      var $warningMsg = $(
        '<div><h5>Although the following features were ' +
          'successfully created, you should be aware of the following ' +
          'warnings ( ' +
          warningKeys.length +
          ' warnings total): </h5><div>',
      );
      $warningMsg.append($warningList);
      return $warningMsg;
    }

    // This should be called to roll back any features that were added.
    function removeNewFeatures(featureIds) {
      var apiCaller = require('../apiCaller');

      // Cannot send too many ids at once (get a 413 error),
      // so break ids into chunks
      var chunks = _.groupBy(featureIds, (id, index) =>
        Math.floor(index / 100),
      );
      _.each(chunks, apiCaller.deleteFeatures.bind(apiCaller, APP.projectId));
    }
  },
});
module.exports = TableView;
