/* eslint-disable @typescript-eslint/no-var-requires */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-import-module-exports
const BluebirdPromise = require('bluebird');
const currentUser = require('../../../../../currentUser');
const currentUserArbiter = require('../../../../../permissions/currentUserArbiter');
const ProjectUserCollection = require('./userList/ProjectUserCollection');
const ProjectUserCollectionView = require('./userList/ProjectUserCollectionView');
const InviteUsersFormLayoutView = require('./inviteUsersForm/InviteUsersFormLayoutView');

const UsersLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/users.pug'),

  className: 'dashboard-section',

  regions: {
    inviteUsersForm: '#dashboard-invite-users-form-container',
    userList: '#dashboard-user-list',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
  },

  onShow() {
    this.$('[data-toggle=tooltip]').tooltip();

    const projectUserCollection = new ProjectUserCollection([], {
      projectId: this.projectModel.id,
      isFederalProject: this.projectModel.get('is_federal'),
    });

    const projectUserCollectionFetchPromise =
      projectUserCollection.fetchPromise();
    const currentUserHasProjectUserUpdatePermissionPromise =
      currentUserArbiter.hasProjectPermission(
        this.projectModel.id,
        'PROJECT_USER_UPDATE',
      );

    return BluebirdPromise.join(
      currentUserHasProjectUserUpdatePermissionPromise,
      projectUserCollectionFetchPromise,
      (hasUserUpdatePermission) => {
        const projectUserCollectionView = new ProjectUserCollectionView({
          collection: projectUserCollection,
        });

        currentUser.set('hasUserUpdatePermission', hasUserUpdatePermission);
        this.userList.show(projectUserCollectionView);

        if (hasUserUpdatePermission) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line no-underscore-dangle
          this._renderInviteUsersForm(projectUserCollection);
        }
      },
    );
  },

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-underscore-dangle
  _renderInviteUsersForm(projectUserCollection) {
    const inviteUsersFormLayoutView = new InviteUsersFormLayoutView({
      projectUserCollection,
      projectModel: this.projectModel,
    });

    this.inviteUsersForm.show(inviteUsersFormLayoutView);
    this.listenTo(inviteUsersFormLayoutView, 'sendInvite:success', () => {
      projectUserCollection.fetch();
    });
  },
});

module.exports = UsersLayoutView;
