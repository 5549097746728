/* eslint-disable */
// Services
var APP = require('../config');
var S3Upload = require('../fileUpload/S3Upload');

// Views
var UploadTableView = require('./tableView');
var UploadProgressView = require('../fileUpload/progressView');

var UploadFileView = Backbone.View.extend({
  template: require('./templates/uploadData.pug'),

  events: {
    'click form#upload-file-form .modal-footer input.btn': '_handleUploadFile',
  },

  render: function () {
    this._initEl();
    this._cacheElements();
    this._updateFileUploadButton();
    this._initDatumPopover();
    this._initUploadTableView();
    this._addProgressBar();

    this.$el.modal('show');

    return this;
  },

  show: function () {
    this.render();
  },

  hideModal: function () {
    this.$el.modal('hide');
  },

  _initEl: function () {
    // Limit on number of lines allowed in a csv file
    this._lineLimit = 10000;
    this._bucket = APP.csvUploadsBucketName;

    this.setElement(this.template());

    this.$el.on('hidden.bs.modal', this.remove.bind(this));
  },

  _addProgressBar: function () {
    this._progressBar = new UploadProgressView();
    this.$('.modal-footer').prepend(this._progressBar.render().el);
  },

  /**
   * Select items from the modal so that they don't have to be looked up more
   * than once.
   */
  _cacheElements: function () {
    this._$errorNode = this.$el.find('.alert-danger');
    this._$fileName = this.$('#file-name');
    this._$file = this.$(':file');
    this._$uploadFileDiv = this.$('#upload-file-div');
    this._$uploadDataWrapper = this.$('#upload-data-wrapper');
    this._$submitButton = this.$(
      'form#upload-file-form .modal-footer input.btn',
    );
  },

  /**
   * Replace the standard file upload button with a nicer looking bootstrap button.
   */
  _updateFileUploadButton: function () {
    // Change the style of the choose file button
    var wrapper = $('<div/>').css({ height: 0, width: 0, overflow: 'hidden' });
    var fileInput = this._$file.wrap(wrapper);

    var _this = this;
    fileInput.change(function () {
      var path = $(this).val().split('\\');
      _this._$fileName.text(path[path.length - 1]);
    });

    // First make sure this is the only click event registered.
    this._$uploadDataWrapper
      .click(function (e) {
        e.preventDefault();
        fileInput.click();
      })
      .show();
  },

  /**
   * Initialize the popover that explains what a datum is.
   */
  _initDatumPopover: function () {
    this.$('#datum-info').popover({
      container: '#upload-data-modal',
      html: true,
      content:
        'The earth is shaped approximately like an ' +
        'ellipsoid (a flattened shere). The datum describes how this ' +
        'ellipsoid was approximated. Longitude and latitude are always ' +
        'associated with a datum. If you find that your data is off by a ' +
        'few hundered yards, your data might not be in the WGS84 datum. ' +
        '<br> <br>  WGS84 is one of the more popular datums. Also ' +
        'popular is NAD83 which is very similar to WGS84. ' +
        '<br> <br> Wish we supported other datums? Let us ' +
        'know. It is on our road map, but we prioritize based on feedback ' +
        'from users.',
    });
  },

  /**
   * Initialize the table view which will be used to display the first three
   * rows in the table and ask questions about the data
   */
  _initUploadTableView: function () {
    this.$el.on(
      'shown.bs.modal',
      function () {
        this._uploadTableView = new UploadTableView({ bucket: this._bucket });
        this.listenTo(this._uploadTableView, 'hideModal', this.hideModal);
      }.bind(this),
    );
  },

  _displayErrors: function ($errors) {
    this._$errorNode.html($errors.html()).show();
    this._$uploadFileDiv.show();
    this._uploadTableView.hide();
    this._$submitButton.prop('disabled', false);
  },

  _handleUploadFile: function (e) {
    if (!this._validateFile(e)) {
      return;
    }

    this._$submitButton.prop('disabled', true);

    return this._uploadFile()
      .then(this._sampleFile.bind(this))
      .then(this._checkNumFeatures.bind(this))
      .then(this._showTable.bind(this))
      .catch(this._uploadError.bind(this));
  },

  _validateFile: function (e) {
    var fileName = this._$file.val() || '';
    var fileNameEmpty = fileName === '';
    var fileEndsInCsv = /\.csv$/i.test(fileName);
    var $errors = $('<p>');

    if (fileNameEmpty || !fileEndsInCsv) {
      e.preventDefault();
      var errorMessage = fileNameEmpty
        ? 'You must select a file to upload.'
        : 'You can only upload csv files at this time.';

      $errors.html(errorMessage);
      this._displayErrors($errors);
      return false;
    }

    return true;
  },

  _uploadFile: function () {
    var mapId = this.model.get('selected_map');
    var s3Upload = new S3Upload({
      fileSelector: this.$('[type=file]')[0],
      S3PutSignedUrl: APP.apiUrl + '/buckets/' + this._bucket + '/signedUrl',
      projectId: this.model.id,
      mapId: mapId,
      fileFormat: 'csv',
      onProgress: function (percent) {
        this._progressBar.updateProgress(percent);
      }.bind(this),
    });

    return s3Upload.uploadFiles.call(s3Upload);
  },

  _sampleFile: function (key) {
    key = _.isArray(key) ? key[0] : key;

    return new Promise(
      function (resolve, reject) {
        $.ajax({
          data: {
            bucket: this._bucket,
            key: key,
          },
          type: 'PUT',
          url: APP.parseGeoFileUrl + '/sampleCSVAndRemoveWhitespace',
          xhrFields: { withCredentials: true },
        })
          .done(function (response) {
            var options = _.extend(response, { key: key });
            resolve(options);
          })
          .fail(reject);
      }.bind(this),
    );
  },

  _checkNumFeatures: function (options) {
    var numFeatures = options.numFeatures;
    var errorMsg;

    if (numFeatures > parseInt(this._lineLimit)) {
      errorMsg =
        'Mapistry does not support files with more than ' +
        this._lineLimit +
        ' lines.';
    } else if (!numFeatures) {
      errorMsg = 'Oops! You are trying to upload an empty file.';
    }

    if (errorMsg) {
      return Promise.reject(errorMsg);
    }

    return Promise.resolve(options);
  },

  /**
   * Check if there was an error or if the number of rows in the file exceeds
   * the number of allowable rows. Otherwise, update the modal to start asking
   * questions about the file's format.
   */
  _showTable: function (options) {
    this._$uploadFileDiv.hide();
    this._uploadTableView.show();
    this._uploadTableView.createTable(options);
  },

  _uploadError: function (error) {
    APP.log('DEBUG: error', error);
    var $errors = $('<p>');
    var errorText =
      'There was an error on the server, your file could not be uploaded. Please contact support: help@mapistry.com';

    if (error.status === 422) {
      errorText = error.responseText;
    } else if (typeof error === 'string') {
      errorText = error;
    }

    $errors.html(errorText);
    this._displayErrors($errors);
  },
});

module.exports = UploadFileView;
