import { Api } from '@monorepo/shared/apiClient';
import { Button } from '@monorepo/shared/components';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import { useOrganization } from '@monorepo/shared/hooks/useOrganization';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import { FormErrors } from '../elements';
import { OrganizationSearchBar } from './OrganizationSearchBar';

export interface BulkUploadForOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onError: (errors: Api.MultiErrorResponseData | null) => void;
  onSuccess: () => void;
  organizationId: string;
}

interface BulkUploadForOrganizationPageProps {
  header: React.ReactNode;
  ModalComponent: (props: BulkUploadForOrganizationModalProps) => JSX.Element;
}

export function BulkUploadForOrganizationPage(
  props: BulkUploadForOrganizationPageProps,
) {
  const { header, ModalComponent } = props;
  const [isOpen, open, close] = useOpenClose();
  const [saveErrors, setSaveErrors] =
    useState<Api.MultiErrorResponseData | null>();

  const [organizationId, setOrganizationId] = useState<string>();
  const [orgValidationError, setOrgValidationError] = useState<string | null>();
  const handleSearch = useCallback(
    (searchId: string) => {
      setOrgValidationError(null);
      setSaveErrors(null);

      const trimmedSearchId = searchId.trim();
      if (uuidValidate(trimmedSearchId) && uuidVersion(trimmedSearchId) === 4) {
        setOrganizationId(trimmedSearchId);
      } else {
        setOrgValidationError('Invalid organization ID.');
      }
    },
    [setOrganizationId, setSaveErrors],
  );

  const {
    organization,
    error: fetchOrgError,
    isLoading: isOrganizationLoading,
  } = useOrganization({
    organizationId,
  });

  return (
    <div className="bulk-upload-for-org">
      {header}

      <OrganizationSearchBar
        isLoading={isOrganizationLoading}
        onSearch={handleSearch}
      />

      {organization && (
        <>
          <h2>{organization.name}</h2>
          <div className="bulk-upload-for-org__org-details">
            Created at {format(new Date(organization.createdAt), 'yyyy-MM-dd')}
          </div>
          <Button onClick={open}>Upload</Button>

          <ModalComponent
            isOpen={isOpen}
            onClose={close}
            onError={setSaveErrors}
            onSuccess={() => setSaveErrors(null)}
            organizationId={organization.id}
          />
        </>
      )}

      <div className="bulk-upload-for-org__error">
        {!!orgValidationError && <FormErrors errors={[orgValidationError]} />}
        {!!fetchOrgError && !orgValidationError && !saveErrors && (
          <FormErrors
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={[fetchOrgError.data]}
            label="Could not find an organization."
          />
        )}
        {!!saveErrors && (
          <FormErrors errors={saveErrors.errors} label={saveErrors.message} />
        )}
      </div>
    </div>
  );
}
