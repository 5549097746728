var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (instantaneousLimitDisplayText, isCaliforniaIndustrial, isRequired, limitLabelText, parameterName, shouldShowInstantaneousBenchmark, warning) {
      pug_html = pug_html + "\u003Cdiv class=\"panel-heading flex align-items-center justify-content-space-between\" style=\"height: 55px\"\u003E\u003Cdiv\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = parameterName) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
if (warning) {
pug_html = pug_html + "\u003Ca class=\"fa fa-exclamation-triangle warning-yellow\" data-toggle=\"popover\" style=\"padding-left: 5px\" title=\"Saltwater or freshwater?\"\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"display-none\" id=\"receiving-waters-warning-popover-content-wrapper\"\u003E\u003Cspan\u003EPlease tell us if you are discharging to salt water or fresh water. \u003Ca href='#' class='open-sector-data-modal'\u003EClick here to update.\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
if (isCaliforniaIndustrial) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' (' + limitLabelText + ' ' + instantaneousLimitDisplayText + ')') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (shouldShowInstantaneousBenchmark) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' (' + limitLabelText + ' ' + instantaneousLimitDisplayText + ')') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (!isRequired) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger btn-w-icon delete-sample-event-parameter\"\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"locations-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"storm-average-row-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"annual-average-row-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"benchmark-limit-row-container\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "instantaneousLimitDisplayText" in locals_for_with ?
        locals_for_with.instantaneousLimitDisplayText :
        typeof instantaneousLimitDisplayText !== 'undefined' ? instantaneousLimitDisplayText : undefined, "isCaliforniaIndustrial" in locals_for_with ?
        locals_for_with.isCaliforniaIndustrial :
        typeof isCaliforniaIndustrial !== 'undefined' ? isCaliforniaIndustrial : undefined, "isRequired" in locals_for_with ?
        locals_for_with.isRequired :
        typeof isRequired !== 'undefined' ? isRequired : undefined, "limitLabelText" in locals_for_with ?
        locals_for_with.limitLabelText :
        typeof limitLabelText !== 'undefined' ? limitLabelText : undefined, "parameterName" in locals_for_with ?
        locals_for_with.parameterName :
        typeof parameterName !== 'undefined' ? parameterName : undefined, "shouldShowInstantaneousBenchmark" in locals_for_with ?
        locals_for_with.shouldShowInstantaneousBenchmark :
        typeof shouldShowInstantaneousBenchmark !== 'undefined' ? shouldShowInstantaneousBenchmark : undefined, "warning" in locals_for_with ?
        locals_for_with.warning :
        typeof warning !== 'undefined' ? warning : undefined));
    ;;return pug_html;};
module.exports = template;