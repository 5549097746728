/* eslint-disable */
var newCustomIconView = require('./newCustomIconView').singleton();
var BaseShapeItemView = require('./BaseShapeItemView');

module.exports = BaseShapeItemView.extend({
  template: require('./templates/polyline.pug'),

  ui: {
    $selectpicker: 'select.decorator',
    $uploadCustomMarker: '#upload-custom-marker',
    $uploadCustomMarkerForm: 'form#upload-custom-marker-form',
    $colorContainer: '#color-container',
    $labelSettings: '.label-settings',
    $labelTextColorContainer: '#label-text-color-container',
    $labelTextSizeContainer: '#label-text-size-container',
    $labelBackgroundColorContainer: '#label-background-color-container',
    $labelShowChemicalAttributesContainer:
      '#label_show_chemical_attributes-container',
    $leaderlineWeightContainer: '#leaderline-weight-container',
    $leaderlineOpacityContainer: '#leaderline-opacity-container',
    $leaderlineColorContainer: '#leaderline-color-container',
    $leaderlineDisplayContainer: '#leaderline-display-container',

    $errorContainer: '.alert-danger',
    $faUpload: '.fa-upload',
    $faRefresh: '.fa-refresh',
  },

  behaviors: {
    DisplayMessages: {},
    SelectPicker: {},
  },

  templateHelpers() {
    let decorator = this.model.get('decorator');
    let isDecoratorSelected = function (d) {
      return d === decorator;
    };

    return _.extend(BaseShapeItemView.prototype.templateHelpers.call(this), {
      isDecoratorSelected,
    });
  },

  initialize() {
    this._customIconTemplate = require('./templates/customIconDecorator.pug');
    this.listenTo(newCustomIconView, 'validated', this._saveImage);
  },

  onShow() {
    this._appendCustomIcons();
    this.ui.$selectpicker
      .val(this.model.get('decorator'))
      .selectpicker('refresh');
    BaseShapeItemView.prototype.onShow.call(this);
  },

  _formatStyles(newStyles) {
    let formattedStyles = BaseShapeItemView.prototype._formatStyles(newStyles);
    formattedStyles.dash_array = this._convertToDashArray(
      newStyles.line_style,
      newStyles.weight,
    );
    formattedStyles.weight = newStyles.weight;
    formattedStyles.decorator = newStyles.decorator;
    formattedStyles.custom_icon = this.$('.decorator option:selected').hasClass(
      'custom-icon',
    );
    return formattedStyles;
  },
});
