module.exports = Backbone.Marionette.ItemView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/remoteDocuments.pug'),

  initialize(options = {}) {
    this.documentsLocation = options.documentsLocation;
    this.project = options.project;
  },

  templateHelpers() {
    return {
      projectName: this.project.name,
      locationUrl: this.documentsLocation.url,
    };
  },
});
