var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (availableSampleParameters, hasExistingParameters, modalTitle, parameterListPanelTitle, showOutfallsAndFrequency, showParameterStatus, showSamplingFrequencyPerOutfall) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal","fade",showOutfallsAndFrequency || showSamplingFrequencyPerOutfall ? 'wide-modal' : ''], [false,false,true]), false, true)) + "\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003E" + (pug.escape(null == (pug_interp = modalTitle) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel panel-default\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch5 class=\"panel-title\"\u003EAdd Parameters\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cdiv class=\"row padding-tb-20\"\u003E\u003Cdiv class=\"col-xs-7\"\u003E\u003Cform class=\"form-inline form-inline-with-margin-sm text-center\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cselect class=\"form-control add-parameter\" type=\"text\" name=\"slug\" style=\"width: 300px\"\u003E\u003Coption value=\"\" disabled selected\u003ESelect a parameter\u003C\u002Foption\u003E";
// iterate availableSampleParameters
;(function(){
  var $$obj = availableSampleParameters;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var parameter = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", parameter.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = parameter.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var parameter = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", parameter.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = parameter.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-5\"\u003E\u003Cbutton class=\"btn btn-success\" type=\"submit\"\u003EAdd a new parameter\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel panel-default\"\u003E\u003Cdiv class=\"panel-heading\"\u003E\u003Ch5 class=\"panel-title\"\u003E" + (pug.escape(null == (pug_interp = parameterListPanelTitle) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel-body\"\u003E";
if (hasExistingParameters) {
if (showOutfallsAndFrequency || showSamplingFrequencyPerOutfall) {
if (showParameterStatus) {
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Ch5\u003EParameter\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-2\"\u003E\u003Ch5\u003EStatus\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Ch5\u003ESamples Collected At\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Ch5\u003ESampling Frequency\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-4\"\u003E\u003Ch5\u003EParameter\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Ch5\u003ESamples Collected At\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Ch5\u003ESampling Frequency\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Ch5\u003EParameter\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E";
if (showParameterStatus) {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-6\"\u003E\u003Ch5\u003EStatus\u003C\u002Fh5\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
}
else {
pug_html = pug_html + "\u003Cp\u003EThere are no parameters associated with this site yet.\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cdiv id=\"parameter-collection-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success btn-done\" type=\"button\"\u003EDone\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "availableSampleParameters" in locals_for_with ?
        locals_for_with.availableSampleParameters :
        typeof availableSampleParameters !== 'undefined' ? availableSampleParameters : undefined, "hasExistingParameters" in locals_for_with ?
        locals_for_with.hasExistingParameters :
        typeof hasExistingParameters !== 'undefined' ? hasExistingParameters : undefined, "modalTitle" in locals_for_with ?
        locals_for_with.modalTitle :
        typeof modalTitle !== 'undefined' ? modalTitle : undefined, "parameterListPanelTitle" in locals_for_with ?
        locals_for_with.parameterListPanelTitle :
        typeof parameterListPanelTitle !== 'undefined' ? parameterListPanelTitle : undefined, "showOutfallsAndFrequency" in locals_for_with ?
        locals_for_with.showOutfallsAndFrequency :
        typeof showOutfallsAndFrequency !== 'undefined' ? showOutfallsAndFrequency : undefined, "showParameterStatus" in locals_for_with ?
        locals_for_with.showParameterStatus :
        typeof showParameterStatus !== 'undefined' ? showParameterStatus : undefined, "showSamplingFrequencyPerOutfall" in locals_for_with ?
        locals_for_with.showSamplingFrequencyPerOutfall :
        typeof showSamplingFrequencyPerOutfall !== 'undefined' ? showSamplingFrequencyPerOutfall : undefined));
    ;;return pug_html;};
module.exports = template;