import React, { useMemo } from 'react';
import cn from 'classnames';
import { OnboardingApi } from '../../apiClient';

interface OnboardingDocumentStatusDisplayProps {
  status: OnboardingApi.OnboardingDocumentStatus;
}

export function OnboardingDocumentStatusDisplay(
  props: OnboardingDocumentStatusDisplayProps,
) {
  const { status } = props;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  const statusMessage = useMemo(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (status) {
      case 'action_required':
        return 'Action Required';
      case 'uploaded':
        return 'Uploaded';
      case 'accepted':
        return 'Accepted';
    }
  }, [status]);

  return (
    <span
      className={cn('onboarding-document-status-display', {
        uploaded: status === OnboardingApi.OnboardingDocumentStatus.uploaded,
        'action-required':
          status === OnboardingApi.OnboardingDocumentStatus.actionRequired,
        accepted: status === OnboardingApi.OnboardingDocumentStatus.accepted,
      })}
    >
      {statusMessage}
    </span>
  );
}
