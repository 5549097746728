import React from 'react';
import startCase from 'lodash.startcase';
import { OnboardingSection } from '../../apiClient/types';
import { OnboardingDocumentsCountDisplay as DocumentsCountDisplay } from '../OnboardingDocumentsCountDisplay';

interface OnboardingDocumentsTableModuleRowProps {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionsCell?: React.ReactElement<any>;
  hideDocumentsCount?: boolean;
  section: OnboardingSection;
}

export function OnboardingDocumentsTableModuleRow(
  props: OnboardingDocumentsTableModuleRowProps,
) {
  const { section, hideDocumentsCount } = props;

  const { uploadedDocumentCount, acceptedDocumentCount, totalDocumentCount } =
    section.documentCounts;

  const actionsCell =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    props.actionsCell !== undefined ? props.actionsCell : <td />;

  return (
    <tr className="module-row">
      <td>{startCase(section.moduleName)}</td>
      {hideDocumentsCount ? (
        <td />
      ) : (
        <td>
          <DocumentsCountDisplay
            label="Uploaded"
            currentCount={uploadedDocumentCount + acceptedDocumentCount}
            totalCount={totalDocumentCount}
          />
        </td>
      )}
      {hideDocumentsCount ? (
        <td />
      ) : (
        <td>
          <DocumentsCountDisplay
            label="Accepted"
            currentCount={acceptedDocumentCount}
            totalCount={totalDocumentCount}
          />
        </td>
      )}
      <td />
      {actionsCell}
    </tr>
  );
}
