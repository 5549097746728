/* eslint-disable */
const ChemicalTextInputView = Backbone.Marionette.ItemView.extend({
  template: require('./ChemicalTextInput.pug'),

  initialize: function (options) {
    this.model.set('showLabel', options.showLabel);
  },
});

module.exports = ChemicalTextInputView;
