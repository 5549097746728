/* eslint-disable */
var BaseModalView = require('./BaseModalView');

var GenericModalView = BaseModalView.extend({
  childViews: [],

  events: {
    'hidden.bs.modal': '_remove',
    'shown.bs.modal': '_focusOnFirstInput',
  },

  _focusOnFirstInput() {
    let input = this.$('input').eq(0);
    if (input) {
      input.focus();
    }
  },

  _remove() {
    this.childViews.forEach(function (view) {
      view.remove();
    });
    this.remove();
  },

  template: require('./templates/genericModal.pug'),

  render(data) {
    this.setElement(this.template(data));
    this.$el.modal('show');
    return this;
  },

  appendView(view) {
    this.childViews.push(view);
    return this.$('.modal-content-placeholder').append(view.render().el);
  },

  close() {
    this.$el.modal('hide');
  },
});

module.exports = GenericModalView;
