/* eslint-disable */
const moment = require('moment');
const projectStateConfig = require('mapistry-project-state-config');

var dashboardConfig = {
  getCurrentStartDate: function (project) {
    return this.getCurrentStartDateAsMoment(project).format('YYYY-MM-DD');
  },

  getCurrentStartDateAsMoment: function (project) {
    return moment(projectStateConfig.getSwpppYearStartDate(project));
  },

  getCurrentEndDate: function (project) {
    return this.getCurrentEndDateAsMoment(project).format('YYYY-MM-DD');
  },

  getCurrentEndDateAsMoment: function (project) {
    return moment(projectStateConfig.getSwpppYearEndDate(project));
  },

  getCurrentSwpppYear: function (project) {
    return projectStateConfig.getSwpppStartYearForMoment(project, moment());
  },
};

module.exports = dashboardConfig;
