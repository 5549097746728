/* eslint-disable */
var _s = require('underscore.string');

var AverageRowView = require('./AverageRowView');

var QuarterlyAverageRowView = AverageRowView.extend({
  tagName: 'tr',

  className: function () {
    if (this.model.get('quarterly_average_satisfied') === false) {
      return 'alert-danger quarterly-average';
    }
    return 'quarterly-average';
  },

  template: require('./templates/quarterlyAverageRow.pug'),

  templateHelpers: function () {
    let parameterSlug = this.model.get('slug');
    let samplingUnits = this.sectorData.getSamplingUnits(parameterSlug);
    let sectorData = this.sectorData;
    let isWesternWA = this.model.get('is_western_wa');
    let isRcraFacility = this.model.get('is_rcra_facility');
    let siteDischargesIntoPugetSound = this.model.get(
      'site_discharges_into_puget_sound',
    );
    let samplingUnitsDisplayText = samplingUnits && samplingUnits.display_text;

    return {
      getQuarterlyAverageLabel: sectorData.getQuarterlyAverageLabel(),
      getAverageDisplayText: function (value) {
        if (!value) {
          return 'N/A';
        }
        const quarterlyAverageLimitOptions = {
          parameterSlug,
          isWesternWA,
          isRcraFacility,
          siteDischargesIntoPugetSound,
        };
        return `${this._formatLikeLimitValue(
          value,
          sectorData.getQuarterlyAverageLimit(quarterlyAverageLimitOptions),
        )} ${samplingUnitsDisplayText || 'N/A'}`;
      }.bind(this),
      getAverageLimitDisplayText: function (value) {
        if (!value) {
          return 'N/A';
        }
        return value === 'hardness_dependent'
          ? `${_s.humanize(value)}`
          : `${_s.humanize(value)} ${samplingUnitsDisplayText}`;
      },
    };
  },
});

module.exports = QuarterlyAverageRowView;
