import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { OnboardingDocumentsPage } from './pages/OnboardingDocumentsPage';
import { AdminOnboardingDocumentsPage } from './admin/pages/AdminOnboardingDocumentsPage';

export function OnboardingRoutes() {
  return (
    <Switch>
      <Route path="/onboarding" component={OnboardingDocumentsPage} />
    </Switch>
  );
}

export function OnboardingAdminRoutes() {
  return (
    <Switch>
      <Route
        path="/admin/onboarding/:organizationId"
        component={AdminOnboardingDocumentsPage}
      />
    </Switch>
  );
}
