import { format, getQuarter } from 'date-fns';
import {
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { localEquivalentOfUTC } from '../../../utils';
import { LoadingIndicator, Menu } from '../../elements';
import RecordPanelListItemExpandable from '../../views/projectDashboard/RecordPanelListItemExpandable';
import EmissionTrackingListEmptyStates from './EmissionTrackingListEmptyStates';
import LogTableCellFactory from './LogTableCellFactory';

class EmissionTrackingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedIntervals: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { airEmissionLogs } = this.props;

    if (prevProps.airEmissionLogs !== airEmissionLogs) {
      if (!airEmissionLogs.length) {
        this.setState({ fetchedIntervals: {} });
      } else {
        this.updateFetchedIntervals();
      }
    }
  }

  handleAccordionToggle(key, emissionLog) {
    const { fetchEmissionLogs } = this.props;
    const { fetchedIntervals } = this.state;
    if (!emissionLog.children) {
      this.setState({
        fetchedIntervals: { ...fetchedIntervals, [key]: 'isFetching' },
      });
      fetchEmissionLogs(emissionLog);
    }
  }

  getKey(log) {
    return `${log.start}|${log.end}`;
  }

  actions(log) {
    const { onEditRecord, onDeleteRecord } = this.props;
    return (
      <div className="emission-tracking-list__actions">
        <Menu
          options={[
            {
              label: 'Edit',
              iconIdentifier: 'edit',
              onClick: () => onEditRecord(log),
            },
            {
              label: 'Delete',
              iconIdentifier: 'trash',
              onClick: () => onDeleteRecord(log),
              danger: true,
            },
          ]}
        />
      </div>
    );
  }

  findLog(logs, keyToFind) {
    return logs.reduce((foundLog, log) => {
      if (foundLog) {
        return foundLog;
      }
      if (keyToFind === this.getKey(log)) {
        return log;
      }
      if (log.children) {
        return this.findLog(log.children, keyToFind);
      }
      return null;
    }, null);
  }

  updateFetchedIntervals() {
    const { airEmissionLogs } = this.props;
    const { fetchedIntervals } = this.state;
    const updatedFetched = {};
    Object.keys(fetchedIntervals).forEach((key) => {
      if (fetchedIntervals[key] === 'isFetching') {
        const log = this.findLog(airEmissionLogs, key);
        if (log.children) {
          updatedFetched[key] = 'fetched';
          return;
        }
      }
      updatedFetched[key] = fetchedIntervals[key];
    });
    this.setState({ fetchedIntervals: updatedFetched });
  }

  formatDate(log) {
    const startDate = localEquivalentOfUTC(log.start);
    const endDate = localEquivalentOfUTC(log.end);
    if (log.frequency === IntervalFrequencyEnum.YEAR) {
      return format(startDate, 'yyyy');
    }

    const intervalFrequency = IntervalFrequencyFactory.For(
      log.frequency,
      log.customFrequency,
    );

    if (intervalFrequency.isQuarterPermutation()) {
      const quarter = getQuarter(startDate);
      return (
        <span>
          <strong>{`Q${quarter}`}</strong>
          {` | ${format(startDate, 'MMM')} - ${format(endDate, 'MMM')}`}
        </span>
      );
    }
    if (intervalFrequency.isBiannualPermutation()) {
      return `${format(startDate, 'MMM')} - ${format(endDate, 'MMM')}`;
    }
    if (intervalFrequency.isMonthlyPermutation()) {
      return format(startDate, 'MMMM');
    }
    if (log.frequency === IntervalFrequencyEnum.WEEK) {
      return `${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd')}`;
    }
    if (log.frequency === IntervalFrequencyEnum.DAY) {
      return format(startDate, 'MMM dd');
    }
    return format(startDate, 'dd MMMM | HH:mm');
  }

  rowSummary(log, isLoading) {
    const { loggedItems } = this.props;
    return (
      <div className="emission-tracking-list__row">
        <div className="emission-tracking-list__item-wrapper">
          <div className="emission-tracking-list__item">
            <div className="flex items-center">
              <div>{this.formatDate(log)}</div>
              <div className="padding-left-10">
                {isLoading && <LoadingIndicator isAbsolute={false} size={12} />}
              </div>
            </div>
          </div>
          {log.statistics.map((stat) => (
            <LogTableCellFactory
              key={stat.resourceId}
              frequency={log.frequency}
              customFrequency={log.customFrequency}
              statistic={stat}
              loggedItems={loggedItems}
            />
          ))}
        </div>
        {!log.frequency ? (
          this.actions(log)
        ) : (
          <div className="emission-tracking-list__actions" />
        )}
      </div>
    );
  }

  rowDetails(log) {
    if (!log.frequency) {
      return null;
    }
    return log.children ? (
      this.rows(log.children)
    ) : (
      <RecordPanelListItemExpandable summary={<div>Loading...</div>} />
    );
  }

  rows(emissionLogs = []) {
    const { fetchedIntervals } = this.state;
    return emissionLogs.length ? (
      emissionLogs.map((log) => {
        const key = this.getKey(log);
        const isLoading = fetchedIntervals[key] === 'isFetching';
        return (
          <RecordPanelListItemExpandable
            initializeExpanded={!!log.children}
            key={key}
            onClick={() => this.handleAccordionToggle(key, log)}
            summary={this.rowSummary(log, isLoading)}
            details={this.rowDetails(log)}
          />
        );
      })
    ) : (
      <RecordPanelListItemExpandable summary={<div>No Logs</div>} />
    );
  }

  render() {
    const {
      airEmissionLogs,
      isLoading,
      hasSelectedColumns,
      navigateToSettings,
      projectHasLogs,
      showEditLogModal,
    } = this.props;

    if (!airEmissionLogs.length && isLoading) {
      return (
        <LoadingIndicator
          className="emission-tracking-list__loading"
          isAbsolute={false}
        />
      );
    }

    if (!hasSelectedColumns || !airEmissionLogs.length || !projectHasLogs) {
      return (
        <EmissionTrackingListEmptyStates
          hasLoggedItems={hasSelectedColumns}
          hasSelectedColumns={airEmissionLogs.length > 0}
          hasAnyLogs={projectHasLogs}
          navigateToSettings={navigateToSettings}
          showEditLogModal={showEditLogModal}
        />
      );
    }

    return this.rows(airEmissionLogs);
  }
}

const AirEmissionLogType = PropTypes.shape({
  items: PropTypes.arrayOf(PropTypes.string),
  statistics: PropTypes.arrayOf(PropTypes.shape({})),
});

EmissionTrackingList.propTypes = {
  fetchEmissionLogs: PropTypes.func.isRequired,
  hasSelectedColumns: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  navigateToSettings: PropTypes.func.isRequired,
  onDeleteRecord: PropTypes.func.isRequired,
  onEditRecord: PropTypes.func.isRequired,
  airEmissionLogs: PropTypes.arrayOf(AirEmissionLogType),
  projectHasLogs: PropTypes.bool,
  showEditLogModal: PropTypes.func.isRequired,
};

EmissionTrackingList.defaultProps = {
  airEmissionLogs: [],
  projectHasLogs: false,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EmissionTrackingList;
