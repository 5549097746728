/* eslint-disable */
let DischargeLocationItemView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/dischargeLocation.pug'),
  events: {
    'change .outfall-frequency': '_updateModel',
  },
  ui: {
    selectOutfallFrequency: 'select.outfall-frequency',
  },
  modelEvents: {
    'change:frequency': '_handleChangeOutfallFrequency',
  },

  _updateModel() {
    const newFrequency = this.ui.selectOutfallFrequency.val();
    this.model.set({ frequency: newFrequency });
  },

  _handleChangeOutfallFrequency() {
    this.trigger('change:dischargeLocationFrequency', {
      id: this.model.get('id'),
      frequency: this.model.get('frequency'),
    });
  },
});

module.exports = DischargeLocationItemView;
