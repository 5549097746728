import APP from '@monorepo/old-web/js/config';
import { useProjectUsers } from '@monorepo/shared/hooks/useProjectUsers';
import PropTypes from 'prop-types';
import React from 'react';
import { isNullOrUndefined } from '../../../utils';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const UserCell = (props) => {
  const { statistic } = props;
  const { users } = useProjectUsers({
    projectId: APP.projectId,
    includeArchived: true,
  });
  const matchingUser = users?.find(
    (user) => user.userId === statistic.totalLoggedValue,
  );
  return (
    <div className="emission-tracking-list__item">
      <span>
        {isNullOrUndefined(statistic.totalLoggedValue)
          ? '--'
          : matchingUser?.userName || 'User not found'}
      </span>
    </div>
  );
};

UserCell.propTypes = {
  statistic: PropTypes.shape({
    totalLoggedValue: PropTypes.number,
  }),
};

UserCell.defaultProps = {
  statistic: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UserCell;
