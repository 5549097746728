/* eslint-disable global-require */
module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/hmbpUpdateComposite.pug'),

  emptyView: require('./NoHmbpUpdatesView'),

  childViewContainer: 'ul',

  events: {
    'click .add-new-update': '_handleAddNewUpdate',
  },

  initialize(options = {}) {
    this.projectState = options.projectState;
    this.isDisabled = options.isDisabled;
    this.addNewUpdateModal = options.addNewUpdateModal;
    this.planName = options.planName;
  },

  templateHelpers() {
    return {
      planName: this.planName,
    };
  },

  childViewOptions() {
    return {
      projectState: this.projectState,
      isDisabled: this.isDisabled,
      planName: this.planName,
    };
  },

  getChildView() {
    return require('./HmbpUpdateRowView');
  },

  _handleAddNewUpdate() {
    if (!this.isDisabled()) {
      this.addNewUpdateModal.showModal();
    }
  },
});
