/* eslint-disable */
var BaseDisplaySettingCollection = require('./baseDisplaySettingCollection');
var FeatureDisplaySettingModel = require('./featureDisplaySettingModel');

var FeatureDisplaySettingCollection = BaseDisplaySettingCollection.extend({
  model: FeatureDisplaySettingModel,

  fetch: function (options) {
    return BaseDisplaySettingCollection.prototype.fetch.call(this, options);
  },

  onFeatureIds: function () {
    return this.where({ feature_on: true }).map(function (fds) {
      return fds.get('feature_id');
    });
  },

  offFeatureIds: function () {
    return this.where({ feature_on: false }).map(function (fds) {
      return fds.get('feature_id');
    });
  },
});

module.exports = FeatureDisplaySettingCollection;
