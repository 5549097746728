import React from 'react';
import PropTypes from 'prop-types';
import {
  GenericLogLimitType,
  GenericLogStatus,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';

import Units from '../../../utils/units';
import { capitalizeFirstLetter } from '../../../utils/shared';
import {
  delimitNumbers,
  fixedDecimalPlaces,
  isNullOrUndefined,
} from '../../../utils';
import { MapistryTooltip } from '../../elements';

const toolTipLabel = {
  [GenericLogLimitType.AVERAGE]: 'Average',
  [GenericLogLimitType.GEOMETRIC_MEAN]: 'Geometric Mean',
  [GenericLogLimitType.MAX]: 'Max',
  [GenericLogLimitType.MIN]: 'Min',
};

const StatisticsCell = (props) => {
  const { frequency, customFrequency, statistic } = props;

  const limitFrequency = () => {
    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    if (intervalFrequency.isBiannualPermutation()) {
      return '6 months';
    }
    return capitalizeFirstLetter(frequency);
  };

  const noLimitFrequency = () => {
    if (frequency === IntervalFrequencyEnum.YEAR) {
      return 'Annual';
    }
    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    if (intervalFrequency.isBiannualPermutation()) {
      return '6 month';
    }
    return capitalizeFirstLetter(frequency);
  };

  const statColor = () => {
    if (statistic.status === GenericLogStatus.LIMIT_EXCEEDED) {
      return 'color-red';
    }
    if (statistic.status === GenericLogStatus.LIMIT_WARNING) {
      return 'color-warning-yellow';
    }
    return 'color-default';
  };

  const tooltipText = () => {
    if (!frequency) {
      return '';
    }
    const limitText = statistic.limits.map((limit) => (
      <div key={limit.type}>
        <strong>{`${toolTipLabel[limit.type]} Limit: `}</strong>
        {`${limit.value} ${Units.formatUnitDisplayText(
          limit.units,
        )} / ${limitFrequency()}`}
      </div>
    ));
    return limitText.length
      ? limitText
      : `${noLimitFrequency()} limits are not defined.`;
  };

  return (
    <div className={`emission-tracking-list__item ${statColor()}`}>
      <MapistryTooltip placement="top" title={tooltipText()}>
        <span>
          {isNullOrUndefined(statistic.totalLoggedValue)
            ? '--'
            : delimitNumbers(fixedDecimalPlaces(statistic.totalLoggedValue, 3))}
        </span>
      </MapistryTooltip>
    </div>
  );
};

StatisticsCell.propTypes = {
  frequency: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  customFrequency: PropTypes.object,
  statistic: PropTypes.shape({
    limits: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        units: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    totalLoggedValue: PropTypes.number,
  }),
};

StatisticsCell.defaultProps = {
  frequency: null,
  customFrequency: null,
  statistic: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default StatisticsCell;
