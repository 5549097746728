/* eslint-disable */
const template = require('./templates/pollutantsOfConcernRegion.pug');

const PollutantsOfConcernView = Backbone.Marionette.ItemView.extend({
  template,

  ui: {
    $pollutantsOfConcern: 'input[name="pollutants_of_concern"]',
  },

  events: {
    'change @ui.$pollutantsOfConcern': '_handleChangePollutantsOfConcern',
  },

  initialize(options) {
    this.pollutantsOfConcernOptions = options.pollutantsOfConcernOptions;
  },

  templateHelpers() {
    const savedPollutantsOfConcern =
      this.model.get('pollutants_of_concern') || [];
    const isChecked = (slug) => savedPollutantsOfConcern.includes(slug);

    return {
      isChecked,
      pollutantsOfConcernOptions: this.pollutantsOfConcernOptions,
    };
  },

  _handleChangePollutantsOfConcern() {
    const APP = require('../../../../../../config');
    const checkedPollutants = this.$('input:checked');
    const pollutantsOfConcern = [];
    // eslint-disable-next-line func-names
    checkedPollutants.each(function () {
      pollutantsOfConcern.push($(this).val()); // eslint-disable-line no-undef
    });

    // wait for server to save so that when sampling parameters update,
    // field has been saved on server side
    this.model
      .save(
        {
          pollutants_of_concern: pollutantsOfConcern,
        },
        { wait: true },
      )
      .then(() => APP.vent.trigger('pollutantsOfConcern:change'));
  },
});
module.exports = PollutantsOfConcernView;
