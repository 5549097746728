import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import _get from 'lodash.get';
import { GenericLogLimitType } from 'mapistry-shared';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AddButton,
  HelpIcon,
  Select,
  TD,
  TR,
  TableAction,
  TextField,
} from '../../../elements';
import { SelectDischargeLocation } from '../SelectDischargeLocation';
import { SelectFrequency } from '../SelectFrequency';
import { SelectParameter } from '../SelectParameter';
import { SelectStatus } from './SelectStatus';
import {
  FormErrors,
  LoggedItemWithType,
  WaterLogLimitItemFormSubmission,
} from './types';

import { EquationFlyout, NotesPopover, TABLE_SETTINGS } from '../shared';

import { isNullOrUndefined } from '../../../../utils';
import Units from '../../../../utils/units';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.WATER_LOG_LIMIT_ITEMS;

const MAX_MIN_OPTIONS = [
  GenericLogLimitType.MAX,
  GenericLogLimitType.MIN,
  GenericLogLimitType.AVERAGE,
  GenericLogLimitType.GEOMETRIC_MEAN,
].map((opt) => ({
  label: opt.replace('_', ' '),
  value: opt,
}));

enum Fields {
  dischargeLocation = 'dischargeLocation',
  parameter = 'parameter',
  resource = 'resource',
  status = 'status',
}

interface WaterLogLimitRowProps {
  addLimitGroup: (groupId: string, limitItemIdx: number) => void;
  deleteLimitGroup: (
    limitItemIdx: number,
    limitIdx: number,
    groupId: string,
  ) => void;
  deleteLimitItem: (limitItemIdx: number, groupId: string) => void;
  formErrors: FormErrors;
  groupId: string;
  limitItem: WaterLogLimitItemFormSubmission;
  limitItemIdx: number;
  loggedItems: LoggedItemWithType[];
  logProjectId: string;
  measureRowHeight: () => void;
  selectMenuPortalRef: React.RefObject<HTMLDivElement>;
  stageEdit: (
    editsToMake: {
      editField: string;
      nextValue:
        | string
        | {
            resourceType?: string;
            resourceId?: string;
            resourceUnits?: string;
          };
    },
    editsLocation: { limitItemIdx: number; groupId: string; limitIdx?: number },
  ) => void;
}

export const WaterLogLimitRow = memo((props: WaterLogLimitRowProps) => {
  const [visibleFlyout, setVisibleFlyout] = useState<string | null>(null);

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const isFlyoutVisibleForPath = (groupId, resourceId) =>
    visibleFlyout === `${groupId}.${resourceId}`;

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const toggleFlyoutForRecord = (groupId, resourceId) => {
    setVisibleFlyout(`${groupId}.${resourceId}`);
  };

  const {
    addLimitGroup,
    deleteLimitGroup,
    deleteLimitItem,
    formErrors,
    groupId,
    limitItem: inboundLimitItem,
    limitItemIdx,
    loggedItems,
    logProjectId,
    measureRowHeight,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const prevLimitItem = usePrevious(inboundLimitItem);

  useEffect(() => {
    if (prevLimitItem?.limits.length !== inboundLimitItem.limits.length) {
      measureRowHeight(); // recalculate row height
    }
  }, [inboundLimitItem, prevLimitItem, measureRowHeight]);

  const limitItem = useMemo(() => {
    const item = { ...inboundLimitItem };
    const { dischargeLocation, parameter, resourceId } = inboundLimitItem;
    if (resourceId && !dischargeLocation && !parameter) {
      const loggedItem = loggedItems.find((l) => l.id === resourceId);
      item.dischargeLocation = loggedItem?.location;
      item.parameter = loggedItem?.parameter;
    }
    return item;
  }, [inboundLimitItem, loggedItems]);

  const allItemsToSelect = useMemo(
    () =>
      loggedItems.map((item) => ({
        label: item.parameter,
        value: item.id,
        resourceId: item.id,
        resourceUnits: item.units,
      })),
    [loggedItems],
  );

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const getRelatedUnitsByResourceId = (resourceId) => {
    if (isNullOrUndefined(resourceId)) {
      return [];
    }
    const matchingItem = allItemsToSelect.find(
      (item) => item.resourceId === resourceId,
    );
    if (!matchingItem) return [];
    return Units.getRelatedUnits(matchingItem.resourceUnits);
  };

  const stageChangedValue = useCallback(
    (editField: string, nextValue, limitIdx?: number) => {
      let resource: LoggedItemWithType | undefined;
      let updateResource = false;
      const { dischargeLocation, parameter } = limitItem;
      if (editField === Fields.dischargeLocation) {
        resource = loggedItems.find(
          (item) => item.location === nextValue && item.parameter === parameter,
        );
        updateResource = true;
      }
      if (editField === Fields.parameter) {
        resource = loggedItems.find(
          (item) =>
            item.location === dischargeLocation && item.parameter === nextValue,
        );
        updateResource = true;
      }
      stageEdit({ editField, nextValue }, { limitItemIdx, groupId, limitIdx });
      if (updateResource) {
        stageEdit(
          {
            editField: Fields.resource,
            nextValue: {
              resourceType: resource?.resourceType,
              resourceId: resource?.id,
              resourceUnits: resource?.units,
            },
          },
          { limitItemIdx, groupId, limitIdx },
        );
      }
    },
    [groupId, limitItem, limitItemIdx, loggedItems, stageEdit],
  );

  const deleteOnClick = useCallback(
    () => deleteLimitItem(limitItemIdx, groupId),
    [groupId, limitItemIdx, deleteLimitItem],
  );

  const sharedSelectProps = {
    menuPlacement: 'auto',
    menuPortalTarget: selectMenuPortalRef.current,
    isClearable: false,
  };

  const errorsForLimitItem = formErrors?.groups?.[groupId]?.[limitItemIdx];
  const errorsForLimit = errorsForLimitItem?.limits;

  return (
    <>
      {!isNullOrUndefined(limitItem.resourceId) && (
        <EquationFlyout
          logProjectId={logProjectId}
          onClose={() => setVisibleFlyout(null)}
          resourceId={limitItem.resourceId}
          resourceType={limitItem.resourceType}
          visible={isFlyoutVisibleForPath(groupId, limitItem.resourceId)}
        />
      )}
      <TR>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <SelectDischargeLocation
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLimitItem}
            item={limitItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue(Fields.dischargeLocation, opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <div className="flex items-center">
            <SelectParameter
              /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              errors={errorsForLimitItem}
              item={limitItem}
              menuRef={selectMenuPortalRef}
              onChange={(opt) => stageChangedValue(Fields.parameter, opt)}
            />
            {!isNullOrUndefined(limitItem.resourceId) && (
              <span
                className="pointer"
                onClick={() =>
                  toggleFlyoutForRecord(groupId, limitItem.resourceId)
                }
                onKeyPress={() =>
                  toggleFlyoutForRecord(groupId, limitItem.resourceId)
                }
                role="button"
                tabIndex={0}
              >
                <HelpIcon text="More info" />
              </span>
            )}
          </div>
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          <SelectStatus
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLimitItem}
            item={limitItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue(Fields.status, opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          {limitItem.limits.map((limit, limitIdx) => (
            <Fragment key={limit.renderKey}>
              <div className="limit-items__row-container">
                <div className="limit-items__row-content">
                  <Select
                    {...sharedSelectProps}
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.units`,
                      )
                    }
                    isDisabled={isNullOrUndefined(limitItem.resourceId)}
                    placeholder="Units"
                    options={getRelatedUnitsByResourceId(limitItem.resourceId)}
                    onChange={(opt) =>
                      stageChangedValue('units', opt.value, limitIdx)
                    }
                    value={getRelatedUnitsByResourceId(
                      limitItem.resourceId,
                    ).find((o) => o.value === limit.units)}
                  />
                  <TextField
                    controlled={false}
                    defaultValue={limit.limitValue || ''}
                    disabled={isNullOrUndefined(limitItem.resourceId)}
                    type="number"
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.limitValue`,
                      )
                    }
                    onBlur={(e) =>
                      stageChangedValue('limitValue', e.target.value, limitIdx)
                    }
                  />
                  <Select
                    {...sharedSelectProps}
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.limitType`,
                      )
                    }
                    isDisabled={isNullOrUndefined(limitItem.resourceId)}
                    placeholder="Limit type"
                    options={MAX_MIN_OPTIONS}
                    onChange={(opt) =>
                      stageChangedValue('limitType', opt.value, limitIdx)
                    }
                    value={MAX_MIN_OPTIONS.find(
                      (o) => o.value === limit.limitType,
                    )}
                  />
                  <span className="text__spacer">per</span>
                  <SelectFrequency
                    customFrequencyFieldName="limitCustomFrequency"
                    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
                    errors={errorsForLimit?.[limitIdx]}
                    frequencyFieldName="limitFrequency"
                    item={limitItem}
                    limit={limit}
                    menuRef={selectMenuPortalRef}
                    onChange={(frequency, customFrequency) => {
                      stageChangedValue('limitFrequency', frequency, limitIdx);
                      stageChangedValue(
                        'limitCustomFrequency',
                        customFrequency,
                        limitIdx,
                      );
                    }}
                  />
                </div>
                <div className="w-10">
                  {limitIdx > 0 && (
                    <TableAction
                      actionType="delete"
                      deleteTooltipText="Delete limit"
                      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
                      onClick={() =>
                        deleteLimitGroup(limitItemIdx, limitIdx, groupId)
                      }
                    />
                  )}
                </div>
              </div>
              {limitIdx === limitItem.limits.length - 1 && (
                <AddButton onClick={() => addLimitGroup(groupId, limitItemIdx)}>
                  Add a limit item
                </AddButton>
              )}
            </Fragment>
          ))}
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <NotesPopover
            notes={limitItem.notes}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClose={(nextNotes) => stageChangedValue('notes', nextNotes)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[5]} growth={COLUMN_GROWTH[5]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClick={deleteOnClick}
          />
        </TD>
      </TR>
    </>
  );
});
