/* eslint-disable */
import { KeyService } from '@monorepo/shared/KeyService';

const _s = require('underscore.string');

const APP = require('../../../../config');
const TagTypeCollectionView = require('./tagTypeCollectionView');
const TagTypeCollection = require('../../../../models_collections/tagTypeCollection');
const confirmView = require('../../../../modals/confirmView').singleton();

let ContentLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/tagsView.pug'),

  className: 'panel panel-default',

  regions: {
    tagTypeList: '#tagTypeList',
  },

  ui: {
    $tagTypeName: '#tag-type-name',
    $errorContainer: '.alert-danger',
    $editableTag: '.editable-tag-name',
  },

  events: {
    'click #create-tag': '_createTag',
    'keydown .editable-tag-name': '_handleTagKeypress',
    'blur .editable-tag-name': '_handleTagBlur',
    'click .delete-tag': '_confirmDeleteTag',
  },

  initialize(options = {}) {
    this.edit = true;
    this._getTags(options.organizationId);

    this.listenTo(APP.vent, 'userSettingsOrganization:change', (options) => {
      this._getTags(options.organizationId);
    });
  },

  _getTags(organizationId) {
    this.tagTypeCollection = new TagTypeCollection({ organizationId });
    return this.tagTypeCollection.fetch().then(() => {
      let tagsView = new TagTypeCollectionView({
        collection: this.tagTypeCollection,
      });
      this.tagTypeList.show(tagsView);
    });
  },

  _createTag(e) {
    e.preventDefault();
    let name = this.ui.$tagTypeName.val();
    if (this._isValid(name)) {
      this.tagTypeCollection.create(
        { name: name.trim() },
        {
          wait: true,
          success: this._clearInput.bind(this),
          error: this.triggerMethod.bind(
            this,
            'ShowError',
            'There was a problem creating the tag type. Please try again later.',
          ),
        },
      );
    }
  },

  _isValid(name) {
    if (_s.isBlank(name)) {
      this.triggerMethod('ShowError', 'The name cannot be blank.');
      return false;
    } else if (this.tagTypeCollection.where({ name }).length > 0) {
      this.triggerMethod('ShowError', 'That tag already exists.');
      return false;
    }
    return true;
  },

  _handleTagKeypress(e) {
    if (KeyService.isEnterKey(e.keyCode)) {
      e.currentTarget.blur();
      return false;
    } else if (KeyService.isEscapeKey(e.keyCode)) {
      this.edit = false;
      e.currentTarget.blur();
      this._resetTag();
      return false;
    }
  },

  _handleTagBlur(e) {
    if (this.edit) {
      let id = e.target.parentElement.dataset.id;
      let name = e.target.textContent;
      this._editTag(id, name, e.target);
    } else {
      this.edit = true;
    }
  },

  _editTag(id, name, target) {
    let changedTag = this.tagTypeCollection.find({ id });
    if (changedTag.get('name') === name) {
      return;
    } else if (this._isValid(name)) {
      changedTag.save(
        { name },
        {
          wait: true,
          error: () => {
            this.triggerMethod(
              'ShowError',
              'There was a problem updating the tag type. Please try again later.',
            );
            this._resetTag();
          },
        },
      );
    } else {
      $(target).text(changedTag.get('name'));
    }
  },

  _resetTag() {
    this.tagTypeList.currentView.render();
  },

  _confirmDeleteTag(e) {
    let name = e.currentTarget.dataset.name;
    let id = e.currentTarget.parentElement.dataset.id;
    let options = {
      text: 'the ' + name + ' tag?',
      confirmClicked: this._deleteTag.bind(this, id),
    };

    confirmView.showModal(options);
  },

  _deleteTag(id) {
    if (id) {
      this.tagTypeCollection.find({ id }).destroy({
        wait: true,
        error: this.triggerMethod.bind(
          this,
          'ShowError',
          'There was a problem deleting the tag type. Please try again later.',
        ),
      });
    } else {
      this.triggerMethod(
        'ShowError',
        'There was a problem deleting the tag type. Please try again later.',
      );
    }
  },

  _clearInput() {
    this.ui.$tagTypeName.val('');
  },
});

module.exports = ContentLayoutView;
