import React from 'react';
import PropTypes from 'prop-types';

import { Flyout } from '../../../../elements';

import EquationBlock from './EquationBlock';

const EquationFlyout = (props) => {
  const { resourceCollection, onClose, visible } = props;

  return (
    <Flyout
      alignment="right"
      resourceCollection={resourceCollection}
      onClose={onClose}
      title="Item Information"
      visible={visible}
    >
      {resourceCollection.map((resource) => (
        <EquationBlock
          name={resource.name}
          key={resource.label}
          label={resource.label}
          factors={resource.children}
        />
      ))}
    </Flyout>
  );
};

EquationFlyout.propTypes = {
  onClose: PropTypes.func.isRequired,
  resourceCollection: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visible: PropTypes.bool.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EquationFlyout;
