import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateEmissionItemGroupAction } from '../../../../../actions/air';
import ProjectContext from '../../../../../contexts/ProjectContext';
import withProvider from '../../../../withProvider';
import DroppableGroup from './DroppableGroup';

class DroppableGroupContainer extends Component {
  constructor(props) {
    super(props);
    this.handleEditGroupName = this.handleEditGroupName.bind(this);
  }

  handleEditGroupName(nextName, groupId) {
    const { groups, logProjectId, updateEmissionItemGroup } = this.props;
    const { projectId } = this.context;

    updateEmissionItemGroup(projectId, {
      ...groups[groupId],
      id: groupId,
      logProjectId,
      name: nextName,
    });
  }

  render() {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <DroppableGroup
        handleEditGroupName={this.handleEditGroupName}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateEmissionItemGroup: (projectId, itemGroup) =>
    dispatch(updateEmissionItemGroupAction(projectId, itemGroup)),
});

DroppableGroupContainer.propTypes = {
  groups: PropTypes.shape({}).isRequired,
  logProjectId: PropTypes.string.isRequired,
  updateEmissionItemGroup: PropTypes.func.isRequired,
};

DroppableGroupContainer.contextType = ProjectContext;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(null, mapDispatchToProps)(DroppableGroupContainer),
);
