var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isOregon) {
      pug_html = pug_html + "\u003Cdiv class=\"text-center\" style=\"margin: 200px 0\"\u003E\u003Ch1\u003ENo Data Available\u003Cbr\u003E";
if (isOregon) {
pug_html = pug_html + "\u003Csmall\u003EComplete a Routine Inspection form to get started\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Csmall\u003EComplete a Sampling Observation form to get started\u003C\u002Fsmall\u003E";
}
pug_html = pug_html + "\u003C\u002Fh1\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isOregon" in locals_for_with ?
        locals_for_with.isOregon :
        typeof isOregon !== 'undefined' ? isOregon : undefined));
    ;;return pug_html;};
module.exports = template;