import { GenericLogLoggedItemType } from 'mapistry-shared';
import React, { memo, useCallback } from 'react';

import { Select, TableAction, TD, TextField, TR } from '../../../elements';
import { SelectFrequency } from '../SelectFrequency';
import { NotesPopover, TABLE_SETTINGS } from '../shared';
import EditSelectOptionsModal from './EditSelectOptionsModal';
import { SelectUnit } from './SelectUnit';
import { LoggedItemRowProps } from './types';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.LOGGED_ITEMS;

const TYPE_OPTIONS = [
  { label: 'Boolean', value: GenericLogLoggedItemType.BOOLEAN },
  { label: 'Date', value: GenericLogLoggedItemType.DATE },
  { label: 'Number', value: GenericLogLoggedItemType.NUMBER },
  { label: 'Signature', value: GenericLogLoggedItemType.SIGNATURE },
  { label: 'Single Select', value: GenericLogLoggedItemType.SINGLE_SELECT },
  { label: 'Text', value: GenericLogLoggedItemType.TEXT },
  { label: 'Time', value: GenericLogLoggedItemType.TIME },
  { label: 'User', value: GenericLogLoggedItemType.USER },
];

const DISABLED_DELETE_TEXT =
  'This item cannot be deleted because data has already been logged or it is being used in other calculations';

export const LoggedItemsRow = memo(
  ({
    deleteLoggedItem,
    formErrors,
    groupId,
    loggedItem,
    loggedItemIdx,
    stageEdit,
    selectMenuPortalRef,
  }: LoggedItemRowProps) => {
    const stageChangedValue = useCallback(
      (editField, nextValue) => {
        stageEdit({ editField, nextValue }, { loggedItemIdx, groupId });
      },
      [groupId, loggedItemIdx, stageEdit],
    );

    const deleteOnClick = useCallback(
      () => deleteLoggedItem(loggedItemIdx, groupId),
      [groupId, loggedItemIdx, deleteLoggedItem],
    );

    const sharedSelectProps = {
      className: 'flex-grow-1',
      menuPlacement: 'auto',
      menuPortalTarget: selectMenuPortalRef.current,
      isClearable: false,
    };

    const errorsForLoggedItem = formErrors?.groups?.[groupId]?.[loggedItemIdx];

    return (
      <TR>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <TextField
            autoFocus={!loggedItem.name}
            controlled={false}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            defaultValue={loggedItem.userDefinedId || ''}
            onBlur={(e) => stageChangedValue('userDefinedId', e.target.value)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <TextField
            controlled={false}
            error={!!errorsForLoggedItem?.name}
            defaultValue={loggedItem.name || ''}
            onBlur={(e) => stageChangedValue('name', e.target.value)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          <div className="flex">
            <Select
              {...sharedSelectProps}
              error={!!errorsForLoggedItem?.itemType}
              isDisabled={loggedItem.isLocked}
              options={TYPE_OPTIONS}
              onChange={(opt) => {
                stageChangedValue('itemType', opt.value);
                if (opt.value !== GenericLogLoggedItemType.NUMBER) {
                  stageChangedValue('units', null);
                }
              }}
              value={TYPE_OPTIONS.find((o) => o.value === loggedItem.itemType)}
            />
            {loggedItem.itemType === GenericLogLoggedItemType.SINGLE_SELECT && (
              <EditSelectOptionsModal
                options={loggedItem.selectTypeOptions}
                onClose={(options) =>
                  stageChangedValue('selectTypeOptions', options)
                }
                title={loggedItem.name || 'Logged item'}
              />
            )}
          </div>
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          <SelectUnit
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLoggedItem}
            loggedItem={loggedItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue('units', opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <div className="logged-items__frequency-wrapper">
            <SelectFrequency
              customFrequencyFieldName="customFrequency"
              /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              errors={errorsForLoggedItem}
              frequencyFieldName="frequency"
              item={loggedItem}
              menuRef={selectMenuPortalRef}
              onChange={(frequency, customFrequency) => {
                stageChangedValue('frequency', frequency);
                stageChangedValue('customFrequency', customFrequency);
              }}
            />
          </div>
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[5]} growth={COLUMN_GROWTH[5]}>
          <NotesPopover
            notes={loggedItem.notes}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClose={(notes) => stageChangedValue('notes', notes)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[6]} growth={COLUMN_GROWTH[6]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            disabledTooltipText={
              loggedItem.isLocked ? DISABLED_DELETE_TEXT : null
            }
            disabled={loggedItem.isLocked}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClick={deleteOnClick}
          />
        </TD>
      </TR>
    );
  },
);
