/* eslint-disable */
const unescape = require('lodash.unescape');

var FeatureModel = Backbone.Model.extend({
  layer: function () {
    var layerCollection = require('./layerCollection').singleton();
    return layerCollection.get(this.get('layer_id'));
  },

  getType: function () {
    if (!this._type) {
      var layer = this.layer();
      this._type = layer ? layer.getType() : this.get('type');
    }

    return this._type;
  },

  getLabelOffsetCoordinates: function (zoomScale) {
    var offsetX = this.get('label_offset_x');
    var offsetY = this.get('label_offset_y');

    if (
      !zoomScale ||
      (offsetX === '0' && offsetY === '0') ||
      (!offsetX && !offsetY)
    ) {
      return;
    }

    if (zoomScale) {
      offsetX = offsetX / zoomScale;
      offsetY = offsetY / zoomScale;
    }

    return [offsetX, offsetY];
  },

  /**
   * Validations for attr names
   *
   * @param name string - The name of the attr
   * @return array - An array filled with errors
   */
  validateAttr: function (name) {
    var errors = [];

    if (name === '') {
      errors.push('Name is required.');
    }

    if (this.layer().isExistingAttrName(name)) {
      errors.push(
        'This attribute already exists. Please choose a unique name.',
      );
    }

    if (this.isReservedAttrName(name)) {
      errors.push(
        name + ' is a reserved attribute name. Please choose another name.',
      );
    }

    if (name.indexOf('.') !== -1) {
      errors.push('Periods (.) are not allowed in attribute names.');
    }

    if (name.indexOf('$') !== -1) {
      errors.push('Dollar signs ($) are not allowed in attribute names.');
    }

    return errors;
  },

  isCustomIcon: function () {
    if (!this.isMarker() && !this.isPolyline()) {
      return false;
    }

    return this.layer().style().get('custom_icon');
  },

  isMarker: function () {
    return this.getType() === 'marker';
  },

  isPolyline: function () {
    return this.getType() === 'polyline';
  },

  isPolygon: function () {
    return this.getType() === 'polygon';
  },

  calculateAreaInSquareMeters: function () {
    if (!this.isPolygon()) {
      return 0;
    }

    return L.GeometryUtil.geodesicArea(_.flatten(this.get('geography')));
  },

  isUnitsMetric: function () {
    var projectMapsService = require('../project/services/projectMapsService');
    var mapModel = projectMapsService.getSelectedMap();
    return mapModel.isMetric();
  },

  displayArea: function () {
    if (!this.isPolygon()) {
      return 0;
    }

    // In demo mode, the geomArea attr is not set when creating polygons,
    // so area is calculated with Leaflet.
    var area = this.get('geom_area') || this.calculateAreaInSquareMeters();

    return unescape(L.GeometryUtil.readableArea(area, this.isUnitsMetric()));
  },

  calcDistance: function () {
    if (!this.isPolyline()) {
      return 0;
    }

    var geography = _.flatten(this.get('geography'));

    // Convert plain JS objects with lat and lng attrs into Leaflet latLng objects
    var latLngs = geography.map(function (latLng) {
      return L.latLng(latLng.lat, latLng.lng);
    });

    var totalDistance = 0;
    var indexOfLastLatLng = latLngs.length - 1;
    var i;

    // Add up the distance between all points in polyline
    for (i = 0; i < indexOfLastLatLng; i++) {
      totalDistance += latLngs[i].distanceTo(latLngs[i + 1]);
    }

    return totalDistance;
  },

  displayDistance: function () {
    if (!this.isPolyline()) {
      return 0;
    }

    // In demo mode, the geomLength attr is not set when creating polylines,
    // so length is calculated with Leaflet.
    var length = this.get('geom_length') || this.calcDistance();

    return L.GeometryUtil.readableDistance(length, this.isUnitsMetric());
  },

  displayNameLabel: function () {
    return 'Feature Label';
  },

  displayBuildingNameLabel: function () {
    return 'Building Name';
  },

  getLatitude: function () {
    return this.get('geography').lat;
  },

  displayLatitude: function () {
    return this.getLatitude() + '°';
  },

  getLongitude: function () {
    return this.get('geography').lng;
  },

  displayLongitude: function () {
    return this.getLongitude() + '°';
  },

  attrs: function () {
    return this.layer().attrs()[this.id] || [];
  },

  attrIds: function () {
    var attrs = this.attrs();

    if (attrs) {
      return attrs.map(function (attr) {
        return attr.attrModel.id;
      });
    }
  },

  address: function () {
    return _.compact(
      this.geocodedAttrs().map(function (attr) {
        return attr.value;
      }),
    ).join(' ');
  },

  geocodedAttrs: function () {
    var attrs = this.attrs();

    attrs = attrs
      .filter(function (attr) {
        return attr.geocoded;
      })
      .concat(this.suggestedAttrs({ filter: 'geocoded' }));

    // Don't do anything else if no geocoded rows to display
    if (!attrs.length) {
      return attrs;
    }

    var sortedAttrs = new Array(4);

    // Sort if more than one structured address field
    // Street Address first, City second, State third, Postal Code last
    if (attrs.length > 1) {
      attrs.forEach(function (attr) {
        switch (attr.name) {
          case 'Street Address':
            sortedAttrs[0] = attr;
            break;
          case 'City':
            sortedAttrs[1] = attr;
            break;
          case 'State':
            sortedAttrs[2] = attr;
            break;
          case 'Postal Code':
            sortedAttrs[3] = attr;
            break;
        }
      });
    } else {
      sortedAttrs = attrs;
    }

    return _.compact(sortedAttrs);
  },

  nonGeocodedAttrs: function () {
    return this.attrs().filter(function (attr) {
      return !attr.geocoded;
    });
  },

  /**
   * This is used to get attrs defined on other features in the layer, but not on
   * this feature.
   *
   * Parameters:
   *     options: obj
   *        filter: string ('geocoded' or 'nonGeocoded')
   *
   * Return:
   *     None
   */
  suggestedAttrs: function (options = {}) {
    var attrsDefinedOnOtherFeatures = [];
    var uniqueAttrIds = [];
    var layerAttrs = _.flatten(_.values(this.layer().attrs()));
    var attrIds = this.attrIds();

    layerAttrs.forEach(function (attr) {
      var attrId = attr.attrModel.id;

      if (!_.contains(attrIds, attrId) && !_.contains(uniqueAttrIds, attrId)) {
        delete attr.valueModel;
        delete attr.value;
        attrsDefinedOnOtherFeatures.push(attr);
        uniqueAttrIds.push(attrId);
      }
    });

    if (options.filter === 'geocoded') {
      attrsDefinedOnOtherFeatures = attrsDefinedOnOtherFeatures.filter(
        function (attr) {
          return attr.geocoded;
        },
      );
    } else if (options.filter === 'nonGeocoded') {
      attrsDefinedOnOtherFeatures = attrsDefinedOnOtherFeatures.filter(
        function (attr) {
          return !attr.geocoded;
        },
      );
    }

    return attrsDefinedOnOtherFeatures;
  },

  addAttr: function (name, value, format, template_key) {
    this.layer().addAttr(this.id, name, value, format, template_key);
  },

  addAttrValue: function (value, attrId) {
    this.layer().addAttrValue(this.id, value, attrId);
  },

  /**
   * Check to see if name is a reserved keyword for features
   */
  isReservedAttrName: function (name) {
    name = name.toLowerCase();
    var reservedNames = [
      this.displayBuildingNameLabel().toLowerCase(),
      'feature label',
      'latitude',
      'longitude',
      'area',
      'length',
      'name',
      '_id',
      'id',
      'layerName',
      'leafletId',
      'userId',
    ];

    return _.contains(reservedNames, name);
  },

  /**
   * In Water Suite, a user can have several sampling locations for collecting
   * water for different purposes at the same coordinates.
   * In this case we are showing only normal location in the sidebar and on the map,
   * locations for different sampling purposes can be added or removed in their parent feature
   * edit modal.
   * @returns {boolean}
   */
  shouldShowInSidebar() {
    return !this.get('parent_id') && !this.get('sample_type');
  },

  isDischargeLocation() {
    return this.layer().isDischargeLocationLayer();
  },

  getDependantFeatures() {
    const featureId = this.get('id');
    const allLayerFeatures = this.layer().features();
    return allLayerFeatures.filter((f) => f.get('parent_id') === featureId);
  },
});

module.exports = FeatureModel;
