/* eslint-disable */
var APP = require('../config');

var TopographyOverlayButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/topographyOverlayButton.pug'),

  initialize() {
    this.listenTo(
      APP.vent,
      'deactive:topography-map:button',
      this._deactiveTopographyMapType.bind(this),
    );
  },

  templateHelpers: function () {
    return {
      isTopographyOn: this.model.isTopographyOn(),
    };
  },

  ui: {
    $buttonIcon: '#topography-overlay',
  },

  events: {
    click: '_toggleTopographyMapType',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _toggleTopographyMapType: function () {
    this.model
      .toggleTopographyMapType()
      .bind(this)
      .then(function () {
        APP.vent.trigger('deactive:street-map:button');
      })
      .then(function () {
        this.trigger('toggle');
        this.ui.$buttonIcon.toggleClass('active');
      });
  },

  _deactiveTopographyMapType: function () {
    this.ui.$buttonIcon.removeClass('active');
  },
});

module.exports = TopographyOverlayButtonView;
