/* eslint-disable */
module.exports = Backbone.Model.extend({
  isCustomIcon: function () {
    return this.get('custom_icon');
  },

  getIcon: function () {
    var iconCollection = require('../iconCollection').singleton();
    var name = this.get('shape') || this.get('decorator');
    var icon = iconCollection.findWhere({ name: name });
    return icon;
  },

  needToShowLabel: function () {
    return !this.has('label_style') || this.get('label_style') !== 'none';
  },

  /**
   * This is useful for passing options to Leaflet
   * @returns {object} Returns a shallow copy of the models attributes with the keys camelized
   */
  camelizeToJSON: function () {
    var _s = require('underscore.string');

    return _.reduce(
      this.toJSON(),
      function (memo, val, key) {
        memo[_s.camelize(key)] = val;
        return memo;
      },
      {},
    );
  },
});
