import React from 'react';
import MapistryTooltip from '../../../elements/MapistryTooltip';

function MarkerError() {
  return (
    <MapistryTooltip placement="top" title="This section has errors">
      <span className="section-marker section-error-marker">!</span>
    </MapistryTooltip>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MarkerError;
