/* eslint-disable */
let BaseController = require('../../BaseController');
let rootLayoutView = require('../../rootLayoutView');
let _ = require('underscore');

let SettingDashboardsController = BaseController.extend({
  goToDefaultRoute() {
    require('../../router').goToSettingDashboard('notifications', null, true);
  },

  showSettingDashboard(currentSettingSlug, userId) {
    if (_.isEmpty(userId)) {
      userId = null;
    }
    let NavbarView = require('../navbar/NavbarView');
    let SettingDashboardLayoutView = require('./SettingDashboardLayoutView');

    return this.enforceAuthentication()
      .then(() => {
        rootLayoutView.navbar.show(new NavbarView({ activeClass: 'settings' }));
        rootLayoutView.content.show(
          new SettingDashboardLayoutView({ currentSettingSlug, userId }),
        );
      })
      .catch(this.handleError.bind(this));
  },

  showSettingsDashboardForSpecificUser(userId, currentSettingSlug) {
    return this.showSettingDashboard(currentSettingSlug, userId);
  },

  goToDefaultRouteForUserNotLoggedIn(userId) {
    require('../../router').goToSettingDashboard('notifications', userId, true);
  },
});

module.exports = SettingDashboardsController;
