/* eslint-disable */
var APP = require('../config');
var TitlebarModel = require('./titlebarModel');

var TitlebarCollection = Backbone.Collection.extend({
  url: function () {
    return APP.apiUrl + '/projects/' + APP.projectId + '/titlebars';
  },

  model: TitlebarModel,

  initialize: function () {
    this.on('change:logo_image', this._updateAllModels);
  },

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  _updateAllModels: function (updatedModel) {
    if (!(updatedModel && updatedModel.changed)) {
      return;
    }

    this.each(function (model) {
      if (updatedModel.id === model.id) {
        return;
      }

      model.save(updatedModel.changed);
    });
  },
});

Backbone.addSingletonToCollection(TitlebarCollection);
module.exports = TitlebarCollection;
