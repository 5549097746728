import React from 'react';
import Select from '../../../elements/Select';
import FormField from './FormField';

class MultiLocationField extends FormField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);

    return (
      <Select
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        isMulti
        error={error}
      />
    );
  }

  formatOptions(templateField) {
    const value = this.parsedValue();
    const options = templateField.options.filter(
      (option) => !option.isArchived || option.id === value[0],
    );
    return options.map((option) => ({
      label: option.name,
      value: option.id,
    }));
  }

  parsedValue() {
    const value = this.getValue();
    if (value && value.length && value.indexOf('[') === -1) {
      // This is just a string. Somehow some values get into this state. Need to get to the root of this.
      return [value];
    }
    return value && value.length ? JSON.parse(value) : [];
  }

  formatValue(formattedOptions) {
    const parsedValue = this.parsedValue();
    return parsedValue.map((value) =>
      formattedOptions.find((option) => option.value === value),
    );
  }

  handleChange = (selection) => {
    const { templateField, onChange } = this.props;
    let newValue = '';
    if (selection && selection.length) {
      const selectedValues = selection.map((option) => option.value);
      newValue = JSON.stringify(selectedValues);
    }
    return onChange(templateField.slug, newValue);
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiLocationField;
