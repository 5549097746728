var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getAverageDisplayText, storm_average, title) {
      pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = 'Storm ' + title) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getAverageDisplayText(storm_average, true)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }.call(this, "getAverageDisplayText" in locals_for_with ?
        locals_for_with.getAverageDisplayText :
        typeof getAverageDisplayText !== 'undefined' ? getAverageDisplayText : undefined, "storm_average" in locals_for_with ?
        locals_for_with.storm_average :
        typeof storm_average !== 'undefined' ? storm_average : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;};
module.exports = template;