/* eslint-disable */
let dateService = require('../../../../../../../../services/dateService');
let updatesCompletedPopoverTemplate = require('./templates/updatesCompletedPopover.pug');
let EditModalView = require('../../../../shared/swpppUpdates/SwpppUpdateModalView');

let SwpppUpdateRowView = Backbone.Marionette.LayoutView.extend({
  tagName: 'li',

  className: 'flex justify-content-space-between',

  template: require('./templates/swpppUpdateRow.pug'),

  ui: {
    delete: '.delete-swppp-update',
    $updateCompletedCheckmark: '.update-is-complete-checkmark',
  },

  events: {
    'hidden.bs.modal': 'render',
    'click .show-swppp-update': '_handleShowSwpppUpdate',
  },

  regions: {
    modalContainer: '.modal-container',
  },

  behaviors: {
    Tooltip: {},
    WarnBeforeDelete: {},
  },

  initialize(options = {}) {
    this.projectState = options.projectState;
    this.isDisabled = options.isDisabled;
    this.planName = options.planName;
  },

  onRender() {
    this._enablePopovers();
  },

  templateHelpers: function () {
    let swpppUpdate = this.model;

    return {
      label: dateService.prettifyDateNotInUTCYet(
        swpppUpdate.get('dateUpdated') || swpppUpdate.get('createdAt'),
      ),
      planName: this.planName,
    };
  },

  getWarningBeforeDeleteText: function () {
    let createdAt = dateService.prettifyDate(this.model.get('createdAt'));
    return 'the ' + this.planName + ' update created on ' + createdAt + '?';
  },

  _enablePopovers() {
    let trigger = 'hover';
    let container = 'body';

    let errors = this.model.get('errors') || [];

    this.$('.warning-red').popover({
      content: errors[0] && errors[0].shortMessage,
      trigger: trigger,
      container: container,
    });

    let warnings = this.model.get('warnings') || [];
    this.$('.warning-yellow').popover({
      content: warnings[0] && warnings[0].shortMessage,
      trigger: trigger,
      container: container,
    });

    let dateUpdated = this.model.get('dateUpdated');
    let popupModel = this.model.toJSON();
    popupModel.dateUpdated =
      dateUpdated && dateService.prettifyDateNotInUTCYet(dateUpdated);

    let dateSubmittedToGovernment = this.model.get('dateSubmittedToGovernment');
    popupModel.dateSubmittedToGovernment =
      dateSubmittedToGovernment &&
      dateService.prettifyDate(dateSubmittedToGovernment);
    popupModel.projectState = this.projectState;

    this.$('.update-is-complete-checkmark').popover({
      html: true,
      content: updatesCompletedPopoverTemplate(popupModel),
      trigger: trigger,
      container: container,
    });
  },

  _handleShowSwpppUpdate() {
    if (!this.isDisabled()) {
      let editModalView = new EditModalView({
        model: this.model,
        projectState: this.projectState,
        title: this.planName,
      });

      this.modalContainer.show(editModalView);
      editModalView.showModal();
    }
  },
});

module.exports = SwpppUpdateRowView;
