/* eslint-disable */
const _ = require('underscore');

const getResultsForParameter = (samplingParameter, samplingResults) => {
  const resultHasParameter = (result, samplingParameter) =>
    result.parameters.some(
      (parameter) => parameter.slug === samplingParameter.get('parameter_slug'),
    );
  return samplingResults.filter((result) =>
    resultHasParameter(result, samplingParameter),
  );
};

const parameterHasResults = (samplingParameter, samplingResults) => {
  const applicableResults = getResultsForParameter(
    samplingParameter,
    samplingResults,
  );
  return applicableResults.length > 0;
};

const getLocationsForParameter = (samplingParameter, samplingResults) => {
  return samplingResults
    .map((result) => {
      const resultParameter = _.find(
        result.parameters,
        (parameter) =>
          parameter.slug === samplingParameter.get('parameter_slug'),
      );
      return resultParameter.locations;
    })
    .reduce((accum, locations) => accum.concat(locations), []);
};

const dischargeLocationTestedInResults = (
  dischargeLocation,
  resultsLocations,
) => {
  return resultsLocations.some(
    (resultLocation) =>
      resultLocation.location_id === dischargeLocation.id &&
      resultLocation.parameter_value !== null,
  );
};

const parameterTestedAtAllLocations = (samplingParameter, samplingResults) => {
  const applicableResults = getResultsForParameter(
    samplingParameter,
    samplingResults,
  );
  const allResultsLocations = getLocationsForParameter(
    samplingParameter,
    applicableResults,
  );
  return samplingParameter
    .get('discharge_locations')
    .every((dischargeLocation) => {
      return dischargeLocationTestedInResults(
        dischargeLocation,
        allResultsLocations,
      );
    });
};

const parameterTestedAnyLocation = (samplingParameter, samplingResults) => {
  const applicableResults = getResultsForParameter(
    samplingParameter,
    samplingResults,
  );
  const allResultsLocations = getLocationsForParameter(
    samplingParameter,
    applicableResults,
  );
  return allResultsLocations.some(
    (resultLocation) => resultLocation.parameter_value !== null,
  );
};

const meetsSamplingRequirements = (
  samplingParameter,
  samplingResults,
  requiresSamplesAtAllLocations,
) => {
  return (
    parameterHasResults(samplingParameter, samplingResults) &&
    ((requiresSamplesAtAllLocations &&
      parameterTestedAtAllLocations(samplingParameter, samplingResults)) ||
      (!requiresSamplesAtAllLocations &&
        parameterTestedAnyLocation(samplingParameter, samplingResults)))
  );
};

const allSamplesMeetRequirements = (
  samplingParameters,
  samplingResults,
  requiresSamplesAtAllLocations = true,
) => {
  return (
    samplingParameters.length > 0 &&
    samplingParameters.every((samplingParameter) =>
      meetsSamplingRequirements(
        samplingParameter,
        samplingResults,
        requiresSamplesAtAllLocations,
      ),
    )
  );
};

module.exports = {
  allSamplesMeetRequirements,
};
