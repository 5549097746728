import { SaveState } from '@monorepo/shared/types/SaveState';
import update from 'immutability-helper';
import _get from 'lodash.get';
import { GenericLogLoggedItemType, GenericLogType } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import {
  createEmissionRollingCalculationAction,
  deleteEmissionItemGroupAction,
  deleteEmissionRollingCalculationAction,
  fetchEmissionCalculatedValuesAction,
  fetchEmissionFactorsAction,
  fetchEmissionItemGroupsAction,
  fetchEmissionLoggedItemsAction,
  fetchEmissionRollingCalculationsAction,
  updateEmissionRollingCalculationAction,
} from '../../../../actions/air';
import ProjectContext from '../../../../contexts/ProjectContext';
import {
  getCalculatedValues,
  getIsFetching,
  getLoggedItems,
  getRollingCalculationGroups,
  getRollingCalculations,
} from '../../../../selectors/genericLogs';
import { isNullOrUndefined } from '../../../../utils';
import withProvider from '../../../withProvider';
import RollingCalculationsTable from './RollingCalculationsTable';

class RollingCalculationsContainer extends Component {
  constructor(props) {
    super(props);
    this.formSubmissionDraftObject = {
      groupId: null,
      calculationType: null,
      calculationDuration: null,
      calculationFrequency: null,
      name: null,
      notes: null,
      resourceType: null,
      resourceId: null,
      units: null,
    };
    this.initialState = {
      formErrors: {
        displayable: [],
      },
      formSubmissionDraft: {
        groups: {},
      },
      formSaveState: SaveState.CLEAN,
      deletedGroups: [],
      itemsToUpsert: [],
      itemsToDelete: [],
    };
    this.state = this.initialState;
    this.addRollingCalculation = this.addRollingCalculation.bind(this);
    this.deleteRollingCalculation = this.deleteRollingCalculation.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.reorderCollectionInDragDropContext =
      this.reorderCollectionInDragDropContext.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const {
      fetchEmissionItemGroups,
      fetchEmissionLoggedItems,
      fetchEmissionFactors,
      fetchEmissionCalculatedValues,
      fetchEmissionRollingCalculations,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchEmissionLoggedItems(projectId, logProjectId);
    fetchEmissionFactors(projectId, logProjectId);
    fetchEmissionCalculatedValues(projectId, logProjectId);
    fetchEmissionRollingCalculations(projectId, logProjectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      itemGroups,
      rollingCalculations,
      setHasSubmittedForm,
      shouldSubmitForm,
    } = this.props;

    const { formSubmissionDraft, deletedGroups } = prevState;

    const hasFetchedRollingCalculations =
      (prevProps.isFetching && !isFetching) ||
      !_.isEqual(rollingCalculations, prevProps.rollingCalculations);

    if (!prevProps.shouldSubmitForm && shouldSubmitForm) {
      setHasSubmittedForm();
      this.validateFormSubmission().then(this.submitForm);
    }

    if (
      Object.keys(itemGroups).length >
      Object.keys(formSubmissionDraft.groups).length
    ) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (!nextFormSubmissionDraft.groups[id]) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
      return;
    }

    if (!_.isEqual(itemGroups, prevProps.itemGroups)) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (
          !deletedGroups.includes(id) &&
          !nextFormSubmissionDraft.groups[id]
        ) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
      return;
    }

    if (hasFetchedRollingCalculations) {
      const nextRollingCalculations = rollingCalculations.reduce(
        (acc, rollingCalculation) =>
          update(acc, {
            [rollingCalculation.groupId]: (group) =>
              group
                ? update(group, {
                    $push: [
                      {
                        ...rollingCalculation,
                        renderKey: rollingCalculation.id,
                      },
                    ],
                  })
                : [{ ...rollingCalculation, renderKey: rollingCalculation.id }],
          }),
        {},
      );

      this.setState((ps) =>
        update(ps, {
          formSubmissionDraft: {
            groups: {
              $merge: nextRollingCalculations,
            },
          },
        }),
      );
    }
  }

  handleDeleteGroup(groupId) {
    const {
      deleteEmissionItemGroup,
      fetchEmissionRollingCalculations,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;

    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              $set: _.omit(prevState.formSubmissionDraft.groups, groupId),
            },
          },
          deletedGroups: {
            $push: [groupId],
          },
        }),
      async () => {
        await deleteEmissionItemGroup(projectId, groupId);
        await fetchEmissionRollingCalculations(projectId, logProjectId);
      },
    );
  }

  deleteRollingCalculation(rollingCalculationIdx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemId =
      formSubmissionDraft.groups[groupId][rollingCalculationIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                $splice: [[rollingCalculationIdx, 1]],
              },
            },
          },
          itemsToDelete: {
            // If a user just created this rolling calculation and removed it right away,
            // don't add it to the list, there is nothing to delete on the server.
            $push: deletedItemId ? [deletedItemId] : [],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        const { setFormSaveState } = this.props;
        setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  reorderCollectionInDragDropContext(nextFormSubmissionDraft, itemToUpsert) {
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            $set: nextFormSubmissionDraft,
          },
          itemsToUpsert: {
            $push: [itemToUpsert],
          },
        }),
      () => {
        const { setFormSaveState } = this.props;
        setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  stageEdit(editsToMake, editsLocation) {
    const { editField, nextValue } = editsToMake;
    const { rollingCalculationIdx, groupId } = editsLocation;
    const { formSubmissionDraft } = this.state;
    const { setFormSaveState } = this.props;
    const editedId =
      formSubmissionDraft.groups[groupId][rollingCalculationIdx].id;
    const nextResourceValue = (() => {
      if (editField === 'resource') {
        return {
          resourceId: nextValue.resourceId,
          resourceType: nextValue.resourceType,
          resourceUnits: nextValue.resourceUnits,
        };
      }
      return {
        [editField]: nextValue === '' ? null : nextValue,
      };
    })();
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [rollingCalculationIdx]: {
                  $merge: nextResourceValue,
                },
              },
            },
          },
          itemsToUpsert: {
            $push: [editedId],
          },
        }),
      () => {
        setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  addRollingCalculation(groupId = null) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: (n) =>
              update(n || [], {
                $push: [
                  {
                    ...this.formSubmissionDraftObject,
                    renderKey: uuidv4(),
                    groupId,
                  },
                ],
              }),
          },
        },
      }),
    );
  }

  validateFormSubmission() {
    const { formSubmissionDraft } = this.state;
    const formErrors = {
      displayable: [],
      groups: {},
    };

    const limitItemRequiredFields = [
      {
        field: 'name',
        message: 'Name is required.',
      },
      {
        field: 'resourceId',
        message: 'Item used for calculations is required.',
      },
      {
        field: 'units',
        message: 'Units is required.',
      },
      {
        field: 'calculationType',
        message: 'Calculation type is required.',
      },
      {
        field: 'calculationDuration',
        message: 'Calculation duration is required.',
      },
    ];

    _.map(formSubmissionDraft.groups, (group, groupId) => {
      formErrors.groups[groupId] = {};
      _.map(group, (rollingCalculation, rollingCalculationId) => {
        limitItemRequiredFields.forEach((rf) => {
          if (isNullOrUndefined(rollingCalculation[rf.field])) {
            const errorMessage = `${
              rollingCalculation.name ? `${rollingCalculation.name}: ` : ''
            }${rf.message}`;
            formErrors.groups[groupId] = {
              ...formErrors.groups[groupId],
              [rollingCalculationId]: {
                ...formErrors.groups[groupId][rollingCalculationId],
                [rf.field]: errorMessage,
              },
            };
            formErrors.displayable.push(errorMessage);
          }
        });
      });
    });

    return new Promise((res) => {
      this.setState(
        (prevState) =>
          update(prevState, {
            formErrors: {
              $set: {
                ...formErrors,
                displayable: formErrors.displayable.sort(),
              },
            },
          }),
        res,
      );
    });
  }

  submitForm() {
    const { formErrors, itemsToUpsert, itemsToDelete, formSubmissionDraft } =
      this.state;

    const {
      deleteEmissionRollingCalculation,
      logProjectId,
      setFormSaveState,
      setHasSubmittedForm,
      upsertRollingCalculation,
    } = this.props;
    const { projectId } = this.context;

    if (formErrors.displayable.length === 0) {
      _.map(formSubmissionDraft.groups, (group) => {
        group.forEach((rollingCalculation) => {
          if (itemsToUpsert.includes(rollingCalculation.id)) {
            upsertRollingCalculation(projectId, {
              ...rollingCalculation,
              logProjectId,
            });
          }
        });
      });
      itemsToDelete.forEach((id) => {
        deleteEmissionRollingCalculation(projectId, id);
      });

      this.setState({
        itemsToUpsert: [],
        itemsToDelete: [],
      });
      setHasSubmittedForm();
      setFormSaveState(SaveState.SAVED);
    }
  }

  render() {
    const { formErrors, formSubmissionDraft } = this.state;

    const orderedFormSubmissionDraft = {
      groups: {
        null: _get(formSubmissionDraft, 'groups.null', []),
        ..._.omit(formSubmissionDraft.groups, 'null'),
      },
    };

    const {
      calculatedValues,
      isFetching,
      itemGroups,
      loggedItems,
      logProjectId,
      rollingCalculations,
      selectMenuPortalRef,
    } = this.props;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <RollingCalculationsTable
        addRollingCalculation={this.addRollingCalculation}
        calculatedValues={calculatedValues}
        deleteRollingCalculation={this.deleteRollingCalculation}
        formErrors={formErrors}
        formSubmissionDraft={orderedFormSubmissionDraft}
        handleDeleteGroup={this.handleDeleteGroup}
        isLoading={isFetching}
        itemGroups={itemGroups}
        loggedItems={loggedItems}
        logProjectId={logProjectId}
        onDragEnd={this.reorderCollectionInDragDropContext}
        rollingCalculations={rollingCalculations}
        selectMenuPortalRef={selectMenuPortalRef}
        stageEdit={this.stageEdit}
      />
    );
  }
}

RollingCalculationsContainer.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteEmissionItemGroup: PropTypes.func.isRequired,
  deleteEmissionRollingCalculation: PropTypes.func.isRequired,
  fetchEmissionFactors: PropTypes.func.isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  fetchEmissionCalculatedValues: PropTypes.func.isRequired,
  fetchEmissionRollingCalculations: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  setFormSaveState: PropTypes.func.isRequired,
  setHasSubmittedForm: PropTypes.func.isRequired,
  shouldSubmitForm: PropTypes.bool.isRequired,
  upsertRollingCalculation: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  const calculatedValues = getCalculatedValues(state, logProjectId);
  const loggedItems = getLoggedItems(state, logProjectId);

  const calculatedValuesWithTypes = calculatedValues.map((cv) => ({
    ...cv,
    resourceType: GenericLogType.CALCULATED_VALUE,
  }));

  const loggedItemsWithTypes = loggedItems.map((item) => ({
    ...item,
    resourceType: GenericLogType.LOGGED_ITEM,
  }));

  const numericLoggedItemsWithTypes = loggedItemsWithTypes.filter(
    (item) => item.itemType === GenericLogLoggedItemType.NUMBER,
  );

  return {
    isFetching: getIsFetching(state, 'airEmissionsRollingCalculations'),
    calculatedValues: calculatedValuesWithTypes,
    itemGroups: getRollingCalculationGroups(state, logProjectId),
    loggedItems: numericLoggedItemsWithTypes,
    rollingCalculations: getRollingCalculations(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertRollingCalculation: (projectId, rollingCalculation) =>
    rollingCalculation.id
      ? dispatch(
          updateEmissionRollingCalculationAction(projectId, rollingCalculation),
        )
      : dispatch(
          createEmissionRollingCalculationAction(projectId, rollingCalculation),
        ),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  fetchEmissionFactors: (projectId, logProjectId) =>
    dispatch(fetchEmissionFactorsAction(projectId, logProjectId)),
  fetchEmissionCalculatedValues: (projectId, logProjectId) =>
    dispatch(fetchEmissionCalculatedValuesAction(projectId, logProjectId)),
  fetchEmissionRollingCalculations: (projectId, logProjectId) =>
    dispatch(fetchEmissionRollingCalculationsAction(projectId, logProjectId)),
  deleteEmissionRollingCalculation: (projectId, id) =>
    dispatch(
      deleteEmissionRollingCalculationAction(
        projectId,
        ownProps.logProjectId,
        id,
      ),
    ),
  deleteEmissionItemGroup: (projectId, id) =>
    dispatch(
      deleteEmissionItemGroupAction(projectId, ownProps.logProjectId, id),
    ),
});

RollingCalculationsContainer.contextType = ProjectContext;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(RollingCalculationsContainer),
);
