/* eslint-disable */
var projectMapsService = {
  initialize: function (projectId) {
    this._projectId = projectId;
  },

  getSelectedMap: function () {
    if (!this._projectId) {
      throw new Error(
        'This service needs to be initialized with a project ID.',
      );
    }

    var projectCollection =
      require('../../models_collections/projectCollection').singleton();
    var project = projectCollection.get(this._projectId);
    var mapId = project.get('selected_map');
    var mapCollection =
      require('../../models_collections/mapCollection').singleton();

    return mapCollection.get(mapId);
  },

  isSelectedMap: function (mapId) {
    var selectedMap = this.getSelectedMap();
    return (selectedMap && selectedMap.id) === mapId;
  },
};

module.exports = projectMapsService;
