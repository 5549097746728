import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useIsSuperAdmin } from '@monorepo/shared/hooks/permissions/useIsSuperAdmin';
import { useFeatureFlags } from 'mapistry-shared/api';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Menu } from '../../elements';
import RecordPanel from '../../views/projectDashboard/RecordPanel';
import EditParametersModal from './EditParametersModal';
import { ParameterSettingsUploadModal } from './ParameterSettingsUploadModal';
import { SamplingResultsParametersList } from './SamplingResultCalendarList/SamplingResultsParametersList';
import SamplingResultModal from './SamplingResultModal';
import SamplingResultRecordList from './SamplingResultRecordList';
import { SamplingResultsUploadModal } from './SamplingResultsUploadModal';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function SamplingResultRecordPanel({
  hasConfiguredSamplingParameters,
  editModalParameterSlug,
  editParametersModalIsOpen,
  editResultsModalIsOpen,
  onCloseEditParametersModal,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onCloseParameterSettingsUploadModal,
  onCloseResultsEditModal,
  onCloseSamplingResultsUploadModal,
  onDownloadEvents,
  onOpenEditParametersModal,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onOpenParameterSettingsUploadModal,
  onOpenResultsEditModal,
  onOpenSamplingResultsUploadModal,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  parameterSettingUploadModalIsOpen,
  projectId,
  samplingResultAttachmentToEdit,
  samplingResultsUploadModalIsOpen,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  wastewaterParameters,
}) {
  const isSuperAdmin = useIsSuperAdmin();
  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(projectId);
  const { areWaterSamplingCalendarsEnabledInRecordSection } = useFeatureFlags();

  const menuOptions = useMemo(() => {
    const options = [
      ...(hasProjectUpdatePermission
        ? [
            {
              disabled: !hasConfiguredSamplingParameters,
              label: 'Upload Lab Report',
              onClick: onOpenSamplingResultsUploadModal,
              iconIdentifier: 'notes',
            },
          ]
        : []),
      {
        label: 'Sampling Parameter Settings',
        onClick: onOpenEditParametersModal,
        iconIdentifier: 'cog',
      },
      ...(hasProjectUpdatePermission
        ? [
            {
              disabled: !hasConfiguredSamplingParameters,
              label: 'Add Sampling Result',
              onClick: onOpenResultsEditModal,
              iconIdentifier: 'edit',
            },
          ]
        : []),
      {
        disabled: !hasConfiguredSamplingParameters,
        label: 'Download',
        onClick: onDownloadEvents,
        iconIdentifier: 'download',
      },
    ];

    return options;
  }, [
    hasConfiguredSamplingParameters,
    hasProjectUpdatePermission,
    onDownloadEvents,
    onOpenEditParametersModal,
    onOpenResultsEditModal,
    onOpenSamplingResultsUploadModal,
  ]);

  const handleOpenParameterSettingUploadModal = useCallback(() => {
    onCloseEditParametersModal();
    onOpenParameterSettingsUploadModal();
  }, [onCloseEditParametersModal, onOpenParameterSettingsUploadModal]);

  return (
    <RecordPanel
      header={
        <>
          <div className="list-header">Detailed Sampling Results</div>
          <Menu options={menuOptions} />
        </>
      }
    >
      {/* TOO: Take out the feature flag after it got released. Delete as much code as you can from the old record list */}
      {areWaterSamplingCalendarsEnabledInRecordSection ? (
        <SamplingResultsParametersList
          openEditParametersModal={onOpenEditParametersModal}
          openResultsEditModal={onOpenResultsEditModal}
          projectId={projectId}
        />
      ) : (
        <SamplingResultRecordList
          onOpenEditParametersModal={onOpenEditParametersModal}
          onOpenResultsEditModal={onOpenResultsEditModal}
        />
      )}
      <SamplingResultModal
        attachment={samplingResultAttachmentToEdit}
        open={editResultsModalIsOpen}
        onClose={onCloseResultsEditModal}
        initialSearchParameterSlug={editModalParameterSlug}
      />
      <EditParametersModal
        canUpload={isSuperAdmin}
        open={editParametersModalIsOpen}
        onClose={onCloseEditParametersModal}
        onOpenUpload={handleOpenParameterSettingUploadModal}
      />
      <SamplingResultsUploadModal
        enabledSamplingParameters={wastewaterParameters}
        isOpen={samplingResultsUploadModalIsOpen}
        onClose={onCloseSamplingResultsUploadModal}
        projectId={projectId}
      />
      <ParameterSettingsUploadModal
        isOpen={parameterSettingUploadModalIsOpen}
        onClose={onCloseParameterSettingsUploadModal}
        projectId={projectId}
      />
    </RecordPanel>
  );
}

SamplingResultRecordPanel.defaultProps = {
  editModalParameterSlug: null,
  editParametersModalIsOpen: false,
  samplingResultAttachmentToEdit: {},
};

SamplingResultRecordPanel.propTypes = {
  editParametersModalIsOpen: PropTypes.bool,
  editResultsModalIsOpen: PropTypes.bool.isRequired,
  editModalParameterSlug: PropTypes.string,
  hasConfiguredSamplingParameters: PropTypes.bool.isRequired,
  onCloseEditParametersModal: PropTypes.func.isRequired,
  onCloseResultsEditModal: PropTypes.func.isRequired,
  onCloseSamplingResultsUploadModal: PropTypes.func.isRequired,
  onDownloadEvents: PropTypes.func.isRequired,
  onOpenEditParametersModal: PropTypes.func.isRequired,
  onOpenResultsEditModal: PropTypes.func.isRequired,
  onOpenSamplingResultsUploadModal: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  samplingResultAttachmentToEdit: PropTypes.shape({}),
  samplingResultsUploadModalIsOpen: PropTypes.bool.isRequired,
};
