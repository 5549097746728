/* eslint-disable */
var ContentLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/profileSettingsContent.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    basicInfo: '#basic-info',
    contactDetails: '#contact-details',
    signature: '#signature',
  },

  events: {
    'click #save-profile-settings': '_saveProfileSettings',
  },

  ui: {
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
  },

  behaviors: {
    DisplayMessages: {},
  },

  onShow() {
    let BasicInfoCompositeView = require('./userProfileSettings/BasicInfoItemView');
    let ContactDetailsCompositeView = require('./userProfileSettings/ContactDetailsCompositeView');
    let SignatureItemView = require('../../../user/SignatureItemView');

    this.basicInfo.show(new BasicInfoCompositeView({ model: this.model }));
    this.contactDetails.show(
      new ContactDetailsCompositeView({
        collection: this._getContactDetailsCollection(),
      }),
    );

    if (require('../../../currentUser').id === this.model.id) {
      this.signature.show(new SignatureItemView({ model: this.model }));
    }
  },

  _getUserAttr(attrName) {
    return this.model.has(attrName) ? this.model.get(attrName) : '';
  },

  _saveProfileSettings() {
    let userUpdates = this.basicInfo.currentView.ui.$form.serializeObject();
    _.extend(
      userUpdates,
      this.contactDetails.currentView.ui.$form.serializeObject(),
    );

    if (this.signature.currentView && this.signature.currentView.signaturePad) {
      const signaturePad = this.signature.currentView.signaturePad;

      const signatureGotEdited = signaturePad.points.length > 0;
      const signatureFieldIsEmpty = signaturePad.isEmpty();
      const oldSignature = this._getUserAttr('signature') || null;

      let newSignature;
      if (signatureGotEdited) newSignature = signaturePad.toDataURL();
      else if (signatureFieldIsEmpty) newSignature = null;
      else newSignature = oldSignature; // signature field didn't get touched

      _.extend(userUpdates, { signature: newSignature });
    }

    return this.model
      .savePromise(userUpdates, { wait: true })
      .then(() =>
        this.triggerMethod('ShowSuccess', 'Profile settings were updated.'),
      )
      .catch(() =>
        this.triggerMethod(
          'ShowError',
          'An error occurred, please try again.  If this issue persists, please contact Mapistry support.',
        ),
      );
  },

  _getContactDetailsCollection() {
    let email = new Backbone.Model({
      category: 'email',
      contactLabel: 'Email',
      contactName: 'email',
      contactValue: this._getUserAttr('email'),
    });

    let officePhone = new Backbone.Model({
      category: 'phone-number',
      contactLabel: 'Office Phone Number',
      contactName: 'office_phone_number',
      contactValue: this._getUserAttr('office_phone_number'),
    });

    let mobilePhone = new Backbone.Model({
      category: 'phone-number',
      contactLabel: 'Mobile Phone Number',
      contactName: 'mobile_phone_number',
      contactValue: this._getUserAttr('mobile_phone_number'),
    });

    return new Backbone.Collection([email, officePhone, mobilePhone]);
  },
});

module.exports = ContentLayoutView;
