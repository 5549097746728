import Bluebird from 'bluebird';
import moment from 'moment';
import APP from '../config';
import { doMultipartUpload } from './S3MultipartUploader';

function getMapboxUserName() {
  return 'azadratzki';
}

function getMapboxUploadDetails() {
  const url = `https://api.mapbox.com/uploads/v1/${getMapboxUserName()}/credentials?access_token=${
    APP.mapboxToken
  }`;

  const xhrOptions = {
    type: 'POST',
    url,
    xhrFields: {
      withCredentials: false,
    },
  };

  return Bluebird.resolve($.ajax(xhrOptions)); // eslint-disable-line no-undef
}

function getMapboxUploadUrl() {
  return `https://api.mapbox.com/uploads/v1/${getMapboxUserName()}?access_token=${
    APP.mapboxToken
  }`;
}

function getMapboxUploadStatusUrl(uploadId) {
  return `https://api.mapbox.com/uploads/v1/${getMapboxUserName()}/${uploadId}?access_token=${
    APP.mapboxToken
  }`;
}

function getTilesetId(layerId) {
  return `${getMapboxUserName()}.${layerId.substr(0, 32)}`;
}

export function uploadFileToMapbox(
  layerId,
  layerName,
  fileDescriptorObject,
  progressCb,
) {
  return getMapboxUploadDetails()
    .tap((mapboxUploadDetails) =>
      doMultipartUpload(mapboxUploadDetails, fileDescriptorObject, progressCb),
    )
    .then((mapboxUploadDetails) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-use-before-define
      completeMapboxUpload(layerId, layerName, mapboxUploadDetails),
    )
    .then((results) => ({
      mapbox_tileset_id: results.id,
      mapbox_tileset_slug: results.tileset,
    }));
}

export function getFileUploadStatus(uploadId) {
  /**
   * Example response
   * {
   *   complete: true
   *   created: "2021-06-02T21:35:35.992Z"
   *   error: null
   *   id: "ckpfznx9c01ml21mqjepnw6tz"
   *   modified: "2021-06-02T21:35:35.992Z"
   *   name: "Austin Soiland Test Upload"
   *   owner: "azadratzki"
   *   progress: 1
   *   tileset: "azadratzki.fa8e21bd-1837-4e59-95db-a664ce86"
   * }
   */
  const options = {
    type: 'GET',
    url: getMapboxUploadStatusUrl(uploadId),
    contentType: 'application/json',
    dataType: 'json',
    xhrFields: {
      withCredentials: false,
    },
  };
  return Bluebird.resolve($.ajax(options)); // eslint-disable-line no-undef
}

function completeMapboxUpload(layerId, layerName, mapboxUploadDetails) {
  return new Bluebird((resolve, reject) => {
    const options = {
      type: 'POST',
      url: getMapboxUploadUrl(),
      data: JSON.stringify({
        url: mapboxUploadDetails.url,
        tileset: getTilesetId(layerId),
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-use-before-define
        name: getMapboxFileName(layerId),
      }),
      xhrFields: {
        withCredentials: false,
      },
      contentType: 'application/json',
      dataType: 'json',
      error: (e) => {
        const errMessage =
          (e && e.responseJSON && e.responseJSON.message) ||
          'Unknown error occurred during file upload';
        reject(new Error(errMessage));
      },
      success: (results) => {
        resolve(results);
      },
    };
    return $.ajax(options); // eslint-disable-line no-undef
  });
}

function getMapboxFileName(layerId) {
  const orgIdPart =
    (APP.organizationId && APP.organizationId.substr(0, 6)) || 'noorg';
  const projectIdPart =
    (APP.projectId && APP.projectId.substr(0, 6)) || 'noproj';
  const layerIdPart = (layerId && layerId.substr(0, 6)) || 'nolayer';
  const datePart = moment().format('YYYY-MM-DD');
  return `${APP.environment}_org_${orgIdPart}_proj_${projectIdPart}_layer_${layerIdPart}_${datePart}`;
}
