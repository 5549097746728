/* eslint-disable */
var ProjectLayoutView = Backbone.Marionette.LayoutView.extend({
  className: 'project-map-page-content-container',
  template: require('./templates/project.pug'),

  regions: {
    sidebar: '#sidebar',
    map: '#map',
  },

  onShow: function () {
    var SidebarLayoutView = require('./sidebar/SidebarLayoutView');
    var projectCollection =
      require('../models_collections/projectCollection').singleton();
    let currentProject = projectCollection.getCurrent();
    var isFederalProject = currentProject.get('is_federal');
    var mapCollection =
      require('../models_collections/mapCollection').singleton();
    var mapId = this.model.get('selected_map');
    var mapModel = mapCollection.get(mapId);
    const APP = require('../config');
    const organizationId = this.model.get('organization_id');

    this.sidebar.show(new SidebarLayoutView({ model: this.model }), {
      preventDestroy: true,
    });
    this._showMap(isFederalProject, organizationId, mapModel);

    this.listenTo(
      projectCollection,
      'change:selected_map',
      function (project, mapId) {
        var mapModel = mapCollection.get(mapId);

        APP.vent.trigger('projectLayoutView:change:selected_map');
        mapModel
          .fetchDisplaySettings()
          .bind(this)
          .then(
            this._showMap.bind(
              this,
              isFederalProject,
              organizationId,
              mapModel,
            ),
          );
      },
    );
  },

  _showMap: function (isFederalProject, organizationId, mapModel) {
    var MapView = require('../map/MapView');
    this.map.show(
      new MapView({
        model: mapModel,
        isFederalProject,
        organizationId,
      }),
    );
  },
});

module.exports = ProjectLayoutView;
