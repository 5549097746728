/* eslint-disable */
const apiCaller = require('../apiCaller');
const ProjectListAttrTableModel = require('./projectListAttrTableModel');

const ProjectListAttrTableCollection = Backbone.Collection.extend({
  model: ProjectListAttrTableModel,
  comparator: 'name',

  initialize(options = {}) {
    this.key = options.key;
  },

  url: function () {
    const APP = require('../config');
    return apiCaller.getProjectListAttrs(APP.projectId, this.key);
  },

  fetch: function (options = {}) {
    options.cache = false;
    options.wait = true;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
});

Backbone.addSingletonToCollection(ProjectListAttrTableCollection);
module.exports = ProjectListAttrTableCollection;
