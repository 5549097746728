var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isShown, key, label, showLabel, value) {
      if (showLabel && isShown) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
if (showLabel) {
if (isShown) {
pug_html = pug_html + "\u003Cinput" + (" class=\"new-attr-value form-control\""+pug.attr("name", key, true, true)+pug.attr("value", value ? value : '', true, true)) + "\u003E";
}
}
else {
pug_html = pug_html + "\u003Cinput" + (" class=\"new-attr-value form-control\""+pug.attr("name", key, true, true)) + "\u003E";
}
    }.call(this, "isShown" in locals_for_with ?
        locals_for_with.isShown :
        typeof isShown !== 'undefined' ? isShown : undefined, "key" in locals_for_with ?
        locals_for_with.key :
        typeof key !== 'undefined' ? key : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "showLabel" in locals_for_with ?
        locals_for_with.showLabel :
        typeof showLabel !== 'undefined' ? showLabel : undefined, "value" in locals_for_with ?
        locals_for_with.value :
        typeof value !== 'undefined' ? value : undefined));
    ;;return pug_html;};
module.exports = template;