/* eslint-disable */
var bing = require('../services/bing');
var layerCollection =
  require('../models_collections/layerCollection').singleton();
var EditAttributeRowView = require('./editAttributeRowView');
var EditAttributeModel = require('../models_collections/editAttributeModel');
var ChemicAttrsSelectView = require('./ChemicalAttrsSelectView');
const ChemicalInputListView = require('./chemical/ChemicalInputListView');
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import _ from 'underscore';
import attrStore from '../attrStore';
import {
  buildingNameOptionMapper,
  clearSelect2,
  getBuildingNames,
  kvpsPropFilter,
  validateListAttr,
} from './utils';

var EditAttributeListView = Backbone.View.extend({
  id: 'body-container',

  template: require('./templates/attributeList.pug'),

  events: {
    'click #add': '_submitNewAttr',
    'click #edit': '_activeEditMode',
    'change #new-attr-format': '_changeFormat',
    'change .chemical-attributes-select-create': '_chooseSelectAttr',
  },

  initialize: function (options) {
    this._layer = layerCollection.get(this.model.get('layer_id'));
    this.listenTo(
      this._layer.attrCollectionWrapper,
      'add destroy',
      this.render,
    );
    this.listenTo(
      this._layer.attrCollectionWrapper,
      'add:errors',
      this.displayErrors,
    );
    this.listenTo(this.model, 'change', this.render);
  },

  render: function (options = {}) {
    const displayEdit = options.editMode ? 'hidden' : '';
    const formatValue = this.model.get('formatValue');
    this._editMode = !!options.editMode;
    this.templateData = {
      displayEdit: displayEdit,
      listTypes: this.templateData
        ? this.templateData.listTypes
        : attrStore.getLists(),
      formatValue,
      nameValue: this.getNameValue(formatValue),
    };

    this.$el.empty().append(this.template(this.templateData));
    const listType = this.getCurrentListType();
    this.chemicalInputListView1 = new ChemicalInputListView({
      collection: new Backbone.Collection(
        this.prepareChemicalViewList(listType, 0, 18),
      ),
      showLabel: formatValue === 'list',
    });
    this.$el
      .find('#edit-fields')
      .append(this.chemicalInputListView1.render().el);

    this.chemicalInputListView2 = new ChemicalInputListView({
      collection: new Backbone.Collection(
        this.prepareChemicalViewList(listType, 18),
      ),
      showLabel: formatValue === 'list',
    });
    this.$el
      .find('.chemical-attributes-select-create')
      .append(this.chemicalInputListView2.render().el);

    this._$form = this.$('form');
    this._$errorNode = this.$el.find('.alert');
    this._appendFeatureLabelRow();
    this._appendBuildingNameRow();
    this._appendMeasurementRows();
    this._appendRows(this.model.geocodedAttrs());
    this._appendRows(this.model.nonGeocodedAttrs());
    this._appendRows(this.model.suggestedAttrs({ filter: 'nonGeocoded' }));

    // We no longer pay for bing.
    // Uncomment when we have a solution of getting point's elevation.
    // if (this.model.isMarker()) {
    //   this._appendElevationRow();
    // }

    if (this.model.get('formatValue') === 'list') {
      this.selectedAttributes =
        this.selectedAttributes || this._getChemicalAttributes();
      this.selectContainer = this.$el.find(
        '.chemical-attributes-select-create',
      );
      this.chemicView = new ChemicAttrsSelectView({
        chemicalAttributes: this.selectedAttributes,
        isEdited: true,
        templateKey: this.model.get('templateKey'),
      }).render();
      this.selectContainer.append(this.chemicView.el);
    }

    return this;
  },

  getNameValue: function (formatValue) {
    return formatValue === 'list'
      ? this._$form
        ? this._$form.serializeObject().name
        : ''
      : '';
  },

  getCurrentListType: function () {
    return this.templateData.listTypes.find(
      (listType) => listType.key === this.model.get('formatListKey'),
    );
  },

  prepareChemicalViewList: function (listType, begin, end) {
    let chemicalViewList = this._getEditFields().filter((f) => f.initial);

    if (listType) {
      chemicalViewList = chemicalViewList.map((item) =>
        listType.kvps.find((kvp) => kvp.key === item.key),
      );
    }

    return chemicalViewList.slice(begin || 0, end);
  },

  /**
   * Helper used to display errors in the modal
   * @param: errors {string | array<strings>} - The errors to display
   */
  displayErrors: function (errors) {
    var $errorsNode = $('<ul>');

    errors = _.flatten([errors]);

    _.each(errors, function (msg) {
      $errorsNode.append('<li>' + msg + '</li>');
    });

    if ($errorsNode.find('li').length) {
      this._$errorNode.html($errorsNode.html()).show();
    }
  },

  displaySavingCue: function () {
    this.$('.saving-cue').show().fadeOut(2000);
  },

  _getEditFields: function () {
    const formatListKey = this.model.get('formatListKey');
    if (!formatListKey) {
      return [{ key: 'value', label: 'Value', initial: true }];
    }
    return attrStore
      .getListKvps(formatListKey)
      .filter((field) => !field.hidden);
  },

  _getChemicalAttributes: function () {
    const formatListKey = this.model.get('formatListKey');
    if (!formatListKey) {
      return [{ key: 'value', label: 'Value' }];
    }
    return attrStore.getListChemicalAttributes(formatListKey);
  },

  _changeFormat: function (event) {
    const selectEl = event.target;
    const optionEl = selectEl.options[selectEl.selectedIndex];
    const formatValue = event.target.value;
    const formatListKey = optionEl.dataset.listKey;

    this.model.set({
      formatValue: formatValue,
      formatListKey: formatListKey,
    });
  },

  /**
   * @param attrs {Array<objects>}
   */
  _appendRows: function (attrs) {
    attrs.forEach(function (attr) {
      this._appendAttrRow(attr);
    }, this);
  },

  _appendElevationRow: function () {
    if (!this.model.isMarker()) {
      return;
    }

    return bing
      .getElevation(this.model.getLatitude(), this.model.getLongitude())
      .bind(this)
      .then(function (elevation) {
        var value = L.GeometryUtil.readableDistance(
          elevation,
          this.model.isUnitsMetric(),
        );
        this._appendAttrRow({ name: 'Elevation', value: value });
      });
  },

  /**
   * Measurement attrs include lat, long, distance and area
   */
  _appendMeasurementRows: function () {
    if (this.model.isMarker()) {
      this._appendLatitudeRow();
      this._appendLongitudeRow();
    } else if (this.model.isPolyline()) {
      this._appendDistanceRow();
    } else if (this.model.isPolygon()) {
      this._appendAreaRow();
    }
  },

  /**
   * Area attr
   */
  _appendAreaRow: function () {
    this._appendAttrRow({ name: 'Area', value: this.model.displayArea() });
  },

  /**
   * Distance attr
   */
  _appendDistanceRow: function () {
    this._appendAttrRow({
      name: 'Length',
      value: this.model.displayDistance(),
    });
  },

  /**
   * Feature Label attr
   */
  _appendFeatureLabelRow: function () {
    this._appendAttrRow({
      name: this.model.displayNameLabel(),
      value: this.model.get('name'),
      featureModel: this.model,
      isFeatureLabel: true,
    });
  },

  _appendBuildingNameRow: function () {
    const value = this.model.get('building_name');
    this._appendAttrRow({
      name: this.model.displayBuildingNameLabel(),
      value,
      featureModel: this.model,
      isBuildingName: true,
      type: 'select',
      options: getBuildingNames(layerCollection).map(
        buildingNameOptionMapper(value),
      ),
    });
  },

  /**
   * Latitude attr
   */
  _appendLatitudeRow: function () {
    this._appendAttrRow({
      name: 'Latitude',
      value: this.model.displayLatitude(),
    });
  },

  /**
   * Longitude attr
   */
  _appendLongitudeRow: function () {
    this._appendAttrRow({
      name: 'Longitude',
      value: this.model.displayLongitude(),
    });
  },

  /**
   * Append a new row to the list of attributes.
   *
   * @param attr {Object}
   *   - name {String} Required, The name of the attribute.
   *   - value {String} - The value of the attribute.
   *   - featureModel {Backbone.Model} - Optional, Should be included if attr is feature label
   */
  _appendAttrRow: function (attr) {
    attr.editMode = this._editMode;
    attr.featureModel = this.model;

    const model = new EditAttributeModel(attr);
    if (model.blockRender()) {
      return;
    }

    const view = new EditAttributeRowView({
      model: model,
      parentView: this,
    });

    this.$('#attr-rows-parent').append(view.render().el);
  },

  /**
   * Handles submission of new attr
   */
  _submitNewAttr: function (e) {
    let data = this._$form.serializeObject();
    const key = this.model.get('formatListKey');

    if (data.format === 'list') {
      let kvps = attrStore.getListKvps(key);
      kvps = kvps.map((kvp) => {
        if (_.has(data, kvp.key)) {
          kvp.value = data[kvp.key];
          kvp.isShown = true;
        }
        return kvp;
      });

      const attrs = [];
      this.selectedAttributes.forEach((attr) => {
        if (!attr.switchable) {
          attrs.push({
            text: attr.text,
            selected: attr.selected,
            key: attr.key,
          });
        } else {
          attrs.push({
            text: attr.text,
            selected: attr.selected,
            key: attr.key,
            switchable: attr.switchable,
          });
        }
      });
      kvps.push({
        key: 'chemical-attrs',
        label: 'Chemical Attrs',
        value: attrs,
      });

      data = {
        name: data.name,
        format: data.format,
        template_key: key,
        value: kvps,
      };
    }

    const valid = this.validateAttr(data);
    if (valid) {
      const dataValue =
        data.format === 'list' ? data.value.map(kvpsPropFilter) : data.value;
      this.model.addAttr(data.name, dataValue, data.format, data.template_key);
      if (data.format === 'list') {
        this.resetListTypeValues();
      }
    }
    e.preventDefault();
  },

  resetListTypeValues: function () {
    this.selectedAttributes = this.selectedAttributes.map((attr) => ({
      text: attr.text,
      selected: false,
      key: attr.key,
      switchable: attr.switchable,
    }));
    this.templateData = null;
    this._$form = null;
  },

  /**
   * Validates an attr
   *
   * @param data {object}
   *   - format {string} 'text' or 'number'
   *   - name {string}
   *   - value {string}
   *
   * @returns {boolean}
   */
  validateAttr: function (data) {
    var name = data.name.toLowerCase();
    var errors = [];

    if (data.name === 'Feature Label') {
      errors.push(this._layer.validateFeatureName(data.value));
    } else {
      errors.push(this.model.validateAttr(name));
    }

    if (data.format === 'number' && isNaN(data.value)) {
      errors.push('Value must be numeric.');
    }

    if (data.format === 'list') {
      errors.push(validateListAttr(data.value));
    }

    errors = _.flatten(errors);

    if (errors.length) {
      this.displayErrors(errors);
    } else {
      return true;
    }
  },

  _activeEditMode: function (e) {
    e.preventDefault();
    this.render({ editMode: true });
  },

  handleAttrChange: function () {
    this._$errorNode.hide();
    this.displaySavingCue();
  },

  switchFields: function () {
    setTimeout(() => {
      const data = this._$form.serializeObject();
      const listType = this.getCurrentListType();
      const kvps = listType.kvps;
      const attributes = this.selectedAttributes;

      kvps.forEach((kvp) => {
        if (data[kvp.key]) {
          kvp.value = data[kvp.key];
        }
        if (kvp.switchable) {
          const attribute = attributes.find(
            (attr) => attr.key === kvp.relatedAttr,
          );
          kvp.isShown = attribute.selected;
        }
      });
      listType.kvps = kvps;
      clearSelect2.bind(this)();
      this.render();
    });
  },

  _chooseSelectAttr: function (e) {
    const attributes = this.selectedAttributes;
    const selectedValues = JSON.parse(this.chemicView.getCurrentValue());

    if (selectedValues) {
      try {
        _.each(attributes, (attr, index) => {
          const foundIndex = selectedValues.findIndex(
            (value) => value === attr.key,
          );

          if (foundIndex !== -1) {
            attributes[index].selected = true;

            if (attr.switchable) {
              this.switchFields();
            }
          } else if (attr.selected) {
            attributes[index].selected = false;

            if (attr.switchable) {
              this.switchFields();
            }
          }
        });
      } catch (e) {}
    } else {
      _.each(attributes, (attr) => {
        attr.selected = false;

        if (attr.switchable) {
          this.switchFields();
        }
      });
    }
  },
});

module.exports = EditAttributeListView;
