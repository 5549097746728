/* eslint-disable */
var Organization = Backbone.Model.extend({});
var APP = require('../config');
var currentUserArbiter = require('../permissions/currentUserArbiter');

var OrganizationCollection = Backbone.Collection.extend({
  model: Organization,
  url: APP.apiUrl + '/organizations',

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  isNameAlreadyInUse: function (name) {
    return !!this.find(function (organization) {
      return organization.get('name').toLowerCase() === name.toLowerCase();
    });
  },

  whereUpdatePermissionsGranted: function () {
    return Promise.resolve(this.toJSON()).filter(function (organization) {
      return currentUserArbiter.hasOrganizationUpdatePermission(
        organization.id,
      );
    });
  },

  fetchTags: function (organizationId) {},

  currentOrganizationTags: {},
});

Backbone.addSingletonToCollection(OrganizationCollection);

module.exports = OrganizationCollection;
