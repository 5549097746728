import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
import { isNumeric, isValidDate } from '../../../utils';

const allSamplingParameters = SamplingParameters.getAll();
const hasValue = (fieldValue) => fieldValue != null && fieldValue !== '';

const onSaveValidations = {
  noValueForParameterValue: {
    name: 'parameterValue',
    errorMessage: 'Parameter value is required.',
  },
  noValueForMethod: {
    name: 'methodText',
    requiredIfEmpty: ['methodSlug'],
    errorMessage: 'Method is required.',
  },
};

export function validateField(result, fieldName, fieldValue) {
  if (
    fieldName === 'parameterValue' &&
    hasValue(fieldValue) &&
    fieldValue !== 'ND' &&
    !isNumeric(fieldValue)
  ) {
    return {
      id: 'invalidParameterValue',
      name: 'parameterValue',
      errorMessage: "Parameter value must be a number or 'ND' (Not Detected).",
    };
  }

  if (fieldName === 'units' && !hasValue(fieldValue) && result?.parameterSlug) {
    const parameter = allSamplingParameters.find(
      (p) => p.slug === result.parameterSlug,
    );
    if (parameter.units?.length) {
      return {
        id: 'invalidUnitsValue',
        name: 'units',
        errorMessage: 'Units is required for this parameter.',
      };
    }
  }

  if (fieldName === 'detectionLimit' && hasValue(fieldValue)) {
    if (!isNumeric(fieldValue)) {
      return {
        id: 'invalidDetectionLimit',
        name: 'detectionLimit',
        errorMessage: 'The method detection limit must be a number.',
      };
    }
  }
  if (fieldName === 'reportingLimit' && hasValue(fieldValue)) {
    if (!isNumeric(fieldValue)) {
      return {
        id: 'invalidReportingLimit',
        name: 'reportingLimit',
        errorMessage: 'The reporting limit must be a number.',
      };
    }
  }

  if (fieldName === 'detectionLimit' && result != null) {
    const { detectionLimit, reportingLimit } = {
      ...result,
      [fieldName]: fieldValue,
    };
    if (
      hasValue(detectionLimit) &&
      hasValue(reportingLimit) &&
      isNumeric(detectionLimit) &&
      isNumeric(reportingLimit) &&
      Number.parseFloat(detectionLimit) > Number.parseFloat(reportingLimit)
    ) {
      return {
        id: 'detectionLimitLessThanReporting',
        name: 'detectionLimit',
        errorMessage:
          'The method detection limit must be less than the reporting limit.',
      };
    }
  }

  if (
    fieldName === 'analysisDate' &&
    hasValue(fieldValue) &&
    !isValidDate(fieldValue)
  ) {
    return {
      id: 'invalidAnalysisDate',
      name: 'analysisDate',
      errorMessage: 'Invalid analysis date.',
    };
  }
  return null;
}

function doesOnSaveValidationPass(result, onSaveValidationId) {
  const validation = onSaveValidations[onSaveValidationId];
  const fieldsToCheck = validation.requiredIfEmpty
    ? [validation.name, ...validation.requiredIfEmpty]
    : [validation.name];
  const areAllEmpty = fieldsToCheck.every((field) => !hasValue(result[field]));
  return !areAllEmpty;
}

export function validateOnUpdate(result, currentErrors = []) {
  const fieldsToValidate = Object.keys(result);
  const onUpdateErrors = fieldsToValidate
    .map((fieldName) => validateField(result, fieldName, result[fieldName]))
    .filter((errorObject) => errorObject != null);
  const currentOnSaveErrors = currentErrors.filter(
    (errorObject) =>
      onSaveValidations[errorObject.id] != null &&
      !doesOnSaveValidationPass(result, errorObject.id),
  );
  return currentOnSaveErrors.concat(onUpdateErrors);
}

export function validateOnSave(result) {
  const onSaveErrors = Object.keys(onSaveValidations)
    .filter((validationId) => !doesOnSaveValidationPass(result, validationId))
    .map((validationId) => ({
      ...onSaveValidations[validationId],
      id: validationId,
    }));
  return onSaveErrors.concat(validateOnUpdate(result, []));
}
