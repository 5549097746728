/* eslint-disable */
let moment = require('moment');

const samplePeriodCircleProps = {
  default: {
    colorClass: 'incomplete',
    iconClass: '',
    tooltip: '',
  },
  incomplete: {
    colorClass: 'upcoming',
    iconClass: '',
    tooltip: '',
  },
  complete: {
    colorClass: 'complete',
    iconClass: '#check',
    tooltip: '',
  },
};

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/samplePeriodCircle.pug'),

  templateHelpers: function () {
    return samplePeriodCircleProps[this.status];
  },

  initialize: function (options) {
    this.status =
      Object.keys(samplePeriodCircleProps).indexOf(options.status) > -1
        ? options.status
        : 'default';
  },
});
