/* eslint-disable */
var BaseShapeItemView = require('./BaseShapeItemView');

module.exports = BaseShapeItemView.extend({
  template: require('./templates/polygon.pug'),

  ui: {
    $colorContainer: '#color-container',
    $labelSettings: '.label-settings',
    $labelTextColorContainer: '#label-text-color-container',
    $labelTextSizeContainer: '#label-text-size-container',
    $labelBackgroundColorContainer: '#label-background-color-container',
    $labelShowChemicalAttributesContainer:
      '#label_show_chemical_attributes-container',
    $leaderlineWeightContainer: '#leaderline-weight-container',
    $leaderlineOpacityContainer: '#leaderline-opacity-container',
    $leaderlineColorContainer: '#leaderline-color-container',
    $leaderlineDisplayContainer: '#leaderline-display-container',
  },

  templateHelpers() {
    let fillOpacity = this.model.get('fill_opacity');
    let isFillOpacitySelected = function (f) {
      return parseFloat(f) === parseFloat(fillOpacity);
    };
    return _.extend(BaseShapeItemView.prototype.templateHelpers.call(this), {
      isFillOpacitySelected,
    });
  },

  _formatStyles(newStyles) {
    let formattedStyles = BaseShapeItemView.prototype._formatStyles(newStyles);
    formattedStyles.dash_array = this._convertToDashArray(
      newStyles.line_style,
      newStyles.weight,
    );
    formattedStyles.weight = newStyles.weight;
    formattedStyles.fill_color = formattedStyles.color;
    formattedStyles.fill_opacity = newStyles.fill_opacity;
    return formattedStyles;
  },
});
