import { useQuery } from 'react-query';
import { Api } from '@monorepo/shared/apiClient';
import {
  adminFetchOnboardingSession,
  AdminFetchOnboardingSessionVariables,
  AdminFetchOnboardingSessionResponse,
} from '../adminOnboardingApiClient';
import { createAdminOnboardingSessionKey } from './createAdminOnboardingSessionKey';

type FetchAdminOnboardingSessionQueryFn = Api.DataHookQueryFn<
  typeof createAdminOnboardingSessionKey,
  typeof adminFetchOnboardingSession
>;

const queryFn: FetchAdminOnboardingSessionQueryFn = (_, organizationId) =>
  adminFetchOnboardingSession({
    organizationId,
  });

type Input = Api.DataHookInput<
  AdminFetchOnboardingSessionVariables,
  AdminFetchOnboardingSessionResponse
>;

export function useAdminOnboardingSession(input: Input) {
  const key = Api.isDisabledDataHookInput(input)
    ? undefined
    : createAdminOnboardingSessionKey(input.variables);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line prefer-destructuring
  const config = input.config;

  const { data, ...queryInfo } = useQuery(key, queryFn, config);

  return {
    ...queryInfo,
    onboardingSession: data,
  };
}
