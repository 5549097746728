import React from 'react';
import cn from 'classnames';
import AcceptedIcon from '@material-ui/icons/Lock';
import FileIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/HighlightOff';
import { IconButton } from '@monorepo/old-web/js/components/elements';
import { OnboardingApi } from '../../apiClient';

interface OnboardingDocumentNameDisplayProps {
  downloadUrl: string;
  name: string;
  onClear: () => void;
  status: OnboardingApi.OnboardingDocumentStatus;
}

export function OnboardingDocumentNameDisplay(
  props: OnboardingDocumentNameDisplayProps,
) {
  const { name, status, downloadUrl, onClear } = props;
  return (
    <div
      className={cn('onboarding-document-name-display', {
        'action-required':
          status === OnboardingApi.OnboardingDocumentStatus.actionRequired,
        uploaded: status === OnboardingApi.OnboardingDocumentStatus.uploaded,
        accepted: status === OnboardingApi.OnboardingDocumentStatus.accepted,
      })}
    >
      <FileIcon className="left-icon" />
      <a className="name" href={downloadUrl} download>
        {name}
      </a>
      {status === OnboardingApi.OnboardingDocumentStatus.accepted ? (
        <div className="accepted right-icon">
          <AcceptedIcon />
        </div>
      ) : (
        <IconButton className="clear right-icon" onClick={onClear}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
}
