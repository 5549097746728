/* eslint-disable */
const BluebirdPromise = require('bluebird');

BluebirdPromise.config({
  warnings: {
    wForgottenReturn: false,
  },
});

Backbone.addSingletonToCollection = function (Collection, options) {
  if (!Collection._instance) {
    Collection._instance = new Collection([], options);
  }

  // return promise with fetched data
  Collection.fetchSingleton = function (options = {}) {
    return new BluebirdPromise(function (resolve, reject) {
      let fetchOptions = _.extend(
        {
          success: function (collection) {
            resolve(collection);
          },
          error: function (err) {
            reject(err);
          },
        },
        options,
      );

      Collection._instance.fetch(fetchOptions);
    });
  };

  Collection.singleton = function () {
    return Collection._instance;
  };
};

Backbone.addSingletonToView = function (View) {
  if (!View._instance) {
    View._instance = new View();
  }

  View.singleton = function () {
    return View._instance;
  };
};

(function () {
  // Include in an IIFE so originalDestroy isn't global
  let originalDestroy = Backbone.Model.prototype.destroy;

  Backbone.Model.prototype.destroy = function () {
    this.stopListening();
    return originalDestroy.apply(this, arguments);
  };
})();

Backbone.Collection.prototype.fetchPromise = function (options = {}) {
  return new BluebirdPromise(
    function (resolve, reject) {
      let fetchOptions = _.extend(options, {
        success: function (collection) {
          resolve(collection);
        },
        error: function (err) {
          reject(err);
        },
      });

      this.fetch(fetchOptions);
    }.bind(this),
  );
};

Backbone.Collection.prototype.createPromise = function (
  attributes,
  options = {},
) {
  return new BluebirdPromise(
    function (resolve, reject) {
      let createOptions = _.extend(options, {
        success: function (collection) {
          resolve(collection);
        },
        error: function (collection, response) {
          reject(response);
        },
      });

      this.create(attributes, createOptions);
    }.bind(this),
  );
};

Backbone.Model.prototype.fetchPromise = function (options = {}) {
  return new BluebirdPromise(
    function (resolve, reject) {
      let fetchOptions = _.extend(options, {
        success: function (model) {
          resolve(model);
        },
        error: function (model, response) {
          reject(response);
        },
      });

      this.fetch(fetchOptions);
    }.bind(this),
  );
};

Backbone.Model.prototype.savePromise = function (
  attributes = [],
  options = {},
) {
  return new BluebirdPromise(
    function (resolve, reject) {
      let saveOptions = _.extend(options, {
        success: function (model) {
          resolve(model);
        },
        error: function (err) {
          reject(err);
        },
      });

      this.save(attributes, saveOptions);
    }.bind(this),
  );
};
