var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (id, name) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"tag-type badge col-xs-11 margin-top-10 padding-10\""+pug.attr("data-id", id, true, true)) + "\u003E\u003Cspan class=\"editable-tag-name\" contenteditable=\"true\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca" + (" class=\"delete-tag font-size-16 white red-on-hover pull-right\""+" rel=\"tipsy\""+pug.attr("title", 'Delete ' + name, true, true)+pug.attr("data-name", name, true, true)) + "\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;