import React from 'react';
import { ComponentWrapper } from '../../../components/componentWrapper';
import { UserBulkUpload } from '../../../components/settings/UserBulkUpload';

const UserBulkUploadLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/userBulkUpload.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    content: '#user-bulk-upload',
  },

  onShow() {
    this.content.show(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      new ComponentWrapper(<UserBulkUpload />, { noStyling: true }),
    );
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UserBulkUploadLayoutView;
