import _ from 'underscore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CalendarName } from 'mapistry-shared';

import withProvider from '../../withProvider';
import APP from '../../../config';

import { objectToSortedArray } from '../../../utils/shared';
import { fetchProjectAction } from '../../../actions/project';
import { fetchCalendarFrequenciesAction } from '../../../actions/calendar';
import { fetchWastewaterSamplingParametersAction } from '../../../actions/wastewater';
import PHLogRecordPanel from './PHLogRecordPanel';

class PHLogRecordPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editParametersModalIsOpen: false,
      phCalibrationModalIsOpen: false,
      phLogModalIsOpen: false,
    };
    this.openPhCalibrationModal = this.openPhCalibrationModal.bind(this);
    this.openPhLogModal = this.openPhLogModal.bind(this);
  }

  componentDidMount() {
    const {
      fetchCalendarFrequencies,
      fetchProject,
      fetchWastewaterSamplingParameters,
      project,
    } = this.props;
    if (project.id) {
      fetchCalendarFrequencies(project, CalendarName.PH_LOG_READINGS);
      fetchCalendarFrequencies(project, CalendarName.PH_LOG_CALIBRATION);
      fetchWastewaterSamplingParameters();
    } else {
      fetchProject();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchCalendarFrequencies,
      fetchWastewaterSamplingParameters,
      project,
    } = this.props;
    if (_.isNull(prevProps.project.id) && !_.isNull(project.id)) {
      fetchCalendarFrequencies(project, CalendarName.PH_LOG_READINGS);
      fetchCalendarFrequencies(project, CalendarName.PH_LOG_CALIBRATION);
      fetchWastewaterSamplingParameters();
    }
  }

  openPhCalibrationModal(event, attachment) {
    this.setState({
      phCalibrationEventToEdit: event,
      phCalibrationAttachmentToEdit: attachment,
      phCalibrationModalIsOpen: true,
    });
  }

  openPhLogModal(event, attachment) {
    this.setState({
      phLogEventToEdit: event,
      phLogAttachmentToEdit: attachment,
      phLogModalIsOpen: true,
    });
  }

  render() {
    const {
      editParametersModalIsOpen,
      phCalibrationModalIsOpen,
      phCalibrationAttachmentToEdit,
      phCalibrationEventToEdit,
      phLogAttachmentToEdit,
      phLogEventToEdit,
      phLogModalIsOpen,
    } = this.state;
    const {
      fetchCalendarFrequencies,
      fetchWastewaterSamplingParameters,
      project,
      wastewaterParameters,
    } = this.props;
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <PHLogRecordPanel
        editParametersModalIsOpen={editParametersModalIsOpen}
        hasConfiguredPhSettings={
          wastewaterParameters.filter(
            // eslint-disable-next-line camelcase
            ({ parameter_slug }) => parameter_slug === 'ph',
          ).length > 0
        }
        onCloseEditParametersModal={() => {
          this.setState({ editParametersModalIsOpen: false }, () => {
            fetchCalendarFrequencies(project, CalendarName.PH_LOG_READINGS);
            fetchCalendarFrequencies(project, CalendarName.PH_LOG_CALIBRATION);
            fetchWastewaterSamplingParameters();
          });
        }}
        onClosePhCalibrationModal={() =>
          this.setState({ phCalibrationModalIsOpen: false })
        }
        onClosePhLogModal={() => this.setState({ phLogModalIsOpen: false })}
        onOpenEditParametersModal={() =>
          this.setState({ editParametersModalIsOpen: true })
        }
        onOpenPhCalibrationModal={this.openPhCalibrationModal}
        onOpenPhLogModal={this.openPhLogModal}
        phCalibrationModalIsOpen={phCalibrationModalIsOpen}
        phLogModalIsOpen={phLogModalIsOpen}
        phCalibrationAttachmentToEdit={phCalibrationAttachmentToEdit}
        phCalibrationEventToEdit={phCalibrationEventToEdit}
        phLogAttachmentToEdit={phLogAttachmentToEdit}
        phLogEventToEdit={phLogEventToEdit}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { project } = state;
  return {
    project,
    wastewaterParameters: objectToSortedArray(
      state.wastewater.parameters,
      'parameter_slug',
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCalendarFrequencies: (project, calendarName) =>
    dispatch(fetchCalendarFrequenciesAction(project.id, calendarName)),
  fetchProject: () => dispatch(fetchProjectAction(APP.projectId)),
  fetchWastewaterSamplingParameters: () =>
    dispatch(fetchWastewaterSamplingParametersAction(APP.projectId)),
});

PHLogRecordPanelContainer.propTypes = {
  fetchCalendarFrequencies: PropTypes.func.isRequired,
  fetchWastewaterSamplingParameters: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  project: PropTypes.shape({ id: PropTypes.string }),
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  wastewaterParameters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PHLogRecordPanelContainer.defaultProps = {
  project: {},
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(PHLogRecordPanelContainer),
);
