import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import { localEquivalentOfUTC, UTCEquivalentOfLocal } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import APP from '../../../../config';
import {
  Button,
  ConfirmDialog,
  DatePicker,
  ErrorMessage,
  FieldWrapper,
  MapistryTooltip,
  Modal,
  NoLocationsDefined,
  SaveButton,
  Select,
  TextField,
  TimePicker,
} from '../../../elements';

const PhLogModal = (props) => {
  const {
    addRecordLocation,
    errors,
    formSaveState,
    formSubmissionDraft,
    goToMapsURL,
    monitoringLocations,
    onCancelDirtyCloseConfirmation,
    onClose,
    onCloseErrorMessage,
    onConfirmDirtyCloseConfirmation,
    open,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    originalReadingUserId,
    removeRecordLocationAtIdx,
    saveError,
    shouldDisableAddLocation,
    shouldShowDirtyCloseConfirmation,
    stageEdit,
    submitForm,
  } = props;

  const { userOptions: USER_OPTIONS } = useProjectUserOptions({
    projectId: APP.projectId,
    previouslySavedUserId: originalReadingUserId,
  });

  const MONITORING_LOCATION_OPTIONS = monitoringLocations.map((ml) => ({
    label: ml.name,
    value: ml.id,
  }));

  const PHTYPE_OPTIONS = [
    { value: 'phMeter', label: 'pH meter' },
    { value: 'litmusPaper', label: 'Litmus paper' },
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-unstable-nested-components
  const Errors = () => (
    <div className="section-errors">
      <div className="b pb3">Oops! There are errors in your form:</div>
      <ul className="error-list">
        {_.map(errors.displayable, (e) => (
          <li key={e} className="error-list-item">
            {e}
          </li>
        ))}
      </ul>
    </div>
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-unstable-nested-components
  const DirtyDraftExitConfirmation = () => (
    <ConfirmDialog
      confirmButtonText="Close without saving"
      danger
      description="Are you sure you want to close without saving?"
      open={shouldShowDirtyCloseConfirmation}
      onCancelled={onCancelDirtyCloseConfirmation}
      onConfirmed={onConfirmDirtyCloseConfirmation}
      title="Confirm"
    />
  );

  return (
    <Modal
      footer={
        <div className="modal-footer__actions">
          <Button
            color="secondary"
            disabled={formSaveState === SaveState.SAVING}
            onClick={onClose}
          >
            {formSaveState === SaveState.DIRTY ? 'Cancel' : 'Close'}
          </Button>
          <span className="ph2" />
          <SaveButton onSave={submitForm} saveState={formSaveState} />
        </div>
      }
      header={<h4 className="modal-title">pH Log</h4>}
      fullWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className="ph-log-modal">
        {_.isEmpty(monitoringLocations) && (
          <NoLocationsDefined
            goToMapsURL={goToMapsURL}
            message="You must define all water monitoring locations before configuring the water pH log."
          />
        )}
        <DirtyDraftExitConfirmation />
        {saveError && (
          <ErrorMessage
            errorMessage={saveError}
            onClose={onCloseErrorMessage}
          />
        )}
        {!_.isEmpty(monitoringLocations) && (
          <>
            {_get(errors, 'displayable', []).length > 0 && <Errors />}
            <div className="section-ph-log-details">
              <div className="field-wrapper">
                <FieldWrapper label="Log date" isRequired>
                  <DatePicker
                    autoOk
                    className="date-field"
                    clearable
                    error={!_.isUndefined(errors.logDate)}
                    maxDate={new Date()}
                    onChange={(date) =>
                      stageEdit(
                        'logDate',
                        UTCEquivalentOfLocal(new Date(date.setHours(0, 0, 0))),
                      )
                    }
                    value={localEquivalentOfUTC(formSubmissionDraft.logDate)}
                  />
                </FieldWrapper>
              </div>
              <div className="field-wrapper">
                <FieldWrapper label="Log time" isRequired>
                  <TimePicker
                    autoOk
                    className="time-field"
                    clearable
                    error={!_.isUndefined(errors.logTime)}
                    onChange={(time) => stageEdit('logTime', time)}
                    value={formSubmissionDraft.logTime}
                  />
                </FieldWrapper>
              </div>
              <div className="field-wrapper">
                <FieldWrapper label="Person reading pH meter" isRequired>
                  <Select
                    error={!_.isUndefined(errors.userId)}
                    isClearable={false}
                    options={USER_OPTIONS}
                    onChange={(opt) => stageEdit('userId', opt.value)}
                    value={_.find(
                      USER_OPTIONS,
                      (o) => o.value === formSubmissionDraft.userId,
                    )}
                  />
                </FieldWrapper>
              </div>
            </div>
            <div className="section-ph-log-location">
              <div className="row-header">
                <div>
                  Monitoring Location
                  <span className="required-label">(required)</span>
                </div>
                <div>
                  Type
                  <span className="required-label">(required)</span>
                </div>
                <div>
                  pH Reading
                  <span className="required-label">(required)</span>
                </div>
              </div>
              {_.map(formSubmissionDraft.recordsLocations, (rl, idx) => (
                <div className="row-details" key={rl.locationId || idx}>
                  <div className="field-wrapper">
                    <FieldWrapper label="Monitoring Location" isRequired>
                      <Select
                        error={
                          !!_get(errors, `recordsLocations[${idx}].locationId`)
                        }
                        isClearable={false}
                        isFixed
                        options={MONITORING_LOCATION_OPTIONS}
                        onChange={(opt) =>
                          stageEdit('locationId', opt.value, idx)
                        }
                        value={_.find(
                          MONITORING_LOCATION_OPTIONS,
                          (o) =>
                            o.value ===
                            formSubmissionDraft.recordsLocations[idx]
                              .locationId,
                        )}
                      />
                    </FieldWrapper>
                  </div>
                  <div className="field-wrapper">
                    <FieldWrapper label="Type" isRequired>
                      <Select
                        error={
                          !!_get(errors, `recordsLocations[${idx}].phType`)
                        }
                        isClearable={false}
                        options={PHTYPE_OPTIONS}
                        onChange={(opt) => stageEdit('phType', opt.value, idx)}
                        value={_.find(
                          PHTYPE_OPTIONS,
                          (o) =>
                            o.value ===
                            formSubmissionDraft.recordsLocations[idx].phType,
                        )}
                      />
                    </FieldWrapper>
                  </div>
                  <div className="field-wrapper">
                    <FieldWrapper label="pH Reading" isRequired>
                      <TextField
                        controlled={false}
                        error={
                          !!_get(errors, `recordsLocations[${idx}].phReading`)
                        }
                        defaultValue={
                          formSubmissionDraft.recordsLocations[idx].phReading ||
                          ''
                        }
                        onBlur={(e) =>
                          stageEdit('phReading', e.target.value, idx)
                        }
                      />
                    </FieldWrapper>
                  </div>
                  <div className="row-delete">
                    <Button
                      color="danger"
                      className="button-delete"
                      onClick={() => {
                        removeRecordLocationAtIdx(idx);
                      }}
                    >
                      Delete section
                    </Button>
                    <MapistryTooltip
                      placement="top"
                      title="Delete this section"
                    >
                      {/* TODO: Fix this the next time the file is edited. */}
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div
                        className="icon-delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeRecordLocationAtIdx(idx);
                        }}
                      >
                        <SvgIcon
                          identifier="trash"
                          className="red hover-dark-red grow-large f5"
                        />
                      </div>
                    </MapistryTooltip>
                  </div>
                </div>
              ))}
              {!shouldDisableAddLocation && (
                <div className="dib pv3">
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="grow pointer green hover-dark-green flex items-center pl4"
                    onClick={addRecordLocation}
                  >
                    <SvgIcon identifier="add" />
                    <span className="pl3">Add a location</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

PhLogModal.propTypes = {
  addRecordLocation: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    displayable: PropTypes.bool,
    logDate: PropTypes.instanceOf(Date),
    logTime: PropTypes.instanceOf(Date),
    userId: PropTypes.string,
  }).isRequired,
  formSaveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  formSubmissionDraft: PropTypes.shape({
    logDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    logTime: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    recordsLocations: PropTypes.arrayOf(
      PropTypes.shape({
        locationId: PropTypes.string,
        phType: PropTypes.string,
        phReading: PropTypes.string,
      }),
    ),
    userId: PropTypes.string,
  }).isRequired,
  goToMapsURL: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCancelDirtyCloseConfirmation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
  onConfirmDirtyCloseConfirmation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  removeRecordLocationAtIdx: PropTypes.func.isRequired,
  saveError: PropTypes.string,
  shouldDisableAddLocation: PropTypes.bool.isRequired,
  shouldShowDirtyCloseConfirmation: PropTypes.bool.isRequired,
  stageEdit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

PhLogModal.defaultProps = {
  saveError: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PhLogModal;
