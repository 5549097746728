/* eslint-disable */
import Autolinker from 'autolinker';
import 'core-js/features/array/find-index';
import _ from 'underscore';
import attrStore from '../attrStore';
import {
  checkSwichableFields,
  clearSelect2,
  formatChemicalAttributes,
  getChemicalAttributes,
  isContainerDescriptionRequired,
  kvpsPropFilter,
  setChemicalAttributes,
  validateListAttr,
} from './utils';

var confirmView = require('../modals/confirmView').singleton();
var ChemicAttrsSelectView = require('./ChemicalAttrsSelectView');

var FeatureAttributesRowView = Backbone.View.extend({
  tagName: 'tr',
  className: 'attr-fields-container',
  template: require('./templates/attribute.pug'),

  events: {
    'click .delete': '_confirmDeleteAttr',
    'keypress .edit-name': '_handleEditNameKeypress',
    'keypress .edit-value': '_handleEditValueKeypress',
    'blur .edit-name': '_handleEditNameBlur',
    'blur .edit-value': '_handleEditValue',
    'select2:select .select-value': '_handleEditValue',
    'hidden.bs.popover': '_removePopovers',
    'change .chemical-attributes-select': '_checkChemicAttr',
    'change select[name="container_type"]': '_containerTypeChanged',
  },

  initialize: function (options) {
    this._parentView = options.parentView;
    this._$errorNode = this.$el.find('.alert');

    const popoverCheckList = this.generatePopoverCheckList();
    this.jsonModel = _.extend({ popoverCheckList }, this.model.toJSON());
  },

  render: function () {
    const templateKey = this.model.get('templateKey');
    if (this.jsonModel.isList) {
      const value = JSON.parse(this.model.get('value'));
      const listValues = this.jsonModel.listValues;
      const templateKey = this.model.get('templateKey');
      checkSwichableFields(value, listValues, templateKey);
    }

    this.$el.html(
      this.template(
        _.extend({}, this.jsonModel, this.templateHelpers(this.jsonModel)),
      ),
    );

    this._$nameNode = this.$('.name');
    this._$valueNode = this.$('.value');

    this.selectContainer = this.$el.find('.chemical-attributes-select');
    if (this.jsonModel.isList) {
      const value = JSON.parse(this.model.get('value'));
      const attributes = formatChemicalAttributes(templateKey)(
        getChemicalAttributes(value),
      );
      this.chemicAttrsSelectView = new ChemicAttrsSelectView({
        chemicalAttributes: attributes,
        isEdited: this.model.get('editMode'),
        templateKey: templateKey,
      }).render();
      this.selectContainer.append(this.chemicAttrsSelectView.el);
    }

    // Values may have links that we automagically turn into hyperlinks.
    // It's easier to do here rather than in the template.
    this._renderValue();

    // initialize popover
    this.$('.geolocated-popover').popover({ trigger: 'hover' });

    this._postRender();

    return this;
  },

  templateHelpers({ selectMap }) {
    return {
      formatMultiSelect: (multiSelect) => {
        if (selectMap.get(multiSelect.key)) {
          if (!Array.isArray(multiSelect.value)) {
            return selectMap.get(multiSelect.key)
              ? selectMap.get(multiSelect.key).get(multiSelect.value)
              : '';
          }
          return multiSelect.value
            .map((value) => selectMap.get(multiSelect.key).get(value))
            .join(', ');
        }
        return '';
      },
      isOptionSelected: (option, value) => {
        if (value) {
          if (Array.isArray(value)) {
            return value.indexOf(option.key) !== -1;
          } else {
            return option.key === value;
          }
        }
        return false;
      },
    };
  },

  generatePopoverCheckList() {
    return {
      container_description: true,
    };
  },

  _hideContainerDescription() {
    this.$el.find('[data-key="container_description"]').hide();
    this.$el
      .find('[data-key="container_description"] [name="container_description"]')
      .val('');
  },

  _containerTypeChanged(e) {
    if (!isContainerDescriptionRequired($(e.target).val())) {
      this._hideContainerDescription();
    } else {
      this.$el.find('[data-key="container_description"]').show();
    }
    this._handleEditValue(e);
  },

  _postRender() {
    const isEdited = this.model.get('editMode');
    const containerTypeField = _.find(
      this.jsonModel.listValues,
      (field) => field.key === 'container_type',
    );
    if (
      containerTypeField &&
      !isContainerDescriptionRequired(containerTypeField.value)
    ) {
      this._hideContainerDescription();
    }

    this.$el.find('[data-toggle="popover"]').popover();

    if (isEdited) {
      const width = '200px';
      this.$el.find('[data-toggle="multiselect"]').select2({
        placeholder: 'Select attributes',
        width,
        disabled: false,
      });
    }

    if (isEdited && this.jsonModel.type === 'select') {
      this.$el.find('.select-value').select2({
        width: '200px',
        tags: this.jsonModel.options,
        dropdownParent: this.$el,
      });
    }
  },

  /**
   * Turn any links in value field into hyperlinks
   */
  _renderValue: function () {
    if (!this.model.has('value')) {
      return;
    }

    let value = this.model.get('value').toString();

    this._$valueNode.find('.attr-cell').html(Autolinker.link(value));
  },

  _renderEditInput: function (name) {
    var input = templatizer.main.modals.editFeature.featureAttributes.input;
    var data = {
      name: name,
      value: this.model.get(name),
      editType: 'edit-' + name,
    };

    return input(data);
  },

  /**
   * After the popovers are hidden, we need to remove them from the DOM
   * otherwise Bootstrap keeps adding more of them to the DOM on each hover.
   * Seems like a bug with Bootstrap. If not removed, the leftover popovers
   * cause the tabs in the modal not to work because they rest invisibly over
   * the tabs and prevent them from being clicked.
   */
  _removePopovers: function () {
    this._parentView.$('.popover').remove();
  },

  _confirmDeleteAttr: function (e) {
    var options = {
      text: 'the ' + this.model.get('name') + ' attribute?',
      confirmClicked: this._deleteAttr.bind(this),
      useSpinner: false,
    };

    confirmView.showModal(options);

    if (e) {
      e.preventDefault();
    }
  },

  _deleteAttr: function () {
    this.model.get('valueModel').destroy();
  },

  _editAttr: function (e) {
    if (this.model.nameIsEditable()) {
      this._$nameNode.html(this._renderEditInput('name'));
    }

    this._$valueNode.html(this._renderEditInput('value'));

    e.preventDefault();
  },

  _handleEditNameKeypress: function (e) {
    if (e.which === 13) {
      this._handleEditNameBlur(e);
    }
  },

  _handleEditNameBlur: function (e) {
    var $editInput = $(e.target);
    var name = $editInput.val();

    // Don't do anything if input hasn't changed
    if (name.toLowerCase() === this.model.get('name').toLowerCase()) {
      return;
    }

    var valid = this._parentView.validateAttr({ name: name });

    if (valid) {
      this.model.get('attrModel').save({ name: name }, this._saveOptions());
    } else {
      $editInput.focus();
    }
  },

  _handleEditValueKeypress: function (e) {
    if (e.which === 13) {
      this._handleEditValue(e);
    }
  },

  _displayErrors: function (errors, validationEl) {
    var $errorsNode = $('<ul>');

    _.each(errors, function (msg) {
      $errorsNode.append('<li>' + msg + '</li>');
    });

    if ($errorsNode.find('li').length) {
      validationEl.html($errorsNode.html()).show();
    }
  },

  _handleEditValue: function (e) {
    const name = this.model.get('name');
    var $targetInput = $(e.target);
    var value = $targetInput.val();
    let oldValue = this.model.get('value');
    const isList = this.model.get('isList');
    const listWasFirstUse = isList && !oldValue;

    if (isList) {
      const templateKey = this.model.get('templateKey');
      const template = attrStore.getListKvps(templateKey);

      // If the model "template" does not have chemicalAttributes then
      // pull them from the model "value" and combine them with the template.
      if (!getChemicalAttributes(template)) {
        template.push({
          key: 'chemical-attrs',
          label: 'Chemical Attrs',
          value: getChemicalAttributes(JSON.parse(oldValue)),
        });
      }
      if (!oldValue) {
        oldValue = JSON.stringify(template);
      }
      const data = template;
      const containerEl = $targetInput.parents('.attr-fields-container');
      const inputEls = $('.attr-list-value', containerEl);
      inputEls.each((i, el) => {
        const key = el.name;
        const updateValue = $(el).val();
        const kvp = _.find(data, (kvp) => kvp.key === key);
        if (kvp) {
          kvp.value = updateValue;
          kvp.isShown = true;
        } else {
          data.push({
            key,
            value: updateValue,
            isShown: true,
          });
        }
      });

      const validationEl = $('.list-value-validations', containerEl);
      let errorMessages = validateListAttr(data);
      this.model.set('errorMessages', errorMessages);
      if (errorMessages && errorMessages.length) {
        validationEl.css('display', 'block');
        this._displayErrors(errorMessages, validationEl);
        return;
      }
      validationEl.css('display', 'none');

      value = JSON.stringify(data.map(kvpsPropFilter));
      this.jsonModel.listValues = this.jsonModel.listValues.map((val) => {
        const foundEl = JSON.parse(value).find(
          (field) => field.key === val.key,
        );
        if (foundEl) {
          val.value = foundEl.value;
        }
        return val;
      });
    }

    var valueIsTheSame =
      (value &&
        oldValue !== undefined &&
        typeof oldValue === 'number' &&
        +value === oldValue) ||
      (typeof oldValue === 'string' &&
        value.toLowerCase() === oldValue.toLowerCase());

    // Don't do anything if value hasn't changed
    if (valueIsTheSame) {
      return;
    } else {
      this.model.set('value', value);
    }

    // User edited the feature label
    if (this.model.has('isFeatureLabel')) {
      this._handleEditFeatureLabel($targetInput, name, value);
      return;
    }

    if (this.model.has('isBuildingName')) {
      this._handleEditBuildingName($targetInput, value);
      return;
    }

    // User edited a normal value attribute
    if (this.model.has('valueModel')) {
      this._handleEditValueAttr($targetInput, value);
      return;
    }

    // If this point is reached, the user added a suggested attribute value
    this._handleAddSuggestedAttr($targetInput, value);

    if (isList && listWasFirstUse) {
      this._parentView._activeEditMode({ preventDefault: () => {} });
      this._parentView.render();
    }
  },

  _handleEditFeatureLabel: function ($targetInput, name, value) {
    var valid = this._parentView.validateAttr({ name: name, value: value });

    if (!valid) {
      $targetInput.focus();
      return;
    }

    this.model.get('featureModel').save({ name: value }, this._saveOptions());
  },

  _handleEditBuildingName: function ($targetInput, value) {
    this.model
      .get('featureModel')
      .save({ building_name: value }, this._saveOptions());
  },

  _handleEditValueAttr: function ($targetInput, value) {
    if (this._isChangingNumericValueToText(value)) {
      this._handleChagedNumericValueToText($targetInput);
      return;
    }

    // User changed existing value to nothing
    if (value === '') {
      this._parentView.displayErrors('Value cannot be blank.');
      $targetInput.focus();
      return;
    }

    this.model.get('valueModel').save({ value: value }, this._saveOptions());
  },

  _handleAddSuggestedAttr: function ($targetInput, value) {
    if (this._isChangingNumericValueToText(value)) {
      this._handleChagedNumericValueToText($targetInput);
      return;
    }

    var attrId = this.model.get('attrModel').id;
    this.model.get('featureModel').addAttrValue(value, attrId);
  },

  _isChangingNumericValueToText: function (value) {
    return this.model.get('isNumeric') && _.isNaN(+value);
  },

  _handleChagedNumericValueToText: function ($targetInput) {
    this._parentView.displayErrors('Value must be numeric.');
    $targetInput.focus();
  },

  _saveOptions: function () {
    var _this = this;

    return {
      success: function () {
        _this._parentView.handleAttrChange();
      },
      error: function (model, response) {
        var errors = [];

        _.values(response.responseJSON).forEach(function (errorArray) {
          errors = errors.concat(errorArray);
        });

        _this._parentView.displayErrors(errors);
      },
    };
  },

  _isEditInputActive: function () {
    var $activeElement = $(document.activeElement);

    return $activeElement.is('.edit-name') || $activeElement.is('.edit-value');
  },

  switchFields: function () {
    setTimeout(() => {
      clearSelect2.bind(this)();
      this.render();
    });
  },

  _checkChemicAttr: function (e) {
    let value = JSON.parse(this.model.get('value'));
    let attributes = getChemicalAttributes(value);
    attributes = setChemicalAttributes(
      attributes,
      value,
      this.model.get('templateKey'),
    );

    const currentValues = JSON.parse(
      this.chemicAttrsSelectView.getCurrentValue(),
    );

    if (currentValues) {
      attributes.forEach((attr, index, source) => {
        const foundIndex = currentValues.findIndex((item) => item === attr.key);
        if (foundIndex !== -1) {
          source[index].selected = true;

          if (attr.switchable) {
            this.switchFields();
          }
        } else if (attr.selected === true) {
          source[index].selected = false;

          if (attr.switchable) {
            this.switchFields();
          }
        }
      });
    } else {
      attributes.forEach((attr) => {
        attr.selected = false;

        if (attr.switchable) {
          this.switchFields();
        }
      });
    }
    value = value.map((val) => {
      const foundField = this.jsonModel.listValues.find(
        (field) => field.key === val.key,
      );
      if (foundField) {
        val.value = foundField.value;
      }

      return val;
    });
    this.model.set({ value: JSON.stringify(value) });
    this.model
      .get('valueModel')
      .save({ value: JSON.stringify(value) }, this._saveOptions());
  },
});

module.exports = FeatureAttributesRowView;
