var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isEmpty, isPHLinked, parameterSlug) {
      pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-success\"\u003E\u003Cp class=\"message\"\u003ESuccess\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\" style=\"padding-top: 10px\"\u003E\u003Cdiv class=\"col-xs-2 text-center\"\u003E\u003Clabel\u003ELocation\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-2\"\u003E\u003Clabel\u003EParameter Value\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-2\"\u003E\u003Clabel\u003EMethod Used\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
if (parameterSlug != 'ph') {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E\u003Clabel\u003EReporting Limit\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-2\"\u003E\u003Clabel\u003EMethod Detection Limit\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E\u003Clabel\u003EAnalysis Date\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"location-rows\"\u003E\u003C\u002Fdiv\u003E";
if ((parameterSlug !== 'ph' || !isPHLinked) && !isEmpty) {
pug_html = pug_html + "\u003Cdiv class=\"row\" style=\"padding: 10px; margin-right: 0px\"\u003E\u003Cdiv class=\"col-xs-1 col-xs-offset-11\"\u003E\u003Cdiv class=\"btn btn-success save-location\"\u003ESave\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "isEmpty" in locals_for_with ?
        locals_for_with.isEmpty :
        typeof isEmpty !== 'undefined' ? isEmpty : undefined, "isPHLinked" in locals_for_with ?
        locals_for_with.isPHLinked :
        typeof isPHLinked !== 'undefined' ? isPHLinked : undefined, "parameterSlug" in locals_for_with ?
        locals_for_with.parameterSlug :
        typeof parameterSlug !== 'undefined' ? parameterSlug : undefined));
    ;;return pug_html;};
module.exports = template;