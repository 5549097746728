import { connect } from 'react-redux';
import _get from 'lodash.get';

import withProvider from '../../../withProvider';
import RecordPanelCalendarAccordion from './RecordPanelCalendarAccordion';

const mapStateToProps = (state, ownProps) => {
  const parentIntervals = _get(
    state.calendar.parentIntervals,
    ownProps.calendarName,
    {},
  );
  return {
    ...ownProps,
    parentIntervals,
    microIntervals: state.calendar.microIntervals,
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps)(RecordPanelCalendarAccordion),
);
