var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (label, radioButtons) {
      pug_html = pug_html + "\u003Ch4\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003Cdiv class=\"checkbox\"\u003E\u003Cinput type=\"checkbox\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"radio\"\u003E";
// iterate radioButtons
;(function(){
  var $$obj = radioButtons;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var btn = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"padding-right-20 padding-bottom-10\"\u003E\u003Cinput" + (" type=\"radio\""+pug.attr("name", btn.group, true, true)+pug.attr("value", btn.slug, true, true)+pug.attr("id", btn.slug, true, true)+pug.attr("checked", btn.id !== null, true, true)) + "\u003E\u003Clabel" + (pug.attr("for", btn.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = btn.text) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var btn = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"padding-right-20 padding-bottom-10\"\u003E\u003Cinput" + (" type=\"radio\""+pug.attr("name", btn.group, true, true)+pug.attr("value", btn.slug, true, true)+pug.attr("id", btn.slug, true, true)+pug.attr("checked", btn.id !== null, true, true)) + "\u003E\u003Clabel" + (pug.attr("for", btn.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = btn.text) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "radioButtons" in locals_for_with ?
        locals_for_with.radioButtons :
        typeof radioButtons !== 'undefined' ? radioButtons : undefined));
    ;;return pug_html;};
module.exports = template;