/* eslint-disable */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { useMountEffect } from '@monorepo/shared/hooks/useMountEffect';
import { fetchWastewaterParameterLimitCalculationsAction } from '../../../../actions/wastewater';
import withProvider from '../../../withProvider';
import SamplingResultFormParameterLimitCalculation from './SamplingResultFormParameterLimitCalculation';
import SamplingResultFormParameterLocation from './SamplingResultFormParameterLocation';

const allSamplingUnits = SamplingUnits.getAll();
function getAvailableUnits(parameterSlug) {
  const parameterUnitSlug = SamplingParameters.getUnits(parameterSlug);
  if (!parameterUnitSlug) return [];

  const parameterUnit = allSamplingUnits.find(
    (u) => u.slug === parameterUnitSlug,
  );
  const relatedUnits = parameterUnit.measure
    ? allSamplingUnits.filter((u) => u.measure === parameterUnit.measure)
    : [parameterUnit];
  return relatedUnits.map((u) => ({
    displayText: u.display_text,
    slug: u.slug,
  }));
}

function SamplingResultFormParameter({
  analysisMethods,
  fetchWastewaterParameterLimitCalculations,
  isFetchingLimits,
  limitCalculationsForParameter,
  monitoringLocations,
  onChange,
  onDelete,
  projectId,
  samplingEventId,
  showMethodSelect,
  wastewaterParameter,
  wastewaterSamplingResults,
}) {
  useMountEffect(() => {
    if (
      samplingEventId &&
      !limitCalculationsForParameter &&
      !isFetchingLimits
    ) {
      fetchWastewaterParameterLimitCalculations(
        projectId,
        wastewaterParameter.parameter_slug,
        samplingEventId,
      );
    }
  });

  const availableUnits = useMemo(
    () => getAvailableUnits(wastewaterParameter.parameter_slug),
    [wastewaterParameter.parameter_slug],
  );
  const locationName = (id) =>
    monitoringLocations.find((location) => location.id === id).name;
  const hasErrors = wastewaterSamplingResults.some(
    (result) => result.errors != null && result.errors.length > 0,
  );
  const resultErrorToComponent = (result, error) => (
    <div key={`error-${result.locationId}-${error.id}`}>
      {locationName(result.locationId)}
      {': '}
      {error.errorMessage}
    </div>
  );
  const errorComponents = !hasErrors
    ? []
    : wastewaterSamplingResults
        .filter((result) => result.errors != null)
        .map((result) =>
          result.errors.map((error) => resultErrorToComponent(result, error)),
        )
        .reduce((accum, elements) => [...accum, ...elements], []);

  const locationComponents = monitoringLocations.map((location) => {
    const {
      id: samplingResultId,
      analysisDate,
      detectionLimit,
      errors,
      methodSlug,
      methodText,
      parameterValue,
      reportingLimit,
      units,
    } = {
      analysisDate: null,
      detectionLimit: null,
      errors: [],
      methodSlug: null,
      methodText: null,
      parameterValue: null,
      reportingLimit: null,
      ...wastewaterSamplingResults.find(
        (result) => result.locationId === location.id,
      ),
    };

    const limitCalculationsForLocation =
      limitCalculationsForParameter?.[location.id]?.limitCalculations || [];

    const limitCalculationComponents = limitCalculationsForLocation.map(
      (limitCalculation) => {
        const {
          calculationLabel,
          calculationValue,
          limitLower,
          limitUpper,
          units: limitUnits,
          warning,
        } = {
          calculationLabel: null,
          calculationValue: null,
          limitLower: null,
          limitUpper: null,
          units: null,
          ...limitCalculation,
        };
        return (
          <SamplingResultFormParameterLimitCalculation
            key={
              `ww-sampling-result-limit-calculation-${wastewaterParameter.parameter_slug}-${location.id}` +
              `-${limitCalculation.calculationLabel}--${limitCalculation.units}` +
              `--${limitCalculation.limitUpper}--${limitCalculation.limitLower}`
            }
            calculationLabel={calculationLabel}
            calculationValue={calculationValue}
            limitLower={limitLower}
            limitType={wastewaterParameter.limitType}
            limitUpper={limitUpper}
            units={limitUnits}
            warning={warning}
          />
        );
      },
    );
    const children = limitCalculationComponents.length > 0 && (
      <div className="ww-limit-calculation-container">
        {limitCalculationComponents}
      </div>
    );
    return (
      <SamplingResultFormParameterLocation
        key={`ww-sampling-result-${wastewaterParameter.parameter_slug}-${location.id}`}
        analysisDate={analysisDate}
        analysisMethods={analysisMethods}
        availableUnits={availableUnits}
        detectionLimit={detectionLimit}
        errors={errors}
        locationName={location.name}
        methodSlug={methodSlug}
        methodText={methodText}
        onDelete={() => onDelete(samplingResultId)}
        onChange={(payload) =>
          onChange({
            ...payload,
            locationId: location.id,
            parameterSlug: wastewaterParameter.parameter_slug,
            samplingResultId,
          })
        }
        parameterValue={parameterValue}
        projectId={projectId}
        reportingLimit={reportingLimit}
        samplingResultId={samplingResultId}
        showMethodSelect={showMethodSelect}
        units={units}
      >
        {children}
      </SamplingResultFormParameterLocation>
    );
  });

  /* eslint-disable jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */
  return (
    <div className="parameter-table">
      <div className="result-headings">
        <label>LOCATION</label>
        <label className="parameter-value-column">PARAMETER VALUE</label>
        <label>METHOD USED</label>
        <label className="limit-column">REPORTING LIMIT</label>
        <label className="limit-column">METHOD DETECTION LIMIT</label>
        <label>ANALYSIS DATE</label>
        <label className="delete-column">DELETE</label>
      </div>
      <div className={`error-row ${hasErrors ? 'error-row-shown' : ''}`}>
        {errorComponents.length > 0 && (
          <>
            <label>These are validation errors:</label>
            {errorComponents}
          </>
        )}
      </div>
      <div className="locations-container">{locationComponents}</div>
    </div>
  );
}

SamplingResultFormParameter.defaultProps = {
  limitCalculationsForParameter: null,
  samplingEventId: null,
};

SamplingResultFormParameter.propTypes = {
  analysisMethods: PropTypes.arrayOf(
    PropTypes.shape({
      display_text: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  fetchWastewaterParameterLimitCalculations: PropTypes.func.isRequired,
  limitCalculationsForParameter: PropTypes.object,
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  samplingEventId: PropTypes.string,
  showMethodSelect: PropTypes.bool.isRequired,
  wastewaterParameter: PropTypes.object.isRequired,
  wastewaterSamplingResults: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { samplingEventId, wastewaterParameter } = ownProps;
  const parameterSlug = wastewaterParameter.parameter_slug;
  const {
    wastewater: { isFetching, limitCalculations },
  } = state;

  const limitCalculationsForParameter = limitCalculations.find(
    (l) =>
      l.parameterSlug === parameterSlug &&
      l.samplingEventId === samplingEventId,
  )?.limitCalculations;
  return {
    isFetchingLimits:
      isFetching?.[`limitCalculation-${samplingEventId}-${parameterSlug}`] ??
      false,
    limitCalculationsForParameter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchWastewaterParameterLimitCalculations: (
    projectId,
    parameterSlug,
    samplingEventId,
  ) =>
    dispatch(
      fetchWastewaterParameterLimitCalculationsAction(
        projectId,
        parameterSlug,
        samplingEventId,
      ),
    ),
});

export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(SamplingResultFormParameter),
);
