/* eslint-disable */
import attrStore from '../attrStore';

var ChemicalAttrsSelect = Backbone.Marionette.ItemView.extend({
  className: 'form-group',

  template: require('./templates/chemicalAttrsSelect.pug'),

  ui: {
    $select: 'select',
  },

  onRender() {
    const attributes =
      this.options.chemicalAttributes ||
      attrStore.getListChemicalAttributes(this.options.templateKey);
    const isEdited = this.options.isEdited;
    const disabled = this.options.disabled;

    let data = attributes.map((item) => {
      return {
        id: item.key,
        text: item.text,
        selected: item.selected,
      };
    });

    this.ui.$select.attr('id', this.makeid());
    this.ui.$select.select2({
      placeholder: isEdited ? 'Select attributes' : 'No attributes selected',
      data: data,
      width: '200px',
      disabled: isEdited ? !isEdited : disabled ? disabled : true,
    });

    // Hack to fix placeholder not showing on initial load
    this.$('.select2-search--inline').css({ width: '170px' });
    this.$('.select2-search__field').css({ width: '170px' });
  },

  getCurrentValue() {
    return JSON.stringify(this.ui.$select.val());
  },

  makeid() {
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },
});

module.exports = ChemicalAttrsSelect;
