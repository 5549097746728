/* eslint-disable */
import { enforceAuthentication } from './loader';
const BluebirdPromise = require('bluebird');
let errors = require('./errors');

let BaseController = Backbone.Marionette.Controller.extend({
  enforceAuthentication() {
    return enforceAuthentication();
  },

  handleError(e) {
    // Ignore cancellation errors
    // I don't think we actually cancel any promises anywhere
    if (e instanceof BluebirdPromise.CancellationError) {
      return;
    }

    console.log('Controller error', e);
    let router = require('./router');

    if (e instanceof errors.AuthenticationError) {
      router.goToLoginPage();
    } else if (e instanceof errors.UnauthorizedError) {
      router.goToRootUrl();
    } else if (e instanceof errors.PageNotFoundError) {
      router.goToErrorPage(404);
    } else if (e instanceof errors.GenericError) {
      router.goToErrorPage(null, e.message);
    } else if (e instanceof errors.RecoveryQuestionNotSetError) {
      router.goToSetRecoveryQuestion();
    } else {
      router.goToErrorPage(500);
    }
  },
});

module.exports = BaseController;
