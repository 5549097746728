/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/noSpccUpdates.pug'),

  initialize(options) {
    this.planName = options.planName;
  },

  templateHelpers() {
    return {
      planName: this.planName,
    };
  },
});
