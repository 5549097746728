/* eslint-disable */
var APP = require('../../config');

var BaseDisplaySettingCollection = Backbone.Collection.extend({
  initialize: function (models, attrs) {
    if (!attrs || !attrs.projectId || !attrs.mapId || !attrs.type) {
      throw new Error(
        'Must pass projectId, mapId and type into ' +
          attrs.type +
          'DisplaySettingCollection',
      );
    }

    this._projectId = attrs.projectId;
    this._mapId = attrs.mapId;
    this.type = attrs.type;
  },

  url: function () {
    return (
      APP.apiUrl +
      '/projects/' +
      this._projectId +
      '/maps/' +
      this._mapId +
      '/' +
      this.type +
      'DisplaySettings'
    );
  },

  fetch: function (options = {}) {
    options.cache = false;
    options.wait = true;

    return Backbone.Collection.prototype.fetch.call(this, options);
  },
});

module.exports = BaseDisplaySettingCollection;
