import React from 'react';
import { CustomFrequency, GenericLogLoggedItemType } from 'mapistry-shared';
import SignatureCell from './SignatureCell';
import StatisticsCell from './StatisticsCell';
import TextCell from './TextCell';
import UserCell from './UserCell';

type LogTableCellFactoryProps = {
  loggedItems: {
    id: string;
    itemType: string;
  }[];
  frequency?: string;
  customFrequency?: CustomFrequency;
  statistic: {
    resourceId: string;
    totalLoggedValue?: number;
  };
};

const LogTableCellFactory = (props: LogTableCellFactoryProps) => {
  const { frequency, customFrequency, loggedItems, statistic } = props;
  const { resourceId, totalLoggedValue } = statistic;
  const loggedItem = loggedItems.find((li) => li.id === resourceId);

  if (!loggedItem) {
    if (totalLoggedValue && totalLoggedValue >= 0) {
      // This is a calculated value.
      return (
        <StatisticsCell
          frequency={frequency}
          customFrequency={customFrequency}
          statistic={statistic}
        />
      );
    }
    return <TextCell statistic={null} />;
  }

  switch (loggedItem.itemType) {
    case GenericLogLoggedItemType.NUMBER:
      return (
        <StatisticsCell
          frequency={frequency}
          customFrequency={customFrequency}
          statistic={statistic}
        />
      );
    case GenericLogLoggedItemType.BOOLEAN:
    case GenericLogLoggedItemType.DATE:
    case GenericLogLoggedItemType.SINGLE_SELECT:
    case GenericLogLoggedItemType.TEXT:
    case GenericLogLoggedItemType.TIME:
      return <TextCell statistic={statistic} />;
    case GenericLogLoggedItemType.SIGNATURE:
      return <SignatureCell statistic={statistic} />;
    case GenericLogLoggedItemType.USER:
      return <UserCell statistic={statistic} />;
    default:
      return <TextCell statistic={null} />;
  }
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LogTableCellFactory;
