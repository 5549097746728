/* eslint-disable */
var jqueryOverrides = function () {
  // Make sure AJAX requests are not cached
  $.ajaxSetup({ cache: false });

  /*
   * General purpose function for serializing objects
   * http://stackoverflow.com/questions/1184624/convert-form-data-to-js-object-with-jquery
   */
  $.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  $.ajaxSetup({ xhrFields: { withCredentials: true } });
};

module.exports = jqueryOverrides;
