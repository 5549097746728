var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (header, isDischargeLocation, rotation, showSpinner) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade above-trip-overlay\" id=\"edit-feature-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"new-feature-modal\" aria-hidden=\"true\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E×\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\" id=\"edit-feature-styles-modal-label\"\u003E" + (pug.escape(null == (pug_interp = header) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E";
if (showSpinner) {
pug_html = pug_html + "\u003Cimg id=\"edit-feature-modal-spinnner\" src=\"https:\u002F\u002Fs3.amazonaws.com\u002FmapistryAssets\u002Fgreen-spinner.gif\"\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"show-layer-modal\"\u003EClick here if you are looking to edit layer details or styles. &nbsp;\u003Ci class=\"fa fa-external-link\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Cdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"padding-right-10\" for=\"#feature-rotation\"\u003ESymbol\u002FLabel Rotation\u003C\u002Flabel\u003E\u003Cselect class=\"form-control\" id=\"feature-rotation\" name=\"rotation\"\u003E\u003Coption" + (" value=\"0\""+pug.attr("selected", rotation == 0, true, true)) + "\u003E&#8593; (0 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"45\""+pug.attr("selected", rotation == 45, true, true)) + "\u003E&#8599; (45 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"90\""+pug.attr("selected", rotation == 90, true, true)) + "\u003E&#8594; (90 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"135\""+pug.attr("selected", rotation == 135, true, true)) + "\u003E&#8600; (135 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"180\""+pug.attr("selected", rotation == 180, true, true)) + "\u003E&#8595; (180 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"225\""+pug.attr("selected", rotation == 225, true, true)) + "\u003E&#8601; (225 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"270\""+pug.attr("selected", rotation == 270, true, true)) + "\u003E&#8592; (270 Degrees)\u003C\u002Foption\u003E\u003Coption" + (" value=\"315\""+pug.attr("selected", rotation == 315, true, true)) + "\u003E&#8598; (315 Degrees)\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
if (isDischargeLocation) {
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"padding-right-10\" for=\"#feature-sample-type\"\u003E\u003Cdiv\u003EAdditional Sample Types\u003C\u002Fdiv\u003E\u003Cdiv style=\"font-weight: 400\"\u003EWhat other sampling purposes you will use this location for?\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003Cselect class=\"form-control\" id=\"feature-sample-type\" name=\"sample-type\" multiple\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Chr\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"feature-attrs\"\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E";
if (isDischargeLocation) {
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"padding-right-10\" for=\"#feature-aliases\"\u003E\u003Cdiv\u003EFeature Name Aliases\u003C\u002Fdiv\u003E\u003Cdiv style=\"font-weight: 400\"\u003EAdd alternative names for this discharge location. Aliases can improve the automatic matching of discharge locations in Mapistry to the name your lab gives to your discharge locations, which can prevent human error when uploading lab data.\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003Cselect class=\"form-control\" id=\"feature-aliases\" name=\"aliases\" multiple\u003E\u003Coption\u003E\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Chr\u003E\u003Cdiv id=\"features-photos-container\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-danger btn-delete pull-left\"\u003EDelete\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-success btn-done\" type=\"submit\" data-id=\"close\"\u003EDone\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"feature-photo-overlay-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "header" in locals_for_with ?
        locals_for_with.header :
        typeof header !== 'undefined' ? header : undefined, "isDischargeLocation" in locals_for_with ?
        locals_for_with.isDischargeLocation :
        typeof isDischargeLocation !== 'undefined' ? isDischargeLocation : undefined, "rotation" in locals_for_with ?
        locals_for_with.rotation :
        typeof rotation !== 'undefined' ? rotation : undefined, "showSpinner" in locals_for_with ?
        locals_for_with.showSpinner :
        typeof showSpinner !== 'undefined' ? showSpinner : undefined));
    ;;return pug_html;};
module.exports = template;