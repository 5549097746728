/* eslint-disable */
let EnableDatetimepicker = Backbone.Marionette.Behavior.extend({
  defaults: {
    autoclose: true,
  },

  onRender: function () {
    this.ui.$datetimepicker.datetimepicker(this.options);

    if (this.options.changeDate) {
      this.view.delegateEvents(
        _.extend(
          { 'changeDate @ui.$datetimepicker': this.options.changeDate },
          this.view.events,
        ),
      );
    }

    // Make sure $datetimepicker doesn't bubble up show.bs.tab event
    this.ui.$datetimepicker.on('show.bs.tab', function (e) {
      e.stopPropagation();
    });
  },
});

module.exports = EnableDatetimepicker;
