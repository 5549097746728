/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/rowItemView.pug'),
  className: 'list-item-row',
  tagName: 'tr',

  initialize() {},

  templateHelpers() {
    return {
      location: this.model.get('feature_name'),
      kvps: this.model.get('value'),
    };
  },
});
