var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (date_updated, update_notes) {
      pug_html = pug_html + "\u003Cb\u003EDate Changes Made: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = date_updated) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr\u003E\u003Cb\u003ENotes: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = update_notes || '[No notes were recorded]') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }.call(this, "date_updated" in locals_for_with ?
        locals_for_with.date_updated :
        typeof date_updated !== 'undefined' ? date_updated : undefined, "update_notes" in locals_for_with ?
        locals_for_with.update_notes :
        typeof update_notes !== 'undefined' ? update_notes : undefined));
    ;;return pug_html;};
module.exports = template;