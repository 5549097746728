import { useWastewaterSamplingParameterWithDisplayText } from '@monorepo/old-web/js/hooks/water/useWastewaterSamplingParameters';
import { Loading } from '@monorepo/shared/components';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useWastewaterMonitoringLocation } from '@monorepo/shared/hooks/useMapFeatures';
import { format } from 'date-fns';
import {
  CalendarEventStatus,
  DateLike,
  localEquivalentOfUTC,
} from 'mapistry-shared';
import React, { useState } from 'react';
import { useWastewaterSamplingEvents } from '../../../../hooks/water/useWastewaterSamplingEvents';
import { CalendarCardWithoutProvider } from '../CalendarCard';
import NeverEventCalendar from '../CalendarCard/NeverEventCalendar';
import { WaterSamplingEventChoiceMenu } from './WaterSamplingEventChoiceMenu';

export const WastewaterParameterLocationCalendarCard = ({
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  locationId,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  openResultsEditModal,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  parameterSlug,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  projectId,
}) => {
  const [currentDate, setCurrentDate] = useState<DateLike>();
  const [eventChoices, setEventChoices] = useState([]);
  const [eventChoiceTarget, setEventChoiceTarget] = useState();

  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(projectId);

  const { monitoringLocation, isLoading: locationIsLoading } =
    useWastewaterMonitoringLocation({ projectId, locationId });
  const {
    samplingParameterWithDisplayText,
    isLoading: samplingParameterIsLoading,
  } = useWastewaterSamplingParameterWithDisplayText({
    projectId,
    parameterSlug,
  });

  const { wastewaterSamplingCalendarEvents } = useWastewaterSamplingEvents({
    projectId,
    parameterSlug: `${parameterSlug},${locationId}`,
    date: currentDate,
    config: { keepPreviousData: true }, // to allow flipping back and forth between calendar pages
  });

  const handleFetchCalendarEvents = async (
    pId: string,
    calendarName: string,
    date: DateLike,
  ) => {
    // react query fetches on date change
    setCurrentDate(date);
  };

  const closeEventChoiceMenu = () => {
    setEventChoices([]);
    setEventChoiceTarget(undefined);
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const getNumberOfCompletedCalendarEvents = (calendarEvents): number =>
    calendarEvents.filter(
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      (event) => event.status === CalendarEventStatus.COMPLETE,
    ).length;

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const openCalendarEventModalWhenUserHasPermissions = (calendarEvent) => {
    const samplingEventToShow = calendarEvent.attachments[0];

    // people without update permission can open existing events to see them, but not add new ones
    if (!samplingEventToShow && !hasProjectUpdatePermission) {
      return;
    }

    openResultsEditModal(samplingEventToShow, samplingParameterWithDisplayText);
    closeEventChoiceMenu();
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const openEventChoiceMenu = (calendarEvents, menuTarget) => {
    setEventChoices(calendarEvents);
    setEventChoiceTarget(menuTarget);
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const handleEventClick = (calendarEvents, clickEvent) => {
    const numberOfCompletedCalendarEvents =
      getNumberOfCompletedCalendarEvents(calendarEvents);
    if (
      numberOfCompletedCalendarEvents === 0 ||
      (numberOfCompletedCalendarEvents === 1 && calendarEvents.length === 1) // click on a single item
    ) {
      openCalendarEventModalWhenUserHasPermissions(calendarEvents[0]);
    } else {
      openEventChoiceMenu(calendarEvents, clickEvent.currentTarget);
    }
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const tooltipText = (status, count, events) => {
    const eventDate = events[0].attachments[0]?.date;
    const addNewText = hasProjectUpdatePermission
      ? 'Create a sampling event'
      : 'You do not have permissions to add sampling events.';

    switch (status) {
      case CalendarEventStatus.COMPLETE:
        return count === 1
          ? `1 Completed sampling event: ${format(
              localEquivalentOfUTC(new Date(eventDate)),
              'MMM dd, HH:mm',
            )} `
          : `${count} Completed sampling events`;
      case CalendarEventStatus.OVERDUE:
        return count === 1
          ? '1 Missed sampling event'
          : `${count} Missed sampling events`;
      case CalendarEventStatus.UPCOMING:
        return count === 1
          ? '1 sampling event due soon'
          : `${count} sampling events due soon`;
      case CalendarEventStatus.AS_NEEDED:
      case CalendarEventStatus.EXTRA:
      case CalendarEventStatus.INCOMPLETE:
        return addNewText;
      case CalendarEventStatus.NOT_NEEDED:
        return 'Not Required';
      default:
        if (events[0].dueDate) {
          return count === 1
            ? '1 Future inspection'
            : `${count} Future inspections`;
        }
        return addNewText;
    }
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const tooltipTitle = (events) => {
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const statusCount = events.reduce((acc, event) => {
      const existingStatus = acc[event.status];
      if (existingStatus) {
        acc[event.status] = existingStatus + 1;
      } else {
        acc[event.status] = 1;
      }
      return acc;
    }, {});
    const statusCountKeys = Object.keys(statusCount);
    return statusCountKeys.reduce((acc: JSX.Element[], status) => {
      if (
        (status === CalendarEventStatus.AS_NEEDED ||
          status === CalendarEventStatus.INCOMPLETE) &&
        statusCountKeys.length > 1
      ) {
        return acc;
      }
      const count = statusCount[status];
      acc.push(<div key={status}>{tooltipText(status, count, events)}</div>);
      return acc;
    }, []);
  };

  if (
    locationIsLoading ||
    samplingParameterIsLoading ||
    !monitoringLocation ||
    !samplingParameterWithDisplayText
  ) {
    return (
      <div style={{ paddingTop: '50px' }}>
        <Loading size="medium" />
      </div>
    );
  }

  const calendarEventsForThisLocation =
    /* @ts-expect-error - old code does not play well with types */
    wastewaterSamplingCalendarEvents?.events?.[locationId]?.events;
  const intervalForThisLocation =
    /* @ts-expect-error - old code does not play well with types */
    wastewaterSamplingCalendarEvents?.events?.[locationId]?.interval;

  return (
    <>
      <CalendarCardWithoutProvider
        calendarName="wastewaterSampling"
        events={calendarEventsForThisLocation}
        currentInterval={intervalForThisLocation}
        fetchEvents={handleFetchCalendarEvents}
        helpTextForEvent={() => undefined}
        onEventClick={handleEventClick}
        projectId={projectId}
        title={`${samplingParameterWithDisplayText.displayText} — ${monitoringLocation.name}`}
        tooltipTitle={tooltipTitle}
        defaultView={
          <NeverEventCalendar calendarTitle="Water Sampling Result collections" />
        }
      />
      <WaterSamplingEventChoiceMenu
        eventChoices={eventChoices}
        hasProjectUpdatePermission={hasProjectUpdatePermission}
        menuAnchor={eventChoiceTarget}
        closeEventChoiceMenu={() => setEventChoiceTarget(undefined)}
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        onEventClick={(calendarEvent) =>
          handleEventClick([calendarEvent], undefined)
        }
      />
    </>
  );
};
