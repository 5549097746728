import React from 'react';
import { isValidDate } from '../../../../utils';
import TimePicker from '../../../elements/TimePicker';
import FormField from './FormField';

class TimeField extends FormField {
  constructor(props) {
    super(props);

    if (
      this.getValue() == null &&
      props.templateField.dynamicallyGenerateDefault
    ) {
      this.handleTimeChange(new Date());
    }
  }

  getInputComponent(templateField, error) {
    const { disabled } = this.props;

    return (
      <TimePicker
        autoOk
        className="time-field"
        clearable
        disabled={disabled}
        error={error}
        onChange={(date, textValue) => this.handleTimeChange(date, textValue)}
        value={this.getTimeValue()}
      />
    );
  }

  getTimeValue() {
    const fieldValue = this.getValue();

    if (fieldValue == null || !fieldValue.length) {
      return null;
    }

    const hours = fieldValue.substring(0, 2);
    const minutes = fieldValue.substring(2, 4);
    const value = new Date();
    try {
      value.setHours(parseInt(hours, 10));
      value.setMinutes(parseInt(minutes, 10));
      return value;
    } catch (e) {
      return null;
    }
  }

  handleTimeChange = (date, textValue) => {
    const { templateField, onChange } = this.props;
    if (date && isValidDate(date)) {
      const timeString = this.formatTimeValue(date);
      return onChange(templateField.slug, timeString);
    }
    if (date && !textValue.includes('_')) {
      return onChange(templateField.slug, '', ['Invalid time']);
    }
    return onChange(templateField.slug, '');
  };

  // this also exists on the backend when generating a default value
  formatTimeValue(date) {
    const zeroPaddedHours = `0${date.getHours()}`.slice(-2);
    const zeroPaddedMinutes = `0${date.getMinutes()}`.slice(-2);
    return `${zeroPaddedHours}${zeroPaddedMinutes}`;
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TimeField;
