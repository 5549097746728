/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/headerView.pug'),
  tagName: 'tr',

  initialize() {},

  templateHelpers() {
    return {
      kvps: this.model.get('value'),
    };
  },
});
