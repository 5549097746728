/* eslint-disable */
let _ = require('underscore');

var AlabamaPermitDataView = Backbone.Marionette.LayoutView.extend({
  className: 'row',
  template: require('./templates/alabamaPermitData.pug'),

  templateHelpers() {
    return {
      permitRecords: function () {
        var permitSlugs = this._getSelectedPermitSlugs();
        return this._getPermitRecords(permitSlugs);
      }.bind(this),
    };
  },

  regions: {
    permitSummaryTable: '#permit-summary-table-container',
    dsnChoices: '#dsn-choices-container',
    industrialActivityChoices: '#industrial-activity-choices-container',
  },

  ui: {
    $modal: '.modal',
    $permitSelect: 'select[name="permits"]',
    $form: 'form',
  },

  events: {
    'click .edit-permits': '_showModal',
    'click .btn-submit': '_saveSectorData',
    'hidden.bs.modal': 'render',
    'change @ui.$permitSelect': '_handleChangePermitSelect',
    'click .dsn-choices > div.checkbox > label > input[name="dsns"]':
      '_handleChangeDsnSelect',
  },

  modelEvents: {
    'change:permits': '_handleSectorDataChange',
    'change:industrial_activities': '_handleSectorDataChange',
  },

  initialize() {
    this.industrialStormwaterSectorData =
      require('industrial-stormwater-sector-data')(this.model.get('state'));
  },

  onShow() {
    if (!_.some(this.model.getPermitDsnSlugs())) {
      this._showModal();
    }
  },

  _showModal() {
    this._initPermitSelect();
    var permitSlugs = this._getSelectedPermitSlugs(
      this.model.getPermitDsnSlugs(),
    );

    if (_.some(permitSlugs)) {
      let currentPermitRecords = this._getPermitRecords(permitSlugs);
      let dsnRecords = this._getDsnRecords(currentPermitRecords);
      let industrialActivities = this.model.get('industrial_activities');

      this._showPermitSummaryTable(currentPermitRecords);
      this._showDsnChoices(dsnRecords);
      this._showIndustrialActivityChoices(
        this.model.getPermitDsnSlugs(),
        industrialActivities,
      );
    }

    this.ui.$modal.modal('show');
  },

  _showPermitSummaryTable(permitRecords) {
    let PermitSummaryTableView = require('./PermitSummaryTableView');
    let permitSummaryTableView = new PermitSummaryTableView({
      collection: new Backbone.Collection(permitRecords),
    });
    this.permitSummaryTable.show(permitSummaryTableView);
  },

  _showDsnChoices(dsnRecords) {
    let PermitDsnChoicesView = require('./PermitDsnChoicesView');
    let permitDsnChoicesView = new PermitDsnChoicesView({
      collection: new Backbone.Collection(dsnRecords),
    });
    this.dsnChoices.show(permitDsnChoicesView);
  },

  _showIndustrialActivityChoices(permitDsns, selectedActivities) {
    let industrialActivityRecords = _.map(
      this.industrialStormwaterSectorData.getIndustrialActivities(permitDsns),
      (activity) => {
        activity.is_checked =
          selectedActivities && selectedActivities.indexOf(activity.slug) > -1;
        return activity;
      },
    );

    if (!_.some(industrialActivityRecords)) {
      this.industrialActivityChoices.empty();
      return;
    }

    let IndustrialActivityChoicesView = require('../sectorData/IndustrialActivityChoicesView');
    let industrialActivityChoicesView = new IndustrialActivityChoicesView({
      collection: new Backbone.Collection(industrialActivityRecords),
    });
    this.industrialActivityChoices.show(industrialActivityChoicesView);
  },

  _initPermitSelect() {
    let self = this;
    let data = _.map(
      this.industrialStormwaterSectorData.getAllPermitRecords(),
      function (record) {
        return {
          id: record.slug,
          text: `${record.slug} - ${record.industry}`,
          selected: _.contains(self._getSelectedPermitSlugs(), record.slug),
        };
      },
    );

    this.ui.$permitSelect.select2({
      placeholder: 'Please enter your General NPDES Permits e.g. ALG020000',
      width: '100%',
      data: data,
      templateSelection(record) {
        return record.id;
      },
    });
  },

  _getSelectedPermitSlugs() {
    var permitSlugs = _.map(
      this.model.getPermitDsnSlugs(),
      (record) => record.split(':')[0],
    );
    return _.uniq(permitSlugs);
  },

  _getPermitRecords(permitSlugs) {
    return _.map(
      permitSlugs,
      this.industrialStormwaterSectorData.getPermitRecord,
    );
  },

  _getDsnRecords(permitRecords) {
    return _.map(permitRecords, (permitRecord) => {
      return {
        permit: permitRecord.slug,
        dsnRecords: this._getDsnRecordsForPermit(permitRecord),
      };
    });
  },

  _getDsnRecordsForPermit(permitRecord) {
    var dsnRecords = this.industrialStormwaterSectorData.getDsnByPermit(
      permitRecord.slug,
    );
    var selectedPermitDsnSlugs = this.model.getPermitDsnSlugs();
    return _.map(dsnRecords, (dsnRecord) => {
      dsnRecord.selected = _.contains(
        selectedPermitDsnSlugs,
        dsnRecord.dsn_slug,
      );
      return dsnRecord;
    });
  },

  _handleSectorDataChange() {
    let APP = require('../../../../../../config');
    APP.vent.trigger('sectorData:change');
  },

  _handleChangePermitSelect() {
    let selectedPermitSlugs = this.ui.$permitSelect.val();
    if (selectedPermitSlugs) {
      let selectedPermitRecords = this._getPermitRecords(selectedPermitSlugs);
      let dsnRecords = this._getDsnRecords(selectedPermitRecords);
      this._showPermitSummaryTable(selectedPermitRecords);
      this._showDsnChoices(dsnRecords);
    } else {
      this.dsnChoices.empty();
      this.permitSummaryTable.empty();
      this.industrialActivityChoices.empty();
    }
  },

  _handleChangeDsnSelect() {
    let selectedPermitDsnSlugs = this.ui.$form.serializeObject().dsns;
    if (!selectedPermitDsnSlugs) {
      this.industrialActivityChoices.empty();
      return;
    }

    let permitDsns = selectedPermitDsnSlugs.toString().split(',');
    let industrialActivities = this.model.get('industrial_activities');
    this._showIndustrialActivityChoices(permitDsns, industrialActivities);
  },

  _saveSectorData(e) {
    e.preventDefault();

    const serializedForm = this.ui.$form.serializeObject();
    const selectedPermitDsnSlugs = serializedForm.dsns;
    const selectedIndustrialActivities = serializedForm.industrial_activities;
    const formattedPermits = selectedPermitDsnSlugs
      ? selectedPermitDsnSlugs.toString()
      : null;
    const formattedIndustrialActivities = selectedIndustrialActivities
      ? selectedIndustrialActivities.toString()
      : null;

    this.model.save(
      {
        permits: formattedPermits,
        industrial_activities: formattedIndustrialActivities,
      },
      { wait: true },
    );
    // this._saveIntervalSettings();
    this.ui.$modal.modal('hide');
  },

  /*
    We used to update the interval settings for visual assessments based on permit
    and DSN codes to equal the frequency of the most frequent sampling parameter.
    We have not been using this interval setting since we moved visual assessments
    into a calendar component so there is no harm in removing it. Also, This potentially
    becomes much more complicated with our new frequency setting capabilities in that
    calendar.

    I'm commenting it out instead of removing it so that we have a reference and
    can address the need later if we decide to.
  */
  // _saveIntervalSettings() {
  // let interval = this.industrialStormwaterSectorData.getHighestFrequency({
  //   permitDsns: formattedPermits && formattedPermits.split(','),
  //   industrialActivities:
  //     formattedIndustrialActivities &&
  //     formattedIndustrialActivities.split(','),
  // });
  //   let project = this.model.toJSON();
  //   let formTemplateSlug = FormSlugs.getVisualAssesmentsTemplate(
  //     project.state,
  //     project.is_federal,
  //   );
  //   let startDate = projectStateConfig.getSwpppYearStartDate(project);
  //   startDate = moment(startDate).isBefore(moment(project.created_at))
  //     ? startDate
  //     : moment(project.created_at).format('YYYY-MM-DD');

  //   let data = {
  //     interval,
  //     swppp_start_year: projectStateConfig.getSwpppStartYearForDate(
  //       project,
  //       startDate,
  //     ),
  //     form_template_slug: formTemplateSlug,
  //   };

  //   apiCaller
  //     .saveRoutineInspectionSettings(project.id, formTemplateSlug, data)
  //     .then(() => {
  //       APP.vent.trigger('formListSettings:save');
  //     });
  // },
});
module.exports = AlabamaPermitDataView;
