/* eslint-disable */
var APP = require('../../config');
var newMapView = require('./mapsPanel/createMapView').singleton();
var MapListView = require('./mapsPanel/mapListView');
var LayerListView = require('./layersPanel/layerListView');
var CreateLayerModalView = require('../../layerManagement/CreateLayerModalView');
var ChecklistView = require('./checklist/ChecklistView');
var ChecklistCollection = require('./checklist/ChecklistCollection');

var SidebarLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./sidebar.pug'),

  events: {
    'click #new-view-button': '_showNewMapModal',
    // This upload functionality is now broken, it used to do two things:
    // - upload file to S3 bucket, something wrong with our request config and upload fails;
    // - parse csv and save table rows as map features, this doesn't work either since parsing
    // used to happen on separate server which we are not running any more: https://github.com/Mapistry/parseGeoFile
    // 'click #upload-data-button': '_uploadData',
    'click #create-layer-button': '_createLayer',
    'click #checklist-tab': '_showChecklist',
  },

  ui: {
    $checklist: '#checklist',
    $checklistTabText: '.checklist-tab-text',
  },

  regions: {
    mapList: '#map-list-region',
    layerList: '#layer-list-region',
    checklist: '#checklist',
  },

  templateHelpers() {
    return {
      projectName: this.model.get('name'),
    };
  },

  onShow: function () {
    this.mapList.show(new MapListView());
    this.layerList.show(new LayerListView());
  },

  _showNewMapModal: function () {
    newMapView.showModal();
  },

  _uploadData: function () {
    var UploadFeatureDataView = require('../../featureUpload/uploadFeatureDataView');
    var uploadFeatureDataView = new UploadFeatureDataView({
      model: this.model,
    });
    uploadFeatureDataView.showModal();
  },

  _createLayer: function () {
    var modal = new CreateLayerModalView();
    modal.render();
  },

  _showChecklist: function (e) {
    APP.vent.trigger('body:clicked', e);
    this.$el.toggleClass('checklist-open');

    if (this.$el.hasClass('checklist-open')) {
      ChecklistCollection.fetchSingleton({ silent: true })
        .bind(this)
        .then(function () {
          this.checklist.show(
            new ChecklistView({
              collection: ChecklistCollection.singleton(),
              project: this.model,
            }),
          );
          this.ui.$checklistTabText.html('Hide Checklist');
        });
    } else {
      this.checklist.empty();
      this.ui.$checklistTabText.html('Show Checklist');
    }
  },
});

module.exports = SidebarLayoutView;
