/* eslint-disable */
var APP = require('../../../config');
var mapCollection =
  require('../../../models_collections/mapCollection').singleton();
var layerCollection =
  require('../../../models_collections/layerCollection').singleton();
var confirmView = require('../../../modals/confirmView').singleton();
var editFeatureView =
  require('../../../featureManagement/EditFeatureView').singleton();
var configureTipsy = require('../../../lib/tipsyConfig');
var projectMapsService = require('../../services/projectMapsService');

var FeatureRowView = Backbone.View.extend({
  template: require('./featureRow.pug'),

  events: {
    'click .feature-label': '_handleFeatureClick',
    'click .edit-attr': '_editFeature',
    'click .delete-attr': '_confirmDeleteFeature',
  },

  initialize: function () {
    this.listenTo(this.model, 'destroy', this._removeView);
    this.listenTo(this.model, 'change:name', this.render);
    this.listenTo(mapCollection, 'change:feature_on', function (fdsModel) {
      if (fdsModel.get('feature_id') === this.model.id) {
        this.render();
      }
    });

    this._layer = layerCollection.get(this.model.get('layer_id'));
  },

  render: function () {
    var selectedMap = projectMapsService.getSelectedMap();
    var isLayerOn = selectedMap.isLayerOn(this._layer.id);
    var isFeatureOn = selectedMap.isFeatureOn(this.model.id);
    var isActive = isLayerOn && isFeatureOn;

    this.$el.html(
      this.template({
        id: this.model.id,
        name: this.model.get('name'),
        active: isActive ? 'active' : '',
      }),
    );

    configureTipsy(this.$('[rel=tipsy]'));

    return this;
  },

  /**
   * Remove the view because the model was destroyed.
   */
  _removeView: function () {
    this.$el.hide();

    setTimeout(
      function () {
        this.remove();
      }.bind(this),
      500,
    );
  },

  /**
   * This function should be executed in the event that the checkbox for
   * created by render is clicked. The feature this.model will be drawn or
   * removed from the map depending on that state of the checkbox.
   * In addition the state will be recorded for all selected views, since
   * whether or not a feature is turned on is assocaited with each view.
   */
  _handleFeatureClick: function () {
    projectMapsService.getSelectedMap().toggleFeatureOn(this.model);
  },

  _editFeature: function () {
    editFeatureView.showModal({
      model: this.model,
      projectSettings: this.projectSettings,
    });
  },

  _confirmDeleteFeature: function () {
    var options = {
      text: 'the ' + this.model.get('name') + ' feature?',
      confirmClicked: this._deleteFeature.bind(this),
      useSpinner: false,
    };

    confirmView.showModal(options);
  },

  _deleteFeature: function () {
    this.model.destroy();
  },
});

module.exports = FeatureRowView;
