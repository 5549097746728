import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@monorepo/shared/components';
import StatsListItem from './StatsListItem';
import RecordPanelListItemExpandable from '../../../views/projectDashboard/RecordPanelListItemExpandable';
import { isNullOrUndefined } from '../../../../utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function FlowLogList(props) {
  const {
    flowLogStats,
    isFetching,
    monitoringLocations,
    onEditFlowLogReading,
  } = props;

  // QA locations are only for sampling results
  const nonQaDischargeLocations = useMemo(
    () => monitoringLocations.filter((l) => !l.sampleType && !l.parentId),
    [monitoringLocations],
  );

  if (nonQaDischargeLocations.length === 0) {
    return (
      <div className="not-configured">
        To start logging flow, add locations to your discharge layers on the
        map.
      </div>
    );
  }

  const locationHasYearStats = (locationId) =>
    !isNullOrUndefined(flowLogStats[locationId]) &&
    !isNullOrUndefined(flowLogStats[locationId].year) &&
    flowLogStats[locationId].year.length > 0;
  return (
    <div className="flow-log-list">
      {isFetching && (
        <div className="w-100 h-100 flex justify-center items-center">
          <div style={{ padding: '20px' }}>
            <Loading size="medium" />
          </div>
        </div>
      )}
      {nonQaDischargeLocations.map(({ id, name }) => (
        <RecordPanelListItemExpandable
          key={id}
          classes={['flow-log-monitoring-location-list-item']}
          summary={<div>{name}</div>}
          details={
            <>
              {!locationHasYearStats(id) && (
                <div className="empty-flow-log">{`No flow log entries for ${name}`}</div>
              )}
              {locationHasYearStats(id) && (
                <div className="parameter-stats-container">
                  <div className="aggregate-stats-header-row">
                    <div>Average Volume (gallons)</div>
                    <div>Maximum Volume (gallons)</div>
                    <div>Minimum Volume (gallons)</div>
                    <div>Total Volume (millions of gallons)</div>
                    <div>Average Daily Flow Rate (gpm)</div>
                    <div>Maxiumum Daily Flow Rate (gpm)</div>
                    <div>Minimum Daily Flow Rate (gpm)</div>
                    <div>Total Discharge Days</div>
                  </div>
                  {flowLogStats[id].year.map((stats) => (
                    <StatsListItem
                      key={`${id}-${stats.startDate}`}
                      locationId={id}
                      onEditFlowLogReading={onEditFlowLogReading}
                      statsSummary={stats}
                    />
                  ))}
                </div>
              )}
            </>
          }
        />
      ))}
    </div>
  );
}

FlowLogList.defaultProps = {
  flowLogStats: null,
  isFetching: false,
  monitoringLocations: [],
};

FlowLogList.propTypes = {
  flowLogStats: PropTypes.shape({
    locationId: PropTypes.arrayOf(
      PropTypes.shape({
        averageVolume: PropTypes.number,
        minVolume: PropTypes.number,
        maxVolume: PropTypes.number,
        totalVolumeMillionsOfGallons: PropTypes.number,
        numberOfReadings: PropTypes.number,
        frequency: PropTypes.string,
        endDate: PropTypes.string,
        startDate: PropTypes.string,
      }),
    ),
  }),
  isFetching: PropTypes.bool,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onEditFlowLogReading: PropTypes.func.isRequired,
};
