/* eslint-disable */
var FocusModalInput = Backbone.Marionette.Behavior.extend({
  events: {
    'shown.bs.modal': '_focusInput',
  },

  _focusInput: function () {
    this.ui.$focusModalInput.focus();
  },
});

module.exports = FocusModalInput;
