/* eslint-disable */
var StyleModel = require('@monorepo/old-web/js/models_collections/styles/styleModel');
var WebMapMarkerIconFactory = require('@monorepo/old-web/js/map/WebMapMarkerIconFactory');

module.exports = function () {
  L.Icon.Default.imagePath = '/images/third-party';

  // This prevents undefined errors from Leaflet
  L.PolylineDecorator.prototype.on =
    L.PolylineDecorator.prototype.on || function () {};
  L.PolylineDecorator.prototype.off =
    L.PolylineDecorator.prototype.off || function () {};

  /** Adds a decorator to a Polyline
   *
   * Parameters:
   *    None
   *
   * Return:
   *     object
   *        The decorator associated with the polyline that was created
   */
  L.Polyline.prototype.addDecorator = function (featureId, styleSettings) {
    if (
      this.options.decorator === 'none' ||
      this.options.decorator === undefined
    ) {
      return;
    }

    var shapes = {
      arrow: 'triangle',
      arrowMultiple: 'triangle',
      arrowReverse: 'triangle-down',
      arrowReverseMultiple: 'triangle-down',
      circles: 'circle',
      squares: 'square',
      pluses: 'plus',
      asterisks: 'asterisk',
    };

    var offset = 0;
    var repeat = 100;
    if (
      styleSettings.decoratorDirectional &&
      !styleSettings.decoratorSingleSymbol
    ) {
      repeat = 30;
    }
    if (
      styleSettings.decoratorDirectional &&
      styleSettings.decoratorSingleSymbol
    ) {
      repeat = 0;
    }

    let size;
    if (this.options.weight < 5) {
      size =
        this.options.decorator.indexOf('arrow') >= 0
          ? this.options.weight * 8
          : this.options.weight * 5;
    } else {
      size =
        this.options.decorator.indexOf('arrow') >= 0
          ? this.options.weight * 5
          : this.options.weight * 3;
    }

    var styleModel = new StyleModel({
      size,
      color: this.options.color,
      shape: shapes[this.options.decorator],
      opacity: 1,
    });

    if (this.options.decorator === 'arrow') {
      offset = '100%';
    } else if (this.options.decorator === 'arrowReverse') {
      repeat = 0;
    }

    var rotate = styleSettings.customIcon ? false : true;
    if (styleSettings.decoratorDirectional) {
      rotate = true;
    }

    var markerOptions = {
      icon: WebMapMarkerIconFactory.create(
        styleModel,
        styleSettings.customIcon,
      ),
    };
    var marker = L.Symbol.marker({
      rotate: rotate,
      markerOptions: markerOptions,
    });

    // For some reason it wouldn't let me specify the clickable option
    // when creating the marker.
    marker.options.markerOptions.clickable = true;

    var patterns = [{ offset: offset, repeat: repeat, symbol: marker }];
    var decorator = L.polylineDecorator(this, { patterns: patterns });

    decorator.patternsBackup = patterns;
    decorator._leaflet_id = featureId + '_decorator';
    decorator.isDecorator = true;

    var feature = this;

    // Give the decorator a handle to the feature it's attached to
    decorator.getFeature = function () {
      return feature;
    };

    // This needs to be set after decorator is added to the polyGroup.
    // Otherwise, the decorator doesn't get added to the polyGroup.
    decorator.modelId = featureId;

    return decorator;
  };
};
