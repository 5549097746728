/* eslint-disable */
const moment = require('moment');

let apiCaller = require('../../../../../../../apiCaller');
let dashboardConfig = require('../../../../../config');
let SamplingEventModel = require('./SamplingEventModel');

let GenericSamplingResultsModalView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/modal.pug'),

  regions: {
    samplingEventsNav: '#sampling-events-nav',
    samplingEventContent: '#sampling-event-content',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;

    let APP = require('../../../../../../../config');
    this.listenTo(
      APP.vent,
      'samplingResults:hideModal',
      this._hideModal.bind(this),
    );
  },

  showModal() {
    let BaseTabCollectionView = require('../../../../../../../tabs/BaseTabCollectionView');
    this.samplingEventLabelCollection = this._getSamplingEventLabelCollection();
    this.samplingEventNavCollectionView = new BaseTabCollectionView({
      collection: this.samplingEventLabelCollection,
    });

    this.listenTo(
      this.samplingEventNavCollectionView,
      'show:tab',
      this._showTabContent,
    );
    this.listenTo(
      this.samplingEventNavCollectionView,
      'childview:show:tab',
      this._showTabContent,
    );

    this.$('.modal').modal('show');

    return this.samplingEventLabelCollection.fetchPromise().then(() => {
      this.samplingEventNavCollectionView.collection.models = _.sortBy(
        this.samplingEventNavCollectionView.collection.models,
        (m) => m.attributes.label,
      ).reverse();
      return this.samplingEventsNav.show(this.samplingEventNavCollectionView);
    });
  },

  _hideModal(cb) {
    if (cb) {
      this.$('.modal').one('hidden.bs.modal', cb);
    }
    this.$('.modal').modal('hide');
  },

  _showTabContent: function () {
    let target = arguments[0].target
      ? arguments[0].target
      : arguments[1].target;
    let samplingEventId = target.getAttribute('data-model-id');
    let swpppYear = target.getAttribute('swppp-year');
    if (!samplingEventId) {
      let NoSamplingEventParametersView = require('./NoSamplingEventParametersView');
      return this.samplingEventContent.show(
        new NoSamplingEventParametersView({
          project: this.projectModel.toJSON(),
        }),
      );
    }

    this._showSamplingEventContent(samplingEventId, swpppYear);
  },

  _showSamplingEventContent(samplingEventId, swpppYear) {
    let SpinnerItemView = require('../../../../../../../services/SpinnerItemView');
    let dischargeLocations =
      require('../../../industrial/stormwater/siteSummary/stormwaterSamples/FeaturesInDischargeLocationsLayerCollection').singleton();

    // turn on a spinner, since this enough time for user to notice lag.
    this.samplingEventContent.show(new SpinnerItemView());

    let projectId = this.projectModel.toJSON().id;

    return apiCaller
      .getSamplingEvent(projectId, samplingEventId, true)
      .then((samplingEvent) => {
        let samplingEventModel = new SamplingEventModel(samplingEvent);
        let SamplingEventParameterCollection = require('./SamplingEventParameterCollection');
        let SamplingEventParametersLayoutView = require('./SamplingEventParametersLayoutView');
        let samplingEventParametersLayoutView =
          new SamplingEventParametersLayoutView({
            swpppYear: swpppYear,
            projectModel: this.projectModel,
            samplingEventModel: samplingEventModel,
            samplingEventParameterCollection:
              new SamplingEventParameterCollection(
                samplingEventModel.get('parameters'),
                { projectId, samplingEventId },
              ),
            hasSamplingLocations: !!(
              dischargeLocations && dischargeLocations.length
            ),
          });

        this.samplingEventContent.show(samplingEventParametersLayoutView);
      });
  },

  _getSamplingEventLabelCollection() {
    let project = this.projectModel.toJSON();
    let projectId = project.id;

    let startDate = dashboardConfig.getCurrentStartDate(project);
    let endDate = dashboardConfig.getCurrentEndDate(project);
    let SamplingEventCollection = require('./SamplingEventLabelCollection');

    return new SamplingEventCollection([], {
      projectId,
      project,
      startDate,
      endDate,
    });
  },
});

module.exports = GenericSamplingResultsModalView;
