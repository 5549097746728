var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getMonthlyAverageDisplayText, getQuarterlyAverageDisplayText) {
      pug_html = pug_html + "\u003Ctable class=\"table\"\u003E\u003Ctbody\u003E\u003Ctr class=\"monthly-average\"\u003E\u003Ctd\u003EMonthly Average\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getMonthlyAverageDisplayText()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003Ctr class=\"quarterly-average\"\u003E\u003Ctd\u003EQuarterly Average\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getQuarterlyAverageDisplayText()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E";
    }.call(this, "getMonthlyAverageDisplayText" in locals_for_with ?
        locals_for_with.getMonthlyAverageDisplayText :
        typeof getMonthlyAverageDisplayText !== 'undefined' ? getMonthlyAverageDisplayText : undefined, "getQuarterlyAverageDisplayText" in locals_for_with ?
        locals_for_with.getQuarterlyAverageDisplayText :
        typeof getQuarterlyAverageDisplayText !== 'undefined' ? getQuarterlyAverageDisplayText : undefined));
    ;;return pug_html;};
module.exports = template;