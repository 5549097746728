/* eslint-disable */
import React from 'react';
import { AcceptTosView } from '../../../authentication/components/tos/AcceptTosView';
import { ComponentWrapper } from '../components/componentWrapper';
import errors from '../errors';

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const BaseController = require('../BaseController');

// Views
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const authenticationLayoutView = require('./authenticationLayoutView');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const LoginView = require('./LoginView');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const SignUpView = require('./signUp/SignUpView');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const ForgotPasswordView = require('./ForgotPasswordView');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const PasswordResetView = require('./PasswordResetView');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const SetRecoveryQuestionView = require('./SetRecoveryQuestionView');

// Services
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const currentUser = require('../currentUser');
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const apiCaller = require('../apiCaller');

const AuthenticationController = BaseController.extend({
  login(queryParams) {
    return apiCaller.getPageSetupVars().then(() => {
      if (currentUser.isLoggedIn()) {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        return require('../router').goToRootUrl();
      }
      const contentModel = new Backbone.Model(queryParams);
      authenticationLayoutView.content.show(
        new LoginView({ model: contentModel }),
      );
    });
  },

  logout() {
    return apiCaller
      .logout()
      .then(() => {
        currentUser.id = null;
        if (typeof localStorage !== 'undefined') {
          localStorage.clear();
        }
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        require('../router').goToHomepageUrl();
      })
      .catch((err) => {
        console.log('DEBUG: Could not log out properly', err);
      });
  },

  forgotPassword() {
    if (currentUser.isLoggedIn()) {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      return require('../router').goToRootUrl();
    }
    authenticationLayoutView.content.show(new ForgotPasswordView());
  },

  acceptTos() {
    const errorHandler = (err) => {
      this.handleError(new errors.AuthenticationError(err.message));
    };
    return this.enforceAuthentication()
      .then(() => {
        const tosPage = new ComponentWrapper(<AcceptTosView />);
        authenticationLayoutView.content.show(tosPage);
      })
      .catch(errorHandler);
  },

  showPasswordReset(queryParams) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const router = require('../router');
    if (currentUser.isLoggedIn()) {
      return router.goToRootUrl();
    }
    const token = queryParams && queryParams.token;
    return apiCaller
      .verifyPasswordResetToken(token)
      .bind(this)
      .then((resp) => {
        const recoveryQuestion = resp._embedded.user.recovery_question.question;

        if (
          !recoveryQuestion ||
          recoveryQuestion === 'Stormpath recovery answer'
        ) {
          router.goToErrorPage(
            null,
            'Your password cannot be reset because you have not chosen a security question.',
          );
        } else {
          const passwordResetModel = new Backbone.Model({
            token,
            recoveryQuestion,
          });
          authenticationLayoutView.content.show(
            new PasswordResetView({ model: passwordResetModel }),
          );
        }
      })
      .catch((err) => {
        if (err?.responseText?.includes('Invalid password reset token')) {
          const errorMessage =
            'Your password reset link is invalid or expired. Please make sure you click the link in the most recent reset email you received. If you need a new link fill out the form below.';
          authenticationLayoutView.content.show(
            new ForgotPasswordView({ errorMessage }),
          );
        } else {
          this.handleError(
            // eslint-disable-next-line global-require, no-new-require, new-cap, @typescript-eslint/no-var-requires
            new require('../errors').GenericError(err.responseText),
          );
        }
      });
  },

  signUp(queryParams) {
    return apiCaller.getPageSetupVars().then(() => {
      if (currentUser.isLoggedIn()) {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        return require('../router').goToRootUrl();
      }

      const contentModel = new Backbone.Model(queryParams);
      authenticationLayoutView.content.show(
        new SignUpView({ model: contentModel }),
      );
    });
  },

  setRecoveryQuestion() {
    authenticationLayoutView.content.show(new SetRecoveryQuestionView());
  },
});

module.exports = AuthenticationController;
