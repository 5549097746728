import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import { CUSTOM_FREQUENCY } from '@monorepo/shared/contexts/FrequencyContext/types';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import _get from 'lodash.get';
import { GenericLogLimitType, IntervalFrequencyEnum } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { isNullOrUndefined } from '../../../../utils';
import Units from '../../../../utils/units';
import {
  AddButton,
  HelpIcon,
  Select,
  TableAction,
  TD,
  TextField,
  TR,
} from '../../../elements';
import { FrequencySelect } from '../../../frequencyForm';
import { EquationFlyout, NotesPopover, TABLE_SETTINGS } from '../shared';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.LIMIT_ITEMS;

const INTERVAL_FREQUENCY_ENUM_GROUP = [CUSTOM_FREQUENCY];
const FREQUENCIES = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.YEAR,
  INTERVAL_FREQUENCY_ENUM_GROUP,
];

const MAX_MIN_OPTIONS = [
  GenericLogLimitType.MAX,
  GenericLogLimitType.MIN,
  GenericLogLimitType.AVERAGE,
].map((opt) => ({ label: opt, value: opt }));

const LimitItemsRow = (props) => {
  const [visibleFlyout, setVisibleFlyout] = useState(null);

  const isFlyoutVisibleForPath = (groupId, resourceId) =>
    visibleFlyout === `${groupId}.${resourceId}`;

  const toggleFlyoutForRecord = (groupId, resourceId) => {
    setVisibleFlyout(`${groupId}.${resourceId}`);
  };

  const {
    addLimitGroup,
    calculatedValues,
    deleteLimitGroup,
    deleteLimitItem,
    emissionFactors,
    formErrors,
    groupId,
    limitItem,
    limitItemIdx,
    loggedItems,
    logProjectId,
    measureRowHeight,
    rollingCalculations,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const prevLimitItem = usePrevious(limitItem);

  useEffect(() => {
    if (prevLimitItem?.limits.length !== limitItem.limits.length) {
      measureRowHeight(); // recalculate row height
    }
  }, [limitItem, prevLimitItem, measureRowHeight]);

  const allItemsToSelect = () =>
    calculatedValues
      .concat(loggedItems)
      .concat(emissionFactors)
      .concat(rollingCalculations)
      .map((item) => ({
        label: item.name,
        value: item.id,
        resourceType: item.resourceType,
        resourceId: item.id,
        resourceUnits: item.units,
      }));

  const getRelatedUnitsByResourceId = (resourceId) => {
    if (isNullOrUndefined(resourceId)) {
      return [];
    }
    const allItems = allItemsToSelect();
    const matchingItem = allItems.find(
      (item) => item.resourceId === resourceId,
    );
    return Units.getRelatedUnits(_get(matchingItem, 'resourceUnits'));
  };

  const stageChangedValue = useCallback(
    (editField, nextValue, limitIdx) => {
      stageEdit({ editField, nextValue }, { limitItemIdx, groupId, limitIdx });
    },
    [groupId, limitItemIdx, stageEdit],
  );

  const deleteOnClick = useCallback(
    () => deleteLimitItem(limitItemIdx, groupId),
    [groupId, limitItemIdx, deleteLimitItem],
  );

  const sharedSelectProps = {
    menuPlacement: 'auto',
    menuPortalTarget: selectMenuPortalRef.current,
    isClearable: false,
  };

  return (
    <>
      <EquationFlyout
        logProjectId={logProjectId}
        onClose={() => setVisibleFlyout(null)}
        resourceId={limitItem.resourceId}
        resourceType={limitItem.resourceType}
        visible={isFlyoutVisibleForPath(groupId, limitItem.resourceId)}
      />
      <TR>
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <TextField
            autoFocus={!limitItem.resourceId}
            controlled={false}
            defaultValue={limitItem.userDefinedId || ''}
            error={
              !!_get(
                formErrors,
                `groups.${groupId}.${limitItemIdx}.userDefinedId`,
              )
            }
            onBlur={(e) => stageChangedValue('userDefinedId', e.target.value)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <div className="flex items-center">
            <Select
              className="flex-1"
              error={
                !!_get(
                  formErrors,
                  `groups.${groupId}.${limitItemIdx}.resourceId`,
                )
              }
              menuPlacement="auto"
              menuPortalTarget={selectMenuPortalRef.current}
              isClearable={false}
              options={allItemsToSelect()}
              onChange={(opt) => {
                toggleFlyoutForRecord(groupId, limitItem.resourceId);
                return stageChangedValue('resource', {
                  resourceType: opt.resourceType,
                  resourceId: opt.resourceId,
                  resourceUnits: opt.resourceUnits,
                });
              }}
              value={allItemsToSelect().find(
                (o) => o.value === limitItem.resourceId,
              )}
            />
            {!isNullOrUndefined(limitItem.resourceId) && (
              <span
                className="pointer"
                onClick={() =>
                  toggleFlyoutForRecord(groupId, limitItem.resourceId)
                }
                onKeyPress={() =>
                  toggleFlyoutForRecord(groupId, limitItem.resourceId)
                }
                role="button"
                tabIndex={0}
              >
                <HelpIcon text="More info" />
              </span>
            )}
          </div>
        </TD>
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          {limitItem.limits.map((limit, limitIdx) => (
            <Fragment key={limit.renderKey}>
              <div className="limit-items__row-container">
                <div className="limit-items__row-content">
                  <Select
                    {...sharedSelectProps}
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.units`,
                      )
                    }
                    isDisabled={isNullOrUndefined(limitItem.resourceId)}
                    placeholder="Units"
                    options={getRelatedUnitsByResourceId(limitItem.resourceId)}
                    onChange={(opt) =>
                      stageChangedValue('units', opt.value, limitIdx)
                    }
                    value={getRelatedUnitsByResourceId(
                      limitItem.resourceId,
                    ).find((o) => o.value === limit.units)}
                  />
                  <TextField
                    controlled={false}
                    defaultValue={limit.limitValue || ''}
                    disabled={isNullOrUndefined(limitItem.resourceId)}
                    type="number"
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.limitValue`,
                      )
                    }
                    onBlur={(e) =>
                      stageChangedValue('limitValue', e.target.value, limitIdx)
                    }
                  />
                  <Select
                    {...sharedSelectProps}
                    error={
                      !!_get(
                        formErrors,
                        `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.limitType`,
                      )
                    }
                    isDisabled={isNullOrUndefined(limitItem.resourceId)}
                    placeholder="Limit type"
                    options={MAX_MIN_OPTIONS}
                    onChange={(opt) =>
                      stageChangedValue('limitType', opt.value, limitIdx)
                    }
                    value={MAX_MIN_OPTIONS.find(
                      (o) => o.value === limit.limitType,
                    )}
                  />
                  <span className="text__spacer">per</span>
                  <FrequencyProvider
                    selectedFrequency={limit.limitFrequency}
                    customFrequency={limit.limitCustomFrequency}
                    noRepeat
                    noRepeatOn
                    noStartDate
                    onFrequencyChange={(frequency, customFrequency) => {
                      stageChangedValue('limitFrequency', frequency, limitIdx);
                      stageChangedValue(
                        'limitCustomFrequency',
                        customFrequency,
                        limitIdx,
                      );
                    }}
                  >
                    <FrequencySelect
                      {...sharedSelectProps}
                      disabled={isNullOrUndefined(limitItem.resourceId)}
                      frequencyOptions={FREQUENCIES}
                      error={
                        !!_get(
                          formErrors,
                          `groups.${groupId}.${limitItemIdx}.limits.${limitIdx}.limitFrequency`,
                        )
                      }
                      customFrequencyMessageStart="This limit will be effective"
                      customPopperHeader="Frequency selector"
                      customPopperSubHeader="Select a frequency"
                      placeholder="Frequency"
                      showCustomFrequencyEditButton
                    />
                  </FrequencyProvider>
                </div>
                <div className="w-10">
                  {limitIdx > 0 && (
                    <TableAction
                      actionType="delete"
                      deleteTooltipText="Delete limit"
                      onClick={() =>
                        deleteLimitGroup(limitItemIdx, limitIdx, groupId)
                      }
                    />
                  )}
                </div>
              </div>
              {limitIdx === limitItem.limits.length - 1 && (
                <AddButton onClick={() => addLimitGroup(groupId, limitItemIdx)}>
                  Add a limit item
                </AddButton>
              )}
            </Fragment>
          ))}
        </TD>
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          <NotesPopover
            notes={limitItem.notes}
            onClose={(nextNotes) => stageChangedValue('notes', nextNotes)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            onClick={deleteOnClick}
          />
        </TD>
      </TR>
    </>
  );
};

LimitItemsRow.propTypes = {
  addLimitGroup: PropTypes.func.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteLimitGroup: PropTypes.func.isRequired,
  deleteLimitItem: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  limitItem: PropTypes.shape({
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
    userDefinedId: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    limits: PropTypes.array,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  limitItemIdx: PropTypes.number.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  measureRowHeight: PropTypes.func.isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default React.memo(LimitItemsRow);
