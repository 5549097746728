import { /* GenericLogType, */ Interval } from 'mapistry-shared';
import React, { useState } from 'react';
import { CustomLogTemplateSection } from '../../../hooks/genericLogs/useCustomLogTemplates';
import { localEquivalentOfUTC } from '../../../utils';
import { ConfirmDialog, Page } from '../../elements';
import { ComponentPicker } from '../ComponentPicker';
import EditLogModal from '../EditLogModal';
import { LinkToLogsDashboardFromWithinBackboneApp } from '../LinkToLogsDashboardFromWithinBackboneRouter';
import { useCurrentLogProject } from '../LogProjectContext';
import { ColumnHeaders } from './ColumnHeaders';
import FilterMenu from './FilterMenu';
import { GenericLogTrackingViewMenu } from './GenericLogTrackingViewMenu';
import { GenericLogTrackingViewTabs } from './GenericLogTrackingViewTabs';
import { LoggedItemValueEvent } from './types';

interface GenericLogTrackingViewProps {
  loggedItemValueEvents: LoggedItemValueEvent[];
  allParameters: {
    id: string;
    label: string;
    // type: GenericLogType; // TODO: GenericLogType is a const instead of a proper enum.
    type: string;
  }[];
  fetchEmissionLogs: (logStats: Interval) => void;
  isLoading: boolean;
  isTabsLoading: boolean;
  loggedItems: {
    logProjectId: string;
    records: {
      id: string;
      isLocked: boolean;
      name: string;
      projectId: string;
      userDefinedId: string;
    }[];
  };
  logProjectId: string;
  navigateToSettings: () => void;
  onDeleteRecord: (log: LoggedItemValueEvent) => void;
  onDownload: () => void;
  onFilterChange: (selectedParameters: { id: string; type: string }[]) => void;
  onSaveLog: () => void;
  onTabCreate: (tab: CustomLogTemplateSection | undefined) => void;
  onTabDelete: (tab: CustomLogTemplateSection) => void;
  onTabRename: (tab: CustomLogTemplateSection) => void;
  onTabSelect: (tab: CustomLogTemplateSection) => void;
  projectHasLogs: boolean;
  projectId: string;
  selectedTab: CustomLogTemplateSection;
  tabs: CustomLogTemplateSection[];
}

export const GenericLogTrackingView = ({
  loggedItemValueEvents,
  allParameters,
  fetchEmissionLogs,
  isLoading,
  isTabsLoading,
  loggedItems,
  logProjectId,
  navigateToSettings,
  onDeleteRecord,
  onDownload,
  onFilterChange,
  onSaveLog,
  onTabSelect,
  onTabCreate,
  onTabDelete,
  onTabRename,
  projectId,
  projectHasLogs,
  selectedTab,
  tabs,
}: GenericLogTrackingViewProps) => {
  const [logToDelete, setLogToDelete] = useState<
    LoggedItemValueEvent | undefined
  >();
  const [logDateTimeToEdit, setLogDateTimeToEdit] = useState<
    Date | undefined
  >();
  const [showEditLogModal, setShowEditLogModal] = useState<boolean>(false);
  const { logProject } = useCurrentLogProject();

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const handleEditRecord = (log) => {
    const datetime = localEquivalentOfUTC(log.start);
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    setLogDateTimeToEdit(datetime);
    setShowEditLogModal(true);
  };

  const handleSaveLog = () => {
    onSaveLog();
    setLogDateTimeToEdit(undefined);
    setShowEditLogModal(false);
  };

  const getLogName = () => logProject?.name || 'Custom Log';

  return (
    <Page>
      {logToDelete && (
        <ConfirmDialog
          danger
          confirmButtonText="Delete"
          onCancelled={() => setLogToDelete(undefined)}
          onConfirmed={() => {
            onDeleteRecord(logToDelete);
            setLogToDelete(undefined);
          }}
          open
          title="Are you sure you want to delete this log?"
        />
      )}
      {showEditLogModal && (
        <EditLogModal
          logDatetime={logDateTimeToEdit}
          logProjectId={logProjectId}
          navigateToSettings={navigateToSettings}
          onClose={() => {
            setLogDateTimeToEdit(undefined);
            setShowEditLogModal(false);
          }}
          onSave={handleSaveLog}
          projectId={projectId}
          title={getLogName()}
        />
      )}
      <>
        <div className="page__back-link-container">
          <LinkToLogsDashboardFromWithinBackboneApp projectId={projectId} />
          <GenericLogTrackingViewMenu
            onAddLog={() => setShowEditLogModal(true)}
            onDownload={onDownload}
            onGoToSettings={navigateToSettings}
          />
        </div>
        <h1 className="page__header">{getLogName()}</h1>
        <div className="emission-tracking-log__body">
          <div className="emission-tracking-log__tabs">
            <GenericLogTrackingViewTabs
              isTabsLoading={isTabsLoading}
              onTabCreate={onTabCreate}
              onTabDelete={onTabDelete}
              onTabRename={onTabRename}
              onTabSelect={onTabSelect}
              selectedTab={selectedTab}
              tabs={tabs}
            />
            <FilterMenu
              options={allParameters}
              onChange={onFilterChange}
              tab={selectedTab}
            />
          </div>
          <div className="emission-tracking-log__table">
            <div className="emission-tracking-list__header">
              <ColumnHeaders loggedItemValueEvents={loggedItemValueEvents} />
            </div>
            <div className="emission-tracking-list__body">
              {/*
               * TODO: The airEmissionLogs prop is passed to EmissionTrackingList.
               * We should update this prop name when we touch that component
               */}
              <ComponentPicker
                fetchEmissionLogs={fetchEmissionLogs}
                isLoading={isLoading}
                onDeleteRecord={setLogToDelete}
                onEditRecord={handleEditRecord}
                loggedItems={loggedItems}
                airEmissionLogs={loggedItemValueEvents}
                hasSelectedColumns={allParameters.length > 0}
                navigateToSettings={navigateToSettings}
                projectHasLogs={projectHasLogs}
                showEditLogModal={() => setShowEditLogModal(true)}
                type={selectedTab?.type}
              />
            </div>
          </div>
        </div>
      </>
    </Page>
  );
};
