/* eslint-disable */
var _s = require('underscore.string');

var phoneNumber = {
  normalize: function (phoneNumber) {
    return phoneNumber.replace(/[-()\s]/g, '');
  },

  format: function (phoneNumber) {
    if (phoneNumber === '') {
      return;
    }

    var normalizedPhoneNumber = this.normalize(phoneNumber);
    var formattedPhoneNumber = normalizedPhoneNumber;

    if (normalizedPhoneNumber[5]) {
      formattedPhoneNumber = _s.insert(formattedPhoneNumber, 6, '-');
    }

    if (normalizedPhoneNumber[2]) {
      formattedPhoneNumber = _s.insert(formattedPhoneNumber, 3, ') ');
    }

    return _s.insert(formattedPhoneNumber, 0, '(');
  },

  /**
   * Example of valid formats: (555) 1234-5678, (555) 1234-5678 x999, (555) 1234-5678 ext. 999
   */
  isFormatted: function (phoneNumber) {
    return (
      phoneNumber[0] === '(' && phoneNumber[4] === ')' && phoneNumber[9] === '-'
    );
  },
};

module.exports = phoneNumber;
