import _get from 'lodash.get';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TaskForm } from '../../../task/TaskForm';
import FormField from './FormField';

class TasksField extends FormField {
  getInputComponent() {
    const { disabled, onError, projectId, submissionField } = this.props;
    // this.locations = this.formatOptions('locations', location => !location.isArchived);
    this.value = this.getValueOrDefault();
    const { tasks } = this.value;
    const errors = submissionField.errors || [];

    return (
      <div className="tasks-field__input">
        <TaskForm
          disabled={disabled}
          errors={errors}
          folderSlugPrefix="form-field-tasks"
          multi
          onChange={this.handleChange}
          onError={onError}
          projectId={projectId}
          tasks={tasks}
        />
      </div>
    );
  }

  // formatOptions(key, filter) {
  //   const { options } = this.props.templateField;
  //   const array = filter
  //     ? options[key].filter(filter)
  //     : options[key];
  //   return array.map(value => ({
  //     label: value.name,
  //     value: value.id
  //   }));
  // }

  getValueOrDefault() {
    const value = this.getValue();
    return {
      resourceId: _get(value, 'resourceId', uuidv4()),
      tasks: _get(value, 'tasks', []),
    };
  }

  fieldClassName() {
    return 'tasks-field';
  }

  embeddedErrorMessage() {
    return null;
  }

  handleChange = (newTasks, newErrors) => {
    const { onChange, templateField } = this.props;

    const newValue = {
      ...this.value,
      tasks: newTasks,
    };

    onChange(templateField.slug, newValue, newErrors);
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TasksField;
