/* eslint-disable */
var APP = require('../../../config');
var confirmView = require('../../../modals/confirmView').singleton();
var infoView = require('../../../modals/infoView').singleton();
var featureCollection =
  require('../../../models_collections/featureCollection').singleton();
var mapCollection =
  require('../../../models_collections/mapCollection').singleton();
var projectCollection =
  require('../../../models_collections/projectCollection').singleton();
var bigSpinner = require('../../../services/bigSpinner');
var apiCaller = require('../../../apiCaller');
var MapService = require('../../../services/MapService');

var MapListRowView = Backbone.Marionette.ItemView.extend({
  template: require('./mapRow.pug'),

  events: {
    'click label': '_mapSelected',
    'click .edit': '_editMapViewName',
    'click .delete': '_confirmDeleteMapView',
    'blur .edit-map-view': '_saveNewMapViewName',
  },

  templateHelpers: function () {
    var projectMapsService = require('../../services/projectMapsService');
    var model = this.model;
    var projectId = APP.projectId;
    var mapId = model.id;

    return {
      screenshotLink: apiCaller.getScreenshotLinkUrl(projectId, mapId),
      isChecked: function () {
        return projectMapsService.isSelectedMap(mapId);
      },
    };
  },

  initialize: function () {
    this.listenTo(this.model, 'change:name', this.render);
    this.listenTo(projectCollection, 'change:selected_map', this.render);
    this._name = 'MapListRowView';
  },

  onRender: function () {
    this.$('[rel=tipsy]').tipsy({ fade: true, delayIn: 1000, gravity: 's' });
    this._$mapViewButtons = this.$el.find('.buttons');
  },

  _saveNewMapViewName: function () {
    var newName = this.$('.edit-map-view').val();

    this.model.save({ name: newName });
    this.render();
  },

  /**
   * Change the label with this map view's name to an input box.
   */
  _editMapViewName: function (e) {
    var $label = this.$('label');
    var $input = $('<input type="text" />');

    e.preventDefault();
    $input.addClass('edit-map-view');
    $input.val(this.model.get('name'));
    $input.focus();
    $label.replaceWith($input);
  },

  _mapSelected: function () {
    // For better UX, big spinner is shown here if needed and hidden when map is done
    // updating. The map always tries to hide the big spinner even if it's not showing
    // when redrawing features.
    if (featureCollection.length > 100) {
      bigSpinner.show().then(updateSelectedMap.bind(this));
    } else {
      updateSelectedMap.call(this);
    }

    async function updateSelectedMap() {
      var project = projectCollection.getCurrent();
      const mapService = new MapService();
      const result = await mapService.setSelectedMap(
        project.get('id'),
        this.model.id,
      );
      return project.set({ selected_map: result.mapId });
    }
  },

  _restoreButtons: function () {
    this._$mapViewButtons.removeClass('active');
  },

  _confirmDeleteMapView: function () {
    var useSpinner = false;

    if (mapCollection.length > 1) {
      var options = {
        text: 'the ' + this.model.get('name') + ' map?',
        confirmClicked: this._deleteMapView.bind(this),
        useSpinner: useSpinner,
      };

      confirmView.showModal(options);
    } else {
      infoView.showModal({
        text: 'You cannot delete the last map in a project.',
      });
    }
  },

  _deleteMapView: function () {
    var project = projectCollection.getCurrent();
    if (
      project.get('selected_map') === this.model.id &&
      mapCollection.length > 1
    ) {
      var id =
        this.model.id === mapCollection.at(0).id
          ? mapCollection.at(1).id
          : mapCollection.at(0).id;
      project.save({ selected_map: id });
    }

    this.model.destroy();
  },
});

module.exports = MapListRowView;
