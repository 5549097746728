import { SelectOption } from '@monorepo/shared/apiClient/types';

export enum State {
  arizona = 'az',
  california = 'ca',
  florida = 'fl',
  northCarolina = 'nc',
  texas = 'tx',
  washington = 'wa',
}

enum StatusLabel {
  baseline = 'Baseline',
  benchmarkExceedance = 'Benchmark Exceedance',
  consistentAttainment = 'Consistent Attainment',
  exempt = 'Exempt',
  level1 = 'Level 1',
  level2 = 'Level 2',
  level3 = 'Level 3',
  tier1 = 'Tier 1',
  tier2 = 'Tier 2',
  tier3 = 'Tier 3',
}

enum StatusValue {
  baseline = 'baseline',
  benchmarkExceedance = 'benchmark-exceedance',
  consistentAttainment = 'consistent-attainment',
  exempt = 'exempt',
  level1 = 'level1',
  level2 = 'level2',
  level3 = 'level3',
  tier1 = 'tier1',
  tier2 = 'tier2',
  tier3 = 'tier3',
}

export const Status = {
  baseline: { label: StatusLabel.baseline, value: StatusValue.baseline },
  benchmarkExceedance: {
    label: StatusLabel.benchmarkExceedance,
    value: StatusValue.benchmarkExceedance,
  },
  consistentAttainment: {
    label: StatusLabel.consistentAttainment,
    value: StatusValue.consistentAttainment,
  },
  exempt: { label: StatusLabel.exempt, value: StatusValue.exempt },
  level1: { label: StatusLabel.level1, value: StatusValue.level1 },
  level2: { label: StatusLabel.level2, value: StatusValue.level2 },
  level3: { label: StatusLabel.level3, value: StatusValue.level3 },
  tier1: { label: StatusLabel.tier1, value: StatusValue.tier1 },
  tier2: { label: StatusLabel.tier2, value: StatusValue.tier2 },
  tier3: { label: StatusLabel.tier3, value: StatusValue.tier3 },
} as const;

export const getStatusOptionsByState = (state?: State | string) => {
  const filtered = [] as SelectOption[];
  if (!state) {
    return filtered;
  }
  if ([State.florida, State.texas].some((s) => s === state)) {
    filtered.push(Status.exempt);
  }
  if ([State.arizona, State.texas, State.washington].some((s) => s === state)) {
    filtered.push(Status.consistentAttainment);
  }
  filtered.push(Status.baseline);
  if ([State.california, State.washington].some((s) => s === state)) {
    filtered.push(Status.level1);
    filtered.push(Status.level2);
  }
  if ([State.washington].some((s) => s === state)) {
    filtered.push(Status.level3);
  }
  if ([State.northCarolina].some((s) => s === state)) {
    filtered.push(Status.tier1);
    filtered.push(Status.tier2);
    filtered.push(Status.tier3);
  }
  if (
    ![State.california, State.northCarolina, State.washington].some(
      (s) => s === state,
    )
  ) {
    filtered.push(Status.benchmarkExceedance);
  }
  return filtered;
};
