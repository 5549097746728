import { useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useWindowResizeHandler(onResize: () => void) {
  useEffect(() => {
    const debounce = (fn: () => void, ms: number) => {
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      let timer;
      return () => {
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        window.clearTimeout(timer);
        timer = window.setTimeout(() => {
          timer = null;
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          fn.apply(this);
        }, ms);
      };
    };

    const debouncedHandleResize = debounce(onResize, 100);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  });
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default useWindowResizeHandler;
