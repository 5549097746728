/* eslint-disable */
// Views
const CreateAndEditMapViewBase = require('../../project/sidebar/mapsPanel/CreateAndEditMapViewBase');

// Services
const APP = require('../../config');
const projectMapsService = require('../../project/services/projectMapsService');

const EditTitlebarView = CreateAndEditMapViewBase.extend({
  template: require('./editLegendView.pug'),

  events: {
    'shown.bs.modal': '_handleModalShown',
    'hidden.bs.modal': '_removeView',
    'click #upload-image-wrapper': '_clickRealFileUploadButton',
    'change #btn-hidden-file': '_updateImageName',
    'click .btn-success': '_submitForms',
    'keydown input': '_handleKeyPress',
  },

  setModel() {
    this.model = projectMapsService.getSelectedMap();
  },

  templateHelpers() {
    const legendFontSize = this.model.get('legend_font_size');
    return {
      isLegendFontSizeSelected: (o) => parseInt(o) === parseInt(legendFontSize),
    };
  },

  _handleModalShown() {
    this.$('#titlebar-view-name').focus();
  },

  /**
   * Submit the legend form and to edit the legend's contents
   */
  _submitForms() {
    const formData = this._$mapForm.serializeObject();

    this._$loader.show();
    this._$submitButton.hide();

    return new Promise((resolve, reject) => {
      this.model
        .save({ legend_font_size: formData.legend_font_size })
        .done(resolve)
        .fail(reject);
    })
      .then(this._hideModal.bind(this))
      .catch(
        this._displayErrors.bind(
          this,
          $('<p>There was an error editing the legend. Please try again.</p>'),
        ),
      );
  },

  /**
   * Return all the data needed to render template correctly
   */
  _getTemplateData() {
    const title = 'Edit Legend';
    const map = this.model;
    const legendFontSize = map.get('legend_font_size');

    return {
      title,
      legendFontSize,
      loaderUrl: APP.assetsRoot + 'ajax-login-loader.gif',
      isLegendFontSizeSelected: (o) => parseInt(o) === parseInt(legendFontSize),
    };
  },
});

Backbone.addSingletonToView(EditTitlebarView);
module.exports = EditTitlebarView;
