/* eslint-disable */
import IntervalFrequency from '../../../../../../../lib/IntervalFrequency';

let projectStateConfig = require('mapistry-project-state-config');
let apiCaller = require('../../../../../../../apiCaller');
let DownloadParameterFileItemView = require('./DownloadParameterFileItemView');

let AddOrDownloadParametersItemView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/addOrDownloadParameters.pug'),

  regions: {
    downloadRegion: '#download-region',
  },

  templateHelpers() {
    let project = this.projectModel.toJSON();
    let isCaliforniaIndustrial =
      projectStateConfig.isCaliforniaIndustrial(project);
    let allSamplingParameters = require('industrial-stormwater-sector-data')(
      this.projectModel.get('state'),
    ).getAvailableSamplingParameters();
    let existingSampleParameterSlugs =
      this.samplingEventParameterCollection.pluck('slug');
    let availableSampleParameters = allSamplingParameters.filter(
      (parameter) => {
        return !_.contains(existingSampleParameterSlugs, parameter.slug);
      },
    );

    let phNotice = `We do not use the pH value from uploaded files. ${
      isCaliforniaIndustrial
        ? 'pH should be entered into the related Sampling Observation form instead, ideally while you are in the field.'
        : 'pH should be manually entered below.'
    }`;

    return {
      availableSampleParameters: availableSampleParameters,
      isCaliforniaIndustrial: isCaliforniaIndustrial,
      phNotice: phNotice,
    };
  },

  behaviors: {
    DisplayMessages: {},
  },

  ui: {
    $select: 'select',
    $hiddenFileUploadInput: '#selected-sampling-upload-file',
    $fakeFileUploadInput: '#fake-sampling-file-upload-input',
    $uploadButton: '#upload-sampling-results-button',

    // used by behaviors
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
  },

  events: {
    submit: '_createSampleEventParameter',
    'click @ui.$uploadButton': '_uploadFile',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.samplingEventId = options.samplingEventId;
    this.samplingEventParameterCollection =
      options.samplingEventParameterCollection;
  },

  onRender() {
    this.ui.$select.select2();
    this.ui.$hiddenFileUploadInput.change(
      function () {
        this.ui.$fakeFileUploadInput.val(this.ui.$hiddenFileUploadInput.val());
      }.bind(this),
    );
  },

  onShow() {
    this._renderDownloadButton();
  },

  _renderDownloadButton() {
    let downloadView = new DownloadParameterFileItemView({
      sampling_event_id: this.samplingEventId,
      project_id: this.projectModel.id,
    });
    this.downloadRegion.show(downloadView);
  },

  _createSampleEventParameter(e) {
    e.preventDefault();
    let slug = this.ui.$select.val();

    if (!slug) {
      return;
    }

    let project = this.projectModel.toJSON();
    let projectId = project.id;

    return apiCaller
      .createParameterForLocationsInSamplingEvent(
        this.projectModel.id,
        this.samplingEventId,
        slug,
      )
      .then(() => apiCaller.getSamplingEvent(projectId, this.samplingEventId))
      .then((samplingEvent) => {
        let SamplingEventModel = require('./SamplingEventModel');
        let samplingEventModel = new SamplingEventModel(samplingEvent);
        let parameters = samplingEventModel.get('parameters');

        this.samplingEventParameterCollection.add(
          _.findWhere(parameters, { slug }),
        );
        return this._addNewParameterToSamplingParametersCollection(slug);
      });
  },

  _addNewParameterToSamplingParametersCollection(parameterSlug) {
    const samplingParametersCollection =
      require('../../../../../../../models_collections/samplingParametersCollection').singleton();
    return samplingParametersCollection.createPromise(
      {
        discharge_locations: [],
        frequency: IntervalFrequency.BIANNUAL,
        parameter_slug: parameterSlug,
        project_id: this.projectModel.id,
        status: 'baseline',
      },
      { wait: true },
    );
  },
  _uploadFile() {
    let fileName = this.ui.$hiddenFileUploadInput.val();
    if (!fileName) {
      return this.triggerMethod(
        'ShowError',
        'You must select a file before uploading.',
      );
    }

    if (!/csv$/i.test(fileName)) {
      return this.triggerMethod(
        'ShowError',
        'File must be formatted as a CSV (Comma-Separated Values).',
      );
    }

    let reader = new FileReader();
    let file = this.ui.$hiddenFileUploadInput.get(0).files[0];

    reader.onloadend = this._handleFileReadResultsObject.bind(this);
    reader.readAsText(file, 'utf8');
  },

  _handleFileReadResultsObject(resultsObject) {
    let fileContents =
      resultsObject && resultsObject.target && resultsObject.target.result;

    // Gracefully deal with files with CR line terminators
    fileContents = fileContents.replace(/\r/g, '\n');

    if (!this._fileHasEnoughLines(fileContents)) {
      return this.triggerMethod(
        'ShowError',
        'File must be a CSV and must contain at least 1 line.',
      );
    }

    apiCaller
      .uploadSamplingResultsFile(
        this.projectModel.id,
        this.samplingEventId,
        fileContents,
      )
      .bind(this)
      .then(function () {
        this.triggerMethod('ShowSuccess', 'File upload successful!');
        return apiCaller
          .getSamplingEvent(this.projectModel.id, this.samplingEventId)
          .then((results) =>
            this.samplingEventParameterCollection.reset(results.parameters),
          );
      })
      .catch(function (error) {
        this.triggerMethod('ShowError', error);
      });
  },

  _fileHasEnoughLines(fileContents) {
    let matches = fileContents && fileContents.match(/\n/g);
    return matches && matches.length >= 1;
  },
});

module.exports = AddOrDownloadParametersItemView;
