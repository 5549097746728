import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '@material-ui/core';
import { usePrevious } from '@monorepo/shared/hooks/usePrevious';

interface OnboardingDocumentRequestTitleInputProps {
  onFinishEditing: (title: string) => void;
  title: string;
}

export function OnboardingDocumentRequestTitleInput(
  props: OnboardingDocumentRequestTitleInputProps,
) {
  const { onFinishEditing } = props;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/destructuring-assignment
  const initialTitle = props.title;

  const [currentTitle, setCurrentTitle] = useState(initialTitle);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = e.target.value;
    setCurrentTitle(updatedTitle);
  }, []);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const updatedTitle = e.target.value;
      if (updatedTitle !== initialTitle) onFinishEditing(updatedTitle);
    },
    [onFinishEditing, initialTitle],
  );

  const prevInitialTitle = usePrevious(initialTitle);
  useEffect(() => {
    const initialTitleChanged = initialTitle !== prevInitialTitle;
    const initialTitleDifferentThanCurrent = initialTitle !== currentTitle;
    if (initialTitleChanged && initialTitleDifferentThanCurrent) {
      setCurrentTitle(initialTitle);
    }
  }, [initialTitle, prevInitialTitle, currentTitle]);

  return (
    <Input value={currentTitle} onChange={handleChange} onBlur={handleBlur} />
  );
}
