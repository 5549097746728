var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (deleteClass, displayEditName, displayEditValue, displayLabelName, displayLabelValue, formatMultiSelect, geocoded, id, isList, isOptionSelected, listValues1, listValues2, name, popoverCheckList, selectMap, type, value) {
      pug_html = pug_html + "\u003Ctd" + (" class=\"attr-details name\""+pug.attr("value", name, true, true)) + "\u003E";
if (isList) {
pug_html = pug_html + "\u003Clabel\u003EProduct Name\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelName + geocoded], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if ((geocoded)) {
pug_html = pug_html + "\u003Ca class=\"glyphicon glyphicon-globe geolocated-popover\" data-container=\"td\" data-toggle=\"popover\" data-placement=\"top\" data-content=\"The location of this marker is defined by its address. If you change the address the marker will move.\"\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-name",displayEditName], [false,false,false,true]), false, true)+" name=\"name\""+pug.attr("value", name, true, true)) + "\u003E";
if (isList) {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger list-value-validations\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E";
if (isList) {
pug_html = pug_html + "\u003Ctd\u003E";
// iterate listValues1
;(function(){
  var $$obj = listValues1;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("data-key", kvp.key, true, true)) + "\u003E";
if (kvp.key !== 'chemical-attrs' && kvp.isShown) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "&nbsp";
if ((popoverCheckList[kvp.key])) {
pug_html = pug_html + "\u003Ca class=\"badge small\" data-toggle=\"popover\" data-content=\"The description of this container must only contain letters. Commas, numbers, symbols, etc. are not allowed.\"\u003E?\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E";
if ((kvp.type === 'select')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = selectMap.get(kvp.key) ? selectMap.get(kvp.key).get(kvp.value) : '') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var item = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var item = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else
if ((kvp.type === 'multiselect')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = formatMultiSelect(kvp)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+" data-toggle=\"multiselect\""+pug.attr("name", kvp.key, true, true)+pug.attr("multiple", true, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var item = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var item = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)+pug.attr("value", kvp.value || '', true, true)) + "\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("data-key", kvp.key, true, true)) + "\u003E";
if (kvp.key !== 'chemical-attrs' && kvp.isShown) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "&nbsp";
if ((popoverCheckList[kvp.key])) {
pug_html = pug_html + "\u003Ca class=\"badge small\" data-toggle=\"popover\" data-content=\"The description of this container must only contain letters. Commas, numbers, symbols, etc. are not allowed.\"\u003E?\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E";
if ((kvp.type === 'select')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = selectMap.get(kvp.key) ? selectMap.get(kvp.key).get(kvp.value) : '') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var item = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var item = $$obj[pug_index3];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else
if ((kvp.type === 'multiselect')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = formatMultiSelect(kvp)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+" data-toggle=\"multiselect\""+pug.attr("name", kvp.key, true, true)+pug.attr("multiple", true, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var item = $$obj[pug_index4];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var item = $$obj[pug_index4];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)+pug.attr("value", kvp.value || '', true, true)) + "\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftd\u003E\u003Ctd\u003E";
// iterate listValues2
;(function(){
  var $$obj = listValues2;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var kvp = $$obj[pug_index5];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("data-key", kvp.key, true, true)) + "\u003E";
if (kvp.key !== 'chemical-attrs' && kvp.isShown) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "&nbsp";
if ((popoverCheckList[kvp.key])) {
pug_html = pug_html + "\u003Ca class=\"badge small\" data-toggle=\"popover\" data-content=\"The description of this container must only contain letters. Commas, numbers, symbols, etc. are not allowed.\"\u003E?\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E";
if ((kvp.type === 'select')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = selectMap.get(kvp.key) ? selectMap.get(kvp.key).get(kvp.value) : '') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var item = $$obj[pug_index6];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var item = $$obj[pug_index6];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else
if ((kvp.type === 'multiselect')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = formatMultiSelect(kvp)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+" data-toggle=\"multiselect\""+pug.attr("name", kvp.key, true, true)+pug.attr("multiple", true, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index7 = 0, $$l = $$obj.length; pug_index7 < $$l; pug_index7++) {
        var item = $$obj[pug_index7];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index7 in $$obj) {
      $$l++;
      var item = $$obj[pug_index7];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)+pug.attr("value", kvp.value || '', true, true)) + "\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var kvp = $$obj[pug_index5];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("data-key", kvp.key, true, true)) + "\u003E";
if (kvp.key !== 'chemical-attrs' && kvp.isShown) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = kvp.label) ? "" : pug_interp)) + "&nbsp";
if ((popoverCheckList[kvp.key])) {
pug_html = pug_html + "\u003Ca class=\"badge small\" data-toggle=\"popover\" data-content=\"The description of this container must only contain letters. Commas, numbers, symbols, etc. are not allowed.\"\u003E?\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Flabel\u003E";
if ((kvp.type === 'select')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = selectMap.get(kvp.key) ? selectMap.get(kvp.key).get(kvp.value) : '') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index8 = 0, $$l = $$obj.length; pug_index8 < $$l; pug_index8++) {
        var item = $$obj[pug_index8];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index8 in $$obj) {
      $$l++;
      var item = $$obj[pug_index8];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", kvp.value === item.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else
if ((kvp.type === 'multiselect')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = formatMultiSelect(kvp)) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+" data-toggle=\"multiselect\""+pug.attr("name", kvp.key, true, true)+pug.attr("multiple", true, true, true)) + "\u003E";
// iterate kvp.options
;(function(){
  var $$obj = kvp.options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index9 = 0, $$l = $$obj.length; pug_index9 < $$l; pug_index9++) {
        var item = $$obj[pug_index9];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index9 in $$obj) {
      $$l++;
      var item = $$obj[pug_index9];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item, kvp.value), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value","attr-list-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("name", kvp.key, true, true)+pug.attr("value", kvp.value || '', true, true)) + "\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Cdiv class=\"chemical-attributes-select\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd" + (" class=\"value\""+pug.attr("value", value, true, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["attr-cell",displayLabelValue], [false,true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = value) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
if (type === 'select') {
pug_html = pug_html + "\u003Cselect" + (pug.attr("class", pug.classes(["form-control","edit-input","select-value","edit-value",displayEditValue], [false,false,false,false,true]), false, true)+pug.attr("id", id, true, true)+pug.attr("name", value, true, true)) + "\u003E\u003C\u002Fselect\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (pug.attr("class", pug.classes(["form-control","edit-input","edit-value",displayEditValue], [false,false,false,true]), false, true)+" name=\"value\""+pug.attr("value", value, true, true)) + "\u003E";
}
pug_html = pug_html + "\u003C\u002Ftd\u003E";
}
if (isList) {
pug_html = pug_html + "\u003Ctd\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn","delete",deleteClass], [false,false,true]), false, true)) + "\u003E\u003Cspan class=\"glyphicon glyphicon-trash\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd colspan=\"2\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn","delete",deleteClass], [false,false,true]), false, true)) + "\u003E\u003Cspan class=\"glyphicon glyphicon-trash\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Ftd\u003E";
}
    }.call(this, "deleteClass" in locals_for_with ?
        locals_for_with.deleteClass :
        typeof deleteClass !== 'undefined' ? deleteClass : undefined, "displayEditName" in locals_for_with ?
        locals_for_with.displayEditName :
        typeof displayEditName !== 'undefined' ? displayEditName : undefined, "displayEditValue" in locals_for_with ?
        locals_for_with.displayEditValue :
        typeof displayEditValue !== 'undefined' ? displayEditValue : undefined, "displayLabelName" in locals_for_with ?
        locals_for_with.displayLabelName :
        typeof displayLabelName !== 'undefined' ? displayLabelName : undefined, "displayLabelValue" in locals_for_with ?
        locals_for_with.displayLabelValue :
        typeof displayLabelValue !== 'undefined' ? displayLabelValue : undefined, "formatMultiSelect" in locals_for_with ?
        locals_for_with.formatMultiSelect :
        typeof formatMultiSelect !== 'undefined' ? formatMultiSelect : undefined, "geocoded" in locals_for_with ?
        locals_for_with.geocoded :
        typeof geocoded !== 'undefined' ? geocoded : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "isList" in locals_for_with ?
        locals_for_with.isList :
        typeof isList !== 'undefined' ? isList : undefined, "isOptionSelected" in locals_for_with ?
        locals_for_with.isOptionSelected :
        typeof isOptionSelected !== 'undefined' ? isOptionSelected : undefined, "listValues1" in locals_for_with ?
        locals_for_with.listValues1 :
        typeof listValues1 !== 'undefined' ? listValues1 : undefined, "listValues2" in locals_for_with ?
        locals_for_with.listValues2 :
        typeof listValues2 !== 'undefined' ? listValues2 : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "popoverCheckList" in locals_for_with ?
        locals_for_with.popoverCheckList :
        typeof popoverCheckList !== 'undefined' ? popoverCheckList : undefined, "selectMap" in locals_for_with ?
        locals_for_with.selectMap :
        typeof selectMap !== 'undefined' ? selectMap : undefined, "type" in locals_for_with ?
        locals_for_with.type :
        typeof type !== 'undefined' ? type : undefined, "value" in locals_for_with ?
        locals_for_with.value :
        typeof value !== 'undefined' ? value : undefined));
    ;;return pug_html;};
module.exports = template;