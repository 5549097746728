/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  className: 'form-group',

  ui: {
    $input: 'input',
  },

  template: require('./templates/contactRow.pug'),
});
