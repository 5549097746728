/* eslint-disable */
// Views
var ErrorController = require('./ErrorController');
var BaseRouter = require('../BaseRouter');

var ErrorRouter = BaseRouter.extend({
  controller: new ErrorController(),

  appRoutes: {
    error: 'handleError',
  },
});

module.exports = ErrorRouter;
