import BluebirdPromise from 'bluebird';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import _ from 'underscore';

if (process.env.NODE_ENV !== 'development') {
  import('../../../server/dataDogRum');
}

BluebirdPromise.config({
  warnings: {
    wForgottenReturn: false,
  },
});
BluebirdPromise.noConflict();
window._ = _;

/* eslint-disable @typescript-eslint/no-var-requires */
window.Backbone.$ = window.$;

require('../lib/backboneOverrides');
require('./appPageBoot')();
