import {
  getLessThanNumberMatch,
  ValidNumberString,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { NOT_DETECTED } from './types';

export function parseParameterValue(
  value: string | number,
  reportingLimit?: ValidNumberString,
  detectionLimit?: ValidNumberString,
): number | typeof NOT_DETECTED {
  if (value === NOT_DETECTED) return value;

  // "<X" values are considered to be not detected
  const match = getLessThanNumberMatch(value);
  if (match.hasMatch) {
    return NOT_DETECTED;
  }

  // If a value is greater than a detection limit, a lab must have estimated it, and it is valid,
  // no matter whether it's less than a reporting limit or not.
  if (detectionLimit) {
    const isLessThanDetectionLimit = Number(value) < Number(detectionLimit);
    return isLessThanDetectionLimit ? NOT_DETECTED : Number(value);
  }

  const isLessThanReportingLimit =
    reportingLimit && Number(value) < Number(reportingLimit);

  return isLessThanReportingLimit ? NOT_DETECTED : Number(value);
}

export function parseRawValue(
  value: string | number,
  unitSlug: string | undefined,
  unitOptions: { label: string; value: string }[],
): string {
  if (value === NOT_DETECTED) return value;

  let parsedValue = value;
  const match = getLessThanNumberMatch(value);
  if (match.hasMatch) {
    parsedValue = `<${match.number}`;
  }

  const unit = unitOptions.find((u) => u.value === unitSlug);
  if (!unit) return String(parsedValue);

  const unitsLabel = unit.label;
  return `${parsedValue} ${unitsLabel}`;
}
