/* eslint-disable */
var WebMapMarkerFactory = require('./WebMapMarkerFactory');
var WebMapPolylineFactory = require('./WebMapPolylineFactory');
var WebMapPolygonFactory = require('./WebMapPolygonFactory');

var WebMapShapeFactory = {
  create: function (shapeModel) {
    switch (shapeModel.get('type')) {
      case 'marker':
        return WebMapMarkerFactory.create(shapeModel);
      case 'polyline':
        return WebMapPolylineFactory.create(shapeModel);
      case 'polygon':
        return WebMapPolygonFactory.create(shapeModel);
    }
  },
};

module.exports = WebMapShapeFactory;
