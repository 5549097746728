import { ProjectWithUsersResponse } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createKey = ({
  organizationId,
  permission,
}: Api.FetchAllProjectsOnOrgWithUsersProps) =>
  ['org-projects-with-users', organizationId, permission] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchAllProjectsOnOrgWithUsers
>;

const fetcher: Fetcher = (_, organizationId, permission) =>
  Api.fetchAllProjectsOnOrgWithUsers({ organizationId, permission });

type UseOrganizationProjectsProps =
  Partial<Api.FetchAllProjectsOnOrgWithUsersProps> & {
    config?: QueryConfig<ProjectWithUsersResponse[], Api.ErrorResponse>;
  };

export function useOrgProjectsWithUsers({
  config: inputConfig,
  organizationId,
  permission,
}: UseOrganizationProjectsProps) {
  const key = organizationId
    ? createKey({ organizationId, permission })
    : undefined;
  const config = {
    enabled: !!organizationId,
    ...inputConfig,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    projectsWithUsers: data,
  };
}
