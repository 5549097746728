/* eslint-disable */
let documentCompositeView = require('../documents/DocumentsCompositeView');

module.exports = documentCompositeView.extend({
  template: require('./templates/exceedance-reports.pug'),
  childView: require('./exceedanceReportRowItemView'),
  childViewContainer: '#exceedance-report-list',
  emptyView: require('./NoExceedanceReportsView'),

  childViewOptions() {
    return {
      isDisabled: this.isDisabled,
      projectId: this.projectId,
    };
  },

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
    this.projectId = options.project.id;
  },
});
