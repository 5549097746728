import { HelpIcon } from '@monorepo/shared/components';
import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import { CUSTOM_FREQUENCY } from '@monorepo/shared/contexts/FrequencyContext/types';
import { CustomFrequency, IntervalFrequencyEnum } from 'mapistry-shared';
import React from 'react';
import { isNullOrUndefined } from '../../../utils';
import { FrequencySelect } from '../../frequencyForm';
import { Limit, LimitItemFormSubmission } from './LimitItems/types';
import { LoggedItemFormSubmission } from './LoggedItems/types';

const INTERVAL_FREQUENCY_ENUM_GROUP = [CUSTOM_FREQUENCY];
const FREQUENCIES = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.YEAR,
  INTERVAL_FREQUENCY_ENUM_GROUP,
];

const FREQUENCIES_WITH_AS_NEEDED = [
  IntervalFrequencyEnum.AS_NEEDED,
  ...FREQUENCIES,
];

interface SelectFrequencyProps {
  errors: { [field: string]: string };
  menuRef: React.RefObject<HTMLDivElement>;
  onChange: (
    frequency?: IntervalFrequencyEnum | null,
    customFrequency?: CustomFrequency,
  ) => void;
}

interface SelectLoggedItemFrequencyProps extends SelectFrequencyProps {
  customFrequencyFieldName: 'customFrequency';
  frequencyFieldName: 'frequency';
  item: LoggedItemFormSubmission;
  limit?: never;
}

interface SelectLimitItemFrequencyProps extends SelectFrequencyProps {
  customFrequencyFieldName: 'limitCustomFrequency';
  frequencyFieldName: 'limitFrequency';
  item: LimitItemFormSubmission;
  limit: Limit;
}

const isLoggedItem = (
  x: LoggedItemFormSubmission | LimitItemFormSubmission,
): x is LoggedItemFormSubmission => 'isLocked' in x;

export const SelectFrequency = <
  T extends LoggedItemFormSubmission | LimitItemFormSubmission,
>(
  props: T extends LoggedItemFormSubmission
    ? SelectLoggedItemFrequencyProps
    : SelectLimitItemFrequencyProps,
) => {
  const {
    customFrequencyFieldName,
    errors,
    frequencyFieldName,
    item,
    limit,
    menuRef,
    onChange,
  } = props;
  return (
    <>
      <FrequencyProvider
        selectedFrequency={
          isLoggedItem(item)
            ? /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              item[frequencyFieldName]
            : limit?.[frequencyFieldName]
        }
        customFrequency={
          isLoggedItem(item)
            ? /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              item[customFrequencyFieldName]
            : /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              limit?.[customFrequencyFieldName]
        }
        noRepeat
        noRepeatOn={!isLoggedItem(item)}
        noStartDate
        onFrequencyChange={onChange}
      >
        <FrequencySelect
          className="flex-grow-1"
          customFrequencyMessageStart={`This ${
            isLoggedItem(item) ? 'logged item' : 'limit'
          } will be effective`}
          customPopperHeader="Frequency selector"
          customPopperSubHeader="Select a frequency"
          disabled={
            isLoggedItem(item)
              ? item.isLocked
              : isNullOrUndefined(item.resourceId)
          }
          error={!!errors?.[frequencyFieldName]}
          frequencyOptions={
            isLoggedItem(item) ? FREQUENCIES_WITH_AS_NEEDED : FREQUENCIES
          }
          isClearable={false}
          menuPlacement="auto"
          menuPortalTarget={menuRef.current}
          placeholder={isLoggedItem(item) ? undefined : 'Frequency'}
          showCustomFrequencyEditButton={
            isLoggedItem(item) ? !item.isLocked : true
          }
        />
      </FrequencyProvider>
      {isLoggedItem(item) && item.isLocked && (
        <HelpIcon text="Updates to this logged item is limited because data has already been logged or it is being used in other calculations" />
      )}
    </>
  );
};
