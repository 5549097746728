/* eslint-disable */
let MapistryProjectStateConfig = require('mapistry-project-state-config');
let moment = require('moment');

var apiCaller = require('../../../../../../../apiCaller');

var SamplingEventLabelCollection = Backbone.Collection.extend({
  comparator: 'event_date',

  url() {
    return apiCaller.getSamplingEventsIndexUrl(
      this.projectId,
      this.startDate,
      this.endDate,
    );
  },

  parse(results) {
    if (!this.skipBlankSamplingEvent && !results.length) {
      results.push(this._getBlankSamplingEvent());
    }

    return results.length < 7 || this.skipBlankSamplingEvent
      ? this._doNotGroup(results)
      : this._groupBySwpppYear(results);
  },

  initialize(models, options = {}) {
    this.project = options.project;
    this.projectId = options.projectId;
    this.startDate = options.startDate;
    this.endDate = options.endDate;
    this.skipBlankSamplingEvent = options.skipBlankSamplingEvent;
  },

  isGrouped() {
    return !!this._isGrouped;
  },

  _getBlankSamplingEvent() {
    return {
      label: 'New Sampling Event',
    };
  },

  _doNotGroup(results) {
    this._isGrouped = false;
    return results;
  },

  _groupBySwpppYear(rows) {
    this._isGrouped = true;
    let groupedRows = _.groupBy(rows, (r) =>
      MapistryProjectStateConfig.getSwpppYearStartDateForDate(
        this.project,
        r.event_date,
      ),
    );

    return _.sortBy(
      _.map(
        groupedRows,
        (rows, dateIdentifier) => {
          return {
            collection: new Backbone.Collection(rows),
            groupId:
              moment(dateIdentifier, 'YYYY MM').format('YYYY-MM') +
              '-sampling-results',
            label: this._getSwpppYearLabel(dateIdentifier),
          };
        },
        this,
      ),
      'label',
    );
  },

  _getSwpppYearLabel(dateIdentifier) {
    if (!dateIdentifier) {
      return 'No date specified';
    }
    return (
      moment(dateIdentifier, 'ddd MMM DD YYYY').format('YYYY MMM') +
      ' - ' +
      moment(dateIdentifier, 'ddd MMM DD YYYY')
        .subtract(1, 'month')
        .add(1, 'year')
        .format('YYYY MMM')
    );
  },
});

module.exports = SamplingEventLabelCollection;
