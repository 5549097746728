/* eslint-disable */
module.exports = Backbone.Model.extend({
  defaults: {
    warnings: [],
    errors: [],
  },

  urlRoot: function () {
    return this.collection.urlWithoutQueryParams();
  },
});
