/* eslint-disable */
import { FormSubmissionService } from '../../../../../../../../services/FormSubmissionService';
const apiCaller = require('../../../../../../../../apiCaller');
const errorView =
  require('../../../../../../../../modals/errorView').singleton();
const infoView = require('../../../../../../../../modals/infoView').singleton();

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/swpppHeader.pug'),

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadSwpppCollection = options.uploadSwpppCollection;
    this.projectModel = options.projectModel;
    this.planName = options.planName;
    this.hasAlreadyRequestedDownload = false;

    this._fetchSwpppSubmission();
  },

  ui: {
    $uploadSwpppPdf: '#upload-swppp-pdf',
    $faCloudUpload: '.fa-cloud-upload',
    $faCloudDownload: '.fa-cloud-download',
    $faSpinner: '.fa-spinner',
    $downloadFormPdf: '#download-form-pdf',
  },

  templateHelpers() {
    const swpppSubmissionId = this.swpppSubmissionId;

    return {
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      swpppUpload: this.uploadSwpppCollection.length,
      isSwpppProject: this.projectModel.isSwpppProject(),
      isStormwaterComplianceEnabled:
        this.projectModel.isStormwaterComplianceEnabled(),
      swpppSubmissionId,
      planName: this.planName,
      hasAlreadyRequestedDownload: this.hasAlreadyRequestedDownload,
    };
  },

  behaviors: {
    Tooltip: {},
  },

  events: {
    'click .fa-cloud-download': '_handleClickDownloadUserSuppliedSWPPP',
    'click .view-swppp': '_handleClickViewSwppp',
    'click .download-user-supplied-pdf':
      '_handleClickDownloadUserSuppliedSWPPP',
    'click .fa-cloud-upload': '_handleFileUpload',
    'click .upload-user-supplied-pdf': '_handleFileUpload',
    'change #upload-swppp-pdf': '_saveUploadedSWPPP',
    'click #download-form-pdf': '_downloadFormPdf',
  },

  _downloadFormPdf() {
    if (!this.hasAlreadyRequestedDownload) {
      // close any open tooltips so they aren't stuck if we re-render & their anchors are removed
      document.getElementsByClassName('tipsy').forEach((el) => el.remove());
      new FormSubmissionService()
        .requestPdf(this.swpppSubmissionId)
        .then(() => {
          this.hasAlreadyRequestedDownload = true;
          this.render();
        });
    }
  },

  _handleClickViewSwppp() {
    if (!this.hasProjectUpdatePermission) {
      infoView.showModal({
        text:
          'You do not have sufficient permissions to edit this ' +
          this.planName +
          '.',
      });
      return;
    }

    if (!this.projectModel.isSwpppProject()) {
      infoView.showModal({
        title: 'This Feature Is Not Included In Your Plan',
        text:
          'Mapistry has a very easy tool for creating ' +
          this.planName +
          's. Just answer a series of questions and we will generate the ' +
          this.planName +
          ' document for you! Please contact Mapistry if you are interested in our ' +
          this.planName +
          ' Builder! You can contact us with the chat box in the lower right hand corner of your screen.',
      });
      return;
    }

    this._goToSwppp();
  },

  _handleClickDownloadUserSuppliedSWPPP() {
    window.open(
      this.uploadSwpppCollection
        .at(this.uploadSwpppCollection.length - 1)
        .get('url'),
    );
  },

  _goToSwppp() {
    let swpppFormSlug = this.projectModel.getSwpppFormSlug();
    const url = apiCaller.getFormSubmissionUrl(
      this.projectModel.id,
      swpppFormSlug,
      swpppFormSlug,
    );
    window.top.location.href = url;
  },

  _fetchSwpppSubmission() {
    apiCaller.getSwpppSubmissionId(this.projectModel.id).then((resp) => {
      if (resp && resp.swpppSubmissionId) {
        this.swpppSubmissionId = resp.swpppSubmissionId;
        this.render();
      }
    });
  },

  _handleFileUpload() {
    this.ui.$uploadSwpppPdf.click();
  },

  toggleButtons() {
    if (this.ui.$faSpinner.css('display') === 'none') {
      this.ui.$faSpinner.css('display', 'inline-block');
    } else {
      this.ui.$faSpinner.css('display', 'none');
    }

    this.ui.$faCloudUpload.toggle();
    this.ui.$faCloudDownload.toggle();
  },

  _saveUploadedSWPPP() {
    let file = this.ui.$uploadSwpppPdf.prop('files')[0];
    let data = new FormData();
    data.append('file', file);
    let options = { data: data, processData: false, contentType: false };
    this.toggleButtons();

    this.uploadSwpppCollection
      .createPromise(null, options)
      .bind(this)
      .then(this.render.bind(this))
      .catch(() => {
        this.toggleButtons();
        let errorMsg =
          'There was an error on the server, and your ' +
          this.planName +
          ' could not be uploaded. Please try again or contact us if the problem persists.';
        errorView.showModal({ error: errorMsg });
      });
  },
});
