/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import IntervalFrequency from '../../../../lib/IntervalFrequency';
import {
  Button,
  ConfirmDialog,
  FieldWrapper,
  MapistryTooltip,
  Modal,
  NoLocationsDefined,
  SaveButton,
  Select,
  TextField,
} from '../../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function FlowLogSettingsModal({
  formDraft,
  formErrors,
  goToMapsURL,
  monitoringLocations,
  onAddLimitGroup,
  onClose,
  onConfirmCancel,
  onConfirmClose,
  onRemoveLimitGroup,
  onSave,
  open,
  saveState,
  showDirtyFormConfirmation,
  stageEdit,
}) {
  const { limitGroups } = formDraft;

  const calculationMethodOptions = [
    { label: 'average per day', value: 'average_per_day' },
    { label: 'max per day', value: 'max_per_day' },
    { label: 'average per month', value: 'average_per_month' },
    { label: 'total per month', value: 'total_per_month' },
    { label: 'average per year', value: 'average_per_year' },
    { label: 'total per year', value: 'total_per_year' },
  ];
  const toCalculationMethod = (frequency, calculationType) => {
    const { DAY, MONTH, YEAR } = IntervalFrequency;
    if (frequency === DAY && calculationType === 'average') {
      return 'average_per_day';
    }
    if (frequency === DAY && calculationType === 'max') {
      return 'max_per_day';
    }
    if (frequency === MONTH && calculationType === 'average') {
      return 'average_per_month';
    }
    if (frequency === MONTH && calculationType === 'total') {
      return 'total_per_month';
    }
    if (frequency === YEAR && calculationType === 'average') {
      return 'average_per_year';
    }
    if (frequency === YEAR && calculationType === 'total') {
      return 'total_per_year';
    }
    return null;
  };
  const fromCalculationMethod = (calculationMethod) => {
    const { DAY, MONTH, YEAR } = IntervalFrequency;
    const mapping = {
      average_per_day: {
        calculationType: 'average',
        frequency: DAY,
      },
      max_per_day: {
        calculationType: 'max',
        frequency: DAY,
      },
      average_per_month: {
        calculationType: 'average',
        frequency: MONTH,
      },
      total_per_month: {
        calculationType: 'total',
        frequency: MONTH,
      },
      average_per_year: {
        calculationType: 'average',
        frequency: YEAR,
      },
      total_per_year: {
        calculationType: 'total',
        frequency: YEAR,
      },
    };
    return mapping[calculationMethod];
  };

  const limitTypes = {
    flowRate: {
      calculationMethodOptions: calculationMethodOptions.filter(
        ({ value }) => value === 'max_per_day',
      ),
      label: 'Flow rate',
      unitsOptions: [
        { label: 'gallons per minute', value: 'gallons_per_minute' },
      ],
      value: 'flowRate',
    },
    volume: {
      calculationMethodOptions,
      label: 'Volume',
      unitsOptions: [
        { label: 'gallons', value: 'gallons' },
        { label: 'millions of gallons', value: 'millions_of_gallons' },
      ],
      value: 'volume',
    },
  };
  const LimitGroupRows = ({ limitType }) =>
    limitGroups[limitType.value].map((limitGroup, idx) => (
      <div className="row-details" key={limitGroup.key}>
        <div className="limit-type-label">{limitType.label}</div>
        <div className="field-wrapper limit-label">
          <FieldWrapper isRequired label="Limit">
            <TextField
              controlled={false}
              defaultValue={limitGroups[limitType.value][idx].limitValue || ''}
              error={
                !!_get(
                  formErrors,
                  `limitGroups[${limitType.value}][${idx}].limitValue`,
                )
              }
              onBlur={(e) =>
                stageEdit({ limitValue: e.target.value }, idx, limitType.value)
              }
            />
          </FieldWrapper>
        </div>
        <div className="field-wrapper units-label">
          <FieldWrapper isRequired label="Units">
            <Select
              error={
                !!_get(
                  formErrors,
                  `limitGroups[${limitType.value}][${idx}].units`,
                )
              }
              isClearable={false}
              isFixed
              onChange={(opt) =>
                stageEdit({ units: opt.value }, idx, limitType.value)
              }
              options={limitType.unitsOptions}
              value={limitType.unitsOptions.find(
                (option) =>
                  option.value === limitGroups[limitType.value][idx].units,
              )}
            />
          </FieldWrapper>
        </div>
        <div className="field-wrapper calculation-method">
          <FieldWrapper isRequired label="Calculation Method">
            <Select
              error={
                !!_get(
                  formErrors,
                  `limitGroups[${limitType.value}][${idx}].frequency`,
                )
              }
              isClearable={false}
              isFixed
              onChange={(opt) =>
                stageEdit(
                  fromCalculationMethod(opt.value),
                  idx,
                  limitType.value,
                )
              }
              options={limitType.calculationMethodOptions}
              value={limitType.calculationMethodOptions.find(
                (option) =>
                  option.value ===
                  toCalculationMethod(
                    limitGroups[limitType.value][idx].frequency,
                    limitGroups[limitType.value][idx].calculationType,
                  ),
              )}
            />
          </FieldWrapper>
        </div>
        <div className="row-delete actions-label">
          <Button
            className="button-delete"
            color="danger"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveLimitGroup(idx, limitType.value);
            }}
          >
            Delete limit
          </Button>
          <MapistryTooltip placement="top" title="Delete this limit">
            <div
              className="icon-delete"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveLimitGroup(idx, limitType.value);
              }}
            >
              <SvgIcon identifier="trash" className="trash-icon" />
            </div>
          </MapistryTooltip>
        </div>
      </div>
    ));

  const errorComponent = (id, msg) => <div key={`error-${id}`}>{msg}</div>;
  const errorComponents = Object.keys(formErrors.displayable)
    .reduce(
      (accum, displayableKey) => [
        ...accum,
        ...formErrors.displayable[displayableKey],
      ],
      [],
    )
    .map((error) => errorComponent(error.id, error.message));
  return (
    <Modal
      className="flow-log-settings-modal"
      footer={
        <div className="main-actions">
          <Button
            color="secondary"
            disabled={SaveState.SAVING === saveState}
            onClick={onClose}
          >
            {SaveState.DIRTY === saveState ? 'Cancel' : 'Close'}
          </Button>
          <span className="spacer" />
          <SaveButton onSave={onSave} saveState={saveState} />
        </div>
      }
      fullWidth={false}
      header={<h4>Flow Log Configuration</h4>}
      onClose={onClose}
      open={open}
    >
      {monitoringLocations.length === 0 && (
        <NoLocationsDefined
          goToMapsURL={goToMapsURL}
          message="You must define all water monitoring locations before configuring the water flow log."
        />
      )}
      <ConfirmDialog
        confirmButtonText="Close without saving"
        danger
        onCancelled={onConfirmCancel}
        onConfirmed={onConfirmClose}
        open={showDirtyFormConfirmation}
        title="Are you sure you want to close without saving?"
      />
      {monitoringLocations.length > 0 && (
        <div className="limits-table">
          <div className="row-header">
            <div className="limit-type-label">LIMIT TYPE</div>
            <div className="limit-label">LIMIT</div>
            <div className="units-label">UNITS</div>
            <div className="units-label calculation-method">
              CALCULATION METHOD
            </div>
            <div className="actions-label">ACTIONS</div>
          </div>
          <div
            className={`error-row ${
              errorComponents.length > 0 ? 'error-row-shown' : ''
            }`}
          >
            {errorComponents.length > 0 && (
              <>
                <div className="error-row-heading">
                  These are validation errors:
                </div>
                {errorComponents}
              </>
            )}
          </div>
          <div className="limit-type-container">
            <LimitGroupRows limitType={limitTypes.volume} />
            <div className="add-limit-group">
              <div onClick={() => onAddLimitGroup(limitTypes.volume.value)}>
                <SvgIcon identifier="add" />
                <span>Add a volume limit group</span>
              </div>
            </div>
          </div>
          <div className="limit-type-container">
            <LimitGroupRows limitType={limitTypes.flowRate} />
            <div className="add-limit-group">
              <div onClick={() => onAddLimitGroup(limitTypes.flowRate.value)}>
                <SvgIcon identifier="add" />
                <span>Add a flow rate limit group</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

FlowLogSettingsModal.defaultProps = {
  monitoringLocations: [],
};
FlowLogSettingsModal.propTypes = {
  formDraft: PropTypes.shape({
    // limitGroups: { [volume/flowRate]: PropTypes.arrayOf(PropTypes.object) }
    limitGroups: PropTypes.shape({}),
  }).isRequired,
  formErrors: PropTypes.shape({
    // displayableShape => PropTypes.shape({
    //    id: PropTypes.string,
    //    message: PropTypes.string
    // })
    // displayable: { [volume/flowRate]: PropTypes.arrayOf(displayableShape) }
    displayable: PropTypes.shape({}),
    // limitGroups: { [volume/flowRate]: PropTypes.arrayOf(PropTypes.object) }
    limitGroups: PropTypes.shape({}),
  }).isRequired,
  goToMapsURL: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onAddLimitGroup: PropTypes.func.isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  onConfirmClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveLimitGroup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  saveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  showDirtyFormConfirmation: PropTypes.bool.isRequired,
  stageEdit: PropTypes.func.isRequired,
};
