/* eslint-disable */
let apiCaller = require('../../../../../../../../apiCaller');
let infoView = require('../../../../../../../../modals/infoView').singleton();

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/exceedance-report-row.pug'),

  tagName: 'li',

  ui: {
    delete: '.delete-document',
  },

  events: {
    'click .go-to-exceedance-report': '_goToExceedanceReportForm',
    'click .download-document': '_handleClickDownloadExceedanceReport',
  },

  behaviors: {
    Tooltip: { gravity: 'e' },
    WarnBeforeDelete: {},
  },

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
    this.projectId = options.projectId;
  },

  templateHelpers() {
    return {
      isForm: this.model.has('formTemplateSlug'),
      downloadPdfUrl: this.model.has('submissionId')
        ? apiCaller.downloadPdfUrl(this.model.get('submissionId'))
        : null,
    };
  },

  getWarningBeforeDeleteText() {
    return this.model.get('name') + '?';
  },

  _handleClickDownloadExceedanceReport() {
    window.open(this.model.get('src'));
  },

  _goToExceedanceReportForm(e) {
    let currentUserArbiter = require('../../../../../../../../permissions/currentUserArbiter');
    e.preventDefault();

    if (this.isDisabled && this.isDisabled()) {
      return;
    }

    return currentUserArbiter
      .hasProjectUpdatePermission(this.projectId)
      .bind(this)
      .then((hasProjectUpdatePermission) => {
        if (hasProjectUpdatePermission) {
          this._goToForm();
        } else {
          infoView.showModal({
            text: 'You do not have sufficient permissions to edit this observation form.',
          });
        }
      });
  },

  _goToForm() {
    const url = apiCaller.getFormSubmissionUrl(
      this.projectId,
      this.model.get('formTemplateSlug'),
      this.model.get('formSubmissionSlug'),
    );
    window.top.location.href = url;
  },
});
