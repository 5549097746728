/* eslint-disable */
var customSpinnerId = '#mapistryLoader';

// Constructor
function Spinner() {}

Spinner.prototype.spin = function (target) {
  this.stop();
  var clonedNode = $(customSpinnerId).get(0).cloneNode(true);
  clonedNode.setAttribute('id', null);
  var targetNode = $(target).get(0);
  if (targetNode.firstChild) {
    targetNode.insertBefore(clonedNode, targetNode.firstChild);
  } else {
    targetNode.appendChild(clonedNode);
  }
  this.spinner = clonedNode;
};

Spinner.prototype.stop = function () {
  if (this.spinner) {
    $(this.spinner).remove();
    this.spinner = null;
  }
};

module.exports = Spinner;
