/* eslint-disable */
var BaseShapeItemView = require('./BaseShapeItemView');

module.exports = BaseShapeItemView.extend({
  template: require('./templates/aerialimagery.pug'),

  templateHelpers() {
    let fillOpacity = this.model.get('opacity');
    let isFillOpacitySelected = function (f) {
      return parseFloat(f) === parseFloat(fillOpacity);
    };
    return _.extend(BaseShapeItemView.prototype.templateHelpers.call(this), {
      isFillOpacitySelected,
    });
  },

  _formatStyles(newStyles) {
    return { opacity: newStyles.fill_opacity };
  },
});
