/* eslint-disable */
var APP = require('../config');

var DownloadMapButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/downloadMapButton.pug'),

  templateHelpers: function () {
    var apiCaller = require('../apiCaller');

    return {
      screenshotLink: apiCaller.getScreenshotLinkUrl(
        this.projectId,
        this.mapId,
        this.orientation,
        this.mapSize,
      ),
    };
  },

  ui: {
    $button: '.leaflet-button',
    $menu: '.leaflet-download-actions',
    $cancel: '.cancel',
    $mapSize: '.map-size',
  },

  events: {
    'click @ui.$button': '_toggleDownloadPreview',
    'click @ui.$cancel': '_toggleDownloadPreview',
    'change @ui.$mapSize': '_handleChangeMapSize',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  initialize: function (options = {}) {
    this.projectId = options.projectId;
    this.mapId = options.mapId;
    this.orientation = options.orientation || 'landscape';
    this.mapSize = options.mapSize || 'letter';
  },

  onRender: function () {
    this.$(
      'option[value="' + this.mapSize + ',' + this.orientation + '"]',
    ).prop('selected', true);
  },

  _toggleDownloadPreview: function () {
    APP.vent.trigger('downloadMapButtonView:click:downloadMapButton');
    this.trigger('click:downloadMapButton');
  },

  _handleChangeMapSize: function () {
    var mapSizeSelectValues = this.ui.$mapSize.val().split(',');
    var mapSize = mapSizeSelectValues[0];
    var orientation = mapSizeSelectValues[1];

    APP.vent.trigger(
      'downloadMapButtonView:change:mapSize',
      orientation,
      mapSize,
    );
    this.trigger('change:mapSize');
    this.orientation = orientation;
    this.mapSize = mapSize;
    this.render();
  },
});

module.exports = DownloadMapButtonView;
