var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isTeslaFremontLevel2On) {
      pug_html = pug_html + "\u003Cdiv class=\"leaflet-button cad-button\" rel=\"tipsy\" data-toggle=\"tooltip\" data-placement=\"right\" title=\"Toggle Fremont factory Level 2\"\u003E\u003Cspan" + (pug.attr("class", pug.classes([isTeslaFremontLevel2On ? 'active' : ''], [true]), false, true)+" id=\"tesla-fremont-level2\"") + "\u003EL2\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isTeslaFremontLevel2On" in locals_for_with ?
        locals_for_with.isTeslaFremontLevel2On :
        typeof isTeslaFremontLevel2On !== 'undefined' ? isTeslaFremontLevel2On : undefined));
    ;;return pug_html;};
module.exports = template;