/* eslint-disable react/prop-types */

import React from 'react';
import ProgressBar from '../../../elements/ProgressBar';
import Section from './Section';
import SectionParent from './SectionParent';

class FormSidebar extends React.Component {
  getSubmissionGroups(submissionGroups, templateGroupId) {
    return submissionGroups.filter(
      (sg) => sg.formTemplateFieldGroupId === templateGroupId,
    );
  }

  getSections(templateGroups, submissionGroups) {
    return templateGroups.map((templateGroup) => {
      const matchingSubmissionGroups = this.getSubmissionGroups(
        submissionGroups,
        templateGroup.id,
      );

      if (templateGroup.isDuplicable) {
        return this.sectionParent(templateGroup, matchingSubmissionGroups);
      }

      return this.section(templateGroup, matchingSubmissionGroups[0]);
    });
  }

  getSteps(templateGroups, submissionGroups) {
    return templateGroups.reduce(
      (acc, templateGroup) => {
        const matchingSubmissionGroups = this.getSubmissionGroups(
          submissionGroups,
          templateGroup.id,
        );

        acc.complete += matchingSubmissionGroups.reduce(
          (count, group) => count + (group.isComplete ? 1 : 0),
          0,
        );
        acc.total += templateGroup.isDuplicable
          ? matchingSubmissionGroups.length
          : 1;

        return acc;
      },
      { total: 0, complete: 0 },
    );
  }

  submissionGroupHasError(templateGroup, submissionGroup) {
    return (
      templateGroup.hasError ||
      (submissionGroup &&
        submissionGroup.fields.some(
          (field) => field.errors && field.errors.length > 0,
        ))
    );
  }

  sectionParent(templateGroup, submissionGroups) {
    const {
      currentSubmissionGroupNumber,
      currentTemplateGroupId,
      onClickSection,
      onDeleteSubsection,
      onCreateSubsection,
      isStencil,
    } = this.props;
    return (
      <SectionParent
        key={templateGroup.id}
        currentSubmissionGroupNumber={currentSubmissionGroupNumber}
        templateGroupId={templateGroup.id}
        isSelected={templateGroup.id === currentTemplateGroupId}
        isStencil={isStencil}
        name={templateGroup.name}
        subsections={submissionGroups}
        onClick={onClickSection}
        onDeleteSubsection={onDeleteSubsection}
        onCreateSubsection={onCreateSubsection}
      />
    );
  }

  section(templateGroup, submissionGroup) {
    const { currentTemplateGroupId, onClickSection } = this.props;
    const hasError = this.submissionGroupHasError(
      templateGroup,
      submissionGroup,
    );

    return (
      <Section
        key={templateGroup.id}
        templateGroupId={templateGroup.id}
        isSelected={templateGroup.id === currentTemplateGroupId}
        isComplete={submissionGroup && submissionGroup.isComplete}
        name={templateGroup.name}
        error={hasError}
        onClick={() => onClickSection(templateGroup.id, 0)}
      />
    );
  }

  render() {
    const { project, templateGroups, submissionGroups } = this.props;
    const { complete, total } = this.getSteps(templateGroups, submissionGroups);

    return (
      <div className="form-submission-editor-sidebar">
        <div className="sidebar">
          <h1 className="project-name">{project && project.name}</h1>
          <h2 className="sidebar-header">Sections</h2>
          <div className="form-sections">
            {this.getSections(templateGroups, submissionGroups)}
          </div>
        </div>
        <div className="sidebar-footer">
          <ProgressBar
            header="Form Progress"
            complete={complete}
            total={total}
          />
        </div>
      </div>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormSidebar;
