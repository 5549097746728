/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/stormEventRow.pug'),

  tagName: 'li',

  className: 'flex align-items-center',

  behaviors: {
    Tooltip: {},
  },

  initialize() {
    this.listenTo(this.model, 'change', this.render);
  },

  templateHelpers() {
    let stormEvent = this.model;
    let moment = require('moment');
    let rainfallAmt =
      stormEvent && stormEvent.has('rainfall_amt')
        ? ' (' + stormEvent.get('rainfall_amt') + '" of rain)'
        : '';
    return {
      stormLabel: moment(stormEvent.get('storm_start_time')).format(
        'MMM DD, YYYY',
      ),
      rainfallAmt: rainfallAmt,
    };
  },

  onShow() {
    this.delegateEvents(
      _.extend(
        {
          'click .edit-or-delete-storm-event':
            this._handleEditOrDeleteEvent.bind(this),
        },
        this.events,
      ),
    );
  },

  _handleEditOrDeleteEvent() {
    let APP = require('../../../../../../../../config');
    APP.vent.trigger('edit-or-delete-rain-event', this.model);
  },
});
