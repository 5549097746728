/* eslint-disable */
var WebMapFeatureModel = Backbone.Model.extend({
  defaults: {
    isTemp: false,
    geography: undefined,
    type: undefined,
    labelOffsetX: undefined,
    labelOffsetY: undefined,
    rotation: undefined,
  },
});

module.exports = WebMapFeatureModel;
