/* eslint-disable */
var SicCodeSummaryRowView = require('./SicCodeSummaryRowView');

var SicCodeSummaryTableView = Backbone.Marionette.CompositeView.extend({
  tagName: 'table',

  className: 'table table-striped',

  template: require('./templates/sicCodeSummaryTable.pug'),

  childView: SicCodeSummaryRowView,

  childViewContainer: 'tbody',
});

module.exports = SicCodeSummaryTableView;
