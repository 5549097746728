import { RadioGroup } from '@monorepo/shared/components/RadioGroup';
import React from 'react';
import FormField from './FormField';

class BooleanField extends FormField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);

    return (
      <RadioGroup
        options={formattedOptions}
        groupName={templateField.slug}
        onChange={this.handleChange}
        value={this.getValue()}
        error={error}
      />
    );
  }

  formatOptions(templateField) {
    return templateField.options.map((option) => ({
      value: option,
      label: option,
    }));
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default BooleanField;
