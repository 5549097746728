/* eslint-disable */
import * as Sentry from '@sentry/browser';

const apiCaller = require('../../apiCaller');
const currentUser = require('../../currentUser');
const trackGoogleAdwordsConversion = require('../../services/analytics/trackGoogleAdwordsConversion');

const newProjectType = null;

const SignUpView = Backbone.Marionette.ItemView.extend({
  template: require('./sign-up.pug'),

  ui: {
    $errorContainer: '.alert-danger',
    $emailInput: '[name=email]',
    $nameInput: '[name=user-name]',
    $jobTitleInput: '[name=job-title]',
    $passwordInput: '[name=password]',
    $acceptTosInput: '[name=acceptLatestTos]',
    $btnSubmit: '[type=submit]',
    $signUpForm: 'form',
    $tokenErrorMsg: '.token-not-present',
  },

  behaviors: {
    DisplayMessages: {},
    DisplayLoader: {},
  },

  events: {
    'submit @ui.$signUpForm': 'signUp',
  },

  onRender() {
    $('html > body').attr('data-view', 'sign-up');
    const email = this.model.get('email');
    const token = this.model.get('token');

    if (!email || !token) {
      this.ui.$signUpForm.hide();
      this.ui.$tokenErrorMsg.show();
    }
  },

  async signUp(e) {
    e.preventDefault();
    this.triggerMethod('ShowLoader');

    const data = {
      name: this.ui.$nameInput.val(),
      email: this.ui.$emailInput.val(),
      job_title: this.ui.$jobTitleInput.val(),
      password: this.ui.$passwordInput.val(),
      newProjectType,
      acceptLatestTos: this.ui.$acceptTosInput.is(':checked'),
      token: this.model.get('token'),
    };

    try {
      const user = await apiCaller.trySignUp(data);

      // feature flags come with page setup vars and are only accurate on a per user basis
      // once the user is defined (=logged in). If we stayed in the backbone app after logging in,
      // we'd have to refetch the page setup vars here. However, we are forwarding to the vortex app,
      // which refetches pageSetupVars by itself

      currentUser.set(user);

      // If both user id and email are set, only id will be visible in Sentry issue
      Sentry.setUser({
        email: user.email,
      });

      const router = require('../../router');
      router.goToRootUrl();
    } catch (error) {
      this.triggerMethod('ResetLoader');
      this.triggerMethod('ShowError', error, 60000);
    }
  },
});

module.exports = SignUpView;
