/* eslint-disable */
import { KeyService } from '@monorepo/shared/KeyService';

var phoneNumber = require('../../../../services/phoneNumber');
var BaseContactItemView = require('./BaseContactItemView');

module.exports = BaseContactItemView.extend({
  events: {
    'keyup @ui.$input': '_handleKeyUp',
    'change @ui.$input': '_handleChange',
  },

  onRender: function () {
    if (!this.ui.$input.val() || this._isPhoneNumberFormatted()) {
      return;
    }

    this._displayFormattedPhoneNumber();
  },

  _handleKeyUp: function (e) {
    var normalizedPhoneNumber = this._getNormalizedPhoneNumber();
    var isDeletingChars = KeyService.isDeletionKey(e.which);

    if (
      isDeletingChars ||
      normalizedPhoneNumber.length === 0 ||
      normalizedPhoneNumber.length >= 10
    ) {
      return;
    }

    this._displayFormattedPhoneNumber();
  },

  /**
   * This handles browser autocomplete or if user copy and pastes the number
   */
  _handleChange: function () {
    if (this._isPhoneNumberFormatted()) {
      return;
    }

    this._displayFormattedPhoneNumber();
  },

  /**
   * Example of valid formats: (555) 1234-5678, (555) 1234-5678 x999, (555) 1234-5678 ext. 999
   */
  _isPhoneNumberFormatted: function () {
    return phoneNumber.isFormatted(this.ui.$input.val());
  },

  _getNormalizedPhoneNumber: function () {
    return phoneNumber.normalize(this.ui.$input.val());
  },

  _displayFormattedPhoneNumber: function () {
    var formattedPhoneNumber = phoneNumber.format(this.ui.$input.val());
    this.ui.$input.val(formattedPhoneNumber);
  },
});
