import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash.get';

import withProvider from '../../../withProvider';

import FlowLogList from './FlowLogList';

function FlowLogListContainer(props) {
  const { flowLogStats, isFetching, monitoringLocations, onOpenEditModal } =
    props;

  return (
    <FlowLogList
      flowLogStats={flowLogStats}
      isFetching={isFetching}
      monitoringLocations={monitoringLocations}
      onEditFlowLogReading={(id) => onOpenEditModal(id)}
    />
  );
}

FlowLogListContainer.defaultProps = {
  isFetching: false,
};

FlowLogListContainer.propTypes = {
  flowLogStats: PropTypes.object, // eslint-disable-line
  isFetching: PropTypes.bool,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  flowLogLimits: state.wastewater.flowLogLimits,
  flowLogReadings: state.wastewater.flowLogReadings,
  flowLogStats: state.wastewater.flowLogStats,
  isFetching: _get(
    state.wastewater,
    'isFetching.flowLogStatsForRecords',
    false,
  ),
  monitoringLocations: state.wastewater.monitoringLocations,
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(connect(mapStateToProps)(FlowLogListContainer));
