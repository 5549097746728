/* eslint-disable */
var APP = require('../../config');
var markerStyleCollection = require('./markerStyleCollection').singleton();
var polylineStyleCollection = require('./polylineStyleCollection').singleton();
var polygonStyleCollection = require('./polygonStyleCollection').singleton();
var aerialimageryStyleCollection =
  require('./aerialimageryStyleCollection').singleton();

var styleCollectionsWrapper = {
  instance: null,

  singleton: function () {
    if (!this.instance) {
      this.instance = this._initialize(APP.projectId);
    }
    return this.instance;
  },

  _initialize: function () {
    _.extend(this, Backbone.Events);
    this.listenTo(markerStyleCollection, 'add', this._handleAdd);
    this.listenTo(markerStyleCollection, 'change', this._handleChange);
    this.listenTo(polylineStyleCollection, 'add', this._handleAdd);
    this.listenTo(polylineStyleCollection, 'change', this._handleChange);
    this.listenTo(polygonStyleCollection, 'add', this._handleAdd);
    this.listenTo(polygonStyleCollection, 'change', this._handleChange);
    this.listenTo(aerialimageryStyleCollection, 'add', this._handleAdd);
    this.listenTo(aerialimageryStyleCollection, 'change', this._handleChange);
    return this;
  },

  fetch: function (options) {
    switch (options.type) {
      case 'marker':
        markerStyleCollection.fetch({
          success: options.success,
          error: options.error,
        });
        break;
      case 'polyline':
        polylineStyleCollection.fetch({
          success: options.success,
          error: options.error,
        });
        break;
      case 'polygon':
        polygonStyleCollection.fetch({
          success: options.success,
          error: options.error,
        });
      case 'aerialimagery':
        aerialimageryStyleCollection.fetch({
          success: options.success,
          error: options.error,
        });
    }
  },

  fetchAll: function (options) {
    async.parallel(
      [
        function (cb) {
          markerStyleCollection.fetch({
            error: function (collection, response) {
              APP.log({
                func: 'fetchAll',
                backboneFunc: 'fetch',
                collection: 'markerStyle',
                projectId: APP.projectId,
                error: response.responseText,
              });
            },
            complete: function () {
              cb();
            },
          });
        }.bind(this),
        function (cb) {
          polylineStyleCollection.fetch({
            error: function (collection, response) {
              APP.log({
                func: 'fetchAll',
                backboneFunc: 'fetch',
                collection: 'polylineStyle',
                projectId: APP.projectId,
                error: response.responseText,
              });
            },
            complete: function () {
              cb();
            },
          });
        }.bind(this),
        function (cb) {
          polygonStyleCollection.fetch({
            error: function (collection, response) {
              APP.log({
                func: 'fetchAll',
                backboneFunc: 'fetch',
                collection: 'polygonStyle',
                projectId: APP.projectId,
                error: response.responseText,
              });
            },
            complete: function () {
              cb();
            },
          });
        }.bind(this),
      ],

      function () {
        options.complete();
      },
    );
  },

  getStyle: function (type, layerId) {
    switch (type) {
      case 'marker':
        return markerStyleCollection.findWhere({ layer_id: layerId });
      case 'polyline':
        return polylineStyleCollection.findWhere({ layer_id: layerId });
      case 'polygon':
        return polygonStyleCollection.findWhere({ layer_id: layerId });
      case 'aerialimagery':
        return aerialimageryStyleCollection.findWhere({ layer_id: layerId });
    }
  },

  _handleAdd: function () {
    this.trigger('add');
  },

  _handleChange: function (style) {
    this.trigger('change', style);
  },
};

module.exports = styleCollectionsWrapper;
