/* eslint-disable */
/* eslint-disable no-underscore-dangle */
const _ = require('underscore');

const apiCaller = require('../apiCaller');
const AlertMixin = require('../mixins/views/AlertMixin');

const CopyFormView = Backbone.Marionette.ItemView.extend({
  className: 'container-sm text-center margin-top-50',

  // eslint-disable-next-line global-require
  template: require('./templates/copyForm.pug'),

  templateHelpers() {
    const { projectCollection } = this;

    return {
      relatedSubmissions: _.groupBy(this.relatedSubmissions, 'project_id'),
      getProjectName(projectId) {
        return projectCollection.get(projectId).get('name');
      },
    };
  },

  ui: {
    $select: 'select',
  },

  events: {
    'click #create-from-scratch': '_handleCreateFromScratch',
    'click #copy-form': '_handleCopyForm',
    'click #back-to-dashbaord': '_goToDashboard',
  },

  initialize(options) {
    this.projectId = options.projectId;
    this.projectCollection = options.projectCollection;
    this.templateSlug = options.templateSlug;
    this.submissionSlug = options.submissionSlug;
    this.relatedSubmissions = options.relatedSubmissions;
  },

  _handleCreateFromScratch() {
    // eslint-disable-next-line global-require
    const router = require('../router');
    router.goToFormSubmissionEditor({
      projectId: this.projectId,
      templateSlug: this.templateSlug,
      submissionSlug: this.submissionSlug,
    });
  },

  _handleCopyForm() {
    const submissionIdToCopy = this.ui.$select.val();
    this._disableButtons();
    this._goToForm(submissionIdToCopy);
  },

  _goToForm(submissionIdToCopy) {
    // eslint-disable-next-line global-require
    const router = require('../router');

    return apiCaller
      .createFormSubmission(
        this.projectId,
        this.templateSlug,
        this.submissionSlug,
        submissionIdToCopy,
      )
      .then(() => {
        router.goToFormSubmissionEditor({
          projectId: this.projectId,
          templateSlug: this.templateSlug,
          submissionSlug: this.submissionSlug,
        });
      })
      .catch(() => {
        this._showErrors();
        this._enableButtons();
      });
  },

  _enableButtons() {
    this.$('button').prop('disabled', false);
  },

  _disableButtons() {
    this.$('button').prop('disabled', true);
  },

  _goToDashboard(e) {
    e.preventDefault();
    // eslint-disable-next-line global-require
    const router = require('../router');
    return router.goToProjectDashboard(this.projectId);
  },
});

CopyFormView.prototype = _.extend({}, AlertMixin, CopyFormView.prototype);

module.exports = CopyFormView;
