/* eslint-disable */
// Views
var CreateAndEditMapViewBase = require('./CreateAndEditMapViewBase');

// Collections and Models
var mapCollection =
  require('../../../models_collections/mapCollection').singleton();
var featureCollection =
  require('../../../models_collections/featureCollection').singleton();
var NewMapModel = require('../../../models_collections/newAndEditMap/newMapModel');

// Services
var bigSpinner = require('../../../services/bigSpinner');
var APP = require('../../../config');

var CreateMapView = CreateAndEditMapViewBase.extend({
  template: require('./createAndEditMapView.pug'),

  events: {
    'shown.bs.modal': '_handleModalShown',
    'hidden.bs.modal': '_removeView',
    'click #upload-image-wrapper': '_clickRealFileUploadButton',
    'change #btn-hidden-file': '_updateImageName',
    'click .btn-success': '_submitForms',
    'keydown input': '_handleKeyPress',
  },

  setModel: function () {
    this.model = new NewMapModel();
  },

  _handleModalShown: function () {
    this.$('#map-name').focus();
  },

  /**
   * Return all the data needed to render template correctly
   */
  _getTemplateData: function () {
    var projectMapsService = require('../../services/projectMapsService');
    var titlebar = projectMapsService.getSelectedMap().titlebar();
    var title = 'New Map';
    var logoImage = titlebar.get('logo_image');

    var displayVal = function displayVal(name) {
      return titlebar.get(name) ? titlebar.get(name) : '';
    };

    return {
      title: title,
      name: '',
      titlebarName: displayVal('name'),
      figureNum: displayVal('figure_number'),
      fontSize: displayVal('font_fize'),
      loaderUrl: APP.assetsRoot + 'ajax-login-loader.gif',
      csrfToken: APP.csrfToken,
      projectId: APP.projectId,
      logoImageName:
        logoImage !== 'DEFAULT'
          ? logoImage.substr(
              logoImage.indexOf('_', logoImage.indexOf('_') + 1) + 1,
            )
          : '',
      isFontSizeSelected: (o) =>
        parseInt(o) === parseInt(titlebar.get('font_size')),
    };
  },

  /**
   * Create a new map view from the data entered in the two forms in this view
   */
  _submitForms: function () {
    // Show the loader and hide the submit button
    this._$loader.show();
    this._$submitButton.hide();

    // Check for validatidy of data and extract all form data into model
    var mapName = this._$mapForm.serializeObject().name;
    var $errors = this._validateMapName(mapName);
    if ($errors) {
      return this._displayErrors($errors);
    }

    this.model.set('name', mapName);
    this.model.setTitlebarParams(
      this._$titlebarForm.serializeObject(),
      !this._$file.val(),
    );
    this.model.setLogoFormData(this._getLogoFormData());

    // Show loading mask
    return this._showSpinnerIfNecessary()
      .then(this.model.createMap.bind(this.model))
      .then(bigSpinner.hide())
      .then(this.model.updateTitlebarAndSelectedMap.bind(this.model))
      .then(this._hideModal.bind(this))
      .then(this._uploadOrCopyLogoImage.bind(this))
      .catch(bigSpinner.hide);
  },

  _showSpinnerIfNecessary: function () {
    return Promise.resolve().then(function () {
      if (featureCollection.length > 100) {
        return bigSpinner.show();
      }
    });
  },

  /**
   * If a new file is selected, upload that file. Otherwise if the map previously
   * selected had a logo, copy that logo. Update the titlebar accordingly.
   */
  _uploadOrCopyLogoImage: function () {
    if (!!this._$file.val()) {
      return this.model.saveLogoImage();
    } else if (this.model.titlebarParams.logoImage !== 'DEFAULT') {
      return this.model.copyLogoImage();
    }
  },

  _validateMapName: function (mapName) {
    var $errorsNode = $('<ul>');
    var errors = mapCollection.validateNewMapView(mapName.toLowerCase());

    _.each(errors, function (msg) {
      $errorsNode.append('<li>' + msg + '</li>');
    });

    if (!$errorsNode.find('li').length) {
      return;
    }
    return $errorsNode;
  },
});

Backbone.addSingletonToView(CreateMapView);
module.exports = CreateMapView;
