import React, { memo, useCallback } from 'react';
import { TD, TR, TableAction } from '../../../elements';
import { SelectDischargeLocation } from '../SelectDischargeLocation';
import { SelectFrequency } from '../SelectFrequency';
import { SelectParameter } from '../SelectParameter';
import { NotesPopover, TABLE_SETTINGS } from '../shared';
import { SelectUnit } from './SelectUnit';
import { LoggedItemRowProps } from './types';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.WATER_LOGGED;

const DISABLED_DELETE_TEXT =
  'This item cannot be deleted because data has already been logged or it is being used in other calculations';

export const WaterLogRow = memo(
  ({
    deleteLoggedItem,
    formErrors,
    groupId,
    loggedItem,
    loggedItemIdx,
    stageEdit,
    selectMenuPortalRef,
  }: LoggedItemRowProps) => {
    const stageChangedValue = useCallback(
      (editField, nextValue) =>
        stageEdit({ editField, nextValue }, { loggedItemIdx, groupId }),
      [groupId, loggedItemIdx, stageEdit],
    );

    const deleteOnClick = useCallback(
      () => deleteLoggedItem(loggedItemIdx, groupId),
      [groupId, loggedItemIdx, deleteLoggedItem],
    );

    const errorsForLoggedItem = formErrors?.groups?.[groupId]?.[loggedItemIdx];

    return (
      <TR>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <SelectDischargeLocation
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLoggedItem}
            item={loggedItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue('location', opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <SelectParameter
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLoggedItem}
            item={loggedItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue('parameter', opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          <SelectUnit
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            errors={errorsForLoggedItem}
            loggedItem={loggedItem}
            menuRef={selectMenuPortalRef}
            onChange={(opt) => stageChangedValue('units', opt)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          <div className="logged-items__frequency-wrapper">
            <SelectFrequency
              customFrequencyFieldName="customFrequency"
              /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
              errors={errorsForLoggedItem}
              frequencyFieldName="frequency"
              item={loggedItem}
              menuRef={selectMenuPortalRef}
              onChange={(frequency, customFrequency) => {
                stageChangedValue('frequency', frequency);
                stageChangedValue('customFrequency', customFrequency);
              }}
            />
          </div>
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <NotesPopover
            notes={loggedItem.notes}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClose={(notes) => stageChangedValue('notes', notes)}
          />
        </TD>
        {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
        <TD width={COLUMN_WIDTHS[5]} growth={COLUMN_GROWTH[5]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            disabledTooltipText={
              loggedItem.isLocked ? DISABLED_DELETE_TEXT : null
            }
            disabled={loggedItem.isLocked}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            onClick={deleteOnClick}
          />
        </TD>
      </TR>
    );
  },
);
