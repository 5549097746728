/* eslint-disable */
var APP = require('../config');

var TeslaFremontLevel1ButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/teslaFremontLevel1ButtonView.pug'),

  initialize() {
    this.listenTo(
      APP.vent,
      'deactivate:tesla-fremont-level1:button',
      this._deactivateTeslaLevel1.bind(this),
    );
  },

  templateHelpers: function () {
    return {
      isTeslaFremontLevel1On: this.model.isTeslaFremontLevel1On(),
    };
  },

  ui: {
    $buttonIcon: '#tesla-fremont-level1',
  },

  events: {
    click: '_toggleTeslaLevel1',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _toggleTeslaLevel1: function () {
    this.model
      .toggleTeslaLevel1()
      .bind(this)
      .then(function () {
        APP.vent.trigger('deactivate:tesla-fremont-level2:button');
      })
      .then(function () {
        this.trigger('toggle');
        this.ui.$buttonIcon.toggleClass('active');
      });
  },

  _deactivateTeslaLevel1: function () {
    this.ui.$buttonIcon.removeClass('active');
  },
});

module.exports = TeslaFremontLevel1ButtonView;
