var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (almostOverdue, date_submitted, daysToAlmostOverdue, label, overdue) {
      pug_html = pug_html + "\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (date_submitted) {
pug_html = pug_html + "\u003Csvg class=\"check\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (overdue) {
pug_html = pug_html + "\u003Csvg class=\"errors warning-red fire\" data-trigger=\"hover\" data_html=\"true\" data-title=\"Action Required\" data-content=\"This DMR is overdue! If you have already submitted it, click the due date to record the date the DMR was submitted.\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (almostOverdue) {
pug_html = pug_html + "\u003Csvg" + (" class=\"warnings warning-yellow exclamation\""+" data-trigger=\"hover\" data-html=\"true\" data-title=\"Notice\""+pug.attr("data-content", `This DMR is due in less than ${daysToAlmostOverdue} days.`, true, true)) + "\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca class=\"open-modal\" href=\"#\"\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Cdiv class=\"modal-container\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "almostOverdue" in locals_for_with ?
        locals_for_with.almostOverdue :
        typeof almostOverdue !== 'undefined' ? almostOverdue : undefined, "date_submitted" in locals_for_with ?
        locals_for_with.date_submitted :
        typeof date_submitted !== 'undefined' ? date_submitted : undefined, "daysToAlmostOverdue" in locals_for_with ?
        locals_for_with.daysToAlmostOverdue :
        typeof daysToAlmostOverdue !== 'undefined' ? daysToAlmostOverdue : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "overdue" in locals_for_with ?
        locals_for_with.overdue :
        typeof overdue !== 'undefined' ? overdue : undefined));
    ;;return pug_html;};
module.exports = template;