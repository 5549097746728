var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (didUserSelectNewFile, getFileName, getFileSize) {
      pug_html = pug_html + "\u003Cdiv id=\"aerial-imagery-data-wrapper\"\u003E\u003Ch4\u003EImagery File\u003C\u002Fh4\u003E\u003Clabel" + (pug.attr("class", pug.classes(["cursor-pointer","underline-hover",didUserSelectNewFile() ? 'green' : ''], [false,false,true]), false, true)+" id=\"clickable-file-label\" for=\"upload-aerial-imagery-file-selector\"") + "\u003E" + (pug.escape(null == (pug_interp = getFileName()) ? "" : pug_interp)) + "\u003Ci class=\"fa fa-pencil cursor-pointer margin-left-10\"\u003E\u003C\u002Fi\u003E\u003C\u002Flabel\u003E\u003Cdiv\u003E\u003Cspan" + (" id=\"reset-explainer-message\""+pug.attr("style", pug.style('margin-left: 5px; visibility: ' + (didUserSelectNewFile() ? 'visible;' : 'hidden;')), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = getFileSize() + ' will be uploaded upon saving the layer') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan" + (" class=\"cursor-pointer\""+" id=\"reset-file-name\""+pug.attr("style", pug.style('visibility: ' + (didUserSelectNewFile() ? 'visible;' : 'hidden;')), true, true)) + "\u003EReset\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cinput id=\"upload-aerial-imagery-file-selector\" type=\"file\" name=\"file\" accept=\"*tiff\" style=\"display: none\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "didUserSelectNewFile" in locals_for_with ?
        locals_for_with.didUserSelectNewFile :
        typeof didUserSelectNewFile !== 'undefined' ? didUserSelectNewFile : undefined, "getFileName" in locals_for_with ?
        locals_for_with.getFileName :
        typeof getFileName !== 'undefined' ? getFileName : undefined, "getFileSize" in locals_for_with ?
        locals_for_with.getFileSize :
        typeof getFileSize !== 'undefined' ? getFileSize : undefined));
    ;;return pug_html;};
module.exports = template;