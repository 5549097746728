var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (projectName) {
      pug_html = pug_html + "\u003Cdiv id=\"checklist\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"text-center\" id=\"checklist-tab\"\u003E\u003Cspan class=\"checklist-tab-text\"\u003EView Checklist\u003C\u002Fspan\u003E\u003Cspan class=\"caret\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sidebar-block\" id=\"layers-block\"\u003E\u003Cdiv class=\"sidebar-header flex align-items-center justify-content-space-between margin-bottom-15\"\u003E\u003Ch3" + (" class=\"project-name\""+" rel=\"tipsy\""+pug.attr("title", projectName, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = projectName) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003Cdiv class=\"flex align-items-center justify-content-space-between\"\u003E\u003Ch4\u003ELayers\u003C\u002Fh4\u003E\u003Cdiv class=\"flex align-items-center\"\u003E\u003Ca class=\"margin-right-10 flex\" id=\"create-layer-button\" rel=\"tipsy\" title=\"Create a layer\"\u003E\u003Csvg class=\"add white\"\u003E\u003Cuse xlink:href=\"#add\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C!--a#upload-data-button.flex(rel='tipsy', title='Upload a csv file')--\u003E\u003C!--  svg.upload.white--\u003E\u003C!--    use(xlink:href='#upload')--\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"layer-list-region\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"sidebar-block\" id=\"views-block\"\u003E\u003Cdiv class=\"sidebar-header flex align-items-center justify-content-space-between margin-bottom-15\"\u003E\u003Ch4\u003EMaps\u003C\u002Fh4\u003E\u003Ca id=\"new-view-button\" rel=\"tipsy\" title=\"Create a new map\"\u003E\u003Csvg class=\"add white\"\u003E\u003Cuse xlink:href=\"#add\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"map-list-region\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "projectName" in locals_for_with ?
        locals_for_with.projectName :
        typeof projectName !== 'undefined' ? projectName : undefined));
    ;;return pug_html;};
module.exports = template;