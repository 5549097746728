/* eslint-disable */
import { CircularProgress } from '@material-ui/core';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import sectorData from 'industrial-stormwater-sector-data';
import SamplingMethods from 'industrial-stormwater-sector-data/lib/samplingMethods';
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
import {
  localEquivalentOfUTC,
  StateAbbreviations,
  UTCEquivalentOfLocal,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';

import {
  AccordionPanel,
  DatePicker,
  ErrorMessage,
  FieldWrapper,
  Select,
  TextField,
  TimePicker,
} from '../../../elements';

import { validateOnUpdate } from '../../validations/wastewaterSamplingResultValidations';
import SamplingResultFormParameter from './SamplingResultFormParameter';

const allSamplingParameters = SamplingParameters.getAll();
const sortedSamplingMethods = SamplingMethods.getAll().sort((m1, m2) =>
  m1.display_text.toLowerCase() > m2.display_text.toLowerCase() ? 1 : -1,
);

const defaultUnitsFor = (wastewaterSamplingResults, parameter) => {
  const resultWithUnits = wastewaterSamplingResults.filter(
    (result) =>
      result.parameterSlug === parameter.parameter_slug && result.units != null,
  );
  if (resultWithUnits.length > 0) {
    return resultWithUnits[0].units;
  }

  const locationWithUnits = parameter.sp_monitoring_locations.find(
    (ml) => !!ml.units,
  );
  if (locationWithUnits) {
    return locationWithUnits.units;
  }

  return allSamplingParameters.find((p) => p.slug === parameter.parameter_slug)
    .units;
};

const allSamplingUnits = SamplingUnits.getAll();
const composeRawValue = (parameterValue, unitSlug) => {
  if (parameterValue == null) return;

  const unitConfig = allSamplingUnits.find((u) => u.slug === unitSlug);
  const unitLabel = unitConfig?.display_text;
  return parameterValue === 'ND' || !unitLabel
    ? parameterValue
    : `${parameterValue} ${unitLabel}`;
};

export default class SamplingResultForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParameterSlug: props.initialSearchParameterSlug,
    };
  }

  handleEventDateChange = (date) => {
    const { onChange } = this.props;
    const parsedDate = date
      ? UTCEquivalentOfLocal(new Date(date.setHours(0, 0, 0)))
      : null;

    onChange({ name: 'eventDate', value: parsedDate });
  };

  handleSamplingResultsDelete = async (samplingResultId) => {
    const { samplingResults, onChange } = this.props;
    const resultsWithoutDeletedOne = samplingResults.filter(
      (samplingResult) => samplingResult.id !== samplingResultId,
    );
    onChange({ name: 'samplingResults', value: resultsWithoutDeletedOne });
  };

  handleSamplingResultsChange = (payload) => {
    const { onChange, project, samplingResults, wastewaterParameters } =
      this.props;
    const { samplingResultId, locationId, parameterSlug, name, value } =
      payload;
    const parameterBeingUpdated = wastewaterParameters.find(
      (parameter) => parameter.parameter_slug === parameterSlug,
    );

    const resultMatches = (result) =>
      result.parameterSlug === parameterSlug &&
      result.locationId === locationId &&
      (samplingResultId || result.id ? samplingResultId === result.id : true);

    const resultExists = samplingResults.some(resultMatches);

    const unitsWereUpdated = name === 'units';
    const withUpdatedUnits = (result) => {
      if (!unitsWereUpdated || result.parameterSlug !== parameterSlug) {
        return result;
      }
      const newUnits = value;
      return {
        ...result,
        units: newUnits,
        rawValue: composeRawValue(result.parameterValue, newUnits),
      };
    };

    const newResult = {
      ...samplingResults.find(resultMatches),
      [name]: value,
    };

    if (resultExists) {
      const newResults = samplingResults.map((result) => {
        if (!resultMatches(result)) {
          return withUpdatedUnits(result);
        }

        if (name === 'methodSlug') {
          newResult.methodText = null;
        }
        if (name === 'methodText') {
          newResult.methodSlug = null;
        }

        const { errors: currentErrors, ...currentResult } = result;
        const errors = validateOnUpdate(newResult, currentErrors);

        return {
          ...currentResult,
          ...newResult,
          rawValue: composeRawValue(newResult.parameterValue, newResult.units),
          errors,
        };
      });
      onChange({ name: 'samplingResults', value: newResults });
    } else {
      let defaultMethodSlug = null;
      if (
        this.isAllowedToSelectFromMapistryMethods() &&
        name !== 'methodText'
      ) {
        const stateSectorData = sectorData(project.state);
        const availableMethods =
          stateSectorData.getAvailableSamplingMethods(parameterSlug);
        defaultMethodSlug =
          availableMethods.length === 0 ? null : availableMethods[0].slug;
      }
      const defaultUnits = defaultUnitsFor(
        samplingResults,
        parameterBeingUpdated,
      );
      const errors = validateOnUpdate(newResult, []);
      onChange({
        name: 'samplingResults',
        value: samplingResults.map(withUpdatedUnits).concat({
          methodSlug: defaultMethodSlug,
          units: defaultUnits,
          ...newResult,
          locationId,
          parameterSlug,
          rawValue: composeRawValue(
            newResult.parameterValue,
            newResult.units || defaultUnits,
          ),
          errors,
        }),
      });
    }
  };

  loading() {
    return (
      <div className="loading">
        <CircularProgress
          classes={{ colorPrimary: 'mapistry-green' }}
          size={80}
        />
      </div>
    );
  }

  samplingEventHeader() {
    const { searchParameterSlug } = this.state;
    const {
      eventDate,
      eventTime,
      labReportNumber,
      onChange,
      wastewaterParameters,
    } = this.props;

    const parametersOptions = wastewaterParameters.map((enabledParameter) => {
      const parameterInfo = allSamplingParameters.find(
        (p) => p.slug === enabledParameter.parameter_slug,
      );
      const casNumberLabel = parameterInfo.cas_number
        ? ` (${parameterInfo.cas_number})`
        : '';
      return {
        label: `${parameterInfo.display_text}${casNumberLabel}`,
        value: enabledParameter.parameter_slug,
      };
    });

    return (
      <>
        <div className="sampling-event-dates">
          <FieldWrapper
            className="sampling-event-field"
            label="Sampling Date"
            isRequired
          >
            <DatePicker
              autoOk
              className="date-field"
              clearable
              maxDate={new Date()}
              onChange={this.handleEventDateChange}
              value={localEquivalentOfUTC(eventDate)}
            />
          </FieldWrapper>
          <FieldWrapper
            className="sampling-event-field"
            isRequired
            label="Sampling Time"
          >
            <TimePicker
              autoOk
              className="time-field"
              clearable
              onChange={(time) => onChange({ name: 'eventTime', value: time })}
              value={eventTime}
            />
          </FieldWrapper>
          <FieldWrapper className="sampling-event-field" label="Lab Report #">
            <TextField
              value={labReportNumber}
              onChange={(event) => {
                onChange({
                  name: 'labReportNumber',
                  value: event.target.value,
                });
              }}
            />
          </FieldWrapper>
        </div>

        <label
          className="parameter-search"
          htmlFor="wastewater-sampling-results-parameter-search-input"
          id="wastewater-sampling-results-parameter-search-label"
        >
          <SvgIcon
            aria-hidden
            className={`parameter-search-icon${
              searchParameterSlug ? ' parameter-search-icon--active' : ''
            }`}
            identifier="search"
          />
          <span className="visually-hidden">Search by Sampling Parameter</span>
          <Select
            inputId="wastewater-sampling-results-parameter-search-input"
            aria-labelledby="wastewater-sampling-results-parameter-search-label"
            className="parameter-search-input"
            options={parametersOptions}
            onChange={(opt) =>
              this.setState({ searchParameterSlug: opt?.value })
            }
            placeholder="Search by Sampling Parameter"
            value={parametersOptions.find(
              (opt) => opt.value === searchParameterSlug,
            )}
          />
        </label>
      </>
    );
  }

  errorMessage() {
    const { saveError, showFormError, errorCount, onCloseErrorMessage } =
      this.props;
    const { searchParameterSlug } = this.state;
    let displayErrorMessage = saveError || '';
    if (searchParameterSlug) {
      displayErrorMessage +=
        ' You might need to clear the sampling parameter filter to see the error.';
    }
    return (
      showFormError && (
        <ErrorMessage
          errorMessage={displayErrorMessage}
          numberOfFormErrors={errorCount}
          onClose={onCloseErrorMessage}
        />
      )
    );
  }

  // Only California sites can select methods from Mapistry list (for SMARTS reporting),
  // everyone else has to manually type method name.
  isAllowedToSelectFromMapistryMethods() {
    const { project } = this.props;
    return project.state === StateAbbreviations.CA;
  }

  render() {
    const { searchParameterSlug } = this.state;
    const {
      initialSearchParameterSlug,
      isLoading,
      monitoringLocations,
      project,
      samplingEventId,
      samplingResults,
      wastewaterParameters,
    } = this.props;

    const filteredParameters = searchParameterSlug
      ? wastewaterParameters.filter(
          (p) => p.parameter_slug === searchParameterSlug,
        )
      : wastewaterParameters;

    const stateSectorData = sectorData(project.state);

    const parameterResults = (parameterSlug) =>
      samplingResults.filter(
        (result) => result.parameterSlug === parameterSlug,
      );
    const hasErrors = (results) =>
      results.some(
        (result) => result.errors != null && result.errors.length > 0,
      );

    const parameterComponents = filteredParameters.map((parameter) => (
      <AccordionPanel
        key={`panel-${parameter.parameter_slug}`}
        className="panel-sampling-event"
        headingView={
          <div className="panel-heading-parameter">
            <strong>
              {stateSectorData.getParameterDisplayText(
                parameter.parameter_slug,
              )}
            </strong>
            {hasErrors(parameterResults(parameter.parameter_slug)) && (
              <SvgIcon
                className="wastewater-sampling-result-icon-error"
                identifier="exclamation"
                withBorder
              />
            )}
          </div>
        }
        initialIsExpanded={
          initialSearchParameterSlug === parameter.parameter_slug
        }
      >
        <SamplingResultFormParameter
          analysisMethods={sortedSamplingMethods}
          monitoringLocations={monitoringLocations}
          onChange={this.handleSamplingResultsChange}
          onDelete={this.handleSamplingResultsDelete}
          projectId={project.id}
          samplingEventId={samplingEventId}
          showMethodSelect={this.isAllowedToSelectFromMapistryMethods()}
          wastewaterParameter={parameter}
          wastewaterSamplingResults={parameterResults(parameter.parameter_slug)}
        />
      </AccordionPanel>
    ));

    return isLoading ? (
      this.loading()
    ) : (
      <div className="wastewater-sampling-result-modal-content">
        <div className="wastewater-modal-content">
          {this.samplingEventHeader()}
          <div className="parameter-container">{parameterComponents}</div>
          {this.errorMessage()}
        </div>
      </div>
    );
  }
}

SamplingResultForm.defaultProps = {
  errorCount: 0,
  eventDate: null,
  eventTime: null,
  initialSearchParameterSlug: '',
  isLoading: false,
  labReportNumber: null,
  samplingEventId: null,
  saveError: null,
  showFormError: false,
};

SamplingResultForm.propTypes = {
  errorCount: PropTypes.number,
  eventDate: PropTypes.instanceOf(Date),
  eventTime: PropTypes.instanceOf(Date),
  initialSearchParameterSlug: PropTypes.string,
  isLoading: PropTypes.bool,
  labReportNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  samplingEventId: PropTypes.string,
  samplingResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveError: PropTypes.string,
  showFormError: PropTypes.bool,
  wastewaterParameters: PropTypes.arrayOf(PropTypes.object).isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
