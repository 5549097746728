/* eslint-disable */
var DsnChoicesView = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/dsnChoices.pug'),

  childView: require('./DsnChoiceRow'),

  childViewContainer: '.dsn-choices',

  initialize: function () {
    this.collection = new Backbone.Collection(this.model.get('dsnRecords'));
  },
});

module.exports = DsnChoicesView;
