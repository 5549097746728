import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { Api } from '@monorepo/shared/apiClient';
import { setWithMessage } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileRecordHelpers';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import {
  getLessThanNumberMatch,
  isValidNumber,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { RecordValue } from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import {
  SamplingMethod,
  SamplingParameter,
} from '@monorepo/shared/types/industrial-stormwater-sector-data';
import { UTCEquivalentOfLocal } from 'mapistry-shared';
/* @ts-expect-error – no TS declarations for this old Mapistry package */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingMethods from 'industrial-stormwater-sector-data/lib/samplingMethods';
import { validateSamplingParameterUnits } from '../shared/uploadValidators';
import { NOT_DETECTED, uploadFieldKeys } from './types';

const allParameters = SamplingParameters.getAll() as SamplingParameter[];
const allMethods = SamplingMethods.getAll() as SamplingMethod[];

export function validateAndParseParameterSlug(
  record: FlatfileRecord,
  parameterSlug: RecordValue,
  casNumber: RecordValue,
  enabledSamplingParameterSlugs: string[],
): void {
  // If no value – there is nothing to parse
  if (!parameterSlug && !casNumber) {
    const errorMsg =
      'Either type a CAS number or select a sampling parameter from a dropdown';
    record.addError(uploadFieldKeys.casNumber, errorMsg);
    record.addError(uploadFieldKeys.parameterSlug, errorMsg);
    return;
  }

  // If we have only parameter slug then it's valid since only enabled parameters could be matched
  if (!casNumber) {
    return;
  }

  // CAS number takes precedence over parameter slug,
  // so if it has any validation errors we don't import that row
  const casNumberParameters = allParameters.filter(
    (p) => p.cas_number === casNumber,
  );

  if (!casNumberParameters.length) {
    record.addError(
      uploadFieldKeys.casNumber,
      `${casNumber} CAS number has no corresponding water parameter in Mapistry system`,
    );
    return;
  }

  const casNumberEnabledParameters = casNumberParameters.filter((p) =>
    enabledSamplingParameterSlugs.includes(p.slug),
  );
  const casNumberEnabledParamLabels = casNumberEnabledParameters
    .map((p) => `"${p.display_text}"`)
    .join(', or ');

  if (!casNumberEnabledParameters[0]) {
    const casNumberParamLabels = casNumberParameters
      .map((p) => `"${p.display_text}"`)
      .join(', ');
    const singleParamMsg = `${casNumber} CAS number corresponds to ${casNumberParamLabels} parameter that was not added to your monitoring water parameters`;
    const multiParamMsg = `${casNumber} CAS number corresponds to several parameters that were not added to your monitoring water parameters: ${casNumberParamLabels}`;
    record.addError(
      uploadFieldKeys.casNumber,
      casNumberParameters.length === 1 ? singleParamMsg : multiParamMsg,
    );
    return;
  }

  // If only CAS number is given, we try to fill in parameter slug
  if (!parameterSlug) {
    if (casNumberEnabledParameters.length > 1) {
      record.addError(
        uploadFieldKeys.parameterSlug,
        `${casNumber} CAS number corresponds to several parameters, please select one: ${casNumberEnabledParamLabels}`,
      );
      return;
    }

    const casNumberParameterSlug = casNumberEnabledParameters[0].slug;
    setWithMessage(
      uploadFieldKeys.parameterSlug,
      record,
      casNumberParameterSlug,
      `This parameter corresponds to ${casNumber} CAS number`,
    );
    return;
  }

  // If parameter slug is present we need to make sure that it matches cas number
  const doParameterAndCasNumberMatch = casNumberEnabledParameters.some(
    (p) => p.slug === parameterSlug,
  );
  if (!doParameterAndCasNumberMatch) {
    const parameterLabel = allParameters.find(
      (p) => p.slug === parameterSlug,
    )?.display_text; // eslint-disable-line camelcase
    record.addError(
      uploadFieldKeys.parameterSlug,
      `${parameterLabel} doesn't match provided CAS number, did you mean ${casNumberEnabledParamLabels}?`,
    );
  }

  // If we have both CAS number and parameter slug, and they match
}

export function validateMethodSlug(
  methodFieldId: string,
  record: FlatfileRecord,
  methodSlug: RecordValue,
  parameterSlug: RecordValue,
): void {
  // pH parameter is the only one that requires method slug to be specified
  if (parameterSlug === 'ph' && !methodSlug) {
    record.addError(methodFieldId, 'Method (SMARTS) is required for pH logs');
    return;
  }

  // If no values – there is nothing to parse
  if (!parameterSlug || !methodSlug) {
    return;
  }

  /*
    Currently methods that can be used with each parameter are outdated,
    until we clean them, we can't enforce any validation but pH,
    otherwise it might prevent a user to upload valid data.
  */
  if (parameterSlug !== 'ph') {
    return;
  }

  // pH results can be uploaded with the rest of lab results,
  // we will save them as if they were entered manually in "Water: pH logs" section,
  // thus hardcoded slugs
  const parameterMethodSlugs =
    parameterSlug === 'ph'
      ? ['litmus_paper', 'ph_meter']
      : SamplingParameters.getMethods(parameterSlug);

  // Each parameter can be tested only with specified list of methods
  if (parameterMethodSlugs.includes(methodSlug)) {
    return;
  }

  const parameterLabel = allParameters.find(
    (p) => p.slug === parameterSlug,
  )?.display_text; // eslint-disable-line camelcase
  const methodLabelsString = allMethods
    .filter((m) => parameterMethodSlugs.includes(m.slug))
    .map((m) => m.display_text)
    .join(', ');
  record.addError(
    methodFieldId,
    `${parameterLabel} can be tested only with next methods: ${methodLabelsString}`,
  );
}

export function validateDetectionLimit(
  detectionLimitFieldId: string,
  record: FlatfileRecord,
  detectionLimit?: RecordValue,
  reportingLimit?: RecordValue,
): void {
  if (
    detectionLimit &&
    reportingLimit &&
    Number(detectionLimit) > Number(reportingLimit)
  ) {
    record.addError(
      detectionLimitFieldId,
      'The method detection limit must be less than the reporting limit.',
    );
  }
}

export function validateSamplingResultUnits(
  unitFieldId: string,
  record: FlatfileRecord,
  parameterSlug: RecordValue,
): void {
  validateSamplingParameterUnits(unitFieldId, record, parameterSlug);
}

export function validateAndParseParameterValue(
  record: FlatfileRecord,
  parameterSlug: RecordValue,
  parameterValue: RecordValue,
  reportingLimit: RecordValue,
  detectionLimit: RecordValue,
): void {
  const isPh = parameterSlug === 'ph';

  // If no value or it's valid – there is nothing to parse
  if (!parameterValue || (!isPh && parameterValue === NOT_DETECTED)) {
    return;
  }

  if (isPh && !isValidNumber(parameterValue)) {
    record.addError(
      uploadFieldKeys.parameterValue,
      'pH parameters must have numeric values',
    );
    return;
  }

  if (isValidNumber(parameterValue)) {
    // pH value can be only numeric, so it's required to be strictly greater than DL or RL.
    // For other parameters such values will be converted to "ND" before sending to the server.
    if (!isPh) return;

    const isLessThanDetectionLimit =
      detectionLimit &&
      isValidNumber(detectionLimit) &&
      Number(parameterValue) <= Number(detectionLimit);
    const isLessThanReportingLimit =
      reportingLimit &&
      isValidNumber(reportingLimit) &&
      Number(parameterValue) <= Number(reportingLimit);
    if (isLessThanDetectionLimit || isLessThanReportingLimit) {
      record.addError(
        uploadFieldKeys.parameterValue,
        'The pH value must be greater than the reporting and detection limit',
      );
      return;
    }
    return;
  }

  // Check that a string is some variant of Not Detected and convert it to proper constant
  const lowerCasedParameterValue = String(parameterValue).toLowerCase().trim();
  if (
    lowerCasedParameterValue === 'not detected' ||
    lowerCasedParameterValue === 'nd'
  ) {
    setWithMessage(
      uploadFieldKeys.parameterValue,
      record,
      NOT_DETECTED,
      `Original value: ${parameterValue}`,
    );
    return;
  }

  const match = getLessThanNumberMatch(parameterValue);
  if (match.hasMatch) {
    // If a value is in a "<X" format, "X" must be a number and less or equal to a reporting limit
    if (
      reportingLimit &&
      (!isValidNumber(reportingLimit) ||
        Number(match.number) > Number(reportingLimit))
    ) {
      record.addError(
        uploadFieldKeys.parameterValue,
        `If defined as "< number", number must be less than or equal to a reporting limit: ${reportingLimit}`,
      );
      return;
    }

    // If there is no reporting limit we consider that "<X" value defines the reporting limit
    if (!reportingLimit) {
      setWithMessage(
        uploadFieldKeys.reportingLimit,
        record,
        match.number,
        `Extracted form parameter value: ${parameterValue}`,
      );
      return;
    }

    // Leaving "<X" value as it is
    return;
  }

  record.addError(
    uploadFieldKeys.parameterValue,
    'The value must be a number, "ND", or in a "< number" format',
  );
}

export async function validateSamplingResultDoesNotExist(
  fieldIdToShowTheErrorOn: string,
  record: FlatfileRecord,
  locationId: string,
  parameterSlug: string,
  projectId: string,
  date: string,
  time: string,
): Promise<void> {
  const samplingDate = UTCEquivalentOfLocal(parseDateString(date));

  const existingRecord = await Api.fetchSamplingResults(projectId, {
    date: samplingDate,
    locationId,
    parameterSlug,
    time,
  });

  if (existingRecord) {
    record.addError(
      fieldIdToShowTheErrorOn,
      'A sampling result already exists for this exact date, parameter and location.',
    );
  }
}
