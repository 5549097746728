var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (rows) {
      pug_html = pug_html + "\u003Cdiv id=\"upload-template\"\u003E\u003Cdiv id=\"table-msg-area\"\u003E\u003Ch5 id=\"table-msg\"\u003E\u003C\u002Fh5\u003E\u003Ch6 id=\"table-hint\"\u003E\u003C\u002Fh6\u003E\u003Cdiv id=\"yes-no-buttons\"\u003E\u003Cbutton class=\"btn btn-success\" id=\"upload-yes\"\u003EYes\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-danger\" id=\"upload-no\"\u003ENo\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"display-none\" id=\"address-buttons\"\u003E\u003Cbutton class=\"btn btn-success\" id=\"lat-lng-encoded\"\u003ELatitude\u002FLongitude\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-primary\" id=\"address-encoded\"\u003EAddresses\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cbr\u003E\u003Cdiv id=\"uploaded-table-div\"\u003E\u003Ctable class=\"table-responsive table-striped\"\u003E";
// iterate rows
;(function(){
  var $$obj = rows;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var row = $$obj[index];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var col = $$obj[pug_index1];
if ((index === 0)) {
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var col = $$obj[pug_index1];
if ((index === 0)) {
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var row = $$obj[index];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate row
;(function(){
  var $$obj = row;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var col = $$obj[pug_index2];
if ((index === 0)) {
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var col = $$obj[pug_index2];
if ((index === 0)) {
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = col) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Ca class=\"pull-left\" id=\"btn-back\" href=\"#\"\u003E\u003C back\u003C\u002Fa\u003E\u003Ca class=\"pull-right\" id=\"btn-skip\" href=\"#\"\u003Eskip \u003E\u003C\u002Fa\u003E\u003Cimg class=\"loader pull-right\" src=\"https:\u002F\u002Fs3.amazonaws.com\u002FmapistryAssets\u002Fajax-login-loader.gif\"\u003E\u003Cbutton class=\"btn btn-success pull-right\" id=\"btn-done\"\u003EDone\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "rows" in locals_for_with ?
        locals_for_with.rows :
        typeof rows !== 'undefined' ? rows : undefined));
    ;;return pug_html;};
module.exports = template;