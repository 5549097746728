var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (btnConfirm, btnDelete, confirmMsg, error, text) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade above-trip-overlay\" id=\"confirm-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"confirm-modal-label\" aria-hidden=\"true\" data-backdrop=\"static\" data-keyboard=\"false\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Ch4 class=\"modal-title\"\u003EConfirm\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cspan class=\"name\"\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cdiv class=\"error-msg\"\u003E" + (null == (pug_interp = error) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cspan class=\"pull-left\" id=\"confirmation-msg\"\u003E" + (pug.escape(null == (pug_interp = confirmMsg) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbutton class=\"btn btn-default\" type=\"button\" data-dismiss=\"modal\"\u003ECancel\u003C\u002Fbutton\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn","btn-danger",btnDelete], [false,false,true]), false, true)+" id=\"btn-delete\" type=\"button\" data-dismiss=\"modal\"") + "\u003EDelete\u003C\u002Fbutton\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn","btn-primary",btnConfirm], [false,false,true]), false, true)+" id=\"btn-confirm\" type=\"button\" data-dismiss=\"modal\"") + "\u003EContinue\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "btnConfirm" in locals_for_with ?
        locals_for_with.btnConfirm :
        typeof btnConfirm !== 'undefined' ? btnConfirm : undefined, "btnDelete" in locals_for_with ?
        locals_for_with.btnDelete :
        typeof btnDelete !== 'undefined' ? btnDelete : undefined, "confirmMsg" in locals_for_with ?
        locals_for_with.confirmMsg :
        typeof confirmMsg !== 'undefined' ? confirmMsg : undefined, "error" in locals_for_with ?
        locals_for_with.error :
        typeof error !== 'undefined' ? error : undefined, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined));
    ;;return pug_html;};
module.exports = template;