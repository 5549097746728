/* eslint-disable */
var Progress = Backbone.View.extend({
  template: require('./templates/uploadProgress.pug'),

  initialize: function () {
    this._setProgress(0);
  },

  render: function () {
    this.$el.html(
      this.template({
        progress: this._getProgress(),
      }),
    );

    return this;
  },

  updateProgress: function (progress) {
    this._setProgress(progress);
    this.render()._showProgress();
  },

  _getProgress: function () {
    return this._progress;
  },

  _setProgress: function (progress) {
    this._progress = progress;
  },

  _showProgress: function () {
    this.$('.progress').show();
  },

  _hideProgress: function () {
    this.$('.progress').hide();
  },
});

module.exports = Progress;
