var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (availableRoles, hasUserUpdatePermission, isFirst, isOrganizationAdmin, loggedInUserId, primaryAdminRole, reasonPrimaryAdminSelectIsHidden, reasonSelectIsHidden, roleLabels, selectedRole, userId, userName) {
      pug_html = pug_html + "\u003Cdiv class=\"row user-row flex align-items-center\"\u003E\u003Cdiv class=\"col-xs-4\"\u003E\u003Cdiv" + (" class=\"user-email clickable\""+" data-toggle=\"popover\""+pug.attr("data-placement", isFirst ? 'bottom' : 'top', true, true)+" data-trigger=\"hover click\" data-title=\"User Contact Info\"") + "\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = userName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4\"\u003E";
if (selectedRole == primaryAdminRole) {
pug_html = pug_html + "\u003Ca" + (" data-toggle=\"tooltip\" data-placement=\"top\""+pug.attr("data-original-title", reasonPrimaryAdminSelectIsHidden, true, true)) + "\u003EPrimary Site Admin\u003C\u002Fa\u003E";
}
else
if (isOrganizationAdmin) {
pug_html = pug_html + "\u003Ca" + (" data-toggle=\"tooltip\" data-placement=\"top\""+pug.attr("data-original-title", reasonSelectIsHidden, true, true)) + "\u003ESite Admin\u003C\u002Fa\u003E";
}
else
if (!isOrganizationAdmin && hasUserUpdatePermission) {
pug_html = pug_html + "\u003Cselect class=\"role form-control\"\u003E";
// iterate availableRoles
;(function(){
  var $$obj = availableRoles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var roleValue = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", roleValue, true, true)+pug.attr("selected", roleValue == selectedRole, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = roleLabels[roleValue]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var roleValue = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", roleValue, true, true)+pug.attr("selected", roleValue == selectedRole, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = roleLabels[roleValue]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
else
if (!hasUserUpdatePermission) {
pug_html = pug_html + "\u003Ca data-toggle=\"tooltip\" data-placement=\"top\" data-original-title=\"Only a site admin can assign roles.\"\u003E" + (pug.escape(null == (pug_interp = roleLabels[selectedRole]) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4\"\u003E";
if (loggedInUserId === userId || hasUserUpdatePermission) {
pug_html = pug_html + "\u003Ca class=\"padding-right-10 user-settings-link\" rel=\"tipsy\" title=\"Edit user's settings\"\u003E\u003Csvg class=\"cog\"\u003E\u003Cuse xlink:href=\"#cog\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
if (loggedInUserId != userId && !isOrganizationAdmin && hasUserUpdatePermission && selectedRole != primaryAdminRole) {
pug_html = pug_html + "\u003Ca class=\"delete\" href=\"#\" rel=\"tipsy\" title=\"Revoke user's access to this site\"\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cspan\u003E&nbsp;\u003C\u002Fspan\u003E\u003Cspan class=\"saved text-success\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"saving text-info\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"error-saving text-danger\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "availableRoles" in locals_for_with ?
        locals_for_with.availableRoles :
        typeof availableRoles !== 'undefined' ? availableRoles : undefined, "hasUserUpdatePermission" in locals_for_with ?
        locals_for_with.hasUserUpdatePermission :
        typeof hasUserUpdatePermission !== 'undefined' ? hasUserUpdatePermission : undefined, "isFirst" in locals_for_with ?
        locals_for_with.isFirst :
        typeof isFirst !== 'undefined' ? isFirst : undefined, "isOrganizationAdmin" in locals_for_with ?
        locals_for_with.isOrganizationAdmin :
        typeof isOrganizationAdmin !== 'undefined' ? isOrganizationAdmin : undefined, "loggedInUserId" in locals_for_with ?
        locals_for_with.loggedInUserId :
        typeof loggedInUserId !== 'undefined' ? loggedInUserId : undefined, "primaryAdminRole" in locals_for_with ?
        locals_for_with.primaryAdminRole :
        typeof primaryAdminRole !== 'undefined' ? primaryAdminRole : undefined, "reasonPrimaryAdminSelectIsHidden" in locals_for_with ?
        locals_for_with.reasonPrimaryAdminSelectIsHidden :
        typeof reasonPrimaryAdminSelectIsHidden !== 'undefined' ? reasonPrimaryAdminSelectIsHidden : undefined, "reasonSelectIsHidden" in locals_for_with ?
        locals_for_with.reasonSelectIsHidden :
        typeof reasonSelectIsHidden !== 'undefined' ? reasonSelectIsHidden : undefined, "roleLabels" in locals_for_with ?
        locals_for_with.roleLabels :
        typeof roleLabels !== 'undefined' ? roleLabels : undefined, "selectedRole" in locals_for_with ?
        locals_for_with.selectedRole :
        typeof selectedRole !== 'undefined' ? selectedRole : undefined, "userId" in locals_for_with ?
        locals_for_with.userId :
        typeof userId !== 'undefined' ? userId : undefined, "userName" in locals_for_with ?
        locals_for_with.userName :
        typeof userName !== 'undefined' ? userName : undefined));
    ;;return pug_html;};
module.exports = template;