import { Api } from '@monorepo/shared/apiClient';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import sectorData from 'industrial-stormwater-sector-data';
import { useQuery } from 'react-query';

const federalSectorData = sectorData('federal');

export const getQueryKey = (projectId: string) => [
  'wastewaterSamplingParameters',
  projectId,
];

const fetcher = (_: string, projectId: string) =>
  Api.fetchWastewaterSamplingParameters(projectId);

export const useWastewaterSamplingParameters = (projectId: string) => {
  const { data, ...rest } = useQuery(getQueryKey(projectId), fetcher);

  // ph gets recorded outside the regular wastewater suite
  const samplingParametersWithoutPh = data?.filter(
    (o) => o.parameter_slug !== 'ph',
  );

  return {
    wastewaterSamplingParameters: samplingParametersWithoutPh,
    ...rest,
  };
};

export const useWastewaterSamplingParameterWithDisplayText = ({
  projectId,
  parameterSlug,
}: {
  projectId: string;
  parameterSlug: string;
}) => {
  const { wastewaterSamplingParameters, ...rest } =
    useWastewaterSamplingParameters(projectId);

  const parameter = wastewaterSamplingParameters?.find(
    (p) => p.parameter_slug === parameterSlug,
  );
  const parameterDisplayText = parameter
    ? federalSectorData.getParameterDisplayText(parameter.parameter_slug)
    : 'Unknown Sampling Parameter';

  return {
    samplingParameterWithDisplayText: {
      ...parameter,
      displayText: parameterDisplayText,
    },
    ...rest,
  };
};
