/* eslint-disable */
import APP from '@monorepo/old-web/js/config';
import { AnalyticsRoles } from 'mapistry-shared';

let currentUser = require('../../currentUser');

let NavbarView = Backbone.Marionette.ItemView.extend({
  template: require('../../../shared/navbar/templates/navbar.pug'),

  ui: {
    $brand: '.navbar-brand',
    $mapsPage: '.project-maps',
    $projectDashboard: '.project-dashboard',
    $userSettings: '.user-settings',
    $settings: '#settings',
    // top level package routes
    $onboarding: '.onboarding',
    $analytics: '.analytics',
    $dataSetup: '.data-setup',
  },

  events: {
    'click @ui.$brand': '_goToProject',
    'click @ui.$mapsPage': '_goToMaps',
    'click @ui.$projectDashboard': '_goToProject',
    'click @ui.$userSettings': '_goToUserSettings',
    // top level package routes
    'click @ui.$onboarding': '_goToReactRoute',
    'click @ui.$analytics': '_goToAnalytics',
    'click @ui.$dataSetup': '_goToDataSetup',
  },

  initialize(options = {}) {
    let project = options.project;
    this.organizationId = options.organizationId;
    this.organization = options.organization;
    this.projectId = options.projectId;
    this.activeClass = options.activeClass;
    this.hasOnboardingSession = options.organization
      ? options.organization.get('onboardingLive')
      : false;
    this.isData2Enabled = APP.featureFlags.isData2Enabled;

    if (!project) {
      const projectCollection =
        require('../../models_collections/projectCollection').singleton();
      project = projectCollection.getCurrent();
    }

    if (!this.projectId) {
      this._setProjectId(project).then(this._setOrganizationId.bind(this));
    } else if (!this.organizationId && this.projectId === project.id) {
      this._setOrganizationId(project);
    }
  },

  templateHelpers() {
    return {
      userEmail: currentUser.get('email'),
      showDropdownMenu: true,
      isData2Enabled: this.isData2Enabled,
    };
  },

  onDomRefresh() {
    const analyticsRole = currentUser.get('analytics_role');
    const userHasAnalyticsPermission =
      analyticsRole && analyticsRole !== AnalyticsRoles.NONE;

    this.ui.$analytics.toggle(userHasAnalyticsPermission);
    this.ui.$dataSetup.toggle(this.isData2Enabled);
    let element = this._getActiveElement(userHasAnalyticsPermission);
    if (element) {
      element.addClass('active');
    }

    if (this.hasOnboardingSession || this.activeClass === 'onboarding') {
      this.ui.$onboarding.removeClass('hidden');
    }
  },

  _getActiveElement(userHasAnalyticsPermission) {
    switch (this.activeClass) {
      case 'project':
        return this.ui.$projectDashboard;
      case 'organization':
        return this.ui.$organizationDashboard;
      case 'map':
        return this.ui.$mapsPage;
      case 'settings':
        return this.ui.$settings;
      case 'analytics':
        // You can actually visit the Log Values dashboard w/o Analytics permission if you click View log values on a GL widget
        return userHasAnalyticsPermission
          ? this.ui.$analytics
          : this.ui.$projectDashboard;
      default:
        return this.ui[`$${this.activeClass}`];
    }
  },

  _setProjectId(project) {
    let projectPromise = project
      ? P.resolve(project)
      : require('../../apiCaller').getLastProject();
    return projectPromise.then((p) => {
      this.projectId = p.id;
      return p;
    });
  },

  _setOrganizationId(project) {
    if (!this.organizationId && project) {
      if (project.organization_id) {
        this.organizationId = project.organization_id;
      } else if (project.has('organization_id')) {
        this.organizationId = project.get('organization_id');
      }
    }
  },

  _goToProject() {
    require('../../router').goToProjectDashboard(
      this.projectId,
      this.organizationId,
    );
  },

  _goToMaps() {
    if (!this.projectId || !this.organizationId) {
      require('../../router').goToRootUrl();
    }
    require('../../router').goToProjectUrl(this.organizationId, this.projectId);
  },

  _goToReactRoute(e) {
    const pathFromClass = e.currentTarget.className
      .split(' ')
      .filter((str) => str !== 'active')[0];
    Backbone.history.navigate(pathFromClass, { trigger: true });
  },

  _goToUserSettings() {
    require('../../router').goToSettingDashboard();
  },

  _goToAnalytics(e) {
    window.location.href = `/vortex/analytics`;
  },

  _goToDataSetup(e) {
    window.location.href = `/vortex/organizations/${this.organizationId}/data-setup`;
  },
});

module.exports = NavbarView;
