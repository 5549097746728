/* eslint-disable */
var FeatureModel = require('./featureModel');

var FeatureCollection = Backbone.Collection.extend({
  model: FeatureModel,
  comparator: 'name',

  url: function () {
    var APP = require('../config');
    var apiCaller = require('../apiCaller');
    return apiCaller.getProjectFeaturesUrl(APP.projectId);
  },

  fetch: function (options = {}) {
    options.cache = false;
    options.wait = true;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
});

Backbone.addSingletonToCollection(FeatureCollection);

module.exports = FeatureCollection;
