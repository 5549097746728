import React from 'react';

interface OnboardingWizardLayoutActionsHeaderProps {
  children: React.ReactNode;
}

export function OnboardingWizardLayoutActionsHeader(
  props: OnboardingWizardLayoutActionsHeaderProps,
) {
  const { children } = props;
  return (
    <div className="onboarding-wizard-layout-actions-header">{children}</div>
  );
}
