/* eslint-disable */
import IntervalFrequency from '../../../../../../../../lib/IntervalFrequency';

const projectStateConfig = require('mapistry-project-state-config');
const _ = require('underscore');

module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/updateParameterStatusModal.pug'),

  ui: {
    $modal: '.modal',
    $addParameter: 'select.add-parameter',
  },

  getChildView() {
    if (this.showSamplingFrequencyPerOutfall) {
      return require('./ParameterStatusRowCompositeView');
    }
    return require('./ParameterStatusRowItemView');
  },

  childViewContainer: '#parameter-collection-container',

  events: {
    'click .btn-done': 'hideModal',
    'click .btn-success[type="submit"]': '_addNewParameter',
  },

  behaviors: {
    DisplayMessages: {},
  },

  childViewOptions(model) {
    const availableDischargeLocations = this.availableDischargeLocations.map(
      (dischargeLocation) => ({
        id: dischargeLocation.get('id'),
        name: dischargeLocation.get('name'),
      }),
    );
    const commonOptions = {
      availableDischargeLocations,
      getParameterDisplayText: this.sectorData.getParameterDisplayText.bind(
        this.sectorData,
      ),
      projectModel: this.projectModel,
      showParameterStatus: this.showParameterStatus,
    };

    if (this.showSamplingFrequencyPerOutfall) {
      return _.extend({}, commonOptions, {
        collection: new Backbone.Collection(model.get('discharge_locations'), {
          comparator: 'name',
        }),
      });
    }
    return _.extend({}, commonOptions, {
      showOutfallsAndFrequency: this.showOutfallsAndFrequency,
    });
  },

  initialize(options = {}) {
    this.availableDischargeLocations = options.availableDischargeLocations;
    this.projectModel = options.projectModel;
    this.sectorData = options.sectorData;
    this.suite = options.suite;
    this.modalTitle = options.modalTitle;
    this.showSamplingFrequencyPerOutfall =
      projectStateConfig.hasSamplingParamFrequencyPerOutfall(
        this.projectModel.toJSON(),
      );
    this.showOutfallsAndFrequency =
      projectStateConfig.hasSamplingParamFrequencyPerParameter(
        this.projectModel.toJSON(),
      ) &&
      projectStateConfig.hasDischargeLocationsPerParameter(
        this.projectModel.toJSON(),
      );
    this.showParameterStatus = options.showParameterStatus;
    let requiredSamplingParametersOptions = {};

    if (
      this.projectModel.has('sic_codes') ||
      this.projectModel.has('industrial_activities') ||
      this.projectModel.has('is_rcra_facility') ||
      this.projectModel.has('site_discharges_into_puget_sound')
    ) {
      requiredSamplingParametersOptions = {
        sicCodeSlugs: this.projectModel.getSicCodeSlugs(),
        industrialActivities: this.projectModel.get('industrial_activities'),
        isRcraFacility: this.projectModel.get('is_rcra_facility'),
        siteDischargesIntoPugetSound: this.projectModel.get(
          'site_discharges_into_puget_sound',
        ),
      };
    }

    this.requiredSamplingParameters =
      this.sectorData.getRequiredSamplingParameters(
        requiredSamplingParametersOptions,
      );
  },

  templateHelpers() {
    let allSamplingParameters =
      this.sectorData.getAvailableSamplingParameters();
    let existingSampleParameterSlugs = this.collection.pluck('slug');
    let availableSampleParameters = allSamplingParameters.filter(
      (parameter) => {
        return !_.contains(existingSampleParameterSlugs, parameter.slug);
      },
    );

    return {
      availableSampleParameters: availableSampleParameters,
      hasExistingParameters: !!this.collection.length,
      modalTitle: this.modalTitle,
      parameterListPanelTitle: this.showParameterStatus
        ? 'Parameter Status'
        : 'Sampling Parameters',
      showParameterStatus: this.showParameterStatus,
      showSamplingFrequencyPerOutfall: this.showSamplingFrequencyPerOutfall,
      showOutfallsAndFrequency: this.showOutfallsAndFrequency,
    };
  },

  onBeforeRender() {
    let parameterSlugs = this.collection.pluck('parameter_slug');

    _.each(this.requiredSamplingParameters, (sp) => {
      if (!_.contains(parameterSlugs, sp.slug)) {
        // this collection update also affects CurrentStatusCollectionView
        this.collection.add({
          project_id: this.projectModel.id,
          parameter_slug: sp.slug,
          frequency: IntervalFrequency.BIANNUAL,
          status: 'baseline',
          suite: this.suite,
        });
      }
    });
  },

  showModal() {
    this.ui.$modal.modal('show');
  },

  hideModal() {
    this.ui.$modal.modal('hide');
  },

  _addNewParameter() {
    return this.collection.createPromise(
      {
        parameter_slug: this.ui.$addParameter.val(),
        project_id: this.projectModel.id,
        frequency: IntervalFrequency.BIANNUAL,
        status: 'baseline',
        suite: this.suite,
      },
      { wait: true },
    );
  },
});
