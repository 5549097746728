var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (averageErrors, averageWarningText, errors, instantaneousErrors, instantaneousLabel, instantaneousWarnings, label) {
      if (instantaneousErrors.length || averageErrors.length) {
pug_html = pug_html + "\u003Csvg class=\"fire warning-red padding-right-13\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (instantaneousWarnings.length) {
pug_html = pug_html + "\u003Csvg class=\"exclamation warning-yellow padding-right-13\"\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + ":&nbsp\u003C\u002Fspan\u003E\u003Cdiv\u003E";
if (instantaneousErrors.length && averageErrors.length) {
pug_html = pug_html + "\u003Cspan" + (" class=\"red cursor-pointer\""+" rel=\"tipsy\""+pug.attr("title", instantaneousErrors.join(', and ') + ', and ' + averageErrors.join(', and '), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = instantaneousLabel + ' & ' + averageWarningText) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (instantaneousErrors.length) {
pug_html = pug_html + "\u003Cspan" + (" class=\"red cursor-pointer\""+" rel=\"tipsy\""+pug.attr("title", instantaneousErrors.join(', and '), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = instantaneousLabel) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (instantaneousWarnings.length && averageErrors.length) {
pug_html = pug_html + "\u003Cspan" + (" class=\"red cursor-pointer\""+" rel=\"tipsy\""+pug.attr("title", instantaneousWarnings.join(', and ') + ', and ' + averageErrors.join(', and '), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = instantaneousLabel + ' & ' + averageWarningText) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else {
if (instantaneousWarnings.length) {
pug_html = pug_html + "\u003Cspan" + (" class=\"color-warning-yellow cursor-pointer\""+" rel=\"tipsy\""+pug.attr("title", instantaneousWarnings.join(', and '), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = instantaneousLabel) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (averageErrors.length) {
pug_html = pug_html + "\u003Cspan" + (" class=\"red cursor-pointer\""+" rel=\"tipsy\""+pug.attr("title", averageErrors.join(', and '), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = averageWarningText) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (errors.length) {
pug_html = pug_html + "\u003Cspan class=\"red\"\u003E" + (pug.escape(null == (pug_interp = errors.join(', and ')) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "averageErrors" in locals_for_with ?
        locals_for_with.averageErrors :
        typeof averageErrors !== 'undefined' ? averageErrors : undefined, "averageWarningText" in locals_for_with ?
        locals_for_with.averageWarningText :
        typeof averageWarningText !== 'undefined' ? averageWarningText : undefined, "errors" in locals_for_with ?
        locals_for_with.errors :
        typeof errors !== 'undefined' ? errors : undefined, "instantaneousErrors" in locals_for_with ?
        locals_for_with.instantaneousErrors :
        typeof instantaneousErrors !== 'undefined' ? instantaneousErrors : undefined, "instantaneousLabel" in locals_for_with ?
        locals_for_with.instantaneousLabel :
        typeof instantaneousLabel !== 'undefined' ? instantaneousLabel : undefined, "instantaneousWarnings" in locals_for_with ?
        locals_for_with.instantaneousWarnings :
        typeof instantaneousWarnings !== 'undefined' ? instantaneousWarnings : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined));
    ;;return pug_html;};
module.exports = template;