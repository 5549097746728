/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ProjectContext from '../../../../contexts/ProjectContext';
import { delayedModalClose } from '../../../../utils';
import {
  Button,
  FieldWrapper,
  Modal,
  SaveButton,
  TextField,
} from '../../../elements';

class AddItemGroup extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      formSaveState: SaveState.CLEAN,
      groupName: '',
      modalIsVisible: false,
    };
    this.state = this.initialState;
    this.createEmissionItemGroup = this.createEmissionItemGroup.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  createEmissionItemGroup() {
    const { groupName } = this.state;
    const { createEmissionItemGroup, groupType, logProjectId } = this.props;
    const { projectId } = this.context;
    if (groupName.length) {
      this.setState(
        {
          formSaveState: SaveState.SAVED,
        },
        () => {
          createEmissionItemGroup(projectId, {
            groupType,
            logProjectId,
            name: groupName,
          });
          delayedModalClose(this.resetState);
        },
      );
    }
  }

  resetState() {
    this.setState(this.initialState);
  }

  render() {
    const { formSaveState, groupName, modalIsVisible } = this.state;
    return (
      <Fragment>
        <div className="flex">
          <div
            className="action__common grow"
            onClick={() => this.setState({ modalIsVisible: true })}
          >
            <SvgIcon identifier="add" />
            <span className="pl2">Add a group</span>
          </div>
        </div>
        <Modal
          footer={
            <div className="modal-footer__actions">
              <Button color="secondary" onClick={this.resetState}>
                {[SaveState.SAVED, SaveState.CLEAN].includes(formSaveState)
                  ? 'Close'
                  : 'Cancel'}
              </Button>
              <span className="ph2" />
              <SaveButton
                onSave={this.createEmissionItemGroup}
                saveState={formSaveState}
              >
                Save group
              </SaveButton>
            </div>
          }
          header={<h4 className="modal-title">Add a group</h4>}
          fullWidth={false}
          maxWidth="xs"
          onClose={this.resetState}
          open={modalIsVisible}
        >
          <FieldWrapper label="Group name">
            <TextField
              controlled
              autoFocus
              onChange={(e) =>
                this.setState({
                  formSaveState: e.target.value.length
                    ? SaveState.DIRTY
                    : SaveState.CLEAN,
                  groupName: e.target.value,
                })
              }
              onEnterKey={this.createEmissionItemGroup}
              value={groupName}
            />
          </FieldWrapper>
        </Modal>
      </Fragment>
    );
  }
}

AddItemGroup.propTypes = {
  createEmissionItemGroup: PropTypes.func.isRequired,
  logProjectId: PropTypes.string.isRequired,
};

AddItemGroup.contextType = ProjectContext;

export default AddItemGroup;
