/* eslint-disable */
const OrganizationTagsView = require('./organizationSettings/organizationTagsView');
const OrganizationSelectionView = require('./organizationSettings/organizationSelectionView');

let ContentLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/organizationSettingsContent.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    organizationSelection: '#organization-selection',
    tags: '#tags',
  },

  ui: {
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
  },

  behaviors: {
    DisplayMessages: {},
  },

  initialize(options) {
    this.currentUser = options.model;
  },

  onShow() {
    let adminOrganizations = this.currentUser.get('adminOrganizations');
    if (!_.some(adminOrganizations)) return;

    let organizationSelectionView = new OrganizationSelectionView({
      model: new Backbone.Model({ adminOrganizations }),
    });
    this.organizationSelection.show(organizationSelectionView);

    let tagsView = new OrganizationTagsView({
      organizationId: adminOrganizations[0].get('id'),
    });
    this.tags.show(tagsView);
  },

  onChildviewShowError(childView, msg) {
    this.triggerMethod('ShowError', msg);
  },
});

module.exports = ContentLayoutView;
