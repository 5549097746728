/* eslint-disable */
var projectStateConfig = require('mapistry-project-state-config');

var LocationRowView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/locationRow.pug'),

  templateHelpers() {
    let samplingUnits = this.sectorData.getSamplingUnits(this.parameterSlug);

    return {
      methodDisplayText: this.sectorData.getSamplingMethodDisplayText(
        this.model.get('method_slug'),
      ),
      samplingUnitsDisplayText:
        (samplingUnits && samplingUnits.display_text) || 'N/A',
      availableSamplingMethods: this.sectorData.getAvailableSamplingMethods(
        this.parameterSlug,
      ),
      parameterSlug: this.parameterSlug,
      isPHLinked: this.isPHLinked,
      alertClass: this._getAlertClassForRow(),
    };
  },

  ui: {
    $parameterValue: '.location-parameter-value',
    $method: '.location-method',
    $detectionLimit: '.detection-limit',
    $reportingLimit: '.reporting-limit',
    $datepicker: '[data-role="datepicker"]',
  },

  behaviors: {
    EnableDatepicker: {
      format: 'yyyy-mm-dd',
    },
  },

  initialize: function (options = {}) {
    this.projectModel = options.projectModel;
    this.parameterSlug = options.parameterSlug;
    this.sectorData = require('industrial-stormwater-sector-data')(
      this.projectModel.get('state'),
    );
    this.isPHLinked = options.isPHLinked;
  },

  _getAlertClassForRow() {
    let sicCodeSlugs = this.projectModel.getSicCodeSlugs();
    let industrialActivities = this.projectModel.getIndustrialActivitySlugs();
    let projectModelJSON = this.projectModel.toJSON();
    let receivingWaterIsSaltwater =
      projectModelJSON.receiving_water_is_saltwater;
    let hardnessOfReceivingWater = projectModelJSON.receiving_water_hardness;
    let parameterSlug = this.parameterSlug;
    let parameterValue = this.model.get('parameter_value');
    let parameterMethod = this.model.get('method_slug');
    let instantaneousNalSatisfied,
      previousExceedance,
      currentExceedance,
      satisfiedSampleRequirement;
    let options = { parameterSlug };

    if (projectStateConfig.isWashingtonIndustrial(projectModelJSON)) {
      _.extend(options, {
        isWesternWA: projectModelJSON.is_western_wa,
        isRcraFacility: projectModelJSON.is_rcra_facility,
        siteDischargesIntoPugetSound:
          projectModelJSON.site_discharges_into_puget_sound,
      });
    } else if (projectStateConfig.isAlabamaIndustrial(projectModelJSON)) {
      let permitDsns = this.projectModel.getPermitDsnSlugs();
      let industrialActivities = this.projectModel.getIndustrialActivitySlugs();
      _.extend(options, { permitDsns, industrialActivities });
    } else {
      _.extend(options, {
        sicCodeSlugs,
        industrialActivities,
        receivingWaterIsSaltwater,
      });
    }

    let isRequiredParam = this.sectorData.isRequiredSamplingParameter(options);

    if (projectStateConfig.hasConsecutiveExceedance(projectModelJSON)) {
      previousExceedance = !this.sectorData.satisfiesBenchmarkLimit({
        parameterSlug,
        parameterValue: this.model.get('parameter_previous_value'),
        sicCodeSlugs,
        industrialActivities,
        hardnessOfReceivingWater,
        receivingWaterIsSaltwater,
      });
      currentExceedance = !this.sectorData.satisfiesBenchmarkLimit({
        parameterSlug,
        parameterValue,
        industrialActivities,
        hardnessOfReceivingWater,
        receivingWaterIsSaltwater,
        sicCodeSlugs,
      });
    }

    if (parameterValue) {
      satisfiedSampleRequirement = true;
      if (projectStateConfig.isAlabamaIndustrial(projectModelJSON)) {
        options.parameterValue = parameterValue;
        options.dischargeToPws =
          options.industrialActivities.indexOf('discharge-to-pws') > -1;
        instantaneousNalSatisfied = this.sectorData.satisfiesLimit(options);
      } else {
        instantaneousNalSatisfied = this.sectorData.satisfiesInstantaneousLimit
          ? this.sectorData.satisfiesInstantaneousLimit({
              parameterSlug,
              parameterValue,
              sicCodeSlugs,
              industrialActivities,
              hardnessOfReceivingWater,
              receivingWaterIsSaltwater,
            })
          : true;
      }
    } else if (projectStateConfig.isAlabamaIndustrial(projectModelJSON)) {
      satisfiedSampleRequirement = this.model.get(
        'satisfied_sample_requirement',
      );
    }

    if (
      (parameterValue && instantaneousNalSatisfied === false) ||
      (isRequiredParam && !satisfiedSampleRequirement && !parameterMethod) ||
      (previousExceedance === true && currentExceedance === true)
    ) {
      return 'alert-danger';
    } else if (currentExceedance === true) {
      return 'alert-warning';
    }
    return '';
  },
});

module.exports = LocationRowView;
