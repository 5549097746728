/* eslint-disable */
var IconModel = Backbone.Model.extend({
  isSharedLibraryIcon: function () {
    const sharedLibrary = this.get('shared_library');
    return !!sharedLibrary;
  },

  url: function () {
    if (this.isSharedLibraryIcon()) {
      return `/images/icons/shared/${this.get('shared_library')}/${this.get(
        'filename',
      )}`;
    }
    return this.has('url')
      ? this.get('url')
      : Backbone.Model.prototype.url.call(this);
  },
});
module.exports = IconModel;
