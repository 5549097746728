/* eslint-disable */
var InfoModel = Backbone.Model.extend({
  initialize: function (options) {
    this.set({
      text: options.text,
      title: options.title || 'Heads Up',
    });
  },
});

module.exports = InfoModel;
