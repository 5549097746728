/* eslint-disable */
let apiCaller = require('../../../../../apiCaller');

let ProjectSubheaderItemView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/projectSubheader.pug'),

  tagName: 'div',

  className: 'flex align-items-center',

  ui: {
    $editAddress: '.edit-address',
    $editCity: '.edit-city',
    $state: '.show-state',
    $editZip: '.edit-zip',
    $saveSiteAddress: '.save-site-address',
    $siteAddress: '.site-address',
    $editSiteAddressIcon: '.edit-site-address-icon',
  },

  behaviors: {
    Tooltip: {},
  },

  events: {
    'click .edit-site-address-icon': '_editSiteAddress',
    'click .save-site-address': '_saveSiteAddress',
  },

  templateHelpers() {
    return {
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      fullAddress: this.fullAddress,
    };
  },

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.fullAddress = this.model.hasAddress() && this.model.getFullAddress();
  },

  _editSiteAddress() {
    this.ui.$siteAddress.hide();
    this.ui.$editSiteAddressIcon.hide();

    this.ui.$editAddress.show();
    this.ui.$editCity.show();
    this.ui.$state.show();
    this.ui.$editZip.show();
    this.ui.$saveSiteAddress.show();

    this.ui.$editAddress.focus();
  },

  _saveSiteAddress() {
    let site_address = this.ui.$editAddress.val();
    let site_city = this.ui.$editCity.val();
    let site_zip = this.ui.$editZip.val();
    return apiCaller
      .geolocate(
        this.model.formatAddress(
          site_address,
          site_city,
          this.model.get('state'),
          site_zip,
        ),
      )
      .bind(this)
      .then((site_address_geo) => {
        return this.model.savePromise(
          { site_address, site_city, site_zip, site_address_geo },
          { wait: true },
        );
      })
      .then(() => {
        this.ui.$editAddress.hide();
        this.ui.$editCity.hide();
        this.ui.$state.hide();
        this.ui.$editZip.hide();
        this.ui.$saveSiteAddress.hide();

        this.ui.$siteAddress
          .text(this.model.hasAddress() && this.model.getFullAddress())
          .show();
        this.ui.$editSiteAddressIcon.show();
      })
      .catch(() => {
        this.triggerMethod(
          'show:error',
          'The address could not be saved. Please check that this is a valid address.',
        );
      });
  },
});

module.exports = ProjectSubheaderItemView;
