/* eslint-disable */
import IntervalFrequency from '../../../../../../../../lib/IntervalFrequency';
import 'core-js/features/array/find-index';

const projectStateConfig = require('mapistry-project-state-config');
const APP = require('../../../../../../../../../js/config');

module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/parameterStatusCompositeRow.pug'),

  childView: require('./DischargeLocationItemView'),

  childViewContainer: '#discharge-location-collection',

  events: {
    'change select.selected-discharge-locations':
      '_handleChangeDischargeLocations',
    'change select.status': '_handleChangeStatus',
  },
  modelEvents: {
    'change:discharge_locations': '_updateDischargeLocations',
  },
  _updateDischargeLocations() {
    this.collection.set(this.model.get('discharge_locations'), {
      comparator: 'name',
    });
  },
  childEvents: {
    'change:dischargeLocationFrequency':
      '_handleChangeDischargeLocationFrequency',
  },
  ui: {
    selectDischargeLocations: 'select.selected-discharge-locations',
    selectStatus: 'select.status',
    delete: '.delete-parameter-status',
  },

  behaviors: {
    Tooltip: {},
    WarnBeforeDelete: {},
  },

  initialize(options = {}) {
    this.availableDischargeLocations = options.availableDischargeLocations;
    this.getParameterDisplayText = options.getParameterDisplayText;
    this.project = options.projectModel.toJSON();
    this.showParameterStatus = options.showParameterStatus;
  },

  templateHelpers() {
    const selectedDischargeLocationIds = this.model.get('discharge_locations')
      ? this.model
          .get('discharge_locations')
          .map((dischargeLocationModel) => dischargeLocationModel.id)
      : [];

    return {
      getParameterDisplayText: this.getParameterDisplayText,
      availableDischargeLocations: this.availableDischargeLocations,
      isWashingtonIndustrial: projectStateConfig.isWashingtonIndustrial(
        this.project,
      ),
      selectedDischargeLocationIds,
      showParameterStatus: this.showParameterStatus,
    };
  },

  _handleChangeDischargeLocations() {
    const selectedIds = this.ui.selectDischargeLocations.val() || [];
    const currentDischargeLocations =
      this.model.get('discharge_locations') || [];

    const matchesId = function (id) {
      return function (otherId) {
        return id === otherId;
      };
    };
    const currentIds = currentDischargeLocations.map((dl) => dl.id);
    const newIds = selectedIds.filter((id) => !currentIds.some(matchesId(id)));

    const newDischargeLocations = this.availableDischargeLocations
      .filter((dischargeLocation) =>
        newIds.some(matchesId(dischargeLocation.id)),
      )
      .map((dischargeLocation) => ({
        id: dischargeLocation.id,
        name: dischargeLocation.name,
        frequency: IntervalFrequency.BIANNUAL,
      }));

    const isDischargeLocationSelected = (dischargeLocation) =>
      selectedIds.some(matchesId(dischargeLocation.id));
    const filteredDischargeLocations = currentDischargeLocations.filter(
      isDischargeLocationSelected,
    );

    const discharge_locations = filteredDischargeLocations.concat(
      newDischargeLocations,
    );
    return this.model.savePromise({ discharge_locations }).then(() => {
      APP.vent.trigger('sampleParameter:update');
    });
  },

  _handleChangeDischargeLocationFrequency(childView, updatedDischargeLocation) {
    const changedId = updatedDischargeLocation.id;
    const changedFrequency = updatedDischargeLocation.frequency;

    const discharge_locations = this.collection.toJSON();
    const idx = discharge_locations.findIndex((dl) => dl.id === changedId);
    discharge_locations[idx].frequency = changedFrequency;
    return this.model.savePromise({ discharge_locations }).then(() => {
      APP.vent.trigger('sampleParameter:update');
    });
  },

  _handleChangeStatus() {
    this._setStatusColor();
    return this.model.savePromise({ status: this.ui.selectStatus.val() });
  },

  onShow() {
    this._setStatusColor();
    this.ui.selectDischargeLocations.select2({
      width: '100%',
      placeholder: 'Select sampling locations',
    });
  },

  _setStatusColor() {
    let status = this.ui.selectStatus.val();
    this.ui.selectStatus.removeClass('mapistry-green');
    this.ui.selectStatus.removeClass('warning-yellow');
    this.ui.selectStatus.removeClass('warning-red');
    switch (status) {
      case 'baseline':
        this.ui.selectStatus.addClass('mapistry-green');
        break;
      case 'level1':
        this.ui.selectStatus.addClass('warning-yellow');
        break;
      case 'level2':
        this.ui.selectStatus.addClass('warning-yellow');
        break;
      case 'level3':
        this.ui.selectStatus.addClass('warning-red');
        break;
    }
  },

  getWarningBeforeDeleteText() {
    let parameterSlug = this.model.get('parameter_slug');
    return `the ${this.getParameterDisplayText(parameterSlug)} parameter?`;
  },
});
