/* eslint-disable */
const ChemicalSelectView = Backbone.Marionette.ItemView.extend({
  template: require('./ChemicalSelectView.pug'),

  modelEvents: {
    change: 'modelChanged',
  },

  initialize(options) {
    this.model.set('showLabel', options.showLabel);
  },

  modelChanged() {
    this.render();
  },
});

module.exports = ChemicalSelectView;
