import { IconButton } from '@monorepo/shared/components';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { localEquivalentOfUTC, UTCEquivalentOfLocal } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  DatePicker,
  FieldWrapper,
  Select,
  SelectCreatable,
  TextField,
} from '../../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function SamplingResultFormParameterLocation({
  analysisDate,
  analysisMethods,
  availableUnits,
  children,
  detectionLimit,
  errors,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  samplingResultId,
  locationName,
  methodSlug,
  methodText,
  onChange,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  onDelete,
  parameterValue,
  projectId,
  reportingLimit,
  showMethodSelect,
  units,
}) {
  const notAvailableOption = {
    label: 'N/A',
    value: null,
  };

  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(projectId);

  let analysisMethodsIsDisabled;
  let analysisOptions;
  let selectedMethodOption;
  if (showMethodSelect) {
    // analysisMethods is already sorted
    analysisMethodsIsDisabled = analysisMethods.length === 0;
    analysisOptions = analysisMethodsIsDisabled
      ? [notAvailableOption]
      : analysisMethods.map((samplingMethod) => ({
          label: samplingMethod.display_text,
          value: samplingMethod.slug,
        }));
    selectedMethodOption = analysisMethodsIsDisabled
      ? notAvailableOption
      : analysisOptions.find((option) => option.value === methodSlug) || null;
    if (!selectedMethodOption && methodText) {
      selectedMethodOption = {
        label: methodText,
        value: methodText,
      };
    }
  }

  const handleMethodChange = useCallback(
    (selection) => {
      if (!showMethodSelect) return;
      const { value } = selection;
      const mapistryMethod = analysisMethods.find((m) => m.slug === value);
      if (mapistryMethod) {
        onChange({ name: 'methodSlug', value });
      } else {
        onChange({ name: 'methodText', value });
      }
    },
    [analysisMethods, onChange, showMethodSelect],
  );

  const unitsDisabled = availableUnits.length === 0;
  const unitsOptions = unitsDisabled
    ? [notAvailableOption]
    : availableUnits
        .map((unit) => ({
          label: unit.displayText,
          value: unit.slug,
        }))
        .sort((u1, u2) =>
          u1.label.toLowerCase() < u2.label.toLowerCase() ? -1 : 1,
        );
  const selectedUnitsOption = unitsDisabled
    ? notAvailableOption
    : unitsOptions.find((option) => option.value === units) || null;

  const hasErrors = errors.length > 0;
  const fieldHasError = (fieldName) =>
    errors.find((err) => err.name === fieldName) != null;

  const handleAnalysisDateChange = useCallback(
    (date) => {
      const parsedDate = date
        ? UTCEquivalentOfLocal(new Date(date.setHours(0, 0, 0)))
        : null;

      onChange({ name: 'analysisDate', value: parsedDate });
    },
    [onChange],
  );

  return (
    <div className="result-input-row">
      <div className="form-inline">
        <div className="location-name">
          {locationName}
          {hasErrors && (
            <SvgIcon
              className="wastewater-sampling-result-icon-error"
              identifier="exclamation"
              withBorder
            />
          )}
        </div>
        <div className="parameter-value-column parameter-value-column-input">
          <div className="parameter-value-column-value pr2">
            <SelectCreatable
              error={fieldHasError('parameterValue')}
              isClearable={false}
              menuIsOpen={parameterValue == null || parameterValue === ''}
              onChange={(selection) =>
                onChange({
                  name: 'parameterValue',
                  value: selection.value,
                })
              }
              onInputChange={(value, { action }) => {
                // Prevent clears on input-blur and menu-close actions.
                if (action === 'input-change') {
                  onChange({
                    name: 'parameterValue',
                    value,
                  });
                }
              }}
              options={[
                {
                  label: 'ND (Not Detected)',
                  value: 'ND',
                },
              ]}
              value={
                parameterValue == null
                  ? null
                  : {
                      label: parameterValue,
                      value: parameterValue,
                    }
              }
            />
          </div>
          <div className="parameter-value-column-units">
            <Select
              error={fieldHasError('units')}
              isClearable={false}
              isDisabled={unitsDisabled}
              isFixed
              options={unitsOptions}
              value={selectedUnitsOption}
              onChange={(selection) =>
                onChange({
                  name: 'units',
                  value: selection.value,
                })
              }
            />
          </div>
        </div>
        {showMethodSelect && (
          <SelectCreatable
            error={fieldHasError('methodSlug') || fieldHasError('methodText')}
            isClearable={false}
            isDisabled={analysisMethodsIsDisabled}
            isFixed
            onChange={handleMethodChange}
            options={analysisOptions}
            value={selectedMethodOption}
          />
        )}
        {!showMethodSelect && (
          <TextField
            error={fieldHasError('methodText')}
            value={methodText}
            onChange={(e) =>
              onChange({
                name: 'methodText',
                value: e.target.value,
              })
            }
          />
        )}
        <FieldWrapper
          className="field-w-units limit-column"
          footerText={selectedUnitsOption?.label}
        >
          <TextField
            error={fieldHasError('reportingLimit')}
            value={reportingLimit}
            onChange={(e) =>
              onChange({
                name: 'reportingLimit',
                value: e.target.value,
              })
            }
          />
        </FieldWrapper>
        <FieldWrapper
          className="field-w-units limit-column"
          footerText={selectedUnitsOption?.label}
        >
          <TextField
            error={fieldHasError('detectionLimit')}
            value={detectionLimit}
            onChange={(e) =>
              onChange({
                name: 'detectionLimit',
                value: e.target.value,
              })
            }
          />
        </FieldWrapper>
        <DatePicker
          error={fieldHasError('analysisDate')}
          onChange={handleAnalysisDateChange}
          maxDate={new Date()}
          showHelperText={false}
          value={localEquivalentOfUTC(analysisDate)}
        />
        <div className="delete-column">
          <IconButton
            className="delete-column-input"
            onClick={onDelete}
            title="Delete sampling result"
            disabled={!samplingResultId || !hasProjectUpdatePermission}
          >
            <SvgIcon identifier="trash" />
          </IconButton>
        </div>
      </div>
      {children}
    </div>
  );
}

SamplingResultFormParameterLocation.defaultProps = {
  analysisDate: null,
  children: null,
  detectionLimit: null,
  errors: [],
  parameterValue: null,
  methodSlug: null,
  methodText: null,
  reportingLimit: null,
  units: null,
};

SamplingResultFormParameterLocation.propTypes = {
  analysisDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  analysisMethods: PropTypes.arrayOf(
    PropTypes.shape({
      display_text: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  availableUnits: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  children: PropTypes.node,
  detectionLimit: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      errorMessage: PropTypes.string,
    }),
  ),
  methodSlug: PropTypes.string,
  methodText: PropTypes.string,
  locationName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  parameterValue: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  reportingLimit: PropTypes.string,
  showMethodSelect: PropTypes.bool.isRequired,
  units: PropTypes.string,
};
