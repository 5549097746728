var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (availableRoles, defaultSelectedRole, roleLabels) {
      pug_html = pug_html + "\u003Cform class=\"invitation-form form-inline project-invite-users-form\"\u003E\u003Cdiv class=\"success-msg alert alert-success\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"error-msg alert alert-danger\"\u003E\u003C\u002Fdiv\u003E\u003Ch5\u003EInvite Users\u003C\u002Fh5\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cdiv class=\"single-select-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput class=\"form-control invited-user-name\" type=\"text\" name=\"name\" placeholder=\"Name\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cselect class=\"form-control\" id=\"project-invitation-role\"\u003E";
// iterate availableRoles
;(function(){
  var $$obj = availableRoles;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var roleValue = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", roleValue, true, true)+pug.attr("selected", roleValue === defaultSelectedRole, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = roleLabels[roleValue]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var roleValue = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", roleValue, true, true)+pug.attr("selected", roleValue === defaultSelectedRole, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = roleLabels[roleValue]) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cspan class=\"badge clickable invite-help\" data-toggle=\"popover\" data-placement=\"bottom\" data-trigger=\"hover\"\u003E?\u003C\u002Fspan\u003E\u003Cbutton class=\"btn btn-success\" id=\"send-project-invitation\" type=\"submit\" data-id=\"close\" data-loading-text=\"Sending...\"\u003ESend\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";
    }.call(this, "availableRoles" in locals_for_with ?
        locals_for_with.availableRoles :
        typeof availableRoles !== 'undefined' ? availableRoles : undefined, "defaultSelectedRole" in locals_for_with ?
        locals_for_with.defaultSelectedRole :
        typeof defaultSelectedRole !== 'undefined' ? defaultSelectedRole : undefined, "roleLabels" in locals_for_with ?
        locals_for_with.roleLabels :
        typeof roleLabels !== 'undefined' ? roleLabels : undefined));
    ;;return pug_html;};
module.exports = template;