/* eslint-disable */
import { uploadFileToMapbox } from '@monorepo/old-web/js/fileUpload/mapboxFileUploader';
import util from '../utility';
const UploadProgressView = require('../fileUpload/progressView');

import Bluebird from 'bluebird';

Bluebird.config({
  cancellation: true,
});

let LargeFileUploadProgressTray = Backbone.Marionette.ItemView.extend({
  template: require('./templates/largeFileUploadProgressTray.pug'),

  ui: {
    $fileName: '#large-file-upload-name-area',
    $fileStats: '#large-file-upload-stats',
    $progressBar: '#large-file-upload-progress-bar',
    $closeButton: '#large-file-upload-close',
    $body: '#large-file-upload-body',
    $error: '.error-text',
  },

  events: {
    'click @ui.$closeButton': '_checkForClose',
  },

  initialize() {
    this._progressBar = new UploadProgressView();
    this.windowListener = this._checkForClose.bind(this);
  },

  /**
   * Call this before you call render or anything else
   */
  startUploadingFile(layerModel, file) {
    this.model = layerModel;
    this.file = file;
    this.uploadPromise = this._handleUploadAerialImagery(layerModel, file);
    return this.uploadPromise;
  },

  showError(err) {
    const errorText =
      (err && err.message) ||
      (err && err.name) ||
      (typeof err === 'string' && err) ||
      'Error processing file';
    this.ui.$body.hide();
    this.ui.$error.show();
    this.ui.$error.text(errorText);
    this.errorShown = true;
  },

  onRender() {
    const fileName = (this.file && this.file.name) || 'Imagery File';
    this.ui.$progressBar.prepend(this._progressBar.render().el);
    this.ui.$fileName.html(fileName);
    this._updateProgress(-1);

    if (!this.errorShown) {
      this.ui.$error.hide();
    }

    // eslint-disable-next-line no-undef
    $('#file-uploader-tray').show();
  },

  onDestroy() {
    this.closeTray();
  },

  _checkForClose(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (this.errorShown) {
      return this.closeTray();
    }

    if (window.confirm('Are you sure you want to stop this upload?')) {
      this.trigger('canceled');
    }
  },

  _updateProgress(percent = -1) {
    const fileSize = (this.file && this.file.size) || 0;
    const readableFileSize = util.getReadableFileSizeString(fileSize);

    const uploadedSoFar =
      percent === -1
        ? '0'
        : util.getReadableFileSizeString(
            Math.round((fileSize * percent) / 100),
          );

    const fileInfoString = `${uploadedSoFar} of ${readableFileSize} uploaded`;
    this.ui.$fileStats.html(fileInfoString);

    this._progressBar.updateProgress(percent === -1 ? 1 : percent);
  },

  _handleUploadAerialImagery(savedLayerModel, file) {
    return uploadFileToMapbox(
      savedLayerModel.id,
      savedLayerModel.get('name'),
      file,
      (p) => this._updateProgress(p),
    ).then(({ mapbox_tileset_id, mapbox_tileset_slug }) => {
      return savedLayerModel.save(
        {
          mapbox_tileset_id,
          mapbox_tileset_slug: null,
          mapbox_filename: file.name,
          mapbox_finished_processing_at: null,
          mapbox_upload_error: null,
        },
        {
          wait: true,
        },
      );
    });
  },

  closeTray() {
    this._progressBar.updateProgress(0);
    $('#file-uploader-tray').hide();
    this.errorShown = false;
  },
});

export default LargeFileUploadProgressTray;
