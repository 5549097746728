/* eslint-disable */
import UTIL from '../utility';
const UploadProgressView = require('../fileUpload/progressView');

const TWENTY_GB = 1024 * 1024 * 1024 * 20;

const AerialImageryUploadView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/aerialImageryUploadArea.pug'),

  ui: {
    $uploadAerialImageryWrapper: '#aerial-imagery-data-wrapper',
    $aerialImageFileSelectBtn: '#upload-aerial-imagery-file-selector',
    $aerialImageryProgressBar: '#aerial-imagery-progress-bar',
    $clickableFilenameLabel: '#clickable-file-label',
    $resetFileName: '#reset-file-name',
  },

  templateHelpers() {
    let _this = this;

    return {
      getFileName: () =>
        (_this.newFile && _this.newFile.name) ||
        _this.model.get('mapbox_filename') ||
        'Select File',
      didUserSelectNewFile: () => _this.didUserSelectNewFile(),
      getFileSize: () =>
        UTIL.getReadableFileSizeString(
          (_this.newFile && _this.newFile.size) || 0,
        ),
    };
  },

  events: {
    'change @ui.$aerialImageFileSelectBtn': '_setNewFile',
    'click @ui.$clickableFilenameLabel': '_clickOnFileButton',
    'click @ui.$resetFileName': '_resetFileSelection',
  },

  regions: {
    layerNameInput: '#layer-name-input-container',
    layerTagsSelect: '#layer-tags-select-container',
  },

  onRender() {
    this._addProgressBar();
  },

  _addProgressBar() {
    this._progressBar = new UploadProgressView();
    this.ui.$aerialImageryProgressBar.prepend(this._progressBar.render().el);
  },

  _resetFileSelection() {
    this.newFile = null;
    this.render();
  },

  _clickOnFileButton() {
    const fileEl = this.ui.$aerialImageFileSelectBtn.el;
    if (fileEl && document.createEvent) {
      const evt = document.createEvent('MouseEvents');
      evt.initEvent('click', true, false);
      fileEl.dispatchEvent(evt);
    }
  },

  _setNewFile() {
    const [err, file] = this.validateFile();
    if (err) {
      return err;
    }

    this.newFile = file;
    this.render();
  },

  validateFile() {
    const file = this.getFileObject();

    let msg;
    if (!(file && file.name)) {
      msg = 'Must select a file';
    } else if (file.size > TWENTY_GB) {
      msg = 'File size cannot exceed 20 GB';
    }

    return [msg, file];
  },

  getFileObject() {
    return this.ui.$aerialImageFileSelectBtn[0].files.item(0) || this.newFile;
  },

  didUserSelectNewFile() {
    const fileName = this.newFile && this.newFile.name;
    const isDifferent = this.model.get('mapbox_filename') !== fileName;
    return !!fileName && isDifferent;
  },
});

module.exports = AerialImageryUploadView;
