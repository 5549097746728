/* eslint-disable */
const _ = require('underscore');
const rootLayoutView = require('../rootLayoutView');
const NavbarView = require('../dashboards/navbar/NavbarView');
const ProjectLayoutView = require('./ProjectLayoutView');
const APP = require('../config');
const BaseController = require('../BaseController');
const organizationCollection =
  require('../models_collections/organizationCollection').singleton();
const projectCollection =
  require('../models_collections/projectCollection').singleton();
const bigSpinner = require('../services/bigSpinner');

var ProjectController = BaseController.extend({
  showProject: function (organizationId, projectId, queryParams) {
    // Show a loading view until we've rendered the project
    bigSpinner.show();

    APP.projectId = projectId;
    APP.organizationId = organizationId;

    var dataLoader = require('./dataLoader');

    return this.enforceAuthentication()
      .then(
        this._setPageUpForDownloadMapModeIfNecessary.bind(this, queryParams),
      )
      .then(dataLoader.load.bind(dataLoader))
      .then(() => {
        var project = projectCollection.get(projectId);
        var projectMapsService = require('./services/projectMapsService');
        var organization = organizationCollection.get(organizationId);

        projectMapsService.initialize(project.id);
        rootLayoutView.setupPageForProjectViewerIfNecessary();
        rootLayoutView.navbar.show(
          new NavbarView({
            organizationId,
            organization,
            project,
            activeClass: 'map',
          }),
        );
        rootLayoutView.content.show(new ProjectLayoutView({ model: project }));
        return null; // suppress errors from runaway promises
      })
      .catch(this.handleError.bind(this))
      .finally(bigSpinner.hide.bind(bigSpinner));
  },

  redirectToShowProject: function (projectId) {
    var router = require('../router');

    return this.enforceAuthentication()
      .then(() => projectCollection.fetchPromise())
      .then(function (projects) {
        var currentProject = projects.get(projectId);
        router.goToProjectUrl(
          currentProject.get('organization_id'),
          currentProject.id,
        );
      })
      .catch(this.handleError.bind(this));
  },

  _setPageUpForDownloadMapModeIfNecessary: function (options) {
    if (!_.isObject(options)) {
      return;
    }

    if (options.print_map_mode == 1) {
      rootLayoutView.addClass('download-map-mode');
    }

    if (options.orientation === 'portrait') {
      rootLayoutView.addClass('download-map-portrait');
    }

    if (options.map_size === 'tabloid') {
      rootLayoutView.addClass('download-map-tabloid');
    }
  },
});

module.exports = ProjectController;
