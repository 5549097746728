var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (anyExceedances, benchmarkLimitText) {
      pug_html = pug_html + "\u003Ctr\u003E\u003Ctd style=\"width: 200px\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = 'Benchmark Limit: ' + benchmarkLimitText) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E";
if (anyExceedances) {
pug_html = pug_html + "\u003Ctd class=\"alert-warning\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = 'A yellow row means there is a single exceedance.') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003Ctd class=\"alert-danger\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = 'A red row means there are at least 2 consecutive exceedances and a corrective plan must be submitted within 90 days of the end of the monitoring period.') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }.call(this, "anyExceedances" in locals_for_with ?
        locals_for_with.anyExceedances :
        typeof anyExceedances !== 'undefined' ? anyExceedances : undefined, "benchmarkLimitText" in locals_for_with ?
        locals_for_with.benchmarkLimitText :
        typeof benchmarkLimitText !== 'undefined' ? benchmarkLimitText : undefined));
    ;;return pug_html;};
module.exports = template;