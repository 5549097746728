var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isTeslaProject) {
      pug_html = pug_html + "\u003Cdiv id=\"search-bar\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"toggle-sidebar\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"download-map-button\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"geolocate-button\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"street-overlay-button\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"topography-overlay-button\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"opacity-slider\"\u003E\u003C\u002Fdiv\u003E";
if (isTeslaProject) {
pug_html = pug_html + "\u003Cdiv class=\"map-component\" id=\"tesla-fremont-level-1-button\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"map-component\" id=\"tesla-fremont-level-2-button\"\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "isTeslaProject" in locals_for_with ?
        locals_for_with.isTeslaProject :
        typeof isTeslaProject !== 'undefined' ? isTeslaProject : undefined));
    ;;return pug_html;};
module.exports = template;