import { useEffect, useRef } from 'react';

/**
 * Use this hook to check whether a component is mounted or not.
 * Common use case is inside an async callback before setting component state.
 * If async function resolves after a user switches to a different view,
 * that will cause the async callback to be executed after the component was unmounted.
 *
 * @example
 
 const isMounted = useIsMountedRef();
 const [isLoading, setIsLoading] = useState(false);
 const handler = useCallback(async () => {
    try {
      setIsLoading(true);
      async doSomethingAsync();
      if (!isMounted.current) return;
      setIsLoading(false);
    } catch (err) {
      if (!isMounted.current) return;
      setIsLoading(false);
    }
 }, [isMounted, showSuccessToast]);
 */
export const useIsMountedRef = () => {
  const isMounted = useRef(true);

  // Set isMounted to false when component unmounts
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  ); // Only run on mount and unmount

  return isMounted;
};
