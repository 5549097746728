/* eslint-disable */
var APP = require('../config');
var IconModel = require('./iconModel');

var IconCollection = Backbone.Collection.extend({
  model: IconModel,

  initialize: function () {
    // Listen only once because this should only happen on initial fetch
    this.listenToOnce(this, 'sync', this._setExpiresAt);
  },

  _setExpiresAt: function () {
    if (!this.models || !this.models[0]) {
      return;
    }

    var icon = this.models[0];
    var url = icon.url();
    if (!url || icon.isSharedLibraryIcon()) {
      return;
    }

    // Expire 10 min before actual expiration just as a buffer.
    // AWS uses seconds and not milliseconds for their expires value.
    this._expiresAt =
      url.split('Expires=').pop().split('&').shift() * 1000 - 600000;
  },

  isExpired: function () {
    return Date.now() > this._expiresAt;
  },

  refresh: function (cb) {
    this.fetch({
      success: function () {
        this._setExpiresAt();
        cb();
      }.bind(this),
      error: function (model, response) {
        APP.log({
          func: 'refresh',
          backboneFunc: 'fetch',
          collection: 'iconCollection',
          projectId: this.projectId,
          error: response.responseText,
        });
      }.bind(this),
    });
  },

  isExistingName: function (newName) {
    var names = this.pluck('name').map(function (name) {
      return name.toLowerCase();
    });

    return _.contains(names, newName.toLowerCase());
  },

  fetch: function (options = {}) {
    options.cache = false;
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  url: function () {
    return (
      APP.apiUrl +
      '/organizations/' +
      APP.organizationId +
      '/projects/' +
      APP.projectId +
      '/icons'
    );
  },
});

Backbone.addSingletonToCollection(IconCollection);

module.exports = IconCollection;
