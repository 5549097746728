/* eslint-disable */
var BaseModalView = Backbone.View.extend({
  _clearAlerts: function () {
    this.$('.error-msg').html('').slideUp();
    this.$('.success-msg').html('').slideUp();
  },

  _showErrors: function (errors) {
    var errorMessage =
      'Sorry, there was an error completing that action, please try again soon.';

    if (errors) {
      if (errors.responseText) {
        errorMessage = errors.responseText;
      } else if (errors.message) {
        errorMessage = errors.message;
      } else if (typeof errors === 'string') {
        errorMessage = errors;
      }
    }

    this._clearAlerts();
    this.$('.error-msg').html(errorMessage).slideDown();
  },

  _showSuccess: function (message) {
    this._clearAlerts();
    this.$('.success-msg').html(message).slideDown().delay(5000).slideUp();
  },

  /**
   * Toggles the button between loading and not loading
   */
  _toggleButton: function () {
    this.$('.btn').toggle();
    this.$('.loader').toggle();
  },
});

module.exports = BaseModalView;
