/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/noExceedances.pug'),
  tagName: 'li',
  className: 'flex flex-center flex-stretch',
  initialize(options) {
    this.text = options.text;
  },
  templateHelpers() {
    return { text: this.text };
  },
});
