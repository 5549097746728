/* eslint-disable */
// Views
var AuthenticationController = require('./AuthenticationController');
var BaseRouter = require('../BaseRouter');

var AuthenticationRouter = BaseRouter.extend({
  controller: new AuthenticationController(),

  appRoutes: {
    login: 'login',
    logout: 'logout',
    'forgot-password': 'forgotPassword',
    'password-reset': 'showPasswordReset',
    'sign-up': 'signUp',
    'set-recovery-question': 'setRecoveryQuestion',
    'accept-tos': 'acceptTos',
  },
});

module.exports = AuthenticationRouter;
