import { SheetConfig } from '@flatfile/api/api';
import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import {
  getNumericColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { isValidNumber } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { RecordValue } from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { Rules } from '@monorepo/shared/utils/CustomFrequencyValidator';
import { defaultSelectedRepeatOn } from '@monorepo/shared/utils/frequencyUtils';
import {
  CustomFrequency,
  CustomFrequencyMessage,
  Days,
  IntervalFrequencyEnum,
  MonthOfYear,
  Months,
} from 'mapistry-shared';

export enum CustomFrequencyFieldKeys {
  customFrequency = 'customFrequency',
  frequencyNumberOfEvents = 'frequencyNumberOfEvents',
  frequencyRepeatEvery = 'frequencyRepeatEvery',
  frequencyStartMonth = 'frequencyStartMonth',
  frequencyStartDay = 'frequencyStartDay',
  frequencyRepeatOn = 'frequencyRepeatOn',
}

const monthOptions = Object.keys(MonthOfYear).map((key) => ({
  value: key,
  /* @ts-expect-error `key` is a key of `MonthOfYear` */
  label: MonthOfYear[key],
}));

export const customFrequencyFieldSettings: SheetConfig['fields'] = [
  getNumericColumnSetting({
    label: '# of events (frequency)',
    key: CustomFrequencyFieldKeys.frequencyNumberOfEvents,
    description:
      'How many time an event should take place, e.g. "2" for twice a week',
  }),
  getNumericColumnSetting({
    label: 'Repeat every ... (frequency)',
    key: CustomFrequencyFieldKeys.frequencyRepeatEvery,
    description:
      'How ofter an event should be repeated, e.g. "4" for every four months',
  }),
  getSingleSelectColumnSetting(
    {
      label: 'Start month (frequency)',
      key: CustomFrequencyFieldKeys.frequencyStartMonth,
      description:
        'For annual and monthly frequencies, from which month we should start count, e.g. "Apr" for semi-annual events with due dates in Mar and Sep',
    },
    monthOptions,
  ),
  getTextColumnSetting({
    label: 'Start day (frequency)',
    key: CustomFrequencyFieldKeys.frequencyStartDay,
    description:
      'For annual and monthly frequencies, which day of a month to start from. For weekly frequency, what day of a week to start from, e.g. "7" for Sunday',
  }),
  getTextColumnSetting({
    label: 'Repeat on ... (frequency)',
    key: CustomFrequencyFieldKeys.frequencyRepeatOn,
    description:
      'For monthly frequency, comma-separated list of months at which an event should take place, e.g. "Jun, Jul, Aug" for summer months',
  }),

  // Only internal Mapistry tools should allow import with custom frequencies and readonly fields.
  getTextColumnSetting({
    label: '[Do not edit] Custom Frequency',
    key: CustomFrequencyFieldKeys.customFrequency,
    readonly: true,
  }),
];

const customizableFrequencies = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.YEAR,
];

function isCustomizableFrequency(
  frequency: RecordValue,
): frequency is IntervalFrequencyEnum {
  return customizableFrequencies.includes(frequency as IntervalFrequencyEnum);
}

export function parseCustomFrequency(
  record: FlatfileRecord,
  frequencyKey: string,
): void {
  const frequency = record.get(frequencyKey);

  const numberOfEventsValue = record.get(
    CustomFrequencyFieldKeys.frequencyNumberOfEvents,
  );
  const everyValue = record.get(CustomFrequencyFieldKeys.frequencyRepeatEvery);
  const repeatOnValue = record.get(CustomFrequencyFieldKeys.frequencyRepeatOn);
  const startDayValue = record.get(CustomFrequencyFieldKeys.frequencyStartDay);
  const startMonthValue = record.get(
    CustomFrequencyFieldKeys.frequencyStartMonth,
  );

  // No custom frequency
  if (
    !numberOfEventsValue &&
    !everyValue &&
    !repeatOnValue &&
    !startDayValue &&
    !startMonthValue
  ) {
    record.set(CustomFrequencyFieldKeys.customFrequency, null);
    return;
  }

  // Custom frequency options were provided
  if (!frequency) {
    record.addError(
      frequencyKey,
      'You specified custom frequency options but not the frequency itself',
    );
    return;
  }

  // Only specific frequencies can be customized
  if (!isCustomizableFrequency(frequency)) {
    record.addError(
      frequencyKey,
      `Only next frequencies can be customized: ${customizableFrequencies.join(
        ', ',
      )}`,
    );

    return;
  }

  const customFrequencyFileImportValidator =
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    new CustomFrequencyFileImportValidator(frequency);
  const { customFrequency, fieldValues, errors } =
    customFrequencyFileImportValidator.validate(record);

  const friendlyMessage = new CustomFrequencyMessage(
    frequency,
    customFrequency,
  );

  record.set(
    CustomFrequencyFieldKeys.customFrequency,
    JSON.stringify(customFrequency),
  );
  record.addInfo(frequencyKey, friendlyMessage.getShortMessage());

  Object.keys(fieldValues).forEach((fieldKey) => {
    /* @ts-expect-error expected invalid values */
    record.set(fieldKey, fieldValues[fieldKey]);
  });
  Object.keys(errors).forEach((fieldKey) => {
    /* @ts-expect-error expected invalid values */
    record.addError(fieldKey, errors[fieldKey]);
  });
}

type ValidationResult<ValueType> = {
  value: ValueType;
  error?: string;
};

function validatePositiveInteger(
  value: RecordValue,
  defaultValue: number | undefined,
): ValidationResult<
  typeof defaultValue extends undefined ? number | undefined : number
> {
  if (!value) {
    return {
      value: defaultValue as typeof defaultValue extends undefined
        ? undefined
        : number,
    };
  }
  const parsedNumber = Number(value);
  const isValid =
    isValidNumber(value) && Number.isInteger(parsedNumber) && parsedNumber > 0;
  const parsedNumberOrDefault = isValid ? Number(value) : defaultValue;
  return {
    value: parsedNumberOrDefault as typeof defaultValue extends undefined
      ? number | undefined
      : number,
    error: isValid ? undefined : 'Must be positive integer',
  };
}

function isRepeatOnListValid(
  givenRepeatOnList: string[],
  defaultRepeatOnList: Days[] | Months[],
): givenRepeatOnList is Days[] | Months[] {
  const hasInvalidRepeatOn = givenRepeatOnList.some(
    (i) => !(defaultRepeatOnList as string[]).includes(i),
  );
  return !hasInvalidRepeatOn;
}

const defaultNumberOfEvents = 1;
const defaultEvery = 1;
const defaultStartDays: Partial<Record<IntervalFrequencyEnum, number>> = {
  [IntervalFrequencyEnum.WEEK]: 7, // Sunday
  [IntervalFrequencyEnum.MONTH]: 1,
  [IntervalFrequencyEnum.YEAR]: 1,
};
const defaultStartMonths: Partial<Record<IntervalFrequencyEnum, number>> = {
  [IntervalFrequencyEnum.MONTH]: 0, // Jan
  [IntervalFrequencyEnum.YEAR]: 0, // Jan
};

class CustomFrequencyFileImportValidator {
  frequency: IntervalFrequencyEnum;

  customFrequency: CustomFrequency;

  fieldValues: { [key in CustomFrequencyFieldKeys]?: RecordValue } = {};

  errors: { [key in CustomFrequencyFieldKeys]?: string } = {};

  constructor(frequency: IntervalFrequencyEnum) {
    this.frequency = frequency;
    this.customFrequency = {
      numberOfEvents: defaultNumberOfEvents,
      every: defaultEvery,
    };
  }

  validate(record: FlatfileRecord) {
    const numberOfEvents = record.get(
      CustomFrequencyFieldKeys.frequencyNumberOfEvents,
    );
    this.validateNumberOfEvents(numberOfEvents);
    const every = record.get(CustomFrequencyFieldKeys.frequencyRepeatEvery);
    this.validateRepeatEvery(every);
    const startDay = record.get(CustomFrequencyFieldKeys.frequencyStartDay);
    this.validateStartDay(startDay);
    const startMonth = record.get(CustomFrequencyFieldKeys.frequencyStartMonth);
    this.validateStartMonth(startMonth);
    // Repeat on should be validated last because it requires other custom frequency options
    const repeatOn = record.get(CustomFrequencyFieldKeys.frequencyRepeatOn);
    this.validateRepeatOn(repeatOn);

    return {
      customFrequency: this.customFrequency,
      fieldValues: this.fieldValues,
      errors: this.errors,
    };
  }

  validateNumberOfEvents(numberOfEvents: RecordValue) {
    const { value, error } = validatePositiveInteger(
      numberOfEvents,
      defaultNumberOfEvents,
    );
    this.customFrequency.numberOfEvents = value;
    if (error) {
      this.errors[CustomFrequencyFieldKeys.frequencyNumberOfEvents] = error;
    } else {
      this.fieldValues[CustomFrequencyFieldKeys.frequencyNumberOfEvents] =
        value;
    }
  }

  validateRepeatEvery(repeatEvery: RecordValue) {
    const { value, error: positiveIntegerError } = validatePositiveInteger(
      repeatEvery,
      defaultEvery,
    );
    let error = positiveIntegerError;
    if (!error) {
      const supportedEvery = Rules.SupportedEvery[this.frequency];
      error = supportedEvery.includes(value)
        ? undefined
        : `${
            this.frequency
          } frequency supports next values: ${supportedEvery.join(', ')}`;
    }
    this.customFrequency.every = value;
    if (error) {
      this.errors[CustomFrequencyFieldKeys.frequencyRepeatEvery] = error;
    } else {
      this.fieldValues[CustomFrequencyFieldKeys.frequencyRepeatEvery] = value;
    }
  }

  validateStartDay(startDay: RecordValue) {
    const { value, error: positiveIntegerError } = validatePositiveInteger(
      startDay,
      defaultStartDays[this.frequency],
    );
    let error = positiveIntegerError;
    if (!error) {
      const maxStartDay = Rules.MaxStartDay[this.frequency];
      error =
        maxStartDay && value > maxStartDay
          ? `Max start day for ${this.frequency} frequency is ${maxStartDay}`
          : undefined;
    }
    this.customFrequency.startDay = value;
    if (error) {
      this.errors[CustomFrequencyFieldKeys.frequencyStartDay] = error;
    } else {
      this.fieldValues[CustomFrequencyFieldKeys.frequencyStartDay] = value;
    }
  }

  validateStartMonth(startMonth: RecordValue) {
    // No need for additional validation because this field is a select box
    this.customFrequency.startMonth = startMonth
      ? Number(startMonth)
      : defaultStartMonths[this.frequency];
  }

  validateRepeatOn(repeatOn: RecordValue) {
    // In frequency popup default daily "repeat on" excludes weekends, but we need all week days
    const defaultRepeatOnList =
      this.frequency === IntervalFrequencyEnum.DAY
        ? Object.values(Days)
        : defaultSelectedRepeatOn(this.frequency, this.customFrequency);

    if (!repeatOn) {
      if (!defaultRepeatOnList.length) return;
      this.customFrequency.repeatOn = defaultRepeatOnList;
      this.fieldValues[CustomFrequencyFieldKeys.frequencyRepeatOn] =
        defaultRepeatOnList.join(', ');
      return;
    }

    if (!defaultRepeatOnList.length) {
      this.errors[CustomFrequencyFieldKeys.frequencyRepeatOn] =
        'This frequency doesn\'t support "Repeat on" value';
      return;
    }

    const givenRepeatOnList = String(repeatOn)
      .split(',')
      .map((i) => i.trim());
    if (isRepeatOnListValid(givenRepeatOnList, defaultRepeatOnList)) {
      this.customFrequency.repeatOn = givenRepeatOnList;
    } else {
      this.errors[
        CustomFrequencyFieldKeys.frequencyRepeatOn
      ] = `This custom frequency can be repeated only on: ${defaultRepeatOnList.join(
        ', ',
      )}`;
    }
  }
}
