/* eslint-disable */
let SamplingResultSummaryRowView = require('./SamplingResultSummaryRowView');
let NoSamplingParametersView = require('./NoSamplingParametersView');

let SamplingResultSummaryCollectionView =
  Backbone.Marionette.CollectionView.extend({
    tagName: 'ul',

    className: 'list-styled',

    childView: SamplingResultSummaryRowView,

    emptyView: NoSamplingParametersView,
  });

module.exports = SamplingResultSummaryCollectionView;
