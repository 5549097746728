/* eslint-disable */
import P from 'bluebird';
import APP from '../config';
const LayerTagsSelectView = require('./LayerTagsSelectView');
const AerialImageryUploadView = require('./AerialImageryUploadView');
const layerCollection =
  require('../models_collections/layerCollection').singleton();

const CreateLayerView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/createLayer.pug'),

  ui: {
    $layerType: '#layer-type',
    $layerNameInput: 'input#layer-name',
  },

  events: {
    'submit #layer-name-form': '_handleSubmit',
    'change @ui.$layerType': '_checkForAerialImagerySelection',
  },

  regions: {
    layerNameInput: '#layer-name-input-container',
    layerTagsSelect: '#layer-tags-select-container',
    aerialImageryUploadContainer: '#aerial-imagery-upload-container',
  },

  onRender() {
    this.layerTagsSelect.show(new LayerTagsSelectView({ model: this.model }));
    this.aerialImageryUploadContainer.show(
      new AerialImageryUploadView({ model: this.model }),
    );
    const currentValues = this._getCurrentValues();
    this._showAppropriateFormPartsBasedOnLayerType(currentValues.type);
    this.ui.$layerNameInput.focus();
  },

  /**
   * If aerial imagery is selected, we want to remove the form tags option and
   * offer an upload button instead. This function only runs when creating a new
   * layer after the user toggles the layer type
   */
  _checkForAerialImagerySelection(e) {
    this._showAppropriateFormPartsBasedOnLayerType(
      e && e.target && e.target.value,
    );
  },

  _showAppropriateFormPartsBasedOnLayerType(type) {
    if (type === 'aerialimagery') {
      this.layerTagsSelect.$el.hide();
      this.aerialImageryUploadContainer.$el.show();
    } else {
      this.layerTagsSelect.$el.show();
      this.aerialImageryUploadContainer.$el.hide();
    }
  },

  _handleSubmit(e) {
    e.preventDefault();

    const isNewLayer = this.model.isNew();

    const currentValues = this._getCurrentValues();
    let err = this._validateLayerName(currentValues.name);
    const userUploadedNewFile =
      !!this.aerialImageryUploadContainer.currentView.didUserSelectNewFile();

    const shouldValidateNewFile =
      currentValues.type === 'aerialimagery' &&
      (isNewLayer || userUploadedNewFile);

    if (shouldValidateNewFile) {
      let [fileErr] =
        this.aerialImageryUploadContainer.currentView.validateFile();
      err = err || fileErr;
    }

    if (err) {
      return this._triggerError(err, isNewLayer);
    }

    this._saveLayer(currentValues)
      .then((layerModel) => {
        if (userUploadedNewFile) {
          APP.vent.trigger('layerManagement:startFileUpload', {
            layerModel,
            file: this.aerialImageryUploadContainer.currentView.getFileObject(),
          });
        }
      })
      .then(() => this._triggerSuccess(isNewLayer))
      .catch((saveError) => this._triggerError(saveError, isNewLayer));
  },

  _validateLayerName(name) {
    if (!name) {
      return 'Layer name is blank';
    }

    const existingLayer = layerCollection.iFindWhere('name', name);
    if (existingLayer && existingLayer.id !== this.model.id) {
      return `Another layer with the same name already exists: ${name}`;
    }
  },

  _saveLayer(data) {
    if (!this.model.isNew()) {
      return P.resolve(this.model.save(data, { wait: true })).return(
        this.model,
      );
    }

    return new P((resolve, reject) => {
      layerCollection.create(
        data,
        {
          success: resolve,
          error: reject,
        },
        { wait: true },
      );
    });
  },

  _getCurrentValues() {
    const results = {
      name: this.ui.$layerNameInput.val().trim(),
      type: this.ui.$layerType.val() || this.model.get('type'),
      form_tags: null,
    };

    if (results.type !== 'aerialimagery') {
      results.form_tags = this.layerTagsSelect.currentView.getCurrentValue();
    }

    return results;
  },

  _triggerError(e, isNewLayer) {
    if (isNewLayer) {
      this.trigger('error', e);
    } else {
      return APP.vent.trigger('editLayerDetailsView:error', e);
    }
  },

  _triggerSuccess(isNewLayer) {
    if (isNewLayer) {
      this.trigger('success');
    } else {
      return APP.vent.trigger('editLayerDetailsView:success');
    }
  },
});

module.exports = CreateLayerView;
