import { connect } from 'react-redux';
import _ from 'underscore';
import _get from 'lodash.get';

import withProvider from '../../../withProvider';
import RecordPanelCalendarChild from './RecordPanelCalendarChild';

const mapStateToProps = (state, ownProps) => {
  const { calendarName, event, locationId, parameterSlug } = ownProps;

  // From event we need to fetch micro intervals
  const eventsAtLocation = _get(
    state.calendar.samplingEvents,
    `${calendarName}.${parameterSlug}.${locationId}.events`,
    [],
  );
  const eventsInTree = _.filter(
    eventsAtLocation,
    (e) => e.startDate === event.start,
  );
  const combinedAttachments = eventsInTree.map((e) => e.attachments).flat();
  const [eventInTree] = eventsInTree;
  const nextEvent = eventInTree
    ? {
        ...eventInTree,
        attachments: combinedAttachments,
        start: eventInTree.startDate,
        end: eventInTree.dueDate,
      }
    : event;

  return {
    ...ownProps,
    attachments: nextEvent.attachments,
    event: nextEvent,
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(connect(mapStateToProps)(RecordPanelCalendarChild));
