var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (adminOrganizations, selectedOrganization) {
      pug_html = pug_html + "\u003Cp class=\"font-size-16\"\u003EOrganization\u003C\u002Fp\u003E\u003Cspan class=\"font-size-20\" id=\"selected-organization-name\"\u003E" + (pug.escape(null == (pug_interp = selectedOrganization) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (adminOrganizations.length > 1) {
pug_html = pug_html + "\u003Cselect class=\"col-xs-4\" id=\"organization-select\"\u003E";
// iterate adminOrganizations
;(function(){
  var $$obj = adminOrganizations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var val = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = val.get('name')) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var val = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", val.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = val.get('name')) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Ca id=\"show-organization-select\" rel=\"tipsy\" title=\"Change Organization\"\u003E\u003Ci class=\"fa fa-list-alt\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
    }.call(this, "adminOrganizations" in locals_for_with ?
        locals_for_with.adminOrganizations :
        typeof adminOrganizations !== 'undefined' ? adminOrganizations : undefined, "selectedOrganization" in locals_for_with ?
        locals_for_with.selectedOrganization :
        typeof selectedOrganization !== 'undefined' ? selectedOrganization : undefined));
    ;;return pug_html;};
module.exports = template;