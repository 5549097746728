var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (description, dsnNumber, dsn_slug, selected) {
      pug_html = pug_html + "\u003Clabel\u003E";
if (selected) {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\" name=\"dsns\""+pug.attr("value", dsn_slug, true, true)+pug.attr("checked", true, true, true)) + "\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\" name=\"dsns\""+pug.attr("value", dsn_slug, true, true)) + "\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"bold margin-left-5\"\u003E" + (pug.escape(null == (pug_interp = 'DSN' + dsnNumber + ': ') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = description) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
    }.call(this, "description" in locals_for_with ?
        locals_for_with.description :
        typeof description !== 'undefined' ? description : undefined, "dsnNumber" in locals_for_with ?
        locals_for_with.dsnNumber :
        typeof dsnNumber !== 'undefined' ? dsnNumber : undefined, "dsn_slug" in locals_for_with ?
        locals_for_with.dsn_slug :
        typeof dsn_slug !== 'undefined' ? dsn_slug : undefined, "selected" in locals_for_with ?
        locals_for_with.selected :
        typeof selected !== 'undefined' ? selected : undefined));
    ;;return pug_html;};
module.exports = template;