/* eslint-disable */
// Collections
var NewAndEditMapModelBase = require('./newAndEditMapModelBase');

var EditTitlebarModel = NewAndEditMapModelBase.extend({
  setTitlebarParams: function (titlebarForm, noLogoChosen) {
    this.titlebarParams = titlebarForm;

    // Record the current logo image that is being used. If logo upload fails,
    // it can be reset to this value.
    this.resetLogoImage = this.map.titlebar().get('logo_image');

    if (!noLogoChosen) {
      this.titlebarParams.logoImage = '';
    }
  },

  updateTitlebar: function () {
    return new Promise(
      function (resolve, reject) {
        this.map
          .titlebar()
          .save(this.titlebarParams)
          .done(resolve)
          .fail(reject);
      }.bind(this),
    );
  },
});

module.exports = EditTitlebarModel;
