/* eslint-disable */
var currentUser = require('../currentUser');
var httpStore = require('../permissions/httpStore');
var SharedArbiter = require('../../shared/permissions/SharedPermissionsArbiter');

var currentUserArbiter = new SharedArbiter(currentUser.id, httpStore);

var warmupCache = function (userId) {
  return httpStore.get(userId, null);
};

if (currentUser.id) {
  warmupCache(currentUser.id);
} else {
  currentUser.on('change:id', function () {
    currentUserArbiter.setUserId(currentUser.id);
    warmupCache(currentUser.id);
  });
}

module.exports = currentUserArbiter;
