var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (availableDischargeLocations, frequency, getParameterDisplayText, isArizonaIndustrial, isCaliforniaIndustrial, isFloridaIndustrial, isNorthCarolinaIndustrial, isTexasIndustrial, isWashingtonIndustrial, parameter_slug, selectedDischargeLocationIds, showOutfallsAndFrequency, showParameterStatus, status) {
      pug_html = pug_html + "\u003Cdiv class=\"row padding-top-10\"\u003E";
if (showOutfallsAndFrequency) {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-4\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getParameterDisplayText(parameter_slug)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Cselect class=\"selected-discharge-locations\" name=\"discharge_locations\" multiple=\"multiple\"\u003E";
// iterate availableDischargeLocations
;(function(){
  var $$obj = availableDischargeLocations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var dischargeLocation = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", dischargeLocation.id, true, true)+pug.attr("selected", selectedDischargeLocationIds.indexOf(dischargeLocation.id) > -1, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = dischargeLocation.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var dischargeLocation = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", dischargeLocation.id, true, true)+pug.attr("selected", selectedDischargeLocationIds.indexOf(dischargeLocation.id) > -1, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = dischargeLocation.name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-3\"\u003E\u003Cselect class=\"form-control frequency\" type=\"text\" name=\"frequency\"\u003E\u003Coption" + (" value=\"quarter\""+pug.attr("selected", frequency === 'quarter', true, true)) + "\u003EQuarterly\u003C\u002Foption\u003E\u003Coption" + (" value=\"biannual\""+pug.attr("selected", frequency === 'biannual' || frequency === null, true, true)) + "\u003ESemi-Annually\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-6\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getParameterDisplayText(parameter_slug)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-4\"\u003E";
if (showParameterStatus) {
pug_html = pug_html + "\u003Cselect class=\"status form-control\" type=\"text\" name=\"status\" style=\"width: 150px\"\u003E";
if (isFloridaIndustrial || isTexasIndustrial) {
pug_html = pug_html + "\u003Coption" + (" value=\"exempt\""+pug.attr("selected", status === 'exempt', true, true)) + "\u003EExempt\u003C\u002Foption\u003E";
}
if (isWashingtonIndustrial || isArizonaIndustrial || isTexasIndustrial) {
pug_html = pug_html + "\u003Coption" + (" value=\"consistent-attainment\""+pug.attr("selected", status === 'consistent-attainment', true, true)) + "\u003EConsistent Attainment\u003C\u002Foption\u003E";
}
pug_html = pug_html + "\u003Coption" + (" value=\"baseline\""+pug.attr("selected", status === 'baseline', true, true)) + "\u003EBaseline\u003C\u002Foption\u003E";
if (isCaliforniaIndustrial || isWashingtonIndustrial) {
pug_html = pug_html + "\u003Coption" + (" value=\"level1\""+pug.attr("selected", status === 'level1', true, true)) + "\u003ELevel 1\u003C\u002Foption\u003E\u003Coption" + (" value=\"level2\""+pug.attr("selected", status === 'level2', true, true)) + "\u003ELevel 2\u003C\u002Foption\u003E";
if (isWashingtonIndustrial) {
pug_html = pug_html + "\u003Coption" + (" value=\"level3\""+pug.attr("selected", status === 'level3', true, true)) + "\u003ELevel 3\u003C\u002Foption\u003E";
}
}
else
if (isNorthCarolinaIndustrial) {
pug_html = pug_html + "\u003Coption" + (" value=\"tier1\""+pug.attr("selected", status === 'tier1', true, true)) + "\u003ETier 1\u003C\u002Foption\u003E\u003Coption" + (" value=\"tier2\""+pug.attr("selected", status === 'tier2', true, true)) + "\u003ETier 2\u003C\u002Foption\u003E\u003Coption" + (" value=\"tier3\""+pug.attr("selected", status === 'tier3', true, true)) + "\u003ETier 3\u003C\u002Foption\u003E";
}
else {
pug_html = pug_html + "\u003Coption" + (" value=\"benchmark-exceedance\""+pug.attr("selected", status === 'benchmark-exceedance', true, true)) + "\u003EBenchmark Exceedance\u003C\u002Foption\u003E";
}
pug_html = pug_html + "\u003C\u002Fselect\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"col-xs-2\"\u003E\u003Cbutton class=\"btn btn-danger btn-w-icon delete-parameter-status\" rel=\"tipsy\" data-toggle=\"tooltip\" title=\"Remove Parameter\"\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "availableDischargeLocations" in locals_for_with ?
        locals_for_with.availableDischargeLocations :
        typeof availableDischargeLocations !== 'undefined' ? availableDischargeLocations : undefined, "frequency" in locals_for_with ?
        locals_for_with.frequency :
        typeof frequency !== 'undefined' ? frequency : undefined, "getParameterDisplayText" in locals_for_with ?
        locals_for_with.getParameterDisplayText :
        typeof getParameterDisplayText !== 'undefined' ? getParameterDisplayText : undefined, "isArizonaIndustrial" in locals_for_with ?
        locals_for_with.isArizonaIndustrial :
        typeof isArizonaIndustrial !== 'undefined' ? isArizonaIndustrial : undefined, "isCaliforniaIndustrial" in locals_for_with ?
        locals_for_with.isCaliforniaIndustrial :
        typeof isCaliforniaIndustrial !== 'undefined' ? isCaliforniaIndustrial : undefined, "isFloridaIndustrial" in locals_for_with ?
        locals_for_with.isFloridaIndustrial :
        typeof isFloridaIndustrial !== 'undefined' ? isFloridaIndustrial : undefined, "isNorthCarolinaIndustrial" in locals_for_with ?
        locals_for_with.isNorthCarolinaIndustrial :
        typeof isNorthCarolinaIndustrial !== 'undefined' ? isNorthCarolinaIndustrial : undefined, "isTexasIndustrial" in locals_for_with ?
        locals_for_with.isTexasIndustrial :
        typeof isTexasIndustrial !== 'undefined' ? isTexasIndustrial : undefined, "isWashingtonIndustrial" in locals_for_with ?
        locals_for_with.isWashingtonIndustrial :
        typeof isWashingtonIndustrial !== 'undefined' ? isWashingtonIndustrial : undefined, "parameter_slug" in locals_for_with ?
        locals_for_with.parameter_slug :
        typeof parameter_slug !== 'undefined' ? parameter_slug : undefined, "selectedDischargeLocationIds" in locals_for_with ?
        locals_for_with.selectedDischargeLocationIds :
        typeof selectedDischargeLocationIds !== 'undefined' ? selectedDischargeLocationIds : undefined, "showOutfallsAndFrequency" in locals_for_with ?
        locals_for_with.showOutfallsAndFrequency :
        typeof showOutfallsAndFrequency !== 'undefined' ? showOutfallsAndFrequency : undefined, "showParameterStatus" in locals_for_with ?
        locals_for_with.showParameterStatus :
        typeof showParameterStatus !== 'undefined' ? showParameterStatus : undefined, "status" in locals_for_with ?
        locals_for_with.status :
        typeof status !== 'undefined' ? status : undefined));
    ;;return pug_html;};
module.exports = template;