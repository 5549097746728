/* eslint-disable */
var WashingtonRegionView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/washingtonRegion.pug'),

  ui: {
    $pugetDischargeYesRadio: '#puget-discharge-yes-radio',
    $pugetDischargeNoRadio: '#puget-discharge-no-radio',
    $pugetDischargeInput: 'input[name="puget_discharge"]',
    $rcraYesRadio: '#rcra-facility-yes-radio',
    $rcraNoRadio: '#rcra-facility-no-radio',
    $rcraFacilityInput: 'input[name="rcra_facility"]',
    $westernRadio: '#western-radio',
    $easternRadio: '#eastern-radio',
    $westOrEastWAInput: 'input[name="west_or_east_washington"]',
  },

  events: {
    'change @ui.$pugetDischargeInput': '_handleChangePugetDischarge',
    'change @ui.$rcraFacilityInput': '_handleChangeRcraFacility',
    'change @ui.$westOrEastWAInput': '_handleChangeWestOrEast',
  },

  modelEvents: {
    'change:is_western_wa change:is_rcra_facility change:site_discharges_into_puget_sound':
      '_handleModelChange',
  },

  onRender() {
    if (this.model.get('is_rcra_facility')) {
      this.ui.$rcraYesRadio.prop('checked', true);
    } else if (this.model.get('is_rcra_facility') === false) {
      this.ui.$rcraNoRadio.prop('checked', true);
    }
    if (this.model.get('is_western_wa')) {
      this.ui.$westernRadio.prop('checked', true);
    } else if (this.model.get('is_western_wa') === false) {
      this.ui.$easternRadio.prop('checked', true);
    }
    if (this.model.get('site_discharges_into_puget_sound')) {
      this.ui.$pugetDischargeYesRadio.prop('checked', true);
    } else if (this.model.get('site_discharges_into_puget_sound') === false) {
      this.ui.$pugetDischargeNoRadio.prop('checked', true);
    }

    this.$('#east-or-west-wa-popover').popover({
      html: true,
      content: function () {
        return $('#east-or-west-wa-popover-content-wrapper').html();
      },
    });
  },

  _getIsWesternWASelected() {
    let isWesternWA = this.$(
      'input[name="west_or_east_washington"]:checked',
    ).val();
    return isWesternWA ? isWesternWA === 'true' : null;
  },

  _handleChangeWestOrEast() {
    // wait for server to save so that when sampling parameters update,
    // field has been saved on server side
    this.model.save(
      {
        is_western_wa: this._getIsWesternWASelected(),
      },
      { wait: true },
    );
  },

  _getIsRcraFacilitySelected() {
    let isRcraFacility = this.$('input[name="rcra_facility"]:checked').val();
    return isRcraFacility ? isRcraFacility === 'true' : null;
  },

  _handleChangeRcraFacility() {
    // wait for server to save so that when sampling parameters update,
    // field has been saved on server side
    this.model.save(
      {
        is_rcra_facility: this._getIsRcraFacilitySelected(),
      },
      { wait: true },
    );
  },

  _getDischargesIntoPugetSelected() {
    let dischargesIntoPuget = this.$(
      'input[name="puget_discharge"]:checked',
    ).val();
    return dischargesIntoPuget ? dischargesIntoPuget === 'true' : null;
  },

  _handleChangePugetDischarge() {
    // wait for server to save so that when sampling parameters update,
    // field has been saved on server side
    this.model.save(
      {
        site_discharges_into_puget_sound:
          this._getDischargesIntoPugetSelected(),
      },
      { wait: true },
    );
  },

  _handleModelChange() {
    let APP = require('../../../../../../config');
    APP.vent.trigger('washingtonRegion:change');
  },
});
module.exports = WashingtonRegionView;
