import { BaseService } from './BaseService';

class FormTemplateService extends BaseService {
  getBySlugForSubmission(slug, projectId, forStencil) {
    const url = `${this.apiUrl}/api/v2/form-templates/${slug}/${projectId}?forStencil=${forStencil}`;

    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      return null;
    });
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormTemplateService;
