import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { RecordValue } from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';

export function userBelongsToProject(
  userId: RecordValue,
  projectId: RecordValue,
  userIdsByProjectId: {
    [projectId: string]: string[];
  },
): boolean {
  if (!userId || !projectId || typeof projectId !== 'string') {
    return true;
  }

  const assignableUsers = userIdsByProjectId[projectId];
  if (!assignableUsers?.includes(userId.toString())) {
    return false;
  }
  return true;
}

export function validateThatUserBelongsToProject(
  userFieldId: string,
  record: FlatfileRecord,
  projectId: RecordValue,
  userIdsByProjectId: {
    [projectId: string]: string[];
  },
): void {
  record.validate(
    userFieldId,
    (value) => userBelongsToProject(value, projectId, userIdsByProjectId),
    "User doesn't belong to this site, or doesn't have permission to be assigned to a task.",
  );
}
