/* eslint-disable */
var _s = require('underscore.string');
let projectStateConfig = require('mapistry-project-state-config');

const OFF = '__off__';

var UserNotificationRow = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  template: require('./templates/userNotificationRow.pug'),

  templateHelpers() {
    let slug = this.model.get('slug');
    let radioButtons = [];

    if (Array.isArray(slug)) {
      const text = this.model.get('text');
      const ids = this.model.get('notificationId');
      const selected = ids.find((id) => id !== null);

      radioButtons = [
        {
          group: slug[0],
          slug: `${OFF}${slug[0]}`,
          text: 'Off',
          id: !selected,
        },
      ].concat(
        slug.map((s, i) => ({
          group: slug[0],
          slug: s,
          text: text[i],
          id: ids[i],
        })),
      );
    }

    return {
      radioButtons,
    };
  },

  ui: {
    $switch: '.checkbox input',
    $radio: '.radio input',
  },

  events: {
    'switchChange.bootstrapSwitch': '_handleToggleNotificationSubscription',
    'change input[type=radio]': '_handleChangeRadioSelection',
  },

  onRender() {
    if (Array.isArray(this.model.get('slug'))) {
      this.ui.$switch.hide();
    } else {
      this.ui.$switch.bootstrapSwitch({
        state: !!this.model.get('notificationId'),
      });
      this.ui.$radio.hide();
    }
  },

  _handleToggleNotificationSubscription() {
    let userId =
      this.model.collection.userId || require('../../../../currentUser').id;
    if (!this.model.get('notificationId')) {
      return this._enableUserNotification(userId);
    }
    this._disableUserNotification(userId);
  },

  _disableUserNotification(userId) {
    require('../../../../apiCaller').disableUserNotification(
      userId,
      this.model.get('notificationId'),
    );
    this.model.set('notificationId', null);
  },

  _enableUserNotification(userId) {
    return require('../../../../apiCaller')
      .enableUserNotification(userId, this.model.get('slug'))
      .then((model) => this.model.set('notificationId', model.id));
  },

  _handleChangeRadioSelection(event) {
    const apiCaller = require('../../../../apiCaller');
    const userId =
      this.model.collection.userId || require('../../../../currentUser').id;
    const notificationSlug = event.target.value;

    const other = this.model.get('notificationId').find((id) => id !== null);
    if (other) {
      apiCaller.disableUserNotification(userId, other);
    }

    if (notificationSlug.indexOf(OFF) === 0) {
      const newIds = this.model.get('slug').map((_) => null);
      this.model.set('notificationId', newIds);
    } else {
      return apiCaller
        .enableUserNotification(userId, notificationSlug)
        .then((updated) => {
          const newIds = this.model
            .get('slug')
            .map((slug) => (slug === notificationSlug ? updated.id : null));
          this.model.set('notificationId', newIds);
        });
    }
  },
});

module.exports = UserNotificationRow;
