import BaseValidator from './BaseValidator';

class SignatureValidator extends BaseValidator {
  constructor(templateField, submissionField, currentUser) {
    super(templateField, submissionField);
    this.currentUser = currentUser;
  }

  validate() {
    const value = this.getParsedValue();
    const currentUserSigner = value.find(
      (signer) => signer.user_id === this.currentUser.id,
    );
    if (
      this.templateField.required &&
      (!value.length || (currentUserSigner && !currentUserSigner.signed))
    ) {
      return ['Your signature is required'];
    }
    return [];
  }

  getParsedValue() {
    const value = this.getValue();
    return value != null && value.length ? JSON.parse(value) : [];
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SignatureValidator;
