import React from 'react';
import { BulkUploadForOrganizationPage } from '../BulkUploadForOrganizationPage';
import { TaskUploadModal } from './TasksUploadModal';

export function TaskBulkUpload() {
  const header = (
    <>
      <h1>Bulk Task Upload</h1>
      <p>
        You can upload new tasks. Users assigned to these tasks will receive
        email notifications about them if they have activated their Mapistry
        accounts.
      </p>
      <p>
        &quot;Is Recurring?&quot; column can have any boolean-like values
        (true/false , 0/1 , yes/no , y/n , on/off, enabled/disabled).
      </p>
    </>
  );
  return (
    <BulkUploadForOrganizationPage
      header={header}
      ModalComponent={TaskUploadModal}
    />
  );
}
