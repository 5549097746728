var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (downloadPdfUrl, isForm, name, permissions) {
      if (!isForm) {
pug_html = pug_html + "\u003Cdiv class=\"flex justify-content-space-between align-items-center\"\u003E\u003Cdiv class=\"flex cursor-pointer download-document\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["flex","icon-button",permissions.hasDeletePermission ? 'delete-document' : 'disabled'], [false,false,true]), false, true)+" rel=\"tipsy\""+pug.attr("title", permissions.hasDeletePermission ? 'Delete exceedance-report' : 'Only admins can delete documents uploaded by others.', true, true)) + "\u003E\u003Cspan class=\"visually-hidden\"\u003E" + (pug.escape(null == (pug_interp = permissions.hasDeletePermission ? 'Delete exceedance-report' : 'Only admins can delete documents uploaded by others.') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cli class=\"flex\"\u003E\u003Ca class=\"go-to-exceedance-report doc-name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (downloadPdfUrl) {
pug_html = pug_html + "\u003Ca" + (" class=\"padding-left-10 margin-left-auto\""+pug.attr("href", downloadPdfUrl, true, true)+" target=\"_blank\" rel=\"tipsy\" title=\"Download PDF\"") + "\u003E\u003Csvg class=\"download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fli\u003E";
}
    }.call(this, "downloadPdfUrl" in locals_for_with ?
        locals_for_with.downloadPdfUrl :
        typeof downloadPdfUrl !== 'undefined' ? downloadPdfUrl : undefined, "isForm" in locals_for_with ?
        locals_for_with.isForm :
        typeof isForm !== 'undefined' ? isForm : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "permissions" in locals_for_with ?
        locals_for_with.permissions :
        typeof permissions !== 'undefined' ? permissions : undefined));
    ;;return pug_html;};
module.exports = template;