/* eslint-disable */
var configureTipsy = require('../../lib/tipsyConfig');

/**
 * Leaflet control for creating scalebar.
 * https://github.com/shramov/leaflet-plugins/blob/master/control/Scale.js
 */

L.Control.Scale = L.Control.extend({
  options: {
    position: 'bottomright',
    useCanvas: null,
    width: 100,
    unitsMetric: false,
    disableToggle: false,
  },

  initialize: function (options) {
    L.Util.setOptions(this, options);
  },

  onAdd: function (map) {
    this._map = map;

    if (!this.options.useCanvas && this.options.useCanvas !== false) {
      this.options.useCanvas = 'HTMLCanvasElement' in window;
    }

    var template = require('./scalebar.pug');
    this._$container = $(
      template({
        useCanvas: this.options.useCanvas,
        disableToggle: this.options.disableToggle,
      }),
    );
    configureTipsy(this._$container.find('[rel=tipsy]'), { gravity: 'w' });
    this._label = this._$container.find('#scalebar-label').get(0);
    this._canvas = this._$container.find('.scalebar-canvas').get(0);
    this._$noScalebarClass = this._$container.find('.no-scalebar');
    this._$showScalbarClass = this._$container.find('.show-scalebar');
    this._$showUnitClass = this._$container.find('.show-unit');
    this._$northArrow = this._$container.find('#north-arrow');
    this._$toggleScale = this._$container.find('#toggle-scale-units-href');
    this.update();

    this._$toggleScale.on(
      'click',
      function () {
        this._$container.find('[rel=tipsy]').tipsy('hide');
        map.fire('click:toggleScale');
      }.bind(this),
    );
    map.on('zoomend', this.update, this);

    return this._$container.get(0);
  },

  onRemove: function (map) {
    map.off('zoomend', this.update, this);
  },

  getPosition: function () {
    return this.options.position;
  },

  getContainer: function () {
    return this._container.get(0);
  },

  update: function () {
    if (!this._map) {
      return;
    }

    var size = this.options.width,
      iw;

    var b = this._map.getBounds(),
      pb = this._map.getPixelBounds(),
      northEast = b.getNorthEast(),
      northWest = b.getNorthWest(),
      southEast = b.getSouthEast(),
      southWest = b.getSouthWest(),
      center = b.getCenter(),
      width = this._degLength(
        L.latLng(center.lat, northWest.lng),
        L.latLng(center.lat, northEast.lng),
      );

    // This is a ad hoc way to determine whether or not to show the scalebar.
    // The earth is round and a map is flat, so if you are too zoomed out, the
    // scale at the top of the visible map, and the scale are going to be
    // different. This is more accure at the poles. In an effort to not mislead
    // the user, when the scale because particularly inaccurate, we do not
    // display a scale.
    if (
      width > 11000 ||
      (width > 1000 &&
        (northEast.lat > 60 ||
          northWest.lat > 60 ||
          southEast.lat < -60 ||
          southWest.lat < -60))
    ) {
      this._$noScalebarClass.show();
      this._$showScalbarClass.hide();
      this._$showUnitClass.hide();
      this._$northArrow.removeClass('with-scalebar-north-arrow');
      this._$northArrow.addClass('no-scalebar-north-arrow');
      this._$container.addClass('no-scalebar-control-height');
    } else {
      width = (size * width) / (pb.max.x - pb.min.x);

      // Add units
      if (!this.options.unitsMetric) {
        // Convert to miles
        var miles = width * 0.621371,
          yards = width * 3280.84;

        if (miles >= 1) {
          iw = this._round(miles);
          width = miles;
          this._label.innerHTML = iw + ' mi';
        } else {
          iw = this._round(yards);
          width = yards;
          this._label.innerHTML = iw + ' ft';
        }
      } else {
        iw = this._round(width);

        if (iw >= 1) {
          this._label.innerHTML = iw + ' km';
        } else {
          this._label.innerHTML = Math.round(1000 * iw) + ' m';
        }
      }

      size = (size * iw) / width;

      if (this.options.useCanvas) {
        this._canvas.width = size + 1;
        this._canvas.height = 10 + 1;

        var ctx = this._canvas.getContext('2d');
        this._draw(ctx, size, 5);
      } else {
        this._canvas.style.width = size;
        this._canvas.style.height = 5;
      }

      this._$noScalebarClass.hide();
      this._$showScalbarClass.css('display', 'block');
      this._$showUnitClass.css('display', 'inline-block');
      this._$northArrow.addClass('with-scalebar-north-arrow');
      this._$northArrow.removeClass('no-scalebar-north-arrow');
      this._$container.removeClass('no-scalebar-control-height');
    }
  },

  _draw: function (ctx, width, height) {
    ctx.beginPath();
    ctx.fillStyle = ctx.strokeStyle = '#000';
    ctx.lineWidth = 0.5;

    ctx.strokeRect(0, height, width / 2, height);
    ctx.fillRect(0, height, width / 2, height);
    ctx.strokeRect(width / 2, height, width / 2, height);

    ctx.moveTo(0, 0);
    ctx.lineTo(0, height);
    ctx.moveTo(width / 2, 0);
    ctx.lineTo(width / 2, height);
    ctx.moveTo(width, 0);
    ctx.lineTo(width, height);
    ctx.stroke();
  },

  _degLength: function (p1, p2) {
    // Get distance between points and convert m to km
    return p1.distanceTo(p2) / 1000;
  },

  // A custom rounding function. It rounds to nice numbers like 300 instead of
  // 287.
  _round: function (x) {
    var div = 1;
    while (div < x) {
      div *= 10;
    }
    while (div > x) {
      div /= 10;
    }
    var s = div;
    while (s < x) {
      s += div;
    }
    if (s > 5 * div) {
      s = 10 * div;
    }
    return s;
  },
});

module.exports = L.Control.Scale;
