/* eslint-disable */
var APP = require('../config');

var TeslaFremontLevel2ButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/teslaFremontLevel2ButtonView.pug'),

  initialize() {
    this.listenTo(
      APP.vent,
      'deactivate:tesla-fremont-level2:button',
      this._deactivateTeslaLevel2.bind(this),
    );
  },

  templateHelpers: function () {
    return {
      isTeslaFremontLevel2On: this.model.isTeslaFremontLevel2On(),
    };
  },

  ui: {
    $buttonIcon: '#tesla-fremont-level2',
  },

  events: {
    click: '_toggleTeslaLevel2',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _toggleTeslaLevel2: function () {
    this.model
      .toggleTeslaLevel2()
      .bind(this)
      .then(function () {
        APP.vent.trigger('deactivate:tesla-fremont-level1:button');
      })
      .then(function () {
        this.trigger('toggle');
        this.ui.$buttonIcon.toggleClass('active');
      });
  },

  _deactivateTeslaLevel2: function () {
    this.ui.$buttonIcon.removeClass('active');
  },
});

module.exports = TeslaFremontLevel2ButtonView;
