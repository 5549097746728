/* eslint-disable */
// Services
var bigSpinner = require('../services/bigSpinner');
var apiCaller = require('../apiCaller');

// Views
var errorView = require('../modals/errorView').singleton();
var infoView = require('../modals/infoView').singleton();

var SearchAddressView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/searchbar.pug'),

  ui: {
    $searchInput: '#search-input',
    $searchIcon: '.glyphicon-search',
    $removeIcon: '.glyphicon-remove',
  },

  events: {
    'focus @ui.$searchInput': '_expandSearchBox',
    'blur @ui.$searchInput': '_shrinkSearchBox',
    'keydown @ui.$searchInput': '_handleSearch',
    'click @ui.$searchIcon': '_handleSearch',
    'click @ui.$removeIcon': '_clearSearchBox',
  },

  _expandSearchBox: function () {
    this.ui.$searchInput.addClass('active');
  },

  _shrinkSearchBox: function () {
    this.ui.$searchInput.removeClass('active');
  },

  _clearSearchBox: function () {
    this.ui.$searchInput.val('').focus();
    this.ui.$removeIcon.hide();
    this.trigger('clear');
  },

  _handleSearch: function (e) {
    var searchVal = this.ui.$searchInput.val();

    if (!this.ui.$removeIcon.is(':visible')) {
      this.ui.$removeIcon.show();
    }

    // If backspace or delete pressed and last character in search box, hide remove icon
    if (searchVal.length <= 1 && (e.which === 8 || e.which === 46)) {
      this.ui.$removeIcon.hide();
    }

    // Only search when enter pressed or search icon clicked
    if (searchVal.length && (e.which === 13 || e.which === 1)) {
      this._goToAddress(searchVal);
    }
  },

  _goToAddress: function (searchVal) {
    return bigSpinner
      .show()
      .then(() => {
        const coordMatches = searchVal.match(
          /^[ ]*(-?\d+\.?\d*)[, ]+(-?\d+\.?\d*)[ ]*$/i,
        );
        if (coordMatches) {
          const coord1 = Number(coordMatches[1]);
          const coord2 = Number(coordMatches[2]);

          if (coord1 < -90 || coord1 > 90) {
            return {
              lat: coord2,
              lng: coord1,
            };
          }
          return {
            lat: coord1,
            lng: coord2,
          };
        }
        return apiCaller.geolocate(searchVal);
      })
      .then(
        function (result) {
          this.trigger('search', result.lat, result.lng);
        }.bind(this),
      )
      .catch(function (error) {
        if (typeof error === 'string') {
          errorView.showModal({ error: error });
          return;
        }

        if (error.status === 500) {
          errorView.showModal({
            error:
              'We are sorry, but there was an error on the ' +
              'server and we could not move the map to ' +
              searchVal,
          });
          return;
        }

        infoView.showModal({
          text:
            'We couldn\'t find "' +
            searchVal +
            '" on the map.  Please try another search.',
        });
      })
      .finally(bigSpinner.hide);
  },
});

module.exports = SearchAddressView;
