/* eslint-disable */
const apiCaller = require('../apiCaller');
const rootLayoutView = require('../rootLayoutView');
const currentUser = require('../currentUser');
const ErrorPageView = require('./ErrorPageView');
const NavbarView = require('../dashboards/navbar/NavbarView');

let ErrorController = Backbone.Marionette.Controller.extend({
  handleError(queryParams) {
    const router = require('../router');
    const code = queryParams && queryParams.code;
    const message = queryParams && decodeURIComponent(queryParams.message);
    const errorPageView = new ErrorPageView({ code: code, message: message });

    return apiCaller.getPageSetupVars().then(() => {
      if (currentUser.isLoggedIn()) {
        rootLayoutView.navbar.show(new NavbarView());
      }
      rootLayoutView.content.show(errorPageView);
    });
  },
});

module.exports = ErrorController;
