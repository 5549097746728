/* eslint-disable */
module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./signatureView.pug'),

  events: {
    'click #clear-signature': '_clearSignaturePad',
  },

  ui: {
    $canvas: 'canvas',
    $panel: '.panel',
  },

  onRender() {
    this.signaturePad = new SignaturePad(this.ui.$canvas[0]);

    if (this.model.has('signature')) {
      this.signaturePad.fromDataURL(this.model.get('signature'));
    }
  },

  _clearSignaturePad() {
    this.signaturePad.clear();
  },
});
