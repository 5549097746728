import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntervalFrequency } from 'mapistry-shared';

import APP from '../../../config';
import { fetchProjectAction } from '../../../actions/project';
import {
  downloadWastewaterFlowLog,
  fetchFlowLogLimits,
  fetchFlowLogStatsAction,
  fetchWastewaterMonitoringLocationsAction,
} from '../../../actions/wastewater';
import withProvider from '../../withProvider';

import FlowLogRecordPanel from './FlowLogRecordPanel';

class FlowLogRecordPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalIsOpen: false,
      flowReadingIdToEdit: null,
      settingsModalIsOpen: false,
      flowLogModalIsOpen: false,
    };
  }

  componentDidMount() {
    const {
      fetchFlowLimits,
      fetchFlowLogStats,
      getProject,
      getMonitoringLocations,
    } = this.props;
    getProject();
    fetchFlowLimits();
    fetchFlowLogStats({ frequency: IntervalFrequency.YEAR });
    getMonitoringLocations();
  }

  render() {
    const {
      editModalIsOpen,
      flowLogModalIsOpen,
      flowReadingIdToEdit,
      settingsModalIsOpen,
    } = this.state;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { downloadFlowLog, monitoringLocations, projectId } = this.props;

    return (
      <FlowLogRecordPanel
        editModalIsOpen={editModalIsOpen}
        flowLogUploadModalIsOpen={flowLogModalIsOpen}
        flowReadingIdToEdit={flowReadingIdToEdit}
        monitoringLocations={monitoringLocations}
        onDownloadFlowLog={() => downloadFlowLog()}
        onCloseEditModal={() =>
          this.setState({
            editModalIsOpen: false,
            flowReadingIdToEdit: null,
          })
        }
        onCloseSettingsModal={() =>
          this.setState({ settingsModalIsOpen: false })
        }
        onCloseFlowLogUploadModal={() =>
          this.setState({ flowLogModalIsOpen: false })
        }
        onOpenEditModal={(readingIdToEdit = null) =>
          this.setState({
            editModalIsOpen: true,
            flowReadingIdToEdit: readingIdToEdit,
          })
        }
        onOpenFlowLogUploadModal={() =>
          this.setState({ flowLogModalIsOpen: true })
        }
        onOpenSettingsModal={() => this.setState({ settingsModalIsOpen: true })}
        projectId={projectId}
        settingsModalIsOpen={settingsModalIsOpen}
      />
    );
  }
}

FlowLogRecordPanelContainer.defaultProps = {
  monitoringLocations: [],
};

FlowLogRecordPanelContainer.propTypes = {
  downloadFlowLog: PropTypes.func.isRequired,
  fetchFlowLimits: PropTypes.func.isRequired,
  fetchFlowLogStats: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  getMonitoringLocations: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  monitoringLocations: state.wastewater.monitoringLocations,
});

const mapDispatchToProps = (dispatch) => ({
  projectId: APP.projectId,
  downloadFlowLog: () => downloadWastewaterFlowLog(APP.projectId),
  fetchFlowLimits: () => dispatch(fetchFlowLogLimits(APP.projectId)),
  fetchFlowLogStats: (query) =>
    dispatch(fetchFlowLogStatsAction(APP.projectId, query)),
  getProject: () => dispatch(fetchProjectAction(APP.projectId)),
  getMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(FlowLogRecordPanelContainer),
);
