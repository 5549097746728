import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import { localEquivalentOfUTC, UTCEquivalentOfLocal } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import _ from 'underscore';
import APP from '../../../../config';
import {
  Button,
  ConfirmDialog,
  DatePicker,
  ErrorMessage,
  FieldWrapper,
  Modal,
  NoLocationsDefined,
  SaveButton,
  Select,
  TimePicker,
} from '../../../elements';

const PhCalibrationModal = ({
  errors,
  formSaveState,
  formSubmissionDraft,
  goToMapsURL,
  monitoringLocations,
  onCancelDirtyCloseConfirmation,
  onClose,
  onCloseErrorMessage,
  onConfirmDirtyCloseConfirmation,
  open,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  originalCalibratingUserId,
  saveError,
  shouldShowDirtyCloseConfirmation,
  submitForm,
  stageEdit,
}) => {
  const { userOptions: USER_OPTIONS } = useProjectUserOptions({
    projectId: APP.projectId,
    previouslySavedUserId: originalCalibratingUserId,
  });

  const MONITORING_LOCATION_OPTIONS = useMemo(
    () =>
      monitoringLocations
        .filter((ml) => !ml.sampleType && !ml.parentId) // QA locations are only for sampling results
        .map((ml) => ({
          label: ml.name,
          value: ml.id,
        })),
    [monitoringLocations],
  );

  const CALIBRATION_METHOD_OPTIONS = [
    { value: '2_point', label: '2 point' },
    { value: '3_point', label: '3 point' },
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-unstable-nested-components
  const Errors = () => (
    <div className="section-errors">
      <div className="section-errors__headline">
        Oops! There are errors in your form:
      </div>
      <ul className="error-list">
        {_.map(errors.displayable, (e) => (
          <li key={e} className="error-list-item">
            {e}
          </li>
        ))}
      </ul>
    </div>
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-unstable-nested-components
  const DirtyDraftExitConfirmation = () => (
    <ConfirmDialog
      confirmButtonText="Close without saving"
      danger
      description="Are you sure you want to close without saving?"
      open={shouldShowDirtyCloseConfirmation}
      onCancelled={onCancelDirtyCloseConfirmation}
      onConfirmed={onConfirmDirtyCloseConfirmation}
      title="Confirm"
    />
  );

  return (
    <Modal
      footer={
        <div className="modal-footer__actions">
          <Button
            color="secondary"
            disabled={formSaveState === SaveState.SAVING}
            onClick={onClose}
          >
            {formSaveState === SaveState.DIRTY ? 'Cancel' : 'Close'}
          </Button>
          <span className="ph2" />
          <SaveButton onSave={submitForm} saveState={formSaveState} />
        </div>
      }
      header={<h4 className="modal-title">pH Calibrations</h4>}
      fullWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className="ph-calibration-modal">
        {_.isEmpty(monitoringLocations) && (
          <NoLocationsDefined
            goToMapsURL={goToMapsURL}
            message="You must define all water monitoring locations before recording pH calibrations."
          />
        )}
        <DirtyDraftExitConfirmation />
        {saveError && (
          <ErrorMessage
            errorMessage={saveError}
            onClose={onCloseErrorMessage}
          />
        )}
        {!_.isEmpty(monitoringLocations) && (
          <>
            {_get(errors, 'displayable', []).length > 0 && <Errors />}
            <div className="section-ph-calibration-details">
              <div className="field-wrapper">
                <FieldWrapper label="Calibration date" isRequired>
                  <DatePicker
                    autoOk
                    className="date-field"
                    clearable
                    error={!_.isUndefined(errors.calibrationDate)}
                    maxDate={new Date()}
                    onChange={(date) =>
                      stageEdit(
                        'calibrationDate',
                        UTCEquivalentOfLocal(new Date(date.setHours(0, 0, 0))),
                      )
                    }
                    value={localEquivalentOfUTC(
                      formSubmissionDraft.calibrationDate,
                    )}
                  />
                </FieldWrapper>
              </div>
              <div className="field-wrapper">
                <FieldWrapper label="Calibration time" isRequired>
                  <TimePicker
                    autoOk
                    className="time-field"
                    clearable
                    error={!_.isUndefined(errors.calibrationTime)}
                    onChange={(time) => stageEdit('calibrationTime', time)}
                    value={formSubmissionDraft.calibrationTime}
                  />
                </FieldWrapper>
              </div>
              <div className="field-wrapper">
                <FieldWrapper label="User performing pH calibration" isRequired>
                  <Select
                    error={!_.isUndefined(errors.userId)}
                    isClearable={false}
                    options={USER_OPTIONS}
                    onChange={(opt) => stageEdit('userId', opt.value)}
                    value={_.find(
                      USER_OPTIONS,
                      (o) => o.value === formSubmissionDraft.userId,
                    )}
                  />
                </FieldWrapper>
              </div>
            </div>
            <div className="section-ph-log-location">
              <div className="row-header">
                <div>Monitoring Location</div>
                <div>Calibration method</div>
              </div>
              <div className="row-details">
                <div className="field-wrapper">
                  <FieldWrapper label="Monitoring Location" isRequired>
                    <Select
                      error={!_.isUndefined(errors.locationId)}
                      isClearable={false}
                      isFixed
                      options={MONITORING_LOCATION_OPTIONS}
                      onChange={(opt) => stageEdit('locationId', opt.value)}
                      value={_.find(
                        MONITORING_LOCATION_OPTIONS,
                        (o) => o.value === formSubmissionDraft.locationId,
                      )}
                    />
                  </FieldWrapper>
                </div>
                <div className="field-wrapper">
                  <FieldWrapper label="Calibration Method" isRequired>
                    <Select
                      error={!_.isUndefined(errors.calibrationMethod)}
                      isClearable={false}
                      options={CALIBRATION_METHOD_OPTIONS}
                      onChange={(opt) =>
                        stageEdit('calibrationMethod', opt.value)
                      }
                      value={_.find(
                        CALIBRATION_METHOD_OPTIONS,
                        (o) =>
                          o.value === formSubmissionDraft.calibrationMethod,
                      )}
                    />
                  </FieldWrapper>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

PhCalibrationModal.propTypes = {
  errors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.shape({})),
    calibrationDate: PropTypes.string,
    calibrationTime: PropTypes.string,
    calibrationMethod: PropTypes.string,
    locationId: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  formSaveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  formSubmissionDraft: PropTypes.shape({
    calibrationDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    calibrationTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    calibrationMethod: PropTypes.string,
    locationId: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  goToMapsURL: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCancelDirtyCloseConfirmation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
  onConfirmDirtyCloseConfirmation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  saveError: PropTypes.string,
  shouldShowDirtyCloseConfirmation: PropTypes.bool.isRequired,
  stageEdit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

PhCalibrationModal.defaultProps = {
  saveError: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default PhCalibrationModal;
