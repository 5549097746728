/* eslint-disable */
var apiCaller = require('../../../../../../../apiCaller');

var SamplingEventParameterCollection = Backbone.Collection.extend({
  model: Backbone.Model.extend({ idAttribute: 'slug' }),

  comparator: 'slug',

  /**
   * IMPORTANT: Endpoint is currently not RESTful. Do not use create or fetch. Only destroy works as expected.
   */
  url() {
    return apiCaller.getSamplingEventParameterUrl(
      this.projectId,
      this.samplingEventId,
    );
  },

  initialize(models, options = {}) {
    this.projectId = options.projectId;
    this.samplingEventId = options.samplingEventId;
  },
});

module.exports = SamplingEventParameterCollection;
