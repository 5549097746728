/* eslint-disable */
var APP = require('../config');

var StreetOverlayButtonView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/streetOverlayButton.pug'),

  initialize() {
    this.listenTo(
      APP.vent,
      'deactive:street-map:button',
      this._deactiveTopographyMapType.bind(this),
    );
  },

  templateHelpers: function () {
    return {
      areLabelsOn: this.model.areLabelsOn(),
    };
  },

  ui: {
    $buttonIcon: '#street-overlay',
  },

  events: {
    click: '_toggleBaseMapType',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _toggleBaseMapType: function () {
    this.model
      .toggleBaseMapType()
      .bind(this)
      .then(function () {
        APP.vent.trigger('deactive:topography-map:button');
      })
      .then(function () {
        this.trigger('toggle');
        this.ui.$buttonIcon.toggleClass('active');
      });
  },

  _deactiveTopographyMapType: function () {
    this.ui.$buttonIcon.removeClass('active');
  },
});

module.exports = StreetOverlayButtonView;
