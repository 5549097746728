var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (availableSampleParameters) {
      pug_html = pug_html + "\u003Cdiv class=\"panel panel-default\"\u003E\u003Cdiv class=\"panel-heading\"\u003EAdd \u002F Download Parameters\u003C\u002Fdiv\u003E\u003C!--.row(style=\"padding-top:5px;\")--\u003E\u003C!--  .col-xs-10.col-xs-offset-1.text-center--\u003E\u003C!--    .alert.alert-success--\u003E\u003C!--    .alert.alert-danger--\u003E\u003C!--  .row(style=\"padding:10px 0 5px 0;\")--\u003E\u003C!--    .col-xs-10.col-xs-offset-1--\u003E\u003C!--      small--\u003E\u003C!--        i--\u003E\u003C!--          b Note: &nbsp;--\u003E\u003C!--          span= phNotice--\u003E\u003C!--  .row--\u003E\u003C!--    .col-xs-8.col-xs-offset-1--\u003E\u003C!--      .input-group--\u003E\u003C!--        input.form-control#fake-sampling-file-upload-input(type=\"text\" name=\"file-name\" placeholder=\"Upload a sampling results csv file\")--\u003E\u003C!--        input#selected-sampling-upload-file(type=\"file\" style=\"display:none;\")--\u003E\u003C!--        a.btn.input-group-addon(onclick=\"$('#selected-sampling-upload-file').click();\") Browse--\u003E\u003C!--    .col-xs-2--\u003E\u003C!--      a.btn.btn-success#upload-sampling-results-button Upload--\u003E\u003C!--  .row--\u003E\u003C!--    .col-xs-12.text-center--\u003E\u003C!--      b -or---\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cdiv class=\"row\" style=\"padding: 25px 0 10px 0\"\u003E\u003Cdiv class=\"col-xs-12 text-center\"\u003E\u003Cform class=\"form-inline form-inline-with-margin-sm text-center\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cselect class=\"form-control\" type=\"text\" name=\"slug\" style=\"width: 300px\"\u003E\u003Coption value=\"\" disabled selected\u003ESelect a parameter\u003C\u002Foption\u003E";
// iterate availableSampleParameters
;(function(){
  var $$obj = availableSampleParameters;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var parameter = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", parameter.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = parameter.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var parameter = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", parameter.slug, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = parameter.display_text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-success\" type=\"submit\"\u003EAdd\u003C\u002Fbutton\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"download-region\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "availableSampleParameters" in locals_for_with ?
        locals_for_with.availableSampleParameters :
        typeof availableSampleParameters !== 'undefined' ? availableSampleParameters : undefined));
    ;;return pug_html;};
module.exports = template;