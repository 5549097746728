/* eslint-disable */
var APP = require('../config');
var iconCollection =
  require('../models_collections/iconCollection').singleton();

var NewCustomIconView = Backbone.View.extend({
  template: require('./templates/newCustomIcon.pug'),

  events: {
    'hidden.bs.modal': '_removeView',
    'click button[type="submit"]': '_setCustomIconName',
    'shown.bs.modal': '_focusNameInput',
  },

  render: function () {
    this.setElement(this.template());
    this.$el.modal('show');
    return this;
  },

  showModal: function () {
    this._name = 'NewCustomIconView';
    this.render();
  },

  _removeView: function () {
    this.remove();
  },

  _focusNameInput: function () {
    this.$('input').focus();
  },

  _setCustomIconName: function (e) {
    var name = this.$('#custom-icon-name-input').val();

    e.preventDefault();

    if (this._validate(name)) {
      this.trigger('validated', name);
      this.$el.modal('hide');
    }
  },

  _validate: function (name) {
    var error;

    if (name === '') {
      error = 'Name is required';
    }

    if (iconCollection.isExistingName(name)) {
      error =
        'You already have an icon named ' +
        name +
        '. Please choose a unique name';
    }

    if (error) {
      var $errorsNode = $('<div>').text(error);
      this.$('.alert-danger').html($errorsNode.html()).show();
      return;
    }
    return true;
  },
});

Backbone.addSingletonToView(NewCustomIconView);
module.exports = NewCustomIconView;
