/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import React from 'react';
import PropTypes from 'prop-types';

export default function RecordPanel({ children, header }) {
  return (
    <div className="record-panel">
      <div className="record-panel-header right-col-heading heading">
        {header}
      </div>
      <div className="record-panel-content">{children}</div>
    </div>
  );
}

RecordPanel.defaultProps = {
  children: undefined,
  header: undefined,
};
RecordPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
