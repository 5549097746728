/* eslint-disable */
import _ from 'underscore';
import attrStore from '../attrStore';
import UTILITY from '../utility';

var EditAttributeModel = Backbone.Model.extend({
  defaults: {
    formatValue: 'text',
    formatListKey: null,
  },

  NON_EDITABLE_NAMES: [
    'building name',
    'feature label',
    'latitude',
    'longitude',
    'area',
    'length',
    'street address',
    'city',
    'state',
    'postal code',
    'address',
    'elevation',
  ],

  NON_EDITABLE_NAMES_FOR_VALUES: [
    'latitude',
    'longitude',
    'area',
    'length',
    'elevation',
  ],

  NON_DELETABLE_NAMES: [
    'building name',
    'feature label',
    'latitude',
    'longitude',
    'area',
    'length',
    'elevation',
  ],

  nameIsEditable: function () {
    return !_.contains(this.NON_EDITABLE_NAMES, this.get('name').toLowerCase());
  },

  valueIsEditable: function () {
    return !_.contains(
      this.NON_EDITABLE_NAMES_FOR_VALUES,
      this.get('name').toLowerCase(),
    );
  },

  blockRender() {
    return (
      UTILITY.ATTRS.rendersOnlyToOwningFeature(this.get('templateKey')) &&
      !this.get('valueModel')
    );
  },

  toJSON: function () {
    const id = this.cid;
    const name = this.get('name');
    const type = this.get('type');
    const options = this.get('options');
    const nameIsEditable = this.nameIsEditable();
    const valueIsEditable = this.valueIsEditable();
    const editMode = this.get('editMode');
    let value = this.get('value');
    const templateKey = this.get('templateKey');
    const hideDeleteBtn =
      _.contains(this.NON_DELETABLE_NAMES, name.toLowerCase()) ||
      value === undefined;

    const isList = !!this.get('isList');
    const listHasValue = !!this.get('value');
    let listValues =
      isList && listHasValue ? JSON.parse(this.get('value')) : [];
    if (isList && !listHasValue) {
      const key = this.get('templateKey');
      listValues = attrStore.getListKvps(key);
    }

    const editFields = attrStore
      .getListKvps(templateKey)
      .filter((field) => !field.hidden);
    const selectMap = new Map();
    if (editFields && isList && listHasValue) {
      const keyValueMap = listValues.reduce((map, field) => {
        map.set(field.key, field.value);
        return map;
      }, new Map());
      listValues = editFields.map((field) =>
        _.extend({}, field, { value: keyValueMap.get(field.key) }),
      );
      listValues
        .filter(
          (field) => field.type === 'select' || field.type === 'multiselect',
        )
        .map((field) => {
          const options = field.options ? field.options : [];
          return {
            key: field.key,
            map: options.reduce((map, field) => {
              map.set(field.key, field.label);
              return map;
            }, new Map()),
          };
        })
        .forEach(({ key, map }) => {
          selectMap.set(key, map);
        });
    }

    return {
      name,
      displayLabelName: editMode && nameIsEditable && value ? 'hidden' : '',
      displayEditName: editMode && nameIsEditable && value ? '' : 'hidden',
      value,
      displayLabelValue: editMode && valueIsEditable ? 'hidden' : '',
      displayEditValue: editMode && valueIsEditable ? '' : 'hidden',
      deleteClass: hideDeleteBtn ? 'invisible' : '',
      geocoded: this.get('geocoded') ? 'geocoded' : '',
      templateKey,
      isList,
      listValues: listValues.filter((field) => !field.hidden),
      listValues1: listValues.slice(0, 17),
      listValues2: listValues.slice(17),
      selectMap,
      type,
      options,
      id,
    };
  },
});

module.exports = EditAttributeModel;
