import React from 'react';
import FormField from './FormField';
import TextFieldElement from '../../../elements/TextField';

class TextField extends FormField {
  getInputComponent(templateField, error) {
    const { disabled } = this.props;

    return (
      <TextFieldElement
        value={this.getValue()}
        onChange={this.handleChange}
        error={error}
        disabled={disabled}
      />
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TextField;
