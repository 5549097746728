/* eslint-disable */
let apiCaller = require('../../../../../../../apiCaller');
let errorView = require('../../../../../../../modals/errorView').singleton();
let infoView = require('../../../../../../../modals/infoView').singleton();
let router = require('../../../../../../../router');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/hmbpHeader.pug'),

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadHmbpCollection = options.uploadHmbpCollection;
    this.projectModel = options.projectModel;
    this.planName = options.planName;
  },

  ui: {
    $uploadHmbpPdf: '#upload-hmbp-pdf',
    $faCloudUpload: '.fa-cloud-upload',
    $faCloudDownload: '.fa-cloud-download',
    $faSpinner: '.fa-spinner',
  },

  templateHelpers() {
    let hmbpDocModel = null;
    this.uploadHmbpCollection.models.forEach((doc) => {
      if (
        !hmbpDocModel ||
        new Date(hmbpDocModel.get('created_at')) <
          new Date(doc.get('created_at'))
      ) {
        hmbpDocModel = doc;
      }
    });

    return {
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      downloadPdfUrl: hmbpDocModel ? hmbpDocModel.get('url') : '',
      hmbpUpload: hmbpDocModel,
      planName: this.planName,
    };
  },

  behaviors: {
    Tooltip: {},
  },

  events: {
    'click .download-hmbp-plan': '_handleClickDownloadUserSuppliedHmbp',
    'click .download-user-supplied-pdf': '_handleClickDownloadUserSuppliedHmbp',
    'click .upload-hmbp-plan': '_handleFileUpload',
    'click .upload-user-supplied-pdf': '_handleFileUpload',
    'change #upload-hmbp-pdf': '_saveUploadedHmbp',
  },

  _handleClickDownloadUserSuppliedHmbp() {
    window.open(
      this.uploadHmbpCollection
        .at(this.uploadHmbpCollection.length - 1)
        .get('src'),
    );
  },

  _handleFileUpload() {
    this.ui.$uploadHmbpPdf.click();
  },

  toggleButtons() {
    if (this.ui.$faSpinner.css('display') === 'none') {
      this.ui.$faSpinner.css('display', 'inline-block');
    } else {
      this.ui.$faSpinner.css('display', 'none');
    }

    this.ui.$faCloudUpload.toggle();
    this.ui.$faCloudDownload.toggle();
  },

  _saveUploadedHmbp() {
    let file = this.ui.$uploadHmbpPdf.prop('files')[0];
    let data = new FormData();
    data.append('file', file);
    let options = { data: data, processData: false, contentType: false };
    this.toggleButtons();

    this.uploadHmbpCollection
      .createPromise(null, options)
      .bind(this)
      .then(this.render.bind(this))
      .catch(() => {
        this.toggleButtons();
        let errorMsg =
          'There was an error on the server, and your ' +
          this.planName +
          ' could not be uploaded. Please try again or contact us if the problem persists.';
        errorView.showModal({ error: errorMsg });
      });
  },
});
