import { Select } from '@monorepo/old-web/js/components/elements';
import React, { useCallback, useMemo } from 'react';
import { ValuesType } from 'utility-types';
import { OnboardingApi } from '../apiClient';

type StatusSelectValue = OnboardingApi.OnboardingDocumentStatus | null;

interface OnboardingDocumentStatusSelectProps {
  id?: string;
  isClearable?: boolean;
  onChange: (staus: StatusSelectValue) => void;
  value: StatusSelectValue;
}

export function OnboardingDocumentStatusSelect(
  props: OnboardingDocumentStatusSelectProps,
) {
  const { id, value, onChange, isClearable } = props;
  const selectedOption = useMemo(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    () => statusOptions.find((opt) => opt.value === value),
    [value],
  );
  const handleChange = useCallback(
    (opt: ValuesType<typeof statusOptions> | null) => {
      onChange(opt == null ? null : opt.value);
    },
    [onChange],
  );
  return (
    <Select
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      id={id}
      value={selectedOption}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      options={statusOptions}
      onChange={handleChange}
      isClearable={isClearable}
    />
  );
}

const statusOptions = [
  {
    label: 'Action Required',
    value: OnboardingApi.OnboardingDocumentStatus.actionRequired,
  },
  {
    label: 'Uploaded',
    value: OnboardingApi.OnboardingDocumentStatus.uploaded,
  },
  {
    label: 'Accepted',
    value: OnboardingApi.OnboardingDocumentStatus.accepted,
  },
];
