/* eslint-disable */
var NewAndEditMapModelBase = Backbone.Model.extend({
  initialize: function () {
    var projectMapsService = require('../../project/services/projectMapsService');
    this.map = projectMapsService.getSelectedMap();
  },

  setLogoFormData: function (logoFormData) {
    this.logoFormData = logoFormData;
  },

  /**
   * Send the logo to the server, and update the titlebar with the new logoImage name
   */
  saveLogoImage: function () {
    var APP = require('../../config');

    return new Promise(
      function (resolve) {
        this.logoFormData.append('mapId', this.map.id);

        $.ajax({
          url:
            APP.apiUrl + '/buckets/' + APP.titlebarLogoBucketName + '/images',
          type: 'POST',
          data: this.logoFormData,
          cache: false,
          contentType: false,
          processData: false,
        }).always(
          function (logoImageName) {
            this.map.titlebar().save({ logo_image: logoImageName });
            resolve();
          }.bind(this),
        );
      }.bind(this),
    );
  },
});

module.exports = NewAndEditMapModelBase;
