import { useHasProjectUpdatePermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Menu } from '../../elements';
import RecordPanel from '../../views/projectDashboard/RecordPanel';
import EditFlowLogModal from './EditFlowLogModal';
import FlowLogList from './FlowLogList';
import FlowLogSettingsModal from './FlowLogSettingsModal';
import { FlowLogsUploadModal } from './FlowLogsUploadModal/FlowLogsUploadModal';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function FlowLogRecordPanel(props) {
  const {
    editModalIsOpen,
    flowLogUploadModalIsOpen,
    flowReadingIdToEdit,
    monitoringLocations,
    onCloseEditModal,
    onCloseSettingsModal,
    onCloseFlowLogUploadModal,
    onDownloadFlowLog,
    onOpenFlowLogUploadModal,
    onOpenSettingsModal,
    onOpenEditModal,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    projectId,
    settingsModalIsOpen,
  } = props;
  const hasProjectUpdatePermission = useHasProjectUpdatePermissions(projectId);

  const hasConfiguredFlowLog = monitoringLocations.length > 0;
  const menuOptions = useMemo(() => {
    const options = [
      ...(hasProjectUpdatePermission
        ? [
            {
              disabled: !hasConfiguredFlowLog,
              label: 'Upload Flow Logs',
              onClick: onOpenFlowLogUploadModal,
              iconIdentifier: 'upload',
            },
          ]
        : []),
      {
        label: 'Flow Log Settings',
        onClick: onOpenSettingsModal,
        iconIdentifier: 'cog',
      },
      ...(hasProjectUpdatePermission
        ? [
            {
              disabled: !hasConfiguredFlowLog,
              label: 'Add Volume',
              onClick: () => onOpenEditModal(),
              iconIdentifier: 'edit',
            },
          ]
        : []),
      {
        disabled: !hasConfiguredFlowLog,
        label: 'Download',
        onClick: onDownloadFlowLog,
        iconIdentifier: 'download',
      },
    ];

    return options;
  }, [
    hasConfiguredFlowLog,
    onDownloadFlowLog,
    onOpenEditModal,
    onOpenFlowLogUploadModal,
    onOpenSettingsModal,
    hasProjectUpdatePermission,
  ]);

  return (
    <RecordPanel
      header={
        <>
          <div className="list-header">Flow Log</div>
          <Menu options={menuOptions} />
        </>
      }
    >
      <FlowLogList onOpenEditModal={onOpenEditModal} />
      <EditFlowLogModal
        flowReadingIdToEdit={flowReadingIdToEdit}
        onClose={onCloseEditModal}
        open={editModalIsOpen}
      />
      <FlowLogSettingsModal
        onClose={onCloseSettingsModal}
        open={settingsModalIsOpen}
      />
      <FlowLogsUploadModal
        isOpen={flowLogUploadModalIsOpen}
        onClose={onCloseFlowLogUploadModal}
      />
    </RecordPanel>
  );
}

FlowLogRecordPanel.defaultProps = {
  editModalIsOpen: false,
  flowLogUploadModalIsOpen: false,
  flowReadingIdToEdit: null,
  monitoringLocations: [],
  settingsModalIsOpen: false,
};

FlowLogRecordPanel.propTypes = {
  editModalIsOpen: PropTypes.bool,
  flowLogUploadModalIsOpen: PropTypes.bool,
  flowReadingIdToEdit: PropTypes.string,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onCloseEditModal: PropTypes.func.isRequired,
  onCloseSettingsModal: PropTypes.func.isRequired,
  onCloseFlowLogUploadModal: PropTypes.func.isRequired,
  onDownloadFlowLog: PropTypes.func.isRequired,
  onOpenFlowLogUploadModal: PropTypes.func.isRequired,
  onOpenSettingsModal: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  settingsModalIsOpen: PropTypes.bool,
};
