import React, { Fragment, useMemo } from 'react';
import { OnboardingApi } from '../../apiClient';
import { sortAndOrderAndFilterOnboardingProjectSections } from '../../helpers/sortAndOrderAndFilterOnboardingProjectSections';

interface ProjectRowProps {
  projectName: string;
  sections: OnboardingApi.OnboardingSection[];
}

interface ModuleRowProps {
  section: OnboardingApi.OnboardingSection;
}

interface DocumentRowProps {
  documentRequest: OnboardingApi.OnboardingDocumentRequest;
}

interface NewDocumentRowProps {
  sectionId: string;
}

interface OnboardingDocumentsTableProps {
  renderDocumentRow: (
    rowProps: DocumentRowProps & { key: string },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => React.ReactElement<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderModuleRow: (rowProps: ModuleRowProps) => React.ReactElement<any>;
  renderNewDocumentRow: (
    rowProps: NewDocumentRowProps,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => React.ReactElement<any>;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderProjectRow: (rowProps: ProjectRowProps) => React.ReactElement<any>;
  sections: OnboardingApi.OnboardingSection[];
  statusFilter: OnboardingApi.OnboardingDocumentStatus | null;
}

export function OnboardingDocumentsTable(props: OnboardingDocumentsTableProps) {
  const {
    sections,
    statusFilter,
    renderProjectRow,
    renderModuleRow,
    renderDocumentRow,
    renderNewDocumentRow,
  } = props;

  const allProjectSections = useMemo(
    () =>
      sortAndOrderAndFilterOnboardingProjectSections(sections, statusFilter),
    [sections, statusFilter],
  );

  return (
    <table className="onboarding-documents-table">
      <colgroup>
        <col className="col-suite" />
        <col className="col-documents" />
        <col className="col-status" />
        <col className="col-notes" />
        <col className="col-actions" />
      </colgroup>
      <thead>
        <tr>
          <th>Suite</th>
          <th>Documents</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Actions</th>
        </tr>
      </thead>
      {allProjectSections?.map(([projectName, projectSections]) => (
        <tbody key={projectName}>
          {renderProjectRow({ projectName, sections: projectSections })}
          {projectSections.map((section) => (
            <Fragment key={`${section.projectName}.${section.moduleName}`}>
              {renderModuleRow({ section })}
              {section.enabled && (
                <>
                  {section.documentRequests.map((documentRequest) =>
                    renderDocumentRow({
                      key: documentRequest.id,
                      documentRequest,
                    }),
                  )}
                  {renderNewDocumentRow({ sectionId: section.id })}
                </>
              )}
            </Fragment>
          ))}
        </tbody>
      ))}
    </table>
  );
}
