/* eslint-disable */
var WebMapMarkerIconFactory = {
  create: function (styleModel, iconModel, rotation) {
    var size = styleModel.get('size');
    var shape = styleModel.get('shape');
    var opacity = styleModel.get('opacity');
    var iconSize;
    var iconAnchor;
    var $html;
    var className;

    if (iconModel) {
      const isSharedLibrary = !!iconModel.get('shared_library');
      if (isSharedLibrary && size < 22) {
        size = 22;
      }

      iconSize = iconModel.get('isWider') ? [size, 'auto'] : ['auto', size];
      iconAnchor = L.point(size / 2, size / 2);
      className = 'leaflet-div-icon-custom-image';
      $html = $('<img>').attr('src', iconModel.url()).css('opacity', opacity);

      if (iconModel.get('isWider')) {
        $html.css('width', size);
      } else {
        $html.css('height', size);
      }
    } else {
      var fontSizeStyle = 'font-size: ' + size + 'px;';
      var colorStyle = 'color: ' + styleModel.get('color') + '!important;';
      var opacityStyle = 'opacity: ' + opacity;

      iconSize = [size, size];
      className = 'leaflet-div-icon-custom';

      $html = $('<i>')
        .addClass('fa')
        .addClass('fa-' + shape)
        .addClass('styles')
        .attr('style', fontSizeStyle + colorStyle + opacityStyle);

      if (shape === 'map-marker') {
        var $whiteCircle = $('<div>')
          .addClass('marker-circle')
          .css({
            width: size / 2.5,
            height: size / 2.5,
            top: size / 6,
            left: size / 3,
          });

        $html.append($whiteCircle);
        iconAnchor = L.point(size / 2, size - 3);
      }
    }

    if (rotation) {
      $html.css('transform', `rotate(${rotation}deg)`);
    }

    return L.divIcon({
      iconSize: iconSize,
      iconAnchor: iconAnchor,
      className: className,
      html: $html[0].outerHTML,
    });
  },
};

module.exports = WebMapMarkerIconFactory;
