/* eslint-disable */
let errorView = require('../../../../../../../../modals/errorView').singleton();

module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/documents.pug'),
  childView: require('./documentRowItemView.js'),
  childViewContainer: '#document-list',
  emptyView: require('./NoDocumentsView'),

  ui: {
    $uploadDocument: '#upload-document',
    $faSpinner: '.fa-spinner',
    $faCloudUpload: '.fa-cloud-upload',
    $addDocumentLabel: '.add-document-label',
  },

  events: {
    'click .upload-new-document': '_uploadNewDocument',
    'change #upload-document': '_saveUploadedDoc',
  },

  behaviors: {
    Tooltip: {},
  },

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
  },

  _uploadNewDocument() {
    if (!(this.isDisabled && this.isDisabled())) {
      this.ui.$uploadDocument.click();
    }
  },

  toggleButtons() {
    this.ui.$faSpinner.toggle();
    this.ui.$faCloudUpload.toggle();
    this.ui.$addDocumentLabel.toggle();
  },

  _saveUploadedDoc() {
    let files = this.ui.$uploadDocument.prop('files');
    let errorMsg =
      'There was an error on the server and at least one of your documents could not be uploaded.\
     Please try again or contact us if the problem persists.';
    this.toggleButtons();

    _.each(files, (file) => {
      let data = new FormData();
      data.append('file', file);
      let options = {
        data: data,
        processData: false,
        contentType: false,
        wait: true,
      };
      this.collection
        .createPromise(null, options)
        .bind(this)
        .then(this.render.bind(this))
        .catch(() => {
          if (!errorView.isShowing) {
            this.toggleButtons();
            errorView.showModal({ error: errorMsg });
          }
        });
    });
  },
});
