var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isData2Enabled, showDropdownMenu, showMenuLinks, userEmail) {
      pug_html = pug_html + "\u003Cnav class=\"navbar navbar-inverse navbar-custom\" id=\"navbar-view\" role=\"navigation\"\u003E\u003Cdiv class=\"navbar-brand-container\"\u003E\u003Ca class=\"navbar-brand\"\u003E\u003Csvg class=\"logo-full\"\u003E\u003Cuse xlink:href=\"#Logo\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"container-fluid\"\u003E\u003Cdiv class=\"main-nav-container\"\u003E\u003Ca class=\"onboarding hidden\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#m-org-dashboard\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003EOnboarding\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"analytics\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#analytics\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003EAnalytics\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"project-dashboard\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#m-site-dashboard\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003ESites\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Ca class=\"project-maps\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#m-map-dashboard\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003EMaps\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
if (isData2Enabled) {
pug_html = pug_html + "\u003Ca class=\"data-setup\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#data-setup\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003EData Setup\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (showDropdownMenu) {
pug_html = pug_html + "\u003Cul class=\"nav navbar-nav pull-right\"\u003E\u003Cli class=\"dropdown dropdown-menu-right\" id=\"settings\"\u003E\u003Ca class=\"dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\" aria-expanded=\"false\"\u003E\u003Csvg\u003E\u003Cuse xlink:href=\"#m-cog\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003Cspan\u003ESettings\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003Cul class=\"dropdown-menu\" role=\"menu\"\u003E\u003Cli class=\"email-address\"\u003E" + (pug.escape(null == (pug_interp = userEmail) ? "" : pug_interp)) + "\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca class=\"user-settings\"\u003ESettings\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli\u003E\u003Ca href=\"\u002Flogout\"\u003ELogout\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E";
}
if (showMenuLinks) {
pug_html = pug_html + "\u003Cul class=\"nav navbar-nav pull-right\"\u003E\u003C\u002Ful\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fnav\u003E";
    }.call(this, "isData2Enabled" in locals_for_with ?
        locals_for_with.isData2Enabled :
        typeof isData2Enabled !== 'undefined' ? isData2Enabled : undefined, "showDropdownMenu" in locals_for_with ?
        locals_for_with.showDropdownMenu :
        typeof showDropdownMenu !== 'undefined' ? showDropdownMenu : undefined, "showMenuLinks" in locals_for_with ?
        locals_for_with.showMenuLinks :
        typeof showMenuLinks !== 'undefined' ? showMenuLinks : undefined, "userEmail" in locals_for_with ?
        locals_for_with.userEmail :
        typeof userEmail !== 'undefined' ? userEmail : undefined));
    ;;return pug_html;};
module.exports = template;