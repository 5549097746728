import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { MapistryTooltip } from '../../../elements';
import { isNullOrUndefined } from '../../../../utils';
import { LIMIT_TYPE } from '../../../../utils/shared';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function SamplingResultFormParameterLimitCalculation({
  calculationLabel,
  calculationValue,
  limitLower,
  limitType,
  limitUpper,
  units,
  warning,
}) {
  const hasCalculation = !isNullOrUndefined(calculationLabel);
  const hasLimit =
    !isNullOrUndefined(limitLower) || !isNullOrUndefined(limitUpper);
  const isRangeLimit = LIMIT_TYPE.RANGE === limitType;
  const hasSingleLimit = hasLimit && !isRangeLimit;
  const unitsToDisplay = isNullOrUndefined(units) ? '' : ` ${units}`;
  const limitText = isRangeLimit
    ? ` Limit range ${limitLower}${unitsToDisplay} - ${limitUpper}${unitsToDisplay}`
    : `Limit ${
        !isNullOrUndefined(limitLower) ? limitLower : limitUpper
      }${unitsToDisplay}`;
  const calculationValueNumber = Number.parseFloat(calculationValue);
  const limitLowerNumber = Number.parseFloat(limitLower);
  const limitUpperNumber = Number.parseFloat(limitUpper);
  const isWithinRange =
    limitLowerNumber <= calculationValueNumber &&
    calculationValueNumber <= limitUpperNumber;

  const warningText = (() => {
    if (!isNullOrUndefined(warning)) {
      return warning;
    }
    if (hasCalculation) {
      if (isRangeLimit && !isWithinRange) {
        return `The ${calculationLabel.toLowerCase()} is outside the defined limit range.`;
      }
      if (hasSingleLimit && limitLowerNumber > calculationValueNumber) {
        return `The ${calculationLabel.toLowerCase()} is below the defined limit.`;
      }
      if (hasSingleLimit && calculationValueNumber > limitUpperNumber) {
        return `The ${calculationLabel.toLowerCase()} has exceeded the defined limit.`;
      }
    }
    return null;
  })();
  return (
    hasCalculation && (
      <div className="wastewater-sampling-result-limit-calculation">
        <div className="wastewater-sampling-result-limit-calculation-label">
          <span>{calculationLabel}</span>
          {!isNullOrUndefined(warningText) && (
            <MapistryTooltip placement="top" title={warningText}>
              <div>
                <SvgIcon
                  className="wastewater-sampling-result-icon-error"
                  identifier="exclamation"
                  withBorder
                />
              </div>
            </MapistryTooltip>
          )}
        </div>
        <span className="wastewater-sampling-result-limit-calculation-label">
          {`${calculationValue}${unitsToDisplay}`}
        </span>
        {hasLimit && (
          <span className="wastewater-sampling-result-limit-user-limit">
            {hasCalculation ? `(${limitText})` : limitText}
          </span>
        )}
      </div>
    )
  );
}

SamplingResultFormParameterLimitCalculation.defaultProps = {
  calculationLabel: null,
  calculationValue: null,
  limitLower: null,
  limitType: null,
  limitUpper: null,
  units: '',
  warning: null,
};

SamplingResultFormParameterLimitCalculation.propTypes = {
  calculationLabel: PropTypes.string,
  calculationValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limitLower: PropTypes.string,
  limitType: PropTypes.oneOf(Object.values(LIMIT_TYPE)),
  limitUpper: PropTypes.string,
  units: PropTypes.string,
  warning: PropTypes.string,
};
