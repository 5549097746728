var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (kvps, location) {
      pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = location) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
// iterate kvps
;(function(){
  var $$obj = kvps;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var kvp = $$obj[pug_index0];
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = kvp.value) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003Ctd class=\"chemical-attrs\"\u003E\u003Cdiv id=\"select-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
    }.call(this, "kvps" in locals_for_with ?
        locals_for_with.kvps :
        typeof kvps !== 'undefined' ? kvps : undefined, "location" in locals_for_with ?
        locals_for_with.location :
        typeof location !== 'undefined' ? location : undefined));
    ;;return pug_html;};
module.exports = template;