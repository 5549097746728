/* eslint-disable */
var ShowLoader = Backbone.Marionette.Behavior.extend({
  events: {
    ShowLoader: 'onShowLoader',
    ResetLoader: 'onResetLoader',
  },

  onShowLoader: function () {
    if (this.view.ui.$btnSubmit && this.view.ui.$btnSubmit.button) {
      this.view.ui.$btnSubmit.button('loading');
    }
  },

  onResetLoader: function () {
    if (this.view.ui.$btnSubmit && this.view.ui.$btnSubmit.button) {
      this.view.ui.$btnSubmit.button('reset');
    }
  },
});

module.exports = ShowLoader;
