/* eslint-disable */
var ChecklistCollection = Backbone.Collection.extend({
  url: function () {
    var APP = require('../../../config');
    return APP.apiUrl + '/projects/' + APP.projectId + '/checklist';
  },

  parse: function (resp) {
    return resp.checklist_steps;
  },
});

Backbone.addSingletonToCollection(ChecklistCollection);
module.exports = ChecklistCollection;
