/* eslint-disable */
// Collections
var iconCollection =
  require('../models_collections/iconCollection').singleton();

// Services
var APP = require('../config');

var EditLayerStylesLayoutView = Backbone.Marionette.LayoutView.extend({
  events: {
    'submit #edit-layer-styles-form': '_handleSubmit',
  },

  ui: {
    $editLayerStylesForm: '#edit-layer-styles-form',
  },

  regions: {
    styleContent: '.styles-content-region',
  },

  template: require('./templates/editLayerStyles.pug'),

  behaviors: {
    Tooltip: {},
  },

  onShow: function () {
    if (this.model.containsMarkers()) {
      let MarkersItemView = require('./MarkersItemView');
      this._styleItemView = new MarkersItemView({ model: this.model.style() });
    } else if (this.model.containsPolylines()) {
      let PolylineItemView = require('./PolylineItemView');
      this._styleItemView = new PolylineItemView({ model: this.model.style() });
    } else if (this.model.containsPolygons()) {
      let PolygonItemView = require('./PolygonItemView');
      this._styleItemView = new PolygonItemView({ model: this.model.style() });
    } else if (this.model.isAerialImageryLayer()) {
      let AerialimageryItemView = require('./AerialimageryItemView');
      this._styleItemView = new AerialimageryItemView({
        model: this.model.style(),
      });
    }
    this.styleContent.show(this._styleItemView);

    return this;
  },

  _handleSubmit: function (e) {
    e.preventDefault();

    if (!this.ui.$editLayerStylesForm.length) {
      return;
    }

    // format the new styles object to match db format
    var newStyles = this._styleItemView._formatStyles(
      this.ui.$editLayerStylesForm.serializeObject(),
    );

    return this.model
      .style()
      .savePromise(newStyles, { wait: true })
      .then(APP.vent.trigger.bind(APP.vent, 'editLayerStylesView:success'))
      .catch(APP.vent.trigger.bind(APP.vent, 'editLayerStylesView:error'));
  },
});

module.exports = EditLayerStylesLayoutView;
