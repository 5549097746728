import { SaveState } from '@monorepo/shared/types/SaveState';
import { FullMonths } from 'mapistry-shared';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useCustomLogTemplates } from '../../../../hooks/genericLogs/useCustomLogTemplates';
import {
  FieldWrapper,
  FormActions,
  Select,
  TextField,
} from '../../../elements';
import {
  useCurrentLogProject,
  useUpdateLogProject,
} from '../../LogProjectContext';

interface EditLogProjectProps {
  handleClose: () => void;
}

const dateOptions = Object.keys(FullMonths).map((monthKey, monthNumber) => ({
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  label: `${FullMonths[monthKey]} 1`,
  value: monthNumber,
}));

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const findDateOption = (dateValue) => dateOptions[dateValue] ?? undefined;

const EditLogProject = ({ handleClose }: EditLogProjectProps): ReactElement => {
  const { id, logProject } = useCurrentLogProject();
  const logTemplate = useCustomLogTemplates();
  const [name, setName] = useState(logProject?.name || '');
  const [permitStartDate, setPermitStartDate] = useState(
    findDateOption(logProject?.intervalStartMonth),
  );
  const [informationalText, setInformationalText] = useState(
    logProject?.informationalText || '',
  );
  const [formSaveState, setFormSaveState] = useState(SaveState.CLEAN);
  useEffect(() => {
    const saveState =
      name !== logProject?.name ||
      informationalText !== logProject?.informationalText ||
      permitStartDate?.value !== logProject?.intervalStartMonth
        ? SaveState.DIRTY
        : SaveState.CLEAN;
    setFormSaveState(saveState);
  }, [informationalText, logProject, name, permitStartDate]);

  const [updateLogProject, { reset, isLoading, isError, isSuccess }] =
    useUpdateLogProject();
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await updateLogProject({
        id,
        name,
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        intervalStartMonth: permitStartDate?.value ?? null,
        informationalText,
      });
    },
    [id, informationalText, name, permitStartDate, updateLogProject],
  );

  if (isSuccess) {
    handleClose();
    reset();
  }

  return (
    <form onSubmit={handleSubmit} className="edit-log-name">
      <FieldWrapper
        label="Log Name"
        className={`field name ${
          logTemplate.hasPermitStartDate ? 'w50' : 'w100'
        }`}
      >
        <TextField value={name} onChange={(e) => setName(e.target.value)} />
      </FieldWrapper>
      {logTemplate.hasPermitStartDate && (
        <FieldWrapper
          label="Start date of permit year"
          className="field permit-start-date w50"
        >
          <Select
            isDisabled={isLoading}
            isClearable
            onChange={(option) => setPermitStartDate(option)}
            options={dateOptions}
            value={permitStartDate}
            error={isError}
          />
        </FieldWrapper>
      )}
      <FieldWrapper
        disabled={isLoading}
        className="field informational-text-input w100"
        label="Informational Text"
        helpText={
          'This information will be displayed at the top of the "Add a log" modal as well as your log data export'
        }
        helpTextPlacement="right"
      >
        <TextField
          error={isError}
          multiline
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          rowsMax={6}
          disabled={isLoading}
          value={informationalText}
          onChange={(e) => setInformationalText(e.target.value)}
        />
      </FieldWrapper>
      {isError && (
        <div className="form-errors__container">
          <div className="form-errors__header">Error updating log project</div>
        </div>
      )}

      <div className="field buttons w100">
        <FormActions
          formSaveState={formSaveState}
          onSave={handleSubmit}
          onClose={handleClose}
          saveText="Save Settings"
        />
      </div>
    </form>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditLogProject;
