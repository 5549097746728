import React from 'react';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import MapistryTooltip from '../../../elements/MapistryTooltip';

function MarkerComplete() {
  return (
    <MapistryTooltip placement="top" title="This section is complete">
      <span className="section-marker section-complete-marker">
        <SvgIcon identifier="check" className="form-submission-marker-icon" />
      </span>
    </MapistryTooltip>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MarkerComplete;
