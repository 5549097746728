import { Api } from '@monorepo/shared/apiClient';
import { useQuery } from 'react-query';

export const mapFeaturesQueryKey = ({
  projectId,
  tags,
}: Api.FetchMapFeaturesProps) => ['mapFeature', projectId, tags];

const fetcher = (_: string, projectId: string, tags: string[]) =>
  Api.fetchMapFeatures({ projectId, tags });

/**
 * @deprecated use useMapFeatures hook from shared package
 */
export const useMapFeatures = (props: Api.FetchMapFeaturesProps) => {
  const { data, ...rest } = useQuery(mapFeaturesQueryKey(props), fetcher);
  return { mapFeatures: data, ...rest };
};
