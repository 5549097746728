/* eslint-disable */
let moment = require('moment');

let BaseSwpppUpdateModalView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/modal.pug'),

  templateHelpers() {
    let dmr = this.model;
    let dateSubmitted = dmr && dmr.get('date_submitted');

    return {
      formattedDateSubmitted: dateSubmitted
        ? moment(new Date(dateSubmitted)).format('MM/DD/YYYY')
        : '',
      formattedDueDate: moment(new Date(dmr.get('due_date'))).format(
        'MM/DD/YYYY',
      ),
    };
  },

  ui: {
    $dateInput: '.input-group.date input',
    $datepicker: '.input-group.date',
    $modal: '.modal',
    $errorContainer: '.alert-danger',
  },

  events: {
    'click .btn-submit': '_saveDMR',
  },

  behaviors: {
    EnableDatepicker: {},
    DisplayMessages: {},
  },

  showModal() {
    this.ui.$modal.modal('show');
  },

  hideModal() {
    this.ui.$modal.on(
      'hidden.bs.modal',
      this.triggerMethod.bind(this, 'hidden:modal'),
    );
    this.ui.$modal.modal('hide');
  },

  _getFormattedDate(dateUpdated) {
    let dateService = require('../../../../../../../../services/dateService');
    let isValidDate = dateService.isValidDate(dateUpdated);

    return isValidDate ? moment(dateUpdated, 'MM/DD/YYYY').format() : null;
  },

  _saveDMR: function () {
    return this.model
      .savePromise(
        {
          date_submitted: this._getFormattedDate(
            this.$('form').serializeObject().date_submitted,
          ),
        },
        { wait: true },
      )
      .then(this.hideModal.bind(this))
      .catch(
        this.triggerMethod.bind(
          this,
          'ShowError',
          'There was a error saving the DMR submission date. Please try again.',
        ),
      );
  },
});

module.exports = BaseSwpppUpdateModalView;
