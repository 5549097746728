/* eslint-disable */
var ProjectUserRowView = require('./ProjectUserRowView');

var ProjectUserCollectionView = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'list-styled',

  childView: ProjectUserRowView,
});

module.exports = ProjectUserCollectionView;
