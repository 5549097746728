/* eslint-disable react/prop-types */

import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import React from 'react';
import { MapistryTooltip } from '../../../elements';
import { PlayArrow } from '../../../elements/MuiIcon';
import MarkerComplete from './MarkerComplete';
import MarkerEmpty from './MarkerEmpty';
import MarkerError from './MarkerError';
import MarkerTentative from './MarkerTentative';
import SectionChild from './SectionChild';

class SectionParent extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = { showChildren: true };

  constructor(props) {
    super(props);

    this.createSubsection = this.createSubsection.bind(this);
    this.toggleChildrenVisibility = this.toggleChildrenVisibility.bind(this);
    this.handleClickParentSection = this.handleClickParentSection.bind(this);
  }

  getSubsections(subsections) {
    const { showChildren } = this.state;

    if (!showChildren) {
      return [];
    }
    const {
      templateGroupId,
      isSelected,
      isStencil,
      currentSubmissionGroupNumber,
      onClick,
      onDeleteSubsection,
    } = this.props;

    return subsections.map((subsection, index) => (
      <SectionChild
        key={subsection.key}
        templateGroupId={templateGroupId}
        submissionGroupNumber={index}
        isSelected={isSelected && index === currentSubmissionGroupNumber}
        isComplete={subsection.isComplete}
        isDeletable={subsection.isProtected && !isStencil}
        isGroupSelected={isSelected}
        name={subsection.name}
        error={this.subsectionHasError(subsection)}
        onClick={onClick}
        onDeleteSubsection={onDeleteSubsection}
      />
    ));
  }

  statusMarker(subsections) {
    if (this.hasError(subsections)) {
      return <MarkerError />;
    }
    if (this.isTentative(subsections)) {
      return <MarkerTentative />;
    }
    if (this.isComplete(subsections)) {
      return <MarkerComplete />;
    }
    return <MarkerEmpty />;
  }

  hasError(subsections) {
    return subsections.some((subsection) =>
      this.subsectionHasError(subsection),
    );
  }

  subsectionHasError(subsection) {
    return (
      subsection.fields &&
      subsection.fields.some((field) => field.errors && field.errors.length > 0)
    );
  }

  isTentative(subsections) {
    return !subsections || subsections.length === 0;
  }

  isComplete(subsections) {
    return subsections.every((subsection) => subsection.isComplete);
  }

  createSubsection(event) {
    const { onCreateSubsection, templateGroupId } = this.props;
    event.stopPropagation();
    onCreateSubsection(templateGroupId);
    this.setState({ showChildren: true });
  }

  toggleChildrenVisibility(event) {
    const { showChildren } = this.state;

    event.stopPropagation();
    this.setState({ showChildren: !showChildren });
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  handleClickParentSection() {
    const { onClick, templateGroupId } = this.props;
    this.setState({ showChildren: true });
    onClick(templateGroupId, 0);
  }

  render() {
    const { subsections, isSelected, name } = this.props;
    const { showChildren } = this.state;

    const expandedClass =
      subsections.length && showChildren ? ' rotate-ninety' : '';
    const sectionSelectedClass = isSelected ? ' sidebar-section-selected' : '';

    return (
      <div className="section-wrapper">
        <button
          type="button"
          className={`sidebar-section${sectionSelectedClass}`}
          onClick={this.handleClickParentSection}
        >
          <PlayArrow
            className={`parent-section-toggle-icon${expandedClass}`}
            onClick={this.toggleChildrenVisibility}
          />
          {this.statusMarker(subsections)}
          <span className="sidebar-section-text section-text-bold">{name}</span>
        </button>
        <MapistryTooltip placement="top" title={`Add ${name}`}>
          <button
            type="button"
            className="create-subsection-button"
            onClick={this.createSubsection}
          >
            <SvgIcon identifier="add" className="create-subsection-icon" />
          </button>
        </MapistryTooltip>
        {this.getSubsections(subsections)}
      </div>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SectionParent;
