/* eslint-disable */
module.exports = Backbone.Marionette.CollectionView.extend({
  childView: require('./CarouselPhotoItemView'),
  className: 'carousel-inner',

  onShow() {
    if (this.$el.children().length) {
      $(this.$el.children()[0]).addClass('active');
    }

    this.$el.prop('role', 'listbox');
  },

  onAddChild() {
    if (this.$el.children().length === 1) {
      $(this.$el.children()[0]).addClass('active');
    }
  },
});
