import BaseRouter from '@monorepo/old-web/js/BaseRouter';
import ReactBackboneController from './ReactBackboneController';

const ReactBackboneRouter = BaseRouter.extend({
  controller: new ReactBackboneController(),

  appRoutes: {
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug/:calendarName/groups/:groupId':
      'showFormSubmissionGroup',
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug(/:calendarName)/show-form':
      'showFormWithoutEvent',
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug/:calendarName/:eventDate/:eventNumber/show-form':
      'showFormWithEvent',
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug/:calendarName/:eventDate/:eventNumber':
      'showFormSubmissionWithEvent',
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug/:calendarName/stencil':
      'showFormSubmissionStencil',
    'projects/:projectId/form-submissions/:templateSlug/:submissionSlug(/:calendarName)':
      'showFormSubmissionWithoutEvent',
    // React routes, rendered from public/js/routing/ReactRoutes.tsx
    'projects/:projectId/*splat': 'showProjectReactRoute',
    'onboarding(/*splat)': 'showTopLevelReactRoute',
    // Admin routes
    'admin/onboarding(/*splat)': 'showTopLevelReactRoute',
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ReactBackboneRouter;
