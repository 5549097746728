/* eslint-disable */
const _ = require('underscore');
// This object is a mixin for L.Marker We declare it here as both need to include the contents.
L.BaseLabelMethods = {
  showLeaderline: function () {
    if (this.leaderline && this._map) {
      this.leaderline.setMarkerLatLng(this._latlng);
      this.leaderline._leaflet_id = this._leaflet_id + '_leaderline';
      this._nonPointGroup && this._nonPointGroup.addLayer(this.leaderline);
      if (this._liderlineGroup) {
        if (this._liderlineIsHidden(this.leaderline) && this._liderlineGroup) {
          this._replaceHiddenliderline(this.leaderline);
        } else {
          this._liderlineGroup.addLayer(this.leaderline);
        }
      }
    }

    return this;
  },

  hideLeaderline: function () {
    if (this.leaderline) {
      if (this._nonPointGroup) {
        this._nonPointGroup.removeLayer(this.leaderline);
      }
      if (this._liderlineGroup) {
        this._liderlineGroup.removeLayer(this.leaderline);
      }
    }
    return this;
  },

  bindLeaderline: function (
    options,
    marker,
    liderlineGroup,
    hiddenLiderlineArray,
  ) {
    this._nonPointGroup = this.options.nonPointGroup;
    this._liderlineGroup = liderlineGroup;
    this._hiddenLiderlineArray = hiddenLiderlineArray;

    if (!this.leaderline) {
      this.on('remove', this.hideLeaderline, this)
        .on('move', this._moveLeaderline, this)
        .on('add', this.showLeaderline, this);

      this._hasLeaderlineHandlers = true;
    }

    require('./Leaderline');
    options.label = this;
    if (marker) {
      this.marker = marker;
      marker.on('move', this._moveMarker, this);
      if (marker.type === 'polygon' || marker.type === 'polyline') {
        marker.on('mousedown', this._moveMarker, this);
      }
    }
    this.leaderline = L.leaderline(options);

    return this;
  },

  unbindLeaderline: function () {
    if (this.leaderline) {
      this.hideLeaderline();

      this.leaderline = null;

      if (this._hasLeaderlineHandlers) {
        this.off('remove', this.hideLeaderline, this).off(
          'move',
          this._moveLeaderline,
          this,
        );
      }

      if (this.marker) {
        this.marker.off('move', this._moveMarker, this);
      }

      this._hasLeaderlineHandlers = false;
    }
    return this;
  },

  getLeaderline: function () {
    return this.leaderline;
  },

  _moveMarker: function (e) {
    const latLngs = [];
    if (this.leaderline) {
      const oldLatLngs = this.leaderline.getLatLngs();
      if (oldLatLngs) {
        latLngs.push(e.latlng, ...oldLatLngs.slice(1));
        this.leaderline.setLatLngs(latLngs);
        this.leaderline.setMarkerLatLng(e.latlng);
      }
    }
  },

  _centerChangedHandler: function () {
    if (!this.moved) {
      const center = this.marker.getBounds().getCenter();
      this.setLatLng(center);
    }
  },

  _moveLeaderline: function (e) {
    let latLngs = [];
    if (this.leaderline) {
      const oldLatLngs = this.leaderline.getLatLngs();
      if (oldLatLngs) {
        latLngs = oldLatLngs.slice(0, oldLatLngs.length - 1);
        latLngs.push(e.latlng);
      }
      this.leaderline.setLatLngs(latLngs);
    }
  },

  _liderlineIsHidden(leaderline) {
    return !!_.find(
      this._hiddenLiderlineArray,
      (hiddenLiderline) =>
        hiddenLiderline._leaflet_id === leaderline._leaflet_id,
    );
  },

  _replaceHiddenliderline(leaderline) {
    let index = _.findIndex(
      this._hiddenLiderlineArray,
      (hiddenLiderline) =>
        hiddenLiderline._leaflet_id === leaderline._leaflet_id,
    );
    if (index !== -1) {
      this._hiddenLiderlineArray[index] = leaderline;
    }
  },
};
L.Label.include(L.BaseLabelMethods);
