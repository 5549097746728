/* eslint-disable */
var LayerModel = require('./LayerModel');
var APP = require('../config');
var styleCollections =
  require('../models_collections/styles/styleCollectionsWrapper').singleton();

// A collection of layers
var LayerCollection = Backbone.Collection.extend({
  model: LayerModel,
  comparator: function (model) {
    return model.get('name').toLowerCase();
  },

  url: function () {
    var publishPageUrl =
      '/projects/' + APP.projectId + '/maps/' + APP.mapId + '/layers';
    var mainPageUrl = '/projects/' + APP.projectId + '/layers';

    return APP.apiUrl + (APP.mapId ? publishPageUrl : mainPageUrl);
  },

  create: function (attrs, options = {}) {
    options.wait = true;
    var originalSuccess = options.success;
    var originalError = options.error;

    options.success = successWrapper;

    return Backbone.Collection.prototype.create.call(this, attrs, options);

    // wrap the incoming success with a function that refreshes
    // the styleCollection BEFORE calling the passed in success
    function successWrapper(layer) {
      originalSuccess = originalSuccess || function () {};
      styleCollections.fetch({
        type: attrs.type,
        success: originalSuccess.bind(null, layer),
        error: originalError,
      });
    }
  },

  fetch: function (options = {}) {
    options.cache = false;
    options.wait = true;

    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  containsLayerWithName: function (name) {
    return !!this.iFindWhere('name', name);
  },

  // case insensitive find where
  iFindWhere: function (key, val) {
    return this.filter(function (item) {
      return item.get(key).toLowerCase().trim() === val.toLowerCase().trim();
    })[0];
  },

  containsCustomIcons: function () {
    return this.find(function (layer) {
      return layer.containsCustomIcon();
    });
  },
});

Backbone.addSingletonToCollection(LayerCollection);

module.exports = LayerCollection;
