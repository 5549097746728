import React from 'react';

type ProgressBarProps = {
  header: string;
  complete: number;
  total: number;
};

function ProgressBar(props: ProgressBarProps) {
  const { header, complete, total } = props;
  const completePercent = total === 0 ? 0 : `${(complete / total) * 100}%`;

  return (
    <div className="progress-bar__container">
      <div className="progress-bar__header">
        <div className="title">{header}</div>
        <div className="steps">
          Steps Complete {complete}/{total}
        </div>
      </div>
      <div className="progress-bar__progress">
        <div className="bar" style={{ width: completePercent }} />
      </div>
    </div>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ProgressBar;
