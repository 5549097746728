/* eslint-disable */
const APP = require('./config');

var BaseRouter = Backbone.Marionette.AppRouter.extend({
  /**
   * Override the execute method so it includes basic query string parsing
   * @param callback - called if provided
   * @param args - any args that were originally passed into execute
   */
  execute: function (callback, args) {
    args.push(this._parseQueryString(args.pop()));
    if (callback) {
      callback.apply(this, args);
    }
  },

  // interrupt leaving the page via site navigation
  navigate: function (fragment, options) {
    if (APP.isNavigationBlocked) {
      const continueNavigation = () =>
        Backbone.history.navigate(fragment, options);
      const cancelNavigation = () => false;
      APP.vent.trigger('navigation:blocked', {
        continue: continueNavigation,
        cancel: cancelNavigation,
      });
    } else {
      Backbone.history.navigate(fragment, options);
      return this;
    }
  },

  _parseQueryString: function (queryString) {
    var results = {};

    if (!queryString) {
      return results;
    }

    // split on & to get all key=value parts
    queryString.split('&').forEach(function (keyValueString) {
      if (keyValueString) {
        // split on ='s so we can assign key to value in results object
        var keyValueArray = keyValueString.split('=');
        results[keyValueArray[0]] = keyValueArray[1];
      }
    });

    return results;
  },

  onRoute: function () {
    this._broadcastRouteChange();
  },

  _broadcastRouteChange: function () {
    var APP = require('./config');
    APP.vent.trigger('routeChange');
  },
});

module.exports = BaseRouter;
