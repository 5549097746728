import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createKey = ({
  organizationId,
}: Api.FetchOrganizationTagTypesProps) =>
  ['org-tag-types', organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchOrganizationTagTypes
>;

const fetcher: Fetcher = (_, organizationId) =>
  Api.fetchOrganizationTagTypes({ organizationId });

type UseOrganizationTagTypesProps =
  Partial<Api.FetchOrganizationTagTypesProps> & {
    config?: QueryConfig<Api.FetchOrgTagTypesResponse, Api.ErrorResponse>;
  };

export function useOrganizationTagTypes({
  config: inputConfig,
  organizationId,
}: UseOrganizationTagTypesProps) {
  const key = organizationId ? createKey({ organizationId }) : undefined;
  const config = {
    enabled: !!organizationId,
    ...inputConfig,
  };
  const { data, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    ...queryInfo,
    tagTypes: data,
  };
}
