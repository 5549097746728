var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isTopographyOn) {
      pug_html = pug_html + "\u003Cdiv class=\"leaflet-button\" rel=\"tipsy\" data-toggle=\"tooltip\" data-placement=\"right\" title=\"Toggle map topography\"\u003E\u003Cspan" + (pug.attr("class", pug.classes(["fa","fa-angle-double-up",isTopographyOn ? 'active' : ''], [false,false,true]), false, true)+" id=\"topography-overlay\"") + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isTopographyOn" in locals_for_with ?
        locals_for_with.isTopographyOn :
        typeof isTopographyOn !== 'undefined' ? isTopographyOn : undefined));
    ;;return pug_html;};
module.exports = template;