/* eslint-disable */
let apiCaller = require('../../../../../../../../apiCaller');
let FeaturesInDischargeLocationsLayerCollection = Backbone.Collection.extend({
  url() {
    let APP = require('../../../../../../../../config');
    return apiCaller.getDischargeLocationsUrl(APP.projectId);
  },
});

Backbone.addSingletonToCollection(FeaturesInDischargeLocationsLayerCollection);
module.exports = FeaturesInDischargeLocationsLayerCollection;
