/* eslint-disable */
import APP from '@monorepo/old-web/js/config';
import rootLayoutView from '@monorepo/old-web/js/rootLayoutView';
import ImpersonatingUserView from '@monorepo/old-web/js/user/ImpersonatingUserView';
import impersonateUserListener from '@monorepo/old-web/js/utils/impersonateUserListener';
import _ from 'underscore';
import ReactBackboneRouter from '../../../public/js/routing/ReactBackboneRouter';
import AuthenticationRouter from './authentication/AuthenticationRouter';
import BackboneWrappersRouter from './backboneWrappers/BackboneWrappersRouter';
import SettingDashboardsRouter from './dashboards/settings/SettingDashboardsRouter';
import ErrorRouter from './errorPages/ErrorRouter';
import {
  handleLargeUploadFile,
  stopLargeFileUpload,
} from './largeFileUploader';
import ProjectRouter from './project/ProjectRouter';

const subRouters = {
  authentication: AuthenticationRouter,
  project: ProjectRouter,
  react: ReactBackboneRouter,
  settingDashboards: SettingDashboardsRouter,
  errors: ErrorRouter,
  backboneWrappers: BackboneWrappersRouter,
};

const router = {
  routers: {},

  getSubRouter(name) {
    return this.routers[name];
  },

  start() {
    // interrupt leaving the page via bookmarks or address bar change
    window.addEventListener('beforeunload', (event) => {
      if (APP.isNavigationBlocked) {
        event.preventDefault();
        event.returnValue = 'There are unsaved changes.';
        return event.returnValue;
      }
      delete event.returnValue;
    });
    impersonateUserListener();
    rootLayoutView.impersonating.show(new ImpersonatingUserView());

    _.each(
      subRouters,
      function (Router, routerName) {
        this.routers[routerName] = new Router();
      },
      this,
    );

    // Start Backbone's push-state router
    if (Backbone.history) {
      if (!Backbone.history.start({ pushState: true })) this.goToErrorPage(404);

      // interrupt leaving the page via browser back and forward buttons
      const originalLoad = Backbone.history.loadUrl;
      Backbone.history.loadUrl = function () {
        if (APP.isNavigationBlocked) {
          const continueNavigation = () => originalLoad.apply(this, arguments);
          const cancelNavigation = () => window.history.forward();
          APP.vent.trigger('navigation:blocked', {
            continue: continueNavigation,
            cancel: cancelNavigation,
          });
          return false;
        }
        originalLoad.apply(this, arguments);
      };
    }

    APP.vent.on('layerManagement:startFileUpload', handleLargeUploadFile);
    APP.vent.on('layerManagement:doneFileUpload', stopLargeFileUpload);
  },

  /**
   * Common route helpers
   */
  goToErrorPage(code, message) {
    const oldLocation = window.location.href;
    const url = `/error?code=${code || ''}&message=${encodeURIComponent(
      message,
    )}`;
    this.getSubRouter('errors').navigate(url, { trigger: true });
    window.history.pushState({}, 'Return Url', oldLocation);
  },

  goToLoginPage() {
    this.getSubRouter('authentication').navigate('/login', { trigger: true });
  },

  goToSetRecoveryQuestion() {
    this.getSubRouter('authentication').navigate('/set-recovery-question', {
      trigger: true,
    });
  },

  goToSignUpPage() {
    this.getSubRouter('authentication').navigate('/sign-up', { trigger: true });
  },

  goToLogout() {
    this.getSubRouter('authentication').navigate('/logout', { trigger: true });
  },

  goToForgotPassword() {
    this.getSubRouter('authentication').navigate('/forgot-password', {
      trigger: true,
    });
  },

  goToRootUrl() {
    // '/' would also be in the vortex, but `/vortex` makes clearer that we're navigating to the vortex app now
    window.location.href = `/vortex`;
  },

  goToProjectUrl(organizationId, projectId) {
    const urlParts = ['organizations', organizationId, 'projects', projectId];
    const url = `/${urlParts.join('/')}`;
    this.getSubRouter('project').navigate(url, { trigger: true });
  },

  goToHomepageUrl() {
    window.location = 'http://www.mapistry.com/';
  },

  goToFormSubmissionEditor(options) {
    const {
      projectId,
      templateSlug,
      submissionSlug,
      shouldReplace = false,
      calendarName = null,
      eventDate = null,
      eventNumber = null,
    } = options;
    let url = `/projects/${projectId}/form-submissions/${templateSlug}/${submissionSlug}`;
    if (eventDate) {
      url += `/${calendarName}/${eventDate}/${eventNumber}`;
    } else if (calendarName) {
      url += `/${calendarName}`;
    }
    Backbone.history.navigate(url, { trigger: true, replace: shouldReplace });
  },

  goToMapsUrl(organizationId, projectId) {
    window.top.location.href = `/organizations/${organizationId}/projects/${projectId}`;
  },

  goToProjectDashboard(projectId, organizationId) {
    window.location.href = `/vortex/organizations/${organizationId}/sites/${projectId}/tasks`;
  },

  goToInpsectionsDashboard(projectId, organizationId) {
    window.location.href = `/vortex/organizations/${organizationId}/sites/${projectId}/inspections`;
  },

  goToSettingDashboard(settingSlug, userId, shouldReplace = false) {
    const baseUrl = userId
      ? `/dashboard/user/${userId}/settings`
      : '/dashboard/settings';
    const url = settingSlug ? `${baseUrl}/${settingSlug}` : baseUrl;
    this.getSubRouter('settingDashboards').navigate(url, {
      trigger: true,
      replace: shouldReplace,
    });
  },
};

module.exports = router;
