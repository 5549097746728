/* eslint-disable */
import IntervalFrequency from '../../../../lib/IntervalFrequency';

const moment = require('moment');

let FormListRowView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  className: 'flex align-items-center',
  template: require('./templates/formListRow.pug'),

  templateHelpers() {
    let self = this;
    let model = this.model;
    let isNestedRowView = this.isNestedRowView;
    let apiCaller = require('../../../../apiCaller');
    return {
      downloadPdfUrl: this.model.id
        ? apiCaller.downloadPdfUrl(this.model.id)
        : '',

      getCompleted() {
        return (
          model.get('marked_completed_by_user') ||
          model.get('calculated_complete')
        );
      },

      checkForIcon() {
        return (
          model.get('marked_completed_by_user') ||
          model.get('calculated_complete') ||
          model.get('warnings') ||
          model.get('errors')
        );
      },

      showAsLink() {
        if (!model.has('period_start_date')) {
          return true;
        }

        let date = moment(model.get('period_start_date'), moment.ISO_6801);
        return date < moment();
      },

      getLabel() {
        if (isNestedRowView) {
          if (model.get('interval') === IntervalFrequency.TWICE_WEEK) {
            return self._getTwiceWeeklyLabel();
          }
          if (model.get('interval') === IntervalFrequency.WEEK) {
            return self._getWeeklyLabel();
          }
          if (model.get('period_start_date') != null) {
            return moment(model.get('period_start_date'), 'YYYY-MM-DD').format(
              'MMMM DD',
            );
          }
          const labelMoment = moment(model.get('label'), 'YYYY-MM-DD HHmm');
          if (
            labelMoment.isValid() &&
            !model.get('label').indexOf('Inspection') > -1
          ) {
            return `${labelMoment.format('MMMM DD')} at ${labelMoment.format(
              'HHmm',
            )}`;
          }
        }
        return model.get('label');
      },
    };
  },

  behaviors: {
    Tooltip: {},
  },

  ui: {
    $goToSubmission: '.go-to-submission',
    $errors: '.errors',
    $warnings: '.warnings',
  },

  events: {
    'click @ui.$goToSubmission': '_handleClickGoToSubmission',
  },

  initialize(options = {}) {
    this.isNestedRowView = options.isNestedRowView;
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.isDisabled = options.isDisabled;
  },

  _handleClickGoToSubmission(e) {
    let infoView = require('../../../../modals/infoView').singleton();

    e.preventDefault();

    if (this.isDisabled && this.isDisabled()) {
      return;
    }

    if (this.hasProjectUpdatePermission) {
      this._goToSubmission();
    } else {
      infoView.showModal({
        text: 'You do not have sufficient permissions to edit this observation form.',
      });
    }
  },

  onRender() {
    let makeList = (list) => {
      let messages = _.pluck(list, 'short_message');
      return `<ul class="list-styled"><li> ${messages.join(
        '</li><li>',
      )} </li></ul>`;
    };

    let errors = this.model.get('errors') || [];
    let warnings = this.model.get('warnings') || [];

    this.ui.$errors.popover({ content: makeList(errors) });
    this.ui.$warnings.popover({ content: makeList(warnings) });
  },

  _goToSubmission() {
    let projectId = this.model.get('project_id');
    let formTemplateSlug = this.model.get('form_template_slug');
    const url = apiCaller.getFormSubmissionUrl(
      projectId,
      formTemplateSlug,
      this.model.get('slug'),
    );
    window.top.location.href = url;
  },

  _getWeeklyLabel() {
    const start = moment(this.model.get('period_start_date'));
    const end = start.clone().add(6, 'day');

    return `${start.format('MMMM DD')} - ${end.format('MMMM DD')}`;
  },

  _getTwiceWeeklyLabel() {
    let periodMoment = moment(this.model.get('period_start_date'));
    let startOfWeek = periodMoment.clone().startOf('week');
    let endOfWeek = periodMoment.clone().endOf('week');
    let templateSlug = this.model.get('form_template_slug');
    let inspectionNumber = this.model
      .get('slug')
      .substring(templateSlug.length + 1, templateSlug.length + 2);
    return `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format(
      'MMMM DD',
    )} (${inspectionNumber})`;
  },
});

module.exports = FormListRowView;
