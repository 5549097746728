import { createMemoryHistory } from 'history';

/**
 * The backboneHistory is a custom history object which wraps a MemoryHistory,
 * and replaces methods like push() and back() with custom versions which
 * kick these commands back up to Backbone.history, which is the real source
 * of truth.
 *
 * This allows us to use regular old <NavLink/> and history.push() in React!
 */
const backboneHistory = createMemoryHistory();

backboneHistory.originalPush = backboneHistory.push;

backboneHistory.push = function push(to, state) {
  if (state) {
    throw new Error(
      'replace(to, state) state argument is not supported in custom history',
    );
  }
  const location = typeof to === 'object' ? to : { pathname: to };
  Backbone.history.navigate(backboneHistory.createHref(location), {
    trigger: true,
  });
};

backboneHistory.replace = function replace(to, state) {
  if (state) {
    throw new Error(
      'replace(to, state) state argument is not supported in custom history',
    );
  }
  const location = typeof to === 'object' ? to : { pathname: to };
  Backbone.history.navigate(backboneHistory.createHref(location), {
    trigger: true,
    replace: true,
  });
};

backboneHistory.go = function go(delta) {
  window.history.go(delta);
};

backboneHistory.back = function back() {
  window.history.back();
};

backboneHistory.forward = function forward() {
  window.history.forward();
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default backboneHistory;
