/* eslint-disable */
let _s = require('underscore.string');
let AverageRowView = Backbone.Marionette.ItemView.extend({
  template: false,

  templateHelpers: function () {
    let parameterSlug = this.model.get('slug');
    let samplingUnits = this.sectorData.getSamplingUnits(parameterSlug);
    let sectorData = this.sectorData;
    let samplingUnitsDisplayText = samplingUnits && samplingUnits.display_text;
    let slug = this.model.get('slug');

    return {
      title:
        this.projectModel.get('state') === 'sc' &&
        (slug === 'e_coli' ||
          slug === 'enterococci' ||
          slug === 'fecal_coliform')
          ? 'Geometric Mean'
          : 'Average',
      getAnnualAverageLabel: sectorData.getAnnualAverageLabel
        ? sectorData.getAnnualAverageLabel()
        : '',
      annualAverageSatisfied: () => this._getAnnualAverageSatisfied(),
      getAverageDisplayText: function (value = this._getAverage()) {
        if (!value) {
          return 'N/A';
        }
        return `${value} ${samplingUnitsDisplayText || 'N/A'}`;
      }.bind(this),
      getAverageLimitDisplayText: function (value) {
        if (!value) {
          return 'N/A';
        }
        return value === 'hardness_dependent'
          ? `${_s.humanize(value)}`
          : `${value} ${samplingUnitsDisplayText}`;
      },
      warning: function (value) {
        return value === 'hardness_dependent';
      },
    };
  },

  initialize: function (options = {}) {
    this.sectorData = options.sectorData;
    this.swpppYear = options.swpppYear;
    this.projectModel = options.projectModel;
  },

  _formatLikeLimitValue: function (value) {
    try {
      let valueAsFloat = parseFloat(value);
      let averageLimit = this.sectorData.getAnnualAverageLimit
        ? this.sectorData.getAnnualAverageLimit({
            parameterSlug: this.model.get('slug'),
            hardnessOfReceivingWater: this.projectModel.get(
              'receiving_water_hardness',
            ),
            isReceivingWaterBodySaltWater: this.projectModel.get(
              'receiving_water_is_saltwater',
            ),
            sicCodeSlugs: this.projectModel.getSicCodeSlugs(),
            industrialActivities:
              this.projectModel.getIndustrialActivitySlugs(),
          })
        : null;

      if (averageLimit === null || !(averageLimit && averageLimit.toString)) {
        return _s.numberFormat(valueAsFloat, 3);
      }

      let averageLimitAsString = averageLimit.toString();

      // return if the averageLimit is suspiciously long, or it doesn't have a decimal with numbers after it
      if (
        averageLimitAsString.length > 10 ||
        !/\.\d+$/.test(averageLimitAsString)
      ) {
        return _s.numberFormat(valueAsFloat, 3);
      }

      let numberOfSignificantDigits =
        averageLimitAsString.split('.')[1].length + 1;
      return _s.numberFormat(valueAsFloat, numberOfSignificantDigits);
    } catch (e) {
      return value;
    }
  },
});

module.exports = AverageRowView;
