/* eslint-disable */
let apiCaller = require('../apiCaller');

let PasswordResetView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/passwordReset.pug'),

  templateHelpers: function () {
    let APP = require('../config');
    return {
      loaderSrc: APP.assetsRoot + 'ajax-login-loader.gif',
    };
  },

  ui: {
    $recoveryQuestion: '#recovery-question',
    $password: '#password',
    $passwordConfirmation: '#password-confirmation',
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
    $btnSubmit: '[type=submit]',
    $loader: '.loader',
  },

  behaviors: {
    DisplayMessages: {},
  },

  events: {
    'submit form': '_resetPassword',
  },

  onRender: function () {
    $('html > body').attr('data-view', 'not-authenticated');
  },

  _toggleLoader: function () {
    this.ui.$loader.toggle();
  },

  _resetPassword: function (e) {
    let router = require('../router');
    e.preventDefault();

    let password = this.ui.$password.val().trim();
    let passwordConfirmation = this.ui.$passwordConfirmation.val().trim();
    let token = this.model.get('token');
    let recoveryQuestionAnswer = this.ui.$recoveryQuestion.val().trim();
    let message = 'Your password has been successfully changed.';

    if (password !== passwordConfirmation) {
      this.triggerMethod(
        'ShowError',
        "Your password doesn't match.",
        10 * 1000,
      );
      return;
    }

    this._toggleLoader();
    return apiCaller
      .resetPassword(
        password,
        passwordConfirmation,
        token,
        recoveryQuestionAnswer,
      )
      .then(this.triggerMethod.bind(this, 'ShowSuccess', message))
      .catch((error) => this.triggerMethod('ShowError', error, 10 * 1000))
      .finally(this._toggleLoader.bind(this));
  },
});

module.exports = PasswordResetView;
