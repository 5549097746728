import { activateUsersBulk } from '@monorepo/shared/apiClient/users';
import { Button, Loading } from '@monorepo/shared/components';
import { useToast } from '@monorepo/shared/contexts';
import { useIsMountedRef } from '@monorepo/shared/hooks/useIsMountedRef';
import React, { FormEvent, useCallback, useState } from 'react';
import { FormErrors, SelectCreatable } from '../../elements';

interface Option {
  label: string;
  value: string;
}

interface MultiErrorResponse {
  message: string;
  errors: string[];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isMultiErrorResponse(res: any): res is MultiErrorResponse {
  return typeof res?.message === 'string' && Array.isArray(res?.errors);
}

export function UserBulkActivation() {
  const isMounted = useIsMountedRef();

  const [emailOptions, setEmailOptions] = useState<Option[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<MultiErrorResponse | null>();

  const { success: showSuccessToast } = useToast();

  const handleOnChange = useCallback(
    (newOptions: Option[] | null) => {
      if (!newOptions) {
        setEmailOptions([]);
        return;
      }
      // deduping options, don't allow adding the same exact option multiple times
      const emails = newOptions.reduce((acc: string[], option: Option) => {
        const separatedEmails = option.value
          .split(',')
          .map((email) => email.toLowerCase().trim());
        return [...acc, ...separatedEmails];
      }, []);
      const uniqueEmails = Array.from(new Set(emails));
      const deduppedOptions = uniqueEmails
        .map((email) => ({
          label: email,
          value: email,
        }))
        .sort((a, b) =>
          a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1,
        );
      setEmailOptions(deduppedOptions);
    },
    [setEmailOptions],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSaveError(null);
      setIsSaving(true);

      const emails = emailOptions.map((o) => o.value);
      try {
        await activateUsersBulk(emails);

        if (!isMounted.current) return;

        showSuccessToast(
          'Success! The invites will be sent within 10 min. They will be sent only to users who never received project invite from Mapistry.',
          { autoHideDuration: 20 * 1000 },
        );
        setEmailOptions([]);
        setIsSaving(false);
      } catch (err) {
        if (!isMounted.current) return;

        setIsSaving(false);

        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        const serverError = err?.data;
        if (isMultiErrorResponse(serverError)) {
          setSaveError(serverError);
        } else {
          let message: string;
          if (typeof serverError === 'string') {
            message = serverError;
          } else if (typeof serverError?.message === 'string') {
            message = serverError.message;
          } else {
            message =
              'Unknown error occurred. Please try again later, or contact someone from the Engineering team.';
          }
          setSaveError({
            message,
            errors: [],
          });
        }
      }
    },
    [
      emailOptions,
      setEmailOptions,
      setIsSaving,
      isMounted,
      setSaveError,
      showSuccessToast,
    ],
  );

  return (
    <>
      <h2>Activate Users</h2>
      <p>
        Insert user emails separated by a comma into a text box below. If you
        have a list of emails in a spread sheet, you can use{' '}
        <a
          className="user-bulk-activation__external-link"
          href="https://delim.co/"
          target="_blank"
          rel="noreferrer noopener"
        >
          this website
        </a>{' '}
        , it will insert commas for you.
      </p>
      <p>
        If a user already received an invitation email, it won&apos;t be sent a
        second time. This means that you can safely re-upload the same list of
        emails.
      </p>
      <p>
        Note: comma is used as a separator here instead of usual &quot;|&quot;,
        because &quot;|&quot; is a valid email character but comma is not.
      </p>

      <form onSubmit={handleSubmit}>
        <SelectCreatable
          isMulti
          onChange={handleOnChange}
          options={emailOptions}
          value={emailOptions}
        />
        <div className="user-bulk-activation__button-container">
          <Button
            color="secondary"
            disabled={isSaving || emailOptions.length === 0}
            startIcon={isSaving ? <Loading size="small" /> : undefined}
            type="submit"
          >
            {isSaving ? 'Saving...' : 'Activate users'}
          </Button>
        </div>
      </form>
      {!!saveError && (
        <div className="user-bulk-activation__errors">
          <FormErrors errors={saveError.errors} label={saveError.message} />
        </div>
      )}
    </>
  );
}
