/* eslint-disable */
module.exports = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',
  className: 'rain-log-list list-styled',
  emptyView: require('./NoRainEventsView'),
  getChildView: function () {
    if (this.collection.isGrouped()) {
      return require('./CollapsibleStormEventRowItemView');
    }
    return require('./StormEventRowItemView');
  },
});
