import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';
import { CalendarName } from 'mapistry-shared';
import { Loading } from '@monorepo/shared/components';
import withProvider from '../../../withProvider';
import SamplingResultRecordList from './SamplingResultRecordList';

import APP from '../../../../config';
import { deleteWastewaterSamplingResultAction } from '../../../../actions/wastewater';
import {
  fetchParentIntervalsAction,
  fetchSamplingCalendarEvents,
} from '../../../../actions/calendar';
import { objectToSortedArray } from '../../../../utils/shared';
import { CalendarFrequencyType } from '../../../propTypes';

const SamplingResultRecordListContainer = (props) => {
  const {
    deleteAttachment,
    fetchAttachments,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    fetchIntervalSkeleton,
    isLoading,
    monitoringLocations,
    onOpenResultsEditModal,
    onOpenEditParametersModal,
    samplingResultFrequencies,
    projectId,
    wastewaterParameters,
  } = props;

  useEffect(() => {
    if (projectId) {
      fetchIntervalSkeleton(projectId, CalendarName.WASTEWATER_SAMPLING);
    }
  }, [projectId, fetchIntervalSkeleton]);

  if (isLoading) {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <div style={{ paddingTop: '50px' }}>
        <Loading size="medium" />
      </div>
    );
  }

  return (
    <SamplingResultRecordList
      onFetchAttachment={fetchAttachments}
      onEditAttachment={onOpenResultsEditModal}
      onDeleteAttachment={deleteAttachment}
      monitoringLocations={monitoringLocations}
      editParametersModal={onOpenEditParametersModal}
      samplingResultFrequencies={samplingResultFrequencies}
      projectId={projectId}
      wastewaterParameters={wastewaterParameters}
    />
  );
};

SamplingResultRecordListContainer.defaultProps = {
  samplingResultFrequencies: [],
  projectId: null,
};

SamplingResultRecordListContainer.propTypes = {
  deleteAttachment: PropTypes.func.isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onOpenResultsEditModal: PropTypes.func.isRequired,
  onOpenEditParametersModal: PropTypes.func.isRequired,
  samplingResultFrequencies: PropTypes.arrayOf(CalendarFrequencyType),
  projectId: PropTypes.string,
  wastewaterParameters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => {
  const { calendar, project, wastewater } = state;
  const isLoading =
    wastewater.isFetching?.monitoringLocations ||
    wastewater.isFetching?.samplingParameters ||
    project.isFetching ||
    !project.id ||
    project.id !== APP.projectId;
  return {
    isLoading,
    monitoringLocations: wastewater.monitoringLocations,
    samplingResultFrequencies:
      calendar.frequencies[CalendarName.WASTEWATER_SAMPLING],
    projectId: APP.projectId,
    wastewaterParameters: _.filter(
      objectToSortedArray(wastewater.parameters, 'parameter_slug'),
      (o) => o.parameter_slug !== 'ph',
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteAttachment: (attachment) =>
    dispatch(
      deleteWastewaterSamplingResultAction({
        projectId: APP.projectId,
        samplingResultId: attachment.samplingResultId,
      }),
    ),
  fetchAttachments: (start, end, parameter) =>
    dispatch(
      fetchSamplingCalendarEvents({
        calendarName: CalendarName.WASTEWATER_SAMPLING,
        parameterSlug: parameter.parameter_slug,
        end,
        projectId: APP.projectId,
        start,
      }),
    ),
  fetchIntervalSkeleton: (projectId, calendarName) =>
    dispatch(fetchParentIntervalsAction(projectId, calendarName)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SamplingResultRecordListContainer),
);
