/* eslint-disable react/prop-types */

import React from 'react';
import MarkerError from './MarkerError';
import MarkerComplete from './MarkerComplete';
import MarkerEmpty from './MarkerEmpty';

class Section extends React.Component {
  statusMarker() {
    const { error, isComplete } = this.props;
    if (error) {
      return <MarkerError />;
    }
    if (isComplete) {
      return <MarkerComplete />;
    }
    return <MarkerEmpty />;
  }

  render() {
    const { isSelected, isComplete, name, onClick } = this.props;
    const sectionSelectedClass = isSelected ? ' sidebar-section-selected' : '';
    const sectionCompleteClass = isComplete ? ' section-text-bold' : '';

    return (
      <button
        type="button"
        className={`parent-section-toggle-spacer sidebar-section${sectionSelectedClass}`}
        onClick={onClick}
      >
        {this.statusMarker()}
        <span className={`sidebar-section-text${sectionCompleteClass}`}>
          {name}
        </span>
      </button>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default Section;
