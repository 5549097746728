import { http } from '@monorepo/shared/apiClient';
import {
  OnboardingDocumentRequest,
  OnboardingDocumentStatus,
  OnboardingSession,
} from '../../apiClient/types';

export interface AdminFetchOnboardingSessionVariables {
  organizationId: string;
}

export type AdminFetchOnboardingSessionResponse = OnboardingSession;

export async function adminFetchOnboardingSession(
  variables: AdminFetchOnboardingSessionVariables,
) {
  const { organizationId } = variables;
  const res = await http.get<AdminFetchOnboardingSessionResponse>(
    `/admin/onboarding/${organizationId}`,
  );
  return res.data;
}

export interface AdminGoLiveWithOnboardingSessionVariables {
  organizationId: string;
  live: boolean;
}

export type AdminGoLiveWithOnboardingSessionResponse = void;

export async function adminGoLiveWithOnboardingSession(
  variables: AdminGoLiveWithOnboardingSessionVariables,
) {
  const { organizationId, live } = variables;
  const res = await http.post<AdminGoLiveWithOnboardingSessionResponse>(
    `/admin/onboarding/${organizationId}/go-live`,
    { live },
  );
  return res.data;
}

export interface AdminToggleOnboardingSectionsVariables {
  organizationId: string;
  sections: OnboardingSectionToggleInfo[];
}

export interface OnboardingSectionToggleInfo {
  projectId: string;
  moduleName: string;
  enabled: boolean;
}

export type AdminToggleOnboardingSectionsResponse = void;

export async function adminToggleOnboardingSections(
  variables: AdminToggleOnboardingSectionsVariables,
) {
  const { organizationId, sections } = variables;
  const res = await http.post<AdminToggleOnboardingSectionsResponse>(
    `/admin/onboarding/${organizationId}/toggle-sections`,
    { sections },
  );
  return res.data;
}

export interface AdminCreateOnboardingDocumentRequestVariables {
  organizationId: string;
  documentRequest: AdminCreateOnboardingDocumentRequestValues;
}

export interface AdminCreateOnboardingDocumentRequestValues {
  onboardingSectionId: string;
  title: string;
  notes: string;
}

export type AdminCreateOnboardingDocumentRequestResponse =
  OnboardingDocumentRequest;

export async function adminCreateOnboardingDocumentRequest(
  variables: AdminCreateOnboardingDocumentRequestVariables,
) {
  const { organizationId, documentRequest } = variables;
  const res = await http.post<AdminCreateOnboardingDocumentRequestResponse>(
    `/admin/onboarding/${organizationId}/document-request`,
    documentRequest,
  );
  return res.data;
}

export interface AdminUpdateOnboardingDocumentRequestVariables {
  organizationId: string;
  documentRequestId: string;
  documentRequest: AdminUpdateOnboardingDocumentRequestValues;
}

export interface AdminUpdateOnboardingDocumentRequestValues {
  title?: string;
  notes?: string;
}

export type AdminUpdateOnboardingDocumentRequestResponse =
  OnboardingDocumentRequest;

export async function adminUpdateOnboardingDocumentRequest(
  variables: AdminUpdateOnboardingDocumentRequestVariables,
) {
  const { organizationId, documentRequestId, documentRequest } = variables;
  const res = await http.put<AdminUpdateOnboardingDocumentRequestResponse>(
    `/admin/onboarding/${organizationId}/document-request/${documentRequestId}`,
    documentRequest,
  );
  return res.data;
}

export interface AdminRemoveOnboardingDocumentRequestVariables {
  organizationId: string;
  documentRequestId: string;
}

export type AdminRemoveOnboardingDocumentRequestResponse = void;

export async function adminRemoveOnboardingDocumentRequest(
  variables: AdminRemoveOnboardingDocumentRequestVariables,
) {
  const { organizationId, documentRequestId } = variables;
  const res = await http.delete<AdminRemoveOnboardingDocumentRequestResponse>(
    `/admin/onboarding/${organizationId}/document-request/${documentRequestId}`,
  );
  return res.data;
}

export interface AdminChangeOnboardingDocumentStatusVariables {
  organizationId: string;
  documentRequestId: string;
  status: OnboardingDocumentStatus;
  notes?: string;
}

export type AdminChangeOnboardingDocumentStatusResponse = void;

export async function adminChangeOnboardingDocumentStatus(
  variables: AdminChangeOnboardingDocumentStatusVariables,
) {
  const { organizationId, documentRequestId, status, notes } = variables;
  const res = await http.post<AdminChangeOnboardingDocumentStatusResponse>(
    `/admin/onboarding/${organizationId}/document-request/${documentRequestId}/status`,
    { status, notes },
  );
  return res.data;
}

export interface AdminUploadOnboardingDocumentVariables {
  organizationId: string;
  documentRequestId: string;
  file: File;
}

export type AdminUploadOnboardingDocumentResponse = void;

export async function adminUploadOnboardingDocument(
  variables: AdminUploadOnboardingDocumentVariables,
) {
  const { organizationId, documentRequestId, file } = variables;
  const formData = new FormData();
  formData.append('file', file);
  const res = await http.post<AdminUploadOnboardingDocumentResponse>(
    `/admin/onboarding/${organizationId}/document-request/${documentRequestId}/upload-document`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
}

export interface AdminClearOnboardingDocumentVariables {
  organizationId: string;
  documentRequestId: string;
}

export type AdminClearOnboardingDocumentResponse = void;

export async function adminClearOnboardingDocument(
  variables: AdminClearOnboardingDocumentVariables,
) {
  const { organizationId, documentRequestId } = variables;
  const res = await http.post<AdminClearOnboardingDocumentResponse>(
    `/admin/onboarding/${organizationId}/document-request/${documentRequestId}/clear-document`,
  );
  return res.data;
}
