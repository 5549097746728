var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (errorMessage) {
      pug_html = pug_html + "\u003Cdiv class=\"error-page-container\"\u003E\u003Cdiv class=\"container\"\u003E\u003Cdiv class=\"alert alert-danger\" style=\"display: block\"\u003E\u003Cstrong\u003EOops!\u003C\u002Fstrong\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = ' ' + errorMessage) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cp\u003EIf this is the first time encountering the problem, consider refreshing the page and trying again. Otherwise, please contact the support team for assistance.\u003C\u002Fp\u003E\u003Cp\u003E(800) 553-7420 ext 1 | \u003Ca class=\"mapistry-green dark-green-on-hover\" href=\"mailto:help@mapistry.com\"\u003Ehelp@mapistry.com\u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003Ca class=\"mapistry-link-button primary medium\" href=\"\u002F\"\u003EGo To Mapistry Home\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "errorMessage" in locals_for_with ?
        locals_for_with.errorMessage :
        typeof errorMessage !== 'undefined' ? errorMessage : undefined));
    ;;return pug_html;};
module.exports = template;