var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (active, id, name) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["feature-container",active], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"flex justify-content-space-between align-items-center\"\u003E\u003Cdiv class=\"flex flex-1\"\u003E\u003Clabel" + (" class=\"feature-label\""+pug.attr("id", id, true, true)) + "\u003E\u003Ci class=\"fa fa-eye\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-eye-slash\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"feature-name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex buttons\"\u003E\u003Ca class=\"delete-attr margin-right-10\" rel=\"tipsy\" title=\"Delete feature\"\u003E\u003Csvg class=\"trash small red\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ca class=\"edit-attr\" rel=\"tipsy\" original-title=\"Edit attributes\"\u003E\u003Csvg class=\"icon-edit small white\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "active" in locals_for_with ?
        locals_for_with.active :
        typeof active !== 'undefined' ? active : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;