/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  FieldWrapper,
  LoadingIndicator,
  ToggleSwitch,
} from '@monorepo/old-web/js/components/elements';
import { useSearchParamState } from '@monorepo/shared/hooks/navigation/useSearchParamState';
import { useConfirmDialog } from '@monorepo/shared/hooks/useConfirmDialog';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { OnboardingDocumentStatusFilter } from '../../components/OnboardingDocumentStatusFilter';
import {
  OnboardingDocumentsTableNewDocumentRow as NewDocumentRow,
  OnboardingDocumentsTable,
} from '../../components/OnboardingDocumentsTable';
import {
  OnboardingWizardLayoutActionsHeader as ActionsHeader,
  OnboardingWizardLayout as Layout,
} from '../../components/OnboardingWizardLayout';
import { AdminOnboardingDocumentsPageParams } from '../../navigationTypes';
import { AdminOnboardingApi } from '../apiClient';
import {
  useAdminChangeOnboardingDocumentStatus,
  useAdminClearOnboardingDocument,
  useAdminCreateOnboardingDocumentRequest,
  useAdminGoLiveWithOnboardingSession,
  useAdminOnboardingSession,
  useAdminRemoveOnboardingDocumentRequest,
  useAdminToggleOnboardingSections,
  useAdminUpdateOnboardingDocumentRequest,
  useAdminUploadOnboardingDocument,
} from '../apiClient/hooks';
import {
  OnboardingDocumentsTableAdminDocumentRow as DocumentRow,
  OnboardingDocumentsTableAdminModuleRow as ModuleRow,
  OnboardingDocumentsTableAdminProjectRow as ProjectRow,
} from '../components/OnboardingDocumentsTable';

export function AdminOnboardingDocumentsPage() {
  const { organizationId } = useParams<AdminOnboardingDocumentsPageParams>();

  const {
    onboardingSession,
    isLoading: onboardingSessionIsLoading,
    error: onboardingSessionError,
  } = useAdminOnboardingSession({
    variables: { organizationId },
  });

  const [statusFilter, changeStatusFilter] = useSearchParamState('status');

  const [goLiveMutation] = useAdminGoLiveWithOnboardingSession();
  const [toggleSectionsMutation] = useAdminToggleOnboardingSections();
  const [createDocumentRequestMutation] =
    useAdminCreateOnboardingDocumentRequest();
  const [updateDocumentRequestMutation] =
    useAdminUpdateOnboardingDocumentRequest();
  const [removeDocumentRequestMutation] =
    useAdminRemoveOnboardingDocumentRequest();
  const [changeDocumentStatusMutation] =
    useAdminChangeOnboardingDocumentStatus();
  const [uploadDocumentMutation] = useAdminUploadOnboardingDocument();
  const [clearDocumentMutation] = useAdminClearOnboardingDocument();

  const confirm = useConfirmDialog();

  const handleGoLive = useCallback(
    async (e) => {
      const live = e.target.checked;
      const isConfirmed = await confirm({
        title: !live ? 'Take down' : 'Go live',
        description: !live
          ? 'Customers will no longer be able to access the onboarding session if you do.'
          : 'Customers will be able to access the onboarding sessions once you do.',
        confirmButtonText: !live ? 'Take down' : 'Go live',
      });
      if (isConfirmed) {
        goLiveMutation({
          organizationId,
          live,
        });
      }
    },
    [goLiveMutation, organizationId, confirm],
  );

  const handleToggleSingleSection = useCallback(
    (sectionInfo: AdminOnboardingApi.OnboardingSectionToggleInfo) => {
      toggleSectionsMutation({
        organizationId,
        sections: [sectionInfo],
      });
    },
    [toggleSectionsMutation, organizationId],
  );

  const handleCreateDocumentRequest = useCallback(
    (
      documentRequest: AdminOnboardingApi.AdminCreateOnboardingDocumentRequestValues,
    ) => {
      createDocumentRequestMutation({
        organizationId,
        documentRequest,
      });
    },
    [createDocumentRequestMutation, organizationId],
  );

  const handleUpdateDocumentRequestTitle = useCallback(
    (documentRequestId: string, title: string) => {
      updateDocumentRequestMutation({
        organizationId,
        documentRequestId,
        documentRequest: { title },
      });
    },
    [updateDocumentRequestMutation, organizationId],
  );

  const handleUpdateDocumentRequestNotes = useCallback(
    (documentRequestId: string, notes: string) => {
      updateDocumentRequestMutation({
        organizationId,
        documentRequestId,
        documentRequest: { notes },
      });
    },
    [updateDocumentRequestMutation, organizationId],
  );

  const handleRemoveDocumentRequest = useCallback(
    (documentRequestId: string) => {
      removeDocumentRequestMutation({
        organizationId,
        documentRequestId,
      });
    },
    [removeDocumentRequestMutation, organizationId],
  );

  const handleChangeDocumentRequestStatus = useCallback(
    (
      documentRequestId: string,
      status: AdminOnboardingApi.OnboardingDocumentStatus,
      notes?: string,
    ) => {
      changeDocumentStatusMutation({
        organizationId,
        documentRequestId,
        status,
        notes,
      });
    },
    [changeDocumentStatusMutation, organizationId],
  );

  const handleUploadDocument = useCallback(
    (documentRequestId: string, file: File) => {
      uploadDocumentMutation({
        organizationId,
        documentRequestId,
        file,
      });
    },
    [uploadDocumentMutation, organizationId],
  );

  const handleClearDocument = useCallback(
    (documentRequestId: string) => {
      clearDocumentMutation({
        organizationId,
        documentRequestId,
      });
    },
    [clearDocumentMutation, organizationId],
  );

  if (onboardingSessionIsLoading) {
    return <LoadingIndicator />;
  }
  if (onboardingSessionError || !onboardingSession) {
    return <div>An error occurred, try refreshing your browser</div>;
  }
  return (
    <Layout organizationName={onboardingSession.organizationName}>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <header style={headerStyles}>
        <h1>Admin documents view</h1>
        <FieldWrapper label="Live">
          <ToggleSwitch
            isChecked={onboardingSession.live}
            onChange={handleGoLive}
          />
        </FieldWrapper>
      </header>
      <ActionsHeader>
        <OnboardingDocumentStatusFilter
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          filterValue={statusFilter}
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          onFilterChange={changeStatusFilter}
        />
      </ActionsHeader>
      <OnboardingDocumentsTable
        sections={onboardingSession.sections}
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        statusFilter={statusFilter}
        renderProjectRow={(rowProps) => <ProjectRow {...rowProps} />}
        renderModuleRow={(rowProps) => (
          <ModuleRow
            {...rowProps}
            onToggleSection={handleToggleSingleSection}
          />
        )}
        renderDocumentRow={(rowProps) => (
          <DocumentRow
            {...rowProps}
            onUpdateDocumentRequestTitle={handleUpdateDocumentRequestTitle}
            onUpdateDocumentRequestNotes={handleUpdateDocumentRequestNotes}
            onChangeDocumentRequestStatus={handleChangeDocumentRequestStatus}
            onRemoveDocumentRequest={handleRemoveDocumentRequest}
            onUploadDocument={handleUploadDocument}
            onClearDocument={handleClearDocument}
          />
        )}
        renderNewDocumentRow={(rowProps) => (
          <NewDocumentRow
            {...rowProps}
            onCreateDocumentRequest={handleCreateDocumentRequest}
          />
        )}
      />
    </Layout>
  );
}

const headerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
} as const;
