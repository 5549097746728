import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { SaveState } from '@monorepo/shared/types/SaveState';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, IconButton, SaveButton } from '../../../elements';

export const ContentFooter = (props) => {
  const { isLocked, onClose, onSave, onGoToNextSection, saveState } = props;

  return (
    <div className="main-footer">
      <span className="next-section-button grow">
        <IconButton onClick={onGoToNextSection}>
          <div className="flex items-center next-section-label">
            Next Section
            <SvgIcon identifier="m-arrow-right" />
          </div>
        </IconButton>
      </span>
      <div className="main-actions">
        <Button color="secondary" onClick={onClose}>
          Exit
        </Button>
        <SaveButton
          disabled={isLocked}
          saveState={saveState}
          onSave={onSave}
          saveText="Save Form"
        />
      </div>
    </div>
  );
};

ContentFooter.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onGoToNextSection: PropTypes.func.isRequired,
  saveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
};
