import React, { useMemo } from 'react';
import cn from 'classnames';

interface OnboardingDocumentsCountDisplayProps {
  currentCount: number;
  label: string;
  totalCount: number;
}

export function OnboardingDocumentsCountDisplay(
  props: OnboardingDocumentsCountDisplayProps,
) {
  const { label, currentCount, totalCount } = props;

  const countRatio = currentCount / totalCount;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  const arcStartBorderFill = useMemo(() => {
    if (countRatio === 0 || totalCount === 0) return 'transparent';
    if (countRatio < 0.5) {
      return 'currentColor transparent transparent transparent';
    }
    if (countRatio < 0.75) {
      return 'currentColor currentColor transparent transparent';
    }
    if (countRatio < 1) {
      return 'currentColor currentColor currentColor transparent';
    }
    if (countRatio === 1) return 'currentColor';
  }, [countRatio, totalCount]);

  const arcStartStyles = useMemo(
    () => ({ borderColor: arcStartBorderFill }),
    [arcStartBorderFill],
  );

  const complete = countRatio === 1;
  return (
    <div
      className={cn('onboarding-documents-count-display', {
        complete,
      })}
    >
      <span className="count">
        {currentCount}/{totalCount}
      </span>
      <span className="count-label">{label}</span>
      <div className="arc total" />
      <div className="arc current" style={arcStartStyles} />
    </div>
  );
}
