/* eslint-disable */
let moment = require('moment');
let P = require('bluebird');

let dateService = require('../../../../../../../../services/dateService');
let DocumentCollection = require('../../../../../../../../models_collections/documentCollection');
let RainLogPhotosItemView = require('./RainLogPhotosLayoutView');

var StormEventUpdateModalView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/modal.pug'),

  regions: {
    rainLogPhotos: '#rain-log-photos',
  },

  templateHelpers() {
    let stormEvent = this.model;
    let rainfallAmt =
      stormEvent && stormEvent.has('rainfall_amt')
        ? stormEvent.get('rainfall_amt')
        : '';
    let notes =
      stormEvent && stormEvent.has('notes') ? stormEvent.get('notes') : '';
    let confirmed =
      stormEvent && stormEvent.has('confirmed')
        ? stormEvent.get('confirmed')
        : null;
    let userGenerated =
      stormEvent && stormEvent.has('user_generated')
        ? stormEvent.get('user_generated')
        : null;

    let stormStartTime =
      stormEvent &&
      stormEvent.has('storm_start_time') &&
      moment(stormEvent.get('storm_start_time')).format('YYYY-MM-DD HH:mm');
    let stormEndTime =
      stormEvent &&
      stormEvent.has('storm_end_time') &&
      moment(stormEvent.get('storm_end_time')).format('YYYY-MM-DD HH:mm');

    return {
      stormStartTime: stormStartTime ? stormStartTime : '',
      stormEndTime: stormEndTime ? stormEndTime : '',
      notes,
      rainfallAmt,
      updatingStormEvent: !!stormEvent,
      confirmed,
      userGenerated,
      isStormwaterComplianceEnabled:
        this.projectModel.isStormwaterComplianceEnabled(),
    };
  },

  ui: {
    $modal: '.rain-event-create-update-delete-modal',
    $datetimepicker: '[data-role="datetimepicker"]',
    delete: '.delete-rain-event',
    $didSamplingNo: '.did-sampling-no',
    $didSamplingYes: '.did-sampling-yes',
    $errorContainer: '.alert-danger',
    $samplingGroup: '.sampling-group',
    $samplingGroupErrorText: '.sampling-group .error-text',
    $rainfallGroup: '.rainfall-group',
    $rainfallGroupErrorText: '.rainfall-group .error-text',
    $startTimeGroup: '.start-time-group',
    $timeErrorText: '.time-error-text',
    $endTimeGroup: '.end-time-group',
    $btnSubmit: 'button.btn-submit',
  },

  events: {
    'click .btn-submit': '_saveStormEvent',
    'click .mark-confirmed-rain-event': '_markConfirmed',
    'click .mark-unconfirmed-rain-event': '_markUnconfirmed',
    'hidden.bs.modal': '_checkForZombiePhotos',
  },

  behaviors: {
    EnableDatetimepicker: {},
    DisplayMessages: {},
    WarnBeforeDelete: {},
    DisplayLoader: {},
  },

  initialize(options = {}) {
    this.rainLogCollection = options.rainLogCollection;
    this.projectId = options.projectId;
    this.projectModel = options.projectModel;
  },

  getWarningBeforeDeleteText() {
    return ' this rain event?';
  },

  onRender() {
    if (this.model) {
      this.listenToOnce(this.model, 'destroy', this.hideModal.bind(this));
    }
  },

  showModal(model) {
    this.model = model;
    this.render();

    if (this.model) {
      let didSampling =
        this.model.has('did_sampling') && this.model.get('did_sampling');
      this.ui.$didSamplingYes.prop('checked', didSampling);
      this.ui.$didSamplingNo.prop('checked', !didSampling);
    }

    return this.showPhotos().then(() => this.ui.$modal.modal('show'));
  },

  showPhotos() {
    this.photoList = new DocumentCollection([], {
      projectId:
        this.model && this.model.has('project_id')
          ? this.model.get('project_id')
          : this.projectId,
      folderSlug: this._getPhotosFolderSlug(),
    });

    return this.photoList.fetchPromise().then(() => {
      let rainLogPhotosItemView = new RainLogPhotosItemView({
        photoList: this.photoList,
      });
      this.listenTo(
        rainLogPhotosItemView,
        'ShowLoader',
        this.triggerMethod.bind(this, 'ShowLoader'),
      );
      this.listenTo(
        rainLogPhotosItemView,
        'ResetLoader',
        this.triggerMethod.bind(this, 'ResetLoader'),
      );
      this.rainLogPhotos.show(rainLogPhotosItemView);
    });
  },

  hideModal() {
    this.ui.$modal.modal('hide');
  },

  _getPhotosFolderSlug(model = this.model) {
    return 'rain-log-photos-' + (model ? model.id : this.cid);
  },

  _saveStormEvent() {
    this.triggerMethod.bind(this, 'ShowLoader');
    let data = this.$('form').serializeObject();
    data.rainfall_amt = parseFloat(data.rainfall_amt);
    if (!this._validateData(data)) {
      return;
    }

    data.storm_start_time = this._getFormattedDateTime(data.storm_start_time);
    data.storm_end_time = this._getFormattedDateTime(data.storm_end_time);
    data.project_id =
      this.model && this.model.has('project_id')
        ? this.model.get('project_id')
        : this.projectId;

    let createNewStormEvent = !this.model;
    let saveOrCreate = !createNewStormEvent
      ? this.model.savePromise(data)
      : this.rainLogCollection.createPromise(data, { wait: true });
    return saveOrCreate
      .bind(this)
      .then((model) => {
        this.model = model;
        if (
          createNewStormEvent &&
          this.photoList.models &&
          this.photoList.models.length
        ) {
          return P.each(this.photoList.models, (photo) =>
            photo.save({ folder_slug: this._getPhotosFolderSlug(model) }),
          );
        }
      })
      .then(this.hideModal.bind(this))
      .catch(
        this.triggerMethod.bind(
          this,
          'ShowError',
          'There was an error saving your rain event.',
        ),
      )
      .finally(this.triggerMethod.bind(this, 'ResetLoader'));
  },

  // If a new storm event was being created, but it wasn't successful, delete any photos
  _checkForZombiePhotos() {
    if (this.photoList.models && this.photoList.models.length && !this.model) {
      return P.each(this.photoList.models, (photo) => photo.destroy());
    }
  },

  _validateData(data) {
    let validTime = this._validateStormStartAndEndTime(data);
    let validSample = this._validateSamplingTakenField(data);
    let validRainfall = this._validateRainfallAmt(data);

    return validTime && validSample && validRainfall;
  },

  _validateStormStartAndEndTime(data) {
    let timeErrorMsg = '';
    if (!dateService.isValidDateTime(data.storm_start_time)) {
      this.ui.$startTimeGroup.addClass('has-error');
      timeErrorMsg =
        'The storm start time is required and must be valid date and time. ';
    } else {
      this.ui.$startTimeGroup.removeClass('has-error');
    }

    if (!dateService.isValidDateTime(data.storm_end_time)) {
      this.ui.$endTimeGroup.addClass('has-error');
      timeErrorMsg +=
        'The storm end time is required and must be valid date and time. ';
    } else {
      this.ui.$endTimeGroup.removeClass('has-error');
    }

    if (
      !timeErrorMsg &&
      moment(data.storm_start_time).isAfter(data.storm_end_time)
    ) {
      this.ui.$startTimeGroup.addClass('has-error');
      timeErrorMsg += 'The storm start time must be before the storm end time';
    }

    this.ui.$timeErrorText.text(timeErrorMsg);
    return !timeErrorMsg;
  },

  _validateSamplingTakenField(data) {
    if (
      this.projectModel.isStormwaterComplianceEnabled() &&
      !data.did_sampling
    ) {
      this.ui.$samplingGroup.addClass('has-error');
      this.ui.$samplingGroupErrorText.text('This field is required.');
      return false;
    }

    this.ui.$samplingGroup.removeClass('has-error');
    this.ui.$samplingGroupErrorText.text('');
    return true;
  },

  _validateRainfallAmt(data) {
    let rainfallAmt = data.rainfall_amt;
    if (!_.isNumber(rainfallAmt) || _.isNaN(rainfallAmt) || rainfallAmt < 0) {
      this.ui.$rainfallGroup.addClass('has-error');
      this.ui.$rainfallGroupErrorText.text(
        'This field is required. It must be a number greater than 0.',
      );
      return false;
    }

    this.ui.$rainfallGroup.removeClass('has-error');
    this.ui.$rainfallGroupErrorText.text('');
    return true;
  },

  _getFormattedDateTime(dateTime) {
    return moment(dateTime).format();
  },

  _markConfirmed() {
    return this.model
      .save({ confirmed: true })
      .then(this._saveStormEvent.bind(this));
  },

  _markUnconfirmed() {
    return this.model
      .save({ confirmed: false })
      .then(this.hideModal.bind(this));
  },
});

module.exports = StormEventUpdateModalView;
