import React from 'react';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/CheckCircle';

type OnboardingWizardLayoutProps = {
  children: React.ReactNode;
  organizationName: string;
};

export function OnboardingWizardLayout(props: OnboardingWizardLayoutProps) {
  const { children, organizationName } = props;
  return (
    <div className="onboarding-wizard-layout">
      <div className="layout-sidebar">
        <h3>{organizationName}</h3>
        <ul className="steps-list">
          <li>
            <CheckIcon className="step-icon complete" />
            <span>Set up your sites</span>
          </li>
          <li>
            <CheckIcon className="step-icon complete" />
            <span>Add site modules</span>
          </li>
          <li>
            <CheckIcon className="step-icon complete" />
            <span>Add users</span>
          </li>
          <li>
            <div className="step-icon number">
              <span>4</span>
            </div>
            <span>Add documents</span>
            <ArrowIcon className="arrow-icon" />
          </li>
        </ul>
        <div className="gradient-shape first" />
        <div className="gradient-shape second" />
        <div className="gradient-shape third" />
      </div>
      <div className="layout-content">{children}</div>
    </div>
  );
}
