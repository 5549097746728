var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isChecked, name) {
      pug_html = pug_html + "\u003Cdiv class=\"map-view\"\u003E\u003Cinput" + (" type=\"radio\" name=\"map-views\""+pug.attr("checked", isChecked(), true, true)) + "\u003E\u003Clabel\u003E\u003Ci class=\"fa fa-eye\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-eye-slash\"\u003E\u003C\u002Fi\u003E\u003Cspan class=\"map-view-name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cdiv class=\"pull-right buttons padding-right-20\"\u003E\u003Ca class=\"delete margin-right-10\" href=\"#\" rel=\"tipsy\" title=\"Delete map\"\u003E\u003Csvg class=\"trash small\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ca class=\"edit\" href=\"#\" rel=\"tipsy\" title=\"Edit map name\"\u003E\u003Csvg class=\"edit small white\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isChecked" in locals_for_with ?
        locals_for_with.isChecked :
        typeof isChecked !== 'undefined' ? isChecked : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;