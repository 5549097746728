import {
  CustomFrequencyFieldKeys,
  customFrequencyFieldSettings,
  parseCustomFrequency,
} from '@monorepo/old-web/js/components/wastewater/SamplingResultRecordPanel/ParameterSettingsUploadModal/CustomFrequencyFileUploadHelper';
import { saveWastewaterParameterSettingsBulk } from '@monorepo/shared/apiClient/water/wastewaterSamplingResults';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getNumericColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import {
  validatePositiveNumber,
  validateValueOrNull,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useMapFeatures } from '@monorepo/shared/hooks/useMapFeatures';
/* @ts-expect-error expected invalid values */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
/* @ts-expect-error expected invalid values */
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
import {
  CreateWastewaterSettingsBulk,
  IntervalFrequencyFactory,
  waterTags,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { NON_CUSTOM_FREQUENCIES } from '../EditParametersModal/FrequencyPicker';
import {
  calculationMethodologyOptions,
  samplingTypeOptions,
} from '../EditParametersModal/ParameterLimitGroupRow';
import { validateParameterSettingUnits } from './parameterSettingValidators';

const uploadFieldKeys = {
  calculationMethodology: 'calculationMethodology',
  frequency: 'frequency',
  limitLower: 'limitLower',
  limitUpper: 'limitUpper',
  monitoringLocationId: 'monitoringLocationId',
  parameterSlug: 'parameterSlug',
  samplingType: 'samplingType',
  units: 'units',
  ...CustomFrequencyFieldKeys,
} as const;

const parameterOptions = SamplingParameters.getAll().map(
  (p: {
    display_text: string;
    slug: string;
    alternative_spelling?: string[];
  }) => ({
    label: p.display_text,
    value: p.slug,
    alternativeNames: p.alternative_spelling,
  }),
);

const unitOptions = SamplingUnits.getAll().map(
  (u: {
    display_text: string;
    slug: string;
    alternative_spelling?: string[];
  }) => ({
    label: u.display_text,
    value: u.slug,
    alternativeNames: u.alternative_spelling,
  }),
);
const frequencyOptions = NON_CUSTOM_FREQUENCIES.map((f) => ({
  value: f,
  label: IntervalFrequencyFactory.For(f).toString(),
}));

type ParameterSettingsUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
};

export function ParameterSettingsUploadModal(
  props: ParameterSettingsUploadModalProps,
) {
  const { isOpen, onClose, projectId } = props;

  const { isLoading, mapFeatures } = useMapFeatures({
    projectId,
    tags: waterTags,
  });
  const locationOptions = useMemo(
    () =>
      mapFeatures
        ?.filter((f) => !f.sampleType && !f.parentId) // QA locations are only for sampling results
        .map((f) => ({
          label: f.name,
          value: f.id,
        })) || [],
    [mapFeatures],
  );

  const workbook = useMemo<WorkbookConfig>(
    () => ({
      name: 'Upload parameter settings',
      sheets: [
        {
          name: 'Parameter settings',
          slug: 'upload-parameter-settings',
          fields: [
            getSingleSelectColumnSetting(
              {
                label: 'Water discharge location',
                key: uploadFieldKeys.monitoringLocationId,
                isRequired: true,
              },
              locationOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Parameter',
                key: uploadFieldKeys.parameterSlug,
                isRequired: true,
              },
              parameterOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Frequency',
                key: uploadFieldKeys.frequency,
              },
              frequencyOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Sampling type',
                key: uploadFieldKeys.samplingType,
              },
              samplingTypeOptions,
            ),
            getSingleSelectColumnSetting(
              {
                label: 'Calculation methodology',
                key: uploadFieldKeys.calculationMethodology,
              },
              calculationMethodologyOptions,
            ),
            getTextColumnSetting({
              label: 'Upper limit',
              key: uploadFieldKeys.limitUpper,
            }),
            getNumericColumnSetting({
              label: 'Lower limit',
              key: uploadFieldKeys.limitLower,
            }),
            getSingleSelectColumnSetting(
              {
                label: 'Units',
                key: uploadFieldKeys.units,
              },
              unitOptions,
            ),
            ...customFrequencyFieldSettings,
          ],
        },
      ],
    }),
    [locationOptions],
  );

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(async (record) => {
    // Internal artifact: if CX team has "Report" as limit value, it's considered to be not defined
    if (record.get(uploadFieldKeys.limitUpper) === 'Report') {
      record.set(uploadFieldKeys.limitUpper, null);
    }
    validatePositiveNumber(uploadFieldKeys.limitUpper, record);
    validatePositiveNumber(uploadFieldKeys.limitLower, record);

    validateParameterSettingUnits(
      uploadFieldKeys.units,
      record,
      record.get(uploadFieldKeys.parameterSlug),
    );
    // DB expects one of "composite" or "grab" or null value, not an empty string
    validateValueOrNull(uploadFieldKeys.samplingType, record);
    validateValueOrNull(uploadFieldKeys.limitLower, record);
    validateValueOrNull(uploadFieldKeys.limitUpper, record);

    parseCustomFrequency(record, uploadFieldKeys.frequency);
  }, []);

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: CreateWastewaterSettingsBulk[]) => {
      await saveWastewaterParameterSettingsBulk(projectId, results);

      return `
        Successfully uploaded parameter settings!
        Now you can see your data in "Water: Sampling Results" Records section.
      `;
    },
    [projectId],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ projectId }),
    [projectId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      workbook={workbook}
    />
  );
}
