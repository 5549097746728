var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (url) {
      pug_html = pug_html + "\u003Cimg" + (pug.attr("src", url, true, true)+" alt=\"Image could not load\"") + "\u003E";
    }.call(this, "url" in locals_for_with ?
        locals_for_with.url :
        typeof url !== 'undefined' ? url : undefined));
    ;;return pug_html;};
module.exports = template;