var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (checkForIcon, downloadPdfUrl, errors, getCompleted, getLabel, id, showAsLink, warnings) {
      if (checkForIcon()) {
pug_html = pug_html + "\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (errors && errors.length) {
pug_html = pug_html + "\u003Csvg class=\"errors warning-red fire\" data-trigger=\"hover\" data-html=\"true\" data-title=\"Action Required\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (warnings && warnings.length) {
pug_html = pug_html + "\u003Csvg class=\"warnings warning-yellow exclamation\" data-trigger=\"hover\" data-html=\"true\" data-title=\"Notice\"\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (getCompleted()) {
pug_html = pug_html + "\u003Csvg class=\"check\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if (showAsLink()) {
pug_html = pug_html + "\u003Ca class=\"go-to-submission\"\u003E" + (pug.escape(null == (pug_interp = getLabel()) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getLabel()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
if (id) {
pug_html = pug_html + "\u003Ca" + (" class=\"download-pdf flex\""+pug.attr("href", downloadPdfUrl, true, true)+" target=\"_blank\" rel=\"tipsy\" title=\"Download PDF\" style=\"padding-right: 20px; margin-left: auto\"") + "\u003E\u003Csvg class=\"download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
    }.call(this, "checkForIcon" in locals_for_with ?
        locals_for_with.checkForIcon :
        typeof checkForIcon !== 'undefined' ? checkForIcon : undefined, "downloadPdfUrl" in locals_for_with ?
        locals_for_with.downloadPdfUrl :
        typeof downloadPdfUrl !== 'undefined' ? downloadPdfUrl : undefined, "errors" in locals_for_with ?
        locals_for_with.errors :
        typeof errors !== 'undefined' ? errors : undefined, "getCompleted" in locals_for_with ?
        locals_for_with.getCompleted :
        typeof getCompleted !== 'undefined' ? getCompleted : undefined, "getLabel" in locals_for_with ?
        locals_for_with.getLabel :
        typeof getLabel !== 'undefined' ? getLabel : undefined, "id" in locals_for_with ?
        locals_for_with.id :
        typeof id !== 'undefined' ? id : undefined, "showAsLink" in locals_for_with ?
        locals_for_with.showAsLink :
        typeof showAsLink !== 'undefined' ? showAsLink : undefined, "warnings" in locals_for_with ?
        locals_for_with.warnings :
        typeof warnings !== 'undefined' ? warnings : undefined));
    ;;return pug_html;};
module.exports = template;