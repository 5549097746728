/* eslint-disable */
var _ = require('underscore');
var APP = require('../config');
const BluebirdPromise = require('bluebird');

var bing = {
  _baseURL: 'https://dev.virtualearth.net/REST/v1/',
  _elevationCache: {},

  /**
   *
   * Return:
   *      A promise that resolves with elevation: integer
   *          Approximate elevation in meters of lat/lng
   *
   */
  getElevation: function (lat, lng) {
    var latLng = [lat, lng].join(',');
    var cachedElevation = this._elevationCache[latLng];

    if (cachedElevation) {
      return BluebirdPromise.resolve(cachedElevation);
    }

    var query = { points: latLng, key: APP.bingApiKey };
    var url = this._baseURL + 'Elevation/List';
    var reqPromise = $.ajax({
      url: url,
      dataType: 'jsonp',
      data: query,
      jsonp: 'jsonp',
    });

    return BluebirdPromise.resolve(reqPromise)
      .bind(this)
      .then(function (response) {
        var elevation =
          (response &&
            response.resourceSets &&
            response.resourceSets[0] &&
            response.resourceSets[0].resources &&
            response.resourceSets[0].resources[0] &&
            response.resourceSets[0].resources[0].elevations &&
            response.resourceSets[0].resources[0].elevations[0]) ||
          null;

        if (_.isNumber(elevation)) {
          this._elevationCache[latLng] = elevation;
        }
        return elevation;
      });
  },
};

module.exports = bing;
