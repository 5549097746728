/* eslint-disable */
let moment = require('moment');

let SpccUpdateModalView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/modal.pug'),

  templateHelpers() {
    let spccUpdate = this.model;
    let dateUpdated = spccUpdate && spccUpdate.get('date_updated');
    let formattedDateUpdated = moment(new Date(dateUpdated))
      .utc()
      .format('MM/DD/YYYY');
    let dateSubmittedToGovernment =
      spccUpdate && spccUpdate.get('date_submitted_to_government');
    let formattedDateSubmittedToGovernment = moment(
      new Date(dateSubmittedToGovernment),
    ).format('MM/DD/YYYY');
    let preparer =
      spccUpdate && spccUpdate.has('preparer')
        ? spccUpdate.get('preparer')
        : '';
    let reason =
      spccUpdate && spccUpdate.has('reason') ? spccUpdate.get('reason') : '';
    let section =
      spccUpdate && spccUpdate.has('section') ? spccUpdate.get('section') : '';

    return {
      formattedDateUpdated: dateUpdated ? formattedDateUpdated : '',
      formattedDateSubmittedToGovernment: dateSubmittedToGovernment
        ? formattedDateSubmittedToGovernment
        : '',
      getTitle: this._getTitle.bind(this),
      getPreparer: () => preparer,
      getReason: () => reason,
      getSection: () => section,
      projectState: this.projectState,
      title: this.title,
    };
  },

  ui: {
    $dateInput: '.input-group.date input',
    $notesInput: 'textarea[name=update_notes]',
    $datepicker: '.input-group.date',
    $preparer: 'input#preparer',
    $section: 'input#section',
    $reason: 'input#reason',
    $modal: '.modal',
  },

  events: {
    'click .btn-submit': '_saveSpccUpdate',
  },

  behaviors: {
    EnableDatepicker: {},
  },

  initialize(options = {}) {
    this.projectState = options.projectState;
    this.title = options.title || 'SPCC';
  },

  showModal() {
    this.ui.$modal.modal('show');
  },

  hideModal() {
    this.ui.$modal.modal('hide');
  },

  _getSerializedData() {
    let data = this.$('form').serializeObject();
    let dateUpdated = data.date_updated;
    let dateSubmittedToGovernment = data.date_submitted_to_government;

    data.date_updated = this._getFormattedDate(dateUpdated);
    data.date_submitted_to_government = this._getFormattedDate(
      dateSubmittedToGovernment,
    );
    return data;
  },

  _saveSpccUpdate: function () {
    if (this.model) {
      this.model
        .savePromise(this._getSerializedData(), { wait: true })
        .then(this.hideModal.bind(this));
    } else {
      this.collection
        .createPromise(this._getSerializedData(), { wait: true })
        .then(this.hideModal.bind(this));
    }
  },

  _getTitle() {
    return this.title + ' Updates';
  },

  _enableDateAndNotes() {
    this.ui.$dateInput.prop('disabled', false);
    this.ui.$notesInput.prop('disabled', false);
    this.ui.$preparer.prop('disabled', false);
    this.ui.$section.prop('disabled', false);
    this.ui.$reason.prop('disabled', false);
  },

  _disableDateAndNotes() {
    this.ui.$dateInput.prop('disabled', true);
    this.ui.$notesInput.prop('disabled', true);
    this.ui.$preparer.prop('disabled', true);
    this.ui.$section.prop('disabled', true);
    this.ui.$reason.prop('disabled', true);
  },

  _getFormattedDate(dateUpdated) {
    let dateService = require('../../../../../../services/dateService');
    let isValidDate = dateService.isValidDate(dateUpdated);

    return isValidDate
      ? dateService.prettifyDateNotInUTCYet(dateUpdated)
      : null;
  },
});

module.exports = SpccUpdateModalView;
