var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (rotation, url) {
      pug_html = pug_html + "\u003Ca class=\"delete-feature-photo\"\u003E\u003Ci class=\"fa fa-times-circle-o fa-2x\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Cimg" + (pug.attr("class", pug.classes(["feature-photo-image",'rotate-' + rotation], [false,true]), false, true)+pug.attr("src", url, true, true)+" alt=\"failed to load image\"") + "\u003E";
    }.call(this, "rotation" in locals_for_with ?
        locals_for_with.rotation :
        typeof rotation !== 'undefined' ? rotation : undefined, "url" in locals_for_with ?
        locals_for_with.url :
        typeof url !== 'undefined' ? url : undefined));
    ;;return pug_html;};
module.exports = template;