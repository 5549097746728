/* eslint-disable */
// Inspired by tutorial here: https://devcenter.heroku.com/articles/s3-upload-node
// and this code: https://github.com/flyingsparx/s3upload-coffee-javascript
const BluebirdPromise = require('bluebird');

var S3Upload = function (options = {}) {
  if (!options.S3PutSignedUrl) {
    throw new Error('S3PutSignedUrl is required');
  }
  if (!options.fileSelector) {
    throw new Error('fileSelector is required');
  }
  if (!options.onProgress) {
    throw new Error('onProgress is required');
  }
  if (!options.projectId) {
    throw new Error('projectId is required');
  }
  if (!options.mapId) {
    throw new Error('mapId is required');
  }
  if (!options.fileFormat) {
    throw new Error('fileFormat is required');
  }

  _.extend(this, options);
};

_.extend(S3Upload.prototype, {
  uploadFiles: function () {
    this.onProgress(0);
    return BluebirdPromise.map(
      this._filesToArray(),
      function (file) {
        return this.uploadFile(file);
      }.bind(this),
    );
  },

  uploadFile: function (file) {
    return this._executeOnSignedUrl(file).then(
      this._uploadToS3.bind(this, file),
    );
  },

  _filesToArray: function () {
    var files = this.fileSelector.files;
    var filesArray = [];

    for (var i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }

    return filesArray;
  },

  _executeOnSignedUrl: function (file) {
    return new BluebirdPromise(
      function (resolve, reject) {
        $.get(this.S3PutSignedUrl, {
          objectType: file.type,
          projectId: this.projectId,
          mapId: this.mapId,
          filename: file.name,
          fileType: this.fileFormat,
        })
          .done(resolve)
          .fail(function (jqXHR) {
            reject(jqXHR.responseText);
          });
      }.bind(this),
    );
  },

  _uploadToS3: function (file, signedUrlResponse) {
    var url = signedUrlResponse.signedUrl;
    var key = signedUrlResponse.key;

    return new BluebirdPromise(
      function (resolve, reject) {
        $.ajax({
          async: true,
          contentType: file.type,
          data: file,

          // Header indicates that the file is should be uploaded as private file
          // that can only be accessed by those with the appropriate AWS credentials
          headers: { 'x-amz-acl': 'private' },

          // Because the data object is a file, setting processData to false is
          // necessary to prevent the data from being processed and transformed
          // into a query string
          processData: false,
          xhrFields: {
            withCredentials: false,
          },
          type: 'PUT',
          url: url,
          xhr: this._xhr.bind(this),
        })
          .done(
            function () {
              this.onProgress.bind(this, 100);
              resolve(key);
            }.bind(this),
          )
          .fail(function (jqXHR) {
            reject(jqXHR.responseText);
          });
      }.bind(this),
    );
  },

  _xhr: function () {
    //http://stackoverflow.com/questions/15668339/can-onprogress-functionality-be-added-to-jquery-ajax-by-using-xhrfields
    var xhr = $.ajaxSettings.xhr();

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        this.onProgress(Math.round((e.loaded / e.total) * 100));
      }
    }.bind(this);

    return xhr;
  },
});

module.exports = S3Upload;
