import { CircularProgress } from '@material-ui/core';
import cn from 'classnames';
import { format } from 'date-fns';
import { localEquivalentOfUTC } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import APP from '../../../../config';
import Menu from '../../../elements/Menu';
import { MoreHoriz } from '../../../elements/MuiIcon';
import { FormSubmissionLockInfo } from './FormSubmissionLockInfo';

export class ContentHeading extends React.Component {
  modeText() {
    const { isStencil, eventDate } = this.props;
    if (isStencil) {
      return 'template for';
    }
    const localEventDate = localEquivalentOfUTC(eventDate);
    const dueText = localEventDate
      ? `inspection due ${format(localEventDate, 'MMM dd, yyyy')}`
      : '';
    return `Editing ${dueText}`;
  }

  sectionTitle() {
    const { error, sectionName, subsectionName } = this.props;
    if (error) {
      return 'Error';
    }
    if (sectionName == null) {
      return 'Loading...';
    }
    if (subsectionName) {
      return `${sectionName} - ${subsectionName}`;
    }
    return sectionName;
  }

  renderLastUpdated() {
    const { isStencil, updatedAt, updatedBy } = this.props;

    if (!updatedAt) return null;

    const updatedAtDate = new Date(updatedAt);
    return (
      <div
        className={cn('main-updated-at', {
          'main-updated-at--stencil': isStencil,
        })}
      >
        Last updated on {format(updatedAtDate, 'M/d/yyyy h:mm aa')}
        {updatedBy && ` by ${updatedBy}`}
      </div>
    );
  }

  render() {
    const {
      currentUser,
      disableDownload,
      disableDelete,
      formTemplateId,
      isStencil,
      lockInfo,
      onDownload,
      onDelete,
      requestPageReloadBeforeYieldingFormLock,
      showDownloadingProgress,
    } = this.props;

    const options = [
      {
        label: 'Email PDF',
        disabled: disableDownload,
        onClick: onDownload,
        iconIdentifier: 'download',
      },
      // eslint-disable-next-line camelcase
      ...(currentUser?.is_super_admin && formTemplateId
        ? [
            {
              label: 'Admin Form Editor',
              onClick: () => {
                const url = `${APP.adminAppUrl}/form-templates/${formTemplateId}`;
                window.open(url);
              },
              iconIdentifier: 'cog',
            },
          ]
        : []),
      {
        isDivider: true,
        key: 'divider-1',
      },
      {
        label: 'Discard and Close',
        disabled: disableDelete,
        onClick: onDelete,
        iconIdentifier: 'trash',
        danger: true,
      },
    ];

    return (
      <div className={cn('main-header', { 'main-header--stencil': isStencil })}>
        <div className="header-content">
          <FormSubmissionLockInfo
            isStencil={isStencil}
            lockInfo={lockInfo}
            currentUserId={currentUser?.id}
            requestPageReloadBeforeYieldingFormLock={
              requestPageReloadBeforeYieldingFormLock
            }
          />
          <div className="header-submission-info">
            {isStencil && <div className="main-icon--stencil" />}
            <div className="main-title-container">
              <h1 className="main-h1">
                <div
                  className={cn('main-mode', {
                    'main-mode--stencil': isStencil,
                  })}
                >
                  {this.modeText()}
                </div>
                <div className="main-title">{this.sectionTitle()}</div>
              </h1>
              {this.renderLastUpdated()}
            </div>
            {showDownloadingProgress && <CircularProgress />}
            <Menu
              buttonClassName={isStencil ? 'menu-trigger--stencil' : undefined}
              className="menu-trigger"
              icon={<MoreHoriz className="menu-button-icon" />}
              options={options}
              title="More Options"
            />
          </div>
        </div>
      </div>
    );
  }
}

ContentHeading.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    is_super_admin: PropTypes.bool,
  }),
  disableDelete: PropTypes.bool,
  disableDownload: PropTypes.bool,
  error: PropTypes.bool,
  eventDate: PropTypes.string,
  formTemplateId: PropTypes.string,
  /* TODO: Fix this the next time the file is edited */
  /* eslint-disable-next-line react/require-default-props */
  lockInfo: PropTypes.shape({
    lockedBy: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    expiresAt: PropTypes.string,
  }),
  isStencil: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  requestPageReloadBeforeYieldingFormLock: PropTypes.bool.isRequired,
  sectionName: PropTypes.string,
  showDownloadingProgress: PropTypes.bool,
  subsectionName: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
};

ContentHeading.defaultProps = {
  currentUser: null,
  disableDelete: false,
  disableDownload: true,
  error: false,
  eventDate: null,
  formTemplateId: null,
  isStencil: false,
  sectionName: null,
  showDownloadingProgress: false,
  subsectionName: null,
  updatedAt: null,
  updatedBy: null,
};
