/* eslint-disable */
const {
  isPolygonContainsCenter,
  isPolygonContainsCenter2,
} = require('../utils');

L.LeaderLine = L.Polyline.extend({
  includes: L.Mixin.Events,

  options: {
    markerLatLng: null,
    label: null,
    map: null,
  },

  initialize(options) {
    L.setOptions(this, options);
    L.Polyline.prototype.initialize.call(
      this,
      [],
      _.omit(options, ['markerLatLng']),
    );
  },

  onAdd(map) {
    const APP = require('../../config');
    const label = this.options.label;

    this._map = map;

    this._resetLatLngs();

    if (label._animated) {
      label.on('zoomanim', this._zoomAnimation, this);
    }

    APP.vent.on(
      'editLayerStylesView:success editLayerDetailsView:success',
      this._updateLatLngsAndRedraw,
      this,
    );

    if (this._map && label && label.marker && label.marker.type === 'marker') {
      this._map.on('draw:editstart', () => {
        label.unbindLeaderline();
      });
    }
    if (label) {
      label.on('updatecontent', this._updateLatLngsAndRedraw, this);
    }

    L.Polyline.prototype.onAdd.call(this, map);
  },

  onRemove(map) {
    this.options.label.off('updatecontent', this._updateLatLngsAndRedraw, this);
    L.Polyline.prototype.onRemove.call(this, map);
  },

  setMarkerLatLng(latlng) {
    this._markerlatlng = L.latLng(latlng);
    if (this._map) {
      this._resetLatLngs();
    }
    return this;
  },

  _updateLatLngsAndRedraw() {
    if (this._map) {
      this._resetLatLngs();
    }
    return this.redraw();
  },

  _zoomAnimation(opt) {
    if (this._map) {
      this._resetLatLngs(1 / opt.scale);
    }
    return this.redraw();
  },

  _resetLatLngs(scale = 1) {
    let label = this.options.label,
      marker = this.options.label.marker,
      userOffset = label._convertOffsetToPoint(),
      endLayerPoint = label.moved
        ? this._map.latLngToLayerPoint(label.getLatLng())
        : this._map.latLngToLayerPoint(this._markerlatlng),
      direction = label.options.direction,
      labelWidth = label.getLabelWidth(),
      labelHeight = label.getLabelHeight();

    userOffset = userOffset.multiplyBy(scale);

    if (direction === 'custom') {
      endLayerPoint.x += userOffset.x;
      endLayerPoint.y += userOffset.y;

      endLayerPoint.x += Math.round((labelWidth / 2) * scale);
      endLayerPoint.y += Math.round((labelHeight / 2) * scale);
    } else {
      endLayerPoint.x += scale * 10;
    }

    let endLatLng = this._map.layerPointToLatLng(endLayerPoint);

    let startLatLng;
    if (marker.type === 'polyline') {
      startLatLng =
        this._getClosestLatLng(marker, this._map) || this._markerlatlng;
    } else if (marker.type === 'polygon') {
      startLatLng = marker.getBounds().getCenter();
    } else {
      startLatLng = this._markerlatlng;
    }

    this._latlngs = [startLatLng, endLatLng];
  },

  _getClosestLatLng(marker, map) {
    const center = marker.getBounds().getCenter();
    const point = map.latLngToLayerPoint(center);
    const closestPoint = marker.closestLayerPoint(point);
    return closestPoint && map.layerPointToLatLng(closestPoint);
  },
});

L.leaderline = function (options) {
  return new L.LeaderLine(options);
};
