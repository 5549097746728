import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDropDown, ArrowRight } from '../../elements/MuiIcon';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class RecordPanelListItemExpandable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.initializeExpanded,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick } = this.props;

    this.setState((prevState) => {
      const newIsExpanded = !prevState.isExpanded;
      if (onClick) {
        onClick(newIsExpanded);
      }
      return {
        isExpanded: newIsExpanded,
      };
    });
  }

  render() {
    const { classes, details, summary } = this.props;

    /* eslint-disable */
    const isExpanded = !_.isUndefined(this.props.isExpanded)
      ? this.props.isExpanded
      : this.state.isExpanded;
    /* eslint-enable */
    const uniqueId = uuidv4();
    const className = ['record-panel-expandable-list-item']
      .concat(classes)
      .join(' ');

    if (details == null) {
      return (
        <div className={className}>
          <div className="summary-container">
            <div className="record-panel-list-item gray-link">
              <div className="no__icon">{summary}</div>
            </div>
          </div>
        </div>
      );
    }

    const hiddenClass = isExpanded ? ' show-children' : ' hide-children';
    const expandIcon = isExpanded ? (
      <ArrowDropDown fontSize="large" className="collapse-caret" />
    ) : (
      <ArrowRight fontSize="large" className="collapse-caret" />
    );
    return (
      <div className={className}>
        <div className="summary-container">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="record-panel-list-item cursor-pointer"
            onClick={this.handleClick}
          >
            {expandIcon}
            {summary}
          </div>
        </div>
        <div
          className={`details-container panel-collapse${hiddenClass}`}
          id={uniqueId}
        >
          {isExpanded && details}
        </div>
      </div>
    );
  }
}

RecordPanelListItemExpandable.defaultProps = {
  classes: [],
  details: undefined,
  initializeExpanded: false,
  isExpanded: undefined,
  onClick: null,
  summary: null,
};
RecordPanelListItemExpandable.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  details: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  initializeExpanded: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  summary: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
