import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import APP from '../../../../../config';
import StatsListItem from './StatsListItem';
import {
  fetchFlowLogReadingsAction,
  fetchFlowLogStatsAction,
} from '../../../../../actions/wastewater';
import withProvider from '../../../../withProvider';

function StatsListItemContainer(props) {
  const {
    flowLogLimits,
    flowLogReadings,
    flowLogStats,
    locationId,
    statsSummary,
    onEditFlowLogReading,
    fetchFlowLogReadings,
    fetchFlowLogStats,
  } = props;

  return (
    <StatsListItem
      flowLogLimits={flowLogLimits}
      flowLogReadings={flowLogReadings}
      flowLogStats={flowLogStats}
      locationId={locationId}
      onEditFlowLogReading={onEditFlowLogReading}
      onFetchFlowLogReadings={fetchFlowLogReadings}
      onFetchFlowLogStats={fetchFlowLogStats}
      statsSummary={statsSummary}
    />
  );
}

StatsListItemContainer.propTypes = {
  flowLogLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowLogReadings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowLogStats: PropTypes.shape({}).isRequired,
  locationId: PropTypes.string.isRequired,
  statsSummary: PropTypes.shape({}).isRequired,
  onEditFlowLogReading: PropTypes.func.isRequired,
  fetchFlowLogReadings: PropTypes.func.isRequired,
  fetchFlowLogStats: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  flowLogLimits: state.wastewater.flowLogLimits,
  flowLogReadings: state.wastewater.flowLogReadings,
  flowLogStats: state.wastewater.flowLogStats,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFlowLogReadings: (query) =>
    dispatch(fetchFlowLogReadingsAction(APP.projectId, query)),
  fetchFlowLogStats: (query) =>
    dispatch(fetchFlowLogStatsAction(APP.projectId, query)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(StatsListItemContainer),
);
