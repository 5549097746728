import { SaveState } from '@monorepo/shared/types/SaveState';
import update from 'immutability-helper';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import {
  createEmissionFactorAction,
  deleteEmissionFactorAction,
  deleteEmissionItemGroupAction,
  fetchEmissionFactorsAction,
  fetchEmissionItemGroupsAction,
  updateEmissionFactorAction,
} from '../../../../actions/air';
import ProjectContext from '../../../../contexts/ProjectContext';
import {
  getEmissionFactorGroups,
  getEmissionFactors,
  getIsFetching,
} from '../../../../selectors/genericLogs';
import { isNullOrUndefined } from '../../../../utils';
import withProvider from '../../../withProvider';
import EmissionsFactorsTable from './EmissionsFactorsTable';

class FactorsContainer extends Component {
  constructor(props) {
    super(props);
    this.formSubmissionDraftObject = {
      groupId: null,
      itemType: null,
      itemValue: null,
      name: null,
      notes: null,
      units: null,
    };
    this.initialState = {
      formErrors: {
        displayable: [],
      },
      formSubmissionDraft: {
        groups: {},
      },
      formSaveState: SaveState.CLEAN,
      deletedGroups: [],
      itemsToUpsert: [],
      itemsToDelete: [],
    };
    this.state = this.initialState;
    this.addEmissionFactor = this.addEmissionFactor.bind(this);
    this.deleteEmissionFactor = this.deleteEmissionFactor.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.reorderCollectionInDragDropContext =
      this.reorderCollectionInDragDropContext.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const { fetchEmissionItemGroups, fetchEmissionFactors, logProjectId } =
      this.props;
    const { projectId } = this.context;
    fetchEmissionItemGroups(projectId, logProjectId);
    fetchEmissionFactors(projectId, logProjectId);
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      itemGroups,
      emissionFactors,
      setHasSubmittedForm,
      shouldSubmitForm,
    } = this.props;

    const { formSubmissionDraft, deletedGroups } = prevState;

    const hasFetchedEmissionFactors =
      (prevProps.isFetching && !isFetching) ||
      !_.isEqual(emissionFactors, prevProps.emissionFactors);

    if (!prevProps.shouldSubmitForm && shouldSubmitForm) {
      setHasSubmittedForm();
      this.validateFormSubmission().then(this.submitForm);
    }

    if (
      Object.keys(itemGroups).length >
      Object.keys(formSubmissionDraft.groups).length
    ) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (!nextFormSubmissionDraft.groups[id]) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (!_.isEqual(itemGroups, prevProps.itemGroups)) {
      const nextFormSubmissionDraft = formSubmissionDraft;
      Object.keys(itemGroups).forEach((id) => {
        if (
          !deletedGroups.includes(id) &&
          !nextFormSubmissionDraft.groups[id]
        ) {
          nextFormSubmissionDraft.groups[id] = [];
        }
      });
      return this.setState({ formSubmissionDraft: nextFormSubmissionDraft });
    }

    if (hasFetchedEmissionFactors) {
      const nextEmissionFactors = emissionFactors.reduce(
        (acc, emissionFactor) =>
          update(acc, {
            [emissionFactor.groupId]: (group) =>
              group
                ? update(group, {
                    $push: [
                      { ...emissionFactor, renderKey: emissionFactor.id },
                    ],
                  })
                : [{ ...emissionFactor, renderKey: emissionFactor.id }],
          }),
        {},
      );

      return this.setState((ps) =>
        update(ps, {
          formSubmissionDraft: {
            groups: {
              $merge: nextEmissionFactors,
            },
          },
        }),
      );
    }
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  setFormSaveState(formSaveState) {
    const { setFormSaveState } = this.props;
    setFormSaveState(formSaveState);
  }

  handleDeleteGroup(groupId) {
    const { deleteEmissionItemGroup, fetchEmissionFactors, logProjectId } =
      this.props;
    const { projectId } = this.context;

    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              $set: _.omit(prevState.formSubmissionDraft.groups, groupId),
            },
          },
          deletedGroups: {
            $push: [groupId],
          },
        }),
      async () => {
        await deleteEmissionItemGroup(projectId, groupId);
        await fetchEmissionFactors(projectId, logProjectId);
      },
    );
  }

  deleteEmissionFactor(emissionsFactorIdx, groupId) {
    const { formSubmissionDraft } = this.state;
    const deletedItemId =
      formSubmissionDraft.groups[groupId][emissionsFactorIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                $splice: [[emissionsFactorIdx, 1]],
              },
            },
          },
          itemsToDelete: {
            $push: [deletedItemId],
          },
          formErrors: {
            $set: {
              displayable: [],
            },
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  reorderCollectionInDragDropContext(nextFormSubmissionDraft, itemToUpsert) {
    this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            $set: nextFormSubmissionDraft,
          },
          itemsToUpsert: {
            $push: [itemToUpsert],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  stageEdit(editsToMake, editsLocation) {
    const { editField, nextValue } = editsToMake;
    const { emissionsFactorIdx, groupId } = editsLocation;
    const { formSubmissionDraft } = this.state;
    const editedId = formSubmissionDraft.groups[groupId][emissionsFactorIdx].id;
    return this.setState(
      (prevState) =>
        update(prevState, {
          formSubmissionDraft: {
            groups: {
              [groupId]: {
                [emissionsFactorIdx]: {
                  $merge: {
                    [editField]: nextValue === '' ? null : nextValue,
                  },
                },
              },
            },
          },
          itemsToUpsert: {
            $push: [editedId],
          },
        }),
      () => {
        this.setFormSaveState(SaveState.DIRTY);
      },
    );
  }

  addEmissionFactor(groupId = null) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          groups: {
            [groupId]: (n) =>
              update(n || [], {
                $push: [
                  {
                    ...this.formSubmissionDraftObject,
                    renderKey: uuidv4(),
                    groupId,
                  },
                ],
              }),
          },
        },
      }),
    );
  }

  validateFormSubmission() {
    const { formSubmissionDraft } = this.state;
    const formErrors = {
      displayable: [],
      groups: {},
    };

    const limitItemRequiredFields = [
      {
        field: 'name',
        message: 'Name is required.',
      },
      {
        field: 'itemType',
        message: 'Type is required.',
      },
      {
        field: 'itemValue',
        message: 'Value is required.',
      },
      {
        field: 'units',
        message: 'Units is required.',
      },
    ];

    _.map(formSubmissionDraft.groups, (group, groupId) => {
      formErrors.groups[groupId] = {};
      _.map(group, (emissionFactor, emissionFactorId) => {
        limitItemRequiredFields.forEach((rf) => {
          if (isNullOrUndefined(emissionFactor[rf.field])) {
            const errorMessage = `${
              emissionFactor.name ? `${emissionFactor.name}: ` : ''
            }${rf.message}`;
            formErrors.groups[groupId] = {
              ...formErrors.groups[groupId],
              [emissionFactorId]: {
                ...formErrors.groups[groupId][emissionFactorId],
                [rf.field]: errorMessage,
              },
            };
            formErrors.displayable.push(errorMessage);
          }
        });
      });
    });

    return new Promise((res) => {
      this.setState(
        (prevState) =>
          update(prevState, {
            formErrors: {
              $set: {
                ...formErrors,
                displayable: formErrors.displayable.sort(),
              },
            },
          }),
        res,
      );
    });
  }

  submitForm() {
    const { formErrors, itemsToUpsert, itemsToDelete, formSubmissionDraft } =
      this.state;

    const {
      deleteEmissionFactor,
      logProjectId,
      setHasSubmittedForm,
      upsertEmissionFactor,
    } = this.props;
    const { projectId } = this.context;

    if (formErrors.displayable.length === 0) {
      _.map(formSubmissionDraft.groups, (group) => {
        group.forEach((emissionFactor) => {
          if (itemsToUpsert.includes(emissionFactor.id)) {
            upsertEmissionFactor(projectId, {
              ...emissionFactor,
              logProjectId,
            });
          }
        });
      });
      itemsToDelete.forEach((id) => {
        deleteEmissionFactor(projectId, id);
      });
      this.setState({
        itemsToUpsert: [],
        itemsToDelete: [],
      });
      setHasSubmittedForm();
      this.setFormSaveState(SaveState.SAVED);
    }
  }

  render() {
    const { formErrors, formSubmissionDraft } = this.state;

    const orderedFormSubmissionDraft = {
      groups: {
        null: _get(formSubmissionDraft, 'groups.null', []),
        ..._.omit(formSubmissionDraft.groups, 'null'),
      },
    };

    const {
      emissionFactors,
      isFetching,
      itemGroups,
      logProjectId,
      selectMenuPortalRef,
    } = this.props;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <EmissionsFactorsTable
        addEmissionFactor={this.addEmissionFactor}
        deleteEmissionFactor={this.deleteEmissionFactor}
        emissionFactors={emissionFactors}
        formErrors={formErrors}
        formSubmissionDraft={orderedFormSubmissionDraft}
        handleDeleteGroup={this.handleDeleteGroup}
        isLoading={isFetching}
        itemGroups={itemGroups}
        logProjectId={logProjectId}
        onDragEnd={this.reorderCollectionInDragDropContext}
        selectMenuPortalRef={selectMenuPortalRef}
        stageEdit={this.stageEdit}
      />
    );
  }
}

FactorsContainer.propTypes = {
  deleteEmissionItemGroup: PropTypes.func.isRequired,
  deleteEmissionFactor: PropTypes.func.isRequired,
  fetchEmissionItemGroups: PropTypes.func.isRequired,
  fetchEmissionFactors: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  setFormSaveState: PropTypes.func.isRequired,
  setHasSubmittedForm: PropTypes.func.isRequired,
  shouldSubmitForm: PropTypes.bool.isRequired,
  upsertEmissionFactor: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { logProjectId } = ownProps;
  return {
    isFetching: getIsFetching(state, 'airEmissionsEmissionFactors'),
    emissionFactors: getEmissionFactors(state, logProjectId),
    itemGroups: getEmissionFactorGroups(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  upsertEmissionFactor: (projectId, emissionFactor) =>
    emissionFactor.id
      ? dispatch(updateEmissionFactorAction(projectId, emissionFactor))
      : dispatch(createEmissionFactorAction(projectId, emissionFactor)),
  fetchEmissionItemGroups: (projectId, logProjectId) =>
    dispatch(fetchEmissionItemGroupsAction(projectId, logProjectId)),
  fetchEmissionFactors: (projectId, logProjectId) =>
    dispatch(fetchEmissionFactorsAction(projectId, logProjectId)),
  deleteEmissionFactor: (projectId, id) =>
    dispatch(deleteEmissionFactorAction(projectId, ownProps.logProjectId, id)),
  deleteEmissionItemGroup: (projectId, id) =>
    dispatch(
      deleteEmissionItemGroupAction(projectId, ownProps.logProjectId, id),
    ),
});

FactorsContainer.contextType = ProjectContext;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(FactorsContainer),
);
