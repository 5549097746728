/* eslint-disable */
var DropdownTabView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  template: require('./templates/dropdownTab.pug'),

  events: {
    click: 'activate',
  },

  attributes() {
    return {
      'data-model-id': this.model.id,
      'swppp-year': this.model.get('swppp_year'),
    };
  },

  activate() {
    this.$el.tab('show');
  },
});

module.exports = DropdownTabView;
