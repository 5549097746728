import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns';
import _get from 'lodash.get';
import { IntervalFrequency, SimpleUnits } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

import {
  capitalizeFirstLetter,
  isNullOrUndefined,
  UTCEquivalentOfLocal,
} from '../../../utils';

import Units from '../../../utils/units';

import {
  AnimatedComponent,
  Button,
  Card,
  Chart,
  DatePicker,
  FieldWrapper,
  FormErrors,
  IconButton,
  MapistryTooltip,
  Select,
} from '../../elements';

import { ANIMATION_TYPES } from '../../elements/AnimatedComponent';

const CALCULATION_TYPE = {
  average: 'average',
  max: 'max',
  total: 'total',
};

const LOG_TYPE = {
  flowRate: 'flowRate',
  volume: 'volume',
};

const FlowLogChart = (props) => {
  const {
    chartData,
    errors,
    filterSettings,
    isLoading,
    monitoringLocations,
    setFilterSetting,
    settingsIsVisible,
    toggleSettingsIsVisible,
  } = props;

  const hasChartData = _get(chartData, 'records.series', []).length > 0;

  const title = settingsIsVisible ? 'Filter settings' : 'Water flow over time';
  const icon = settingsIsVisible ? null : (
    <MapistryTooltip placement="top" title="Filter">
      <div className="flex items-center">
        <SvgIcon identifier="filter" />
        <span className="filter__text">Filter</span>
      </div>
    </MapistryTooltip>
  );
  const MONITORING_LOCATION_OPTIONS = monitoringLocations.map((m) => ({
    label: m.name,
    value: m.id,
  }));
  const LOG_TYPE_OPTIONS = [
    {
      label: 'Flow Rate',
      value: LOG_TYPE.flowRate,
    },
    {
      label: 'Volume',
      value: LOG_TYPE.volume,
    },
  ];
  const CALCULATION_OPTIONS = [
    {
      label: 'Average',
      value: 'average',
    },
    {
      label: 'Max',
      value: 'max',
    },
    {
      label: 'Min',
      value: 'min',
    },
  ];

  if (filterSettings.logType === LOG_TYPE.volume) {
    CALCULATION_OPTIONS.push({
      label: 'Total',
      value: 'total',
    });
  }

  const getUnitsOptions = (logType) => {
    if (isNullOrUndefined(logType) || logType === LOG_TYPE.flowRate) {
      return Units.getRelatedUnits(SimpleUnits.GPM);
    }

    if (logType === LOG_TYPE.volume) {
      return Units.getRelatedUnits(SimpleUnits.GALLONS);
    }
    return [];
  };

  const FREQUENCY_OPTIONS = [
    IntervalFrequency.DAY,
    IntervalFrequency.MONTH,
    IntervalFrequency.YEAR,
  ].map((f) => ({
    label: capitalizeFirstLetter(f),
    value: f,
  }));

  const NUMBER_OF_MONTH_OPTIONS = 24;
  const MONTH_OPTIONS = _.range(NUMBER_OF_MONTH_OPTIONS - 1, -1, -1).reduce(
    (accum, val) => {
      const d = sub(new Date(), { months: val });
      return {
        end: accum.end.concat({
          label: format(endOfMonth(d), 'M-d-yyyy'),
          value: UTCEquivalentOfLocal(endOfMonth(d)),
        }),
        start: accum.start.concat({
          label: format(startOfMonth(d), 'M-d-yyyy'),
          value: UTCEquivalentOfLocal(startOfMonth(d)),
        }),
      };
    },
    { end: [], start: [] },
  );
  const NUMBER_OF_YEAR_OPTIONS = 4;
  const YEAR_OPTIONS = _.range(NUMBER_OF_YEAR_OPTIONS - 1, -1, -1).reduce(
    (accum, val) => {
      const d = sub(new Date(), { years: val });
      return {
        end: accum.end.concat({
          label: format(endOfYear(d), 'M-d-yyyy'),
          value: UTCEquivalentOfLocal(endOfYear(d)),
        }),
        start: accum.start.concat({
          label: format(startOfYear(d), 'M-d-yyyy'),
          value: UTCEquivalentOfLocal(startOfYear(d)),
        }),
      };
    },
    { end: [], start: [] },
  );
  const LOW_FREQUENCY_DATE_OPTIONS = {
    [IntervalFrequency.MONTH]: MONTH_OPTIONS,
    [IntervalFrequency.YEAR]: YEAR_OPTIONS,
  };

  return (
    <Card
      className="flex-1"
      actions={
        <IconButton onClick={toggleSettingsIsVisible}>{icon}</IconButton>
      }
      title={title}
    >
      <>
        {settingsIsVisible && (
          <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
            <div className="settings-card__container">
              <div className="settings-content">
                {_get(errors, 'displayable', []).length > 0 && (
                  <FormErrors errors={errors.displayable} />
                )}
                <div className="filter-settings__container">
                  <div className="field-wrapper">
                    <FieldWrapper label="Select monitoring locations">
                      <Select
                        error={!isNullOrUndefined(errors.monitoringLocationIds)}
                        isClearable={false}
                        isFixed
                        isMulti
                        options={MONITORING_LOCATION_OPTIONS}
                        onChange={(options) =>
                          setFilterSetting(
                            'monitoringLocationIds',
                            isNullOrUndefined(options)
                              ? null
                              : options.map((o) => o.value),
                          )
                        }
                        value={
                          isNullOrUndefined(
                            filterSettings.monitoringLocationIds,
                          )
                            ? []
                            : MONITORING_LOCATION_OPTIONS.filter((o) =>
                                filterSettings.monitoringLocationIds.some(
                                  (id) => id === o.value,
                                ),
                              )
                        }
                      />
                    </FieldWrapper>
                  </div>
                  <div className="field-wrapper">
                    <FieldWrapper label="Select the data type">
                      <Select
                        error={!isNullOrUndefined(errors.logType)}
                        isFixed
                        isClearable={false}
                        options={LOG_TYPE_OPTIONS}
                        onChange={(opt) =>
                          setFilterSetting('logType', opt.value)
                        }
                        value={LOG_TYPE_OPTIONS.find(
                          (o) => o.value === filterSettings.logType,
                        )}
                      />
                    </FieldWrapper>
                  </div>
                  <div className="field-wrapper">
                    <FieldWrapper label="Units">
                      <div className="filter-settings__multi-field-row">
                        <Select
                          className="w-40"
                          error={!isNullOrUndefined(errors.units)}
                          isFixed
                          isClearable={false}
                          options={getUnitsOptions(filterSettings.logType)}
                          onChange={(opt) =>
                            setFilterSetting('units', opt.value)
                          }
                          value={getUnitsOptions(filterSettings.logType).find(
                            (o) => o.value === filterSettings.units,
                          )}
                        />
                        <Select
                          className="w-20"
                          error={!isNullOrUndefined(errors.calculationType)}
                          isFixed
                          isClearable={false}
                          options={CALCULATION_OPTIONS}
                          onChange={(opt) =>
                            setFilterSetting('calculationType', opt.value)
                          }
                          value={CALCULATION_OPTIONS.find(
                            (o) => o.value === filterSettings.calculationType,
                          )}
                        />
                        <span className="text__spacer">per</span>
                        <Select
                          className="w-25"
                          error={!isNullOrUndefined(errors.frequency)}
                          isFixed
                          isClearable={false}
                          options={FREQUENCY_OPTIONS}
                          onChange={(opt) =>
                            setFilterSetting('frequency', opt.value)
                          }
                          value={FREQUENCY_OPTIONS.find(
                            (o) => o.value === filterSettings.frequency,
                          )}
                        />
                      </div>
                    </FieldWrapper>
                  </div>
                  <div className="field-wrapper">
                    <FieldWrapper label="Select a date range">
                      {(isNullOrUndefined(filterSettings.frequency) ||
                        filterSettings.frequency === IntervalFrequency.DAY) && (
                        <div className="filter-settings__multi-field-row">
                          <DatePicker
                            autoOk
                            className="date-field w-40"
                            clearable
                            error={!isNullOrUndefined(errors.start)}
                            maxDate={new Date()}
                            onChange={(date) => setFilterSetting('start', date)}
                            value={filterSettings.start}
                          />
                          <span className="text__spacer">to</span>
                          <DatePicker
                            autoOk
                            className="date-field w-40"
                            clearable
                            error={!isNullOrUndefined(errors.end)}
                            maxDate={new Date()}
                            onChange={(date) => setFilterSetting('end', date)}
                            value={new Date(filterSettings.end)}
                          />
                        </div>
                      )}
                      {(filterSettings.frequency === IntervalFrequency.MONTH ||
                        filterSettings.frequency ===
                          IntervalFrequency.YEAR) && (
                        <div className="filter-settings__multi-field-row">
                          <Select
                            className="w-40"
                            error={!isNullOrUndefined(errors.start)}
                            isFixed
                            isClearable={false}
                            options={
                              LOW_FREQUENCY_DATE_OPTIONS[
                                filterSettings.frequency
                              ].start
                            }
                            onChange={(opt) =>
                              setFilterSetting('start', opt.value)
                            }
                            value={LOW_FREQUENCY_DATE_OPTIONS[
                              filterSettings.frequency
                            ].start.find(
                              (o) =>
                                o.value.getTime() ===
                                filterSettings.start.getTime(),
                            )}
                          />
                          <span className="text__spacer">to</span>
                          <Select
                            className="w-40"
                            error={!isNullOrUndefined(errors.end)}
                            isFixed
                            isClearable={false}
                            options={
                              LOW_FREQUENCY_DATE_OPTIONS[
                                filterSettings.frequency
                              ].end
                            }
                            onChange={(opt) =>
                              setFilterSetting('end', opt.value)
                            }
                            value={LOW_FREQUENCY_DATE_OPTIONS[
                              filterSettings.frequency
                            ].end.find(
                              (o) =>
                                o.value.getTime() ===
                                filterSettings.end.getTime(),
                            )}
                          />
                        </div>
                      )}
                    </FieldWrapper>
                  </div>
                </div>
              </div>
              <div className="settings-actions">
                <Button onClick={toggleSettingsIsVisible}>Apply</Button>
              </div>
            </div>
          </AnimatedComponent>
        )}
        {!settingsIsVisible && (
          <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
            <div className="chart-container">
              {hasChartData && (
                <Chart
                  axes={chartData.axes}
                  colors={chartData.colors}
                  fill={chartData.fill}
                  isLoading={isLoading}
                  markers={chartData.markers}
                  plotOptions={chartData.plotOptions}
                  series={chartData.records.series}
                  categories={chartData.records.categories}
                  title={chartData.title}
                  tooltip={chartData.tooltip}
                  yaxis={chartData.yaxis}
                />
              )}
              {!hasChartData &&
                (isLoading ? (
                  <Chart isLoading={isLoading} />
                ) : (
                  <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
                    <div className="chart-default__container">
                      <div className="chart-overlay__container">
                        <div className="chart-overlay__content">
                          To view the water flow recorded over time, configure
                          settings by clicking the button below.
                        </div>
                        <Button
                          onClick={toggleSettingsIsVisible}
                          color="secondary"
                        >
                          Filter Settings
                        </Button>
                      </div>
                      <img
                        className="w-100 h-100"
                        alt="Default chart"
                        src="https://s3.amazonaws.com/mapistryAssets/chartDefault1.png"
                      />
                    </div>
                  </AnimatedComponent>
                ))}
            </div>
          </AnimatedComponent>
        )}
      </>
    </Card>
  );
};
FlowLogChart.propTypes = {
  chartData: PropTypes.shape({
    axes: PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
      y: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    }).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    fill: PropTypes.shape({}),
    markers: PropTypes.shape({
      size: PropTypes.arrayOf(PropTypes.number),
    }),
    plotOptions: PropTypes.shape({}),
    records: PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      ),
      series: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    title: PropTypes.string,
    tooltip: PropTypes.shape({}),
    yaxis: PropTypes.shape({}),
  }).isRequired,
  errors: PropTypes.shape({
    calculationType: PropTypes.string,
    displayable: PropTypes.arrayOf(PropTypes.string),
    end: PropTypes.string,
    frequency: PropTypes.string,
    logType: PropTypes.string,
    monitoringLocationIds: PropTypes.string,
    start: PropTypes.string,
    units: PropTypes.string,
  }).isRequired,
  filterSettings: PropTypes.shape({
    calculationType: PropTypes.string,
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    frequency: PropTypes.string,
    logType: PropTypes.string,
    monitoringLocationIds: PropTypes.arrayOf(PropTypes.string),
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    units: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setFilterSetting: PropTypes.func.isRequired,
  settingsIsVisible: PropTypes.bool.isRequired,
  toggleSettingsIsVisible: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FlowLogChart;

export { CALCULATION_TYPE, LOG_TYPE };
