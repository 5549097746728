import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FlowLogLazyFetchListItem from '../FlowLogLazyFetchListItem';
import FlowReadingListItem from '../FlowReadingListItem';

import {
  delimitNumbers,
  fixedDecimalPlaces,
  isNullOrUndefined,
} from '../../../../../utils';
import { formattedCalendarDateFromEvent } from '../../../../../utils/calendarEvents';
import cellWithAppliedLimits from '../flowLogListHelpers';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class StatsListItem extends Component {
  buildListItem(parentProps) {
    const { locationId, statsSummary, onFetch } = parentProps;
    const {
      flowLogLimits,
      flowLogStats,
      onEditFlowLogReading,
      onFetchFlowLogReadings,
      flowLogReadings,
    } = this.props;
    const readingsForStat = flowLogReadings.filter(
      ({ dateOfDischarge, monitoringLocationId }) =>
        locationId === monitoringLocationId &&
        dateOfDischarge >= statsSummary.startDate &&
        dateOfDischarge <= statsSummary.endDate,
    );
    const limitsForFrequency = flowLogLimits.filter(
      ({ frequency }) => statsSummary.frequency === frequency,
    );
    const hasNestedStats =
      !isNullOrUndefined(statsSummary.childStatsFrequency) &&
      !isNullOrUndefined(
        flowLogStats[locationId][statsSummary.childStatsFrequency],
      ) &&
      flowLogStats[locationId][statsSummary.childStatsFrequency].filter(
        (childStats) =>
          childStats.startDate >= statsSummary.startDate &&
          childStats.startDate <= statsSummary.endDate,
      ).length > 0;
    let Details;
    if (
      isNullOrUndefined(statsSummary.childStatsFrequency) &&
      readingsForStat.length > 0
    ) {
      Details = (
        <>
          <div className="flow-reading-header-row">
            <div>Date of Discharge</div>
            <div>Volume (gallons)</div>
            <div>Average Flow Rate (gpm)</div>
            <div>Hours of Discharge</div>
            <div className="actions-column">Actions</div>
          </div>
          {readingsForStat.map((reading) => (
            <FlowReadingListItem
              key={`${locationId}-reading-${reading.dateOfDischarge}`}
              flowReading={reading}
              onEditFlowLogReading={onEditFlowLogReading}
            />
          ))}
        </>
      );
    } else if (hasNestedStats) {
      Details = (
        <>
          {flowLogStats[locationId][statsSummary.childStatsFrequency]
            .filter(
              (stats) =>
                stats.startDate >= statsSummary.startDate &&
                stats.startDate < statsSummary.endDate,
            )
            .map((stats) =>
              this.buildListItem({
                locationId,
                onFetch: () => {
                  const readings = flowLogReadings.filter(
                    ({ dateOfDischarge, monitoringLocationId }) =>
                      monitoringLocationId === stats.monitoringLocationId &&
                      dateOfDischarge >= stats.startDate &&
                      dateOfDischarge <= stats.endDate,
                  );
                  if (readings.length === 0) {
                    onFetchFlowLogReadings({
                      endDate: stats.endDate,
                      startDate: stats.startDate,
                      locationId,
                    });
                  }
                },
                statsSummary: stats,
              }),
            )}
        </>
      );
    } else {
      Details = null;
    }
    return (
      <FlowLogLazyFetchListItem
        key={`${locationId}-${statsSummary.startDate}`}
        onFetch={onFetch}
        summary={
          <div className="flex items-center">
            <div className="date-label">
              {formattedCalendarDateFromEvent({
                end: statsSummary.endDate,
                frequency: statsSummary.frequency,
                start: statsSummary.startDate,
              })}
            </div>
            <div className="aggregate-stats-data-container">
              {cellWithAppliedLimits(
                limitsForFrequency.filter(
                  ({ calculationType, limitType }) =>
                    calculationType === 'average' && limitType === 'volume',
                ),
                delimitNumbers(
                  fixedDecimalPlaces(statsSummary.averageVolume, 1),
                ),
                'gallons',
                (value) => delimitNumbers(fixedDecimalPlaces(value, 1)),
              )}
              <div>{delimitNumbers(statsSummary.maxVolume)}</div>
              <div>{delimitNumbers(statsSummary.minVolume)}</div>
              {cellWithAppliedLimits(
                limitsForFrequency.filter(
                  ({ calculationType, limitType }) =>
                    calculationType === 'total' && limitType === 'volume',
                ),
                delimitNumbers(statsSummary.totalVolumeMillionsOfGallons),
                'millions_of_gallons',
                (value) => delimitNumbers(value),
              )}
              <div>
                {delimitNumbers(
                  fixedDecimalPlaces(statsSummary.averageFlowRate, 1),
                )}
              </div>
              <div>
                {delimitNumbers(
                  fixedDecimalPlaces(statsSummary.maxFlowRate, 1),
                )}
              </div>
              <div>
                {delimitNumbers(
                  fixedDecimalPlaces(statsSummary.minFlowRate, 1),
                )}
              </div>
              <div>{statsSummary.numberOfReadings}</div>
            </div>
          </div>
        }
        details={Details}
      />
    );
  }

  render() {
    const { locationId, onFetchFlowLogStats, flowLogStats, statsSummary } =
      this.props;
    const hasNestedStats =
      !isNullOrUndefined(statsSummary.childStatsFrequency) &&
      !isNullOrUndefined(
        flowLogStats[locationId][statsSummary.childStatsFrequency],
      ) &&
      flowLogStats[locationId][statsSummary.childStatsFrequency].filter(
        (childStats) =>
          childStats.startDate >= statsSummary.startDate &&
          childStats.startDate <= statsSummary.endDate,
      ).length > 0;
    return this.buildListItem({
      locationId,
      onFetch: () => {
        if (!hasNestedStats) {
          onFetchFlowLogStats({
            endDate: statsSummary.endDate,
            frequency: statsSummary.childStatsFrequency,
            locationId,
            startDate: statsSummary.startDate,
          });
        }
      },
      statsSummary,
    });
  }
}

StatsListItem.defaultProps = {
  flowLogLimits: [],
  flowLogReadings: [],
  flowLogStats: {},
};

StatsListItem.propTypes = {
  flowLogLimits: PropTypes.arrayOf(PropTypes.shape({})),
  flowLogReadings: PropTypes.arrayOf(PropTypes.shape({})),
  flowLogStats: PropTypes.shape({}),
  locationId: PropTypes.string.isRequired,
  onEditFlowLogReading: PropTypes.func.isRequired,
  onFetchFlowLogReadings: PropTypes.func.isRequired,
  onFetchFlowLogStats: PropTypes.func.isRequired,
  statsSummary: PropTypes.shape({
    endDate: PropTypes.string,
    childStatsFrequency: PropTypes.string,
    locationId: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};
