/* eslint-disable */
var FeatureDisplaySettingModel = Backbone.Model.extend({
  defaults: function () {
    return {
      feature_on: true,
    };
  },
});

module.exports = FeatureDisplaySettingModel;
