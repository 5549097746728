var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (dateSubmittedToGovernment, dateUpdated, projectState, updateNotes) {
      pug_html = pug_html + "\u003Cb\u003EDate Changes Made: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = dateUpdated) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr\u003E";
if (projectState === 'ca') {
pug_html = pug_html + "\u003Cb\u003EDate Changes Submitted to SMARTS: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = dateSubmittedToGovernment) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr\u003E";
}
pug_html = pug_html + "\u003Cb\u003ENotes: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = updateNotes || '[No notes were recorded]') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }.call(this, "dateSubmittedToGovernment" in locals_for_with ?
        locals_for_with.dateSubmittedToGovernment :
        typeof dateSubmittedToGovernment !== 'undefined' ? dateSubmittedToGovernment : undefined, "dateUpdated" in locals_for_with ?
        locals_for_with.dateUpdated :
        typeof dateUpdated !== 'undefined' ? dateUpdated : undefined, "projectState" in locals_for_with ?
        locals_for_with.projectState :
        typeof projectState !== 'undefined' ? projectState : undefined, "updateNotes" in locals_for_with ?
        locals_for_with.updateNotes :
        typeof updateNotes !== 'undefined' ? updateNotes : undefined));
    ;;return pug_html;};
module.exports = template;