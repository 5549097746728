/* eslint-disable */
let _ = require('underscore');
let GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');

let WashingtonSamplingEventParameterView =
  GenericSamplingEventParameterView.extend({
    _afterOnShow: function () {
      this._showQuarterlyAverageRows();
    },

    modelEvents: {
      'change:locations': '_showQuarterlyAverageRows',
    },

    _isRequiredSamplingParameter: function (parameterSlug) {
      return this.sectorData.isRequiredSamplingParameter({
        parameterSlug,
        isWesternWA: this.projectModel.get('is_western_wa'),
        isRcraFacility: this.projectModel.get('is_rcra_facility'),
        siteDischargesIntoPugetSound: this.projectModel.get(
          'site_discharges_into_puget_sound',
        ),
      });
    },

    _showQuarterlyAverageRows: function () {
      // we don't show pH averages, because pH is a logarithmic value
      if (this.model.get('slug') === 'ph') {
        return;
      }

      let QuarterlyAverageCollectionView = require('./QuarterlyAverageCollectionView');
      let quarterlyAverageCollection = new Backbone.Collection();
      _.each(
        this.model.get('locations'),
        (location) => {
          quarterlyAverageCollection.add(
            _.extend(
              _.pick(this.model.attributes, 'slug', 'quarterly_average_limit'),
              _.pick(
                location,
                'location_id',
                'quarterly_average',
                'name',
                'quarterly_average_satisfied',
              ),
            ),
          );
        },
        this,
      );

      let quarterlyAverageCollectionView = new QuarterlyAverageCollectionView({
        collection: quarterlyAverageCollection,
        sectorData: this.sectorData,
      });
      this.annualAverageRow.show(quarterlyAverageCollectionView);
    },
  });

module.exports = WashingtonSamplingEventParameterView;
