import React, { useCallback } from 'react';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { GenericLogType } from 'mapistry-shared';
import { EmptyTabContent, TABLE_SETTINGS } from '../shared';

import { TextField } from '../../../elements';
import EmissionTrackingTable from '../shared/EmissionTrackingTable';
import CalculatedValuesRow from './CalculatedValuesRow';

const CalculatedValuesTable = (props) => {
  const {
    addCalculatedValue,
    addEquationFactor,
    calculatedValues,
    deleteCalculatedValue,
    deleteEquationFactor,
    emissionFactors,
    formErrors,
    formSubmissionDraft,
    handleDeleteGroup,
    loggedItems,
    logProjectId,
    isLoading,
    itemGroups,
    onDragEnd,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const renderRow = (
    calculatedValue,
    index,
    groupId,
    measureRowHeight,
    isDragging,
  ) => (
    <CalculatedValuesRow
      addEquationFactor={addEquationFactor}
      calculatedValue={calculatedValue}
      calculatedValueIdx={index}
      calculatedValues={calculatedValues}
      deleteCalculatedValue={deleteCalculatedValue}
      deleteEquationFactor={deleteEquationFactor}
      emissionFactors={emissionFactors}
      formErrors={formErrors}
      groupId={groupId}
      isDragging={isDragging}
      loggedItems={loggedItems}
      measureRowHeight={measureRowHeight}
      selectMenuPortalRef={selectMenuPortalRef}
      stageEdit={stageEdit}
    />
  );

  const renderRowWhileBeingDragged = (calculatedValue) => (
    <TextField
      disabled
      controlled={false}
      defaultValue={calculatedValue.name || '...dragging'}
    />
  );

  const getRowCountForGroup = useCallback(
    (groupOfItems) =>
      groupOfItems.reduce((sum, item) => sum + item.factors.length, 0),
    [],
  );

  const hasCalculatedValues =
    calculatedValues.length ||
    Object.keys(formSubmissionDraft.groups).some(
      (groupKey) => _get(formSubmissionDraft.groups, groupKey).length,
    );

  if (!hasCalculatedValues && !isLoading) {
    return (
      <EmptyTabContent title="Calculated Values" addItem={addCalculatedValue} />
    );
  }

  return (
    <EmissionTrackingTable
      addItem={addCalculatedValue}
      formErrors={formErrors}
      formSubmissionDraft={formSubmissionDraft}
      getRowCountForGroup={getRowCountForGroup}
      groupType={GenericLogType.CALCULATED_VALUE}
      handleDeleteGroup={handleDeleteGroup}
      hasFixedRowHeight={false}
      itemGroups={itemGroups}
      items={calculatedValues}
      isLoading={isLoading}
      logProjectId={logProjectId}
      onDragEnd={onDragEnd}
      renderRow={renderRow}
      renderRowWhileBeingDragged={renderRowWhileBeingDragged}
      tableInfo={TABLE_SETTINGS.CALCULATED_VALUES}
    />
  );
};

CalculatedValuesTable.propTypes = {
  addCalculatedValue: PropTypes.func.isRequired,
  addEquationFactor: PropTypes.func.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteCalculatedValue: PropTypes.func.isRequired,
  deleteEquationFactor: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  logProjectId: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default CalculatedValuesTable;
