/* eslint-disable */
var newCustomIconView = require('./newCustomIconView').singleton();
var BaseShapeItemView = require('./BaseShapeItemView');

module.exports = BaseShapeItemView.extend({
  template: require('./templates/marker.pug'),

  events: {
    'click .fa-upload': '_handleFileUpload',
    'change #upload-custom-marker': '_promptForName',
    'change .shape': '_handleChangeSelectColor',
    'change .label-display': '_handleChangeLabelDisplay',
    'change .leaderline-display': '_handleChangeLeaderlineDisplay',
  },

  templateHelpers() {
    let markerSize = this._getMarkerSizeStringValue(
      this.model.get('size'),
      this.model.get('shape'),
    );
    let isMarkerSizeSelected = function (ms) {
      return ms === markerSize;
    };
    let shape = this.model.get('shape');
    let isShapeSelected = function (s) {
      return s === shape;
    };
    let leaderlineColor = this.model.get('leaderline_color');
    let isLeaderlineColorSelected = (c) => c === leaderlineColor;
    let leaderlineStyle = this.model.get('leaderline_style');
    let isLeaderlineStyleSelected = (s) => s === leaderlineStyle;
    let leaderlineOpacity = this.model.get('leaderline_opacity');
    let isLeaderlineOpacitySelected = (o) =>
      parseFloat(o) === parseFloat(leaderlineOpacity);
    let leaderlineWeight = this.model.get('leaderline_weight');
    let isLeaderlineWeightSelected = (o) =>
      parseInt(o) === parseInt(leaderlineWeight);

    return _.extend(BaseShapeItemView.prototype.templateHelpers.call(this), {
      isMarkerSizeSelected,
      isShapeSelected,
      isLeaderlineStyleSelected,
      isLeaderlineColorSelected,
      isLeaderlineOpacitySelected,
      isLeaderlineWeightSelected,
    });
  },

  ui: {
    $selectpicker: 'select.shape',
    $uploadCustomMarker: '#upload-custom-marker',
    $uploadCustomMarkerForm: 'form#upload-custom-marker-form',
    $errorContainer: '.alert-danger',
    $colorContainer: '#color-container',
    $labelSettings: '.label-settings',
    $labelTextColorContainer: '#label-text-color-container',
    $labelTextSizeContainer: '#label-text-size-container',
    $labelBackgroundColorContainer: '#label-background-color-container',
    $labelShowChemicalAttributesContainer:
      '#label_show_chemical_attributes-container',
    $leaderlineWeightContainer: '#leaderline-weight-container',
    $leaderlineOpacityContainer: '#leaderline-opacity-container',
    $leaderlineColorContainer: '#leaderline-color-container',
    $leaderlineDisplayContainer: '#leaderline-display-container',
    $faUpload: '.fa-upload',
    $faRefresh: '.fa-refresh',
  },

  behaviors: {
    DisplayMessages: {},
    SelectPicker: {},
  },

  onShow() {
    this._appendCustomIcons();
    this.ui.$selectpicker.val(this.model.get('shape')).selectpicker('refresh');
    if (this.model.get('custom_icon')) {
      this.ui.$colorContainer.hide();
    }

    if (
      this.model.has('leaderline_style') &&
      this.model.get('leaderline_style') === 'none'
    ) {
      this.ui.$leaderlineWeightContainer.hide();
      this.ui.$leaderlineOpacityContainer.hide();
      this.ui.$leaderlineColorContainer.hide();
    }

    BaseShapeItemView.prototype.onShow.call(this);
  },

  initialize() {
    this._customIconTemplate = require('./templates/customIconMarker.pug');
    this.listenTo(newCustomIconView, 'validated', this._saveImage);
  },

  _isMarker() {
    return true;
  },

  _handleChangeSelectColor: function (e) {
    if ($(e.target).find(':selected').hasClass('custom-icon')) {
      this.ui.$colorContainer.slideUp();
    } else {
      this.ui.$colorContainer.slideDown();
    }
  },

  _handleChangeLeaderlineDisplay(e) {
    if ($(e.target).val() === 'none') {
      this.ui.$leaderlineWeightContainer.slideUp();
      this.ui.$leaderlineOpacityContainer.slideUp();
      this.ui.$leaderlineColorContainer.slideUp();
    } else {
      this.ui.$leaderlineWeightContainer.slideDown();
      this.ui.$leaderlineOpacityContainer.slideDown();
      this.ui.$leaderlineColorContainer.slideDown();
    }
  },

  /**
   * Get the markerSize value.
   *
   * @param markerSize {Integer} - The markerSize integer value
   * @param markerShape {String} - The shape of the marker
   * @return String - markerSize translated to it's English equivalent
   */
  _getMarkerSizeStringValue(markerSize, markerShape) {
    var sizeTranslator = {};
    var defaultMarkerSizes = this._getDefaultMarkerSizes(markerShape);

    sizeTranslator[defaultMarkerSizes.small] = 'small';
    sizeTranslator[defaultMarkerSizes.medium] = 'medium';
    sizeTranslator[defaultMarkerSizes.large] = 'large';
    sizeTranslator[defaultMarkerSizes.xlarge] = 'xlarge';
    sizeTranslator[defaultMarkerSizes.xxlarge] = 'xxlarge';

    return sizeTranslator[markerSize];
  },

  _formatStyles(newStyles) {
    let formattedStyles = BaseShapeItemView.prototype._formatStyles(newStyles);
    formattedStyles.shape = newStyles.shape;
    formattedStyles.size = this._getDefaultMarkerSizes(newStyles.shape)[
      newStyles.size
    ];
    formattedStyles.custom_icon = this.$('.shape option:selected').hasClass(
      'custom-icon',
    );

    formattedStyles.leaderline_style = newStyles.leaderline_style;
    formattedStyles.leaderline_color = newStyles.leaderline_color;
    formattedStyles.leaderline_opacity = newStyles.leaderline_opacity;
    formattedStyles.leaderline_weight = newStyles.leaderline_weight;

    return formattedStyles;
  },

  /**
   * Default size key
   * @param markerShape String - The shape of the marker
   * @return Object - Defaults depend on marker shape
   */
  _getDefaultMarkerSizes: function (markerShape) {
    if (markerShape === 'map-marker') {
      return {
        small: 30,
        medium: 45,
        large: 55,
        xlarge: 70,
        xxlarge: 80,
      };
    } else {
      return {
        small: 15,
        medium: 30,
        large: 45,
        xlarge: 60,
        xxlarge: 75,
      };
    }
  },
});
