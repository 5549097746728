var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getParameterDisplayText, isWashingtonIndustrial, parameter_slug, showParameterStatus, status) {
      if (showParameterStatus) {
if ((status === 'tier3')) {
pug_html = pug_html + "\u003C!-- todo what is worse than \"warning-red\"?--\u003E";
}
if ((status === 'level3' || status === 'tier2' || (status === 'level2' && !isWashingtonIndustrial))) {
pug_html = pug_html + "\u003Csvg class=\"warning-red padding-right-13\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
if ((status === 'level3')) {
pug_html = pug_html + "\u003Cspan class=\"red\"\u003ELevel 3&nbsp\u003C\u002Fspan\u003E";
}
else
if ((status === 'tier2')) {
pug_html = pug_html + "\u003Cspan class=\"red\"\u003ETier 2&nbsp\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"red\"\u003ELevel 2&nbsp\u003C\u002Fspan\u003E";
}
}
else
if ((status === 'level1' || status === 'tier1' || status === 'benchmark-exceedance' || status === 'level2')) {
pug_html = pug_html + "\u003Csvg class=\"warning-yellow padding-right-13\"\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
if ((status === 'level1')) {
pug_html = pug_html + "\u003Cspan class=\"color-warning-yellow\"\u003ELevel 1&nbsp\u003C\u002Fspan\u003E";
}
else
if ((status === 'tier1')) {
pug_html = pug_html + "\u003Cspan class=\"color-warning-yellow\"\u003ETier 1&nbsp\u003C\u002Fspan\u003E";
}
else
if ((status === 'level2')) {
pug_html = pug_html + "\u003Cspan class=\"color-warning-yellow\"\u003ELevel 2&nbsp\u003C\u002Fspan\u003E";
}
else
if ((status === 'benchmark-exceedance')) {
pug_html = pug_html + "\u003Cspan class=\"color-warning-yellow\"\u003EBenchmark Exceedance 1&nbsp\u003C\u002Fspan\u003E";
}
}
else {
pug_html = pug_html + "\u003Csvg class=\"mapistry-green padding-right-13\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
if ((status === 'consistent-attainment')) {
pug_html = pug_html + "\u003Cspan class=\"mapistry-green\"\u003EConsistent Attainment&nbsp\u003C\u002Fspan\u003E";
}
else
if ((status === 'exempt')) {
pug_html = pug_html + "\u003Cspan class=\"mapistry-green\"\u003EExempt&nbsp\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"mapistry-green\"\u003EBaseline&nbsp\u003C\u002Fspan\u003E";
}
}
}
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getParameterDisplayText(parameter_slug)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }.call(this, "getParameterDisplayText" in locals_for_with ?
        locals_for_with.getParameterDisplayText :
        typeof getParameterDisplayText !== 'undefined' ? getParameterDisplayText : undefined, "isWashingtonIndustrial" in locals_for_with ?
        locals_for_with.isWashingtonIndustrial :
        typeof isWashingtonIndustrial !== 'undefined' ? isWashingtonIndustrial : undefined, "parameter_slug" in locals_for_with ?
        locals_for_with.parameter_slug :
        typeof parameter_slug !== 'undefined' ? parameter_slug : undefined, "showParameterStatus" in locals_for_with ?
        locals_for_with.showParameterStatus :
        typeof showParameterStatus !== 'undefined' ? showParameterStatus : undefined, "status" in locals_for_with ?
        locals_for_with.status :
        typeof status !== 'undefined' ? status : undefined));
    ;;return pug_html;};
module.exports = template;