import { PageNotFoundError } from '../../errors';
import { enforceAuthentication } from '../../loader';
import OrganizationCollection from '../../models_collections/organizationCollection';
import ProjectCollection from '../../models_collections/projectCollection';
import rootLayoutView from '../../rootLayoutView';
import bigSpinner from '../../services/bigSpinner';
import NavbarView from '../navbar/NavbarView';

const projectCollection = ProjectCollection.singleton();
const organizationCollection = OrganizationCollection.singleton();

const fetchProject = async (projectId) => {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const APP = require('../../config');
  const model = projectCollection.get(projectId);
  if (model && projectId === APP.projectId) {
    return model;
  }
  APP.projectId = projectId;
  bigSpinner.show();
  let project;
  try {
    await enforceAuthentication();
    const fetchOptions = { silent: true, reset: true };
    await ProjectCollection.fetchSingleton(fetchOptions);
    project = projectCollection.get(projectId);
    bigSpinner.hide();
  } catch (error) {
    bigSpinner.hide();
    throw error;
  }
  if (!project) {
    throw new PageNotFoundError();
  }
  return project;
};

/**
 * Fetch a Project model and associated dashboard data, rendering navbar
 *
 * @param {string} projectId
 * @returns {Promise<Backbone.Model>} - Project model
 */
export const loadProject = async (projectId) => {
  const project = await fetchProject(projectId);
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const APP = require('../../config');
  APP.projectId = project.id;
  const organizationId = project.get('organization_id');
  const organization = organizationCollection.get(organizationId);
  const navbarView = new NavbarView({
    organizationId,
    organization,
    project,
    activeClass: 'project',
  });
  rootLayoutView.navbar.show(navbarView);
  return project;
};
