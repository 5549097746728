import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { GenericLogType } from 'mapistry-shared';
import { EmptyTabContent, TABLE_SETTINGS } from '../shared';
import { TextField } from '../../../elements';
import EmissionTrackingTable from '../shared/EmissionTrackingTable';
import UnitConversionsRow from './UnitConversionsRow';

const UnitConversionsTable = (props) => {
  const {
    addUnitConversion,
    calculatedValues,
    deleteUnitConversion,
    emissionFactors,
    formErrors,
    formSubmissionDraft,
    handleDeleteGroup,
    loggedItems,
    logProjectId,
    isLoading,
    itemGroups,
    onDragEnd,
    selectMenuPortalRef,
    stageEdit,
    unitConversions,
  } = props;

  const renderRow = (unitConversion, index, groupId) => (
    <UnitConversionsRow
      calculatedValues={calculatedValues}
      deleteUnitConversion={deleteUnitConversion}
      emissionFactors={emissionFactors}
      formErrors={formErrors}
      groupId={groupId}
      loggedItems={loggedItems}
      selectMenuPortalRef={selectMenuPortalRef}
      stageEdit={stageEdit}
      unitConversion={unitConversion}
      unitConversionIdx={index}
    />
  );

  const renderRowWhileBeingDragged = (unitConversion) => (
    <TextField
      disabled
      controlled={false}
      defaultValue={unitConversion.name || '...dragging'}
    />
  );
  const hasUnitConversions =
    unitConversions.length ||
    Object.keys(formSubmissionDraft.groups).some(
      (groupKey) => _get(formSubmissionDraft.groups, groupKey).length,
    );

  if (!hasUnitConversions && !isLoading) {
    return (
      <EmptyTabContent title="Unit Conversions" addItem={addUnitConversion} />
    );
  }

  return (
    <EmissionTrackingTable
      addItem={addUnitConversion}
      formErrors={formErrors}
      formSubmissionDraft={formSubmissionDraft}
      renderRow={renderRow}
      renderRowWhileBeingDragged={renderRowWhileBeingDragged}
      groupType={GenericLogType.UNIT_CONVERSION}
      handleDeleteGroup={handleDeleteGroup}
      isLoading={isLoading}
      itemGroups={itemGroups}
      items={unitConversions}
      logProjectId={logProjectId}
      onDragEnd={onDragEnd}
      tableInfo={TABLE_SETTINGS.UNIT_CONVERSIONS}
    />
  );
};
UnitConversionsTable.propTypes = {
  addUnitConversion: PropTypes.func.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteUnitConversion: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  unitConversions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default UnitConversionsTable;
