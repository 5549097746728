/* eslint-disable */
var newCustomIconView = require('./newCustomIconView').singleton();
var iconCollection =
  require('../models_collections/iconCollection').singleton();

var APP = require('../config');

module.exports = Backbone.Marionette.ItemView.extend({
  events: {
    'click .fa-upload': '_handleFileUpload',
    'change #upload-custom-marker': '_promptForName',
    'change .label-display': '_handleChangeLabelDisplay',
    'change .leaderline-display': '_handleChangeLeaderlineDisplay',
  },

  templateHelpers() {
    let opacity = this.model.get('opacity');
    let isOpacitySelected = function (o) {
      return parseFloat(o) === parseFloat(opacity);
    };
    let labelTextSize = this.model.get('label_text_size');
    let isLabelTextSizeSelected = function (o) {
      return parseFloat(o) === parseFloat(labelTextSize);
    };
    let labelTextColor = this.model.get('label_text_color');
    let isLabelTextColorSelected = function (c) {
      return c === labelTextColor;
    };
    let color = this.model.get('color');
    let isColorSelected = function (c) {
      return c === color;
    };
    let labelStyle = this.model.get('label_style');
    let isLabelStyleSelected = function (s) {
      return s === labelStyle;
    };
    let labelBackgroundColor = this.model.get('label_background_color');
    let isLabelBackgroundColorSelected = function (s) {
      return s === labelBackgroundColor;
    };
    let labelShowChemicalAttributes = this.model.get(
      'label_show_chemical_attributes',
    );
    let isLabelShowChemicalAttributesSelected = function (s) {
      return s === labelShowChemicalAttributes;
    };
    let leaderlineColor = this.model.get('leaderline_color');
    let isLeaderlineColorSelected = (c) => c === leaderlineColor;
    let leaderlineStyle = this.model.get('leaderline_style');
    let isLeaderlineStyleSelected = (s) => s === leaderlineStyle;
    let leaderlineOpacity = this.model.get('leaderline_opacity');
    let isLeaderlineOpacitySelected = (o) =>
      parseFloat(o) === parseFloat(leaderlineOpacity);
    let leaderlineWeight = this.model.get('leaderline_weight');
    let isLeaderlineWeightSelected = (o) =>
      parseInt(o) === parseInt(leaderlineWeight);

    let polylinePolygonHelpers = {
      isOpacitySelected,
      isColorSelected,
      isLabelStyleSelected,
      isLabelTextColorSelected,
      isLabelTextSizeSelected,
      isLabelBackgroundColorSelected,
      isLabelShowChemicalAttributesSelected,
      isLeaderlineStyleSelected,
      isLeaderlineColorSelected,
      isLeaderlineOpacitySelected,
      isLeaderlineWeightSelected,
    };

    if (!this._isMarker()) {
      let weight = this.model.get('weight');
      let lineStyle = this.model.get('dash_array');
      let isLineStyleSelected = function (s) {
        return (s && lineStyle) || !(s || lineStyle);
      };
      let isWeightSelected = function (w) {
        return parseInt(w) === parseInt(weight);
      };
      return _.extend(polylinePolygonHelpers, {
        isLineStyleSelected,
        isWeightSelected,
      });
    }

    return polylinePolygonHelpers;
  },

  onShow() {
    if (
      this.model.has('label_style') &&
      this.model.get('label_style') === 'none'
    ) {
      if (this.ui.$leaderlineDisplayContainer) {
        this.ui.$leaderlineDisplayContainer.hide();
      }
      this.ui.$labelTextColorContainer.hide();
      this.ui.$labelTextSizeContainer.hide();
      this.ui.$labelBackgroundColorContainer.hide();
      this.ui.$labelShowChemicalAttributesContainer.hide();
    }

    if (
      this.model.has('leaderline_style') &&
      this.model.get('leaderline_style') === 'none'
    ) {
      this.ui.$leaderlineWeightContainer.hide();
      this.ui.$leaderlineOpacityContainer.hide();
      this.ui.$leaderlineColorContainer.hide();
    }
  },

  _isMarker() {
    return false;
  },

  _handleChangeLabelDisplay: function (e) {
    if ($(e.target).val() === 'none') {
      if (this.ui.$leaderlineDisplayContainer) {
        this.ui.$leaderlineDisplayContainer.slideUp();
      }
      this.ui.$labelSettings.slideUp();
      this.ui.$labelTextColorContainer.slideUp();
      this.ui.$labelTextSizeContainer.slideUp();
      this.ui.$labelBackgroundColorContainer.slideUp();
      this.ui.$labelShowChemicalAttributesContainer.slideUp();
    } else {
      if (this.ui.$leaderlineDisplayContainer) {
        this.ui.$leaderlineDisplayContainer.slideDown();
      }
      this.ui.$labelSettings.slideDown();
      this.ui.$labelTextColorContainer.slideDown();
      this.ui.$labelTextSizeContainer.slideDown();
      this.ui.$labelBackgroundColorContainer.slideDown();
      this.ui.$labelShowChemicalAttributesContainer.slideDown();
    }
  },

  _handleChangeLeaderlineDisplay(e) {
    if ($(e.target).val() === 'none') {
      this.ui.$leaderlineWeightContainer.slideUp();
      this.ui.$leaderlineOpacityContainer.slideUp();
      this.ui.$leaderlineColorContainer.slideUp();
    } else {
      this.ui.$leaderlineWeightContainer.slideDown();
      this.ui.$leaderlineOpacityContainer.slideDown();
      this.ui.$leaderlineColorContainer.slideDown();
    }
  },

  _handleFileUpload: function () {
    this.ui.$uploadCustomMarker.click();
  },

  _promptForName: function () {
    newCustomIconView.showModal();
  },

  _saveImage: function (name) {
    var _this = this;
    var file = this.ui.$uploadCustomMarker.prop('files')[0];
    if (!file) return;
    var data = new FormData();
    var reader = new FileReader();
    var img = new Image();
    var dataUrl;

    this._toggleButtons();

    // Reader is used for image preview
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      dataUrl = e.target.result;
      img.src = dataUrl;
    };

    img.onload = function () {
      data.append('file', file);
      data.append('name', name);
      data.append('is_wider', this.width > this.height);
      let options = { data: data, processData: false, contentType: false };
      return iconCollection
        .createPromise(null, options)
        .then((model) => {
          model.set('url', dataUrl);
          _this._updateImageName({ name: model.get('name'), dataUrl });

          if (_this._isMarker()) {
            _this.ui.$colorContainer.slideUp();
          }
        })
        .catch(APP.vent.trigger.bind(APP.vent, 'editLayerStylesView:error'))
        .finally(() => {
          _this.ui.$uploadCustomMarkerForm[0].reset();
          _this._toggleButtons();
        });
    };
  },

  _toggleButtons: function () {
    this.ui.$faUpload.toggle();
    this.ui.$faRefresh.toggle();
  },

  /**
   * Format styles for API call
   * @param newStyles - Object - Styles selected by the user
   */
  _formatStyles: function (newStyles) {
    return {
      color: newStyles.color,
      label_style: newStyles.label_style,
      label_show_chemical_attributes: newStyles.label_show_chemical_attributes,
      opacity: newStyles.opacity,
      align_line_decorator: newStyles.align_line_decorator,
      label_text_color: newStyles.label_text_color,
      label_text_size: newStyles.label_text_size,
      label_background_color: newStyles.label_background_color,
      leaderline_style: newStyles.leaderline_style,
      leaderline_color: newStyles.leaderline_color,
      leaderline_opacity: newStyles.leaderline_opacity,
      leaderline_weight: newStyles.leaderline_weight,
    };
  },

  /**
   * Converts the choice of 'solid' or 'dashed' line to a
   * dashArray which Leaflet understands
   *
   * @param lineStyle {string} - 'solid' or 'dashed'
   * @param weight {integer} - The weight of the line used to calculate dash line
   * @returns {string} - format for leaflet dashArray
   */
  _convertToDashArray: function (lineStyle, weight) {
    if (lineStyle === 'dashed') {
      return weight / 2 + ',' + weight * 2;
    }
    return '';
  },

  _appendCustomIcons() {
    // Append custom icons to shape list
    let customIconsList = [];
    iconCollection.each(function (icon) {
      customIconsList.push({
        dataUrl: icon.url(),
        name: icon.get('name'),
      });
    });
    this._addCustomIcons(customIconsList);
  },

  _updateImageName: function (options) {
    this._addCustomIcons([options]);
    this.ui.$selectpicker.val(options.name).selectpicker('refresh');
  },

  _addCustomIcons(optionsList) {
    _.each(optionsList, (options) => {
      this.ui.$selectpicker.append(
        this._customIconTemplate({
          url: options.dataUrl,
          name: options.name,
        }),
      );
    });
  },
});
