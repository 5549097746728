/* eslint-disable */
const SamplePeriodCircle = require('./SamplePeriodCircleView');

const {
  allSamplesMeetRequirements,
} = require('../../../../../../../../lib/samplingParameterResultsHelper');

const determineStatus = (
  semiAnnualParametersMeetRequirements,
  q1ParametersMeetRequirements,
  q2ParametersMeetRequirements,
) => {
  const completeStatus = 'complete';
  const incompleteStatus = 'incomplete';
  if (q1ParametersMeetRequirements && q2ParametersMeetRequirements) {
    if (semiAnnualParametersMeetRequirements) {
      return [completeStatus, completeStatus];
    } else {
      return [completeStatus, incompleteStatus];
    }
  } else if (!q1ParametersMeetRequirements && !q2ParametersMeetRequirements) {
    return [incompleteStatus, incompleteStatus];
  } else if (q1ParametersMeetRequirements) {
    return [completeStatus, incompleteStatus];
  }
  return [incompleteStatus, completeStatus];
};

module.exports = Backbone.Marionette.LayoutView.extend({
  regions: {
    sample1: '.sample-1',
    sample2: '.sample-2',
  },

  template: require('./templates/quarterOutfallAndFrequencySamplingPeriod.pug'),

  initialize(options = {}) {
    this.requiresSamplesAtAllLocations = options.requiresSamplesAtAllLocations;
    this.semiAnnualParameters = options.semiAnnualParameters;
    this.quarterlyParameters = options.quarterlyParameters;
    this.q1Label = options.q1Label;
    this.q2Label = options.q2Label;
    this.samplingResultsQ1 = options.samplingResultsQ1;
    this.samplingResultsQ2 = options.samplingResultsQ2;
  },

  templateHelpers() {
    return {
      q1Label: this.q1Label,
      q2Label: this.q2Label,
    };
  },

  onShow() {
    const semiAnnualParametersMeetRequirements =
      this.semiAnnualParameters.length === 0
        ? true
        : allSamplesMeetRequirements(
            this.semiAnnualParameters,
            this.samplingResultsQ1.concat(this.samplingResultsQ2),
            this.requiresSamplesAtAllLocations,
          );
    const q1ParametersMeetRequirements = allSamplesMeetRequirements(
      this.quarterlyParameters,
      this.samplingResultsQ1,
      this.requiresSamplesAtAllLocations,
    );
    const q2ParametersMeetRequirements = allSamplesMeetRequirements(
      this.quarterlyParameters,
      this.samplingResultsQ2,
      this.requiresSamplesAtAllLocations,
    );

    const [statusQ1, statusQ2] = determineStatus(
      semiAnnualParametersMeetRequirements,
      q1ParametersMeetRequirements,
      q2ParametersMeetRequirements,
    );

    this.sample1.show(
      new SamplePeriodCircle({
        status: statusQ1,
      }),
    );
    this.sample2.show(
      new SamplePeriodCircle({
        status: statusQ2,
      }),
    );
  },
});
