import PropTypes from 'prop-types';
import React from 'react';
import { isNullOrUndefined } from '../../../../utils';
import { LoadingIndicator } from '../../../elements';
import RecordPanelListItemExpandable from '../../../views/projectDashboard/RecordPanelListItemExpandable';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class FlowLogLazyFetchListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isFetching: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { details } = this.props;
    const dataHasBeenFetched =
      isNullOrUndefined(prevProps.details) && !isNullOrUndefined(details);
    if (dataHasBeenFetched) {
      this.setState({
        isExpanded: true,
        isFetching: false,
      });
    }
  }

  render() {
    const { isExpanded, isFetching } = this.state;
    const { details, onFetch, summary } = this.props;
    return (
      <RecordPanelListItemExpandable
        isExpanded={isExpanded}
        summary={
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={() => {
              if (details == null) {
                this.setState(
                  {
                    isExpanded: true,
                    isFetching: true,
                  },
                  onFetch,
                );
              } else {
                this.setState({
                  isExpanded: !isExpanded,
                });
              }
            }}
          >
            {summary}
          </div>
        }
        details={
          <>
            {isFetching && <LoadingIndicator isAbsolute={false} size={16} />}
            {!isFetching && details}
          </>
        }
      />
    );
  }
}

FlowLogLazyFetchListItem.defaultProps = {
  details: null,
  summary: null,
};

FlowLogLazyFetchListItem.propTypes = {
  details: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onFetch: PropTypes.func.isRequired,
  summary: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};
