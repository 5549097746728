/* eslint-disable */
import attrStore from '../attrStore';
import _ from 'underscore';
import { convertSetToArray } from '../utils';

function validateListAttr(listValues) {
  const lengthRangeAttrs = ['grid_number'];
  const errors = [];
  const ignoreList = [];
  const containerType = _.find(
    listValues,
    (kvp) => kvp.key === 'container_type',
  );

  if (containerType && !isContainerDescriptionRequired(containerType.value)) {
    ignoreList.push('container_description');
  }

  ignoreList.push.apply(
    ignoreList,
    _.chain(listValues)
      .filter((kvp) => !kvp.isShown)
      .map((kvp) => kvp.key)
      .value(),
  );

  listValues.forEach((kvp) => {
    if (ignoreList.indexOf(kvp.key) !== -1) {
      return;
    }
    if (!kvp.value && kvp.required) {
      errors.push(`${kvp.label} is required.`);
    } else if (kvp.value && kvp.validation) {
      const regExp = new RegExp(kvp.validation);
      const isLengthRangeAttr = lengthRangeAttrs.find(
        (attr) => attr === kvp.key,
      );

      if (isLengthRangeAttr && !regExp.test(kvp.value.length)) {
        errors.push(kvp.errorMessage);
      } else if (!isLengthRangeAttr && !regExp.test(kvp.value)) {
        errors.push(kvp.errorMessage);
      }
    }
  });
  return errors;
}

function isContainerDescriptionRequired(value) {
  if (Array.isArray(value)) {
    return value.indexOf('other') !== -1;
  } else {
    return value === 'other';
  }
}

function kvpsPropFilter(field) {
  return {
    key: field.key,
    label: field.label,
    value: field.value,
    required: field.required,
  };
}

function getChemicalAttributes(value) {
  const chemicalAttrs = value.find((attr) => attr.key === 'chemical-attrs');
  return chemicalAttrs ? chemicalAttrs.value : null;
}

function setChemicalAttributes(attributes, value, listKey) {
  if (!attributes) {
    attributes = attrStore.getListChemicalAttributes(listKey);
    attributes = setSwitchableFields(attributes, listKey);
    const chemicalAttrs = _.find(
      value,
      (attr) => attr.key === 'chemical-attrs',
    );
    if (chemicalAttrs) {
      chemicalAttrs.value = attributes;
    } else {
      value.push({
        key: 'chemical-attrs',
        label: 'Chemical Attrs',
        value: attributes,
      });
    }
  } else {
    attributes = setSwitchableFields(attributes, listKey);
  }

  return attributes;
}

function setSwitchableFields(attributes, listKey) {
  const switchableFields = attrStore.getSwitchableChemicalAttrs(listKey);

  attributes.forEach((attr) => {
    const switchableAttr = switchableFields.find((sAttr) => sAttr === attr.key);
    if (switchableAttr && !attr.switchable) {
      attr.switchable = true;
    }
  });

  return attributes;
}

function checkSwichableFields(value, listValues, templateKey) {
  const existedAttributes = getChemicalAttributes(value, templateKey);
  const attributes =
    existedAttributes || attrStore.getListChemicalAttributes(templateKey);

  listValues.forEach((field) => {
    if (field.switchable) {
      const attribute = attributes.find(
        (attr) => attr.key === field.relatedAttr,
      );
      field.isShown = attribute.selected;
    }
  });
}

function clearSelect2() {
  $(this.selectContainer).find('select').select2('close');
  $(this.selectContainer).find('select').select2('destroy');
}

function formatChemicalAttributes(templateKey) {
  return (attributes) => {
    const template = attrStore.getListChemicalAttributes(templateKey);

    if (!attributes) {
      return template;
    } else {
      const map = attributes.reduce((map, attr) => {
        map.set(attr.key, attr);
        return map;
      }, new Map());
      return template.map((templateAttr) => {
        const valueAttr = map.get(templateAttr.key);
        if (valueAttr) {
          return _.extend({}, templateAttr, { selected: valueAttr.selected });
        } else {
          return templateAttr;
        }
      });
    }
  };
}

function extractProjectSettings(projectSettings) {
  const HMBP_CHEMICALS_LOCATION_FIELD = 'hmbp-chemicals-location-field';
  const hmbpChemicalsLocationField = projectSettings.find(
    (item) => item.get('setting_key') === HMBP_CHEMICALS_LOCATION_FIELD,
  );
  return {
    hmbpChemicalsLocationField: hmbpChemicalsLocationField
      ? hmbpChemicalsLocationField.get('setting_value')
      : null,
  };
}

function buildingNameOptionMapper(value) {
  return (buildingName) => ({
    id: buildingName,
    text: buildingName,
    selected: value === buildingName,
  });
}

function getBuildingNames(layerCollection) {
  const nameSet = layerCollection.reduce((set, layer) => {
    layer.layerBuildingNames().forEach((name) => set.add(name));
    return set;
  }, new Set());
  return convertSetToArray(nameSet);
}

module.exports = {
  validateListAttr,
  kvpsPropFilter,
  getChemicalAttributes,
  setChemicalAttributes,
  setSwitchableFields,
  checkSwichableFields,
  clearSelect2,
  formatChemicalAttributes,
  extractProjectSettings,
  buildingNameOptionMapper,
  getBuildingNames,
  isContainerDescriptionRequired,
};
