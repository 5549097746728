import React from 'react';
import Select from '../../../elements/Select';
import FormField from './FormField';

class MultiSelectField extends FormField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);

    return (
      <Select
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        isMulti
        error={error}
      />
    );
  }

  formatOptions(templateField) {
    return templateField.options.map((option) => ({
      value: option,
      label: option,
    }));
  }

  formatValue(formattedOptions) {
    const parsedValue = this.getParsedValue();
    return parsedValue.map((value) =>
      formattedOptions.find((option) => option.value === value),
    );
  }

  getParsedValue() {
    const rawValue = this.getValue();
    return rawValue && rawValue.length ? rawValue.split(',') : [];
  }

  handleChange(selection) {
    const { templateField, onChange } = this.props;
    let newValue = '';
    if (selection && selection.length) {
      const selectedValues = selection.map((option) => option.value);
      newValue = selectedValues.join(',');
    }
    return onChange(templateField.slug, newValue);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MultiSelectField;
