import { CustomLogType } from '@monorepo/shared/apiClient/types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentLogProject } from '../../components/genericLogs/LogProjectContext';

export enum CustomLogTemplateRowType {
  limitItem = 'limitItem',
  loggedItem = 'loggedItem',
  waterLimitItem = 'waterLimitItem',
  waterLoggedItem = 'waterLoggedItem',
}

export enum CustomLogTemplateSectionType {
  calculatedValues = 'calculatedValues',
  factors = 'factors',
  limitItems = 'limitItems',
  loggedItems = 'loggedItems',
  rawData = 'rawData',
  rollingCalculations = 'rollingCalculations',
  unitConversions = 'unitConversions',
}

export enum CustomLogTemplateMenuCallbackType {
  addALog = 'addALog',
  downloadLogs = 'downloadLogs',
  logSettings = 'logSettings',
  logView = 'logView',
}

export type CustomLogTemplateSection = {
  path?: string;
  label?: string;
  type: CustomLogTemplateSectionType;
  rowType?: CustomLogTemplateRowType;
};

export type CustomLogTemplateMenu = {
  label: string;
  iconIdentifier: string;
  type: CustomLogTemplateMenuCallbackType;
};

export type CustomLogTemplateSettings = {
  menu: CustomLogTemplateMenu[];
  sections: CustomLogTemplateSection[];
};

export type CustomLogTemplateView = {
  availableSections: CustomLogTemplateSection[];
  menu: CustomLogTemplateMenu[];
  requiredSections: CustomLogTemplateSection[];
};

export type CustomLogTemplate = {
  type: CustomLogType;
  hasPermitStartDate: boolean;
  settings: CustomLogTemplateSettings;
  view: CustomLogTemplateView;
};

const templateMap = {
  custom: {
    type: CustomLogType.custom,
    hasPermitStartDate: false,
    settings: {
      sections: [
        {
          path: '/logged-items',
          label: 'Logged items',
          type: CustomLogTemplateSectionType.loggedItems,
          rowType: CustomLogTemplateRowType.loggedItem,
        },
        {
          path: '/factors',
          label: 'Factors',
          type: CustomLogTemplateSectionType.factors,
        },
        {
          path: '/limit-items',
          label: 'Limit items',
          type: CustomLogTemplateSectionType.limitItems,
          rowType: CustomLogTemplateRowType.limitItem,
        },
        {
          path: '/calculated-items',
          label: 'Calculated values',
          type: CustomLogTemplateSectionType.calculatedValues,
        },
        {
          path: '/rolling-calculations',
          label: 'Rolling calculations',
          type: CustomLogTemplateSectionType.rollingCalculations,
        },
        {
          path: '/unit-conversion',
          label: 'Unit conversion',
          type: CustomLogTemplateSectionType.unitConversions,
        },
      ],
      menu: [
        {
          label: 'View all logs',
          iconIdentifier: 'documents',
          type: CustomLogTemplateMenuCallbackType.logView,
        },
      ],
    },
    view: {
      availableSections: [
        {
          type: CustomLogTemplateSectionType.rawData,
        },
      ],
      menu: [
        {
          label: 'Add a log',
          iconIdentifier: 'add',
          type: CustomLogTemplateMenuCallbackType.addALog,
        },
        {
          label: 'Log Settings',
          iconIdentifier: 'cog',
          type: CustomLogTemplateMenuCallbackType.logSettings,
        },
        {
          label: 'Download logs',
          iconIdentifier: 'download',
          type: CustomLogTemplateMenuCallbackType.downloadLogs,
        },
      ],
      requiredSections: [
        {
          type: CustomLogTemplateSectionType.rawData,
        },
      ],
    },
  },
  stormwater: {
    type: CustomLogType.stormwater,
    hasPermitStartDate: true,
    settings: {
      sections: [
        {
          path: '/logged-parameters',
          label: 'Logged parameters',
          type: CustomLogTemplateSectionType.loggedItems,
          rowType: CustomLogTemplateRowType.waterLoggedItem,
        },
        {
          path: '/parameter-limits',
          label: 'Parameter limits',
          type: CustomLogTemplateSectionType.limitItems,
          rowType: CustomLogTemplateRowType.waterLimitItem,
        },
      ],
      menu: [
        {
          label: 'View all sampling results',
          iconIdentifier: 'documents',
          type: CustomLogTemplateMenuCallbackType.logView,
        },
      ],
    },
    view: {
      availableSections: [
        {
          label: 'Summary view',
          type: CustomLogTemplateSectionType.rawData, // TODO: change this value when the new view exists
        },
      ],
      menu: [
        {
          label: 'Add a log',
          iconIdentifier: 'add',
          type: CustomLogTemplateMenuCallbackType.addALog,
        },
        {
          label: 'Log Settings',
          iconIdentifier: 'cog',
          type: CustomLogTemplateMenuCallbackType.logSettings,
        },
        {
          label: 'Download logs',
          iconIdentifier: 'download',
          type: CustomLogTemplateMenuCallbackType.downloadLogs,
        },
      ],
      requiredSections: [
        {
          label: 'Raw data view',
          type: CustomLogTemplateSectionType.rawData,
        },
      ],
    },
  },
  water: {
    type: CustomLogType.water,
    hasPermitStartDate: true,
    settings: {
      sections: [
        {
          path: '/logged-parameters',
          label: 'Logged parameters',
          type: CustomLogTemplateSectionType.loggedItems,
          rowType: CustomLogTemplateRowType.waterLoggedItem,
        },
        {
          path: '/parameter-limits',
          label: 'Parameter limits',
          type: CustomLogTemplateSectionType.limitItems,
          rowType: CustomLogTemplateRowType.waterLimitItem,
        },
      ],
      menu: [
        {
          label: 'View all sampling results',
          iconIdentifier: 'documents',
          type: CustomLogTemplateMenuCallbackType.logView,
        },
      ],
    },
    view: {
      availableSections: [
        {
          label: 'Summary view',
          type: CustomLogTemplateSectionType.rawData, // TODO: change this value when the new view exists
        },
      ],
      menu: [
        {
          label: 'Add a log',
          iconIdentifier: 'add',
          type: CustomLogTemplateMenuCallbackType.addALog,
        },
        {
          label: 'Log Settings',
          iconIdentifier: 'cog',
          type: CustomLogTemplateMenuCallbackType.logSettings,
        },
        {
          label: 'Download logs',
          iconIdentifier: 'download',
          type: CustomLogTemplateMenuCallbackType.downloadLogs,
        },
      ],
      requiredSections: [
        {
          label: 'Raw data view',
          type: CustomLogTemplateSectionType.rawData,
        },
      ],
    },
  },
};

export const useCustomLogTemplates = (): CustomLogTemplate => {
  const { logProject } = useCurrentLogProject();
  return templateMap[logProject?.type ?? CustomLogType.custom];
};

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
export const withLogTemplate = (Comp) => (props) => {
  const logTemplate = useCustomLogTemplates();
  return <Comp logTemplate={logTemplate} {...props} />;
};

export const useLocateTemplateSettingsSection = (
  template: CustomLogTemplate,
) => {
  const { pathname } = useLocation();
  const parts = pathname.split('/');
  const sectionPath = `/${parts[parts.length - 1]}`;
  return template.settings.sections.find(
    (section) => sectionPath === section.path,
  );
};
