import { CustomLogType } from '@monorepo/shared/apiClient/types';
import { CalendarName, GenericLogLoggedItemType } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useCustomLogTemplates } from '../../../../hooks/genericLogs/useCustomLogTemplates';
import Units from '../../../../utils/units';
import { Select } from '../../../elements';
import { LoggedItemFormSubmission } from './types';

interface SelectUnitProps {
  errors: { [field: string]: string };
  loggedItem: LoggedItemFormSubmission;
  menuRef: React.RefObject<HTMLDivElement>;
  onChange: (option: string) => void;
}

const UNIT_OPTIONS = Units.getAllForSuite(CalendarName.GENERIC_LOG);

export const SelectUnit = ({
  errors,
  loggedItem,
  menuRef,
  onChange,
}: SelectUnitProps) => {
  const { type: logTemplateType } = useCustomLogTemplates();

  const unitOptions = useMemo(
    () =>
      loggedItem.isLocked
        ? Units.getRelatedUnits(loggedItem.units)
        : Units.getAllForSuite(CalendarName.GENERIC_LOG),
    [loggedItem],
  );

  const handleOnChange = useCallback(
    (option) => onChange(option?.value),
    [onChange],
  );

  return (
    <Select
      className="flex-grow-1"
      error={!!errors?.units}
      isClearable={false}
      isDisabled={
        logTemplateType === CustomLogType.custom &&
        loggedItem.itemType !== GenericLogLoggedItemType.NUMBER
      }
      menuPlacement="auto"
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      menuPortalTarget={menuRef.current}
      options={unitOptions}
      onChange={handleOnChange}
      value={UNIT_OPTIONS.find((o) => o.value === loggedItem.units)}
    />
  );
};
