var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getAverageDisplayText, getAverageLimitDisplayText, getQuarterlyAverageLabel, name, quarterly_average, quarterly_average_limit) {
      pug_html = pug_html + "\u003Ctd\u003EQuarterly Avg for Location " + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getAverageDisplayText(quarterly_average)) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E\u003C\u002Ftd\u003E\u003Ctd style=\"width: 300px\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = '(' + getQuarterlyAverageLabel + ': ' + getAverageLimitDisplayText(quarterly_average_limit) + ')') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E";
    }.call(this, "getAverageDisplayText" in locals_for_with ?
        locals_for_with.getAverageDisplayText :
        typeof getAverageDisplayText !== 'undefined' ? getAverageDisplayText : undefined, "getAverageLimitDisplayText" in locals_for_with ?
        locals_for_with.getAverageLimitDisplayText :
        typeof getAverageLimitDisplayText !== 'undefined' ? getAverageLimitDisplayText : undefined, "getQuarterlyAverageLabel" in locals_for_with ?
        locals_for_with.getQuarterlyAverageLabel :
        typeof getQuarterlyAverageLabel !== 'undefined' ? getQuarterlyAverageLabel : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "quarterly_average" in locals_for_with ?
        locals_for_with.quarterly_average :
        typeof quarterly_average !== 'undefined' ? quarterly_average : undefined, "quarterly_average_limit" in locals_for_with ?
        locals_for_with.quarterly_average_limit :
        typeof quarterly_average_limit !== 'undefined' ? quarterly_average_limit : undefined));
    ;;return pug_html;};
module.exports = template;