import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import IconButton from '../../../elements/buttons/IconButton';

function EmptyDuplicableSection(props) {
  const { templateGroup, onCreateSubsection } = props;

  return (
    <div className="empty-duplicable-section">
      <span>{`There are currently no ${templateGroup.name}.`}</span>
      <IconButton
        className="create-section-button grow"
        onClick={() => onCreateSubsection(templateGroup.id)}
      >
        <SvgIcon identifier="add" className="create-section-icon" />
        <span>{`Add ${templateGroup.name}`}</span>
      </IconButton>
    </div>
  );
}

EmptyDuplicableSection.propTypes = {
  templateGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onCreateSubsection: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EmptyDuplicableSection;
