var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (hasAlreadyRequestedDownload, isSwpppProject, planName, swpppSubmissionId, swpppUpload) {
      pug_html = pug_html + "\u003Cdiv class=\"flex right-col-heading justify-content-space-between heading\"\u003E\u003Cdiv class=\"flex align-items-center\"\u003E\u003Cdiv class=\"list-header\"\u003E" + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex align-items-center\"\u003E";
if (isSwpppProject) {
pug_html = pug_html + "\u003Ca" + (" class=\"view-swppp padding-left-10 flex\""+" rel=\"tipsy\""+pug.attr("title", 'Create or edit your ' + planName, true, true)) + "\u003E\u003Csvg class=\"edit\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
else {
if (swpppUpload) {
pug_html = pug_html + "\u003Ca" + (" class=\"margin-right-10\""+" rel=\"tipsy\" href=\"#\""+pug.attr("title", 'Download your ' + planName, true, true)) + "\u003E\u003Csvg class=\"download fa-cloud-download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ci class=\"fa fa-spinner fa-spin swppp-spinner\"\u003E\u003C\u002Fi\u003E\u003Ca class=\"download-user-supplied-pdf\"\u003E" + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Ca" + (" class=\"margin-left-10\""+" href=\"#\" rel=\"tipsy\""+pug.attr("title", 'Upload a new version of your ' + planName, true, true)) + "\u003E\u003Csvg class=\"upload upload-pdf fa-cloud-upload\"\u003E\u003Cuse xlink:href=\"#upload\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ci class=\"fa fa-spinner fa-spin upload-spinner\"\u003E\u003C\u002Fi\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"upload-user-supplied-pdf\"\u003E" + (pug.escape(null == (pug_interp = planName) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003Ca href=\"#\"\u003E\u003Ci class=\"fa fa-cloud-upload padding-left-7\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003Ci class=\"fa fa-spinner fa-spin upload-spinner padding-left-7\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Cinput id=\"upload-swppp-pdf\" type=\"file\" name=\"file\" accept=\".pdf,.doc,.docx,.docm,.dtox,.dotm,.dot,.docb,.txt\"\u003E";
}
if (swpppSubmissionId) {
if (hasAlreadyRequestedDownload) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"margin-left-15 color-light-gray\""+" rel=\"tipsy\""+pug.attr("title", `The ${planName} is being processed and you will receive an email with a link to download it.`, true, true)) + "\u003E\u003Csvg class=\"download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (" class=\"margin-left-15\""+" id=\"download-form-pdf\" href=\"#\" rel=\"tipsy\""+pug.attr("title", `Request PDF generated by Mapistry's ${planName} builder`, true, true)) + "\u003E\u003Csvg class=\"download\"\u003E\u003Cuse xlink:href=\"#download\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "hasAlreadyRequestedDownload" in locals_for_with ?
        locals_for_with.hasAlreadyRequestedDownload :
        typeof hasAlreadyRequestedDownload !== 'undefined' ? hasAlreadyRequestedDownload : undefined, "isSwpppProject" in locals_for_with ?
        locals_for_with.isSwpppProject :
        typeof isSwpppProject !== 'undefined' ? isSwpppProject : undefined, "planName" in locals_for_with ?
        locals_for_with.planName :
        typeof planName !== 'undefined' ? planName : undefined, "swpppSubmissionId" in locals_for_with ?
        locals_for_with.swpppSubmissionId :
        typeof swpppSubmissionId !== 'undefined' ? swpppSubmissionId : undefined, "swpppUpload" in locals_for_with ?
        locals_for_with.swpppUpload :
        typeof swpppUpload !== 'undefined' ? swpppUpload : undefined));
    ;;return pug_html;};
module.exports = template;