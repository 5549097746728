import React from 'react';
import { ComponentWrapper } from '../../../components/componentWrapper';
import { TaskBulkUpload } from '../../../components/settings/TaskBulkUpload';

const TaskBulkUploadLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/taskBulkUpload.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    content: '#task-bulk-upload',
  },

  onShow() {
    this.content.show(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      new ComponentWrapper(<TaskBulkUpload />, { noStyling: true }),
    );
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TaskBulkUploadLayoutView;
