/* eslint-disable */
const apiCaller = require('../../../../../apiCaller');
const ProjectTagCollection = require('./ProjectTagCollection');
const TagtypeCollection = require('../../../../../models_collections/tagTypeCollection');
const TagCollectionView = require('./TagCollectionView');

let TagsView = Backbone.Marionette.LayoutView.extend({
  className: 'flex align-items-center',
  template: require('./templates/tags.pug'),
  regions: {
    projectTags: '#project-tags',
    editProjectTags: '#edit-project-tags',
    tagsModal: '#tags-modal',
  },

  ui: {
    $modal: '.modal',
    $tagtypeSelect: '#tag-type-selection',
    $tagNameSelect: '#tag-name-selection',
    $tagNameSelectionGroup: '#tag-name-selection-group',
    $error: '.alert-danger',
  },

  events: {
    'click .edit-tags': '_showModal',
    'change #tag-type-selection': '_handleTagtypeChange',
    'select2:selecting #tag-name-selection': '_handleAddTag',
    'select2:unselecting #tag-name-selection': '_handleRemoveTag',
  },

  behaviors: {
    Tooltip: {},
  },

  templateHelpers() {
    let self = this;

    return {
      hasProjectUpdatePermission: self.hasProjectUpdatePermission,
    };
  },

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    Promise.all([this._showTags(), this._setTagtypeSelection()]).then(
      this._setProjectTagSelection.bind(this),
    );
  },

  _showTags() {
    this.projectTagCollection = new ProjectTagCollection({
      projectId: this.model.get('id'),
    });

    this.projectTagCollection.fetch().then(() => {
      let tagsView = new TagCollectionView({
        collection: this.projectTagCollection,
      });
      this.projectTags.show(tagsView);
    });
  },

  _setTagtypeSelection() {
    this.tagtypeCollection = new TagtypeCollection({
      organizationId: this.model.get('organization_id'),
    });
    return this.tagtypeCollection.fetch().then(() => {
      let data = this.tagtypeCollection.map((tagtype) => {
        return { id: tagtype.get('id'), text: tagtype.get('name') };
      });

      this.ui.$tagtypeSelect.select2({
        placeholder: 'There are no tag types for the organization',
        width: '100%',
        data,
      });

      return this._fetchTagTypes();
    });
  },

  _fetchTagTypes() {
    let tagtypeId = this.ui.$tagtypeSelect.val();
    if (!tagtypeId) return Promise.resolve();

    let url = apiCaller.getTypeTagsUrl(tagtypeId);
    return new Promise((resolve, reject) => {
      $.get(url)
        .done((data) => {
          this.tagtypeTags = data;
          resolve();
        })
        .fail((jqXHR) => {
          reject(jqXHR.responseText);
        });
    });
  },

  // We are lazy loading the tag selection for each tag type, currently.
  // We could get tags for all tagtypes up front to appear more performant
  _setProjectTagSelection() {
    if (this.tagtypeCollection.length === 0) {
      this.ui.$tagNameSelectionGroup.hide();
      return;
    }

    let data = this.tagtypeTags.map((tag) => {
      let existingProjectTag = this.projectTagCollection.find(
        (projectTag) =>
          projectTag.get('tagtype_id') === tag.tagtype_id &&
          projectTag.get('tag_name') === tag.tag_name,
      );
      let selected = existingProjectTag != undefined;
      return { id: tag.tagtype_id, text: tag.tag_name, selected };
    });

    this.ui.$tagNameSelect.select2({
      placeholder: 'Please select the tag names',
      width: '100%',
      data,
      tags: true,
    });
  },

  _resetProjectTagSelection() {
    this.ui.$tagNameSelect.select2().empty();
    this._setProjectTagSelection();
  },

  _showModal() {
    this.ui.$modal.modal('show');
  },

  _handleTagtypeChange(e) {
    this._fetchTagTypes().then(this._resetProjectTagSelection.bind(this));
  },

  _handleAddTag(e) {
    this._clearError();
    let tagtypeId = this.ui.$tagtypeSelect.val();
    let newTagName = e.params.args.data.text;

    this._createTag(tagtypeId, newTagName);
  },

  _handleRemoveTag(e) {
    let tagtypeId = e.params.args.data.id;
    let tagName = e.params.args.data.text;
    this._deleteTag(tagtypeId, tagName);
  },

  _createTag(tagtypeId, newTagName) {
    this.projectTagCollection.create(
      { tagtype_id: tagtypeId, name: newTagName.trim() },
      {
        wait: true,
        success: this._tagCreated.bind(this),
        error: this._tagActionFailure.bind(
          this,
          'There was a problem creating the tag type. Please try again later.',
        ),
      },
    );
  },

  _deleteTag(tagtypeId, tagName) {
    this.projectTagCollection
      .find((projectTag) => {
        return (
          projectTag.get('tagtype_id') === tagtypeId &&
          projectTag.get('tag_name') === tagName
        );
      })
      .destroy({
        wait: true,
        error: this._tagActionFailure.bind(
          this,
          'There was a problem deleting the tag type. Please try again later.',
        ),
      });
  },

  _tagCreated(newTag) {
    let existingTagChoice = _.find(
      this.tagtypeTags,
      (tag) => tag.tag_name === newTag.get('tag_name'),
    );
    if (existingTagChoice === undefined) {
      this.tagtypeTags.push(newTag.toJSON());
    }
    this._resetProjectTagSelection();
  },

  _tagActionFailure(message) {
    this._clearError();
    this._showError(message);
    this._resetProjectTagSelection();
  },

  _showError(message) {
    this.ui.$error.html(message).slideDown().delay(5000).slideUp();
  },

  _clearError() {
    this.ui.$error.html('').slideUp();
  },
});

module.exports = TagsView;
