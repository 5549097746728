/* eslint-disable */
var APP = require('../config');

/**
 * The model representing the upload table.
 *
 * Attributes:
 *     step: number
 *         The step number the user is currently on in upload process
 *     totalSteps: number
 *         The total number of steps in the process
 */
var Table = Backbone.Model.extend({
  initialize: function () {
    this.set({ breadcrumbs: [], columns: {}, projectId: APP.projectId });
  },

  storeColumnNames: function (columns) {
    var attrs = {};

    _.each(columns, function (name, index) {
      attrs[index] = name;
    });
    this.set({ attributes: attrs });
  },

  /**
   * Checks to see if user is at first step in the upload process
   */
  isAtFirstStep: function () {
    return this.getCurrentStep() === 'layerName';
  },

  /**
   * Checks to see if user is at the file type question in the upload
   * process
   *
   * Return:
   *     boolean
   *        true if use is at the file type question step, false otherwise
   */
  isAtFileTypeStep: function () {
    return this.getCurrentStep() === 'filetype';
  },

  /**
   * Checks to see if user is at the separate columns question in the upload
   * process
   *
   * Parameters:
   *     None
   *
   * Return:
   *     boolean
   *        true if use is at the separate columns question step, false otherwise
   */
  isAtSeparateColumnsStep: function () {
    return this.getCurrentStep() === 'separateColumns';
  },

  /**
   * Checks to see if user is at the postal code step in the upload process
   *
   * Parameters:
   *     None
   *
   * Return:
   *     boolean
   *        true if use is at the postal code step, false otherwise
   */
  isAtPostalCodeStep: function () {
    return this.getCurrentStep() === 'Postal Code';
  },

  /** Checks to see if user is at any of the column picking steps in the upload process
   *
   * Parameters:
   *     None
   *
   * Return:
   *     boolean
   *        true if use is at any of the column picking steps, false otherwise
   */
  isAtColumnPickingStep: function () {
    var steps = _.values(this.getColumnPickingSteps());
    return _.contains(steps, this.getCurrentStep());
  },

  /** Checks to see if user is at the question that asks whether there are separate
   * columns for addresses in the upload process
   *
   * Parameters:
   *     None
   *
   * Return:
   *     boolean
   *        true if use is at the question that asks whether there are separate
   *        columns for addresses, false otherwise
   */
  isAtUnstructuredAddressStep: function () {
    return this.getCurrentStep() === 'Address';
  },

  /** Checks to see if user is at last step in the upload process
   *
   * Parameters:
   *     None
   *
   * Return:
   *     boolean
   *        true if use is at the feature label step, false otherwise
   */
  isAtLastStep: function () {
    return this.getCurrentStep() === 'Feature Label';
  },

  /* Gets data about the current column
   *
   * Parameters:
   *     None
   *
   * Return:
   *     obj
   *        Contains the index of the column as well as the original column header
   */
  getCurrentColumnData: function () {
    return this.get('columns')[this.getCurrentStep()];
  },

  /* Gets all the column picking steps
   *
   * Parameters:
   *     None
   *
   * Return:
   *     obj
   *        The values are the strings used for each step
   */
  getColumnPickingSteps: function () {
    return {
      latitude: 'Latitude',
      longitude: 'Longitude',
      featureLabel: 'Feature Label',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      postalCode: 'Postal Code',
      address: 'Address',
    };
  },

  /* Updates the breadcrumbs when going to the next step
   *
   * Parameters:
   *     None
   *
   * Return:
   *     None
   */
  addToBreadcrumbs: function (breadcrumb) {
    this.attributes.breadcrumbs.push(breadcrumb);
  },

  /* Updates the breadcrumbs when going back a step
   *
   * Parameters:
   *     None
   *
   * Return:
   *     None
   */
  popBreadcrumbs: function () {
    this.attributes.breadcrumbs.pop();
  },

  /* Gets the current step
   *
   * Parameters:
   *     None
   *
   * Return:
   *     string
   *        The current step that the user is on
   */
  getCurrentStep: function () {
    return this.attributes.breadcrumbs.slice(-1)[0];
  },

  /* Updates column data stored in case user needs to revert column to original
   * state
   *
   * Parameters:
   *    index: number
   *        The index of the column
   *    columnName: string
   *        The current column header
   *    originalColumnName: string
   *        The original column header
   *
   * Return:
   *     None
   */
  _updateColumnData: function (index, columnName, originalColumnName) {
    this.attributes.columns[columnName] = {
      index: index,
      originalColumnName: originalColumnName,
    };
  },

  /* Updates the breadcrumbs when the user is on the column picking steps
   *
   * Parameters:
   *     index: number
   *        The column index
   *     originalColumnName: string
   *        The original column header
   *
   * Return:
   *     string
   *        The current step that the user is on after the update
   */
  updateBreadcrumbs: function (index, originalColumnName) {
    var steps = this.getColumnPickingSteps(),
      latitude = steps.latitude,
      longitude = steps.longitude,
      featureLabel = steps.featureLabel,
      streetAddress = steps.streetAddress,
      city = steps.city,
      state = steps.state,
      postalCode = steps.postalCode,
      address = steps.address,
      currentStep;

    switch (this.getCurrentStep()) {
      case latitude:
        this.set({ latitudeColumn: index, latitudeColumnHeader: latitude });
        this._updateColumnData(index, latitude, originalColumnName);
        currentStep = longitude;
        break;
      case longitude:
        this.set({ longitudeColumn: index, longitudeColumnHeader: longitude });
        this._updateColumnData(index, longitude, originalColumnName);
        currentStep = featureLabel;
        break;
      case featureLabel:
        this.set({ nameColumn: index, nameColumnHeader: featureLabel });
        this._updateColumnData(index, featureLabel, originalColumnName);
        currentStep = 'end';
        break;
      case streetAddress:
        this.set({
          streetAddressColumn: index,
          streetAddressColumnHeader: streetAddress,
        });
        this._updateColumnData(index, streetAddress, originalColumnName);
        currentStep = city;
        break;
      case city:
        this.set({ cityColumn: index, cityColumnHeader: city });
        this._updateColumnData(index, city, originalColumnName);
        currentStep = state;
        break;
      case state:
        this.set({ stateColumn: index, stateColumnHeader: state });
        this._updateColumnData(index, state, originalColumnName);
        currentStep = postalCode;
        break;
      case postalCode:
        this.set({
          postalCodeColumn: index,
          postalCodeColumnHeader: postalCode,
        });
        this._updateColumnData(index, postalCode, originalColumnName);
        currentStep = featureLabel;
        break;
      case address:
        this.set({
          unstructuredAddressColumn: index,
          unstructuredAddressColumnHeader: address,
        });
        this._updateColumnData(index, address, originalColumnName);
        currentStep = featureLabel;
        break;
    }

    this.addToBreadcrumbs(currentStep);

    return currentStep;
  },

  /**
   * Updates any user attr columns if they edit the name of the column
   *
   * Parameters:
   *     colName: string
   *        The new colName defined by the user
   *
   * Return:
   *     None
   */
  updateAttribute: function (colName) {
    this.get('attributes')[this.get('currentEditIndex')] = colName;
  },

  /** Converts this model into JSON which compatible with what the server is expecting
   *
   * Parameters:
   *     None
   *
   * Return:
   *     obj
   *        Contains the data needed to create the features from the file
   */
  toJSON: function () {
    var attrs = Backbone.Model.prototype.toJSON.call(this),
      nonUserAttrsIndexes = _.map(attrs.columns, function (col) {
        return col.index !== undefined ? col.index.toString() : undefined;
      });

    // Replace empty columns with place holders and construct an array that
    // lists the indicies of the attributes in the row. Convert attributes
    // from an object to an array
    var emptyColumnHeaderCounter = 0;
    var newAttributes = [];
    attrs.attributeIndices = [];
    _.each(attrs.attributes, function (a, index) {
      if (!_.contains(nonUserAttrsIndexes, index)) {
        attrs.attributeIndices.push(index);
        if (a === '') {
          emptyColumnHeaderCounter++;
          newAttributes.push('Column' + (emptyColumnHeaderCounter - 1));
        } else {
          newAttributes.push(a);
        }
      }
    });
    attrs.attributes = newAttributes;

    delete attrs.breadcrumbs;
    delete attrs.columns;

    attrs.type = 'marker';

    return attrs;
  },
});

module.exports = Table;
