var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (description, is_checked, slug) {
      pug_html = pug_html + "\u003Clabel\u003E";
if (is_checked) {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\" name=\"industrial_activities\""+pug.attr("value", slug, true, true)+pug.attr("checked", true, true, true)) + "\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"checkbox\" name=\"industrial_activities\""+pug.attr("value", slug, true, true)) + "\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"pl3\"\u003E" + (pug.escape(null == (pug_interp = description) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E";
    }.call(this, "description" in locals_for_with ?
        locals_for_with.description :
        typeof description !== 'undefined' ? description : undefined, "is_checked" in locals_for_with ?
        locals_for_with.is_checked :
        typeof is_checked !== 'undefined' ? is_checked : undefined, "slug" in locals_for_with ?
        locals_for_with.slug :
        typeof slug !== 'undefined' ? slug : undefined));
    ;;return pug_html;};
module.exports = template;