/* eslint-disable */
let LocationRowView = require('./LocationRowView');
let NoLocationRowView = require('./NoLocationRowView');
let projectStateConfig = require('mapistry-project-state-config');
let dateService = require('../../../../../../../../services/dateService');

let LocationCompositeView = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/locationCompositeView.pug'),

  childView: LocationRowView,

  childViewContainer: '#location-rows',

  emptyView: NoLocationRowView,

  emptyViewContainer: '#location-rows',

  behaviors: {
    DisplayMessages: {},
  },

  events: {
    'click @ui.$saveButton': '_saveLocation',
  },

  ui: {
    $errorContainer: '.alert-danger',
    $successContainer: '.alert-success',
    $saveButton: '.save-location',
  },

  templateHelpers() {
    return {
      isEmpty: this.collection.length === 0,
      isPHLinked: this.isPHLinked,
      parameterSlug: this.parameterSlug,
    };
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.parameterSlug = options.parameterSlug;

    let project = this.projectModel.toJSON();
    this.isPHLinked =
      projectStateConfig.isCaliforniaIndustrial(project) ||
      projectStateConfig.isOneOfStatesIndustrial(project, [
        'al',
        'ar',
        'il',
        'la',
        'nc',
        'nj',
        'oh',
        'pa',
        'sc',
        'tn',
        'wv',
      ]);
  },

  childViewOptions() {
    return {
      projectModel: this.projectModel,
      parameterSlug: this.parameterSlug,
      isPHLinked: this.isPHLinked,
    };
  },

  _getDataForAllLocations() {
    return _.compact(
      this.children.map(function (view) {
        let model = view.model;
        let data = {
          parameter_value: view.ui.$parameterValue.val() || null,
          method_slug: view.ui.$method.val() || null,
          detection_limit: view.ui.$detectionLimit.val() || null,
          reporting_limit: view.ui.$reportingLimit.val() || null,
          analysis_date: view.ui.$datepicker.find('input').val() || null,
        };

        let errorMsg = this._validate(data, model.get('parameter_slug'));
        if (errorMsg) {
          return { view, errorMsg: model.get('name') + ': ' + errorMsg };
        }

        return { data, view };
      }, this),
    );
  },

  _showSaveMessages(dataToSave) {
    let modelsNotSavable = _.filter(dataToSave, (sd) => sd.errorMsg);
    let delayTime = 3000 + modelsNotSavable.length * 1000;

    if (modelsNotSavable && modelsNotSavable.length) {
      let message = `<strong>Could not save changes for the following locations</strong>:<br>${_.pluck(
        modelsNotSavable,
        'errorMsg',
      ).join('<br>')}`;
      this.triggerMethod('ShowError', { message }, delayTime);
      _.each(modelsNotSavable, (mns) =>
        mns.view.$el.removeClass().addClass('bg-danger'),
      );
    } else {
      _.each(dataToSave, (mns) => mns.view.$el.removeClass());
      this.triggerMethod(
        'ShowSuccess',
        '<strong>Save successful</strong>',
        delayTime,
      );
    }
  },

  _saveLocation() {
    let dataToSave = this._getDataForAllLocations();
    this._showSaveMessages(dataToSave);

    let modelsSaveable = _.filter(dataToSave, (sd) => !sd.errorMsg);
    let promises = modelsSaveable.map((sd) => {
      return sd.view.model.savePromise(sd.data, { wait: true }).then(() => {
        sd.view.render();
      });
    });

    return Promise.all(promises).catch(
      this.triggerMethod.bind(this, 'ShowError'),
    );
  },

  _validate(data, parameterSlug) {
    let detectionLimit = parseFloat(data.detection_limit);
    let reportingLimit = parseFloat(data.reporting_limit);
    let parameterValue = data.parameter_value;
    let matches = parameterValue && parameterValue.match(/\d+(\.\d+)?/);

    if (parameterSlug !== 'ph') {
      if (!_.isFinite(detectionLimit)) {
        return 'The method detection limit must be a number.';
      } else if (!_.isFinite(reportingLimit)) {
        return 'The reporting limit must be a number.';
      } else if (
        data.detection_limit &&
        data.reporting_limit &&
        detectionLimit > reportingLimit
      ) {
        return 'The method detection limit must be less than the reporting limit.';
      } else if (
        !(
          parameterValue === '<' + data.detection_limit ||
          parameterValue === 'ND' ||
          _.isFinite(parseFloat(parameterValue))
        )
      ) {
        return (
          'The parameter value must be a number OR "<' +
          data.detection_limit +
          '" (your detection limit) OR "ND" (for non detect)'
        );
      } else if (!dateService.isValidDate(data.analysis_date)) {
        return 'There is an invalid analysis date';
      }
    } else {
      if (parameterValue && !dateService.isValidDate(data.analysis_date)) {
        return 'There is an invalid analysis date';
      } else if (!parameterValue) {
        return 'Please add pH value and method to the corresponding Sampling Observation form.';
      }
    }
    return '';
  },
});

module.exports = LocationCompositeView;
