/* eslint-disable */
var AverageRowView = require('./AverageRowView');

var AnnualAverageRowView = AverageRowView.extend({
  tagName: 'table',

  className: 'table',

  template: require('./templates/annualAverageRow.pug'),

  modelEvents: {
    'change:annual_average': 'render',
  },

  events: {
    'click .open-sector-data-modal': '_openSectorDataModal',
  },

  onRender() {
    this.$('.warning-yellow').popover({
      html: true,
      content: function () {
        return $('#harndess-warning-popover-content-wrapper').html();
      },
    });
  },

  _getAverage() {
    return this.model.get('annual_average')[this.swpppYear];
  },

  _getAnnualAverageSatisfied() {
    return this.model.has('annual_average_satisfied')
      ? this.model.get('annual_average_satisfied')[this.swpppYear]
      : true;
  },

  _openSectorDataModal() {
    let APP = require('../../../../../../../config');
    APP.vent.trigger('samplingResults:hideModal');
    APP.vent.trigger('sectorData:openModal');
  },
});

module.exports = AnnualAverageRowView;
