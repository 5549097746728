import { LoadingIndicator } from '@monorepo/old-web/js/components/elements';
import { useSearchParamState } from '@monorepo/shared/hooks/navigation/useSearchParamState';
import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { OnboardingApi } from '../apiClient';
import {
  useClearOnboardingDocument,
  useCreateOnboardingDocumentRequest,
  useOnboardingSession,
  useRemoveOnboardingDocumentRequest,
  useUpdateOnboardingDocumentRequest,
  useUploadOnboardingDocument,
} from '../apiClient/hooks';
import { OnboardingDocumentStatusFilter } from '../components/OnboardingDocumentStatusFilter';
import {
  OnboardingDocumentsTableDocumentRow as DocumentRow,
  OnboardingDocumentsTableModuleRow as ModuleRow,
  OnboardingDocumentsTableNewDocumentRow as NewDocumentRow,
  OnboardingDocumentsTable,
  OnboardingDocumentsTableProjectRow as ProjectRow,
} from '../components/OnboardingDocumentsTable';
import {
  OnboardingWizardLayoutActionsHeader as ActionsHeader,
  OnboardingWizardLayout as Layout,
} from '../components/OnboardingWizardLayout';

export function OnboardingDocumentsPage() {
  const {
    onboardingSession,
    isLoading: onboardingSessionIsLoading,
    error: onboardingSessionError,
  } = useOnboardingSession({
    config: {
      retry: () => false,
    },
  });

  const [statusFilter, changeStatusFilter] = useSearchParamState('status');

  const [createDocumentRequestMutation] = useCreateOnboardingDocumentRequest();
  const [updateDocumentRequestMutation] = useUpdateOnboardingDocumentRequest();
  const [removeDocumentRequestMutation] = useRemoveOnboardingDocumentRequest();
  const [uploadDocumentMutation] = useUploadOnboardingDocument();
  const [clearDocumentMutation] = useClearOnboardingDocument();

  const handleCreateDocumentRequest = useCallback(
    (documentRequest: OnboardingApi.CreateOnboardingDocumentRequestValues) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createDocumentRequestMutation({
        documentRequest,
      });
    },
    [createDocumentRequestMutation],
  );

  const handleUpdateDocumentRequestTitle = useCallback(
    (documentRequestId: string, title: string) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateDocumentRequestMutation({
        documentRequestId,
        documentRequest: { title },
      });
    },
    [updateDocumentRequestMutation],
  );

  const handleRemoveDocumentRequest = useCallback(
    (documentRequestId: string) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      removeDocumentRequestMutation({
        documentRequestId,
      });
    },
    [removeDocumentRequestMutation],
  );

  const handleUploadDocument = useCallback(
    (documentRequestId: string, file: File) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      uploadDocumentMutation({
        documentRequestId,
        file,
      });
    },
    [uploadDocumentMutation],
  );

  const handleClearDocument = useCallback(
    (documentRequestId: string) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      clearDocumentMutation({
        documentRequestId,
      });
    },
    [clearDocumentMutation],
  );

  if (onboardingSessionIsLoading) {
    return <LoadingIndicator />;
  }
  if (onboardingSessionError || !onboardingSession) {
    return <Redirect to="/" />;
  }
  return (
    <Layout organizationName={onboardingSession.organizationName}>
      <h1>Upload documents for each of your sites</h1>
      <ActionsHeader>
        <OnboardingDocumentStatusFilter
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          filterValue={statusFilter}
          /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
          onFilterChange={changeStatusFilter}
        />
      </ActionsHeader>
      <OnboardingDocumentsTable
        sections={onboardingSession.sections}
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        statusFilter={statusFilter}
        renderProjectRow={(rowProps) => <ProjectRow {...rowProps} />}
        renderModuleRow={(rowProps) => <ModuleRow {...rowProps} />}
        renderDocumentRow={(rowProps) => (
          <DocumentRow
            {...rowProps}
            onUpdateDocumentRequestTitle={handleUpdateDocumentRequestTitle}
            onRemoveDocumentRequest={handleRemoveDocumentRequest}
            onUploadDocument={handleUploadDocument}
            onClearDocument={handleClearDocument}
          />
        )}
        renderNewDocumentRow={(rowProps) => (
          <NewDocumentRow
            {...rowProps}
            onCreateDocumentRequest={handleCreateDocumentRequest}
          />
        )}
      />
    </Layout>
  );
}
