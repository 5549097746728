var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (email) {
      pug_html = pug_html + "\u003Cdiv class=\"impersonating-user\"\u003EImpersonating " + (pug.escape(null == (pug_interp = email) ? "" : pug_interp)) + " &nbsp;\u003Ca class=\"stop-impersonating\" href=\"#\"\u003EStop Impersonating\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "email" in locals_for_with ?
        locals_for_with.email :
        typeof email !== 'undefined' ? email : undefined));
    ;;return pug_html;};
module.exports = template;