// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-import-module-exports
import APP from '../config';

/* eslint-disable */
let _ = require('underscore');
const BluebirdPromise = require('bluebird');
let bigSpinner = require('../services/bigSpinner');
let WebMapFeatureModel = require('./WebMapFeatureModel');
let WebMapDrawControl = require('./WebMapDrawControl');
let WebMapShapeFactory = require('./WebMapShapeFactory');
let WebMapMarkerFactory = require('./WebMapMarkerFactory');
let WebMapShapeModel = require('./WebMapShapeModel');
let ChemicalAttributesTableView = require('./chemicalAttributesTable/ChemicalAttributesTableView');

import {
  convertToBaseUnits,
  flatReducer,
  generateAttributeKey,
  groupByCasNumber,
  groupByUnits,
  miniMapMapper,
  naturalCmpBy,
} from '../utils/hmbp';

/**
 * API to create and interact with map in the browser.
 */
function WebMap(options = {}) {
  this.DEFAULT_ZOOM = 18;
  this.MODES = {
    normal: 'normal',
    draw: 'draw',
    edit: 'edit',
    delete: 'delete',
  };
  this.mode = this.MODES.normal;
  this._chemicalAttributesTableView = new ChemicalAttributesTableView();

  this._tileLayer = this._createTileLayer({
    baseMapType: options.baseMapType,
    opacity: options.opacity,
    isNewbyProject: options.isNewbyProject,
  });

  this._markerClusterGroup = new L.MarkerClusterGroup({
    disableClusteringAtZoom: 15,
    spiderfyOnMaxZoom: false,
    zoomToBoundsOnClick: true,
    showCoverageOnHover: false,
    maxClusterRadius: 20,
  });

  this._polyGroup = new L.FeatureGroup();
  this._decoratorMarkerGroup = new L.FeatureGroup();
  this._tempMarkerGroup = new L.FeatureGroup();
  this._liderlineGroup = new L.FeatureGroup();
  this._hiddenLiderlineArray = [];
  this._controls = {};

  let layers = [
    this._tileLayer,
    this._markerClusterGroup,
    this._polyGroup,
    this._decoratorMarkerGroup,
    this._tempMarkerGroup,
    this._liderlineGroup,
  ];

  if (options.isLehighProject) {
    this._createLehighGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    if (this._lehighGridLayer) {
      layers.push(this._lehighGridLayer);
    }
  }

  if (options.isTeslaProject) {
    this._createTeslaFremontGrid({
      teslaMap: options.teslaFremontLevel,
      projectId: options.projectId,
      opacity: options.opacity,
    });
    if (this._teslaFremontGridLayer) {
      layers.push(this._teslaFremontGridLayer);
    }
  }

  this._teslaDeerCreekGridLayer = {};
  if (options.isTeslaDeerCreekProject) {
    _.each([24, 25, 26], (buildingNumber) => {
      this._createTeslaDeerCreekGrid({
        projectId: options.projectId,
        buildingNumber,
        opacity: options.opacity,
      });
      layers.push(this._teslaDeerCreekGridLayer[buildingNumber]);
    });
  }

  if (options.isTesla901Page) {
    this._createTesla901PageGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    layers.push(this._tesla901PageGridLayer);
  }

  if (options.isTesla47400Kato) {
    this._createTesla47400KatoGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    layers.push(this._tesla47400KatoGridLayer);
  }

  this._map = L.map('map', {
    center: options.center,
    zoom: options.zoom,
    attributionControl: options.attributionControl || false,
    maxZoom: options.maxZoom || 21,
    minZoom: options.minZoom || 2,
    worldCopyJump: options.worldCopyJump || true,
    layers: layers,
  });
  this.scrollWheelZoom = this._map.scrollWheelZoom;
  this._addDefaultControls(options.isMetric, options.disableToggleScale);
  this._registerEvents();

  return this;
}

// Mixin Backbone Events
_.extend(WebMap.prototype, Backbone.Events);

WebMap.prototype.remove = function () {
  this._map.remove();
};

WebMap.prototype.disableClickPropagation = function (el) {
  L.DomEvent.disableClickPropagation(el);
};

WebMap.prototype.getCenter = function () {
  return this._map.getCenter();
};

WebMap.prototype.getZoom = function () {
  return this._map.getZoom();
};

WebMap.prototype.getZoomScale = function (zoom) {
  return this._map.getZoomScale(zoom);
};

WebMap.prototype.addFeatureModelsAsLayers = function (featureModels) {
  let leafletLayers = this._convertFeatureModelsToLeafletLayers(featureModels);
  let leafletMarkerLayers = [];

  return BluebirdPromise.bind(this)
    .then(function () {
      if (featureModels.length > 100) {
        return bigSpinner.show();
      }
    })
    .then(function () {
      leafletLayers.forEach(function (leafletLayer) {
        if (this._isLayerTypeMarker(leafletLayer.type)) {
          leafletMarkerLayers.push(leafletLayer);
        } else {
          this._polyGroup.addLayer(leafletLayer);
        }
      }, this);
      this._markerClusterGroup.addLayers(leafletMarkerLayers);
    })
    .finally(() => {
      bigSpinner.hide();
    });
};

WebMap.prototype.addSearchLayer = function (lat, lng) {
  let modelId = 'search';
  let webMapShapeModel = new WebMapShapeModel({
    featureGroup: this._markerClusterGroup,
    geography: { lat: lat, lng: lng },
    type: 'marker',
  });

  let searchLayer = WebMapMarkerFactory.createSearchMarker(webMapShapeModel);

  this._addTempLayer(searchLayer, modelId);
};

WebMap.prototype.addGeolocateLayer = function (lat, lng) {
  let modelId = 'geolocate';
  let webMapShapeModel = new WebMapShapeModel({
    featureGroup: this._markerClusterGroup,
    geography: { lat: lat, lng: lng },
    type: 'marker',
  });
  let geolocateLayer =
    WebMapMarkerFactory.createGeolocateMarker(webMapShapeModel);

  this._addTempLayer(geolocateLayer, modelId);
};

WebMap.prototype.removeLayer = function (layerId, type) {
  if (this._isLayerTypeMarker(type)) {
    this._removeMarkerLayer(layerId);
  } else if (this._isLayerTypePoly(type)) {
    this._removePolyLayer(layerId);
  }
};

WebMap.prototype.removeLayers = function (layerIds, type) {
  if (this._isLayerTypeMarker(type)) {
    this._removeMarkerLayers(layerIds);
  } else if (this._isLayerTypePoly(type)) {
    this._removePolyLayers(layerIds);
  }
};

WebMap.prototype.getLayer = function (layerId, type) {
  if (this._isLayerTypeMarker(type)) {
    return (
      this._markerClusterGroup.getLayer(layerId) ||
      this._polyGroup.getLayer(layerId)
    );
  } else if (this._isLayerTypePoly(type)) {
    return this._polyGroup.getLayer(layerId);
  }
};

WebMap.prototype.getLayers = function (type) {
  if (this._isLayerTypeMarker(type)) {
    return this._markerClusterGroup.getLayers();
  }

  if (this._isLayerTypePoly(type)) {
    return this._polyGroup.getLayers();
  }

  if (type === undefined) {
    let markerLayers = this._markerClusterGroup.getLayers();
    let polyLayers = this._polyGroup.getLayers();
    return markerLayers.concat(polyLayers);
  }
};

WebMap.prototype.clearTempLayers = function () {
  this._tempMarkerGroup.clearLayers();
};

WebMap.prototype.hideLiderlineLayers = function () {
  this._liderlineGroup
    .getLayers()
    .forEach((layer) => this._hiddenLiderlineArray.push(layer));
  this._liderlineGroup.clearLayers();
};

WebMap.prototype.restoreLiderlineLayers = function () {
  this._hiddenLiderlineArray
    .filter((layer) => layer.getLatLngs().length > 0)
    .forEach((layer) => this._liderlineGroup.addLayer(layer));
  this._hiddenLiderlineArray.length = 0;
};

WebMap.prototype.hasSearchMarker = function () {
  return !!this._getSearchMarker();
};

WebMap.prototype.removeSearchMarker = function () {
  let searchLayer = this._getSearchMarker();

  if (searchLayer) {
    this._tempMarkerGroup.removeLayer(searchLayer);
  }
};

WebMap.prototype.updateLabel = function (feature) {
  let layer =
    this._markerClusterGroup.getLayer(feature.id) ||
    this._polyGroup.getLayer(feature.id);
  let label = layer && layer.getLabel();
  if (label) {
    label.setContent(this._renderLabelContent(feature));
  }
};

WebMap.prototype.invalidateSize = function () {
  this._map.invalidateSize();
};

WebMap.prototype.hasControl = function (controlName) {
  return !!this._controls[controlName];
};

WebMap.prototype.removeControl = function (controlName) {
  if (!this._controls[controlName]) {
    return;
  }

  this._map.removeControl(this._controls[controlName]);
  delete this._controls[controlName];
};

WebMap.prototype.addDrawControl = function (options = {}) {
  options.markerClusterGroup = this._markerClusterGroup;
  options.polyGroup = this._polyGroup;
  this._controls.draw = new WebMapDrawControl(options);
  this._map.addControl(this._controls.draw);
};

WebMap.prototype.addLegend = function () {
  require('./leafletControls/legend');
  this._controls.legend = new L.Control.Legend();
  this._map.addControl(this._controls.legend);
};

WebMap.prototype.renderLegend = function (options) {
  this._controls.legend.render(options);
};

WebMap.prototype.addScalebar = function (options = {}) {
  require('./leafletControls/scalebar');
  this._controls.scalebar = L.control.scale(options);
  this._controls.scalebar.options.unitsMetric =
    options.isMetric || options.unitsMetric;
  this._map.addControl(this._controls.scalebar);
};

WebMap.prototype.disableLegendEditMode = function () {
  this._controls.legend.disableEditMode();
};

WebMap.prototype.setView = function (center, zoom, options) {
  zoom = zoom || this.DEFAULT_ZOOM;
  this._map.setView(center, zoom, options);
};

WebMap.prototype.updateMapOpacity = function (opacity) {
  this._tileLayer.setOpacity(opacity / 100);
  if (this._lehighGridLayer) {
    this._lehighGridLayer.setOpacity(opacity / 100);
  }

  if (this._teslaFremontGridLayer) {
    this._teslaFremontGridLayer.setOpacity(opacity / 100);
  }

  if (this._teslaDeerCreekGridLayer) {
    _.each(_.keys(this._teslaDeerCreekGridLayer), (buildingNumber) => {
      if (this._teslaDeerCreekGridLayer[buildingNumber]) {
        this._teslaDeerCreekGridLayer[buildingNumber].setOpacity(opacity / 100);
      }
    });
  }

  if (this._tesla901PageGridLayer) {
    this._tesla901PageGridLayer.setOpacity(opacity / 100);
  }

  if (this._tesla47400KatoGridLayer) {
    this._tesla47400KatoGridLayer.setOpacity(opacity / 100);
  }
};

WebMap.prototype.updateTileLayer = function (options) {
  this._map.removeLayer(this._tileLayer);
  this._tileLayer = this._createTileLayer(options);
  this._map.addLayer(this._tileLayer);

  if (this._lehighGridLayer) {
    this._map.removeLayer(this._lehighGridLayer);
    this._createLehighGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    this._map.addLayer(this._lehighGridLayer);
    this._lehighGridLayer.bringToFront();
  }

  if (this._teslaFremontGridLayer) {
    this._map.removeLayer(this._teslaFremontGridLayer);
  }
  if (options.teslaMap !== 'None') {
    this._createTeslaFremontGrid({
      teslaMap: options.teslaMap,
      projectId: options.projectId,
      opacity: options.opacity,
    });
    this._map.addLayer(this._teslaFremontGridLayer);
    this._teslaFremontGridLayer.bringToFront();
  }

  if (this._teslaDeerCreekGridLayer) {
    _.each(_.keys(this._teslaDeerCreekGridLayer), (buildingNumber) => {
      if (this._teslaDeerCreekGridLayer[buildingNumber]) {
        this._map.removeLayer(this._teslaDeerCreekGridLayer[buildingNumber]);

        this._createTeslaDeerCreekGrid({
          projectId: options.projectId,
          buildingNumber,
          opacity: options.opacity,
        });
        this._map.addLayer(this._teslaDeerCreekGridLayer[buildingNumber]);
        this._teslaDeerCreekGridLayer[buildingNumber].bringToFront();
      }
    });

    this._bringAerialImageryLayersToFront();
  }

  if (this._tesla901PageGridLayer) {
    this._map.removeLayer(this._tesla901PageGridLayer);
    this._createTesla901PageGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    this._map.addLayer(this._tesla901PageGridLayer);
    this._tesla901PageGridLayer.bringToFront();
  }

  if (this._tesla47400KatoGridLayer) {
    this._map.removeLayer(this._tesla47400KatoGridLayer);
    this._createTesla47400KatoGrid({
      projectId: options.projectId,
      opacity: options.opacity,
    });
    this._map.addLayer(this._tesla47400KatoGridLayer);
    this._tesla47400KatoGridLayer.bringToFront();
  }
};

WebMap.prototype._isInNormalMode = function () {
  return this.mode === this.MODES.normal;
};

WebMap.prototype._isInDeleteMode = function () {
  return this.mode === this.MODES.delete;
};

WebMap.prototype._isInEditMode = function () {
  return this.mode === this.MODES.edit;
};

WebMap.prototype._isLayerTypeMarker = function (layerType) {
  return layerType === 'marker';
};

WebMap.prototype._isLayerTypePoly = function (layerType) {
  return _.contains(['poly', 'polygon', 'polyline'], layerType);
};

WebMap.prototype._defaultTileLayerOptions = function (options = {}) {
  return {
    maxZoom: 21,
    maxNativeZoom: 19,
    minZoom: 2,
    detectRetina: true,
    reuseTiles: true,
    opacity: options.opacity / 100,
  };
};

WebMap.prototype._getTileUrl = function (options) {
  if (!(options && options.token && options.style)) {
    throw new Error('options.style and options.token are required.');
  }

  return `https://api.mapbox.com/styles/v1/azadratzki/${
    options.style
  }/tiles/256/{z}/{x}/{y}${options.retina ? '@2x' : ''}?access_token=${
    options.token
  }`;
};

WebMap.prototype._createTileLayer = function (options = {}) {
  const mapBoxStyles = {
    AerialWithLabels: 'cjoop6st83pog2rkewom87ep8',
    Aerial: 'cjoafztsb0jkr2smpfgsm3w9i',
    Topography: 'cjohmrwci072y2sltjl9da88t',
  };

  if (options.isNewbyProject) {
    mapBoxStyles.Aerial = 'ck0ymjevu0imf1dmv0ctbjfn0';
    mapBoxStyles.AerialWithLabels = 'ck0ymhz870ios1cmnhux2esm1';
  }
  const style = mapBoxStyles[options.baseMapType];
  return L.tileLayer(
    this._getTileUrl({ token: APP.mapboxToken, style, retina: true }),
    _.extend({}, this._defaultTileLayerOptions(options)),
  );
};

WebMap.prototype._bringAerialImageryLayersToFront = function () {
  _.each(this._aerialimageryTileLayers, (l) => {
    l.tileLayer.bringToFront();
  });
};

WebMap.prototype._showAerialImageryBaseLayer = function (layer, opacity) {
  let _opacity = parseFloat(opacity);
  if (typeof _opacity !== 'number' || isNaN(opacity)) {
    _opacity = 1;
  }

  this._aerialimageryTileLayers = this._aerialimageryTileLayers || [];
  const existingTileLayer = _.findWhere(this._aerialimageryTileLayers, {
    layer_id: layer.id,
  });
  if (existingTileLayer) {
    return existingTileLayer.tileLayer.setOpacity(_opacity);
  }

  const tilesetSlug = layer.get('mapbox_tileset_slug');
  if (!tilesetSlug) {
    return;
  }

  const tileLayer = L.tileLayer(
    `https://api.mapbox.com/v4/${tilesetSlug}/{z}/{x}/{y}@2x.png?access_token=${APP.mapboxToken}`,
    { maxZoom: 24 },
  );

  tileLayer.setOpacity(_opacity);

  this._aerialimageryTileLayers.push({
    layer_id: layer.id,
    tileLayer,
  });

  this._map.addLayer(tileLayer);
};

WebMap.prototype._removeAerialImageryBaseLayer = function (layer) {
  const layerToRemove = _.findWhere(this._aerialimageryTileLayers, {
    layer_id: layer.id,
  });

  if (!layerToRemove) {
    return;
  }

  this._map.removeLayer(layerToRemove.tileLayer);

  this._aerialimageryTileLayers = _.reject(this._aerialimageryTileLayers, {
    layer_id: layerToRemove.layer_id,
  });
};

WebMap.prototype._createTeslaFremontGrid = function (options = {}) {
  let apiCaller = require('../apiCaller');
  let url;
  let teslaMap = options.teslaMap;
  let projectId = options.projectId;
  options.opacity = options.opacity || 100;
  options = this._defaultTileLayerOptions(options);
  options.minZoom = 14;
  options.maxZoom = 20;
  options.reuseTiles = true;

  if (teslaMap === 'FremontLevelOne') {
    url = apiCaller.getTeslaTilesBaseUrl(
      projectId,
      'tesla-fremont-factory-level1',
    );
  } else if (teslaMap === 'FremontLevelTwo') {
    url = apiCaller.getTeslaTilesBaseUrl(
      projectId,
      'tesla-fremont-factory-level2',
    );
  } else {
    return;
  }

  this._teslaFremontGridLayer = L.tileLayer(url + '{z}/{x}/{y}.png', options);
  this._teslaFremontGridLayer.on(
    'load',
    function () {
      this.trigger('tileLayerLoad:teslaFremontGrid');
      this._bringTeslaLayersToFront();
    }.bind(this),
  );
};

WebMap.prototype._createTeslaDeerCreekGrid = function (options = {}) {
  let apiCaller = require('../apiCaller');
  let url;
  let projectId = options.projectId;
  let buildingNumber = options.buildingNumber;
  options.opacity = options.opacity || 100;
  options = this._defaultTileLayerOptions(options);
  options.minZoom = 18;
  options.maxZoom = 24;
  options.reuseTiles = true;

  if (buildingNumber === 26) {
    options.minZoom = 17;
    options.maxZoom = 23;
  }

  url = apiCaller.getTeslaTilesBaseUrl(
    projectId,
    'tesla-deer-creek-building-' + buildingNumber,
  );
  this._teslaDeerCreekGridLayer[buildingNumber] = L.tileLayer(
    url + '{z}/{x}/{y}.png',
    options,
  );
  this._teslaDeerCreekGridLayer[buildingNumber].on(
    'load',
    function () {
      this.trigger('tileLayerLoad:teslaDeerCreek' + buildingNumber + 'Grid');
      this._bringTeslaLayersToFront();
    }.bind(this),
  );
};

WebMap.prototype._createLehighGrid = function (options = {}) {
  let apiCaller = require('../apiCaller');
  let url;
  let projectId = options.projectId;
  options.opacity = options.opacity || 100;
  options = this._defaultTileLayerOptions(options);
  options.minZoom = 14;
  options.maxZoom = 20;
  options.reuseTiles = true;

  url = apiCaller.getTeslaTilesBaseUrl(projectId, 'lehigh-santa-margarita');
  this._lehighGridLayer = L.tileLayer(url + '{z}/{x}/{y}.png', options);

  this._lehighGridLayer.on(
    'load',
    function () {
      this.trigger('tileLayerLoad:lehighGrid');
      this._lehighGridLayer.bringToFront();
    }.bind(this),
  );
};

WebMap.prototype._createTesla901PageGrid = function (options = {}) {
  let apiCaller = require('../apiCaller');
  let url;
  let projectId = options.projectId;
  options.opacity = options.opacity || 100;
  options = this._defaultTileLayerOptions(options);
  options.minZoom = 16;
  options.maxZoom = 22;
  options.reuseTiles = true;

  url = apiCaller.getTeslaTilesBaseUrl(projectId, 'tesla-901-page');
  this._tesla901PageGridLayer = L.tileLayer(url + '{z}/{x}/{y}.png', options);

  this._tesla901PageGridLayer.on(
    'load',
    function () {
      this.trigger('tileLayerLoad:tesla901PageGrid');
      this._bringTeslaLayersToFront();
    }.bind(this),
  );
};

WebMap.prototype._createTesla47400KatoGrid = function (options = {}) {
  let apiCaller = require('../apiCaller');
  let url;
  let projectId = options.projectId;
  options.opacity = options.opacity || 100;
  options = this._defaultTileLayerOptions(options);
  options.minZoom = 16;
  options.maxZoom = 22;
  options.reuseTiles = true;

  url = apiCaller.getTeslaTilesBaseUrl(projectId, 'tesla-47400-kato');
  this._tesla47400KatoGridLayer = L.tileLayer(url + '{z}/{x}/{y}.png', options);

  this._tesla47400KatoGridLayer.on(
    'load',
    function () {
      this.trigger('tileLayerLoad:tesla47400KatoGrid');
      this._bringTeslaLayersToFront();
    }.bind(this),
  );
};

WebMap.prototype._addDefaultControls = function (isMetric, disableToggleScale) {
  this.addLegend();
  this.addScalebar({
    unitsMetric: isMetric,
    disableToggle: disableToggleScale,
  });
  this.addDrawControl({ isMetric: isMetric });
};

WebMap.prototype._renderLabelContent = function (featureModel) {
  let styleModel = featureModel.layer().style();
  let name = featureModel.escape('name');
  const attrs = featureModel.attrs();
  if (
    attrs.length &&
    styleModel.get('label_show_chemical_attributes') === 'standard'
  ) {
    try {
      const groupedAttrs = [];
      const preparedAttrs = attrs
        .filter((attr) => attr.isList)
        .map((attr) => _.indexBy(JSON.parse(attr.value), 'key'))
        .map(miniMapMapper);

      preparedAttrs
        .reduce((map, value, index) => {
          const key = generateAttributeKey(value);
          if (isNaN(value.maximum_daily_amount)) {
            return map.set(`${key}-${index}`, value);
          }

          const list = map.get(key);
          if (list) {
            map.set(key, list.concat([value]));
          } else {
            map.set(key, [value]);
          }
          return map;
        }, new Map())
        .forEach((value) => groupedAttrs.push(value));

      const regrouped = groupedAttrs
        .map(groupByCasNumber)
        .reduce(flatReducer, [])
        .map(convertToBaseUnits)
        .map(groupByUnits)
        .reduce(flatReducer, []);

      const values = [];
      for (let group of regrouped) {
        if (group.length > 1) {
          const maximumDailyAmounts = [];
          for (let attribute of group) {
            maximumDailyAmounts.push(attribute.maximum_daily_amount);
          }

          const consolidatedAttr = _.extend({}, group[0]);
          consolidatedAttr.maximum_daily_amount = maximumDailyAmounts.reduce(
            (amount1, amount2) => {
              const floatAmount1 = parseFloat(amount1);
              const floatAmount2 = parseFloat(amount2);
              return (
                (isNaN(floatAmount1) ? 0 : floatAmount1) +
                (isNaN(floatAmount2) ? 0 : floatAmount2)
              );
            },
          );
          values.push(consolidatedAttr);
        } else if (group.length === 1) {
          values.push(group[0]);
        }
      }

      const sortedValues = values.sort(naturalCmpBy('chemical_name'));

      return this._chemicalAttributesTableView.template({
        values: sortedValues,
        name,
      });
    } catch (e) {
      console.error(e);
    }
  }
  return name;
};

WebMap.prototype._convertFeatureModelsToLeafletLayers = function (
  featureModels,
) {
  featureModels = _.flatten([featureModels]);

  return _.flatten(
    featureModels.map(function (featureModel) {
      let styleModel = featureModel.layer().style();
      let needToShowLabel = styleModel.needToShowLabel();
      let zoomScale;
      let type = featureModel.getType();

      if (needToShowLabel) {
        if (type !== 'marker') {
          let zoomLevelWhenOffsetCreated = featureModel.get(
            'zoom_level_when_label_offset_created',
          );
          zoomScale =
            zoomLevelWhenOffsetCreated &&
            this.getZoomScale(zoomLevelWhenOffsetCreated);
        } else {
          zoomScale = 1;
        }
      }

      let labelOffsetCoordinates =
        featureModel.getLabelOffsetCoordinates(zoomScale);
      let webMapShapeModel = new WebMapShapeModel({
        id: featureModel.id,
        styleModel: styleModel,
        featureGroup: featureModel.isMarker()
          ? this._markerClusterGroup
          : this._polyGroup,
        liderlineGroup: this._liderlineGroup,
        hiddenLiderlineArray: this._hiddenLiderlineArray,
        decoratorMarkerGroup: this._decoratorMarkerGroup,
        labelOffsetCoordinates: labelOffsetCoordinates,
        geography: featureModel.get('geography'),
        rotation: !!featureModel.get('rotation')
          ? parseInt(featureModel.get('rotation'))
          : 0,
        labelContent: this._renderLabelContent(featureModel),
        type: type,
      });

      let leafletLayer = WebMapShapeFactory.create(webMapShapeModel);

      leafletLayer.modelId = featureModel.id;
      leafletLayer._leaflet_id = featureModel.id;
      leafletLayer.type = type;

      // Setup the label for this leaflet layer if label is turned on
      let label = leafletLayer.getLabel();
      if (label) {
        label.modelId = featureModel.id;
        label.type = type;

        // Setup the leaderline for this leaflet layer if leaderline is turned on
        if (leafletLayer.getLabel().getLeaderline) {
          let leaderline = leafletLayer.getLabel().getLeaderline();
          if (leaderline) {
            leaderline.modelId = featureModel.id;
            leaderline.type = 'polyline';
          }
        }
      }

      return leafletLayer;
    }, this),
  );
};

WebMap.prototype._addTempLayer = function (tempLayer, modelId) {
  tempLayer.isTemp = true;
  tempLayer.modelId = modelId;

  // set the z-index higher than other markers and labels on the map
  tempLayer.setZIndexOffset(400);

  this._tempMarkerGroup.addLayer(tempLayer);
};

WebMap.prototype._removeMarkerLayer = function (layerId) {
  let layer = this._markerClusterGroup.getLayer(layerId);
  if (layer) {
    this._markerClusterGroup.removeLayer(layer);
  }
};

WebMap.prototype._removeMarkerLayers = function (layerIds) {
  layerIds = _.compact(_.flatten([layerIds]));

  if (!layerIds.length) {
    return;
  }

  // removeLayers causes flickering, so only use it when there are a lot of features
  // to remove. Removing features one by one doesn't cause flickering, but it's too
  // slow to use when removing a lot of features.
  if (layerIds.length > 100) {
    let layers = layerIds.map(function (layerId) {
      return this._markerClusterGroup.getLayer(layerId);
    }, this);
    this._markerClusterGroup.removeLayers(layers);
  } else {
    layerIds.forEach(this._removeMarkerLayer, this);
  }
};

WebMap.prototype._removePolyLayer = function (layerId) {
  let layer = this._polyGroup.getLayer(layerId);

  if (layer && layer.hasDecorator) {
    let decoratorLayer = this._polyGroup.getLayer(layerId + '_decorator');
    this._polyGroup.removeLayer(decoratorLayer);
    this._removeDecoratorMarkers(decoratorLayer);
  }

  if (layer && layer.hasMask) {
    this._polyGroup.removeLayer(layerId + '_mask');
  }

  this._polyGroup.removeLayer(layer);
};

WebMap.prototype._removePolyLayers = function (layerIds) {
  layerIds = _.flatten([layerIds]);

  if (!layerIds.length) {
    return;
  }

  layerIds.forEach(this._removePolyLayer, this);
};

WebMap.prototype._findDecoratorMarkers = function (layer) {
  return this._decoratorMarkerGroup
    .getLayers()
    .filter(function (decoratorMarkerLayer) {
      return layer.modelId === decoratorMarkerLayer.id;
    });
};

WebMap.prototype._removeDecoratorMarkers = function (layer) {
  this._findDecoratorMarkers(layer).forEach(function (decoratorMarkerLayer) {
    this._decoratorMarkerGroup.removeLayer(decoratorMarkerLayer);
  });
};

WebMap.prototype._getSearchMarker = function () {
  return _.find(this._tempMarkerGroup.getLayers(), function (layer) {
    return layer.modelId === 'search';
  });
};

WebMap.prototype._bringTeslaLayersToFront = function () {
  if (this._teslaFremontGridLayer) {
    this._teslaFremontGridLayer.bringToFront();
  }

  _.each(_.keys(this._teslaDeerCreekGridLayer), (buildingNumber) => {
    if (this._teslaDeerCreekGridLayer[buildingNumber]) {
      this._teslaDeerCreekGridLayer[buildingNumber].bringToFront();
    }
  });

  if (this._tesla901PageGridLayer) {
    this._tesla901PageGridLayer.bringToFront();
  }

  if (this._tesla47400KatoGridLayer) {
    this._tesla47400KatoGridLayer.bringToFront();
  }
};

WebMap.prototype._registerEvents = function () {
  this._tileLayer.on(
    'load',
    function () {
      this.trigger('tileLayerLoad:bing');
      this._bringTeslaLayersToFront();
    }.bind(this),
  );

  this._map.on('click', this._handleClickMapEvent.bind(this));
  this._markerClusterGroup.on('click', this._handleClickLayerEvent.bind(this));
  this._polyGroup.on('click', this._handleClickLayerEvent.bind(this));
  this._decoratorMarkerGroup.on(
    'click',
    this._handleClickLayerEvent.bind(this),
  );
  this._tempMarkerGroup.on('click', this._handleClickTempLayerEvent.bind(this));
  this._map.on('moveend', this._bubbleEventWithoutPayload.bind(this));
  this._registerDrawEvents();
  this._registerControlEvents();
};

WebMap.prototype._registerDrawEvents = function () {
  this._map.on('draw:created', this._bubbleSingleLayerDrawEvent.bind(this));
  this._map.on(
    'draw:drawstart',
    function () {
      this.clearTempLayers();
      this.mode = this.MODES.draw;
    }.bind(this),
  );

  // TODO: Refactor MapistryLeaflet.Draw to get rid of this event. MapistryLeaflet.Draw
  // is currently reaching into our map and deleting all the markers and then firing this
  // event so that we know what markers to redraw. Leave this here until
  // MapistryLeaflet.Draw stops overstepping it's boundaries and deleting features from
  // our map.
  this._map.on('draw:allMarkers', this._bubbleEventWithoutPayload.bind(this));

  this._map.on(
    'draw:editstart',
    function () {
      this.clearTempLayers();
      this.hideLiderlineLayers();
      this.mode = this.MODES.edit;
    }.bind(this),
  );
  this._map.on('draw:edited', (e) => {
    this.restoreLiderlineLayers();
    this._bubbleDrawEditedEvent(e);
  });
  this._map.on(
    'draw:deletestart',
    function () {
      this.clearTempLayers();
      this.mode = this.MODES.delete;
    }.bind(this),
  );
  this._map.on('draw:deleted', this._bubbleDrawDeletedEvent.bind(this));
  this._map.on(
    'draw:revertEdits',
    function (e) {
      if (this._isInEditMode()) {
        this.restoreLiderlineLayers();
        this.trigger('draw:revertEditedFeatures');
      }

      if (this._isInDeleteMode()) {
        this.trigger(e.type);
      }
    }.bind(this),
  );
  this._map.on(
    'draw:drawstop draw:editstop draw:deletestop',
    function () {
      this.mode = this.MODES.normal;
    }.bind(this),
  );
};

WebMap.prototype._registerControlEvents = function () {
  this._map.on('click:toggleScale', this._bubbleEventWithoutPayload.bind(this));
};

WebMap.prototype._getTypeFromSingleLayerLeafletEvent = function (e) {
  return e.layerType || e.layer.type;
};

WebMap.prototype._getGeographyFromLeafletLayer = function (leafletLayer, type) {
  switch (type) {
    case 'marker':
      return leafletLayer.getLatLng();
    case 'polyline':
      return leafletLayer.getLatLngs();
    case 'polygon':
      return [leafletLayer.getLatLngs()];
    default:
      throw new Error('Invalid feature type.');
  }
};

/**
 * Calculate the offset of a polyline and polygon features label based on
 * where the user dragged the label to.
 *
 * @param {Object} feature - The object created by the leaflet draw library.
 * @Note: In Leaflet terminology, everything that is drawn is a "layer".
 */
WebMap.prototype._getPolyShapeLabelOffset = function (leafletLayer) {
  let label = leafletLayer.getLabel && leafletLayer.getLabel();

  if (!label) {
    return { x: 0, y: 0 };
  }

  let labelPos = this._map.latLngToLayerPoint(label.getLatLng());
  let center = leafletLayer.getBounds().getCenter();
  let centerCoordinates = this._map.latLngToLayerPoint(center);

  let offset;
  if (!label.moved) {
    const userOffset = label.getUserOffset();
    offset = {
      x: userOffset.x + labelPos.x - centerCoordinates.x,
      y: userOffset.y + labelPos.y - centerCoordinates.y,
    };
  } else {
    offset = {
      x: labelPos.x - centerCoordinates.x,
      y: labelPos.y - centerCoordinates.y,
    };
  }

  return offset;
};

WebMap.prototype._getLabelOffsetFromLeafletLayer = function (
  leafletLayer,
  type,
) {
  let offset;

  if (type === 'marker' && leafletLayer.getLabel && leafletLayer.getLabel()) {
    offset = leafletLayer.getLabel().getUserOffset();
  } else if (type === 'polygon' || type === 'polyline') {
    offset = this._getPolyShapeLabelOffset(leafletLayer, type);
  }

  return offset;
};

WebMap.prototype._createWebMapFeatureModelFromLeafletLayer = function (
  leafletLayer,
  type,
) {
  let offset = this._getLabelOffsetFromLeafletLayer(leafletLayer, type);

  return new WebMapFeatureModel({
    id: leafletLayer.modelId,
    isTemp: leafletLayer.isTemp,
    geography: this._getGeographyFromLeafletLayer(leafletLayer, type),
    type: type,
    rotation:
      leafletLayer && leafletLayer.label
        ? leafletLayer.label.options.rotation
        : 0,
    labelOffsetX: offset && offset.x,
    labelOffsetY: offset && offset.y,
  });
};

/**
 * To view all the attributes of the Leaflet event object param refer to:
 * http://leafletjs.com/reference.html#event-objects
 *
 * @param {Object} e - This event object is created by Leaflet.
 * @param {String} e.originalEvent - The original DOM mouse event fired by the browser.
 */
WebMap.prototype._handleClickMapEvent = function (e) {
  this.trigger('click', e.originalEvent);
};

WebMap.prototype._handleClickLayerEvent = function (e) {
  let type = this._getTypeFromSingleLayerLeafletEvent(e);
  let leafletLayer = this.getLayer(e.layer.modelId, type);

  if (this._isInDeleteMode()) {
    this.removeLayer(leafletLayer.modelId, type);
    return;
  }

  if (this._isInNormalMode()) {
    let webMapFeature = this._createWebMapFeatureModelFromLeafletLayer(
      leafletLayer,
      type,
    );
    this.trigger('click:feature', webMapFeature);
  }
};

WebMap.prototype._handleClickTempLayerEvent = function (e) {
  let type = 'marker';
  let leafletLayerId = this._tempMarkerGroup.getLayerId(e.layer);
  let leafletLayer = this._tempMarkerGroup.getLayer(leafletLayerId);
  let webMapFeature = this._createWebMapFeatureModelFromLeafletLayer(
    leafletLayer,
    type,
  );

  this.trigger('click:feature', webMapFeature);
};

WebMap.prototype._bubbleSingleLayerDrawEvent = function (e) {
  let type = this._getTypeFromSingleLayerLeafletEvent(e);
  let leafletLayer = e.layer;
  let webMapFeature = this._createWebMapFeatureModelFromLeafletLayer(
    leafletLayer,
    type,
  );
  let eventType = e.type;
  this.trigger(eventType, webMapFeature);
};

WebMap.prototype._bubbleDrawEditedEvent = function (e) {
  let webMapFeatures = e.layers.getLayers().map(function (leafletLayer) {
    return this._createWebMapFeatureModelFromLeafletLayer(
      leafletLayer,
      leafletLayer.type,
    );
  }, this);
  let eventType = e.type;
  this.trigger(eventType, webMapFeatures);
};

WebMap.prototype._bubbleDrawDeletedEvent = function (e) {
  let webMapFeatureIds = _.pluck(e.layers.getLayers(), 'modelId');
  let eventType = e.type;
  this.trigger(eventType, webMapFeatureIds);
};

WebMap.prototype._bubbleEventWithoutPayload = function (e) {
  let eventType = e.type;
  this.trigger(eventType);
};

module.exports = WebMap;
