import React, { useCallback, useMemo } from 'react';
import { useProject } from '../../../../contexts/ProjectContext';
import { Select } from '../../../elements';
import { getStatusOptionsByState } from './constants';
import { WaterLogLimitItemFormSubmission } from './types';

interface SelectStatusProps {
  errors: { [field: string]: string };
  item: WaterLogLimitItemFormSubmission;
  menuRef: React.RefObject<HTMLDivElement>;
  onChange: (option: string) => void;
}

export const SelectStatus = ({
  errors,
  item,
  menuRef,
  onChange,
}: SelectStatusProps) => {
  const { project } = useProject();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line prefer-destructuring
  const status = item.status;
  const options = useMemo(
    () => getStatusOptionsByState(project?.state),
    [project?.state],
  );

  const handleOnChange = useCallback(
    (option) => onChange(option?.value),
    [onChange],
  );

  const optionFromValue = useMemo(
    () => options.find((opt) => opt.value === status),
    [options, status],
  );

  return (
    <Select
      className="flex-grow-1"
      error={!!errors?.status}
      isClearable
      menuPlacement="auto"
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      menuPortalTarget={menuRef.current}
      options={options}
      onChange={handleOnChange}
      value={optionFromValue}
    />
  );
};
