import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import { Api } from '@monorepo/shared/apiClient';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import { UTCEquivalentOfLocal } from 'mapistry-shared';
import APP from '../../../../config';

export enum uploadFieldKeys {
  dateOfDischarge = 'dateOfDischarge',
  flowReading = 'flowReading',
  flowReadingUnits = 'flowReadingUnits',
  hoursOfDischarge = 'hoursOfDischarge',
  monitoringLocationId = 'monitoringLocationId',
  personReportingDischarge = 'personReportingDischarge',
  rateOfDischarge = 'rateOfDischarge',
  rateOfDischargeUnits = 'rateOfDischargeUnits',
}

export const validateFlowReadingOrRateOfDischarge = (
  record: FlatfileRecord,
): void => {
  const [flowReading, rateOfDischarge] = [
    record.get(uploadFieldKeys.flowReading),
    record.get(uploadFieldKeys.rateOfDischarge),
  ];
  if ((!flowReading && !rateOfDischarge) || (flowReading && rateOfDischarge)) {
    record.addError(
      uploadFieldKeys.flowReading,
      'Either Volume or Rate of discharge are required.',
    );
    record.addError(
      uploadFieldKeys.rateOfDischarge,
      'Either Volume or Rate of discharge are required.',
    );
  }
};

export const validateLogDoesNotExist = async (
  record: FlatfileRecord,
): Promise<void> => {
  const [dateOfDischarge, monitoringLocationId] = [
    record.get(uploadFieldKeys.dateOfDischarge),
    record.get(uploadFieldKeys.monitoringLocationId),
  ] as [string, string];

  if (!dateOfDischarge || !monitoringLocationId) {
    return;
  }
  const { projectId } = APP;
  const dischargeDate = UTCEquivalentOfLocal(parseDateString(dateOfDischarge));
  const existing = await Api.fetchFlowLogs({
    endDate: dischargeDate,
    locationId: monitoringLocationId,
    projectId,
    startDate: dischargeDate,
  });

  if (existing.length) {
    record.addError(
      uploadFieldKeys.dateOfDischarge,
      'A flow log record already exists for this date and location.',
    );
  }
};

export const validateRateOfDischargeUnits = (record: FlatfileRecord): void => {
  const [rateOfDischarge, rateOfDischargeUnits] = [
    record.get(uploadFieldKeys.rateOfDischarge),
    record.get(uploadFieldKeys.rateOfDischargeUnits),
  ];
  if (rateOfDischarge && !rateOfDischargeUnits) {
    record.addError(uploadFieldKeys.rateOfDischargeUnits, 'Required');
  }
};
