import React from 'react';
import SelectCreatable from '../../../elements/SelectCreatable';
import FormField from './FormField';

class SingleSelectFreeFormField extends FormField {
  getInputComponent(templateField, error) {
    const formattedOptions = this.formatOptions(templateField);
    const { isStencil } = this.props;

    return (
      <SelectCreatable
        options={formattedOptions}
        value={this.formatValue(formattedOptions)}
        onChange={this.handleChange}
        error={error}
        isClearable={isStencil || !templateField.required}
      />
    );
  }

  formatOptions(templateField) {
    return templateField.options.map((option) => ({
      value: option,
      label: option,
    }));
  }

  formatValue() {
    const value = this.getValue();
    return value === '' ? null : { value, label: value };
  }

  handleChange(selection) {
    const { templateField, onChange } = this.props;
    const newValue = selection ? selection.value : '';
    return onChange(templateField.slug, newValue);
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SingleSelectFreeFormField;
