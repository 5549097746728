/* eslint-disable */
var APP = require('../config');

var ErrorPage = Backbone.Marionette.ItemView.extend({
  templateHelpers: function () {
    return {
      assetsRoot: APP.assetsRoot,
      errorMessage: this.errorMessage,
    };
  },

  initialize: function (options = {}) {
    this.errorMessage = options.message;

    if (options.message && !options.code) {
      this.template = require('./templates/generic.pug');
      return;
    }

    switch (options.code) {
      case '404':
        this.template = require('./templates/404.pug');
        break;
      case '500':
        this.template = require('./templates/500.pug');
        break;
      default:
        throw new Error('Invalid error code.');
    }
  },
});

module.exports = ErrorPage;
