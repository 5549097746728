var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (displayEdit, formatValue, listTypes, nameValue) {
      pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group col-xs-6\"\u003E\u003Ch4\u003EAttributes\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group col-xs-6 text-right\"\u003E\u003Cdiv id=\"btn-edit-container\"\u003E\u003Cspan class=\"saving-cue\"\u003ESaved\u003C\u002Fspan\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["btn",displayEdit], [false,true]), false, true)+" id=\"edit\"") + "\u003E\u003Cspan\u003EEdit Attributes\u003C\u002Fspan\u003E\u003Cspan class=\"glyphicon glyphicon-pencil\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ctable class=\"table table-striped\"\u003E\u003Ctbody id=\"attr-rows-parent\"\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003Cform id=\"feature-attr-form\" action=\"\"\u003E\u003Ctable class=\"table\"\u003E\u003Cthead\u003E\u003Ctr\u003E";
if (formatValue === 'list') {
pug_html = pug_html + "\u003Ctd\u003EProduct Name\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003EName\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd\u003EValue\u003C\u002Ftd\u003E";
if (formatValue === 'list') {
pug_html = pug_html + "\u003Ctd\u003E\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd\u003EFormat\u003C\u002Ftd\u003E\u003Ctd\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003Ctr\u003E\u003Ctd\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"new-attr-name\" name=\"name\""+pug.attr("value", nameValue, true, true)) + "\u003E\u003C\u002Ftd\u003E\u003Ctd id=\"edit-fields\"\u003E\u003C\u002Ftd\u003E";
if (formatValue === 'list') {
pug_html = pug_html + "\u003Ctd\u003E\u003Cdiv class=\"chemical-attributes-select-create\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd\u003E\u003Cselect class=\"label-display form-control\" id=\"new-attr-format\" name=\"format\"\u003E\u003Coption" + (" value=\"text\""+pug.attr("selected", formatValue === 'text', true, true)) + "\u003Etext\u003C\u002Foption\u003E\u003Coption" + (" value=\"number\""+pug.attr("selected", formatValue === 'number', true, true)) + "\u003Enumber\u003C\u002Foption\u003E";
// iterate listTypes
;(function(){
  var $$obj = listTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var list = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (" value=\"list\""+pug.attr("selected", formatValue === 'list', true, true)+pug.attr("data-list-key", list.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = list.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var list = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (" value=\"list\""+pug.attr("selected", formatValue === 'list', true, true)+pug.attr("data-list-key", list.key, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = list.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cbutton class=\"btn btn-success\" id=\"add\"\u003EAdd\u003C\u002Fbutton\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003C\u002Ftable\u003E\u003C\u002Fform\u003E";
    }.call(this, "displayEdit" in locals_for_with ?
        locals_for_with.displayEdit :
        typeof displayEdit !== 'undefined' ? displayEdit : undefined, "formatValue" in locals_for_with ?
        locals_for_with.formatValue :
        typeof formatValue !== 'undefined' ? formatValue : undefined, "listTypes" in locals_for_with ?
        locals_for_with.listTypes :
        typeof listTypes !== 'undefined' ? listTypes : undefined, "nameValue" in locals_for_with ?
        locals_for_with.nameValue :
        typeof nameValue !== 'undefined' ? nameValue : undefined));
    ;;return pug_html;};
module.exports = template;