/* eslint-disable */
const APP = require('../../../../config');

let organizationSelectionView = Marionette.ItemView.extend({
  template: require('./templates/organizationSelection.pug'),

  ui: {
    $selectedOrganizationName: '#selected-organization-name',
    $organizationSelect: '#organization-select',
    $showOrganizationSelect: '#show-organization-select',
  },

  events: {
    'click #show-organization-select': '_showOrganizationSelect',
    'select2:close #organization-select': '_handleCloseOrganizationSelect',
  },

  templateHelpers() {
    let model = this.model;
    return {
      selectedOrganization: model.get('adminOrganizations')[0].get('name'),
    };
  },

  onShow() {
    this.ui.$organizationSelect.select2();
    this.ui.$organizationSelect.next('.select2-container').hide();
  },

  _showOrganizationSelect() {
    this.ui.$selectedOrganizationName.hide();
    this.ui.$showOrganizationSelect.hide();
    this.ui.$organizationSelect.next('.select2-container').show();
  },

  _handleCloseOrganizationSelect() {
    let organizationId = this.ui.$organizationSelect.val();
    APP.vent.trigger('userSettingsOrganization:change', { organizationId });

    let organizationName = $('#organization-select option:selected').text();
    this.ui.$selectedOrganizationName.text(organizationName);

    this.ui.$selectedOrganizationName.show();
    this.ui.$showOrganizationSelect.show();
    this.ui.$organizationSelect.next('.select2-container').hide();
  },
});

module.exports = organizationSelectionView;
