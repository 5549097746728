var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (frequency, name) {
      pug_html = pug_html + "\u003Cdiv class=\"row flex align-items-center\"\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Cselect class=\"form-control outfall-frequency\" type=\"text\" name=\"frequency\"\u003E\u003Coption" + (" value=\"quarter\""+pug.attr("selected", frequency === 'quarter', true, true)) + "\u003EQuarterly\u003C\u002Foption\u003E\u003Coption" + (" value=\"biannual\""+pug.attr("selected", frequency === 'biannual' || frequency === null, true, true)) + "\u003ESemi-Annually\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "frequency" in locals_for_with ?
        locals_for_with.frequency :
        typeof frequency !== 'undefined' ? frequency : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;