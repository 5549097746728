import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProjectProvider } from '../../contexts/ProjectContext';
import ErrorPage404 from '../404';
import { LoadingIndicator } from '../elements';
import { GenericLogSettingsContainer } from '../genericLogs/EmissionTrackingSettings';
import EmissionTrackingView from '../genericLogs/EmissionTrackingView';

const ProjectRoutes = ({ match }) => {
  const { projectId } = match.params;
  const baseUrl = `/projects/:projectId`;
  if (!projectId) {
    return <LoadingIndicator />;
  }
  return (
    <ProjectProvider projectId={projectId}>
      <Switch>
        <Route
          path={`${baseUrl}/custom-logs/:logProjectId/settings`}
          component={GenericLogSettingsContainer}
        />
        <Route
          path={`${baseUrl}/custom-logs/:logProjectId`}
          component={EmissionTrackingView}
        />
        <Route component={ErrorPage404} />
      </Switch>
    </ProjectProvider>
  );
};
ProjectRoutes.propTypes = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
};
ProjectRoutes.defaultProps = { match: undefined };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ProjectRoutes;
