var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (formattedDateSubmitted, formattedDueDate) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003EDishcharge Monitoring Report\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cform class=\"container-fluid\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Clabel for=\"due_date\"\u003EDue Date\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"due_date\" name=\"due_date\""+pug.attr("disabled", true, true, true)+pug.attr("value", formattedDueDate, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Clabel\u003EDate DMR Submitted:\u003C\u002Flabel\u003E\u003Cdiv class=\"input-group date\" data-role=\"datepicker\"\u003E\u003Cinput" + (" class=\"form-control\""+" type=\"text\" name=\"date_submitted\""+pug.attr("value", formattedDateSubmitted, true, true)) + "\u003E\u003Cspan class=\"input-group-addon\"\u003E\u003Ci class=\"glyphicon glyphicon-th\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success btn-submit\" type=\"button\"\u003ESave changes\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "formattedDateSubmitted" in locals_for_with ?
        locals_for_with.formattedDateSubmitted :
        typeof formattedDateSubmitted !== 'undefined' ? formattedDateSubmitted : undefined, "formattedDueDate" in locals_for_with ?
        locals_for_with.formattedDueDate :
        typeof formattedDueDate !== 'undefined' ? formattedDueDate : undefined));
    ;;return pug_html;};
module.exports = template;