var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (name) {
      pug_html = pug_html + "\u003Cform class=\"clearfix\" id=\"layer-name-form\" action=\"\"\u003E\u003Cdiv id=\"layer-name-input-container\"\u003E\u003Ch4\u003EName:\u003C\u002Fh4\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"layer-name\" name=\"name\" type=\"text\""+pug.attr("value", name, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"layer-tags-select-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"aerial-imagery-upload-container\"\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-success pull-right\" type=\"submit\" data-id=\"close\"\u003ESave\u003C\u002Fbutton\u003E\u003C\u002Fform\u003E";
    }.call(this, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;