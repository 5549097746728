/* eslint-disable */
import { Api } from '@monorepo/shared/apiClient';
import { SaveState } from '@monorepo/shared/types/SaveState';
import sectorData from 'industrial-stormwater-sector-data';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { queryCache } from 'react-query';
import { connect } from 'react-redux';
import logger from '../../../../../../../server/config/logger';
import {
  addWastewaterSamplingEventAction,
  editWastewaterSamplingEventAction,
  fetchWastewaterSamplingEventAction,
  initSamplingEventAction,
  initWastewaterLimitCalculationsAction,
} from '../../../../actions/wastewater';
import APP from '../../../../config';
import { matchSamplingEventsKey } from '../../../../hooks/water/useWastewaterSamplingEvents';
import { delayedModalClose } from '../../../../utils';
import withProvider from '../../../withProvider';
import { validateOnSave } from '../../validations/wastewaterSamplingResultValidations';
import SamplingResultModal from './SamplingResultModal';

class SamplingResultModalContainer extends Component {
  constructor(props) {
    super(props);
    this.INITIAL_STATE = {
      deleteErrored: false,
      errorCount: 0,
      eventDate: null,
      eventTime: null,
      hasFetchedSamplingEvent: false,
      hasFinishedFetchingSamplingEvent: false,
      labReportNumber: null,
      samplingResults: [],
      saveError: null,
      saveState: SaveState.CLEAN,
      showConfirmation: false,
      showDeleteSamplingEventConfirmation: false,
      showFormError: false,
    };
    this.state = this.INITIAL_STATE;
  }

  componentDidUpdate() {
    const {
      attachment,
      fetchWastewaterSamplingEvent,
      open,
      project,
      samplingEvent,
    } = this.props;

    const {
      hasFetchedSamplingEvent,
      hasFinishedFetchingSamplingEvent,
      samplingResults,
      saveState,
    } = this.state;

    APP.isNavigationBlocked = ![SaveState.CLEAN, SaveState.SAVED].includes(
      saveState,
    );

    const userIsEditing = !!attachment?.samplingEventId;

    // User just opened the modal to edit sampling event => fetch sampling event data
    if (open && userIsEditing && !hasFetchedSamplingEvent) {
      return this.setState(
        {
          hasFetchedSamplingEvent: true,
        },
        () =>
          fetchWastewaterSamplingEvent(project.id, attachment.samplingEventId),
      );
    }

    // When sampling event is fetched copy its data to the state,
    // this will be updated when user edit sampling results or event dates
    if (
      open &&
      userIsEditing &&
      samplingEvent &&
      samplingResults.length === 0 &&
      !hasFinishedFetchingSamplingEvent
    ) {
      const { eventDate, eventTime, labReportNumber } = samplingEvent;
      return this.setState({
        hasFinishedFetchingSamplingEvent: true,
        eventDate,
        eventTime,
        labReportNumber,
        samplingResults: samplingEvent.samplingResults,
      });
    }

    return null;
  }

  handleFormChange = (payload) => {
    this.setState({
      [payload.name]: payload.value,
      saveState: SaveState.DIRTY,
    });
  };

  handleSave = () => {
    const { samplingResults } = this.state;
    const resultsWithOnSaveValidations = samplingResults.reduce(
      (accum, result) =>
        accum.concat({
          ...result,
          errors: validateOnSave(result),
        }),
      [],
    );

    let firstFaultyResult = null;
    const errorCount = resultsWithOnSaveValidations.reduce((total, result) => {
      if (result.errors.length > 0 && !firstFaultyResult) {
        firstFaultyResult = result;
      }
      return total + result.errors.length;
    }, 0);

    // when there are duplicates (multiple results attached to the same event),
    // and one of them is faulty, but not the one that is currently visible in the UI
    // this error message won't show in the UI, so printing it to give CX a hint at what's going on
    if (firstFaultyResult) {
      logger.error(
        `Invalid sampling results data: ${firstFaultyResult.errors[0].errorMessage} for parameter ${firstFaultyResult.parameterSlug}`,
      );
    }

    this.setState(
      {
        errorCount,
        samplingResults: resultsWithOnSaveValidations,
        saveError: null,
        showFormError: errorCount > 0,
      },
      () => {
        if (errorCount === 0) {
          const samplingResultsToSave = samplingResults.map(
            ({ errors, ...rest }) => ({ ...rest }),
          );
          this.saveEventAndResults(samplingResultsToSave);
        }
      },
    );
  };

  saveEventAndResults = (samplingResultsToSave) => {
    const {
      attachment,
      addWastewaterSamplingEvent,
      editWastewaterSamplingEvent,
      project,
    } = this.props;
    const { eventDate, eventTime, labReportNumber } = this.state;

    const timeString = `${eventTime.getHours()}:${eventTime.getMinutes()}:00}`;
    const eventData = {
      id: attachment?.samplingEventId,
      eventDate,
      eventTime: timeString,
      labReportNumber,
      samplingResults: samplingResultsToSave,
    };

    this.setState({ saveState: SaveState.SAVING });

    let savePromise;
    if (!attachment?.samplingEventId) {
      savePromise = addWastewaterSamplingEvent(project.id, eventData);
    } else {
      savePromise = editWastewaterSamplingEvent(
        project.id,
        attachment.samplingEventId,
        eventData,
      );
    }
    savePromise
      .then(() => {
        this.setState(
          {
            saveState: SaveState.SAVED,
          },
          () => delayedModalClose(this.closeOrShowConfirmation),
        );
      })
      .catch((error) => {
        this.setState({
          saveError: error,
          saveState: SaveState.DIRTY,
          showFormError: true,
        });
      })
      .finally(() => {
        this.removeAllSamplingEventsQueries();
      });
  };

  closeOrShowConfirmation = () => {
    const { saveState } = this.state;
    if ([SaveState.CLEAN, SaveState.SAVED].includes(saveState)) {
      this.closeModal();
    } else {
      this.setState({ showConfirmation: true });
    }
  };

  showDeleteSamplingEventConfirmation = () => {
    this.setState({ showDeleteSamplingEventConfirmation: true });
  };

  closeModal = () => {
    const { initSamplingEvent, initWastewaterLimitCalculations, onClose } =
      this.props;
    // Close the modal first before resetting its state
    // because all side effects will take place only when the modal is open
    onClose();
    initSamplingEvent();
    initWastewaterLimitCalculations();
    this.setState(this.INITIAL_STATE);
  };

  deleteSamplingEvent = async () => {
    const { project, attachment } = this.props;
    const projectId = project.id;
    const samplingEventId = attachment?.samplingEventId;

    try {
      await Api.deleteWastewaterSamplingEvent(projectId, samplingEventId);
      this.removeAllSamplingEventsQueries();
      this.closeModal();
    } catch (e) {
      this.setState({ deleteErrored: true });
      // eslint-disable-next-line no-console
      console.log('deleting this event errored with the following error:', e);
    }
  };

  removeAllSamplingEventsQueries() {
    // invalidate all sampling events on this project, since we might have deleted
    // results for multiple parameters
    const { project } = this.props;
    const queryKeyForAllSamplingEvents = matchSamplingEventsKey(project.id);
    queryCache.removeQueries(queryKeyForAllSamplingEvents);
  }

  render() {
    const {
      attachment,
      initialSearchParameterSlug,
      isLoading,
      monitoringLocations,
      open,
      project,
      wastewaterParameters,
    } = this.props;

    const {
      deleteErrored,
      errorCount,
      eventDate,
      eventTime,
      labReportNumber,
      samplingResults,
      saveError,
      saveState,
      showConfirmation,
      showDeleteSamplingEventConfirmation,
      showFormError,
    } = this.state;

    return (
      <SamplingResultModal
        deleteErrored={deleteErrored}
        errorCount={errorCount}
        eventDate={eventDate}
        eventTime={eventTime}
        initialSearchParameterSlug={initialSearchParameterSlug}
        isCancelDisabled={SaveState.SAVING === saveState}
        isLoading={isLoading}
        labReportNumber={labReportNumber}
        onClose={this.closeOrShowConfirmation}
        onCloseErrorMessage={() =>
          this.setState({
            errorCount: 0,
            saveError: null,
            showFormError: false,
          })
        }
        onConfirmCancel={() => this.setState({ showConfirmation: false })}
        onDeleteSamplingEvent={this.showDeleteSamplingEventConfirmation}
        onCancelDeleteSamplingEvent={() =>
          this.setState({ showDeleteSamplingEventConfirmation: false })
        }
        onConfirmClose={this.closeModal}
        onConfirmDeleteSamplingEvent={this.deleteSamplingEvent}
        onFormChange={this.handleFormChange}
        onSave={this.handleSave}
        open={open}
        project={project}
        samplingEventId={attachment?.samplingEventId}
        samplingResults={samplingResults}
        saveError={saveError}
        saveState={saveState}
        showConfirmation={showConfirmation}
        showDeleteSamplingEventConfirmation={
          showDeleteSamplingEventConfirmation
        }
        showFormError={showFormError}
        monitoringLocations={monitoringLocations}
        wastewaterParameters={wastewaterParameters}
      />
    );
  }
}

SamplingResultModalContainer.defaultProps = {
  attachment: null,
  initialSearchParameterSlug: null,
  isLoading: false,
  open: false,
  samplingEvent: null,
  monitoringLocations: [],
  wastewaterParameters: [],
};

SamplingResultModalContainer.propTypes = {
  addWastewaterSamplingEvent: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    samplingResultId: PropTypes.string,
  }),
  editWastewaterSamplingEvent: PropTypes.func.isRequired,
  fetchWastewaterSamplingEvent: PropTypes.func.isRequired,
  initialSearchParameterSlug: PropTypes.string,
  initSamplingEvent: PropTypes.func.isRequired,
  initWastewaterLimitCalculations: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  samplingEvent: PropTypes.shape({
    id: PropTypes.string,
    eventDate: PropTypes.instanceOf(Date),
    eventTime: PropTypes.instanceOf(Date),
    samplingResults: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  wastewaterParameters: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  const {
    project,
    wastewater: { isFetching, monitoringLocations, parameters, samplingEvent },
  } = state;

  const stateSectorData = sectorData(project.state);
  const filteredAndSortedParams = Object.values(parameters || {})
    // pH sampling results are recorded separately
    .filter((p) => p.parameter_slug !== 'ph')
    .sort((p1, p2) => {
      const displayText1 = stateSectorData.getParameterDisplayText(
        p1.parameter_slug,
      );
      const displayText2 = stateSectorData.getParameterDisplayText(
        p2.parameter_slug,
      );
      return displayText1.toLowerCase() > displayText2.toLowerCase() ? 1 : -1;
    });

  return {
    isLoading: isFetching?.samplingEvent ?? false,
    monitoringLocations,
    project,
    samplingEvent,
    wastewaterParameters: filteredAndSortedParams,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addWastewaterSamplingEvent: (projectId, eventData) =>
    dispatch(addWastewaterSamplingEventAction(projectId, eventData)),
  editWastewaterSamplingEvent: (projectId, samplingEventId, eventData) =>
    dispatch(
      editWastewaterSamplingEventAction(projectId, samplingEventId, eventData),
    ),
  fetchWastewaterSamplingEvent: (projectId, samplingEventId) =>
    dispatch(fetchWastewaterSamplingEventAction(projectId, samplingEventId)),
  initSamplingEvent: () => dispatch(initSamplingEventAction()),
  initWastewaterLimitCalculations: () =>
    dispatch(initWastewaterLimitCalculationsAction()),
});

export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(SamplingResultModalContainer),
);
