import { MapFeatureResponse, waterTags } from 'mapistry-shared';
import { QueryConfig, useQuery } from 'react-query';
import { Api } from '../apiClient';

export const createKey = ({ projectId, tags }: Api.FetchMapFeaturesProps) =>
  ['map-features', projectId, tags] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchMapFeatures
>;

const fetcher: Fetcher = (_, projectId, tags) =>
  Api.fetchMapFeatures({
    projectId,
    tags,
  });

type UseMapFeaturesProps = Api.FetchMapFeaturesProps & {
  config?: QueryConfig<MapFeatureResponse[], Api.ErrorResponse>;
};

export function useMapFeatures({
  config: inputConfig,
  projectId,
  tags,
}: UseMapFeaturesProps) {
  const config = {
    enabled: !!projectId,
    ...inputConfig,
  };
  const key = createKey({ projectId, tags });
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    mapFeatures: data,
  };
}

type UseWastewaterMonitoringLocationsProps = { projectId: string } & {
  config?: QueryConfig<MapFeatureResponse[], Api.ErrorResponse>;
};

export function useWastewaterMonitoringLocations({
  config: inputConfig,
  projectId,
}: UseWastewaterMonitoringLocationsProps) {
  const config = {
    enabled: !!projectId,
    ...inputConfig,
  };

  const { mapFeatures, ...rest } = useMapFeatures({
    projectId,
    tags: waterTags,
    config,
  });

  return { monitoringLocations: mapFeatures, ...rest };
}

export function useWastewaterMonitoringLocation({
  projectId,
  locationId,
}: {
  projectId: string;
  locationId: string;
}) {
  const { monitoringLocations, ...rest } = useWastewaterMonitoringLocations({
    projectId,
  });

  const monitoringLocation = monitoringLocations?.find(
    (l) => l.id === locationId,
  );

  return {
    monitoringLocation,
    ...rest,
  };
}
