/* eslint-disable */
module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/dmrLayout.pug'),

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
    this.collection = options.collection;
  },

  regions: {
    dmrRecords: '.form-list',
  },

  onShow() {
    this._showDMRRecords();
  },

  _showDMRRecords() {
    let DMRCollectionView = require('./DMRCollectionView');
    let dmrCollectionView = new DMRCollectionView({
      collection: this.collection,
      isDisabled: this.isDisabled,
    });

    this.dmrRecords.show(dmrCollectionView);
  },
});
