/* eslint-disable */
const SamplePeriodCircle = require('./SamplePeriodCircleView');

const {
  allSamplesMeetRequirements,
} = require('../../../../../../../../lib/samplingParameterResultsHelper');

module.exports = Backbone.Marionette.LayoutView.extend({
  regions: {
    sample1: '.sample-1',
  },

  template: require('./templates/semiAnnualOutfallAndFrequencySamplingPeriod.pug'),

  initialize(options = {}) {
    this.requiresSamplesAtAllLocations = options.requiresSamplesAtAllLocations;
    this.samplingParameters = options.samplingParameters;
    this.samplingResults = options.samplingResults;
    this.semiAnnualLabel = options.semiAnnualLabel;
  },

  templateHelpers() {
    return {
      semiAnnualLabel: this.semiAnnualLabel,
    };
  },

  onShow() {
    const status = allSamplesMeetRequirements(
      this.samplingParameters,
      this.samplingResults,
      this.requiresSamplesAtAllLocations,
    )
      ? 'complete'
      : 'incomplete';

    this.sample1.show(new SamplePeriodCircle({ status }));
  },
});
