/* eslint-disable */
var BenchmarkLimitRowView = Backbone.Marionette.ItemView.extend({
  tagName: 'table',

  className: 'table',

  template: require('./templates/benchmarkLimitRow.pug'),

  modelEvents: {
    'change:locations': 'render',
  },

  initialize: function (options = {}) {
    this.sectorData = options.sectorData;
  },

  templateHelpers() {
    let benchmarkLimit = this.model.get('benchmark_limit');

    return {
      anyExceedances: this._anyExceedances(benchmarkLimit),
      benchmarkLimitText: this._benchmarkLimitText(benchmarkLimit),
    };
  },

  _anyExceedances(benchmarkLimit) {
    var result = false;
    _.each(this.model.get('locations'), (location) => {
      if (location.parameter_value > benchmarkLimit) result = true;
    });
    return result;
  },

  _benchmarkLimitText(benchmarkLimit) {
    const parameterSlug = this.model.get('slug');
    const samplingUnits = this.sectorData.getSamplingUnits(parameterSlug);
    const samplingUnitsText = samplingUnits && samplingUnits.display_text;
    return `${benchmarkLimit} ${samplingUnitsText}`;
  },
});

module.exports = BenchmarkLimitRowView;
