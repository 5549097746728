import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { useProjectUserOptions } from '@monorepo/shared/hooks/useProjectUserOptions';
import { SaveState } from '@monorepo/shared/types/SaveState';
import _get from 'lodash.get';
import { localEquivalentOfUTC, UTCEquivalentOfLocal } from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import APP from '../../../../config';
import { isNullOrUndefined } from '../../../../utils';
import {
  Button,
  ConfirmDialog,
  DatePicker,
  ErrorMessage,
  FieldWrapper,
  MapistryTooltip,
  Modal,
  SaveButton,
  Select,
  TextField,
} from '../../../elements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function EditFlowLogModal({
  addReadingIsDisabled,
  formDraft,
  formErrors,
  monitoringLocations,
  onAddFlowReading,
  onClose,
  onCloseErrorMessage,
  onConfirmCancel,
  onConfirmClose,
  onRemoveFlowReading,
  onSave,
  open,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/prop-types
  originalReportingUserId,
  saveError,
  saveState,
  showConfirmation,
  stageEdit,
}) {
  const { flowLogReadings } = formDraft;

  const { userOptions } = useProjectUserOptions({
    projectId: APP.projectId,
    previouslySavedUserId: originalReportingUserId,
  });

  const dischargeLocationsOptions = useMemo(
    () =>
      monitoringLocations
        .filter((l) => !l.sampleType && !l.parentId) // QA locations are only for sampling results
        .map((location) => ({
          label: location.name,
          value: location.id,
        })),
    [monitoringLocations],
  );

  const selectedLocationIds = formDraft.flowLogReadings.map(
    ({ monitoringLocationId }) => monitoringLocationId,
  );

  const availableLocationOptions = (idx) =>
    dischargeLocationsOptions.filter(
      (locationOption) =>
        locationOption.value === flowLogReadings[idx].monitoringLocationId ||
        !selectedLocationIds.includes(locationOption.value),
    );

  const errorComponent = (id, msg) => <div key={`error-${id}`}>{msg}</div>;
  const errorComponents = formErrors.displayable.map((error) =>
    errorComponent(error.id, error.message),
  );
  return (
    <Modal
      className="edit-flow-log-modal"
      footer={
        <div className="main-actions">
          <Button
            color="secondary"
            disabled={SaveState.SAVING === saveState}
            onClick={onClose}
          >
            {SaveState.DIRTY === saveState ? 'Cancel' : 'Close'}
          </Button>
          <span className="spacer" />
          <SaveButton onSave={onSave} saveState={saveState} />
        </div>
      }
      header={<h4>Flow Log</h4>}
      onClose={onClose}
      open={open}
    >
      <ConfirmDialog
        confirmButtonText="Close without saving"
        danger
        onCancelled={onConfirmCancel}
        onConfirmed={onConfirmClose}
        open={showConfirmation}
        title="Are you sure you want to close without saving?"
      />
      {saveError && (
        <ErrorMessage errorMessage={saveError} onClose={onCloseErrorMessage} />
      )}
      <div className="flow-log-details">
        <div
          className={`error-row ${
            errorComponents.length > 0 ? 'error-row-shown' : ''
          }`}
        >
          {errorComponents.length > 0 && (
            <>
              <div className="error-row-heading">
                These are validation errors:
              </div>
              {errorComponents}
            </>
          )}
        </div>
        <div className="flow-log-shared-readings-fields">
          <div className="field-wrapper">
            <FieldWrapper isRequired label="Date of discharge">
              <DatePicker
                autoOk
                className="date-field"
                clearable
                error={!isNullOrUndefined(formErrors.dateOfDischarge)}
                maxDate={new Date()}
                onChange={(date) =>
                  stageEdit({
                    dateOfDischarge: UTCEquivalentOfLocal(
                      new Date(date.setHours(0, 0, 0)),
                    ),
                  })
                }
                showHelperText={false}
                value={localEquivalentOfUTC(formDraft.dateOfDischarge)}
              />
            </FieldWrapper>
          </div>
          <div className="field-wrapper person-reporting-discharge">
            <FieldWrapper isRequired label="Person reporting discharge">
              <Select
                error={!isNullOrUndefined(formErrors.personReportingDischarge)}
                isClearable={false}
                onChange={(opt) =>
                  stageEdit({ personReportingDischarge: opt.value })
                }
                options={userOptions}
                value={userOptions.find(
                  (opt) => opt.value === formDraft.personReportingDischarge,
                )}
              />
            </FieldWrapper>
          </div>
        </div>
      </div>
      <div className="flow-log-readings-table">
        <div className="row-header">
          <div>DISCHARGE LOCATION</div>
          <div>
            VOLUME
            <span className="required-label">(required)</span>
          </div>
          <div>HOURS OF DISCHARGE</div>
          <div className="actions-label">ACTIONS</div>
        </div>
        {flowLogReadings.map((flowLogReading, idx) => (
          <div className="row-details" key={flowLogReading.key}>
            <div className="field-wrapper">
              <FieldWrapper isRequired label="Discharge Location">
                <Select
                  error={
                    !!_get(
                      formErrors,
                      `flowLogReadings[${idx}].monitoringLocationId`,
                    )
                  }
                  isClearable={false}
                  isFixed
                  onChange={(opt) =>
                    stageEdit({ monitoringLocationId: opt.value }, idx)
                  }
                  options={availableLocationOptions(idx)}
                  value={dischargeLocationsOptions.find(
                    (option) =>
                      option.value ===
                      flowLogReadings[idx].monitoringLocationId,
                  )}
                />
              </FieldWrapper>
            </div>
            <div className="field-wrapper">
              <FieldWrapper
                className="field-w-units"
                footerText="gallons"
                isRequired
                label="Volume"
              >
                <TextField
                  controlled={false}
                  defaultValue={flowLogReadings[idx].flowReading || ''}
                  error={
                    !!_get(formErrors, `flowLogReadings[${idx}].flowReading`)
                  }
                  onBlur={(e) =>
                    stageEdit(
                      {
                        flowReading:
                          e.target.value === '' ? null : e.target.value,
                      },
                      idx,
                    )
                  }
                />
              </FieldWrapper>
            </div>
            <div className="field-wrapper">
              <FieldWrapper label="Hours of Discharge">
                <TextField
                  controlled={false}
                  defaultValue={flowLogReadings[idx].hoursOfDischarge || ''}
                  error={
                    !!_get(
                      formErrors,
                      `flowLogReadings[${idx}].hoursOfDischarge`,
                    )
                  }
                  onBlur={(e) =>
                    stageEdit(
                      {
                        hoursOfDischarge:
                          e.target.value === '' ? null : e.target.value,
                      },
                      idx,
                    )
                  }
                />
              </FieldWrapper>
            </div>
            <div className="row-delete actions-label">
              <Button
                className="button-delete"
                color="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveFlowReading(idx);
                }}
              >
                Delete flow reading
              </Button>
              <MapistryTooltip placement="top" title="Delete this flow reading">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className="icon-delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveFlowReading(idx);
                  }}
                >
                  <SvgIcon identifier="trash" className="trash-icon" />
                </div>
              </MapistryTooltip>
            </div>
          </div>
        ))}
        {!addReadingIsDisabled && (
          <div className="add-flow-log-reading">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div onClick={onAddFlowReading}>
              <SvgIcon identifier="add" />
              <span>Add a flow reading</span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

EditFlowLogModal.defaultProps = {
  monitoringLocations: [],
  saveError: null,
};
EditFlowLogModal.propTypes = {
  addReadingIsDisabled: PropTypes.bool.isRequired,
  formDraft: PropTypes.shape({
    dateOfDischarge: PropTypes.instanceOf(Date),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    flowLogReadings: PropTypes.arrayOf(PropTypes.object),
    personReportingDischarge: PropTypes.string,
  }).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        message: PropTypes.string,
      }),
    ),
    dateOfDischarge: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    flowLogReadings: PropTypes.arrayOf(PropTypes.object),
    personReportingDischarge: PropTypes.string,
  }).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onAddFlowReading: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseErrorMessage: PropTypes.func.isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  onConfirmClose: PropTypes.func.isRequired,
  onRemoveFlowReading: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  saveError: PropTypes.string,
  saveState: PropTypes.oneOf([...Object.values(SaveState)]).isRequired,
  showConfirmation: PropTypes.bool.isRequired,
  stageEdit: PropTypes.func.isRequired,
};
