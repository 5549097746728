/* eslint-disable */
const WebMapLabelOptionsFactory = require('./WebMapLabelOptionsFactory');
const { extractLeaderlineOptions } = require('./utils');

const WebMapPolylineFactory = {
  create: function (shapeModel) {
    const polyGroup = shapeModel.get('featureGroup');
    const styleModel = shapeModel.get('styleModel');
    const geography = shapeModel.get('geography');
    const liderlineGroup = shapeModel.get('liderlineGroup');
    const hiddenLiderlineArray = shapeModel.get('hiddenLiderlineArray');
    var leafletLayer = L.polyline(geography, styleModel.camelizeToJSON());
    var styleSettings = {
      customIcon: styleModel.getIcon(),
      decoratorDirectional: styleModel.get('decorator_directional'),
      decoratorSingleSymbol: styleModel.get('decorator_single_symbol'),
    };
    var decorator = leafletLayer.addDecorator(shapeModel.id, styleSettings);

    // Draw a thicker invisible line over line to make it easier to click
    if (styleModel.get('weight') < 10) {
      var leafletLayerMask = this._createLayerMask(shapeModel);

      polyGroup.addLayer(leafletLayerMask);
      leafletLayer.hasMask = true;
    }

    if (decorator) {
      polyGroup.addLayer(decorator);

      var markersOnDecorator = decorator.getLayers();

      // Add modelId onto each marker on decorator
      markersOnDecorator.forEach(function (markerLayer) {
        markerLayer.modelId = shapeModel.id;
        markerLayer.type = shapeModel.get('type');

        // Add the decorator markers to marker cluster group, so we have a way
        // to listen to events that happen to the decorator marker
        shapeModel.get('decoratorMarkerGroup').addLayer(markerLayer);
      });
      leafletLayer.hasDecorator = true;
    }

    if (styleModel.needToShowLabel()) {
      require('./leaderline/BaseLabelMethods');

      const centerCoordinates = leafletLayer.getBounds().getCenter();
      const labelOptions = WebMapLabelOptionsFactory.create(
        shapeModel,
        centerCoordinates,
      );
      leafletLayer.bindLabel(shapeModel.get('labelContent'), labelOptions);

      const leaderlineStyle = styleModel.get('leaderline_style');
      if (leaderlineStyle && leaderlineStyle === 'standard') {
        leafletLayer
          .getLabel()
          .bindLeaderline(
            extractLeaderlineOptions(styleModel),
            leafletLayer,
            liderlineGroup,
            hiddenLiderlineArray,
          );
      }
    }

    return leafletLayer;
  },

  _createLayerMask: function (shapeModel) {
    var leafletLayerMask;
    var options = shapeModel.get('styleModel').toJSON();

    options.weight = 10;
    options.opacity = 0;
    leafletLayerMask = L.polyline(shapeModel.get('geography'), options);
    leafletLayerMask._leaflet_id = shapeModel.id + '_mask';
    leafletLayerMask.isPolylineMask = true;
    leafletLayerMask.modelId = shapeModel.id;
    leafletLayerMask.type = shapeModel.get('type');

    return leafletLayerMask;
  },
};

module.exports = WebMapPolylineFactory;
