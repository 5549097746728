import { SaveState } from '@monorepo/shared/types/SaveState';
import { startOfToday } from 'date-fns';
import update from 'immutability-helper';
import _get from 'lodash.get';
import { CalendarName, UTCEquivalentOfLocal } from 'mapistry-shared';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import _s from 'underscore.string';
import APP from '../../../../config';
import withProvider from '../../../withProvider';
import PhLogModal from './PhLogModal';

import {
  createPhLogReadingAction,
  editPhLogReadingAction,
  fetchWastewaterMonitoringLocationsAction,
} from '../../../../actions/wastewater';
import { delayedModalClose } from '../../../../utils';

import { fetchSamplingCalendarEvents } from '../../../../actions/calendar';

class PhLogModalContainer extends Component {
  constructor(props) {
    super(props);
    this.INITIAL_STATE = {
      formErrors: {},
      formSaveState: SaveState.CLEAN,
      formSubmissionDraft: {
        logDate: UTCEquivalentOfLocal(startOfToday()),
        logTime: new Date(),
        recordsLocations: [
          {
            locationId: null,
            phReading: null,
            phType: null,
          },
        ],
      },
      originalReadingUserId: null,
      saveError: null,
      shouldDisableAddLocation: false,
      shouldShowDirtyCloseConfirmation: false,
    };
    this.state = this.INITIAL_STATE;

    this.addRecordLocation = this.addRecordLocation.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onModalRequestClose = this.onModalRequestClose.bind(this);
    this.removeRecordLocationAtIdx = this.removeRecordLocationAtIdx.bind(this);
    this.stageEdit = this.stageEdit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.validateFormSubmission = this.validateFormSubmission.bind(this);
  }

  componentDidMount() {
    const { fetchWastewaterMonitoringLocations } = this.props;
    fetchWastewaterMonitoringLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { attachment } = this.props;
    const { formSaveState } = this.state;

    APP.isNavigationBlocked = formSaveState === SaveState.DIRTY;

    const userIsEditing =
      (!_get(prevState, 'formSubmissionDraft.id') && _get(attachment, 'id')) ||
      (attachment !== prevProps.attachment &&
        _get(prevState, 'formSubmissionDraft.id'));
    if (userIsEditing) {
      // TODO: looks like db model data is bleeding through here, this should be fixed
      this.setState((prev) =>
        update(prev, {
          formSubmissionDraft: {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/prop-types
            id: { $set: attachment.id },
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/prop-types
            logDate: { $set: attachment.log_date },
            logTime: {
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line react/prop-types
              $set: `${attachment.log_date.slice(0, 10)}T${
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types
                attachment.log_time
              }`,
            },
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/prop-types
            userId: { $set: attachment.user_id },
            recordsLocations: {
              0: {
                $set: {
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line react/prop-types
                  locationId: attachment.monitoring_location_id,
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line react/prop-types
                  phType: _s.camelize(attachment.ph_type),
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line react/prop-types
                  phReading: attachment.ph_reading,
                },
              },
            },
          },
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line react/prop-types
          originalReadingUserId: { $set: attachment.user_id },
          shouldDisableAddLocation: { $set: true },
        }),
      );
    }
  }

  onModalRequestClose() {
    const { formSaveState } = this.state;
    if (formSaveState !== SaveState.CLEAN) {
      this.setState({
        shouldShowDirtyCloseConfirmation: true,
      });
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    const { onClose } = this.props;
    this.setState(this.INITIAL_STATE, onClose);
  }

  addRecordLocation() {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          recordsLocations: {
            $push: [
              {
                locationId: null,
                phReading: null,
                phType: null,
              },
            ],
          },
        },
      }),
    );
  }

  removeRecordLocationAtIdx(idx) {
    this.setState((prevState) =>
      update(prevState, {
        formSubmissionDraft: {
          recordsLocations: {
            $splice: [[idx, 1]],
          },
        },
      }),
    );
  }

  stageEdit(editField, nextValue, recordsLocationsIdx = null) {
    if (!_.isNull(recordsLocationsIdx)) {
      this.setState((prevState) =>
        update(prevState, {
          formSaveState: { $set: SaveState.DIRTY },
          formSubmissionDraft: {
            recordsLocations: {
              [recordsLocationsIdx]: {
                $merge: {
                  [editField]: nextValue,
                },
              },
            },
          },
        }),
      );
    } else {
      this.setState((prevState) =>
        update(prevState, {
          formSaveState: { $set: SaveState.DIRTY },
          formSubmissionDraft: {
            $merge: {
              [editField]: nextValue,
            },
          },
        }),
      );
    }
  }

  submitForm() {
    const { createOrUpdatePhLogReading, event } = this.props;
    const { formSubmissionDraft } = this.state;
    this.setState({
      formSaveState: SaveState.SAVING,
      saveError: null,
    });

    Promise.all(
      formSubmissionDraft.recordsLocations.map((rl) => {
        const { logTime } = formSubmissionDraft;
        const timeString = `${logTime.getHours()}:${logTime.getMinutes()}:${logTime.getSeconds()}`;
        return createOrUpdatePhLogReading(
          {
            id: formSubmissionDraft.id,
            logDate: formSubmissionDraft.logDate,
            logTime: timeString,
            monitoringLocationId: rl.locationId,
            phReading: rl.phReading,
            phType: rl.phType,
            userId: formSubmissionDraft.userId,
          },
          { start: _get(event, 'start', null), end: _get(event, 'end', null) },
        );
      }),
    )
      .then(() => {
        this.setState(
          {
            formSaveState: SaveState.SAVED,
          },
          () => delayedModalClose(this.closeModal),
        );
      })
      .catch((error) => {
        this.setState({
          formSaveState: SaveState.DIRTY,
          saveError: error,
        });
      });
  }

  validateFormSubmission() {
    const { formSubmissionDraft } = this.state;
    const formErrors = {
      displayable: [],
      recordsLocations: [],
    };
    /*
     * Ensure required fields exist
     */
    const requiredFieldsWithMessage = [
      {
        field: 'logDate',
        message: 'Log date is required.',
      },
      {
        field: 'logTime',
        message: 'Log time is required.',
      },
      {
        field: 'userId',
        message: 'Person reading pH meter is required.',
      },
    ];

    _.map(requiredFieldsWithMessage, (rf) => {
      if (!_.has(formSubmissionDraft, rf.field)) {
        formErrors[rf.field] = rf.message;
        formErrors.displayable.push(rf.message);
      }
    });

    const locationRequiredFieldsWithMessage = [
      {
        field: 'locationId',
        message: 'Monitoring location is required.',
      },
      {
        field: 'phType',
        message: 'pH reading type is required.',
      },
      {
        field: 'phReading',
        message: 'pH value is required.',
      },
    ];

    _.map(formSubmissionDraft.recordsLocations, (rl, idx) => {
      _.map(locationRequiredFieldsWithMessage, (rf) => {
        if (_.isNull(rl[rf.field])) {
          const errorMessage = `Location ${idx + 1}: ${rf.message}`;
          formErrors.recordsLocations[idx] = {
            ...formErrors.recordsLocations[idx],
            [rf.field]: errorMessage,
          };
          formErrors.displayable.push(errorMessage);
        }
      });
    });

    /*
     * Ensure fields are of the proper value
     */
    // Ensure pH readings are numeric between 0 and 14
    _.map(formSubmissionDraft.recordsLocations, (rl, idx) => {
      if (
        !_.isFinite(rl.phReading) ||
        !(
          Number.parseFloat(rl.phReading) >= 0 &&
          Number.parseFloat(rl.phReading) <= 14
        )
      ) {
        const errorMessage = `Location ${
          idx + 1
        }: pH reading must be a number between 0 and 14.`;
        formErrors.recordsLocations[idx] = {
          ...formErrors.recordsLocations[idx],
          phReading: errorMessage,
        };
        formErrors.displayable.push(errorMessage);
      }
    });

    if (formErrors.displayable.length === 0) {
      return this.setState({ formErrors: {} }, this.submitForm);
    }

    return this.setState((prevState) =>
      update(prevState, {
        formErrors: {
          $set: formErrors,
        },
      }),
    );
  }

  render() {
    const { monitoringLocations, open, organizationId, projectId } = this.props;
    const {
      formErrors,
      formSaveState,
      formSubmissionDraft,
      originalReadingUserId,
      saveError,
      shouldDisableAddLocation,
      shouldShowDirtyCloseConfirmation,
    } = this.state;
    const goToMapsURL = `/organizations/${organizationId}/projects/${projectId}`;

    return (
      <PhLogModal
        addRecordLocation={this.addRecordLocation}
        errors={formErrors}
        formSaveState={formSaveState}
        formSubmissionDraft={formSubmissionDraft}
        goToMapsURL={() => {
          window.top.location.href = goToMapsURL;
        }}
        monitoringLocations={monitoringLocations}
        onCancelDirtyCloseConfirmation={() =>
          this.setState({ shouldShowDirtyCloseConfirmation: false })
        }
        onClose={this.onModalRequestClose}
        onCloseErrorMessage={() => this.setState({ saveError: null })}
        onConfirmDirtyCloseConfirmation={() =>
          this.setState(
            { shouldShowDirtyCloseConfirmation: false },
            this.closeModal,
          )
        }
        open={open}
        originalReadingUserId={originalReadingUserId}
        removeRecordLocationAtIdx={this.removeRecordLocationAtIdx}
        saveError={saveError}
        shouldDisableAddLocation={shouldDisableAddLocation}
        shouldShowDirtyCloseConfirmation={shouldShowDirtyCloseConfirmation}
        stageEdit={this.stageEdit}
        submitForm={this.validateFormSubmission}
      />
    );
  }
}

PhLogModalContainer.propTypes = {
  attachment: PropTypes.shape({}),
  createOrUpdatePhLogReading: PropTypes.func.isRequired,
  event: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  fetchWastewaterMonitoringLocations: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  organizationId: PropTypes.string,
};

PhLogModalContainer.defaultProps = {
  organizationId: null,
  projectId: null,
  attachment: null,
  event: null,
};

const mapStateToProps = (state) => {
  const {
    project: { id, organizationId },
    wastewater: { monitoringLocations },
  } = state;

  return {
    monitoringLocations,
    organizationId,
    projectId: id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOrUpdatePhLogReading: (phLog, interval) =>
    phLog.id
      ? dispatch(
          editPhLogReadingAction({
            phLog,
            projectId: APP.projectId,
          }),
        ).then(() =>
          dispatch(
            fetchSamplingCalendarEvents({
              calendarName: CalendarName.PH_LOG_READINGS,
              end: interval.end,
              projectId: APP.projectId,
              start: interval.start,
            }),
          ),
        )
      : dispatch(
          createPhLogReadingAction({
            phLog,
            projectId: APP.projectId,
          }),
        ),
  fetchWastewaterMonitoringLocations: () =>
    dispatch(fetchWastewaterMonitoringLocationsAction(APP.projectId)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(PhLogModalContainer),
);
