/* eslint-disable import/no-import-module-exports */
import APP from '@monorepo/old-web/js/config';
import ProjectService from '@monorepo/old-web/js/services/ProjectService';
import { enforceAuthentication } from '../loader';
// eslint-disable-next-line import/default
import ProjectCollection from '../models_collections/projectCollection';
// eslint-disable-next-line import/default
import BaseController from '../BaseController';
import ProjectRecordsLayoutView from '../dashboards/projects/content/generic/projectRecords/ProjectRecordsLayoutView';
// eslint-disable-next-line import/default
import UsersLayoutView from '../dashboards/projects/content/generic/projectUsers/ProjectUsersLayoutView';
// eslint-disable-next-line import/default
import ProjectSettingsLayoutView from '../dashboards/projects/content/generic/projectSettings/ProjectSettingsLayoutView';
import bigSpinner from '../services/bigSpinner';
import BackboneWrapperLayoutView from './BackboneWrapperLayoutView';
/* eslint-enable import/no-import-module-exports */

const BackboneWrappersController = BaseController.extend({
  async projectRecords(projectId) {
    bigSpinner.show();
    try {
      await enforceAuthentication();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-underscore-dangle
      const project = await this._getProject(projectId);
      const projectService = new ProjectService();
      const projectCalendars = await projectService.getCalendars(project.id);
      const recordsView = new ProjectRecordsLayoutView({
        projectModel: project,
        projectCalendars,
      });
      BackboneWrapperLayoutView.content.show(recordsView);
      bigSpinner.hide();
    } catch (e) {
      bigSpinner.hide();
      throw e;
    }
  },

  async projectSettings(projectId) {
    bigSpinner.show();
    try {
      await enforceAuthentication();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-underscore-dangle
      const projectModel = await this._getProject(projectId);
      const settingsView = new ProjectSettingsLayoutView({ projectModel });
      BackboneWrapperLayoutView.content.show(settingsView);
      bigSpinner.hide();
    } catch (e) {
      bigSpinner.hide();
      throw e;
    }
  },

  async projectUsers(projectId) {
    bigSpinner.show();
    try {
      await enforceAuthentication();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-underscore-dangle
      const projectModel = await this._getProject(projectId);
      const usersView = new UsersLayoutView({ projectModel });
      BackboneWrapperLayoutView.content.show(usersView);
      bigSpinner.hide();
    } catch (e) {
      bigSpinner.hide();
      throw e;
    }
  },

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-underscore-dangle
  async _getProject(projectId) {
    APP.projectId = projectId;
    await ProjectCollection.fetchSingleton({ silent: true, reset: true });
    const projectCollection = ProjectCollection.singleton();
    return projectCollection.get(projectId);
  },
});

module.exports = BackboneWrappersController;
