/* eslint-disable */
const projectStateConfig = require('mapistry-project-state-config');

var NoSamplingEventParametersView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/noSamplingEventParameters.pug'),

  templateHelpers: function () {
    return {
      isOregon: projectStateConfig.isOregonIndustrial(this.project),
    };
  },

  initialize: function (options = {}) {
    this.project = options.project;
  },
});

module.exports = NoSamplingEventParametersView;
