var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (alwaysFreshwater, hardnessDoesNotMatter) {
      pug_html = pug_html + "\u003Clabel\u003EIf you test for metals, you may need to let us know the following:\u003C\u002Flabel\u003E\u003Cdiv class=\"well\"\u003E";
if (!alwaysFreshwater) {
pug_html = pug_html + "\u003Clabel\u003EReceiving Waters\u003Ca class=\"badge small margin-left-5\" id=\"receiving-waters-popover\" data-original-title=\"\" title=\"\" data-toggle=\"popover\" data-placement=\"top\"\u003E?\u003C\u002Fa\u003E\u003Cdiv class=\"display-none\" id=\"receiving-waters-popover-content-wrapper\"\u003E\u003Cspan\u003EThe benchmarks you are required to meet might rely on whether or not you discharge to salt or fresh water. Typically these are derived from the EPA MSGP, you can read more about it \u003Ca target=\"_blank\" class='mapistry-green' href='http:\u002F\u002Fwww.mapistry.com\u002Fhubfs\u002Fmsgp2015_appendixj.pdf'\u003Ehere\u003C\u002Fa\u003E.\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003Cdiv\u003E\u003Clabel class=\"radio-inline\"\u003E\u003Cinput id=\"freshwater-radio\" type=\"radio\" name=\"receiving_water_is_saltwater\" value=\"false\"\u003E\u003Cspan class=\"margin-left-5\"\u003EFresh water\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Clabel class=\"radio-inline\"\u003E\u003Cinput id=\"saltwater-radio\" type=\"radio\" name=\"receiving_water_is_saltwater\" value=\"true\"\u003E\u003Cspan class=\"margin-left-5\"\u003ESalt water\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
}
if (!hardnessDoesNotMatter) {
pug_html = pug_html + "\u003Cdiv class=\"margin-top-20 display-none\" id=\"hardness-div\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel for=\"hardness\"\u003EHardness of your receiving water body\u003Ca class=\"badge small margin-left-5\" data-original-title=\"\" title=\"\" data-toggle=\"popover\" data-placement=\"top\" data-content=\"Since you discharge to fresh water, the benchmarks you are required to meet might rely on the hardness of the receiving water body.\"\u003E?\u003C\u002Fa\u003E\u003C\u002Flabel\u003E\u003Cdiv class=\"input-group\" style=\"max-width: 150px\"\u003E\u003Cinput class=\"form-control\" id=\"hardness-input\" type=\"text\" aria-describedby=\"mg-l-addon\" name=\"receiving_water_hardness\"\u003E\u003Cspan class=\"input-group-addon\" id=\"mg-l-addon\"\u003Emg\u002FL\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "alwaysFreshwater" in locals_for_with ?
        locals_for_with.alwaysFreshwater :
        typeof alwaysFreshwater !== 'undefined' ? alwaysFreshwater : undefined, "hardnessDoesNotMatter" in locals_for_with ?
        locals_for_with.hardnessDoesNotMatter :
        typeof hardnessDoesNotMatter !== 'undefined' ? hardnessDoesNotMatter : undefined));
    ;;return pug_html;};
module.exports = template;