/* eslint-disable */
import { mapLayerTagLabels, MapLayerTags, waterTags } from 'mapistry-shared';

const waterTagOptions = waterTags
  .map((t) => ({
    value: t,
    label: mapLayerTagLabels[t],
  }))
  .sort((op1, op2) => (op1.label > op2.label ? 1 : -1));

const DEFAULT_WATER_TAG = MapLayerTags.DISCHARGE_LOCATIONS;

const nonWaterTags = Object.values(MapLayerTags).filter(
  (t) => !waterTags.includes(t),
);

const LayerTagsSelectView = Backbone.Marionette.ItemView.extend({
  className: 'form-group',

  // eslint-disable-next-line global-require
  template: require('./templates/layerTagsSelect.pug'),

  ui: {
    $isDischargeLocationsLayer: 'input#is-discharge-locations-layer',
    $isDischargeLocationsLayerContainer:
      '#is-discharge-locations-layer-container',
    $tagSelect: 'select#layer-tags-select',
    $waterTagsContainer: '#water-tags-container',
    $waterTagSelect: 'select#layer-water-tags-select',
  },

  events: {
    'change @ui.$isDischargeLocationsLayer': '_toggleWaterTagsSelect',
  },

  onRender() {
    const selectedTags = this.model.getFormTags();
    const nonWaterTagOptions = nonWaterTags
      .map((tag) => ({
        id: tag,
        text: mapLayerTagLabels[tag],
        selected: selectedTags.includes(tag),
      }))
      .sort((op1, op2) => (op1.text > op2.text ? 1 : -1));
    this.ui.$tagSelect.select2({
      placeholder: 'Select all tags that apply',
      data: nonWaterTagOptions,
      width: '100%',
    });

    const selectedWaterTags = selectedTags.filter((t) => waterTags.includes(t));
    waterTagOptions.forEach((op) => {
      const isSelected = selectedWaterTags.length
        ? selectedWaterTags.includes(op.value)
        : op.value === DEFAULT_WATER_TAG;

      this.ui.$waterTagSelect.append(
        $('<option>') // eslint-disable-line no-undef
          .attr('value', op.value)
          .attr('selected', isSelected)
          .text(op.label),
      );
    });

    this._toggleWaterTagsSelect();

    // Hack to fix placeholder not showing on initial load
    this.$('.select2-search--inline').css({ width: '170px' });
    this.$('.select2-search__field').css({ width: '170px' });
  },

  getCurrentValue() {
    const nonWaterTagValues = this.ui.$tagSelect.val() || [];
    const waterTagValue = this._isWaterLayer() && this.ui.$waterTagSelect.val();
    const tagValues = waterTagValue
      ? nonWaterTagValues.concat(waterTagValue)
      : nonWaterTagValues;
    return JSON.stringify(tagValues.length ? tagValues : null);
  },

  _toggleWaterTagsSelect() {
    // User decide on layer creation whether it should include discharge locations.
    // On consequent edits they can only change the discharge medium (water tag).
    const isEditingExistingLayer = !this.model.isNew();
    if (isEditingExistingLayer) {
      this.ui.$isDischargeLocationsLayerContainer.hide();
    }

    if (this._isWaterLayer()) {
      this.ui.$waterTagsContainer.show();
    } else {
      this.ui.$waterTagsContainer.hide();
    }
  },

  _isWaterLayer() {
    const isEditingExistingLayer = !this.model.isNew();
    return isEditingExistingLayer
      ? this.model.getFormTags().some((t) => waterTags.includes(t))
      : this.ui.$isDischargeLocationsLayer.is(':checked');
  },
});

module.exports = LayerTagsSelectView;
