var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (featureCount, fileUploadedSuccessfully, hasFailedUpload, isActive, isProcessing, is_permanent, mapbox_filename, mapbox_upload_error, name, needsFileInformation, type) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["layer-container",isActive() ? 'active' : ''], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"flex align-items-center justify-content-space-between\"\u003E\u003Cdiv class=\"flex flex-1\"\u003E\u003Clabel class=\"layer-label\"\u003E\u003Cdiv class=\"flex\"\u003E\u003Cdiv\u003E\u003Ci class=\"fa fa-eye\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-eye-slash\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"layer-name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex buttons\"\u003E";
if (!is_permanent) {
pug_html = pug_html + "\u003Ca class=\"delete margin-right-10 flex align-items-center\" href=\"#\" rel=\"tipsy\" title=\"Delete layer\"\u003E\u003Csvg class=\"trash small red\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Ca class=\"edit flex align-items-center\" href=\"#\" rel=\"tipsy\" title=\"Edit layer\"\u003E\u003Csvg class=\"icon-edit small white\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
if (type !== 'aerialimagery') {
pug_html = pug_html + "\u003Cspan class=\"layer-badge badge clickable\" id=\"layer-count\" rel=\"tipsy\" title=\"Expand feature list\"\u003E" + (pug.escape(null == (pug_interp = featureCount()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
else
if (needsFileInformation()) {
pug_html = pug_html + "\u003Cspan class=\"layer-badge badge clickable\" id=\"layer-processing-in-mapbox\" rel=\"tipsy\" title=\"Aerial imagery layer has no file associated with it, please upload a file\" style=\"background-color: transparent\"\u003E\u003Ca class=\"warning-yellow yellow-on-hover\" href=\"#\"\u003E\u003Ci class=\"fa fa-cloud-upload font-size-20\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
else
if (isProcessing()) {
pug_html = pug_html + "\u003Cspan class=\"layer-badge badge clickable\" id=\"layer-processing-in-mapbox\" rel=\"tipsy\" title=\"Aerial Imagery is still processing\" style=\"background-color: transparent\"\u003E\u003Ca class=\"white\" href=\"#\"\u003E\u003Ci class=\"fa fa-spinner fa-spin font-size-20\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
else
if (hasFailedUpload()) {
pug_html = pug_html + "\u003Cspan" + (" id=\"layer-failed-in-mapbox\" rel=\"tipsy\""+pug.attr("title", mapbox_upload_error || 'File failed to process', true, true)) + "\u003E\u003Ca class=\"red red-on-hover\" href=\"#\"\u003E\u003Ci class=\"fa fa-cloud-upload font-size-20\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
else
if (fileUploadedSuccessfully()) {
pug_html = pug_html + "\u003Cspan" + (" id=\"layer-failed-in-mapbox\" rel=\"tipsy\""+pug.attr("title", mapbox_filename, true, true)) + "\u003E\u003Ca class=\"green green-on-hover\" href=\"#\"\u003E\u003Ci class=\"fa fa-cloud-upload font-size-20\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-11 col-xs-offset-1\"\u003E\u003Cdiv class=\"previous\"\u003E\u003Ca href=\"#\"\u003E\u003Ci class=\"fa fa-arrow-circle-up more-features\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"layer\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"next\"\u003E\u003Ca href=\"#\"\u003E\u003Ci class=\"fa fa-arrow-circle-down more-features\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "featureCount" in locals_for_with ?
        locals_for_with.featureCount :
        typeof featureCount !== 'undefined' ? featureCount : undefined, "fileUploadedSuccessfully" in locals_for_with ?
        locals_for_with.fileUploadedSuccessfully :
        typeof fileUploadedSuccessfully !== 'undefined' ? fileUploadedSuccessfully : undefined, "hasFailedUpload" in locals_for_with ?
        locals_for_with.hasFailedUpload :
        typeof hasFailedUpload !== 'undefined' ? hasFailedUpload : undefined, "isActive" in locals_for_with ?
        locals_for_with.isActive :
        typeof isActive !== 'undefined' ? isActive : undefined, "isProcessing" in locals_for_with ?
        locals_for_with.isProcessing :
        typeof isProcessing !== 'undefined' ? isProcessing : undefined, "is_permanent" in locals_for_with ?
        locals_for_with.is_permanent :
        typeof is_permanent !== 'undefined' ? is_permanent : undefined, "mapbox_filename" in locals_for_with ?
        locals_for_with.mapbox_filename :
        typeof mapbox_filename !== 'undefined' ? mapbox_filename : undefined, "mapbox_upload_error" in locals_for_with ?
        locals_for_with.mapbox_upload_error :
        typeof mapbox_upload_error !== 'undefined' ? mapbox_upload_error : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "needsFileInformation" in locals_for_with ?
        locals_for_with.needsFileInformation :
        typeof needsFileInformation !== 'undefined' ? needsFileInformation : undefined, "type" in locals_for_with ?
        locals_for_with.type :
        typeof type !== 'undefined' ? type : undefined));
    ;;return pug_html;};
module.exports = template;