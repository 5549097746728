import { http } from '@monorepo/shared/apiClient';
import { OnboardingDocumentRequest, OnboardingSession } from './types';

export type FetchOnboardingSessionVariables = undefined;

export type FetchOnboardingSessionResponse = OnboardingSession;

export async function fetchOnboardingSession() {
  const res = await http.get<FetchOnboardingSessionResponse>(
    '/api/v2/onboarding',
  );
  return res.data;
}

export interface CreateOnboardingDocumentRequestVariables {
  documentRequest: CreateOnboardingDocumentRequestValues;
}

export interface CreateOnboardingDocumentRequestValues {
  onboardingSectionId: string;
  title: string;
  notes: string;
}

export type CreateOnboardingDocumentRequestResponse = OnboardingDocumentRequest;

export async function createOnboardingDocumentRequest(
  variables: CreateOnboardingDocumentRequestVariables,
) {
  const { documentRequest } = variables;
  const res = await http.post<CreateOnboardingDocumentRequestResponse>(
    '/api/v2/onboarding/document-request',
    documentRequest,
  );
  return res.data;
}

export interface UpdateOnboardingDocumentRequestVariables {
  documentRequestId: string;
  documentRequest: UpdateOnboardingDocumentRequestValues;
}

export interface UpdateOnboardingDocumentRequestValues {
  title?: string;
}

export type UpdateOnboardingDocumentRequestResponse = OnboardingDocumentRequest;

export async function updateOnboardingDocumentRequest(
  variables: UpdateOnboardingDocumentRequestVariables,
) {
  const { documentRequestId, documentRequest } = variables;
  const res = await http.put<UpdateOnboardingDocumentRequestResponse>(
    `/api/v2/onboarding/document-request/${documentRequestId}`,
    documentRequest,
  );
  return res.data;
}

export interface RemoveOnboardingDocumentRequestVariables {
  documentRequestId: string;
}

export type RemoveOnboardingDocumentRequestResponse = void;

export async function removeOnboardingDocumentRequest(
  variables: RemoveOnboardingDocumentRequestVariables,
) {
  const { documentRequestId } = variables;
  const res = await http.delete<RemoveOnboardingDocumentRequestResponse>(
    `/api/v2/onboarding/document-request/${documentRequestId}`,
  );
  return res.data;
}

export interface UploadOnboardingDocumentVariables {
  documentRequestId: string;
  file: File;
}

export type UploadOnboardingDocumentResponse = void;

export async function uploadOnboardingDocument(
  variables: UploadOnboardingDocumentVariables,
) {
  const { documentRequestId, file } = variables;
  const formData = new FormData();
  formData.append('file', file);
  const res = await http.post<UploadOnboardingDocumentResponse>(
    `/api/v2/onboarding/document-request/${documentRequestId}/upload-document`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
}

export interface ClearOnboardingDocumentVariables {
  documentRequestId: string;
}

export type ClearOnboardingDocumentResponse = void;

export async function clearOnboardingDocument(
  variables: ClearOnboardingDocumentVariables,
) {
  const { documentRequestId } = variables;
  const res = await http.post<ClearOnboardingDocumentResponse>(
    `/api/v2/onboarding/document-request/${documentRequestId}/clear-document`,
  );
  return res.data;
}
