import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../elements';

const EmptyTabContent = (props) => {
  const { addItem, title } = props;
  return (
    <div className="emissions-tracking__empty-tab">
      <div className="emissions-tracking__empty-tab-content">
        <div>You currently have no {title}.</div>
        <div>To add {title} click the button below.</div>
        <Button color="secondary" onClick={() => addItem(null)}>
          Add {title}
        </Button>
      </div>
    </div>
  );
};

EmptyTabContent.propTypes = {
  addItem: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EmptyTabContent;
