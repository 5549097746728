/* eslint-disable */
let _ = require('underscore');
let GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');

let CaliforniaSamplingEventParameterView =
  GenericSamplingEventParameterView.extend({
    _isRequiredSamplingParameter: function (parameterSlug) {
      return this.sectorData.isRequiredSamplingParameter({ parameterSlug });
    },
  });

module.exports = CaliforniaSamplingEventParameterView;
