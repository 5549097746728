/* eslint-disable */
var apiCaller = require('../../../../../../../../apiCaller');

var LocationCollection = Backbone.Collection.extend({
  url: function () {
    return apiCaller.getSamplingResultsUrl(this.projectId);
  },

  initialize: function (models, options = {}) {
    this.projectId = options.projectId;
  },
});

module.exports = LocationCollection;
