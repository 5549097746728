/* eslint-disable */
var InfoModel = require('../models_collections/infoModel');

var InfoView = Backbone.View.extend({
  template: require('./templates/info.pug'),

  events: {
    'hidden.bs.modal': '_hideModal',
  },

  render: function () {
    this.setElement(this.template(this.model.toJSON()));
    this.$el.modal('show');
  },

  showModal: function (options = {}) {
    var title = options.title;
    var text = options.text;

    this.model = new InfoModel({
      title: title,
      text: text,
    });
    this.render();
  },

  _hideModal: function () {
    this.remove();
  },
});

Backbone.addSingletonToView(InfoView);
module.exports = InfoView;
