/* eslint-disable */
let RelatedUsersMultiSelectView = require('./RelatedUsersMultiSelectView');

module.exports = RelatedUsersMultiSelectView.extend({
  activate(emailsToExclude) {
    let options = {
      width: '300px',
      tags: true,
      maximumSelectionLength: 1,
      minimumInputLength: 2,
      placeholder: 'Select an existing user or enter a new email.',
      selectOnClose: true,
    };

    return this._getUsers(emailsToExclude).then((users) => {
      let data = this.getFormattedUserData(users);
      this.ui.$select.select2(_.extend(options, { data }));
      this._initializeOnSelect();
      this._initializeOnUnSelect();
    });
  },

  _initializeOnSelect() {
    this.ui.$select.on(
      'select2:select',
      function (e) {
        let user = e.params.data;
        this.ui.$select.find('option:selected').val(user.id);

        if (user.userName) {
          this.triggerMethod('select:user', user);
        }

        // This is a hack to fix a 2nd empty line being added to input field
        this.$('.select2-search__field').css('width', 0);
      }.bind(this),
    );
  },

  _initializeOnUnSelect() {
    this.ui.$select.on(
      'select2:unselect',
      this.triggerMethod.bind(this, 'unselect:user'),
    );
  },

  getValue() {
    return this.ui.$select.val();
  },
});
