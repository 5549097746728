import React from 'react';
import { v4 as uuid } from 'uuid';
import FormField from './FormField';
import { DocumentType } from '../../../../types/ts-types';
import DocumentUploader, { ALL } from '../../../elements/DocumentUploader';

interface TemplateField {
  slug: string;
}

interface DocumentUploaderOnChangeArg {
  folderSlug: string;
  loadingDocuments: DocumentType[];
  loadedDocuments: DocumentType[];
}

export class DocumentField extends FormField {
  documentType = ALL;

  getInputComponent(templateField: TemplateField) {
    const { disabled, disabledMessage, projectId, onError } = this.props;
    const folderSlug = this.getValue() || this.createFolderSlug(templateField);
    return (
      <DocumentUploader
        disabled={disabled}
        disabledMessage={disabledMessage}
        documentType={this.documentType}
        folderSlug={folderSlug}
        onChange={(changeContext: DocumentUploaderOnChangeArg) =>
          this.handleChange(changeContext)
        }
        onError={onError}
        projectId={projectId}
      />
    );
  }

  fieldClassName() {
    return 'form-document-field';
  }

  handleChange(changeContext: DocumentUploaderOnChangeArg) {
    const { templateField, onChange } = this.props;
    const { folderSlug, loadingDocuments, loadedDocuments } = changeContext;
    const value = this.getValue();
    const newValue =
      loadedDocuments?.length || loadingDocuments?.length ? folderSlug : '';
    if (value !== newValue && !loadingDocuments?.length) {
      return onChange(templateField.slug, newValue);
    }
    return null;
  }

  createFolderSlug(templateField: TemplateField) {
    const uniqueId = uuid();
    return `form-field-documents-${templateField.slug}-${uniqueId}`;
  }
}
