var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (dischargeMaxLimitText) {
      pug_html = pug_html + "\u003Ctr\u003E\u003Ctd style=\"width: 200px\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = 'Daily Maximum: ' + dischargeMaxLimitText) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }.call(this, "dischargeMaxLimitText" in locals_for_with ?
        locals_for_with.dischargeMaxLimitText :
        typeof dischargeMaxLimitText !== 'undefined' ? dischargeMaxLimitText : undefined));
    ;;return pug_html;};
module.exports = template;