import { useWastewaterSamplingParameters } from '@monorepo/old-web/js/hooks/water/useWastewaterSamplingParameters';
import { Button, Loading } from '@monorepo/shared/components';
/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
import sectorData from 'industrial-stormwater-sector-data';
import React from 'react';
import RecordPanelListItemExpandable from '../../../views/projectDashboard/RecordPanelListItemExpandable';
import { SamplingResultsLocationList } from './SamplingResultsLocationList';

const federalSectorData = sectorData('federal');

interface SamplingResultsParametersListProps {
  openEditParametersModal: () => void;
  openResultsEditModal: () => void;
  projectId: string;
}

export const SamplingResultsParametersList = ({
  openEditParametersModal,
  openResultsEditModal,
  projectId,
}: SamplingResultsParametersListProps) => {
  const { isLoading, isError, wastewaterSamplingParameters } =
    useWastewaterSamplingParameters(projectId);

  if (isLoading) {
    return (
      <div style={{ paddingTop: '50px' }}>
        <Loading size="medium" />
      </div>
    );
  }
  if (isError) {
    return (
      <div style={{ padding: '50px' }}>
        An error happened while loading your sampling parameters. Try refreshing
        the page or contacting customer support.
      </div>
    );
  }

  if (!wastewaterSamplingParameters?.length) {
    return (
      <div className="not-configured">
        <Button onClick={openEditParametersModal} color="secondary">
          Configure Sampling Parameters
        </Button>
      </div>
    );
  }

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  function getParameterDisplayText(parameter) {
    return federalSectorData.getParameterDisplayText(parameter.parameter_slug);
  }

  const sortedWastewaterSamplingParameters = wastewaterSamplingParameters.sort(
    (param1, param2) => {
      const displayText1 = getParameterDisplayText(param1);
      const displayText2 = getParameterDisplayText(param2);

      return displayText1.localeCompare(displayText2);
    },
  );

  return sortedWastewaterSamplingParameters.map((parameter) => (
    <RecordPanelListItemExpandable
      key={parameter.id}
      summary={<div>{getParameterDisplayText(parameter)}</div>}
      details={
        <SamplingResultsLocationList
          projectId={projectId}
          parameterSlug={parameter.parameter_slug}
          openResultsEditModal={openResultsEditModal}
        />
      }
    />
  ));
};
