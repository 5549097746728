/* eslint-disable */
/**
 * NOTE: Make sure page refreshes after using this function, so that document.write
 * is restored.
 *
 * Reference: http://articles.adamwrobel.com/2010/12/23/trigger-adwords-conversion-on-javascript-event
 */
module.exports = function trackGoogleAdwordsConversion(label) {
  var conversionLabels = {
    signup: 'PuWpCPP_plwQ-r6XzgM',
  };

  google_conversion_label = conversionLabels[label];

  document.write = function (text) {
    $('#google-conversion').append(text);
  };

  return Promise.resolve(
    $.getScript('//www.googleadservices.com/pagead/conversion.js'),
  );
};
