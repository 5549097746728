import { SheetConfig } from '@flatfile/api/api';
import { Api } from '@monorepo/shared/apiClient';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getDateColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { announceError } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileApiHelpers';
import { validateDayField } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useOrganizationTagTypes } from '@monorepo/shared/hooks/useOrgTagTypes';
import { useOrganizationUsers } from '@monorepo/shared/hooks/useOrgUsers';
import { format } from 'date-fns';
import {
  CreateProjectModuleBulk,
  CreateProjectTagBulk,
  ProjectModuleName,
  StateAbbreviations,
  projectModuleLabels,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';

enum uploadFieldKeys {
  name = 'name',
  siteAddress = 'siteAddress',
  siteAdmin = 'siteAdmin',
  siteCity = 'siteCity',
  siteZip = 'siteZip',
  startDate = 'startDate',
  state = 'state',
}

interface ProjectUploadRecord {
  [uploadFieldKeys.name]: string;
  [uploadFieldKeys.siteAddress]: string;
  [uploadFieldKeys.siteAdmin]: string;
  [uploadFieldKeys.siteCity]: string;
  [uploadFieldKeys.siteZip]: string;
  [uploadFieldKeys.startDate]: string;
  [uploadFieldKeys.state]: StateAbbreviations;
  [key: string]: string; // tags and modules are dynamic
}

const projectModuleNames = Object.values(ProjectModuleName);
const stateOptions = Object.values(StateAbbreviations).map((state) => ({
  label: state,
  value: state,
}));

type ProjectsUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onError: (saveError: Api.MultiErrorResponseData) => void;
  onSuccess: () => void;
  organizationId: string;
};

export function ProjectsUploadModal(props: ProjectsUploadModalProps) {
  const { isOpen, onClose, onError, onSuccess, organizationId } = props;

  const { users, isLoading: isUsersLoading } = useOrganizationUsers({
    organizationId,
  });
  const userOptions = useMemo(() => {
    if (!users) return [];
    return users
      .map((u) => ({
        label: u.email,
        value: u.id,
      }))
      .sort((a, b) =>
        a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1,
      );
  }, [users]);

  const { tagTypes, isLoading: isTagTypesLoading } = useOrganizationTagTypes({
    organizationId,
  });

  const isLoading = isUsersLoading || isTagTypesLoading;

  const workbook = useMemo<WorkbookConfig>(() => {
    const projectFields: SheetConfig['fields'] = [
      getTextColumnSetting({
        label: 'Project Name',
        key: uploadFieldKeys.name,
        isRequired: true,
      }),
      getTextColumnSetting({
        label: 'Street Address',
        key: uploadFieldKeys.siteAddress,
        isRequired: true,
      }),
      getTextColumnSetting({
        label: 'City',
        key: uploadFieldKeys.siteCity,
        isRequired: true,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'State',
          key: uploadFieldKeys.state,
          isRequired: true,
        },
        stateOptions,
      ),
      getTextColumnSetting({
        label: 'Postal Code',
        key: uploadFieldKeys.siteZip,
      }),
      getDateColumnSetting({
        label: 'Project Start Date',
        key: uploadFieldKeys.startDate,
        isRequired: true,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Primary Site Admin Email',
          key: uploadFieldKeys.siteAdmin,
          isRequired: true,
        },
        userOptions,
      ),
    ];

    let tagTypeFields: SheetConfig['fields'] = [];
    if (tagTypes?.length) {
      tagTypeFields = tagTypes.map((tt) => {
        const textColumnSetting = getTextColumnSetting({
          label: `Tag Type: ${tt.name}`,
          key: tt.id,
          description:
            'To create more than one tag for the same tag type, separate values by "|".',
        });
        return textColumnSetting;
      });
    }

    const moduleFields: SheetConfig['fields'] = Object.values(
      ProjectModuleName,
    ).map((moduleName) => {
      const textColumnSetting = getDateColumnSetting({
        label: `Module: ${projectModuleLabels[moduleName]}`,
        key: moduleName,
        description:
          "Provide start date for the module. If left empty, a module wouldn't be enabled.",
      });
      return textColumnSetting;
    });

    const fields = [...projectFields, ...tagTypeFields, ...moduleFields];
    return {
      name: 'Upload sites',
      sheets: [
        {
          name: 'Sites',
          slug: 'upload-sites',
          fields,
        },
      ],
    };
  }, [tagTypes, userOptions]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(async (record) => {
    validateDayField(uploadFieldKeys.startDate, record, {
      canBeInTheFuture: true,
    });

    projectModuleNames.forEach((moduleName) => {
      validateDayField(moduleName, record, { canBeInTheFuture: true });
    });
  }, []);

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: ProjectUploadRecord[], jobId) => {
      const projectResults = results.map((result) => {
        const project = {
          name: result.name,
          organizationId,
          siteAddress: result.siteAddress,
          siteCity: result.siteCity,
          siteZip: result.siteZip,
          startDate: format(new Date(result.startDate), 'yyyy-MM-dd'),
          state: result.state,
        };

        const modules: CreateProjectModuleBulk[] = [];
        projectModuleNames.forEach((moduleName) => {
          const date = result[moduleName];
          if (!date) return;
          modules.push({
            name: moduleName,
            startDate: format(new Date(date), 'yyyy-MM-dd'),
          });
        });

        const tags: CreateProjectTagBulk[] = [];
        (tagTypes || []).forEach((tt) => {
          const tagValuesForThisType = result[tt.id];
          const tagValuesList =
            typeof tagValuesForThisType === 'string'
              ? tagValuesForThisType.split('|')
              : [];
          tagValuesList.forEach((tagValue) => {
            const trimmedValue = tagValue.trim();
            if (!trimmedValue) return;
            tags.push({
              name: trimmedValue,
              organizationTagtypeId: tt.id,
            });
          });
        });

        return {
          modules,
          primaryAdminUserId: result.siteAdmin,
          project,
          tags,
        };
      });

      const result = await Api.saveProjectsBulk(projectResults);

      if (!result.success) {
        onError({
          message: 'The following site details could not be saved:',
          errors: result.errors,
        });

        await announceError(
          jobId,
          'Could not save your data',
          'There were errors processing your data, close the upload modal to see more details.',
        );
        return undefined;
      }

      onSuccess();

      const noun = projectResults.length === 1 ? 'Project' : 'Projects';
      return `
        Successfully uploaded ${noun} rows!
        Go to Sites and refresh the page.
        `;
    },
    [onError, onSuccess, organizationId, tagTypes],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ organizationId }),
    [organizationId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      customErrorMessage="There were errors processing your data, close the upload modal to see more details."
      recordHookCallback={recordHookCallback}
      showTableForManualInput
      workbook={workbook}
    />
  );
}
