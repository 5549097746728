/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import {
  GenericLogType,
  CalculationOperator,
  CalendarName,
  IntervalFrequencyFactory,
  SimpleUnits,
  UNIT_TYPES_LIST,
} from 'mapistry-shared';
import { Select, TableAction, TD, TextField, TR } from '../../../elements';
import { NotesPopover, TABLE_SETTINGS } from '../shared';
import Units from '../../../../utils/units';
import { isNullOrUndefined } from '../../../../utils';

const DISABLED_DELETE_TEXT =
  'This item cannot be deleted because it is being used in other calculations';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.CALCULATED_VALUES;

const CALCULATION_TYPES = {
  ADDITION: {
    label: 'plus (+)',
    value: CalculationOperator.ADD,
  },
  SUBTRACTION: {
    label: 'minus (-)',
    value: CalculationOperator.SUBTRACT,
  },
  MULTIPLICATION: {
    label: 'times (*)',
    value: CalculationOperator.MULTIPLY,
  },
  DIVISION: {
    label: 'divided by (÷)',
    value: CalculationOperator.DIVIDE,
  },
  EXPONENT: {
    label: 'exponent',
    value: CalculationOperator.EXPONENT,
  },
};

class CalculatedValuesRow extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.calculatedValue.factors.length !==
      prevProps.calculatedValue.factors.length
    ) {
      this.props.measureRowHeight();
    }
  }

  handleEquationFactorNameChange = (opt, factorIdx) => {
    const { calculatedValue } = this.props;
    const newValue = {
      value: opt.value,
      resourceType: opt.resourceType,
      resourceId: opt.resourceId,
    };

    if (opt.frequency) {
      this.handleStageChangedValue('frequency', opt.frequency);
      this.handleStageChangedValue('customFrequency', opt.customFrequency);
      // Updating equation factor to one without frequency may change the equation frequency,
      // calculate equation frequency without this factor and if it differs from prev equation frequency – update
    } else {
      this.stageEquationFrequencyIfNeeded(factorIdx);
    }

    // Update units for newly selected equation factor if previously selected unit is not compatible
    const currentlySelectedUnit = calculatedValue.factors[factorIdx].units;
    const needUpdateUnit =
      !currentlySelectedUnit ||
      !Units.areRelated(currentlySelectedUnit, opt.units);
    const newUnit = needUpdateUnit ? opt.units : currentlySelectedUnit;
    if (needUpdateUnit) {
      this.handleStageChangedValue('units', newUnit, factorIdx);
    }

    // Remove equation units if not compatible with the rest of equation
    // For example if user selected completely different equation factor
    this.removeEquationUnitsIfNeeded(
      { ...newValue, units: newUnit },
      factorIdx,
    );

    this.handleStageChangedValue('name', newValue, factorIdx);
  };

  handleEquationFactorUnitsChange = (units, factorIdx) => {
    // For some calculations units must be exact,
    // for example, we don't support lbs / gallons = g/gallon, but grams / gallons = g/gallon is fine,
    // Update equation unit if it's not compatible with the equation any more
    this.removeEquationUnitsIfNeeded({ units }, factorIdx);
    this.handleStageChangedValue('units', units, factorIdx);
  };

  handleStageChangedValue = (editField, nextValue, factorIdx = undefined) => {
    const { stageEdit, calculatedValueIdx, groupId } = this.props;
    stageEdit(
      { editField, nextValue },
      { calculatedValueIdx, groupId, factorIdx },
    );
  };

  handleDeleteEquationFactor = (groupId, calculatedValueIdx, factorIdx) => {
    const { calculatedValue, deleteEquationFactor, stageEdit } = this.props;

    // Remove calculation operator if the last equation factor was deleted
    if (factorIdx === calculatedValue.factors.length - 1) {
      stageEdit(
        { editField: 'calculationType', nextValue: null },
        { calculatedValueIdx, groupId, factorIdx: factorIdx - 1 },
      );
    }

    // If equation has frequency – deleting one of its factors may change it,
    // calculate equation frequency without this factor and if it differs from prev equation frequency – update
    if (calculatedValue.frequency) {
      this.stageEquationFrequencyIfNeeded(factorIdx);
    }

    // If equation has units – deleting one of its factors may make that unit incompatible with the equation
    this.removeEquationUnitsIfNeeded(null, factorIdx);

    deleteEquationFactor(groupId, calculatedValueIdx, factorIdx);
  };

  handleDeleteCalculatedValue = () => {
    const { deleteCalculatedValue, calculatedValueIdx, groupId } = this.props;
    deleteCalculatedValue(calculatedValueIdx, groupId);
  };

  getPossibleComputedUnits(factors) {
    const calculationTypes = _.pluck(factors, 'calculationType').filter(
      (t) => t,
    );
    // Allow single line calculation which basically functions as unit conversion
    if (!calculationTypes.length && factors?.[0]?.resourceId) {
      const allItems = this.allItemsToSelect();
      const forItem = allItems.find(
        (i) => i.resourceId === factors[0].resourceId,
      );
      if (
        forItem.resourceType === GenericLogType.LOGGED_ITEM ||
        forItem.resourceType === GenericLogType.CALCULATED_VALUE
      ) {
        return Units.getRelatedUnits(forItem.units);
      }
    }
    if (!calculationTypes.length) {
      return [];
    }
    if (
      calculationTypes.some((t) =>
        [CalculationOperator.ADD, CalculationOperator.SUBTRACT].includes(t),
      )
    ) {
      const firstNonUnitlessUnit = this.getFirstNonUnitlessFactorUnit(factors);
      return Units.getRelatedUnits(firstNonUnitlessUnit);
    }

    if (calculationTypes.every((t) => t === CalculationOperator.MULTIPLY)) {
      return Units.getMultiplicableUnitsForEquation(factors);
    }

    if (calculationTypes.every((t) => t === CalculationOperator.DIVIDE)) {
      return Units.getDivisibleUnitsForEquation(factors);
    }

    if (calculationTypes.every((t) => t === CalculationOperator.EXPONENT)) {
      const allItems = this.allItemsToSelect();
      const factorValuesMap = factors.reduce(
        (acc, f) => ({
          ...acc,
          [f.resourceId]: Number(
            allItems.find((i) => i.resourceId === f.resourceId)?.itemValue,
          ),
        }),
        {},
      );
      return Units.getExponentialUnitsForEquation(factors, factorValuesMap);
    }
    return [];
  }

  getFactorUnitsAtIdx(factors, factorIdx) {
    if (factorIdx < 0) return [];
    if (factorIdx === 0 && factors.length < 2) {
      return Units.getAllForSuite(CalendarName.GENERIC_LOG);
    }
    // If user wants to change first equation factor but they already
    // selected another equation factor, we limit selection to what is compatible
    // with already selected factors and calculation type.
    if (factorIdx === 0 && factors[1].name) {
      const firstCalculationType = factors[0].calculationType;
      const firstFactorUnits = factors[0].units;
      if (firstCalculationType === CALCULATION_TYPES.EXPONENT.value) {
        return [UNIT_TYPES_LIST.find((u) => u.value === firstFactorUnits)];
      }
      if (firstCalculationType === CALCULATION_TYPES.DIVISION.value) {
        return Units.getRelatedUnits(firstFactorUnits);
      }
      // a * b = b * a
      if (firstCalculationType === CALCULATION_TYPES.MULTIPLICATION.value) {
        const secondFactorUnits = factors[1].units;
        return Units.getMultiplicableUnitsForUnit(secondFactorUnits);
      }
      // All other calculation types are symmetrical, meaning no matter of the order number of equation factors
      // available units for selection are the same
    }

    const prevIdx = factorIdx === 0 ? 0 : factorIdx - 1;
    const prevFactor = factors[prevIdx];
    const prevCalculationType = prevFactor.calculationType;

    // Find first non-unitless equation factor which is not the target factor,
    // this will be what our target factor's units must be related to
    let prevUnits = prevFactor.units;
    if (prevUnits === SimpleUnits.UNITLESS && prevIdx > 0) {
      prevUnits = this.getFirstNonUnitlessFactorUnit(factors, factorIdx);
    }

    switch (prevCalculationType) {
      case CALCULATION_TYPES.ADDITION.value:
      case CALCULATION_TYPES.SUBTRACTION.value:
        return Units.getAdditionSubtractionUnitsForUnit(prevUnits);
      case CALCULATION_TYPES.MULTIPLICATION.value:
        return Units.getMultiplicableUnitsForUnit(prevUnits);
      case CALCULATION_TYPES.DIVISION.value:
        return Units.getDivisibleUnitsForUnit(prevUnits);
      case CALCULATION_TYPES.EXPONENT.value:
        return Units.getExponentialUnitsForUnit(prevUnits);
      default:
        return [];
    }
  }

  getFirstNonUnitlessFactorUnit(factors, excludeFactorIdx = undefined) {
    return factors.reduce((units, factor, idx) => {
      if (idx === excludeFactorIdx) return units;
      return factor.units && factor.units !== SimpleUnits.UNITLESS
        ? factor.units
        : units;
    }, SimpleUnits.UNITLESS);
  }

  getFactorCalculationTypeAtIdx(factors, factorIdx) {
    if (factorIdx === 0 && !factors[1]?.name) {
      const BASE_CALCULATION_TYPES = [
        CALCULATION_TYPES.ADDITION,
        CALCULATION_TYPES.SUBTRACTION,
        CALCULATION_TYPES.MULTIPLICATION,
        CALCULATION_TYPES.DIVISION,
      ];
      if (Units.canBeRaisedToExponent(factors[factorIdx].units)) {
        BASE_CALCULATION_TYPES.push(CALCULATION_TYPES.EXPONENT);
      }
      return BASE_CALCULATION_TYPES;
    }

    // Don't allow user to update first calculation type if user already selected at least two
    // equation factors that fit that calculation
    if (factorIdx === 0 && !!factors[1]?.name) {
      const firstFactorCalculationType = factors[0].calculationType;
      const interChangeableTypes = [
        CALCULATION_TYPES.ADDITION,
        CALCULATION_TYPES.SUBTRACTION,
      ];
      const interChangeableTypesValues = interChangeableTypes.map(
        (t) => t.value,
      );
      if (interChangeableTypesValues.includes(firstFactorCalculationType)) {
        return interChangeableTypes;
      }
      const calculationTypeList = Object.values(CALCULATION_TYPES);
      return [
        calculationTypeList.find((t) => t.value === firstFactorCalculationType),
      ];
    }

    const prevFactor = factors[factorIdx - 1];
    const prevCalculationType = prevFactor.calculationType;

    switch (prevCalculationType) {
      case CALCULATION_TYPES.ADDITION.value:
      case CALCULATION_TYPES.SUBTRACTION.value:
        return [CALCULATION_TYPES.ADDITION, CALCULATION_TYPES.SUBTRACTION];
      default:
        return [];
    }
  }

  getEquationFrequency(factors) {
    const allAvailableItems = this.allItemsToSelect();
    return factors.reduce((frequency, factor) => {
      if (frequency) {
        return frequency;
      }
      const item = allAvailableItems.find(
        (i) => i.resourceId === factor.resourceId,
      );
      return item && item.frequency
        ? {
            frequency: item.frequency,
            customFrequency: item.customFrequency,
            intervalFrequency: IntervalFrequencyFactory.For(
              item.frequency,
              item.customFrequency,
            ),
          }
        : null;
    }, null);
  }

  stageEquationFrequencyIfNeeded(removedFactorIdx) {
    const { calculatedValue } = this.props;
    if (!calculatedValue.frequency) {
      return;
    }
    const newFactors = calculatedValue.factors.filter(
      (f, idx) => idx !== removedFactorIdx,
    );
    const newFrequency = this.getEquationFrequency(newFactors);
    const sameFrequency = newFrequency?.intervalFrequency.sameAs(
      IntervalFrequencyFactory.For(
        calculatedValue.frequency,
        calculatedValue.customFrequency,
      ),
    );
    if (sameFrequency) return;

    this.handleStageChangedValue('frequency', newFrequency?.frequency);
    this.handleStageChangedValue(
      'customFrequency',
      newFrequency?.customFrequency,
    );
  }

  removeEquationUnitsIfNeeded(newValues, factorIdx) {
    const { calculatedValue } = this.props;
    if (!calculatedValue.units) {
      return;
    }
    let newFactors = calculatedValue.factors;
    if (newValues) {
      newFactors = calculatedValue.factors.map((factor, idx) =>
        idx === factorIdx ? { ...factor, ...newValues } : factor,
      );
    } else {
      newFactors = calculatedValue.factors.filter(
        (f, idx) => idx !== factorIdx,
      );
    }
    const possibleComputedUnitValues = this.getPossibleComputedUnits(
      newFactors,
    ).map((u) => u.value);
    if (!possibleComputedUnitValues.includes(calculatedValue.units)) {
      this.handleStageChangedValue('units', null);
    }
  }

  isUnitsDisabled(factors, factorIdx) {
    if (factorIdx === 0) {
      return isNullOrUndefined(factors[0].name);
    }

    return (
      isNullOrUndefined(factors?.[factorIdx - 1]?.name) ||
      isNullOrUndefined(factors?.[factorIdx - 1]?.units) ||
      isNullOrUndefined(factors?.[factorIdx - 1]?.calculationType)
    );
  }

  isNameDisabled(factors, factorIdx) {
    if (factorIdx === 0) {
      return false;
    }

    return (
      isNullOrUndefined(factors?.[factorIdx - 1]?.name) ||
      isNullOrUndefined(factors?.[factorIdx - 1]?.units) ||
      isNullOrUndefined(factors?.[factorIdx - 1]?.calculationType)
    );
  }

  canAddAdditionalFactor(calculatedValue, factorIdx) {
    if (factorIdx !== calculatedValue.factors.length - 1) {
      return false;
    }
    const lastCalculationType =
      calculatedValue.factors?.[calculatedValue.factors.length - 2]
        ?.calculationType;

    if (
      [
        CalculationOperator.MULTIPLY,
        CalculationOperator.DIVIDE,
        CalculationOperator.EXPONENT,
      ].includes(lastCalculationType)
    ) {
      return false;
    }
    return true;
  }

  allItemsToSelect() {
    const { calculatedValues, emissionFactors, loggedItems } = this.props;

    return this.mapItemsToOptions(
      calculatedValues.concat(loggedItems).concat(emissionFactors),
    );
  }

  availableItemsToSelect(calculatedValue, factorIdx) {
    const allAvailableItems = this.allItemsToSelect();
    const isFirstRowAndShouldSelectAll =
      factorIdx === 0 && calculatedValue.factors.length < 2;

    if (isFirstRowAndShouldSelectAll) {
      return allAvailableItems;
    }

    const includesExponentialCalculation =
      calculatedValue.factors?.[0].calculationType ===
      CALCULATION_TYPES.EXPONENT.value;

    const possibleNextUnits = this.getFactorUnitsAtIdx(
      calculatedValue.factors,
      factorIdx,
    );

    const calculationFrequency = this.getEquationFrequency(
      calculatedValue.factors,
    );

    const firstFactor = calculatedValue.factors?.[0];
    const supportedExponentialValues = includesExponentialCalculation
      ? Units.getSupportedExponentValuesForUnit(firstFactor.units)
      : [];
    const availableItems = allAvailableItems.filter((item) => {
      const isSelf = item.resourceId === calculatedValue.id;
      const isAlreadyIncluded = Boolean(
        calculatedValue.factors.find((f) => f.resourceId === item.resourceId),
      );
      const isSameFrequency =
        !item.frequency ||
        !calculationFrequency ||
        calculationFrequency?.intervalFrequency.sameAs(
          IntervalFrequencyFactory.For(item.frequency, item.customFrequency),
        );
      const hasComparableUnits = possibleNextUnits.some(
        (unit) => unit.value === item.units,
      );
      const shouldIncludeItem =
        !isSelf && !isAlreadyIncluded && isSameFrequency && hasComparableUnits;
      if (includesExponentialCalculation) {
        return (
          shouldIncludeItem &&
          supportedExponentialValues.includes(Number(item.itemValue))
        );
      }
      return shouldIncludeItem;
    });
    return availableItems;
  }

  availableUnitsToSelect(factors, factorIdx) {
    const allItemsToSelect = this.allItemsToSelect();
    const forItem = allItemsToSelect.find(
      (i) => i.resourceId === factors?.[factorIdx]?.resourceId,
    );
    if (!forItem) return [];

    return Units.getRelatedUnits(forItem.units);
  }

  mapItemsToOptions(items) {
    return items.map((item) => ({
      ...item,
      label: item.name,
      value: item.id,
      resourceType: item.resourceType,
      resourceId: item.id,
    }));
  }

  render() {
    const {
      addEquationFactor,
      calculatedValue,
      calculatedValueIdx,
      groupId,
      formErrors,
      selectMenuPortalRef,
    } = this.props;

    const sharedSelectProps = {
      menuPlacement: 'auto',
      menuPortalTarget: selectMenuPortalRef.current,
      isClearable: false,
    };

    return (
      <TR>
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <TextField
            autoFocus={!calculatedValue.name}
            controlled={false}
            defaultValue={calculatedValue.name || ''}
            error={!!formErrors?.groups?.[groupId]?.[calculatedValueIdx]?.name}
            onBlur={(e) => this.handleStageChangedValue('name', e.target.value)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <div className="calculated-value__units">
            <Select
              {...sharedSelectProps}
              isDisabled={isNullOrUndefined(
                calculatedValue.factors?.[0]?.units,
              )}
              error={
                !!formErrors?.groups?.[groupId]?.[calculatedValueIdx]?.units
              }
              options={this.getPossibleComputedUnits(calculatedValue.factors)}
              onChange={(opt) =>
                this.handleStageChangedValue('units', opt.value)
              }
              value={this.getPossibleComputedUnits(
                calculatedValue.factors,
              ).find((o) => o.value === calculatedValue.units)}
            />
            <span className="calculated-value__equals">=</span>
          </div>
        </TD>
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          {calculatedValue.factors.map((factor, factorIdx) => (
            <Fragment key={`${factor.name}.${factor.units}`}>
              <div className="calculated-value-factors__container">
                <div className="calculated-values-factors__select-container">
                  <Select
                    {...sharedSelectProps}
                    error={
                      !!formErrors?.groups?.[groupId]?.[calculatedValueIdx]
                        ?.factors?.[factorIdx]?.name
                    }
                    isDisabled={this.isNameDisabled(
                      calculatedValue.factors,
                      factorIdx,
                    )}
                    options={this.availableItemsToSelect(
                      calculatedValue,
                      factorIdx,
                    )}
                    onChange={(opt) =>
                      this.handleEquationFactorNameChange(opt, factorIdx)
                    }
                    placeholder="Name"
                    value={this.allItemsToSelect().find(
                      (o) => o.value === factor.resourceId,
                    )}
                  />
                  <Select
                    {...sharedSelectProps}
                    isDisabled={this.isUnitsDisabled(
                      calculatedValue.factors,
                      factorIdx,
                    )}
                    error={
                      !!formErrors?.groups?.[groupId]?.[calculatedValueIdx]
                        ?.factors?.[factorIdx]?.units
                    }
                    options={this.availableUnitsToSelect(
                      calculatedValue.factors,
                      factorIdx,
                    )}
                    onChange={(opt) =>
                      this.handleEquationFactorUnitsChange(opt.value, factorIdx)
                    }
                    placeholder="Units"
                    value={this.availableUnitsToSelect(
                      calculatedValue.factors,
                      factorIdx,
                    ).find((o) => o.value === factor.units)}
                  />
                  {factorIdx !== calculatedValue.factors.length - 1 && (
                    <Select
                      {...sharedSelectProps}
                      error={
                        !!formErrors?.groups?.[groupId]?.[calculatedValueIdx]
                          ?.factors?.[factorIdx]?.calculationType
                      }
                      isDisabled={isNullOrUndefined(
                        calculatedValue.factors?.[factorIdx]?.units,
                      )}
                      options={this.getFactorCalculationTypeAtIdx(
                        calculatedValue.factors,
                        factorIdx,
                      )}
                      onChange={(opt) =>
                        this.handleStageChangedValue(
                          'calculationType',
                          opt.value,
                          factorIdx,
                        )
                      }
                      placeholder="Calculation type"
                      value={this.getFactorCalculationTypeAtIdx(
                        calculatedValue.factors,
                        factorIdx,
                      ).find((o) => o.value === factor.calculationType)}
                    />
                  )}
                </div>
                {factorIdx !== 0 && (
                  <TableAction
                    actionType="delete"
                    deleteTooltipText="Delete item"
                    onClick={() =>
                      this.handleDeleteEquationFactor(
                        groupId,
                        calculatedValueIdx,
                        factorIdx,
                      )
                    }
                  />
                )}
              </div>
              {this.canAddAdditionalFactor(calculatedValue, factorIdx) && (
                <div className="flex pv3">
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="action__common grow"
                    onClick={() =>
                      addEquationFactor(groupId, calculatedValueIdx)
                    }
                  >
                    <SvgIcon identifier="add" />
                    <span className="pl2">Add another item</span>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </TD>
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          <NotesPopover
            notes={calculatedValue.notes}
            onClose={(nextNotes) =>
              this.handleStageChangedValue('notes', nextNotes)
            }
          />
        </TD>
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            onClick={this.handleDeleteCalculatedValue}
            disabledTooltipText={
              calculatedValue.isReferenced ? DISABLED_DELETE_TEXT : null
            }
            disabled={calculatedValue.isReferenced}
          />
        </TD>
      </TR>
    );
  }
}

CalculatedValuesRow.propTypes = {
  addEquationFactor: PropTypes.func.isRequired,
  calculatedValue: PropTypes.shape({
    renderKey: PropTypes.string,
    name: PropTypes.string,
    factors: PropTypes.array,
    units: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  calculatedValueIdx: PropTypes.number.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteCalculatedValue: PropTypes.func.isRequired,
  deleteEquationFactor: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  measureRowHeight: PropTypes.func.isRequired,
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

export default React.memo(CalculatedValuesRow);
