var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getProjectName, relatedSubmissions) {
      pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-12 text-left lead\"\u003E\u003Ca class=\"green-on-hover\" id=\"back-to-dashbaord\"\u003E&#10094; Back to site dashboard\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert alert-danger error-msg\"\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E\u003Cdiv class=\"form-inline form-inline-with-margin-sm\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel\u003ECreate From Scratch\u003C\u002Flabel\u003E\u003Cbutton class=\"btn btn-success\" id=\"create-from-scratch\" type=\"submit\" data-id=\"close\" data-loading-text=\"Loading...\"\u003ENext\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E\u003Ch1\u003Eor\u003C\u002Fh1\u003E\u003Chr\u003E\u003Cdiv class=\"form-inline form-inline-with-margin-sm\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel\u003EStart With Copy\u003C\u002Flabel\u003E\u003Cselect class=\"form-control\"\u003E";
// iterate relatedSubmissions
;(function(){
  var $$obj = relatedSubmissions;
  if ('number' == typeof $$obj.length) {
      for (var projectId = 0, $$l = $$obj.length; projectId < $$l; projectId++) {
        var submissionsForProject = $$obj[projectId];
pug_html = pug_html + "\u003Coptgroup" + (pug.attr("label", getProjectName(projectId), true, true)) + "\u003E";
// iterate submissionsForProject
;(function(){
  var $$obj = submissionsForProject;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var submission = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", submission.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = submission.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var submission = $$obj[pug_index1];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", submission.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = submission.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Foptgroup\u003E";
      }
  } else {
    var $$l = 0;
    for (var projectId in $$obj) {
      $$l++;
      var submissionsForProject = $$obj[projectId];
pug_html = pug_html + "\u003Coptgroup" + (pug.attr("label", getProjectName(projectId), true, true)) + "\u003E";
// iterate submissionsForProject
;(function(){
  var $$obj = submissionsForProject;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var submission = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", submission.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = submission.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var submission = $$obj[pug_index2];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", submission.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = submission.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Foptgroup\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-success\" id=\"copy-form\" type=\"submit\" data-id=\"close\" data-loading-text=\"Loading...\"\u003ENext\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E";
    }.call(this, "getProjectName" in locals_for_with ?
        locals_for_with.getProjectName :
        typeof getProjectName !== 'undefined' ? getProjectName : undefined, "relatedSubmissions" in locals_for_with ?
        locals_for_with.relatedSubmissions :
        typeof relatedSubmissions !== 'undefined' ? relatedSubmissions : undefined));
    ;;return pug_html;};
module.exports = template;