import React, { useMemo } from 'react';
import { OnboardingApi } from '../../apiClient';
import { OnboardingDocumentsCountDisplay as DocumentsCountDisplay } from '../OnboardingDocumentsCountDisplay';

interface OnboardingDocumentsTableProjectRowProps {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionsCell?: React.ReactElement<any>;
  hideDocumentsCount?: boolean;
  projectName: string;
  sections: OnboardingApi.OnboardingSection[];
}

export function OnboardingDocumentsTableProjectRow(
  props: OnboardingDocumentsTableProjectRowProps,
) {
  const { projectName, sections, hideDocumentsCount } = props;

  const { uploadedDocumentCount, acceptedDocumentCount, totalDocumentCount } =
    useMemo(
      () =>
        sections.reduce<OnboardingApi.OnboardingDocumentCounts>(
          (acc, section) => {
            acc.actionRequiredDocumentCount +=
              section.documentCounts.actionRequiredDocumentCount;
            acc.uploadedDocumentCount +=
              section.documentCounts.uploadedDocumentCount;
            acc.acceptedDocumentCount +=
              section.documentCounts.acceptedDocumentCount;
            acc.totalDocumentCount += section.documentCounts.totalDocumentCount;
            return acc;
          },
          {
            actionRequiredDocumentCount: 0,
            uploadedDocumentCount: 0,
            acceptedDocumentCount: 0,
            totalDocumentCount: 0,
          },
        ),
      [sections],
    );

  const actionsCell =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    props.actionsCell !== undefined ? props.actionsCell : <td />;

  return (
    <tr className="project-row">
      <td>{projectName}</td>
      {hideDocumentsCount ? (
        <td />
      ) : (
        <td>
          <DocumentsCountDisplay
            label="Uploaded"
            currentCount={uploadedDocumentCount + acceptedDocumentCount}
            totalCount={totalDocumentCount}
          />
        </td>
      )}
      {hideDocumentsCount ? (
        <td />
      ) : (
        <td>
          <DocumentsCountDisplay
            label="Accepted"
            currentCount={acceptedDocumentCount}
            totalCount={totalDocumentCount}
          />
        </td>
      )}
      <td />
      {actionsCell}
    </tr>
  );
}
