import React from 'react';
import FormField from './FormField';
import TextField from '../../../elements/TextField';

class NumberField extends FormField {
  getInputComponent(templateField, error) {
    return (
      <TextField
        required={templateField.required}
        value={this.getValue()}
        type="number"
        onChange={this.handleChange}
        error={error}
      />
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default NumberField;
