import APP from '@monorepo/old-web/js/config';

const impersonateUserListener = () => {
  const origOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function handleOpen(...args) {
    this.addEventListener('load', () => {
      const impersonatingUserEmail = this.getResponseHeader(
        'IMPERSONATING_USER_EMAIL',
      );
      if (impersonatingUserEmail) {
        APP.vent.trigger('impersonating-user', impersonatingUserEmail);
      }
    });
    origOpen.apply(this, args);
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default impersonateUserListener;
