/* eslint-disable */
// Views
var ProjectController = require('./ProjectController');
var BaseRouter = require('../BaseRouter');

var ProjectRouter = BaseRouter.extend({
  controller: new ProjectController(),

  appRoutes: {
    'projects/:projectId': 'redirectToShowProject',
    'organizations/:organizationId/projects/:projectId': 'showProject',
  },
});

module.exports = ProjectRouter;
