/* eslint-disable */
/* eslint-disable @typescript-eslint/no-var-requires */
import { permissionRoleLabels, ProjectPermissionRoles } from 'mapistry-shared';

const currentUser = require('../../../../../../currentUser');
const confirmView = require('../../../../../../modals/confirmView').singleton();
const projectUserDataProvider = require('../projectUserDataProvider');

const ProjectUserRowView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',

  // eslint-disable-next-line global-require
  template: require('./templates/projectUserRow.pug'),

  templateHelpers() {
    const model = this.model;
    // underscore is in the global space
    // eslint-disable-next-line no-undef
    const iterableProjectRoles = _.flatten([
      model.get('projectRoles').split(','),
    ]);

    return {
      hasUserUpdatePermission: currentUser.get('hasUserUpdatePermission'),
      availableRoles: Object.values(ProjectPermissionRoles),
      roleLabels: permissionRoleLabels,
      loggedInUserId: currentUser.id,
      reasonPrimaryAdminSelectIsHidden:
        'This user is a primary site administrator. To change this role you must select another user to be the primary site administrator first.',
      reasonSelectIsHidden:
        'This user is an organization administrator.  You cannot change the project role of an organization administrator.',

      // should only ever be 1 role, but just in case
      selectedRole: iterableProjectRoles[0],
      primaryAdminRole: ProjectPermissionRoles.PROJECT_PRIMARY_ADMIN,
      isFirst: model.get('userId') === model.collection.models[0].get('userId'),
    };
  },

  ui: {
    $savingMessage: '.saving',
    $savedMessage: '.saved',
    $errorMessage: '.error-saving',
    $delete: '.delete',
    $userEmail: '.user-email',
    $userSettingsLink: '.user-settings-link',
  },

  events: {
    'click @ui.$delete': '_confirmRemoveUserRoleFromProject',
    'change select': '_updateUserRole',
    'click @ui.$userSettingsLink': '_goToUserSettings',
  },

  onShow() {
    this._enablePopovers();
  },

  onRender() {
    this.$('[data-toggle=tooltip]').tooltip();
  },

  _confirmRemoveUserRoleFromProject() {
    const options = {
      text: `${this.model.get('userName')} as a ${permissionRoleLabels[
        this.model.get('projectRoles')
      ]?.toLowerCase()}?`,
      confirmClicked: this._removeUserRoleFromProject.bind(this),
    };

    confirmView.showModal(options);
  },

  _updateUserRole(e) {
    const userId = this.model.get('userId');
    const projectId = this.model.collection.projectId;
    // jquery is in the global space
    // eslint-disable-next-line no-undef
    const newRole = $(e.target).val();

    this._showInProcess('Saving...');
    projectUserDataProvider
      .updateUserProjectRole(userId, projectId, newRole)
      .then(() => this.model.set('projectRoles', newRole))
      .then(() => {
        this._showDone('Saved!');
      })
      .catch(() => {
        this._showError('Error saving, please try again.');
      });
  },

  _removeUserRoleFromProject() {
    const userId = this.model.get('userId');
    const projectId = this.model.collection.projectId;
    const projectRoles = this.model.get('projectRoles');

    this._showInProcess('Deleting...')
      .then(
        projectUserDataProvider.removeProjectUser.bind(
          projectUserDataProvider,
          userId,
          projectId,
          projectRoles,
        ),
      )
      .then(this.remove.bind(this))
      .catch((err) => {
        const responseJson = err?.responseJSON;
        if (responseJson?.message && responseJson?.errors) {
          this._showError(
            `${responseJson.message}: ${responseJson.errors.join('; ')}.`,
          );
        } else {
          this._showError(
            `Couldn't remove a user from the site. Please try again later or contact Mapistry customer service.`,
          );
        }
      });
  },

  _showInProcess(message) {
    this._clearMessages();
    return Promise.resolve(this.ui.$savingMessage.html(message).slideDown());
  },

  _showDone(message) {
    this._clearMessages();
    this.ui.$savedMessage
      .html(message)
      .slideDown()
      .slideDown()
      .delay(3000)
      .slideUp();
  },

  _showError(message) {
    this._clearMessages();
    this.ui.$errorMessage.html(message).slideDown();
  },

  _clearMessages() {
    this.ui.$savedMessage.hide();
    this.ui.$savingMessage.hide();
    this.ui.$errorMessage.hide();
  },

  _enablePopovers() {
    this.ui.$userEmail.popover({
      content: this._getUserContactInfoHTML(),
      html: true,
    });
  },

  _getUserContactInfoHTML() {
    let content = `<strong>Email:</strong></br>&nbsp&nbsp&nbsp&nbsp${this.model.get(
      'userEmail',
    )}</br>`;
    if (this.model.has('jobTitle') && this.model.get('jobTitle')) {
      content += `<strong>Job Title:</strong></br>&nbsp&nbsp&nbsp&nbsp${this.model.get(
        'jobTitle',
      )}</br>`;
    }
    if (
      this.model.has('officePhoneNumber') &&
      this.model.get('officePhoneNumber')
    ) {
      content += `<strong>Office Phone Number:</strong></br>&nbsp&nbsp&nbsp&nbsp${this.model.get(
        'officePhoneNumber',
      )}</br>`;
    }
    if (
      this.model.has('mobilePhoneNumber') &&
      this.model.get('mobilePhoneNumber')
    ) {
      content += `<strong>Mobile Phone Number:</strong></br>&nbsp&nbsp&nbsp&nbsp${this.model.get(
        'mobilePhoneNumber',
      )}</br>`;
    }
    return content;
  },

  _goToUserSettings() {
    window.top.location = `/dashboard/user/${this.model.get(
      'userId',
    )}/settings`;
  },
});

module.exports = ProjectUserRowView;
