import React, { useCallback } from 'react';
import {
  FieldWrapper,
  ToggleSwitch,
} from '@monorepo/old-web/js/components/elements';
import { AdminOnboardingApi } from '../../apiClient';
import { OnboardingDocumentsTableModuleRow as CustomerModuleRow } from '../../../components/OnboardingDocumentsTable';

type CustomerModuleRow = React.ComponentProps<typeof CustomerModuleRow>;

interface OnboardingDocumentsTableAdminModuleRowProps
  extends CustomerModuleRow {
  onToggleSection: (
    sectionInfo: AdminOnboardingApi.OnboardingSectionToggleInfo,
  ) => void;
}

export function OnboardingDocumentsTableAdminModuleRow(
  props: OnboardingDocumentsTableAdminModuleRowProps,
) {
  const { onToggleSection, ...moduleRowProps } = props;
  const { section } = moduleRowProps;

  const handleToggleEnabled = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      onToggleSection({
        projectId: section.projectId,
        moduleName: section.moduleName,
        enabled: checked,
      });
    },
    [onToggleSection, section],
  );

  return (
    <CustomerModuleRow
      {...moduleRowProps}
      actionsCell={
        <td>
          <FieldWrapper label="Enabled">
            <ToggleSwitch
              isChecked={section.enabled}
              onChange={handleToggleEnabled}
              highContrast
            />
          </FieldWrapper>
        </td>
      }
      hideDocumentsCount={!section.enabled}
    />
  );
}
