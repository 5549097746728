var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (figure_number, font_size, imgSrc, mapName, projectName, siteAddress) {
      pug_html = pug_html + "\u003Cdiv class=\"title-bar-control\"\u003E\u003Cdiv class=\"tr\"\u003E\u003Cdiv class=\"td\" id=\"titlebar-logo\"\u003E\u003Cspan class=\"glyphicon glyphicon-upload upload cursor-pointer\" rel=\"tipsy\" title=\"Upload a new logo\"\u003E\u003C\u002Fspan\u003E\u003Cdiv class=\"logo-container\"\u003E\u003Cimg" + (" id=\"logo-img\""+pug.attr("src", imgSrc, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"middle-column td\""+pug.attr("style", pug.style(`font-size: ${font_size}px`), true, true)) + "\u003E\u003Cp id=\"view-name\"\u003E";
if (figure_number) {
pug_html = pug_html + "\u003Cb\u003E" + (pug.escape(null == (pug_interp = mapName) ? "" : pug_interp)) + " - Figure " + (pug.escape(null == (pug_interp = figure_number) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
else {
pug_html = pug_html + "\u003Cb\u003E" + (pug.escape(null == (pug_interp = mapName) ? "" : pug_interp)) + "\u003C\u002Fb\u003E";
}
pug_html = pug_html + "\u003C\u002Fp\u003E\u003Cp class=\"text-left\" id=\"titlebar-location\"\u003E\u003Cb\u003ELocation: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = projectName) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Cp class=\"text-left\"\u003E\u003Cb\u003EAddress: &nbsp;\u003C\u002Fb\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = siteAddress) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Ca class=\"edit-titlebar\" href=\"#\" rel=\"tipsy\" title=\"Edit titlebar\"\u003E\u003Cspan class=\"glyphicon glyphicon-pencil\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "figure_number" in locals_for_with ?
        locals_for_with.figure_number :
        typeof figure_number !== 'undefined' ? figure_number : undefined, "font_size" in locals_for_with ?
        locals_for_with.font_size :
        typeof font_size !== 'undefined' ? font_size : undefined, "imgSrc" in locals_for_with ?
        locals_for_with.imgSrc :
        typeof imgSrc !== 'undefined' ? imgSrc : undefined, "mapName" in locals_for_with ?
        locals_for_with.mapName :
        typeof mapName !== 'undefined' ? mapName : undefined, "projectName" in locals_for_with ?
        locals_for_with.projectName :
        typeof projectName !== 'undefined' ? projectName : undefined, "siteAddress" in locals_for_with ?
        locals_for_with.siteAddress :
        typeof siteAddress !== 'undefined' ? siteAddress : undefined));
    ;;return pug_html;};
module.exports = template;