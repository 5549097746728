import React from 'react';
import PropTypes from 'prop-types';
import { CalendarName } from 'mapistry-shared';
import sectorData from 'industrial-stormwater-sector-data';
import { Button } from '@monorepo/shared/components';
import RecordPanelListItemExpandable from '../../../views/projectDashboard/RecordPanelListItemExpandable';
import RecordPanelCalendarAccordion from '../../../views/projectDashboard/RecordPanelCalendarAccordion';
import { CalendarFrequencyType } from '../../../propTypes';

const federalSectorData = sectorData('federal');

const SamplingResultRecordList = ({
  editParametersModal,
  monitoringLocations,
  onEditAttachment,
  onDeleteAttachment,
  onFetchAttachment,
  samplingResultFrequencies,
  projectId,
  wastewaterParameters,
}) => {
  if (!wastewaterParameters.length) {
    return (
      <div className="not-configured">
        <Button onClick={editParametersModal} color="secondary">
          Configure Sampling Parameters
        </Button>
      </div>
    );
  }

  return (
    <div className="accordion-container">
      {wastewaterParameters.map((param) => (
        <RecordPanelListItemExpandable
          key={param.id}
          summary={
            <div>
              {federalSectorData.getParameterDisplayText(param.parameter_slug)}
            </div>
          }
          details={
            <RecordPanelCalendarAccordion
              calendarName={CalendarName.WASTEWATER_SAMPLING}
              frequencies={samplingResultFrequencies}
              onDeleteAttachment={onDeleteAttachment}
              onEditAttachment={(event, attachment) =>
                onEditAttachment(attachment, param)
              }
              onFetchAttachment={(start, end) =>
                onFetchAttachment(start, end, param)
              }
              projectId={projectId}
              resourceConversionCollection={monitoringLocations}
              parameterSlug={param.parameter_slug}
            />
          }
        />
      ))}
    </div>
  );
};

SamplingResultRecordList.propTypes = {
  editParametersModal: PropTypes.func.isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onEditAttachment: PropTypes.func.isRequired,
  onFetchAttachment: PropTypes.func.isRequired,
  monitoringLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  samplingResultFrequencies: PropTypes.arrayOf(CalendarFrequencyType)
    .isRequired,
  projectId: PropTypes.string.isRequired,
  wastewaterParameters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SamplingResultRecordList;
