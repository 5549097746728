var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (completed, errors, expandByDefault, getCollapseId, isGroupCollapsible, label, showAsLink, warnings) {
      if (isGroupCollapsible) {
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-12\"\u003E\u003Cdiv class=\"dashboard-item-status-icon-container\"\u003E";
if (errors && errors.length) {
pug_html = pug_html + "\u003Csvg class=\"errors warning-red fire\" data-trigger=\"hover\" data-html=\"true\" data-title=\"Action Required\"\u003E\u003Cuse xlink:href=\"#close\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (warnings && warnings.length) {
pug_html = pug_html + "\u003Csvg class=\"warnings warning-yellow exclamation\" data-trigger=\"hover\" data-html=\"true\" data-title=\"Notice\"\u003E\u003Cuse xlink:href=\"#exclamation\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
else
if (completed) {
pug_html = pug_html + "\u003Csvg class=\"check\"\u003E\u003Cuse xlink:href=\"#check\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca" + (pug.attr("class", pug.classes(["green-on-hover","flex",showAsLink ? 'gray-link' : ''], [false,false,true]), false, true)+" type=\"button\" data-toggle=\"collapse\""+pug.attr("href", '#' + getCollapseId, true, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["fa",expandByDefault ? 'fa-caret-down' : 'fa-caret-right'], [false,true]), false, true)+" id=\"collapse-caret\" style=\"margin-right: 8px; font-size: 20px\"") + "\u003E \u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-12\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["child-view-container",expandByDefault ? 'panel-collapse collapse out in' : 'panel-collapse collapse out'], [false,true]), false, true)+pug.attr("id", getCollapseId, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"child-view-container\"\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "completed" in locals_for_with ?
        locals_for_with.completed :
        typeof completed !== 'undefined' ? completed : undefined, "errors" in locals_for_with ?
        locals_for_with.errors :
        typeof errors !== 'undefined' ? errors : undefined, "expandByDefault" in locals_for_with ?
        locals_for_with.expandByDefault :
        typeof expandByDefault !== 'undefined' ? expandByDefault : undefined, "getCollapseId" in locals_for_with ?
        locals_for_with.getCollapseId :
        typeof getCollapseId !== 'undefined' ? getCollapseId : undefined, "isGroupCollapsible" in locals_for_with ?
        locals_for_with.isGroupCollapsible :
        typeof isGroupCollapsible !== 'undefined' ? isGroupCollapsible : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "showAsLink" in locals_for_with ?
        locals_for_with.showAsLink :
        typeof showAsLink !== 'undefined' ? showAsLink : undefined, "warnings" in locals_for_with ?
        locals_for_with.warnings :
        typeof warnings !== 'undefined' ? warnings : undefined));
    ;;return pug_html;};
module.exports = template;