/* eslint-disable */
var APP = require('../config');

var ToggleSidebarView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/toggleSidebar.pug'),

  ui: {
    $toggleSidebar: '.leaflet-button',
  },

  events: {
    'click @ui.$toggleSidebar': '_toggleSidebar',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _toggleSidebar: function () {
    APP.vent.trigger('toggleSidebarView:click:toggleSidebarButton');
    this.trigger('click:toggleSidebarButton');
  },
});

module.exports = ToggleSidebarView;
