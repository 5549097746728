/* eslint-disable */
import _ from 'underscore';
import _s from 'underscore.string';
import {
  replaceSelectTypeValues,
  hmbpTableMapper,
  groupByKey,
  groupByUnits,
  convertToBaseUnits,
  hmbpTableRemapper,
  groupByCasNumber,
  flatReducer,
  groupByLength,
  naturalCmpBy,
  groupSortedBy,
} from '../../../../../../../../utils/hmbp';
import {
  convertSetToArray,
  setArrayToSet,
} from '../../../../../../../../utils';
import { prepareOptionsForModal, extractProjectSettings } from './helper';

const CollectionView = require('./CollectionView');
const HeaderView = require('./HeaderView');
const apiCaller = require('../../../../../../../../apiCaller');
const layoutView = require('./templates/layoutView.pug');
const EmptyView = require('./EmptyView');

const attrWhiteList = [
  'chemical_name',
  'common_name',
  'cas_number',
  'physical_state',
  'units',
  'maximum_daily_amount',
  'largest_container',
  'grid_number',
  'container_type',
  'container_description',
  'hazardous_material_type',
  'curies',
  'state_waste_code',
  'days_on_site',
  'storage_pressure',
  'storage_temperature',
  'chemical_description_comment',
  'us_epa_substance',
  'DOT_hazard_classification_ID',
  'average_daily_amount',
  'annual_waste_amount',
  'cers_chemical_library_id',
  'primary_fire_code_hazard_class',
  'secondary_fire_code_hazard_class',
  'third_fire_code_hazard_class',
  'fourth_fire_code_hazard_class',
  'fifth_fire_code_hazard_class',
  'sixth_fire_code_hazard_class',
  'seventh_fire_code_hazard_class',
  'eighth_fire_code_hazard_class',
];

module.exports = Backbone.Marionette.LayoutView.extend({
  template: layoutView,
  emptyView: EmptyView,

  regions: {
    attrTableHeader: '#attr-table-header',
    attrTableList: '#attr-table-list',
    modalContainer: '.list-settings-modal-container',
  },

  events: {
    'click .download-chemical-file': '_download',
  },

  initialize(options = {}) {
    this.listSettings = options.listSettings;
    this.project = options.project;
    this.projectSettings = options.projectSettings;
  },

  onShow() {
    if (this.listSettings) {
      this.prepareSettings();
    }

    if (this.collection.length > 0) {
      const headerModel = this.filterModelAttr(this.collection.models[0]);
      this.attrTableHeader.show(
        new HeaderView({
          model: headerModel,
        }),
      );

      this.attrTableList.show(
        new CollectionView({
          collection: this.prepareData(
            this.collection,
            headerModel,
            this.projectSettings,
          ),
        }),
      );
    }
  },

  filterModelAttr(model) {
    const newModel = model.clone();
    const value = newModel
      .get('value')
      .filter((attr) => attrWhiteList.indexOf(attr.key) !== -1);
    newModel.set('value', value);
    return newModel;
  },

  _handleClickViewOrEditSettings(modalView) {
    modalView.showModal();
  },

  prepareSettings() {
    const ModalView = this.listSettings.modalSettingsView;
    const modalView = new ModalView(
      prepareOptionsForModal(this.project, this.projectSettings),
    );

    this.delegateEvents(
      _.extend(
        {
          'click .view-or-edit-settings':
            this._handleClickViewOrEditSettings.bind(this, modalView),
        },
        this.events,
      ),
    );
    this.modalContainer.show(modalView);
  },

  prepareData(data, headerModel, projectSettings) {
    const { hmbpChemicalsLocationField } =
      extractProjectSettings(projectSettings);
    const collection = data.clone();
    const preparedAttrs = collection.map(
      hmbpTableMapper(hmbpChemicalsLocationField),
    );
    const groupedByKey = groupByKey(hmbpChemicalsLocationField)(preparedAttrs);

    const regrouped = groupedByKey
      .map(groupByCasNumber)
      .reduce(flatReducer, [])
      .map(convertToBaseUnits)
      .map(groupByUnits)
      .reduce(flatReducer, [])
      .map(groupByLength)
      .reduce(flatReducer, []);

    const result = [];
    regrouped.forEach((group) => {
      if (group.length > 1) {
        const gridNumberSet = new Set();
        const maximumDailyAmounts = [];
        const containerTypeSet = new Set();
        const locationSet = new Set();
        const containerDescriptionSet = new Set();
        let largestContainer = 0;
        group.forEach((attribute) => {
          gridNumberSet.add(attribute.grid_number);
          setArrayToSet(attribute.container_type, containerTypeSet);
          maximumDailyAmounts.push(attribute.maximum_daily_amount);
          containerDescriptionSet.add(attribute.container_description);
          if (
            parseFloat(largestContainer) < parseFloat(attribute.container_size)
          ) {
            largestContainer = parseFloat(attribute.container_size);
          }
          locationSet.add(attribute.location);
        });

        const consolidatedAttr = _.extend({}, group[0]);
        consolidatedAttr.maximum_daily_amount = maximumDailyAmounts.reduce(
          (amount1, amount2) => {
            const floatAmount1 = parseFloat(amount1);
            const floatAmount2 = parseFloat(amount2);
            return (
              (Number.isNaN(floatAmount1) ? 0 : floatAmount1) +
              (Number.isNaN(floatAmount2) ? 0 : floatAmount2)
            );
          },
        );

        consolidatedAttr.container_description = convertSetToArray(
          containerDescriptionSet,
        )
          .filter((desc) => desc)
          .sort(_s.naturalCmp)
          .join(', ');
        consolidatedAttr.largest_container = largestContainer;
        consolidatedAttr.grid_number = convertSetToArray(gridNumberSet)
          .sort(_s.naturalCmp)
          .join(', ');
        consolidatedAttr.location = convertSetToArray(locationSet)
          .sort(_s.naturalCmp)
          .join(', ');
        consolidatedAttr.container_type = convertSetToArray(containerTypeSet);

        result.push(consolidatedAttr);
      } else if (group.length === 1) {
        const groupEl = group[0];
        groupEl.largest_container = group[0].container_size;

        result.push(groupEl);
      }
    });

    const sortedResult = this.sortData(result);

    return replaceSelectTypeValues(
      new Backbone.Collection(sortedResult.map(hmbpTableRemapper(headerModel))),
      headerModel,
    );
  },

  sortData(data) {
    return data
      .sort(naturalCmpBy('location'))
      .reduce(groupSortedBy('location'), [])
      .map((listGroupByLocation) =>
        listGroupByLocation.sort(
          naturalCmpBy(['chemical_name', 'common_name']),
        ),
      )
      .reduce(flatReducer, []);
  },

  _download() {
    const APP = require('../../../../../../../../config');
    const url = apiCaller.getProjectListAttrsDownloadCers(
      APP.projectId,
      this.collection.key,
    );
    window.open(url);
  },
});
