/* eslint-disable */
let apiCaller = require('../../../../../../../apiCaller');
let dashboardConfig = require('../../../../../config');
let projectCollection =
  require('../../../../../../../models_collections/projectCollection').singleton();

let SamplingResultSummaryCollection = Backbone.Collection.extend({
  url() {
    let APP = require('../../../../../../../config');
    let project = projectCollection.get(APP.projectId);
    let year = dashboardConfig.getCurrentSwpppYear(project);
    return apiCaller.getSamplingResultSummariesUrl(APP.projectId, year);
  },

  comparator: 'parameter_slug',
});

Backbone.addSingletonToCollection(SamplingResultSummaryCollection);
module.exports = SamplingResultSummaryCollection;
