import {
  ConfirmDialog,
  ExpandablePanel,
} from '@monorepo/old-web/js/components/elements';
import { KeyService } from '@monorepo/shared/KeyService';
import { SvgIcon } from '@monorepo/shared/components';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { getParameterDisplayText } from '../../../../utils/shared';
import { ParameterLimitGroupRow } from './ParameterLimitGroupRow';
import { ParameterSectionError } from './ParameterSectionError';
import { ParameterSectionHeader } from './ParameterSectionHeader';
import {
  ParameterLimitType,
  ParameterMonitoringLocation,
  ValidationError,
} from './types';

type ParameterExpandableSectionProps = {
  locations?: {
    id: string;
    name: string;
  }[];
  onAddLimitGroup: (parameterId: string, locationId: string) => void;
  onDeleteParameter: (parameterId: string) => void;
  onDeleteParameterMonitoringLocation: (
    spml: ParameterMonitoringLocation,
  ) => void;
  parameterId: string;
  parameterLimitType: ParameterLimitType;
  parameterMonitoringLocations: ParameterMonitoringLocation[];
  parameterSlug: string;
  stageSamplingParameterEdit: (
    key: string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    spml: ParameterMonitoringLocation,
  ) => void;
};

export const ParameterExpandableSection = memo(
  (props: ParameterExpandableSectionProps) => {
    const {
      locations,
      onAddLimitGroup,
      onDeleteParameter,
      onDeleteParameterMonitoringLocation,
      parameterId,
      parameterLimitType,
      parameterMonitoringLocations,
      parameterSlug,
      stageSamplingParameterEdit,
    } = props;
    const parameterDisplayText = getParameterDisplayText(parameterSlug);
    const hasLocations = !!locations?.length;

    const [deleteConfirmOpen, setDeleteConfirm] = useState(false);

    const [limitErrors, setLimitErrors] = useState<
      ValidationError[] | undefined
    >();
    const hasErrors = !!limitErrors?.length;

    const parameterLimitsByLocationId = useMemo<{
      [locationId: string]: ParameterMonitoringLocation[];
    }>(
      () =>
        parameterMonitoringLocations
          // reverse sort by sampling type to move the undefined records to the end
          .sort((a, b) => (a.sampling_type > b.sampling_type ? -1 : 1))
          .reduce((acc, spml) => {
            const isVirtualMonitoringLocation = !spml.id;
            if (isVirtualMonitoringLocation) return acc;
            const update = { ...acc };
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            const existing = update[spml.monitoring_location_id];
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            update[spml.monitoring_location_id] = existing
              ? [...existing, spml]
              : [spml];
            return update;
          }, {}),
      [parameterMonitoringLocations],
    );

    const stageUpdateForAllMatching = useCallback(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (
        locationId: string,
        fieldKey: string,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
      ) =>
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        parameterLimitsByLocationId[locationId].forEach((l) =>
          stageSamplingParameterEdit(fieldKey, value, l),
        ),
      [parameterLimitsByLocationId, stageSamplingParameterEdit],
    );

    const onChange = useCallback<
      ParameterExpandableSectionProps['stageSamplingParameterEdit']
    >(
      (key, value, spml) => {
        if (
          key === 'sampling_type' ||
          key === 'frequency' ||
          key === 'custom_frequency'
        ) {
          // one of the defaulted fields has been updated. Stage updates for all of
          // the records with the same location id and then get outta here.
          stageUpdateForAllMatching(spml.monitoring_location_id, key, value);
          return;
        }

        // stage the current change
        stageSamplingParameterEdit(key, value, spml);

        // stage custom frequency and frequency from an existing record if they don't exist
        if (!spml.custom_frequency && !spml.frequency) {
          // since the arrays are sorted with the empty ones at the end the first record will have a frequency if any do
          const spmlWithFrequency =
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            parameterLimitsByLocationId[spml.monitoring_location_id][0];
          if (spmlWithFrequency?.frequency) {
            stageSamplingParameterEdit(
              'custom_frequency',
              spmlWithFrequency.custom_frequency,
              spml,
            );
            stageSamplingParameterEdit(
              'frequency',
              spmlWithFrequency.frequency,
              spml,
            );
          }
        }

        // stage sampling type from an existing record if it doesn't exist
        if (!spml.sampling_type) {
          // since the arrays are sorted with the empty ones at the end the first record will have a sampling type if any do
          const spmlWithSamplingType =
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            parameterLimitsByLocationId[spml.monitoring_location_id][0];
          // eslint-disable-next-line camelcase
          if (spmlWithSamplingType?.sampling_type) {
            stageSamplingParameterEdit(
              'sampling_type',
              spmlWithSamplingType.sampling_type,
              spml,
            );
          }
        }
      },
      [
        parameterLimitsByLocationId,
        stageSamplingParameterEdit,
        stageUpdateForAllMatching,
      ],
    );

    return (
      <>
        <ExpandablePanel
          classes={{
            details: 'mapistry-accordion-panel-details__no-padding',
          }}
          body={
            !hasLocations ? null : (
              <>
                <ParameterSectionHeader />
                <ParameterSectionError errors={limitErrors} />

                {locations?.map((l) => (
                  <div key={l.id}>
                    <div className="section--parameter-row">
                      <div className="section--parameter-row--item">
                        {l.name}
                      </div>
                    </div>
                    {parameterLimitsByLocationId[l.id]?.map((spml, index) => (
                      <ParameterLimitGroupRow
                        hideSamplingTypeAndFrequency={index > 0}
                        key={spml.id}
                        limitErrors={limitErrors}
                        limitType={parameterLimitType}
                        locationName={l.name}
                        onDelete={onDeleteParameterMonitoringLocation}
                        onLimitError={setLimitErrors}
                        parameterSlug={parameterSlug}
                        spml={spml}
                        stageSamplingParameterEdit={onChange}
                      />
                    ))}
                    <div className="w-100 pv3 flex justify-center">
                      <div
                        role="button"
                        tabIndex={0}
                        className="grow pointer f6 green hover-dark-green flex items-center"
                        onClick={() => onAddLimitGroup(parameterId, l.id)}
                        onKeyDown={(e) => {
                          if (KeyService.triggersButtonClick(e.keyCode)) {
                            onAddLimitGroup(parameterId, l.id);
                          }
                        }}
                      >
                        <SvgIcon identifier="add" />
                        <span className="pl3">Add a limit group</span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )
          }
          title={
            <div className="flex items-center justify-between w-100">
              <div className="flex items-center pl2">
                <div className="pr3">{parameterDisplayText}</div>
                {hasErrors && (
                  <SvgIcon
                    className="red f4"
                    identifier="exclamation"
                    withBorder
                  />
                )}
              </div>
              <div
                role="button"
                tabIndex={0}
                aria-label="Delete"
                className="pointer flex items-center fw6"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteConfirm(true);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (KeyService.triggersButtonClick(e.keyCode)) {
                    setDeleteConfirm(true);
                  }
                }}
              >
                <SvgIcon
                  identifier="trash"
                  className="red hover-dark-red grow-large f5"
                />
              </div>
            </div>
          }
        />

        <ConfirmDialog
          confirmButtonText="Delete"
          danger
          description={`Are you sure you want to delete the ${parameterDisplayText} parameter?`}
          open={deleteConfirmOpen}
          onCancelled={() => setDeleteConfirm(false)}
          onConfirmed={() => {
            setDeleteConfirm(false);
            onDeleteParameter(parameterId);
          }}
          title="Confirm"
        />
      </>
    );
  },
);
