/* eslint-disable */
let projectStateConfig = require('mapistry-project-state-config');
let GenericSamplingEventParameterView = require('./GenericSamplingEventParameterView');
let CaliforniaSamplingEventParameterView = require('./CaliforniaSamplingEventParameterView');
let WashingtonSamplingEventParameterView = require('./WashingtonSamplingEventParameterView');
let OregonSamplingEventParameterView = require('./OregonSamplingEventParameterView');
let PennsylvaniaSamplingEventParameterView = require('./PennsylvaniaSamplingEventParameterView');
let AlabamaSamplingEventParameterView = require('./AlabamaSamplingEventParameterView');
let NewJerseySamplingEventParameterView = require('./NewJerseySamplingEventParameterView');

let SamplingEventParameterCollectionView =
  Backbone.Marionette.CollectionView.extend({
    getChildView: function () {
      let projectAsJSON = this.projectModel.toJSON();
      if (projectStateConfig.isCaliforniaIndustrial(projectAsJSON)) {
        return CaliforniaSamplingEventParameterView;
      } else if (projectStateConfig.isWashingtonIndustrial(projectAsJSON)) {
        return WashingtonSamplingEventParameterView;
      } else if (projectStateConfig.isOregonIndustrial(projectAsJSON)) {
        return OregonSamplingEventParameterView;
      } else if (projectStateConfig.hasConsecutiveExceedance(projectAsJSON)) {
        return PennsylvaniaSamplingEventParameterView;
      } else if (projectStateConfig.isAlabamaIndustrial(projectAsJSON)) {
        return AlabamaSamplingEventParameterView;
      } else if (projectStateConfig.isStateIndustrial(projectAsJSON, 'nj')) {
        return NewJerseySamplingEventParameterView;
      }
      return GenericSamplingEventParameterView;
    },

    initialize: function (options = {}) {
      this.projectModel = options.projectModel;
      this.samplingEventId = options.samplingEventId;
      this.swpppYear = options.swpppYear;
    },

    childViewOptions: function () {
      return {
        projectModel: this.projectModel,
        samplingEventId: this.samplingEventId,
        swpppYear: this.swpppYear,
      };
    },
  });

module.exports = SamplingEventParameterCollectionView;
