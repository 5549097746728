import React, { memo } from 'react';
import {
  CustomLogTemplateMenuCallbackType,
  useCustomLogTemplates,
} from '../../../hooks/genericLogs/useCustomLogTemplates';
import { Menu } from '../../elements';

interface GenericLogTrackingViewMenuProps {
  onAddLog: () => void;
  onDownload: () => void;
  onGoToSettings: () => void;
}

export const GenericLogTrackingViewMenu = memo(
  ({
    onAddLog,
    onDownload,
    onGoToSettings,
  }: GenericLogTrackingViewMenuProps) => {
    const { view: logTemplateView } = useCustomLogTemplates();
    const { menu } = logTemplateView;

    const callbackMap = {
      [CustomLogTemplateMenuCallbackType.addALog]: onAddLog,
      [CustomLogTemplateMenuCallbackType.downloadLogs]: onDownload,
      [CustomLogTemplateMenuCallbackType.logSettings]: onGoToSettings,
    };

    const menuOptions = menu.map((item) => ({
      ...item,
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      onClick: callbackMap[item.type],
    }));
    return (
      <Menu className="emission-tracking-log__menu" options={menuOptions} />
    );
  },
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogTrackingViewMenu;
