import React, { memo } from 'react';
import {
  CustomLogTemplateRowType,
  CustomLogTemplateSectionType,
} from '../../hooks/genericLogs/useCustomLogTemplates';
import CalculatedValues from './EmissionTrackingSettings/CalculatedValues';
import Factors from './EmissionTrackingSettings/Factors';
// This imports both the table and the row so we get a cycle
// If this seems to be a problem we can separate this into multiple "pickers" (ajb)
// eslint-disable-next-line import/no-cycle
import LimitItems from './EmissionTrackingSettings/LimitItems';
import LimitItemsRow from './EmissionTrackingSettings/LimitItems/LimitItemsRow';
import { WaterLogLimitRow } from './EmissionTrackingSettings/LimitItems/WaterLogLimitRow';
// eslint-disable-next-line import/no-cycle
import LoggedItems from './EmissionTrackingSettings/LoggedItems';
import { LoggedItemsRow } from './EmissionTrackingSettings/LoggedItems/LoggedItemsRow';
import { WaterLogRow } from './EmissionTrackingSettings/LoggedItems/WaterLogRow';
import RollingCalculations from './EmissionTrackingSettings/RollingCalculations';
import UnitConversions from './EmissionTrackingSettings/UnitConversions';
import EmissionTrackingList from './EmissionTrackingView/EmissionTrackingList';

interface ComponentPickerProps {
  type?: string;
  [propName: string]: unknown;
}

const map = {
  // rows
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateRowType.limitItem]: (props) => <LimitItemsRow {...props} />,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateRowType.loggedItem]: (props) => (
    <LoggedItemsRow {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateRowType.waterLimitItem]: (props) => (
    <WaterLogLimitRow {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateRowType.waterLoggedItem]: (props) => (
    <WaterLogRow {...props} />
  ),

  // sections
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.calculatedValues]: (props) => (
    <CalculatedValues {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.factors]: (props) => <Factors {...props} />,
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.limitItems]: (props) => (
    <LimitItems {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.loggedItems]: (props) => (
    <LoggedItems {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.rawData]: (props) => (
    <EmissionTrackingList {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.rollingCalculations]: (props) => (
    <RollingCalculations {...props} />
  ),
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  [CustomLogTemplateSectionType.unitConversions]: (props) => (
    <UnitConversions {...props} />
  ),
};

export const ComponentPicker = memo(({ type, ...rest }: ComponentPickerProps) =>
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  type && map[type] ? map[type](rest) : null,
);
