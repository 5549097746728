import React, { useCallback } from 'react';
import { useConfirmDialog } from '@monorepo/shared/hooks/useConfirmDialog';
import { usePromptDialog } from '@monorepo/shared/hooks/usePromptDialog';
import { AddButton } from '@monorepo/old-web/js/components/elements';
import { AdminOnboardingApi } from '../../apiClient';
import { OnboardingDocumentsTableDocumentRow as CustomerDocumentRow } from '../../../components/OnboardingDocumentsTable';
import { OnboardingDocumentStatusSelect } from '../../../components/OnboardingDocumentStatusSelect';

type CustomerDocumentRow = React.ComponentProps<typeof CustomerDocumentRow>;

interface OnboardingDocumentsTableAdminDocumentRowProps
  extends CustomerDocumentRow {
  onChangeDocumentRequestStatus: (
    documentRequestId: string,
    status: AdminOnboardingApi.OnboardingDocumentStatus,
    notes?: string,
  ) => void;
  onUpdateDocumentRequestNotes: (
    documentRequestId: string,
    notes: string,
  ) => void;
}

export function OnboardingDocumentsTableAdminDocumentRow(
  props: OnboardingDocumentsTableAdminDocumentRowProps,
) {
  const {
    onUpdateDocumentRequestNotes,
    onChangeDocumentRequestStatus,
    ...documentRowProps
  } = props;
  const { documentRequest } = documentRowProps;

  const prompt = usePromptDialog();
  const confirm = useConfirmDialog();

  const handleUpdateNotes = useCallback(async () => {
    const notes = await prompt({
      title: 'Notes:',
      confirmButtonText: 'Save',
      initialInputValue: documentRequest.notes,
      multiline: true,
    });
    if (!notes) return;
    onUpdateDocumentRequestNotes(documentRequest.id, notes);
  }, [onUpdateDocumentRequestNotes, documentRequest, prompt]);

  const handleChangeStatus = useCallback(
    async (status: AdminOnboardingApi.OnboardingDocumentStatus | null) => {
      // status should never be null since isClearable={false} on the select
      // but the types dont recognize that
      if (status == null) return;
      if (documentRequest.documentId == null) return;
      if (status === AdminOnboardingApi.OnboardingDocumentStatus.uploaded) {
        return;
      }
      if (
        status === AdminOnboardingApi.OnboardingDocumentStatus.actionRequired
      ) {
        const notes = await prompt({
          title: 'Reject document?',
          description:
            'Changing the status back to "action required" will notify users that there are updates they need to make. Please add a note to clarify what action is needed.',
          confirmButtonText: 'Save',
          initialInputValue: documentRequest.notes,
          multiline: true,
        });
        if (notes == null) return;
        onChangeDocumentRequestStatus(documentRequest.id, status, notes);
      }
      if (status === AdminOnboardingApi.OnboardingDocumentStatus.accepted) {
        const isConfirmed = await confirm({
          title: 'Accept document?',
          description:
            'Changing the status to "accepted" will lock the document and notify users it is complete.',
        });
        if (!isConfirmed) return;
        onChangeDocumentRequestStatus(documentRequest.id, status);
      }
    },
    [onChangeDocumentRequestStatus, documentRequest, confirm, prompt],
  );

  return (
    <CustomerDocumentRow
      {...documentRowProps}
      editable
      statusCell={
        <td>
          <OnboardingDocumentStatusSelect
            value={documentRequest.status}
            onChange={handleChangeStatus}
            isClearable={false}
          />
        </td>
      }
      notesCell={
        <td>
          <AddButton
            type="button"
            color="secondary"
            size="small"
            onClick={handleUpdateNotes}
          >
            Add notes
          </AddButton>
        </td>
      }
    />
  );
}
