import _ from 'underscore';
import { HMBP_CHEMICALS_LOCATION_FIELD } from './constants';

function extractProjectSettings(projectSettings) {
  const hmbpChemicalsLocationField = projectSettings.find(
    (item) => item.get('setting_key') === HMBP_CHEMICALS_LOCATION_FIELD,
  );
  return {
    hmbpChemicalsLocationField: hmbpChemicalsLocationField
      ? hmbpChemicalsLocationField.get('setting_value')
      : 'feature_name',
  };
}

function prepareOptionsForModal(project, projectSettings) {
  return _.extend({}, extractProjectSettings(projectSettings), {
    projectId: project.id,
  });
}

export { prepareOptionsForModal, extractProjectSettings };
