import { IMAGE } from '../../../elements/DocumentUploader';
import UTIL from '../../../../utility';
import { DocumentField } from './DocumentField';

export class PhotoField extends DocumentField {
  documentType = IMAGE;

  fieldClassName() {
    return 'form-photo-field';
  }

  createFolderSlug(templateField) {
    const uniqueId = UTIL.IDENTIFIER.generateUUIDv4();
    return `form-field-photos-${templateField.slug}-${uniqueId}`;
  }
}
