/* eslint-disable react/sort-comp */
import { ToastContext } from '@monorepo/shared/contexts';
import { SaveState } from '@monorepo/shared/types/SaveState';
import {
  getDuplicableGroupName,
  isConditionallyShownFieldVisible,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React from 'react';
import APP from '../../../config';
import {
  FormSubmissionService,
  REQUEST_PDF_SUCCEEDED_MESSAGE,
} from '../../../services/FormSubmissionService';
import FormTemplateService from '../../../services/FormTemplateService';
import ProjectService from '../../../services/ProjectService';
import UserService from '../../../services/UserService';
import UTIL from '../../../utility';
import FormSubmissionEditor from './FormSubmissionEditor';
import ValidationFactory from './validations/ValidationFactory';

class FormSubmissionContainer extends React.Component {
  SHOW_SAVED_TIME = 2000;

  constructor(props) {
    super(props);
    this.state = {
      formTemplate: { groups: [], name: null, slug: null },
      formSubmission: {
        groups: [],
        eventDate: undefined,
        eventNumber: undefined,
        calendarName: undefined,
      },
      currentUser: null,
      project: null,
      loadError: false,
      lockInfo: null,
      requestPageReloadBeforeYieldingFormLock: false,

      currentSubmissionGroupNumber: 0,
      currentTemplateGroupId: null,
      errorCount: 0,
      errorMessage: null,
      saveState: SaveState.CLEAN,
      downloading: false,
      hasAlreadyRequestedDownload: false,
    };

    this.saveTimeoutHandler = null;

    this.formTemplateService = new FormTemplateService();
    this.formSubmissionService = new FormSubmissionService();
    this.userService = new UserService();
    this.projectService = new ProjectService();

    this.handleClickSection = this.handleClickSection.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFieldError = this.handleFieldError.bind(this);
    this.handleCreateSubsection = this.handleCreateSubsection.bind(this);
    this.handleDeleteSubsection = this.handleDeleteSubsection.bind(this);
    this.handleUpdateSignature = this.handleUpdateSignature.bind(this);
    this.handleCloseErrorMessage = this.handleCloseErrorMessage.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleSaveForm = this.handleSaveForm.bind(this);
    this.handleDeleteSubmission = this.handleDeleteSubmission.bind(this);
    this.handleDownloadForm = this.handleDownloadForm.bind(this);
    this.handleGoToNextSection = this.handleGoToNextSection.bind(this);
    this.releaseLock = this.releaseLock.bind(this);
  }

  componentDidMount() {
    const preLoads = [
      this.loadFormTemplate(),
      this.loadFormSubmission(),
      this.loadLock(),
      this.loadCurrentUser(),
      this.loadProject(),
    ];
    Promise.all(preLoads)
      .then(
        ([formTemplate, formSubmission, lockInfo, currentUser, project]) => {
          const initializedSubmission = this.initializeSubmission(
            formTemplate,
            formSubmission,
          );
          const currentTemplateGroup = this.getInitialTemplateGroup(
            initializedSubmission,
            formTemplate,
          );
          const currentSubmissionGroupNumber =
            this.getInitialSubmissionGroupNumber(
              initializedSubmission,
              currentTemplateGroup,
            );
          initializedSubmission.groups = this.initializeSubmissionGroups(
            initializedSubmission,
            formTemplate,
            currentTemplateGroup,
            currentSubmissionGroupNumber,
          );

          this.setState({
            formTemplate,
            formSubmission: initializedSubmission,
            currentTemplateGroupId: currentTemplateGroup.id,
            currentSubmissionGroupNumber,
            currentUser,
            lockInfo,
            project,
          });
        },
      )
      .catch(() => this.setState({ loadError: true }));

    this.listenForTabExitToReleaseFormLock();
  }

  componentDidUpdate() {
    const { saveState } = this.state;
    APP.isNavigationBlocked = saveState === SaveState.DIRTY;
  }

  componentWillUnmount() {
    clearTimeout(this.saveTimeoutHandler);
    this.removeListenerForExitToReleaseFormLock();
    this.releaseLock();
  }

  listenForTabExitToReleaseFormLock() {
    window.addEventListener('pagehide', this.releaseLock);
  }

  removeListenerForExitToReleaseFormLock() {
    window.removeEventListener('pagehide', this.releaseLock);
  }

  getTemplateGroup(submissionGroup, formTemplate) {
    return formTemplate.groups.find(
      (tg) => tg.id === submissionGroup.formTemplateFieldGroupId,
    );
  }

  getSubmissionGroups(submissionGroups, templateGroupId) {
    return submissionGroups.filter(
      (sg) => sg.formTemplateFieldGroupId === templateGroupId,
    );
  }

  getInitialTemplateGroup(submission, formTemplate) {
    const { groupId } = this.props;

    if (!groupId) {
      return formTemplate.groups[0];
    }
    const submissionGroup = submission.groups.find((sg) => sg.id === groupId);
    if (!submissionGroup) {
      return formTemplate.groups[0];
    }
    return this.getTemplateGroup(submissionGroup, formTemplate);
  }

  getCurrentTemplateGroup(currentSubmissionGroup) {
    const { formTemplate, currentTemplateGroupId } = this.state;

    const currentTemplateGroup = formTemplate.groups.find(
      (tg) => tg.id === currentTemplateGroupId,
    );
    if (!currentSubmissionGroup) {
      return currentTemplateGroup;
    }
    const shownFields = this.filterHiddenFields(
      currentTemplateGroup,
      currentSubmissionGroup,
    );
    return { ...currentTemplateGroup, fields: shownFields };
  }

  getInitialSubmissionGroupNumber(submission, currentTemplateGroup) {
    const { groupId } = this.props;

    if (!groupId) {
      return 0;
    }

    const currentGroupCandidates = this.getSubmissionGroups(
      submission.groups,
      currentTemplateGroup.id,
    );
    return currentGroupCandidates.findIndex((sg) => sg.id === groupId);
  }

  getCurrentSubmissionGroup(formSubmission) {
    const { currentSubmissionGroupNumber, currentTemplateGroupId } = this.state;
    if (!currentTemplateGroupId) {
      return null;
    }
    const submissionGroups = this.getSubmissionGroups(
      formSubmission.groups,
      currentTemplateGroupId,
    );
    return submissionGroups[currentSubmissionGroupNumber];
  }

  getSubmissionField(submissionGroup, slug) {
    return submissionGroup.fields.find((sf) => sf.fieldSlug === slug);
  }

  getErrorCount(submissionGroups) {
    return submissionGroups.reduce((totalCount, group) => {
      const fieldCount = group.fields.reduce(
        (count, field) =>
          field.errors && field.errors.length ? count + 1 : count,
        0,
      );
      return totalCount + fieldCount;
    }, 0);
  }

  getTemplateErrorCount(templateGroups) {
    return templateGroups.reduce((count, g) => (count + g.hasError ? 1 : 0), 0);
  }

  setFormDirty() {
    clearTimeout(this.saveTimeoutHandler);
    this.setState({ saveState: SaveState.DIRTY });
  }

  async handleClickSection(
    selectedTemplateGroupId,
    selectedSubmissionGroupNumber,
  ) {
    this.extendLock();

    const { formSubmission, formTemplate } = this.state;
    let updatedTemplateGroups = formTemplate.groups;

    const previousSubmissionGroup =
      this.getCurrentSubmissionGroup(formSubmission);
    const nextGroupSet = this.getSubmissionGroups(
      formSubmission.groups,
      selectedTemplateGroupId,
    );
    const nextSubmissionGroup = nextGroupSet[selectedSubmissionGroupNumber];
    const selectedTemplateGroup = formTemplate.groups.find(
      (tg) => tg.id === selectedTemplateGroupId,
    );
    const updatedGroups = await this.transitionGroups(
      previousSubmissionGroup,
      nextSubmissionGroup,
    );
    if (!nextSubmissionGroup && !selectedTemplateGroup.isDuplicable) {
      updatedTemplateGroups = this.clearTemplateGroupError(
        selectedTemplateGroupId,
      );
      updatedGroups.push(this.createSubmissionGroup(selectedTemplateGroup));
      this.setFormDirty();
    }

    this.setState({
      currentTemplateGroupId: selectedTemplateGroupId,
      currentSubmissionGroupNumber: selectedSubmissionGroupNumber,
      formTemplate: { ...formTemplate, groups: updatedTemplateGroups },
      formSubmission: { ...formSubmission, groups: updatedGroups },
    });
  }

  async handleGoToNextSection() {
    const {
      currentTemplateGroupId,
      currentSubmissionGroupNumber,
      formSubmission,
      formTemplate,
    } = this.state;

    const currentGroupSet = this.getSubmissionGroups(
      formSubmission.groups,
      currentTemplateGroupId,
    );

    if (currentSubmissionGroupNumber < currentGroupSet.length - 1) {
      this.handleClickSection(
        currentTemplateGroupId,
        currentSubmissionGroupNumber + 1,
      );
      return;
    }

    const currentTemplateIndex = formTemplate.groups.findIndex(
      (tg) => tg.id === currentTemplateGroupId,
    );
    const nextTemplateIndex =
      currentTemplateIndex +
      (currentTemplateIndex < formTemplate.groups.length - 1 ? 1 : 0);
    const nextTemplate = formTemplate.groups[nextTemplateIndex];

    this.handleClickSection(nextTemplate.id, 0);
  }

  handleCreateSubsection(parentId) {
    const { formTemplate, formSubmission } = this.state;

    const templateGroup = formTemplate.groups.find((tg) => tg.id === parentId);
    const siblingGroups = this.getSubmissionGroups(
      formSubmission.groups,
      templateGroup.id,
    );
    const lastGroupOrderNumber = siblingGroups.reduce(
      (lastOrderNumber, g) =>
        g.orderNumber > lastOrderNumber ? g.orderNumber : lastOrderNumber,
      0,
    );
    const newGroup = this.createSubmissionGroup(templateGroup);
    newGroup.orderNumber = lastGroupOrderNumber + 1;
    newGroup.name = `${templateGroup.name} ${newGroup.orderNumber}`;
    const updatedSubmissionGroups = [...formSubmission.groups, newGroup];
    this.setFormDirty();

    this.setState({
      formSubmission: { ...formSubmission, groups: updatedSubmissionGroups },
      currentTemplateGroupId: templateGroup.id,
      currentSubmissionGroupNumber: siblingGroups.length,
    });
  }

  handleDeleteSubsection(groupToDelete, deletedGroupNumber) {
    const { formTemplate, formSubmission } = this.state;

    const templateGroup = this.getTemplateGroup(groupToDelete, formTemplate);
    let updatedGroups = formSubmission.groups.filter(
      (sg) => sg.key !== groupToDelete.key,
    );
    updatedGroups = this.renameGroups(updatedGroups, templateGroup);
    const updatedSubmission = { ...formSubmission, groups: updatedGroups };

    this.setFormDirty();
    this.setState({
      formSubmission: updatedSubmission,
      currentSubmissionGroupNumber: this.nextGroupNumber(
        templateGroup.id,
        deletedGroupNumber,
      ),
    });
  }

  handleFieldChange(slug, fieldValue, errors = []) {
    const { formSubmission, formTemplate } = this.state;
    clearTimeout(this.saveTimeoutHandler);

    const currentSubmissionGroup =
      this.getCurrentSubmissionGroup(formSubmission);
    const updatedGroups = formSubmission.groups.map((submissionGroup) => {
      let groupName = submissionGroup.name;
      if (submissionGroup === currentSubmissionGroup) {
        const submissionField = this.getSubmissionField(submissionGroup, slug);
        const templateGroup = this.getTemplateGroup(
          submissionGroup,
          formTemplate,
        );

        submissionField.fieldValue = fieldValue;
        submissionField.errors = errors;

        if (templateGroup.isDuplicable) {
          groupName = this.groupName(
            submissionGroup,
            templateGroup,
            formSubmission.groups,
          );
        }
      }
      return { ...submissionGroup, name: groupName };
    });

    this.setFormDirty();
    this.setState({
      formSubmission: { ...formSubmission, groups: updatedGroups },
    });
  }

  handleFieldError(errorMessage) {
    this.setState({ errorMessage });
  }

  async handleSaveForm() {
    const { formTemplate } = this.state;
    const { isStencil } = this.props;

    this.extendLock();

    this.setState({
      saveState: SaveState.SAVING,
      errorCount: 0,
      errorMessage: null,
    });
    const updatedSubmission = await this.saveSubmission();

    if (updatedSubmission) {
      let errorCount = 0;
      let updatedTemplateGroups = formTemplate.groups;
      let updatedGroups = [...updatedSubmission.groups];

      if (!isStencil) {
        updatedTemplateGroups = this.validateRequiredGroups(updatedGroups);
        updatedGroups = this.validateForm(updatedGroups);
        errorCount =
          this.getErrorCount(updatedGroups) +
          this.getTemplateErrorCount(updatedTemplateGroups);
      }
      updatedSubmission.groups = this.formatSubmissionGroups(
        updatedGroups,
        formTemplate,
      );

      this.setState({
        errorCount,
        formTemplate: { ...formTemplate, groups: updatedTemplateGroups },
        formSubmission: updatedSubmission,
        hasAlreadyRequestedDownload: false,
        saveState: SaveState.SAVED,
      });
      this.saveTimeoutHandler = setTimeout(
        () => this.setState({ saveState: SaveState.CLEAN }),
        this.SHOW_SAVED_TIME,
      );
    }
  }

  async handleDeleteSubmission() {
    const {
      project,
      formSubmission: { id: formSubmissionId },
    } = this.state;

    try {
      await this.formSubmissionService.delete(project.id, formSubmissionId);
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      const router = require('../../../router');
      router.goToInpsectionsDashboard(project.id, project.organizationId);
    } catch {
      this.setState({
        errorMessage: 'There was a problem deleting the submission.',
      });
    }
  }

  async handleDownloadForm() {
    const { formSubmission } = this.state;
    const { success } = this.context;

    this.setState({ downloading: true });
    try {
      await this.formSubmissionService.requestPdf(formSubmission.id);
      this.setState({ downloading: false, hasAlreadyRequestedDownload: true });
      success(REQUEST_PDF_SUCCEEDED_MESSAGE, { autoHideDuration: 10 * 1000 });
    } catch {
      this.setState({ downloading: false, errorMessage: 'Download failed.' });
    }
  }

  handleUpdateSignature(user) {
    const { currentUser } = this.state;

    const updatedUser = { ...currentUser, signature: user.signature };
    this.setState({ currentUser: updatedUser });
  }

  handleCloseErrorMessage() {
    this.setState({ errorCount: 0, errorMessage: null });
  }

  handleCloseForm() {
    const { project } = this.state;
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const router = require('../../../router');
    return router.goToInpsectionsDashboard(project.id, project.organizationId);
  }

  releaseLock() {
    const { projectId, calendarName, submissionSlug } = this.props;
    this.formSubmissionService.releaseLock(
      projectId,
      calendarName,
      submissionSlug,
    );
  }

  loadCurrentUser() {
    return this.userService.getCurrentUser();
  }

  loadProject() {
    const { projectId } = this.props;

    return this.projectService.getById(projectId);
  }

  loadFormTemplate() {
    const { templateSlug, projectId, isStencil } = this.props;

    return this.formTemplateService
      .getBySlugForSubmission(templateSlug, projectId, isStencil)
      .then((formTemplate) => {
        if (!formTemplate) {
          throw new Error('no form template');
        }
        return formTemplate;
      });
  }

  loadFormSubmission() {
    const { templateSlug, projectId, submissionSlug, calendarName } =
      this.props;

    return this.formSubmissionService.getBySlug(
      projectId,
      submissionSlug,
      templateSlug,
      calendarName,
    );
  }

  async extendLock() {
    const newLockInfo = await this.loadLock();
    const { lockInfo: oldLockInfo, currentUser } = this.state;

    const oldLockOwnerId = oldLockInfo?.lockedBy.id;
    const newLockOwnerId = newLockInfo?.lockedBy.id;

    // refetch latest submission if you suddenly became the lock owner, so you get
    // the changes that someone just saved before getting to edit
    if (
      oldLockOwnerId !== currentUser.id &&
      newLockOwnerId === currentUser.id
    ) {
      this.setState({
        requestPageReloadBeforeYieldingFormLock: true,
        lockInfo: newLockInfo,
      });
    } else {
      this.setState({ lockInfo: newLockInfo });
    }
  }

  loadLock() {
    const { calendarName, projectId, submissionSlug } = this.props;

    return this.formSubmissionService.requestLock(
      projectId,
      calendarName,
      submissionSlug,
    );
  }

  initializeSubmission(formTemplate, fetchedSubmission) {
    const { isStencil } = this.props;

    if (!fetchedSubmission) {
      const submission = this.createFormSubmission(formTemplate.id);
      return { ...submission, isStencil };
    }

    return { ...fetchedSubmission, isStencil };
  }

  initializeSubmissionGroups(
    submission,
    formTemplate,
    currentTemplateGroup,
    currentSubmissionGroupNumber,
  ) {
    let submissionGroups = [...submission.groups];
    const candidateGroups = this.getSubmissionGroups(
      submissionGroups,
      currentTemplateGroup.id,
    );

    if (!candidateGroups.length) {
      const newGroup = this.createSubmissionGroup(currentTemplateGroup);
      submissionGroups.push(newGroup);
      this.setState({ saveState: SaveState.DIRTY });
    }

    if (!submission.isStencil) {
      const currentSubmissionGroup =
        candidateGroups[currentSubmissionGroupNumber];
      submissionGroups = submissionGroups.map((group) => {
        if (group === currentSubmissionGroup && group.stencilDefault) {
          this.setState({ saveState: SaveState.DIRTY });
          return { ...group, stencilDefault: false };
        }
        return group;
      });
    }

    return this.formatSubmissionGroups(submissionGroups, formTemplate);
  }

  formatSubmissionGroups(submissionGroups, formTemplate) {
    return submissionGroups.reduce((accumulator, submissionGroup) => {
      const templateGroup = this.getTemplateGroup(
        submissionGroup,
        formTemplate,
      );

      if (
        templateGroup &&
        !this.isParentGroup(templateGroup, submissionGroup)
      ) {
        accumulator.push({
          ...submissionGroup,
          key: submissionGroup.id || UTIL.IDENTIFIER.generateUUIDv4(),
          fields: this.addMissingFields(submissionGroup, templateGroup),
          name: this.groupName(
            submissionGroup,
            templateGroup,
            submissionGroups,
          ),
        });
      }
      return accumulator;
    }, []);
  }

  isParentGroup(templateGroup, submissionGroup) {
    return (
      templateGroup.isDuplicable &&
      !submissionGroup.parentFieldGroupId &&
      !submissionGroup.fields.length
    );
  }

  formatSubmissionToSave() {
    const { formSubmission } = this.state;
    const { calendarName, eventDate, eventNumber } = this.props;

    const formattedSubmission = {
      ...formSubmission,
      groups: this.formatGroupsToSave(formSubmission.groups),
    };
    if (eventDate) {
      formattedSubmission.eventDate = eventDate;
    }
    if (eventNumber) {
      formattedSubmission.eventNumber = eventNumber;
    }
    if (calendarName) {
      formattedSubmission.calendarName = calendarName;
    }
    return formattedSubmission;
  }

  formatGroupsToSave(groups) {
    return groups.map((group) => {
      const { key, name, ...formattedGroup } = group;
      formattedGroup.fields = this.formatFieldsToSave(group.fields);
      return formattedGroup;
    });
  }

  formatFieldsToSave(fields) {
    return fields.map((field) => {
      const { errors, ...formattedField } = field;
      return formattedField;
    });
  }

  createFormSubmission(templateId) {
    const { projectId, submissionSlug, isStencil } = this.props;

    return {
      projectId,
      formTemplateId: templateId,
      slug: submissionSlug,
      isStencil,
      groups: [],
    };
  }

  createSubmissionGroup(templateGroup) {
    const { isStencil } = this.props;
    const fields = templateGroup.fields.map((tf) =>
      this.createSubmissionField(tf),
    );

    return {
      key: UTIL.IDENTIFIER.generateUUIDv4(),
      formTemplateFieldGroupId: templateGroup.id,
      isComplete: false,
      stencilDefault: isStencil,
      orderNumber: 1,
      fields,
    };
  }

  createSubmissionField(templateField) {
    return {
      fieldSlug: templateField.slug,
      fieldValue: templateField.defaultValue,
    };
  }

  addMissingFields(submissionGroup, templateGroup) {
    return templateGroup.fields.reduce((accumulator, templateField) => {
      if (templateField.type === 'explanation_area') {
        return accumulator;
      }
      let submissionField = this.getSubmissionField(
        submissionGroup,
        templateField.slug,
      );
      if (!submissionField) {
        submissionField = this.createSubmissionField(templateField);
      }
      return [...accumulator, submissionField];
    }, []);
  }

  nextGroupNumber(deletedTemplateGroupId, deletedGroupNumber) {
    const { currentTemplateGroupId, currentSubmissionGroupNumber } = this.state;
    if (
      currentTemplateGroupId === deletedTemplateGroupId &&
      currentSubmissionGroupNumber >= deletedGroupNumber
    ) {
      const newSubmissionGroupNumber = currentSubmissionGroupNumber - 1;
      return Math.max(0, newSubmissionGroupNumber);
    }
    return currentSubmissionGroupNumber;
  }

  async transitionGroups(previousSubmissionGroup, nextSubmissionGroup) {
    const { formSubmission } = this.state;
    const { isStencil } = this.props;
    if (isStencil) {
      return [...formSubmission.groups];
    }

    const updatedGroupPromises = formSubmission.groups.map(async (group) => {
      let { isComplete, stencilDefault } = group;
      let nowComplete;

      if (group === previousSubmissionGroup) {
        isComplete = await this.isGroupComplete(formSubmission, group);
      }
      if (group === nextSubmissionGroup) {
        if (stencilDefault) {
          stencilDefault = false;
          this.setFormDirty();
        }
        // these 2 if blocks handle the unfortunate scenario where the mobile
        // app (or some other culprit) creates a group but does not mark it complete
        if (!isComplete) {
          nowComplete = await this.isGroupComplete(formSubmission, group);
        }
        if (!isComplete && nowComplete) {
          this.setFormDirty();
        }
      }
      return {
        ...group,
        isComplete,
        stencilDefault,
      };
    });
    return Promise.all(updatedGroupPromises);
  }

  async isGroupComplete(formSubmission, submissionGroup) {
    const partialSubmission = { ...formSubmission, groups: [submissionGroup] };
    try {
      const response = await this.formSubmissionService.isComplete(
        partialSubmission,
      );
      return response.groups[0].isComplete;
    } catch {
      this.setState({
        errorMessage: 'There was a problem checking section for completion.',
      });
      return false;
    }
  }

  async saveSubmission() {
    const formSubmission = this.formatSubmissionToSave();
    try {
      const saveSubmissionResult = await this.formSubmissionService.save(
        formSubmission,
      );
      return saveSubmissionResult;
    } catch (e) {
      this.setFormDirty();
      this.setState({
        errorMessage: e.message,
      });
      return null;
    }
  }

  validateRequiredGroups(submissionGroups) {
    const { formTemplate } = this.state;

    return formTemplate.groups.map((templateGroup) => {
      const hasError =
        templateGroup.isRequired &&
        !templateGroup.isDuplicable &&
        this.getSubmissionGroups(submissionGroups, templateGroup.id).length ===
          0;
      return { ...templateGroup, hasError };
    }, submissionGroups);
  }

  clearTemplateGroupError(selectedTemplateGroupId) {
    const { formTemplate } = this.state;

    return formTemplate.groups.map((tg) =>
      tg.id === selectedTemplateGroupId ? { ...tg, hasError: false } : tg,
    );
  }

  validateForm(submissionGroups) {
    const { formTemplate } = this.state;

    return submissionGroups.map((submissionGroup) => {
      const templateGroup = this.getTemplateGroup(
        submissionGroup,
        formTemplate,
      );
      if (
        !this.shouldShowGroup(
          templateGroup,
          formTemplate.groups,
          submissionGroups,
        )
      ) {
        return submissionGroup;
      }

      const shownTemplateFields = this.filterHiddenFields(
        templateGroup,
        submissionGroup,
      );
      return this.validateGroup(submissionGroup, shownTemplateFields);
    });
  }

  validateGroup(submissionGroup, shownTemplateFields) {
    const { currentUser } = this.state;

    const validatedFields = submissionGroup.fields.map((submissionField) => {
      const templateField = shownTemplateFields.find(
        (tf) => tf.slug === submissionField.fieldSlug,
      );
      if (!templateField) {
        return submissionField;
      }
      const validator = ValidationFactory.For(
        templateField,
        submissionField,
        currentUser,
      );
      return { ...submissionField, errors: validator.validate() };
    });

    return { ...submissionGroup, fields: validatedFields };
  }

  filterHiddenGroups(templateGroups, submissionGroups) {
    return templateGroups.filter((candidateTemplateGroup) =>
      this.shouldShowGroup(
        candidateTemplateGroup,
        templateGroups,
        submissionGroups,
      ),
    );
  }

  shouldShowGroup(candidateTemplateGroup, templateGroups, submissionGroups) {
    // This should be managed by adding conditional groups to form templates
    if (candidateTemplateGroup.slug !== 'discharge') {
      return true;
    }
    const conditionalTemplateGroup = templateGroups.find(
      (tg) => tg.slug === 'inspect',
    );
    const conditionalSubmissionGroup = submissionGroups.find(
      (sg) => sg.formTemplateFieldGroupId === conditionalTemplateGroup.id,
    );
    if (!conditionalSubmissionGroup) {
      return true;
    }
    const conditionalSubmissionField = conditionalSubmissionGroup.fields.find(
      (sf) => sf.fieldSlug === 'inspect-discharge',
    );
    if (!conditionalSubmissionField) {
      return true;
    }
    return conditionalSubmissionField.fieldValue === 'Yes';
  }

  filterHiddenFields(templateGroup, submissionGroup) {
    return templateGroup.fields.filter((candidate) => {
      if (!candidate.isFieldConditional) {
        return true;
      }
      const conditionalTemplateField = templateGroup.fields.find(
        (tf) => tf.id === candidate.conditionalFieldId,
      );
      // this check would not be necessary if we ensured conditional
      // field id's were removed when the field was
      if (!conditionalTemplateField) {
        return true;
      }
      const conditionalSubmissionField = this.getSubmissionField(
        submissionGroup,
        conditionalTemplateField.slug,
      );
      return this.shouldShowField(
        candidate,
        conditionalSubmissionField,
        submissionGroup,
        templateGroup,
      );
    });
  }

  shouldShowField(
    conditionallyShownField,
    conditionalField,
    submissionGroup,
    templateGroup,
  ) {
    if (!conditionalField) {
      return true;
    }

    const { fieldValue } = conditionalField;
    const { conditionalFieldValue, conditionalOperator } =
      conditionallyShownField;

    const isFieldVisible = isConditionallyShownFieldVisible(
      fieldValue,
      conditionalOperator,
      conditionalFieldValue,
    );

    let isParentFieldVisible = true;
    const parentField = templateGroup.fields.find(
      (tf) => tf.slug === conditionalField.fieldSlug,
    );

    // recursively check visibility of parent field (and its parent, etc)
    if (parentField && parentField.isFieldConditional) {
      const parentConditionalField = templateGroup.fields.find(
        (tf) => tf.id === parentField.conditionalFieldId,
      );

      if (parentConditionalField) {
        const parentConditionalSubmissionField = this.getSubmissionField(
          submissionGroup,
          parentConditionalField.slug,
        );

        isParentFieldVisible = this.shouldShowField(
          parentField,
          parentConditionalSubmissionField,
          submissionGroup,
          templateGroup,
        );
      }
    }

    return isFieldVisible && isParentFieldVisible;
  }

  renameGroups(submissionGroups, templateGroup) {
    return submissionGroups.map((submissionGroup) => {
      let { name } = submissionGroup;
      if (submissionGroup.formTemplateFieldGroupId === templateGroup.id) {
        name = this.groupName(submissionGroup, templateGroup, submissionGroups);
      }
      return { ...submissionGroup, name };
    });
  }

  groupName(submissionGroup, templateGroup, allSubmissionGroups) {
    const defaultName = templateGroup.name;
    const { isDuplicable } = templateGroup;
    if (!isDuplicable || !submissionGroup) {
      return defaultName;
    }
    const submissionGroupsForGivenTemplateGroup = this.getSubmissionGroups(
      allSubmissionGroups,
      templateGroup.id,
    );
    const subgroupIndex = submissionGroupsForGivenTemplateGroup.findIndex(
      (group) => group.id === submissionGroup.id,
    );
    const orderNumber = subgroupIndex + 1;
    return getDuplicableGroupName(templateGroup, submissionGroup, orderNumber);
  }

  render() {
    const {
      formTemplate,
      formSubmission,
      currentSubmissionGroupNumber,
      project,
      currentUser,
      saveState,
      downloading,
      hasAlreadyRequestedDownload,
      loadError,
      errorCount,
      errorMessage,
      lockInfo,
      requestPageReloadBeforeYieldingFormLock,
    } = this.state;
    const { eventDate, isStencil } = this.props;

    const currentSubmissionGroup =
      this.getCurrentSubmissionGroup(formSubmission);
    const currentTemplateGroup = this.getCurrentTemplateGroup(
      currentSubmissionGroup,
    );
    const filteredTemplateGroups = this.filterHiddenGroups(
      formTemplate.groups,
      formSubmission.groups,
    );
    const filteredTemplate = {
      ...formTemplate,
      groups: filteredTemplateGroups,
    };

    return (
      <FormSubmissionEditor
        eventDate={eventDate}
        formTemplate={filteredTemplate}
        formSubmission={formSubmission}
        currentTemplateGroup={currentTemplateGroup}
        currentSubmissionGroup={currentSubmissionGroup}
        currentSubmissionGroupNumber={currentSubmissionGroupNumber}
        project={project}
        currentUser={currentUser}
        saveState={saveState}
        downloading={downloading}
        hasAlreadyRequestedDownload={hasAlreadyRequestedDownload}
        loadError={loadError}
        errorCount={errorCount}
        errorMessage={errorMessage}
        lockInfo={lockInfo}
        isStencil={isStencil}
        onClickSection={this.handleClickSection}
        onFieldChange={this.handleFieldChange}
        onFieldError={this.handleFieldError}
        onCreateSubsection={this.handleCreateSubsection}
        onDeleteSubsection={this.handleDeleteSubsection}
        onUpdateSignature={this.handleUpdateSignature}
        onCloseErrorMessage={this.handleCloseErrorMessage}
        onCloseForm={this.handleCloseForm}
        onSaveForm={this.handleSaveForm}
        onDeleteSubmission={this.handleDeleteSubmission}
        onDownloadForm={this.handleDownloadForm}
        onGoToNextSection={this.handleGoToNextSection}
        requestPageReloadBeforeYieldingFormLock={
          requestPageReloadBeforeYieldingFormLock
        }
      />
    );
  }
}

FormSubmissionContainer.propTypes = {
  calendarName: PropTypes.string,
  eventDate: PropTypes.string,
  eventNumber: PropTypes.string,
  groupId: PropTypes.number,
  isStencil: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  submissionSlug: PropTypes.string.isRequired,
  templateSlug: PropTypes.string.isRequired,
};

FormSubmissionContainer.defaultProps = {
  calendarName: null,
  eventDate: null,
  eventNumber: null,
  groupId: null,
  isStencil: false,
};

FormSubmissionContainer.contextType = ToastContext;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormSubmissionContainer;
