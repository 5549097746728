/* eslint-disable */
var CreateAndEditMapViewBase = Backbone.View.extend({
  template: require('./createAndEditMapView.pug'),

  /**
   * Set the view element the template, filled in with appropriate data
   */
  render: function () {
    this.setElement(this.template(this._getTemplateData()));

    // Cache some jquery elements for faster performance
    this._$errorNode = this.$('.alert');
    this._$loader = this.$('.loader');
    this._$submitButton = this.$("#logo-file-form input.btn[type='button']");
    this._$btnHiddenFile = this.$('#btn-hidden-file');
    this._$imageName = this.$('#image-name');
    this._$titlebarForm = this.$('#titlebar-form');
    this._$file = this.$(':file');
    this._$mapForm = this.$('#map-form');
  },

  showModal: function () {
    this.setModel();
    this.render();
    this.$el.modal('show');
  },

  _removeView: function () {
    this.remove();
  },

  /**
   * When user clicks on our Bootstrap file button, this function should be
   * triggered to click the real file upload button that is hidden (because
   * it is ugly)
   */
  _clickRealFileUploadButton: function (e) {
    e.preventDefault();

    this._$errorNode.hide();
    this._$btnHiddenFile.click();
  },

  /**
   * Update the image name displayed to the right of the upload button
   */
  _updateImageName: function (e) {
    var path = $(e.target).val().split('\\');
    this._$imageName.text(path[path.length - 1]);
  },

  /**
   * Submit forms if users presses the enter key
   */
  _handleKeyPress: function (e) {
    // If it's not the enter key, return
    if (e.which !== 13) {
      return;
    }
    e.preventDefault();

    this._submitForms();
  },

  /**
   * Display supplied errors and make the submit button clickable again
   */
  _displayErrors: function ($errors) {
    this._$loader.hide();
    this._$submitButton.show();
    this._$errorNode.html($errors.html()).show();
  },

  _hideModal: function () {
    this.$el.modal('hide');
  },

  /**
   * Get a FormData object populated with the file that the user has selected
   */
  _getLogoFormData: function () {
    // Get the only dom element matching #logo-form and create a FormData object
    var formData = new FormData(this.$('#logo-form')[0]);

    // Get the only dom element matching #btn-hidden-file
    var inputFiles = this.$('#btn-hidden-file')[0];

    // Add the file to the formData. There should only be one file, so just add
    // the first one.
    formData.append('file', inputFiles.files[0]);

    return formData;
  },
});

module.exports = CreateAndEditMapViewBase;
