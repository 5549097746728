/* eslint-disable */
const apiCaller = require('../apiCaller');

let TagTypeCollection = Backbone.Collection.extend({
  initialize(options = {}) {
    this.organizationId = options.organizationId;
  },

  url() {
    return apiCaller.getOrganizationTagTypeUrl(this.organizationId);
  },
});

module.exports = TagTypeCollection;
