/* eslint-disable */
let FormListRowView = require('./FormListRowView');
let moment = require('moment');

let CollapsibleFormListRowView = Backbone.Marionette.CompositeView.extend({
  tagName: 'li',

  template: require('./templates/nestedFormListRow.pug'),

  templateHelpers() {
    let isGroupCollapsible = this._isGroupCollapsible();
    let expandByDefault = this.model.get('lastGroup');
    let getCollapseId = [
      this._getCollapseIdPrefix(),
      this.model.get('groupId'),
    ].join('-');
    let showAsLink =
      moment(this.model.get('groupId'), 'YYYY-MM').startOf('month') < moment();

    return { showAsLink, getCollapseId, expandByDefault, isGroupCollapsible };
  },

  childViewContainer: '.child-view-container',
  childView: FormListRowView,

  behaviors: {
    Tooltip: {},
  },

  ui: {
    $errors: '.errors',
    $warnings: '.warnings',
    $collapse: '.collapse',
    $collapseCaret: '#collapse-caret',
  },

  events: {
    'show.bs.collapse': '_showDownCaret',
    'hide.bs.collapse': '_showLeftCaret',
  },

  childViewOptions() {
    return {
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      isNestedRowView: this.collection && this.collection.length > 1,
      isDisabled: this.isDisabled,
    };
  },

  initialize(options = {}) {
    this.collection = this.model.get('collection');
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.isDisabled = options.isDisabled;
  },

  _getCollapseIdPrefix() {
    return this.model.get('templateSlug');
  },

  _showDownCaret() {
    this.ui.$collapseCaret.removeClass('fa-caret-right');
    this.ui.$collapseCaret.addClass('fa-caret-down');
  },

  _showLeftCaret() {
    this.ui.$collapseCaret.removeClass('fa-caret-down');
    this.ui.$collapseCaret.addClass('fa-caret-right');
  },

  _isGroupCollapsible() {
    if (
      this.collection.length === 1 &&
      this.collection.at(0).has('label') &&
      this.collection.at(0).get('label').indexOf('Add New') > -1
    ) {
      return false;
    } else if (this._allGroupsCollapsible() || this.collection.length > 1) {
      return true;
    }
    return false;
  },

  _allGroupsCollapsible() {
    return true; // if true, even a collection of length one will be grouped.
  },
});

module.exports = CollapsibleFormListRowView;
