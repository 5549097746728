import apiCaller from './apiCaller';
import APP from './config';
import currentUser from './currentUser';
import { AuthenticationError, RecoveryQuestionNotSetError } from './errors';

export const enforceAuthentication = async () => {
  await apiCaller.getPageSetupVars();

  if (!currentUser.isLoggedIn()) {
    throw new AuthenticationError('User not logged in');
  }

  if (!APP.recoveryQuestionSet) {
    // After switching from Stormpath to Okta, we need users to create a security question and answer
    throw new RecoveryQuestionNotSetError('User must set security question');
  }

  return null;
};
