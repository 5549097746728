var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (error, hidden, text) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade above-trip-overlay\" id=\"error-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"error-modal-label\" aria-hidden=\"true\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-hidden=\"true\"\u003E×\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003EError\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["alert","alert-danger",hidden], [false,false,true]), false, true)) + "\u003E" + (null == (pug_interp = error) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cp\u003E" + (null == (pug_interp = text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-danger pull-right\" data-dismiss=\"modal\"\u003EClose\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "error" in locals_for_with ?
        locals_for_with.error :
        typeof error !== 'undefined' ? error : undefined, "hidden" in locals_for_with ?
        locals_for_with.hidden :
        typeof hidden !== 'undefined' ? hidden : undefined, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined));
    ;;return pug_html;};
module.exports = template;