/* eslint-disable */
var _ = require('underscore');
var confirmView = require('../modals/confirmView').singleton();

var WarnBeforeDelete = Backbone.Marionette.Behavior.extend({
  defaults: {
    text: 'this?',
  },

  events: {
    'click @ui.delete': 'warn',
  },

  warn: function () {
    var model = this.view.model;
    var view = this.view;

    if (typeof view.getWarningBeforeDeleteText === 'function') {
      this.options.text = view.getWarningBeforeDeleteText();
    }

    var options = _.extend(this.options, {
      confirmClicked: function () {
        model
          .destroy({ wait: true })
          .done(function () {
            view.trigger('success');
          })
          .fail(function () {
            view.trigger('error');
          });
      },
    });

    confirmView.showModal(options);
  },
});

module.exports = WarnBeforeDelete;
