import React from 'react';
import { ValidationError } from './types';

type ParameterSectionErrorProps = {
  errors?: ValidationError[];
};

export function ParameterSectionError(props: ParameterSectionErrorProps) {
  const { errors } = props;
  if (!errors?.length) return null;
  return (
    <div className="error-row">
      <div className="fw6">Errors:</div>
      {errors.map((e) => (
        <div key={e.text}>
          {e.name}: {e.text}
        </div>
      ))}
    </div>
  );
}
