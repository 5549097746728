/* eslint-disable */
import BaseListSettingsModalView from '../../../../../formLists/BaseListSettingsModalView';
import apiCaller from '../../../../../../../../apiCaller';
import { HMBP_CHEMICALS_LOCATION_FIELD } from './constants';
import APP from '../../../../../../../../config';

let SettingsModalView = BaseListSettingsModalView.extend({
  template: require('./templates/settingsModal.pug'),

  ui: {
    $modal: '.modal.hmbp-chemicals-settings',
    $form: 'form',
    $errorContainer: '.alert-danger',
  },

  events: {
    'click .btn-submit': '_saveSettings',
  },

  templateHelpers() {
    return {
      isHmbpChemicalsLocationFieldChecked: (value) =>
        this._hmbpChemicalsLocationField === value,
    };
  },

  initialize(options = {}) {
    this._projectId = options.projectId;
    this._hmbpChemicalsLocationField = options.hmbpChemicalsLocationField;
  },

  _saveSettings() {
    const formData = this.ui.$form.serializeObject();
    const data = {
      settingKey: HMBP_CHEMICALS_LOCATION_FIELD,
      settingValue: formData.location,
    };

    return apiCaller
      .saveProjectSetting(this._projectId, data)
      .bind(this)
      .then(this.hideModal.bind(this))
      .then(() => {
        setTimeout(() => APP.vent.trigger('formListSettings:save'));
      })
      .catch((error) => this.triggerMethod('ShowError', error));
  },
});

module.exports = SettingsModalView;
