export interface OnboardingSession {
  id: string;
  organizationId: string;
  organizationName: string;
  live: boolean;
  sections: OnboardingSection[];
}

interface BaseOnboardingSection {
  projectId: string;
  projectName: string;
  moduleName: string;
  documentRequests: OnboardingDocumentRequest[];
  documentCounts: OnboardingDocumentCounts;
}

export interface OnboardingDocumentCounts {
  actionRequiredDocumentCount: number;
  uploadedDocumentCount: number;
  acceptedDocumentCount: number;
  totalDocumentCount: number;
}

export interface UninitializedOnboardingSection extends BaseOnboardingSection {
  enabled: false;
}

export interface InitializedOnboardingSection extends BaseOnboardingSection {
  id: string;
  enabled: boolean;
}

export type OnboardingSection =
  | UninitializedOnboardingSection
  | InitializedOnboardingSection;

interface BaseOnboardingDocumentRequest {
  id: string;
  onboardingSectionId: string;
  title: string;
  status: OnboardingDocumentStatus;
  notes: string;
  customerEditable: boolean;
  createdAt: string;
  updatedAt?: string;
}

export interface UnfulfilledOnboardingDocumentRequest
  extends BaseOnboardingDocumentRequest {
  documentId: null;
}

export interface FulfilledOnboardingDocumentRequest
  extends BaseOnboardingDocumentRequest {
  documentId: string;
  documentName: string;
  documentDescription: string;
}

export type OnboardingDocumentRequest =
  | UnfulfilledOnboardingDocumentRequest
  | FulfilledOnboardingDocumentRequest;

export enum OnboardingDocumentStatus {
  actionRequired = 'action_required',
  uploaded = 'uploaded',
  accepted = 'accepted',
}
