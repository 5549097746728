// eslint-disable-next-line import/default
import UsersLayoutView from '@monorepo/old-web/js/dashboards/projects/content/generic/users/UsersLayoutView';

const ProjectUsersLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/users.pug'),

  className: 'backbone-wrapper-content-container',

  regions: {
    projectUsers: '#project-users',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
  },

  async onShow() {
    this.projectUsers.show(
      new UsersLayoutView({ projectModel: this.projectModel }),
    );
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ProjectUsersLayoutView;
