var pug = require("!../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (hasProjectUpdatePermission, name) {
      pug_html = pug_html + "\u003Ch3 class=\"project-name\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E\u003Cinput" + (" class=\"form-control edit-project-name-input display-none\""+pug.attr("value", name, true, true)) + "\u003E";
if (hasProjectUpdatePermission) {
pug_html = pug_html + "\u003Ca class=\"edit-project-name-icon flex\" rel=\"tipsy\" title=\"Edit project name\"\u003E\u003Csvg class=\"edit\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
    }.call(this, "hasProjectUpdatePermission" in locals_for_with ?
        locals_for_with.hasProjectUpdatePermission :
        typeof hasProjectUpdatePermission !== 'undefined' ? hasProjectUpdatePermission : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;