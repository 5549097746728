/* eslint-disable */
var ContactItemViewFactory = require('./ContactItemViewFactory');

module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/contactDetails.pug'),

  className: 'panel panel-default',

  getChildView: function (contactItem) {
    return ContactItemViewFactory.create(contactItem);
  },

  childViewContainer: 'form.form-horizontal',

  ui: {
    $form: 'form',
  },
});
