import React from 'react';
import PropTypes from 'prop-types';

const EquationBlock = (props) => {
  const { name, label, factors } = props;
  return (
    <div className="equation-block__wrapper">
      <div className="equation-block__header-wrapper">
        <div className="equation-block__title">{name}</div>
        <div className="equation-block__subtitle">{label}</div>
      </div>
      {factors.length > 0 && (
        <div className="equation-block__content-wrapper">
          <div className="equation-block__equals">=</div>
          {factors.map((factor) =>
            factor.calculationType ? (
              <div className="equation-block__calculation-type">
                <span>{factor.calculationType}</span>
              </div>
            ) : (
              <div className="equation-block__equation-factor">
                <div className="equation-block__equation-factor-title">
                  {factor.name}
                </div>
                <div className="equation-block__equation-factor-subtitle">
                  {factor.label}
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

EquationBlock.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  factors: PropTypes.arrayOf(PropTypes.shape({})),
};

EquationBlock.defaultProps = {
  factors: [],
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EquationBlock;
