/* eslint-disable */
// Services
var APP = require('../config');
var WebMap = require('./WebMap');
var bigSpinner = require('../services/bigSpinner');
var tipsyConfig = require('../lib/tipsyConfig');
const BluebirdPromise = require('bluebird');

// Collections
var styleCollections =
  require('../models_collections/styles/styleCollectionsWrapper').singleton();
var iconCollection =
  require('../models_collections/iconCollection').singleton();
var mapCollection = require('../models_collections/mapCollection').singleton();
var featureCollection =
  require('../models_collections/featureCollection').singleton();
var layerCollection =
  require('../models_collections/layerCollection').singleton();

// Views
var newFeatureView = require('../featureManagement/NewFeatureView').singleton();
var EditFeatureView =
  require('../featureManagement/EditFeatureView').singleton();
var SearchAddressView = require('./SearchAddressView');
var GeolocateButtonView = require('./GeolocateButtonView');
var StreetOverlayButtonView = require('./StreetOverlayButtonView');
var TopographyOverlayButtonView = require('./TopographyOverlayButtonView');
var TeslaFremontLevelOneView = require('./TeslaFremontLevel1ButtonView');
var TeslaFremontLevelTwoView = require('./TeslaFremontLevel2ButtonView');
var OpacitySliderView = require('./OpacitySliderView');
var ToggleSidebarView = require('./ToggleSidebarView');

function touchToMouse(event, type) {
  if (event.touches.length > 1) {
    return;
  }
  var touch = event.changedTouches[0];
  if (!type) {
    switch (event.type) {
      case 'touchstart':
        type = 'mousedown';
        break;
      case 'touchmove':
        type = 'mousemove';
        break;
      case 'touchend':
        type = 'mouseup';
        break;
      default:
        return;
    }
  }
  var simulatedEvent = document.createEvent('MouseEvent');
  simulatedEvent.initMouseEvent(
    type,
    true,
    true,
    window,
    1,
    touch.screenX,
    touch.screenY,
    touch.clientX,
    touch.clientY,
    false,
    false,
    false,
    false,
    0,
    null,
  );
  touch.target.dispatchEvent(simulatedEvent);
  event.preventDefault();
}

function prepareSimulatedTouchMapEvents(element) {
  element.ontouchmove = touchToMouse;
  element.ontouchstart = touchToMouse;
  element.ontouchend = (event) => {
    touchToMouse(event, 'mousemove');
    touchToMouse(event);
  };
}

var MapView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/map.pug'),

  regions: {
    search: '#search-bar',
    geolocateButton: '#geolocate-button',
    streetOverlayButton: '#street-overlay-button',
    topographyOverlayButton: '#topography-overlay-button',
    teslaFremontLevelOneButton: '#tesla-fremont-level-1-button',
    teslaFremontLevelTwoButton: '#tesla-fremont-level-2-button',
    opacitySlider: '#opacity-slider',
    downloadMapButton: '#download-map-button',
    toggleSidebarButton: '#toggle-sidebar',
  },

  templateHelpers: function () {
    return {
      isTeslaProject: this.isTeslaProject,
    };
  },

  initialize: function (options = {}) {
    this.isFederalProject = options.isFederalProject;
    this.editedFeatures = {};
    const projectId = this.model.get('project_id');
    const { organizationId } = options;
    this.isLehighProject =
      organizationId === '9769b2fe-f616-49d5-a64b-97c32efc9d06';
    this.isTeslaProject = projectId === '3f490254-98cb-41bf-acc7-7ebe0a2fd561';
    this.isTeslaDeerCreekProject =
      projectId === '353e2fe5-daf1-442c-9547-69b50c11d1d4';
    this.isTesla901Page = projectId === 'e4e71ce7-53c4-4ff6-8abb-62485b801cae';
    this.isTesla47400Kato =
      projectId === '377e4c7b-428f-403e-910d-b4c4462b138f';
    this.isNewbyProject = projectId === '234544df-d016-4053-8e73-016ac66cb5ad';
  },

  onShow: function () {
    $('html > body').attr('data-view', 'map');
    $('html').css('overflow', 'auto');
    this.map = new WebMap({
      center: this.model.get('center'),
      zoom: this.model.get('zoom'),
      baseMapType: this.model.get('base_map_type'),
      teslaFremontLevel: this.model.get('tesla_fremont_level'),
      isMetric: this.model.isMetric(),
      opacity: this.model.get('opacity'),
      disableToggleScale: !!this.isFederalProject,
      isNewbyProject: this.isNewbyProject,
      isLehighProject: this.isLehighProject,
      isTeslaProject: this.isTeslaProject,
      isTeslaDeerCreekProject: this.isTeslaDeerCreekProject,
      isTesla901Page: this.isTesla901Page,
      isTesla47400Kato: this.isTesla47400Kato,
      projectId: this.model.get('project_id'),
    });

    var tileLayerLoadPromise = new BluebirdPromise(
      function (resolve) {
        let bingLoaded = false;
        let teslaLoaded = !this.isTeslaProject;
        let lehighLoaded = !this.isLehighProject;
        let teslaDeerCreekLoaded = [];
        _.each([24, 25, 26], (k) => {
          teslaDeerCreekLoaded[k] = !this.isTeslaDeerCreekProject;
        });
        let tesla901PageLoaded = !this.isTesla901Page;
        let tesla47400KatoLoaded = !this.isTesla47400Kato;

        function resolvePromise() {
          if (
            this.isTeslaProject &&
            (this.model.isTeslaFremontLevel1On() ||
              this.model.isTeslaFremontLevel2On())
          ) {
            if (bingLoaded && teslaLoaded) {
              bingLoaded = false;
              teslaLoaded = !this.isTeslaProject;
              resolve();
            }
          } else if (this.isTesla901Page) {
            if (bingLoaded && tesla901PageLoaded) {
              bingLoaded = false;
              tesla901PageLoaded = !this.isTesla901Page;
              resolve();
            }
          } else if (this.isTesla47400Kato) {
            if (bingLoaded && tesla47400KatoLoaded) {
              bingLoaded = false;
              tesla47400KatoLoaded = !this.isTesla47400Kato;
              resolve();
            }
          } else if (this.isTeslaDeerCreekProject) {
            if (
              bingLoaded &&
              teslaDeerCreekLoaded[24] &&
              teslaDeerCreekLoaded[25] &&
              teslaDeerCreekLoaded[26]
            ) {
              bingLoaded = false;
              _.each([24, 25, 26], (k) => {
                teslaDeerCreekLoaded[k] = !this.isTeslaDeerCreekProject;
              });
              resolve();
            }
          } else if (this.isLehighProject) {
            if (bingLoaded && lehighLoaded) {
              bingLoaded = false;
              lehighLoaded = !this.isLehighProject;
              resolve();
            }
          } else {
            bingLoaded = false;
            resolve();
          }
        }

        this.listenTo(
          this.map,
          'tileLayerLoad:bing',
          function () {
            bingLoaded = true;
            resolvePromise.call(this);
          }.bind(this),
        );

        this.listenTo(this.map, 'tileLayerLoad:lehighGrid', function () {
          lehighLoaded = true;
          resolvePromise.call(this);
        });

        this.listenTo(this.map, 'tileLayerLoad:teslaFremontGrid', function () {
          teslaLoaded = true;
          resolvePromise.call(this);
        });
        this.listenTo(
          this.map,
          'tileLayerLoad:teslaDeerCreek24Grid',
          function () {
            teslaDeerCreekLoaded[24] = true;
            resolvePromise.call(this);
          },
        );
        this.listenTo(
          this.map,
          'tileLayerLoad:teslaDeerCreek25Grid',
          function () {
            teslaDeerCreekLoaded[25] = true;
            resolvePromise.call(this);
          },
        );
        this.listenTo(
          this.map,
          'tileLayerLoad:teslaDeerCreek26Grid',
          function () {
            teslaDeerCreekLoaded[26] = true;
            resolvePromise.call(this);
          },
        );
        this.listenTo(
          this.map,
          'tileLayerLoad:tesla47400KatoGrid',
          function () {
            tesla47400KatoLoaded = true;
            resolvePromise.call(this);
          },
        );
        this.listenTo(this.map, 'tileLayerLoad:tesla901PageGrid', function () {
          tesla901PageLoaded = true;
          resolvePromise.call(this);
        });
      }.bind(this),
    );
    this._prettifyTooltipsForZoomControls();
    this._prettifyTooltipsForDrawControls();
    this._drawFeaturesQueue = [];
    this._showSearch();
    this._showGeolocateButton();
    this._showStreetOverlayButton();
    this._showTopographyOverlayButton();
    this._showTeslaFremontLevel1Button();
    this._showTeslaFremontLevel2Button();
    this._showOpacitySlider();
    this._showDownloadMapButton();
    this._showToggleSidebarButton();
    this._initializeLegend();
    this._drawFeatures();
    this._drawAerialimageryLayers();
    if (typeof window.callPhantom === 'function') {
      tileLayerLoadPromise.then(function () {
        setTimeout(function () {
          window.callPhantom('takeShot');
        }, 500);
      });
    }
    this._registerEvents();
    prepareSimulatedTouchMapEvents($('#map')[0]);
  },

  onDestroy: function () {
    if (this.map) {
      this.map.remove();
    }
  },

  _showSearch: function () {
    var searchAddressView = new SearchAddressView();
    this.search.show(searchAddressView);
    this.map.disableClickPropagation(searchAddressView.el);
    this.listenTo(searchAddressView, 'search', this._handleSearch);
    this.listenTo(
      searchAddressView,
      'clear',
      this.map.clearTempLayers.bind(this.map),
    );
  },

  _showGeolocateButton: function () {
    var geolocateButtonView = new GeolocateButtonView();
    this.geolocateButton.show(geolocateButtonView);
    this.listenTo(
      geolocateButtonView,
      'turnOff',
      this.map.clearTempLayers.bind(this.map),
    );
    this.listenTo(
      geolocateButtonView,
      'geolocate:success',
      function (lat, lng, successCount) {
        // only set the view on the first lat/lng coords received
        if (successCount === 1) {
          this.map.setView({ lat: lat, lng: lng });
        }

        this.map.clearTempLayers();
        this.map.addGeolocateLayer(lat, lng);
      },
    );
  },

  _showStreetOverlayButton: function () {
    var streetOverlayButtonView = new StreetOverlayButtonView({
      model: this.model,
    });
    this.streetOverlayButton.show(streetOverlayButtonView);
    this.listenTo(streetOverlayButtonView, 'toggle', this._updateTileLayer);
  },

  _showTopographyOverlayButton: function () {
    var topographyOverlayButtonView = new TopographyOverlayButtonView({
      model: this.model,
    });
    this.topographyOverlayButton.show(topographyOverlayButtonView);
    this.listenTo(topographyOverlayButtonView, 'toggle', this._updateTileLayer);
  },

  _showTeslaFremontLevel1Button: function () {
    if (this.isTeslaProject) {
      let teslaFremontLevel1ButtonView = new TeslaFremontLevelOneView({
        model: this.model,
      });
      this.teslaFremontLevelOneButton.show(teslaFremontLevel1ButtonView);
      this.listenTo(
        teslaFremontLevel1ButtonView,
        'toggle',
        this._updateTileLayer,
      );
    }
  },

  _showTeslaFremontLevel2Button: function () {
    if (this.isTeslaProject) {
      let teslaFremontLevel2ButtonView = new TeslaFremontLevelTwoView({
        model: this.model,
      });
      this.teslaFremontLevelTwoButton.show(teslaFremontLevel2ButtonView);
      this.listenTo(
        teslaFremontLevel2ButtonView,
        'toggle',
        this._updateTileLayer,
      );
    }
  },

  _showOpacitySlider: function () {
    var opacitySliderView = new OpacitySliderView({ model: this.model });
    this.opacitySlider.show(opacitySliderView);
    this.map.disableClickPropagation(opacitySliderView.el);
    this.listenTo(opacitySliderView, 'change', function (opacity) {
      this.map.updateMapOpacity(opacity);
    });
  },

  _showDownloadMapButton: function () {
    var DownloadMapButtonView = require('./DownloadMapButtonView');
    var downloadMapButtonView = new DownloadMapButtonView({
      projectId: this.model.get('project_id'),
      mapId: this.model.id,
    });
    this.downloadMapButton.show(downloadMapButtonView);
    this.listenTo(
      downloadMapButtonView,
      'click:downloadMapButton change:mapSize',
      function () {
        setTimeout(this.map.invalidateSize.bind(this.map), 600);
      },
    );
    this.listenTo(
      downloadMapButtonView,
      'click:downloadMapButton',
      function () {
        if (this.map.scrollWheelZoom.enabled()) {
          this.map.scrollWheelZoom.disable();
        } else {
          this.map.scrollWheelZoom.enable();
        }
      },
    );
  },

  _showToggleSidebarButton: function () {
    var toggleSidebarView = new ToggleSidebarView({});
    this.toggleSidebarButton.show(toggleSidebarView);
  },

  _registerEvents: function () {
    this.listenTo(
      APP.vent,
      'toggleSidebarView:click:toggleSidebarButton',
      this.map.invalidateSize.bind(this.map),
    );
    this.listenTo(this.map, 'click', this._disableLegendEditMode);
    this.listenTo(this.map, 'click:feature', this._handleFeatureClick);
    this.listenTo(this.map, 'draw:created', this._openNewFeatureModal);
    this.listenTo(this.map, 'draw:edited', this._updateFeatures);
    this.listenTo(this.map, 'draw:allMarkers', this._drawFeatures);
    this.listenTo(this.map, 'draw:deleted', this._deleteFeatures);
    this.listenTo(this.map, 'draw:revertEdits', this._drawFeatures);
    this.listenTo(this.map, 'moveend', this._updateSelectedMap);
    this.listenTo(this.map, 'drop:feature', this._dropFeature);
    this.listenTo(
      this.map,
      'draw:revertEditedFeatures',
      this._revertEditedFeatures,
    );

    // update scalebar listeners
    this.listenTo(this.map, 'moveend', this._updateScalebar);
    this.listenTo(this.map, 'click:toggleScale', function () {
      this._toggleScaleUnits();
      this._updateScalebar();
      this._updateDrawControls();
    });

    this.listenTo(featureCollection, 'add', this._handleNewFeature);
    this.listenTo(featureCollection, 'remove', this._handleRemoveFeature);
    this.listenTo(featureCollection, 'removeBulk', this.removeFeatures);
    this.listenTo(featureCollection, 'toggleOn', this._drawFeatures);
    this.listenTo(featureCollection, 'toggleOff', this.removeFeatures);
    this.listenTo(featureCollection, 'change:name', this._updateLabel);
    this.listenTo(featureCollection, 'change:geography', function (feature) {
      if (feature.isMarker()) {
        this._redrawFeature(feature);
      }
    });
    this.listenTo(featureCollection, 'change:rotation', function (feature) {
      if (feature.isMarker()) {
        this._redrawFeature(feature);
      }
    });
    this.listenTo(styleCollections, 'change', this._handleStyleChange);

    // toggling listeners
    this.listenTo(
      mapCollection,
      'change:feature_on',
      this._handleFeatureDisplaySettingChange,
    );
    this.listenTo(
      mapCollection,
      'change:layer_on',
      this._handleLayerDisplaySettingChange,
    );
    this.listenTo(
      mapCollection,
      'featureDisplaySetting:add',
      this._handleFeatureDisplaySettingChange,
    );

    // this is triggered when changing maps
    this.listenTo(
      mapCollection,
      'displaySettings:fetched',
      this._handleDisplaySettingsFetch,
    );
  },

  _initializeLegend: function () {
    // Add these listeners after everything fetched so that the legend is not
    // unnecessarily updated after every layer and map view added.
    this.listenTo(layerCollection, 'remove', this._updateLegend);
    this.listenTo(layerCollection, 'remove', this._undrawIfAerialImageryLayer);
    this.listenTo(layerCollection, 'add', this._fetchLayerDisplaySettings);
    this.listenTo(layerCollection, 'change:name', this._updateLegend);
    this.listenTo(
      layerCollection,
      'change:mapbox_tileset_slug',
      this._drawAerialimageryLayers,
    );
    this.listenTo(layerCollection, 'change:attrs', (attrValue) => {
      this._updateLabel(featureCollection.get(attrValue.get('feature_id')));
    });
    this.listenTo(featureCollection, 'change:geography', function (feature) {
      if (this.isFederalProject && feature.layer().isSiteBoundaryLayer()) {
        this._updateLegend();
      }
    });
    this.listenTo(mapCollection, 'displaySettings:fetched', this._updateLegend);
    this.listenTo(mapCollection, 'change', this._updateLegend);
    this.listenTo(mapCollection, 'layerDisplaySetting:add', this._updateLegend);
    this.listenTo(styleCollections, 'add', this._updateLegend);
    this.listenTo(styleCollections, 'change', this._updateLegend);

    this._updateLegend();
  },

  /**
   * Re-render the legend.
   */
  _updateLegend: function () {
    if (this.map && !this.map.hasControl('legend')) {
      return;
    }

    var options = {
      layerCollection: layerCollection,
      mapModel: this.model,
      iconCollection: iconCollection,
      isFederalProject: this.isFederalProject,
    };

    if (iconCollection.isExpired() && layerCollection.containsCustomIcons()) {
      iconCollection.refresh(this.map.renderLegend.bind(this.map, options));
    } else {
      this.map.renderLegend(options);
    }
  },

  _undrawIfAerialImageryLayer: function (layer) {
    this.map._removeAerialImageryBaseLayer(layer);
  },

  _fetchLayerDisplaySettings: function () {
    this.model._layerDisplaySettingCollection.fetch();
  },

  /**
   * Disables edit mode of if user clicks anywhere outside of the legend
   */
  _disableLegendEditMode: function (e) {
    if ($(e.target).closest('#legend-container').length < 1) {
      this.map.disableLegendEditMode();
    }
  },

  _updateTileLayer: function () {
    this.map.updateTileLayer({
      baseMapType: this.model.get('base_map_type'),
      opacity: this.model.get('opacity'),
      teslaMap: this.model.get('tesla_fremont_level'),
      projectId: this.model.get('project_id'),
      isNewbyProject: this.isNewbyProject,
    });
  },

  _updateScalebar: function () {
    this.map.removeControl('scalebar');
    this.map.addScalebar({
      isMetric: this.model.isMetric(),
      disableToggle: this.isFederalProject ? true : false,
    });
  },

  _prettifyTooltipsForZoomControls: function () {
    var $el = $('.leaflet-control-zoom a').prop('rel', 'tipsy');
    tipsyConfig($el, { gravity: 'w' });
  },

  _prettifyTooltipsForDrawControls: function () {
    var $el = $('.leaflet-draw-toolbar a').prop('rel', 'tipsy');
    tipsyConfig($el, { gravity: 'e' });
  },

  _updateDrawControls: function () {
    this.map.removeControl('draw');
    this.map.addDrawControl({ isMetric: this.model.isMetric() });
    this._prettifyTooltipsForDrawControls();
  },

  _updateSelectedMap: function () {
    this.model.save({ center: this.map.getCenter(), zoom: this.map.getZoom() });
  },

  _openNewFeatureModal: function (webMapFeature) {
    newFeatureView.showModal(webMapFeature);
  },

  _updateFeatures: function (webMapFeatures) {
    webMapFeatures.forEach(function (webMapFeature) {
      const feature = featureCollection.get(webMapFeature.id);
      const data = {
        geography: webMapFeature.get('geography'),
        rotation: !!webMapFeature.get('rotation')
          ? parseInt(webMapFeature.get('rotation'))
          : 0,
        label_offset_x: webMapFeature.get('labelOffsetX'),
        label_offset_y: webMapFeature.get('labelOffsetY'),
        zoom_level_when_label_offset_created: this.map.getZoom(),
      };
      feature.save(data);
    }, this);

    Object.keys(this.editedFeatures).forEach(function (featureId) {
      const feature = featureCollection.get(featureId);
      const data = {
        label_offset_x: this.editedFeatures[featureId].new.label_offset_x,
        label_offset_y: this.editedFeatures[featureId].new.label_offset_y,
        zoom_level_when_label_offset_created:
          this.editedFeatures[featureId].new
            .zoom_level_when_label_offset_created,
      };
      feature.save(data);
      return this._redrawFeature(feature);
    }, this);

    this.editedFeatures = {};
  },

  _revertEditedFeatures: function () {
    Object.keys(this.editedFeatures).forEach(function (featureId) {
      var feature = featureCollection.get(featureId);
      feature.attributes.label_offset_x =
        this.editedFeatures[featureId].old.label_offset_x;
      feature.attributes.label_offset_y =
        this.editedFeatures[featureId].old.label_offset_y;
      feature.attributes.zoom_level_when_label_offset_created =
        this.editedFeatures[featureId].old.zoom_level_when_label_offset_created;
      return this._redrawFeature(feature);
    }, this);

    this.editedFeatures = {};
  },

  _dropFeature: function (webMapFeature) {
    var feature = featureCollection.get(webMapFeature.id);

    this.editedFeatures[webMapFeature.id] = {
      old: this.editedFeatures[webMapFeature.id]
        ? this.editedFeatures[webMapFeature.id].old
        : {
            label_offset_x: feature.attributes.label_offset_x,
            label_offset_y: feature.attributes.label_offset_y,
            zoom_level_when_label_offset_created:
              feature.attributes.zoom_level_when_label_offset_created,
          },
      new: {
        label_offset_x: webMapFeature.attributes.labelOffsetX,
        label_offset_y: webMapFeature.attributes.labelOffsetY,
        zoom_level_when_label_offset_created: this.map.getZoom(),
      },
    };

    feature.attributes.label_offset_x = webMapFeature.attributes.labelOffsetX;
    feature.attributes.label_offset_y = webMapFeature.attributes.labelOffsetY;
    feature.attributes.zoom_level_when_label_offset_created =
      this.map.getZoom();

    return this._redrawFeature(feature);
  },

  _deleteFeatures: function (webMapFeatureIds) {
    webMapFeatureIds.forEach(function (id) {
      featureCollection.get(id).destroy();
    });
  },

  _toggleScaleUnits: function () {
    if (this.isFederalProject) {
      return;
    }
    this.model.toggleScaleUnits();
  },

  _updateLabel: function (feature) {
    this.map.updateLabel(feature);
  },

  /**
   * Remove features from the map
   *
   * @param featureModels {Backbone.Model | Array<Backbone.Model>} - The features to be removed
   * @returns {BluebirdPromise}
   */
  removeFeatures: function (featureModels) {
    var markerIds = [];
    var polyIds = [];

    featureModels = _.flatten([featureModels]);

    featureModels.forEach(function (feature) {
      if (feature.isMarker()) {
        markerIds.push(feature.id);
      } else {
        polyIds.push(feature.id);
      }
    });

    return BluebirdPromise.bind(this)
      .then(function () {
        if (featureModels.length > 100) {
          return bigSpinner.show();
        }
      })
      .then(function () {
        this.map.removeLayers(markerIds, 'marker');
        this.map.removeLayers(polyIds, 'poly');
      })
      .finally(bigSpinner.hide);
  },

  _handleFeatureClick: function (webMapFeature) {
    // Temp markers are placed on the map with geolocate and search
    if (webMapFeature.get('isTemp')) {
      this._openNewFeatureModal(webMapFeature);
      this._showSearch();
      return;
    }

    return EditFeatureView.showModal({
      model: featureCollection.get(webMapFeature.id),
    });
  },

  _drawAerialimageryLayers: function () {
    const aerialImageryLayers = layerCollection.where({
      type: 'aerialimagery',
    });
    const aerialImageryLayersOn = _.filter(aerialImageryLayers, (l) =>
      this.model.isLayerOn(l.id),
    );

    _.each(aerialImageryLayersOn, (l) => {
      const styleModel = styleCollections.getStyle('aerialimagery', l.id);
      const opacity =
        (styleModel && styleModel.get && styleModel.get('opacity')) || 1;

      if (l.get('mapbox_tileset_slug')) {
        this.map._showAerialImageryBaseLayer(l, opacity);
      } else {
        this.map._removeAerialImageryBaseLayer(l);
      }
    });
  },

  /**
   * Draw features on the map
   *
   * @param features {Backbone.Model | Array<Backbone.Model>} - Features to draw.
   *   If features is undefined all features in layers that are on will be drawn.
   */
  _drawFeatures: function (features) {
    var featuresToDraw = features || this._allOnFeaturesInOnLayers();

    // This allows either a single model or an array of models to be passed in
    featuresToDraw = _.flatten([featuresToDraw]);

    this._drawFeaturesQueue.push(featuresToDraw);
    return this._drawFeaturesQueueWorker();
  },

  _drawFeaturesQueueWorker: function () {
    if (
      this._drawFeaturesQueue.length === 0 ||
      this.alreadyProcessingDrawFeaturesQueue
    ) {
      return;
    }

    this.alreadyProcessingDrawFeaturesQueue = true;
    var featuresToDraw = this._drawFeaturesQueue.shift();

    // Filter out features that are already drawn on the map because it's not
    // necessary to draw them again.
    var drawnFeatureIds = this._drawnFeatureIds();

    featuresToDraw = featuresToDraw.filter(function (feature) {
      return !_.contains(drawnFeatureIds, feature.id);
    }, this);

    return this.map.addFeatureModelsAsLayers(featuresToDraw).finally(
      function () {
        this.alreadyProcessingDrawFeaturesQueue = false;
        return this._drawFeaturesQueueWorker();
      }.bind(this),
    );
  },

  _allOnFeaturesInOnLayers: function () {
    var onFeatureIds = this.model.onFeatureIds();
    var onFeatureIdsInOnLayers = onFeatureIds.filter(
      function (id) {
        var feature = featureCollection.get(id);

        if (feature) {
          return this.model.isLayerOn(feature.layer().id);
        }
      }.bind(this),
    );

    return onFeatureIdsInOnLayers.map(function (id) {
      return featureCollection.get(id);
    });
  },

  _updateBaseLayers(ldsModel, layer) {
    if (ldsModel.get('layer_on') === true) {
      const styleModel = styleCollections.getStyle('aerialimagery', layer.id);
      this.map._showAerialImageryBaseLayer(layer, styleModel.get('opacity'));
    } else {
      this.map._removeAerialImageryBaseLayer(layer);
    }
  },

  _handleStyleChange: function (style) {
    const layer = layerCollection.get(style.get('layer_id'));
    const shouldDrawAerialImageryLayer =
      layer &&
      layer.isAerialImageryLayer &&
      layer.isAerialImageryLayer() &&
      this.model.isLayerOn(layer.id);

    if (shouldDrawAerialImageryLayer) {
      this.map._showAerialImageryBaseLayer(layer, style.get('opacity'));
    } else {
      var onFeaturesInLayer = layer.onFeatures();
      return this.removeFeatures(onFeaturesInLayer).then(
        this._drawFeatures.bind(this, onFeaturesInLayer),
      );
    }
  },

  _handleFeatureDisplaySettingChange: function (fdsModel) {
    var feature = featureCollection.get(fdsModel.get('feature_id'));

    if (fdsModel.get('feature_on')) {
      this._drawFeatures(feature);
    } else {
      this.removeFeatures(feature);
    }
  },

  _handleLayerDisplaySettingChange: function (ldsModel) {
    var layer = layerCollection.get(ldsModel.get('layer_id'));
    if (layer.isAerialImageryLayer()) {
      return this._updateBaseLayers(ldsModel, layer);
    }

    if (ldsModel.get('layer_on')) {
      this._drawFeatures(layer.onFeatures());
    } else {
      this.removeFeatures(layer.onFeatures());
    }
  },

  _redrawFeature: function (feature) {
    return this.removeFeatures(feature).then(
      this._drawFeatures.bind(this, feature),
    );
  },

  _handleDisplaySettingsFetch: function () {
    var featuresToDraw = this._allOnFeaturesInOnLayers();
    var featureIdsToDraw = featuresToDraw.map(function (feature) {
      return feature.id;
    });
    var featureIdsToRemove = _.difference(
      this._drawnFeatureIds(),
      featureIdsToDraw,
    );
    var featuresToRemove = featureIdsToRemove.map(function (id) {
      return featureCollection.get(id);
    });

    // remove any false or undefined values that may have resulted
    // from features being on the map that aren't in the collection
    // eg, the search marker we put down after a search
    featuresToRemove = _.compact(featuresToRemove);

    if (featuresToDraw.length) {
      this._drawFeatures(featuresToDraw);
    }
    if (featuresToRemove.length) {
      this.removeFeatures(featuresToRemove);
    }
  },

  _drawnFeatureIds: function () {
    var drawnFeatureIds = _.pluck(this.map.getLayers(), 'modelId');
    return _.uniq(drawnFeatureIds);
  },

  _handleSearch: function (lat, lng) {
    this.map.clearTempLayers();
    this.map.setView({ lat, lng });
    this.map.addSearchLayer(lat, lng);
  },

  _handleNewFeature: function (feature) {
    if (this.map.hasSearchMarker()) {
      this.map.removeSearchMarker();
    }

    this.model
      .fetchDisplaySettings()
      .bind(this)
      .then(function () {
        var layer = feature.layer();

        // Toggle existing layer on if it's currently off
        if (!this.model.isLayerOn(layer.id)) {
          this.model.toggleLayerOn(layer);
        }
      });
  },

  _handleRemoveFeature: function (feature) {
    this.removeFeatures(feature);
    this.model
      .fetchDisplaySettings()
      .bind(this)
      .then(function () {
        var layer = feature.layer();
        var layerIsOn = this.model.isLayerOn(layer.id);
        var isLastOnFeatureInLayer = layer.onFeatureCount(this.model) === 0;

        // Toggle layer off if last on feature in layer is deleted
        if (layerIsOn && isLastOnFeatureInLayer) {
          this.model.toggleLayerOn(layer);
        }
      });
  },
});

module.exports = MapView;
