/* eslint-disable */
let BaseParameterLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/parameterLayout.pug'),

  regions: {
    editParameterModal: '#edit-parameter-modal-container',
    parametersContainer: '#parameters-container',
  },

  events: {
    'click .edit-parameter-status': '_showModal',
  },

  onShow: function () {
    this._showParameterCollection();
    this._showEditParameterModalView();
  },

  _showModal() {
    this.modal.showModal();
  },
});

module.exports = BaseParameterLayoutView;
