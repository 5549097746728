import { SimpleUnits, UnitString } from 'mapistry-shared';

export type FlowLogUpload = {
  dateOfDischarge: string;
  flowReading?: string;
  flowReadingUnits?: UnitString;
  hoursOfDischarge?: string;
  monitoringLocationId: string;
  personReportingDischarge: string;
  rateOfDischarge?: string;
  rateOfDischargeUnits?: UnitString;
};

export const rateOfDischargeUnit = {
  cubicFeetPerSecond: `${SimpleUnits.CUBIC_FEET}/${SimpleUnits.SECONDS}`,
  cubicFeetPerMinute: `${SimpleUnits.CUBIC_FEET}/${SimpleUnits.MINUTES}`,
  cubicFeetPerHour: `${SimpleUnits.CUBIC_FEET}/${SimpleUnits.HOURS}`,
  cubicFeetPerDay: `${SimpleUnits.CUBIC_FEET}/${SimpleUnits.DAYS}`,
  cubicMetersPerSecond: `${SimpleUnits.CUBIC_METER}/${SimpleUnits.SECONDS}`,
  cubicMetersPerMinute: `${SimpleUnits.CUBIC_METER}/${SimpleUnits.MINUTES}`,
  cubicMetersPerHour: `${SimpleUnits.CUBIC_METER}/${SimpleUnits.HOURS}`,
  cubicMetersPerDay: `${SimpleUnits.CUBIC_METER}/${SimpleUnits.DAYS}`,
  gallonsPerSecond: `${SimpleUnits.GALLONS}/${SimpleUnits.SECONDS}`,
  gallonsPerMinute: `${SimpleUnits.GALLONS}/${SimpleUnits.MINUTES}`,
  gallonsPerHour: `${SimpleUnits.GALLONS}/${SimpleUnits.HOURS}`,
  gallonsPerDay: `${SimpleUnits.GALLONS}/${SimpleUnits.DAYS}`,
  millionGallonsPerSecond: `${SimpleUnits.MGAL}/${SimpleUnits.SECONDS}`,
  millionGallonsPerMinute: `${SimpleUnits.MGAL}/${SimpleUnits.MINUTES}`,
  millionGallonsPerHour: `${SimpleUnits.MGAL}/${SimpleUnits.HOURS}`,
  millionGallonsPerDay: `${SimpleUnits.MGAL}/${SimpleUnits.DAYS}`,
} as const;
