/* eslint-disable no-use-before-define */
import P from 'bluebird';

const maxUploadTries = 3;

/**
 * @param {object} awsDetails
 * @param awsDetails.accessKeyId
 * @param awsDetails.bucket
 * @param awsDetails.key
 * @param [awsDetails.secretAccessKey]
 * @param [awsDetails.sessionToken]
 * @param awsDetails.url
 * @param file
 * @param progressCb
 * @returns {Promise}
 */
export async function doMultipartUpload(awsDetails, file, progressCb) {
  const { accessKeyId, bucket, key, secretAccessKey, sessionToken } =
    awsDetails;

  const AWS = await import('aws-sdk');

  const s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
    sessionToken,
    region: 'us-east-1',
  });

  const startTime = new Date();
  return getMultipartUploadObject(s3, bucket, key)
    .then((multipart) => {
      const uploadId = multipart.UploadId;
      return uploadParts(s3, multipart, file, progressCb).then((multipartMap) =>
        completeMultipartUpload(s3, bucket, key, multipartMap, uploadId),
      );
    })
    .then(() => {
      const delta = (new Date() - startTime) / 1000;
      // eslint-disable-next-line no-console
      console.info('Completed upload in', delta, 'seconds');
    });
}

function getMultipartUploadObject(s3, bucket, key) {
  const multipartUploadParams = {
    Bucket: bucket,
    Key: key,
  };
  return new P((resolve, reject) => {
    s3.createMultipartUpload(multipartUploadParams, (mpErr, multipart) => {
      if (mpErr) {
        return reject(mpErr);
      }
      return resolve(multipart);
    });
  });
}

function completeMultipartUpload(s3, bucket, key, multipartMap, uploadId) {
  const doneParams = {
    Bucket: bucket,
    Key: key,
    MultipartUpload: multipartMap,
    UploadId: uploadId,
  };

  return new P((resolve, reject) => {
    /* eslint-disable no-console */
    s3.completeMultipartUpload(doneParams, (err, data) => {
      if (err) {
        console.log('An error occurred while completing the multipart upload');
        reject(err);
      } else {
        console.log('Final upload data:', data);
        resolve();
      }
    });
    /* eslint-enable no-console */
  });
}

function getOptimalChunkSizeForUploads(file) {
  const fiveMB = 1024 * 1024 * 5;

  // AWS has a 10k max part number size. So each part has to be at least
  // 1/10000th of the file
  const minimumUploadSize = Math.ceil(file.size / 10000);

  // Use 5MB unless the minimum has to be bigger
  return Math.max(fiveMB, minimumUploadSize);
}

/**
 * @param s3
 * @param {object} multipart
 * @param multipart.Bucket
 * @param multipart.Key
 * @param file
 * @param {() => any} progressCb
 * @returns {*}
 */
function uploadParts(s3, multipart, file, progressCb) {
  // eslint-disable-next-line no-console
  console.log('Got upload ID', multipart.UploadId);
  const multipartMap = { Parts: [] };
  const partParamsConfigs = [];
  const optimalUploadSize = getOptimalChunkSizeForUploads(file);

  // eslint-disable-next-line no-console
  console.log(`Optimal Upload Size is: ${optimalUploadSize} bytes`);

  // Grab each partSize chunk and upload it as a part
  for (
    let rangeStart = 0, partNum = 1;
    rangeStart < file.size;
    rangeStart += optimalUploadSize, partNum++
  ) {
    // let rangeEnd = Math.min(rangeStart + optimalUploadSize, file.length);
    partParamsConfigs.push({
      // Body: file.slice(rangeStart, rangeEnd),
      Bucket: multipart.Bucket,
      Key: multipart.Key,
      PartNumber: String(partNum),
      UploadId: multipart.UploadId,
    });
  }

  let partsDoneSoFar = 0;
  /* eslint-disable no-console */
  return P.map(
    partParamsConfigs,
    (partParams) => {
      const partNumber = parseInt(partParams.PartNumber, 10);
      const rangeStart = (partNumber - 1) * optimalUploadSize; // partNumber index starts at 1, so we need to subtract 1 first
      const rangeEnd = Math.min(rangeStart + optimalUploadSize, file.size);

      console.log(
        `Uploading part: # ${partNumber}, Range start: ${rangeStart}, Range end: ${rangeEnd}`,
      );

      /* eslint-disable no-param-reassign */
      partParams.Body = file.slice(rangeStart, rangeEnd);

      return uploadPart(s3, multipart, partParams, multipartMap).then(() => {
        partsDoneSoFar += 1;
        const progressSoFar = Math.round(
          (100 * partsDoneSoFar) / partParamsConfigs.length,
        );
        console.log(
          'progressSoFar / total = ',
          progressSoFar,
          `(${partsDoneSoFar}/${partParamsConfigs.length})`,
        );
        if (typeof progressCb === 'function') {
          progressCb(progressSoFar);
        }
      });
    },
    { concurrency: 5 },
  ).return(multipartMap);
  /* eslint-enable no-console */
}

function uploadPart(s3, multipart, partParams, multipartMap, tryNum = 1) {
  return new P((resolve, reject) => {
    /* eslint-disable no-console */
    s3.uploadPart(partParams, (multiErr, mData) => {
      if (multiErr) {
        console.log('multiErr, upload part error:', multiErr);
        if (tryNum < maxUploadTries) {
          console.log(`Retrying upload of part: # ${partParams.PartNumber}`);
          return resolve(
            uploadPart(s3, multipart, partParams, multipartMap, tryNum + 1),
          );
        }
        console.log('Failed uploading part: #', partParams.PartNumber);
        return reject(multiErr);
      }

      /* eslint-disable no-param-reassign */
      multipartMap.Parts[partParams.PartNumber - 1] = {
        ETag: mData.ETag,
        PartNumber: Number(partParams.PartNumber),
      };
      console.log('Completed part', partParams.PartNumber);
      console.log('mData', mData);
      return resolve();
    });
    /* eslint-enable no-console */
  });
}
