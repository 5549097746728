import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import APP from '../config';
import { Button } from './elements';

const ErrorPage404 = ({ fullPage = false }) => {
  // basically need to override the CSS that makes the .spill be 100vh height
  const className = cn('error-page-container', 'spill', {
    'no-full-height': !fullPage,
  });
  return (
    <div className={className}>
      <img alt="404" src={`${APP.assetsRoot}404_spill.svg`} />
      <div className="text-wrapper">
        <h2>404</h2>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h3>Oh no! Looks like you've gone down the wrong drain.</h3>
        <p>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          If you think you've reached this page in error, let us know. You can
          email us at&nbsp;
          <a
            className="mapistry-green dark-green-on-hover"
            href="mailto:help@mapistry.com"
          >
            help@mapistry.com
          </a>
          .
        </p>
        <Button className="margin-top-50">Return to Site Dashboard</Button>
      </div>
    </div>
  );
};

ErrorPage404.propTypes = {
  fullPage: PropTypes.bool,
};

ErrorPage404.defaultProps = {
  fullPage: false,
};
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ErrorPage404;
