import { connect } from 'react-redux';
import withProvider from '../../../withProvider';
import AddItemGroup from './AddItemGroup';

import { createEmissionItemGroupAction } from '../../../../actions/air';

const mapDispatchToProps = (dispatch) => ({
  createEmissionItemGroup: (projectId, group) =>
    dispatch(createEmissionItemGroupAction(projectId, group)),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(connect(null, mapDispatchToProps)(AddItemGroup));
