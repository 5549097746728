/* eslint-disable */
let RecordsSpinnerView = Backbone.Marionette.ItemView.extend({
  template: require('../dashboards/projects/content/custom/industrial/stormwater/records/templates/spinner.pug'),

  ui: {
    spinner: '#records-spinner',
  },

  initialize() {
    this.recordsSpinner = new Spinner({
      lines: 13,
      length: 20,
      width: 10,
      radius: 30,
      top: '200px',
    });
  },

  onShow() {
    this.recordsSpinner.spin(this.ui.spinner[0]);
  },
});

module.exports = RecordsSpinnerView;
