var pug = require("!../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (job_title, name) {
      pug_html = pug_html + "\u003Cdiv class=\"panel-heading\" id=\"basic-info-body\"\u003E\u003Cdiv class=\"panel-title\"\u003EBasic Information\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cform class=\"form-horizontal\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"col-xs-3 control-label\" for=\"user-name-profile-setting\"\u003EName\u003C\u002Flabel\u003E\u003Cdiv class=\"col-xs-9\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"user-name-profile-setting\" type=\"text\""+pug.attr("value", name, true, true)+" name=\"name\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"col-xs-3 control-label\" for=\"job-title-form-setting\"\u003EJob Title\u003C\u002Flabel\u003E\u003Cdiv class=\"col-xs-9\"\u003E\u003Cinput" + (" class=\"form-control\""+" id=\"job-title-form-setting\" type=\"text\""+pug.attr("value", job_title, true, true)+" name=\"job_title\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "job_title" in locals_for_with ?
        locals_for_with.job_title :
        typeof job_title !== 'undefined' ? job_title : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;