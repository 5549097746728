/* eslint-disable */
let apiCaller = require('../apiCaller');

let DocumentCollection = Backbone.Collection.extend({
  initialize(models, options = {}) {
    this.projectId = options.projectId;
    this.folderSlug = options.folderSlug;
    const baseUrl = this.url();
    this.model = Backbone.Model.extend({
      urlRoot: baseUrl,
      destroy: function (options) {
        const opts = { ...options, url: `${baseUrl}/${this.get('fileId')}` };
        return Backbone.Model.prototype.destroy.call(this, opts);
      },
    });
  },

  url: function () {
    return apiCaller.getBaseProjectDocumentUrl(this.projectId, this.folderSlug);
  },

  comparator: 'created_at',
});

module.exports = DocumentCollection;
