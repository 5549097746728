import BaseRouter from '../BaseRouter';
// eslint-disable-next-line import/default
import BackboneWrappersController from './BackboneWrappersController';

const BackboneWrappersRouter = BaseRouter.extend({
  controller: new BackboneWrappersController(),

  appRoutes: {
    'project-records/:projectId': 'projectRecords',
    'project-users/:projectId': 'projectUsers',
    'project-settings/:projectId': 'projectSettings',
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default BackboneWrappersRouter;
