import { Api } from '@monorepo/shared/apiClient';
import { MutationConfig, queryCache, useMutation } from 'react-query';
import {
  adminChangeOnboardingDocumentStatus,
  AdminChangeOnboardingDocumentStatusResponse,
} from '../adminOnboardingApiClient';
import { createAdminOnboardingSessionKey } from './createAdminOnboardingSessionKey';

type Input = {
  config?: MutationConfig<
    AdminChangeOnboardingDocumentStatusResponse,
    Api.ErrorResponse
  >;
};

const mutationFn = adminChangeOnboardingDocumentStatus;

export function useAdminChangeOnboardingDocumentStatus(input?: Input) {
  const config = input?.config;

  const mutationInfo = useMutation(mutationFn, {
    ...config,
    onSuccess: (res, variables) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryCache.invalidateQueries(createAdminOnboardingSessionKey(variables));
      return config?.onSuccess?.(res, variables);
    },
  });

  return mutationInfo;
}
