import React from 'react';
import { BulkUploadForOrganizationPage } from '../BulkUploadForOrganizationPage';
import { ProjectsUploadModal } from './ProjectsUploadModal';

export function ProjectBulkUpload() {
  const header = (
    <>
      <h1>Bulk Site Upload</h1>
      <p>
        <b>Organization</b> that you want to create sites for should already
        exist. Sites can be uploaded for one organization at a time. This way we
        can get all the information about this org, e.g. its tag types and
        users. To create a new organization go to the admin panel and create a
        new site for a new user.
      </p>
      <p>
        <b>Users</b> that you want to make primary admins should already exist
        and belong to a target organization. It can be achieved by adding a user
        to any site in that organization. By limiting users to only those who
        belong to an organization we can have a nice dropdown with their names.
      </p>
      <p>
        Currently we don&apos;t populate <b>site&apos;s geolocation</b> from its
        address when you upload them with this tool. It means that for{' '}
        <b>rain events</b> to work, a new site needs to have at least one
        location on the map.
      </p>
    </>
  );
  return (
    <BulkUploadForOrganizationPage
      header={header}
      ModalComponent={ProjectsUploadModal}
    />
  );
}
