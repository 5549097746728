var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (backgroundColor, color, customIconUrl, decorator, hidden, layerLabel, layerName, lineStyle, shape, type) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["legend-item",hidden], [false,true]), false, true)+pug.attr("style", pug.style(`color: ${color} !important;`), true, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["symbol-container",decorator], [false,true]), false, true)) + "\u003E\u003Ci" + (pug.attr("class", pug.classes(["fa","layer-symbol",'fa-' + shape], [false,false,true]), false, true)+pug.attr("style", pug.style(`color: ${color} !important;`), true, true)) + "\u003E\u003C\u002Fi\u003E";
if (customIconUrl) {
pug_html = pug_html + "\u003Cdiv class=\"custom-icon-container\"\u003E\u003Cimg" + (pug.attr("src", customIconUrl, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
if ((type === 'polyline')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["layer-symbol",decorator + ' ' + type], [false,true]), false, true)+pug.attr("style", pug.style(`border-top-style: ${lineStyle}; border-top-color: ${color} !important;`), true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
if ((type === 'polygon')) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["layer-symbol",type], [false,true]), false, true)+pug.attr("style", pug.style(`border-style: ${lineStyle} ; border-color: ${color} !important; background: ${backgroundColor} !important;`), true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ci class=\"fa fa-eye\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-eye-slash\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003Cspan" + (" class=\"layer-name\""+pug.attr("data-layer-name", layerName, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = layerLabel) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "backgroundColor" in locals_for_with ?
        locals_for_with.backgroundColor :
        typeof backgroundColor !== 'undefined' ? backgroundColor : undefined, "color" in locals_for_with ?
        locals_for_with.color :
        typeof color !== 'undefined' ? color : undefined, "customIconUrl" in locals_for_with ?
        locals_for_with.customIconUrl :
        typeof customIconUrl !== 'undefined' ? customIconUrl : undefined, "decorator" in locals_for_with ?
        locals_for_with.decorator :
        typeof decorator !== 'undefined' ? decorator : undefined, "hidden" in locals_for_with ?
        locals_for_with.hidden :
        typeof hidden !== 'undefined' ? hidden : undefined, "layerLabel" in locals_for_with ?
        locals_for_with.layerLabel :
        typeof layerLabel !== 'undefined' ? layerLabel : undefined, "layerName" in locals_for_with ?
        locals_for_with.layerName :
        typeof layerName !== 'undefined' ? layerName : undefined, "lineStyle" in locals_for_with ?
        locals_for_with.lineStyle :
        typeof lineStyle !== 'undefined' ? lineStyle : undefined, "shape" in locals_for_with ?
        locals_for_with.shape :
        typeof shape !== 'undefined' ? shape : undefined, "type" in locals_for_with ?
        locals_for_with.type :
        typeof type !== 'undefined' ? type : undefined));
    ;;return pug_html;};
module.exports = template;