/* eslint-disable */
var LocationCompositeView = Backbone.Marionette.CollectionView.extend({
  childView: require('./QuarterlyAverageRowView'),

  tagName: 'table',

  className: 'table',

  initialize: function (options = {}) {
    this.sectorData = options.sectorData;
  },

  childViewOptions: function () {
    return {
      sectorData: this.sectorData,
    };
  },
});

module.exports = LocationCompositeView;
