import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { GenericLogType } from 'mapistry-shared';
import { EmptyTabContent, TABLE_SETTINGS } from '../shared';
import { TextField } from '../../../elements';
import EmissionTrackingTable from '../shared/EmissionTrackingTable';
import RollingCalculationsRow from './RollingCalculationsRow';

const RollingCalculationsTable = (props) => {
  const {
    addRollingCalculation,
    calculatedValues,
    deleteRollingCalculation,
    formErrors,
    formSubmissionDraft,
    handleDeleteGroup,
    isLoading,
    itemGroups,
    loggedItems,
    logProjectId,
    onDragEnd,
    rollingCalculations,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const renderRow = (rollingCalculation, index, groupId) => (
    <RollingCalculationsRow
      calculatedValues={calculatedValues}
      deleteRollingCalculation={deleteRollingCalculation}
      formErrors={formErrors}
      groupId={groupId}
      loggedItems={loggedItems}
      logProjectId={logProjectId}
      rollingCalculation={rollingCalculation}
      rollingCalculationIdx={index}
      selectMenuPortalRef={selectMenuPortalRef}
      stageEdit={stageEdit}
    />
  );

  const renderRowWhileBeingDragged = (rollingCalculation) => (
    <TextField
      disabled
      controlled={false}
      defaultValue={rollingCalculation.name || '...dragging'}
    />
  );

  const hasRollingCalculations =
    rollingCalculations.length ||
    Object.keys(formSubmissionDraft.groups).some(
      (groupKey) => _get(formSubmissionDraft.groups, groupKey).length,
    );

  if (!hasRollingCalculations && !isLoading) {
    return (
      <EmptyTabContent
        title="Rolling Calculations"
        addItem={addRollingCalculation}
      />
    );
  }

  return (
    <EmissionTrackingTable
      addItem={addRollingCalculation}
      formErrors={formErrors}
      formSubmissionDraft={formSubmissionDraft}
      groupType={GenericLogType.ROLLING_CALCULATION}
      handleDeleteGroup={handleDeleteGroup}
      isLoading={isLoading}
      itemGroups={itemGroups}
      items={rollingCalculations}
      logProjectId={logProjectId}
      onDragEnd={onDragEnd}
      tableInfo={TABLE_SETTINGS.ROLLING_CALCULATIONS}
      renderRow={renderRow}
      renderRowWhileBeingDragged={renderRowWhileBeingDragged}
    />
  );
};

RollingCalculationsTable.propTypes = {
  addRollingCalculation: PropTypes.func.isRequired,
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteRollingCalculation: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  rollingCalculations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default RollingCalculationsTable;
