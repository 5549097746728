/* eslint-disable */
let SamplingEventParametersLayoutView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/samplingEventParameters.pug'),

  regions: {
    analysisDate: '#analysis-date-input',
    downloadButton: '#download-sampling-event',
    newParameterForm: '#new-sampling-event-parameter-form-container',
    parametersContainer: '#sampling-event-parameters-container',
  },

  initialize: function (options = {}) {
    this.projectModel = options.projectModel;
    this.samplingEventModel = options.samplingEventModel;
    this.samplingEventParameterCollection =
      options.samplingEventParameterCollection;
    this.hasSamplingLocations = options.hasSamplingLocations;
    this.swpppYear = options.swpppYear;
  },

  onShow: function () {
    if (!this.hasSamplingLocations) {
      let NoSamplingLocationsView = require('./NoSamplingLocationsView');
      return this.parametersContainer.show(new NoSamplingLocationsView());
    }

    let APP = require('../../../../../../../config');

    this.listenTo(this.samplingEventParameterCollection, 'add', function () {
      APP.vent.trigger('sampleEventParameter:add');
    });
    this.listenTo(
      this.samplingEventParameterCollection,
      'destroy',
      function () {
        APP.vent.trigger('sampleEventParameter:destroy');
      },
    );
    this.listenTo(this.samplingEventParameterCollection, 'reset', function () {
      APP.vent.trigger('sampleEventParameter:reset');
    });

    this._showAddOrDownloadParametersForm(
      this.projectModel,
      this.samplingEventParameterCollection,
      this.samplingEventModel.id,
    );
    this._showSampleEventParameters(
      this.projectModel,
      this.samplingEventParameterCollection,
      this.samplingEventModel.id,
    );
    this._showAnalysisDate(
      this.samplingEventModel.get('analysis_date'),
      this.samplingEventModel,
    );
  },

  _showAnalysisDate: function (analysisDate, samplingEventModel) {
    let AnalysisDateItemView = require('./EventDateItemView');
    let analysisDateItemView = new AnalysisDateItemView({
      model: samplingEventModel,
    });
    this.analysisDate.show(analysisDateItemView);
  },

  _showAddOrDownloadParametersForm: function (
    projectModel,
    samplingEventParameterCollection,
    samplingEventId,
  ) {
    let NewSamplingEventParameterItemView = require('./AddOrDownloadParametersItemView');
    let newSamplingEventParameterItemView =
      new NewSamplingEventParameterItemView({
        projectModel,
        samplingEventParameterCollection,
        samplingEventId,
      });

    this.newParameterForm.show(newSamplingEventParameterItemView);
  },

  _showSampleEventParameters: function (
    projectModel,
    samplingEventParameterCollection,
    samplingEventId,
  ) {
    let SamplingEventParameterCollectionView = require('./SamplingEventParameterCollectionView');
    let samplingEventParameterCollectionView =
      new SamplingEventParameterCollectionView({
        projectModel,
        samplingEventId,
        swpppYear: this.swpppYear,
        collection: samplingEventParameterCollection,
      });

    this.parametersContainer.show(samplingEventParameterCollectionView);
  },
});

module.exports = SamplingEventParametersLayoutView;
