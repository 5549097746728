/* eslint-disable */
const BluebirdPromise = require('bluebird');
// initialize big spinner
var bigSpinner = new Spinner({
  lines: 13,
  length: 20,
  width: 10,
  radius: 30,
  top: '100px',
});
var $bigSpinnerContainer = $('#loading-big');
var $mask = $('#loader-mask');

bigSpinner.show = function (doNotWaitForSpinnerToFinish) {
  bigSpinner.spin($bigSpinnerContainer[0]);

  if (doNotWaitForSpinnerToFinish) {
    return showMaskAndContainer();
  } else {
    return (
      showMaskAndContainer()
        // wait to make sure spinner is visible because bigSpinner.spin
        // does not have a callback
        .delay(100)
    );
  }

  function showMaskAndContainer() {
    return BluebirdPromise.all([
      showJqueryObj($mask),
      showJqueryObj($bigSpinnerContainer),
    ]);
  }
};

bigSpinner.hide = function () {
  bigSpinner.stop();
  $mask.hide();
  $bigSpinnerContainer.hide();
};

function showJqueryObj($jqueryObj) {
  return new BluebirdPromise(function (resolve) {
    // Show jquery object immediately (after 1ms)
    $jqueryObj.show(1, function () {
      resolve();
    });
  });
}

module.exports = bigSpinner;
