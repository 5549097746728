/* eslint-disable */
var SettingDashboardsController = require('./SettingDashboardsController');
var BaseRouter = require('../../BaseRouter');

var SettingDashboardsRouter = BaseRouter.extend({
  controller: new SettingDashboardsController(),

  appRoutes: {
    'dashboard/settings': 'goToDefaultRoute',
    'dashboard/settings/:settingSlug': 'showSettingDashboard',
    'dashboard/user/:userId/settings': 'goToDefaultRouteForUserNotLoggedIn',
    'dashboard/user/:userId/settings/:settingSlug':
      'showSettingsDashboardForSpecificUser',
  },
});

module.exports = SettingDashboardsRouter;
