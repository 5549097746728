/* eslint-disable */
let TooltipBehavior = require('./Tooltip');
let WarnBeforeDeleteBehavior = require('./WarnBeforeDelete');
let DisplayMessagesBehavior = require('./DisplayMessages');
let DisplayLoaderBehavior = require('./DisplayLoader');
let FocusModalInput = require('./FocusModalInput');
let ScrollbarBehavior = require('./Scrollbar');
let PopoverBehavior = require('./Popover');
let EnableDatepicker = require('./EnableDatepicker');
let EnableDatetimepicker = require('./EnableDatetimepicker');
let SelectPicker = require('./SelectPicker');

let behaviorsRegistry = function () {
  return {
    Tooltip: TooltipBehavior,
    WarnBeforeDelete: WarnBeforeDeleteBehavior,
    DisplayMessages: DisplayMessagesBehavior,
    DisplayLoader: DisplayLoaderBehavior,
    FocusModalInput: FocusModalInput,
    Scrollbar: ScrollbarBehavior,
    Popover: PopoverBehavior,
    EnableDatepicker: EnableDatepicker,
    EnableDatetimepicker: EnableDatetimepicker,
    SelectPicker: SelectPicker,
  };
};

module.exports = behaviorsRegistry;
