import { SaveState } from '@monorepo/shared/types/SaveState';
import { startOfToday } from 'date-fns';
import _get from 'lodash.get';
import { IntervalFrequency, UTCEquivalentOfLocal } from 'mapistry-shared';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addFlowLogReading,
  deleteFlowLogReading,
  editFlowLogReading,
  fetchFlowLogStatsAction,
  resetErrorStateAction,
} from '../../../../actions/wastewater';
import APP from '../../../../config';
import { goToMapsUrl } from '../../../../router';
import {
  delayedModalClose,
  isNullOrUndefined,
  isNumeric,
  isValidDate,
} from '../../../../utils';
import { ErrorType } from '../../../propTypes';
import withProvider from '../../../withProvider';

import EditFlowLogModal from './EditFlowLogModal';

class EditFlowLogModalContainer extends Component {
  constructor(props) {
    super(props);
    this.INITIAL_STATE = {
      formDraft: {
        dateOfDischarge: UTCEquivalentOfLocal(startOfToday()),
        flowLogReadings: [
          {
            key: uuidv4(),
            monitoringLocationId: null,
            flowReading: null,
            hoursOfDischarge: null,
          },
        ],
      },
      formErrors: {
        dateOfDischarge: null,
        displayable: [],
        flowLogReadings: [],
        personReportingDischarge: null,
      },
      flowReadingsToDelete: [],
      saveError: null,
      saveState: SaveState.CLEAN,
      showConfirmation: false,
      originalReportingUserId: null,
    };
    this.state = this.INITIAL_STATE;
    this.closeModal = this.closeModal.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const {
      actionError,
      flowLogReadings,
      flowReadingIdToEdit,
      resetErrorState,
      open,
    } = this.props;

    const { formErrors, saveState } = this.state;
    APP.isNavigationBlocked = saveState === SaveState.DIRTY;

    const userIsEditing =
      isNullOrUndefined(prevProps.flowReadingIdToEdit) &&
      !isNullOrUndefined(flowReadingIdToEdit);
    const actionHasErrored =
      isNullOrUndefined(prevProps.actionError) &&
      !isNullOrUndefined(actionError);
    const formFinishedSavingWithServerErrors =
      prevState.saveState === SaveState.SAVING &&
      saveState === SaveState.SAVED &&
      formErrors.displayable.length > 0;
    if (!prevProps.open && open && !userIsEditing) {
      this.setState(this.INITIAL_STATE);
    } else if (!prevProps.open && open && userIsEditing) {
      const flowReadingToEdit = flowLogReadings.find(
        ({ id }) => id === flowReadingIdToEdit,
      );
      const utcDate = new Date(flowReadingToEdit.dateOfDischarge);
      const dateOfDischarge = new Date();
      dateOfDischarge.setUTCFullYear(utcDate.getUTCFullYear());
      dateOfDischarge.setUTCMonth(utcDate.getUTCMonth());
      dateOfDischarge.setUTCDate(utcDate.getUTCDate());
      this.setState({
        ...this.INITIAL_STATE,
        formDraft: {
          dateOfDischarge,
          flowLogReadings: [
            {
              key: flowReadingToEdit.id,
              id: flowReadingToEdit.id,
              monitoringLocationId: flowReadingToEdit.monitoringLocationId,
              flowReading: flowReadingToEdit.flowReading,
              hoursOfDischarge: flowReadingToEdit.hoursOfDischarge,
            },
          ],
          personReportingDischarge: flowReadingToEdit.personReportingDischarge,
        },
        originalReportingUserId: flowReadingToEdit.personReportingDischarge,
      });
    } else if (actionHasErrored) {
      const dateOfDischargeError = actionError.message?.includes(
        'date of discharge',
      )
        ? actionError.message
        : null;
      const fieldThatErrored = actionError.message?.includes(
        'date of discharge',
      )
        ? 'dateOfDischarge'
        : null;
      this.setState(
        {
          formErrors: {
            ...formErrors,
            dateOfDischarge: dateOfDischargeError,
            displayable: !actionError.message
              ? formErrors.displayable
              : formErrors.displayable.concat({
                  id: 'actionError',
                  field: fieldThatErrored,
                  flowLogReadingIndex: null,
                  message: actionError.message,
                }),
          },
          // Clear error from store (after it's been copied to state)
          //  so that any future errored actions can get picked up for display.
        },
        resetErrorState,
      );
    } else if (formFinishedSavingWithServerErrors) {
      this.setState({
        saveState: SaveState.DIRTY,
      });
    } else if (flowLogReadings.length > prevProps.flowLogReadings.length) {
      // When a new flow log reading is saved, update it's id in the formDraft.
      const newReading = flowLogReadings.find(({ id }) =>
        prevProps.flowLogReadings.every(({ id: prevId }) => prevId !== id),
      );
      this.setState((state) => ({
        formDraft: {
          ...state.formDraft,
          flowLogReadings: state.formDraft.flowLogReadings.map((reading) => {
            if (
              reading.monitoringLocationId !== newReading.monitoringLocationId
            ) {
              return reading;
            }
            return {
              ...reading,
              id: newReading.id,
            };
          }),
        },
      }));
    }
  }

  addFlowReading() {
    const { formDraft } = this.state;
    const { flowLogReadings } = formDraft;
    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: {
        ...formDraft,
        flowLogReadings: [
          ...flowLogReadings,
          {
            key: uuidv4(),
            monitoringLocationId: null,
            flowReading: null,
            hoursOfDischarge: null,
          },
        ],
      },
    });
  }

  closeModal() {
    const { onClose } = this.props;
    this.setState(this.INITIAL_STATE, onClose);
  }

  removeFlowReading(idxToRemove) {
    const {
      formDraft,
      formErrors: { displayable, flowLogReadings: flowLogReadingsErrors },
      flowReadingsToDelete,
    } = this.state;
    const { flowLogReadings } = formDraft;

    const readingToRemove = flowLogReadings[idxToRemove];
    const newFlowReadingsToDelete = isNullOrUndefined(readingToRemove.id)
      ? flowReadingsToDelete
      : flowReadingsToDelete.concat(readingToRemove);

    const newFlowLogReadings = [...flowLogReadings];
    newFlowLogReadings.splice(idxToRemove, 1);
    const newFlowLogReadingsErrors = [...flowLogReadingsErrors];
    newFlowLogReadingsErrors.splice(idxToRemove, 1);

    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: {
        ...formDraft,
        flowLogReadings: newFlowLogReadings,
      },
      formErrors: {
        displayable: displayable.filter(
          ({ flowLogReadingIndex }) => flowLogReadingIndex !== idxToRemove,
        ),
        flowLogReadings: newFlowLogReadingsErrors,
      },
      flowReadingsToDelete: newFlowReadingsToDelete,
    });
  }

  saveForm() {
    const { formDraft, flowReadingsToDelete } = this.state;
    const { addReading, deleteReading, editReading } = this.props;

    const toUtcString = (date) =>
      new Date(date.toISOString().slice(0, 10)).toISOString();
    const toApiFormat = (readingToSave) => ({
      ...readingToSave,
      flowReading: Number.parseFloat(readingToSave.flowReading),
      hoursOfDischarge: isNullOrUndefined(readingToSave.hoursOfDischarge)
        ? null
        : Number.parseFloat(readingToSave.hoursOfDischarge),
      dateOfDischarge: toUtcString(formDraft.dateOfDischarge),
      personReportingDischarge: formDraft.personReportingDischarge,
    });

    const flowLogReadingsToAdd = formDraft.flowLogReadings.filter(
      (flowLogReading) => isNullOrUndefined(flowLogReading.id),
    );

    const flowLogReadingsToEdit = formDraft.flowLogReadings.filter(
      (flowLogReading) => !isNullOrUndefined(flowLogReading.id),
    );

    this.setState({ saveState: SaveState.SAVING });
    Promise.all([
      ...flowLogReadingsToAdd.map(({ key, ...flowLogReading }) =>
        addReading(toApiFormat(flowLogReading)),
      ),
      ...flowLogReadingsToEdit.map(({ id, key, ...flowLogReadingData }) =>
        editReading(id, toApiFormat(flowLogReadingData)),
      ),
      ...flowReadingsToDelete.map((flowReading) =>
        deleteReading(flowReading.id, toApiFormat(flowReading)),
      ),
    ])
      .then(() => {
        this.setState(
          {
            flowReadingsToDelete: [],
            saveState: SaveState.SAVED,
          },
          () => delayedModalClose(this.closeModal),
        );
      })
      .catch((error) => {
        this.setState({
          saveError: error,
          saveState: SaveState.DIRTY,
        });
      });
  }

  submitForm() {
    const formErrors = this.validateForm();
    this.setState(
      {
        formErrors,
        saveError: null,
      },
      () => {
        if (formErrors.displayable.length === 0) {
          this.saveForm();
        }
      },
    );
  }

  // nextFields: { [editField1]: nextValue1, [editField2]: nextValue2, ...}
  stageEdit(nextFields, idxToUpdate = null) {
    const {
      formDraft,
      formErrors: {
        dateOfDischarge,
        displayable,
        flowLogReadings: flowLogReadingsErrors,
      },
    } = this.state;
    const { flowLogReadings } = formDraft;
    const updatedFields = Object.keys(nextFields);

    let nextDraft;

    if (isNullOrUndefined(idxToUpdate)) {
      nextDraft = {
        ...formDraft,
        ...nextFields,
        flowLogReadings,
      };
    } else {
      const nextFlowLogReadings = [...flowLogReadings];
      nextFlowLogReadings[idxToUpdate] = {
        ...nextFlowLogReadings[idxToUpdate],
        ...nextFields,
      };
      nextDraft = {
        ...formDraft,
        flowLogReadings: nextFlowLogReadings,
      };
    }

    this.setState({
      saveState: SaveState.DIRTY,
      formDraft: nextDraft,
      formErrors: {
        dateOfDischarge: updatedFields.includes('dateOfDischarge')
          ? null
          : dateOfDischarge,
        displayable: displayable.filter(
          ({ field, flowLogReadingIndex }) =>
            flowLogReadingIndex !== idxToUpdate ||
            !updatedFields.includes(field),
        ),
        flowLogReadings: flowLogReadingsErrors.map(
          (flowLogReadingError, idx) => {
            if (idx !== idxToUpdate) {
              return flowLogReadingError;
            }
            const newFlowLogReadingError = updatedFields.reduce(
              (accum, updatedField) => {
                const { [updatedField]: _, ...rest } = flowLogReadingError;
                return rest;
              },
              {},
            );
            return newFlowLogReadingError;
          },
        ),
      },
    });
  }

  validateForm() {
    const {
      formDraft: { dateOfDischarge, flowLogReadings },
    } = this.state;
    const { monitoringLocations } = this.props;

    const dateOfDischargeValidations = [
      {
        id: 'dateOfDischargeRequired',
        isInvalid: (dateToCheck) => isNullOrUndefined(dateToCheck),
        field: 'dateOfDischarge',
        message: () => 'Date of discharge is required.',
      },
      {
        id: 'dateOfDischargeIsValid',
        isInvalid: (dateToCheck) =>
          !isNullOrUndefined(dateToCheck) && !isValidDate(dateToCheck),
        field: 'dateOfDischarge',
        message: () => 'Date of discharge must be a date.',
      },
    ];

    const errorRowName = (flowLogReading, idx) => {
      const location = monitoringLocations.find(
        ({ id }) => id === flowLogReading.monitoringLocationId,
      );
      const locationName = isNullOrUndefined(location)
        ? `Flow reading ${idx + 1}`
        : location.name;
      return locationName;
    };
    const flowLogReadingsValidations = [
      {
        id: 'flowReadingNumeric',
        isInvalid: (flowLogReading) =>
          !isNullOrUndefined(flowLogReading.flowReading) &&
          !isNumeric(flowLogReading.flowReading),
        field: 'flowReading',
        message: (flowLogReading, idx) =>
          `${errorRowName(flowLogReading, idx)}: the volume must be a number.`,
      },
      {
        id: 'hoursOfDischargeNumeric',
        isInvalid: (flowLogReading) =>
          !isNullOrUndefined(flowLogReading.hoursOfDischarge) &&
          !isNumeric(flowLogReading.hoursOfDischarge),
        field: 'hoursOfDischarge',
        message: (flowLogReading, idx) =>
          `${errorRowName(
            flowLogReading,
            idx,
          )}: the hours of discharge must be a number.`,
      },
      {
        id: 'monitoringLocationRequired',
        isInvalid: (flowLogReading) =>
          isNullOrUndefined(flowLogReading.monitoringLocationId),
        field: 'monitoringLocationId',
        message: (flowLogReading, idx) =>
          `${errorRowName(
            flowLogReading,
            idx,
          )}: monitoring location is required.`,
      },
      {
        id: 'flowReadingRequired',
        isInvalid: (flowLogReading) =>
          isNullOrUndefined(flowLogReading.flowReading),
        field: 'flowReading',
        message: (flowLogReading, idx) =>
          `${errorRowName(flowLogReading, idx)}: volume is required.`,
      },
    ];

    const dateOfDischargeErrors = dateOfDischargeValidations.reduce(
      (accum, { field, id, isInvalid, message }) => {
        if (isInvalid(dateOfDischarge)) {
          return {
            dateOfDischarge: message(),
            displayable: [
              ...accum.displayable,
              {
                field,
                id: `${id}`,
                flowLogReadingIndex: null,
                message: message(),
              },
            ],
          };
        }
        return accum;
      },
      { dateOfDischarge: null, displayable: [] },
    );
    return flowLogReadings.reduce(
      (accum, flowLogReading, idx) => {
        const applicableErrors = flowLogReadingsValidations.filter(
          ({ isInvalid }) => isInvalid(flowLogReading),
        );
        const displayableErrors = applicableErrors.map(
          ({ field, id, message }) => ({
            field,
            id: `${id}-${idx}`,
            flowLogReadingIndex: idx,
            message: message(flowLogReading, idx),
          }),
        );
        const flowLogReadingFieldErrors = applicableErrors.reduce(
          (errorsAccum, { field, message }) => ({
            ...errorsAccum,
            [field]: message,
          }),
          {},
        );
        return {
          ...accum,
          displayable: [...accum.displayable, ...displayableErrors],
          flowLogReadings: [
            ...accum.flowLogReadings,
            flowLogReadingFieldErrors,
          ],
        };
      },
      { ...dateOfDischargeErrors, flowLogReadings: [] },
    );
  }

  render() {
    const {
      formDraft,
      formErrors,
      saveError,
      saveState,
      showConfirmation,
      originalReportingUserId,
    } = this.state;
    const {
      flowReadingIdToEdit,
      isFetching,
      monitoringLocations,
      open,
      project,
    } = this.props;

    return (
      <EditFlowLogModal
        addReadingIsDisabled={
          formDraft.flowLogReadings.length === monitoringLocations.length ||
          !isNullOrUndefined(flowReadingIdToEdit)
        }
        formDraft={formDraft}
        formErrors={formErrors}
        // TODO replace goToMapsURL when React router is implemented
        goToMapsURL={() => goToMapsUrl(project.organizationId, project.id)}
        isFetching={isFetching}
        monitoringLocations={monitoringLocations}
        onAddFlowReading={() => this.addFlowReading()}
        onCloseErrorMessage={() => this.setState({ saveError: null })}
        onConfirmCancel={() => this.setState({ showConfirmation: false })}
        onConfirmClose={() =>
          this.setState({ showConfirmation: false }, this.closeModal)
        }
        onClose={() =>
          [SaveState.CLEAN, SaveState.SAVED].includes(saveState)
            ? this.closeModal()
            : this.setState({ showConfirmation: true })
        }
        onRemoveFlowReading={(idxToRemove) =>
          this.removeFlowReading(idxToRemove)
        }
        onSave={() => this.submitForm()}
        open={open}
        originalReportingUserId={originalReportingUserId}
        saveError={saveError}
        saveState={saveState}
        showConfirmation={showConfirmation}
        stageEdit={(nextFields, idxToUpdate) =>
          this.stageEdit(nextFields, idxToUpdate)
        }
      />
    );
  }
}
EditFlowLogModalContainer.defaultProps = {
  actionError: null,
  flowReadingIdToEdit: null,
  isFetching: false,
  monitoringLocations: [],
};

EditFlowLogModalContainer.propTypes = {
  actionError: ErrorType,
  addReading: PropTypes.func.isRequired,
  deleteReading: PropTypes.func.isRequired,
  editReading: PropTypes.func.isRequired,
  flowReadingIdToEdit: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  flowLogReadings: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetErrorState: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  monitoringLocations: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
    organizationId: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  actionError: state.wastewater.errorMessage,
  flowLogReadings: state.wastewater.flowLogReadings,
  isFetching: _get(state.wastewater, 'isFetching.flowLogReadings', false),
  project: state.project,
  monitoringLocations: state.wastewater.monitoringLocations,
});

const mapDispatchToProps = (dispatch) => {
  const refreshFlowLogStats = (dateOfDischarge, monitoringLocationId) => {
    const frequenciesToFetch = [
      IntervalFrequency.YEAR,
      IntervalFrequency.MONTH,
    ];
    return Promise.all(
      frequenciesToFetch.map((frequency) => {
        // TODO replace `startOf`/`endOf` with `date-fns`/Mapistry handrolled lib when
        // that decision is made.
        const startDate = moment
          .utc(dateOfDischarge)
          .startOf(frequency)
          .toISOString();
        const endDate = moment
          .utc(dateOfDischarge)
          .endOf(frequency)
          .toISOString();
        const query = {
          endDate,
          frequency,
          monitoringLocationId,
          startDate,
        };
        return dispatch(fetchFlowLogStatsAction(APP.projectId, query));
      }),
    );
  };
  return {
    addReading: (flowLogReading) =>
      dispatch(addFlowLogReading(APP.projectId, flowLogReading)).then(() =>
        refreshFlowLogStats(
          flowLogReading.dateOfDischarge,
          flowLogReading.monitoringLocationId,
        ),
      ),
    deleteReading: (id, flowLogReading) =>
      dispatch(deleteFlowLogReading(APP.projectId, id)).then(() =>
        refreshFlowLogStats(
          flowLogReading.dateOfDischarge,
          flowLogReading.monitoringLocationId,
        ),
      ),
    editReading: (id, flowLogReading) =>
      dispatch(editFlowLogReading(APP.projectId, id, flowLogReading)).then(() =>
        refreshFlowLogStats(
          flowLogReading.dateOfDischarge,
          flowLogReading.monitoringLocationId,
        ),
      ),
    resetErrorState: () => dispatch(resetErrorStateAction()),
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(EditFlowLogModalContainer),
);
