/* eslint-disable */
var APP = require('../config');

var RootLayoutView = Backbone.Marionette.LayoutView.extend({
  el: 'body',

  regions: {
    content: '#content-container',
  },

  events: {
    click: '_handleClick',
  },

  appendToBody: function (html) {
    this.$el.append(html);
  },

  addClass: function (cssClassName) {
    this.$el.addClass(cssClassName);
  },

  _handleClick: function (e) {
    APP.vent.trigger('body:clicked', e);
  },
});

module.exports = new RootLayoutView();
