import React from 'react';
import { OnboardingApi } from '../apiClient';
import { OnboardingDocumentStatusSelect } from './OnboardingDocumentStatusSelect';

type StatusFilter = OnboardingApi.OnboardingDocumentStatus | null;

interface OnboardingDocumentStatusFilterProps {
  filterValue: StatusFilter;
  onFilterChange: (statusFilter: StatusFilter) => void;
}

export function OnboardingDocumentStatusFilter(
  props: OnboardingDocumentStatusFilterProps,
) {
  const { filterValue, onFilterChange } = props;

  return (
    <div>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor="onboarding-document-status-filter"
        className="label-wrapper"
      >
        Status
      </label>
      <OnboardingDocumentStatusSelect
        id="onboarding-document-status-filter"
        value={filterValue}
        onChange={onFilterChange}
        isClearable
      />
    </div>
  );
}
