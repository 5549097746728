// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default {
  CALCULATED_VALUES: {
    COLUMN_LABELS: [
      'item name',
      'output units',
      'items used for calculation',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [17, 10, 50, null, null],
    COLUMN_GROWTH: ['05', null, null, null, null],
  },

  LOGGED_ITEMS: {
    COLUMN_LABELS: [
      'id',
      'item name',
      'data type',
      'units',
      'frequency',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [7, 17, 10, 10, 8, null, null],
    COLUMN_GROWTH: [null, '05', null, null, null, null],

    TOOLTIP_TEXTS: [
      'Use this area to identify the emission source for your facility. This could be an equipment or tank ID, or location of the emission source if there are multiple types of the same emission sources. (Optional)',
    ],
  },

  WATER_LOGGED: {
    COLUMN_LABELS: [
      'Discharge Location',
      'Parameters',
      'units',
      'frequency',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [24, 24, 12, 12, null, null],
    COLUMN_GROWTH: [null, '05', null, null, null],
  },

  ROLLING_CALCULATIONS: {
    COLUMN_LABELS: [
      'item name',
      'items used for calculations',
      'units',
      'calculation over time',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [17, 17, 7, 30, null, null],
    COLUMN_GROWTH: ['03', '03', null, null, null, null],
  },

  UNIT_CONVERSIONS: {
    COLUMN_LABELS: [
      'item name',
      'items used for calculations',
      'units to convert to',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [17, 17, 12, null, null],
    COLUMN_GROWTH: ['03', '03', null, null, null],
  },

  LIMIT_ITEMS: {
    COLUMN_LABELS: ['id', 'item name', 'limits', 'notes', 'actions'],
    COLUMN_WIDTHS: [7, 17, 55, null, null],
    COLUMN_GROWTH: [null, '04', '01', null],
  },

  WATER_LOG_LIMIT_ITEMS: {
    COLUMN_LABELS: [
      'Discharge Location',
      'Parameters name',
      'Status',
      'Limits and Benchmarks',
      'notes',
      'actions',
    ],
    COLUMN_WIDTHS: [20, 20, 10, 30, null, null],
    COLUMN_GROWTH: [null, null, '04', null, null],
  },

  FACTORS: {
    COLUMN_LABELS: ['item name', 'type', 'value', 'units', 'notes', 'actions'],
    COLUMN_WIDTHS: [17, 15, 12, 10, null, null],
    COLUMN_GROWTH: ['05', null, null, null, null, null],
  },

  ROW_HEIGHT: 65,

  // this is roughly the height of everything above the table
  // grossly adding this to get around scrolling as much as possible when there are no groups
  SPACE_ABOVE_TABLE: 412, //  in px
};
