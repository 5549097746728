var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (activity_represented, sector, sector_description, sic_code, subsector) {
      pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = sic_code) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = sector) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = sector_description) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = subsector) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = activity_represented) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }.call(this, "activity_represented" in locals_for_with ?
        locals_for_with.activity_represented :
        typeof activity_represented !== 'undefined' ? activity_represented : undefined, "sector" in locals_for_with ?
        locals_for_with.sector :
        typeof sector !== 'undefined' ? sector : undefined, "sector_description" in locals_for_with ?
        locals_for_with.sector_description :
        typeof sector_description !== 'undefined' ? sector_description : undefined, "sic_code" in locals_for_with ?
        locals_for_with.sic_code :
        typeof sic_code !== 'undefined' ? sic_code : undefined, "subsector" in locals_for_with ?
        locals_for_with.subsector :
        typeof subsector !== 'undefined' ? subsector : undefined));
    ;;return pug_html;};
module.exports = template;