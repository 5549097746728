/* eslint-disable */
let ParameterFrequencyItemView = require('./ParameterFrequencyItemView');
let NoParametersItemView = require('./NoParametersItemView');

let ParameterFrequencyCollectionView =
  Backbone.Marionette.CollectionView.extend({
    tagName: 'table',
    childView: ParameterFrequencyItemView,
    emptyView: NoParametersItemView,
  });

module.exports = ParameterFrequencyCollectionView;
