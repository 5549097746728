/* eslint-disable */
let apiCaller = require('../../../../apiCaller');

module.exports = Backbone.Collection.extend({
  initialize(models, options = {}) {
    this.projectId = options.projectId;
  },

  url() {
    return apiCaller.getProjectRainEventUrl(this.projectId);
  },

  comparator: 'storm_start_time',
});
