import { ApiError, BaseService } from './BaseService';

class MapService extends BaseService {
  getLayerArea(projectId, layerAreaSlug) {
    const url = `${this.apiUrl}/api/v2/${projectId}/maps/layer-area/${layerAreaSlug}`;
    return fetch(url, { credentials: 'include' }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to get layer area');
    });
  }

  setSelectedMap(projectId, mapId) {
    const url = `${this.apiUrl}/api/v2/projects/${projectId}/selected-map`;
    return fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({ mapId }),
    }).then((result) => {
      if (result.ok) {
        return result.json();
      }
      throw new ApiError('failed to set selected map');
    });
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MapService;
