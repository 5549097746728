import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash.get';

import withProvider from '../../../withProvider';
import { formattedCalendarDateFromEvent } from '../../../../utils/calendarEvents';
import { fetchMicroIntervalsAction } from '../../../../actions/calendar';

import { LoadingIndicator } from '../../../elements';
import RecordPanelListItemExpandable from '../RecordPanelListItemExpandable';
import RecordPanelCalendarChild from '../RecordPanelCalendarChild';

class RecordPanelCalendarChildren extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.fetchMicroIntervals = this.fetchMicroIntervals.bind(this);
  }

  fetchMicroIntervals() {
    const { fetchMicroIntervals, microIntervals } = this.props;
    if (!microIntervals.length) {
      fetchMicroIntervals();
      return this.setState(
        {
          isLoading: true,
        },
        () => {
          setTimeout(
            () =>
              this.setState({
                isLoading: false,
              }),
            500,
          );
        },
      );
    }
    return null;
  }

  render() {
    const {
      calendarName,
      event,
      fetchMicroIntervals,
      frequency,
      locationId,
      microIntervals,
      onDeleteAttachment,
      onEditAttachment,
      onFetchAttachment,
      parameterSlug,
      projectId,
    } = this.props;

    const { isLoading } = this.state;
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <RecordPanelListItemExpandable
        onClick={(isExpanded) => isExpanded && this.fetchMicroIntervals()}
        summary={
          <div className="w-100">
            <span>{formattedCalendarDateFromEvent(event)}</span>
            <span className="pl3">
              {isLoading && <LoadingIndicator isAbsolute={false} size={16} />}
            </span>
          </div>
        }
        details={microIntervals.map((microInterval) => (
          <RecordPanelCalendarChild
            calendarName={calendarName}
            event={microInterval}
            fetchMicroIntervals={fetchMicroIntervals}
            frequency={frequency}
            locationId={locationId}
            microIntervals={microIntervals}
            onDeleteAttachment={onDeleteAttachment}
            onEditAttachment={onEditAttachment}
            onFetchAttachment={onFetchAttachment}
            projectId={projectId}
            parameterSlug={parameterSlug}
          />
        ))}
      />
    );
  }
}

RecordPanelCalendarChildren.propTypes = {
  calendarName: PropTypes.string.isRequired,
  event: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }).isRequired,
  fetchMicroIntervals: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  microIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteAttachment: PropTypes.func.isRequired,
  onEditAttachment: PropTypes.func.isRequired,
  onFetchAttachment: PropTypes.func.isRequired,
  parameterSlug: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const microIntervals = _get(
    state.calendar.microIntervals,
    `${ownProps.frequency}`,
    [],
  ).filter(
    (interval) =>
      interval.start >= ownProps.event.start &&
      interval.end <= ownProps.event.end,
  );
  return {
    microIntervals,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchMicroIntervals: () =>
    dispatch(
      fetchMicroIntervalsAction({
        calendarName: ownProps.calendarName,
        end: ownProps.event.end,
        frequency: ownProps.frequency,
        projectId: ownProps.projectId,
        start: ownProps.event.start,
      }),
    ),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(RecordPanelCalendarChildren),
);
