/* eslint-disable */
// Views
var BaseModalView = require('../modals/BaseModalView');
var GenericModalView = require('../modals/GenericModalView');
var CreateLayerView = require('./CreateLayerView');

// Services
var APP = require('../config');

var CreateLayerModalView = BaseModalView.extend({
  render: function () {
    var currentUserArbiter = require('../permissions/currentUserArbiter');
    currentUserArbiter
      .hasProjectUpdatePermission(APP.projectId)
      .bind(this)
      .then(function (hasProjectUpdatePermission) {
        // render a modal view
        var genericModalView = new GenericModalView();
        genericModalView.render({ header: 'Create Layer' });

        if (!hasProjectUpdatePermission) {
          return genericModalView._showErrors(
            'You do not have permissions to edit this layer',
          );
        }

        // create a createLayer view
        var LayerModel = require('../models_collections/LayerModel');
        var createLayerView = new CreateLayerView({ model: new LayerModel() });

        this.listenTo(
          createLayerView,
          'error',
          genericModalView._showErrors.bind(genericModalView),
        );
        this.listenTo(
          createLayerView,
          'success',
          genericModalView.close.bind(genericModalView),
        );

        // append the create layer view to the modal
        genericModalView.appendView(createLayerView);

        // register this facilitator view to also be removed when modal is closed
        genericModalView.childViews.push(this);
      });

    return this;
  },
});

module.exports = CreateLayerModalView;
