/* eslint-disable */
let apiCaller = require('../../../../../../../apiCaller');
let errorView = require('../../../../../../../modals/errorView').singleton();
let infoView = require('../../../../../../../modals/infoView').singleton();
let router = require('../../../../../../../router');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/spccHeader.pug'),

  initialize(options = {}) {
    this.hasProjectUpdatePermission = options.hasProjectUpdatePermission;
    this.uploadSpccCollection = options.uploadSpccCollection;
    this.projectModel = options.projectModel;
    this.planName = options.planName;
  },

  ui: {
    $uploadSpccPdf: '#upload-spcc-pdf',
    $faCloudUpload: '.fa-cloud-upload',
    $faCloudDownload: '.fa-cloud-download',
    $faSpinner: '.fa-spinner',
  },

  templateHelpers() {
    let spccDocModel = null;
    this.uploadSpccCollection.models.forEach((doc) => {
      if (
        !spccDocModel ||
        new Date(spccDocModel.get('created_at')) <
          new Date(doc.get('created_at'))
      ) {
        spccDocModel = doc;
      }
    });

    return {
      hasProjectUpdatePermission: this.hasProjectUpdatePermission,
      downloadPdfUrl: spccDocModel ? spccDocModel.get('url') : '',
      spccUpload: spccDocModel,
      isSpccProject: this.projectModel.isSpccProject(),
      planName: this.planName,
    };
  },

  behaviors: {
    Tooltip: {},
  },

  events: {
    'click .download-spcc-plan': '_handleClickDownloadUserSuppliedSpcc',
    'click .download-user-supplied-pdf': '_handleClickDownloadUserSuppliedSpcc',
    'click .upload-spcc-plan': '_handleFileUpload',
    'click .upload-user-supplied-pdf': '_handleFileUpload',
    'change #upload-spcc-pdf': '_saveUploadedSpcc',
  },

  _handleClickDownloadUserSuppliedSpcc() {
    window.open(
      this.uploadSpccCollection
        .at(this.uploadSpccCollection.length - 1)
        .get('src'),
    );
  },

  _handleFileUpload() {
    this.ui.$uploadSpccPdf.click();
  },

  toggleButtons() {
    if (this.ui.$faSpinner.css('display') === 'none') {
      this.ui.$faSpinner.css('display', 'inline-block');
    } else {
      this.ui.$faSpinner.css('display', 'none');
    }

    this.ui.$faCloudUpload.toggle();
    this.ui.$faCloudDownload.toggle();
  },

  _saveUploadedSpcc() {
    let file = this.ui.$uploadSpccPdf.prop('files')[0];
    let data = new FormData();
    data.append('file', file);
    let options = { data: data, processData: false, contentType: false };
    this.toggleButtons();

    this.uploadSpccCollection
      .createPromise(null, options)
      .bind(this)
      .then(this.render.bind(this))
      .catch(() => {
        this.toggleButtons();
        let errorMsg =
          'There was an error on the server, and your ' +
          this.planName +
          ' could not be uploaded. Please try again or contact us if the problem persists.';
        errorView.showModal({ error: errorMsg });
      });
  },
});
