/* eslint-disable */
const APP = require('../../config');
const projectCollection =
  require('../../models_collections/projectCollection').singleton();
const mapCollection =
  require('../../models_collections/mapCollection').singleton();
const editTitlebarView = require('./editTitlebarView').singleton();

const TitlebarView = Backbone.Marionette.ItemView.extend({
  template: require('./titlebar.pug'),
  el: '#titlebar',

  templateHelpers: function () {
    var project = projectCollection.getCurrent();
    var map = mapCollection.get(project.get('selected_map'));

    return {
      imgSrc: APP.assetsRoot + 'ajax-loader.gif',
      projectName: project.get('name'),
      mapName: map.get('name'),
      siteAddress: project.getFullAddress(),
    };
  },

  ui: {
    $titlebarControl: '.title-bar-control',
    $uploadIcon: '.upload',
    $editTitlebarIcon: '.edit-titlebar',
    $logoImg: '#logo-img',
  },

  behaviors: {
    Tooltip: {},
  },

  events: {
    'click @ui.$uploadIcon': '_uploadLogoImage',
    'click @ui.$editTitlebarIcon': '_editTitlebar',
  },

  modelEvents: {
    change: 'render',
  },

  onRender: function () {
    this._displayLogoImage();
  },

  onDestroy() {
    console.log('onDestroy');
  },

  _displayLogoImage: function () {
    this.model
      .getImgSrc()
      .bind(this)
      .then(function (imgSrc) {
        this.ui.$logoImg.attr('src', imgSrc);
      });
  },

  /**
   * Open a modal for editing everything that has to do with the titlebar.
   */
  _editTitlebar: function (e) {
    e.preventDefault();
    editTitlebarView.showModal();
  },

  /**
   * Open a modal for uploading a different logo image.
   */
  _uploadLogoImage: function () {
    editTitlebarView.showModal();
  },
});

module.exports = TitlebarView;
