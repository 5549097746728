import ProjectRoutes from '@monorepo/old-web/js/components/routing/ProjectRoutes';
import {
  OnboardingAdminRoutes,
  OnboardingRoutes,
} from '@monorepo/onboarding/OnboardingRoutes';
import React from 'react';
import { Route, Router as ReactRouter, RouterProps } from 'react-router-dom';

// When adding a new route here you also need to modify public/js/routing/ReactBackboneRouter.js
function ReactRoutes(props: RouterProps) {
  const { history } = props;
  return (
    <ReactRouter history={history}>
      <Route path="/projects/:projectId" component={ProjectRoutes} />
      <Route path="/onboarding" component={OnboardingRoutes} />
      {/* Admin routes */}
      <Route path="/admin/onboarding" component={OnboardingAdminRoutes} />
    </ReactRouter>
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ReactRoutes;
