var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isHmbpChemicalsLocationFieldChecked) {
      pug_html = pug_html + "\u003Cdiv class=\"modal hmbp-chemicals-settings fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003EHazMat Chemicals Settings\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cp class=\"message\"\u003EError\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cform class=\"container-fluid\"\u003E\u003Cdiv\u003E\u003Clabel class=\"control-label\"\u003EChemical location should be\u003Cbr\u003E\u003Clabel class=\"radio-inline\"\u003E\u003Cinput" + (" type=\"radio\" name=\"location\" value=\"building_name\""+pug.attr("checked", isHmbpChemicalsLocationFieldChecked('building_name'), true, true)) + "\u003E\u003Cspan class=\"margin-left-5\"\u003EBuilding Names\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Clabel class=\"radio-inline\"\u003E\u003Cinput" + (" type=\"radio\" name=\"location\" value=\"feature_name\""+pug.attr("checked", isHmbpChemicalsLocationFieldChecked('feature_name'), true, true)) + "\u003E\u003Cspan class=\"margin-left-5\"\u003EMap Feature Names\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success btn-submit\" type=\"button\"\u003ESave changes\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isHmbpChemicalsLocationFieldChecked" in locals_for_with ?
        locals_for_with.isHmbpChemicalsLocationFieldChecked :
        typeof isHmbpChemicalsLocationFieldChecked !== 'undefined' ? isHmbpChemicalsLocationFieldChecked : undefined));
    ;;return pug_html;};
module.exports = template;