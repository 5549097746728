/* eslint-disable */
const projectStateConfig = require('mapistry-project-state-config');

let SamplingResultSummariesHeaderView = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/samplingResultSummariesHeader.pug'),

  regions: {
    modalContainer: '#sampling-events-modal-container',
  },

  behaviors: {
    Tooltip: {},
  },

  initialize: function (options = {}) {
    this.projectModel = options.projectModel;
    this.isDisabled = options.isDisabled;
  },

  onShow: function () {
    let modalView = this._getSamplingResultsModalView();
    this.delegateEvents({
      'click .show-full-sampling-results':
        this._handleClickShowFullSamplingResults.bind(this, modalView),
    });
    this.modalContainer.show(modalView);
  },

  _handleClickShowFullSamplingResults(modalView) {
    if (!this.isDisabled()) {
      modalView.showModal();
    }
  },

  _getSamplingResultsModalView: function () {
    let ModalView = require('../results/GenericSamplingResultsModal');
    return new ModalView({ projectModel: this.projectModel });
  },
});

module.exports = SamplingResultSummariesHeaderView;
