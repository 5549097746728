import React, { useCallback } from 'react';
import { Api } from '@monorepo/shared/apiClient';
import { AddButton } from '@monorepo/old-web/js/components/elements';
import { OnboardingApi } from '../apiClient';
import { OnboardingDocumentNameDisplay } from './OnboardingDocumentNameDisplay';

interface OnboardingDocumentUploadInputProps {
  documentRequest: OnboardingApi.OnboardingDocumentRequest;
  onClear: (documentRequestId: string) => void;
  onUpload: (documentRequestId: string, file: File) => void;
}

export function OnboardingDocumentUploadInput(
  props: OnboardingDocumentUploadInputProps,
) {
  const { documentRequest, onUpload, onClear } = props;

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.currentTarget.files;
      const file = fileList?.[0];
      if (!file) return;
      onUpload(documentRequest.id, file);
    },
    [onUpload, documentRequest.id],
  );

  const handleClear = useCallback(() => {
    onClear(documentRequest.id);
  }, [onClear, documentRequest.id]);

  const handleUploadButtonLabelClick = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => e.currentTarget.click(),
    [],
  );

  if (documentRequest.documentId) {
    return (
      <OnboardingDocumentNameDisplay
        name={documentRequest.documentName}
        status={documentRequest.status}
        downloadUrl={Api.documentDownloadUrl(documentRequest.documentId)}
        onClear={handleClear}
      />
    );
  }

  return (
    <>
      <input
        id={documentRequest.id}
        type="file"
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
      {/* Breaking ally here due to technical limitation in MUI */}
      {/* Keyboard control should still work */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={documentRequest.id}
        className="label-wrapper"
        onClick={handleUploadButtonLabelClick}
      >
        <AddButton type="button" color="secondary" size="small">
          Add Document
        </AddButton>
      </label>
    </>
  );
}
