/* eslint-disable */
/** Initialize the Backbone.Model for ErrorView
 *
 * Parameters:
 *    None
 *
 * Return:
 *    ErrorModel Backbone.Model
 */

module.exports = Backbone.Model.extend({
  /**
   * Initialize the model
   *
   * Parameters:
   *    options: Object
   *      error: string
   *        Error message to display
   *      text: HTML
   *        Text to display outside of error
   *
   * Return:
   *    None
   */
  initialize: function (options) {
    this.set({
      error: options.error,
      text: options.text,
      hidden: options.error ? '' : 'hidden',
    });
  },
});
