/* eslint-disable */
module.exports = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'list-styled',

  childView: require('./DMRRowView.js'),

  childViewOptions() {
    return { isDisabled: this.isDisabled };
  },

  initialize(options = {}) {
    this.isDisabled = options.isDisabled;
  },
});
