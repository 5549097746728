/* eslint-disable */

import moment from 'moment';

// Views
import { getFileUploadStatus } from '@monorepo/old-web/js/fileUpload/mapboxFileUploader';
var FeatureRowView = require('./FeatureRowView');
var confirmView = require('../../../modals/confirmView').singleton();
var errorView = require('../../../modals/errorView').singleton();
var EditLayerModalView = require('../../../layerManagement/EditLayerModalView');

// Collections
var mapCollection =
  require('../../../models_collections/mapCollection').singleton();
var featureCollection =
  require('../../../models_collections/featureCollection').singleton();

// Services
var configureTipsy = require('../../../lib/tipsyConfig');
var APP = require('../../../config');
var projectMapsService = require('../../services/projectMapsService');

var LayerRowView = Backbone.Marionette.ItemView.extend({
  template: require('./layerRow.pug'),

  events: {
    'click .layer-label': '_handleLayerClick',
    'click .layer-badge': '_badgeClicked',
    'click .edit': '_showEditLayerModal',
    'click .more-features': '_updateExpandedFeatureList',
    'click .delete': '_confirmDeleteLayer',
  },

  initialize: function () {
    this.listenTo(this.model, 'destroy', this._handleModelDestroy);
    this.listenTo(this.model, 'change:name', this.render);

    const mapboxChangesThatRequirePolling = [
      'change:mapbox_upload_error',
      'change:mapbox_finished_processing_at',
      'change:mapbox_tileset_id',
      'change:mapbox_filename',
    ].join(' ');

    this.listenTo(this.model, mapboxChangesThatRequirePolling, () => {
      if (
        !this.uploadStatusPollInterval &&
        this.model.isProcessingAerialImagery()
      ) {
        this._pollForUploadStatus();
        this.uploadStatusPollInterval = setInterval(
          () => this._pollForUploadStatus(),
          30 * 1000,
        );
      }
      this.render();
    });

    this.listenTo(
      mapCollection,
      'layerDisplaySetting:add',
      function (ldsModel) {
        if (ldsModel.get('layer_id') === this.model.id) {
          this.render();
        }
      },
    );
    this.listenTo(
      mapCollection,
      'featureDisplaySetting:add',
      function (fdsModel) {
        var feature = featureCollection.get(fdsModel.get('feature_id'));
        if (feature.layer().id === this.model.id) {
          this.render();
        }
      },
    );
    this.listenTo(mapCollection, 'change:layer_on', function (ldsModel) {
      if (ldsModel.get('layer_id') === this.model.id) {
        this.render();
      }
    });
    this.listenTo(mapCollection, 'displaySettings:fetched', this.render);
    this.listenTo(featureCollection, 'destroy', this._updateCounter);

    // Counters to help display large numbers of features
    this._expandStartIndex = 0;

    if (this.model.isProcessingAerialImagery()) {
      this._pollForUploadStatus();
      this.uploadStatusPollInterval = setInterval(
        () => this._pollForUploadStatus(),
        30 * 1000,
      );
    }
  },

  onBeforeDestroy() {
    if (this.uploadStatusPollInterval) {
      clearInterval(this.uploadStatusPollInterval);
    }
  },

  _pollForUploadStatus() {
    return getFileUploadStatus(this.model.get('mapbox_tileset_id')).then(
      (results) => {
        const startedAt = moment(
          this.model.get('mapbox_started_processing_at'),
        );
        const isMoreThan5HoursOld = moment().isAfter(startedAt.add(5, 'hours'));

        if (results.complete) {
          this.model.save({
            mapbox_finished_processing_at: new Date(),
            mapbox_tileset_slug: results.tileset,
            mapbox_upload_error: null,
          });
          clearInterval(this.uploadStatusPollInterval);
          this.render();
        } else if (results.error) {
          this.model.save({
            mapbox_upload_error: results.error,
            mapbox_tileset_slug: null,
          });
          clearInterval(this.uploadStatusPollInterval);
          this.render();
        } else if (isMoreThan5HoursOld) {
          this.model.save({
            mapbox_upload_error: 'Mapbox processing took too long',
            mapbox_tileset_slug: null,
          });
          clearInterval(this.uploadStatusPollInterval);
          this.render();
        }
      },
    );
  },

  templateHelpers: function () {
    var view = this;
    var model = this.model;
    return {
      isActive: function () {
        return view.isActive();
      },
      featureCount: function () {
        return (model && model.featureCount && model.featureCount()) || 0;
      },
      needsFileInformation: function () {
        return model && model.needsFileInformation();
      },
      isProcessing: function () {
        return model && model.isProcessingAerialImagery();
      },
      hasFailedUpload: function () {
        return model && model.hasFailedUpload();
      },
      fileUploadedSuccessfully: function () {
        return model && model.fileUploadedSuccessfully();
      },
    };
  },

  isActive: function () {
    return projectMapsService.getSelectedMap().isLayerOn(this.model.id);
  },

  onRender: function () {
    configureTipsy(this.$('[rel=tipsy]'));

    if (this.isActive() && this.model.get('expanded')) {
      this._expandFeatures();
    }
  },

  _displayErrors: function (errors) {
    var $errorNode = $('<ul>');

    _.each(errors, function (error) {
      $errorNode.append('<li>' + error + '</li>');
    });

    errorView.showModal({ error: $errorNode[0].outerHTML });
  },

  _showEditLayerModal: function (e) {
    e.preventDefault();
    var modalView = new EditLayerModalView({ model: this.model });
    modalView.render();
  },

  /**
   * Toggle the expand property for this layer.
   */
  _badgeClicked: function () {
    var selectedMap = projectMapsService.getSelectedMap();

    // Make sure the tool tip goes away, there seems to be a bug where
    // sometimes it sticks around
    this.$('.badge[rel=tipsy]').tipsy('hide');

    if (!selectedMap.isLayerOn(this.model.id)) {
      this.model.set('expanded', true);
      selectedMap.toggleLayerOn(this.model);
    } else {
      this.model.toggleExpand();
      this.render();
    }
  },

  /**
   * Put layer into active state
   */
  _activate: function () {
    this.$('.layer-container').addClass('active');
  },

  /**
   * Handles a layer click
   */
  _handleLayerClick: function () {
    projectMapsService.getSelectedMap().toggleLayerOn(this.model);
  },

  /**
   * Update the badge that shows the count of the number of features in this
   * layer.
   */
  _updateCounter: function () {
    var badgeVal = this.model.featureCount();
    var badge = this.$('#layer-count');

    badge.html(badgeVal);
  },

  /**
   * When there are too many features to list, button(s) will appear to allow
   * the user to view the next block of features (or previous block). The function
   * updates the displayed block of features.
   */
  _updateExpandedFeatureList: function (e) {
    e.preventDefault();
    this._expandStartIndex = $(e.target).data('startindex');
    this.render();
    this.trigger('scrollToTop');
  },

  /**
   * If the features is 'expanded', render the list of all features.
   */
  _expandFeatures: function () {
    // If the layer is expanded, add a list of all features in that layer
    // below the name of the layer.
    var featureIndex = 0;
    var featureLimit = 100;
    var thisLayerFeatures = this.model.features();

    if (this._expandStartIndex > 0) {
      // Add a button for showing more features that are listed previously
      // to the ones that are going to be shown here
      this.$('.previous')
        .find('.more-features')
        .data('startindex', this._expandStartIndex - featureLimit)
        .show();
    }

    _.each(
      thisLayerFeatures,
      function (feature) {
        if (
          feature.shouldShowInSidebar() &&
          featureIndex >= this._expandStartIndex &&
          featureIndex < this._expandStartIndex + featureLimit
        ) {
          var featureView = new FeatureRowView({ model: feature });

          this.$('.layer').append(featureView.render().el);
        }

        featureIndex++;
      },
      this,
    );

    if (thisLayerFeatures.length >= this._expandStartIndex + featureLimit) {
      // Add a button for showing more features
      this.$('.next')
        .find('.more-features')
        .attr('data-startindex', this._expandStartIndex + featureLimit)
        .show();
    }
  },

  /**
   * Updates what needs to be updated when layer model destroyed
   */
  _handleModelDestroy: function () {
    APP.vent.trigger('layer:destroyed', this.model.get('name'));
  },

  /**
   * Pops up modal to ask user to confirm deletion of this layer
   */
  _confirmDeleteLayer: function () {
    var options = {
      text: 'the ' + this.model.get('name') + ' layer?',
      confirmClicked: this.model.destroy.bind(this.model),
    };

    confirmView.showModal(options);
  },
});

module.exports = LayerRowView;
