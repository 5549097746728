/* eslint-disable */
var errorView = require('../../../../../../../../modals/errorView').singleton();
let RainLogCollection = require('../../../../../formLists/RainLogCollection');

module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/rainLog.pug'),

  regions: {
    modalContainer: '.modal-container',
    modalContainer2: '.modal-container2',
    stormEventList: '#storm-event-list',
  },

  events: {
    'click .create-new-storm-event': '_handleCreateStormEvent',
  },

  behaviors: {
    Tooltip: {},
  },

  initialize(options = {}) {
    this.project = options.project;
  },

  onShow() {
    let rainLogCollection = this._createRainLogCollection();

    return this._createRainLogCollectionView(rainLogCollection).then(
      this._getCreateNewRainEventModalView.bind(this),
    );
  },

  _createRainLogCollection() {
    return new RainLogCollection([], { projectId: this.project.id });
  },

  _getCreateNewRainEventModalView(rainLogCollection) {
    let StormEventUpdateModalView = require('./StormEventUpdateModalView');
    this.stormEventUpdateModalView = new StormEventUpdateModalView({
      rainLogCollection,
      projectId: this.project.id,
      projectModel: this.project,
    });

    this.modalContainer.show(this.stormEventUpdateModalView);
  },

  _handleEditStormEvent(model) {
    this.stormEventUpdateModalView.showModal(model);
  },

  _handleCreateStormEvent() {
    this._handleEditStormEvent(null);
  },

  _createRainLogCollectionView(rainLogCollection) {
    let APP = require('../../../../../../../../config');
    let RainLogCollectionView = require('./RainLogCollectionView');
    return rainLogCollection
      .fetchPromise()
      .bind(this)
      .then((collection) => {
        let rainLogCollectionView = new RainLogCollectionView({ collection });
        this.listenTo(
          APP.vent,
          'edit-or-delete-rain-event',
          this._handleEditStormEvent.bind(this),
        );
        this.stormEventList.show(rainLogCollectionView);
        return collection;
      });
  },
});
