var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isOptionSelected, key, label, options, showLabel, value) {
      if (showLabel) {
pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = label) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E";
}
pug_html = pug_html + "\u003Cselect" + (" class=\"js-states label-display form-control\""+pug.attr("name", key, true, true)+pug.attr("multiple", true, true, true)) + "\u003E";
// iterate options
;(function(){
  var $$obj = options;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.key, true, true)+pug.attr("selected", isOptionSelected(item), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.label) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E";
if ((value === 'other')) {
pug_html = pug_html + "\u003Clabel\u003EContainer&nbsp\u003Cspan\u003EDescription&nbsp\u003Ca class=\"badge small\" data-toggle=\"popover\" data-content=\"The description of this container must only contain letters. Commas, numbers, symbols, etc. are not allowed.\"\u003E?\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Flabel\u003E\u003Cinput class=\"new-attr-value form-control\" name=\"container_description\" value=\"\"\u003E";
}
    }.call(this, "isOptionSelected" in locals_for_with ?
        locals_for_with.isOptionSelected :
        typeof isOptionSelected !== 'undefined' ? isOptionSelected : undefined, "key" in locals_for_with ?
        locals_for_with.key :
        typeof key !== 'undefined' ? key : undefined, "label" in locals_for_with ?
        locals_for_with.label :
        typeof label !== 'undefined' ? label : undefined, "options" in locals_for_with ?
        locals_for_with.options :
        typeof options !== 'undefined' ? options : undefined, "showLabel" in locals_for_with ?
        locals_for_with.showLabel :
        typeof showLabel !== 'undefined' ? showLabel : undefined, "value" in locals_for_with ?
        locals_for_with.value :
        typeof value !== 'undefined' ? value : undefined));
    ;;return pug_html;};
module.exports = template;