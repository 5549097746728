import React from 'react';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import FormField from './FormField';
import TextField from '../../../elements/TextField';
import IconButton from '../../../elements/buttons/IconButton';
import MapistryTooltip from '../../../elements/MapistryTooltip';
import MapService from '../../../../services/MapService';

class MapAreaField extends FormField {
  getInputComponent(templateField, error) {
    return (
      <TextField
        className="map-area-field"
        required={templateField.required}
        value={this.getValue()}
        type="number"
        onChange={this.handleChange}
        error={error}
        endAdornment={this.mapAreaButton(templateField)}
      />
    );
  }

  mapAreaButton(templateField) {
    const tooltipText = this.getValue() ? 'Reset' : 'Set';

    return (
      <MapistryTooltip title={`${tooltipText} value from map`} placement="top">
        <div>
          <IconButton
            onClick={() => this.handleUpdateValueClick(templateField)}
          >
            <SvgIcon identifier="m-map-dashboard" />
          </IconButton>
        </div>
      </MapistryTooltip>
    );
  }

  async handleUpdateValueClick(templateField) {
    const { projectId, onChange, onError } = this.props;

    try {
      const parsedLayerTags = JSON.parse(templateField.locationLayerTags);
      const mapService = new MapService();
      const result = await mapService.getLayerArea(
        projectId,
        parsedLayerTags[0],
      );
      onChange(templateField.slug, result.area.toString());
    } catch {
      onError('Failed to get area. Please try again.');
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default MapAreaField;
