/* eslint-disable */
var _ = require('underscore');
var formatKey = require('./formatKey');
const BluebirdPromise = require('bluebird');

/**
 * Arbiter constructor
 */
function SharedPermissionsArbiter(userId, store) {
  this.userId = userId;
  this.store = store;
}

/**
 * Singleton storage (ie, maintain singleton per user)
 */
var singletons = {};
SharedPermissionsArbiter.getFor = function (userId, store) {
  singletons[userId] =
    singletons[userId] || new SharedPermissionsArbiter(userId, store);
  return singletons[userId];
};

/**
 * Prototype Methods
 */
var prototype = {
  setUserId: function (userId) {
    this.userId = userId;
  },

  clear: function () {
    if (!this.userId) {
      return;
    }
    this.store.clear(this.userId);
  },

  hasOrganizationPermission: function (organizationId, permissionType) {
    var key = formatKey.forUserPermissions(
      this.userId,
      organizationId,
      'organization',
    );
    return this._keyHas(key, permissionType);
  },

  hasOrganizationViewPermission: function (organizationId) {
    return this.hasOrganizationPermission(organizationId, 'ORGANIZATION_USER');
  },

  hasOrganizationUpdatePermission: function (organizationId) {
    return this.hasOrganizationPermission(
      organizationId,
      'ORGANIZATION_UPDATE',
    );
  },

  /**
   * Returns true if permission is found directly, or if it's granted via role inheritance
   */
  hasProjectPermission: function (projectId, permissionType) {
    if (!this.userId) {
      return BluebirdPromise.resolve(false);
    }
    var key = formatKey.forUserPermissions(this.userId, projectId, 'project');
    return this._keyHas(key, permissionType);
  },

  hasProjectSettingsPermission: function (projectId) {
    return this.hasProjectPermission(projectId, 'PROJECT_DETAILS_UPDATE');
  },

  hasProjectUpdatePermission: function (projectId) {
    return this.hasProjectPermission(projectId, 'PROJECT_DATA_UPDATE');
  },

  hasProjectViewPermission: function (projectId) {
    return this.hasProjectPermission(projectId, 'PROJECT_DETAILS_READ');
  },

  getGrantedProjectPermissions: function (projectId, permissions) {
    permissions = _.flatten([permissions]);

    var promises = [];
    permissions.forEach(function (permission) {
      promises.push(this.hasProjectPermission(projectId, permission));
    }, this);

    return BluebirdPromise.all(promises).then(function (results) {
      return _.object(permissions, results);
    });
  },

  _getKey: function (key) {
    return this.store.get(this.userId, key);
  },

  _keyHas: function (key, item) {
    return this._getKey(key).then(function (result) {
      return !!(result && _.contains(result.split(','), item));
    });
  },
};

_.extend(SharedPermissionsArbiter.prototype, prototype);

module.exports = SharedPermissionsArbiter;
