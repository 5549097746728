import React, { useLayoutEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import DragIcon from './DragIcon';

type DraggableWrapperProps = {
  index: number;
  uniqueId: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  style: {};
  afterRowRender: () => void;
};

export const DraggableWrapper: React.FC<DraggableWrapperProps> = (props) => {
  const { children, uniqueId, index, style, afterRowRender } = props;

  useLayoutEffect(() => {
    if (afterRowRender) {
      afterRowRender();
    }
  }, [afterRowRender]);

  return (
    <div style={style} key={uniqueId}>
      <Draggable draggableId={uniqueId} index={index}>
        {(dragProvided, snapshot) => (
          <div
            key={uniqueId}
            ref={dragProvided.innerRef}
            className={`table-row__animation-wrapper draggable_default-cursor ${
              snapshot.isDragging ? 'opacity-50 shadow-5' : ''
            }`}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
          >
            <div className="table-row__grab-target-container">
              <DragIcon />
              {children}
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};
