/* eslint-disable */
// Collections
var ProjectCollection = require('./../models_collections/projectCollection');
var MapCollection = require('./../models_collections/mapCollection');
var LayerCollection = require('./../models_collections/layerCollection');
var FeatureCollection = require('./../models_collections/featureCollection');
var MarkerStyleCollection = require('./../models_collections/styles/markerStyleCollection');
var PolylineStyleCollection = require('./../models_collections/styles/polylineStyleCollection');
var AerialimageryStyleCollection = require('./../models_collections/styles/aerialimageryStyleCollection');
var PolygonStyleCollection = require('./../models_collections/styles/polygonStyleCollection');
var IconCollection = require('./../models_collections/iconCollection');
var TitlebarCollection = require('./../models_collections/titlebarCollection');
let errors = require('../errors');

module.exports = {
  load: function () {
    var fetchOptions = { silent: true, reset: true };

    // Load all our data before initializing the app
    return ProjectCollection.fetchSingleton(fetchOptions)
      .then(() => {
        var project = ProjectCollection.singleton().getCurrent();
        if (!project) {
          throw new errors.UnauthorizedError(
            'User does not have permission to access this project map',
          );
        }
      })
      .then(() =>
        Promise.all([
          FeatureCollection.fetchSingleton(fetchOptions),
          LayerCollection.fetchSingleton(fetchOptions),
          MarkerStyleCollection.fetchSingleton(fetchOptions),
          PolylineStyleCollection.fetchSingleton(fetchOptions),
          PolygonStyleCollection.fetchSingleton(fetchOptions),
          AerialimageryStyleCollection.fetchSingleton(fetchOptions),
          IconCollection.fetchSingleton(fetchOptions),
          MapCollection.fetchSingleton(fetchOptions),
          TitlebarCollection.fetchSingleton(fetchOptions),
        ]),
      )
      .then(fetchDisplaySettings)
      .catch(function (err) {
        throw err;
      });

    function fetchDisplaySettings() {
      var project = ProjectCollection.singleton().getCurrent();
      var selectedMap = MapCollection.singleton().getSelectedMap(project);

      return selectedMap.fetchDisplaySettings(selectedMap);
    }
  },
};
