/* eslint-disable */
var CarouselPhotosCollectionView = require('../../../../../../../../photoCarousel/CarouselPhotosCollectionView');
module.exports = Backbone.Marionette.LayoutView.extend({
  template: require('./templates/rainLogPhotos.pug'),

  initialize(options = {}) {
    this.photoList = options.photoList;
  },

  behaviors: {
    DisplayLoader: {},
  },

  ui: {
    $uploadRainLogPhotoInput: 'input#upload-rain-log-photo',
    $btnSubmit: 'button',
    $carousel: '.carousel',
    $rainLogCarousel: '#rain-log-carousel',
  },

  regions: {
    carouselPhotos: '#carousel-photos',
  },

  events: {
    'click #upload-rain-log-photo-btn': '_handleUploadPhoto',
    'change @ui.$uploadRainLogPhotoInput': '_saveUploadedPhoto',
  },

  onRender() {
    if (this.photoList.length) {
      this.ui.$rainLogCarousel.show();
    } else {
      this.ui.$rainLogCarousel.hide();
    }
  },

  onShow() {
    this.ui.$carousel.carousel('pause');
    this.carouselPhotos.show(
      new CarouselPhotosCollectionView({ collection: this.photoList }),
    );
  },

  _handleUploadPhoto() {
    this.ui.$uploadRainLogPhotoInput.click();
  },

  _saveUploadedPhoto() {
    var file = this.ui.$uploadRainLogPhotoInput.prop('files')[0];
    var data = new FormData();
    data.append('file', file);
    let options = { data, processData: false, contentType: false, wait: true };

    this.triggerMethod('ShowLoader');

    return this.photoList
      .createPromise(null, options)
      .bind(this)
      .then(() => this.photoList.fetchPromise())
      .then(() => {
        this.ui.$rainLogCarousel.show();
        this.ui.$carousel.carousel(this.photoList.length - 1);
      })
      .catch(
        this.triggerMethod.bind(
          this,
          'ShowError',
          'There was an error saving your photo.',
        ),
      )
      .finally(this.triggerMethod.bind(this, 'ResetLoader'));
  },
});
