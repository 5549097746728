var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (name, url) {
      pug_html = pug_html + "\u003Coption" + (" class=\"custom-icon\""+pug.attr("data-content", '<img src=' + url + '> ' + name, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }.call(this, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "url" in locals_for_with ?
        locals_for_with.url :
        typeof url !== 'undefined' ? url : undefined));
    ;;return pug_html;};
module.exports = template;