/* eslint-disable */
let moment = require('moment');

let EventDateItemView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/eventDate.pug'),

  templateHelpers() {
    let eventDate = this.model.has('event_date')
      ? moment(this.model.get('event_date')).utc().format('YYYY-MM-DD')
      : '';
    return { eventDate };
  },
});

module.exports = EventDateItemView;
