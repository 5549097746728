/* eslint-disable */
var DropdownTabView = require('./DropdownTabView');

var BaseTabView = Backbone.Marionette.CompositeView.extend({
  tagName: 'li',

  attributes: {
    role: 'presentation',
  },

  className: function () {
    return this.model.collection.isGrouped && this.model.collection.isGrouped()
      ? 'dropdown'
      : '';
  },

  template: require('./templates/tab.pug'),

  ui: {
    $tab: 'a',
  },

  regions: {
    tabsDropdownMenu: '#tabs-dropdown-menu',
  },

  events: {
    click: '_handleClick',
    'show.bs.tab': '_handleShowTab',
  },

  childView: DropdownTabView,

  childViewContainer: 'ul',

  templateHelpers() {
    let dropdown = this._isGrouped();
    return { dropdown };
  },

  onShow() {
    if (this._isGrouped()) {
      if (!this.children.length) {
        return;
      }
      this.children.findByIndex(0).activate();
    }
  },

  activate() {
    if (this._isGrouped()) {
      if (!this.collection.length) {
        return;
      }
      this.collection.findByIndex(0).activate();
    } else {
      this.ui.$tab.tab('show');
    }
  },

  _handleClick() {
    if (!this._isGrouped()) {
      this.ui.$tab.tab('show');
    }
  },

  _isGrouped() {
    return this.model.collection.isGrouped && this.model.collection.isGrouped();
  },

  _handleShowTab: function (e) {
    this.trigger('show:tab', e);
  },
});

module.exports = BaseTabView;
