function extractLeaderlineOptions(styleModel) {
  return {
    markerLatLng: styleModel.get('geography'),
    weight: styleModel.get('leaderline_weight'),
    opacity: styleModel.get('leaderline_opacity'),
    fillOpacity: 1.0,
    color: styleModel.get('leaderline_color'),
  };
}

module.exports = {
  extractLeaderlineOptions,
};
