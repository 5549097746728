import { SheetConfig } from '@flatfile/api/api';
import { Api } from '@monorepo/shared/apiClient';
import { isMultiErrorResponse } from '@monorepo/shared/apiClient/httpClient';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  getSingleSelectColumnSetting,
  getTextColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { uniqueConstraintSetting } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileSettingsHelpers';
import { validateEmailAddress } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import { valueOrUndefinedIfEmpty } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useOrganizationProjects } from '@monorepo/shared/hooks/useOrgProjects';
import {
  AnalyticsRoles,
  ProjectPermissionRoles,
  analyticsRoleLabels,
  permissionRoleLabels,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';

enum uploadFieldKeys {
  analyticsRole = 'analyticsRole',
  email = 'email',
  name = 'name',
  projectId = 'projectId',
  role = 'role',
}

type UserUploadRecord = {
  [uploadFieldKeys.analyticsRole]?: AnalyticsRoles;
  [uploadFieldKeys.name]?: string;
  [uploadFieldKeys.email]: string;
  [uploadFieldKeys.projectId]: string;
  [uploadFieldKeys.role]: ProjectPermissionRoles;
};

const roleOptions = Object.values(ProjectPermissionRoles).map((role) => ({
  label: permissionRoleLabels[role],
  value: role,
}));
const analyticsRoleOptions = Object.values(AnalyticsRoles).map((role) => ({
  label: analyticsRoleLabels[role],
  value: role,
}));

type UsersUploadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onError: (saveError: Api.MultiErrorResponseData) => void;
  onSuccess: () => void;
  organizationId: string;
};

export function UsersUploadModal(props: UsersUploadModalProps) {
  const { isOpen, onClose, onError, onSuccess, organizationId } = props;

  const { projects, isLoading } = useOrganizationProjects({
    organizationId,
  });
  const projectOptions = useMemo(() => {
    if (!projects) return [];
    return projects
      .map((u) => ({
        label: u.name,
        value: u.id,
      }))
      .sort((a, b) =>
        a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1,
      );
  }, [projects]);

  const workbook = useMemo<WorkbookConfig>(() => {
    const fields: SheetConfig['fields'] = [
      getTextColumnSetting({
        label: 'Email',
        key: uploadFieldKeys.email,
        isRequired: true,
      }),
      getTextColumnSetting({
        label: 'User Name',
        key: uploadFieldKeys.name,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Project Name',
          key: uploadFieldKeys.projectId,
          isRequired: true,
        },
        projectOptions,
      ),
      getSingleSelectColumnSetting(
        {
          label: 'Role',
          key: uploadFieldKeys.role,
          isRequired: true,
        },
        roleOptions,
      ),
      getSingleSelectColumnSetting(
        {
          label: 'Analytics Role',
          key: uploadFieldKeys.analyticsRole,
        },
        analyticsRoleOptions,
      ),
    ];

    // declaring as a const before returning for better type checking
    const workbookConfig: WorkbookConfig = {
      name: 'Upload users',
      sheets: [
        {
          name: 'Users',
          slug: 'upload-users',
          fields,
          constraints: [
            uniqueConstraintSetting('User once per email per project', [
              uploadFieldKeys.email,
              uploadFieldKeys.projectId,
            ]),
          ],
        },
      ],
    };

    return workbookConfig;
  }, [projectOptions]);

  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => validateEmailAddress(uploadFieldKeys.email, record),
    [],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: UserUploadRecord[]) => {
      const userResults = results.map((result) => ({
        ...result,
        name: valueOrUndefinedIfEmpty(result[uploadFieldKeys.name]),
        analyticsRole: valueOrUndefinedIfEmpty(
          result[uploadFieldKeys.analyticsRole],
        ),
      }));

      await Api.saveUsersBulk(userResults);
      onSuccess();
      const noun = userResults.length === 1 ? 'User' : 'Users';
      return `Successfully uploaded ${userResults.length} ${noun}!`;
    },
    [onSuccess],
  );

  const onSubmitError = useCallback(
    (error: unknown) => {
      if (isMultiErrorResponse(error)) {
        onError(error.data);
      }
    },
    [onError],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ organizationId }),
    [organizationId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      customErrorMessage="Close the upload modal to see more details."
      onSubmitError={onSubmitError}
      recordHookCallback={recordHookCallback}
      showTableForManualInput
      workbook={workbook}
    />
  );
}
