/* eslint-disable */
let apiCaller = require('../../../../../../apiCaller');

module.exports = Backbone.Collection.extend({
  model: require('./HmbpUpdateModel'),

  url() {
    return apiCaller.getHmbpUpdatesWithQueryParamsUrl(
      this.projectId,
      this.startDate,
      this.endDate,
    );
  },

  urlWithoutQueryParams() {
    return apiCaller.getHmbpUpdatesUrl(this.projectId);
  },

  initialize(models, options = {}) {
    this.project = options.project;
    this.projectId = options.projectId;
    this.startDate = options.startDate;
    this.endDate = options.endDate;
  },
});
