/* eslint-disable */
const _ = require('underscore');
const ChemicalTextInputView = require('./ChemicalTextInput/ChemicalTextInputView');
const ChemicalSelectView = require('./ChemicalSelectView/ChemicalSelectView');
const ContainerTypeSelectView = require('./ContainerTypeSelectView/ContainerTypeSelectView');

const ChemicalInputListView = Backbone.Marionette.CollectionView.extend({
  tagName: 'fieldset',

  getChildView(field) {
    if (field.get('key') === 'container_type') {
      return ContainerTypeSelectView;
    } else if (field.get('type') === 'select') {
      return ChemicalSelectView;
    } else {
      return ChemicalTextInputView;
    }
  },

  childViewOptions() {
    return this.options;
  },
});

module.exports = ChemicalInputListView;
