/* eslint-disable */
const apiCaller = require('../apiCaller');

let ProjectSettingsModel = Backbone.Collection.extend({
  initialize(models, options = {}) {
    this.projectId = options.projectId;
  },

  url: function () {
    return apiCaller.getProjectSettingsUrl(this.projectId);
  },

  save() {
    throw Error('Saving frequency settings model is not implemented.');
  },

  comparator: 'settingKey',
});

module.exports = ProjectSettingsModel;
