/* eslint-disable */
/* eslint-disable @typescript-eslint/no-var-requires */
import { permissionRoleLabels, ProjectPermissionRoles } from 'mapistry-shared';

const RelatedUsersSingleSelectView = require('../../../../../../formWidgets/RelatedUsersSingleSelectView');
const AlertMixin = require('../../../../../../mixins/views/AlertMixin');
const projectUserDataProvider = require('../projectUserDataProvider');
const PendoManager = require('../../../../../../managers/pendoManager');

const InviteUsersFormLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/inviteUsersForm.pug'),

  templateHelpers: {
    availableRoles: Object.values(ProjectPermissionRoles),
    roleLabels: permissionRoleLabels,
    defaultSelectedRole: ProjectPermissionRoles.PROJECT_CONTRIBUTOR,
  },

  ui: {
    $invitedUserName: '.invited-user-name',
    $invitedUserEmail: '.invited-user-email',
    $inviteHelp: '.invite-help',
    $projectRole: '#project-invitation-role',
    $invitedUserEmail: '.invited-user-email',
  },

  events: {
    'click #send-project-invitation': '_sendProjectInvitation',
  },

  regions: {
    singleSelectContainer: '.single-select-container',
  },

  initialize(options = {}) {
    this.projectUserCollection = options.projectUserCollection;
    this.projectModel = options.projectModel;
  },

  onShow() {
    const organizationId = this.projectModel.get('organization_id');
    this._relatedUsersSingleSelectView = new RelatedUsersSingleSelectView({
      organizationId,
    });
    this._enablePopover();
    this._activateSelectWithSuggestedUsers();
    this.singleSelectContainer.show(this._relatedUsersSingleSelectView);
  },

  _enablePopover() {
    this.ui.$inviteHelp.popover({
      // eslint-disable-next-line global-require
      content: require('./templates/rolesHelp.pug'),
      html: true,
    });
  },

  _activateSelectWithSuggestedUsers() {
    const emailsToExclude = this.projectUserCollection.pluck('userEmail');
    this._relatedUsersSingleSelectView.activate(emailsToExclude);

    this.listenTo(
      this._relatedUsersSingleSelectView,
      'select:user',
      this._setName,
    );
    this.listenTo(
      this._relatedUsersSingleSelectView,
      'unselect:user',
      this._clearName,
    );
  },

  _setName(user) {
    const userName = user.userName;
    this.ui.$invitedUserName.val(userName);

    if (userName !== 'Invited User') {
      this.ui.$invitedUserName.prop('disabled', true);
    }
  },

  _clearName() {
    this.ui.$invitedUserName.val('');
    this.ui.$invitedUserName.prop('disabled', false);
  },

  _sendProjectInvitation(e) {
    e.preventDefault();

    this._clearAlerts();
    // eslint-disable-next-line no-undef
    const $btn = $(e.target).button('loading');
    const singleSelectValues = this._relatedUsersSingleSelectView.getValue();
    const email = singleSelectValues[0];
    const roleToApply = this.ui.$projectRole.val();
    const name = this.ui.$invitedUserName.val();

    const invitedUser = {
      userName: name,
      userRole: roleToApply,
      userEmail: email,
      projectId: this.projectModel.id,
      projectName: this.projectModel.get('displayName'),
    };

    projectUserDataProvider
      .saveProjectInvitation(this.projectModel.id, email, roleToApply, name)
      .then(() => {
        this._trackUserInvitationEventInPendo(invitedUser);
        this._relatedUsersSingleSelectView.clear();
        this._clearName();
        this.ui.$invitedUserEmail.val('');
        this._activateSelectWithSuggestedUsers();
        this._showSuccess(
          'Invitation sent. An email will be sent within 10 minutes.',
        );
        this.trigger('sendInvite:success');
      })
      .catch((err) => {
        const status = err?.status;
        const responseJson = err?.responseJSON;
        if (status === 403) {
          this._showErrors(
            'Only Site Administrators can invite users to this project.',
          );
        } else if (responseJson?.message && responseJson.errors) {
          this._showErrors(
            `${responseJson.message}: ${responseJson.errors.join('; ')}.`,
          );
        } else if (status === 422 && err?.responseText) {
          this._showErrors(err.responseText);
        } else {
          this._showErrors(
            `Oh no! Our carrier pigeon couldn't deliver your invite. Please try again later or contact Mapistry customer service.`,
          );
        }
      })
      .finally(() => {
        $btn.button('reset');
      });
  },

  _trackUserInvitationEventInPendo(invitedUser) {
    const trackType = 'Email_Invite';
    projectUserDataProvider
      .getInvitedUserInfo(invitedUser.userEmail)
      .then((userInfo) => {
        // eslint-disable-next-line no-undef
        const user = _.first(userInfo);
        // eslint-disable-next-line no-param-reassign
        invitedUser.id = user.id;
      })
      .catch(() => {
        this._showErrors('Error retrieving user info by email.');
      })
      .finally(() => {
        PendoManager.trackCustomEvent(trackType, invitedUser);
      });
  },
});

// eslint-disable-next-line no-undef
InviteUsersFormLayoutView.prototype = _.extend(
  {},
  AlertMixin,
  InviteUsersFormLayoutView.prototype,
);

module.exports = InviteUsersFormLayoutView;
