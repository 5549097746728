/* eslint-disable */
var layerCollection =
  require('../../../models_collections/layerCollection').singleton();
var LayerRowView = require('./layerRowView');

var LayerListView = Backbone.Marionette.CollectionView.extend({
  className: 'sidebar-list',
  collection: layerCollection,
  childView: LayerRowView,
  behaviors: {
    Scrollbar: {},
  },
});

module.exports = LayerListView;
