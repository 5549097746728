/* eslint-disable */
var APP = require('../config');
var bigSpinner = require('../services/bigSpinner');
var Confirm = require('../models_collections/confirmModel');

var ConfirmView = Backbone.View.extend({
  template: require('./templates/confirm.pug'),

  events: {
    'click #btn-delete': '_confirm',
    'click #btn-confirm': '_confirm',
    'hidden.bs.modal': '_hideModal',
  },

  render: function () {
    this.setElement(this.template(this.model.toJSON()));
    this.$el.modal('show');
  },

  showModal: function (options) {
    this._name = 'ConfirmView';
    this.model = new Confirm(options);
    this.render();
  },

  _hideModal: function () {
    var confirmClicked = this.model.get('confirmClicked');
    var confirmNotClicked = this.model.get('confirmNotClicked');

    if (this.model.get('confirmed')) {
      if (this.model.get('useSpinner')) {
        bigSpinner.show().then(confirmClicked).finally(bigSpinner.hide);
      } else {
        confirmClicked();
      }
    } else if (confirmNotClicked) {
      confirmNotClicked();
    }

    this._fixBootstrapStackedModal();
    this.remove();
  },

  /**
   * https://github.com/twbs/bootstrap/issues/16619
   * TODO: Implement a Bootstrap modal manager
   */
  _fixBootstrapStackedModal: function () {
    APP.vent.trigger('hidden.bs.modal');
  },

  _confirm: function () {
    this.model.set('confirmed', true);
  },
});

Backbone.addSingletonToView(ConfirmView);
module.exports = ConfirmView;
