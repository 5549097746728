/* eslint-disable */
module.exports = Backbone.Marionette.CompositeView.extend({
  template: require('./templates/swpppUpdateComposite.pug'),

  emptyView: require('./NoSwpppUpdatesView'),

  childViewContainer: 'ul',

  events: {
    'click #downloadSwppUpdateLog': '_handleDownloadSwpppUpdateLog',
    'click .add-new-update': '_handleAddNewUpdate',
  },

  initialize(options = {}) {
    this.projectState = options.projectState;
    this.isDisabled = options.isDisabled;
    this.downloadSwpppUpdatesUrl = options.downloadSwpppUpdatesUrl;
    this.addNewUpdateModal = options.addNewUpdateModal;
    this.planName = options.planName;
  },

  templateHelpers() {
    return {
      downloadSwpppUpdatesUrl: this.downloadSwpppUpdatesUrl,
      planName: this.planName,
    };
  },

  childViewOptions() {
    return {
      projectState: this.projectState,
      isDisabled: this.isDisabled,
      planName: this.planName,
    };
  },

  getChildView() {
    return require('./SwpppUpdateRowView');
  },

  _handleDownloadSwpppUpdateLog(e) {
    if (this.isDisabled()) {
      e.preventDefault();
    }
  },

  _handleAddNewUpdate() {
    if (!this.isDisabled()) {
      this.addNewUpdateModal.showModal();
    }
  },
});
