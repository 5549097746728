import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { IntervalFrequencyEnum } from 'mapistry-shared';

import {
  HelpIcon,
  Select,
  TableAction,
  TR,
  TD,
  TextField,
} from '../../../elements';

import { EquationFlyout, NotesPopover, TABLE_SETTINGS } from '../shared';

import Units from '../../../../utils/units';
import { isNullOrUndefined } from '../../../../utils';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.ROLLING_CALCULATIONS;
const CALCULATION_TYPE_OPTIONS = ['sum', 'average'].map((opt) => ({
  label: opt,
  value: opt,
}));

const RollingCalculationsRow = (props) => {
  const [visibleFlyout, setVisibleFlyout] = useState(null);

  const isFlyoutVisibleForPath = (groupId, rollingCalculationIdx) =>
    visibleFlyout === `${groupId}.${rollingCalculationIdx}`;

  const toggleFlyoutForRecord = (groupId, rollingCalculationIdx) => {
    setVisibleFlyout(`${groupId}.${rollingCalculationIdx}`);
  };

  const {
    calculatedValues,
    deleteRollingCalculation,
    formErrors,
    groupId,
    loggedItems,
    logProjectId,
    rollingCalculation,
    rollingCalculationIdx,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const allItemsToSelect = () =>
    calculatedValues.concat(loggedItems).map((item) => ({
      frequency: item.frequency,
      label: item.name,
      value: item.id,
      resourceType: item.resourceType,
      resourceId: item.id,
      resourceUnits: item.units,
    }));

  const getRelatedUnitsByResourceId = (resourceId) => {
    if (isNullOrUndefined(resourceId)) return [];
    const allItems = allItemsToSelect();
    const matchingItem = allItems.find(
      (item) => item.resourceId === resourceId,
    );
    return Units.getRelatedUnits(_get(matchingItem, 'resourceUnits'));
  };

  const stageChangedValue = useCallback(
    (editField, nextValue) => {
      stageEdit({ editField, nextValue }, { rollingCalculationIdx, groupId });
    },
    [groupId, rollingCalculationIdx, stageEdit],
  );

  const deleteOnClick = useCallback(
    () => deleteRollingCalculation(rollingCalculationIdx, groupId),
    [groupId, rollingCalculationIdx, deleteRollingCalculation],
  );

  const sharedSelectProps = {
    menuPlacement: 'auto',
    menuPortalTarget: selectMenuPortalRef.current,
    isClearable: false,
  };

  return (
    <>
      <EquationFlyout
        logProjectId={logProjectId}
        onClose={() => setVisibleFlyout(null)}
        resourceId={rollingCalculation.resourceId}
        resourceType={rollingCalculation.resourceType}
        visible={isFlyoutVisibleForPath(groupId, rollingCalculationIdx)}
      />
      <TR>
        <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
          <TextField
            autoFocus={!rollingCalculation.name}
            controlled={false}
            defaultValue={rollingCalculation.name || ''}
            error={
              !!_get(
                formErrors,
                `groups.${groupId}.${rollingCalculationIdx}.name`,
              )
            }
            onBlur={(e) => stageChangedValue('name', e.target.value)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
          <div className="flex items-center">
            <Select
              {...sharedSelectProps}
              className="flex-1"
              error={
                !!_get(
                  formErrors,
                  `groups.${groupId}.${rollingCalculationIdx}.resourceId`,
                )
              }
              options={allItemsToSelect()}
              onChange={(opt) => {
                stageChangedValue('resource', {
                  resourceType: opt.resourceType,
                  resourceId: opt.resourceId,
                  resourceUnits: opt.resourceUnits,
                });
                return stageChangedValue(
                  'calculationFrequency',
                  opt.frequency === IntervalFrequencyEnum.AS_NEEDED
                    ? IntervalFrequencyEnum.DAY
                    : opt.frequency,
                );
              }}
              value={allItemsToSelect().find(
                (o) => o.value === rollingCalculation.resourceId,
              )}
            />
            {!isNullOrUndefined(rollingCalculation.resourceId) && (
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <span
                className="pointer"
                onClick={() =>
                  toggleFlyoutForRecord(groupId, rollingCalculationIdx)
                }
              >
                <HelpIcon text="More info" />
              </span>
            )}
          </div>
        </TD>
        <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
          <Select
            {...sharedSelectProps}
            error={
              !!_get(
                formErrors,
                `groups.${groupId}.${rollingCalculationIdx}.units`,
              )
            }
            isDisabled={isNullOrUndefined(rollingCalculation.resourceId)}
            options={getRelatedUnitsByResourceId(rollingCalculation.resourceId)}
            onChange={(opt) => stageChangedValue('units', opt.value)}
            value={getRelatedUnitsByResourceId(
              rollingCalculation.resourceId,
            ).find((o) => o.value === rollingCalculation.units)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
          <div className="rolling-calculations__row-container">
            <Select
              {...sharedSelectProps}
              error={
                !!_get(
                  formErrors,
                  `groups.${groupId}.${rollingCalculationIdx}.calculationType`,
                )
              }
              isDisabled={isNullOrUndefined(rollingCalculation.resourceId)}
              options={CALCULATION_TYPE_OPTIONS}
              onChange={(opt) =>
                stageChangedValue('calculationType', opt.value)
              }
              value={CALCULATION_TYPE_OPTIONS.find(
                (o) => o.value === rollingCalculation.calculationType,
              )}
            />
            <span>over</span>
            <TextField
              controlled={false}
              defaultValue={rollingCalculation.calculationDuration || ''}
              error={
                !!_get(
                  formErrors,
                  `groups.${groupId}.${rollingCalculationIdx}.calculationDuration`,
                )
              }
              onBlur={(e) =>
                stageChangedValue('calculationDuration', e.target.value)
              }
              type="number"
            />
            {rollingCalculation.calculationFrequency && (
              <span>{`${rollingCalculation.calculationFrequency}s`}</span>
            )}
          </div>
        </TD>
        <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
          <NotesPopover
            notes={rollingCalculation.notes}
            onClose={(nextNotes) => stageChangedValue('notes', nextNotes)}
          />
        </TD>
        <TD width={COLUMN_WIDTHS[5]} growth={COLUMN_GROWTH[5]}>
          <TableAction
            actionType="delete"
            deleteTooltipText="Delete row"
            onClick={deleteOnClick}
          />
        </TD>
      </TR>
    </>
  );
};

RollingCalculationsRow.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteRollingCalculation: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  rollingCalculation: PropTypes.shape({
    calculationFrequency: PropTypes.string,
    calculationDuration: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    calculationType: PropTypes.string,
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
    name: PropTypes.string,
    units: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  rollingCalculationIdx: PropTypes.number.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default React.memo(RollingCalculationsRow);
