/* eslint-disable */
import _ from 'underscore';

import AggregateAverageItemView from './AggregateAverageItemView';
import GenericSamplingEventParameterView from './GenericSamplingEventParameterView';

const NewJerseySamplingEventParameterView =
  GenericSamplingEventParameterView.extend({
    _afterOnShow: function () {
      this._showAggregateAverageRows();
    },

    modelEvents: {
      'change:locations': '_showAggregateAverageRows',
    },

    _isRequiredSamplingParameter: function (parameterSlug) {
      const industrialActivities =
        this.projectModel.getIndustrialActivitySlugs();
      const sicCodeSlugs = this.projectModel.getSicCodeSlugs();
      return this.sectorData.isRequiredSamplingParameter({
        industrialActivities,
        sicCodeSlugs,
        parameterSlug,
      });
    },

    _shouldShowAverages: function () {
      const industrialActivities =
        this.projectModel.getIndustrialActivitySlugs();
      const parameterSlug = this.model.get('slug');
      return this.sectorData.hasBenchmarkLimits({
        industrialActivities,
        parameterSlug,
      });
    },

    _showAggregateAverageRows: function () {
      if (!this._shouldShowAverages()) {
        return;
      }
      const getMonthlyAverageDisplayText = () =>
        this.model.get('aggregate_monthly_average') || 'N/A';
      const getQuarterlyAverageDisplayText = () =>
        this.model.get('aggregate_quarterly_average') || 'N/A';

      let aggregateAverageItemView = new AggregateAverageItemView({
        getMonthlyAverageDisplayText,
        getQuarterlyAverageDisplayText,
      });
      this.annualAverageRow.show(aggregateAverageItemView);
    },
  });

module.exports = NewJerseySamplingEventParameterView;
