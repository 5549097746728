import { useProject as useProjectFetcher } from '@monorepo/shared/hooks/useProject';
import { Project } from 'mapistry-shared';
import React, { createContext, useContext } from 'react';

type ProjectContextType = {
  project?: Project;
  projectId?: string;
};

const ProjectContext = createContext<ProjectContextType>({
  project: undefined,
  projectId: undefined,
});

type ProjectProviderProps = {
  children: React.ReactNode;
  projectId: string;
};

export const ProjectProvider = (props: ProjectProviderProps) => {
  const { children, projectId } = props;
  const { project } = useProjectFetcher({ variables: { projectId } });
  if (!project?.id) {
    return null;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { project, projectId: project.id };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return context;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ProjectContext;
