var pug = require("!../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (fullAddress, hasProjectUpdatePermission, site_address, site_city, site_zip, state) {
      pug_html = pug_html + "\u003Cinput" + (" class=\"form-control edit-site-location-input edit-address display-none\""+pug.attr("value", site_address, true, true)+" placeholder=\"Address\"") + "\u003E\u003Cinput" + (" class=\"form-control edit-site-location-input edit-city margin-left-10 display-none\""+pug.attr("value", site_city, true, true)+" placeholder=\"City\"") + "\u003E";
if (state && state !== 'other') {
pug_html = pug_html + "\u003Cspan class=\"show-state margin-left-10 display-none\"\u003E" + (pug.escape(null == (pug_interp = state.toUpperCase()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control edit-site-location-input edit-zip margin-left-10 display-none\""+pug.attr("value", site_zip, true, true)+" placeholder=\"Zip Code\"") + "\u003E\u003Cspan class=\"site-address\"\u003E" + (pug.escape(null == (pug_interp = fullAddress ? fullAddress : "Please provide your site's address by clicking the edit button.") ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (hasProjectUpdatePermission) {
pug_html = pug_html + "\u003Ca class=\"edit-site-address-icon margin-left-10 flex\" rel=\"tipsy\" title=\"Edit site address\"\u003E\u003Csvg class=\"edit small\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003Cbutton class=\"save-site-address margin-left-10 display-none btn btn-success\"\u003E" + (pug.escape(null == (pug_interp = 'Save') ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
}
    }.call(this, "fullAddress" in locals_for_with ?
        locals_for_with.fullAddress :
        typeof fullAddress !== 'undefined' ? fullAddress : undefined, "hasProjectUpdatePermission" in locals_for_with ?
        locals_for_with.hasProjectUpdatePermission :
        typeof hasProjectUpdatePermission !== 'undefined' ? hasProjectUpdatePermission : undefined, "site_address" in locals_for_with ?
        locals_for_with.site_address :
        typeof site_address !== 'undefined' ? site_address : undefined, "site_city" in locals_for_with ?
        locals_for_with.site_city :
        typeof site_city !== 'undefined' ? site_city : undefined, "site_zip" in locals_for_with ?
        locals_for_with.site_zip :
        typeof site_zip !== 'undefined' ? site_zip : undefined, "state" in locals_for_with ?
        locals_for_with.state :
        typeof state !== 'undefined' ? state : undefined));
    ;;return pug_html;};
module.exports = template;