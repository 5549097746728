import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModalManager } from '@monorepo/shared/contexts/ModalContext';
import { ReactQueryConfig } from '@monorepo/shared/contexts/ReactQueryConfig';
import { ToastProvider } from '@monorepo/shared/contexts/ToastContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import backboneHistory from '../../../../public/js/routing/backboneHistory';
import { CurrentUserProvider } from '../contexts/CurrentUserContext';
import currentUserModel from '../currentUser';
import { ReactQueryPrefetchGlobals } from './ReactQueryPrefetchGlobals';
import Store from './Store';
import { InspectionFormTheme } from './themes/inspectionFormTheme';

// eslint-disable-next-line react/prop-types
export const Providers = ({ children }) => (
  <ThemeProvider theme={InspectionFormTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ToastProvider>
        <ReactQueryConfig>
          <ReactQueryPrefetchGlobals />
          <CurrentUserProvider currentUser={currentUserModel.attributes}>
            <Router history={backboneHistory}>
              <Store>
                <ModalManager>{children}</ModalManager>
              </Store>
            </Router>
          </CurrentUserProvider>
        </ReactQueryConfig>
      </ToastProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export class ComponentWrapper extends Backbone.View {
  initialize(reactComponent, options) {
    this.component = reactComponent;
    this.options = options ?? {};
  }

  render() {
    if (!this.options.noStyling) {
      this.$el.addClass('component-wrapper');
    }

    if (this.options.noProviders) {
      ReactDOM.render(this.component, this.el);
    } else {
      ReactDOM.render(<Providers>{this.component}</Providers>, this.el);
    }
    return this;
  }

  remove() {
    ReactDOM.unmountComponentAtNode(this.el);
    Backbone.View.prototype.remove.call(this);
  }
}
