/* eslint-disable */
/* eslint-disable import/default */
import AlabamaPermitDataView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/alabamaPermitData/AlabamaPermitDataView';
import SectorDataView from '@monorepo/old-web/js/dashboards/projects/content/custom/industrial/sectorData/SectorDataView';
import ProjectHeaderItemView from '@monorepo/old-web/js/dashboards/projects/content/generic/header/ProjectHeaderItemView';
import ProjectSubheaderItemView from '@monorepo/old-web/js/dashboards/projects/content/generic/header/ProjectSubheaderItemView';
import TagsView from '@monorepo/old-web/js/dashboards/projects/content/generic/projectTags/TagsView';
import * as projectStateConfig from 'mapistry-project-state-config';

const ProjectSettingsLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/settings.pug'),

  className: 'backbone-wrapper-content-container',

  regions: {
    header: '#dashboard-content-header-container',
    subheader: '#dashboard-content-subheader-container',
    tags: '#dashboard-tags-container',
    sectorData: '#dashboard-sector-data-container',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
  },

  async onShow() {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const currentUserArbiter = require('../../../../../permissions/currentUserArbiter');
    const hasProjectUpdatePermission =
      await currentUserArbiter.hasProjectSettingsPermission(
        this.projectModel.id,
      );
    this._showHeader(hasProjectUpdatePermission);
    this._showSubHeader(hasProjectUpdatePermission);
    this._showTags(hasProjectUpdatePermission);

    const project = this.projectModel.toJSON();
    if (projectStateConfig.isAlabamaIndustrial(project)) {
      this._showAlabamaPermitData(hasProjectUpdatePermission);
    } else if (this._shouldShowSectorData(project)) {
      this._showSectorData(hasProjectUpdatePermission);
    }
  },

  _showHeader(hasProjectUpdatePermission) {
    const projectHeaderItemView = new ProjectHeaderItemView({
      model: this.projectModel,
      hasProjectUpdatePermission,
    });
    this.header.show(projectHeaderItemView);
  },

  _showSubHeader(hasProjectUpdatePermission) {
    const projectSubheaderItemView = new ProjectSubheaderItemView({
      model: this.projectModel,
      hasProjectUpdatePermission,
    });
    this.subheader.show(projectSubheaderItemView);
  },

  _showTags(hasProjectUpdatePermission) {
    const tagsView = new TagsView({
      model: this.projectModel,
      hasProjectUpdatePermission,
    });
    this.tags.show(tagsView);
  },

  _shouldShowSectorData(project) {
    return (
      !projectStateConfig.isOneOfStatesIndustrial(project, [
        'ca',
        'or',
        'mi',
      ]) && this.projectModel.isStormwaterComplianceEnabled()
    );
  },

  _showSectorData(hasProjectUpdatePermission) {
    const sectorDataView = new SectorDataView({
      model: this.projectModel,
      hasProjectUpdatePermission,
    });
    this.sectorData.show(sectorDataView);
  },

  _showAlabamaPermitData(hasProjectUpdatePermission) {
    const alabamaPermitDataView = new AlabamaPermitDataView({
      model: this.projectModel,
      hasProjectUpdatePermission,
    });
    this.sectorData.show(alabamaPermitDataView);
  },
});

export default ProjectSettingsLayoutView;
