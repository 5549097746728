import React from 'react';
import PropTypes from 'prop-types';

import cellWithAppliedLimits from '../flowLogListHelpers';
import { ConfirmDialog, Menu } from '../../../../elements';
import {
  delimitNumbers,
  fixedDecimalPlaces,
  isNullOrUndefined,
} from '../../../../../utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default function FlowReadingListItem(props) {
  const {
    flowLogLimits,
    flowReading,
    onConfirmCancel,
    onConfirmDelete,
    onDeleteFlowLogReading,
    onEditFlowLogReading,
    showConfirmation,
  } = props;
  const limitsForDay = flowLogLimits.filter(
    ({ frequency }) => frequency === 'day',
  );
  const flowRateLimits = limitsForDay.filter(
    ({ limitType }) => limitType === 'flowRate',
  );
  const volumeLimits = limitsForDay.filter(
    ({ limitType }) => limitType === 'volume',
  );
  return (
    <div className="flow-reading-entry-row">
      <div>{flowReading.dateOfDischarge.slice(0, 10)}</div>
      {cellWithAppliedLimits(
        volumeLimits,
        flowReading.flowReading,
        'gallons',
        (value) => delimitNumbers(value),
      )}
      {cellWithAppliedLimits(
        flowRateLimits,
        flowReading.flowRate,
        'gallons_per_minute',
        (value) => delimitNumbers(fixedDecimalPlaces(value, 1)),
      )}
      <div>
        {isNullOrUndefined(flowReading.hoursOfDischarge)
          ? ''
          : flowReading.hoursOfDischarge}
      </div>
      <Menu
        className="actions-column"
        options={[
          {
            label: 'Edit',
            onClick: () => onEditFlowLogReading(flowReading.id),
            iconIdentifier: 'edit',
          },
          {
            danger: true,
            label: 'Delete',
            onClick: onDeleteFlowLogReading,
            iconIdentifier: 'trash',
          },
        ]}
      />
      <ConfirmDialog
        confirmButtonText="Delete flow log reading"
        danger
        onCancelled={onConfirmCancel}
        onConfirmed={() =>
          onConfirmDelete(flowReading.id, {
            dateOfDischarge: flowReading.dateOfDischarge,
            monitoringLocationId: flowReading.monitoringLocationId,
          })
        }
        open={showConfirmation}
        title={`Are you sure you want to delete the flow log reading on ${flowReading.dateOfDischarge.slice(
          0,
          10,
        )}?`}
      />
    </div>
  );
}

FlowReadingListItem.defaultProps = {
  showConfirmation: false,
};

FlowReadingListItem.propTypes = {
  flowLogLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowReading: PropTypes.shape({
    dateOfDischarge: PropTypes.string,
    flowRate: PropTypes.number,
    flowReading: PropTypes.number,
    hoursOfDischarge: PropTypes.number,
    id: PropTypes.string,
    monitoringLocationId: PropTypes.string,
  }).isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onDeleteFlowLogReading: PropTypes.func.isRequired,
  onEditFlowLogReading: PropTypes.func.isRequired,
  showConfirmation: PropTypes.bool,
};
