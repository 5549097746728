/* eslint-disable */
let APP = require('../../../../../../../../../js/config');

let projectStateConfig = require('mapistry-project-state-config');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/parameterStatusRow.pug'),

  events: {
    'change select.frequency': '_handleChangeFrequency',
    'change select.selected-discharge-locations':
      '_handleChangeDischargeLocations',
    'change select.status': '_handleChangeStatus',
  },

  ui: {
    selectDischargeLocations: 'select.selected-discharge-locations',
    selectFrequency: 'select.frequency',
    selectStatus: 'select.status',
    delete: '.delete-parameter-status',
  },

  behaviors: {
    Tooltip: {},
    WarnBeforeDelete: {},
  },

  initialize(options = {}) {
    this.availableDischargeLocations = options.availableDischargeLocations;
    this.getParameterDisplayText = options.getParameterDisplayText;
    this.showOutfallsAndFrequency = options.showOutfallsAndFrequency;
    this.showParameterStatus = options.showParameterStatus;
    this.project = options.projectModel.toJSON();
    this.isCaliforniaIndustrial = projectStateConfig.isCaliforniaIndustrial(
      this.project,
    );
    this.isNorthCarolinaIndustrial = projectStateConfig.isStateIndustrial(
      this.project,
      'nc',
    );
  },

  templateHelpers() {
    const selectedDischargeLocationIds = this.model.get('discharge_locations')
      ? this.model
          .get('discharge_locations')
          .map((dischargeLocationModel) => dischargeLocationModel.id)
      : [];

    return {
      getParameterDisplayText: this.getParameterDisplayText,
      showOutfallsAndFrequency: this.showOutfallsAndFrequency,
      showParameterStatus: this.showParameterStatus,
      availableDischargeLocations: this.availableDischargeLocations,
      selectedDischargeLocationIds,
      isCaliforniaIndustrial: this.isCaliforniaIndustrial,
      isWashingtonIndustrial: projectStateConfig.isWashingtonIndustrial(
        this.project,
      ),
      isArizonaIndustrial: projectStateConfig.isStateIndustrial(
        this.project,
        'az',
      ),
      isFloridaIndustrial: projectStateConfig.isStateIndustrial(
        this.project,
        'fl',
      ),
      isTexasIndustrial: projectStateConfig.isStateIndustrial(
        this.project,
        'tx',
      ),
      isNorthCarolinaIndustrial: this.isNorthCarolinaIndustrial,
    };
  },

  _handleChangeFrequency() {
    return this.model
      .savePromise({ frequency: this.ui.selectFrequency.val() })
      .then(() => {
        APP.vent.trigger('sampleParameter:update');
      });
  },

  _handleChangeStatus() {
    this._setStatusColor();
    return this.model.savePromise({ status: this.ui.selectStatus.val() });
  },

  _handleChangeDischargeLocations() {
    const selectedIds = this.ui.selectDischargeLocations.val() || [];
    const discharge_locations = this.availableDischargeLocations.filter(
      (dischargeLocation) => selectedIds.indexOf(dischargeLocation.id) > -1,
    );
    return this.model.savePromise({ discharge_locations }).then(() => {
      APP.vent.trigger('sampleParameter:update');
    });
  },

  onShow() {
    this._setStatusColor();
    if (this.showOutfallsAndFrequency) {
      this.ui.selectDischargeLocations.select2({
        width: '100%',
        placeholder: 'Select sampling locations',
      });
    }
  },

  _setStatusColor() {
    let status = this.ui.selectStatus.val();
    this.ui.selectStatus.removeClass('mapistry-green');
    this.ui.selectStatus.removeClass('warning-yellow');
    this.ui.selectStatus.removeClass('warning-red');
    switch (status) {
      case 'exempt':
      case 'consistent-attainment':
      case 'baseline':
        this.ui.selectStatus.addClass('mapistry-green');
        break;
      case 'level1':
      case 'tier1':
      case 'benchmark-exceedance':
        this.ui.selectStatus.addClass('warning-yellow');
        break;
      case 'level2':
      case 'tier2':
      case 'level3':
        if (this.isCaliforniaIndustrial || this.isNorthCarolinaIndustrial) {
          // this is CA level 2
          this.ui.selectStatus.addClass('warning-red');
        } else {
          this.ui.selectStatus.addClass('warning-yellow');
        }
        break;
      case 'tier3':
      // TODO - what is worse than warning-red?
    }
  },

  getWarningBeforeDeleteText() {
    let parameterSlug = this.model.get('parameter_slug');
    return `the ${this.getParameterDisplayText(parameterSlug)} parameter?`;
  },
});
