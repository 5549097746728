var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (permitRecords, permits) {
      pug_html = pug_html + "\u003Ch5\u003EGeneral NPDES Permits:";
if (permits) {
pug_html = pug_html + "\u003Ca class=\"edit-permits\" rel=\"tipsy\" title=\"Edit Sector Data\" style=\"margin-left: 1em\"\u003E\u003Csvg class=\"edit small\"\u003E\u003Cuse xlink:href=\"#edit\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"edit-permits red\" style=\"margin-left: 1em\"\u003ECLICK HERE TO ENTER GENERAL NPDES PERMIT DATA\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fh5\u003E\u003Cul class=\"list-unstyled\"\u003E";
// iterate permitRecords()
;(function(){
  var $$obj = permitRecords();
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var record = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"badge margin-right-10\"\u003E" + (pug.escape(null == (pug_interp = record.slug + ' - ' + record.industry) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var record = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli class=\"badge margin-right-10\"\u003E" + (pug.escape(null == (pug_interp = record.slug + ' - ' + record.industry) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003Cdiv class=\"modal fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003ESector Data\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cform\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel\u003EGeneral NPDES Permits\u003C\u002Flabel\u003E\u003Cselect name=\"permits\" multiple\u003E\u003Coption\u003E\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-20\" id=\"permit-summary-table-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-50\" id=\"dsn-choices-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-50\" id=\"industrial-activity-choices-container\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success btn-submit\" type=\"button\"\u003ESave changes\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "permitRecords" in locals_for_with ?
        locals_for_with.permitRecords :
        typeof permitRecords !== 'undefined' ? permitRecords : undefined, "permits" in locals_for_with ?
        locals_for_with.permits :
        typeof permits !== 'undefined' ? permits : undefined));
    ;;return pug_html;};
module.exports = template;