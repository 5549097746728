/* eslint-disable */
var APP = require('../config');
var Popover = Backbone.Marionette.Behavior.extend({
  onShow: function () {
    var popovers = this.$('.badge').popover({
      container: 'body',
      html: true,
    });

    APP.vent.on('body:clicked', function (e) {
      var $target = $(e.target);
      if (!($target.hasClass('badge') || $target.hasClass('popover-content'))) {
        popovers.popover('hide');
      }
    });
    this.on('before:destroy', function () {
      popovers.popover('hide');
    });
  },
});

module.exports = Popover;
