// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-import-module-exports
import React from 'react';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-import-module-exports
import { ComponentWrapper } from '@monorepo/old-web/js/components/componentWrapper';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-import-module-exports
import { ProjectBulkUpload } from '@monorepo/old-web/js/components/settings/ProjectBulkUpload';

const SiteBulkUploadLayoutView = Backbone.Marionette.LayoutView.extend({
  // eslint-disable-next-line global-require
  template: require('./templates/siteBulkUpload.pug'),

  className: 'container-fluid margin-top-20',

  regions: {
    content: '#site-bulk-upload',
  },

  onShow() {
    this.content.show(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      new ComponentWrapper(<ProjectBulkUpload />, { noStyling: true }),
    );
  },
});

module.exports = SiteBulkUploadLayoutView;
