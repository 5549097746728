import React from 'react';
import TextField from '../../../elements/TextField';
import FormField from './FormField';

class TextAreaField extends FormField {
  fieldClassName() {
    return 'form-field text-area-field';
  }

  getInputComponent(templateField, error) {
    return (
      <TextField
        required={templateField.required}
        value={this.getValue()}
        multiline
        onChange={this.handleChange}
        error={error}
      />
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TextAreaField;
