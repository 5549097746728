var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (name, permissions) {
      pug_html = pug_html + "\u003Cdiv class=\"flex align-items-center justify-content-space-between\"\u003E\u003Cdiv class=\"flex doc-name download-document cursor-pointer link-color\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex\"\u003E\u003Cbutton" + (pug.attr("class", pug.classes(["flex","icon-button",permissions.hasDeletePermission ? 'delete-document' : 'disabled'], [false,false,true]), false, true)+" rel=\"tipsy\""+pug.attr("title", permissions.hasDeletePermission ? 'Delete document' : 'Only admins can delete documents uploaded by others.', true, true)) + "\u003E\u003Cspan class=\"visually-hidden\"\u003E" + (pug.escape(null == (pug_interp = permissions.hasDeletePermission ? 'Delete document' : 'Only admins can delete documents uploaded by others.') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Csvg class=\"trash\"\u003E\u003Cuse xlink:href=\"#trash\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "permissions" in locals_for_with ?
        locals_for_with.permissions :
        typeof permissions !== 'undefined' ? permissions : undefined));
    ;;return pug_html;};
module.exports = template;