/* eslint-disable */
var moment = require('moment');

/**
 * @deprecated - as of 2020-01-01, we're trying to move away from using these dateService fn's.
 */
var dateService = {
  isValidDate: function (str) {
    return !!str && moment(new Date(str)).isValid();
  },

  isValidDateTime: function (str) {
    return !!str && moment(str).isValid();
  },

  prettifyDateNotInUTCYet: function (date) {
    return moment(date).utc().format('MMMM DD, YYYY');
  },

  prettifyDate: function (dateInUTCformat) {
    return moment(dateInUTCformat).format('MMMM DD, YYYY');
  },
};

module.exports = dateService;
