/* eslint-disable */
var BaseTabView = require('./BaseTabCompositeView');

var BaseTabCollectionView = Backbone.Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'nav nav-tabs',

  attributes: {
    role: 'tablist',
  },

  events: {
    'show.bs.tab': '_handleShowTab',
  },

  childView: BaseTabView,

  childViewOptions(model) {
    let collection = model.get('collection');
    return { collection };
  },

  onShow() {
    if (this.collection.isGrouped && this.collection.isGrouped()) {
      return;
    }

    if (!this.children.length) {
      return;
    }
    this.children.findByIndex(0).activate();
  },

  activate(model) {
    let tabView = this.children.findByModel(model);
    if (!tabView) {
      return;
    }
    tabView.activate();
  },

  _handleShowTab: function (e) {
    if (this.collection.isGrouped && this.collection.isGrouped()) {
      return;
    }

    this.trigger('show:tab', e);
  },
});

module.exports = BaseTabCollectionView;
