import { createAction } from 'redux-actions';
import { waterTags } from 'mapistry-shared';
import { CALL_API } from '../middleware/api';
import * as HTTP from '../utils/HTTP';
import {
  downloadWastewaterEventsUrl,
  downloadWastewaterFlowLogUrl,
} from '../apiCaller';

import {
  ADD_FLOW_LOG_LIMIT_REQUEST,
  ADD_FLOW_LOG_LIMIT_SUCCESS,
  ADD_FLOW_LOG_LIMIT_FAILURE,
  ADD_FLOW_LOG_READING_REQUEST,
  ADD_FLOW_LOG_READING_SUCCESS,
  ADD_FLOW_LOG_READING_FAILURE,
  ADD_WASTEWATER_SAMPLING_EVENT_REQUEST,
  ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  ADD_WASTEWATER_SAMPLING_EVENT_FAILURE,
  ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
  ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
  ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
  CREATE_PH_CALIBRATION_READING_REQUEST,
  CREATE_PH_CALIBRATION_READING_SUCCESS,
  CREATE_PH_CALIBRATION_READING_FAILURE,
  CREATE_PH_LOG_READING_REQUEST,
  CREATE_PH_LOG_READING_SUCCESS,
  CREATE_PH_LOG_READING_FAILURE,
  DELETE_PH_LOG_READING_REQUEST,
  DELETE_PH_LOG_READING_SUCCESS,
  DELETE_PH_LOG_READING_FAILURE,
  DELETE_PH_CALIBRATION_READING_REQUEST,
  DELETE_PH_CALIBRATION_READING_SUCCESS,
  DELETE_PH_CALIBRATION_READING_FAILURE,
  DELETE_FLOW_LOG_LIMIT_REQUEST,
  DELETE_FLOW_LOG_LIMIT_SUCCESS,
  DELETE_FLOW_LOG_LIMIT_FAILURE,
  DELETE_FLOW_LOG_READING_REQUEST,
  DELETE_FLOW_LOG_READING_SUCCESS,
  DELETE_FLOW_LOG_READING_FAILURE,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS,
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE,
  DELETE_WASTEWATER_SAMPLING_RESULT_REQUEST,
  DELETE_WASTEWATER_SAMPLING_RESULT_SUCCESS,
  DELETE_WASTEWATER_SAMPLING_RESULT_FAILURE,
  EDIT_PH_CALIBRATION_READING_REQUEST,
  EDIT_PH_CALIBRATION_READING_SUCCESS,
  EDIT_PH_CALIBRATION_READING_FAILURE,
  EDIT_PH_LOG_READING_REQUEST,
  EDIT_PH_LOG_READING_SUCCESS,
  EDIT_PH_LOG_READING_FAILURE,
  EDIT_FLOW_LOG_LIMIT_REQUEST,
  EDIT_FLOW_LOG_LIMIT_SUCCESS,
  EDIT_FLOW_LOG_LIMIT_FAILURE,
  EDIT_FLOW_LOG_READING_REQUEST,
  EDIT_FLOW_LOG_READING_SUCCESS,
  EDIT_FLOW_LOG_READING_FAILURE,
  FETCH_FLOW_LOG_READINGS_REQUEST,
  FETCH_FLOW_LOG_READINGS_SUCCESS,
  FETCH_FLOW_LOG_READINGS_FAILURE,
  FETCH_FLOW_LOGS_FOR_CHART_REQUEST,
  FETCH_FLOW_LOGS_FOR_CHART_SUCCESS,
  FETCH_FLOW_LOGS_FOR_CHART_FAILURE,
  FETCH_PH_LOGS_FOR_CHART_REQUEST,
  FETCH_PH_LOGS_FOR_CHART_SUCCESS,
  FETCH_PH_LOGS_FOR_CHART_FAILURE,
  FETCH_FLOW_LOG_STATS_REQUEST,
  FETCH_FLOW_LOG_STATS_SUCCESS,
  FETCH_FLOW_LOG_STATS_FAILURE,
  EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST,
  EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
  FETCH_FLOW_LOG_LIMITS_REQUEST,
  FETCH_FLOW_LOG_LIMITS_SUCCESS,
  FETCH_FLOW_LOG_LIMITS_FAILURE,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS,
  FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS,
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE,
  FETCH_WASTEWATER_PARAMETERS_REQUEST,
  FETCH_WASTEWATER_PARAMETERS_SUCCESS,
  FETCH_WASTEWATER_PARAMETERS_FAILURE,
  FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST,
  FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS,
  FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE,
  RESET_WASTEWATER_ERROR_STATE,
  INIT_SAMPLING_EVENT,
  INIT_WASTEWATER_LIMIT_CALCULATION,
} from '.';

const addFlowLogLimitRequest = createAction(ADD_FLOW_LOG_LIMIT_REQUEST);
const addFlowLogLimitSuccess = createAction(ADD_FLOW_LOG_LIMIT_SUCCESS);
const addFlowLogLimitFailure = createAction(ADD_FLOW_LOG_LIMIT_FAILURE);

const addFlowLogReadingRequest = createAction(ADD_FLOW_LOG_READING_REQUEST);
const addFlowLogReadingSuccess = createAction(ADD_FLOW_LOG_READING_SUCCESS);
const addFlowLogReadingFailure = createAction(ADD_FLOW_LOG_READING_FAILURE);

const addWastewaterSamplingEventRequest = createAction(
  ADD_WASTEWATER_SAMPLING_EVENT_REQUEST,
);
const addWastewaterSamplingEventSuccess = createAction(
  ADD_WASTEWATER_SAMPLING_EVENT_SUCCESS,
);
const addWastewaterSamplingEventFailure = createAction(
  ADD_WASTEWATER_SAMPLING_EVENT_FAILURE,
);

const addWastewaterSamplingParameterRequest = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
);
const addWastewaterSamplingParameterSuccess = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
);
const addWastewaterSamplingParameterFailure = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
);

const addSamplingParameterAtMonitoringLocationRequest = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
);
const addSamplingParameterAtMonitoringLocationSuccess = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
);
const addSamplingParameterAtMonitoringLocationFailure = createAction(
  ADD_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
);

const createPhCalibrationReadingRequest = createAction(
  CREATE_PH_CALIBRATION_READING_REQUEST,
);
const createPhCalibrationReadingSuccess = createAction(
  CREATE_PH_CALIBRATION_READING_SUCCESS,
);
const createPhCalibrationReadingFailure = createAction(
  CREATE_PH_CALIBRATION_READING_FAILURE,
);

const createPhLogReadingRequest = createAction(CREATE_PH_LOG_READING_REQUEST);
const createPhLogReadingSuccess = createAction(CREATE_PH_LOG_READING_SUCCESS);
const createPhLogReadingFailure = createAction(CREATE_PH_LOG_READING_FAILURE);

const deleteFlowLogLimitRequest = createAction(DELETE_FLOW_LOG_LIMIT_REQUEST);
const deleteFlowLogLimitSuccess = createAction(DELETE_FLOW_LOG_LIMIT_SUCCESS);
const deleteFlowLogLimitFailure = createAction(DELETE_FLOW_LOG_LIMIT_FAILURE);

const deleteFlowLogReadingRequest = createAction(
  DELETE_FLOW_LOG_READING_REQUEST,
);
const deleteFlowLogReadingSuccess = createAction(
  DELETE_FLOW_LOG_READING_SUCCESS,
);
const deleteFlowLogReadingFailure = createAction(
  DELETE_FLOW_LOG_READING_FAILURE,
);

const deleteWastewaterSamplingParameterRequest = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_REQUEST,
);
const deleteWastewaterSamplingParameterSuccess = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_SUCCESS,
);
const deleteWastewaterSamplingParameterFailure = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_FAILURE,
);

const deleteSamplingParameterAtMonitoringLocationRequest = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_REQUEST,
);
const deleteSamplingParameterAtMonitoringLocationSuccess = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_SUCCESS,
);
const deleteSamplingParameterAtMonitoringLocationFailure = createAction(
  DELETE_WASTEWATER_SAMPLING_PARAMETER_AT_LOCATION_FAILURE,
);

const deletePhLogReadingRequest = createAction(DELETE_PH_LOG_READING_REQUEST);
const deletePhLogReadingSuccess = createAction(DELETE_PH_LOG_READING_SUCCESS);
const deletePhLogReadingFailure = createAction(DELETE_PH_LOG_READING_FAILURE);

const deletePhCalibrationReadingRequest = createAction(
  DELETE_PH_CALIBRATION_READING_REQUEST,
);
const deletePhCalibrationReadingSuccess = createAction(
  DELETE_PH_CALIBRATION_READING_SUCCESS,
);
const deletePhCalibrationReadingFailure = createAction(
  DELETE_PH_CALIBRATION_READING_FAILURE,
);

const editPhCalibrationReadingRequest = createAction(
  EDIT_PH_CALIBRATION_READING_REQUEST,
);
const editPhCalibrationReadingSuccess = createAction(
  EDIT_PH_CALIBRATION_READING_SUCCESS,
);
const editPhCalibrationReadingFailure = createAction(
  EDIT_PH_CALIBRATION_READING_FAILURE,
);

const editPhLogReadingRequest = createAction(EDIT_PH_LOG_READING_REQUEST);
const editPhLogReadingSuccess = createAction(EDIT_PH_LOG_READING_SUCCESS);
const editPhLogReadingFailure = createAction(EDIT_PH_LOG_READING_FAILURE);

const editFlowLogLimitRequest = createAction(EDIT_FLOW_LOG_LIMIT_REQUEST);
const editFlowLogLimitSuccess = createAction(EDIT_FLOW_LOG_LIMIT_SUCCESS);
const editFlowLogLimitFailure = createAction(EDIT_FLOW_LOG_LIMIT_FAILURE);

const editFlowLogReadingRequest = createAction(EDIT_FLOW_LOG_READING_REQUEST);
const editFlowLogReadingSuccess = createAction(EDIT_FLOW_LOG_READING_SUCCESS);
const editFlowLogReadingFailure = createAction(EDIT_FLOW_LOG_READING_FAILURE);

const editSamplingParameterAtMonitoringLocationRequest = createAction(
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_REQUEST,
);
const editSamplingParameterAtMonitoringLocationSuccess = createAction(
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_SUCCESS,
);
const editSamplingParameterAtMonitoringLocationFailure = createAction(
  EDIT_WASTEWATER_SAMPLING_PARAMETER_LOCATION_FAILURE,
);

const editWastewaterSamplingEventRequest = createAction(
  EDIT_WASTEWATER_SAMPLING_EVENT_REQUEST,
);
const editWastewaterSamplingEventSuccess = createAction(
  EDIT_WASTEWATER_SAMPLING_EVENT_SUCCESS,
);
const editWastewaterSamplingEventFailure = createAction(
  EDIT_WASTEWATER_SAMPLING_EVENT_FAILURE,
);

const fetchFlowLogLimitsRequest = createAction(FETCH_FLOW_LOG_LIMITS_REQUEST);
const fetchFlowLogLimitsSuccess = createAction(FETCH_FLOW_LOG_LIMITS_SUCCESS);
const fetchFlowLogLimitsFailure = createAction(FETCH_FLOW_LOG_LIMITS_FAILURE);

const fetchFlowLogReadingsRequest = createAction(
  FETCH_FLOW_LOG_READINGS_REQUEST,
);
const fetchFlowLogReadingsSuccess = createAction(
  FETCH_FLOW_LOG_READINGS_SUCCESS,
);
const fetchFlowLogReadingsFailure = createAction(
  FETCH_FLOW_LOG_READINGS_FAILURE,
);

const fetchFlowLogsForChartRequest = createAction(
  FETCH_FLOW_LOGS_FOR_CHART_REQUEST,
);
const fetchFlowLogsForChartSuccess = createAction(
  FETCH_FLOW_LOGS_FOR_CHART_SUCCESS,
);
const fetchFlowLogsForChartFailure = createAction(
  FETCH_FLOW_LOGS_FOR_CHART_FAILURE,
);

const fetchPHLogsForChartRequest = createAction(
  FETCH_PH_LOGS_FOR_CHART_REQUEST,
);
const fetchPHLogsForChartSuccess = createAction(
  FETCH_PH_LOGS_FOR_CHART_SUCCESS,
);
const fetchPHLogsForChartFailure = createAction(
  FETCH_PH_LOGS_FOR_CHART_FAILURE,
);

const fetchFlowLogStatsRequest = createAction(FETCH_FLOW_LOG_STATS_REQUEST);
const fetchFlowLogStatsSuccess = createAction(FETCH_FLOW_LOG_STATS_SUCCESS);
const fetchFlowLogStatsFailure = createAction(FETCH_FLOW_LOG_STATS_FAILURE);

const fetchWastewaterParameterLimitCalculationsRequest = createAction(
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_REQUEST,
);
const fetchWastewaterParameterLimitCalculationsSuccess = createAction(
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_SUCCESS,
);
const fetchWastewaterParameterLimitCalculationsFailure = createAction(
  FETCH_WASTEWATER_PARAMETER_LIMIT_CALCULATIONS_FAILURE,
);

const fetchWastewaterParametersRequest = createAction(
  FETCH_WASTEWATER_PARAMETERS_REQUEST,
);
const fetchWastewaterParametersSuccess = createAction(
  FETCH_WASTEWATER_PARAMETERS_SUCCESS,
);
const fetchWastewaterParametersFailure = createAction(
  FETCH_WASTEWATER_PARAMETERS_FAILURE,
);

const fetchWastewaterMonitoringLocationsRequest = createAction(
  FETCH_WASTEWATER_MONITORING_LOCATIONS_REQUEST,
);
const fetchWastewaterMonitoringLocationsSuccess = createAction(
  FETCH_WASTEWATER_MONITORING_LOCATIONS_SUCCESS,
);
const fetchWastewaterMonitoringLocationsFailure = createAction(
  FETCH_WASTEWATER_MONITORING_LOCATIONS_FAILURE,
);

const fetchWastewaterSamplingEventRequest = createAction(
  FETCH_WASTEWATER_SAMPLING_EVENT_REQUEST,
);
const fetchWastewaterSamplingEventSuccess = createAction(
  FETCH_WASTEWATER_SAMPLING_EVENT_SUCCESS,
);
const fetchWastewaterSamplingEventFailure = createAction(
  FETCH_WASTEWATER_SAMPLING_EVENT_FAILURE,
);

export const resetErrorStateAction = createAction(RESET_WASTEWATER_ERROR_STATE);

export const initSamplingEventAction = createAction(INIT_SAMPLING_EVENT);
export const initWastewaterLimitCalculationsAction = createAction(
  INIT_WASTEWATER_LIMIT_CALCULATION,
);

const deleteWastewaterSamplingResultRequest = createAction(
  DELETE_WASTEWATER_SAMPLING_RESULT_REQUEST,
);
const deleteWastewaterSamplingResultSuccess = createAction(
  DELETE_WASTEWATER_SAMPLING_RESULT_SUCCESS,
);
const deleteWastewaterSamplingResultFailure = createAction(
  DELETE_WASTEWATER_SAMPLING_RESULT_FAILURE,
);

export const downloadWastewaterEventsAction = (projectId) =>
  window.open(downloadWastewaterEventsUrl(projectId));

export const downloadWastewaterFlowLog = (projectId) =>
  window.open(downloadWastewaterFlowLogUrl(projectId));

export function addFlowLogLimit(projectId, flowLogLimitData) {
  return {
    [CALL_API]: {
      data: flowLogLimitData,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log-limits`,
      lifecycleActions: [
        addFlowLogLimitRequest,
        addFlowLogLimitSuccess,
        addFlowLogLimitFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function addFlowLogReading(projectId, flowLogData) {
  return {
    [CALL_API]: {
      data: flowLogData,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log`,
      lifecycleActions: [
        addFlowLogReadingRequest,
        addFlowLogReadingSuccess,
        addFlowLogReadingFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function addSamplingParameterAtMonitoringLocationAction({
  monitoringLocationId,
  projectId,
  wastewaterSamplingParameterId,
}) {
  const data = {
    monitoringLocationId,
    wastewaterSamplingParameterId,
  };
  return {
    [CALL_API]: {
      data,
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters/${wastewaterSamplingParameterId}/monitoring-locations`,
      lifecycleActions: [
        addSamplingParameterAtMonitoringLocationRequest,
        addSamplingParameterAtMonitoringLocationSuccess,
        addSamplingParameterAtMonitoringLocationFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function addWastewaterSamplingEventAction(projectId, eventData) {
  return {
    [CALL_API]: {
      data: eventData,
      endpoint: `/api/v2/${projectId}/wastewater-sampling-events`,
      lifecycleActions: [
        addWastewaterSamplingEventRequest,
        addWastewaterSamplingEventSuccess,
        addWastewaterSamplingEventFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function addWastewaterSamplingParameterAction({
  parameterSlug,
  projectId,
}) {
  const data = {
    parameterSlug,
    projectId,
  };
  return {
    [CALL_API]: {
      data,
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters`,
      lifecycleActions: [
        addWastewaterSamplingParameterRequest,
        addWastewaterSamplingParameterSuccess,
        addWastewaterSamplingParameterFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createPhCalibrationReadingAction({ phCalibration, projectId }) {
  return {
    [CALL_API]: {
      data: phCalibration,
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-calibrations`,
      lifecycleActions: [
        createPhCalibrationReadingRequest,
        createPhCalibrationReadingSuccess,
        createPhCalibrationReadingFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function createPhLogReadingAction({ phLog, projectId }) {
  return {
    [CALL_API]: {
      data: phLog,
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-readings`,
      lifecycleActions: [
        createPhLogReadingRequest,
        createPhLogReadingSuccess,
        createPhLogReadingFailure,
      ],
      verb: HTTP.Types.POST,
    },
  };
}

export function editPhCalibrationReadingAction({ phCalibration, projectId }) {
  return {
    [CALL_API]: {
      data: phCalibration,
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-calibrations/${phCalibration.id}`,
      lifecycleActions: [
        editPhCalibrationReadingRequest,
        editPhCalibrationReadingSuccess,
        editPhCalibrationReadingFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function editPhLogReadingAction({ phLog, projectId }) {
  return {
    [CALL_API]: {
      data: phLog,
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-readings/${phLog.id}`,
      lifecycleActions: [
        editPhLogReadingRequest,
        editPhLogReadingSuccess,
        editPhLogReadingFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function deletePhLogReadingAction({ id, projectId }) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-readings/${id}`,
      lifecycleActions: [
        deletePhLogReadingRequest,
        deletePhLogReadingSuccess,
        deletePhLogReadingFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deletePhCalibrationAction({ id, projectId }) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-calibrations/${id}`,
      lifecycleActions: [
        deletePhCalibrationReadingRequest,
        deletePhCalibrationReadingSuccess,
        deletePhCalibrationReadingFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteSamplingParameterAction({ paramId, projectId }) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters/${paramId}`,
      lifecycleActions: [
        deleteWastewaterSamplingParameterRequest,
        deleteWastewaterSamplingParameterSuccess,
        deleteWastewaterSamplingParameterFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteFlowLogLimit(projectId, flowLogLimitId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater/flow-log-limits/${flowLogLimitId}`,
      lifecycleActions: [
        deleteFlowLogLimitRequest,
        deleteFlowLogLimitSuccess,
        deleteFlowLogLimitFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteFlowLogReading(projectId, flowLogId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater/flow-log/${flowLogId}`,
      lifecycleActions: [
        deleteFlowLogReadingRequest,
        deleteFlowLogReadingSuccess,
        deleteFlowLogReadingFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function deleteSamplingParameterAtMonitoringLocationAction({
  sPML,
  projectId,
}) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters/${sPML.wastewater_sampling_parameter_id}/monitoring-locations/${sPML.id}`,
      lifecycleActions: [
        deleteSamplingParameterAtMonitoringLocationRequest,
        deleteSamplingParameterAtMonitoringLocationSuccess,
        deleteSamplingParameterAtMonitoringLocationFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}

export function editSamplingParameterAtMonitoringLocationAction({
  parameter,
  projectId,
}) {
  return {
    [CALL_API]: {
      data: parameter,
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters/${parameter.wastewater_sampling_parameter_id}/monitoring-locations/${parameter.monitoring_location_id}`,
      lifecycleActions: [
        editSamplingParameterAtMonitoringLocationRequest,
        editSamplingParameterAtMonitoringLocationSuccess,
        editSamplingParameterAtMonitoringLocationFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function editFlowLogLimit(projectId, flowLogLimitId, flowLogLimitData) {
  return {
    [CALL_API]: {
      data: flowLogLimitData,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log-limits/${flowLogLimitId}`,
      lifecycleActions: [
        editFlowLogLimitRequest,
        editFlowLogLimitSuccess,
        editFlowLogLimitFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function editFlowLogReading(projectId, flowLogId, flowLogData) {
  return {
    [CALL_API]: {
      data: flowLogData,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log/${flowLogId}`,
      lifecycleActions: [
        editFlowLogReadingRequest,
        editFlowLogReadingSuccess,
        editFlowLogReadingFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function editWastewaterSamplingEventAction(
  projectId,
  samplingEventId,
  eventData,
) {
  return {
    [CALL_API]: {
      data: eventData,
      endpoint: `/api/v2/${projectId}/wastewater-sampling-events/${samplingEventId}`,
      lifecycleActions: [
        editWastewaterSamplingEventRequest,
        editWastewaterSamplingEventSuccess,
        editWastewaterSamplingEventFailure,
      ],
      verb: HTTP.Types.PUT,
    },
  };
}

export function fetchFlowLogLimits(projectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater/flow-log-limits`,
      lifecycleActions: [
        fetchFlowLogLimitsRequest,
        fetchFlowLogLimitsSuccess,
        fetchFlowLogLimitsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

/**
 * @param {string} projectId
 * @param {{
 *  locationId: string,
 *  startDate: string,
 *  endDate: string
 * }} query control how the readings are returned, i.e. narrow the search window.
 */
export function fetchFlowLogReadingsAction(projectId, query) {
  return {
    [CALL_API]: {
      data: query,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log`,
      lifecycleActions: [
        fetchFlowLogReadingsRequest,
        fetchFlowLogReadingsSuccess,
        fetchFlowLogReadingsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

/**
 * @param {string} projectId
 * @param {{
 *  calculationType: string,
 *  endDate: string,
 *  frequency: string,
 *  logType: string,
 *  monitoringLocationIds: string[],
 *  startDate: string,
 *  units: string
 * }} query control how the readings are returned, i.e. narrow the search window.
 */
export function fetchFlowLogsForChartAction(projectId, query) {
  return {
    [CALL_API]: {
      data: query,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log/statistics/chart`,
      lifecycleActions: [
        fetchFlowLogsForChartRequest,
        fetchFlowLogsForChartSuccess,
        fetchFlowLogsForChartFailure,
      ],
      // Note this is a POST since that handles the monitoringLocations
      //  array better than a GET
      verb: HTTP.Types.POST,
    },
  };
}

/**
 * @param {string} projectId
 * @param {{
 *  frequency: string,
 *  startDate: string,
 *  endDate: string
 * }} query control how the readings are returned, i.e. narrow the search window.
 */
export function fetchPHLogsForChartAction(projectId, query) {
  return {
    [CALL_API]: {
      data: query,
      endpoint: `/api/v2/${projectId}/wastewater/ph-log-readings/statistics`,
      lifecycleActions: [
        fetchPHLogsForChartRequest,
        fetchPHLogsForChartSuccess,
        fetchPHLogsForChartFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

/**
 * @param {string} projectId
 * @param {{
 *  frequency: string,
 *  endDate?: string,
 *  locationId?: string,
 *  startDate?: string,
 * }} query control how the stats are returned, i.e. narrow the search window,
 *  change how the stats are aggregated.
 */
export function fetchFlowLogStatsAction(projectId, query) {
  return {
    [CALL_API]: {
      data: query,
      endpoint: `/api/v2/${projectId}/wastewater/flow-log/statistics/records`,
      lifecycleActions: [
        fetchFlowLogStatsRequest,
        fetchFlowLogStatsSuccess,
        fetchFlowLogStatsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchWastewaterParameterLimitCalculationsAction(
  projectId,
  parameterSlug,
  samplingEventId,
) {
  return {
    [CALL_API]: {
      endpoint:
        `/api/v2/${projectId}/wastewater-sampling-parameters/${parameterSlug}` +
        `/wastewater-sampling-events/${samplingEventId}/limit-calculations`,
      lifecycleActions: [
        fetchWastewaterParameterLimitCalculationsRequest,
        fetchWastewaterParameterLimitCalculationsSuccess,
        fetchWastewaterParameterLimitCalculationsFailure,
      ],
      verb: HTTP.Types.GET,
    },
    parameterSlug,
    samplingEventId,
  };
}

export function fetchWastewaterSamplingParametersAction(projectId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater-sampling-parameters`,
      lifecycleActions: [
        fetchWastewaterParametersRequest,
        fetchWastewaterParametersSuccess,
        fetchWastewaterParametersFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchWastewaterMonitoringLocationsAction(projectId) {
  const data = { layerTag: waterTags };
  return {
    [CALL_API]: {
      data,
      endpoint: `/api/v2/${projectId}/map-features`,
      lifecycleActions: [
        fetchWastewaterMonitoringLocationsRequest,
        fetchWastewaterMonitoringLocationsSuccess,
        fetchWastewaterMonitoringLocationsFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function fetchWastewaterSamplingEventAction(projectId, samplingEventId) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater-sampling-events/${samplingEventId}`,
      lifecycleActions: [
        fetchWastewaterSamplingEventRequest,
        fetchWastewaterSamplingEventSuccess,
        fetchWastewaterSamplingEventFailure,
      ],
      verb: HTTP.Types.GET,
    },
  };
}

export function deleteWastewaterSamplingResultAction({
  projectId,
  samplingResultId,
}) {
  return {
    [CALL_API]: {
      endpoint: `/api/v2/${projectId}/wastewater-sampling-results/${samplingResultId}`,
      lifecycleActions: [
        deleteWastewaterSamplingResultRequest,
        deleteWastewaterSamplingResultSuccess,
        deleteWastewaterSamplingResultFailure,
      ],
      verb: HTTP.Types.DELETE,
    },
  };
}
