var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (opacity) {
      pug_html = pug_html + "\u003Cdiv class=\"leaflet-button\" rel=\"tipsy\" data-toggle=\"tooltip\" data-placement=\"right\" title=\"Change the opacity of the base map\"\u003E\u003Cinput" + (" type=\"range\" min=\"0\" max=\"100\" step=\"1\""+pug.attr("value", opacity, true, true)+" orient=\"vertical\"") + "\u003E\u003Cspan id=\"opacity-val\"\u003E" + (pug.escape(null == (pug_interp = opacity) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "opacity" in locals_for_with ?
        locals_for_with.opacity :
        typeof opacity !== 'undefined' ? opacity : undefined));
    ;;return pug_html;};
module.exports = template;