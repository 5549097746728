import { SheetConfig } from '@flatfile/api/api';
import { Api } from '@monorepo/shared/apiClient';
import {
  getBooleanColumnSetting,
  getDateColumnSetting,
  getNumericColumnSetting,
  getSingleSelectColumnSetting,
  getTextColumnSetting,
  getTimeColumnSetting,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/columnSettingHelpers';
import { uniqueConstraintSetting } from '@monorepo/shared/components/InteractiveFileUpload/helpers/flatfileSettingsHelpers';
import { parseDateString } from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/dateParsingHelpers';
import {
  didValidationSucceed,
  validateDateTimeFields,
  validateDayField,
  validatePositiveNumber,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/recordValidationHelpers';
import {
  numericValueOrUndefinedIfEmpty,
  valueOrUndefinedIfEmpty,
} from '@monorepo/shared/components/InteractiveFileUpload/helpers/validationAndParsing/valueValidationHelpers';
import { InteractiveFileUploadModal } from '@monorepo/shared/components/InteractiveFileUpload/InteractiveFileUploadModal';
import {
  FlatfileLoggingContext,
  FlatfileSingleSelectOption,
  RecordHookCallback,
  SubmitDataCallback,
  WorkbookConfig,
} from '@monorepo/shared/components/InteractiveFileUpload/types/flatfileTypes';
import { useMapFeatures } from '@monorepo/shared/hooks/useMapFeatures';
import { useProject } from '@monorepo/shared/hooks/useProject';
import {
  SamplingMethod,
  SamplingParameter,
  SamplingUnit,
} from '@monorepo/shared/types/industrial-stormwater-sector-data';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingMethods from 'industrial-stormwater-sector-data/lib/samplingMethods';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingParameters from 'industrial-stormwater-sector-data/lib/samplingParameters';
/* @ts-expect-error - no TS declarations for this old Mapistry package */
import SamplingUnits from 'industrial-stormwater-sector-data/lib/samplingUnits';
import {
  StateAbbreviations,
  UploadSamplingResultRequest,
  UTCEquivalentOfLocal,
  waterTags,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { queryCache } from 'react-query';
import { matchSamplingEventsKey } from '../../../../hooks/water/useWastewaterSamplingEvents';
import { parseParameterValue, parseRawValue } from './parsers';
import {
  validateAndParseParameterSlug,
  validateAndParseParameterValue,
  validateDetectionLimit,
  validateMethodSlug,
  validateSamplingResultDoesNotExist,
  validateSamplingResultUnits,
} from './samplingResultsUploadValidators';
import { uploadFieldKeys, WastewaterSamplingResultUpload } from './types';

const allParameters = SamplingParameters.getAll() as SamplingParameter[];
const allMethods = SamplingMethods.getAll() as SamplingMethod[];
const allUnits = SamplingUnits.getAll() as SamplingUnit[];

const unitOptions: FlatfileSingleSelectOption[] = allUnits
  .filter((u) => !u.limit_only)
  .map((u) => {
    const typedOption: FlatfileSingleSelectOption = {
      label: u.display_text,
      value: u.slug,
      alternativeNames: u.alternative_spelling,
    };
    return typedOption;
  });
const methodOptions = allMethods.map((m) => ({
  label: m.display_text,
  value: m.slug,
  alternativeNames: m.alternative_spelling,
}));

type SamplingResultUploadModalProps = {
  // eslint-disable-next-line camelcase
  enabledSamplingParameters: { parameter_slug: string }[];
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
};

export function SamplingResultsUploadModal(
  props: SamplingResultUploadModalProps,
) {
  const { enabledSamplingParameters, isOpen, onClose, projectId } = props;
  const enabledSamplingParameterSlugs = useMemo(
    () => enabledSamplingParameters.map((p) => p.parameter_slug),
    [enabledSamplingParameters],
  );

  const parameterOptions: FlatfileSingleSelectOption[] = useMemo(() => {
    const enabledParameterSlugs = enabledSamplingParameters.map(
      (p) => p.parameter_slug,
    );
    return allParameters
      .filter((p) => enabledParameterSlugs.includes(p.slug))
      .map((p) => {
        const typedOption: FlatfileSingleSelectOption = {
          label: p.display_text,
          value: p.slug,
          alternativeNames: p.alternative_spelling,
        };
        return typedOption;
      });
  }, [enabledSamplingParameters]);

  const { isLoading: isMapFeaturesLoading, mapFeatures } = useMapFeatures({
    projectId,
    tags: waterTags,
  });
  const locationOptions = useMemo(
    () =>
      mapFeatures
        ?.filter((f) => !f.isArchived)
        .map((f) => ({
          label: f.name,
          value: f.id,
          alternativeNames: f.aliases || [],
        })) || [],
    [mapFeatures],
  );
  const { isLoading: isProjectLoading, project } = useProject({
    variables: { projectId },
  });
  const isCaliforniaState = project?.state === StateAbbreviations.CA;

  const isLoading = isMapFeaturesLoading || isProjectLoading;

  const workbook = useMemo<WorkbookConfig>(() => {
    const beforeMethodFields: SheetConfig['fields'] = [
      getDateColumnSetting({
        label: 'Sample date',
        key: uploadFieldKeys.sampleDate,
        isRequired: true,
      }),
      getTimeColumnSetting({
        label: 'Sample time',
        key: uploadFieldKeys.sampleTime,
      }),
      getTextColumnSetting({
        label: 'Sample description',
        key: uploadFieldKeys.sampleDescription,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Water discharge location',
          key: uploadFieldKeys.locationId,
          isRequired: true,
        },
        locationOptions,
      ),
      getSingleSelectColumnSetting(
        {
          label: 'Parameter',
          key: uploadFieldKeys.parameterSlug,
          description: `To see a parameter in the list you need to add it to your sampling parameter settings for this site`,
          alternativeNames: ['Analyte'],
        },
        parameterOptions,
      ),
      getTextColumnSetting({
        label: 'CAS number',
        key: uploadFieldKeys.casNumber,
      }),
      getTextColumnSetting({
        label: 'Parameter value',
        key: uploadFieldKeys.parameterValue,
        isRequired: true,
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Units',
          key: uploadFieldKeys.units,
        },
        unitOptions,
      ),
    ];

    const californiaMethodFields: SheetConfig['fields'] = [
      getTextColumnSetting({
        label: 'Method (plain text)',
        key: uploadFieldKeys.methodText,
        description:
          'Method will be saved as plain text for information only, sampling results with only this column will be excluded from Mapistry generated SMARTS report.',
      }),
      getSingleSelectColumnSetting(
        {
          label: 'Method (SMARTS)',
          key: uploadFieldKeys.methodSlug,
          description:
            'If you map your method to one of these options, you will be able to generate reports for SMARTS in Mapistry.',
        },
        methodOptions,
      ),
    ];

    const nonCaliforniaMethodFields: SheetConfig['fields'] = [
      getTextColumnSetting({
        label: 'Method',
        key: uploadFieldKeys.methodText,
        isRequired: true,
      }),
    ];

    const methodFields = isCaliforniaState
      ? californiaMethodFields
      : nonCaliforniaMethodFields;

    const afterMethodFields: SheetConfig['fields'] = [
      getNumericColumnSetting({
        label: 'Reporting limit',
        key: uploadFieldKeys.reportingLimit,
        alternativeNames: ['RL'],
      }),
      getNumericColumnSetting({
        label: 'Detection limit',
        key: uploadFieldKeys.detectionLimit,
        alternativeNames: ['DL', 'MDL'],
      }),
      getTextColumnSetting({
        label: 'Evaluation limit',
        key: uploadFieldKeys.evaluationLimit,
        alternativeNames: ['EL'],
      }),
      getTextColumnSetting({
        label: 'Matrix',
        key: uploadFieldKeys.matrix,
      }),
      getTextColumnSetting({
        label: 'Lab qualifiers',
        key: uploadFieldKeys.qualifiers,
        alternativeNames: ['Flags'],
      }),
      getBooleanColumnSetting({
        label: 'Detect',
        key: uploadFieldKeys.detect,
        description: 'e.g. "Y/N" or "yes/no"',
        alternativeNames: ['Detected', 'Detect (Y/N)', 'Detected (Y/N)'],
      }),
      getDateColumnSetting({
        label: 'Analysis date',
        key: uploadFieldKeys.analysisDate,
      }),
      getTextColumnSetting({
        label: 'Lab name',
        key: uploadFieldKeys.labName,
      }),
      getTextColumnSetting({
        label: 'Lab report #',
        key: uploadFieldKeys.labReportNumber,
      }),
    ];

    const fields: SheetConfig['fields'] = [
      ...beforeMethodFields,
      ...methodFields,
      ...afterMethodFields,
    ];

    const workbookConfig: WorkbookConfig = {
      name: 'Upload sampling results',
      sheets: [
        {
          name: 'Sampling results',
          slug: 'upload-sampling-results',
          fields,
          constraints: [
            uniqueConstraintSetting(
              'Sample once per parameter and location and minute',
              [
                uploadFieldKeys.locationId,
                uploadFieldKeys.parameterSlug,
                uploadFieldKeys.sampleDate,
                uploadFieldKeys.sampleTime,
              ],
            ),
          ],
        },
      ],
    };
    return workbookConfig;
  }, [isCaliforniaState, locationOptions, parameterOptions]);

  // "Parameter" (parameter slug) is a required column. A user's file can have
  // only a "CAS Number" column, in this case we will create a virtual column for parameter slug,
  // and get its value from provided CAS number.
  // Custom validations and parsing of user's data
  const recordHookCallback = useCallback<RecordHookCallback>(
    async (record) => {
      validateDateTimeFields(
        uploadFieldKeys.sampleDate,
        uploadFieldKeys.sampleTime,
        record,
      );
      validateDayField(uploadFieldKeys.analysisDate, record);
      validatePositiveNumber(uploadFieldKeys.reportingLimit, record);
      validatePositiveNumber(uploadFieldKeys.detectionLimit, record);

      // Must go before any validation that require parameter slug to be already parsed.
      // A user can provide either parameter or CAS number, and we derive parameter from a CAS number
      // in the later case.
      validateAndParseParameterSlug(
        record,
        record.get(uploadFieldKeys.parameterSlug),
        record.get(uploadFieldKeys.casNumber),
        enabledSamplingParameterSlugs,
      );
      const parameterSlug: string = record.get(
        uploadFieldKeys.parameterSlug,
      ) as string;

      // Non-California sites only have "Method" (a.k.a. method plain text) column
      // which is configured as required in workbook settings.
      if (
        isCaliforniaState &&
        !record.get(uploadFieldKeys.methodSlug) &&
        !record.get(uploadFieldKeys.methodText)
      ) {
        record.addError(
          uploadFieldKeys.methodText,
          'Method Text must be provided when Method (SMARTS) is not',
        );
        record.addError(
          uploadFieldKeys.methodSlug,
          'Method (SMARTS) must be provided when Method text is not',
        );
      }

      // Non-California sites don't have methodSlug column (Method SMARTS), only methodText.
      if (isCaliforniaState) {
        validateMethodSlug(
          uploadFieldKeys.methodSlug,
          record,
          record.get(uploadFieldKeys.methodSlug),
          parameterSlug,
        );
      }

      // We used to have a "pH Log" Records section, as of March 2024 we no longer have it in the UI.
      // Users were able to enter their pH values from that section and with file uploads.
      // Right now they can still upload data, but no way to see it in the UI (maybe in Analytics?)
      // These pH logs are stored separate from other sampling results in "ph_log" DB table.
      // For the time being, there is still code that uses that data, so to remove all loose ends will require
      // Product input. For now, I'm checking that pH parameter is not enabled for a non-California site,
      // otherwise user won't be able to upload pH rows as they will lack methodSlug field
      // which is required in "ph_log" DB table.
      if (!isCaliforniaState && parameterSlug === 'ph') {
        record.addError(
          uploadFieldKeys.parameterSlug,
          'pH parameter cannot be enabled for site outside California, choose a different parameter, e.g. pH (field), pH (meter), or pH (Laboratory)',
        );
      }

      validateDetectionLimit(
        uploadFieldKeys.detectionLimit,
        record,
        record.get(uploadFieldKeys.detectionLimit),
        record.get(uploadFieldKeys.reportingLimit),
      );
      validateSamplingResultUnits(uploadFieldKeys.units, record, parameterSlug);

      validateAndParseParameterValue(
        record,
        parameterSlug,
        record.get(uploadFieldKeys.parameterValue),
        record.get(uploadFieldKeys.reportingLimit),
        record.get(uploadFieldKeys.detectionLimit),
      );

      const dateOutput = record.get(uploadFieldKeys.sampleDate) as string;
      const timeOutput = record.get(uploadFieldKeys.sampleTime) as string;
      const locationId = record.get(uploadFieldKeys.locationId) as string;

      if (
        dateOutput &&
        timeOutput &&
        didValidationSucceed(uploadFieldKeys.sampleDate, record) &&
        didValidationSucceed(uploadFieldKeys.sampleTime, record) &&
        parameterSlug &&
        didValidationSucceed(uploadFieldKeys.parameterSlug, record) &&
        locationId &&
        didValidationSucceed(uploadFieldKeys.locationId, record)
      ) {
        await validateSamplingResultDoesNotExist(
          uploadFieldKeys.sampleDate,
          record,
          locationId,
          parameterSlug,
          projectId,
          dateOutput,
          timeOutput,
        );
      }
    },
    [enabledSamplingParameterSlugs, isCaliforniaState, projectId],
  );

  const onSubmitData = useCallback<SubmitDataCallback>(
    async (results: WastewaterSamplingResultUpload[]) => {
      const samplingResults = results.map((result) => {
        const uploadResult = { ...result };
        const parameterSlug: string = result[uploadFieldKeys.parameterSlug];
        // No need to upload casNumber since we already got parameter slug from it
        delete uploadResult.casNumber;
        // Explicitly assigning "undefined" to empty values because Flatfile returns empty string,
        // but in the DB we don't want two values (null and empty string) to mean absence of value.
        const samplingResult: UploadSamplingResultRequest = {
          ...uploadResult,
          analysisDate: uploadResult.analysisDate
            ? UTCEquivalentOfLocal(parseDateString(uploadResult.analysisDate))
            : undefined,
          detect: valueOrUndefinedIfEmpty(uploadResult.detect),
          detectionLimit: numericValueOrUndefinedIfEmpty(
            uploadResult.detectionLimit,
          ),
          eventDate: UTCEquivalentOfLocal(
            parseDateString(uploadResult.eventDate),
          ),
          labReportNumber: valueOrUndefinedIfEmpty(
            uploadResult.labReportNumber,
          ),
          methodSlug: valueOrUndefinedIfEmpty(uploadResult.methodSlug),
          methodText: valueOrUndefinedIfEmpty(uploadResult.methodText),
          parameterSlug,
          parameterValue: parseParameterValue(
            uploadResult.parameterValue,
            uploadResult.reportingLimit,
            uploadResult.detectionLimit,
          ),
          qualifiers: valueOrUndefinedIfEmpty(uploadResult.qualifiers),
          rawValue: parseRawValue(
            uploadResult.parameterValue,
            uploadResult.units,
            unitOptions,
          ),
          reportingLimit: numericValueOrUndefinedIfEmpty(
            uploadResult.reportingLimit,
          ),
          units: valueOrUndefinedIfEmpty(uploadResult.units),
        };

        return samplingResult;
      });

      await Api.saveWastewaterSamplingResultsBulk(projectId, samplingResults);

      const queryKeyForAllSamplingEvents = matchSamplingEventsKey(projectId);
      queryCache.removeQueries(queryKeyForAllSamplingEvents);

      return `
        Successfully uploaded sampling results!
        Now you can see your data in "Water: Sampling Results" Records section.
      `;
    },
    [projectId],
  );

  const loggingContext: FlatfileLoggingContext = useMemo(
    () => ({ projectId }),
    [projectId],
  );

  return (
    <InteractiveFileUploadModal
      isLoading={isLoading}
      isOpen={isOpen}
      loggingContext={loggingContext}
      onClose={onClose}
      onSubmitData={onSubmitData}
      recordHookCallback={recordHookCallback}
      workbook={workbook}
    />
  );
}
