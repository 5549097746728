/* eslint-disable */
let projectStateConfig = require('mapistry-project-state-config');
let apiCaller = require('../../../../../../../apiCaller');
let dashboardConfig = require('../../../../../config');

let GenericSamplingEventParameterView = Backbone.Marionette.LayoutView.extend({
  className: 'panel panel-default panel-sampling-event',

  template: require('./templates/samplingEventParameterSection.pug'),

  templateHelpers() {
    let parameterSlug = this.model.get('slug');
    let samplingUnits = this.sectorData.getSamplingUnits(parameterSlug);
    let hardnessOfReceivingWater = this.projectModel.get(
      'receiving_water_hardness',
    );
    let isReceivingWaterBodySaltWater = this.projectModel.get(
      'receiving_water_is_saltwater',
    );
    let industrialActivities = this.projectModel.getIndustrialActivitySlugs();
    let sicCodeSlugs = this.projectModel.getSicCodeSlugs();
    let samplingUnitsDisplayText =
      (samplingUnits && samplingUnits.display_text) || 'N/A';
    let instantaneousLimit = this.sectorData.getInstantaneousLimit
      ? this.sectorData.getInstantaneousLimit({
          parameterSlug,
          hardnessOfReceivingWater,
          isReceivingWaterBodySaltWater,
          industrialActivities,
          sicCodeSlugs,
        })
      : null;
    let instantaneousLimitDisplayText = instantaneousLimit
      ? `${instantaneousLimit} ${samplingUnitsDisplayText}`
      : 'N/A';
    let isCaliforniaIndustrial = projectStateConfig.isCaliforniaIndustrial(
      this.projectModel.toJSON(),
    );
    let isAlabamaIndustrial = projectStateConfig.isAlabamaIndustrial(
      this.projectModel.toJSON(),
    );
    let isOregonIndustrial = projectStateConfig.isOregonIndustrial(
      this.projectModel.toJSON(),
    );
    const isNewJerseyIndustrial = projectStateConfig.isStateIndustrial(
      this.projectModel.toJSON(),
      'nj',
    );
    let isNorthCarolinaIndustrial = projectStateConfig.isStateIndustrial(
      this.projectModel.toJSON(),
      'nc',
    );
    let isSouthCarolinaIndustrial = projectStateConfig.isStateIndustrial(
      this.projectModel.toJSON(),
      'sc',
    );
    let hasConsecutiveExceedance = projectStateConfig.hasConsecutiveExceedance(
      this.projectModel.toJSON(),
    );

    const shouldShowNewJerseyInstantaneousBenchmark = () => {
      if (!isNewJerseyIndustrial) {
        return false;
      }

      return this.sectorData.hasInstantaneousLimit({
        industrialActivities,
        parameterSlug,
      });
    };

    const limitLabelText = (() => {
      if (isCaliforniaIndustrial) {
        return 'Instantaneous NAL';
      }
      if (isNewJerseyIndustrial) {
        return 'Daily Max';
      }
      return 'Instantaneous Benchmark';
    })();

    return {
      isCaliforniaIndustrial,
      isRequired: this._isRequiredSamplingParameter(parameterSlug),
      limitLabelText,
      parameterName: this.sectorData.getParameterDisplayText(parameterSlug),
      instantaneousLimitDisplayText: instantaneousLimitDisplayText,
      shouldShowInstantaneousBenchmark:
        (!isSouthCarolinaIndustrial &&
          !isCaliforniaIndustrial &&
          !isAlabamaIndustrial &&
          !hasConsecutiveExceedance &&
          this.model.get('slug') === 'ph') ||
        isOregonIndustrial ||
        isNorthCarolinaIndustrial ||
        shouldShowNewJerseyInstantaneousBenchmark(),
    };
  },

  ui: {
    delete: '.delete-sample-event-parameter',
  },

  events: {
    'click .open-sector-data-modal': '_openSectorDataModal',
  },

  behaviors: {
    WarnBeforeDelete: {},
  },

  getWarningBeforeDeleteText() {
    let parameterName = this.sectorData.getParameterDisplayText(
      this.model.get('slug'),
    );
    return parameterName + '?';
  },

  regions: {
    locations: '.locations-container',
    stormAverageRow: '.storm-average-row-container',
    annualAverageRow: '.annual-average-row-container',
  },

  initialize(options = {}) {
    this.projectModel = options.projectModel;
    this.samplingEventId = options.samplingEventId;
    this.swpppYear = options.swpppYear;
    this.sectorData = this._getSectorData();
  },

  onShow() {
    this._showLocationRows();
    this._afterOnShow();
  },

  // Override this function, not onShow
  _afterOnShow() {
    if (
      this.sectorData.satisfiesAnnualAverageLimit ||
      this.sectorData.satisfiesLimit ||
      this.sectorData.satisfiesBenchmarkLimit
    ) {
      if (
        this.projectModel.get('state') !== 'sc' &&
        this.model.get('slug') === 'ph'
      ) {
        return;
      }
      this._showStormAverageRow();
      this._showAnnualAverageRow();
    }
  },

  onRender() {
    this.$('.warning-yellow').popover({
      html: true,
      content: function () {
        return $('#receiving-waters-warning-popover-content-wrapper').html();
      },
    });
  },

  _getSectorData() {
    return require('industrial-stormwater-sector-data')(
      this.projectModel.get('state'),
    );
  },

  _showLocationRows() {
    let LocationCollection = require('./locations/LocationCollection');
    let locationCollection = new LocationCollection(this._getLocations(), {
      projectId: this.projectModel.id,
    });
    let LocationCompositeView = require('./locations/LocationCompositeView');
    let locationCompositeView = new LocationCompositeView({
      projectModel: this.projectModel,
      parameterSlug: this.model.get('slug'),
      collection: locationCollection,
    });

    // Update the average rows whenever data updated for a location
    this.listenTo(locationCollection, 'change', (locationModel) => {
      let APP = require('../../../../../../../config');
      let project = this.projectModel.toJSON();
      let projectId = project.id;

      apiCaller
        .getSamplingEvent(projectId, locationModel.get('sampling_event_id'))
        .then((samplingEvent) => {
          let parameter = _.findWhere(samplingEvent.parameters, {
            slug: this.model.get('slug'),
          });
          this.model.set(this._getUpdatedModelAttributes(parameter));
        });

      APP.vent.trigger('sampleEventParameterLocation:change');
    });
    this.locations.show(locationCompositeView);
  },

  _getLocations() {
    const samplingParametersCollection =
      require('../../../../../../../models_collections/samplingParametersCollection').singleton();
    const outfallAtSamplingParameterFilter = (location) => {
      const samplingParameter = samplingParametersCollection.find(
        (parameter) => {
          return parameter.get('parameter_slug') === location.parameter_slug;
        },
      );

      return (
        samplingParameter &&
        samplingParameter
          .get('discharge_locations')
          .map((dischargeLocation) => dischargeLocation.id)
          .indexOf(location.location_id) > -1
      );
    };
    const returnAllLocationsFilter = () => true;

    const hasDischargeLocationFiltering =
      projectStateConfig.hasSamplingParamsWithOutfalls(
        this.projectModel.toJSON(),
      );
    const locationFilter = hasDischargeLocationFiltering
      ? outfallAtSamplingParameterFilter
      : returnAllLocationsFilter;

    return this.model
      .get('locations')
      .filter(locationFilter)
      .sort((l1, l2) =>
        l1.name.toLowerCase() < l2.name.toLowerCase() ? -1 : 1,
      );
  },

  _getUpdatedModelAttributes(parameter) {
    let storm_average = parameter.storm_average;
    let annual_average = parameter.annual_average;
    let annual_average_satisfied = parameter.annual_average_satisfied;
    let locations = parameter.locations;
    return {
      storm_average,
      annual_average,
      annual_average_satisfied,
      locations,
    };
  },

  _showStormAverageRow() {
    let StormAverageRowView = require('./StormAverageRowView');
    let stormAverageRowView = new StormAverageRowView({
      model: this.model,
      sectorData: this.sectorData,
      projectModel: this.projectModel,
    });
    this.stormAverageRow.show(stormAverageRowView);
  },

  _showAnnualAverageRow() {
    let AnnualAverageRowView = require('./AnnualAverageRowView');
    let annualAverageRowView = new AnnualAverageRowView({
      model: this.model,
      sectorData: this.sectorData,
      swpppYear: this.swpppYear,
      projectModel: this.projectModel,
    });
    this.annualAverageRow.show(annualAverageRowView);
  },

  _isRequiredSamplingParameter(parameterSlug) {
    let sicCodeSlugs = this.projectModel.getSicCodeSlugs();
    let industrialActivities = this.projectModel.getIndustrialActivitySlugs();
    let isReceivingWaterBodySaltWater = this.projectModel.get(
      'receiving_water_is_saltwater',
    );

    return this.sectorData.isRequiredSamplingParameter({
      sicCodeSlugs,
      industrialActivities,
      isReceivingWaterBodySaltWater,
      parameterSlug,
    });
  },

  _openSectorDataModal() {
    let APP = require('../../../../../../../config');
    APP.vent.trigger('samplingResults:hideModal');
    APP.vent.trigger('sectorData:openModal');
  },
});

module.exports = GenericSamplingEventParameterView;
