/* eslint-disable react/prop-types */

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import FormFieldFactory from '../fields/FormFieldFactory';
import EmptyDuplicableSection from './EmptyDuplicableSection';

class FormContent extends React.Component {
  content() {
    const { error, templateGroup, submissionGroup, isLocked } = this.props;

    if (error) {
      return this.error();
    }
    if (!templateGroup) {
      return this.loading();
    }
    if (!submissionGroup && templateGroup.isDuplicable) {
      return this.emptyDuplicable();
    }

    return (
      <fieldset disabled={isLocked}>
        <legend className="visually-hidden">{templateGroup.name}</legend>
        {this.fields()}
      </fieldset>
    );
  }

  error() {
    return (
      <div className="content-loader">
        There was an error loading the form. Please check the URL and try again.
      </div>
    );
  }

  loading() {
    return (
      <div className="content-loader">
        <CircularProgress />
      </div>
    );
  }

  emptyDuplicable() {
    const { templateGroup, onCreateSubsection } = this.props;

    return (
      <EmptyDuplicableSection
        templateGroup={templateGroup}
        onCreateSubsection={onCreateSubsection}
      />
    );
  }

  fields() {
    const {
      submissionGroup,
      templateGroup,
      projectId,
      currentUser,
      interval,
      isLocked,
      isStencil,
      onFieldChange,
      onFieldError,
      onUpdateSignature,
    } = this.props;
    const submissionFields = (submissionGroup && submissionGroup.fields) || [];

    return templateGroup.fields.map((templateField) => {
      const submissionField = submissionFields.find(
        (sf) => sf.fieldSlug === templateField.slug,
      );

      return FormFieldFactory.For({
        templateField,
        submissionField,
        projectId,
        currentUser,
        interval,
        isStencil,
        isLocked,
        onChange: onFieldChange,
        onError: onFieldError,
        onUpdateSignature,
      });
    });
  }

  render() {
    return <div className="main-content">{this.content()}</div>;
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormContent;
