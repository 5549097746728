/* eslint-disable */
const APP = require('../config');
const SimpleTextView = require('../formWidgets/SimpleTextView');

module.exports = Backbone.Marionette.LayoutView.extend({
  tagName: 'figure',
  className: 'feature-photo-overlay',
  template: require('./templates/featurePhotoOverlay.pug'),

  regions: {
    caption: '.caption',
  },

  ui: {
    $image: 'img.full-size-photo',
    $rotateLeft: '.rotate-button-left',
    $rotateRight: '.rotate-button-right',
    $editCaption: '.edit-caption-button',
    $saveCaption: '.save-caption-button',
    $cancelEditCaption: '.cancel-edit-caption-button',
  },

  events: {
    'click @ui.$image': '_hidePhotoOverlay',
    'click @ui.$rotateLeft': 'handleRotateLeft',
    'click @ui.$rotateRight': 'handleRotateRight',
    'click @ui.$editCaption': 'handleEditCaption',
    'click @ui.$saveCaption': 'handleSaveCaption',
    'click @ui.$cancelEditCaption': 'handleCancelEditCaption',
  },

  onRender() {
    this.caption.show(
      new SimpleTextView({
        content: this.model.get('caption'),
      }),
    );
  },

  _hidePhotoOverlay() {
    APP.vent.trigger('featurePhotoOverlay:hide');
  },

  handleRotateLeft() {
    this._saveDiffRotation(-90);
    this.render();
  },

  handleRotateRight() {
    this._saveDiffRotation(90);
    this.render();
  },

  _saveDiffRotation(diff) {
    let rotation = this.model.get('rotation');
    this.model.set('rotation', Math.round((360 + rotation + diff) % 360));
    return this.model.save();
  },

  handleEditCaption() {
    this.caption.currentView.edit();
    this.ui.$editCaption.hide();
    this.ui.$saveCaption.show();
    this.ui.$cancelEditCaption.show();
  },

  handleSaveCaption() {
    this.model.set('caption', this.caption.currentView.getValue());
    this.model.save();
    this.render();
  },

  handleCancelEditCaption() {
    this.render();
  },
});
