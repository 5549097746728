import React, { useCallback, useMemo } from 'react';
import { WaterLogLimitItemFormSubmission } from './LimitItems/types';
import { LoggedItemFormSubmission } from './LoggedItems/types';
import { useCurrentLogProject } from '../LogProjectContext';
import { useSamplingParameters } from '../SamplingParameterContext';
import { Select } from '../../elements';
import { useLoggedItems } from '../../../hooks/genericLogs/useLoggedItems';

interface SelectParameterProps {
  errors: { [field: string]: string };
  menuRef: React.RefObject<HTMLDivElement>;
  onChange: (option: string) => void;
}

interface SelectParameterForLoggedItemProps extends SelectParameterProps {
  item: LoggedItemFormSubmission;
}

interface SelectParameterForLimitItemProps extends SelectParameterProps {
  item: WaterLogLimitItemFormSubmission;
}

const isLoggedItem = (
  x: LoggedItemFormSubmission | WaterLogLimitItemFormSubmission,
): x is LoggedItemFormSubmission => 'isLocked' in x;

export const SelectParameter = <
  T extends LoggedItemFormSubmission | WaterLogLimitItemFormSubmission,
>(
  props: T extends LoggedItemFormSubmission
    ? SelectParameterForLoggedItemProps
    : SelectParameterForLimitItemProps,
) => {
  const { errors, item, menuRef, onChange } = props;
  const { logProject } = useCurrentLogProject();
  const allSamplingParameters = useSamplingParameters();
  const { loggedItems } = useLoggedItems(logProject?.id ?? '');
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  let dischargeLocation;
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  let parameter;

  if (isLoggedItem(item)) {
    ({ parameter } = item);
  } else {
    ({ dischargeLocation, parameter } = item);
  }

  const options = useMemo(() => {
    if (isLoggedItem(item)) {
      return allSamplingParameters.map((param) => ({
        label: param.display_text,
        value: param.slug,
      }));
    }
    const filtered: { label: string; value: string }[] = [];
    loggedItems?.forEach((loggedItem) => {
      const param = allSamplingParameters?.find(
        (p) => p.slug === loggedItem.parameter,
      );
      if (
        // Didn't find the parameter
        !param ||
        // Exclude parameters that don't match the already selected discharge location
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        (dischargeLocation && dischargeLocation !== loggedItem.location) ||
        // De-dupe
        filtered.some((f) => f.value === param.slug)
      ) {
        return;
      }

      filtered.push({
        label: param.display_text,
        value: param.slug,
      });
    });
    return filtered;
  }, [allSamplingParameters, dischargeLocation, item, loggedItems]);

  const handleOnChange = useCallback(
    (option) => onChange(option?.value),
    [onChange],
  );

  const optionFromValue = useMemo(
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    () => options.find((opt) => opt.value === parameter),
    [options, parameter],
  );

  return (
    <Select
      className="flex-grow-1"
      error={isLoggedItem(item) ? !!errors?.parameter : !!errors?.resourceId}
      isClearable
      menuPlacement="auto"
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      menuPortalTarget={menuRef.current}
      options={options}
      onChange={handleOnChange}
      value={optionFromValue}
    />
  );
};
