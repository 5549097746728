var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (planName) {
      pug_html = pug_html + "\u003Cdiv class=\"dashboard-list-container\"\u003E\u003Cdiv class=\"padding-left-20 padding-tb-20\"\u003E\u003Cdiv class=\"font-size-16 flex align-items-center\"\u003E\u003Cspan class=\"font-weight-500 margin-right-10\"\u003E" + (pug.escape(null == (pug_interp = planName + ' Update Log ') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ca class=\"add-new-update flex\" rel=\"tipsy\" title=\"Add New Update\"\u003E\u003Csvg class=\"add\"\u003E\u003Cuse xlink:href=\"#add\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-12\"\u003E\u003Cul class=\"list-styled\"\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "planName" in locals_for_with ?
        locals_for_with.planName :
        typeof planName !== 'undefined' ? planName : undefined));
    ;;return pug_html;};
module.exports = template;