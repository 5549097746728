import React from 'react';
import PropTypes from 'prop-types';

function ExplanationField(props) {
  const { templateField } = props;

  return (
    <div className="explanation-area" key={templateField.id}>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: templateField.helpText }} />
    </div>
  );
}

ExplanationField.propTypes = {
  templateField: PropTypes.shape({
    id: PropTypes.string,
    helpText: PropTypes.string,
  }).isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default ExplanationField;
