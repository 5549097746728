/* eslint-disable */
const APP = require('../config');
const FeatureDisplaySettingModel = require('./displaySettings/featureDisplaySettingModel');
const BluebirdPromise = require('bluebird');

const MapModel = Backbone.Model.extend({
  initialize: function () {
    if (this.id) {
      this.initializeDisplaySettingCollections();
    }
  },

  initializeDisplaySettingCollections: function () {
    var FeatureDisplaySettingCollection = require('./displaySettings/featureDisplaySettingCollection');
    var LayerDisplaySettingCollection = require('./displaySettings/layerDisplaySettingCollection');

    this._featureDisplaySettingCollection = new FeatureDisplaySettingCollection(
      [],
      {
        projectId: APP.projectId,
        mapId: this.id,
        type: 'feature',
      },
    );
    this._layerDisplaySettingCollection = new LayerDisplaySettingCollection(
      [],
      {
        projectId: APP.projectId,
        mapId: this.id,
        type: 'layer',
      },
    );
    this.listenTo(
      this._featureDisplaySettingCollection,
      'change:feature_on',
      function (model) {
        this.trigger('change:feature_on', model);
      },
    );
    this.listenTo(
      this._layerDisplaySettingCollection,
      'change:layer_on',
      function (model) {
        this.trigger('change:layer_on', model);
      },
    );
    this.listenTo(
      this._featureDisplaySettingCollection,
      'add',
      function (model) {
        this.trigger('featureDisplaySetting:add', model);
      },
    );
    this.listenTo(this._layerDisplaySettingCollection, 'add', function (model) {
      this.trigger('layerDisplaySetting:add', model);
    });
  },

  fetchDisplaySettings: function () {
    return BluebirdPromise.all([
      this._featureDisplaySettingCollection.fetch({ silent: true }),
      this._layerDisplaySettingCollection.fetch({ silent: true }),
    ])
      .bind(this)
      .then(function () {
        this.trigger('displaySettings:fetched');
      });
  },

  urlRoot: function () {
    return APP.apiUrl + '/projects/' + APP.projectId + '/maps';
  },

  titlebar: function () {
    var titlebarCollection = require('./titlebarCollection').singleton();

    return titlebarCollection.findWhere({ map_id: this.id });
  },

  areLabelsOn: function () {
    return this.get('base_map_type') === 'AerialWithLabels';
  },

  isTeslaFremontLevel1On: function () {
    return this.get('tesla_fremont_level') === 'FremontLevelOne';
  },

  toggleTeslaLevel1: function () {
    let teslaMap;
    if (this.isTeslaFremontLevel1On()) {
      teslaMap = 'None';
    } else {
      teslaMap = 'FremontLevelOne';
    }
    return BluebirdPromise.resolve(
      this.save({ tesla_fremont_level: teslaMap }),
    );
  },

  isTeslaFremontLevel2On: function () {
    return this.get('tesla_fremont_level') === 'FremontLevelTwo';
  },

  toggleTeslaLevel2: function () {
    let teslaMap;
    if (this.isTeslaFremontLevel2On()) {
      teslaMap = 'None';
    } else {
      teslaMap = 'FremontLevelTwo';
    }
    return BluebirdPromise.resolve(
      this.save({ tesla_fremont_level: teslaMap }),
    );
  },

  isTopographyOn: function () {
    return this.get('base_map_type') === 'Topography';
  },

  toggleTopographyMapType: function () {
    var baseMapType = this.isTopographyOn() ? 'Aerial' : 'Topography';
    return BluebirdPromise.resolve(this.save({ base_map_type: baseMapType }));
  },

  toggleBaseMapType: function () {
    var baseMapType;
    if (this.isTopographyOn()) {
      baseMapType = 'AerialWithLabels';
    } else {
      baseMapType = this.areLabelsOn() ? 'Aerial' : 'AerialWithLabels';
    }
    return BluebirdPromise.resolve(this.save({ base_map_type: baseMapType }));
  },

  isMetric: function () {
    return this.get('system_of_measurement_to_display') === 'metric';
  },

  isFeatureOn: function (featureId) {
    return _.contains(this.onFeatureIds(), featureId);
  },

  isLayerOn: function (layerId) {
    return _.contains(this.onLayerIds(), layerId);
  },

  isLayerDisplayedInLegend: function (layerId) {
    return _.contains(
      this._layerDisplaySettingCollection.displayedInLegendLayerIds(),
      layerId,
    );
  },

  onFeatureIds: function () {
    return this._featureDisplaySettingCollection.onFeatureIds();
  },

  onLayerIds: function () {
    return this._layerDisplaySettingCollection.onLayerIds();
  },

  offFeatureIds: function () {
    return this._featureDisplaySettingCollection.offFeatureIds();
  },

  offLayerIds: function () {
    return this._layerDisplaySettingCollection.offLayerIds();
  },

  displayedInLegendLayerIds: function () {
    return this._layerDisplaySettingCollection.displayedInLegendLayerIds();
  },

  onFeatures: function () {
    var featureCollection = require('./featureCollection').singleton();

    return featureCollection.filter(function (feature) {
      return _.contains(this.onFeatureIds(), feature.id);
    }, this);
  },

  onLayers: function () {
    var layerCollection = require('./layerCollection').singleton();

    return layerCollection.filter(function (layer) {
      return _.contains(this.onLayerIds(), layer.id);
    }, this);
  },

  displayedInLegendLayers: function () {
    var layerCollection = require('./layerCollection').singleton();

    return layerCollection.filter(function (layer) {
      return _.contains(this.displayedInLegendLayerIds(), layer.id);
    }, this);
  },

  toggleFeatureOn: function (feature) {
    const layer = feature.layer();
    let fds = this._featureDisplaySettingCollection.findWhere({
      feature_id: feature.id,
    });
    const lds = this._layerDisplaySettingCollection.findWhere({
      layer_id: layer.id,
    });
    const featureIsOn = fds && fds.get('feature_on');
    const layerIsOn = lds.get('layer_on');
    const onFeatureCount = feature.layer().onFeatureCount();
    const isLastOnFeatureInLayer = onFeatureCount === 1 && featureIsOn;

    if (layerIsOn && isLastOnFeatureInLayer) {
      lds.save({ layer_on: false });
    } else if (!layerIsOn && !featureIsOn) {
      lds.save({ layer_on: true });
    }

    if (!fds) {
      fds = new FeatureDisplaySettingModel({
        feature_id: feature.id,
      });
      this._featureDisplaySettingCollection.push(fds);
      fds.save(
        {
          feature_on: true,
        },
        {
          wait: true,
        },
      );
      this._featureDisplaySettingCollection.fetch();
    } else {
      fds.save({ feature_on: !featureIsOn });
    }
  },

  toggleLayerOn: function (layer) {
    var lds = this._layerDisplaySettingCollection.findWhere({
      layer_id: layer.id,
    });
    if (lds) {
      lds.save({ layer_on: !lds.get('layer_on') });
    }
  },

  toggleLayerDisplayedInLegend: function (layer) {
    var lds = this._layerDisplaySettingCollection.findWhere({
      layer_id: layer.id,
    });
    lds.save({
      layer_displayed_in_legend: !lds.get('layer_displayed_in_legend'),
    });
  },

  toggleScaleUnits: function () {
    var systemOfMeasurementToDisplay = this.isMetric() ? 'imperial' : 'metric';
    this.save({
      system_of_measurement_to_display: systemOfMeasurementToDisplay,
    });
  },
});

module.exports = MapModel;
