var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (hasPollutantsOfConcernQuestions, hasWashingtonQuestions, sicCodeRecords, sic_codes) {
      pug_html = pug_html + "\u003Ch5\u003ESIC Codes:";
if (sic_codes) {
pug_html = pug_html + "\u003Ca class=\"edit-sic-codes\" rel=\"tipsy\" title=\"Edit Sector Data\" style=\"margin-left: 1em\"\u003E\u003Ci class=\"fa fa-pencil-square-o\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca class=\"edit-sic-codes red\" style=\"margin-left: 1em\"\u003ECLICK HERE TO ENTER SECTOR DATA\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003C\u002Fh5\u003E\u003Cul class=\"list-unstyled\"\u003E";
// iterate sicCodeRecords
;(function(){
  var $$obj = sicCodeRecords;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var record = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E" + (pug.escape(null == (pug_interp = record.sic_code + ' - ' + record.activity_represented) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var record = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E" + (pug.escape(null == (pug_interp = record.sic_code + ' - ' + record.activity_represented) ? "" : pug_interp)) + "\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003Cdiv class=\"modal fade\"\u003E\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Ch4 class=\"modal-title\"\u003ESector Data\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"alert alert-danger\"\u003E\u003C\u002Fdiv\u003E\u003Cform\u003E\u003Cdiv class=\"form-group\"\u003E\u003Clabel\u003ESic Codes\u003C\u002Flabel\u003E\u003Cselect name=\"sic_codes\" multiple\u003E\u003Coption\u003E\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-20\" id=\"sic-code-summary-table-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-50\" id=\"industrial-activity-choices-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"margin-top-50\" id=\"receiving-water-salt-or-fresh-container\"\u003E\u003C\u002Fdiv\u003E";
if (hasPollutantsOfConcernQuestions) {
pug_html = pug_html + "\u003Cdiv class=\"margin-top-50\" id=\"pollutants-of-concern-container\"\u003E\u003C\u002Fdiv\u003E";
}
if (hasWashingtonQuestions) {
pug_html = pug_html + "\u003Cdiv class=\"margin-top-50\" id=\"washington-container\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-success btn-submit\" type=\"button\"\u003ESave changes\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "hasPollutantsOfConcernQuestions" in locals_for_with ?
        locals_for_with.hasPollutantsOfConcernQuestions :
        typeof hasPollutantsOfConcernQuestions !== 'undefined' ? hasPollutantsOfConcernQuestions : undefined, "hasWashingtonQuestions" in locals_for_with ?
        locals_for_with.hasWashingtonQuestions :
        typeof hasWashingtonQuestions !== 'undefined' ? hasWashingtonQuestions : undefined, "sicCodeRecords" in locals_for_with ?
        locals_for_with.sicCodeRecords :
        typeof sicCodeRecords !== 'undefined' ? sicCodeRecords : undefined, "sic_codes" in locals_for_with ?
        locals_for_with.sic_codes :
        typeof sic_codes !== 'undefined' ? sic_codes : undefined));
    ;;return pug_html;};
module.exports = template;