/* eslint-disable */
let DisplayMessages = Backbone.Marionette.Behavior.extend({
  events: {
    ShowError: 'onShowError',
    ShowSuccess: 'onShowSuccess',
  },

  initialize(options = {}) {
    this.delayTime = options.delayTime;
  },

  onShowError(error, delayTime = 4000) {
    delayTime = this.delayTime ? this.delayTime : delayTime;
    delayTime = _.isNumber(delayTime) ? delayTime : 4000;

    let errorMessage =
      'Sorry, there was an error processing your request. Please try again later.';

    if (error && error.message) {
      errorMessage = error.message;
    } else if (error && error.responseJSON && error.responseJSON.error) {
      errorMessage = error.responseJSON.error;
    } else if (
      _.isArray(
        error && error.responseJSON && error.responseJSON.error_messages,
      )
    ) {
      let messages = error.responseJSON.error_messages;

      // since there are a lot of messages, give them more time to read
      delayTime += 10000;

      if (messages.length >= 10) {
        messages = _.first(messages, 10);
        messages.push('...');
      }

      errorMessage = messages.join('<br>');
    } else if (error && error.responseText) {
      errorMessage = error.responseText;
    } else if (typeof error === 'string') {
      errorMessage = error;
    }

    this.view.ui.$errorContainer
      .html(errorMessage)
      .slideDown()
      .delay(delayTime)
      .slideUp();
  },

  onShowSuccess(message) {
    this.view.ui.$successContainer
      .html(message)
      .slideDown()
      .delay(4000)
      .slideUp();
  },
});

module.exports = DisplayMessages;
