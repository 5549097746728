import { FormTemplateFieldTypes } from 'mapistry-shared';
import React from 'react';
import BooleanField from './BooleanField';
import DateField from './DateField';
import { DocumentField } from './DocumentField';
import EmissionObservationField from './EmissionObservationField';
import ExplanationField from './ExplanationField';
import FormField from './FormField';
import MapAreaField from './MapAreaField';
import MultiLocationField from './MultiLocationField';
import MultiSelectField from './MultiSelectField';
import MultiSelectFreeFormField from './MultiSelectFreeFormField';
import MultiUserField from './MultiUserField';
import NumberField from './NumberField';
import PhoneNumberField from './PhoneNumberField';
import { PhotoField } from './PhotoField';
import SignatureField from './SignatureField';
import SingleLocationField from './SingleLocationField';
import SingleSelectField from './SingleSelectField';
import SingleSelectFreeFormField from './SingleSelectFreeFormField';
import SingleUserField from './SingleUserField';
import TasksField from './TasksField';
import TextAreaField from './TextAreaField';
import TextField from './TextField';
import TimeField from './TimeField';

class FormFieldFactory {
  static For(options) {
    const {
      templateField,
      submissionField,
      interval,
      projectId,
      currentUser,
      onUpdateSignature,
      onChange,
      onError,
      isLocked,
      isStencil,
    } = options;

    switch (templateField.type) {
      case FormTemplateFieldTypes.BOOLEAN:
        return (
          <BooleanField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.DATE:
        return (
          <DateField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            interval={interval}
            disabled={isStencil || isLocked}
          />
        );
      case FormTemplateFieldTypes.DOCUMENT_UPLOADER:
        return (
          <DocumentField
            key={`${templateField.id}-${submissionField.fieldValue}`}
            disabled={isStencil || isLocked}
            disabledMessage="Document uploads are disabled when building form templates or when the form is locked."
            onChange={onChange}
            onError={onError}
            projectId={projectId}
            submissionField={submissionField}
            templateField={templateField}
          />
        );
      case FormTemplateFieldTypes.EMISSION_OBSERVATION:
        return (
          <EmissionObservationField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            onError={onError}
            projectId={projectId}
            disabled={isStencil || isLocked}
          />
        );
      case FormTemplateFieldTypes.EXPLANATION_AREA:
        return (
          <ExplanationField
            key={templateField.id}
            templateField={templateField}
          />
        );
      case FormTemplateFieldTypes.MAP_AREA:
        return (
          <MapAreaField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            onError={onError}
            projectId={projectId}
          />
        );
      case FormTemplateFieldTypes.MULTI_LOCATION:
        return (
          <MultiLocationField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.MULTI_SELECT:
        return (
          <MultiSelectField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.MULTI_SELECT_WITH_FREE_FORM:
        return (
          <MultiSelectFreeFormField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.MULTI_USER:
        return (
          <MultiUserField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            currentUser={currentUser}
          />
        );
      case FormTemplateFieldTypes.NUMBER:
        return (
          <NumberField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.PHONE_NUMBER:
        return (
          <PhoneNumberField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.PHOTO_CAROUSEL:
        return (
          <PhotoField
            key={`${templateField.id}-${submissionField.fieldValue}`}
            disabled={isStencil || isLocked}
            disabledMessage="Photo uploads are disabled when building form templates or when the form is locked."
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            projectId={projectId}
            onError={onError}
          />
        );
      case FormTemplateFieldTypes.SIGNATURE:
        return (
          <SignatureField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            currentUser={currentUser}
            onUpdateSignature={onUpdateSignature}
          />
        );
      case FormTemplateFieldTypes.SINGLE_LOCATION:
        return (
          <SingleLocationField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            isStencil={isStencil}
          />
        );
      case FormTemplateFieldTypes.SINGLE_SELECT:
        return (
          <SingleSelectField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            isStencil={isStencil}
          />
        );
      case FormTemplateFieldTypes.SINGLE_SELECT_WITH_FREE_FORM:
        return (
          <SingleSelectFreeFormField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            isStencil={isStencil}
          />
        );
      case 'tasks':
        return (
          <TasksField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            onError={onError}
            projectId={projectId}
            disabled={isStencil || isLocked}
          />
        );
      case FormTemplateFieldTypes.TEXT:
        return (
          <TextField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.TEXT_AREA:
        return (
          <TextAreaField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
          />
        );
      case FormTemplateFieldTypes.TIME:
        return (
          <TimeField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            disabled={isStencil || isLocked}
          />
        );
      case FormTemplateFieldTypes.USER:
        return (
          <SingleUserField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            currentUser={currentUser}
            isStencil={isStencil}
          />
        );
      case FormTemplateFieldTypes.WEATHER:
        return (
          <TextField
            key={templateField.id}
            templateField={templateField}
            submissionField={submissionField}
            onChange={onChange}
            disabled={isStencil || isLocked}
          />
        );
      default:
        return (
          <FormField key={templateField.id} templateField={templateField} />
        );
    }
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormFieldFactory;
