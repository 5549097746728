import React from 'react';
import MultiSignature from '../../../elements/MultiSignature';
import FormField from './FormField';

class SignatureField extends FormField {
  constructor(props) {
    super(props);
    this.originalValue = this.getValue();
  }

  getInputComponent(templateField) {
    const { submissionField, currentUser, onUpdateSignature } = this.props;
    const errorMessage = this.hasError(submissionField)
      ? submissionField.errors.join('\n')
      : null;
    const formattedOptions = this.formatOptions(templateField);

    return (
      <MultiSignature
        users={formattedOptions}
        currentUser={currentUser}
        value={this.getParsedValue(formattedOptions)}
        errorMessage={errorMessage}
        onChange={(currentSigners) => this.handleChangeSigners(currentSigners)}
        onUpdateSignature={onUpdateSignature}
      />
    );
  }

  formatOptions(templateField) {
    const { options } = templateField;
    const { currentUser } = this.props;

    const parsedValue =
      this.originalValue?.length && JSON.parse(this.originalValue);
    // if user was selected, allow it no matter what
    // if current user is NOT mapistry user, filter out mapistry users
    // if archived user, filter it out
    return options.filter((option) => {
      if (
        parsedValue &&
        parsedValue.some((v) => v.signed && v.user_id === option.id)
      ) {
        return true;
      }
      if (
        !currentUser.email.includes('@mapistry.com') &&
        option.email.includes('@mapistry.com')
      ) {
        return false;
      }
      return !option.isArchived;
    });
  }

  embeddedErrorMessage() {
    return null;
  }

  handleChangeSigners(currentSigners) {
    const { templateField, onChange } = this.props;
    onChange(templateField.slug, JSON.stringify(currentSigners));
  }

  getParsedValue(formattedOptions) {
    const value = this.getValue();
    const parsedValue = value != null && value.length ? JSON.parse(value) : [];
    const userIds = formattedOptions.map((o) => o.id);
    return parsedValue.filter((v) => userIds.includes(v.user_id));
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default SignatureField;
