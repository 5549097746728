import akIndustrialChecklist from './ak-industrial-checklist.pug';
import alIndustrialChecklist from './al-industrial-checklist.pug';
import arIndustrialChecklist from './ar-industrial-checklist.pug';
import azIndustrialChecklist from './az-industrial-checklist.pug';
import caIndustrialChecklist from './ca-industrial-checklist.pug';
import coIndustrialChecklist from './co-industrial-checklist.pug';
import ctIndustrialChecklist from './ct-industrial-checklist.pug';
import deIndustrialChecklist from './de-industrial-checklist.pug';
import federalIndustrialChecklist from './federal-industrial-checklist.pug';
import flIndustrialChecklist from './fl-industrial-checklist.pug';
import gaIndustrialChecklist from './ga-industrial-checklist.pug';
import hiIndustrialChecklist from './hi-industrial-checklist.pug';
import iaIndustrialChecklist from './ia-industrial-checklist.pug';
import ilIndustrialChecklist from './il-industrial-checklist.pug';
import inIndustrialChecklist from './in-industrial-checklist.pug';
import ksIndustrialChecklist from './ks-industrial-checklist.pug';
import kyIndustrialChecklist from './ky-industrial-checklist.pug';
import laIndustrialChecklist from './la-industrial-checklist.pug';
import mdIndustrialChecklist from './md-industrial-checklist.pug';
import meIndustrialChecklist from './me-industrial-checklist.pug';
import miIndustrialChecklist from './mi-industrial-checklist.pug';
import mnIndustrialChecklist from './mn-industrial-checklist.pug';
import moIndustrialChecklist from './mo-industrial-checklist.pug';
import msIndustrialChecklist from './ms-industrial-checklist.pug';
import mtIndustrialChecklist from './mt-industrial-checklist.pug';
import ncIndustrialChecklist from './nc-industrial-checklist.pug';
import ndIndustrialChecklist from './nd-industrial-checklist.pug';
import neIndustrialChecklist from './ne-industrial-checklist.pug';
import njIndustrialChecklist from './nj-industrial-checklist.pug';
import nvIndustrialChecklist from './nv-industrial-checklist.pug';
import nyIndustrialChecklist from './ny-industrial-checklist.pug';
import ohIndustrialChecklist from './oh-industrial-checklist.pug';
import okIndustrialChecklist from './ok-industrial-checklist.pug';
import orIndustrialChecklist from './or-industrial-checklist.pug';
import riIndustrialChecklist from './ri-industrial-checklist.pug';
import scIndustrialChecklist from './sc-industrial-checklist.pug';
import sdIndustrialChecklist from './sd-industrial-checklist.pug';
import tnIndustrialChecklist from './tn-industrial-checklist.pug';
import txIndustrialChecklist from './tx-industrial-checklist.pug';
import utIndustrialChecklist from './ut-industrial-checklist.pug';
import vaIndustrialChecklist from './va-industrial-checklist.pug';
import vtIndustrialChecklist from './vt-industrial-checklist.pug';
import waIndustrialChecklist from './wa-industrial-checklist.pug';
import wiIndustrialChecklist from './wi-industrial-checklist.pug';
import wvIndustrialChecklist from './wv-industrial-checklist.pug';
import wyIndustrialChecklist from './wy-industrial-checklist.pug';

import caConstructionChecklist from './ca-construction-checklist.pug';

const templates = {
  construction: {
    ca: caConstructionChecklist,
  },
  industrial: {
    ak: akIndustrialChecklist,
    al: alIndustrialChecklist,
    ar: arIndustrialChecklist,
    az: azIndustrialChecklist,
    ca: caIndustrialChecklist,
    co: coIndustrialChecklist,
    ct: ctIndustrialChecklist,
    de: deIndustrialChecklist,
    federal: federalIndustrialChecklist,
    fl: flIndustrialChecklist,
    ga: gaIndustrialChecklist,
    hi: hiIndustrialChecklist,
    ia: iaIndustrialChecklist,
    il: ilIndustrialChecklist,
    in: inIndustrialChecklist,
    ks: ksIndustrialChecklist,
    ky: kyIndustrialChecklist,
    la: laIndustrialChecklist,
    md: mdIndustrialChecklist,
    me: meIndustrialChecklist,
    mi: miIndustrialChecklist,
    mn: mnIndustrialChecklist,
    mo: moIndustrialChecklist,
    ms: msIndustrialChecklist,
    mt: mtIndustrialChecklist,
    nc: ncIndustrialChecklist,
    nd: ndIndustrialChecklist,
    ne: neIndustrialChecklist,
    nj: njIndustrialChecklist,
    nv: nvIndustrialChecklist,
    ny: nyIndustrialChecklist,
    oh: ohIndustrialChecklist,
    ok: okIndustrialChecklist,
    or: orIndustrialChecklist,
    ri: riIndustrialChecklist,
    sc: scIndustrialChecklist,
    sd: sdIndustrialChecklist,
    tn: tnIndustrialChecklist,
    tx: txIndustrialChecklist,
    ut: utIndustrialChecklist,
    va: vaIndustrialChecklist,
    vt: vtIndustrialChecklist,
    wa: waIndustrialChecklist,
    wi: wiIndustrialChecklist,
    wv: wvIndustrialChecklist,
    wy: wyIndustrialChecklist,
  },
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default templates;
