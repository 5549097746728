import { SamplingParameter } from '@monorepo/shared/types/industrial-stormwater-sector-data';
/* @ts-expect-error - old Mapistry package without TS */
import sectorData from 'industrial-stormwater-sector-data';
import React, { createContext, useContext } from 'react';
import { useProject } from '../../contexts/ProjectContext';

// initialize the context with undefined so that the error in the hook will be
// thrown if we forget to use the Provider
const SamplingParameterContext = createContext<SamplingParameter[] | undefined>(
  undefined,
);

interface SamplingParameterProviderProps {
  children: React.ReactNode;
}

export const SamplingParameterProvider = ({
  children,
}: SamplingParameterProviderProps) => {
  const { project } = useProject();
  const allParameters = sectorData(
    project?.state,
  ).getAvailableSamplingParameters();
  return (
    <SamplingParameterContext.Provider value={allParameters}>
      {children}
    </SamplingParameterContext.Provider>
  );
};

export const useSamplingParameters = () => {
  const context = useContext(SamplingParameterContext);
  if (context === undefined) {
    throw new Error(
      'useSamplingParameters must be used within a SamplingParameterProvider',
    );
  }
  return context;
};
