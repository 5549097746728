var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (locationUrl, projectName) {
      pug_html = pug_html + "\u003Cdiv class=\"stormwater-documents\"\u003E\u003Cdiv class=\"flex right-col-heading justify-content-space-between heading\"\u003E\u003Cdiv class=\"flex align-items-center\"\u003E\u003Cdiv class=\"list-header\"\u003EDocuments List\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"content padding-20\"\u003E\u003Ca" + (" class=\"link-color font-size-16\""+pug.attr("href", locationUrl, true, true)+" target=\"_blank\"") + "\u003E" + (pug.escape(null == (pug_interp = `Click here to view the documents for ${projectName}`) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "locationUrl" in locals_for_with ?
        locals_for_with.locationUrl :
        typeof locationUrl !== 'undefined' ? locationUrl : undefined, "projectName" in locals_for_with ?
        locals_for_with.projectName :
        typeof projectName !== 'undefined' ? projectName : undefined));
    ;;return pug_html;};
module.exports = template;