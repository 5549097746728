import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import {
  AccordionContainer,
  AddButton,
  ConfirmDialog,
  EditableText,
} from '../../../../elements';

import DropPlaceholder from '../DropPlaceholder';

class DroppableGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowDeleteConfirmation: false,
      isExpanded: false,
    };
    this.handleEditGroupName = this.handleEditGroupName.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
  }

  handleDeleteGroup(groupId) {
    const { onDeleteGroup } = this.props;
    this.setState(
      {
        shouldShowDeleteConfirmation: false,
      },
      () => {
        onDeleteGroup(groupId);
      },
    );
  }

  handleEditGroupName(nextName) {
    const { handleEditGroupName, groupId } = this.props;
    this.setState({ isEditingGroup: false }, () => {
      if (nextName.length) {
        handleEditGroupName(nextName, groupId);
      }
    });
  }

  render() {
    const {
      addItemAndScrollToIt,
      children,
      groups,
      groupId,
      isDragging,
      itemCount,
      renderRowWhileBeingDragged,
    } = this.props;

    const { isExpanded, isEditingGroup, shouldShowDeleteConfirmation } =
      this.state;

    const accordionTitleClassName = (id) => {
      if (id === 'null') {
        return 'display-none__override';
      }
      return '';
    };

    const groupName = _get(groups[groupId], 'name');

    return (
      <>
        <ConfirmDialog
          confirmButtonText="Delete group"
          danger
          description="Are you sure you want to delete this group? Items within the group will become ungrouped."
          onCancelled={() =>
            this.setState({
              shouldShowDeleteConfirmation: false,
            })
          }
          onConfirmed={this.handleDeleteGroup}
          open={shouldShowDeleteConfirmation}
          title="Confirm deletion"
        />
        <Droppable
          droppableId={groupId}
          mode="virtual"
          renderClone={(provided, source, rubric) => (
            <div
              className="mapistry-table__row"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {renderRowWhileBeingDragged(rubric.source.index)}
            </div>
          )}
        >
          {(dropProvided, dropSnapshot) => (
            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
              <div
                onMouseEnter={this.handleDragIn}
                onMouseLeave={this.handleDragOut}
              >
                <AccordionContainer
                  className="group__container "
                  isExpanded={groupId === 'null' || isExpanded}
                  onEdit={() => this.setState({ isEditingGroup: true })}
                  onDelete={() =>
                    this.setState({
                      shouldShowDeleteConfirmation: true,
                    })
                  }
                  onToggle={() =>
                    this.setState((prevState) => ({
                      isExpanded: !prevState.isExpanded,
                    }))
                  }
                  title={
                    <div className="group-name__content">
                      <EditableText
                        isEditing={isEditingGroup}
                        onEdit={this.handleEditGroupName}
                        text={groupName}
                      />
                      <div className="group-name__count">{itemCount}</div>
                    </div>
                  }
                  titleClassName={accordionTitleClassName(groupId)}
                >
                  {children({ dropSnapshot })}
                  {itemCount === 0 && (
                    <DropPlaceholder
                      isDragging={isDragging}
                      groupName={groupName}
                    />
                  )}
                  <div className="mapistry-table__row bg-white__override">
                    <AddButton
                      onClick={() =>
                        addItemAndScrollToIt(
                          groupId === 'null' ? null : groupId,
                        )
                      }
                    >{`Add an item ${
                      groupName ? `to ${groupName}` : ''
                    }`}</AddButton>
                  </div>
                </AccordionContainer>
              </div>
            </div>
          )}
        </Droppable>
      </>
    );
  }
}

DroppableGroup.propTypes = {
  addItemAndScrollToIt: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groups: PropTypes.shape({}).isRequired,
  handleEditGroupName: PropTypes.func.isRequired,
  renderRowWhileBeingDragged: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DroppableGroup;
