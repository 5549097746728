/* eslint-disable */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Method22, timeToDate } from 'mapistry-shared';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { KeyService } from '@monorepo/shared/KeyService';
import FormField from './FormField';
import {
  Button,
  TextField,
  TimePicker,
  FieldWrapper,
  HelpIcon,
} from '../../../elements';

const START_ERROR = {
  id: uuidv4(),
  message: 'The time must be after the beginning of the break.',
};
const EMISSION_ERROR = {
  id: uuidv4(),
  message: 'Emission time must be lower than observation time',
};

class EmissionObservationField extends FormField {
  getInputComponent() {
    const { disabled } = this.props;
    this.value = this.getParsedValue();
    const method22Info = new Method22(this.value);

    return (
      <>
        <div className="entries">
          {this.entryHeader()}
          {this.entries(disabled)}
          {this.addEntryButton(disabled)}
          {this.endRow(method22Info)}
        </div>
        <div className="summary">
          {this.summaryHeader()}
          {this.summaryRow(method22Info)}
        </div>
      </>
    );
  }

  getParsedValue() {
    const value = this.getValue();
    return value && value.length
      ? JSON.parse(value)
      : [
          {
            id: uuidv4(),
            startTime: null,
            observationMinutes: 0,
            observationSeconds: 0,
            emissionMinutes: 0,
            emissionSeconds: 0,
          },
        ];
  }

  fieldClassName() {
    return 'emission-observation-field';
  }

  entryHeader() {
    return (
      <div className="header-row">
        <span className="col-1">Clock Time</span>
        <span className="col-2">Observation Period Duration</span>
        <span className="col-3">Accumulated Emission Time</span>
        <span className="col-4" />
      </div>
    );
  }

  entries(disabled) {
    return this.value.map((entry, idx) => {
      const showTrash = idx > 0;
      const prevEntry = this.value[idx - 1];
      const breakTime = this.breakTime(prevEntry, entry);
      const errors = this.validate(entry, breakTime);

      return (
        <React.Fragment key={entry.id}>
          {prevEntry && this.breakRow(breakTime)}
          {this.observationRow(disabled, entry, errors, showTrash)}
          {this.errorRow(errors)}
        </React.Fragment>
      );
    });
  }

  observationRow(disabled, entry = {}, errors = [], shouldShowTrash = false) {
    const {
      id,
      startTime,
      observationMinutes,
      observationSeconds,
      emissionMinutes,
      emissionSeconds,
    } = entry;

    return (
      <div className="observation-row" key={`observation-${id}`}>
        <div className="col-1">
          <FieldWrapper label="Clock Time">
            <TimePicker
              className="emission-time-field"
              value={timeToDate(startTime)}
              onChange={(value) => this.handleStartTimeChange(entry, value)}
              clearable
              autoOk
              disabled={disabled}
              error={errors.includes(START_ERROR)}
            />
          </FieldWrapper>
        </div>
        <div className="col-2">
          <FieldWrapper label="Observation Period Duration">
            <TextField
              controlled
              type="number"
              placeholder="0"
              disabled={disabled}
              value={observationMinutes}
              onChange={(e) =>
                this.handleDurationChange(
                  entry,
                  'observationMinutes',
                  e.target.value,
                )
              }
            />
            <span className="number-label">minutes</span>
            <TextField
              controlled
              type="number"
              placeholder="0"
              disabled={disabled}
              value={observationSeconds}
              onChange={(e) =>
                this.handleDurationChange(
                  entry,
                  'observationSeconds',
                  e.target.value,
                )
              }
            />
            <span className="number-label">seconds</span>
          </FieldWrapper>
        </div>
        <div className="col-3">
          <FieldWrapper label="Accumulated Emission Time">
            <TextField
              controlled
              type="number"
              placeholder="0"
              disabled={disabled}
              value={emissionMinutes}
              error={errors.includes(EMISSION_ERROR)}
              onChange={(e) =>
                this.handleDurationChange(
                  entry,
                  'emissionMinutes',
                  e.target.value,
                )
              }
            />
            <span className="number-label">minutes</span>
            <TextField
              controlled
              type="number"
              placeholder="0"
              disabled={disabled}
              value={emissionSeconds}
              error={errors.includes(EMISSION_ERROR)}
              onChange={(e) =>
                this.handleDurationChange(
                  entry,
                  'emissionSeconds',
                  e.target.value,
                )
              }
            />
            <span className="number-label">seconds</span>
          </FieldWrapper>
        </div>
        <div className="col-4">
          {this.deleteEntryButton(id, shouldShowTrash)}
        </div>
      </div>
    );
  }

  deleteEntryButton(id, shouldShow) {
    return shouldShow ? (
      <>
        <span
          role="button"
          className="remove-entry-icon"
          onClick={() => this.handleDestroyEntry(id)}
          onKeyDown={(e) => {
            if (KeyService.triggersButtonClick(e.keyCode))
              this.handleDestroyEntry(id);
          }}
          tabIndex={0}
        >
          <SvgIcon identifier="trash" />
        </span>
        <Button
          color="danger"
          className="remove-entry-button"
          onClick={() => this.handleDestroyEntry(id)}
        >
          Delete Time Entry
        </Button>
      </>
    ) : null;
  }

  breakRow(breakTime) {
    return (
      <div className="break-row">
        <FieldWrapper label="Break">
          <TimePicker
            className="emission-time-field"
            value={breakTime.start}
            clearable
            autoOk
            disabled
          />
          <span className="break-time-split">to</span>
          <TimePicker
            className="emission-time-field"
            value={breakTime.end}
            clearable
            autoOk
            disabled
          />
        </FieldWrapper>
      </div>
    );
  }

  errorRow(errors) {
    return (
      <div className="entry-error">
        {errors.map((error) => (
          <li key={error.id}>{error.message}</li>
        ))}
      </div>
    );
  }

  addEntryButton(disabled) {
    return (
      <Button
        className="create-entry-button"
        variant="text"
        disabled={disabled}
        onClick={() => this.handleCreateEntry()}
      >
        <SvgIcon identifier="add" className="create-entry-button-icon" />
        <span>Add Time Entry</span>
      </Button>
    );
  }

  endRow(method22Info) {
    return (
      <div className="end-row">
        <FieldWrapper label="End">
          <TimePicker
            className="emission-time-field"
            value={method22Info.endTimeAsDate()}
            clearable
            autoOk
            disabled
          />
        </FieldWrapper>
      </div>
    );
  }

  summaryHeader() {
    return (
      <div className="header-row">
        <span className="col-1">
          Observation Period
          <HelpIcon text="Observation period means the accumulated time period during which observations are conducted, not to be less than the period specified in the applicable regulation." />
        </span>
        <span className="col-2">
          Emissions Frequency
          <HelpIcon text="Emission frequency means the percentage of time that emissions are visible during the observation period." />
        </span>
        <span className="col-3">
          Emissions Time
          <HelpIcon text="Emission time means the accumulated amount of time that emissions are visible during the observation period." />
        </span>
      </div>
    );
  }

  summaryRow(method22Info) {
    return (
      <div className="observation-row">
        <span className="col-1">
          <FieldWrapper label="Observation Period">
            {method22Info.totalObservationDuration()}
          </FieldWrapper>
        </span>
        <span className="col-2">
          <FieldWrapper label="Emissions Frequency">
            {method22Info.emissionsFrequency()}
          </FieldWrapper>
        </span>
        <span className="col-3">
          <FieldWrapper label="Emissions Time">
            {method22Info.totalEmissionDuration()}
          </FieldWrapper>
        </span>
      </div>
    );
  }

  breakTime(entry1 = {}, entry2 = {}) {
    const { startTime, observationSeconds, observationMinutes } = entry1;
    let start;
    const end = timeToDate(entry2.startTime);

    if (startTime) {
      start = timeToDate(startTime);
      start.setSeconds(start.getSeconds() + observationSeconds);
      start.setMinutes(start.getMinutes() + observationMinutes);
    }

    return { start, end };
  }

  validate(entry, breakTime) {
    const {
      startTime,
      observationMinutes,
      observationSeconds,
      emissionMinutes,
      emissionSeconds,
    } = entry;
    const errors = [];
    const entryStartTime = timeToDate(startTime);
    if (entryStartTime && entryStartTime <= breakTime.start) {
      errors.push(START_ERROR);
    }
    const observationTime = observationMinutes + observationSeconds / 60;
    const emissionTime = emissionMinutes + emissionSeconds / 60;
    if (emissionTime > observationTime) {
      errors.push(EMISSION_ERROR);
    }
    return errors;
  }

  handleCreateEntry() {
    const { onChange, templateField } = this.props;
    const newValue = [
      ...this.value,
      {
        id: uuidv4(),
        startTime: null,
        observationMinutes: 0,
        observationSeconds: 0,
        emissionMinutes: 0,
        emissionSeconds: 0,
      },
    ];
    onChange(templateField.slug, JSON.stringify(newValue));
  }

  handleDestroyEntry(id) {
    const { onChange, templateField } = this.props;
    const newValue = this.value.filter((v) => v.id !== id);
    onChange(templateField.slug, JSON.stringify(newValue));
  }

  handleStartTimeChange(entry, newStartTime) {
    const { onChange, templateField } = this.props;
    let startTime;
    if (newStartTime) {
      const zeroPaddedHours = `0${newStartTime.getHours()}`.slice(-2);
      const zeroPaddedMinutes = `0${newStartTime.getMinutes()}`.slice(-2);
      startTime = `${zeroPaddedHours}${zeroPaddedMinutes}`;
    }

    const newValue = this.value.map((v) => {
      if (v.id === entry.id) {
        return { ...v, startTime };
      }
      return v;
    });

    onChange(templateField.slug, JSON.stringify(newValue));
  }

  handleDurationChange(updatedEntry, fieldKey, fieldValue) {
    const { onChange, templateField } = this.props;
    const newValue = this.value.map((entry) => {
      if (entry.id === updatedEntry.id) {
        const updatedValue = fieldValue.length ? parseInt(fieldValue, 10) : 0;
        return { ...entry, [fieldKey]: updatedValue };
      }
      return entry;
    });

    onChange(templateField.slug, JSON.stringify(newValue));
  }
}

export default EmissionObservationField;
