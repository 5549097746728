/* eslint-disable */
var APP = require('../../../config');
import checklistTemplates from './templates';

var ChecklistView = Backbone.Marionette.ItemView.extend({
  events: {
    'click [type="checkbox"]': 'toggleCheckbox',
  },

  initialize: function (options) {
    this._setTemplate(options.project);
  },

  _setTemplate: function (project) {
    this.template = this._getTemplateFromProject(project);
  },

  _getTemplateFromProject: function (project) {
    var state = project.get('state');
    var industryType = project.get('industry_type');

    if (/construction/.test(industryType)) {
      return require('./templates/ca-construction-checklist.pug');
    }

    if (_.contains([true, 'true'], project.get('is_federal'))) {
      return require('./templates/federal-industrial-checklist.pug');
    } else {
      return checklistTemplates.industrial[state];
    }
  },

  templateHelpers: function () {
    var collection = this.collection;
    return {
      isItemChecked: function (name) {
        var model = collection.findWhere({ step_name: name });
        return model && model.get('is_checked');
      },
    };
  },

  onShow: function () {
    var popovers = this.$('.badge').popover({
      container: 'body',
      html: true,
    });

    APP.vent.on('body:clicked', function (e) {
      var $target = $(e.target);

      if (!($target.hasClass('badge') || $target.hasClass('popover-content'))) {
        popovers.popover('hide');
      }
    });
  },

  toggleCheckbox: function (e) {
    var stepName = e.target.name;
    var stepChecked = $(e.target).is(':checked');

    var model = this.collection.findWhere({ step_name: stepName });

    if (model) {
      model.save({ is_checked: stepChecked });
    } else {
      this.collection.create({ is_checked: stepChecked, step_name: stepName });
    }
  },
});

module.exports = ChecklistView;
