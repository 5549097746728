/* eslint-disable */
var OpacitySliderView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/opacitySlider.pug'),

  ui: {
    $input: 'input',
    $displayedValue: '#opacity-val',
  },

  events: {
    'change @ui.$input': '_updateDisplayValue',
    'input @ui.$input': '_updateDisplayValue',
    'mouseup @ui.$input': '_updateSavedValue',
    'touchend @ui.$input': '_updateSavedValue',
  },

  behaviors: {
    Tooltip: {
      gravity: 'w',
    },
  },

  _getCurrentValue: function () {
    return this.ui.$input.val();
  },

  _updateDisplayValue: function () {
    var currentValue = this._getCurrentValue();
    this.ui.$displayedValue.text(currentValue);
    this.model.set({ opacity: currentValue });
    this.trigger('change', currentValue);
  },

  _updateSavedValue: function () {
    this.model.save();
  },
});

module.exports = OpacitySliderView;
