/* eslint-disable */
let BaseRainLogCollection = require('./BaseRainLogCollection');
let moment = require('moment');

let RainLogCollection = BaseRainLogCollection.extend({
  parse(models) {
    models = _.filter(
      models,
      (m) =>
        ((m.confirmed === null || m.confirmed === true) && !m.user_generated) ||
        m.user_generated,
    );
    return this._isGrouped || this._shouldGroup(models)
      ? this._groupByMonth(models)
      : this._doNotGroup(models);
  },

  isGrouped() {
    return this._isGrouped;
  },

  comparator(x, y) {
    let xMoment, yMoment;
    if (!this.isGrouped()) {
      xMoment = moment(x.get('storm_start_time'), 'YYYY MM DD');
      yMoment = moment(y.get('storm_start_time'), 'YYYY MM DD');
    } else {
      xMoment = moment(x.get('groupId'), this._groupIdFormat());
      yMoment = moment(y.get('groupId'), this._groupIdFormat());
    }

    if (xMoment.isBefore(yMoment)) return -1;
    if (xMoment.isSame(yMoment)) return 0;
    return 1;
  },

  create(attrs, options) {
    if (!this.isGrouped()) {
      return BaseRainLogCollection.prototype.create.call(this, attrs, options);
    }

    let stormStartMoment = moment(attrs.storm_start_time).utc();
    let groupId = stormStartMoment.format(this._groupIdFormat());
    let existingGroup = this.findWhere({ groupId });
    if (existingGroup) {
      return existingGroup.get('collection').create(attrs, options);
    }

    let collection = new BaseRainLogCollection([], {
      projectId: this.projectId,
    });
    this.add({
      collection,
      groupId,
      label: stormStartMoment.format(this._labelFormat()),
      lastGroup: false,
    });
    return collection.create(attrs, options);
  },

  unGroup() {
    let ungroupedModels = [];
    _.each(this.models, (c) =>
      c.get('collection').each((m) => ungroupedModels.push(m)),
    );
    return ungroupedModels;
  },

  _shouldGroup: function (models) {
    let startTimes = _.unique(
      _.map(_.pluck(models, 'storm_start_time'), (s) =>
        moment(s).utc().format('YYYY MM'),
      ),
    );
    return startTimes.length > 1 && models.length > 15;
  },

  _doNotGroup(models) {
    this._isGrouped = false;
    return models;
  },

  _groupByMonth(models) {
    this._isGrouped = true;
    let groupedModels = this._groupModels(models);
    let keys = _.keys(groupedModels).sort();
    let lastKey = keys[keys.length - 1];

    return _.map(groupedModels, (models, dateIdentifier) => {
      let dateIdentifierAsMoment = moment(dateIdentifier, 'YYYY MM');
      return {
        collection: new BaseRainLogCollection(models, {
          projectId: this.projectId,
        }),
        groupId: dateIdentifierAsMoment.format(this._groupIdFormat()),
        label: dateIdentifierAsMoment.format(this._labelFormat()),
        lastGroup: dateIdentifier === lastKey,
      };
    });
  },

  _groupModels(models) {
    return _.groupBy(models, (r) =>
      moment(r.storm_start_time).utc().format('YYYY MM'),
    );
  },

  _groupIdFormat() {
    return 'YYYY-MM';
  },

  _labelFormat() {
    return 'YYYY MMM';
  },
});

module.exports = RainLogCollection;
