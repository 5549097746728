/* eslint-disable */
let organizationManagementDataProvider = require('../organizationManagement/dataProvider');

let RelatedUsersMultiSelectView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/relatedUsersMultiSelect.pug'),

  ui: {
    $select: 'select',
  },

  initialize(options = {}) {
    this.organizationId = options.organizationId;
  },

  activate(emailsToExclude, options = {}) {
    return this._getUsers(emailsToExclude).then((users) => {
      this.ui.$select.select2({
        tags: true,
        width: options.width || '300px',
        placeholder: options.placeholder,
        tokenSeparators: [',', ' '],
        data: this.getFormattedUserData(users),
      });
      this._initializeSelectOnBlur();
    });
  },

  _getUsers(emailsToExclude) {
    return organizationManagementDataProvider
      .getOrganizationUsers(this.organizationId)
      .then((users) =>
        users.filter((user) => !_.contains(emailsToExclude, user.email)),
      );
  },

  _initializeSelectOnBlur() {
    this.$el.on(
      'blur',
      '.select2-search__field',
      function (e) {
        let manuallyEnteredEmail = e.target.value;
        if (manuallyEnteredEmail) {
          this._addEmailToValues(manuallyEnteredEmail);
        }
      }.bind(this),
    );
  },

  _addEmailToValues(email) {
    let emails = this.getValues() || [];
    emails.push(email);
    this.ui.$select.val(emails).trigger('change');
  },

  getFormattedUserData(users) {
    return users.map(function (user) {
      let text = user.name + ' (' + user.email + ')';
      return { text: text, id: user.email, userName: user.name };
    }, this);
  },

  getValues() {
    return this.ui.$select.val();
  },

  clear() {
    this.ui.$select.empty().trigger('change');
  },
});

module.exports = RelatedUsersMultiSelectView;
