import React from 'react';
import { isValidDate } from '../../../../utils';
import DatePicker from '../../../elements/DatePicker';
import FormField from './FormField';

class DateField extends FormField {
  constructor(props) {
    super(props);

    if (
      this.getValue() == null &&
      props.templateField.dynamicallyGenerateDefault
    ) {
      this.handleDateChange(new Date());
    }
  }

  // TODO: add warning if is submission date field and value is outside of interval
  getInputComponent(templateField, error) {
    const { disabled } = this.props;

    return (
      <DatePicker
        autoOk
        className="date-field"
        clearable
        disabled={disabled}
        error={error}
        onChange={(date, textValue) => this.handleDateChange(date, textValue)}
        value={this.getDateValue()}
      />
    );
  }

  getDateValue() {
    const fieldValue = this.getValue();

    if (fieldValue == null || !fieldValue.length) {
      return null;
    }

    return new Date(`${fieldValue}T00:00:00`);
  }

  handleDateChange(date, textValue) {
    const { templateField, onChange } = this.props;
    if (date && isValidDate(date)) {
      const dateString = this.formatDateValue(date);
      return onChange(templateField.slug, dateString);
    }
    if (date && !textValue.includes('_')) {
      return onChange(templateField.slug, null, ['Invalid date']);
    }
    return onChange(templateField.slug, null);
  }

  // this also exists on the backend when generating a default value
  formatDateValue(date) {
    const zeroPaddedMonth = `0${date.getMonth() + 1}`.slice(-2);
    const zeroPaddedDay = `0${date.getDate()}`.slice(-2);
    return `${date.getFullYear()}-${zeroPaddedMonth}-${zeroPaddedDay}`;
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default DateField;
