/* eslint-disable */
// Views
var BaseModalView = require('../modals/BaseModalView');
var GenericModalView = require('../modals/GenericModalView');
var EditLayerLayoutView = require('./EditLayerLayoutView');

// Services
var APP = require('../config');

var EditLayerModalView = BaseModalView.extend({
  render() {
    let currentUserArbiter = require('../permissions/currentUserArbiter');
    currentUserArbiter
      .hasProjectUpdatePermission(APP.projectId)
      .bind(this)
      .then(function (hasProjectUpdatePermission) {
        let genericModalView = new GenericModalView();
        genericModalView.render({ header: 'Edit Layer' });

        if (!hasProjectUpdatePermission) {
          return genericModalView._showErrors(
            'You do not have permissions to edit this layer',
          );
        }

        let editLayerLayoutView = new EditLayerLayoutView({
          model: this.model,
        });
        this.listenTo(
          APP.vent,
          'editLayerStylesView:error editLayerDetailsView:error',
          genericModalView._showErrors.bind(genericModalView),
        );
        this.listenTo(
          APP.vent,
          'editLayerStylesView:success editLayerDetailsView:success',
          genericModalView.close.bind(genericModalView),
        );

        genericModalView.appendView(editLayerLayoutView);
        genericModalView.childViews.push(this);
      });
    return this;
  },
});

module.exports = EditLayerModalView;
