/* eslint-disable */
module.exports = Backbone.Marionette.CollectionView.extend({
  emptyView: require('./FeaturePhotoEmptyView'),

  getChildView: function () {
    return require('./FeaturePhotoItemView');
  },

  // initialize: function() {
  //   console.log('COLLECTION INIT');
  //   console.log(this.collection);
  // },
});
