import { KeyService } from '@monorepo/shared/KeyService';
import { SvgIcon } from '@monorepo/shared/components/icons/SvgIcon';
import { SaveState } from '@monorepo/shared/types/SaveState';
import React, { useMemo, useState } from 'react';
import { Button, Modal, SaveButton, SelectCreatable } from '../../../elements';

type EditSelectOptionsModalProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: object[];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: (options: object[]) => void;
  title: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EditSelectOptionsModal = (props: EditSelectOptionsModalProps) => {
  const { options, onClose, title } = props;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [nextOptions, setNextOptions] = useState(options || []);

  const footer = useMemo(
    () => (
      <div className="modal-footer__actions">
        <Button
          color="secondary"
          onClick={() => {
            setNextOptions(options || []);
            setFormIsDirty(false);
            setModalIsVisible(false);
          }}
        >
          {formIsDirty ? 'Cancel' : 'Close'}
        </Button>
        <span className="ph2" />
        <SaveButton
          onSave={() => {
            if (nextOptions?.length) {
              onClose(nextOptions);
            }
            setFormIsDirty(false);
          }}
          saveState={formIsDirty ? SaveState.DIRTY : SaveState.CLEAN}
        />
      </div>
    ),
    [nextOptions, formIsDirty, onClose, options],
  );

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="notes-popover__target-container emission-tracking__action grow"
        onClick={() => setModalIsVisible(true)}
        onKeyDown={(e) => {
          if (KeyService.triggersButtonClick(e.keyCode)) {
            setModalIsVisible(true);
          }
        }}
      >
        <SvgIcon
          className="margin-left-5 dark-blue"
          identifier="cog"
          tooltipTitle="Select Options"
        />
      </div>
      {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
      <Modal
        fullWidth={false}
        header={<h4 className="modal-title">{`${title} - Create options`}</h4>}
        maxWidth="lg"
        onClose={() => setModalIsVisible(false)}
        open={modalIsVisible}
        footer={footer}
      >
        <div className="edit-select-options-modal-content">
          <div className="edit-select-options-modal-title">
            Single select options
          </div>
          <SelectCreatable
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            options={nextOptions}
            /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
            value={nextOptions}
            onChange={(opts) => {
              setNextOptions(opts);
              setFormIsDirty(true);
            }}
            isMulti
          />
        </div>
      </Modal>
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default EditSelectOptionsModal;
