import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';

import { NotesPopover, TABLE_SETTINGS } from '../shared';

import { Select, TableAction, TR, TD, TextField } from '../../../elements';

import Units from '../../../../utils/units';
import { isNullOrUndefined } from '../../../../utils';

const { COLUMN_WIDTHS, COLUMN_GROWTH } = TABLE_SETTINGS.UNIT_CONVERSIONS;

const UnitConversionsRow = (props) => {
  const {
    calculatedValues,
    deleteUnitConversion,
    emissionFactors,
    formErrors,
    groupId,
    loggedItems,
    selectMenuPortalRef,
    stageEdit,
    unitConversion,
    unitConversionIdx,
  } = props;

  const allItemsToSelect = () =>
    calculatedValues
      .concat(loggedItems)
      .concat(emissionFactors)
      .map((item) => ({
        label: item.name,
        value: item.id,
        resourceType: item.resourceType,
        resourceId: item.id,
        resourceUnits: item.units,
      }));

  const getRelatedUnitsByResourceId = (resourceId) => {
    if (isNullOrUndefined(resourceId)) {
      return [];
    }
    const allItems = allItemsToSelect();
    const matchingItem = allItems.find(
      (item) => item.resourceId === resourceId,
    );
    const relatedUnits = Units.getRelatedUnits(
      _get(matchingItem, 'resourceUnits'),
    );
    return relatedUnits.filter(
      (unit) =>
        unit.value !== matchingItem?.resourceUnits && unit.value !== 'unitless',
    );
  };

  const stageChangedValue = useCallback(
    (editField, nextValue) => {
      stageEdit({ editField, nextValue }, { unitConversionIdx, groupId });
    },
    [groupId, unitConversionIdx, stageEdit],
  );

  const deleteOnClick = useCallback(
    () => deleteUnitConversion(unitConversionIdx, groupId),
    [groupId, unitConversionIdx, deleteUnitConversion],
  );

  const sharedSelectProps = {
    menuPlacement: 'auto',
    menuPortalTarget: selectMenuPortalRef.current,
    isClearable: false,
  };

  return (
    <TR>
      <TD width={COLUMN_WIDTHS[0]} growth={COLUMN_GROWTH[0]}>
        <TextField
          autoFocus={!unitConversion.name}
          controlled={false}
          defaultValue={unitConversion.name || ''}
          error={
            !!_get(formErrors, `groups.${groupId}.${unitConversionIdx}.name`)
          }
          onBlur={(e) => stageChangedValue('name', e.target.value)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[1]} growth={COLUMN_GROWTH[1]}>
        <Select
          {...sharedSelectProps}
          error={
            !!_get(
              formErrors,
              `groups.${groupId}.${unitConversionIdx}.resourceId`,
            )
          }
          options={allItemsToSelect()}
          onChange={(opt) =>
            stageChangedValue('resource', {
              resourceType: opt.resourceType,
              resourceId: opt.resourceId,
              resourceUnits: opt.resourceUnits,
            })
          }
          value={allItemsToSelect().find(
            (o) => o.value === unitConversion.resourceId,
          )}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[2]} growth={COLUMN_GROWTH[2]}>
        <Select
          {...sharedSelectProps}
          error={
            !!_get(formErrors, `groups.${groupId}.${unitConversionIdx}.units`)
          }
          isDisabled={isNullOrUndefined(unitConversion.resourceId)}
          options={getRelatedUnitsByResourceId(unitConversion.resourceId)}
          onChange={(opt) => stageChangedValue('units', opt.value)}
          value={getRelatedUnitsByResourceId(unitConversion.resourceId).find(
            (o) => o.value === unitConversion.units,
          )}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[3]} growth={COLUMN_GROWTH[3]}>
        <NotesPopover
          notes={unitConversion.notes}
          onClose={(nextNotes) => stageChangedValue('notes', nextNotes)}
        />
      </TD>
      <TD width={COLUMN_WIDTHS[4]} growth={COLUMN_GROWTH[4]}>
        <TableAction
          actionType="delete"
          deleteTooltipText="Delete row"
          onClick={deleteOnClick}
        />
      </TD>
    </TR>
  );
};

UnitConversionsRow.propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteUnitConversion: PropTypes.func.isRequired,
  emissionFactors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
  unitConversion: PropTypes.shape({
    name: PropTypes.string,
    resourceId: PropTypes.string,
    units: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  unitConversionIdx: PropTypes.number.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default React.memo(UnitConversionsRow);
