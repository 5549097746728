import { useConfirmDialog } from '@monorepo/shared/hooks/useConfirmDialog';
import { SaveState } from '@monorepo/shared/types/SaveState';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useProject } from '../../../contexts/ProjectContext';
import {
  CustomLogTemplateMenuCallbackType,
  CustomLogTemplateSection,
  useCustomLogTemplates,
} from '../../../hooks/genericLogs/useCustomLogTemplates';
import { FormActions, Menu, Page, Tabs, withAnimation } from '../../elements';
import { ComponentPicker } from '../ComponentPicker';
import { LinkToLogsDashboardFromWithinBackboneApp } from '../LinkToLogsDashboardFromWithinBackboneRouter';
import { LogProjectProvider } from '../LogProjectContext';
import { SamplingParameterProvider } from '../SamplingParameterContext';
import LogProjectHeader from './LogProjectHeader';

const GenericLogSettings = memo(() => {
  const { logProjectId, projectId } = useParams<{
    logProjectId: string;
    projectId: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const logTemplate = useCustomLogTemplates();
  const { project } = useProject();
  const { sections } = logTemplate.settings;
  const selectMenuPortalRef = useRef<HTMLDivElement>(null);
  const [formSaveState, setFormSaveState] = useState(SaveState.CLEAN);
  const [shouldSubmitFormInTab, setShouldSubmitFormInTab] = useState<
    CustomLogTemplateSection | undefined
  >();
  const confirm = useConfirmDialog();

  const baseUrl = `/projects/${projectId}/custom-logs/${logProjectId}/settings`;
  const baseUrlWithParams =
    '/projects/:projectId/custom-logs/:logProjectId/settings';
  const getOptionPath = (path = '') => `${baseUrl}${path}`;
  const getOptionPathWithParams = (path = '') => `${baseUrlWithParams}${path}`;
  const selectedTab = sections.find(
    (option) => pathname === getOptionPath(option.path),
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const confirmExitWithoutSave = async () => {
    if (formSaveState !== SaveState.DIRTY) return true;

    const userConfirmsExiting = await confirm({
      title: 'Are you sure you want to exit without saving?',
      description:
        "Any updates you've entered since you last saved will be lost if you proceed.",
      confirmButtonText: 'Exit without saving',
    });

    return userConfirmsExiting;
  };

  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  const handleChangeTab = async (option) => {
    const shouldNavigateAnyways = await confirmExitWithoutSave();
    if (!shouldNavigateAnyways) return;

    history.push(getOptionPath(option.path));
    setFormSaveState(SaveState.CLEAN);
  };

  const handleCancelForm = async () => {
    const shouldNavigateAnyways = await confirmExitWithoutSave();
    if (!shouldNavigateAnyways) return;
    const logsSubnavUrl = `/vortex/organizations/${project?.organizationId}/sites/${project?.id}/logs`;
    window.location.href = logsSubnavUrl;
  };

  const navigateToLogView = useCallback(async () => {
    const shouldNavigateAnyways = await confirmExitWithoutSave();
    if (!shouldNavigateAnyways) return;

    history.push(`/projects/${projectId}/custom-logs/${logProjectId}`);
  }, [confirmExitWithoutSave, history, logProjectId, projectId]);
  const {
    settings: { menu: templateMenu },
  } = useCustomLogTemplates();
  const menuOptions = useMemo(() => {
    const callbackMap = {
      [CustomLogTemplateMenuCallbackType.logView]: navigateToLogView,
    };
    return templateMenu.map((option) => ({
      ...option,
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      onClick: callbackMap[option.type],
    }));
  }, [navigateToLogView, templateMenu]);

  const sharedTableProps = {
    logProjectId,
    setFormSaveState,
    setHasSubmittedForm: () => setShouldSubmitFormInTab(undefined),
    selectMenuPortalRef,
  };

  return (
    <Page>
      <div ref={selectMenuPortalRef} className="mapistry-select" />
      <div className="page-wrapper__with-footer__body">
        <div className="page__back-link-container">
          <LinkToLogsDashboardFromWithinBackboneApp projectId={projectId} />
          <Menu
            className="emission-tracking-settings__menu"
            options={menuOptions}
          />
        </div>
        <LogProjectHeader />
        <div className="emission-tracking__container">
          <div className="tabs__container">
            <Tabs
              options={sections}
              onSelectTab={handleChangeTab}
              selected={selectedTab}
            />
          </div>
          <div className="emission-tracking__body">
            <Switch>
              {sections.map((option) => (
                <Route
                  key={option.type}
                  exact
                  path={getOptionPathWithParams(option.path)}
                  render={() =>
                    withAnimation(
                      <ComponentPicker
                        type={option.type}
                        {...sharedTableProps}
                        shouldSubmitForm={shouldSubmitFormInTab === option}
                      />,
                    )
                  }
                />
              ))}
              {/* @ts-expect-error - TODO: Fix this the next time the file is edited. */}
              <Redirect to={getOptionPathWithParams(sections[0].path)} />
            </Switch>
          </div>
        </div>
      </div>
      <div className="page-wrapper__with-footer__footer">
        <FormActions
          formSaveState={formSaveState}
          onSave={() => setShouldSubmitFormInTab(selectedTab)}
          onClose={handleCancelForm}
          saveText="Save Settings"
        />
      </div>
    </Page>
  );
});

export const GenericLogSettingsContainer = memo(() => {
  const { logProjectId } = useParams<{ logProjectId: string }>();
  return (
    <LogProjectProvider logProjectId={logProjectId}>
      <SamplingParameterProvider>
        <GenericLogSettings />
      </SamplingParameterProvider>
    </LogProjectProvider>
  );
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GenericLogSettingsContainer;
