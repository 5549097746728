/* eslint-disable */
let dateService = require('../../../../../../../services/dateService');
let updatesCompletedPopoverTemplate = require('./templates/updatesCompletedPopover.pug');
let EditModalView = require('../../../shared/hmbpUpdates/HmbpUpdateModalView');
let moment = require('moment');

let HmbpUpdateRowView = Backbone.Marionette.LayoutView.extend({
  tagName: 'li',

  className: 'flex align-items-center justify-content-space-between',

  template: require('./templates/hmbpUpdateRow.pug'),

  ui: {
    delete: '.delete-hmbp-update',
    $updateCompletedCheckmark: '.update-is-complete-checkmark',
  },

  events: {
    'hidden.bs.modal': 'render',
    'click .show-hmbp-update': '_handleShowHmbpUpdate',
  },

  regions: {
    modalContainer: '.modal-container',
  },

  behaviors: {
    Tooltip: {},
    WarnBeforeDelete: {},
  },

  initialize(options = {}) {
    this.projectState = options.projectState;
    this.isDisabled = options.isDisabled;
    this.planName = options.planName;
  },

  onRender() {
    this._enablePopovers();
  },

  templateHelpers: function () {
    let hmbpUpdate = this.model;
    let hmbpUpdateIsCompleted = !!hmbpUpdate.get('date_updated');

    return {
      isCompleted: hmbpUpdateIsCompleted,
      label: dateService.prettifyDateNotInUTCYet(
        hmbpUpdate.get('date_updated') || hmbpUpdate.get('created_at'),
      ),
      planName: this.planName,
    };
  },

  getWarningBeforeDeleteText: function () {
    let createdAt = dateService.prettifyDate(this.model.get('created_at'));
    return 'the ' + this.planName + ' update created on ' + createdAt + '?';
  },

  _enablePopovers() {
    let trigger = 'hover';
    let container = 'body';

    let errors = this.model.get('errors') || [];
    this.$('.warning-red').popover({
      content: errors[0] && errors[0].short_message,
      trigger: trigger,
      container: container,
    });

    let warnings = this.model.get('warnings') || [];
    this.$('.warning-yellow').popover({
      content: warnings[0] && warnings[0].short_message,
      trigger: trigger,
      container: container,
    });

    let dateUpdated = this.model.get('date_updated');
    let popupModel = this.model.toJSON();
    popupModel.date_updated =
      dateUpdated && dateService.prettifyDateNotInUTCYet(dateUpdated);

    let dateSubmittedToGovernment = this.model.get(
      'date_submitted_to_government',
    );
    popupModel.date_submitted_to_government =
      dateSubmittedToGovernment &&
      dateService.prettifyDate(dateSubmittedToGovernment);
    popupModel.projectState = this.projectState;

    this.$('.update-is-complete-checkmark').popover({
      html: true,
      content: updatesCompletedPopoverTemplate(popupModel),
      trigger: trigger,
      container: container,
    });
  },

  _handleShowHmbpUpdate() {
    if (!this.isDisabled()) {
      let editModalView = new EditModalView({
        model: this.model,
        projectState: this.projectState,
        title: this.planName,
      });

      this.modalContainer.show(editModalView);
      editModalView.showModal();
    }
  },
});

module.exports = HmbpUpdateRowView;
