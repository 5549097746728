/* eslint-disable */
const APP = require('../config');

module.exports = Backbone.Marionette.ItemView.extend({
  template: require('./templates/featurePhoto.pug'),
  className: 'photo-item',

  events: {
    'click .feature-photo-image': '_handlePhotoClick',
    'click .delete-feature-photo': '_handlePhotoDelete',
  },

  initialize() {
    this.listenTo(this.model, 'change:rotation', this.render);
  },

  _handlePhotoClick() {
    APP.vent.trigger('projectFeaturePhoto:show', this.model);
  },

  _handlePhotoDelete() {
    APP.vent.trigger('projectFeaturePhoto:destroy', this.model);
  },
});
