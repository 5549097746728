var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (annualAverageSatisfied, annual_average_limit, getAnnualAverageLabel, getAverageDisplayText, getAverageLimitDisplayText, title, warning) {
      pug_html = pug_html + "\u003Ctr" + (pug.attr("class", pug.classes([annualAverageSatisfied() === false ? 'alert-danger' : ''], [true]), false, true)) + "\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = 'Annual ' + title) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003Ctd\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = getAverageDisplayText()) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E\u003C\u002Ftd\u003E\u003Ctd style=\"width: 300px\"\u003E";
if (getAnnualAverageLabel) {
pug_html = pug_html + "\u003Cspan\u003E" + (pug.escape(null == (pug_interp = '(' + getAnnualAverageLabel + ': ' + getAverageLimitDisplayText(annual_average_limit) + ')') ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (warning(annual_average_limit)) {
pug_html = pug_html + "\u003Ca class=\"fa fa-exclamation-triangle warning-yellow\" data-toggle=\"popover\" data-placement=\"left\" style=\"padding-left: 5px\" title=\"Hardness\"\u003E\u003C\u002Fa\u003E\u003Cdiv class=\"display-none\" id=\"harndess-warning-popover-content-wrapper\"\u003E\u003Cspan\u003EPlease tell us the hardness of your receiving water body. \u003Ca href='#' class='open-sector-data-modal'\u003EClick here to update.\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }.call(this, "annualAverageSatisfied" in locals_for_with ?
        locals_for_with.annualAverageSatisfied :
        typeof annualAverageSatisfied !== 'undefined' ? annualAverageSatisfied : undefined, "annual_average_limit" in locals_for_with ?
        locals_for_with.annual_average_limit :
        typeof annual_average_limit !== 'undefined' ? annual_average_limit : undefined, "getAnnualAverageLabel" in locals_for_with ?
        locals_for_with.getAnnualAverageLabel :
        typeof getAnnualAverageLabel !== 'undefined' ? getAnnualAverageLabel : undefined, "getAverageDisplayText" in locals_for_with ?
        locals_for_with.getAverageDisplayText :
        typeof getAverageDisplayText !== 'undefined' ? getAverageDisplayText : undefined, "getAverageLimitDisplayText" in locals_for_with ?
        locals_for_with.getAverageLimitDisplayText :
        typeof getAverageLimitDisplayText !== 'undefined' ? getAverageLimitDisplayText : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined, "warning" in locals_for_with ?
        locals_for_with.warning :
        typeof warning !== 'undefined' ? warning : undefined));
    ;;return pug_html;};
module.exports = template;