/* eslint-disable */
import { createKey as createUserPermissionsKey } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { queryCache } from 'react-query';
import dataProvider from './dataProvider';

const httpStore = {
  _forUserId: null,
  _currentUserPermissionsPromise: null,

  get(userId, key) {
    if (userId !== this._forUserId) {
      this.clear();
    }
    this._forUserId = userId;
    if (!this._currentUserPermissionsPromise) {
      this._currentUserPermissionsPromise =
        dataProvider.fetchCurrentUserPermissions();
    }
    return this._currentUserPermissionsPromise.then((results) => {
      // setting user permissions on initial user permissions load
      // before React even renders
      queryCache.setQueryData(createUserPermissionsKey(), results);
      return results[key];
    });
  },

  clear() {
    this._currentUserPermissionsPromise = null;
    this._forUserId = null;
  },
};

module.exports = httpStore;
