var pug = require("!../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (header, linkTitle) {
      pug_html = pug_html + "\u003Ch4 class=\"flex align-items-center justify-content-space-between\"\u003E" + (pug.escape(null == (pug_interp = header) ? "" : pug_interp)) + "\u003Ca" + (" class=\"edit-parameter-status flex\""+" rel=\"tipsy\""+pug.attr("title", linkTitle, true, true)) + "\u003E\u003Csvg class=\"cog\"\u003E\u003Cuse xlink:href=\"#cog\"\u003E\u003C\u002Fuse\u003E\u003C\u002Fsvg\u003E\u003C\u002Fa\u003E\u003C\u002Fh4\u003E\u003Cdiv id=\"edit-parameter-modal-container\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"parameters-container\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "header" in locals_for_with ?
        locals_for_with.header :
        typeof header !== 'undefined' ? header : undefined, "linkTitle" in locals_for_with ?
        locals_for_with.linkTitle :
        typeof linkTitle !== 'undefined' ? linkTitle : undefined));
    ;;return pug_html;};
module.exports = template;