/* eslint-disable */
let CurrentStatusItemView = Backbone.Marionette.ItemView.extend({
  tagName: 'li',
  template: require('./templates/currentStatusRow.pug'),

  modelEvents: {
    'change:status': 'render',
  },

  initialize(options = {}) {
    this.getParameterDisplayText = options.getParameterDisplayText;
    this.isWashingtonIndustrial = options.isWashingtonIndustrial;
    this.showParameterStatus = options.showParameterStatus;
  },

  templateHelpers() {
    return {
      getParameterDisplayText: this.getParameterDisplayText,
      isWashingtonIndustrial: this.isWashingtonIndustrial,
      showParameterStatus: this.showParameterStatus,
    };
  },
});

module.exports = CurrentStatusItemView;
