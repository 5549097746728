import { FrequencyProvider } from '@monorepo/shared/contexts/FrequencyContext';
import { CUSTOM_FREQUENCY } from '@monorepo/shared/contexts/FrequencyContext/types';
import { CustomFrequency, IntervalFrequencyEnum } from 'mapistry-shared';
import React, { useState } from 'react';
import { FrequencySelect } from '../../../frequencyForm';
import { ParameterMonitoringLocation } from './types';

const INTERVAL_FREQUENCY_ENUM_GROUP = [CUSTOM_FREQUENCY];
export const NON_CUSTOM_FREQUENCIES = [
  IntervalFrequencyEnum.DAY,
  IntervalFrequencyEnum.WEEK,
  IntervalFrequencyEnum.MONTH,
  IntervalFrequencyEnum.QUARTER,
  IntervalFrequencyEnum.BIANNUAL,
  IntervalFrequencyEnum.YEAR,
];
const FREQUENCIES = [...NON_CUSTOM_FREQUENCIES, INTERVAL_FREQUENCY_ENUM_GROUP];

type FrequencyPickerProps = {
  customFrequencyInCamel?: CustomFrequency;
  spml: ParameterMonitoringLocation;
  stageSamplingParameterEdit: (
    key: string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    spml: ParameterMonitoringLocation,
  ) => void;
};

export function FrequencyPicker(props: FrequencyPickerProps) {
  const { customFrequencyInCamel, spml, stageSamplingParameterEdit, ...rest } =
    props;
  // this is a workaround, do not copy!
  // triggering the "stageSamplingParameterEdit" causes the FrequencyPopper to close
  // (presumably because of a whole tree re-render). This is a workaround to avoid that happening.
  const [editingFrequency, setEditingFrequency] = useState<
    IntervalFrequencyEnum | null | undefined
  >();
  const [editingCustomFrequency, setEditingCustomFrequency] = React.useState<
    CustomFrequency | undefined
  >();

  return (
    <FrequencyProvider
      alwaysUseDatePicker
      noStartDate
      selectedFrequency={spml.frequency}
      customFrequency={customFrequencyInCamel}
      onFrequencyChange={(frequency, customFrequency) => {
        if (!customFrequency) {
          stageSamplingParameterEdit('frequency', frequency, spml);
          stageSamplingParameterEdit('custom_frequency', null, spml);
        } else {
          setEditingFrequency(frequency);
          setEditingCustomFrequency(customFrequency);
        }
      }}
      onSave={() => {
        stageSamplingParameterEdit('frequency', editingFrequency, spml);
        stageSamplingParameterEdit(
          'custom_frequency',
          editingCustomFrequency,
          spml,
        );
      }}
    >
      <FrequencySelect
        isClearable={false}
        frequencyOptions={FREQUENCIES}
        customFrequencyMessageStart="Sampling"
        customPopperHeader="Frequency selector"
        customPopperSubHeader="Select a frequency"
        {...rest}
      />
    </FrequencyProvider>
  );
}
