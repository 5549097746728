var pug = require("!../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (permit) {
      pug_html = pug_html + "\u003Clabel\u003E" + (pug.escape(null == (pug_interp = permit) ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Cdiv class=\"well\"\u003E\u003Clabel\u003ESelect all that apply\u003C\u002Flabel\u003E\u003Cdiv class=\"dsn-choices\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "permit" in locals_for_with ?
        locals_for_with.permit :
        typeof permit !== 'undefined' ? permit : undefined));
    ;;return pug_html;};
module.exports = template;