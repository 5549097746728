import { CustomLogType } from '@monorepo/shared/apiClient/types';
import React from 'react';
import _get from 'lodash.get';
import { GenericLogType } from 'mapistry-shared';
import PropTypes from 'prop-types';
import { EmptyTabContent, TABLE_SETTINGS } from '../shared';
import EmissionTrackingTable from '../shared/EmissionTrackingTable';
// ComponentPicker imports both the table and the row so we get a cycle
// If this becomes a problem we can split it into multiple "pickers" (ajb)
// eslint-disable-next-line import/no-cycle
import { ComponentPicker } from '../../ComponentPicker';
import { TextField } from '../../../elements';
import {
  useCustomLogTemplates,
  useLocateTemplateSettingsSection,
} from '../../../../hooks/genericLogs/useCustomLogTemplates';

const LoggedItemsTable = (props) => {
  const {
    addLoggedItem,
    deleteLoggedItem,
    formErrors,
    formSubmissionDraft,
    handleDeleteGroup,
    isLoading,
    itemGroups,
    loggedItems,
    logProjectId,
    onDragEnd,
    selectMenuPortalRef,
    stageEdit,
  } = props;

  const logTemplate = useCustomLogTemplates();
  const { rowType } = useLocateTemplateSettingsSection(logTemplate);
  const isWaterLog =
    logTemplate.type === CustomLogType.stormwater ||
    logTemplate.type === CustomLogType.water;

  const renderRow = (loggedItem, index, groupId) => (
    <ComponentPicker
      type={rowType}
      deleteLoggedItem={deleteLoggedItem}
      formErrors={formErrors}
      groupId={groupId}
      loggedItem={loggedItem}
      loggedItemIdx={index}
      stageEdit={stageEdit}
      selectMenuPortalRef={selectMenuPortalRef}
    />
  );

  const renderRowWhileBeingDragged = (loggedItem) => (
    <TextField
      disabled
      controlled={false}
      defaultValue={loggedItem.name || '...dragging'}
    />
  );

  const hasLoggedItems =
    loggedItems.length ||
    Object.keys(formSubmissionDraft.groups).some(
      (groupKey) => _get(formSubmissionDraft.groups, groupKey).length,
    );

  if (!hasLoggedItems && !isLoading) {
    return <EmptyTabContent title="Logged Items" addItem={addLoggedItem} />;
  }

  return (
    <EmissionTrackingTable
      addItem={addLoggedItem}
      formErrors={formErrors}
      formSubmissionDraft={formSubmissionDraft}
      groupType={GenericLogType.LOGGED_ITEM}
      handleDeleteGroup={handleDeleteGroup}
      itemGroups={itemGroups}
      isLoading={isLoading}
      logProjectId={logProjectId}
      onDragEnd={onDragEnd}
      tableInfo={
        isWaterLog ? TABLE_SETTINGS.WATER_LOGGED : TABLE_SETTINGS.LOGGED_ITEMS
      }
      renderRow={renderRow}
      renderRowWhileBeingDragged={renderRowWhileBeingDragged}
    />
  );
};

LoggedItemsTable.propTypes = {
  addLoggedItem: PropTypes.func.isRequired,
  deleteLoggedItem: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({
    displayable: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  formSubmissionDraft: PropTypes.shape({
    groups: PropTypes.shape({}),
  }).isRequired,
  handleDeleteGroup: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemGroups: PropTypes.shape({}).isRequired,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  selectMenuPortalRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  stageEdit: PropTypes.func.isRequired,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default LoggedItemsTable;
