/* eslint-disable */
var LayerNameChooserView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/layerNameChooser.pug'),

  ui: {
    $layerChooser: 'select',
  },

  events: {
    'select2:select': '_layerChosen',
  },

  activate: function (layerCollection, layerType, defaultValue) {
    let layers = layerCollection.filter(
      (layer) => layerType === layer.getType(),
    );

    this.ui.$layerChooser.select2({
      placeholder: 'Select a layer',
      data: layers.map(function (layer) {
        return {
          id: layer.id,
          text: layer.get('name'),
          numFeatures: layer.featureCount(),
        };
      }),
      width: '100%',
      escapeMarkup: function (markup) {
        return markup;
      },
      templateResult: require('./templates/layerChooserSuggestion.pug'),
    });

    if (defaultValue) {
      this.setValue(defaultValue);
    }
  },

  getValue: function () {
    return this.ui.$layerChooser.val();
  },

  setValue: function (val) {
    this.ui.$layerChooser.val(val).trigger('change');
  },

  _layerChosen: function () {
    this.trigger('layer:chosen');
  },
});

module.exports = LayerNameChooserView;
