import PropTypes from 'prop-types';
import React from 'react';
import { isNullOrUndefined } from '../../../utils';

/* @ts-expect-error - TODO: Fix this the next time the file is edited. */
const TextCell = (props) => {
  const { statistic } = props;
  return (
    <div className="emission-tracking-list__item">
      <span>
        {isNullOrUndefined(statistic?.totalLoggedValue)
          ? '--'
          : statistic.totalLoggedValue}
      </span>
    </div>
  );
};

TextCell.propTypes = {
  statistic: PropTypes.shape({
    totalLoggedValue: PropTypes.number,
  }),
};

TextCell.defaultProps = {
  statistic: null,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default TextCell;
