import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  createEmissionLogTabsAction,
  deleteEmissionLogAction,
  deleteEmissionLogTabsAction,
  downloadEmissionTrackingLog,
  fetchEmissionLoggedItemsAction,
  fetchEmissionLogParametersAction,
  fetchEmissionLogsAction,
  fetchEmissionLogTabsAction,
  resetEmissionLogsAction,
  updateEmissionLogTabsAction,
} from '../../../actions/air';
import ProjectContext from '../../../contexts/ProjectContext';
import {
  getIsFetching,
  getLoggedItems,
  getLogParameters,
  getLogs,
  getLogTabs,
  getProjectHasLogs,
} from '../../../selectors/genericLogs';
import withProvider from '../../withProvider';
import { LogProjectProvider } from '../LogProjectContext';
import { GenericLogTrackingView } from './GenericLogTrackingView';

class EmissionTrackingViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: null,
    };
    this.handleTabCreate = this.handleTabCreate.bind(this);
    this.handleTabDelete = this.handleTabDelete.bind(this);
    this.handleTabRename = this.handleTabRename.bind(this);
    this.fetchEmissionLogs = this.fetchEmissionLogs.bind(this);
    this.handleDeleteRecord = this.handleDeleteRecord.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSaveLog = this.handleSaveLog.bind(this);
  }

  componentDidMount() {
    const {
      fetchTabs,
      fetchParameters,
      fetchEmissionLoggedItems,
      logProjectId,
    } = this.props;
    const { projectId } = this.context;

    fetchEmissionLoggedItems(projectId, logProjectId);
    fetchTabs(projectId, logProjectId);
    fetchParameters(projectId, logProjectId);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { fetchEmissionLog, logProjectId, resetLogs, tabs } = this.props;
    const { projectId } = this.context;
    const { selectedTab } = this.state;
    if (!selectedTab && tabs.length) {
      this.setState({ selectedTab: tabs[0] });
    }
    if (selectedTab && selectedTab !== prevState.selectedTab) {
      await resetLogs(logProjectId);
      fetchEmissionLog(projectId, logProjectId, selectedTab.parameters);
    }
    if (selectedTab && prevProps.tabs !== tabs) {
      const updatedSelectedTab = tabs.find((t) => t.id === selectedTab.id);
      this.setState({ selectedTab: updatedSelectedTab });
    }
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  fetchEmissionLogs(logStats) {
    const { fetchEmissionLog, logProjectId } = this.props;
    const { projectId } = this.context;
    const { selectedTab } = this.state;
    const { start, end, frequency } = logStats;
    fetchEmissionLog(projectId, logProjectId, selectedTab.parameters, {
      start,
      end,
      frequency,
    });
  }

  async handleDeleteRecord(log) {
    const { deleteEmissionLog, fetchEmissionLog, logProjectId, resetLogs } =
      this.props;
    const { projectId } = this.context;
    const { selectedTab } = this.state;
    deleteEmissionLog(projectId, logProjectId, log.start);
    await resetLogs(logProjectId);
    fetchEmissionLog(projectId, logProjectId, selectedTab.parameters);
  }

  async handleSaveLog() {
    const { fetchEmissionLog, logProjectId, resetLogs } = this.props;
    const { projectId } = this.context;
    const { selectedTab } = this.state;
    await resetLogs(logProjectId);
    fetchEmissionLog(projectId, logProjectId, selectedTab.parameters);
  }

  handleFilterChange(selectedParameters) {
    const { selectedTab } = this.state;
    const { logProjectId, updateTab } = this.props;
    const { projectId } = this.context;
    updateTab(projectId, {
      ...selectedTab,
      logProjectId,
      parameters: selectedParameters,
    });
  }

  handleTabCreate(tabOptions) {
    const { createTab, logProjectId, tabs } = this.props;
    const { projectId } = this.context;
    createTab(projectId, {
      label: tabOptions.label || `Tab ${tabs.length + 1}`,
      parameters: [],
      logProjectId,
      type: tabOptions.type,
    });
  }

  handleTabDelete(tab) {
    const { deleteTab, logProjectId } = this.props;
    const { projectId } = this.context;
    deleteTab(projectId, logProjectId, tab.id);
  }

  async handleTabRename(tab, newLabel) {
    const { logProjectId, updateTab } = this.props;
    const { projectId } = this.context;
    if (newLabel.trim().length) {
      updateTab(projectId, { ...tab, label: newLabel, logProjectId });
    }
  }

  navigateToSettings() {
    const { logProjectId } = this.props;
    const { projectId } = this.context;
    const emissionsTrackingSettingsUrl = `/projects/${projectId}/custom-logs/${logProjectId}/settings`;
    Backbone.history.navigate(emissionsTrackingSettingsUrl, { trigger: true });
  }

  render() {
    const {
      allParameters,
      downloadLog,
      isLoading,
      isTabsLoading,
      loggedItems,
      loggedItemValueEvents,
      projectHasLogs,
      logProjectId,
      tabs,
    } = this.props;
    const { projectId } = this.context;
    const { selectedTab } = this.state;

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react/jsx-filename-extension
      <LogProjectProvider logProjectId={logProjectId}>
        <GenericLogTrackingView
          allParameters={allParameters}
          onDownload={() => downloadLog(projectId, logProjectId)}
          fetchEmissionLogs={this.fetchEmissionLogs}
          isLoading={isLoading}
          isTabsLoading={isTabsLoading}
          logProjectId={logProjectId}
          loggedItems={loggedItems}
          loggedItemValueEvents={loggedItemValueEvents}
          navigateToSettings={() => this.navigateToSettings()}
          onDeleteRecord={this.handleDeleteRecord}
          onFilterChange={this.handleFilterChange}
          onTabCreate={this.handleTabCreate}
          onTabDelete={this.handleTabDelete}
          onTabRename={this.handleTabRename}
          onTabSelect={(o) => this.setState({ selectedTab: o })}
          onSaveLog={this.handleSaveLog}
          projectHasLogs={projectHasLogs}
          projectId={projectId}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      </LogProjectProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { logProjectId },
    },
  } = ownProps;

  return {
    allParameters: getLogParameters(state, logProjectId),
    isLoading: getIsFetching(state, 'airEmissionLogs'),
    isTabsLoading: getIsFetching(state, 'airEmissionLogTabs'),
    loggedItems: getLoggedItems(state, logProjectId),
    loggedItemValueEvents: getLogs(state, logProjectId),
    logProjectId,
    projectHasLogs: getProjectHasLogs(state, logProjectId),
    tabs: getLogTabs(state, logProjectId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTab: (projectId, logTab) =>
    dispatch(createEmissionLogTabsAction(projectId, logTab)),
  deleteEmissionLog: (projectId, logProjectId, logDatetime) =>
    dispatch(deleteEmissionLogAction(projectId, logProjectId, { logDatetime })),
  deleteTab: (projectId, logProjectId, id) =>
    dispatch(deleteEmissionLogTabsAction(projectId, logProjectId, id)),
  downloadLog: (projectId, logProjectId) =>
    dispatch(downloadEmissionTrackingLog(projectId, logProjectId)),
  fetchEmissionLoggedItems: (projectId, logProjectId) =>
    dispatch(fetchEmissionLoggedItemsAction(projectId, logProjectId)),
  fetchEmissionLog: (projectId, logProjectId, parameters, interval) =>
    dispatch(
      fetchEmissionLogsAction(projectId, logProjectId, parameters, interval),
    ),
  fetchTabs: (projectId, logProjectId) =>
    dispatch(fetchEmissionLogTabsAction(projectId, logProjectId)),
  fetchParameters: (projectId, logProjectId) =>
    dispatch(fetchEmissionLogParametersAction(projectId, logProjectId)),
  resetLogs: (logProjectId) => dispatch(resetEmissionLogsAction(logProjectId)),
  updateTab: (projectId, logTab) =>
    dispatch(updateEmissionLogTabsAction(projectId, logTab)),
});

EmissionTrackingViewContainer.propTypes = {
  allParameters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  createTab: PropTypes.func.isRequired,
  deleteEmissionLog: PropTypes.func.isRequired,
  deleteTab: PropTypes.func.isRequired,
  downloadLog: PropTypes.func.isRequired,
  fetchEmissionLog: PropTypes.func.isRequired,
  fetchEmissionLoggedItems: PropTypes.func.isRequired,
  fetchTabs: PropTypes.func.isRequired,
  fetchParameters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isTabsLoading: PropTypes.bool,
  loggedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loggedItemValueEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logProjectId: PropTypes.string.isRequired,
  projectHasLogs: PropTypes.bool.isRequired,
  resetLogs: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateTab: PropTypes.func.isRequired,
};

EmissionTrackingViewContainer.defaultProps = {
  isLoading: false,
  isTabsLoading: false,
};
EmissionTrackingViewContainer.contextType = ProjectContext;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(
  connect(mapStateToProps, mapDispatchToProps)(EmissionTrackingViewContainer),
);
