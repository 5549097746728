var pug = require("!../../../../../../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (q1Label, q2Label) {
      pug_html = pug_html + "\u003Cdiv class=\"row sampling-summary-row\"\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = q1Label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cdiv class=\"sample-1 padding-top-7\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Cspan\u003E" + (pug.escape(null == (pug_interp = q2Label) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cdiv class=\"sample-2 padding-top-7\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "q1Label" in locals_for_with ?
        locals_for_with.q1Label :
        typeof q1Label !== 'undefined' ? q1Label : undefined, "q2Label" in locals_for_with ?
        locals_for_with.q2Label :
        typeof q2Label !== 'undefined' ? q2Label : undefined));
    ;;return pug_html;};
module.exports = template;