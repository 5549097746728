import { CustomLogType } from '@monorepo/shared/apiClient/types';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { MapLayerTags, waterTags } from 'mapistry-shared';
import { WaterLogLimitItemFormSubmission } from './LimitItems/types';
import { LoggedItemFormSubmission } from './LoggedItems/types';
import { useCurrentLogProject } from '../LogProjectContext';
import { Select } from '../../elements';
import { useProject } from '../../../contexts/ProjectContext';
import { useMapFeatures } from '../../../hooks/useMapFeatures';
import { useCustomLogTemplates } from '../../../hooks/genericLogs/useCustomLogTemplates';
import { useLoggedItems } from '../../../hooks/genericLogs/useLoggedItems';

const dischargeLocationTags = {
  [CustomLogType.stormwater]: [MapLayerTags.DISCHARGE_LOCATIONS],
  [CustomLogType.water]: waterTags,
};

interface SelectDischargeLocationProps {
  className?: string;
  errors: { [field: string]: string };
  menuRef: React.RefObject<HTMLDivElement>;
  onChange: (option: string) => void;
}

interface SelectDischargeLocationForLoggedItemProps
  extends SelectDischargeLocationProps {
  item: LoggedItemFormSubmission;
}
interface SelectDischargeLocationForLimitItemProps
  extends SelectDischargeLocationProps {
  item: WaterLogLimitItemFormSubmission;
}

const isLoggedItem = (
  x: LoggedItemFormSubmission | WaterLogLimitItemFormSubmission,
): x is LoggedItemFormSubmission => 'isLocked' in x;

export const SelectDischargeLocation = <
  T extends LoggedItemFormSubmission | WaterLogLimitItemFormSubmission,
>(
  props: T extends LoggedItemFormSubmission
    ? SelectDischargeLocationForLoggedItemProps
    : SelectDischargeLocationForLimitItemProps,
): ReactElement => {
  const { className, errors, item, menuRef, onChange } = props;
  const { projectId } = useProject();
  const { logProject } = useCurrentLogProject();
  const { type: logTemplateType } = useCustomLogTemplates();
  const { loggedItems } = useLoggedItems(logProject?.id ?? '');
  const { mapFeatures: dischargeLocations, isLoading } = useMapFeatures({
    projectId: projectId ?? '',
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    tags: dischargeLocationTags[logTemplateType],
  });

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  let dischargeLocation;
  let isMulti = false;
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
  let parameter;

  if (isLoggedItem(item)) {
    dischargeLocation = item.location;
    isMulti = !item.id;
  } else {
    ({ dischargeLocation, parameter } = item);
  }

  const options = useMemo(() => {
    if (isLoggedItem(item)) {
      return (
        dischargeLocations?.map((location) => ({
          label: location.name,
          value: location.id,
        })) || []
      );
    }
    const filtered: { label: string; value: string }[] = [];
    loggedItems?.forEach((loggedItem) => {
      const location = dischargeLocations?.find(
        (l) => l.id === loggedItem.location,
      );

      if (
        // Didn't find the discharge location
        !location ||
        // Exclude discharge locations that don't match the already selected parameter
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
        (parameter && parameter !== loggedItem.parameter) ||
        // De-dupe
        filtered.some((f) => f.value === location.id)
      ) {
        return;
      }

      filtered.push({
        label: location.name,
        value: location.id,
      });
    });
    return filtered;
  }, [dischargeLocations, item, loggedItems, parameter]);

  const optionFromValue = useMemo(() => {
    if (
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      Array.isArray(dischargeLocation) ||
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      typeof dischargeLocation === 'object'
    ) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      return dischargeLocation;
    }
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
    const option = options.find((opt) => opt.value === dischargeLocation);
    return isMulti ? [option] : option;
  }, [dischargeLocation, isMulti, options]);

  const handleOnChange = useCallback(
    (option) => (isMulti ? onChange(option) : onChange(option?.value)),
    [isMulti, onChange],
  );

  return (
    <Select
      className={`${className} flex-grow-1`}
      error={isLoggedItem(item) ? !!errors?.location : !!errors?.resourceId}
      isClearable
      isDisabled={isLoading}
      isMulti={isMulti}
      menuPlacement="auto"
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-expect-error - TODO: Fix this the next time the file is edited. */
      menuPortalTarget={menuRef?.current}
      onChange={handleOnChange}
      options={options}
      value={optionFromValue}
    />
  );
};
