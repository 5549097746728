/* eslint-disable react/prop-types */
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { SaveState } from '@monorepo/shared/types/SaveState';
import React from 'react';
import ConfirmDialog from '../../elements/ConfirmDialog';
import ErrorMessage from '../../elements/ErrorMessage';
import { InspectionFormTheme } from '../../themes/inspectionFormTheme';
import { ContentFooter } from './content/ContentFooter';
import { ContentHeading } from './content/ContentHeading';
import FormContent from './content/FormContent';
import FormSidebar from './sidebar/FormSidebar';

class FormSubmissionEditor extends React.Component {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    groupToDelete: null,
    groupNumberToDelete: null,
    downloadDirtyForm: false,
    deletingForm: false,
  };

  constructor(props) {
    super(props);

    this.handleDeleteSubsection = this.handleDeleteSubsection.bind(this);
    this.handleDownloadForm = this.handleDownloadForm.bind(this);
    this.handleDeleteSubmission = this.handleDeleteSubmission.bind(this);
  }

  getSubmissionGroups(submissionGroups, templateGroupId) {
    return submissionGroups.filter(
      (sg) => sg.formTemplateFieldGroupId === templateGroupId,
    );
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  handleDeleteSubsection(templateGroupId, submissionGroupNumber) {
    const { formSubmission } = this.props;
    const submissionGroups = this.getSubmissionGroups(
      formSubmission.groups,
      templateGroupId,
    );
    const submissionGroup = submissionGroups[submissionGroupNumber];
    this.setState({
      groupToDelete: submissionGroup,
      groupNumberToDelete: submissionGroupNumber,
    });
  }

  confirmDeleteSubsection() {
    const { groupToDelete, groupNumberToDelete } = this.state;
    const { onDeleteSubsection } = this.props;
    onDeleteSubsection(groupToDelete, groupNumberToDelete);
    this.setState({ groupToDelete: null, groupNumberToDelete: null });
  }

  handleDownloadForm() {
    const { saveState, onDownloadForm } = this.props;
    if (saveState === SaveState.DIRTY) {
      this.setState({ downloadDirtyForm: true });
    } else {
      onDownloadForm();
    }
  }

  handleDeleteSubmission() {
    this.setState({ deletingForm: true });
  }

  confirmDownloadForm() {
    const { onDownloadForm } = this.props;
    onDownloadForm();
    this.setState({ downloadDirtyForm: false });
  }

  confirmDeleteSubmission() {
    const { onDeleteSubmission } = this.props;
    onDeleteSubmission();
    this.setState({ deletingForm: false });
  }

  errorMessage() {
    const { errorCount, errorMessage, onCloseErrorMessage } = this.props;
    return errorCount > 0 || errorMessage ? (
      <ErrorMessage
        onClose={onCloseErrorMessage}
        numberOfFormErrors={errorCount}
        errorMessage={errorMessage}
      />
    ) : null;
  }

  confirmDialog() {
    const { groupToDelete, downloadDirtyForm, deletingForm } = this.state;

    if (deletingForm) {
      return (
        <ConfirmDialog
          open
          title="Are you sure you want to discard this submission?"
          confirmButtonText="Discard"
          onConfirmed={() => this.confirmDeleteSubmission()}
          onCancelled={() => this.setState({ deletingForm: false })}
          danger
        />
      );
    }

    if (groupToDelete) {
      return (
        <ConfirmDialog
          open
          title={`Are you sure you want to delete ${groupToDelete.name}?`}
          confirmButtonText="Delete"
          onConfirmed={() => this.confirmDeleteSubsection()}
          onCancelled={() => this.setState({ groupToDelete: null })}
          danger
        />
      );
    }
    if (downloadDirtyForm) {
      return (
        <ConfirmDialog
          open
          title="You are about to download a form with unsaved changes"
          description="Any updates you've entered since you last saved will not be included in the download."
          confirmButtonText="Request Download"
          onConfirmed={() => this.confirmDownloadForm()}
          onCancelled={() => this.setState({ downloadDirtyForm: false })}
          danger
        />
      );
    }
    return null;
  }

  isLockedForEdits() {
    const { currentUser, lockInfo, requestPageReloadBeforeYieldingFormLock } =
      this.props;
    if (requestPageReloadBeforeYieldingFormLock) return true;
    if (!currentUser || !lockInfo) return false;
    return currentUser.id !== lockInfo.lockedBy.id;
  }

  render() {
    const {
      currentSubmissionGroup,
      currentSubmissionGroupNumber,
      currentTemplateGroup,
      currentUser,
      downloading,
      eventDate,
      formSubmission,
      formTemplate,
      lockInfo,
      hasAlreadyRequestedDownload,
      isStencil,
      loadError,
      onClickSection,
      onCloseForm,
      onCreateSubsection,
      onFieldChange,
      onGoToNextSection,
      onFieldError,
      onSaveForm,
      onUpdateSignature,
      project,
      requestPageReloadBeforeYieldingFormLock,
      saveState,
    } = this.props;

    return (
      <div className="form-submission-editor">
        {this.confirmDialog()}
        <FormSidebar
          isStencil={isStencil}
          project={project}
          templateGroups={formTemplate.groups}
          submissionGroups={formSubmission.groups}
          currentTemplateGroupId={
            currentTemplateGroup && currentTemplateGroup.id
          }
          currentSubmissionGroupNumber={currentSubmissionGroupNumber}
          onClickSection={onClickSection}
          onDeleteSubsection={this.handleDeleteSubsection}
          onCreateSubsection={onCreateSubsection}
        />
        <ThemeProvider theme={InspectionFormTheme}>
          <div className="form-submission-editor-main">
            <ContentHeading
              currentUser={currentUser}
              disableDelete={!formSubmission.id || downloading}
              disableDownload={
                !formSubmission.id || downloading || hasAlreadyRequestedDownload
              }
              error={loadError}
              eventDate={eventDate}
              formTemplateId={formTemplate.id}
              lockInfo={lockInfo}
              isStencil={isStencil}
              onDelete={this.handleDeleteSubmission}
              onDownload={this.handleDownloadForm}
              requestPageReloadBeforeYieldingFormLock={
                requestPageReloadBeforeYieldingFormLock
              }
              sectionName={formTemplate.name}
              showDownloadingProgress={downloading}
              updatedAt={formSubmission.updatedAt || formSubmission.createdAt}
              updatedBy={formSubmission.updatedByUserName}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormContent
                templateGroup={currentTemplateGroup}
                submissionGroup={currentSubmissionGroup}
                projectId={formSubmission.projectId}
                currentUser={currentUser}
                error={loadError}
                isLocked={this.isLockedForEdits()}
                isStencil={isStencil}
                onFieldChange={onFieldChange}
                onFieldError={onFieldError}
                onCreateSubsection={onCreateSubsection}
                onUpdateSignature={onUpdateSignature}
              />
            </MuiPickersUtilsProvider>
            <ContentFooter
              saveState={saveState}
              onGoToNextSection={onGoToNextSection}
              isLocked={this.isLockedForEdits()}
              onClose={onCloseForm}
              onSave={onSaveForm}
            />
          </div>
        </ThemeProvider>
        {this.errorMessage()}
      </div>
    );
  }
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default FormSubmissionEditor;
