/* eslint-disable */
module.exports = function () {
  // Hack to fix weird hitting backspace on multi-select converts element to text behavior
  // https://github.com/select2/select2/issues/3354#issuecomment-132389291
  $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
    var oldRemoveChoice = Search.prototype.searchRemoveChoice;

    Search.prototype.searchRemoveChoice = function () {
      oldRemoveChoice.apply(this, arguments);
      this.$search.val('');
    };
  });
};
