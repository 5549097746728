/* eslint-disable */
module.exports = function ($el, options = {}) {
  var defaults = {
    fade: true,
    delayIn: 1000,
    gravity: 's',
  };

  options = _.extend(defaults, options);

  // Nicer looking tooltips used for some buttons.
  // http://onehackoranother.com/projects/jquery/tipsy/
  $el.tipsy(options);
};
