/* eslint-disable */
import { OrgPermissionRoles } from 'mapistry-shared';
import contentLayoutViewFactory from './content/ContentLayoutViewFactory';

let P = require('bluebird');

var BaseDashboardLayoutView = require('../BaseDashboardLayoutView');
const organizationCollection =
  require('../../models_collections/organizationCollection').singleton();
const currentUser = require('../../currentUser');

var SettingDashboardLayoutView = BaseDashboardLayoutView.extend({
  initialize(options = {}) {
    this.currentSettingSlug = options.currentSettingSlug;
    this.userId = options.userId;
  },

  onShow() {
    let organizationPromise = this._getAdminOrganizations();
    organizationPromise.then((adminOrganizations) =>
      this._renderSettingsSidebar(this.currentSettingSlug, adminOrganizations),
    );
    organizationPromise.then((adminOrganizations) =>
      this._renderContent(adminOrganizations),
    );
  },

  _getAdminOrganizations() {
    // this will be removed when we add ability to modify organizations for other users
    if (this.userId && this.userId !== currentUser.id) {
      return P.resolve([]); // using a regular Promise here causes the dreaded promise handler warning :(
    } else {
      return organizationCollection.fetch({ userId: this.userId }).then(() =>
        organizationCollection.where({
          roleName: OrgPermissionRoles.ORGANIZATION_ADMIN,
        }),
      );
    }
  },

  _renderContent(adminOrganizations) {
    return contentLayoutViewFactory
      .create(this.currentSettingSlug, this.userId, adminOrganizations)
      .then((contentLayoutView) => {
        this.content.show(contentLayoutView);
      });
  },

  _renderSettingsSidebar(currentSettingSlug, adminOrganizations) {
    let SidebarCollectionView = require('./sidebar/SidebarSettingCollectionView');

    let sidebarLayoutView = new SidebarCollectionView({
      collection: this._getSettingsCollection(adminOrganizations),
      currentSettingSlug,
      userId: this.userId,
    });
    this.sidebar.show(sidebarLayoutView);
  },

  _getSettingsCollection(adminOrganizations) {
    let settingModels = [
      { slug: 'notifications', label: 'Notifications' },
      { slug: 'profile', label: 'Profile Settings' },
    ];

    if (_.some(adminOrganizations)) {
      settingModels.push({ slug: 'organization', label: 'Organization' });
    }

    const currentUser = require('../../currentUser');
    if (currentUser?.get('is_super_admin')) {
      settingModels.push({
        groupHeader: true,
        label: 'Mapistry Admin Resources:',
      });
      settingModels.push({ slug: 'site-bulk-upload', label: 'Upload Sites' });
      settingModels.push({ slug: 'user-bulk-upload', label: 'Upload Users' });
      settingModels.push({ slug: 'task-bulk-upload', label: 'Upload Tasks' });
    }

    return new Backbone.Collection(settingModels);
  },
});

module.exports = SettingDashboardLayoutView;
