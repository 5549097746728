import { usePrevious } from '@monorepo/shared/hooks/usePrevious';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  AutoSizer,
} from 'react-virtualized';
import { TABLE_SETTINGS } from '.';

const VirtualizedListVaryingRowHeight = (props) => {
  const {
    rowHeight,
    getRowCountForGroup,
    groupOfItems,
    groupId,
    isUsingPlaceholder,
    scrollIndex,
    rowRenderer,
  } = props;

  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: TABLE_SETTINGS.ROW_HEIGHT,
      fixedWidth: true,
    }),
  );

  // eslint-disable-next-line react/prop-types
  const dynamicHeightRowRenderer = ({ index, key, parent, style }) =>
    index >= groupOfItems.length ? null : (
      <CellMeasurer
        cache={cache.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          // 'style' attribute required to position cell (within parent List)
          <div style={style} ref={registerChild}>
            {rowRenderer({ index, measure })}
          </div>
        )}
      </CellMeasurer>
    );

  // recalculating all row heights after drag and drop
  // this is a bit aggressive, could be optimized
  const prevIsUsingPlaceholder = usePrevious(isUsingPlaceholder);
  useEffect(() => {
    if (prevIsUsingPlaceholder === true && isUsingPlaceholder === false) {
      cache.current.clearAll();
    }
  }, [isUsingPlaceholder, prevIsUsingPlaceholder]);

  let itemCount = isUsingPlaceholder
    ? groupOfItems.length + 1
    : groupOfItems.length;
  if (groupOfItems.length === 0) itemCount = 0; // show only the drop placeholder for empty lists

  const numberOfItems = React.useMemo(
    () => getRowCountForGroup(groupOfItems),
    [getRowCountForGroup, groupOfItems],
  );
  // each limit gets a row, and one "Add a Limit" row per item, too
  const totalHeight = (itemCount + numberOfItems) * rowHeight;

  return (
    <AutoSizer
      style={{
        height: `min(calc(100vh - ${TABLE_SETTINGS.SPACE_ABOVE_TABLE}px), ${totalHeight}px)`,
      }}
    >
      {({ height, width }) => (
        <List
          deferredMeasurementCache={cache.current}
          overscanRowCount={5}
          rowCount={itemCount}
          rowHeight={cache.current.rowHeight}
          rowRenderer={dynamicHeightRowRenderer}
          height={height}
          width={width}
          scrollToIndex={groupId === 'null' ? scrollIndex : undefined}
        />
      )}
    </AutoSizer>
  );
};

VirtualizedListVaryingRowHeight.propTypes = {
  rowHeight: PropTypes.number,
  scrollIndex: PropTypes.number,
  getRowCountForGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/forbid-prop-types
  groupOfItems: PropTypes.array.isRequired,
  isUsingPlaceholder: PropTypes.bool.isRequired,
  rowRenderer: PropTypes.func.isRequired,
};

VirtualizedListVaryingRowHeight.defaultProps = {
  rowHeight: TABLE_SETTINGS.ROW_HEIGHT,
  scrollIndex: undefined,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default VirtualizedListVaryingRowHeight;
